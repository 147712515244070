import React, { useState } from "react";

import { usePressListFilterQuery } from "@/queries/hooks/usePressListFilter";
import { ProcessStatusCode } from "@/types/code.types";
import { usePublishableArticleInfoMutation } from "@api/articleInfo/useArticleInfo";
import { useCreateEntryDataMutation } from "@api/createEntryData/createEntryData";
import { useUnsuitablePressCancelMutation } from "@api/unsuitablePress/unsuitablePress";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import useModal from "@hooks/useModal";
import {
  checkSuperAdmin,
  useAuthorityType,
} from "@libs/roles/hooks/useAuthorityType";
import { useEditorRoles } from "@libs/roles/hooks/useEditorRoles";
import { CLASSIFICATION_GROUP } from "@PressListContainer/classificationInfo.constants";
import { formatDate } from "@utils/formatDate";
import { handleDraftError } from "@utils/handleDraftError";

import PressListCompanyShell from "./components/PressListCompanyShell/PressListCompanyShell";
import PressListGuideModal from "./components/PressLIstGuideModal/PressListGuideModal";
import PressListPageEntryGuide from "./components/PressListPageEntryGuide/PressListPageEntryGuide";
import PressListCheckStatusGuide from "./components/PressListTableShellForPressTitle/components/PressListCheckStatusGuide/PressListCheckStatusGuide";
import PressListTableShellForPressTitle from "./components/PressListTableShellForPressTitle/PressListTableShellForPressTitle";
import PressStatus from "./components/PressStatus/PressStatus";
import PublishableCheckboxIcon from "./components/PublishableCheckboxIcon/PublishableCheckboxIcon";
import UnsuitableCheckboxIcon from "./components/UnsuitableCheckboxIcon/UnsuitableCheckboxIcon";
import * as S from "./PressListTableBody.style";
import { EMPTY_SHELL_VALUE } from "../../constants/pressListTable.constants";

const PressListTableBody = () => {
  const { isModal, closeModal, openModal } = useModal();
  const {
    isModal: isGuideModal,
    closeModal: closeGuideModal,
    openModal: openGuideModal,
  } = useModal();
  const {
    isModal: isCheckStatusModal,
    closeModal: closeCheckStatusModal,
    openModal: openCheckStatusModal,
  } = useModal();

  const [targetUnsuitablePressId, setTargetUnsuitablePressId] =
    useState<number>(0);

  const [targetPressId, setTargetPressId] = useState(0);
  const [currentStatus, setCurrentStatus] = useState<ProcessStatusCode | "">(
    "",
  );

  const { data: pressListData, refetch: updatePressList } =
    usePressListFilterQuery();

  const { mutateAsync: updatePublishable } =
    usePublishableArticleInfoMutation();

  const { mutateAsync: turnOffUnsuitable } = useUnsuitablePressCancelMutation();

  const { mutateAsync: createEntryData } = useCreateEntryDataMutation();

  const handleTargetPressId = (id: number) => {
    setTargetPressId(id);
  };

  const handleCurrentStatus = (status: ProcessStatusCode | "") => {
    setCurrentStatus(status);
  };

  const editorRoles = useEditorRoles();
  const authorityType = useAuthorityType();
  const isSuperAdmin = checkSuperAdmin(authorityType);

  const checkDisabledStatusButton = (status: ProcessStatusCode) => {
    if (status !== DRAFT_STATUS_CODE.aiEntryDone) return false;
    if (isSuperAdmin) return false;
    return true;
  };

  const createDAE = (id: number) => {
    if (!isSuperAdmin) return;
    createEntryData(id);
  };

  const handlePublishable = (
    id: number,
    publishable: boolean,
    status: ProcessStatusCode,
    aiDone: boolean,
  ) => {
    if (status === DRAFT_STATUS_CODE.unsuitable) {
      turnOffUnsuitable(id);
      updatePublishable({
        draftArticleId: id,
        isPublishable: publishable,
      });

      setTargetUnsuitablePressId(0);

      if (publishable === true && aiDone === false) {
        createDAE(id);
      }
      return;
    }
    updatePublishable({
      draftArticleId: id,
      isPublishable: publishable,
    });
    setTargetUnsuitablePressId(0);
    if (publishable === true && status === DRAFT_STATUS_CODE.notForAiEntry) {
      createDAE(id);
    }
  };

  const checkDisabledPublishableByRoles = () => {
    const publishEditorCodes = [
      "EDTK0204",
      "EDTK0304",
      "EDTK0404",
      "EDTK0504",
      "EDTK0604",
      "EDTK0704",
      "EDTK0804",
    ];

    const isPublishEditor =
      editorRoles?.filter((role) => publishEditorCodes.includes(role)).length >
      0;
    if (isSuperAdmin) return false;
    if (isPublishEditor) return false;
    return true;
  };

  const checkDisabledUnsuitablePressByRoles = () => {
    if (isSuperAdmin) return false;
    return true;
  };

  const checkDisabledUnsuitablePressByStatus = (status: ProcessStatusCode) => {
    if (status !== DRAFT_STATUS_CODE.published) return false;
    return true;
  };

  const openAccessDeniedModal = (pressId: number) => {
    handleTargetPressId(pressId);
    openGuideModal();
  };

  const openConcurrentAccessDeniedModal = (pressId: number) => {
    handleTargetPressId(pressId);
    openModal();
  };

  const clickUnsuitableCheckbox = (
    pressId: number,
    status: ProcessStatusCode,
  ) => {
    if (status === DRAFT_STATUS_CODE.unsuitable) {
      turnOffUnsuitable(pressId);
    }
    if (status !== DRAFT_STATUS_CODE.unsuitable) {
      setTargetUnsuitablePressId(pressId);
    }
  };

  return (
    <>
      <S.PressListTableBody>
        {pressListData?.data.map((data) => (
          <tr
            key={`row-${data.pressId}`}
            className={handleDraftError(data)[0] ? "error-draft-row" : ""}
          >
            <td>#{data.pressId}</td>
            <td>{data.pressDate}</td>
            <td>
              <PressListCompanyShell
                companyName={data.companyName}
                targetGroup={data.targetGroup}
              />
            </td>
            <td>
              <PublishableCheckboxIcon
                isChecked={data.publishable || false}
                onClick={() =>
                  handlePublishable(
                    data.pressId,
                    !data.publishable,
                    data.draftArticleStatus,
                    data.aiDone || false,
                  )
                }
                disabled={checkDisabledPublishableByRoles()}
              />
            </td>
            <td
              className={
                data.draftArticleStatusHighest === DRAFT_STATUS_CODE.unsuitable
                  ? "is-unsuitable"
                  : ""
              }
            >
              {data.articleRejectReason ? (
                <>
                  {data.articleRejectReason}
                  {data.duplArticleId && `\n(${data.duplArticleId})`}
                </>
              ) : (
                "-"
              )}
              <UnsuitableCheckboxIcon
                isChecked={data.draftArticleStatus === "S930" || false}
                onClick={() =>
                  clickUnsuitableCheckbox(data.pressId, data.draftArticleStatus)
                }
                disabled={
                  checkDisabledUnsuitablePressByRoles() === true ||
                  checkDisabledUnsuitablePressByStatus(
                    data.draftArticleStatusHighest,
                  ) === true
                }
                draftArticleId={data.pressId}
                isOpenModalUnsuitablePress={
                  targetUnsuitablePressId === data.pressId &&
                  !data.articleRejectReason
                }
                articleRejectReason={data.articleRejectReason}
                closeModalUnsuitablePress={() => setTargetUnsuitablePressId(0)}
                mcEditorName={data.mcEditorName}
                aiDone={data.aiDone || false}
              />
            </td>
            <PressListTableShellForPressTitle
              pressData={data}
              openCheckStatusModal={openCheckStatusModal}
              openAccessDeniedModal={() => openAccessDeniedModal(data.pressId)}
              openConcurrentAccessDeniedModal={() =>
                openConcurrentAccessDeniedModal(data.pressId)
              }
              handleTargetPressId={() => handleTargetPressId(data.pressId)}
              handleCurrentStatus={handleCurrentStatus}
            />
            <td>
              {CLASSIFICATION_GROUP[data.classificationAi] || EMPTY_SHELL_VALUE}
            </td>
            <S.ClassificationTableData status={data.draftArticleStatus}>
              {CLASSIFICATION_GROUP[data.classificationManual] ||
                EMPTY_SHELL_VALUE}
            </S.ClassificationTableData>
            <S.StatusTableData
              key={data.pressId}
              code={data.draftArticleStatusHighest}
            >
              <PressStatus
                data={data}
                isDraftError={handleDraftError(data)[0]}
                draftErrorMessage={handleDraftError(data)[1]}
                createDAE={createDAE}
                disabled={checkDisabledStatusButton(
                  data.draftArticleStatusHighest,
                )}
              />
            </S.StatusTableData>
            <td>
              {data.writeStartDt
                ? formatDate(data.writeStartDt, "YY.MM.DD HH:mm:ss")
                : EMPTY_SHELL_VALUE}
            </td>
            <td>
              {data.writeEndDt
                ? formatDate(data.writeEndDt, "YY.MM.DD HH:mm:ss")
                : EMPTY_SHELL_VALUE}
            </td>
            <td>{data.mcEditorName ?? EMPTY_SHELL_VALUE}</td>
            <td>{data.ftEditorName ?? EMPTY_SHELL_VALUE}</td>
            <td>{data.ftEditor2Name ?? EMPTY_SHELL_VALUE}</td>
            <td>{data.pbEditorName ?? EMPTY_SHELL_VALUE}</td>
          </tr>
        ))}
      </S.PressListTableBody>
      {isModal && (
        <PressListPageEntryGuide
          closeModal={closeModal}
          draftArticleId={targetPressId}
          updatePressList={updatePressList}
        />
      )}
      {isGuideModal && (
        <PressListGuideModal
          closeModal={closeGuideModal}
          draftArticleId={targetPressId}
        />
      )}
      {isCheckStatusModal && (
        <PressListCheckStatusGuide
          closeModal={closeCheckStatusModal}
          draftArticleId={targetPressId}
          updatePressList={updatePressList}
          pressListData={pressListData?.data || []}
          openAccessDeniedModal={openGuideModal}
          openConcurrentAccessDeniedModal={openModal}
          currentStatus={currentStatus}
        />
      )}
    </>
  );
};

export default PressListTableBody;
