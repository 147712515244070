import axios, { AxiosError } from "axios";

import { refreshApis } from "@services/refresh.service";
import tokenHelper from "@utils/tokenHelper";

import type { DefaultErrorBody } from "@api/types/defaultErrorBody.types";

export const reloadAccessToken = async (
  error: AxiosError<DefaultErrorBody>,
) => {
  const originalRequestConfig = error.config;
  const refreshToken = tokenHelper.getRefreshToken();

  const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
    await refreshApis.post({
      refreshToken,
    });

  tokenHelper.setAccessToken(newAccessToken);
  tokenHelper.setRefreshToken(newRefreshToken);

  if (originalRequestConfig) {
    originalRequestConfig.headers.set(
      "Authorization",
      `Bearer ${newAccessToken}`,
    );
    return axios.request(originalRequestConfig);
  } else {
    throw error;
  }
};
