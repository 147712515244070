import React from "react";

import { DealsSection } from "@/types/deals.types";
import { useScroll } from "@FillInTableContainer/components/FillInTable/hooks/useScroll";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { TableCreatorGroupTableTitle } from "@FillInTableContainer/shared";
import TableDragDivider from "@FillInTableContainer/shared/components/TableDragDivider/TableDragDivider";
import { useCanEditGroupTable } from "@FillInTableContainer/shared/hooks/useCanEditGroupTable";
import useCheckIsDisplayTableDragDivider from "@FillInTableContainer/shared/hooks/useCheckIsDisplayTableDragDivider";
import { useGetNumberOfTable } from "@FillInTableContainer/shared/hooks/useGetNumberOfTable";
import { useHandleTableCreatorTableDrag } from "@FillInTableContainer/shared/hooks/useHandleTableCreatorTableDrag";
import { TableCreatorSubGroupTableList } from "@FillInTableContainer/shared/types/tableCreator.types";
import { isRequiredGroupTable } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getGroupTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import DealTermsLicenseAgreementTable from "./components/DealTermsLicenseAgreementTable/DealTermsLicenseAgreementTable";
import * as S from "./DealTermsLicenseAgreementGroupTable.style";
import { useHandleDealTermsTableCreatorGroupTable } from "./hooks/useHandleDealTermsTableCreatorGroupTable";

interface Props {
  groupTableKey: DealsSection;
  tableList: TableCreatorSubGroupTableList;
  groupTableIndex: number;
}

const ARTICLE_SECTION = "Deals";

const DealTermsLicenseAgreementGroupTable = ({
  groupTableIndex,
  groupTableKey,
  tableList,
}: Props) => {
  const { register } = useScroll();
  const { tableCount } = useGetNumberOfTable();
  const articleSection = useArticleSection();

  const { handleAddTable } = useHandleDealTermsTableCreatorGroupTable({
    groupTableKey,
  });

  const { checkDisableEdit } = useCanEditGroupTable();

  const {
    handleDragEnd,
    handleDragOn,
    handleDragOver,
    handleDragStart,
    draggedIndexInfo,
    isDraggingTable,
  } = useHandleTableCreatorTableDrag(ARTICLE_SECTION);
  const { isLastTable, handleCheckDragDividerDisplay } =
    useCheckIsDisplayTableDragDivider();

  return (
    <S.GroupTableWrapper
      isDisableEdit={checkDisableEdit(groupTableKey)}
      {...register(groupTableKey)}
    >
      <TableCreatorGroupTableTitle
        title={getGroupTableTitle(articleSection, groupTableKey)}
        isRequired={isRequiredGroupTable(articleSection, groupTableKey)}
      />
      <S.TableCreatorTableListWrapper
        isDisableEdit={checkDisableEdit(groupTableKey)}
      >
        {tableList.map(({ tableKey, groupShellSectionList }, tableIndex) => {
          if (tableKey === "Licensor" || tableKey === "Deal Purpose")
            return (
              <DealTermsLicenseAgreementTable
                key={`table-${String(groupTableIndex)}-${String(tableIndex)}`}
                tableKey={tableKey}
                groupShellSectionList={groupShellSectionList}
                groupTableKey={groupTableKey}
                groupTableIndex={groupTableIndex}
                tableIndex={0}
                tableCount={tableCount(groupTableKey)}
                handleAddTable={handleAddTable}
                handleDragStart={handleDragStart}
                handleDragEnd={handleDragEnd}
                handleDragOn={handleDragOn}
                isDraggableTable={isDraggingTable}
              />
            );

          if (tableKey !== "Licensor" && tableKey !== "Additional Information")
            return newArrayToCountValue(tableCount(groupTableKey)).map(
              (_, multiTableIndex) => {
                return (
                  <S.DraggableTableWrapper
                    key={`table-${String(groupTableIndex)}-${String(
                      multiTableIndex,
                    )}`}
                    isTargetDraggingTable={
                      isDraggingTable && draggedIndexInfo[0] !== multiTableIndex
                    }
                    onDragOver={(e) => handleDragOver(e, multiTableIndex)}
                  >
                    <TableDragDivider
                      isDraggingEndPosition={handleCheckDragDividerDisplay({
                        moveDragIndex: draggedIndexInfo[1],
                        startDragIndex: draggedIndexInfo[0],
                        tableIndex: multiTableIndex,
                        currentGroupTableKey: groupTableKey,
                        isDraggingTable,
                      })}
                    />
                    <DealTermsLicenseAgreementTable
                      key={`table-${String(groupTableIndex)}-${String(
                        multiTableIndex + 1,
                      )}`}
                      tableKey={tableKey}
                      groupShellSectionList={groupShellSectionList}
                      groupTableKey={groupTableKey}
                      groupTableIndex={groupTableIndex}
                      tableIndex={multiTableIndex}
                      tableCount={tableCount(groupTableKey)}
                      handleAddTable={handleAddTable}
                      handleDragStart={handleDragStart}
                      handleDragEnd={handleDragEnd}
                      handleDragOn={handleDragOn}
                      isDraggableTable={isDraggingTable}
                    />
                    {isLastTable(multiTableIndex, groupTableKey) && (
                      <TableDragDivider
                        isDraggingEndPosition={handleCheckDragDividerDisplay({
                          moveDragIndex: draggedIndexInfo[1],
                          startDragIndex: draggedIndexInfo[0],
                          tableIndex: multiTableIndex,
                          currentGroupTableKey: groupTableKey,
                          isDraggingTable,
                          lastIndex: newArrayToCountValue(
                            tableCount(groupTableKey),
                          ).length,
                        })}
                      />
                    )}
                  </S.DraggableTableWrapper>
                );
              },
            );

          return (
            <DealTermsLicenseAgreementTable
              key={`table-${String(groupTableIndex)}-${String(tableIndex)}`}
              tableKey={tableKey}
              groupShellSectionList={groupShellSectionList}
              groupTableKey={groupTableKey}
              groupTableIndex={groupTableIndex}
              tableIndex={0}
              tableCount={tableCount(groupTableKey)}
              handleAddTable={handleAddTable}
              handleDragStart={handleDragStart}
              handleDragEnd={handleDragEnd}
              handleDragOn={handleDragOn}
              isDraggableTable={isDraggingTable}
            />
          );
        })}
      </S.TableCreatorTableListWrapper>
    </S.GroupTableWrapper>
  );
};

export default DealTermsLicenseAgreementGroupTable;
