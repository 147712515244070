import styled from "styled-components";

import {
  DropdownListWrapper,
  ListItem,
  SectionTitle,
} from "@FillInTableContainer/shared/styles/dropdown.style";

export const ListWrapper = styled(DropdownListWrapper)``;
export const ListSectionTitle = styled(SectionTitle)``;

export const DictionaryItem = styled(ListItem)`
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-6, 0.6rem);
`;

export const DataSetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const DataSetSubTitle = styled.div`
  color: var(--Color-Common-Text-Tertiary, #7c899c);
  font-size: 1.2rem;
  font-weight: 600;
`;

export const DataSetContent = styled.div`
  position: relative;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-weight: 600;
  padding-left: 1rem;

  &::before {
    position: absolute;
    left: 0rem;
    top: 0;
    display: block;
    content: "•";
    color: ${({ theme }) => theme.color.neural.gray10};
    font-size: 1.3rem;
  }
`;
