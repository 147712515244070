import TopFixInfo from "@/components/shared/ui/TopFixInfo/TopFixInfo";
import { useImageSearchQuery } from "@api/imageSearch/imageSearch";
import { useInsertImageFilter } from "@InsertImageContainer/shared/contexts/InsertImageFilterContext";
import { useHandleInsertImage } from "@InsertImageContainer/shared/hooks/useHandleInsertImage";

import AIImageContainer from "./components/AIImageContainer/AIImageContainer";
import { useHandleAiImageList } from "./hooks/useHandleAiImageList";
import * as S from "./InsertImageContents.style";
import AiImageFilter from "../AiImageFilter/AiImageFilter";
import ArticleTopHeaderInfo from "../ArticleTopHeaderInfo/ArticleTopHeaderInfo";
import ImageSource from "../ImageSource/ImageSource";
import InsertImageBottomButtonContainer from "../InsertImageBottomButtonContainer/InsertImageBottomButtonContainer";
import InsertImageBox from "../InsertImageBox/InsertImageBox";
import InsertImageFilter from "../InsertImageFilter/InsertImageFilter";
import InsertImageNoDataContainer from "../InsertImageNoDataContainer/InsertImageNoDataContainer";
import InsertImageNoImageDescriptionContainer from "../InsertImageNoImageDescriptionContainer/InsertImageNoImageDescriptionContainer";
import InsertImagePagination from "../InsertImagePagination/InsertImagePagination";
import InsertImageTabMenu from "../InsertImageTabMenu/InsertImageTabMenu";

const InsertImageContents = () => {
  const {
    targetImagePath,
    addHistoryImageList,
    handleSaveAsDraftInsertImage,
    undoImage,
    redoImage,
    disableRedo,
    disableUndo,
    handlePreviewImage,
    previewImage,
    uploadImageToStorage,
    selectedLabelList,
    changeSelectedLabelList,
    handleImageLabel,
    handleDeleteUploadFile,
    tabMenuType,
    handleTabMenuType,
    handleArticleImagePreview,
    handleImageInfoInput,
    canUploadDB,
    imageInfo,
    imageSourceRef,
    artistRef,
    checkedUploadedImage,
    setStatusToInsertImage,
  } = useHandleInsertImage();

  const saveDraftHandler = {
    undoIsDisabled: disableUndo,
    undoOnClickHandler: undoImage,
    saveIsDisabled: false,
    saveOnClickHandler: handleSaveAsDraftInsertImage,
    redoIsDisabled: disableRedo,
    redoOnClickHandler: redoImage,
  };

  const { insertImageFilter } = useInsertImageFilter();
  const { data: searchImageList } = useImageSearchQuery();
  const targetImageList = searchImageList.data.data;
  const searchKeyword =
    "search" in insertImageFilter.filter ? insertImageFilter.filter.search : "";

  const aiImageList = useHandleAiImageList();

  return (
    <S.InsertImageWrapper>
      <TopFixInfo />
      <S.ContentsWrapper>
        <S.InsertImageContainer>
          <ArticleTopHeaderInfo targetImagePath={targetImagePath} />
          <S.InsertImageContent>
            <S.InsertImageMenuWrapper>
              <InsertImageTabMenu
                handleTabMenuType={handleTabMenuType}
                tabMenuType={tabMenuType}
              />
              {tabMenuType === "select" && <InsertImageFilter />}
              {tabMenuType === "ai" && <AiImageFilter />}
            </S.InsertImageMenuWrapper>

            {tabMenuType === "select" && (
              <S.InsertImageContentsScrollWrapper>
                {targetImageList?.length === 0 &&
                  (searchKeyword ? (
                    <InsertImageNoDataContainer />
                  ) : (
                    <InsertImageNoImageDescriptionContainer type="select" />
                  ))}

                <S.ImageListContainer>
                  {targetImageList?.map((image, index) => (
                    <InsertImageBox
                      key={image.imageId}
                      imagePath={image.imagePath}
                      artistName={image.artistName}
                      keywordList={image.imageKeywordDataList}
                      platform={image.platform}
                      isSelectedImage={targetImagePath === image.imagePath}
                      index={index}
                      setStatusToInsertImage={setStatusToInsertImage}
                      addHistoryImageList={addHistoryImageList}
                    />
                  ))}
                </S.ImageListContainer>
              </S.InsertImageContentsScrollWrapper>
            )}

            {tabMenuType === "upload" && (
              <S.InsertImageContentsScrollWrapper>
                <ImageSource
                  previewImage={previewImage}
                  selectedLabelList={selectedLabelList}
                  changeSelectedLabelList={changeSelectedLabelList}
                  canUploadDB={canUploadDB}
                  imageInfo={imageInfo}
                  imageSourceRef={imageSourceRef}
                  artistRef={artistRef}
                  checkedUploadedImage={checkedUploadedImage}
                  handlePreviewImage={handlePreviewImage}
                  handleImageLabel={handleImageLabel}
                  handleDeleteUploadFile={handleDeleteUploadFile}
                  handleArticleImagePreview={handleArticleImagePreview}
                  handleImageInfoInput={handleImageInfoInput}
                  uploadImageToStorage={uploadImageToStorage}
                />
              </S.InsertImageContentsScrollWrapper>
            )}
            {tabMenuType === "ai" && (
              <S.InsertImageContentsScrollWrapper>
                {aiImageList.length === 0 && (
                  <InsertImageNoImageDescriptionContainer type="ai" />
                )}
                <AIImageContainer
                  targetImagePath={targetImagePath}
                  aiImageList={aiImageList}
                  setStatusToInsertImage={setStatusToInsertImage}
                  addHistoryImageList={addHistoryImageList}
                />
              </S.InsertImageContentsScrollWrapper>
            )}
          </S.InsertImageContent>
        </S.InsertImageContainer>
      </S.ContentsWrapper>
      {tabMenuType === "select" && targetImageList?.length > 0 && (
        <InsertImagePagination />
      )}
      <InsertImageBottomButtonContainer saveDraftHandler={saveDraftHandler} />
    </S.InsertImageWrapper>
  );
};

export default InsertImageContents;
