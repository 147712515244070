import { apiClient } from "@api/apiClient";

import type {
  DeletePageEntryResponse,
  GetPageEntryResponse,
  PostPageEntryResponse,
} from "@interface/pageEntry.interface";

const API_ENDPOINT = {
  get: "/des/page-entry/",
  post: "/des/page-entry/update",
  delete: "/des/page-entry/delete",
};

export const pageEntryApis = {
  get: async (url: string) => {
    const { data } = await apiClient.get<GetPageEntryResponse>(
      API_ENDPOINT.get,
      {
        params: {
          currentUrl: url,
        },
      },
    );
    return data;
  },
  post: async (url: string, draftArticleId: string) => {
    const { data } = await apiClient.post<PostPageEntryResponse>(
      API_ENDPOINT.post,
      {},
      {
        params: {
          currentUrl: url,
          draftArticleId,
        },
      },
    );
    return data;
  },
  delete: async (url: string) => {
    const { data } = await apiClient.delete<DeletePageEntryResponse>(
      API_ENDPOINT.delete,
      {
        params: {
          currentUrl: url,
        },
      },
    );
    return data;
  },
};
