import styled from "styled-components";

import { hideScrollbar } from "@styles/mixin";

export const InsertImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
  height: calc(100vh - 12.6rem);
`;

export const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: hidden;
`;

export const InsertImageContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  height: calc(100vh - 12.6rem);

  ${hideScrollbar}
`;

export const InsertImageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  padding-bottom: 20rem;
`;

export const InsertImageContentsScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const SearchBox = styled.div`
  justify-content: space-between;
  align-items: center;
  width: 18.2rem;

  label {
    display: flex;
    height: 2.4rem;
  }
`;

export const ImageListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  gap: 2.4rem 2.4rem;
  padding-bottom: 8rem;

  @media only screen and (min-width: 1px) and (max-width: 1520px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const InsertImageMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2.4rem;
  width: 100%;
  padding: 1.2rem 0;
`;
