import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;
  height: 100%;
`;

export const Description = styled.p`
  color: var(--Color-Common-Text-Tertiary, #7c899c);
  text-align: center;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

export const ResetButton = styled.button`
  display: flex;
  height: 4rem;
  min-height: var(--Size-Btn-Sm, 2.8rem);
  max-height: var(--Size-Btn-Lg, 4.8rem);
  padding: var(--Spacing-Btn-8, 0.8rem) var(--Spacing-24, 2.4rem);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-Btn-4, 0.4rem);
  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Interactive-Primary, #1baefe);
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
`;
