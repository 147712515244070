import styled, { css } from "styled-components";

import {
  DefaultDeleteTableButton,
  DefaultDeleteTableButtonWrapper,
  DefaultGroupShellWrapper,
  TableContentWrapper,
} from "@FillInTableContainer/shared/styles/table.style";

export const TableWrapper = styled(TableContentWrapper)``;

export const ScrollWrapper = styled.div<{
  isTableScroll: boolean;
}>`
  ${({ isTableScroll }) =>
    isTableScroll &&
    css`
      padding-top: 0rem;
      padding-bottom: 1.6rem;
      overflow-x: scroll;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        height: 0.4rem;
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.color.neural.gray05};
        border-radius: 0.2rem;
      }
      &::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.color.neural.gray02};
        border-radius: 0.2rem;
      }
    `}
`;

export const ScrollContentsWrapper = styled.div`
  width: fit-content;
`;

export const GroupShellWrapper = styled(DefaultGroupShellWrapper)``;

export const DeleteTableButtonWrapper = styled(
  DefaultDeleteTableButtonWrapper,
)``;

export const DeleteTableButton = styled(DefaultDeleteTableButton)``;
