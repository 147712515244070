"use client";

import styled from "styled-components";

export const TypeListItem = styled.div`
  position: relative;
  width: 100%;
  padding-left: 1.2rem;
  text-transform: capitalize;

  &::before {
    position: absolute;
    left: 0.4rem;
    top: 0;
    display: block;
    content: "•";
    color: ${({ theme }) => theme.color.neural.gray10};
    font-size: 1rem;
  }
`;
