import {
  ARTICLE_CONTENTS_COMPANY_PROFILE_PARAGRAPH_GROUP_KEY as PARAGRAPH_GROUP_KEY,
  ARTICLE_CONTENTS_COMPANY_PROFILE_SHELL_KEYS as SHELL_KEYS,
} from "@frontend/common";
import dayjs from "dayjs";

import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import {
  REF_CODE_VENTURE_CAPITAL_COMPANY_NAME,
  REF_CODE_VENTURE_CAPITAL_COMPANY_DESCRIPTION,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { VentureCapitalCompanyProfileContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/ventureCapitalCompanyProfileContent.types";

import { createContentFromHTML } from "../../../utils/getValueFromRefMap";

export const useCreateVentureCapitalCompanyProfileContent = () => {
  const refMap = useArticleContentRefMap();

  return () => {
    const content: VentureCapitalCompanyProfileContent = {
      text: {
        [PARAGRAPH_GROUP_KEY]: {
          [SHELL_KEYS.companyName]: createContentFromHTML(
            refMap,
            REF_CODE_VENTURE_CAPITAL_COMPANY_NAME,
          ),
          [SHELL_KEYS.companyDescription]: createContentFromHTML(
            refMap,
            REF_CODE_VENTURE_CAPITAL_COMPANY_DESCRIPTION,
          ),
        },
      },
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
