import { useCallback } from "react";

import { SHELL_PATH_TO_ADD_SHELL } from "@FillInTableContainer/shared/constants/tableCreatorShell.constants";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useCheckDisabledDelete } from "@FillInTableContainer/shared/hooks/useCheckDisabledDelete";
import { useUpdateEntryData } from "@hooks/useUpdateArticleData";
import { createRandomString } from "@organisms/TableCreatorComment/components/CommentModalContainer/components/CommentModalMessageComposer/utils/createRandomString";
import { useEntryDataState } from "@stores/entryData";
import { useInitEntryDataValue } from "@stores/initEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import type { PositionInfoProps } from "../ClinicalTrialsTableCreatorShell";
import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorShellReturn {
  handleClickToDeleteEndpointsTableShell: () => void;
  handleAddEndpointsTableShell: (groupShellInfoParams: {
    tableIndexParameter: number;
    groupShellIndexParameter: number;
    shellIndexParameter: number;
    shellKeyParameter: string;
  }) => void;
  handleKeyDownToDeleteEndpointsTableShell: (event: KeyboardEvent) => void;
  handleClickToDeleteEndpointsTableColumn: () => void;
  handleKeyDownToDeleteEndpointsTableColumn: (event: KeyboardEvent) => void;
}

export const useHandleClinicalTrialsEndpointsTableShell = (
  positionPropsInfo: PositionInfoProps,
): UseHandleTableCreatorShellReturn => {
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { checkDisabledDeleteShell } = useCheckDisabledDelete();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const initEntryData = useInitEntryDataValue(getPressIdFromURL());
  const updateEntryData = useUpdateEntryData();

  const handleAddEndpointsTableShell = (groupShellInfoParams: {
    tableIndexParameter: number;
    groupShellIndexParameter: number;
    shellIndexParameter: number;
    shellKeyParameter: string;
  }) => {
    const {
      tableIndexParameter,
      groupShellIndexParameter,
      shellIndexParameter,
      shellKeyParameter,
    } = groupShellInfoParams;

    const targetShell = SHELL_PATH_TO_ADD_SHELL[shellKeyParameter](
      entryData,
      tableIndexParameter,
      groupShellIndexParameter,
    );

    if (targetShell === undefined) return;

    const defaultPathEndpointGroupInformation = (
      data: ArticleEntryData,
      tableInfoIndex: number,
      groupShellInfoIndex: number,
      shellInfoIndex: number,
    ) =>
      data["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[tableInfoIndex][
        "Endpoints Table Information"
      ]?.[groupShellInfoIndex]["Endpoint Group Information"]?.[shellInfoIndex];

    const createEndpointGroupInformationList = (
      tableInfoIndex: number,
      groupShellInfoIndex: number,
    ) => {
      const shellLength =
        entryData["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[
          tableInfoIndex
        ]["Endpoints Table Information"]?.[groupShellInfoIndex][
          "Endpoint Group Information"
        ].length || 0;

      const newEmptyArray = newArrayToCountValue(shellLength + 1);
      return newEmptyArray;
    };

    const changeShellData: Record<string, ArticleEntryData> = {
      "Endpoint Group Information": {
        "Clinical Trials": {
          ...entryData["Clinical Trials"],
          "Clinical Endpoints (Table)": entryData["Clinical Trials"]?.[
            "Clinical Endpoints (Table)"
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === tableIndexParameter) {
              return {
                ...tableInfo,
                "Endpoints Table Information": tableInfo[
                  "Endpoints Table Information"
                ].map((groupShellInfo, groupShellInfoIndex) => {
                  return {
                    ...groupShellInfo,
                    "Endpoint Group Information":
                      createEndpointGroupInformationList(
                        tableInfoIndex,
                        groupShellInfoIndex,
                      ).map((shellInfo, shellInfoIndex) => {
                        if (shellInfoIndex === shellIndexParameter) {
                          const createAttributionId = () =>
                            createRandomString(8);
                          return {
                            "Group Name": {
                              value: [],
                              options: defaultPathEndpointGroupInformation(
                                initEntryData,
                                0,
                                0,
                                0,
                              )?.["Group Name"]?.options,
                              attributionId: createAttributionId(),
                            },
                            "Group Data": {
                              value: [],
                              options: defaultPathEndpointGroupInformation(
                                initEntryData,
                                0,
                                0,
                                0,
                              )?.["Group Data"]?.options,
                              attributionId: createAttributionId(),
                            },
                            "P-Value": {
                              value: [],
                              options: defaultPathEndpointGroupInformation(
                                initEntryData,
                                0,
                                0,
                                0,
                              )?.["P-Value"]?.options,
                              attributionId: createAttributionId(),
                            },
                          };
                        }
                        if (shellInfoIndex < shellIndexParameter) {
                          return defaultPathEndpointGroupInformation(
                            entryData,
                            tableInfoIndex,
                            groupShellInfoIndex,
                            shellInfoIndex,
                          );
                        }
                        return defaultPathEndpointGroupInformation(
                          entryData,
                          tableInfoIndex,
                          groupShellInfoIndex,
                          shellInfoIndex - 1,
                        );
                      }),
                  };
                }),
              };
            }
            return tableInfo;
          }),
        },
      } as ArticleEntryData,
    };
    setEntryData(changeShellData[shellKeyParameter]);
    updateEntryData(changeShellData[shellKeyParameter]);
    changeCurrentHistory({
      entryData: changeShellData[shellKeyParameter],
      changedDataInfo: {
        target: "shell",
        action: "add",
        position: {
          ...positionPropsInfo,
          shellIndex: shellIndexParameter,
          valueIndex: null,
          value: null,
        },
      },
    });
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleDeleteShell = useCallback(() => {
    if (checkDisabledDeleteShell()) return;

    if (info.groupTableKey === "Clinical Endpoints (Table)") {
      const deleteEndpointTableShell = (): ArticleEntryData => {
        return {
          ...entryData,
          "Clinical Trials": {
            ...entryData["Clinical Trials"],
            "Clinical Endpoints (Table)": entryData["Clinical Trials"]?.[
              "Clinical Endpoints (Table)"
            ]?.map((tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  "Endpoints Table Information": tableInfo?.[
                    "Endpoints Table Information"
                  ]?.map((groupShellInfo) => ({
                    ...groupShellInfo,
                    "Endpoint Group Information": groupShellInfo?.[
                      "Endpoint Group Information"
                    ]?.filter(
                      (_, shellInfoIndex) => shellInfoIndex !== info.shellIndex,
                    ),
                  })),
                };
              }
              return tableInfo;
            }),
          },
        };
      };

      setEntryData(deleteEndpointTableShell());
      changeCurrentHistory({
        entryData: deleteEndpointTableShell(),
        changedDataInfo: {
          target: "shell",
          action: "delete",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    checkDisabledDeleteShell,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  const handleClearShell = useCallback(() => {
    const clearEndpointsTableShell = (): ArticleEntryData => {
      return {
        ...entryData,
        "Clinical Trials": {
          ...entryData["Clinical Trials"],
          "Clinical Endpoints (Table)": entryData["Clinical Trials"]?.[
            "Clinical Endpoints (Table)"
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              if (info.shellKey === "Endpoints Table Notes") {
                return {
                  ...tableInfo,
                  "Endpoints Table Notes": {
                    ...tableInfo["Endpoints Table Notes"],
                    value: [],
                  },
                };
              }
              return {
                ...tableInfo,
                "Endpoints Table Information": tableInfo[
                  "Endpoints Table Information"
                ].map((groupShellInfo, groupShellInfoIndex) => {
                  if (info.shellKey === "Group Name") {
                    return {
                      ...groupShellInfo,
                      "Endpoint Group Information": groupShellInfo[
                        "Endpoint Group Information"
                      ].map((shellInfo, shellInfoIndex) => {
                        if (shellInfoIndex === info.shellIndex) {
                          return {
                            ...shellInfo,
                            "Group Name": {
                              ...shellInfo["Group Name"],
                              value: [],
                            },
                          };
                        }
                        return shellInfo;
                      }),
                    };
                  }
                  if (groupShellInfoIndex === info.groupShellIndex) {
                    if (
                      info.shellKey === "Endpoint Classification" ||
                      info.shellKey ===
                        "Endpoint Name including Measurement Timing"
                    ) {
                      return {
                        ...groupShellInfo,
                        [info.shellKey]: {
                          ...groupShellInfo[info.shellKey],
                          value: [],
                        },
                      };
                    }
                    return {
                      ...groupShellInfo,
                      "Endpoint Group Information": groupShellInfo[
                        "Endpoint Group Information"
                      ].map((shellInfo, shellInfoIndex) => {
                        if (shellInfoIndex === info.shellIndex) {
                          return {
                            ...shellInfo,
                            "Group Data": {
                              ...shellInfo["Group Data"],
                              value: [],
                            },
                            "P-Value": {
                              ...shellInfo["P-Value"],
                              value: [],
                            },
                          };
                        }
                        return shellInfo;
                      }),
                    };
                  }
                  return groupShellInfo;
                }),
              };
            }
            return tableInfo;
          }),
        },
      };
    };

    if (info.groupTableKey === "Clinical Endpoints (Table)") {
      setEntryData(clearEndpointsTableShell());
      changeCurrentHistory({
        entryData: clearEndpointsTableShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  const handleClickToDeleteEndpointsTableShell = () => {
    if (focusStatus.type === "clear") {
      handleClearShell();
    }
    if (focusStatus.type === "delete") {
      handleDeleteShell();
    }
  };

  const handleKeyDownToDeleteEndpointsTableShell = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearShell();
      }
      if (focusStatus.type === "delete" && event.key === "Delete") {
        handleDeleteShell();
      }
    },
    [focusStatus.type, handleClearShell, handleDeleteShell],
  );

  // Endpoints Column Clear / Delete 이벤트

  const handleDeleteColumn = useCallback(() => {
    if (info.groupTableKey === "Clinical Endpoints (Table)") {
      const deleteEndpointTableColumn = (): ArticleEntryData => {
        return {
          ...entryData,
          "Clinical Trials": {
            ...entryData["Clinical Trials"],
            "Clinical Endpoints (Table)": entryData["Clinical Trials"]?.[
              "Clinical Endpoints (Table)"
            ]?.map((tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  "Endpoints Table Information": tableInfo?.[
                    "Endpoints Table Information"
                  ]?.map((groupShellInfo) => {
                    if (info.shellKey === "Group Name") {
                      return {
                        ...groupShellInfo,
                        "Endpoint Group Information": groupShellInfo[
                          "Endpoint Group Information"
                        ].filter(
                          (_, shellInfoIndex) =>
                            shellInfoIndex !== info.shellIndex,
                        ),
                      };
                    }
                    return groupShellInfo;
                  }),
                };
              }
              return tableInfo;
            }),
          },
        };
      };

      setEntryData(deleteEndpointTableColumn());
      changeCurrentHistory({
        entryData: deleteEndpointTableColumn(),
        changedDataInfo: {
          target: "column",
          action: "delete",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  const handleClearColumn = useCallback(() => {
    const clearEndpointsTableColumn = (): ArticleEntryData => {
      return {
        ...entryData,
        "Clinical Trials": {
          ...entryData["Clinical Trials"],
          "Clinical Endpoints (Table)": entryData["Clinical Trials"]?.[
            "Clinical Endpoints (Table)"
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                "Endpoints Table Information": tableInfo[
                  "Endpoints Table Information"
                ].map((groupShellInfo) => {
                  if (info.shellKey === "Group Name") {
                    return {
                      ...groupShellInfo,
                      "Endpoint Group Information": groupShellInfo[
                        "Endpoint Group Information"
                      ].map((shellInfo, shellInfoIndex) => {
                        if (shellInfoIndex === info.shellIndex) {
                          return {
                            ...shellInfo,
                            "Group Name": {
                              ...shellInfo["Group Name"],
                              value: [],
                            },
                            "Group Data": {
                              ...shellInfo["Group Name"],
                              value: [],
                            },
                            "P-Value": {
                              ...shellInfo["P-Value"],
                              value: [],
                            },
                          };
                        }
                        return shellInfo;
                      }),
                    };
                  }
                  if (info.shellKey === "Endpoint Classification") {
                    return {
                      ...groupShellInfo,
                      "Endpoint Classification": {
                        ...groupShellInfo["Endpoint Classification"],
                        value: [],
                      },
                    };
                  }
                  if (
                    info.shellKey ===
                    "Endpoint Name including Measurement Timing"
                  ) {
                    return {
                      ...groupShellInfo,
                      "Endpoint Name including Measurement Timing": {
                        ...groupShellInfo[
                          "Endpoint Name including Measurement Timing"
                        ],
                        value: [],
                      },
                    };
                  }
                  return groupShellInfo;
                }),
              };
            }
            return tableInfo;
          }),
        },
      };
    };

    if (info.groupTableKey === "Clinical Endpoints (Table)") {
      setEntryData(clearEndpointsTableColumn());
      changeCurrentHistory({
        entryData: clearEndpointsTableColumn(),
        changedDataInfo: {
          target: "column",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    const countGroupShell =
      entryData["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[
        info.tableIndex
      ]?.["Endpoints Table Information"]?.at(0)?.["Endpoint Group Information"]
        ?.length || 0;

    if (info.shellKey === "Group Name" && countGroupShell > 1) {
      changeFocusStatus({ type: "delete", container: "column" });
      return;
    }

    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  const handleClickToDeleteEndpointsTableColumn = () => {
    if (focusStatus.type === "clear") {
      handleClearColumn();
    }
    if (focusStatus.type === "delete") {
      handleDeleteColumn();
    }
  };

  const handleKeyDownToDeleteEndpointsTableColumn = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearColumn();
      }
      if (focusStatus.type === "delete" && event.key === "Delete") {
        handleDeleteColumn();
      }
    },
    [focusStatus.type, handleClearColumn, handleDeleteColumn],
  );

  return {
    handleClickToDeleteEndpointsTableShell,
    handleKeyDownToDeleteEndpointsTableShell,
    handleAddEndpointsTableShell,
    handleClickToDeleteEndpointsTableColumn,
    handleKeyDownToDeleteEndpointsTableColumn,
  };
};
