export const formatPrimaryEndpointTag = (tagText: string) => {
  if (!tagText) return "";

  const regex = /\(([^)]+)\)/;
  const match = regex.exec(tagText);
  const tagTextInParentheses = match ? match[0] : "";

  const capitalizedText = tagText
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return capitalizedText.replace(/\(([^)]+)\)/, `${tagTextInParentheses}`);
};
