import { usePageEntryEditorWritingQuery } from "@/queries/hooks/usePageEntry";
import { QUERY_KEYS } from "@api/queryKeys";

const REFETCH_INTERVAL = 4000;

interface Args {
  url: string;
  ftEditorName: string | null;
}

/**
 * Fill In Table 페이지를 대상으로 lock을 가져왔는지 여부를 반환합니다.
 * 가져왔다면 true, 가져오지 못했다면 false, 접근할때 lock이 필요 없는 상황이라면 null을 반환합니다.
 * @param url
 * @returns
 */
export const useHasFillInTableLock = ({ url, ftEditorName }: Args) => {
  const { data: hasLock } = usePageEntryEditorWritingQuery(url, {
    queryKey: [QUERY_KEYS.pageEntryEditorWriting, url],
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: true,
    enabled: !ftEditorName,
  });

  if (ftEditorName && hasLock === undefined) {
    return null;
  }
  if (hasLock === undefined) {
    throw new Error("hasLock is undefined");
  }
  return hasLock;
};
