"use client";

import styled, { css } from "styled-components";

export const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const ShellTitle = styled.div<{ $isNewline: boolean }>`
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-4, 0.4rem);

  color: var(--Color-Common-Text-Tertiary, #7c899c);
  font-family: var(--font-pt-serif);
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 130%;

  ${({ $isNewline }) =>
    $isNewline &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const Company = styled.div`
  display: flex;
  padding: var(--Spacing-0, 0rem) var(--Spacing-4, 0.4rem);
  gap: var(--Spacing-4, 0.4rem);
`;

export const CompanyText = styled.div<{
  $isNewline: boolean;
  $isVentureCapital: boolean;
}>`
  padding: var(--Spacing-2, 0.2rem);
  color: var(--Color-Common-Text-Primary, #2a2f37);

  font-family: var(--font-pt-serif);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 130%;

  ${({ $isNewline, $isVentureCapital }) =>
    $isNewline &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${$isVentureCapital ? 2 : 3};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const HideCompanyCount = styled.div`
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);

  color: var(--Color-Common-Text-Primary, #2a2f37);

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 130%;
`;
