import React from "react";

import { IconAlertTriangle, IconLoader } from "@frontend/common";

import Modal from "@atoms/Modal/Modal";
import useModal from "@hooks/useModal";
import IconInsertImagePlus from "@images/insertImage/icon-plus-insert-image.svg";
import IconSelectedImageCheck from "@images/insertImage/icon-selected-image-check-white.svg";
import IconWide from "@images/insertImage/icon-title-wide.svg";
import {
  AiImageStatus,
  SelectImageType,
} from "@InsertImageContainer/shared/types/insertImage.types";
import ImageView from "@organisms/ImageView/ImageView";

import * as S from "./AIImageBox.style";

import type { ImagePlatform } from "@interface/image.interface";

interface Props {
  imagePath: string;
  platform: ImagePlatform;
  artistName: string;
  isSelectedImage: boolean;
  index: number;
  addHistoryImageList: (type: SelectImageType, index?: number) => void;
  setStatusToInsertImage: () => void;
  imageStatus: AiImageStatus;
}

const AIImageBox = ({
  imagePath,
  artistName,
  platform,
  isSelectedImage,
  index,
  addHistoryImageList,
  setStatusToInsertImage,
  imageStatus,
}: Props) => {
  const { isModal, openModal, closeModal } = useModal();

  const handleClickImage = () => {
    addHistoryImageList("ai", index);
    setStatusToInsertImage();
  };

  const handleZoomEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    openModal();
  };

  if (imageStatus === "GENERATING" || imageStatus === "PENDING")
    return (
      <S.InsertImageBoxWrapper>
        <S.Image imageStatus="GENERATING">
          <S.NoneImage>
            <S.SelectedImageText>
              <IconLoader />
              Generating image...
            </S.SelectedImageText>
          </S.NoneImage>
        </S.Image>
        <S.InfoContainer>
          <S.InfoItem>
            <S.InfoTitle>Source</S.InfoTitle>
            <S.InfoValue>{platform}</S.InfoValue>
          </S.InfoItem>
        </S.InfoContainer>
      </S.InsertImageBoxWrapper>
    );
  if (imageStatus === "FAIL")
    return (
      <S.InsertImageBoxWrapper>
        <S.Image imageStatus="FAIL">
          <S.NoneImage>
            <S.SelectedImageText>
              <IconAlertTriangle />
              Failed to generate image
            </S.SelectedImageText>
          </S.NoneImage>
        </S.Image>
        <S.InfoContainer>
          <S.InfoItem>
            <S.InfoTitle>Source</S.InfoTitle>
            <S.InfoValue>{platform}</S.InfoValue>
          </S.InfoItem>
        </S.InfoContainer>
      </S.InsertImageBoxWrapper>
    );

  return (
    <S.InsertImageBoxWrapper>
      <S.Image
        isSelectedImage={isSelectedImage}
        onClick={handleClickImage}
        imageStatus="SUCCESS"
      >
        <S.ImageBackground
          isSelectedImage={isSelectedImage}
          className="image-background"
        >
          {isSelectedImage ? (
            <S.SelectedImageText>
              <img src={IconSelectedImageCheck} alt="button " />
              Selected
            </S.SelectedImageText>
          ) : (
            <S.SelectedImageText>
              <img src={IconInsertImagePlus} alt="button " />
              Click to Insert Image
            </S.SelectedImageText>
          )}
          <S.ZoomInButton
            type="button"
            className="button-zoom-in"
            onClick={handleZoomEvent}
          >
            <img src={IconWide} alt="button " />
          </S.ZoomInButton>
        </S.ImageBackground>

        <img src={imagePath} alt={artistName} />
      </S.Image>

      <S.InfoContainer>
        <S.InfoItem>
          <S.InfoTitle>Source</S.InfoTitle>
          <S.InfoValue>{platform}</S.InfoValue>
        </S.InfoItem>
      </S.InfoContainer>

      {isModal && (
        <Modal closeModal={closeModal}>
          <ImageView selectImage={imagePath} closeModal={closeModal} />
        </Modal>
      )}
    </S.InsertImageBoxWrapper>
  );
};

export default AIImageBox;
