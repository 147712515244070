import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconMenu = ({ width = 24, height = 24, color = "black" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.875 7C3.875 6.65482 4.15482 6.375 4.5 6.375H19.5C19.8452 6.375 20.125 6.65482 20.125 7C20.125 7.34518 19.8452 7.625 19.5 7.625H4.5C4.15482 7.625 3.875 7.34518 3.875 7ZM3.875 12C3.875 11.6548 4.15482 11.375 4.5 11.375H19.5C19.8452 11.375 20.125 11.6548 20.125 12C20.125 12.3452 19.8452 12.625 19.5 12.625H4.5C4.15482 12.625 3.875 12.3452 3.875 12ZM4.5 16.375C4.15482 16.375 3.875 16.6548 3.875 17C3.875 17.3452 4.15482 17.625 4.5 17.625H19.5C19.8452 17.625 20.125 17.3452 20.125 17C20.125 16.6548 19.8452 16.375 19.5 16.375H4.5Z"
        fill={color}
      />
    </svg>
  );
};

IconMenu.displayName = "Menu";

export default IconMenu;
