import { DrugProfileContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/drugProfileContent.types";

import { convertPhaseToDevelopmentStage } from "./convertPhaseToDevelopmentStage";

export const checkDrugProfileValue = (
  drugProfile: DrugProfileContent,
): boolean => {
  const drugProfileList = drugProfile.text["Drug profile"] ?? [];
  const isDrug =
    drugProfileList.filter(
      (item) =>
        (
          item?.["Brand Name"].value.at(0)?.text ||
          item?.["INN Name"].value.at(0)?.text ||
          item?.["Code Name"].value.at(0)?.text ||
          ""
        ).length > 0,
    ).length > 0;
  const isCompany =
    drugProfileList.filter(
      (item) =>
        (
          item?.["Originator/Developer"]?.value.map?.(
            (value) => value.text ?? "",
          ) ?? []
        ).length > 0,
    ).length > 0;
  const isModality =
    drugProfileList.filter(
      (item) => (item?.Modality.value.at(0)?.text || "").length > 0,
    ).length > 0;
  const isTarget =
    drugProfileList.filter(
      (item) =>
        (item?.Target.value.map((value) => value.text ?? "")?.join(", ") || "")
          .length > 0,
    ).length > 0;
  const isMOA =
    drugProfileList.filter(
      (item) =>
        (item?.MOA.value.map((value) => value.text ?? "")?.join(", ") || "")
          .length > 0,
    ).length > 0;
  const isROA =
    drugProfileList.filter(
      (item) => (item?.ROA.value.at(0)?.text || "").length > 0,
    ).length > 0;
  const isIndication =
    drugProfileList.filter(
      (item) =>
        (
          item?.["Target Indication"].value.map?.(
            (value) => value.text ?? "",
          ) ?? []
        ).length > 0,
    ).length > 0;
  const isStage =
    drugProfileList.filter(
      (item) =>
        (
          item?.["Development Stage"]?.value.at(0)?.text ||
          convertPhaseToDevelopmentStage(item?.Phase?.value.at(0)?.text || "")
        ).length > 0,
    ).length > 0;

  if (
    !isDrug &&
    !isCompany &&
    !isModality &&
    !isTarget &&
    !isMOA &&
    !isROA &&
    !isIndication &&
    !isStage
  ) {
    return false;
  }
  return true;
};

export const checkDrugProfileValueByRow = (
  drugProfile: DrugProfileContent,
  rowIndex: number,
): boolean => {
  const drugProfileList = drugProfile.text["Drug profile"] ?? [];
  const targetDrugProfile = drugProfileList?.[rowIndex];

  const isDrug =
    (
      targetDrugProfile?.["Brand Name"].value.at(0)?.text ||
      targetDrugProfile?.["INN Name"].value.at(0)?.text ||
      targetDrugProfile?.["Code Name"].value.at(0)?.text ||
      ""
    ).length > 0;
  const isCompany =
    (
      targetDrugProfile?.["Originator/Developer"]?.value.map?.(
        (item) => item.text ?? "",
      ) ?? []
    ).length > 0;
  const isModality =
    (targetDrugProfile?.Modality.value.at(0)?.text || "").length > 0;
  const isTarget =
    (
      targetDrugProfile?.Target.value
        .map((item) => item.text ?? "")
        ?.join(", ") || ""
    ).length > 0;
  const isMOA =
    (
      targetDrugProfile?.MOA.value.map((item) => item.text ?? "")?.join(", ") ||
      ""
    ).length > 0;
  const isROA = (targetDrugProfile?.ROA.value.at(0)?.text || "").length > 0;
  const isIndication =
    (
      targetDrugProfile?.["Target Indication"].value.map?.(
        (item) => item.text ?? "",
      ) ?? []
    ).length > 0;
  const isStage =
    (
      targetDrugProfile?.["Development Stage"]?.value.at(0)?.text ||
      convertPhaseToDevelopmentStage(
        targetDrugProfile?.Phase?.value.at(0)?.text || "",
      )
    ).length > 0;

  if (
    !isDrug &&
    !isCompany &&
    !isModality &&
    !isTarget &&
    !isMOA &&
    !isROA &&
    !isIndication &&
    !isStage
  ) {
    return false;
  }
  return true;
};
