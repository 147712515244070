import { useCallback } from "react";

import {
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL,
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_LICENSOR_TABLE,
  ENTRY_DATA_LICENSOR_TABLE_KEYS,
  ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS,
  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS,
  ENTRY_DATA_RIGHTS_SCOPE_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import { KEY_DEALS_TABLE } from "@FillInTableContainer/shared/constants/dealsKey.constants";
import {
  DEFAULT_FOCUS_STATUS,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useCheckDisabledDelete } from "@FillInTableContainer/shared/hooks/useCheckDisabledDelete";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorTableReturn {
  handleClickLicenseeTableDeleteButton: () => void;
  handleKeyDownToDeleteLicenseeTable: (event: KeyboardEvent) => void;
}

export interface TableInfoProps {
  groupTableKey: DealsSection;
  tableKey: string | null;
  groupTableIndex: number;
  tableIndex: number;
  tableCount: number;
}

export const useHandleDealTermsLicenseeTable = (
  tablePropsInfo: TableInfoProps,
): UseHandleTableCreatorTableReturn => {
  const { groupTableKey, tableKey, groupTableIndex, tableIndex } =
    tablePropsInfo;

  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const articleSection = "Deals";

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { checkDisabledDeleteTable } = useCheckDisabledDelete();

  const licenseeTableLength =
    entryData[articleSection]?.[ENTRY_DATA_LICENSEE_TABLE]?.length || 0;

  const handleClearTable = useCallback(() => {
    const targetLicenseeData = entryData.Deals?.[ENTRY_DATA_LICENSEE_TABLE];

    const clearLicensorTable = (): ArticleEntryData => {
      const licensorTableData = entryData.Deals?.[ENTRY_DATA_LICENSOR_TABLE];
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData.Deals,
          [ENTRY_DATA_LICENSOR_TABLE]: {
            ...licensorTableData,
            [ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorName]: {
              ...licensorTableData?.[
                ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorName
              ],
              value: [],
            },
            [ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorResponsibilityRights]: {
              ...licensorTableData?.[
                ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorResponsibilityRights
              ],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    const clearLicenseeTable = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData.Deals,
          [ENTRY_DATA_LICENSEE_TABLE]: targetLicenseeData?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  [ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName]: {
                    ...tableInfo?.[ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName],
                    value: [],
                  },
                  [ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope]: tableInfo?.[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope
                  ]?.map((groupShellInfo) => {
                    return {
                      ...groupShellInfo,
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug]: {
                        ...groupShellInfo?.[ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug],
                        value: [],
                      },
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.exclusivity]: {
                        ...groupShellInfo?.[
                          ENTRY_DATA_RIGHTS_SCOPE_KEYS.exclusivity
                        ],
                        value: [],
                      },
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication]: {
                        ...groupShellInfo?.[
                          ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication
                        ],
                        value: [],
                      },
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory]: {
                        ...groupShellInfo?.[
                          ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory
                        ],
                        value: [],
                      },
                    };
                  }),
                  [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                    {
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherFinancialTermsScLa]:
                        {
                          ...tableInfo?.[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS
                              .strategicCollaborationLicenseAgreementTerms
                          ][
                            ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                              .otherFinancialTermsScLa
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.licenseeResponsibilityAndRightsScLa]:
                        {
                          ...tableInfo?.[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS
                              .strategicCollaborationLicenseAgreementTerms
                          ]?.[
                            ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                              .licenseeResponsibilityAndRightsScLa
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.royalty]:
                        {
                          ...tableInfo?.[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS
                              .strategicCollaborationLicenseAgreementTerms
                          ]?.[
                            ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                              .royalty
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.totalPaymentScLa]:
                        {
                          ...tableInfo?.[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS
                              .strategicCollaborationLicenseAgreementTerms
                          ]?.[
                            ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                              .totalPaymentScLa
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.disclosedPaymentsSumScLa]:
                        {
                          ...tableInfo?.[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS
                              .strategicCollaborationLicenseAgreementTerms
                          ]?.[
                            ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                              .disclosedPaymentsSumScLa
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.upfrontPayment]:
                        {
                          ...tableInfo?.[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS
                              .strategicCollaborationLicenseAgreementTerms
                          ]?.[
                            ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                              .upfrontPayment
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.milestonePaymentAndCondition]:
                        tableInfo[
                          ENTRY_DATA_LICENSEE_TABLE_KEYS
                            .strategicCollaborationLicenseAgreementTerms
                        ][
                          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                            .milestonePaymentAndCondition
                        ].map((subGroupShellInfo) => {
                          return {
                            ...subGroupShellInfo,
                            [ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS.milestoneConditionTitle]:
                              {
                                ...subGroupShellInfo?.[
                                  ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS
                                    .milestoneConditionTitle
                                ],
                                value: [],
                              },
                            [ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS.milestonePayment]:
                              {
                                ...subGroupShellInfo?.[
                                  ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS
                                    .milestonePayment
                                ],
                                value: [],
                              },
                          };
                        }),
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa]:
                        tableInfo[
                          ENTRY_DATA_LICENSEE_TABLE_KEYS
                            .strategicCollaborationLicenseAgreementTerms
                        ][
                          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                            .otherPaymentAndDetailScLa
                        ].map((subGroupShellInfo) => {
                          return {
                            ...subGroupShellInfo,
                            [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentDetailScLa]:
                              {
                                ...subGroupShellInfo?.[
                                  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                                    .otherPaymentDetailScLa
                                ],
                                value: [],
                              },
                            [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentScLa]:
                              {
                                ...subGroupShellInfo?.[
                                  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                                    .otherPaymentScLa
                                ],
                                value: [],
                              },
                          };
                        }),
                    },
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    const clearAdditionalInformationTable = (): ArticleEntryData => {
      const additionalInformationTableData =
        entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL
        ];
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL]: {
            ...additionalInformationTableData,
            [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.additionalInformation]:
              {
                ...additionalInformationTableData?.[
                  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS
                    .additionalInformation
                ],
                value: [],
              },
          },
        },
      } as ArticleEntryData;
    };

    const clearDealPurposeTable = (): ArticleEntryData => {
      const additionalInformationTableData =
        entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL
        ];
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL]: {
            ...additionalInformationTableData,
            [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.purposeOfTheDeal]: {
              ...additionalInformationTableData?.[
                ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.purposeOfTheDeal
              ],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    if (info.tableKey === "Deal Purpose") {
      setEntryData(clearDealPurposeTable());
      changeCurrentHistory({
        entryData: clearDealPurposeTable(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (info.tableKey === "Licensor") {
      setEntryData(clearLicensorTable());
      changeCurrentHistory({
        entryData: clearLicensorTable(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (info.tableKey === "Licensee") {
      setEntryData(clearLicenseeTable());
      changeCurrentHistory({
        entryData: clearLicenseeTable(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      if (licenseeTableLength > 1 && !checkDisabledDeleteTable()) {
        changeFocusStatus({ type: "delete", container: "table" });
      } else {
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
      }
    }

    if (info.tableKey === "Additional Information") {
      setEntryData(clearAdditionalInformationTable());
      changeCurrentHistory({
        entryData: clearAdditionalInformationTable(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    checkDisabledDeleteTable,
    entryData,
    groupTableIndex,
    groupTableKey,
    info.tableIndex,
    info.tableKey,
    licenseeTableLength,
    setEntryData,
    tableIndex,
    tableKey,
  ]);

  const handleDeleteTable = useCallback(() => {
    if (checkDisabledDeleteTable()) {
      return;
    }

    if (licenseeTableLength > 1) {
      changeFocusStatus(DEFAULT_FOCUS_STATUS);

      const deleteLicenseeTable = (): ArticleEntryData => {
        return {
          ...entryData,
          Deals: {
            ...entryData.Deals,
            "Licensee Table": entryData.Deals?.["Licensee Table"]?.filter(
              (_, tableInfoIndex) => tableInfoIndex !== info.tableIndex,
            ),
          },
        };
      };

      if (info.groupTableKey === KEY_DEALS_TABLE.licenseeTable) {
        setEntryData(deleteLicenseeTable());
        changeCurrentHistory({
          entryData: deleteLicenseeTable(),
          changedDataInfo: {
            target: "table",
            action: "delete",
            position: {
              groupTableKey,
              groupTableIndex,
              tableKey,
              tableIndex,
              groupShellKey: null,
              groupShellIndex: null,
              subGroupShellIndex: null,
              subGroupShellKey: null,
              shellKey: null,
              shellIndex: null,
              valueIndex: null,
              value: null,
            },
          },
        });
        changeInfo(DEFAULT_POSITION_INFO);
      }
    }
  }, [
    checkDisabledDeleteTable,
    licenseeTableLength,
    changeFocusStatus,
    info.groupTableKey,
    info.tableIndex,
    entryData,
    setEntryData,
    changeCurrentHistory,
    groupTableKey,
    groupTableIndex,
    tableKey,
    tableIndex,
    changeInfo,
  ]);

  const handleClickLicenseeTableDeleteButton = () => {
    if (focusStatus.type === "clear") handleClearTable();
    if (focusStatus.type === "delete") handleDeleteTable();
  };

  const handleKeyDownToDeleteLicenseeTable = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearTable();
      }
      if (focusStatus.type === "delete" && event.key === "Delete") {
        handleDeleteTable();
      }
      changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    },
    [
      focusStatus.type,
      changeValidatedInfo,
      handleClearTable,
      handleDeleteTable,
    ],
  );

  return {
    handleClickLicenseeTableDeleteButton,
    handleKeyDownToDeleteLicenseeTable,
  };
};
