import styled from "styled-components";

export const FillInTableContainer = styled.div<{ isShowContent: boolean }>`
  width: 100%;
  height: ${({ isShowContent }) => (isShowContent ? "auto" : "4.4rem")};
  overflow: hidden;
  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #f0f9ff);
  padding: ${({ isShowContent }) =>
    isShowContent ? "0.8rem 1.2rem 1.2rem 1.2rem" : "0.8rem 1.2rem"};
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 1.6rem;
  margin-top: 1.6rem;

  button {
    cursor: pointer;
  }

  &:hover {
    background: var(
      --Color-Common-Bg-Interactive-Primary-Subtle-Hovered,
      #e0f4ff
    );
  }
  &:active {
    background: var(
      --Color-Common-Bg-Interactive-Primary-Subtle-Pressed,
      #d1efff
    );
  }
`;

export const FillInTableContainerHeadLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 2.8rem;
`;

export const FillInTableContainerTitle = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  img {
    width: 1.6rem;
    height: 1.6rem;
  }
  button {
    cursor: pointer;
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const FillInTableContainerExpandViewText = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.4rem;

  img {
    width: 2rem;
    height: 2rem;
  }

  span {
    font-weight: 500;
    font-size: 1.4rem;
    color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  }
`;

export const FillInTableContentContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.neural.white};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
  }
`;
