import styled from "styled-components";

export const Button = styled.button`
  position: relative;
  width: 16.8rem;
  height: 4rem;

  background: rgba(255, 153, 0, 0.1);
  border-radius: 0.8rem;

  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1;
  display: flex;
  gap: 0.8rem;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.color.status.progress};
  cursor: not-allowed;

  & > div.message {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -2.2rem;
    min-width: 12.6rem;
    height: 2rem;
    filter: drop-shadow(0px 4px 8px rgba(7, 68, 162, 0.08));

    border-radius: 0.8rem;
    padding: 0.4rem 0.8rem;

    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1;
    background-color: white;
  }

  & > div.triangle {
    position: absolute;
    top: -0.2rem;
    display: flex;
    width: 0;
    height: 0;
    /* 
      CSS를 이용하여 삼각형을 만들기위한 Hack
      border의 Bottom이 높이를 결정하고 Left, Right가 삼각형의 너비를 결정한다.
    */
    border-bottom: 1rem solid white;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;

    rotate: 180deg;

    filter: drop-shadow(0px 4px 8px rgba(7, 68, 162, 0.08));
  }
`;
