"use client";

import styled from "styled-components";

export const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.2rem;
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.4rem;
  padding: 0.2rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
`;

export const SectionSubTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.2rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  padding: 0 0.2rem;
`;
