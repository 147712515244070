import { useCallback } from "react";

import {
  CLINICAL_TRIAL_DESIGN_TABLE_KEY,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS,
  ENTRY_DATA_SECTION_KEYS,
} from "@frontend/common";

import {
  DEFAULT_FOCUS_STATUS,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useCheckDisabledDelete } from "@FillInTableContainer/shared/hooks/useCheckDisabledDelete";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { TableInfoProps } from "../ClinicalTrialDesignTable";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorTableReturn {
  clearClinicalTrialDesignTable: () => void;
}

export const useClearClinicalTrialsClinicalTrialDesignTable = (
  tablePropsInfo: TableInfoProps,
): UseHandleTableCreatorTableReturn => {
  const { groupTableKey, tableKey, groupTableIndex, tableIndex } =
    tablePropsInfo;

  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeFocusStatus } = useTableCreatorFocusStatus();
  const { checkDisabledDeleteTable } = useCheckDisabledDelete();

  const clinicalTrialDesignTableData =
    entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
      ENTRY_DATA_CLINICAL_TRIAL_DESIGN
    ];

  const clinicalTrialDesignTableLength =
    clinicalTrialDesignTableData?.length || 0;

  const clearClinicalTrialDesignTable = useCallback(() => {
    const clearDrugProfile = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: clinicalTrialDesignTableData?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                          .clinicalTrialDesign1
                      ],
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.clinicalDesignPhase]:
                        {
                          ...tableInfo[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                              .clinicalTrialDesign1
                          ]?.[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                              .clinicalDesignPhase
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDiagnostic]:
                        {
                          ...tableInfo[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                              .clinicalTrialDesign1
                          ]?.[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                              .inclusionCriteriaDiagnostic
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDetail]:
                        {
                          ...tableInfo[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                              .clinicalTrialDesign1
                          ]?.[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                              .inclusionCriteriaDetail
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.totalPatientsNumber]:
                        {
                          ...tableInfo[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                              .clinicalTrialDesign1
                          ]?.[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                              .totalPatientsNumber
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialName]: {
                        ...tableInfo[
                          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                            .clinicalTrialDesign1
                        ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialName],
                        value: [],
                      },
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.nctName]: {
                        ...tableInfo[
                          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                            .clinicalTrialDesign1
                        ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.nctName],
                        value: [],
                      },
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialIntervention]:
                        [],
                    },
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2]:
                    tableInfo[
                      ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
                    ].map((subGroupShellInfo) => {
                      return {
                        ...subGroupShellInfo,
                        [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.armName]: {
                          ...subGroupShellInfo[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.armName
                          ],
                          value: [],
                        },
                        [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.patientsNumber]:
                          {
                            ...subGroupShellInfo[
                              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS
                                .patientsNumber
                            ],
                            value: [],
                          },
                        [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation]:
                          subGroupShellInfo[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS
                              .designGroupInformation
                          ].map((shellInfo) => {
                            return {
                              ...shellInfo,
                              [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.does]: {
                                ...shellInfo[
                                  ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.does
                                ],
                                value: [],
                              },
                              [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.drugName]:
                                {
                                  ...shellInfo[
                                    ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS
                                      .drugName
                                  ],
                                  value: [],
                                },
                              [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.duration]:
                                {
                                  ...shellInfo[
                                    ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS
                                      .duration
                                  ],
                                  value: [],
                                },
                            };
                          }),
                      };
                    }),
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    if (info.tableKey === CLINICAL_TRIAL_DESIGN_TABLE_KEY) {
      setEntryData(clearDrugProfile());
      changeCurrentHistory({
        entryData: clearDrugProfile(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      if (clinicalTrialDesignTableLength > 1 && !checkDisabledDeleteTable()) {
        changeFocusStatus({ type: "delete", container: "table" });
      } else {
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
        changeInfo(DEFAULT_POSITION_INFO);
      }
    }
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    checkDisabledDeleteTable,
    clinicalTrialDesignTableData,
    clinicalTrialDesignTableLength,
    entryData,
    groupTableIndex,
    groupTableKey,
    info.tableIndex,
    info.tableKey,
    setEntryData,
    tableIndex,
    tableKey,
  ]);

  return {
    clearClinicalTrialDesignTable,
  };
};
