"use client";

import styled from "styled-components";

export const DrugNameText = styled.div`
  display: flex;
  align-items: center;
  height: 2.8rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.8rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  word-break: keep-all;
  overflow: hidden;
`;
