import React from "react";

import {
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
  LICENSE_AGREEMENT_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import { KEY_DEALS_GROUP_SHELL } from "@FillInTableContainer/shared/constants/dealsKey.constants";
import {
  GROUP_SHELL_PATH_TO_ADD_GROUP_SHELL,
  BASIC_DATA_FOR_ADDING_GROUP_SHELLS,
} from "@FillInTableContainer/shared/constants/tableCreatorGroupShell.constants";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useCheckDisabledDelete } from "@FillInTableContainer/shared/hooks/useCheckDisabledDelete";
import { useUpdateEntryData } from "@hooks/useUpdateArticleData";
import { useEntryDataState } from "@stores/entryData";
import { useInitEntryDataValue } from "@stores/initEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  clickAddRightScopeGroupShellButton: (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
    },
  ) => void;
  handleClickToDeleteLicenseeTableGroupShell: () => void;
  handleKeyDownToDeleteLicenseeTableGroupShell: (event: KeyboardEvent) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: DealsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useHandleDealTermsLicenseeTableGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const articleSection = "Deals";
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const initEntryData = useInitEntryDataValue(getPressIdFromURL());
  const updateEntryData = useUpdateEntryData();
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { checkDisabledDeleteGroupShell } = useCheckDisabledDelete();

  const handleAddGroupShell = (groupShellInfo: {
    tableIndexParameter: number;
    groupShellIndexParameter: number;
    groupShellKeyParameter: string | null;
  }) => {
    if (focusStatus.container !== "noFocus") return;

    const {
      tableIndexParameter,
      groupShellIndexParameter,
      groupShellKeyParameter,
    } = groupShellInfo;

    if (groupShellKeyParameter === null) return;

    const targetShell = GROUP_SHELL_PATH_TO_ADD_GROUP_SHELL[
      groupShellKeyParameter
    ](entryData, tableIndexParameter, groupShellIndexParameter);

    if (targetShell === undefined) return;

    const newArrayLength = targetShell.length + 1 || 1;
    const emptyArray = new Array(newArrayLength).fill({});

    const addRightsScopeGroupShellData = emptyArray.map((_, index) => {
      const RightScopeGroupShellData =
        BASIC_DATA_FOR_ADDING_GROUP_SHELLS[articleSection][
          groupShellKeyParameter
        ](initEntryData);

      if (groupShellKeyParameter !== KEY_DEALS_GROUP_SHELL.rightsScope) return;

      if (index === groupShellIndexParameter) {
        return RightScopeGroupShellData;
      }

      if (index > groupShellIndexParameter) {
        return targetShell[index - 1];
      }

      if (index < groupShellIndexParameter) {
        return targetShell[index];
      }

      return targetShell[index];
    });

    const changeGroupShellData: Record<string, ArticleEntryData> = {
      "Rights Scope": {
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableData, tableDataIndex) => {
              if (tableDataIndex === tableIndexParameter) {
                return {
                  ...tableData,
                  "Rights Scope": addRightsScopeGroupShellData,
                };
              }

              return tableData;
            },
          ),
        },
      } as ArticleEntryData,
    };

    setEntryData(changeGroupShellData[groupShellKeyParameter]);
    updateEntryData(changeGroupShellData[groupShellKeyParameter]);
    changeCurrentHistory({
      entryData: changeGroupShellData[groupShellKeyParameter],
      changedDataInfo: {
        target: "groupShell",
        action: "add",
        position: {
          groupShellKey,
          groupTableIndex,
          tableKey,
          tableIndex,
          groupTableKey,
          groupShellIndex: groupShellIndexParameter,
          subGroupShellIndex: null,
          subGroupShellKey: null,
          shellKey: null,
          shellIndex: null,
          valueIndex: null,
          value: null,
        },
      },
    });

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  };

  const clickAddRightScopeGroupShellButton = (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
    },
  ) => {
    event.stopPropagation();
    handleAddGroupShell(groupShellInfo);
  };

  const handleClearGroupShell = () => {
    const clearRightScopeGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  "Rights Scope": tableInfo["Rights Scope"]?.map(
                    (groupShellInfo, groupShellInfoIndex) => {
                      if (groupShellInfoIndex === info.groupShellIndex) {
                        return {
                          ...groupShellInfo,
                          Drug: {
                            ...groupShellInfo.Drug,
                            value: [],
                          },
                          Exclusivity: {
                            ...groupShellInfo.Exclusivity,
                            value: [],
                          },
                          Indication: {
                            ...groupShellInfo.Indication,
                            value: [],
                          },
                          Territory: {
                            ...groupShellInfo.Territory,
                            value: [],
                          },
                        };
                      }
                      return groupShellInfo;
                    },
                  ),
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const clearFinancialTermsGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;
      if (
        info.groupShellKey !== LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTerms
      )
        return entryData;

      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                const targetSCandLaData =
                  tableInfo?.[
                    "Strategic Collaboration & License Agreement terms"
                  ];
                return {
                  ...tableInfo,
                  "Strategic Collaboration & License Agreement terms": {
                    ...targetSCandLaData,
                    "Milestone Payment and Condition": targetSCandLaData[
                      "Milestone Payment and Condition"
                    ].map((subGroupShellInfo) => {
                      return {
                        ...subGroupShellInfo,
                        "Milestone Condition Title": {
                          ...subGroupShellInfo["Milestone Condition Title"],
                          value: [],
                        },
                        "Milestone Payment": {
                          ...subGroupShellInfo?.["Milestone Payment"],
                          value: [],
                        },
                      };
                    }),
                    "Other Financial Terms (SC & LA)": {
                      ...targetSCandLaData["Other Financial Terms (SC & LA)"],
                      value: [],
                    },
                    Royalty: {
                      ...targetSCandLaData.Royalty,
                      value: [],
                    },
                    "Total Payment (SC & LA)": {
                      ...targetSCandLaData["Total Payment (SC & LA)"],
                      value: [],
                    },
                    "Upfront Payment": {
                      ...targetSCandLaData["Upfront Payment"],
                      value: [],
                    },
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.disclosedPaymentsSumScLa]:
                      {
                        ...targetSCandLaData[
                          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                            .disclosedPaymentsSumScLa
                        ],
                        value: [],
                      },
                  },
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const clearOtherFinancialTermsGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;
      if (info.groupShellKey !== "Other Financial Terms") return entryData;

      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                const targetSCandLaData =
                  tableInfo?.[
                    "Strategic Collaboration & License Agreement terms"
                  ];
                return {
                  ...tableInfo,
                  "Strategic Collaboration & License Agreement terms": {
                    ...targetSCandLaData,
                    "Other Financial Terms (SC & LA)": {
                      ...targetSCandLaData["Other Financial Terms (SC & LA)"],
                      value: [],
                    },
                  },
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const clearLicenseeGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex)
                return {
                  ...tableInfo,
                  "Licensee Name": {
                    ...tableInfo["Licensee Name"],
                    value: [],
                  },
                  "Strategic Collaboration & License Agreement terms": {
                    ...tableInfo[
                      "Strategic Collaboration & License Agreement terms"
                    ],
                    "Licensee Responsibility & Rights (SC & LA)": {
                      ...tableInfo[
                        "Strategic Collaboration & License Agreement terms"
                      ]["Licensee Responsibility & Rights (SC & LA)"],
                      value: [],
                    },
                  },
                };

              return tableInfo;
            },
          ),
        },
      };
    };

    const clearLicensorGroupShell = (): ArticleEntryData => {
      const targetData = entryData.Deals?.["Licensor Table"];
      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensor Table": {
            ...targetData,
            "Licensor Name": {
              ...targetData?.["Licensor Name"],
              value: [],
            },
            "Licensor Responsibility & Rights": {
              ...targetData?.["Licensor Responsibility & Rights"],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    const clearDealPurposeGroupShell = (): ArticleEntryData => {
      const targetData = entryData.Deals?.["Additional Information (Deal)"];
      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Additional Information (Deal)": {
            ...targetData,
            "Purpose of the Deal": {
              ...targetData?.["Purpose of the Deal"],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    const clearAdditionalInformationGroupShell = (): ArticleEntryData => {
      const targetData = entryData.Deals?.["Additional Information (Deal)"];
      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Additional Information (Deal)": {
            ...targetData,
            "Additional Information": {
              ...targetData?.["Additional Information"],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    if (
      info.tableKey === "Deal Purpose" &&
      info.groupShellKey === "Deal Purpose"
    ) {
      setEntryData(clearDealPurposeGroupShell());
      changeCurrentHistory({
        entryData: clearDealPurposeGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
    if (
      info.tableKey === "Additional Information" &&
      info.groupShellKey === "Additional Information"
    ) {
      setEntryData(clearAdditionalInformationGroupShell());
      changeCurrentHistory({
        entryData: clearAdditionalInformationGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (info.tableKey === "Licensor" && info.groupShellKey === "Licensor") {
      setEntryData(clearLicensorGroupShell());
      changeCurrentHistory({
        entryData: clearLicensorGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.tableKey === "Licensee" &&
      info.groupShellKey !== "Rights Scope" &&
      info.groupShellKey !== LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTerms
    ) {
      setEntryData(clearLicenseeGroupShell());
      changeCurrentHistory({
        entryData: clearLicenseeGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (info.groupShellKey === "Rights Scope") {
      const rightsScopeLength =
        entryData?.Deals?.["Licensee Table"]?.[info.tableIndex]?.[
          "Rights Scope"
        ]?.length || 0;

      setEntryData(clearRightScopeGroupShell());
      changeCurrentHistory({
        entryData: clearRightScopeGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      if (rightsScopeLength > 1 && !checkDisabledDeleteGroupShell())
        changeFocusStatus({
          type: "delete",
          container: "groupShell",
        });
      else {
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
        changeInfo(DEFAULT_POSITION_INFO);
      }
    }

    if (
      info.groupShellKey === LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTerms
    ) {
      setEntryData(clearFinancialTermsGroupShell());
      changeCurrentHistory({
        entryData: clearFinancialTermsGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (info.groupShellKey === "Other Financial Terms") {
      setEntryData(clearOtherFinancialTermsGroupShell());
      changeCurrentHistory({
        entryData: clearOtherFinancialTermsGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  };

  const handleDeleteGroupShell = () => {
    if (checkDisabledDeleteGroupShell()) return;

    const deleteRightScopeGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  "Rights Scope": tableInfo["Rights Scope"]?.filter(
                    (_, groupShellInfoIndex) =>
                      groupShellInfoIndex !== info.groupShellIndex,
                  ),
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };
    setEntryData(deleteRightScopeGroupShell());
    changeCurrentHistory({
      entryData: deleteRightScopeGroupShell(),
      changedDataInfo: {
        target: "groupShell",
        action: "delete",
        position: {
          groupShellKey,
          groupTableIndex,
          tableKey,
          tableIndex,
          groupTableKey,
          groupShellIndex,
          subGroupShellIndex: null,
          subGroupShellKey: null,
          shellKey: null,
          shellIndex: null,
          valueIndex: null,
          value: null,
        },
      },
    });

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  };

  const handleClickToDeleteLicenseeTableGroupShell = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShell();
    }
    if (focusStatus.type === "delete") {
      handleDeleteGroupShell();
    }
  };

  const handleKeyDownToDeleteLicenseeTableGroupShell = (
    event: KeyboardEvent,
  ) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearGroupShell();
    }
    if (focusStatus.type === "delete" && event.key === "Delete") {
      handleDeleteGroupShell();
    }
  };

  return {
    clickAddRightScopeGroupShellButton,
    handleClickToDeleteLicenseeTableGroupShell,
    handleKeyDownToDeleteLicenseeTableGroupShell,
  };
};
