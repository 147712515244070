import { IconCheck, IconXMark } from "@frontend/common";

import FilterTag from "@atoms/FilterTag/FilterTag";
import Modal from "@atoms/Modal/Modal";
import { CLASSIFICATION_GROUP } from "@PressListContainer/classificationInfo.constants";

import * as S from "./SelectClassificationForDuplicatePress.style";
import { useDuplicatePress } from "../hooks/useDuplicatePress";
import ListOfDuplicatePress from "../ListOfDuplicatePress/ListOfDuplicatePress";

interface Props {
  closeModal: () => void;
}

const SelectClassificationForDuplicatePress = ({ closeModal }: Props) => {
  const {
    submitButtonForDuplicatePress,
    clickClassificationLabel,
    changeClassificationLabel,
    checkClassificationCheckedStatus,
    checkClassificationAiStatus,
    isDisabledDuplicatePressButton,
  } = useDuplicatePress();

  return (
    <Modal closeModal={closeModal}>
      <S.ClassificationWrapper>
        <S.ClassificationContentWrapper>
          <S.ClassificationRow>
            <S.ClassificationTitle>
              Duplicate Press - Select Manual Classification
            </S.ClassificationTitle>
            <S.ClassificationCloseButton type="button" onClick={closeModal}>
              <S.ClassificationCloseText>Cancel</S.ClassificationCloseText>
              <S.ClassificationXMark>
                <IconXMark color="#E53E06" width={20} height={20} />
              </S.ClassificationXMark>
            </S.ClassificationCloseButton>
          </S.ClassificationRow>
          <S.ClassificationManualFieldset>
            {Object.keys(CLASSIFICATION_GROUP).map((classificationCode) => (
              <FilterTag
                key={classificationCode}
                value={classificationCode}
                tagType="radio"
                name="classification"
                filterType="pressManualClassification"
                checked={checkClassificationCheckedStatus(classificationCode)}
                onClick={clickClassificationLabel}
                onChange={changeClassificationLabel}
                aiSelected={checkClassificationAiStatus(classificationCode)}
              >
                {CLASSIFICATION_GROUP[classificationCode]}
              </FilterTag>
            ))}
          </S.ClassificationManualFieldset>

          <S.DuplicatePressButton
            onClick={(event) => {
              submitButtonForDuplicatePress(event);
              closeModal();
            }}
            disabled={isDisabledDuplicatePressButton}
          >
            <IconCheck width={20} height={20} color="#fff" />
            <S.DuplicatePressButtonButtonText>
              Duplicate
            </S.DuplicatePressButtonButtonText>
          </S.DuplicatePressButton>
          <S.Divider />
          <ListOfDuplicatePress />
        </S.ClassificationContentWrapper>
      </S.ClassificationWrapper>
    </Modal>
  );
};

export default SelectClassificationForDuplicatePress;
