import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useFillInTableRefMap } from "@FillInTableContainer/components/FillInTable/contexts/FillInTableRefMapContext";
import { useDragScroll } from "@FillInTableContainer/components/FillInTable/hooks/useDragScroll";
import { useScroll } from "@FillInTableContainer/components/FillInTable/hooks/useScroll";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { GroupTableKeyType } from "@FillInTableContainer/shared/types/tableCreator.types";

import * as S from "./FillInTableNavigation.style";

type Item = {
  title: string;
  sectionKey: GroupTableKeyType;
};
interface ItemProps extends Item {
  sectionIndex: number;
  focusedSection: string;
  setFocusedSection: Dispatch<SetStateAction<GroupTableKeyType>>;
}

const NavigationItem = ({
  title,
  sectionKey,
  sectionIndex,
  focusedSection,
  setFocusedSection,
}: ItemProps) => {
  const { changeInfo } = useTableCreatorPositionInfo();
  const { scroll } = useScroll();
  const { refMap } = useFillInTableRefMap();

  const handleClick = () => {
    scroll(sectionKey);
    changeInfo({
      ...DEFAULT_POSITION_INFO,
      groupTableKey: sectionKey,
      groupTableIndex: sectionIndex,
    });
  };

  useEffect(() => {
    const targetRef = refMap.get(sectionKey);

    const option = {
      root: null,
      rootMargin: "-25% 0px -75% 0px",
      threshold: [0],
    };

    const observer = new IntersectionObserver((entry) => {
      if (!entry[0].isIntersecting) return;
      setFocusedSection(sectionKey);
    }, option);

    if (targetRef && typeof targetRef !== "function") {
      if (targetRef.current) {
        observer.observe(targetRef.current);
      }
    }

    return () => observer.disconnect();
  }, [refMap, sectionKey, setFocusedSection]);

  return (
    <S.Item isFocused={sectionKey === focusedSection} onClick={handleClick}>
      {title}
    </S.Item>
  );
};

const FillInTableNavigation = ({ itemList }: { itemList: Item[] }) => {
  const { scrollContainerRef, handleMouseDownToDragScroll } = useDragScroll();
  const [focusedSection, setFocusedSection] =
    useState<GroupTableKeyType>("Drug Profile");

  return (
    <S.Scroll ref={scrollContainerRef}>
      <S.Layout onMouseDown={handleMouseDownToDragScroll}>
        {itemList.map(({ title, sectionKey }, sectionIndex) => {
          return (
            <S.ItemWrapper>
              <NavigationItem
                key={sectionKey}
                title={title}
                sectionKey={sectionKey}
                sectionIndex={sectionIndex}
                focusedSection={focusedSection}
                setFocusedSection={setFocusedSection}
              />
            </S.ItemWrapper>
          );
        })}
      </S.Layout>
    </S.Scroll>
  );
};

export default FillInTableNavigation;
