import React, { Component, ReactNode } from "react";

import ErrorMessageContainer from "./ErrorMessageContainer/ErrorMessageContainer";

interface Props {
  children?: ReactNode;
  addComponents?: ReactNode;
}

interface State {
  shouldHandleError: boolean;
  error: Error | null;
}

class ComponentErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shouldHandleError: false,
      error: null,
    };
  }

  public static getDerivedStateFromError(error: Error): State {
    return {
      shouldHandleError: true,
      error,
    };
  }

  public render() {
    const { shouldHandleError, error } = this.state;
    const { children, addComponents } = this.props;

    if (!shouldHandleError) {
      return children;
    }

    if (shouldHandleError) {
      return (
        <>
          <ErrorMessageContainer
            message={error?.message ?? "Please try again later"}
            clickButton={() => {
              this.setState({
                shouldHandleError: false,
                error: null,
              });
            }}
            addComponents={addComponents}
          />
        </>
      );
    }
    return children;
  }
}

export default ComponentErrorBoundary;
