import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconInfo = ({ width = 21, height = 20, color = "#7C899C" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8002 3.68054C7.31002 3.68054 4.48071 6.50985 4.48071 9.99999C4.48071 13.4901 7.31002 16.3194 10.8002 16.3194C14.2903 16.3194 17.1196 13.4901 17.1196 9.99999C17.1196 6.50985 14.2903 3.68054 10.8002 3.68054ZM3.23071 9.99999C3.23071 5.8195 6.61967 2.43054 10.8002 2.43054C14.9806 2.43054 18.3696 5.8195 18.3696 9.99999C18.3696 14.1805 14.9806 17.5694 10.8002 17.5694C6.61967 17.5694 3.23071 14.1805 3.23071 9.99999ZM10.8002 9.37498C11.1453 9.37498 11.4252 9.6548 11.4252 9.99998V12.7778C11.4252 13.1229 11.1453 13.4028 10.8002 13.4028C10.455 13.4028 10.1752 13.1229 10.1752 12.7778V9.99998C10.1752 9.6548 10.455 9.37498 10.8002 9.37498ZM10.8002 6.59721C10.455 6.59721 10.1752 6.87703 10.1752 7.22221C10.1752 7.56739 10.455 7.84721 10.8002 7.84721H10.8071C11.1523 7.84721 11.4321 7.56739 11.4321 7.22221C11.4321 6.87703 11.1523 6.59721 10.8071 6.59721H10.8002Z"
        fill={color}
      />
    </svg>
  );
};

IconInfo.displayName = "Info";
export default IconInfo;
