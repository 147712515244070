"use client";

import styled from "styled-components";

import { TableTitleArrowDirection } from "./types/tableTitleArrowIcon.types";

export const TableTitleArrowIconWrapper = styled.div<{
  direction: TableTitleArrowDirection;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 1.6rem;

  transform: ${({ direction }) => direction === "left" && "scaleX(-1)"};
`;
