import { apiClient } from "@api/apiClient";

import type {
  FilteringData,
  PostPressListFilterResponse,
} from "@interface/pressList.interface";

const API_ENDPOINT = {
  draftArticleList: "/des/draft-article/list-open-search",
  checkPressStatus: "/des/draft-article/status",
};

export const pressListApis = {
  post: async (filteringData: FilteringData) => {
    const { data } = await apiClient.post<PostPressListFilterResponse>(
      API_ENDPOINT.draftArticleList,
      filteringData,
    );
    return data;
  },
  checkPressStatus: async (draftArticleId: string) => {
    const { data } = await apiClient.get(
      `${API_ENDPOINT.checkPressStatus}/${draftArticleId}`,
    );
    return data;
  },
};
