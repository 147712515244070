import styled from "styled-components";

import {
  DeleteButton,
  DeleteButtonWrapper,
  FixedWrapper,
} from "@FillInTableContainer/shared/styles/deleteButton.style";
import { OutsideWrapper } from "@FillInTableContainer/shared/styles/groupShell.style";
import {
  SelectButton,
  SelectButtonDivider,
  SelectButtonDividerWrapper,
  SelectContentWrapper,
} from "@FillInTableContainer/shared/styles/rowSelectButton.style";

export const GroupShellOutsideWrapper = styled.div``;

export const GroupShellWrapper = styled(OutsideWrapper)`
  padding-left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const GroupShell = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  width: 100%;
`;

export const FixedDeleteButtonWrapper = styled(FixedWrapper)<{
  isShellTitle?: boolean;
  isSeparatedTitleRow?: boolean;
  isSeparatedTitlePosition?: number;
}>`
  top: -2.4rem;
  left: 0rem;
`;

export const DeleteTableButtonWrapper = styled(DeleteButtonWrapper)``;

export const DeleteTableButton = styled(DeleteButton)``;

export const SelectWrapper = styled(SelectContentWrapper)``;

export const GroupShellSelectButton = styled(SelectButton)``;

export const GroupShellSelectButtonDivider = styled(SelectButtonDivider)``;

export const GroupShellSelectButtonDividerWrapper = styled(
  SelectButtonDividerWrapper,
)``;
