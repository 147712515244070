import { useMovePage } from "@hooks/useMovePage";
import IconArrowRight from "@images/common/icon-arrow-right.svg";

import WaitForAiButton from "./components/WaitForAiButton/WaitForAiButton";
import { useAIEntryInProgress } from "./hooks/useAIEntryInProgress";
import * as S from "./MoveEditArticleButton.style";

const MoveEditArticleButton = () => {
  const aiEntryInProgress = useAIEntryInProgress();
  const movePage = useMovePage();

  const moveNext = () => {
    movePage("editArticle");
  };

  return aiEntryInProgress ? (
    <WaitForAiButton />
  ) : (
    <S.MoveNextButton onClick={moveNext}>
      Edit & Preview
      <img src={IconArrowRight} alt="go to edit article" />
    </S.MoveNextButton>
  );
};

export default MoveEditArticleButton;
