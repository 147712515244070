import styled, { css } from "styled-components";

export const TableDividerBar = styled.div<{
  isDraggingEndPosition: boolean;
}>`
  width: calc(100% - 1.6rem);
  height: 0.4rem;
  border-radius: 0.2rem;
  background-color: ${({ theme }) => theme.color.node.cyan};
  margin-left: 0.8rem;
  opacity: 0;

  ${({ isDraggingEndPosition }) =>
    isDraggingEndPosition &&
    css`
      opacity: 1;
    `}
`;
