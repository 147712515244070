import styled from "styled-components";

export const FillInTableArticleTable = styled.div<{ isModal?: boolean }>`
  width: 100%;
  background-color: #fff;

  table {
    width: 100%;
    border: 1px solid var(--grayscale-spectrum-neural-blue-gray-03, #dee5ef);
    max-height: 5rem;
  }
  tr {
    border: 0.1rem solid ${({ theme }) => theme.color.neural.gray03};
  }
  td {
    width: 10%;
    font-size: ${({ isModal }) => (isModal ? "1.4rem" : "1.4rem")};
    color: #000;
    font-weight: 400;
    padding: 0.8rem 0.4rem;
    line-height: 145%;

    :first-child {
      padding-left: 0.5rem;
    }

    :not(:first-child) {
      padding-right: 0.5rem;
      text-align: right;
      border-left: 0.1rem solid ${({ theme }) => theme.color.neural.gray03};
    }
  }
`;
