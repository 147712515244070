import {
  AtomEffect,
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
} from "recoil";

import { LabelCode } from "@/types/code.types";
import { queryClient } from "@api/queryClient";
import { QUERY_KEYS } from "@api/queryKeys";
import { pressInfoApis } from "@services/pressInfo.service";
import { createRandomId } from "@utils/createRandomId";
import { parsePressContent } from "@utils/parsePressContent";

import { RECOIL_KEYS } from "./recoilKeys";
import { MAIN_LABEL_INFO } from "../types/mainLabel.data";

import type { PressLabel } from "@/types/pressLabel.type";

type ArticleId = number;
interface PressLabelHistory {
  [key: ArticleId]: {
    item: Array<PressLabel[]>;
    undo: () => void;
  };
}

const history: PressLabelHistory = {};

const decreasePressLabel = (a: PressLabel, b: PressLabel) => b.start - a.start;

const historyEffect =
  (articleId: ArticleId): AtomEffect<PressLabel[]> =>
  ({ onSet, setSelf }) => {
    onSet((newValue) => {
      if (!Object.keys(history).includes(articleId.toString())) {
        history[articleId] = {
          item: [],
          undo: () => {
            if (Object.keys(history[articleId].item).length <= 1) return;
            history[articleId].item.pop();
            setSelf(history[articleId].item.at(-1) ?? []);
          },
        };
      }
      history[articleId].item.push(newValue);
    });
  };

const initPressLabel = async (articleId: ArticleId) => {
  const {
    data: { pressLabelDtoList, pressContent },
  } = await queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.pressInfo, articleId],
    queryFn: () => pressInfoApis.get(articleId),
    staleTime: 60 * 60 * 1000, // FIXME: 우선 임의의 시간인 1시간으로 설정
  });
  if (pressLabelDtoList === null || pressLabelDtoList === undefined) return [];

  const defaultPressLabel: PressLabel[] = [];

  pressLabelDtoList.forEach(
    ({ pressLabelLocationDtos, entity, pressLabelMappingId, label }) => {
      pressLabelLocationDtos.forEach(
        ({ labelLocationId, startIndex, endIndex }) => {
          defaultPressLabel.push({
            key: labelLocationId?.toString(),
            start: startIndex,
            end: endIndex,
            entity,
            color: MAIN_LABEL_INFO[entity],
            label,
            rawLabel: parsePressContent(pressContent).slice(
              startIndex,
              endIndex,
            ),
            labelLocationId,
            pressLabelMappingId,
          });
        },
      );
    },
  );

  return defaultPressLabel.sort(decreasePressLabel);
};

const pressLabelsState = atomFamily<PressLabel[], ArticleId>({
  key: RECOIL_KEYS.pressLabelsState,
  default: initPressLabel,
  effects: (articleId: ArticleId) => [historyEffect(articleId)],
});

const sortedPressLabelsState = selectorFamily<PressLabel[], ArticleId>({
  key: RECOIL_KEYS.sortedPressLabelsState,
  get:
    (articleId) =>
    ({ get }) => {
      const pressLabels = get(pressLabelsState(articleId));
      return pressLabels;
    },
  set:
    (articleId) =>
    ({ set }, newValue) => {
      if (newValue instanceof Array)
        set(pressLabelsState(articleId), newValue.sort(decreasePressLabel));
      else set(pressLabelsState(articleId), newValue);
    },
});

const isOverlay = (a: PressLabel, b: PressLabel) =>
  !(a.end <= b.start || b.end <= a.start);

export const usePressLabelsValue = (articleId: ArticleId) =>
  useRecoilValue(sortedPressLabelsState(articleId));

export const undoPressLabels = (articleId: ArticleId) => () =>
  history[articleId].undo();

export type AddPressLabelAction = {
  labels: PressLabel[];
  selectedLabel: PressLabel;
};

export const useAddPressLabels = (articleId: ArticleId) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      ({ labels: newLabels, selectedLabel }: AddPressLabelAction) => {
        const pressLabels = snapshot
          .getLoadable(sortedPressLabelsState(articleId))
          .getValue();

        const resultLabels = [selectedLabel];

        // 선택된 라벨과 겹치지 않는 라벨만 추가
        pressLabels.forEach((prevLabel) => {
          if (!isOverlay(prevLabel, selectedLabel))
            resultLabels.push(prevLabel);
        });

        // 기존 라벨과 새로운 라벨이 겹치지 않는 경우에 라벨 추가
        newLabels.forEach((newLabel) => {
          if (!resultLabels.find((prevLabel) => isOverlay(prevLabel, newLabel)))
            resultLabels.push(newLabel);
        });

        set(sortedPressLabelsState(articleId), [...resultLabels]);
      },
  );

export const useDeletePressLabel = (articleId: ArticleId) =>
  useRecoilCallback(({ set, snapshot }) => (targetLabel: PressLabel) => {
    const pressLabels = snapshot
      .getLoadable(sortedPressLabelsState(articleId))
      .getValue();

    const removedLabels: Set<PressLabel> = new Set(pressLabels);
    if (removedLabels.has(targetLabel)) removedLabels.delete(targetLabel);

    set(sortedPressLabelsState(articleId), [...removedLabels]);
  });

export const useDeletePressLabels = (articleId: ArticleId) =>
  useRecoilCallback(({ set, snapshot }) => (targetLabel: PressLabel) => {
    const pressLabels = snapshot
      .getLoadable(sortedPressLabelsState(articleId))
      .getValue();

    const removedLabels: Set<PressLabel> = new Set(pressLabels);
    pressLabels.forEach((label) => {
      if (
        label.color === targetLabel.color &&
        label.label === targetLabel.label
      )
        removedLabels.delete(label);
    });

    set(sortedPressLabelsState(articleId), [...removedLabels]);
  });

export const useModifyPressLabel = (articleId: ArticleId) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      (targetLabel: PressLabel, entity: LabelCode) => {
        const pressLabels = snapshot
          .getLoadable(sortedPressLabelsState(articleId))
          .getValue();

        const modifiedLabels: Set<PressLabel> = new Set(pressLabels);

        modifiedLabels.delete(targetLabel);
        modifiedLabels.add({
          ...targetLabel,
          entity,
          color: MAIN_LABEL_INFO[entity],
          key: createRandomId(),
        });

        set(sortedPressLabelsState(articleId), [...modifiedLabels]);
      },
  );

export const useModifyPressLabels = (articleId: ArticleId) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      (targetLabel: PressLabel, entity: LabelCode) => {
        const pressLabels = snapshot
          .getLoadable(sortedPressLabelsState(articleId))
          .getValue();

        const modifiedLabels: Set<PressLabel> = new Set(pressLabels);
        pressLabels.forEach((label) => {
          if (
            label.entity === targetLabel.entity &&
            label.label === targetLabel.label
          ) {
            modifiedLabels.delete(label);
            modifiedLabels.add({
              ...label,
              entity,
              color: MAIN_LABEL_INFO[entity],
              key: createRandomId(),
            });
          }
        });

        set(sortedPressLabelsState(articleId), [...modifiedLabels]);
      },
  );

export const useGetPressLabelsValue = (articleId: ArticleId) => {
  const pressLabels = usePressLabelsValue(articleId);

  return (entity: LabelCode) =>
    pressLabels.filter((label) => label.entity === entity);
};

export const useFilteredPressLabelsValue = (
  articleId: ArticleId,
  entity: LabelCode,
) => usePressLabelsValue(articleId).filter((label) => label.entity === entity);
