import React, { Dispatch, SetStateAction, useRef } from "react";

import EnterIcon from "@images/pressList/icon-enter.svg";
import SearchIcon from "@images/pressList/icon-search-gray.svg";
import XMarkIcon from "@images/pressList/icon-x-mark-gray.svg";

import * as S from "./SearchInput.style";

const PX_PER_REM = 10; // 1rem = 10px

interface Props {
  inputWidth?: number;
  widthResize?: boolean;
  placeholder?: string;
  typing: string;
  setTyping: Dispatch<SetStateAction<string>>;
  onSearchButton: () => void;
  handleSearchKeyboard: () => void;
}

const SearchInput = ({
  inputWidth = 59.2,
  widthResize = false,
  placeholder = "",
  typing,
  setTyping,
  onSearchButton,
  handleSearchKeyboard,
  ...rest
}: Props) => {
  const searchInputRef = useRef<HTMLDivElement>(null);

  const isOverflow =
    (searchInputRef.current?.clientWidth ?? 0) >
    (inputWidth - S.SEARCH_BUTTON_WIDTH - S.SEARCH_TYPING_HINT_WIDTH) *
      PX_PER_REM;
  const isShowSearchTypingHint = !!typing && !isOverflow;
  const isShowDeleteButton = !!typing;

  const handleInput: React.FormEventHandler<HTMLDivElement> = (e) => {
    setTyping(e.currentTarget.innerText);
  };

  const handleDeleteSearch = () => {
    setTyping("");

    if (searchInputRef.current) searchInputRef.current.innerText = "";
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // 개행 방지
    if (e.key !== "Enter") return;

    e.preventDefault();
    handleSearchKeyboard();
    handleDeleteSearch();
  };

  const handleClickSearchButton = () => {
    onSearchButton();
    handleDeleteSearch();
  };

  return (
    <S.SearchWrapper inputWidth={inputWidth} widthResize={widthResize}>
      <S.SearchButton type="button" onClick={handleClickSearchButton}>
        <img width={12} height={12} src={SearchIcon} alt="search icon" />
      </S.SearchButton>
      <S.SearchInput
        ref={searchInputRef}
        contentEditable
        suppressContentEditableWarning
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        {...rest}
      />
      {isShowSearchTypingHint && (
        <S.SearchTypingHintWrapper>
          <img width={10} src={EnterIcon} alt="enter-icon" />
          <S.SearchTypingHint>press Enter</S.SearchTypingHint>
        </S.SearchTypingHintWrapper>
      )}
      {isShowDeleteButton && (
        <S.DeleteButton onClick={handleDeleteSearch}>
          <img src={XMarkIcon} alt="search icon" />
        </S.DeleteButton>
      )}
    </S.SearchWrapper>
  );
};

export default SearchInput;
