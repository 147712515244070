import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconXCircle = ({ width = 24, height = 24, color = "black" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.29163C7.74281 4.29163 4.29167 7.74276 4.29167 12C4.29167 16.2572 7.74281 19.7083 12 19.7083C16.2572 19.7083 19.7083 16.2572 19.7083 12C19.7083 7.74276 16.2572 4.29163 12 4.29163ZM3.04167 12C3.04167 7.05241 7.05245 3.04163 12 3.04163C16.9476 3.04163 20.9583 7.05241 20.9583 12C20.9583 16.9475 16.9476 20.9583 12 20.9583C7.05245 20.9583 3.04167 16.9475 3.04167 12ZM14.9419 9.05803C15.186 9.30211 15.186 9.69783 14.9419 9.94191L12.8839 12L14.9419 14.058C15.186 14.3021 15.186 14.6978 14.9419 14.9419C14.6979 15.186 14.3021 15.186 14.0581 14.9419L12 12.8839L9.94195 14.9419C9.69787 15.186 9.30214 15.186 9.05806 14.9419C8.81398 14.6978 8.81398 14.3021 9.05806 14.058L11.1161 12L9.05806 9.94191C8.81398 9.69783 8.81398 9.30211 9.05806 9.05803C9.30214 8.81395 9.69787 8.81395 9.94195 9.05803L12 11.1161L14.0581 9.05803C14.3021 8.81395 14.6979 8.81395 14.9419 9.05803Z"
        fill={color}
      />
    </svg>
  );
};

IconXCircle.displayName = "XCircle";

export default IconXCircle;
