import { useCallback } from "react";

import useArticleSection from "@/components/business/FillInTableContainer/hooks/useArticleSection";
import { ApprovalProcessType } from "@/types/regulatory.types";
import { APPROVAL_PROCESS_CONTENTS_KEY } from "@FillInTableContainer/shared/constants/regulatoryKey";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { PositionInfoProps } from "../RegulatoryTableCreatorShell";
import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorShellReturn {
  handleKeyDownToDeleteApprovalProcessShell: (event: KeyboardEvent) => void;
  handleClickToDeleteApprovalProcessShell: () => void;
}

export const useHandleRegulatoryApprovalProcessShell = (
  positionPropsInfo: PositionInfoProps,
): UseHandleTableCreatorShellReturn => {
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const articleSection = useArticleSection();

  const handleClearShell = useCallback(() => {
    const clearApprovalProcessShell = (): ArticleEntryData => {
      const key = APPROVAL_PROCESS_CONTENTS_KEY;
      const currentData = entryData.Regulatory?.["Approval Process Contents"];
      if (info.shellKey === key.additionalInformationOnApprovalProcess)
        return {
          ...entryData,
          [articleSection]: {
            ...entryData[articleSection],
            "Approval Process Contents": {
              ...currentData,
              [key.additionalInformationOnApprovalProcess]: {
                ...currentData?.[key.additionalInformationOnApprovalProcess],
                value: [],
              },
            },
          },
        };

      return {
        ...entryData,
        [articleSection]: {
          ...entryData[articleSection],
          "Approval Process Contents": {
            ...currentData,
            [key.ApprovalProcess]: currentData?.[key.ApprovalProcess]?.map(
              (groupShellInfo, groupShellInfoIndex) => {
                if (groupShellInfoIndex === info.groupShellIndex) {
                  return {
                    ...groupShellInfo,
                    [info.shellKey as string]: {
                      ...groupShellInfo[
                        info.shellKey as keyof ApprovalProcessType
                      ],
                      value: [],
                    },
                  };
                }

                return groupShellInfo;
              },
            ),
          },
        },
      };
    };

    if (info.groupTableKey === "Approval Process Contents") {
      setEntryData(clearApprovalProcessShell());
      changeCurrentHistory({
        entryData: clearApprovalProcessShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  }, [
    articleSection,
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  const handleClickToDeleteApprovalProcessShell = () => {
    if (focusStatus.type === "clear") {
      handleClearShell();
    }
  };

  const handleKeyDownToDeleteApprovalProcessShell = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearShell();
      }
    },
    [focusStatus.type, handleClearShell],
  );

  return {
    handleClickToDeleteApprovalProcessShell,
    handleKeyDownToDeleteApprovalProcessShell,
  };
};
