import styled, { css } from "styled-components";

import { AiImageStatus } from "@InsertImageContainer/shared/types/insertImage.types";

export const TabMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 24.4rem;
  height: 3.2rem;
  border-radius: 0.4rem;
  border: 1px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);
  overflow: hidden;
`;

export const TabMenuButton = styled.button<{ isFocused: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0rem 2.4rem;
  gap: 0.8rem;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  border-right: 0.1rem solid #0198e9;

  &:last-child {
    border-right: none;
  }

  ${({ isFocused }) =>
    isFocused
      ? css`
          color: #0198e9;
          background: var(
            --Color-Common-Bg-Interactive-Primary-Subtle,
            #f0f9ff
          );
        `
      : css`
          color: #515c6c;
        `}
`;

export const AiStatusButton = styled.div<{ aiStatus: AiImageStatus }>`
  display: flex;
  padding: var(--Spacing-0, 0rem) var(--Spacing-6, 0.6rem);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-2, 0.2rem);
  height: 2rem;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  border-radius: var(--Border-Radius-4, 0.4rem);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16),
    0px 0px 1px 0px rgba(0, 0, 0, 0.12);

  ${({ aiStatus }) => {
    switch (aiStatus) {
      case "SUCCESS":
        return css`
          color: var(--Color-Text-ToDo, #15d555);
          background: var(--Color-Bg-ToDo, #d5fbe2);
        `;
      case "GENERATING":
        return css`
          color: var(--Color-Text-Progress, #eb8d00);
          background: var(--Color-Bg-Progress, #ffedd1);
        `;
      case "FAIL":
        return css`
          color: var(--Color-Text-Error, #db0f72);
          background: var(--Color-Bg-Error, #fcd4e7);
        `;
      case "PENDING":
        return css`
          color: var(--Color-Text-Progress, #eb8d00);
          background: var(--Color-Bg-Progress, #ffedd1);
        `;
      default:
        return null;
    }
  }}
`;
