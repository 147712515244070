import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconImage = ({ width = 20, height = 20, color = "#7C899C" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.13889 4.375C4.717 4.375 4.375 4.717 4.375 5.13889V14.8611C4.375 15.2046 4.60174 15.4952 4.91372 15.5913L12.3358 8.16917C12.5799 7.92509 12.9756 7.92509 13.2197 8.16917L15.625 10.5745V5.13889C15.625 4.717 15.283 4.375 14.8611 4.375H5.13889ZM15.625 12.3422L12.7778 9.495L6.64777 15.625H14.8611C15.283 15.625 15.625 15.283 15.625 14.8611V12.3422ZM5.14061 16.875H14.8611C15.9734 16.875 16.875 15.9734 16.875 14.8611V5.13889C16.875 4.02665 15.9734 3.125 14.8611 3.125H5.13889C4.02665 3.125 3.125 4.02665 3.125 5.13889V14.8611C3.125 15.9727 4.02552 16.8739 5.1368 16.875C5.13807 16.875 5.13934 16.875 5.14061 16.875ZM7.56945 7.15278C7.33933 7.15278 7.15279 7.33933 7.15279 7.56945C7.15279 7.79957 7.33933 7.98611 7.56945 7.98611C7.79957 7.98611 7.98612 7.79957 7.98612 7.56945C7.98612 7.33933 7.79957 7.15278 7.56945 7.15278ZM5.90279 7.56945C5.90279 6.64897 6.64898 5.90278 7.56945 5.90278C8.48993 5.90278 9.23612 6.64897 9.23612 7.56945C9.23612 8.48992 8.48993 9.23611 7.56945 9.23611C6.64898 9.23611 5.90279 8.48992 5.90279 7.56945Z"
        fill={color}
      />
    </svg>
  );
};

IconImage.displayName = "Image";

export default IconImage;
