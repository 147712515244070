import styled from "styled-components";

import UpdateButton from "@EditArticleContainer/shared/components/UpdateButton/UpdateButton";
import {
  ParagraphContentsWrapper,
  ParagraphWrapper,
} from "@EditArticleContainer/shared/styles/paragraph.style";

export const ParagraphClinicalTrialDesignWrapper = styled(ParagraphWrapper)``;

export const ParagraphClinicalTrialDesignContentsWrapper = styled(
  ParagraphContentsWrapper,
)`
  position: relative;
`;

export const ParagraphClinicalTrialDesignUpdateButton = styled(UpdateButton)`
  position: absolute;
  left: -1.6rem;
  top: -4.1rem;
  transform: translateX(-100%);
`;

export const ParagraphTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
`;
