import React, { useState, useRef, useEffect } from "react";

import { ValueOf } from "@frontend/common";

import { useUpdatePressInfoMutate } from "@api/pressInfo/pressInfo";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import * as S from "./EditPressDateInput.style";

type Props = {
  manualPressDate?: string;
  changeStatus: () => void;
};

const DATE_KEYWORDS = {
  year: "YEAR",
  month: "MONTH",
  day: "DAY",
};

const EditPressDateInput = ({ manualPressDate, changeStatus }: Props) => {
  const { mutateAsync: updatePressInfo } = useUpdatePressInfoMutate();
  const [year, month, day] = manualPressDate?.split(".") || ["", "", ""];
  const [yearValue, setYearValue] = useState(year);
  const [monthValue, setMonthValue] = useState(month);
  const [dayValue, setDayValue] = useState(day);
  const [yearError, setYearError] = useState(false);
  const [monthError, setMonthError] = useState(false);
  const [dayError, setDayError] = useState(false);

  const yearRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);

  const validateYear = (value: string) => {
    const yearRegex = /^(19|20)\d{2}$/;
    const yearValid = yearRegex.test(value);
    setYearError(!yearValid);
    return yearValid;
  };

  const validateMonth = (value: string) => {
    const monthRegex = /^(0?[1-9]|1[0-2])$/;
    const monthValid = monthRegex.test(value);
    setMonthError(!monthValid);
    return monthValid;
  };

  const validateDay = (value: string) => {
    const dayRegex = /^(0?[1-9]|[12]\d|3[01])$/;
    const dayValid = dayRegex.test(value);
    setDayError(!dayValid);
    return dayValid;
  };

  const validateDate = () => {
    const yearValid = validateYear(yearValue);
    const monthValid = validateMonth(monthValue);
    const dayValid = validateDay(dayValue);

    return yearValid && monthValid && dayValid;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    nextRef: React.RefObject<HTMLInputElement> | null,
    dateType: ValueOf<typeof DATE_KEYWORDS>,
  ) => {
    const { value } = e.currentTarget;
    setValue(value);

    if (value.length === e.target.maxLength && nextRef?.current) {
      nextRef.current.focus();

      if (dateType === DATE_KEYWORDS.year) {
        validateYear(value);
        setMonthError(false);
        setDayError(false);
      }
      if (dateType === DATE_KEYWORDS.month) {
        validateMonth(value);
        setDayError(false);
      }
      if (dateType === DATE_KEYWORDS.day) {
        validateDay(value);
      }
    }
  };

  const handleUpdate = () => {
    if (validateDate()) {
      const newDate = `${yearValue}.${monthValue}.${dayValue}`;
      if (newDate !== manualPressDate) {
        updatePressInfo({
          pressId: getPressIdFromURL(),
          manualPressDate: newDate,
        });
      }
      changeStatus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      if (validateDate()) {
        yearRef.current?.blur();
        monthRef.current?.blur();
        dayRef.current?.blur();
      }
    }
  };

  useEffect(() => {
    const handleBlur = () => {
      setTimeout(() => {
        if (
          !yearRef.current?.contains(document.activeElement) &&
          !monthRef.current?.contains(document.activeElement) &&
          !dayRef.current?.contains(document.activeElement)
        ) {
          handleUpdate();
        }
        if (!yearValue && !monthValue && !dayValue) {
          changeStatus();
        }
      }, 0);
    };

    document.addEventListener("focusout", handleBlur);
    return () => document.removeEventListener("focusout", handleBlur);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearValue, monthValue, dayValue]);

  useEffect(() => {
    yearRef.current?.focus();
  }, []);

  return (
    <S.Wrapper>
      <S.YearInput
        ref={yearRef}
        value={yearValue}
        onChange={(e) =>
          handleInputChange(e, setYearValue, monthRef, DATE_KEYWORDS.year)
        }
        onKeyDown={handleKeyDown}
        onBlur={validateDate}
        placeholder="YYYY"
        maxLength={4}
        minLength={4}
        type="text"
        $hasError={yearError}
      />
      <S.MonthInput
        ref={monthRef}
        value={monthValue}
        onChange={(e) =>
          handleInputChange(e, setMonthValue, dayRef, DATE_KEYWORDS.month)
        }
        onKeyDown={handleKeyDown}
        onBlur={validateDate}
        placeholder="MM"
        maxLength={2}
        minLength={1}
        type="text"
        $hasError={monthError}
      />
      <S.DayInput
        ref={dayRef}
        value={dayValue}
        onChange={(e) =>
          handleInputChange(e, setDayValue, null, DATE_KEYWORDS.day)
        }
        onKeyDown={handleKeyDown}
        onBlur={validateDate}
        placeholder="DD"
        maxLength={2}
        minLength={1}
        type="text"
        $hasError={dayError}
      />
    </S.Wrapper>
  );
};

export default EditPressDateInput;
