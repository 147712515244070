import React from "react";

const IconKeyCommentRectangle = () => {
  return (
    <svg
      width="44"
      height="18"
      viewBox="0 0 44 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 18L16.6186 2.89217C19.67 0.118197 24.33 0.118194 27.3814 2.89217L44 18H0Z"
        fill="#F0F2F4"
      />
    </svg>
  );
};

IconKeyCommentRectangle.displayName = "KeyCommentRectangle";

export default IconKeyCommentRectangle;
