import styled from "styled-components";

import { GroupShellTitleWrapper } from "@FillInTableContainer/shared/styles/groupShell.style";
import {
  DefaultDeleteTableButton,
  DefaultDeleteTableButtonWrapper,
  DefaultGroupShellWrapper,
  TableContentWrapper,
} from "@FillInTableContainer/shared/styles/table.style";
import { ScrollTableWrapper } from "@FillInTableContainer/shared/styles/tableScroll.style";

export const TableWrapper = styled(TableContentWrapper)``;

export const ScrollWrapper = styled(ScrollTableWrapper)``;

export const ScrollContentsWrapper = styled.div`
  width: fit-content;
`;

export const GroupShellWrapper = styled(DefaultGroupShellWrapper)``;

export const DeleteTableButtonWrapper = styled(
  DefaultDeleteTableButtonWrapper,
)``;

export const DeleteTableButton = styled(DefaultDeleteTableButton)``;

export const TitleWrapper = styled(GroupShellTitleWrapper)``;
