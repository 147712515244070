import { useCallback, useEffect } from "react";

import { DealsSection } from "@/types/deals.types";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { KEY_CLINICAL_TRIALS } from "@FillInTableContainer/components/FillInTable/constants/clinicalTrials.constants";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import {
  DEFAULT_FOCUS_STATUS,
  FocusStatus,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useHandleDealsDrugProfileGroupShell } from "./useHandleDealsDrugProfileGroupShell";

interface UseHandleGroupShellReturn {
  focusStatus: FocusStatus;
  isFocused: boolean;
  isClearGroupShell: boolean;
  isDeleteGroupShell: boolean;
  isAlertGroupShell: boolean;
  isEnableEdit: boolean;
  isLastGroupShell: boolean;
  handleDefaultFocus: () => void;
  handleClickToDeleteGroupShell: () => void;
  handleFocusGroupShell: () => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: DealsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
  groupShellCount: number;
}

export const useHandleDealsGroupShell = ({
  positionPropsInfo,
  groupShellCount,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableIndex,
  } = positionPropsInfo;

  const articleSection = "Deals";
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { validatedInfo, changeValidatedInfo } = useValidatedInTableCreator();

  const {
    handleClickToClearDrugProfileGroupShell,
    handleKeyDownToClearDrugProfileGroupShell,
  } = useHandleDealsDrugProfileGroupShell({ positionPropsInfo });

  const { data: articleData } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );

  const isEnableEdit = articleData
    ? ENABLE_GROUP_TABLE_KEY_LIST[articleSection][groupTableKey].includes(
        articleData.draftArticleStatus,
      )
    : false;
  const isLastGroupShell = groupShellIndex === groupShellCount - 1;

  const isFocused =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === groupShellKey &&
    info.groupShellIndex === groupShellIndex &&
    info.shellKey === null &&
    isEnableEdit;

  const handleDefaultFocus = useCallback(() => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  }, [changeFocusStatus, changeInfo, changeValidatedInfo]);

  const handleFocusGroupShell = () => {
    changeInfo({
      ...positionPropsInfo,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
    changeFocusStatus({ type: "clear", container: "groupShell" });
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const isClearGroupShell =
    isFocused &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    focusStatus.type === "clear" &&
    focusStatus.container === "groupShell";

  const isDeleteGroupShell =
    isFocused &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    focusStatus.type === "delete" &&
    focusStatus.container === "groupShell";

  const isAlertGroupShell =
    validatedInfo.filter(
      (validatedItem) =>
        validatedItem.groupTableKey === groupTableKey &&
        validatedItem.groupTableIndex === groupTableIndex &&
        validatedItem.tableIndex === tableIndex &&
        validatedItem.groupShellKey === groupShellKey &&
        validatedItem.groupShellIndex === groupShellIndex &&
        validatedItem.shellKey === null &&
        validatedItem.shellIndex === null &&
        validatedItem.valueIndex === null &&
        validatedItem.type === "alert" &&
        validatedItem.container === "groupShell",
    ).length > 0;

  const handleClickToDeleteGroupShell = () => {
    switch (info.groupTableKey) {
      case KEY_CLINICAL_TRIALS.drugProfile:
        handleClickToClearDrugProfileGroupShell();
        break;
      default:
        break;
    }
  };

  const handleKeyDownToDeleteGroupShell = (event: KeyboardEvent) => {
    switch (info.groupTableKey) {
      case KEY_CLINICAL_TRIALS.drugProfile:
        handleKeyDownToClearDrugProfileGroupShell(event);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (focusStatus.container !== "groupShell") return;
    window.addEventListener("keydown", handleKeyDownToDeleteGroupShell);

    return () => {
      window.removeEventListener("keydown", handleKeyDownToDeleteGroupShell);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusStatus]);

  return {
    focusStatus,
    isFocused,
    isClearGroupShell,
    isDeleteGroupShell,
    isAlertGroupShell,
    isEnableEdit,
    isLastGroupShell,
    handleDefaultFocus,
    handleFocusGroupShell,
    handleClickToDeleteGroupShell,
  };
};
