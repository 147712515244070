import React from "react";

import { ParsedJsonTableContentType } from "@/types/fillInTableContent.types";

import * as S from "./FillInTableArticleTable.style";

interface Props {
  item: ParsedJsonTableContentType;
  isModal?: boolean;
}

const FillInTableArticleTable = ({ item, isModal }: Props) => {
  const parseHtmlToTags = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const table = doc.querySelector("table");
    if (!table) return;

    const elementsArray: (string | null)[][] = [];

    const rows = table.querySelectorAll("tr");
    rows.forEach((row) => {
      const cells = row.querySelectorAll("td, th");
      const rowElements = Array.from(cells).map(
        (cell) => cell.textContent && cell.textContent.trim(),
      );
      elementsArray.push(rowElements);
    });

    return elementsArray;
  };

  const checkSameColumnEmptyData = (data: (string | null)[][]) => {
    const columnLength = data[0].length;
    const emptyColumnIndex: number[] = [];
    for (let i = 0; i < columnLength; i++) {
      const columnData = data.map((row) => row[i]);
      if (columnData.every((column) => !column)) {
        emptyColumnIndex.push(i);
      }
    }
    const removeEmptyColumnData = data.map((row) =>
      row.filter((_, index) => !emptyColumnIndex.includes(index)),
    );
    return removeEmptyColumnData;
  };

  const tableData = checkSameColumnEmptyData(parseHtmlToTags(item.html) || []);

  return (
    <S.FillInTableArticleTable isModal={isModal}>
      <table>
        <tbody>
          {tableData?.map((data, index) => (
            <tr key={`table-tr-tag-${String(index)}`}>
              {data?.map((dataItem, dataIndex) => {
                return (
                  <td key={`table-td-tag-${String(dataIndex)}`}>
                    {dataItem?.replace(/\s+/g, " ")}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </S.FillInTableArticleTable>
  );
};

export default FillInTableArticleTable;
