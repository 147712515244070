import styled from "styled-components";

import IconCheckedDefault from "@images/pressList/icon-column-check.svg";

export const CheckboxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    background: var(--Color-Common-Bg-Interactive-Secondary-Hovered, #f0f2f4);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
      0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }
`;

export const CheckboxInput = styled.input`
  all: unset;
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: var(--Border-Radius-2, 0.2rem);
  border: 0.15rem solid var(--Color-Bg-Inverse-ToDo, #15d555);
  overflow: hidden;
  cursor: pointer;

  &:checked {
    background: var(--Color-Bg-Inverse-ToDo, #15d555);
    background-image: url(${IconCheckedDefault});
    background-repeat: no-repeat;
    background-position: center;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
