import styled from "styled-components";

export const CopyButton = styled.button`
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;

  &:hover {
    svg {
      path {
        fill: #017abc;
      }
    }
  }

  &:active {
    svg {
      path {
        fill: #015d8e;
      }
    }
  }
`;

export const IconWrapper = styled.div`
  width: 2.4rem;
  height: 2.4rem;
`;
