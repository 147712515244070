import React from "react";

import {
  ArticleDetailCompanyProfileTable,
  ParagraphSectionLayout,
  ParagraphTitle,
  ARTICLE_CONTENTS_COMPANY_PROFILE_PARAGRAPH_TITLE as PARAGRAPH_TITLE,
  ARTICLE_CONTENTS_COMPANY_PROFILE_PARAGRAPH_GROUP_KEY as PARAGRAPH_GROUP_KEY,
  ARTICLE_CONTENTS_COMPANY_PROFILE_SHELL_KEYS as SHELL_KEYS,
  ARTICLE_CONTENTS_COMPANY_PROFILE_SHELL_TITLE as SHELL_TITLE,
  ARTICLE_CONTENTS_COMPANY_PROFILE_PARAGRAPH_CODE as PARAGRAPH_CODE,
  ARTICLE_CONTENTS_COMPANY_PROFILE_SECTION_CODE as SECTION_CODE,
} from "@frontend/common";

import { useCompanyProfileQuery } from "@api/companyProfile/companyProfile";
import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { useCreateCompanyLogoComponentList } from "@EditArticleContainer/shared/hooks/useCreateCompanyLogoComponentList";
import { useGetCompanyNameList } from "@EditArticleContainer/shared/hooks/useGetCompanyNameList";
import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import { useUpdateParagraph } from "@EditArticleContainer/shared/hooks/useUpdateParagraph";
import {
  REF_CODE_VENTURE_CAPITAL_COMPANY_DESCRIPTION,
  REF_CODE_VENTURE_CAPITAL_COMPANY_NAME,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

import * as S from "./VentureCapitalParagraphCompanyProfile.style";

const VentureCapitalParagraphCompanyProfile = () => {
  const { mainCompanyNameList } = useGetCompanyNameList();
  const { data: companyProfileData } =
    useCompanyProfileQuery(mainCompanyNameList);

  const { findTargetCompanyLogo } = useCreateCompanyLogoComponentList();

  const paragraph = useParagraphEditedData(PARAGRAPH_CODE);
  const [updateParagraph, loadingIndex, updateKeyMap] = useUpdateParagraph({
    paragraphCode: PARAGRAPH_CODE,
    sectionCode: SECTION_CODE,
  });

  const companyNameText = createMultiLineText({
    textList: paragraph.text[PARAGRAPH_GROUP_KEY][
      SHELL_KEYS.companyName
    ].value.map((shell) => shell.text ?? ""),
    parseString: (text) => `${text?.trim()}`,
  });
  const companyDescriptionText = createMultiLineText({
    textList: paragraph.text[PARAGRAPH_GROUP_KEY][
      SHELL_KEYS.companyDescription
    ].value.map((shell) => shell.text ?? ""),
    parseString: (text) => `${text?.trim()}`,
  });

  const originalCompanyProfileTableGroupData = companyProfileData.data?.data;

  const companyProfileTableGroupData =
    originalCompanyProfileTableGroupData?.length === 0
      ? [
          {
            companyName: companyNameText,
            summary: companyDescriptionText,
            pressCompanyId: 0,
            companyTypeCategory: "",
            companyTypeBusiness: "",
            ticker: "",
            yearOfEstablishment: "",
            location: "",
            country: "",
            siteUrl: "",
            companyLogoUri: findTargetCompanyLogo(companyNameText) || "",
            countryCode: null,
          },
        ]
      : originalCompanyProfileTableGroupData
          ?.map((tableData) => ({
            ...tableData,
            companyName: companyNameText,
            summary: companyDescriptionText,
          }))
          ?.filter((tableData) => {
            const locationAndCountryValue = [
              tableData.location,
              tableData.country,
            ].join(", ");
            const values = [
              tableData.companyTypeBusiness,
              tableData.companyTypeCategory,
              locationAndCountryValue,
              tableData.summary,
              tableData.ticker,
              tableData.yearOfEstablishment,
            ].filter((value) => !!value);

            return values.length > 2;
          });

  const companyProfileTableData = [
    {
      title: SHELL_TITLE.companyName,
      text: companyNameText,
      refKey: REF_CODE_VENTURE_CAPITAL_COMPANY_NAME,
    },
    {
      title: SHELL_TITLE.companyDescription,
      text: companyDescriptionText,
      refKey: REF_CODE_VENTURE_CAPITAL_COMPANY_DESCRIPTION,
    },
  ];
  return (
    <ParagraphSectionLayout>
      <ParagraphTitle title={PARAGRAPH_TITLE} />

      <S.TableGroupWrapper key={updateKeyMap.get(0)}>
        <S.ParagraphUpdateButton
          onClick={() => updateParagraph(0)}
          disabled={loadingIndex === 0}
        />
        {companyProfileTableGroupData?.map((tableData) => (
          <ArticleDetailCompanyProfileTable
            key={tableData.companyName}
            tableData={tableData}
            isEditMode
            isVentureCapital
          />
        ))}

        {companyProfileTableData?.map((data) => (
          // NOTE : Company Profile Data 저장하기 위함
          <EditableTextContainer
            refKey={data.refKey}
            item={{
              text: data.text,
              labels: [],
            }}
            canTextEdit
            style={{
              display: "none",
            }}
          />
        ))}
      </S.TableGroupWrapper>
    </ParagraphSectionLayout>
  );
};

export default VentureCapitalParagraphCompanyProfile;
