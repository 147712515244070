import { atomFamily, useRecoilState, useRecoilValue } from "recoil";

import { getEntryData } from "@api/entryData/entryData";

import { RECOIL_KEYS } from "./recoilKeys";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

type ArticleId = string;

const entryDataState = atomFamily<ArticleEntryData, ArticleId>({
  key: RECOIL_KEYS.entryDataState,
  default: (articleId) => getEntryData(articleId),
});

export const useEntryDataValue = (articleId: ArticleId) =>
  useRecoilValue(entryDataState(articleId));
export const useEntryDataState = (articleId: ArticleId) =>
  useRecoilState(entryDataState(articleId));
