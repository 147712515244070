import { ValueOf } from "../../../shared";

export type DEFAULT_LOWER_CASE_DEAL_TYPE = ValueOf<typeof DEAL_TYPE_KEYS>;

// NOTE - Deal Type은 대소문자로 인한 버그를 줄이기 위해 모두 소문자로 변환하여 관리합니다.
export const DEAL_TYPE_KEYS = {
  licenseAgreement: "license agreement",
  mergeAndAcquisition: "merge & acquisition",
  strategicRAndDCollaboration: "strategic r&d collaboration",
  strategicSalesCollaboration: "strategic sales collaboration",
  optionAgreementDrug: "option agreement - drug",
  optionsAgreementCompany: "option agreement - company",
  royaltyPurchaseAgreement: "royalty purchase agreement",
  assetPurchaseAgreementDrug: "asset purchase agreement - drug",
  assetPurchaseAgreementOthers: "asset purchase agreement - others",
  strategicInvestment: "strategic investment",
  contractService: "contract service",
  ventureFormation: "venture formation",
  researchGrant: "research grant",
} as const;

export const DISPLAY_DEAL_TYPE_KEYS = {
  [DEAL_TYPE_KEYS.licenseAgreement]: "License",
  [DEAL_TYPE_KEYS.mergeAndAcquisition]: "M&A",
  [DEAL_TYPE_KEYS.strategicRAndDCollaboration]: "R&D Collaboration",
  [DEAL_TYPE_KEYS.strategicSalesCollaboration]: "Sales Collaboration",
  [DEAL_TYPE_KEYS.optionAgreementDrug]: "Option - Drug",
  [DEAL_TYPE_KEYS.optionsAgreementCompany]: "Option - Company",
  [DEAL_TYPE_KEYS.royaltyPurchaseAgreement]: "Royalty Purchase",
  [DEAL_TYPE_KEYS.assetPurchaseAgreementDrug]: "Drug Asset Purchase",
  [DEAL_TYPE_KEYS.assetPurchaseAgreementOthers]: "Other Asset Purchase",
  [DEAL_TYPE_KEYS.strategicInvestment]: "Investment",
  [DEAL_TYPE_KEYS.contractService]: "Contract Service",
  [DEAL_TYPE_KEYS.ventureFormation]: "Venture",
  [DEAL_TYPE_KEYS.researchGrant]: "Research Grant",
} as const;
