import { RegulatorySection } from "@/types/regulatory.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  handleClickToDeleteKeyCommentGroupShell: () => void;
  handleKeyDownToDeleteKeyCommentGroupShell: (event: KeyboardEvent) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: RegulatorySection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useHandleRegulatoryKeyCommentGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const handleClearGroupShell = () => {
    const clearKeyCommentGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        Regulatory: {
          ...entryData.Regulatory,
          "Key Comment": {
            ...entryData.Regulatory?.["Key Comment"],
            Quote: {
              ...entryData.Regulatory?.["Key Comment"]?.Quote,
              value: [],
            },
            Spokesperson: {
              ...entryData.Regulatory?.["Key Comment"]?.Spokesperson,
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    if (info.groupTableKey === "Key Comment") {
      setEntryData(clearKeyCommentGroupShell());
      changeCurrentHistory({
        entryData: clearKeyCommentGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  };

  const handleClickToDeleteKeyCommentGroupShell = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShell();
    }
  };

  const handleKeyDownToDeleteKeyCommentGroupShell = (event: KeyboardEvent) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearGroupShell();
    }
  };

  return {
    handleClickToDeleteKeyCommentGroupShell,
    handleKeyDownToDeleteKeyCommentGroupShell,
  };
};
