import {
  ENTRY_DATA_KEY_COMMENTS_GROUP_TABLE_KEY as KEY_COMMENT,
  ENTRY_DATA_KEY_COMMENTS_SHELL_KEYS as KEY_COMMENT_SHELL_KEYS,
  ENTRY_DATA_KEY_COMMENT_GROUP_SHELL_KEYS as KEY_COMMENT_GROUP_SHELL_KEYS,
  ENTRY_DATA_KEY_COMMENT_TABLE_KEYS as KEY_COMMENT_TABLE_KEYS,
} from "@frontend/common";

import { VentureCapitalGroupTable } from "@/types/ventureCapital.types";
import { TableCreatorTableList } from "@FillInTableContainer/shared/types/tableCreator.types";

interface VentureCapitalKeyCommentLayout {
  groupTableKey: VentureCapitalGroupTable;
  tableList: TableCreatorTableList;
}
export const VENTURE_CAPITAL_KEY_COMMENT_GROUP_TABLE_LAYOUT: VentureCapitalKeyCommentLayout =
  {
    groupTableKey: KEY_COMMENT,
    tableList: [
      {
        tableKey: KEY_COMMENT_TABLE_KEYS.none,
        groupShellList: [
          {
            groupShellKey: KEY_COMMENT_GROUP_SHELL_KEYS.none,
            shellList: [
              {
                shellKey: KEY_COMMENT_SHELL_KEYS.quote,
              },
              {
                shellKey: KEY_COMMENT_SHELL_KEYS.spokesperson,
              },
            ],
          },
        ],
      },
    ],
  };
