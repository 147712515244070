"use client";

import styled from "styled-components";

export const UpcomingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 1200px;
  min-height: calc(100vh - 5.6rem);
  height: 100%;
  padding-bottom: 5.6rem;
`;

export const UpcomingContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  width: 100%;
  max-width: 120rem;
`;

export const UpcomingTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  width: 100%;
`;
export const UpcomingTitle = styled.h1`
  width: 100%;
  height: 5.6rem;
  font-size: 4rem;
  font-weight: 500;
  line-height: 140%;
  color: var(--NEURAL-Gray-090, #1f2837);
  font-family: var(--font-jost);
  text-align: center;
`;
export const UpcomingDateInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  width: 100%;
  height: 3.9rem;
  font-size: 2.6rem;
  font-weight: 500;
  font-family: var(--font-jost);
  color: var(--NEURAL-Gray-090, #1f2837);

  & > span {
    color: var(--NODE-Sky-04, #019df6);
    font-size: 2.6rem;
    font-weight: 500;
    font-family: var(--font-jost);
  }
`;

export const UpcomingDescription = styled.p`
  width: 100%;
  font-size: 2.4rem;
  font-weight: 400;
  font-family: var(--font-jost);
  color: var(--NEURAL-Gray-080, #3c4757);
  line-height: 150%;
  text-align: center;
  padding-top: 2.8rem;
`;
