import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconCheck = ({ width = 24, height = 24, color = "black" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="modality-icon-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1086 6.55806C19.3527 6.80214 19.3527 7.19786 19.1086 7.44194L9.94192 16.6086C9.69784 16.8527 9.30212 16.8527 9.05804 16.6086L4.89137 12.4419C4.64729 12.1979 4.64729 11.8021 4.89137 11.5581C5.13545 11.314 5.53118 11.314 5.77525 11.5581L9.49998 15.2828L18.2247 6.55806C18.4688 6.31398 18.8645 6.31398 19.1086 6.55806Z"
        fill={color}
      />
    </svg>
  );
};

IconCheck.displayName = "Check";
export default IconCheck;
