import styled from "styled-components";

import AIRegenerateButtonDisabledIcon from "@images/editArticle/icon-ai-regenerate-disabled-button.svg";
import AIRegenerateButtonIcon from "@images/editArticle/icon-ai-regenerate-enabled-button.svg";

export const AIRegenerateButton = styled.button`
  width: 2.1rem;
  height: 2.1rem;
  background-size: 100% 100%;
  background-image: url(${AIRegenerateButtonIcon});
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background-image: url(${AIRegenerateButtonDisabledIcon});
  }
`;
