"use client";

import styled, { css } from "styled-components";

import { Alignment, Position } from "./types/tooltip.types";

const alignmentTransformStyle = {
  left: "",
  right: "",
  center: "translateX(-50%)",
};

const positionTransformStyle = {
  top: "rotateX(180deg)",
  bottom: "translateY(50%)",
  left: "rotate(90deg) translateX(-15%)",
  right: "rotate(-90deg) translateX(15%)",
};

const alignmentStyle = {
  left: css`
    left: 0;
  `,
  right: css`
    right: 0;
  `,
  center: css`
    left: 50%;
  `,
};

const positionStyle = {
  top: css`
    top: -0.7rem;
  `,
  bottom: css`
    bottom: -0.25rem;
  `,
  left: css`
    top: 50%;
    right: auto;
    left: -1.45rem;
  `,
  right: css`
    top: 50%;
    left: auto;
    right: -1.45rem;
  `,
};

export const combineTransforms = (alignment: Alignment, position: Position) => {
  if (position === "left" || position === "right")
    return `${positionTransformStyle[position]}`;

  return `${alignmentTransformStyle[alignment]} ${positionTransformStyle[position]}`;
};

export const TooltipWrapper = styled.div`
  position: relative;
`;

export const BreakImageWrapper = styled.div<{
  position: Position;
  alignment: Alignment;
}>`
  position: absolute;
  ${({ alignment }) => alignmentStyle[alignment]}
  ${({ position }) => positionStyle[position]}

  transform: ${({ alignment, position }) =>
    combineTransforms(alignment, position)};
`;
