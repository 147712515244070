interface Props {
  width?: number;
  height?: number;
  fill?: string;
}

const IconExternalLink = ({
  width = 16,
  height = 16,
  fill = "#2A2F37",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66667 3.625C9.32149 3.625 9.04167 3.34518 9.04167 3C9.04167 2.65482 9.32149 2.375 9.66667 2.375H13C13.0853 2.375 13.1666 2.39209 13.2407 2.42304C13.3095 2.4517 13.3742 2.49318 13.4311 2.54748C13.4385 2.55453 13.4457 2.56175 13.4528 2.56915C13.5064 2.62547 13.5475 2.68935 13.5761 2.75725C13.6076 2.83188 13.625 2.91391 13.625 3V6.33333C13.625 6.67851 13.3452 6.95833 13 6.95833C12.6548 6.95833 12.375 6.67851 12.375 6.33333V4.50888L7.33083 9.55305C7.08675 9.79713 6.69102 9.79713 6.44694 9.55305C6.20287 9.30897 6.20287 8.91325 6.44694 8.66917L11.4911 3.625H9.66667ZM4.11111 5.29167C3.98219 5.29167 3.85854 5.34288 3.76738 5.43405C3.67622 5.52521 3.625 5.64885 3.625 5.77778V11.8889C3.625 12.0178 3.67622 12.1415 3.76738 12.2326C3.85854 12.3238 3.98219 12.375 4.11111 12.375H10.2222C10.3511 12.375 10.4748 12.3238 10.566 12.2326C10.6571 12.1415 10.7083 12.0178 10.7083 11.8889V8.55556C10.7083 8.21038 10.9882 7.93056 11.3333 7.93056C11.6785 7.93056 11.9583 8.21038 11.9583 8.55556V11.8889C11.9583 12.3493 11.7754 12.7909 11.4498 13.1165C11.1243 13.4421 10.6827 13.625 10.2222 13.625H4.11111C3.65067 13.625 3.20908 13.4421 2.8835 13.1165C2.55791 12.7909 2.375 12.3493 2.375 11.8889V5.77778C2.375 5.31733 2.55791 4.87575 2.8835 4.55016C3.20908 4.22458 3.65067 4.04167 4.11111 4.04167H7.44444C7.78962 4.04167 8.06944 4.32149 8.06944 4.66667C8.06944 5.01184 7.78962 5.29167 7.44444 5.29167H4.11111Z"
        fill={fill}
      />
    </svg>
  );
};

IconExternalLink.displayName = "ExternalLink";

export default IconExternalLink;
