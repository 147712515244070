"use client";

import styled, { css } from "styled-components";

export const TabMenuButton = styled.button<{ $isFocused: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-2, 0.2rem);
  height: 2.8rem;
  border-radius: 0.4rem;
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  padding: var(--Spacing-4, 0.4rem) var(--Spacing-8, 0.8rem);
  cursor: pointer;
  outline: none;

  &:hover {
    color: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);
    background: var(--Color-Common-Bg-Interactive-Secondary-Hovered, #f0f2f4);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
      0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }
  &:active {
    color: var(--Color-Common-Text-Interactive-Primary-Pressed, #015d8e);
    background: var(--Color-Common-Bg-Interactive-Secondary-Pressed, #e5e7eb);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
      0px 1px 2px 0px rgba(0, 0, 0, 0.16);
  }
  &:disabled {
    color: var(--Color-Common-Text-Disabled, #96a1b0);
    background: var(--Color-Common-Bg-Disabled, #cbd0d8);
  }

  ${({ $isFocused }) =>
    $isFocused &&
    css`
      color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
      background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #e6f6ff);
      box-shadow: none;

      &:hover,
      &:active {
        color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
        background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #e6f6ff);
        box-shadow: none;
      }
    `}
`;
