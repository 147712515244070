import { rootStyles } from "@frontend/common/src/styles";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
	background-color: white;
  }
	article,
	aside,
	details,
	figcaption,
	figure,
	footer,
	header,
	hgroup,
	menu,
	nav,
	section {
		display: block;
	}

	html {
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		text-size-adjust: 100%;
	}

	ol,
	ul,
	li {
		list-style: none;
	}

	blockquote,
	q {
		quotes: none;
	}

	button {
		vertical-align: baseline;
		border: 0;
		background: none;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	blockquote:before,
	blockquote:after,
	q:before,
	q:after {
		content: "";
		content: none;
	}

	a,
	a:active,
	a:focus,
	a:hover,
	a:link,
	a:visited {
		color: ${({ theme }) => theme.color.neural.gray08};
		text-decoration: none;
		font-family: inherit;
	}
	a::before {
		content: "";
		position: absolute;
		font-size: 0;
	}
	* {	
		font-family: var(--font-pretendard);
		font-style: normal;
		font-weight: 400;
		margin: 0;
		padding: 0;
		border: 0;
		box-sizing: border-box;
	}
	html {
		font-size: 62.5%;
	}

	${rootStyles}
`;
export default GlobalStyle;
