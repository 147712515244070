import styled from "styled-components";

import { hideScrollbar } from "@styles/mixin";

export const EditArticleWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 3.2rem);
`;

export const CenterContainer = styled.div`
  width: 100%;
  max-width: 140rem;
  min-width: 100rem;
  height: 4.2rem;
  margin: 0px auto;
  padding: 0 1rem;
`;

export const EditArticleScrollSection = styled.div`
  width: 100%;
  height: calc(100vh - 6.4rem);
  margin-bottom: 3rem;
  ${hideScrollbar}
`;

export const ContentsWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 28rem);
  width: 100rem;
  margin: 0 auto;
`;

export const TopFixInfoWrapper = styled.div`
  margin-top: 4rem;
  padding-top: 0.8rem;
  padding-right: 4rem;
  padding-left: 4rem;
`;

export const RightButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
`;

export const ButtonLeftButtonWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
`;
