import { useEffect } from "react";

import { useArticleContentDataQuery } from "@api/articleContentData/articleContentData";
import { articleContentDataApis } from "@api/articleContentData/articleContentData.service";
import { useSectionCodeToClassification } from "@hooks/useSectionCode";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export const useCreateArticleContent = () => {
  const { data: articleContent } = useArticleContentDataQuery();
  const sectionCode = useSectionCodeToClassification();

  useEffect(() => {
    if (Object.keys(articleContent).length === 0) {
      articleContentDataApis.create(getPressIdFromURL(), sectionCode);
    }
  }, [articleContent, sectionCode]);
};
