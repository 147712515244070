import styled from "styled-components";

import { DefaultDraggableGroupShellWrapper } from "@FillInTableContainer/shared/styles/groupShell.style";

export const DraggableValueWrapper = styled(
  DefaultDraggableGroupShellWrapper,
)``;

export const ModalityShellRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  gap: 0.8rem;
`;

export const ModalityShellColumn = styled.div<{ $isMultipleValue: boolean }>`
  display: flex;
  flex-direction: column;

  flex: 1;
  width: 100%;
  height: 100%;

  &:first-child {
    max-width: 27.4rem;
  }
  &:last-child {
    padding-right: ${({ $isMultipleValue }) =>
      $isMultipleValue ? "5.6rem" : "2.8rem"};
  }
  &.is-not-modality-first-value-true {
    padding-right: 0;
  }
  &.is-not-modality-value-in-first-column-true {
    max-width: none;
  }

  .delete-value-button {
    position: absolute;
    top: 50%;
    left: ${({ $isMultipleValue }) => ($isMultipleValue ? "529px" : "557px")};
    transform: translateY(-12px);
  }

  .value-drag-button {
    position: absolute;
    left: 566px;
  }

  .modality-flex {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .modality-textfield-height {
    height: 100%;
  }
`;
