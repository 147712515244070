import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { Dim } from "@/components/shared/ui/Dim/Dim.style";
import Loader from "@/components/shared/ui/Loader/Loader";
import { queryClient } from "@api/queryClient";
import Button from "@atoms/Button/Button";
import IconXMarkBlack from "@images/common/icon-x-mark-black.svg";
import IconStatusProgress from "@images/pressList/status/icon-status-progress.svg";
import IconStatusDBSaved from "@images/pressList/status/icon-white-db-saved.svg";
import { ROUTE_PATHS } from "@routes/routePath";

import * as S from "./SaveToDBModalContainer.style";

interface Props {
  closeModal: () => void;
  saveToDB: () => Promise<void>;
  isModalOpen: boolean;
  hasNextRoles?: boolean;
}

const SaveToDBModalContainer = ({
  closeModal,
  saveToDB,
  isModalOpen,
  hasNextRoles,
}: Props) => {
  const navigate = useNavigate();

  const handleContinueEditing = async () => {
    await saveToDB();
    closeModal();
  };

  const handleEditingFinished = async () => {
    await saveToDB();

    // NOTE: navigate 의 순서를 동기적으로 보장하기 위해 setTimeout 설정
    setTimeout(() => {
      navigate(ROUTE_PATHS.pressList);
    }, 0);
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") closeModal();
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [closeModal]);

  const handleOverlayClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (e.target === e.currentTarget) closeModal();
  };

  if (!isModalOpen) return null;

  return (
    <S.Overlay onClick={handleOverlayClick}>
      <S.SaveToDBModalWrapper>
        <S.SaveToDBModalCloseButton onClick={closeModal}>
          <img src={IconXMarkBlack} alt="close modal" />
        </S.SaveToDBModalCloseButton>
        <S.SaveToDBModalContents>
          <S.SaveToDBModalTitle>Save data to the DB</S.SaveToDBModalTitle>
          <S.SaveToDBModalText>
            Would you like to save to the database? <br />
            You can publish the article after saving it to the database.
          </S.SaveToDBModalText>
        </S.SaveToDBModalContents>
        <S.SaveToDBModalButtonWrapper>
          {hasNextRoles ? (
            <Button
              buttonStyle="lineStatusProgress"
              onClick={handleContinueEditing}
            >
              Save and Edit Article
              <img src={IconStatusProgress} alt="status progress" />
            </Button>
          ) : (
            <S.CancelButton onClick={closeModal}>Cancel</S.CancelButton>
          )}
          <Button buttonStyle="solidBlue" onClick={handleEditingFinished}>
            Save and Complete
            <img src={IconStatusDBSaved} alt="status saved" />
          </Button>
        </S.SaveToDBModalButtonWrapper>
      </S.SaveToDBModalWrapper>
      {queryClient.isMutating() && (
        <Dim>
          <Loader />
        </Dim>
      )}
    </S.Overlay>
  );
};

export default SaveToDBModalContainer;
