"use client";

import styled, { css } from "styled-components";

export const TableGridColumnLayoutWrapper = styled.div<{
  $columnCount: number;
  $gap?: number;
}>`
  display: grid;
  width: 100%;
  column-gap: ${({ $gap }) => ($gap !== undefined ? `${$gap}px` : "3.2rem")};

  ${({ $columnCount }) =>
    css`
      grid-template-columns: repeat(${$columnCount}, 1fr);
    `}

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    column-gap: 1.6rem;
  }
`;
