"use client";

import styled, { css } from "styled-components";

export const CategoryWrapper = styled.div`
  display: flex;
`;
export const chipsWrapper = styled.div<{
  $isCategory: boolean;
  $isSelected: boolean;
}>`
  display: flex;
  height: 3.2rem;
  width: auto;
  min-width: 3.2rem;
  padding: 0 1.2rem;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  border-radius: 1.6rem;
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: white;
  cursor: pointer;

  ${({ $isSelected, $isCategory }) =>
    $isSelected &&
    $isCategory &&
    css`
      background-color: #d6effe;
    `}

  ${({ $isSelected, $isCategory }) =>
    $isSelected &&
    !$isCategory &&
    css`
      background-color: #ebf7fe;
    `}
`;

export const ChipsCategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const PlusButton = styled.button`
  padding: 0;
  width: 1.2rem;
  height: 1.2rem;
`;

export const ChipsText = styled.p<{
  $isCategory: boolean;
  $isSelected: boolean;
}>`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: var(--font-pretendard);
  font-size: 1.2rem;
  font-style: normal;

  font-weight: ${({ $isCategory }) => ($isCategory ? 600 : 500)};
  line-height: 100%;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      color: #019df6;
      .modality-icon-fill {
        fill: #019df6;
      }
    `}
`;

export const AccordionButton = styled.button`
  padding: 0.6rem;
  cursor: pointer;
`;

export const Test = styled.div`
  svg {
    path {
      stroke: red;
    }
  }
`;
