import {
  SECTION_CODE_ACCORDING_TO_CLASSIFICATION,
  SectionCode,
} from "@frontend/common/src/constants/articleSection";

import { getArticleInfoData } from "@api/articleInfo/useArticleInfo";
import { articlePublishApis } from "@services/articlePublish.service";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { toastFail } from "@utils/toast";

export const usePublish = () => {
  const articleInfo = getArticleInfoData();
  const classification = articleInfo?.data.classificationManual || "";
  const articleSectionType: SectionCode =
    SECTION_CODE_ACCORDING_TO_CLASSIFICATION[classification];

  const publish = async () => {
    try {
      const response = await articlePublishApis.post(
        getPressIdFromURL(),
        articleSectionType,
      );

      if (response.code !== 200) {
        // NOTE : 90003 에러가 발생하는 경우(error로 잡히지 않은 경우), 원인 확인 BE팀과 필요
        toastFail("Failed to publish article.", "bottomLeft");
      }
      return response.data;
    } catch (error) {
      toastFail("Failed to publish article.", "bottomLeft");
    }
  };

  return publish;
};
