import {
  DataSetDropdownList,
  DefaultDataSetType,
} from "../components/TableCreatorListBox/types/tableCreatorDropdown.types";

export const changeFormatDataSetDropdownList: (
  dropdown: DefaultDataSetType[],
  shellKey: string | null,
) => DataSetDropdownList[] = (dropdown, shellKey) => {
  if (!dropdown.length) return [];

  return [
    {
      key: "DATASET",
      shellKey,
      list: dropdown,
    },
  ];
};
