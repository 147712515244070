import {
  formatDealTypeSellerAndBuyerName,
  PARAGRAPH_CODE,
} from "@frontend/common";

import { useSellerAndBuyerByDealTypeQuery } from "@api/sellerAndBuyerByDealType/sellerAndBuyerByDealType";
import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import { DealTypeAndStatusContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/dealTypeAndStatusContent.types";

type Returns = {
  sellerName?: string;
  buyerName?: string;
};
export const useSellerBuyerByDealType = (): Returns => {
  const dealTypeAndStatus = useParagraphEditedData(
    PARAGRAPH_CODE.TY002.dealTypeAndStatus,
  );

  const dealTypes =
    (dealTypeAndStatus as DealTypeAndStatusContent)?.text?.[
      "Deal Type & Status"
    ]?.["Deal Type"]?.value
      ?.map((value) => value.text?.trim() || "")
      ?.flatMap((textGroup) =>
        textGroup
          ?.split(`\n`)
          ?.map((text) =>
            text.startsWith("- ")
              ? text.replace("- ", "").toLowerCase().trim()
              : text.toLowerCase().toLowerCase().trim(),
          ),
      ) || [];

  const { data: sellerBuyerInfo } = useSellerAndBuyerByDealTypeQuery(dealTypes);

  const sellerName = sellerBuyerInfo?.data?.seller;
  const buyerName = sellerBuyerInfo?.data?.buyer;

  if (!dealTypeAndStatus)
    return {
      sellerName: "",
      buyerName: "",
    };

  return {
    sellerName: formatDealTypeSellerAndBuyerName(sellerName),
    buyerName: formatDealTypeSellerAndBuyerName(buyerName),
  };
};
