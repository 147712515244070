import React from "react";

interface Return {
  checkExistNextLine: ({ textFieldRefCurrent }: ArrowEventPrams) => {
    isHaveNextBottomCaretLine: boolean;
    isHaveNextTopCaretLine: boolean;
  };
  updateTextfieldCaretPosition: (
    event: React.KeyboardEvent<HTMLDivElement>,
    position: "head" | "end",
  ) => void;
  removeTextfieldCaret: () => void;
}

interface ArrowEventPrams {
  textFieldRefCurrent: Element;
}

const useCheckIsActiveDropdownKeyboardNavigation = (): Return => {
  const returnCurrentCaretPosition = (
    textfieldTopPosition: number,
    textfieldBottomPosition: number,
  ) => {
    const selection = window.getSelection();
    // Caret이 없는 경우
    if (!selection?.anchorNode)
      return {
        caretBottomLineNumber: 0,
        caretTopLineNumber: 0,
      };

    const currentCaretSelection = selection?.getRangeAt(0);

    // 입력 값이 없는 경우 true 값으로 설정해주기 위해 true를 반환할 수 있는 값으로 임의 설정
    const currentCaretBottomPosition =
      currentCaretSelection?.getBoundingClientRect().bottom || 0;
    const currentCaretTopPosition =
      currentCaretSelection?.getBoundingClientRect().top ||
      textfieldTopPosition + 100;

    // Textfield 한 줄에 약 15px이므로 해당 값을 활용하여 현재 위치한 line index 계산
    const caretBottomLineNumber = Math.floor(
      (textfieldBottomPosition - currentCaretBottomPosition) / 15,
    );

    const caretTopLineNumber = Math.floor(
      (currentCaretTopPosition - textfieldTopPosition) / 15,
    );

    return {
      caretBottomLineNumber,
      caretTopLineNumber,
    };
  };

  const checkExistNextLine = ({ textFieldRefCurrent }: ArrowEventPrams) => {
    const textfieldTopPosition =
      textFieldRefCurrent.getBoundingClientRect().top;
    const textfieldBottomPosition =
      textFieldRefCurrent.getBoundingClientRect().bottom;

    const { caretBottomLineNumber, caretTopLineNumber } =
      returnCurrentCaretPosition(textfieldTopPosition, textfieldBottomPosition);

    const isHaveNextBottomCaretLine = caretBottomLineNumber - 1 >= 0;
    const isHaveNextTopCaretLine = caretTopLineNumber - 1 >= 0;

    return {
      isHaveNextBottomCaretLine,
      isHaveNextTopCaretLine,
    };
  };

  const removeTextfieldCaret = () => {
    const selection = window.getSelection();

    selection?.removeAllRanges();
  };

  const updateTextfieldCaretPosition = (
    event: React.KeyboardEvent<HTMLDivElement>,
    position: "head" | "end",
  ) => {
    const selection = window.getSelection();

    const isHeadPosition = position === "head";

    const range = document.createRange();
    range.selectNodeContents(event.currentTarget.childNodes[0]);
    range.collapse(isHeadPosition);

    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  return {
    removeTextfieldCaret,
    checkExistNextLine,
    updateTextfieldCaretPosition,
  };
};

export default useCheckIsActiveDropdownKeyboardNavigation;
