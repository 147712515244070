import React, { createContext, useCallback, useContext, useMemo } from "react";

import { ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS } from "@frontend/common";

import { useDesDealTypeDropdownDisplayNameQuery } from "@api/dropDownList/dropdownList";

export const DEFAULT_TABLE_CREATOR_HISTORY: { [key: string]: string } = {};

export const DesDealTypeDropdownDisplayNameContext = createContext<{
  dealTypeDropdownDisplayName: { [key: string]: string };
  changeDealTypeTextToDisplayName: (
    shellKey: string | null,
    currentInputDealType: string,
  ) => string;
}>({
  dealTypeDropdownDisplayName: {},
  changeDealTypeTextToDisplayName: () => "",
});

interface Props {
  children: React.ReactNode;
}

export const DesDealTypeDropdownDisplayNameProvider = ({ children }: Props) => {
  const { data: dealTypeDropdownDisplayName } =
    useDesDealTypeDropdownDisplayNameQuery();

  const getCurrentDealTypeDropdownDisplayName = useCallback(
    (currentDealType: string) => {
      return dealTypeDropdownDisplayName.data[currentDealType];
    },
    [dealTypeDropdownDisplayName],
  );

  const changeDealTypeTextToDisplayName = useCallback(
    (shellKey: string | null, currentInputDealType: string) => {
      if (shellKey !== ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS.dealType)
        return currentInputDealType;

      return (
        getCurrentDealTypeDropdownDisplayName(currentInputDealType) ||
        currentInputDealType
      );
    },
    [getCurrentDealTypeDropdownDisplayName],
  );

  const currentDealTypeDisplayName = useMemo(
    () => ({
      dealTypeDropdownDisplayName: dealTypeDropdownDisplayName.data,
      changeDealTypeTextToDisplayName,
    }),
    [dealTypeDropdownDisplayName, changeDealTypeTextToDisplayName],
  );
  return (
    <DesDealTypeDropdownDisplayNameContext.Provider
      value={currentDealTypeDisplayName}
    >
      {children}
    </DesDealTypeDropdownDisplayNameContext.Provider>
  );
};

export const useDesDealTypeDropdownDisplayNameValue = () => {
  const { dealTypeDropdownDisplayName, changeDealTypeTextToDisplayName } =
    useContext(DesDealTypeDropdownDisplayNameContext);

  return { dealTypeDropdownDisplayName, changeDealTypeTextToDisplayName };
};
