"use client";

import styled from "styled-components";

export const DealTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  background-color: #fff;
  padding-top: 1.6rem;
`;

export const TitleLogo = styled.div`
  background-color: #e6f6ff;
  border-radius: 50%;
  & svg {
    border-radius: 50%;
  }
`;

export const DealTitle = styled.h1`
  color: #2a2f37;
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 120%;

  @media only screen and (min-width: 1px) and (max-width: 989px) {
    font-size: 2rem;
  }
`;
