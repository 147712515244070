/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS,
  MERGE_ACQUISITION_GROUP_SHELL_KEYS,
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
} from "@frontend/common";

import { RightsScope } from "@/types/deals.types";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { createRandomString } from "@organisms/TableCreatorComment/components/CommentModalContainer/components/CommentModalMessageComposer/utils/createRandomString";

import { EXPEDITED_PROGRAM } from "./expeditedProgramKey.constants";

import type { ArticleEntryData } from "@/types/articleEntryData.types";
import type { EndpointsValue, ArmValue } from "@/types/clinicalTrials.types";
import type { ApprovalProcessType } from "@/types/regulatory.types";

export const TABLE_CREATOR_GROUP_SHELL = {
  "Clinical Trials": [
    {
      key: "Drug Name",
      title: "Drug Name",
      isRequired: true,
      isReviseGroupShell: false,
      isGroupShellScroll: false,
      isSeparatedShellTitleWithContents: false,
    },
    {
      key: CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign1,
      title: "Clinical Design-1",
      isRequired: false,
      isReviseGroupShell: false,
      isGroupShellScroll: false,
      isSeparatedShellTitleWithContents: false,
    },
    {
      key: CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign2,
      title: "Clinical Design-2",
      isRequired: false,
      isReviseGroupShell: true,
      isGroupShellScroll: false,
      isSeparatedShellTitleWithContents: false,
    },
    {
      key: "Endpoints Table Information",
      title: "",
      isRequired: false,
      isReviseGroupShell: true,
      isGroupShellScroll: false,
      isSeparatedShellTitleWithContents: true,
    },
  ],
  "Clinical Trial Status": [
    {
      key: "Drug Name",
      title: "Drug Name",
      isRequired: true,
      isReviseGroupShell: false,
      isGroupShellScroll: false,
      isSeparatedShellTitleWithContents: false,
    },
  ],
  Regulatory: [
    {
      key: "Drug Name",
      title: "Drug Name",
      isRequired: true,
      isReviseGroupShell: false,
      isGroupShellScroll: false,
      isSeparatedShellTitleWithContents: false,
    },
    {
      key: "Approval Process",
      title: "",
      isRequired: true,
      isReviseGroupShell: true,
      isGroupShellScroll: false,
      isSeparatedShellTitleWithContents: true,
    },
  ],
  [EXPEDITED_PROGRAM]: [
    {
      key: "Drug Name",
      title: "Drug Name",
      isRequired: true,
      isReviseGroupShell: false,
      isGroupShellScroll: false,
      isSeparatedShellTitleWithContents: false,
    },
  ],
  Deals: [
    {
      key: "Rights Scope",
      title: "Rights Scope",
      description: "(Licensor/Originator -> Licensee/Partner)",
      isRequired: false,
      isReviseGroupShell: true,
      isGroupShellScroll: false,
      isSeparatedShellTitleWithContents: false,
    },
    {
      key: LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS.financialTerms,
      title: "Main Financial Terms",
      description: "(Licensee/Partner -> Licensor/Originator)",
      isRequired: false,
      isReviseGroupShell: false,
      isGroupShellScroll: false,
      isSeparatedShellTitleWithContents: false,
    },
    {
      key: MERGE_ACQUISITION_GROUP_SHELL_KEYS.financialTerms,
      title: "Financial Terms",
      isRequired: false,
      isReviseGroupShell: false,
      isGroupShellScroll: false,
      isSeparatedShellTitleWithContents: false,
    },
    {
      key: "Drug Name",
      title: "Drug Name",
      isRequired: false,
      isReviseGroupShell: false,
      isGroupShellScroll: false,
      isSeparatedShellTitleWithContents: false,
    },
    {
      key: "Other Financial Terms",
      title: "Other Financial Terms",
      isRequired: false,
      isReviseGroupShell: false,
      isGroupShellScroll: false,
      isSeparatedShellTitleWithContents: false,
    },
  ],
  [SECTION_KEYS.ventureCapital]: [
    {
      key: "Drug Name",
      title: "Drug Name",
      isRequired: false,
      isReviseGroupShell: false,
      isGroupShellScroll: false,
      isSeparatedShellTitleWithContents: false,
    },
  ],
};

export const GROUP_SHELL_PATH_TO_ADD_GROUP_SHELL: Record<
  string,
  (
    data: ArticleEntryData,
    tableIndex: number,
    groupShellIndex: number,
  ) => unknown[]
> = {
  "Endpoints Table Information": (
    data: ArticleEntryData,
    tableIndex: number,
    groupShellIndex: number,
  ) =>
    data?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[tableIndex]?.[
      "Endpoints Table Information"
    ] || [],
  "Approval Process": (
    data: ArticleEntryData,
    tableIndex: number,
    groupShellIndex: number,
  ) => {
    return (
      data?.Regulatory?.["Approval Process Contents"]?.["Approval Process"] ||
      []
    );
  },
  "Rights Scope": (
    data: ArticleEntryData,
    tableIndex: number,
    groupShellIndex: number,
  ) => {
    return data?.Deals?.["Licensee Table"]?.[tableIndex]["Rights Scope"] || [];
  },
};

export const BASIC_DATA_FOR_ADDING_GROUP_SHELLS: Record<
  string,
  Record<
    string,
    (
      data: ArticleEntryData,
    ) => EndpointsValue | ApprovalProcessType | RightsScope
  >
> = {
  "Clinical Trials": {
    "Endpoints Table Information": (data: ArticleEntryData) => {
      const createAttributionId = () => createRandomString(8);
      const ApprovalProcessGroupShellData =
        data["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[0][
          "Endpoints Table Information"
        ]?.[0];
      return {
        "Endpoint Classification": {
          value: [],
          options:
            ApprovalProcessGroupShellData?.["Endpoint Classification"].options,
          attributionId: createAttributionId(),
        },
        "Endpoint Name including Measurement Timing": {
          value: [],
          options:
            ApprovalProcessGroupShellData?.[
              "Endpoint Name including Measurement Timing"
            ]?.options,
          attributionId: createAttributionId(),
        },
        "Endpoint Group Information": [
          {
            "Group Name": {
              value: [],
              options:
                ApprovalProcessGroupShellData?.[
                  "Endpoint Group Information"
                ]?.[0]["Group Name"].options,
              attributionId: createAttributionId(),
            },
            "Group Data": {
              value: [],
              options:
                ApprovalProcessGroupShellData?.[
                  "Endpoint Group Information"
                ]?.[0]["Group Data"].options,
              attributionId: createAttributionId(),
            },
            "P-Value": {
              value: [],
              options:
                ApprovalProcessGroupShellData?.[
                  "Endpoint Group Information"
                ]?.[0]["P-Value"].options,
              attributionId: createAttributionId(),
            },
          },
        ],
      } as EndpointsValue;
    },
  },
  Regulatory: {
    "Approval Process": (data: ArticleEntryData) => {
      const createAttributionId = () => createRandomString(8);
      const ApprovalProcessGroupShellData =
        data.Regulatory?.["Approval Process Contents"]?.[
          "Approval Process"
        ]?.[0];

      return {
        "Update Date": {
          value: [],
          options: ApprovalProcessGroupShellData?.["Update Date"].options,
          attributionId: createAttributionId(),
        },
        "Marketing Status": {
          value: [],
          options: ApprovalProcessGroupShellData?.["Marketing Status"].options,
          attributionId: createAttributionId(),
        },
        "Authorized Company": {
          value: [],
          options:
            ApprovalProcessGroupShellData?.["Authorized Company"]?.options,
          attributionId: createAttributionId(),
        },
        "Regulatory Agency": {
          value: [],
          options:
            ApprovalProcessGroupShellData?.["Regulatory Agency"]?.options,
          attributionId: createAttributionId(),
        },
        "Application Type": {
          value: [],
          options: ApprovalProcessGroupShellData?.["Application Type"]?.options,
          attributionId: createAttributionId(),
        },
        "Indication on Label": {
          value: [],
          options:
            ApprovalProcessGroupShellData?.["Indication on Label"]?.options,
          attributionId: createAttributionId(),
        },
        "Dosage and Administration on Label": {
          value: [],
          options:
            ApprovalProcessGroupShellData?.[
              "Dosage and Administration on Label"
            ]?.options,
          attributionId: createAttributionId(),
        },
      } as ApprovalProcessType;
    },
  },
  Deals: {
    "Rights Scope": (data: ArticleEntryData) => {
      const createAttributionId = () => createRandomString(8);
      const rightScopeGroupShellData =
        data.Deals?.["Licensee Table"]?.[0]["Rights Scope"]?.[0];

      return {
        Drug: {
          value: [],
          options: rightScopeGroupShellData?.Drug.options,
          attributionId: createAttributionId(),
        },
        Indication: {
          value: [],
          options: rightScopeGroupShellData?.Indication.options,
          attributionId: createAttributionId(),
        },
        Exclusivity: {
          value: [],
          options: rightScopeGroupShellData?.Exclusivity.options,
          attributionId: createAttributionId(),
        },
        Territory: {
          value: [],
          options: rightScopeGroupShellData?.Territory.options,
          attributionId: createAttributionId(),
        },
      } as RightsScope;
    },
  },
};

type EnableEditGroupShellStatus = {
  "Clinical Trials": {
    [key in string]: string[];
  };
};

export const ENABLE_EDIT_GROUP_SHELL_STATUS_LIST: EnableEditGroupShellStatus = {
  [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
    [CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign1]: [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgressHalf,
    ],
    [CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign2]: [
      DRAFT_STATUS_CODE.dbSavedHalf,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
  },
};
