import styled, { css } from "styled-components";

export const PressListTableHead = styled.thead`
  height: 4.4rem;
`;

export const TableRow = styled.tr``;

export const TableHeadShell = styled.th`
  padding: 0.5rem 0.8rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.neural.gray03};
  vertical-align: middle;

  ul {
  }

  &:first-child {
    li {
      font-size: 1.1rem;
      font-weight: 500;

      strong {
        font-size: 1.1rem;
        font-weight: 500;
      }
    }
  }
`;

export const TableHeadShellContents = styled.ul<{ isOrdering: boolean }>`
  display: flex;
  align-items: top;
  gap: 0.6rem;
  width: 100%;
  height: 100%;
  cursor: pointer;

  ${({ isOrdering }) =>
    isOrdering &&
    css`
      color: ${({ theme }) => theme.color.node.blue};
    `}
`;

export const TableHeadShellTitle = styled.li<{ isOrdering: boolean }>`
  height: 100%;
  font-weight: 600;
  font-size: 0.8rem;
  text-align: left;
  line-height: 1.3rem;

  strong {
    display: block;
    font-weight: 600;
    font-size: 1.3rem;
    color: ${({ theme }) => theme.color.neural.gray08};
  }
  &:hover,
  &:active {
    strong {
      color: ${({ theme }) => theme.color.node.blue};
    }
  }

  ${({ isOrdering }) =>
    isOrdering &&
    css`
      strong {
        color: ${({ theme }) => theme.color.node.blue};
      }
    `}
`;

export const OrderingIcon = styled.li``;
