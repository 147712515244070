import styled from "styled-components";

export const DragButton = styled.div`
  cursor: pointer;
`;

export const HiddenBox = styled.div`
  width: 2.8rem;
  height: 2.4rem;
  background: transparent;
`;
