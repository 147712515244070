import {
  ArticleEntryData,
  VentureCapital,
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_INVESTORS_GROUP_TABLE_KEY as GROUP_TABLE_KEY,
  ENTRY_DATA_INVESTORS_SHELL_KEYS as SHELL_KEYS,
} from "@frontend/common";

export const removeEmptyInvestorName = (
  entryData: ArticleEntryData,
): {
  [SECTION_KEYS.ventureCapital]: Partial<VentureCapital>;
} => {
  return {
    [SECTION_KEYS.ventureCapital]: {
      ...entryData[SECTION_KEYS.ventureCapital],
      [GROUP_TABLE_KEY]: {
        ...entryData?.[SECTION_KEYS.ventureCapital]?.[GROUP_TABLE_KEY],
        [SHELL_KEYS.investorName]: {
          ...entryData?.[SECTION_KEYS.ventureCapital]?.[GROUP_TABLE_KEY]?.[
            SHELL_KEYS.investorName
          ],
          value:
            entryData?.[SECTION_KEYS.ventureCapital]?.[GROUP_TABLE_KEY]?.[
              SHELL_KEYS.investorName
            ]?.value.filter((item) => !!item.text) || [],
          options:
            entryData?.[SECTION_KEYS.ventureCapital]?.[GROUP_TABLE_KEY]?.[
              SHELL_KEYS.investorName
            ]?.options || [],
        },
      },
    },
  };
};
