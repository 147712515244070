import React, { createContext, useContext, useMemo, useState } from "react";

import { GroupTableKeyType } from "../types/tableCreator.types";

export interface PositionInfo {
  groupTableKey: GroupTableKeyType | "";
  groupTableIndex: number;
  tableKey: string | null;
  tableIndex: number;
  groupShellSectionKey?: string | null;
  groupShellSectionIndex?: number | null;
  groupShellKey: string | null;
  groupShellIndex: number | null;
  subGroupShellKey: string | null;
  subGroupShellIndex: number | null;
  shellKey: string | null;
  shellIndex: number | null;
  valueIndex: number | null;
}

export const DEFAULT_POSITION_INFO: PositionInfo = {
  groupTableKey: "",
  groupTableIndex: 0,
  tableKey: "",
  tableIndex: 0,
  groupShellSectionKey: "",
  groupShellSectionIndex: 0,
  groupShellKey: "",
  groupShellIndex: 0,
  subGroupShellKey: "",
  subGroupShellIndex: 0,
  shellKey: "",
  shellIndex: 0,
  valueIndex: 0,
};

export const TableCreatorPositionInfoContext = createContext<{
  info: PositionInfo;
  changeInfo: (newInfo: PositionInfo) => void;
}>({
  info: DEFAULT_POSITION_INFO,
  changeInfo: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const TableCreatorPositionInfoProvider = ({ children }: Props) => {
  const [info, setInfo] = useState<PositionInfo>(DEFAULT_POSITION_INFO);

  const changeInfo = (newInfo: PositionInfo): void => {
    setInfo(newInfo);
  };

  const positionInfoWrapper = useMemo(() => ({ info, changeInfo }), [info]);
  return (
    <TableCreatorPositionInfoContext.Provider value={positionInfoWrapper}>
      {children}
    </TableCreatorPositionInfoContext.Provider>
  );
};

export const useTableCreatorPositionInfo = () => {
  const { info, changeInfo } = useContext(TableCreatorPositionInfoContext);

  return { info, changeInfo };
};
