import React, { useCallback, useEffect, useRef } from "react";

import { useTableCreatorWrapperPositionInfo } from "@FillInTableContainer/shared/context/TableCreatorWrapperPositionContext";

interface UseHandleTableCreatorWrapperPositionInfoReturns {
  tableCreatorWrapperRef: React.RefObject<HTMLDivElement>;
}

export const useHandleTableCreatorWrapperPositionInfo =
  (): UseHandleTableCreatorWrapperPositionInfoReturns => {
    const tableCreatorWrapperRef = useRef<HTMLDivElement>(null);
    const columnWidth = localStorage.getItem("columnWidth");

    const { changeWrapperPositionInfo } = useTableCreatorWrapperPositionInfo();

    const handleChangeWrapperPosition = useCallback(() => {
      if (tableCreatorWrapperRef.current) {
        const tableCreatorWrapperRect =
          tableCreatorWrapperRef.current.getBoundingClientRect();

        changeWrapperPositionInfo({
          width: tableCreatorWrapperRect.width,
          height: tableCreatorWrapperRect.height,
          x: tableCreatorWrapperRect.x,
          y: tableCreatorWrapperRect.y,
        });
      }
    }, [changeWrapperPositionInfo]);

    useEffect(() => {
      handleChangeWrapperPosition();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnWidth]);

    useEffect(() => {
      window.addEventListener("resize", handleChangeWrapperPosition);

      return () => {
        window.removeEventListener("resize", handleChangeWrapperPosition);
      };
    }, [handleChangeWrapperPosition]);

    return {
      tableCreatorWrapperRef,
    };
  };
