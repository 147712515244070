import { atomFamily, useRecoilState, useRecoilValue } from "recoil";

import { RECOIL_KEYS } from "@stores/recoilKeys";

import { DefaultDataSetType } from "../components/TableCreatorListBox/types/tableCreatorDropdown.types";

const datasetDropdownState = atomFamily<DefaultDataSetType, string>({
  key: RECOIL_KEYS.dataset,
  default: {},
});

export const useDatasetDropdownValue = (datasetKey: string) =>
  useRecoilValue(datasetDropdownState(datasetKey));
export const useDatasetDropdownState = (datasetKey: string) =>
  useRecoilState(datasetDropdownState(datasetKey));
