import { useCallback } from "react";

import {
  DEFAULT_FOCUS_STATUS,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useCheckDisabledDelete } from "@FillInTableContainer/shared/hooks/useCheckDisabledDelete";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { TableInfoProps } from "../ClinicalTrialsTableCreatorTable";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorTableReturn {
  handleClickEndpointsDeleteButton: () => void;
  handleKeyDownToDeleteEndpointsTable: (event: KeyboardEvent) => void;
}

export const useHandleClinicalTrialsEndpointsTable = (
  tablePropsInfo: TableInfoProps,
): UseHandleTableCreatorTableReturn => {
  const { groupTableKey, tableKey, groupTableIndex, tableIndex } =
    tablePropsInfo;

  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const articleSection = "Clinical Trials";

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { checkDisabledDeleteTable } = useCheckDisabledDelete();

  const endpointsTableLength =
    entryData[articleSection]?.["Clinical Endpoints (Table)"]?.length || 0;

  const handleClearTable = useCallback(() => {
    const clearEndpointsTable = (): ArticleEntryData => {
      return {
        ...entryData,
        "Clinical Trials": {
          ...entryData["Clinical Trials"],
          "Clinical Endpoints (Table)": entryData["Clinical Trials"]?.[
            "Clinical Endpoints (Table)"
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                "Endpoints Table Information": tableInfo?.[
                  "Endpoints Table Information"
                ]?.map((groupShellInfo) => {
                  return {
                    ...groupShellInfo,
                    "Endpoint Classification": {
                      ...groupShellInfo?.["Endpoint Classification"],
                      value: [],
                    },
                    "Endpoint Name including Measurement Timing": {
                      ...groupShellInfo?.[
                        "Endpoint Name including Measurement Timing"
                      ],
                      value: [],
                    },
                    "Endpoint Group Information": groupShellInfo?.[
                      "Endpoint Group Information"
                    ]?.map((shellInfo) => {
                      return {
                        ...shellInfo,
                        "Group Name": {
                          ...shellInfo?.["Group Name"],
                          value: [],
                        },
                        "Group Data": {
                          ...shellInfo?.["Group Data"],
                          value: [],
                        },
                        "P-Value": {
                          ...shellInfo?.["P-Value"],
                          value: [],
                        },
                      };
                    }),
                  };
                }),
                "Endpoints Table Notes": {
                  ...tableInfo["Endpoints Table Notes"],
                  value: [],
                },
                "Endpoints Table Title": {
                  ...tableInfo["Endpoints Table Title"],
                  value: [],
                },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    if (info.groupTableKey === "Clinical Endpoints (Table)") {
      setEntryData(clearEndpointsTable());
      changeCurrentHistory({
        entryData: clearEndpointsTable(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      if (endpointsTableLength > 1 && !checkDisabledDeleteTable()) {
        changeFocusStatus({ type: "delete", container: "table" });
      } else {
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
      }
    }
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    checkDisabledDeleteTable,
    endpointsTableLength,
    entryData,
    groupTableIndex,
    groupTableKey,
    info.groupTableKey,
    info.tableIndex,
    setEntryData,
    tableIndex,
    tableKey,
  ]);

  const handleDeleteTable = useCallback(() => {
    if (checkDisabledDeleteTable()) {
      return;
    }

    if (endpointsTableLength > 1) {
      changeFocusStatus(DEFAULT_FOCUS_STATUS);

      const deleteEndpoints = (): ArticleEntryData => {
        return {
          ...entryData,
          "Clinical Trials": {
            ...entryData["Clinical Trials"],
            "Clinical Endpoints (Table)": entryData["Clinical Trials"]?.[
              "Clinical Endpoints (Table)"
            ]?.filter(
              (_, tableInfoIndex) => tableInfoIndex !== info.tableIndex,
            ),
          },
        };
      };

      if (info.groupTableKey === "Clinical Endpoints (Table)") {
        setEntryData(deleteEndpoints());
        changeCurrentHistory({
          entryData: deleteEndpoints(),
          changedDataInfo: {
            target: "table",
            action: "delete",
            position: {
              groupTableKey,
              groupTableIndex,
              tableKey,
              tableIndex,
              groupShellKey: null,
              groupShellIndex: null,
              shellKey: null,
              shellIndex: null,
              valueIndex: null,
              value: null,
            },
          },
        });
        changeInfo(DEFAULT_POSITION_INFO);
      }
    }
  }, [
    endpointsTableLength,
    entryData,
    groupTableIndex,
    groupTableKey,
    info,
    tableIndex,
    tableKey,
    setEntryData,
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    checkDisabledDeleteTable,
  ]);

  const handleClickEndpointsDeleteButton = () => {
    if (focusStatus.type === "clear") handleClearTable();
    if (focusStatus.type === "delete") handleDeleteTable();
  };

  const handleKeyDownToDeleteEndpointsTable = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearTable();
      }
      if (focusStatus.type === "delete" && event.key === "Delete") {
        handleDeleteTable();
      }
      changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    },
    [
      focusStatus.type,
      changeValidatedInfo,
      handleClearTable,
      handleDeleteTable,
    ],
  );

  return {
    handleClickEndpointsDeleteButton,
    handleKeyDownToDeleteEndpointsTable,
  };
};
