import React, { forwardRef, useId } from "react";

import * as S from "./ImageInfoInput.style";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  placeholder?: string;
}

const ImageInfoInput = forwardRef(
  (
    { placeholder, label, ...rest }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const inputId = useId();
    return (
      <S.InputWrapper htmlFor={inputId}>
        {label}
        <input id={inputId} placeholder={placeholder} {...rest} ref={ref} />
      </S.InputWrapper>
    );
  },
);

export default ImageInfoInput;
