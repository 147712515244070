import React from "react";

import {
  ParagraphSectionLayout,
  ParagraphTitle,
} from "@frontend/common/src/components/ArticleContent";

import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import { useUpdateParagraph } from "@EditArticleContainer/shared/hooks/useUpdateParagraph";

import ApprovalProcessAdditionalInformationTable from "./components/ApprovalProcessAdditionalInformationTable/ApprovalProcessAdditionalInformationTable";
import ApprovalProcessTable from "./components/ApprovalProcessTable/ApprovalProcessTable";
import * as S from "./RegulatoryParagraphApprovalProcess.style";

const PARAGRAPH_TITLE = "Approval Process";
const PARAGRAPH_CODE = "TC213";

const RegulatoryParagraphApprovalProcess = () => {
  const paragraph = useParagraphEditedData(PARAGRAPH_CODE);
  const [updateApprovalProcessTable, loadingParagraphIndex] =
    useUpdateParagraph({
      paragraphCode: PARAGRAPH_CODE,
      sectionCode: "TY003",
    });

  if (!paragraph) return null;

  const approvalProcessData =
    paragraph.text["Approval Process"]["Approval Process"];
  const additionalInformationData =
    paragraph.text["Approval Process"][
      "Additional Information on the Approval Process"
    ];
  return (
    <ParagraphSectionLayout>
      <ParagraphTitle title={PARAGRAPH_TITLE} />
      <S.ParagraphContentsWrapper>
        <S.ParagraphUpdateButton
          disabled={loadingParagraphIndex === 1}
          onClick={() => updateApprovalProcessTable(1)}
        />
        <S.TableLayout>
          {approvalProcessData.map((tableData, tableIndex) => (
            <ApprovalProcessTable
              key={`${paragraph.updateDt}-${String(tableIndex)}`}
              tableData={tableData}
              tableIndex={tableIndex}
            />
          ))}
          <ApprovalProcessAdditionalInformationTable
            tableData={additionalInformationData}
          />
        </S.TableLayout>
      </S.ParagraphContentsWrapper>
    </ParagraphSectionLayout>
  );
};

export default RegulatoryParagraphApprovalProcess;
