import { useCallback } from "react";

import {
  DEFAULT_FOCUS_STATUS,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useCheckDisabledDelete } from "@FillInTableContainer/shared/hooks/useCheckDisabledDelete";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { TableInfoProps } from "../ClinicalTrialStatusTableCreatorTable";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorTableReturn {
  handleClickClinicalTrialDesign1DeleteButton: () => void;
  handleKeyDownToDeleteClinicalTrialDesign1Table: (
    event: KeyboardEvent,
  ) => void;
}

export const useHandleClinicalTrialStatusClinicalTrialDesign1Table = (
  tablePropsInfo: TableInfoProps,
): UseHandleTableCreatorTableReturn => {
  const { groupTableKey, tableKey, groupTableIndex, tableIndex } =
    tablePropsInfo;

  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const articleSection = "Clinical Trial Status";

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { checkDisabledDeleteTable } = useCheckDisabledDelete();

  const clinicalTrialDesign1TableLength =
    entryData[articleSection]?.["Clinical Trial Design-1"]?.length || 0;

  const handleClearTable = useCallback(() => {
    const clearDrugProfile = (): ArticleEntryData => {
      return {
        ...entryData,
        "Clinical Trial Status": {
          ...entryData["Clinical Trial Status"],
          "Clinical Trial Design-1": entryData["Clinical Trial Status"]?.[
            "Clinical Trial Design-1"
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                "Clinical Design Phase": {
                  ...tableInfo?.["Clinical Design Phase"],
                  value: [],
                },

                "Inclusion Criteria - Diagnostic": {
                  ...tableInfo?.["Inclusion Criteria - Diagnostic"],
                  value: [],
                },
                "Inclusion Criteria - Detail": {
                  ...tableInfo?.["Inclusion Criteria - Detail"],
                  value: [],
                },

                "Total Patients Number": {
                  ...tableInfo?.["Total Patients Number"],
                  value: [],
                },
                "Trial Name": {
                  ...tableInfo?.["Trial Name"],
                  value: [],
                },
                "NCT Name": {
                  ...tableInfo?.["NCT Name"],
                  value: [],
                },
                "Trial Intervention": [],
              };
            }
            return tableInfo;
          }),
        },
      };
    };

    if (info.groupTableKey === "Clinical Trial Design-1") {
      setEntryData(clearDrugProfile());
      changeCurrentHistory({
        entryData: clearDrugProfile(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      if (clinicalTrialDesign1TableLength > 1 && !checkDisabledDeleteTable()) {
        changeFocusStatus({ type: "delete", container: "table" });
      } else {
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
      }
    }
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    checkDisabledDeleteTable,
    clinicalTrialDesign1TableLength,
    entryData,
    groupTableIndex,
    groupTableKey,
    info.groupTableKey,
    info.tableIndex,
    setEntryData,
    tableIndex,
    tableKey,
  ]);

  const handleDeleteTable = useCallback(() => {
    if (checkDisabledDeleteTable()) {
      return;
    }
    if (clinicalTrialDesign1TableLength > 1) {
      changeFocusStatus(DEFAULT_FOCUS_STATUS);

      const deleteDrugProfile = (): ArticleEntryData => {
        return {
          ...entryData,
          "Clinical Trial Status": {
            ...entryData["Clinical Trial Status"],
            "Clinical Trial Design-1": entryData["Clinical Trial Status"]?.[
              "Clinical Trial Design-1"
            ]?.filter(
              (tableInfo, tableInfoIndex) => tableInfoIndex !== info.tableIndex,
            ),
          },
        };
      };
      if (info.groupTableKey === "Clinical Trial Design-1") {
        setEntryData(deleteDrugProfile());
        changeCurrentHistory({
          entryData: deleteDrugProfile(),
          changedDataInfo: {
            target: "table",
            action: "delete",
            position: {
              groupTableKey,
              groupTableIndex,
              tableKey,
              tableIndex,
              groupShellKey: null,
              groupShellIndex: null,
              shellKey: null,
              shellIndex: null,
              valueIndex: null,
              value: null,
            },
          },
        });
        changeInfo(DEFAULT_POSITION_INFO);
      }
    }
  }, [
    clinicalTrialDesign1TableLength,
    entryData,
    groupTableIndex,
    groupTableKey,
    info,
    tableIndex,
    tableKey,
    setEntryData,
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    checkDisabledDeleteTable,
  ]);

  const handleClickClinicalTrialDesign1DeleteButton = () => {
    if (focusStatus.type === "clear") handleClearTable();
    if (focusStatus.type === "delete") handleDeleteTable();
  };

  const handleKeyDownToDeleteClinicalTrialDesign1Table = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearTable();
      }
      if (focusStatus.type === "delete" && event.key === "Delete") {
        handleDeleteTable();
      }
      changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    },
    [
      focusStatus.type,
      changeValidatedInfo,
      handleClearTable,
      handleDeleteTable,
    ],
  );

  return {
    handleClickClinicalTrialDesign1DeleteButton,
    handleKeyDownToDeleteClinicalTrialDesign1Table,
  };
};
