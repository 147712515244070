import { apiClient } from "@api/apiClient";

import type {
  PostImageListResponse,
  PostUploadImageResponse,
} from "@interface/image.interface";

const API_ENDPOINT = {
  uploadImage: "/des/image/upload",
  listImage: "/des/image/list",
};

export const imageApis = {
  upload: async (formData: FormData) => {
    const response = await apiClient.post<PostUploadImageResponse>(
      API_ENDPOINT.uploadImage,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return response.data;
  },
  list: async (list: (string | undefined)[]) => {
    const { data } = await apiClient.post<PostImageListResponse>(
      API_ENDPOINT.listImage,
      list,
    );

    return data;
  },
};
