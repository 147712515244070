"use client";

import styled, { css } from "styled-components";

interface SelectProps {
  $isSelected: boolean;
}

export const ChipsBorder = styled.button<SelectProps>`
  display: flex;
  padding: 0.2rem 0.6rem;
  align-items: center;
  gap: var(--Spacing-2, 0.2rem);
  border-radius: 4.2rem;
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Primary, #fff);
  cursor: pointer;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border: 1px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);
      background: var(--Color-Common-Bg-Interactive-Selected, #e5f7ff);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16),
        0px 0px 1px 0px rgba(0, 0, 0, 0.12);

      > svg > path {
        fill: #0198e9;
      }

      > div {
        color: var(--Color-Common-Text-Interactive-Selected, #0198e9);
      }
    `}

  &:hover {
    border: 1px solid
      var(--Color-Common-Border-Interactive-Primary-Hovered, #017abc);
    background: var(--Color-Common-Bg-Interactive-Secondary, #fff);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);

    > svg > path {
      fill: #017abc;
    }

    > div {
      color: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);
    }
  }

  &:active {
    border-radius: 4.2rem;
    border: 1px solid
      var(--Color-Common-Border-Interactive-Primary-Pressed, #015d8e);
    background: var(--Color-Common-Bg-Primary, #fff);

    > svg > path {
      fill: #015d8e;
    }

    > div {
      color: var(--Color-Common-Text-Interactive-Primary-Pressed, #015d8e);
    }
  }
`;

export const ChipsText = styled.div<SelectProps>`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-weight: 500;
`;
