import React, { useState } from "react";

import { ClassificationCode } from "@/types/code.types";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { usePostCopyPressMutate } from "@api/pressCopy/pressCopy";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export const useDuplicatePress = () => {
  const draftArticleId = getPressIdFromURL();
  const { data: articleInfo } = useArticleInfoQuery(Number(draftArticleId));
  const { mutateAsync: doDuplicatePress } = usePostCopyPressMutate();

  const [classificationManual, setClassificationManual] = useState<
    ClassificationCode | ""
  >(articleInfo?.classificationManual ?? "");

  const submitButtonForDuplicatePress: React.MouseEventHandler<
    HTMLButtonElement
  > = async () => {
    if (classificationManual === "" || classificationManual === null) return;
    doDuplicatePress(classificationManual);
  };

  const clickClassificationLabel: React.MouseEventHandler<HTMLInputElement> = (
    event,
  ) => {
    if (classificationManual === event.currentTarget.value)
      setClassificationManual("");
  };

  const changeClassificationLabel: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) =>
    setClassificationManual(event.currentTarget.value as ClassificationCode);

  const checkClassificationCheckedStatus = (targetCode: string) =>
    classificationManual === targetCode;

  const checkClassificationAiStatus = (targetCode: string) =>
    articleInfo?.classificationAi === targetCode;

  return {
    submitButtonForDuplicatePress,
    clickClassificationLabel,
    changeClassificationLabel,
    checkClassificationCheckedStatus,
    checkClassificationAiStatus,
    isDisabledDuplicatePressButton: !classificationManual,
  };
};
