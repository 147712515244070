import { useLayoutEffect } from "react";

export const useSetScrollPosition = () => {
  useLayoutEffect(() => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      window.scrollTo(0, Number(scrollPosition));
      sessionStorage.removeItem("scrollPosition");
    }
  }, []);
};
