import { useCallback } from "react";

import {
  ClinicalTrialDesign1,
  ClinicalTrialDesign1ShellKeysWithoutTrialIntervention,
} from "@/types/clinicalTrialStatus.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { PositionInfoProps } from "../ClinicalTrialStatusTableCreatorShell";
import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorShellReturn {
  handleClickToClearDesignOneShell: () => void;
  handleKeyDownToClearDesignOneShell: (event: KeyboardEvent) => void;
}

export const useHandleClinicalTrialStatusDesignOneShell = (
  positionPropsInfo: PositionInfoProps,
): UseHandleTableCreatorShellReturn => {
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const handleClearShell = useCallback(() => {
    const clearClinicalTrialDesignOneShell = (): ArticleEntryData => {
      const defaultDataPath = entryData["Clinical Trial Status"]?.[
        "Clinical Trial Design-1"
      ] as ClinicalTrialDesign1;

      if (info.shellKey === "Trial Intervention") {
        return {
          ...entryData,
          "Clinical Trial Status": {
            ...entryData["Clinical Trial Status"],
            "Clinical Trial Design-1": defaultDataPath?.map(
              (tableInfo, tableInfoIndex) => {
                if (tableInfoIndex === info.tableIndex) {
                  return {
                    ...tableInfo,
                    "Trial Intervention": [],
                  };
                }
                return tableInfo;
              },
            ),
          },
        };
      }

      return {
        ...entryData,
        "Clinical Trial Status": {
          ...entryData["Clinical Trial Status"],
          "Clinical Trial Design-1": defaultDataPath?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  [info.shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention]:
                    {
                      ...tableInfo[
                        info.shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention
                      ],
                      value: [],
                    },
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    if (info.groupTableKey === "Clinical Trial Design-1") {
      setEntryData(clearClinicalTrialDesignOneShell());
      changeCurrentHistory({
        entryData: clearClinicalTrialDesignOneShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);
  const handleClickToClearDesignOneShell = () => {
    if (focusStatus.type === "clear") {
      handleClearShell();
    }
  };

  const handleKeyDownToClearDesignOneShell = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearShell();
      }
    },
    [focusStatus.type, handleClearShell],
  );

  return {
    handleClickToClearDesignOneShell,
    handleKeyDownToClearDesignOneShell,
  };
};
