import {
  invalidateAllArticleDataQuery,
  useUpdateArticleContentDataMutation,
} from "@api/articleContentData/articleContentData";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { useArticleInfoStatus } from "@hooks/useArticleInfo";
import { useSectionCodeToClassification } from "@hooks/useSectionCode";
import { useUpdateArticleStatus } from "@hooks/useUpdateArticleStatus";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { toastDraftSave, toastFail } from "@utils/toast";

import { useCreateClinicalTrialsContent } from "./useCreateEditArticleContent/useCreateClinicalTrialsContent";
import { useCreateDealsContent } from "./useCreateEditArticleContent/useCreateDealsContent";
import { useCreateExpeditedProgramContent } from "./useCreateEditArticleContent/useCreateExpeditedProgramContent";
import { useCreateRegulatoryContent } from "./useCreateEditArticleContent/useCreateRegulatoryContent";
import { useCreateVentureCapitalContent } from "./useCreateEditArticleContent/useCreateVentureCapitalContent";

export const useSaveEditedArticle = () => {
  const createClinicalTrialsContent = useCreateClinicalTrialsContent();
  const createDealsContent = useCreateDealsContent();
  const createRegulatoryContent = useCreateRegulatoryContent();
  const createExpeditedProgramContent = useCreateExpeditedProgramContent();
  const createVentureCapitalContent = useCreateVentureCapitalContent();

  const { mutateAsync: updateArticleContent } =
    useUpdateArticleContentDataMutation();
  const updateArticleStatus = useUpdateArticleStatus();
  const articleInfoStatus = useArticleInfoStatus();
  const sectionCode = useSectionCodeToClassification();

  const saveArticleContent = async () => {
    if (sectionCode === "TY001") {
      const content = await createClinicalTrialsContent();
      try {
        await updateArticleContent(content);
        if (articleInfoStatus !== DRAFT_STATUS_CODE.published) {
          updateArticleStatus("editArticle");
        }
        await invalidateAllArticleDataQuery(getPressIdFromURL());
        toastDraftSave();
      } catch (error) {
        toastFail("Failed to save");
      }
    } else if (sectionCode === "TY002") {
      const content = await createDealsContent();
      try {
        await updateArticleContent(content);
        if (articleInfoStatus !== DRAFT_STATUS_CODE.published) {
          updateArticleStatus("editArticle");
        }
        await invalidateAllArticleDataQuery(getPressIdFromURL());
        toastDraftSave();
      } catch (error) {
        toastFail("Failed to save");
      }
    } else if (sectionCode === "TY003") {
      const content = await createRegulatoryContent();
      try {
        await updateArticleContent(content);
        if (articleInfoStatus !== DRAFT_STATUS_CODE.published) {
          updateArticleStatus("editArticle");
        }
        await invalidateAllArticleDataQuery(getPressIdFromURL());
        toastDraftSave();
      } catch (error) {
        toastFail("Failed to save");
      }
    } else if (sectionCode === "TY004") {
      const content = await createExpeditedProgramContent();
      try {
        await updateArticleContent(content);
        if (articleInfoStatus !== DRAFT_STATUS_CODE.published) {
          updateArticleStatus("editArticle");
        }
        await invalidateAllArticleDataQuery(getPressIdFromURL());
        toastDraftSave();
      } catch (error) {
        toastFail("Failed to save");
      }
    } else if (sectionCode === "TY006") {
      const content = await createVentureCapitalContent();
      try {
        await updateArticleContent(content);
        if (articleInfoStatus !== DRAFT_STATUS_CODE.published) {
          updateArticleStatus("editArticle");
        }
        await invalidateAllArticleDataQuery(getPressIdFromURL());
        toastDraftSave();
      } catch (error) {
        toastFail("Failed to save");
      }
    } else {
      throw new Error("Fail to save. Invalid section code");
    }
  };

  return { saveArticleContent };
};
