import { TABLE_CREATOR_GROUP_SHELL } from "../constants/tableCreatorGroupShell.constants";
import { TABLE_CREATOR_GROUP_TABLE } from "../constants/tableCreatorGroupTable.constants";
import { TABLE_CREATOR_SHELL } from "../constants/tableCreatorShell.constants";
import { TABLE_CREATOR_SUB_GROUP_SHELL } from "../constants/tableCreatorSubGroupShell.constants";
import { TABLE_CREATOR_TABLE } from "../constants/tableCreatorTable.constants";

import type { GroupTableKeyType } from "../types/tableCreator.types";
import type { ArticleSectionType } from "@/components/business/FillInTableContainer/types/fillInTable.types";
import type { ShellColumnSize } from "@FillInTableContainer/shared/types/shellSize.types";

// GroupTable
export const isTableCustomTitle = (
  articleSection: ArticleSectionType,
  groupTableKey: GroupTableKeyType,
) =>
  TABLE_CREATOR_GROUP_TABLE[articleSection].find(
    ({ key }) => key === groupTableKey,
  )?.isCustomTitle || false;

export const isRequiredGroupTable = (
  articleSection: ArticleSectionType,
  groupTableKey: GroupTableKeyType,
) =>
  TABLE_CREATOR_GROUP_TABLE[articleSection].find(
    ({ key }) => key === groupTableKey,
  )?.isRequired || false;

export const isEnterTableNotes = (
  articleSection: ArticleSectionType,
  groupTableKey: GroupTableKeyType,
) =>
  TABLE_CREATOR_GROUP_TABLE[articleSection].find(
    ({ key }) => key === groupTableKey,
  )?.isEnterTableNotes || false;

// Table
export const isAddDeleteTable = (
  articleSection: ArticleSectionType,
  tableKey: string | null,
) =>
  TABLE_CREATOR_TABLE[articleSection].find(({ key }) => key === tableKey)
    ?.isReviseTable || false;

export const isTableScroll = (
  articleSection: ArticleSectionType,
  tableKey: string | null,
) =>
  TABLE_CREATOR_TABLE[articleSection].find(({ key }) => key === tableKey)
    ?.isTableScroll || false;

export const isRequiredTable = (
  articleSection: ArticleSectionType,
  tableKey: string | null,
) =>
  TABLE_CREATOR_TABLE[articleSection].find(({ key }) => key === tableKey)
    ?.isRequired || false;

export const getTableColumnSize = (
  articleSection: ArticleSectionType,
  tableKey: string | null,
): ShellColumnSize =>
  TABLE_CREATOR_TABLE[articleSection].find(({ key }) => key === tableKey)
    ?.columnSize || "regular";

export const checkTableFocus = (
  articleSection: ArticleSectionType,
  tableKey: string | null,
) => TABLE_CREATOR_TABLE[articleSection].some(({ key }) => key === tableKey);
// GroupShell
export const isAddDeleteGroupShell = (
  articleSection: ArticleSectionType,
  groupShellKey: string | null,
) =>
  TABLE_CREATOR_GROUP_SHELL[articleSection].find(
    ({ key }) => key === groupShellKey,
  )?.isReviseGroupShell || false;

export const isSeparatedShellTitle = (
  articleSection: ArticleSectionType,
  groupShellKey: string | null,
) =>
  TABLE_CREATOR_GROUP_SHELL[articleSection].find(
    ({ key }) => key === groupShellKey,
  )?.isSeparatedShellTitleWithContents || false;

export const isRequiredGroupShell = (
  articleSection: ArticleSectionType,
  groupShellKey: string | null,
) =>
  TABLE_CREATOR_GROUP_SHELL[articleSection].find(
    ({ key }) => key === groupShellKey,
  )?.isRequired || false;

export const isGroupShellScroll = (
  articleSection: ArticleSectionType,
  groupShellKey: string | null,
) =>
  TABLE_CREATOR_GROUP_SHELL[articleSection].find(
    ({ key }) => key === groupShellKey,
  )?.isGroupShellScroll || false;

// subGroupShell

export const isAddDeleteSubGroupShell = (
  articleSection: ArticleSectionType,
  subGroupShellKey: string | null,
) =>
  TABLE_CREATOR_SUB_GROUP_SHELL[articleSection].find(
    ({ key }) => key === subGroupShellKey,
  )?.isReviseSubGroupShell || false;

export const getHaveSubGroupShellTitle = (
  articleSection: ArticleSectionType,
  subGroupShellKey: string | null,
) =>
  TABLE_CREATOR_SUB_GROUP_SHELL[articleSection].find(
    ({ key }) => key === subGroupShellKey,
  )?.title || null;

// Shell
export const isRequiredShell = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
) =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.isRequired || false;

export const isShellDictionary = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
) =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.isDictionary || false;

export const isAddDeleteShell = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
) =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.isReviseShell || false;

export const getShellColumnSize = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
): ShellColumnSize =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.columnSize || "regular";

export const getMaxShellValueLength = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
) =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.countValueLength || 100;

export const getCustomTitleShellKey = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
) =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.customTitle || "";

export const getShellPlaceholder = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
) =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.placeholder || "";

export const isDisabledInsertText = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
) =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.isDisabledInsertText || false;

export const isBasedOnShellValueDropdown = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
) =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.isBasedOnShellValueDropdown || false;

export const isAutocomplete = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
) =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.isAutocomplete || false;

export const isFocusGroupTable = (groupTableKey: string) =>
  ["Approval Process Contents"].includes(groupTableKey) || false;
