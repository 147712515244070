import { ExpeditedProgramSection } from "@/types/expeditedProgram.types";
import {
  EXPEDITED_PROGRAM_GROUP_KEY as eg,
  EXPEDITED_PROGRAM_CONTENTS as ec,
} from "@FillInTableContainer/shared/constants/expeditedProgramKey.constants";
import { TableCreatorTableList } from "@FillInTableContainer/shared/types/tableCreator.types";

interface TableLayout {
  groupTableKey: ExpeditedProgramSection;
  tableList: TableCreatorTableList;
}

export const EXPEDITED_PROGRAM_DRUG_PROFILE_TABLE_LAYOUT: TableLayout = {
  groupTableKey: "Drug Profile",
  tableList: [
    {
      tableKey: "Drug",
      groupShellList: [
        {
          groupShellKey: "Drug Name",
          shellList: [
            {
              shellKey: "Brand Name",
            },
            {
              shellKey: "INN Name",
            },
            {
              shellKey: "Code Name",
            },
          ],
        },
        {
          groupShellKey: null,
          shellList: [
            {
              shellKey: "ROA",
            },
            {
              shellKey: "Modality",
            },
            {
              shellKey: "Target",
            },
            {
              shellKey: "MOA",
            },
            {
              shellKey: "Target Indication",
            },
            {
              shellKey: "Therapeutic Area",
            },
            {
              shellKey: "Other Characteristics",
            },
            {
              shellKey: "Phase",
            },
            {
              shellKey: "Generic / Biosimilar",
            },
            {
              shellKey: "Ref.Product Name",
            },
            {
              shellKey: "Originator/Developer",
            },
          ],
        },
      ],
    },
  ],
};

export const EXPEDITED_PROGRAM_SINGLE_TABLE_LAYOUT: TableLayout[] = [
  {
    groupTableKey: eg.expeditedProgramContents,
    tableList: [
      {
        tableKey: null,
        groupShellList: [
          {
            groupShellKey: "first",
            shellList: [
              {
                shellKey: ec.updateDate,
              },
              {
                shellKey: ec.designatedCompanyName,
              },
              {
                shellKey: ec.regulatoryAgency,
              },
              {
                shellKey: ec.expeditedProgram,
              },
            ],
          },
          {
            groupShellKey: "second",
            shellList: [
              {
                shellKey: ec.designatedIndication,
              },
              {
                shellKey: ec.AdditionalInformation,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    groupTableKey: eg.keyComment,
    tableList: [
      {
        tableKey: null,
        groupShellList: [
          {
            groupShellKey: null,
            shellList: [
              {
                shellKey: "Quote",
              },
              {
                shellKey: "Spokesperson",
              },
            ],
          },
        ],
      },
    ],
  },
];
