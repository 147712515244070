import styled from "styled-components";

import { MainLabelItem } from "./styles/mainLabel.style";

export const ArticleMainLabelWrapper = styled.div`
  display: block;
  line-height: normal;
`;

export const ArticleMainLabelItem = styled(MainLabelItem)``;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;

  background-color: transparent;
`;
