import { EXPEDITED_PROGRAM } from "./expeditedProgramKey.constants";

export const FILL_IN_TABLE_KEY = {
  ClinicalTrials: "Clinical Trials",
  ClinicalTrialStatus: "Clinical Trial Status",
  Regulatory: "Regulatory",
  Deals: "Deals",
  ExpeditedProgram: EXPEDITED_PROGRAM,
  VentureCapital: "Venture Capital",
} as const;
