import IconDropDown from "@images/mainLabel/icon-dropdown.svg";

import MainLabelCheckboxDropDown from "./components/MainLabelCheckboxDropDown/MainLabelCheckboxDropDown";
import * as S from "./MultipleEditableMainLabel.style";
import { MAIN_LABEL_ENTITY_TO_COLOR } from "../../constants/mainLabelType.constants";
import { useMainLabelOptionList } from "../../hooks/useMainLabelOptionList";
import {
  FormattedMainLabelItemType,
  MainLabelNames,
} from "../../types/articleMainLabel.types";

interface Props {
  labelInfo: FormattedMainLabelItemType;
  dropDownEvents: {
    isOpenDropDown: (labelName: MainLabelNames) => boolean;
    changeTargetDropdown: (labelName: MainLabelNames) => void;
    closeTargetDropdown: () => void;
  };
}

const MultipleEditableMainLabel = ({ labelInfo, dropDownEvents }: Props) => {
  const { entity, value, labelName } = labelInfo;
  const optionList = useMainLabelOptionList(labelName);

  if (optionList.length === 0) return null;
  return (
    <S.MultipleEditableMainLabelWrapper
      color={MAIN_LABEL_ENTITY_TO_COLOR[entity]}
    >
      {value.length === 0 && (
        <S.LabelItem
          color={MAIN_LABEL_ENTITY_TO_COLOR[entity]}
          onClick={() => dropDownEvents.changeTargetDropdown(labelName)}
          isEmpty
        >
          -
        </S.LabelItem>
      )}
      {value.length > 0 &&
        value?.map((item) => (
          <S.LabelItem
            key={item}
            color={MAIN_LABEL_ENTITY_TO_COLOR[entity]}
            onClick={() => dropDownEvents.changeTargetDropdown(labelName)}
          >
            {item}
          </S.LabelItem>
        ))}

      <S.IconDropDown
        onClick={() => dropDownEvents.changeTargetDropdown(labelName)}
        isOpen={dropDownEvents.isOpenDropDown(labelName)}
        src={IconDropDown}
        alt="click dropdown to edit label"
      />
      {dropDownEvents.isOpenDropDown(labelName) && (
        <MainLabelCheckboxDropDown targetValue={value} labelInfo={labelInfo} />
      )}
    </S.MultipleEditableMainLabelWrapper>
  );
};

export default MultipleEditableMainLabel;
