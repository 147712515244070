import React, { useState } from "react";

import useModal from "@hooks/useModal";
import ArrowIconDown from "@images/fillInTable/fillintable-search-arrow-down.svg";
import ArrowIconUp from "@images/fillInTable/fillintable-search-arrow-up.svg";
import SearchIconSky from "@images/fillInTable/search-icon-24-size-sky.svg";
import FillInTableArticleImageModal from "@molecules/FillInTableArticleImageModal/FillInTableArticleImageModal";

import * as S from "./FillInTableArticleImageContainer.style";

import type { ParsedJsonImageContentType } from "@/types/fillInTableContent.types";

interface Props {
  item: ParsedJsonImageContentType;
}

const FillInTableArticleImageContainer = React.memo(({ item }: Props) => {
  const { openModal, closeModal, isModal } = useModal();
  const [isShowContent, setIsShowContent] = useState(false);

  return (
    <S.FillInTableContainer isShowContent={isShowContent}>
      <button type="button" onClick={() => setIsShowContent(!isShowContent)}>
        <S.FillInTableContainerHeadLine>
          <S.FillInTableContainerTitle>
            Press Image
            {isShowContent ? (
              <img src={ArrowIconUp} alt="search-arrow-up" />
            ) : (
              <img src={ArrowIconDown} alt="search-arrow-down" />
            )}
          </S.FillInTableContainerTitle>
          <S.FillInTableContainerExpandViewText
            onClick={(e) => {
              openModal();
              e.stopPropagation();
            }}
          >
            <span>Expand View</span>
            <img src={SearchIconSky} alt="search-icon" />
          </S.FillInTableContainerExpandViewText>
        </S.FillInTableContainerHeadLine>
      </button>
      <S.FillInTableContentContainer>
        <div>
          <img src={item.source} alt="article-img" />
        </div>
      </S.FillInTableContentContainer>
      {isModal && (
        <FillInTableArticleImageModal item={item} closeModal={closeModal} />
      )}
    </S.FillInTableContainer>
  );
});

export default FillInTableArticleImageContainer;
