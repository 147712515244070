import React from "react";

import DealsDealTypeGroupTable from "./components/DealsDealTypeGroupTable/DealsDealTypeGroupTable";
import { DEAL_TYPE_AND_STATUS_LAYOUT } from "./constants/dealTypeAndStatusLayout";

const { groupTableKey, tableList } = DEAL_TYPE_AND_STATUS_LAYOUT;

const GROUP_TABLE_INDEX = 1;

const DealsDealTypeTableCreatorContainer = () => {
  return (
    <DealsDealTypeGroupTable
      key={`group-table-${String(GROUP_TABLE_INDEX)}`}
      groupTableKey={groupTableKey}
      tableList={tableList}
      groupTableIndex={GROUP_TABLE_INDEX}
    />
  );
};

export default DealsDealTypeTableCreatorContainer;
