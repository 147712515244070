"use client";

import styled, { css } from "styled-components";

export const PhaseIconWrapper = styled.div<{
  $processType: "default" | "figure";
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;

  ${({ $processType }) =>
    $processType === "figure" &&
    css`
      gap: 0.6rem;
      padding-bottom: 0.8rem;
    `}
`;

export const PhaseIcon = styled.div<{
  $processType: "default" | "figure";
  $color: "black" | "blue" | "gray" | "darkGreenBlue" | "lightGreenBlue";
}>`
  ${({ $processType }) =>
    $processType === "figure" &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.4rem;
      height: 1.4rem;
      border-radius: 50%;

      svg {
        width: 12px;
        height: 12px;
      }
    `}

  ${({ $processType, $color }) =>
    $processType === "figure" &&
    $color === "blue" &&
    css`
      background-color: #0198e9;
    `}

    ${({ $processType, $color }) =>
    $processType === "figure" &&
    $color === "darkGreenBlue" &&
    css`
      background-color: #7c899c;
    `}

    ${({ $processType, $color }) =>
    $processType === "figure" &&
    $color === "lightGreenBlue" &&
    css`
      background-color: #7c899c;
    `}
`;
