import { DEAL_SWAP_ROLES_BUTTON_TARGET_TABLE_KEYS } from "../constants/dealSwapRoles.constants";

export const checkTargetDealSwapRolesByTableKey = (tableKey: string | null) => {
  if (tableKey === null) return false;

  const isTargetTable =
    DEAL_SWAP_ROLES_BUTTON_TARGET_TABLE_KEYS.includes(tableKey);

  return isTargetTable;
};
