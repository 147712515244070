import React from "react";

import {
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
} from "../../../shared/components/core";
import { EDITABLE_SHELL_PADDING } from "../../EditArticle";

type Props = {
  otherTableItemsList?: {
    title: string;
    value: React.ReactNode;
  }[];
  isEditable?: boolean;
};

const DealTermsOtherTable = ({ otherTableItemsList, isEditable }: Props) => {
  if (!otherTableItemsList) return null;
  if (otherTableItemsList?.length === 0) return null;
  return (
    <TableContainer>
      {otherTableItemsList?.map((shell) => (
        <React.Fragment key={shell.title}>
          <TableRowContainer>
            <TableShellTitle>{shell.title}</TableShellTitle>
          </TableRowContainer>
          <TableRowContainer>
            <TableShellValue
              padding={isEditable ? EDITABLE_SHELL_PADDING : "0.8rem"}
            >
              {shell.value}
            </TableShellValue>
          </TableRowContainer>
        </React.Fragment>
      ))}
    </TableContainer>
  );
};

export default DealTermsOtherTable;
