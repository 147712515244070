"use client";

import styled from "styled-components";

export const ParagraphTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
  height: 3.8rem;
  padding: 0.4rem 0;

  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  color: var(--Color-Common-Text-Success, #0198e9);
  line-height: 100%;
`;
