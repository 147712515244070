import { apiClient } from "@api/apiClient";

import {
  GetTableCreatorShellDropdownResponse,
  ResponseDisplayDealTypeName,
} from "./dropDownList.interface";

import type { SectionCode } from "@FillInTableContainer/components/FillInTable/constants/common.constants";

const API_ENDPOINT = {
  getList: "/des/drop-down/list",
  displayDealType: "/des/drop-down/deal-types-for-efo",
  desDisplayDealType: "/des/drop-down/deal-types-for-des",
};

export const dropDownList = {
  get: async (sectionCode: SectionCode) => {
    const { data } = await apiClient.get<GetTableCreatorShellDropdownResponse>(
      `${API_ENDPOINT.getList}/${sectionCode}`,
    );

    return data;
  },

  display: async () => {
    const response = await apiClient.get<ResponseDisplayDealTypeName>(
      `${API_ENDPOINT.displayDealType}`,
    );

    return response.data;
  },

  desDisplayDealType: async () => {
    const response = await apiClient.get<ResponseDisplayDealTypeName>(
      `${API_ENDPOINT.desDisplayDealType}`,
    );

    return response.data;
  },
};
