import React, { useId } from "react";

import PressUnsuitableReasonContainer from "./components/PressUnsuitableReasonContainer/PressUnsuitableReasonContainer";
import * as S from "./UnsuitableCheckboxIcon.style";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  isChecked: boolean;
  draftArticleId: number;
  isOpenModalUnsuitablePress: boolean;
  articleRejectReason: string | null;
  closeModalUnsuitablePress: () => void;
  mcEditorName: string | null;
  aiDone: boolean;
}

const UnsuitableCheckboxIcon = ({
  isChecked,
  draftArticleId,
  isOpenModalUnsuitablePress,
  articleRejectReason,
  closeModalUnsuitablePress,
  mcEditorName,
  aiDone,
  ...rest
}: Props) => {
  const checkboxId = useId();
  return (
    <>
      <S.CheckboxLabel htmlFor={checkboxId}>
        <S.CheckboxInput
          id={checkboxId}
          type="checkbox"
          checked={isChecked}
          {...rest}
        />
        {articleRejectReason || "-"}
      </S.CheckboxLabel>
      {isOpenModalUnsuitablePress && (
        <PressUnsuitableReasonContainer
          closeModalUnsuitablePress={closeModalUnsuitablePress}
          articleRejectReason={articleRejectReason}
          draftArticleId={draftArticleId}
          mcEditorName={mcEditorName}
          aiDone={aiDone}
        />
      )}
    </>
  );
};

export default UnsuitableCheckboxIcon;
