import React from "react";

import * as S from "./SwapButton.style";
import { IconArrowVerticalSplit } from "../../../../shared/Icons";

type Props = {
  text: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const SwapButton = ({ text, ...props }: Props) => {
  return (
    <S.SwapButton type="button" {...props}>
      <IconArrowVerticalSplit width={16} height={16} color="#515C6C" />
      {text}
    </S.SwapButton>
  );
};

export default SwapButton;
