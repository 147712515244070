"use client";

import { styled } from "styled-components";

const InputBase = styled.input<{ $hasError?: boolean }>`
  outline: ${({ $hasError }) => ($hasError ? "1px solid #DB0F72" : "none")};
`;

export const Wrapper = styled.span`
  display: inline-flex;
  flex-direction: row;
  gap: 0.1rem;
  border: var(--Border-Width-1, 1px) solid
    var(--Color-Common-Border-Secondary, #b1b8c4);
  background: var(--Color-Common-Bg-Primary, #b1b8c4);
  margin-left: 0.4rem;
`;

export const YearInput = styled(InputBase)`
  width: 4.6rem;
  height: 2.2rem;
  padding: 0 0.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  text-align: center;
  &::placeholder {
    color: #b1b8c4;
  }
`;

export const MonthInput = styled(InputBase)`
  width: 2.8rem;
  height: 2.2rem;
  padding: 0 0.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  text-align: center;
  &::placeholder {
    color: #b1b8c4;
  }
`;

export const DayInput = styled(InputBase)`
  width: 2.8rem;
  height: 2.2rem;
  padding: 0 0.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  text-align: center;
  &::placeholder {
    color: #b1b8c4;
  }
`;
