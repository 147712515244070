export const DEALS_GROUP_KEY = {
  drugProfile: "Drug Profile",
  dealTypeAndStatus: "Deal Type & Status",
  dealTerms: "Licensee Table",
  additionalInformation: "Additional Information (Deal)",
  keyComment: "Key Comment",
} as const;

export const KEY_DEALS_TABLE = {
  typeAndStatus: "Deal Type & Status",
  licenseeTable: "Licensee Table",
  licensorTable: "Licensor Table",
} as const;

export const KEY_DEALS_SHELL = {
  licenseeName: "Licensee Name",
  financialTerms: "Other Financial Terms (SC & LA)",
  licenseeResponsibility: "Licensee Responsibility & Rights (SC & LA)",
};

export const KEY_DEALS_GROUP_SHELL = {
  rightsScope: "Rights Scope",
  financialTerms: "Financial terms",
};
