"use client";

import styled from "styled-components";

export const HeaderDesktopWrapper = styled.div`
  display: block;

  @media only screen and (min-width: 1px) and (max-width: 989px) {
    display: none;
  }
`;
