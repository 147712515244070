import React, { useEffect, useRef } from "react";

import { ExpeditedProgramSection } from "@/types/expeditedProgram.types";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import {
  TableDividerPlusBar,
  TableCreatorTableTitle,
} from "@FillInTableContainer/shared";
import {
  GroupTableKeyType,
  TableCreatorGroupShellList,
} from "@FillInTableContainer/shared/types/tableCreator.types";
import { isAddDeleteTable } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";
import { padStart } from "@utils/padStart/padStart";

import ExpeditedProgramDrugProfileGroupShell from "./components/ExpeditedProgramDrugProfileGroupShell/ExpeditedProgramDrugProfileGroupShell";
import * as S from "./ExpeditedProgramDrugProfileTable.style";
import { useExpeditedProgramTableCreator } from "./hooks/useExpeditedProgramTableCreator";

export interface TableInfoProps {
  groupTableKey: ExpeditedProgramSection;
  tableKey: string | null;
  groupShellList: TableCreatorGroupShellList;
  groupTableIndex: number;
  tableIndex: number;
  tableCount: number;
}
interface Props extends TableInfoProps {
  handleAddTable: (tableIndex: number) => void;
  handleDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => void;
  handleDragEnd: (groupTableKey: GroupTableKeyType) => void;
  handleDragOn: () => void;
  isDraggableTable: boolean;
}

const ExpeditedProgramDrugProfileTable = ({
  groupTableKey,
  tableKey,
  groupTableIndex,
  tableIndex,
  tableCount,
  groupShellList,
  handleAddTable,
  handleDragEnd,
  handleDragOn,
  handleDragStart,
  isDraggableTable,
}: Props) => {
  const tableTitleRef = useRef<HTMLDivElement>(null);
  const articleSection = useArticleSection();
  const tablePropsInfo: TableInfoProps = {
    groupTableKey,
    tableKey,
    groupTableIndex,
    tableIndex,
    tableCount,
    groupShellList,
  };

  const {
    focusStatus,
    isFocused,
    isLastTable,
    isFocusedAddButton,
    handleClickDeleteButton,
    focusEventToChangePositionInfo,
    numberOfGroupShellCount,
    changeFocusStatus,
    changePositionInfo,
    changePositionInfoToDefault,
    handleChangeDefaultFocus,
  } = useExpeditedProgramTableCreator(tablePropsInfo);

  const isClearTable =
    isFocused &&
    focusStatus.type === "clear" &&
    focusStatus.container === "table";

  const isDeleteTable =
    isFocused &&
    focusStatus.type === "delete" &&
    focusStatus.container === "table";

  const title =
    tableCount > 1
      ? `${getTableTitle(articleSection, tableKey)} ${padStart(tableIndex + 1)}`
      : getTableTitle(articleSection, tableKey);

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;

        const clickSelectButton = tableTitleRef.current?.contains(target);

        if (clickSelectButton) return;
        if (target.dataset.button) return;

        handleChangeDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleChangeDefaultFocus, isFocused]);

  const handleFocusTableTitle = () => {
    focusEventToChangePositionInfo({
      type: "clear",
      container: "table",
    });
  };

  return (
    <>
      {isAddDeleteTable(articleSection, tableKey) && (
        <TableDividerPlusBar
          tableTitle={getTableTitle(articleSection, tableKey) || ""}
          isLastPosition={false}
          onClick={() => handleAddTable(tableIndex)}
          isVisibility={isFocusedAddButton}
        />
      )}
      <S.TableWrapper
        isFocused={isFocused}
        isClearTable={isClearTable}
        isDeleteTable={isDeleteTable}
        isAddTable={isAddDeleteTable(articleSection, tableKey)}
        isDraggingTable={isFocused && focusStatus.type === "dragging"}
      >
        {(isClearTable || isDeleteTable) && (
          <S.DeleteTableButtonWrapper>
            <S.DeleteTableButton
              data-button="delete-button"
              onClick={handleClickDeleteButton}
            >
              <img src={IconXMark} alt="button for clear table" />
              {focusStatus.type === "clear" ? `Clear ${title} Table` : null}
              {focusStatus.type === "delete" ? `Delete ${title} Table` : null}
            </S.DeleteTableButton>
          </S.DeleteTableButtonWrapper>
        )}
        <div ref={tableTitleRef}>
          {getTableTitle(articleSection, tableKey) && (
            <TableCreatorTableTitle
              tableKey={tableKey}
              groupTableKey={groupTableKey}
              groupTableIndex={groupTableIndex}
              tableIndex={tableIndex}
              isDragging={isFocused && focusStatus.type === "dragging"}
              onClickHandler={handleFocusTableTitle}
              handleDragStart={handleDragStart}
              handleDragEnd={handleDragEnd}
              handleDragOn={handleDragOn}
              isDraggableTable={isDraggableTable}
              changeFocusStatusForDragging={() => {
                changePositionInfo();
                changeFocusStatus({
                  type: "dragging",
                  container: "table",
                });
              }}
              changeFocusStatusForDragEnd={() => {
                changePositionInfoToDefault();
                changeFocusStatus({
                  type: "default",
                  container: "noFocus",
                });
              }}
            />
          )}
        </div>
        <S.Wrapper>
          {groupShellList.map(
            ({ groupShellKey, shellList }, groupShellListIndex) => (
              <S.GroupShellWrapper
                key={`groupShellList-${String(groupShellListIndex)}`}
              >
                {newArrayToCountValue(
                  numberOfGroupShellCount(groupShellKey),
                ).map((_, groupShellIndex) => (
                  <ExpeditedProgramDrugProfileGroupShell
                    key={`group-shell-${String(groupTableIndex)}-${String(
                      tableIndex,
                    )}-${String(groupShellIndex)}`}
                    shellList={shellList}
                    groupTableKey={groupTableKey}
                    groupTableIndex={groupTableIndex}
                    tableKey={tableKey}
                    tableIndex={tableIndex}
                    groupShellKey={groupShellKey}
                    groupShellIndex={groupShellIndex}
                    groupShellCount={numberOfGroupShellCount(groupShellKey)}
                  />
                ))}
              </S.GroupShellWrapper>
            ),
          )}
        </S.Wrapper>
      </S.TableWrapper>
      {isAddDeleteTable(articleSection, tableKey) && (
        <TableDividerPlusBar
          tableTitle={getTableTitle(articleSection, tableKey) || ""}
          onClick={() => handleAddTable(tableIndex + 1)}
          isLastPosition
          isVisibility={isLastTable && isFocusedAddButton}
        />
      )}
    </>
  );
};

export default ExpeditedProgramDrugProfileTable;
