export const ENTRY_DATA_KEY_COMMENTS_GROUP_TABLE_KEY = "Key Comment" as const;

export const ENTRY_DATA_KEY_COMMENT_KEYS = {
  quote: "Quote",
  spokesperson: "Spokesperson",
  keyCommentSetOptions: "Key Comment Set Options",
} as const;
export const ENTRY_DATA_KEY_COMMENTS_SHELL_KEYS = {
  quote: "Quote",
  spokesperson: "Spokesperson",
  keyCommentSetOptions: "Key Comment Set Options",
} as const;

export const ENTRY_DATA_KEY_COMMENT_GROUP_SHELL_KEYS = {
  none: null,
};

export const ENTRY_DATA_KEY_COMMENT_TABLE_KEYS = {
  none: null,
};
