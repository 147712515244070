import React, { useCallback, useEffect } from "react";

import useArticleSection from "@/components/business/FillInTableContainer/hooks/useArticleSection";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { KEY_CLINICAL_TRIALS } from "@FillInTableContainer/components/FillInTable/constants/clinicalTrials.constants";
import { EXPEDITED_PROGRAM } from "@FillInTableContainer/shared/constants/expeditedProgramKey.constants";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import {
  DEFAULT_FOCUS_STATUS,
  FocusStatus,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { ShellColumnSize } from "@FillInTableContainer/shared/types/shellSize.types";
import { getShellColumnSize } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useExpeditedProgramDrugProfileShell } from "./useExpeditedProgramDrugProfileShell";
import { PositionInfoProps } from "../ExpeditedProgramDrugProfileShell";

interface UseHandleTableCreatorShellReturn {
  handleFocusTextFieldWrapper: (event: React.MouseEvent) => void;
  handleClickToDeleteShell: () => void;
  handleFocusOutShell: () => void;
  isFocused: boolean;
  isClearShell: boolean;
  isDeleteShell: boolean;
  isEnableEdit: boolean;
  isAlertShell: boolean;
  isLastShell: boolean;
  focusStatus: FocusStatus;
  columnSize: ShellColumnSize;
  isClearTable: boolean;
  isDeleteTable: boolean;
}

export const useExpeditedProgramShellCreator = (
  positionPropsInfo: PositionInfoProps,
): UseHandleTableCreatorShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
    shellKey,
    shellIndex,
    shellCount,
  } = positionPropsInfo;

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { validatedInfo, changeValidatedInfo } = useValidatedInTableCreator();

  const {
    handleClickToClearDrugProfileShell,
    handleKeyDownToClearDrugProfileShell,
  } = useExpeditedProgramDrugProfileShell(positionPropsInfo);

  const articleSection = useArticleSection();

  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );
  const isEnableEdit = articleInfo
    ? ENABLE_GROUP_TABLE_KEY_LIST[EXPEDITED_PROGRAM][groupTableKey].includes(
        articleInfo.draftArticleStatus,
      )
    : false;

  const columnSize = getShellColumnSize(articleSection, shellKey);
  const isLastShell = shellCount === shellIndex + 1;

  const isFocused =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableIndex === tableIndex &&
    info.tableKey === tableKey &&
    info.groupShellKey === groupShellKey &&
    info.groupShellIndex === groupShellIndex &&
    info.shellKey === shellKey &&
    info.shellIndex === shellIndex &&
    isEnableEdit;

  const isClearShell =
    isFocused &&
    focusStatus.type === "clear" &&
    focusStatus.container === "shell";

  const isDeleteShell =
    isFocused &&
    focusStatus.type === "delete" &&
    focusStatus.container === "shell";

  const isAlertShell =
    validatedInfo.filter(
      (validatedItem) =>
        validatedItem.groupTableKey === groupTableKey &&
        validatedItem.groupTableIndex === groupTableIndex &&
        validatedItem.tableIndex === tableIndex &&
        validatedItem.groupShellKey === groupShellKey &&
        validatedItem.groupShellIndex === groupShellIndex &&
        validatedItem.shellKey === shellKey &&
        validatedItem.shellIndex === shellIndex &&
        validatedItem.valueIndex === null &&
        validatedItem.type === "alert" &&
        validatedItem.container === "shell",
    ).length > 0;

  const isTableFocus =
    info.groupTableKey === groupTableKey &&
    info.tableKey === tableKey &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === null &&
    info.groupShellIndex === null &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    isEnableEdit;

  const isClearTable =
    isTableFocus &&
    focusStatus.type === "clear" &&
    focusStatus.container === "table";

  const isDeleteTable =
    isTableFocus &&
    focusStatus.type === "delete" &&
    focusStatus.container === "table";

  const handleFocusOutShell = () => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  };

  const handleFocusTextFieldWrapper = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (
      focusStatus.type !== "clear" ||
      (focusStatus.type === "clear" && focusStatus.container !== "shell")
    ) {
      changeFocusStatus({
        type: "clear",
        container: "shell",
      });
    }

    changeInfo({
      ...positionPropsInfo,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      valueIndex: null,
    });
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleClickToDeleteShell = () => {
    switch (info.groupTableKey) {
      case KEY_CLINICAL_TRIALS.drugProfile:
        handleClickToClearDrugProfileShell();
        break;
      default:
        break;
    }
  };

  const handleKeyDownToDeleteShell = useCallback(
    (event: KeyboardEvent) => {
      switch (info.groupTableKey) {
        case KEY_CLINICAL_TRIALS.drugProfile:
          handleKeyDownToClearDrugProfileShell(event);
          break;
        default:
          break;
      }
    },
    [handleKeyDownToClearDrugProfileShell, info.groupTableKey],
  );

  useEffect(() => {
    if (focusStatus.container !== "shell") return;
    window.addEventListener("keydown", handleKeyDownToDeleteShell);

    return () => {
      window.removeEventListener("keydown", handleKeyDownToDeleteShell);
    };
  }, [focusStatus, handleKeyDownToDeleteShell]);

  return {
    handleFocusTextFieldWrapper,
    handleClickToDeleteShell,
    handleFocusOutShell,
    isFocused,
    isClearShell,
    isDeleteShell,
    isEnableEdit,
    focusStatus,
    isAlertShell,
    isLastShell,
    columnSize,
    isClearTable,
    isDeleteTable,
  };
};
