import React from "react";

import * as S from "./DealTermsTableMobileContainer.style";
import { useCheckWindowSize } from "../../../shared/hooks/useCheckWindowSize";
import DealTermsTableLayoutMobile from "../DealTermsTableLayoutMobile/DealTermsTableLayoutMobile";
import TableGridColumnLayout from "../TableGridColumnLayout/TableGridColumnLayout";
import TableTitle from "../TableTitle/TableTitle";

type Props = {
  bottomTableHeaderItems: React.ReactNode[];
  bottomTableItemsList: (
    | {
        title: string;
        value: React.ReactNode;
      }[]
    | {
        title: string;
        value: React.ReactNode;
      }
  )[][][];
};

const DealTermsTableMobileContainer = ({
  bottomTableItemsList,
  bottomTableHeaderItems,
}: Props) => {
  const { isMobileScreen } = useCheckWindowSize();
  const bottomLeftTableLength =
    bottomTableItemsList
      .at(0)
      ?.reduce(
        (prev, bottomTableItem) => prev + (bottomTableItem.length ?? 0),
        0,
      ) ?? 0;

  const bottomRightTableLength =
    bottomTableItemsList
      .at(1)
      ?.reduce(
        (prev, bottomTableItem) => prev + (bottomTableItem.length ?? 0),
        0,
      ) ?? 0;

  const maxBottomTableLength = Math.max(
    bottomTableItemsList.at(0)?.length ?? 0,
    bottomTableItemsList.at(1)?.length ?? 0,
  );

  const transformedBottomTableItemsList = Array.from(
    { length: maxBottomTableLength },
    (_, index) => [
      bottomTableItemsList.at(0)?.at(index) ?? [],
      bottomTableItemsList.at(1)?.at(index) ?? [],
    ],
  );

  const leftTableItems = transformedBottomTableItemsList
    .map((items) => items[0])
    .filter((item) => item.length > 0);
  const rightTableItems = transformedBottomTableItemsList
    .map((items) => items[1])
    .filter((item) => item.length > 0);

  const isBottomTableItemsList =
    transformedBottomTableItemsList
      ?.flatMap((items) => (items.length > 0 ? items : []))
      .filter((item) => item.length > 0).length > 0;

  if (!isMobileScreen) return;
  return (
    <S.LayoutWrapper>
      {(bottomLeftTableLength > 0 || bottomRightTableLength > 0) && (
        <TableGridColumnLayout
          columnCount={bottomTableHeaderItems.length}
          gap={64}
        >
          <TableTitle
            title={
              bottomLeftTableLength > 0 ? (
                <>{bottomTableHeaderItems[0]}</>
              ) : null
            }
          />
          <TableTitle
            title={
              bottomRightTableLength ? <>{bottomTableHeaderItems[1]}</> : null
            }
          />
        </TableGridColumnLayout>
      )}
      {isBottomTableItemsList ? (
        <S.GroupTableWrapper>
          <S.GroupTableItem>
            <DealTermsTableLayoutMobile items={leftTableItems} />
          </S.GroupTableItem>
          <S.GroupTableItem>
            <DealTermsTableLayoutMobile items={rightTableItems} />
          </S.GroupTableItem>
        </S.GroupTableWrapper>
      ) : null}
    </S.LayoutWrapper>
  );
};

export default DealTermsTableMobileContainer;
