import { useEffect, useState } from "react";

import { MainLabelNames } from "../types/articleMainLabel.types";

interface UseMainLabelDropDownToggleEvent {
  openTargetDropdownLabelName: MainLabelNames | null;
  isOpenDropDown: (labelName: MainLabelNames) => boolean;
  changeTargetDropdown: (labelName: MainLabelNames) => void;
  closeTargetDropdown: () => void;
}

export const useMainLabelDropDownToggleEvent =
  (): UseMainLabelDropDownToggleEvent => {
    const [openTargetDropdownLabelName, setOpenTargetDropdownLabelName] =
      useState<MainLabelNames | null>(null);

    const isOpenDropDown = (labelName: MainLabelNames) =>
      openTargetDropdownLabelName === labelName;

    const changeTargetDropdown = (labelName: MainLabelNames) => {
      if (openTargetDropdownLabelName === labelName) {
        setOpenTargetDropdownLabelName(null);
      } else {
        setOpenTargetDropdownLabelName(labelName);
      }
    };

    const closeTargetDropdown = () => {
      setOpenTargetDropdownLabelName(null);
    };

    useEffect(() => {
      const closeDropDownUsingESCKey = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          closeTargetDropdown();
        }
      };
      window.addEventListener("keydown", closeDropDownUsingESCKey);
      return () =>
        window.removeEventListener("keydown", closeDropDownUsingESCKey);
    }, []);

    return {
      openTargetDropdownLabelName,
      isOpenDropDown,
      changeTargetDropdown,
      closeTargetDropdown,
    };
  };
