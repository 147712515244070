import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconLeftSingleArrow = ({
  width = 16,
  height = 16,
  color = "#A8B3C5",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Chevron">
        <path
          id="chevron_up_symbol"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0931 13.8289C11.3906 13.5826 11.4147 13.161 11.147 12.8874L6.33301 8L11.147 3.11265C11.4147 2.83897 11.3906 2.41745 11.0931 2.17114C10.7957 1.92484 10.3375 1.94702 10.0697 2.2207L4.85236 7.55402C4.60434 7.80756 4.60434 8.19244 4.85236 8.44598L10.0697 13.7793C10.3375 14.053 10.7957 14.0752 11.0931 13.8289Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

IconLeftSingleArrow.displayName = "LeftSingleArrow";
export default IconLeftSingleArrow;
