import React from "react";

import DragIcon from "@images/fillInTable/modal-drag-icon.svg";
import XMark from "@images/fillInTable/modal-x-mark.svg";
import FillInTableArticleTable from "@molecules/FillInTableArticleTable/FillInTableArticleTable";

import * as S from "./FillInTableArticleTableModal.style";
import { useDragModal } from "./hooks/useDragModal";
import { useResizeModal } from "./hooks/useResizeModal";

import type { ParsedJsonTableContentType } from "@/types/fillInTableContent.types";

interface Props {
  item: ParsedJsonTableContentType;
  closeModal: () => void;
}

const FillInTableArticleTableModal = ({ item, closeModal }: Props) => {
  const { draggedTargetRef, draggedPosition, onMouseDown } = useDragModal();
  const { modalSizeWidth, onMouseDownResize } =
    useResizeModal(draggedTargetRef);

  return (
    <S.FillInTableArticleModalWrapper
      ref={draggedTargetRef}
      draggedPosition={draggedPosition}
      modalSizeWidth={modalSizeWidth}
    >
      <S.FillInTableArticleModalInnerWrapper>
        <S.ResizeBorder
          onMouseDown={(e) => onMouseDownResize(e, "left")}
          isLeftBorder
        />
        <S.FillInTableModalHeadLine onMouseDown={onMouseDown}>
          <S.DragBox>
            <span>Drag</span>
            <img src={DragIcon} alt="drag-icon" />
          </S.DragBox>
          <S.CloseBox onClick={() => closeModal()}>
            <span>Close</span>
            <img src={XMark} alt="modal-x-mark" />
          </S.CloseBox>
        </S.FillInTableModalHeadLine>
        <S.FillInTableContentContainer>
          <FillInTableArticleTable item={item} isModal />
        </S.FillInTableContentContainer>
        <S.ResizeBorder onMouseDown={(e) => onMouseDownResize(e, "right")} />
      </S.FillInTableArticleModalInnerWrapper>
    </S.FillInTableArticleModalWrapper>
  );
};

export default FillInTableArticleTableModal;
