import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  currentDate: string | null;
  timezoneIdentifier: string | null;
};

export const formatDateToShortStringDate = ({
  currentDate,
  timezoneIdentifier,
}: Props) => {
  if (!currentDate) return "";

  if (!timezoneIdentifier) return `${dayjs(currentDate).format("MMM D 'YY")}`;

  const date = dayjs(currentDate).tz(timezoneIdentifier);

  const formattedDate = date.format("MMM D 'YY");

  return `${formattedDate}`;
};
