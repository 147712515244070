import styled from "styled-components";

import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";

export const ParagraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 6.4rem;
  gap: 1.2rem;
`;

export const ParagraphContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.4rem;
  width: 100%;
`;

export const ParagraphContent = styled(EditableTextContainer)``;

export const DeepInsightWrapper = styled.div`
  position: relative;
  display: flex;
  width: 98.4rem;
  min-height: 11.8rem;
  padding: 1.6rem 1.6rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;

  border-radius: 1.2rem;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(7, 7, 7, 0.08);
`;

export const DeepInsightContent = styled(EditableTextContainer)`
  color: ${({ theme }) => theme.color.neural.gray10};

  width: 100%;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 165%; /* 3.3rem */

  &:empty:before {
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 165%; /* 3.3rem */
    color: ${({ theme }) => theme.color.neural.gray06};
    content: attr(placeholder);
  }
`;

export const WaveIcon = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;

  border-radius: 1.2rem;
  opacity: 0.72;
`;
