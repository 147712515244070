export const parsePressContent = (content: string) => {
  try {
    const data = JSON.parse(content);
    if (Array.isArray(data)) {
      return data
        .filter((contentItem) => contentItem.type === "txt")
        .map((contentItem) => contentItem.data)
        .join(" ");
    }
    return content;
  } catch {
    return content;
  }
};
