import React from "react";

import { KeyCommentContainer, ParagraphTitle } from "@frontend/common";

import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import { useUpdateParagraph } from "@EditArticleContainer/shared/hooks/useUpdateParagraph";

import * as S from "./DealsParagraphKeyComments.style";
import { KeyComments } from "./utils/keyComments";

const PARAGRAPH_TITLE = "Key Comments";

const DealsParagraphKeyComments = () => {
  const paragraph = useParagraphEditedData("TC106");
  const [updateKeyComments, loadingKeyCommentsIndex, keyCommentsUpdateKeyMap] =
    useUpdateParagraph({
      paragraphCode: "TC106",
      sectionCode: "TY002",
    });

  if (!paragraph) return null;
  const keyComments = new KeyComments(paragraph);
  const { quote } = keyComments;
  const { spokesperson } = keyComments;

  return (
    <>
      {!keyComments.isEmpty() && (
        <S.ParagraphKeyCommentsWrapper
          disabled={loadingKeyCommentsIndex === 0}
          key={keyCommentsUpdateKeyMap.get(0)}
        >
          <ParagraphTitle title={PARAGRAPH_TITLE} />
          <S.ParagraphKeyCommentsContentsWrapper>
            <S.ParagraphKeyCommentsUpdateButton
              disabled={loadingKeyCommentsIndex === 0}
              onClick={() => updateKeyComments(0)}
            />
            <S.KeyCommentsContentWrapper
              disabled={loadingKeyCommentsIndex === 0}
            >
              <KeyCommentContainer
                quote={
                  <S.QuoteContent
                    item={{
                      text: quote.text,
                      labels: quote.labels,
                    }}
                    canTextEdit
                    canLabelEdit
                    refKey={quote.key}
                    style={{
                      padding: "0.4rem",
                    }}
                  />
                }
                spokesperson={
                  <S.SpokespersonContent
                    item={{
                      text: spokesperson.text,
                      labels: spokesperson.labels,
                    }}
                    canTextEdit
                    canLabelEdit
                    refKey={spokesperson.key}
                    style={{
                      padding: "0.4rem",
                    }}
                  />
                }
              />
            </S.KeyCommentsContentWrapper>
          </S.ParagraphKeyCommentsContentsWrapper>
        </S.ParagraphKeyCommentsWrapper>
      )}
    </>
  );
};

export default DealsParagraphKeyComments;
