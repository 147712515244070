import React, { useState } from "react";

import ClinicalEndpointsTableContent from "./components/ClinicalEndpointsTableContent/ClinicalEndpointsTableContent";
import ClinicalEndpointsTableSwitched from "./components/ClinicalEndpointsTableSwitched/ClinicalEndpointsTableSwitched";
import { ClinicalEndpointsTable } from "../../utils/clinicalEndpointsTable";

interface Props {
  clinicalEndpointsTable: ClinicalEndpointsTable;
  tableIndex: number;
}

const ClinicalEndpointsTableContainer = ({
  clinicalEndpointsTable,
  tableIndex,
}: Props) => {
  const [switchTable, setSwitchTable] = useState(
    clinicalEndpointsTable.title.switch,
  );

  const changeSwitchTable = () => {
    setSwitchTable(!switchTable);
  };

  return (
    <>
      {switchTable ? (
        <ClinicalEndpointsTableSwitched
          clinicalEndpointsTable={clinicalEndpointsTable}
          tableIndex={tableIndex}
          switchTable={changeSwitchTable}
        />
      ) : (
        <ClinicalEndpointsTableContent
          clinicalEndpointsTable={clinicalEndpointsTable}
          tableIndex={tableIndex}
          switchTable={changeSwitchTable}
        />
      )}
    </>
  );
};

export default ClinicalEndpointsTableContainer;
