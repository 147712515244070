import React, { useEffect, useRef, useState } from "react";

import { API_RESPONSE_CODE } from "@frontend/common";
import imageCompression from "browser-image-compression";

import { useGetAiImageData } from "@api/aiImages/aiImages";
import {
  useArticleInfoMutation,
  useArticleInfoQuery,
} from "@api/articleInfo/useArticleInfo";
import { getImageSearchData } from "@api/imageSearch/imageSearch";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { imageApis } from "@services/image.service";
import { getPressIdFromURL, getTabTypeFromURL } from "@utils/getStateFromURL";
import { toastDraftSave, toastFailUpdatedEditor } from "@utils/toast";
import { trimCharacter } from "@utils/trimCharacter";

import { useImageFilterKeyword } from "./useImageFilterKeyword";
import {
  DEFAULT_IMAGE_FILTER,
  useInsertImageFilter,
} from "../contexts/InsertImageFilterContext";
import { useInsertImageHistoryInfo } from "../contexts/InsertImageHistoryInfoContext";
import {
  InsertImageFilterKeys,
  SelectedImageLabelList,
} from "../types/filterKeyword.types";
import {
  HistoryImageInfo,
  HistoryImageList,
  ImageInfoType,
  ImageListData,
  ImageType,
  SelectImageType,
} from "../types/insertImage.types";
import {
  failByApiCallUploadImageAlert,
  failByDuplicatedUploadImageAlert,
} from "../utils/failUploadImageAlert";

interface UseHandleInsertImageReturn {
  imageIndex: number;
  targetImagePath: string | ImageType;
  disableUndo: boolean;
  disableRedo: boolean;
  historyImageList: HistoryImageList;
  setHistoryImageList: React.Dispatch<React.SetStateAction<HistoryImageList>>;
  handleSaveAsDraftInsertImage: () => void;
  addHistoryImageList: (
    type: SelectImageType,
    index?: number,
    imageList?: ImageListData[],
  ) => void;
  undoImage: () => void;
  redoImage: () => void;
  setStatusToInsertImage: () => void;
  selectFile: File | null;
  previewImage: ImageType | null;
  selectedLabelList: SelectedImageLabelList;
  changeSelectedLabelList: (labelList: SelectedImageLabelList) => void;
  handlePreviewImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  uploadImageToStorage: () => void;
  handleImageLabel: (
    event: React.ChangeEvent<HTMLInputElement>,
    labelName: InsertImageFilterKeys,
  ) => void;
  handleDeleteUploadFile: (event: React.MouseEvent<HTMLButtonElement>) => void;
  tabMenuType: SelectImageType;
  handleTabMenuType: (type: SelectImageType) => void;
  handleArticleImagePreview: () => void;
  handleImageInfoInput: (
    event: React.ChangeEvent<HTMLInputElement>,
    name: "artist" | "source",
  ) => void;
  canUploadDB: boolean;
  imageInfo: ImageInfoType;
  imageSourceRef: React.RefObject<HTMLInputElement>;
  artistRef: React.RefObject<HTMLInputElement>;
  checkedUploadedImage: () => boolean;
}

const DEFAULT_IMAGE_INFO = {
  artist: "",
  source: "Shutterstock",
};

export const useHandleInsertImage = (): UseHandleInsertImageReturn => {
  const { insertImageFilter, changeInsertImageFilter } = useInsertImageFilter();
  const imageSourceRef = useRef<HTMLInputElement>(null);
  const artistRef = useRef<HTMLInputElement>(null);

  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );
  const { mutate: updateArticleInfo } = useArticleInfoMutation(
    Number(getPressIdFromURL()),
  );

  const { insertImageHistoryInfo, changeInsertImageHistoryInfo } =
    useInsertImageHistoryInfo();

  const addHistoryImageInfo = (info: HistoryImageInfo) => {
    changeInsertImageHistoryInfo([...insertImageHistoryInfo, info]);
  };

  const [historyImageList, setHistoryImageList] = useState<HistoryImageList>(
    [],
  );

  const [historyImageIndex, setHistoryImageIndex] = useState<number>(-1);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState<number>(-1);
  const [targetImagePath, setTargetImagePath] = useState<string | ImageType>(
    articleInfo?.imagePath || "",
  );

  const [selectFile, setSelectFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<ImageType | null>(null);
  const [selectedLabelList, setSelectedLabelList] =
    useState<SelectedImageLabelList>([]);

  const initTabMenuType = getTabTypeFromURL() === "ai" ? "ai" : "select";
  const [tabMenuType, setTabMenuType] =
    useState<SelectImageType>(initTabMenuType);
  const [canUploadDB, setCanUploadDB] = useState<boolean>(false);
  const [imageInfo, setImageInfo] = useState<ImageInfoType>(DEFAULT_IMAGE_INFO);

  const { keywordList } = useImageFilterKeyword();

  const setStatusToInsertImage = async () => {
    if (articleInfo?.draftArticleStatus === DRAFT_STATUS_CODE.insertImage)
      return;

    await updateArticleInfo({
      draftArticleStatus: DRAFT_STATUS_CODE.insertImage,
    });
  };

  const handleSaveAsDraftInsertImage = async () => {
    const pbEditorName = trimCharacter(articleInfo?.pbEditorName);

    if (pbEditorName === null || pbEditorName === "") {
      try {
        await updateArticleInfo({
          type: "PB",
        });
      } catch (error) {
        toastFailUpdatedEditor();
        return;
      }
    }

    updateArticleInfo({
      draftArticleStatus: DRAFT_STATUS_CODE.insertImage,
      draftArticleImg: insertImageHistoryInfo[currentHistoryIndex]?.imageId,
    });
    toastDraftSave();
  };

  const undoImage = () => {
    const targetIndex = currentHistoryIndex - 1;
    const targetImageInfo = insertImageHistoryInfo[targetIndex];

    if (targetIndex < 0) return;

    setCurrentHistoryIndex(targetIndex);
    setTargetImagePath(targetImageInfo.imagePath);

    if (targetImageInfo?.type === "select") {
      changeInsertImageFilter(targetImageInfo.imageFilter);
      setPreviewImage(null);
      setSelectedLabelList([]);
      setTabMenuType("select");
    }

    if (targetImageInfo?.type === "upload") {
      setPreviewImage(targetImageInfo.imagePath);
      setSelectedLabelList(targetImageInfo.imageKeywordList);
      setTabMenuType("upload");
      setImageInfo({
        artist: targetImageInfo.artistName,
        source: targetImageInfo.platform,
      });
    }
    if (targetImageInfo?.type === "ai") {
      setCurrentHistoryIndex(targetIndex);
      setPreviewImage(null);
      setSelectedLabelList([]);
      setTabMenuType("ai");
    }
  };

  const redoImage = () => {
    const targetIndex = currentHistoryIndex + 1;
    const targetImageInfo = insertImageHistoryInfo[targetIndex];
    if (currentHistoryIndex === insertImageHistoryInfo.length) return;

    setHistoryImageIndex(targetIndex);
    setTargetImagePath(targetImageInfo.imagePath);

    if (targetImageInfo?.type === "select") {
      changeInsertImageFilter(targetImageInfo.imageFilter);
      setCurrentHistoryIndex(targetIndex);
      setPreviewImage(null);
      setSelectedLabelList([]);
      setTabMenuType("select");
    }
    if (targetImageInfo?.type === "upload") {
      setPreviewImage(targetImageInfo.imagePath);
      setSelectedLabelList(targetImageInfo.imageKeywordList);
      setTabMenuType("upload");
      setImageInfo({
        artist: targetImageInfo.artistName,
        source: targetImageInfo.platform,
      });
    }
    if (targetImageInfo?.type === "ai") {
      setCurrentHistoryIndex(targetIndex);
      setPreviewImage(null);
      setSelectedLabelList([]);
      setTabMenuType("ai");
    }
  };

  const getImageList = getImageSearchData(insertImageFilter);
  const getAiImageList = useGetAiImageData();

  const addHistoryImageList = async (type: SelectImageType, index?: number) => {
    if (type === "select") {
      if (index === undefined) return;

      const targetImageList = getImageList?.data.data || [];
      const prevHistoryInfoLength = insertImageHistoryInfo.length;

      addHistoryImageInfo({
        imageId: targetImageList[index].imageId,
        imagePath: targetImageList[index].imagePath,
        imageIndex: index,
        type: "select",
        imageFilter: insertImageFilter,
      });
      setCurrentHistoryIndex(prevHistoryInfoLength);
      setTargetImagePath(targetImageList[index].imagePath);
    }

    if (type === "ai") {
      if (index === undefined) return;

      const targetImageList = getAiImageList?.data || [];
      const prevHistoryInfoLength = insertImageHistoryInfo.length;

      addHistoryImageInfo({
        imageId: targetImageList[index].imageId,
        imagePath: targetImageList[index].imagePath,
        imageIndex: index,
        type: "ai",
      });
      setCurrentHistoryIndex(prevHistoryInfoLength);
      setTargetImagePath(targetImageList[index].imagePath);
    }
  };

  const handleImageLabel = (
    event: React.ChangeEvent<HTMLInputElement>,
    labelName: InsertImageFilterKeys,
  ) => {
    if (event.target.checked) {
      setSelectedLabelList([
        ...selectedLabelList,
        {
          directoryName: labelName,
          keyword: event.target.value.replace("INPUT__", ""),
        },
      ]);
    } else {
      setSelectedLabelList(
        selectedLabelList.filter(
          (label) =>
            label.keyword !== event.target.value.replace("INPUT__", ""),
        ),
      );
    }
  };

  const changeSelectedLabelList = (labelList: SelectedImageLabelList) => {
    setSelectedLabelList(labelList);
  };

  const handlePreviewImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (previewImage) {
      setSelectFile(null);
      setPreviewImage(null);
      setSelectedLabelList([]);
      setTargetImagePath("");
      setImageInfo(DEFAULT_IMAGE_INFO);

      if (imageSourceRef.current)
        imageSourceRef.current.value = DEFAULT_IMAGE_INFO.source;
      if (artistRef.current)
        artistRef.current.value = DEFAULT_IMAGE_INFO.artist;
    }

    const reader = new FileReader();
    if (event.currentTarget.files) {
      setSelectFile(event.currentTarget.files[0]);
      reader.readAsDataURL(event.currentTarget.files[0]);
      reader.onloadend = () => {
        const resultImage: ImageType = reader.result;
        setPreviewImage(resultImage);
      };
    }
  };
  const uploadImageToStorage = async () => {
    if (selectFile) {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 3000,
      };
      const compressedBlobData = await imageCompression(selectFile, options);

      const blobToFile = (theBlob: Blob) => {
        return new File([theBlob], selectFile.name, {
          lastModified: new Date().getTime(),
          type: theBlob.type,
        });
      };

      const compressedFileData = blobToFile(compressedBlobData);

      const formData = new FormData();
      formData.append("imageFile", compressedFileData);

      const info = {
        imageDirName: "B10101",
        platform: imageInfo.source,
        artistName: imageInfo.artist,
        imageMainKeywordName: selectedLabelList[0].keyword,
        imageKeywordDataList: selectedLabelList,
      };
      const blob = new Blob([JSON.stringify(info)], {
        type: "application/json",
      });
      formData.append("imageData", blob);

      try {
        const { data, code, message } = await imageApis.upload(formData);

        if (code === API_RESPONSE_CODE.des.duplicatedUploadImage) {
          failByDuplicatedUploadImageAlert(message);
          return;
        }

        changeInsertImageHistoryInfo([
          ...insertImageHistoryInfo,
          {
            imageId: data.imageId,
            imagePath: data.imagePath,
            artistName: data.artistName,
            platform: data.platform,
            imageKeywordList: selectedLabelList,
            isDBSaved: true,
            type: "upload",
          },
        ]);
        setCurrentHistoryIndex(insertImageHistoryInfo.length);
        setHistoryImageIndex(historyImageList.length + 1);
        setTargetImagePath(data.imagePath);
        setSelectFile(null);
        setPreviewImage(null);
        setSelectedLabelList([]);
        setImageInfo(DEFAULT_IMAGE_INFO);

        if (imageSourceRef.current)
          imageSourceRef.current.value = DEFAULT_IMAGE_INFO.source;
        if (artistRef.current)
          artistRef.current.value = DEFAULT_IMAGE_INFO.artist;
      } catch (error) {
        failByApiCallUploadImageAlert();
      }
    }
  };
  const handleDeleteUploadFile = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    setSelectFile(null);
    setPreviewImage(null);
    setSelectedLabelList([]);
    setImageInfo(DEFAULT_IMAGE_INFO);
    setTargetImagePath("");

    if (imageSourceRef.current)
      imageSourceRef.current.value = DEFAULT_IMAGE_INFO.source;
    if (artistRef.current) artistRef.current.value = DEFAULT_IMAGE_INFO.artist;
  };
  const handleTabMenuType = (type: SelectImageType) => {
    setTabMenuType(type);
  };
  const handleArticleImagePreview = () => {
    setTargetImagePath(previewImage);
  };
  const handleImageInfoInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: "artist" | "source",
  ) => {
    event.preventDefault();
    setImageInfo({ ...imageInfo, [name]: event.currentTarget.value });
  };
  const checkedUploadedImage = () => {
    if (historyImageList.length === 0) return false;
    if (historyImageList[historyImageIndex]?.type === "select") return false;
    if (historyImageList[historyImageIndex]?.type === "upload") return true;
    return false;
  };

  useEffect(() => {
    const isDisabledUploadImageToDB =
      !previewImage ||
      selectedLabelList.length === 0 ||
      imageInfo.artist === "" ||
      imageInfo.source === "" ||
      targetImagePath === "" ||
      targetImagePath !== previewImage;

    setCanUploadDB(!isDisabledUploadImageToDB);
  }, [previewImage, selectedLabelList, imageInfo, targetImagePath]);

  useEffect(() => {
    changeInsertImageFilter({
      ...insertImageFilter,
      filter: {
        keyword: keywordList,
      },
    });

    changeInsertImageHistoryInfo([
      {
        imageId: articleInfo?.draftArticleImg || 0,
        imagePath: articleInfo?.imagePath || "",
        imageIndex: -1,
        type: "select",
        imageFilter: {
          ...insertImageFilter,
          filter: {
            keyword: keywordList,
          },
        },
      },
    ]);
    // DESCRIPTION : 페이지 처음 진입시 기본 filter 값, History 값 설정
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeInsertImageFilter({
      ...DEFAULT_IMAGE_FILTER,
      filter: {
        keyword: keywordList,
      },
    });
    // NOTE : keywordList가 변경되면, 이미지 리스트가 렌더링 되도록 설정
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordList]);

  return {
    imageIndex: currentHistoryIndex,
    targetImagePath,
    disableUndo: currentHistoryIndex <= 1,
    disableRedo: currentHistoryIndex >= insertImageHistoryInfo.length - 1,
    historyImageList,
    handleSaveAsDraftInsertImage,
    addHistoryImageList,
    undoImage,
    redoImage,
    setHistoryImageList,
    selectFile,
    previewImage,
    selectedLabelList,
    changeSelectedLabelList,
    handlePreviewImage,
    uploadImageToStorage,
    handleImageLabel,
    handleDeleteUploadFile,
    tabMenuType,
    handleTabMenuType,
    handleArticleImagePreview,
    handleImageInfoInput,
    canUploadDB,
    imageInfo,
    imageSourceRef,
    artistRef,
    checkedUploadedImage,
    setStatusToInsertImage,
  };
};
