import { apiClient } from "@api/apiClient";
import { AuthorizeData } from "@interface/auth.interface";

export const REFRESH_API_ENDPOINT = {
  post: "/auth/refresh",
};

interface Args {
  refreshToken: string;
}

export const refreshApis = {
  post: async ({ refreshToken }: Args) => {
    const { data } = await apiClient.post<AuthorizeData>(
      REFRESH_API_ENDPOINT.post,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      },
    );

    return {
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
      roles: data.roles,
      authorityType: data.authorityType,
    };
  },
};
