import {
  CLINICAL_TRIAL_DESIGN_1_SHELL_KEYS,
  CLINICAL_TRIAL_DESIGN_GROUP_TABLE_KEY,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
} from "@frontend/common";

import { BASIC_DATA_FOR_ADDING_TABLES } from "@FillInTableContainer/shared/constants/tableCreatorTable.constants";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useUpdateEntryData } from "@hooks/useUpdateArticleData";
import { useEntryDataState } from "@stores/entryData";
import { useInitEntryDataValue } from "@stores/initEntryData";
import { deepCopy } from "@utils/deepCopy";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { CHECK_TABLE_KEY_BY_GROUP_TABLE_KEY } from "../constants/tableCreatorTable.constants";

import type {
  ClinicalTrialsSection,
  DrugProfileValue,
} from "@/types/clinicalTrials.types";

interface UseHandleTableCreatorGroupTableReturn {
  handleAddTable: (tableIndex: number) => void;
  isFocused: boolean;
}

interface Props {
  groupTableKey: ClinicalTrialsSection;
}

export const useHandleClinicalTrialsTableCreatorGroupTable = ({
  groupTableKey,
}: Props): UseHandleTableCreatorGroupTableReturn => {
  const articleId = getPressIdFromURL();
  const articleSection = "Clinical Trials";
  const [entryData, setEntryData] = useEntryDataState(articleId);
  const initEntryData = useInitEntryDataValue(articleId);
  const updateEntryData = useUpdateEntryData();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();

  const isFocused = info.groupTableKey === groupTableKey;

  const handleAddTable = (tableIndex: number) => {
    if (focusStatus.container !== "noFocus") return;

    const targetTableListInEntryData =
      deepCopy(entryData?.[articleSection]?.[groupTableKey]) || [];

    if (!Array.isArray(targetTableListInEntryData)) return;

    const newArrayLength = targetTableListInEntryData.length + 1;
    const emptyArray = new Array(newArrayLength).fill({});

    const addTableData = () => {
      return emptyArray.map((_, index) => {
        if (index === tableIndex) {
          return BASIC_DATA_FOR_ADDING_TABLES[articleSection]?.[groupTableKey](
            initEntryData,
          );
        }
        if (index > tableIndex) {
          return targetTableListInEntryData[index - 1];
        }
        return targetTableListInEntryData[index];
      });
    };

    const clinicalTrialDesign1TableLength =
      entryData?.[articleSection]?.[CLINICAL_TRIAL_DESIGN_GROUP_TABLE_KEY]
        ?.length || 0;

    const updatedEntryData =
      clinicalTrialDesign1TableLength > 1 &&
      newArrayLength === 2 &&
      groupTableKey === "Drug Profile"
        ? {
            [articleSection]: {
              ...entryData[articleSection],
              [groupTableKey as "Drug Profile"]:
                addTableData() as DrugProfileValue[],
              [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: entryData[articleSection]?.[
                ENTRY_DATA_CLINICAL_TRIAL_DESIGN
              ]?.map((tableItem) => ({
                ...tableItem,
                [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]: {
                  ...tableItem[
                    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                  ],
                  [CLINICAL_TRIAL_DESIGN_1_SHELL_KEYS.trialIntervention]: [],
                },
              })),
            },
          }
        : {
            [articleSection]: {
              ...entryData[articleSection],
              [groupTableKey]: addTableData(),
            },
          };

    setEntryData(updatedEntryData);
    updateEntryData(updatedEntryData);
    changeCurrentHistory({
      entryData: updatedEntryData,
      changedDataInfo: {
        target: "table",
        action: "add",
        position: {
          ...DEFAULT_POSITION_INFO,
          groupTableKey,
          tableIndex,
          tableKey:
            CHECK_TABLE_KEY_BY_GROUP_TABLE_KEY[articleSection][groupTableKey],
          value: "",
        },
      },
    });
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeInfo(DEFAULT_POSITION_INFO);
  };

  return {
    handleAddTable,
    isFocused,
  };
};
