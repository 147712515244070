import UndoRedoButton from "@/components/shared/ui/UndoRedoButton/UndoRedoButton";
import { useSaveEditedArticle } from "@EditArticleContainer/shared/hooks/useSaveEditedArticle";

const ArticleDetailFabContentBox = () => {
  const { saveArticleContent } = useSaveEditedArticle();

  const handleSaveDraftButtonClick = async () => {
    await saveArticleContent();
  };

  return (
    <UndoRedoButton
      undoIsDisabled
      saveIsDisabled={false}
      saveOnClickHandler={handleSaveDraftButtonClick}
      redoIsDisabled
    />
  );
};

export default ArticleDetailFabContentBox;
