import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconRotate = ({ width = 20, height = 20, color = "#ffffff" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7928 4.4321C9.58525 4.26082 8.35477 4.48664 7.28676 5.07553C6.21875 5.66442 5.37107 6.58448 4.87146 7.69705C4.37184 8.80962 4.24736 10.0544 4.51677 11.2439C4.78618 12.4334 5.43489 13.5031 6.36514 14.2918C7.29539 15.0805 8.45678 15.5455 9.6743 15.6168C10.8918 15.688 12.0995 15.3616 13.1154 14.6867C14.1313 14.0118 14.9003 13.0251 15.3065 11.8751C15.4215 11.5497 15.7786 11.379 16.104 11.494C16.4295 11.609 16.6001 11.9661 16.4852 12.2915C15.9886 13.697 15.0487 14.9031 13.8071 15.7279C12.5655 16.5527 11.0894 16.9517 9.6013 16.8646C8.11322 16.7776 6.69374 16.2092 5.55677 15.2452C4.4198 14.2812 3.62693 12.9738 3.29765 11.52C2.96837 10.0662 3.12051 8.5448 3.73115 7.18498C4.3418 5.82517 5.37785 4.70066 6.6832 3.98091C7.98854 3.26115 9.49246 2.98515 10.9683 3.19448C12.441 3.40337 13.8061 4.0842 14.8588 5.13467L17.0139 7.16405L17.0139 4.44444C17.0139 4.09926 17.2937 3.81944 17.6389 3.81944C17.9841 3.81943 18.2639 4.09926 18.2639 4.44443L18.2639 8.61111C18.2639 8.77687 18.1981 8.93584 18.0808 9.05305C17.9636 9.17026 17.8047 9.23611 17.6389 9.23611L13.4722 9.2361C13.127 9.2361 12.8472 8.95628 12.8472 8.6111C12.8472 8.26592 13.127 7.9861 13.4722 7.9861L16.0635 7.98611L13.9952 6.03834L13.9815 6.02513C13.1194 5.16247 12.0003 4.60337 10.7928 4.4321Z"
        fill={color}
      />
    </svg>
  );
};

IconRotate.displayName = "Rotate";
export default IconRotate;
