"use client";

import styled, { css } from "styled-components";

export const TableContentsWrapper = styled.div<{
  $containerDirection: "left" | "right" | "both";
}>`
  display: flex;
  flex-direction: row;
  gap: 3.2rem;
  width: 100%;

  ${({ $containerDirection }) => {
    if ($containerDirection === "left") {
      return css`
        justify-content: flex-start;
      `;
    }
    if ($containerDirection === "right") {
      return css`
        justify-content: flex-end;
      `;
    }
    return css`
      justify-content: space-between;
    `;
  }}
`;

export const TableGroupWrapper = styled.div<{ $isArrowVisible: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;

  gap: 0;
  width: calc(50% - 1.6rem);
  height: fit-content;

  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: #f8f9fa;
  padding: var(--Spacing-0, 0rem) var(--Spacing-16, 1.6rem)
    var(--Spacing-16, 1.6rem) var(--Spacing-16, 1.6rem);

  ${({ $isArrowVisible }) =>
    $isArrowVisible &&
    css`
      min-height: 24rem;
    `}
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  flex: 1;
  width: 100%;
  height: fit-content;

  .item-in-row-true {
    border-top: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  }

  .item-in-row-false {
    border-bottom: 0;
  }
  .border-bottom-none,
  .border-bottom-none-true {
    border-bottom: 0;
  }

  .existed-value-text-false {
    display: none;
  }

  .item-in-row-count-1 {
    .existed-value-text-true {
      &.existed-value-text-0 {
        border-right: 0;
      }
    }
  }
`;

export const ContentsWrapper = styled.div`
  margin-bottom: 2.4rem;
  &:last-child {
    margin-bottom: 0;
  }
  .default-table-container {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
    border-left: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
    border-right: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  }
  .top-radius {
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    border-top: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);

    overflow: hidden;
  }
  .bottom-radius {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;

    overflow: hidden;
  }
  .deal-terms-table-value {
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    .value-index-0 {
      font-weight: 600;

      & > div:empty {
        background-color: red;
        padding: 0 !important;
        min-height: 0;
      }

      & > div:empty::before {
        content: "";
      }

      ul {
        padding-left: 0;
      }

      li {
        padding-left: 0rem;
        list-style-type: none;

        &::marker {
          content: "";
        }
      }
    }
    .value-index-1 {
      padding-left: 0.4rem;

      & > div:empty {
        background-color: red;
        padding: 0 !important;
        min-height: 0;
      }

      & > div:empty::before {
        content: "";
      }
    }
  }
`;

export const ArrowContainer = styled.div`
  position: absolute;
  top: 0;
  left: -3.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 100%;
  z-index: 1;
`;
