import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { ARTICLE_SECTION_CODE } from "../constants/fillInTable.constants";

const useArticleSection = () => {
  const draftArticleId = getPressIdFromURL();
  const { data: articleInfo } = useArticleInfoQuery(Number(draftArticleId));
  const classificationCode = articleInfo?.classificationManual;

  if (!classificationCode) {
    throw new Error("classificationCode is not defined");
  }

  return ARTICLE_SECTION_CODE[classificationCode];
};

export default useArticleSection;
