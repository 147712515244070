import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const IconKeyCommentProfile = ({ width = 88, height = 88 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11626_67926)">
        <g opacity="0.8">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.6779 37.4368C49.8174 37.4368 54.7945 32.4597 54.7945 26.3202C54.7945 20.1807 49.8174 15.2036 43.6779 15.2036C37.5384 15.2036 32.5613 20.1807 32.5613 26.3202C32.5613 32.4597 37.5384 37.4368 43.6779 37.4368ZM53.4286 39.2036H34.5714C31.2373 39.2036 28.0397 40.3524 25.6821 42.3972C23.3245 44.442 22 47.2153 22 50.1071V68.6478C22 68.9548 22.2489 69.2036 22.5558 69.2036H31.4286H56.5714H65.4442C65.7511 69.2036 66 68.9548 66 68.6478V50.1071C66 47.2153 64.6755 44.442 62.3179 42.3972C59.9603 40.3524 56.7627 39.2036 53.4286 39.2036Z"
            fill="url(#paint0_linear_11626_67926)"
          />
          <g filter="url(#filter0_bdiii_11626_67926)">
            <path
              d="M44.1166 65.2546C42.7932 65.2546 41.6683 64.8109 40.7419 63.9235C39.8155 63.0361 39.3523 61.9586 39.3523 60.6909V51.5636C39.3523 50.296 39.8155 49.2184 40.7419 48.3311C41.6683 47.4437 42.7932 47 44.1166 47C45.44 47 46.5649 47.4437 47.4912 48.3311C48.4176 49.2184 48.8808 50.296 48.8808 51.5636V60.6909C48.8808 61.9586 48.4176 63.0361 47.4912 63.9235C46.5649 64.8109 45.44 65.2546 44.1166 65.2546ZM42.7064 75.9031C42.6081 75.9031 42.5285 75.8235 42.5285 75.7252V71.3818C42.5285 71.2923 42.4618 71.2169 42.3732 71.2044C39.6901 70.8256 37.4656 69.6537 35.6997 67.6885C33.9402 65.7304 33.0407 63.4572 33.0013 60.869C32.9999 60.7708 33.0796 60.6909 33.1779 60.6909H35.9983C36.0965 60.6909 36.176 60.7707 36.178 60.8689C36.2206 62.8966 36.9945 64.6313 38.4995 66.073C40.0484 67.5567 41.9208 68.298 44.1166 68.297C46.3134 68.297 48.1863 67.5552 49.7352 66.0715C51.2402 64.6298 52.0136 62.8956 52.0552 60.869C52.0572 60.7707 52.1367 60.6909 52.2349 60.6909H55.0553C55.1535 60.6909 55.2333 60.7708 55.2318 60.869C55.1925 63.4572 54.293 65.7304 52.5334 67.6885C50.7675 69.6537 48.543 70.8256 45.8599 71.2044C45.7713 71.2169 45.7047 71.2923 45.7047 71.3818V75.7252C45.7047 75.8235 45.625 75.9031 45.5268 75.9031H42.7064Z"
              fill="url(#paint1_linear_11626_67926)"
              fillOpacity="0.1"
              shapeRendering="crispEdges"
            />
            <path
              d="M44.1166 65.1953C42.8079 65.1953 41.6979 64.7571 40.7829 63.8807C39.868 63.0044 39.4116 61.9422 39.4116 60.6909V51.5636C39.4116 50.3124 39.868 49.2502 40.7829 48.3739C41.6979 47.4974 42.8079 47.0593 44.1166 47.0593C45.4252 47.0593 46.5353 47.4974 47.4502 48.3739C48.3651 49.2502 48.8215 50.3124 48.8215 51.5636V60.6909C48.8215 61.9422 48.3651 63.0044 47.4502 63.8807C46.5353 64.7571 45.4252 65.1953 44.1166 65.1953ZM42.7064 75.8438C42.6409 75.8438 42.5878 75.7907 42.5878 75.7252V71.3818C42.5878 71.2622 42.4988 71.1622 42.3815 71.1457C39.7118 70.7688 37.5001 69.6034 35.7438 67.6489C33.9936 65.7012 33.0997 63.4417 33.0606 60.8681C33.0596 60.8035 33.1122 60.7502 33.1779 60.7502H35.9983C36.0634 60.7502 36.1173 60.8033 36.1187 60.8701C36.1617 62.914 36.9424 64.6636 38.4585 66.1158C40.0188 67.6104 41.9059 68.3573 44.1166 68.3563C46.3283 68.3563 48.216 67.6088 49.7762 66.1143C51.2923 64.6621 52.0725 62.9131 52.1145 60.8702C52.1159 60.8033 52.1698 60.7502 52.2349 60.7502H55.0553C55.1209 60.7502 55.1735 60.8035 55.1725 60.8681C55.1334 63.4417 54.2395 65.7012 52.4893 67.6489C50.733 69.6034 48.5213 70.7688 45.8516 71.1457C45.7343 71.1622 45.6454 71.2622 45.6454 71.3818V75.7252C45.6454 75.7907 45.5923 75.8438 45.5268 75.8438H42.7064Z"
              stroke="url(#paint2_linear_11626_67926)"
              strokeWidth="0.118577"
              shapeRendering="crispEdges"
            />
            <path
              d="M44.1166 65.1953C42.8079 65.1953 41.6979 64.7571 40.7829 63.8807C39.868 63.0044 39.4116 61.9422 39.4116 60.6909V51.5636C39.4116 50.3124 39.868 49.2502 40.7829 48.3739C41.6979 47.4974 42.8079 47.0593 44.1166 47.0593C45.4252 47.0593 46.5353 47.4974 47.4502 48.3739C48.3651 49.2502 48.8215 50.3124 48.8215 51.5636V60.6909C48.8215 61.9422 48.3651 63.0044 47.4502 63.8807C46.5353 64.7571 45.4252 65.1953 44.1166 65.1953ZM42.7064 75.8438C42.6409 75.8438 42.5878 75.7907 42.5878 75.7252V71.3818C42.5878 71.2622 42.4988 71.1622 42.3815 71.1457C39.7118 70.7688 37.5001 69.6034 35.7438 67.6489C33.9936 65.7012 33.0997 63.4417 33.0606 60.8681C33.0596 60.8035 33.1122 60.7502 33.1779 60.7502H35.9983C36.0634 60.7502 36.1173 60.8033 36.1187 60.8701C36.1617 62.914 36.9424 64.6636 38.4585 66.1158C40.0188 67.6104 41.9059 68.3573 44.1166 68.3563C46.3283 68.3563 48.216 67.6088 49.7762 66.1143C51.2923 64.6621 52.0725 62.9131 52.1145 60.8702C52.1159 60.8033 52.1698 60.7502 52.2349 60.7502H55.0553C55.1209 60.7502 55.1735 60.8035 55.1725 60.8681C55.1334 63.4417 54.2395 65.7012 52.4893 67.6489C50.733 69.6034 48.5213 70.7688 45.8516 71.1457C45.7343 71.1622 45.6454 71.2622 45.6454 71.3818V75.7252C45.6454 75.7907 45.5923 75.8438 45.5268 75.8438H42.7064Z"
              stroke="url(#paint3_linear_11626_67926)"
              strokeOpacity="0.4"
              strokeWidth="0.118577"
              shapeRendering="crispEdges"
            />
          </g>
          <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.1437 19.9866C57.5555 19.7807 58.0563 19.9477 58.2623 20.3595C60.1291 24.0932 60.1291 28.4881 58.2623 32.2218C58.0563 32.6336 57.5555 32.8006 57.1437 32.5947C56.7318 32.3887 56.5649 31.8879 56.7708 31.4761C58.403 28.2118 58.403 24.3695 56.7708 21.1052C56.5649 20.6934 56.7318 20.1926 57.1437 19.9866Z"
            fill="url(#paint4_linear_11626_67926)"
          />
          <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.8724 17.1477C62.2863 16.9703 62.7657 17.162 62.9431 17.576C65.6346 23.8563 65.6346 30.9652 62.9431 37.2455C62.7657 37.6595 62.2863 37.8512 61.8724 37.6738C61.4584 37.4964 61.2667 37.017 61.4441 36.6031C63.9598 30.733 63.9598 24.0884 61.4441 18.2184C61.2667 17.8045 61.4584 17.3251 61.8724 17.1477Z"
            fill="url(#paint5_linear_11626_67926)"
          />
          <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.7103 20.1903C29.2985 19.9843 28.7977 20.1513 28.5917 20.5631C26.7249 24.2969 26.7249 28.6917 28.5917 32.4254C28.7977 32.8373 29.2985 33.0042 29.7103 32.7983C30.1222 32.5923 30.2891 32.0915 30.0832 31.6797C28.451 28.4154 28.451 24.5731 30.0832 21.3088C30.2891 20.897 30.1222 20.3962 29.7103 20.1903Z"
            fill="url(#paint6_linear_11626_67926)"
          />
          <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.0075 17.1477C25.5936 16.9703 25.1142 17.162 24.9368 17.576C22.2452 23.8563 22.2452 30.9652 24.9368 37.2455C25.1142 37.6595 25.5936 37.8512 26.0075 37.6738C26.4215 37.4964 26.6132 37.017 26.4358 36.6031C23.9201 30.733 23.9201 24.0884 26.4358 18.2184C26.6132 17.8045 26.4215 17.3251 26.0075 17.1477Z"
            fill="url(#paint7_linear_11626_67926)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_bdiii_11626_67926"
          x="-263.44"
          y="-130.865"
          width="496.538"
          height="503.21"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_11626_67926"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-59.2884" dy="59.2884" />
          <feGaussianBlur stdDeviation="118.577" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.00392157 0 0 0 0 0.596078 0 0 0 0 0.913725 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_11626_67926"
            result="effect2_dropShadow_11626_67926"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_11626_67926"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-8" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.00392157 0 0 0 0 0.596078 0 0 0 0 0.913725 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect3_innerShadow_11626_67926"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_innerShadow_11626_67926"
            result="effect4_innerShadow_11626_67926"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="22" dy="3.07" />
          <feGaussianBlur stdDeviation="120" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="effect4_innerShadow_11626_67926"
            result="effect5_innerShadow_11626_67926"
          />
        </filter>
        <linearGradient
          id="paint0_linear_11626_67926"
          x1="35"
          y1="12.2036"
          x2="60.0842"
          y2="97.5974"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13459" stopColor="#89D6FF" stopOpacity="0.68" />
          <stop offset="0.746062" stopColor="#0198E9" stopOpacity="0.76" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11626_67926"
          x1="36.3934"
          y1="47.4061"
          x2="54.6004"
          y2="52.1837"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.12" />
          <stop offset="1" stopColor="white" stopOpacity="0.22" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11626_67926"
          x1="38.5583"
          y1="49.7791"
          x2="47.7495"
          y2="68.4568"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.66" />
          <stop offset="0.85402" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11626_67926"
          x1="44.4993"
          y1="75.7126"
          x2="38.0761"
          y2="70.2043"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0198E9" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11626_67926"
          x1="74.5"
          y1="26.7034"
          x2="53.3478"
          y2="26.8463"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0198E9" />
          <stop offset="1" stopColor="#4FC2FF" stopOpacity="0.31" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_11626_67926"
          x1="68.5"
          y1="28.7035"
          x2="54.5"
          y2="28.2035"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4BBEFC" />
          <stop offset="0.923755" stopColor="#0198E9" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_11626_67926"
          x1="12.354"
          y1="26.907"
          x2="33.5062"
          y2="27.05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0198E9" />
          <stop offset="1" stopColor="#4FC2FF" stopOpacity="0.31" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_11626_67926"
          x1="19.3799"
          y1="28.7035"
          x2="33.3799"
          y2="28.2035"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4BBEFC" />
          <stop offset="0.923755" stopColor="#0198E9" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_11626_67926">
          <rect width="88" height="88" rx="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconKeyCommentProfile.displayName = "KeyCommentProfile";
export default IconKeyCommentProfile;
