import styled from "styled-components";

export const Button = styled.button`
  all: unset;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1;

  color: #0198e9;
`;
