import React from "react";

import * as S from "./RequiredMark.style";

export type RequiredType = "required" | "conditional";

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  type?: RequiredType;
  text?: string;
}

const RequiredMark = ({ type = "required", text, ...rest }: Props) => {
  return (
    <S.RequiredMark type={type} {...rest}>
      {text}
    </S.RequiredMark>
  );
};

export default RequiredMark;
