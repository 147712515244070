import styled, { css } from "styled-components";

export const TitleBackground = styled.div`
  width: 100%;
  background-color: white;
  margin-top: 0.8rem;
`;

export const CustomTitleWrapper = styled.div<{
  isClearTable: boolean;
}>`
  display: flex;
  height: 3.2rem;
  max-width: 60rem;
  padding: 0.8rem 0.6rem;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.color.neural.white};
  border: 1px solid var(--Color-Common-Border-Secondary, #b1b8c4);

  ${({ isClearTable }) =>
    isClearTable &&
    css`
      border: 1px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);
      background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #e6f6ff);
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.16);

      > input {
        background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #e6f6ff);
      }
    `}

  &:hover {
    border: 1px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);
    background: var(--Color-Common-Bg-Primary, #fff);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
      0px 2px 4px 0px rgba(0, 0, 0, 0.16);
    > input {
      background: var(--Color-Common-Bg-Primary, #fff);
    }
  }

  &:active {
    border: 1px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);
    background: var(--Color-Common-Bg-Primary, #fff);
    > input {
      background: var(--Color-Common-Bg-Primary, #fff);
    }
  }

  &:focus-within {
    border: 1px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);
    background: var(--Color-Common-Bg-Primary, #fff);
    > input {
      background: var(--Color-Common-Bg-Primary, #fff);
    }
  }
`;

export const CustomTitleInput = styled.input`
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.color.neural.gray05};
  }
`;
