import styled, { css } from "styled-components";

import IconCheckWhite from "@images/pressList/icon-check-white.svg";

import type { ProcessStatusCode } from "@/types/code.types";

interface FilterTagStyleProps {
  filterType?:
    | "pressListClassification"
    | "status"
    | "pressManualClassification";
  isAll?: boolean;
  code?: ProcessStatusCode;
  aiSelected?: boolean;
}

export const FilterTagLabel = styled.label<FilterTagStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  line-height: 2.4rem;
  border-radius: 1rem;
  padding: 0rem 1.2rem;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.color.neural.gray03};
  background-color: white;

  ${({ filterType }) =>
    filterType === "pressListClassification" &&
    css`
      &.checked-tag-label {
        border: 0.1rem solid ${({ theme }) => theme.color.node.blue};

        span {
          color: ${({ theme }) => theme.color.node.blue};
        }
      }
    `}

  ${({ filterType, aiSelected }) =>
    filterType === "pressListClassification" &&
    aiSelected &&
    css`
      &.checked-tag-label {
        border: 0.1rem solid ${({ theme }) => theme.color.status.todo};

        span {
          color: ${({ theme }) => theme.color.status.todo};
        }
      }
    `}

  ${({ filterType, code }) =>
    filterType === "status" &&
    code &&
    css`
      &.checked-tag-label {
        border: 0.1rem solid ${({ theme }) => theme.status[code]};
      }
    `}

  ${({ filterType }) =>
    filterType === "pressManualClassification" &&
    css`
      padding: 0 0.8rem;

      &.checked-tag-label {
        border: 0.1rem solid ${({ theme }) => theme.color.node.sky};

        span {
          color: ${({ theme }) => theme.color.node.sky};
        }
      }
    `}

  ${({ isAll }) =>
    isAll &&
    css`
      border: none;
    `}
`;

export const FilterInput = styled.input<FilterTagStyleProps>`
  all: unset;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 0.2rem;
  border: 0.1rem solid ${({ theme }) => theme.color.neural.gray04};
  overflow: hidden;
  box-sizing: border-box;

  &:checked {
    border: 0.1rem solid ${({ theme }) => theme.color.node.blue};
    border-radius: 0.2rem;
    background-color: ${({ theme }) => theme.color.node.blue};
    background-image: url(${IconCheckWhite});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.6rem 0.6rem;

    ${({ filterType, aiSelected }) =>
      filterType === "pressListClassification" &&
      aiSelected &&
      css`
        border: 0.1rem solid ${({ theme }) => theme.color.status.todo};
        background-color: ${({ theme }) => theme.color.status.todo};
      `}
  }

  ${({ filterType, code }) =>
    filterType === "status" &&
    code &&
    css`
      &:checked {
        border: 0.1rem solid ${({ theme }) => theme.status[code]};
        background-color: ${({ theme }) => theme.status[code]};
      }
    `}

  ${({ filterType }) =>
    filterType === "pressManualClassification" &&
    css`
      all: inherit;
      width: 1.2rem;
      height: 1.2rem;
      padding: 0;

      &::after {
        content: "";
        width: 0.9rem;
        height: 0.9rem;
        border-radius: 50%;
        box-shadow: 0 0 0 1px #2a2f37;
      }

      &:checked {
        border: 0.1rem solid ${({ theme }) => theme.color.node.sky};
        border-radius: 50%;
        background: none;
      }
      &:checked::after {
        content: "";
        width: calc(100% - 0.2rem);
        height: calc(100% - 0.2rem);
        background-color: ${({ theme }) => theme.color.node.sky};
        border-radius: 50%;
        box-shadow: none;
      }
    `}

    ${({ isAll }) =>
    isAll &&
    css`
      width: 1.2rem;
      height: 1.2rem;
      border: 0.1rem solid ${({ theme }) => theme.color.neural.gray05};

      &:checked {
        border: 0.1rem solid ${({ theme }) => theme.color.node.sky};
        background-color: ${({ theme }) => theme.color.node.sky};
        background-image: url(${IconCheckWhite});
      }
    `}
`;

export const FilterTagText = styled.span<FilterTagStyleProps>`
  display: flex;
  align-items: center;
  height: 1rem;
  margin-left: 0.8rem;
  color: ${({ theme }) => theme.color.neural.gray08};
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 100%;

  ${({ isAll }) =>
    isAll &&
    css`
      margin-left: 0.4rem;
      vertical-align: middle;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 100%;
    `}

  ${({ filterType, code }) =>
    filterType === "status" &&
    code &&
    css`
      color: ${({ theme }) => theme.status[code]};
    `}

  ${({ filterType, aiSelected }) =>
    filterType === "pressManualClassification" &&
    aiSelected &&
    css`
      color: ${({ theme }) => theme.color.status.todo};
    `}
`;
