import React from "react";

import * as S from "./PressListCheckboxFilter.style";

interface Props {
  filterText: string;
  isChecked: boolean;
  onClick: () => void;
}

const PressListCheckboxFilter = ({ filterText, isChecked, onClick }: Props) => {
  return (
    <S.PressListFilterLabel>
      <S.PressListFilterInput
        type="checkbox"
        checked={isChecked}
        onChange={onClick}
      />
      {filterText}
    </S.PressListFilterLabel>
  );
};

export default PressListCheckboxFilter;
