import { ENTRY_DATA_SECTION_KEYS as SECTION_KEYS } from "@frontend/common";

import useArticleSection from "@/components/business/FillInTableContainer/hooks/useArticleSection";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { EXPEDITED_PROGRAM } from "../constants/expeditedProgramKey.constants";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseGetNumberOfGroupShellReturn {
  groupShellCount: (groupShellKey: string | null, tableIndex: number) => number;
}

const GROUP_SHELL_PATH_TO_COUNT_GROUP_SHELL: Record<
  string,
  {
    [key: string]: (data: ArticleEntryData, tableIndex: number) => number;
  }
> = {
  "Clinical Trials": {
    "Endpoints Table Information": (
      data: ArticleEntryData,
      tableIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[tableIndex]?.[
        "Endpoints Table Information"
      ]?.length || 1,
  },
  "Clinical Trial Status": {},
  Regulatory: {
    "Approval Process": (data: ArticleEntryData) =>
      data?.Regulatory?.["Approval Process Contents"]?.["Approval Process"]
        ?.length || 1,
  },
  [EXPEDITED_PROGRAM]: {},
  Deals: {
    "Rights Scope": (data: ArticleEntryData, tableIndex: number) =>
      data?.Deals?.["Licensee Table"]?.[tableIndex]["Rights Scope"]?.length ||
      1,
    "Drug Profile": (data: ArticleEntryData) =>
      data?.Deals?.["Drug Profile"]?.length || 1,
  },
  [SECTION_KEYS.ventureCapital]: {
    "Drug Profile": (data: ArticleEntryData) =>
      data?.Deals?.["Drug Profile"]?.length || 1,
  },
};

export const useGetNumberOfGroupShell = (): UseGetNumberOfGroupShellReturn => {
  const articleSection = useArticleSection();
  const entryData = useEntryDataValue(getPressIdFromURL());

  return {
    groupShellCount: (groupShellKey: string | null, tableIndex: number) =>
      groupShellKey &&
      GROUP_SHELL_PATH_TO_COUNT_GROUP_SHELL[articleSection][groupShellKey]
        ? GROUP_SHELL_PATH_TO_COUNT_GROUP_SHELL[articleSection][groupShellKey](
            entryData,
            tableIndex,
          ) || 1
        : 1,
  };
};
