"use client";

import styled from "styled-components";

export const TableShellTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 4.2rem;

  line-height: 160%;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.neural.gray10};
  background-color: ${({ theme }) => theme.color.neural.gray02};
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.neural.gray03};
  padding: 1.6rem 1.6rem;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    font-size: 1.2rem;
    padding: 1.2rem 0.8rem;
  }
`;
