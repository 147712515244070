import { useNavigate } from "react-router-dom";

import { clearAuthorization } from "@libs/authorization/utils/clearAuthorization";
import { ROUTE_PATHS } from "@routes/routePath";

interface UseHandleSignOutReturn {
  handleSignOut: () => void;
}

export const useHandleSignOut = (): UseHandleSignOutReturn => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    clearAuthorization();
    navigate(ROUTE_PATHS.signIn);
  };

  return { handleSignOut };
};
