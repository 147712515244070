/**
 * NOTE :
 * title 내에 [translate: 문자] 가 있다면, [translate: 문자] 에 <span class="translate-id">[translate: 문자]</span>
 * title 내에 [reupload: 문자] 가 있다면, [reupload: 문자] 에 <span class="reupload-id">[reupload: 문자]</span>
 */
export const labelingIdInTitle = (title: string) => {
  const translateRegex = /\[translate:\s*([^\]]+)\]/g;
  const reuploadRegex = /\[reupload:\s*([^\]]+)\]/g;

  let labeledTitle = title.replace(
    translateRegex,
    `<span class="translate-id">$&</span>`,
  );
  labeledTitle = labeledTitle.replace(
    reuploadRegex,
    `<span class="reupload-id">$&</span>`,
  );

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: labeledTitle }} />;
};
