import styled, { css } from "styled-components";

import { ImageType } from "../../shared/types/insertImage.types";

export const HeaderTitleWrapper = styled.div`
  position: relative;
`;

export const WideButton = styled.button`
  cursor: pointer;
  padding: 0;
  position: absolute;
  bottom: 1.7rem;
  right: 2rem;
`;

export const TopHeaderInfo = styled.div<{
  image: string | ImageType;
  $isWide: boolean;
}>`
  position: relative;
  width: 100%;
  background-image: ${({ image }) => `url("${image}")`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-blend-mode: darken, normal, normal, normal;
  border-radius: 1.2rem;
  padding: 3.2rem 4rem;

  ${({ $isWide }) =>
    $isWide &&
    css`
      padding: 6.4rem 4rem;
    `}
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-blend-mode: darken, normal, normal, normal;
  z-index: 0;
  border-radius: 1.2rem;
`;

export const TopHeaderInfoContents = styled.div`
  position: relative;
  z-index: 1;
`;

export const ArticleTitle = styled.h4`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 120%;
  color: ${({ theme }) => theme.color.neural.gray00};
  background: rgba(255, 255, 255, 0.2);
  margin-bottom: 1.6rem;
`;

export const ArticleBannerImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
`;
