import { ARTICLE_CONTENTS_DEAL_TERMS_KEYS as DEAL_TERMS_KEYS } from "@frontend/common";

import { DealTermsContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/dealTermsContent.types";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

export const licensorNameText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licensorTable][
        DEAL_TERMS_KEYS.licensorName
      ]?.value?.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });
export const licensorNameLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licensorTable][
    DEAL_TERMS_KEYS.licensorName
  ].value.at(0)?.labels ?? [];

export const licenseeNameText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
        ?.at(0)
        ?.[DEAL_TERMS_KEYS.licenseeName]?.value?.map(
          (item) => item.text ?? "",
        ) ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const licenseeNameLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
    .at(0)
    ?.[DEAL_TERMS_KEYS.licenseeName].value.at(0)?.labels ?? [];

export const licensorResponsibilityText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList: dealTerms.text[DEAL_TERMS_KEYS.dealTerms][
      DEAL_TERMS_KEYS.licensorTable
    ][DEAL_TERMS_KEYS.licensorResponsibilityRights]?.value?.map(
      (item) => item.text ?? "",
    ),
    parseString: (text) => `- ${text?.trim()}`,
  });

export const licensorResponsibilityLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licensorTable][
    DEAL_TERMS_KEYS.licensorResponsibilityRights
  ]?.value.at(0)?.labels ?? [];

export const licenseeResponsibilityText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
        .at(0)
        ?.[DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms][
          DEAL_TERMS_KEYS.licenseeResponsibilityRightsScLa
        ]?.value?.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const licenseeResponsibilityLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
    .at(0)
    ?.[DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms][
      DEAL_TERMS_KEYS.licenseeResponsibilityRightsScLa
    ]?.value.at(0)?.labels ?? [];

export const licenseeUpfrontText = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
    .at(0)
    ?.[DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms][
      DEAL_TERMS_KEYS.upfrontPayment
    ].value.at(0)?.text ?? "";

export const licenseeUpfrontLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
    .at(0)
    ?.[DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms][
      DEAL_TERMS_KEYS.upfrontPayment
    ].value.at(0)?.labels ?? [];

export const licenseeMilestoneText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
        .at(0)
        ?.[DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms][
          DEAL_TERMS_KEYS.milestonePaymentAndCondition
        ].map((item) =>
          [
            item[DEAL_TERMS_KEYS.milestonePayment].value.at(0)?.text?.trim() ??
              "",
            item[DEAL_TERMS_KEYS.milestoneConditionTitle].value
              ?.at(0)
              ?.text?.trim()
              ? `(${item[DEAL_TERMS_KEYS.milestoneConditionTitle].value
                  .at(0)
                  ?.text?.trim()})`
              : "",
          ]
            .filter((text) => text !== "")
            .join(" "),
        ) ?? [],
    parseString: (text) => `- ${text}`,
    bulletStartIndex: 1,
  });

export const licenseeMilestoneLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
    .at(0)
    ?.[DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms][
      DEAL_TERMS_KEYS.milestonePaymentAndCondition
    ].at(0)
    ?.[DEAL_TERMS_KEYS.milestonePayment].value.at(0)?.labels ?? [];

export const licenseeOtherPaymentText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
        .at(0)
        ?.[DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms][
          DEAL_TERMS_KEYS.otherPaymentAndDetailScLa
        ]?.map((item) =>
          [
            item[DEAL_TERMS_KEYS.otherPaymentScLa].value.at(0)?.text?.trim() ??
              "",
            item[DEAL_TERMS_KEYS.otherPaymentDetailScLa]?.value
              ?.at(0)
              ?.text?.trim()
              ? `(${item[DEAL_TERMS_KEYS.otherPaymentDetailScLa]?.value
                  ?.at(0)
                  ?.text?.trim()})`
              : "",
          ]
            .filter((text) => text !== "")
            .join(" "),
        ) ?? [],
    parseString: (text) => `- ${text}`,
    bulletStartIndex: 1,
  });

export const licenseeOtherPaymentLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
    .at(0)
    ?.[DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms]?.[
      DEAL_TERMS_KEYS.otherPaymentAndDetailScLa
    ]?.at(0)
    ?.[DEAL_TERMS_KEYS.otherPaymentScLa]?.value?.at(0)?.labels ?? [];

export const licenseeTotalPriceText = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
    .at(0)
    ?.[DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms][
      DEAL_TERMS_KEYS.totalPaymentScLa
    ].value.at(0)?.text ?? "";

export const licenseeTotalPriceLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
    .at(0)
    ?.[DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms][
      DEAL_TERMS_KEYS.totalPaymentScLa
    ].value.at(0)?.labels ?? [];

export const licenseeRoyaltyText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
        .at(0)
        ?.[DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms][
          DEAL_TERMS_KEYS.royalty
        ].value.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const licenseeRoyaltyLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
    .at(0)
    ?.[DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms][
      DEAL_TERMS_KEYS.royalty
    ].value.at(0)?.labels ?? [];

export const licenseeOtherFinancialTermsText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
        .at(0)
        ?.[DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms][
          DEAL_TERMS_KEYS.otherFinancialTermsScLa
        ].value.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const licenseeOtherFinancialTermsLabels = (
  dealTerms: DealTermsContent,
) =>
  dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable]
    .at(0)
    ?.[DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms][
      DEAL_TERMS_KEYS.otherFinancialTermsScLa
    ].value.at(0)?.labels ?? [];
