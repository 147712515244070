import React from "react";

import IconSaveDraft from "@images/common/icon-save-as-draft-sky.svg";
import IconDisabledRedo from "@images/undoRedo/icon-disabled-redo.svg";
import IconDisabledDraft from "@images/undoRedo/icon-disabled-save-draft.svg";
import IconDisabledUndo from "@images/undoRedo/icon-disabled-undo.svg";
import IconRedo from "@images/undoRedo/icon-redo.svg";
import IconUndo from "@images/undoRedo/icon-undo.svg";

import * as S from "./UndoRedoButton.style";

interface Props {
  undoOnClickHandler?: () => void;
  undoIsDisabled: boolean;
  saveOnClickHandler: () => void;
  saveIsDisabled: boolean;
  redoOnClickHandler?: () => void;
  redoIsDisabled: boolean;
}

const UndoRedoButton = ({
  undoIsDisabled,
  undoOnClickHandler,
  saveIsDisabled,
  saveOnClickHandler,
  redoIsDisabled,
  redoOnClickHandler,
}: Props) => {
  return (
    <S.Container className="undo-redo-container">
      <S.UndoButton
        type="button"
        onClick={undoOnClickHandler}
        disabled={undoIsDisabled}
      >
        <img
          src={undoIsDisabled ? IconDisabledUndo : IconUndo}
          alt="undo icon"
        />
        Undo
      </S.UndoButton>
      <S.SaveButton
        type="button"
        onClick={saveOnClickHandler}
        disabled={saveIsDisabled}
      >
        <img
          src={saveIsDisabled ? IconDisabledDraft : IconSaveDraft}
          alt="save as draft"
        />
        Save as Draft
      </S.SaveButton>
      <S.RedoButton
        type="button"
        onClick={redoOnClickHandler}
        disabled={redoIsDisabled}
      >
        Redo
        <img
          src={redoIsDisabled ? IconDisabledRedo : IconRedo}
          alt="redo icon"
        />
      </S.RedoButton>
    </S.Container>
  );
};

export default UndoRedoButton;
