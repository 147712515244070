import React from "react";

import iconPlus from "@images/fillInTable/tableCreator/icon-plus-white.svg";

import { PLUS_BAR_BUTTON_TEXT_SUB_GROUP_SHELL } from "./constants/subGroupShellPlusBarText.constants";
import * as S from "./SubGroupShellDividerPlusBar.style";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isLastPosition: boolean;
  buttonText?: string;
  subGroupShellKey?: string | null;
  isVisibility: boolean;
}

const SubGroupShellDividerPlusBar = ({
  onClick,
  isLastPosition,
  buttonText,
  isVisibility,
  subGroupShellKey = "",
  ...rest
}: Props) => {
  return (
    <S.GroupShellDividerWrapper
      isLastPosition={isLastPosition}
      onClick={onClick}
      isVisibility={isVisibility}
      {...rest}
    >
      <S.GroupShellDividerPlusBar className="divider-plus-bar">
        <S.SubGroupShellPlusButton className="divider-plus-button">
          <img src={iconPlus} alt="add SubgroupShell" />
          {buttonText
            ? `Add ${buttonText}`
            : `Add ${
                PLUS_BAR_BUTTON_TEXT_SUB_GROUP_SHELL[subGroupShellKey || ""] ||
                "ROW"
              }`}
        </S.SubGroupShellPlusButton>
      </S.GroupShellDividerPlusBar>
    </S.GroupShellDividerWrapper>
  );
};

export default SubGroupShellDividerPlusBar;
