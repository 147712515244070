import React from "react";

import IconLink from "@images/pressClassification/icon-link-skyblue.svg";
import { useArticleDataAtomValue } from "@stores/articleEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import * as S from "./PressTitle.style";
import { removeCodeInTitle } from "../../utils/removeCodeInTitle/removeCodeInTitle";

interface Props {
  isLabel?: boolean;
  imageSrc?: string;
}

const PRESS_TITLE_LABEL = "Press Title";

const PressTitle = ({ isLabel = true, imageSrc = IconLink }: Props) => {
  const articleData = useArticleDataAtomValue(getPressIdFromURL());
  const title = articleData["Press Title"];
  const cleanedTitle = removeCodeInTitle(title);

  return (
    <S.PressTitleWrapper>
      {isLabel && <S.PressTitleLabel>{PRESS_TITLE_LABEL}</S.PressTitleLabel>}
      <S.PressTitle
        onClick={() => window.open(`${articleData["Press Url"]}`, "_blank")}
      >
        <img src={imageSrc} alt="link" />
        <strong>{cleanedTitle}</strong>
      </S.PressTitle>
    </S.PressTitleWrapper>
  );
};

export default PressTitle;
