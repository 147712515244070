import React from "react";

import { SwapButton } from "@frontend/common";

import { useCheckDealSwapRoles } from "./hooks/useCheckDealSwapRoles";
import { useDealSwapRoles } from "./hooks/useDealSwapRoles";

type Props = {
  tableKey: string | null;
};

const DealSwapRolesButton = ({ tableKey }: Props) => {
  const { isTargetDealSwapRoles } = useCheckDealSwapRoles({ tableKey });
  const { clickDealSwapButton } = useDealSwapRoles();
  if (!isTargetDealSwapRoles) return null;
  return <SwapButton text="Swap Roles" onClick={clickDealSwapButton} />;
};

export default DealSwapRolesButton;
