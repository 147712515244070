import { IconLeftSingleArrow, IconRightSingleArrow } from "@frontend/common";

import { getImageSearchData } from "@api/imageSearch/imageSearch";
import { useInsertImageFilter } from "@InsertImageContainer/shared/contexts/InsertImageFilterContext";

import * as S from "./Pagination.style";
import { createPageList } from "./Pagination.utils";

export const PAGINATION_LENGTH = 5;

const Pagination = () => {
  const { insertImageFilter, changeInsertImageFilter } = useInsertImageFilter();
  const { page } = insertImageFilter;

  const getImageList = getImageSearchData(insertImageFilter);
  const maxPage = getImageList?.data.totalPage || 1;

  const pageList = createPageList(page, maxPage);

  const handlePrevSingle = () => {
    if (page === 1) return;

    const targetPage =
      page - PAGINATION_LENGTH < 1 ? 1 : page - PAGINATION_LENGTH;
    changeInsertImageFilter({
      ...insertImageFilter,
      page: targetPage,
    });
  };

  const handleNextSingle = () => {
    if (page === maxPage) return;

    const targetPage =
      page + PAGINATION_LENGTH > maxPage ? maxPage : page + PAGINATION_LENGTH;
    changeInsertImageFilter({
      ...insertImageFilter,
      page: targetPage,
    });
  };

  const handleSpecificPage = (targetPage: number) => {
    changeInsertImageFilter({
      ...insertImageFilter,
      page: targetPage,
    });
  };

  return (
    <S.PaginationWrapper>
      <S.MoveButton
        onClick={handlePrevSingle}
        type="button"
        disabled={page - PAGINATION_LENGTH < 1}
      >
        <IconLeftSingleArrow color="#515C6C" />
      </S.MoveButton>

      <S.NumberWrapper>
        {pageList.map((value) => (
          <S.PageNumber
            key={value}
            isTarget={value === page}
            onClick={() => handleSpecificPage(value)}
          >
            {value}
          </S.PageNumber>
        ))}
      </S.NumberWrapper>

      <S.MoveButton
        onClick={handleNextSingle}
        type="button"
        disabled={page + PAGINATION_LENGTH > maxPage}
      >
        <IconRightSingleArrow fill="#515C6C" />
      </S.MoveButton>
    </S.PaginationWrapper>
  );
};

export default Pagination;
