import React from "react";

import iconPlus from "@images/fillInTable/tableCreator/icon-plus-white.svg";

import * as S from "./TableDividerPlusBar.style";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  tableTitle: string;
  onClick: () => void;
  isLastPosition: boolean;
  isVisibility: boolean;
}

const TableDividerPlusBar = ({
  tableTitle,
  onClick,
  isLastPosition,
  isVisibility,
  ...rest
}: Props) => {
  return (
    <S.TableDividerWrapper
      isVisibility={isVisibility}
      isLastPosition={isLastPosition}
      onClick={onClick}
      {...rest}
    >
      <S.TableDividerPlusBar className="divider-plus-bar">
        <S.TablePlusButton className="divider-plus-button">
          <img src={iconPlus} alt="add table" />
          Add {tableTitle} Data
        </S.TablePlusButton>
      </S.TableDividerPlusBar>
    </S.TableDividerWrapper>
  );
};

export default TableDividerPlusBar;
