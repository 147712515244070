"use client";

import styled, { css } from "styled-components";

const RANK_BACKGROUND = {
  1: css`
    background-color: #0b6cff;
    color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  `,
  2: css`
    background: #019df6;
    color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  `,
  3: css`
    background: #32c6d4;
    color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  `,
  4: css`
    border: 1px solid var(--Color-Common-Border-Primary, #7c899c);
    background: var(--Color-Common-Bg-Primary, #fff);
    color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  `,
  5: css`
    border: 1px solid var(--Color-Common-Border-Primary, #7c899c);
    background: var(--Color-Common-Bg-Primary, #fff);
    color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  `,
};

export const ShellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const Date = styled.div`
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
`;

export const RankChip = styled.div<{ $rank?: number }>`
  display: flex;
  padding: 0.35rem var(--Spacing-8, 0.8rem);
  align-items: center;
  gap: var(--Spacing-2, 0.2rem);
  border-radius: var(--Border-Radius-circle, 99999.9rem);
  width: fit-content;
  margin: 0 0.4rem;
  font-family: ${({ theme }) => theme.font.fontFamily.pretendard};
  line-height: 100%;

  ${({ $rank }) => RANK_BACKGROUND[$rank as keyof typeof RANK_BACKGROUND]}
`;
