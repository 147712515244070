import {
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
  TableSwitchButton,
} from "@frontend/common";

import { Table } from "@EditArticleContainer/shared/components/ArticleTable/ArticleTable";
import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { createIndexList } from "@utils/createIndexList";

import { ClinicalTrialDesign2 } from "../../../utils/clinicalTrialDesign2";

interface Props {
  clinicalTrialDesign2: ClinicalTrialDesign2;
  designGroupTableIndex: number;
  switchTable: () => void;
}

const COMMON_EDITABLE_TEXT_STYLE = {
  padding: "0.8rem",
  borderRadius: "0",
};
const VALUE_SHELL_PADDING = "0.2rem";

const ClinicalTrialDesign2TableSwitched = ({
  clinicalTrialDesign2,
  designGroupTableIndex,
  switchTable,
}: Props) => {
  const emptyList = createIndexList(clinicalTrialDesign2.armName.length);
  const isPatientNumberColumn =
    emptyList.filter(
      (_, index) => !!clinicalTrialDesign2.patientsNumber.at(index)?.text,
    ).length !== 0;
  const titleRow = isPatientNumberColumn
    ? [
        {
          refKey: "[Title] TC004-Arm Name Title",
          value: "Arm Name",
        },
        {
          refKey: "[Title] TC004-Patients Number Title",
          value: "Patients Number",
        },
        {
          refKey: "[Title] TC004-Design Group Information Title",
          value: "Design Group Information",
        },
      ]
    : [
        {
          refKey: "[Title] TC004-Arm Name Title",
          value: "Arm Name",
        },
        {
          refKey: "[Title] TC004-Design Group Information Title",
          value: "Design Group Information",
        },
      ];

  const valueRows = emptyList.map((_, index) => {
    const armName = clinicalTrialDesign2.armName.at(index);
    const patientsNumber = clinicalTrialDesign2.patientsNumber.at(index);
    const designGroupInformation =
      clinicalTrialDesign2.designGroupInformation.at(index);

    if (!isPatientNumberColumn) {
      return [
        {
          refKey: armName?.key
            ? `${armName?.key}||${designGroupTableIndex}`
            : "",
          value: armName?.text ?? "",
          labels: armName?.labels ?? [],
          canTextEdit: true,
          canLabelEdit: true,
        },
        {
          refKey: designGroupInformation?.key
            ? `${designGroupInformation?.key}||${designGroupTableIndex}`
            : "",
          value: designGroupInformation?.text ?? "",
          labels: designGroupInformation?.labels ?? [],
          canTextEdit: true,
          canLabelEdit: true,
        },
      ];
    }

    return [
      {
        refKey: armName?.key ? `${armName?.key}||${designGroupTableIndex}` : "",
        value: armName?.text ?? "",
        labels: armName?.labels ?? [],
        canTextEdit: true,
        canLabelEdit: true,
      },
      {
        refKey: patientsNumber?.key
          ? `${patientsNumber?.key}||${designGroupTableIndex}`
          : "",
        value: patientsNumber?.text ?? "",
        labels: patientsNumber?.labels ?? [],
        canTextEdit: true,
        canLabelEdit: true,
      },
      {
        refKey: designGroupInformation?.key
          ? `${designGroupInformation?.key}||${designGroupTableIndex}`
          : "",
        value: designGroupInformation?.text ?? "",
        labels: designGroupInformation?.labels ?? [],
        canTextEdit: true,
        canLabelEdit: true,
      },
    ];
  });

  const tableItems: Table["tableItems"] = [titleRow, ...valueRows];

  return (
    <TableContainer>
      {tableItems.map((row, rowIndex) => {
        if (rowIndex === 0) {
          return (
            <TableRowContainer key={`row-${String(rowIndex)}`}>
              {row.map((shell, shellIndex) => {
                return (
                  <TableShellTitle key={shell.refKey}>
                    {shellIndex === 0 && (
                      <TableSwitchButton onClick={switchTable} />
                    )}
                    {shell.value}
                  </TableShellTitle>
                );
              })}
            </TableRowContainer>
          );
        }
        return (
          <TableRowContainer key={`row-${String(rowIndex)}`}>
            {row.map((shell, shellIndex) => {
              if (shellIndex === 0) {
                return (
                  <TableShellTitle
                    padding={VALUE_SHELL_PADDING}
                    key={shell.refKey}
                  >
                    <EditableTextContainer
                      item={{
                        text: shell.value,
                        labels: shell.labels ?? [],
                      }}
                      canTextEdit
                      canLabelEdit
                      refKey={shell.refKey}
                      style={COMMON_EDITABLE_TEXT_STYLE}
                      data-switch="true"
                    />
                  </TableShellTitle>
                );
              }
              return (
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  key={shell.refKey}
                >
                  <EditableTextContainer
                    item={{
                      text: shell.value,
                      labels: shell.labels ?? [],
                    }}
                    canTextEdit
                    canLabelEdit
                    refKey={shell.refKey}
                    style={COMMON_EDITABLE_TEXT_STYLE}
                  />
                </TableShellValue>
              );
            })}
          </TableRowContainer>
        );
      })}
    </TableContainer>
  );
};

export default ClinicalTrialDesign2TableSwitched;
