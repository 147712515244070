import styled from "styled-components";

import { preventImgDrag, hideScrollbar } from "@styles/mixin";

interface Props {
  draggedPosition: { x: number; y: number };
  modalSizeWidth: number;
}

export const FillInTableArticleModalWrapper = styled.div.attrs<Props>(
  ({ draggedPosition, modalSizeWidth, theme }) => ({
    style: {
      backgroundColor: `#f0f2f4`,
      border: `0.1rem solid ${theme.color.neural.gray03}`,
      boxShadow: `0 0.8rem 1.6rem 0 rgba(0, 0, 0, 0.08)`,
      width: `${modalSizeWidth === 0 ? "100" : modalSizeWidth / 10}rem`,
      minWidth: "30rem",
      position: `fixed`,
      top: `${draggedPosition.y / 10}rem`,
      left: `${draggedPosition.x / 10}rem`,
      zIndex: 99,
      borderRadius: `0.4rem`,
      resize: `both`,
    },
  }),
)<Props>``;

export const FillInTableArticleModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 51.2rem;
  border-radius: 0.4rem;
  resize: both;

  ${hideScrollbar}
`;

export const FillInTableModalHeadLine = styled.div`
  width: 100%;
  height: 3.6rem;
  padding: 1.2rem 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.4rem 0.4rem 0 0;
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
  position: absolute;
  cursor: grab;
`;

export const DragBox = styled.div`
  display: flex;
  height: 1.6rem;
  gap: 0.4rem;
  align-items: center;

  img {
    width: 1.4rem;
    height: 1.2rem;
    ${preventImgDrag}
  }
  span {
    color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
    font-size: 1.4rem;
    font-weight: 500;
  }
`;

export const CloseBox = styled.button`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  cursor: pointer;

  img {
    width: 1.6rem;
    height: 1.6rem;
  }
  span {
    color: var(--Color-Common-Text-Interactive-Destructive, #e53e06);
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

export const FillInTableContentContainer = styled.div`
  width: 100%;
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 1.2rem 1.2rem 1.2rem;
  border-radius: 0 0 0.4rem 0.4rem;

  img {
    width: auto;
    max-width: 100%;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
`;

export const ResizeBorder = styled.div<{ isLeftBorder?: boolean }>`
  height: 100%;
  width: 0.8rem;

  position: absolute;
  left: ${({ isLeftBorder }) => isLeftBorder && 0};
  right: ${({ isLeftBorder }) => !isLeftBorder && 0};
  cursor: col-resize;
`;
