import { ImageType } from "@/types/insertImage.types";
import AIImageBox from "@InsertImageContainer/components/AIImageBox/AIImageBox";
import {
  AiImageList,
  SelectImageType,
} from "@InsertImageContainer/shared/types/insertImage.types";

import * as S from "./AIImageContainer.style";

interface Props {
  aiImageList: AiImageList;
  targetImagePath: string | ImageType;
  addHistoryImageList: (type: SelectImageType, index?: number) => void;
  setStatusToInsertImage: () => void;
}

const AIImageContainer = ({
  aiImageList,
  targetImagePath,
  setStatusToInsertImage,
  addHistoryImageList,
}: Props) => {
  if (aiImageList.length === 0) return null;
  return (
    <S.ImageListContainer>
      {aiImageList?.map((image, index) => (
        <AIImageBox
          key={image.imageId}
          imagePath={image.imagePath}
          artistName={image.artistName}
          platform={image.platform}
          isSelectedImage={targetImagePath === image.imagePath}
          index={index}
          setStatusToInsertImage={setStatusToInsertImage}
          addHistoryImageList={addHistoryImageList}
          imageStatus={image.imageStatus || "SUCCESS"}
        />
      ))}
    </S.ImageListContainer>
  );
};

export default AIImageContainer;
