import { useSuspenseQueries, useSuspenseQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/queryKeys";

import { modalityCategoryApis } from "./modalityCategory.service";

export const useGetModalityCategory = (modalityValue: string) => {
  const query = useSuspenseQuery({
    queryKey: [QUERY_KEYS.getModalityCategory, modalityValue],
    queryFn: () => {
      if (modalityValue === "")
        return {
          categoryName: "",
          confirmed: false,
        };
      return modalityCategoryApis.get(modalityValue);
    },
  });
  return query;
};

export const useGetModalityCategoryQueries = (modalityValues: string[]) => {
  const queries = useSuspenseQueries({
    queries: modalityValues.map((modality) => ({
      queryKey: [QUERY_KEYS.getModalityCategory, modality],
      queryFn: () => {
        if (modality === "")
          return {
            categoryName: "",
            confirmed: false,
          };
        return modalityCategoryApis.get(modality);
      },
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
      };
    },
  });

  return queries;
};
