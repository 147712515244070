import React, { Suspense } from "react";

import { IconExternalLink, Skeleton } from "@frontend/common";

import * as S from "./ListOfDuplicatePress.style";
import { usePressCopiedList } from "../hooks/usePressCopiedList";
import { movePressDetailPage } from "../utils/movePressDetailPage";

const ListOfDuplicatePress = () => {
  const { pressCopiedList, isLoading } = usePressCopiedList();

  return (
    <Suspense>
      <S.ListOfDuplicatePressWrapper>
        <S.ListOfDuplicateTitle>List of Duplicate Press</S.ListOfDuplicateTitle>
        {isLoading ? <Skeleton width="100%" height="24px" /> : null}

        {pressCopiedList.length === 0 && !isLoading ? (
          <S.NotFoundText>(No duplicate press items found)</S.NotFoundText>
        ) : null}

        {pressCopiedList.length > 0 && !isLoading ? (
          <S.ListOfDuplicateLabelListWrapper>
            {pressCopiedList?.map(
              ({ pressIdText, classificationText, targetUrl }) => (
                <S.ListOfDuplicateLabel
                  onClick={() => movePressDetailPage(targetUrl)}
                >
                  <S.ListOfDuplicateTextId>
                    {pressIdText}
                    <IconExternalLink width={20} height={20} fill="#0198E9" />
                  </S.ListOfDuplicateTextId>
                  <S.ListOfDuplicateTextClassification>
                    {classificationText}
                  </S.ListOfDuplicateTextClassification>
                </S.ListOfDuplicateLabel>
              ),
            )}
          </S.ListOfDuplicateLabelListWrapper>
        ) : null}
      </S.ListOfDuplicatePressWrapper>
    </Suspense>
  );
};

export default ListOfDuplicatePress;
