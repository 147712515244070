import React, { useEffect, useRef, useState } from "react";

import { useGetModalityCategory } from "@api/modalityCategory/modalityCategory";
import { useModalityCategoryValue } from "@FillInTableContainer/shared/context/ModalityCategoryContext";
import { useTableCreatorDropdown } from "@FillInTableContainer/shared/context/TableCreatorDropdownContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";

import { useModalityCategoryStatus } from "./useModalityCategoryStatus";

export const useModalityCategory = (
  modalityValue: string,
  modalityValueIndex: number,
) => {
  const modalityCategoryDropdownRef = useRef<HTMLDivElement>(null);
  const [categoryValueList, setCategoryValueList] = useState<string[]>([]);
  const { changeOriginalModalityCategory, changeUpdateModalityCategory } =
    useModalityCategoryValue();

  const { changeInfo } = useTableCreatorPositionInfo();
  const { data: modalityCategoryData } = useGetModalityCategory(modalityValue);
  const { categoryName, confirmed: isConfirmed } = modalityCategoryData;
  const { status, editableStatus, changeFocusedStatus } =
    useModalityCategoryStatus(modalityValue, isConfirmed, modalityValueIndex);

  const { categoryDropdownList } = useTableCreatorDropdown();

  const changeCategoryValueList = (value: string[]) => {
    setCategoryValueList(value);
  };

  const modalityCategoryListForDropDown =
    categoryDropdownList
      ?.find((dropdown) => dropdown.shellKey === "Modality")
      ?.list?.map((category) => ({
        categoryName: category.categoryKey,
        checked: categoryValueList.includes(category.categoryKey),
      })) || [];

  const modalityCategoryDropDownOpenControl = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (status === "empty") return;
    if (status === "confirmed") return;
    if (status === "focused") return;

    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusedStatus(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalityCategoryDropdownRef.current &&
        !modalityCategoryDropdownRef.current.contains(event.target as Node)
      ) {
        changeFocusedStatus(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [changeFocusedStatus]);

  useEffect(() => {
    setCategoryValueList(categoryName.split("__"));
  }, [categoryName]);

  useEffect(() => {
    if (modalityValue === "") return;
    changeOriginalModalityCategory(modalityValue, categoryName);
    changeUpdateModalityCategory(modalityValue, categoryName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryName]);

  useEffect(() => {
    const transformToString = categoryValueList.join("__");
    changeUpdateModalityCategory(modalityValue, transformToString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryValueList]);

  return {
    modalityCategoryDropdownRef,
    status,
    editableStatus,
    modalityCategoryListForDropDown,
    textFieldValue: categoryValueList.join(", "),
    changeCategoryValueList,
    modalityCategoryDropDownOpenControl,
  };
};
