import { PressListOrderingKey } from "../../../pressList.types";

export const EMPTY_SHELL_VALUE = "-";

export type Ordering = "none" | "asc" | "desc";

export const ORDERING_TABLE = {
  asc: "desc",
  desc: "none",
  none: "asc",
} as const;

export interface TableHeaderList {
  title: string;
  orderingTitle: PressListOrderingKey;
  ordering: Ordering;
}

export const TABLE_HEADER_LIST: TableHeaderList[] = [
  {
    title: "ID",
    orderingTitle: "pressId",
    ordering: "none",
  },
  {
    title: "Press Date",
    orderingTitle: "pressDate",
    ordering: "none",
  },
  {
    title: "Company",
    orderingTitle: "companyName",
    ordering: "none",
  },
  {
    title: "Publishable",
    orderingTitle: "publishable",
    ordering: "none",
  },
  {
    title: "Unsuitable",
    orderingTitle: "articleRejectReason",
    ordering: "none",
  },
  {
    title: "Press Title",
    orderingTitle: "pressTitle",
    ordering: "none",
  },
  {
    title: "AI\nClassification",
    orderingTitle: "classificationAi",
    ordering: "none",
  },
  {
    title: "Manual\nClassification",
    orderingTitle: "classificationManual",
    ordering: "none",
  },
  {
    title: "Status",
    orderingTitle: "draftArticleStatusHighest",
    ordering: "none",
  },
  {
    title: "Start Date",
    orderingTitle: "writeStartDt",
    ordering: "none",
  },
  {
    title: "Complete Date",
    orderingTitle: "writeEndDt",
    ordering: "none",
  },
  {
    title: "MC\nEditor",
    orderingTitle: "mcEditorName",
    ordering: "none",
  },
  {
    title: "FT1\nEditor",
    orderingTitle: "ftEditorName",
    ordering: "none",
  },
  {
    title: "FT2\nEditor",
    orderingTitle: "ftEditor2Name",
    ordering: "none",
  },
  {
    title: "PB\nEditor",
    orderingTitle: "pbEditorName",
    ordering: "none",
  },
];
