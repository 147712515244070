import { modalityCategoryApis } from "@api/modalityCategory/modalityCategory.service";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { useModalityCategoryValue } from "@FillInTableContainer/shared/context/ModalityCategoryContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export const useModalityCategoryUpdate = () => {
  const draftArticleId = getPressIdFromURL();
  const [entryData] = useEntryDataState(draftArticleId);
  const articleSection = useArticleSection();
  const { originalModalityCategory, updateModalityCategory } =
    useModalityCategoryValue();

  const modalityValues = entryData?.[articleSection]?.["Drug Profile"]?.flatMap(
    (table) => table.Modality.value.map((shell) => shell.text),
  );

  const originalModalityCategoryByEntryData = Object.fromEntries(
    Object.entries(originalModalityCategory).filter(
      ([key, value]) => modalityValues?.includes(key) && !!value,
    ),
  );

  const updatedModalityCategoryByEntryData = Object.entries(
    updateModalityCategory,
  ).filter(([key, value]) => modalityValues?.includes(key) && !!value);

  const modalityUpdateData = updatedModalityCategoryByEntryData
    .map(([key, value]) => {
      if (originalModalityCategoryByEntryData[key] !== value) {
        return {
          name: key,
          categoryName: value,
          pressId: Number(draftArticleId),
        };
      }
      return null;
    })
    .filter((item) => item !== null);

  const updateModalityCategoryValue = async () => {
    await Promise.all(
      modalityUpdateData.map((item) =>
        modalityCategoryApis.post(
          item as { name: string; categoryName: string; pressId: number },
        ),
      ),
    );
  };
  return {
    updateModalityCategoryValue,
  };
};
