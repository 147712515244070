import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import { queryClient } from "@api/queryClient";
import { QUERY_KEYS } from "@api/queryKeys";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { ROUTE_PATHS } from "@routes/routePath";
import { articleInfoApis } from "@services/articleInfo.service";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { toastFail, toastSuccess } from "@utils/toast";

import type {
  GetArticleInfoResponse,
  PutArticleInfoArg,
} from "@interface/articleInfo.interface";

export const useArticleInfoQuery = (
  draftArticleId = Number(getPressIdFromURL()),
) => {
  const query = useSuspenseQuery({
    queryKey: [QUERY_KEYS.articleInfo, draftArticleId],
    queryFn: () => articleInfoApis.get(draftArticleId),
    select: ({ data }) => data,
    staleTime: 500, // FIXME: 해당 시간은 임시 값으로, 추후에 정확한 값을 넣어야 함
  });

  return query;
};

export const useArticleInfoMutation = (
  draftArticleId = Number(getPressIdFromURL()),
) => {
  const mutation = useMutation({
    mutationFn: (args: Omit<PutArticleInfoArg, "draftArticleId">) =>
      articleInfoApis.put({
        draftArticleId,
        ...args,
      }),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [QUERY_KEYS.articleInfo, draftArticleId],
      });
    },
    onError: () => {
      toastFail("Failed to update article info");
    },
  });

  return mutation;
};

export const useSetUnsuitableArticleInfoMutation = () => {
  const location = useLocation();
  const toastPosition =
    location.pathname === ROUTE_PATHS.pressList ? "bottomLeft" : "topCenter";

  const mutation = useMutation({
    mutationFn: ({
      draftArticleId,
      articleRejectReason,
      ...rest
    }: {
      draftArticleId: number;
      articleRejectReason: string;
    }) =>
      articleInfoApis.put({
        draftArticleId,
        articleRejectReason,
        draftArticleStatus: DRAFT_STATUS_CODE.unsuitable,
        ...rest,
      }),
    onSuccess: () => {
      toastSuccess(
        "Successfully updated unsuitable reason",
        "green",
        toastPosition,
      );
    },
    onError: () => {
      toastFail("Failed to update unsuitable reason", toastPosition);
    },
  });

  return mutation;
};

export const usePublishableArticleInfoMutation = () => {
  const location = useLocation();
  const toastPosition =
    location.pathname === ROUTE_PATHS.pressList ? "bottomLeft" : "topCenter";

  const mutation = useMutation({
    mutationFn: ({
      draftArticleId,
      isPublishable,
    }: {
      draftArticleId: number;
      isPublishable: boolean;
    }) =>
      articleInfoApis.put({
        draftArticleId,
        publishable: isPublishable,
      }),
    onSuccess: () => {
      toastSuccess("Successfully updated publishable", "green", toastPosition);
    },
    onError: () => {
      toastFail("Failed to update publishable", toastPosition);
    },
  });

  return mutation;
};

export const getArticleInfoData = (
  draftArticleId = Number(getPressIdFromURL()),
) =>
  queryClient.getQueryData<GetArticleInfoResponse>([
    QUERY_KEYS.articleInfo,
    draftArticleId,
  ]);
