import ComponentErrorBoundary from "@/components/shared/business/ComponentErrorBoundary/ComponentErrorBoundary";
import { TableCreatorGroupTableTitle } from "@FillInTableContainer/shared";
import TableCreatorNavigationTableWrapper from "@FillInTableContainer/shared/components/TableCreatorNavigationTableWrapper/TableCreatorNavigationTableWrapper";
import { RegulatoryLayoutValue } from "@FillInTableContainer/shared/types/tableCreator.types";
import {
  getGroupTableTitle,
  getLayoutFromData,
} from "@FillInTableContainer/shared/utils/getTextFromData";

import RegulatoryTableCreatorGroupTable from "./components/RegulatoryTableCreatorGroupTable/RegulatoryTableCreatorGroupTable";

const RegulatoryTableCreator = () => {
  const layout = getLayoutFromData("Regulatory") as RegulatoryLayoutValue;
  const errorWithComponent = (title: string) => (
    <TableCreatorGroupTableTitle title={title} isRequired={false} />
  );

  return (
    <TableCreatorNavigationTableWrapper classificationKey="Regulatory">
      <>
        {layout.map(({ groupTableKey, tableList }, groupTableIndex) => (
          <ComponentErrorBoundary
            addComponents={errorWithComponent(
              getGroupTableTitle("Regulatory", groupTableKey),
            )}
          >
            <RegulatoryTableCreatorGroupTable
              key={`group-table-${String(groupTableIndex)}`}
              groupTableKey={groupTableKey}
              tableList={tableList}
              groupTableIndex={groupTableIndex}
            />
          </ComponentErrorBoundary>
        ))}
      </>
    </TableCreatorNavigationTableWrapper>
  );
};

export default RegulatoryTableCreator;
