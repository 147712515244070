import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  currentDate: string | null;
  isPre: boolean;
  timezoneIdentifier: string | null;
  timezoneAbbreviation: string | null;
};

export const formatDateToFullStringTime = ({
  currentDate,
  isPre,
  timezoneIdentifier,
  timezoneAbbreviation,
}: Props) => {
  if (!currentDate) return "";

  if (!timezoneIdentifier)
    return `${dayjs(currentDate).format("MMM D 'YY, HH:mm:ss")} UTC`;

  const date = dayjs(currentDate).tz(timezoneIdentifier);

  const formattedDate = date.format("MMM D 'YY, HH:mm:ss");

  const preMarketText = isPre ? " pre-market" : "";

  return `${formattedDate}${preMarketText}, ${timezoneAbbreviation}`;
};
