import React from "react";

import { InsertImageFilterProvider } from "@InsertImageContainer/shared/contexts/InsertImageFilterContext";
import { InsertImageHistoryInfoProvider } from "@InsertImageContainer/shared/contexts/InsertImageHistoryInfoContext";

interface Props {
  children: React.ReactNode;
}

const InsertImageProvider = ({ children }: Props) => {
  return (
    <InsertImageFilterProvider>
      <InsertImageHistoryInfoProvider>
        {children}
      </InsertImageHistoryInfoProvider>
    </InsertImageFilterProvider>
  );
};

export default InsertImageProvider;
