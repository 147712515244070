import styled, { css } from "styled-components";

import { AIRegenerateButton } from "@EditArticleContainer/shared/components/AIRegenerateButton/AIRegenerateButton.style";

export const ArticleHeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  background-blend-mode: darken, normal, normal, normal;
  padding: 4rem 0 4rem 0;
  margin: 0.8rem 0rem 0rem 0rem;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-blend-mode: darken, normal, normal, normal;
  z-index: 2;
`;

export const CenterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 100rem;
  height: 100%;
  margin: 0 auto;
  z-index: 3;
`;

export const SectionName = styled.h2`
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.2rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
`;

export const ArticleTitleWrapper = styled.div``;

export const ArticleTitle = styled.h1<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.4;
    `}

  & > * {
    font-family: ${({ theme }) => theme.font.fontFamily.jost};
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 125%;
    width: 100rem;
    color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  }
`;

export const MainLabelWrapper = styled.div`
  line-height: 320%;
`;

export const MainLabel = styled.span<{
  color: "blue" | "orange" | "purple" | "pink" | "yellow" | "green";
}>`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${({ theme }) => theme.color.neural.gray00};
  border-radius: 0.4rem;
  border: 1px solid ${({ theme, color }) => theme.color.label[color]};
  background-color: ${({ theme, color }) =>
    theme.color.labelBackgroundTransparent[color]};
  margin-right: 0.8rem;
  padding: 0.4rem 0.8rem;
`;

export const ArticleDate = styled.h4`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);

  & > img {
    width: 2rem;
    height: 2rem;
  }
`;

export const ArticleInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 2.4rem;
`;

export const ArticleTitleRegenerateButton = styled(AIRegenerateButton)`
  position: absolute;
  top: 50%;
  left: -1.6rem;
  transform: translateX(-100%) translateY(-50%);
`;
