import styled from "styled-components";

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &.is-hide-drug-profile-tableData-true {
    display: none;
  }
`;
