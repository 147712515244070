import { showToast } from "@atoms/Toast/Toast";

export const toastRedirectSuccess = () => {
  showToast({
    text: "You are currently logged in as an administrator.",
    backgroundColor: "green",
  });
};

export const toastRedirectFail = () => {
  showToast({
    text: "Invalid access",
    backgroundColor: "red",
  });
};

export const toastAdminReLogin = () => {
  showToast({
    text: "Please re-login on the admin page.",
    backgroundColor: "red",
  });
};
