export const REGULATORY_GROUP_KEY = {
  regulatory: "Regulatory",
  drugProfile: "Drug Profile",
  approvalProcessContents: "Approval Process Contents",
  keyComment: "Key Comment",
} as const;

export const DRUG_PROFILE_KEY = {
  roa: "ROA",
  modality: "Modality",
  moa: "MOA",
  codeName: "Code Name",
  innName: "INN Name",
  brandName: "Brand Name",
  therapeuticArea: "Therapeutic Area",
  target: "Target",
  targetIndication: "Target Indication",
  otherCharacteristics: "Other Characteristics",
} as const;

export const APPROVAL_PROCESS_CONTENTS_KEY = {
  ApprovalProcess: "Approval Process",
  marketingStatus: "Marketing Status",
  indicationOnLabel: "Indication on Label",
  dosageAndAdministrationOnLabel: "Dosage and Administration on Label",
  regulatoryAgency: "Regulatory Agency",
  applicationType: "Application Type",
  authorizedCompany: "Authorized Company",
  additionalInformationOnApprovalProcess:
    "Additional Information on the Approval Process",
  updateDate: "Update Date",
} as const;

export const KEY_COMMENT = {
  quote: "Quote",
  spokesperson: "Spokesperson",
} as const;
