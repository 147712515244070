import styled, { css } from "styled-components";

export const GroupShellTitle = styled.h4<{
  isAlertGroupShell: boolean;
  isDragging: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  width: fit-content;
  height: 2.4rem;
  max-width: 60rem;
  background-color: white;
  padding: 0 1.6rem;

  cursor: pointer;

  ${({ isAlertGroupShell }) =>
    isAlertGroupShell &&
    css`
      color: ${({ theme }) => theme.color.live.orange};
    `}

  ${({ isDragging }) =>
    isDragging &&
    css`
      border-radius: var(--Border-Radius-0, 0rem);
      background: var(--Color-Bg-Draft, #d9f4f7);
    `}
`;
export const RightButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  height: 100%;
`;

export const TitleTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  min-width: 6.4rem;
  height: 2.4rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--Color-Common-Text-Primary, #2a2f37);
`;
