import { useCallback, useState } from "react";

import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { DataSetDropdownList } from "../components/TableCreatorListBox/types/tableCreatorDropdown.types";
import { GET_DATA_SET_DROPDOWN } from "../constants/dataSetDropdown.constants";
import { changeFormatDataSetDropdownList } from "../utils/changeFormatDataSetDropdownList";

const DATA_SET_DROPDOWN_KEYS = ["Key Comment"];

const useGetDataSetDropdown = (
  groupTableKey: string,
  shellKey: string | null,
) => {
  const draftArticleId = getPressIdFromURL();
  const articleSection = useArticleSection();
  const [entryData] = useEntryDataState(draftArticleId);

  const isDataSetTable = DATA_SET_DROPDOWN_KEYS.includes(groupTableKey);
  const defaultDataSetList = isDataSetTable
    ? changeFormatDataSetDropdownList(
        GET_DATA_SET_DROPDOWN[articleSection][groupTableKey](entryData),
        shellKey,
      )
    : [];

  const [dataSetList, setDataSetList] = useState(defaultDataSetList);

  const changeCurrentRecommendDatasetList = useCallback(
    (newDatasetList: DataSetDropdownList[]) => {
      setDataSetList(newDatasetList);
    },
    [],
  );

  return {
    initialDataSetList: defaultDataSetList,
    changeCurrentRecommendDatasetList,
    dataSetList,
  };
};

export default useGetDataSetDropdown;
