import {
  useArticleContentDataQuery,
  useUpdateArticleContentOriginDataMutation,
} from "@api/articleContentData/articleContentData";
import {
  GetDealsArticleContentData,
  GetExpeditedProgramArticleContentData,
  GetRegulatoryArticleContentData,
  GetVentureCapitalArticleContentData,
} from "@api/articleContentData/articleContentData.interface";
import { articleContentDataApis } from "@api/articleContentData/articleContentData.service";
import { getParagraphData } from "@EditArticleContainer/shared/hooks/useParagraph";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useSectionCodeToClassification } from "./useSectionCode";

export const useResetArticleContent = () => {
  const { data: articleContent } = useArticleContentDataQuery();
  const sectionCode = useSectionCodeToClassification();
  const { mutate: updateArticleContent } =
    useUpdateArticleContentOriginDataMutation();

  const resetArticleContent = async () => {
    // 데이터가 없을때는 아무것도 하지 않음.
    if (Object.keys(articleContent).length === 0) {
      return;
    }

    if (sectionCode === "TY001") {
      await articleContentDataApis.setting(getPressIdFromURL(), sectionCode);

      const [tc003, tc004, tc005, tc006] = await Promise.all([
        getParagraphData({ paragraphCode: "TC003", sectionCode }),
        getParagraphData({ paragraphCode: "TC004", sectionCode }),
        getParagraphData({ paragraphCode: "TC005", sectionCode }),
        getParagraphData({ paragraphCode: "TC006", sectionCode }),
      ]);

      const results = {
        ...articleContent,
        TC003: tc003,
        TC004: tc004,
        TC005: tc005,
        TC006: tc006,
      };

      updateArticleContent(results);
    } else if (sectionCode === "TY002") {
      await articleContentDataApis.setting(getPressIdFromURL(), sectionCode);

      const [tc103, tc106, tc110, tc111, tc112] = await Promise.all([
        getParagraphData({ paragraphCode: "TC103", sectionCode }),
        getParagraphData({ paragraphCode: "TC106", sectionCode }),
        getParagraphData({ paragraphCode: "TC110", sectionCode }),
        getParagraphData({ paragraphCode: "TC111", sectionCode }),
        getParagraphData({ paragraphCode: "TC112", sectionCode }),
      ]);

      const results: GetDealsArticleContentData = {
        ...articleContent,
        TC103: tc103,
        TC106: tc106,
        TC110: tc110,
        TC111: tc111,
        TC112: tc112,
      };

      updateArticleContent(results);
    } else if (sectionCode === "TY003") {
      await articleContentDataApis.setting(getPressIdFromURL(), sectionCode);

      const [tc203, tc206, tc213] = await Promise.all([
        getParagraphData({ paragraphCode: "TC203", sectionCode }),
        getParagraphData({ paragraphCode: "TC206", sectionCode }),
        getParagraphData({ paragraphCode: "TC213", sectionCode }),
      ]);

      const results: GetRegulatoryArticleContentData = {
        ...articleContent,
        TC203: tc203,
        TC206: tc206,
        TC213: tc213,
      };

      updateArticleContent(results);
    } else if (sectionCode === "TY004") {
      await articleContentDataApis.setting(getPressIdFromURL(), sectionCode);

      const [tc303, tc306, tc314] = await Promise.all([
        getParagraphData({ paragraphCode: "TC303", sectionCode }),
        getParagraphData({ paragraphCode: "TC306", sectionCode }),
        getParagraphData({ paragraphCode: "TC314", sectionCode }),
      ]);

      const results: GetExpeditedProgramArticleContentData = {
        ...articleContent,
        TC303: tc303,
        TC306: tc306,
        TC314: tc314,
      };

      updateArticleContent(results);
    } else if (sectionCode === "TY006") {
      await articleContentDataApis.setting(getPressIdFromURL(), sectionCode);

      const [tc503, tc506, tc512, tc515, tc516, tc517, tc518] =
        await Promise.all([
          getParagraphData({ paragraphCode: "TC503", sectionCode }),
          getParagraphData({ paragraphCode: "TC506", sectionCode }),
          getParagraphData({ paragraphCode: "TC512", sectionCode }),
          getParagraphData({ paragraphCode: "TC515", sectionCode }),
          getParagraphData({ paragraphCode: "TC516", sectionCode }),
          getParagraphData({ paragraphCode: "TC517", sectionCode }),
          getParagraphData({ paragraphCode: "TC518", sectionCode }),
        ]);

      const results: GetVentureCapitalArticleContentData = {
        ...articleContent,
        TC503: tc503,
        TC506: tc506,
        TC512: tc512,
        TC515: tc515,
        TC516: tc516,
        TC517: tc517,
        TC518: tc518,
      };

      updateArticleContent(results);
    } else {
      throw new Error("sectionCode is not valid");
    }
  };

  return resetArticleContent;
};
