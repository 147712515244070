import styled from "styled-components";

export const WidthHandleBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 3rem;
`;

export const WidthHandleBarMouseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  height: 6.4rem;
  width: 100%;
  cursor: col-resize;
`;

export const WidthHandleBar = styled.div`
  width: 0.3rem;
  height: 6.4rem;
  border-radius: 2rem;
  opacity: 0.5;
  background: var(--Color-Common-Icon-Interactive-Secondary, #515c6c);
`;
