export const ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY =
  "Financing Information" as const;

export const ENTRY_DATA_FINANCING_INFORMATION_KEYS = {
  amount: "Amount",
  financingInformationDetail: "Financing Information Detail",
  financingPurpose: "Financing Purpose",
} as const;

export const ENTRY_DATA_FINANCING_INFORMATION_SHELL_KEYS = {
  amount: "Amount",
  financingInformationDetail: "Financing Information Detail",
  financingPurpose: "Financing Purpose",
} as const;

export const ENTRY_DATA_FINANCING_INFORMATION_GROUP_SHELL_KEYS = {
  financingSummaryInformation: "Financing Summary Information",
  none: null,
};

export const ENTRY_DATA_FINANCING_INFORMATION_TABLE_KEYS = {
  none: null,
};
