import React, { ReactNode, useEffect } from "react";

import { FILL_IN_TABLE_KEY } from "@FillInTableContainer/shared/constants/fillInTableKey";
import { useValidatedInTableCreator } from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";

import ClinicalTrialsTableCreator from "./components/ClinicalTrialsTableCreator/ClinicalTrialsTableCreator";
import ClinicalTrialStatusTableCreator from "./components/ClinicalTrialStatusTableCreator/ClinicalTrialTableStatusCreator";
import DealsTableCreator from "./components/DealsTableCreator/DealsTableCreator";
import ExpeditedProgramTableCreator from "./components/ExpeditedProgramTableCreator/ExpeditedProgramTableCreator";
import RegulatoryTableCreator from "./components/RegulatoryTableCreator/RegulatoryTableCreator";
import VentureCapitalTableCreator from "./components/VentureCapitalTableCreator/VentureCapitalCreator";
import { useScroll } from "../../hooks/useScroll";

import type { ArticleSection } from "@/types/articleEntryData.types";

export type TableCreator = {
  [key in ArticleSection]: ReactNode;
};

const TABLE_CREATOR_CONTAINER = {
  [FILL_IN_TABLE_KEY.ClinicalTrials]: <ClinicalTrialsTableCreator />,
  [FILL_IN_TABLE_KEY.ClinicalTrialStatus]: <ClinicalTrialStatusTableCreator />,
  [FILL_IN_TABLE_KEY.Regulatory]: <RegulatoryTableCreator />,
  [FILL_IN_TABLE_KEY.Deals]: <DealsTableCreator />,
  [FILL_IN_TABLE_KEY.ExpeditedProgram]: <ExpeditedProgramTableCreator />,
  [FILL_IN_TABLE_KEY.VentureCapital]: <VentureCapitalTableCreator />,
} satisfies Record<string, React.ReactElement>;

const TableCreatorContainer = ({
  articleSection,
}: {
  articleSection: ArticleSection;
}) => {
  const { scroll } = useScroll();
  const { validatedInfo } = useValidatedInTableCreator();

  useEffect(() => {
    if (validatedInfo.length > 0) {
      const {
        groupTableKey,
        groupTableIndex,
        tableIndex,
        tableKey,
        groupShellKey,
        groupShellIndex,
        subGroupShellKey,
        subGroupShellIndex,
        shellIndex,
        shellKey,
      } = validatedInfo[0];

      const targetShellKey =
        shellKey === "Group Data" ? "Endpoint Group Information" : shellKey;
      const targetTableKey =
        tableKey === "Endpoints Table Information" ? "Data" : tableKey;

      // NOTE: 레이아웃에 SubGroupShell 계층이 존재하나 Key 값을 설정하지 않는 경우를 위해 null은 SubGroupShell 계층이 있는 것으로 판단합니다.
      if (subGroupShellKey || subGroupShellKey === null)
        return scroll(
          `${groupTableKey}__${groupTableIndex}__${targetTableKey}__${tableIndex}__${groupShellKey}__${groupShellIndex}__${subGroupShellKey}__${subGroupShellIndex}__${targetShellKey}__${shellIndex}`,
        );

      // NOTE: SubGroupShellKey, SubGroupShellIndex를 아예 설정하지 않은 경우
      scroll(
        `${groupTableKey}__${groupTableIndex}__${targetTableKey}__${tableIndex}__${groupShellKey}__${groupShellIndex}__${targetShellKey}__${shellIndex}`,
      );
    }
  }, [validatedInfo, scroll]);

  return TABLE_CREATOR_CONTAINER[articleSection];
};

export default TableCreatorContainer;
