import { useCallback } from "react";

import {
  ExpeditedProgramContents,
  ExpeditedProgramContentsGroupShellKey,
} from "@/types/expeditedProgram.types";
import {
  EXPEDITED_PROGRAM,
  EXPEDITED_PROGRAM_GROUP_KEY as eg,
} from "@FillInTableContainer/shared/constants/expeditedProgramKey.constants";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { PositionInfoProps } from "../ExpeditedProgramSingleTypeShell";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorShellReturn {
  handleKeyDownToClearExpeditedProgramContentsShell: (
    event: KeyboardEvent,
  ) => void;
  handleClickToClearExpeditedProgramContentsShell: () => void;
}

export const useExpeditedProgramContentsShell = (
  positionPropsInfo: PositionInfoProps,
): UseHandleTableCreatorShellReturn => {
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const handleClearShell = useCallback(() => {
    const clearExpeditedProgramContentsShell = (): ArticleEntryData => {
      const currentData =
        entryData[EXPEDITED_PROGRAM]?.[eg.expeditedProgramContents];

      return {
        ...entryData,
        [EXPEDITED_PROGRAM]: {
          ...entryData[EXPEDITED_PROGRAM],
          [eg.expeditedProgramContents]: {
            ...currentData,
            [info.shellKey as ExpeditedProgramContentsGroupShellKey]: {
              ...currentData?.[
                info.shellKey as ExpeditedProgramContentsGroupShellKey
              ],
              value: [],
            },
          } as ExpeditedProgramContents,
        },
      };
    };

    if (info.groupTableKey === eg.expeditedProgramContents) {
      setEntryData(clearExpeditedProgramContentsShell());
      changeCurrentHistory({
        entryData: clearExpeditedProgramContentsShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  const handleClickToClearExpeditedProgramContentsShell = () => {
    if (focusStatus.type === "clear") {
      handleClearShell();
    }
  };

  const handleKeyDownToClearExpeditedProgramContentsShell = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearShell();
      }
    },
    [focusStatus.type, handleClearShell],
  );

  return {
    handleClickToClearExpeditedProgramContentsShell,
    handleKeyDownToClearExpeditedProgramContentsShell,
  };
};
