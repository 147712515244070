import React from "react";

import {
  ArticleDetailCompanyProfileTable,
  ParagraphSectionLayout,
  ParagraphTitle,
} from "@frontend/common";

import { useCompanyProfileQuery } from "@api/companyProfile/companyProfile";
import { useGetCompanyNameList } from "@EditArticleContainer/shared/hooks/useGetCompanyNameList";

import * as S from "./ParagraphCompanyProfile.style";

const PARAGRAPH_TITLE = "Company Profile";

const ParagraphCompanyProfile = () => {
  const { mainCompanyNameList } = useGetCompanyNameList();
  const { data: companyProfileData } =
    useCompanyProfileQuery(mainCompanyNameList);

  if (mainCompanyNameList?.length === 0) return null;

  const originalCompanyProfileTableGroupData = companyProfileData.data?.data;
  // NOTE : Filter out the data that has less than 3 values
  const companyProfileTableGroupData =
    originalCompanyProfileTableGroupData?.filter((tableData) => {
      const locationAndCountryValue = [
        tableData.location,
        tableData.country,
      ].join(", ");
      const values = [
        tableData.companyTypeBusiness,
        tableData.companyTypeCategory,
        locationAndCountryValue,
        tableData.summary,
        tableData.ticker,
        tableData.yearOfEstablishment,
      ].filter((value) => !!value);

      return values.length > 2;
    });

  if (companyProfileTableGroupData.length === 0) return null;

  return (
    <ParagraphSectionLayout>
      <ParagraphTitle title={PARAGRAPH_TITLE} />
      <S.TableGroupWrapper>
        {companyProfileTableGroupData?.map((tableData) => (
          <ArticleDetailCompanyProfileTable
            key={tableData.companyName}
            tableData={tableData}
            isEditMode
          />
        ))}
      </S.TableGroupWrapper>
    </ParagraphSectionLayout>
  );
};

export default ParagraphCompanyProfile;
