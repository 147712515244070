"use client";

import styled from "styled-components";

export const Wrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
  padding: 0.2rem;
  cursor: pointer;

  outline-width: 2px;
  outline-color: #017abc;
  border-radius: 0.2rem;

  svg {
    path {
      fill: #515c6c;
    }
  }

  &:hover {
    .icon-wrapper {
      border-radius: var(--Border-Radius-4, 0.4rem);
      border: 1.5px solid #96a1b0;
      background: #f0f2f4;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
        0px 0px 2px 0px rgba(0, 0, 0, 0.12);

      svg {
        path {
          fill: #3d4551;
        }
      }
    }
  }

  &:active {
    .icon-wrapper {
      border-radius: var(--Border-Radius-4, 0.4rem);
      border: 1.5px solid #7c899c;
      background: #e5e7eb;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08),
        0px 0px 2px 0px rgba(0, 0, 0, 0.06);

      svg {
        path {
          fill: #2a2f37;
        }
      }
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: var(--Size-Btn-Sm, 2.8rem);
  height: var(--Size-Btn-Sm, 2.8rem);

  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1.5px solid #b1b8c4;
  background: #fff;
  padding: var(--Spacing-Btn-8, 0.28rem) var(--Spacing-16, 0.28rem)
    var(--Spacing-Btn-8, 0.28rem) var(--Spacing-12, 0.28rem);
`;

export const Text = styled.div`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  text-align: center;
  font-family: var(--font-pretendard);
  font-size: 1.1rem;
  font-weight: 500;
`;
