import styled, { css } from "styled-components";

import { TableContentWrapper } from "@FillInTableContainer/shared/styles/table.style";

export const TableWrapper = styled(TableContentWrapper)``;

export const ScrollWrapper = styled.div<{
  isTableScroll: boolean;
}>`
  ${({ isTableScroll }) =>
    isTableScroll &&
    css`
      padding-top: 0rem;
      padding-bottom: 1.6rem;
      overflow-x: scroll;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        height: 0.4rem;
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.color.neural.gray05};
        border-radius: 0.2rem;
      }
      &::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.color.neural.gray02};
        border-radius: 0.2rem;
      }
    `}
`;

export const ScrollContentsWrapper = styled.div`
  width: fit-content;
`;

export const GroupShellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
`;

export const DeleteTableButtonWrapper = styled.div`
  position: absolute;
  top: -2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.4rem;
`;

export const DeleteTableButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  height: 2.4rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.neural.white};
  padding: 0.4rem;
  border-radius: 0.2rem;
  background-color: ${({ theme }) => theme.color.live.orange};
  white-space: nowrap;
  cursor: pointer;
  z-index: 25;
`;
