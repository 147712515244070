"use client";

import styled from "styled-components";

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 20rem;
  background: ${({ theme }) => theme.color.node.sky};
`;
