import { useMutation, useQuery } from "@tanstack/react-query";

import { movePressDetailPage } from "@/components/shared/ui/TopFixInfo/components/TopFixInfoList/components/PressDuplicateButton/components/utils/movePressDetailPage";
import { ClassificationCode } from "@/types/code.types";
import { queryClient } from "@api/queryClient";
import { QUERY_KEYS } from "@api/queryKeys";
import { ROUTE_PATHS } from "@routes/routePath";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { toastFailCopyPress, toastSuccessCopyPress } from "@utils/toast";

import { pressCopyApis } from "./pressCopy.service";

export const useGetPressCopiedList = () => {
  const pressId = getPressIdFromURL();
  const query = useQuery({
    queryKey: [QUERY_KEYS.pressCopiedList, pressId],
    queryFn: async () => {
      const response = await pressCopyApis.getPressCopiedList(pressId);
      return response?.data;
    },
  });

  return query;
};

export const usePostCopyPressMutate = () => {
  const pressId = getPressIdFromURL();
  const mutate = useMutation({
    mutationKey: [QUERY_KEYS.pressCopy, pressId],
    mutationFn: (classificationCode: ClassificationCode) =>
      pressCopyApis.postPressCopy(pressId, classificationCode),
    onSuccess: ({ data: response }) => {
      const newPressId = response.data.detail.press_list_id;
      const targetUrl = `${ROUTE_PATHS.pressClassification}?id=${newPressId}`;

      toastSuccessCopyPress(newPressId, () => movePressDetailPage(targetUrl));
      queryClient.refetchQueries({
        queryKey: [QUERY_KEYS.pressCopiedList, pressId],
      });
    },
    onError: () => {
      toastFailCopyPress();
    },
  });

  return mutate;
};
