import { ArticleData } from "@/types/articleData.types";
import { apiClient } from "@api/apiClient";
import { GetArticleEntryDataResponse } from "@interface/articleEntryData.interface";

const API_ENDPOINT = {
  getArticleEntryData: "/des/article-entry-data/data",
  resetArticleEntryData: "/des/article-entry-data/reset",
  updateArticleEntryData: "/des/article-entry-data/update",
};

export type UpdateArticleData = ArticleData;

export const articleEntryDataApis = {
  get: async (id: string) => {
    const { data } = await apiClient.get<GetArticleEntryDataResponse>(
      API_ENDPOINT.getArticleEntryData,
      {
        params: {
          id,
        },
      },
    );
    return data;
  },
  reset: async (id: string) => {
    const { data } = await apiClient.post(API_ENDPOINT.resetArticleEntryData, {
      id,
    });
    return data;
  },
  update: async (updateData: UpdateArticleData) => {
    const { data } = await apiClient.post(API_ENDPOINT.updateArticleEntryData, {
      ...updateData,
    });
    return data;
  },
};
