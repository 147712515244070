import styled from "styled-components";

export const ValidationInfoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: calc(200% + 1.6rem);
  width: 29.6rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Primary, #fff);

  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  padding: var(--Spacing-4, 0.4rem) var(--Spacing-12, 1.2rem);

  .shell:has(&:hover) {
    border: 1px solid var(--grayscale-spectrum-neural-blue-gray-02, #edf1f8);
    background: #fff;
    box-shadow: none;
  }

  .set-min-height-for-information-true:has(&) {
    min-height: 21.4rem;
  }
`;

export const ValidationTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 2.4rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  padding: 0.4rem 0;
`;

export const ValidationItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;

  width: 100%;
  min-height: 2.2rem;
  color: var(--Color-Common-Text-Secondary, #515c6c);
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 130%;
  padding: 0.4rem 0 0.4rem 0.6rem;

  &::before {
    position: absolute;
    top: 0.4rem;
    left: 0rem;
    content: "•";
  }
`;

export const FormatText = styled.div``;

export const CountryText = styled.div`
  color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  font-size: 1.1rem;
  font-weight: 400;
  opacity: 0.5;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
`;
