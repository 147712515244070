import dayjs from "dayjs";

import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import {
  REF_CODE_QUOTE,
  REF_CODE_SPOKESPERSON,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { KeyCommentsContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/keyCommentsContent.types";

import { createContentFromHTML } from "../../../utils/getValueFromRefMap";

export const useCreateKeyCommentsContent = () => {
  const refMap = useArticleContentRefMap();

  return () => {
    const content: KeyCommentsContent = {
      text: {
        "Key Comments": {
          Spokesperson: createContentFromHTML(refMap, REF_CODE_SPOKESPERSON),
          Quote: createContentFromHTML(refMap, REF_CODE_QUOTE),
        },
      },
      labels: [],
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
