import {
  DEAL_TYPE_SHELL_KEYS,
  ENTRY_DATA_DEAL_TYPE_AND_STATUS,
  ENTRY_DATA_SECTION_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import { KEY_DEALS_TABLE } from "@FillInTableContainer/shared/constants/dealsKey.constants";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  handleClickToClearDealTypeGroupShell: () => void;
  handleKeyDownToClearDealTypeGroupShell: (event: KeyboardEvent) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: DealsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useHandleDealsDealTypeGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();

  const handleClearGroupShell = () => {
    const clearDealTypeGroupShell = (): ArticleEntryData => {
      const currentDealTypeAndStatus =
        entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_DEAL_TYPE_AND_STATUS
        ];

      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          [ENTRY_DATA_DEAL_TYPE_AND_STATUS]: {
            [DEAL_TYPE_SHELL_KEYS.dealStatus]: {
              ...currentDealTypeAndStatus?.[DEAL_TYPE_SHELL_KEYS.dealStatus],
              value: [],
            },
            [DEAL_TYPE_SHELL_KEYS.dealType]: {
              ...currentDealTypeAndStatus?.[DEAL_TYPE_SHELL_KEYS.dealType],
              value: [],
            },
            [DEAL_TYPE_SHELL_KEYS.dealName]: {
              ...currentDealTypeAndStatus?.[DEAL_TYPE_SHELL_KEYS.dealName],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    if (info.groupTableKey === KEY_DEALS_TABLE.typeAndStatus) {
      setEntryData(clearDealTypeGroupShell());
      changeCurrentHistory({
        entryData: clearDealTypeGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  };

  const handleClickToClearDealTypeGroupShell = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShell();
    }
  };

  const handleKeyDownToClearDealTypeGroupShell = (event: KeyboardEvent) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearGroupShell();
    }
  };

  return {
    handleClickToClearDealTypeGroupShell,
    handleKeyDownToClearDealTypeGroupShell,
  };
};
