import styled from "styled-components";

export const SearchResult = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 176rem;
  gap: 0.8rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.color.neural.gray08};

  margin-top: 2.6rem;
`;

export const SearchResultText = styled.div`
  color: ${({ theme }) => theme.color.neural.gray05};
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 100%;
`;

export const SearchTotalCount = styled.span`
  color: ${({ theme }) => theme.color.neural.gray04};
  font-weight: 400;
  font-size: 1.6rem;
`;

export const ResetButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  cursor: pointer;
`;

export const ResetButtonText = styled.span`
  color: ${({ theme }) => theme.color.live.orange};
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 130%;
  text-decoration-line: underline;
`;
