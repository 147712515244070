import styled, { css } from "styled-components";

export const SectionOutsideWrapper = styled.div<{
  isFocused: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.color.neural.white};

  position: relative;
  border: 2px solid transparent;

  ${({ isFocused }) =>
    isFocused &&
    css`
      border: 2px solid
        var(--Color-Common-Border-Interactive-Primary-Subtle-Pressed, #76cffe);
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.16);
    `}
`;
