import { ClinicalTrialsParagraphContents } from "@EditArticleContainer/shared/types/paragraphContents/paragraphContents.types";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useCreateClinicalResultsContent } from "./hooks/useCreateClinicalResultsContent";
import { useCreateClinicalTrialDesignContent } from "./hooks/useCreateClinicalTrialDesignContent";
import { useCreateCompetitiveLandscapeContent } from "./hooks/useCreateCompetitiveLandscapeContent";
import { useCreateDeepInsightContent } from "./hooks/useCreateDeepInsightContent";
import { useCreateDrugProfileContent } from "./hooks/useCreateDrugProfileContent";
import { useCreateKeyCommentsContent } from "./hooks/useCreateKeyCommentsContent";
import { useCreateTitleContent } from "./hooks/useCreateTitleContent";
import { useCreateTopicContent } from "./hooks/useCreateTopicContent";

export const useCreateClinicalTrialsContent = () => {
  const createClinicalResults = useCreateClinicalResultsContent();
  const createDrugProfile = useCreateDrugProfileContent();
  const createClinicalTrialDesign = useCreateClinicalTrialDesignContent();
  const createCompetitiveLandscape = useCreateCompetitiveLandscapeContent();
  const createTitle = useCreateTitleContent();
  const createTopic = useCreateTopicContent();
  const createDeepInsight = useCreateDeepInsightContent();
  const createKeyComments = useCreateKeyCommentsContent();

  return async (): Promise<ClinicalTrialsParagraphContents> => {
    const title = createTitle();
    const topic = createTopic();
    const drugProfile = createDrugProfile();
    const clinicalTrialDesign = createClinicalTrialDesign();
    const clinicalResults = await createClinicalResults();
    const keyComments = createKeyComments();
    const competitiveLandscape = createCompetitiveLandscape();
    const deepInsight = createDeepInsight();

    return {
      "Article Id": Number(getPressIdFromURL()),
      TC001: title,
      TC002: topic,
      TC003: drugProfile,
      TC004: clinicalTrialDesign,
      TC005: clinicalResults,
      TC006: keyComments,
      TC007: competitiveLandscape,
      TC008: deepInsight,
    };
  };
};
