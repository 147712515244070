import styled from "styled-components";

import UpdateButton from "@EditArticleContainer/shared/components/UpdateButton/UpdateButton";

export const ParagraphDealTermsWrapper = styled.div`
  position: relative;
`;

export const ParagraphDealTermsUpdateButton = styled(UpdateButton)`
  position: absolute;
  left: -1.6rem;
  top: 0;
  transform: translateX(-100%);
`;
