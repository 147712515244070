import React, { useCallback, useEffect, useRef } from "react";

import { ENTRY_DATA_CLINICAL_TRIAL_DESIGN } from "@frontend/common";

import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import {
  DEFAULT_FOCUS_STATUS,
  FocusStatus,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  PositionInfo,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { isTableCustomTitle } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getTableCustomTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useClearClinicalTrialsClinicalTrialDesignTable } from "./useClearClinicalTrialsClinicalTrialDesignTable";
import { useDeleteClinicalTrialsClinicalTrialDesignTable } from "./useDeleteClinicalTrialsClinicalTrialDesignTable";
import { TableInfoProps } from "../ClinicalTrialDesignTable";

interface UseHandleTableCreatorTableReturn {
  info: PositionInfo;
  focusStatus: FocusStatus;
  isFocused: boolean;
  isLastTable: boolean;
  isVisibleTableCustomTitle: boolean;
  tableWrapperRef: React.RefObject<HTMLDivElement>;
  tableCustomTitle: string;
  isEndpointsTypeTable: boolean;
  isEnableEdit: boolean;
  isFocusedAddButton: boolean;
  focusEventToChangePositionInfo: (focusEnterType: FocusStatus) => void;
  handleClickDeleteButton: () => void;
  changePositionInfo: () => void;
  changePositionInfoToDefault: () => void;
  changeFocusStatus: (focusStatus: FocusStatus) => void;
  changeInfoToCurrentTable: () => void;
  handleFocusGroupShell: (
    groupShellKey: string | null,
    groupShellIndex: number,
  ) => void;

  handleDefaultFocus: () => void;
}

export const useHandleClinicalTrialsTableCreatorTable = (
  tablePropsInfo: TableInfoProps,
): UseHandleTableCreatorTableReturn => {
  const { groupTableKey, tableKey, groupTableIndex, tableIndex, tableCount } =
    tablePropsInfo;

  const articleSection = "Clinical Trials";

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const [entryData] = useEntryDataState(getPressIdFromURL());
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );

  const { clearClinicalTrialDesignTable } =
    useClearClinicalTrialsClinicalTrialDesignTable(tablePropsInfo);

  const { deleteClinicalTrialDesignTable } =
    useDeleteClinicalTrialsClinicalTrialDesignTable(tablePropsInfo);

  const isEnableEdit = articleInfo
    ? ENABLE_GROUP_TABLE_KEY_LIST[articleSection][groupTableKey].includes(
        articleInfo.draftArticleStatus,
      )
    : false;

  const tableWrapperRef = useRef<HTMLDivElement>(null);

  const isLastTable = tableIndex === tableCount - 1;

  const isFocused =
    info.groupTableKey === groupTableKey &&
    info.tableKey === tableKey &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === null &&
    info.groupShellIndex === null &&
    info.subGroupShellIndex === null &&
    info.subGroupShellKey === null &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    isEnableEdit;

  const isFocusedAddButton =
    focusStatus.container === "noFocus" && isEnableEdit;

  const tableCustomTitle = getTableCustomTitle(
    articleSection,
    entryData,
    groupTableKey,
    tableIndex,
  );

  const isVisibleTableCustomTitle = isTableCustomTitle(
    articleSection,
    groupTableKey,
  );

  const isEndpointsTypeTable = ["Clinical Endpoints (Table)"].includes(
    groupTableKey,
  );

  const changePositionInfo = () => {
    changeInfo({
      groupTableKey,
      groupTableIndex,
      tableKey,
      tableIndex,
      groupShellKey: null,
      groupShellIndex: null,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
  };

  const handleFocusGroupShell = (
    groupShellKey: string | null,
    groupShellIndex: number,
  ) => {
    changeInfo({
      groupTableKey,
      groupTableIndex,
      groupShellKey,
      groupShellIndex,
      tableIndex,
      tableKey,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
    changeFocusStatus({ type: "clear", container: "groupShell" });
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const changePositionInfoToDefault = () => {
    changeInfo(DEFAULT_POSITION_INFO);
  };

  const handleDefaultFocus = () => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const changeInfoToCurrentTable = () => {
    changeInfo({
      groupTableKey,
      groupTableIndex,
      tableKey,
      tableIndex,
      groupShellKey: null,
      groupShellIndex: null,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
  };

  const focusEventToChangePositionInfo = (focusEnterType: FocusStatus) => {
    changeInfoToCurrentTable();
    changeFocusStatus({ type: "clear", container: "table" });

    if (focusEnterType.type === "clear") {
      changeFocusStatus({ type: "clear", container: "table" });
    }
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleClickDeleteButton = () => {
    if (info.groupTableKey === ENTRY_DATA_CLINICAL_TRIAL_DESIGN) {
      if (focusStatus.type === "clear") clearClinicalTrialDesignTable();

      if (focusStatus.type === "delete") deleteClinicalTrialDesignTable();
    }
  };

  const handleKeyDownToDeleteTable = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== "Delete") return;

      if (info.groupTableKey === ENTRY_DATA_CLINICAL_TRIAL_DESIGN) {
        if (focusStatus.type === "clear") clearClinicalTrialDesignTable();

        if (focusStatus.type === "delete") deleteClinicalTrialDesignTable();
      }
    },
    [
      info.groupTableKey,
      focusStatus.type,
      clearClinicalTrialDesignTable,
      deleteClinicalTrialDesignTable,
    ],
  );

  useEffect(() => {
    if (focusStatus.container !== "table") return;
    window.addEventListener("keydown", handleKeyDownToDeleteTable);

    return () => {
      window.removeEventListener("keydown", handleKeyDownToDeleteTable);
    };
  }, [focusStatus, handleKeyDownToDeleteTable]);

  return {
    tableWrapperRef,
    info,
    focusStatus,
    tableCustomTitle,
    isFocused,
    isLastTable,
    isVisibleTableCustomTitle,
    isEndpointsTypeTable,
    isEnableEdit,
    isFocusedAddButton,
    focusEventToChangePositionInfo,
    handleClickDeleteButton,
    handleFocusGroupShell,
    changePositionInfo,
    changeFocusStatus,
    changePositionInfoToDefault,
    changeInfoToCurrentTable,
    handleDefaultFocus,
  };
};
