"use client";

import styled, { css } from "styled-components";

import {
  Position,
  Alignment,
} from "../../../../../../shared/components/core/Tooltip/types/tooltip.types";

const alignmentStyle = {
  left: css`
    left: 0;
  `,
  right: css`
    right: 0;
  `,
  center: css`
    left: 50%;
    transform: translateX(-50%);
  `,
};

const positionStyle = {
  top: css`
    bottom: -15.5rem;
  `,
  bottom: css`
    top: -15.5rem;
  `,
  left: css``,
  right: css``,
};

const statusFontStyle = {
  up: css`
    color: var(--Color-Common-Text-Progress, #11ac45);
    font-weight: 700;
  `,
  down: css`
    color: var(--Color-Common-Text-Danger, #db0f1d);
    font-weight: 700;
  `,
  keep: css`
    color: var(--Color-Common-Text-Primary, #2a2f37);
    font-weight: 400;
  `,
};

export const TooltipWrapper = styled.div<{
  $position: Position;
  $alignment: Alignment;
  $isVisible: boolean;
}>`
  display: none;
  position: absolute;
  visibility: ${({ $isVisible }) => ($isVisible ? "visible" : "hidden")};
  z-index: 50;

  ${({ $position }) => positionStyle[$position]};
  ${({ $alignment }) => alignmentStyle[$alignment]};
`;

export const TooltipContent = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--Spacing-2, 0.2rem);
  font-family: var(--font-pt-serif);
`;

export const ChangeValue = styled.div<{
  $chipStatus: "up" | "keep" | "down";
}>`
  ${({ $chipStatus }) => statusFontStyle[$chipStatus]};
`;

export const PriceValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DateValue = styled.div`
  white-space: nowrap;
`;

export const PriceValue = styled.div`
  color: var(--Color-Label-Text-Interactive-Payment, #597181);
  font-size: 1.2rem;
  font-weight: 700;
  vertical-align: middle;
  white-space: nowrap;
`;

export const List = styled.li`
  display: flex;
  position: relative;
  padding-left: 1rem; // 점과 텍스트 사이의 간격 조정
  line-height: 130%;
  white-space: nowrap;

  &::before {
    content: "•"; // 점(dot) 추가
    position: absolute;
    left: 0;
    color: black; // 점의 색상 설정
  }
`;

export const MultiLineList = styled(List)`
  flex-direction: column;
`;
