"use client";

import styled from "styled-components";

export const DrugNameWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  min-height: 3.2rem;
  padding: 0.2rem;

  div {
    font-family: var(--font-pretendard) !important;
  }
`;

export const DrugNameTitle = styled.div`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: var(--font-pretendard);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  white-space: nowrap;
`;
