import React, { useId } from "react";

import * as S from "./SignInInput.style";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
}

const SignInInput = React.forwardRef(
  (
    { error, label, ...rest }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const inputId = useId();

    return (
      <S.SignInInputWrapper>
        {label && (
          <S.SignInInputLabel htmlFor={inputId}>{label}</S.SignInInputLabel>
        )}
        <S.SignInInput id={inputId} {...rest} ref={ref} />
        <S.SignInInputErrorMessage>{error}</S.SignInInputErrorMessage>
      </S.SignInInputWrapper>
    );
  },
);

export default SignInInput;
