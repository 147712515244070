import dayjs from "dayjs";

import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import {
  REF_CODE_BRAND_NAME,
  REF_CODE_CODE_NAME,
  REF_CODE_COMPANY_NAME,
  REF_CODE_DEVELOPMENT_STAGE,
  REF_CODE_INN_NAME,
  REF_CODE_MEDICAL_SPECIALTY,
  REF_CODE_MOA,
  REF_CODE_MODALITY,
  REF_CODE_OTHER_CHARACTERISTICS,
  REF_CODE_ROA,
  REF_CODE_SEPARATOR,
  REF_CODE_TARGET,
  REF_CODE_TARGET_INDICATION,
  REF_CODE_THERAPEUTIC_AREA,
  REF_CODE_GENERIC_BIO_SIMILAR,
  REF_CODE_REF_PRODUCT_NAME,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createRefCode } from "@EditArticleContainer/shared/libs/refCode/utils/createRefCode";
import { DrugProfileContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/drugProfileContent.types";
import { createContentFromHTML } from "@EditArticleContainer/shared/utils/getValueFromRefMap";

export const useCreateDrugProfileContent = () => {
  const refMap = useArticleContentRefMap();

  const indexList = [...refMap.keys()]
    .filter((key) => key.indexOf(REF_CODE_ROA) !== -1) // ROA를 토대로 Drug Profile의 개수를 파악합니다.
    .map((key) => key.split(REF_CODE_SEPARATOR)[1]);

  return () => {
    const content: DrugProfileContent = {
      text: {
        "Drug profile": indexList.map((index) => ({
          ROA: createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_ROA, index),
          ),
          Modality: createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_MODALITY, index),
          ),
          MOA: createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_MOA, index),
          ),
          "Code Name": createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_CODE_NAME, index),
          ),
          "INN Name": createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_INN_NAME, index),
          ),
          "Brand Name": createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_BRAND_NAME, index),
          ),
          "Medical Specialty": createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_MEDICAL_SPECIALTY, index),
          ),
          Target: createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_TARGET, index),
          ),
          "Other Characteristics": createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_OTHER_CHARACTERISTICS, index),
          ),
          "Therapeutic Area": createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_THERAPEUTIC_AREA, index),
          ),
          "Originator/Developer": createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_COMPANY_NAME, index),
          ),
          "Target Indication": createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_TARGET_INDICATION, index),
          ),
          "Development Stage": createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_DEVELOPMENT_STAGE, index),
          ),
          "Generic / Biosimilar": createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_GENERIC_BIO_SIMILAR, index),
          ),
          "Ref.Product Name": createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_REF_PRODUCT_NAME, index),
          ),
        })),
      },
      labels: [],
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
