import { ARTICLE_CONTENTS_DEAL_PURPOSE_SHELL_KEYS as DEAL_PURPOSE_SHELL_KEYS } from "@frontend/common";
import dayjs from "dayjs";

import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import {
  REF_CODE_ADDITIONAL_INFORMATION,
  REF_CODE_DEAL_PURPOSE,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { AdditionalInformationContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/additionalInformationContent.types";

import { createContentFromHTML } from "../../../utils/getValueFromRefMap";

export const useCreateAdditionalInformationContent = () => {
  const refMap = useArticleContentRefMap();

  return () => {
    const content: AdditionalInformationContent = {
      text: {
        "Additional Information": {
          "Additional Information": createContentFromHTML(
            refMap,
            REF_CODE_ADDITIONAL_INFORMATION,
          ),
          [DEAL_PURPOSE_SHELL_KEYS.dealPurpose]: createContentFromHTML(
            refMap,
            REF_CODE_DEAL_PURPOSE,
          ),
        },
      },
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
