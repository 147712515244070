import {
  ParagraphSectionLayout,
  ParagraphTitle,
  PARAGRAPH_CODE,
  DEALS_PARAGRAPH_CODE,
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
  ARTICLE_CONTENTS_DEAL_PURPOSE_SHELL_TITLE as DEAL_PURPOSE_SHELL_TITLE,
  ARTICLE_CONTENTS_DEAL_PURPOSE_SHELL_KEYS as DEAL_PURPOSE_SHELL_KEYS,
} from "@frontend/common";

import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import { useUpdateParagraph } from "@EditArticleContainer/shared/hooks/useUpdateParagraph";
import { REF_CODE_DEAL_PURPOSE } from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

import * as S from "./DealsParagraphDealPurpose.styles";

const COMMON_EDITABLE_TEXT_STYLE = {
  padding: "0.8rem",
  borderRadius: "0",
};
const VALUE_SHELL_PADDING = "0.2rem";

const PARAGRAPH_TITLE = DEAL_PURPOSE_SHELL_TITLE.dealPurpose;

const DealsParagraphDealPurpose = () => {
  const paragraph = useParagraphEditedData(
    PARAGRAPH_CODE.TY002.additionalInformation,
  );
  const [updateParagraph, loadingIndex, updateKeyMap] = useUpdateParagraph({
    paragraphCode: DEALS_PARAGRAPH_CODE.additionalInformation,
    sectionCode: "TY002",
  });

  const dealPurposeText = createMultiLineText({
    textList: paragraph.text["Additional Information"]?.[
      DEAL_PURPOSE_SHELL_KEYS.dealPurpose
    ]?.value?.map((shell) => shell.text ?? ""),
    parseString: (text) => `- ${text?.trim()}`,
  });

  const dealPurposeLabels =
    paragraph.text["Additional Information"][
      DEAL_PURPOSE_SHELL_KEYS.dealPurpose
    ]?.value?.at(0)?.labels ?? [];

  if (!dealPurposeText || dealPurposeText?.trim() === "") {
    return null;
  }

  return (
    <ParagraphSectionLayout>
      <ParagraphTitle title={PARAGRAPH_TITLE} />
      <S.ParagraphDealPurposeWrapper key={updateKeyMap.get(0)}>
        <S.ParagraphDealPurposeUpdateButton
          onClick={() => updateParagraph(0)}
          disabled={loadingIndex === 0}
        />
        <TableContainer>
          <TableRowContainer>
            <TableShellTitle>{PARAGRAPH_TITLE}</TableShellTitle>
          </TableRowContainer>
          <TableRowContainer>
            <TableShellValue padding={VALUE_SHELL_PADDING}>
              <EditableTextContainer
                refKey={REF_CODE_DEAL_PURPOSE}
                item={{
                  text: dealPurposeText,
                  labels: dealPurposeLabels,
                }}
                canTextEdit
                style={COMMON_EDITABLE_TEXT_STYLE}
              />
            </TableShellValue>
          </TableRowContainer>
        </TableContainer>
      </S.ParagraphDealPurposeWrapper>
    </ParagraphSectionLayout>
  );
};

export default DealsParagraphDealPurpose;
