import React, { useEffect, useRef } from "react";

import {
  CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS,
  CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { getGroupShellTitle } from "@/components/business/FillInTableContainer/shared/utils/getTextFromData";
import useCheckIsDisplaySubGroupShellDragDivider from "@FillInTableContainer/components/FillInTable/components/TableCreator/components/DealsTableCreator/components/DealsDealTermsTableCreatorContainer/components/DealTermsLicenseAgreementGroupTable/components/DealTermsLicenseAgreementTable/components/DealTermsLicenseAgreementGroupShellSection/components/DealTermsLicenseAgreementGroupShell/hooks/useCheckIsDisplaySubGroupShellDragDivider";
import { useGetNumberOfSupGroupShell } from "@FillInTableContainer/components/FillInTable/components/TableCreator/components/DealsTableCreator/components/DealsDealTermsTableCreatorContainer/components/DealTermsLicenseAgreementGroupTable/components/DealTermsLicenseAgreementTable/components/DealTermsLicenseAgreementGroupShellSection/components/DealTermsLicenseAgreementGroupShell/hooks/useGetNumberOfSubGroupShell";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { TableCreatorGroupShellTitle } from "@FillInTableContainer/shared";
import TableDragDivider from "@FillInTableContainer/shared/components/TableDragDivider/TableDragDivider";
import { useHandleTableCreatorSubGroupShellDrag } from "@FillInTableContainer/shared/hooks/useHandleTableCreatorSubGroupShellDrag";
import { isGroupShellScroll } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import * as S from "./ClinicalTrialDesignGroupShell.style";
import ClinicalTrialDesignSubGroupShell from "./components/ClinicalTrialDesignSubGroupShell/ClinicalTrialDesignSubGroupShell";
import { useHandleClinicalTrialsGroupShell } from "./hooks/useHandleClinicalTrialsGroupShell";

import type { ClinicalTrialsSection } from "@/types/clinicalTrials.types";
import type { TableCreatorSubGroupShellList } from "@FillInTableContainer/shared/types/tableCreator.types";

interface Props {
  groupTableKey: ClinicalTrialsSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  subGroupShellList: TableCreatorSubGroupShellList;
  tableKey: string | null;
  tableIndex: number;
  groupShellIndex: number;
  isTableScroll: boolean;
}
const isNotFocusGroupShell: string[] = [
  CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign2,
];

const dragSubGroupShellList: string[] = [
  CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS.arm,
];

const ClinicalTrialDesignGroupShell = ({
  groupTableKey,
  groupTableIndex,
  groupShellKey,
  subGroupShellList,
  tableKey,
  tableIndex,
  groupShellIndex,
  isTableScroll,
}: Props) => {
  const positionPropsInfo = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  };
  const { subGroupShellCount } = useGetNumberOfSupGroupShell();

  const articleSection = useArticleSection();
  const selectButtonRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const {
    focusStatus,
    isFocused,
    isClearGroupShell,
    isDeleteGroupShell,
    isAlertGroupShell,
    isEnableEdit,
    isFocusedAddButton,
    handleFocusGroupShell,
    handleDefaultFocus,
    clickForClearGroupShell,
  } = useHandleClinicalTrialsGroupShell({ positionPropsInfo });

  const {
    handleSubGroupShellDragEnd,
    handleSubGroupShellDragOn,
    handleSubGroupShellDragOver,
    handleSubGroupShellDragStart,
    subGroupShellDraggedIndexInfo,
    isDraggingSubGroupShell,
  } = useHandleTableCreatorSubGroupShellDrag(articleSection, tableIndex);

  const { isLastSubGroupShell, handleCheckDragDividerDisplay } =
    useCheckIsDisplaySubGroupShellDragDivider({
      tableIndex,
      groupShellIndex,
      isDraggingSubGroupShell,
    });

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;
        const clickSelectButton = selectButtonRef.current?.contains(target);
        const clickTitle = titleRef.current?.contains(target);

        if (clickSelectButton || clickTitle) return;
        if (target.dataset.button) return;

        handleDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleDefaultFocus, isDeleteGroupShell, isFocused, isFocusedAddButton]);

  const isPossibleSelected = groupShellKey
    ? !isNotFocusGroupShell.includes(groupShellKey)
    : true;

  const isGroupShellScrollWrapper =
    isGroupShellScroll(articleSection, groupShellKey) ||
    groupShellKey === CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign2;

  const checkIsDisplayDragDividerSubGroupShell = (
    subGroupShellKey: string | null,
  ) => {
    if (!subGroupShellKey) return false;

    return dragSubGroupShellList.includes(subGroupShellKey || "");
  };

  return (
    <>
      <S.GroupShellOutsideWrapper
        $isEnableEdit={isEnableEdit}
        isFocused={isFocused}
        isClearGroupShell={isClearGroupShell}
        isDeleteGroupShell={isDeleteGroupShell}
        isAlertGroupShell={isAlertGroupShell}
      >
        {(isClearGroupShell || isDeleteGroupShell) && isEnableEdit && (
          <S.FixedDeleteButtonWrapper
            isShellTitle={!!getGroupShellTitle(articleSection, groupShellKey)}
          >
            <S.DeleteTableButtonWrapper>
              <S.DeleteTableButton
                data-button="delete-button"
                onClick={clickForClearGroupShell}
              >
                <img src={IconXMark} alt="button for clear Row" />
                {focusStatus.type === "clear" ? `Clear Clinical Design` : null}
                {focusStatus.type === "delete"
                  ? `Delete Clinical Design`
                  : null}
              </S.DeleteTableButton>
            </S.DeleteTableButtonWrapper>
          </S.FixedDeleteButtonWrapper>
        )}
        <S.GroupShellWrapper
          isFocused={isFocused}
          isClearGroupShell={isClearGroupShell}
          isDeleteGroupShell={isDeleteGroupShell}
          isAlertGroupShell={isAlertGroupShell}
          isTableScroll={isTableScroll}
        >
          <S.TitleWrapper ref={titleRef}>
            {getGroupShellTitle(articleSection, groupShellKey) && (
              <TableCreatorGroupShellTitle
                groupShellKey={groupShellKey}
                groupShellCount={1}
                groupShellIndex={groupShellIndex}
                isAlertGroupShell={isAlertGroupShell}
                onClickHandler={handleFocusGroupShell}
                groupTableKey={groupTableKey}
                style={{
                  color: "var(--Color-Common-Text-Primary, #2A2F37)",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                }}
              />
            )}
          </S.TitleWrapper>
          <S.ScrollWrapper
            isTableScroll={isGroupShellScrollWrapper}
            $isFocused={isFocused}
          >
            <S.ScrollContentsWrapper>
              <S.SelectWrapper>
                <S.GroupShellSelectButtonDividerWrapper
                  ref={selectButtonRef}
                  onClick={handleFocusGroupShell}
                  isPossibleSelected={isPossibleSelected}
                >
                  <S.GroupShellSelectButtonDivider className="select-button divider" />
                  <S.GroupShellSelectButton className="select-button button">
                    SELECT
                  </S.GroupShellSelectButton>
                </S.GroupShellSelectButtonDividerWrapper>
                <S.GroupShell>
                  {subGroupShellList.map(
                    (
                      { subGroupShellKey, shellList },
                      subGroupShellListIndex,
                    ) => (
                      <React.Fragment
                        key={`shellList-${String(subGroupShellListIndex)}`}
                      >
                        {newArrayToCountValue(
                          subGroupShellCount(
                            subGroupShellKey,
                            tableIndex,
                            groupShellIndex,
                          ),
                        ).map((_, subGroupShellIndex) => {
                          return (
                            <S.DraggableSubGroupShellWrapper
                              isTargetDraggingTable={
                                isDraggingSubGroupShell &&
                                subGroupShellDraggedIndexInfo[0] !==
                                  subGroupShellIndex &&
                                checkIsDisplayDragDividerSubGroupShell(
                                  subGroupShellKey,
                                )
                              }
                              onDragOver={(event) =>
                                handleSubGroupShellDragOver(
                                  event,
                                  subGroupShellIndex,
                                )
                              }
                            >
                              {checkIsDisplayDragDividerSubGroupShell(
                                subGroupShellKey,
                              ) && (
                                <TableDragDivider
                                  isDraggingEndPosition={handleCheckDragDividerDisplay(
                                    {
                                      moveDragIndex:
                                        subGroupShellDraggedIndexInfo[1],
                                      startDragIndex:
                                        subGroupShellDraggedIndexInfo[0],
                                      subGroupShellIndex,
                                      subGroupShellKey,
                                    },
                                  )}
                                />
                              )}
                              <ClinicalTrialDesignSubGroupShell
                                key={`shell-${String(groupTableIndex)}-${String(
                                  tableIndex,
                                )}-${String(groupShellIndex)}-${String(
                                  subGroupShellIndex,
                                )}`}
                                groupTableKey={groupTableKey}
                                groupTableIndex={groupTableIndex}
                                groupShellKey={groupShellKey}
                                groupShellIndex={groupShellIndex}
                                subGroupShellKey={subGroupShellKey}
                                shellList={shellList}
                                subGroupShellIndex={subGroupShellIndex}
                                subGroupShellCount={subGroupShellCount(
                                  subGroupShellKey,
                                  tableIndex,
                                  groupShellIndex,
                                )}
                                tableKey={tableKey}
                                tableIndex={tableIndex}
                                isFocusedGroupShell={isFocused}
                                isClearGroupShell={isClearGroupShell}
                                isDeleteGroupShell={isDeleteGroupShell}
                                isAlertGroupShell={isAlertGroupShell}
                                isDraggingSubGroupShell={
                                  isDraggingSubGroupShell
                                }
                                handleDragEnd={handleSubGroupShellDragEnd}
                                handleDragOn={handleSubGroupShellDragOn}
                                handleDragStart={handleSubGroupShellDragStart}
                              />
                              {checkIsDisplayDragDividerSubGroupShell(
                                subGroupShellKey,
                              ) &&
                                isLastSubGroupShell(
                                  subGroupShellIndex,
                                  subGroupShellKey,
                                ) && (
                                  <TableDragDivider
                                    isDraggingEndPosition={handleCheckDragDividerDisplay(
                                      {
                                        moveDragIndex:
                                          subGroupShellDraggedIndexInfo[1],
                                        startDragIndex:
                                          subGroupShellDraggedIndexInfo[0],
                                        subGroupShellIndex,
                                        subGroupShellKey,
                                        lastIndex: newArrayToCountValue(
                                          subGroupShellCount(
                                            subGroupShellKey,
                                            tableIndex,
                                            groupShellIndex,
                                          ),
                                        ).length,
                                      },
                                    )}
                                  />
                                )}
                            </S.DraggableSubGroupShellWrapper>
                          );
                        })}
                      </React.Fragment>
                    ),
                  )}
                </S.GroupShell>
              </S.SelectWrapper>
            </S.ScrollContentsWrapper>
          </S.ScrollWrapper>
        </S.GroupShellWrapper>
      </S.GroupShellOutsideWrapper>
    </>
  );
};

export default ClinicalTrialDesignGroupShell;
