"use client";

import styled from "styled-components";

export const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
  flex: 1;
`;

export const EmphasizedText = styled.div`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 0.4rem;
`;
