import { CSSProperties } from "react";

import styled, { css } from "styled-components";

import type { BORDER_BOX_STYLE } from "./BorderBox";
import type { ValueOf } from "../../../types/util.types";

export const BorderBoxWrapper = styled.div<{
  height: CSSProperties["height"];
  radius: string;
  padding: string;
  borderBoxStyle: ValueOf<typeof BORDER_BOX_STYLE>;
  backgroundShadow: boolean;
  backgroundColor: string;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height};
  padding: 0.1rem;
  background: ${({ theme }) => theme.color.gradient.background1};
  border-radius: ${(props) => Number(props.radius) / 10}rem;

  ${({ borderBoxStyle }) =>
    borderBoxStyle === "alert" &&
    css`
      background: ${({ theme }) => theme.color.gradient.background4};
    `}

  ${({ borderBoxStyle }) =>
    borderBoxStyle === "gray" &&
    css`
      background: ${({ theme }) => theme.color.neural.gray05};
    `}

    ${({ borderBoxStyle }) =>
    borderBoxStyle === "gray02" &&
    css`
      background: ${({ theme }) => theme.color.neural.gray02};
    `}

    ${({ borderBoxStyle }) =>
    borderBoxStyle === "gray04" &&
    css`
      background: ${({ theme }) => theme.color.neural.gray04};
    `}

    ${({ borderBoxStyle }) =>
    borderBoxStyle === "sky" &&
    css`
      background: ${({ theme }) => theme.color.node.sky};
    `}

    ${({ borderBoxStyle }) =>
    borderBoxStyle === "disabled" &&
    css`
      background: ${({ theme }) => theme.color.neural.gray03};
    `}

    ${({ borderBoxStyle }) =>
    borderBoxStyle === "unsuitable" &&
    css`
      background: ${({ theme }) => theme.color.status.disable};
    `}

  & > .content {
    width: 100%;
    height: 100%;
    padding: ${(props) => Number(props.padding) / 10}rem;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: ${(props) => Number(props.radius) / 10 - 0.2}rem;

    ${({ backgroundShadow }) =>
      backgroundShadow &&
      css`
        background-repeat: no-repeat;
        background-size: 100% 13.2rem;
        background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.05) 100%
        );
        background-blend-mode: darken;
        background-position-y: bottom;
      `}
  }
`;
