import styled, { css } from "styled-components";

import {
  DividerWrapper,
  PlusBar,
  PlusButton,
} from "@FillInTableContainer/shared/styles/dividerPlusBar.style";

export const GroupShellDividerWrapper = styled(DividerWrapper)`
  left: 0.8rem;
  width: calc(100% - 1.6rem);

  ${({ isLastPosition }) =>
    isLastPosition &&
    css`
      top: calc(100% - 0.8rem);
    `}
`;

export const GroupShellDividerPlusBar = styled(PlusBar)`
  width: 100%;
`;

export const SubGroupShellPlusButton = styled(PlusButton)``;
