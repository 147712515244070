import styled, { css } from "styled-components";

export const DefaultGroupTableWrapper = styled.div<{
  isDisableEdit: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 0.8rem;
  border: 2px solid transparent;

  ${({ isDisableEdit }) =>
    isDisableEdit &&
    css`
      cursor: not-allowed;
    `}
`;

export const DefaultTableListWrapper = styled.div<{
  isDisableEdit: boolean;
}>`
  display: flex;
  flex-direction: column;

  ${({ isDisableEdit }) =>
    isDisableEdit &&
    css`
      opacity: 0.3;
      pointer-events: none;
      cursor: not-allowed;
    `}
`;

export const DefaultDraggableTableWrapper = styled.div<{
  isTargetDraggingTable: boolean;
}>`
  opacity: 1;
  ${({ isTargetDraggingTable }) =>
    isTargetDraggingTable &&
    css`
      opacity: 0.5;
    `}
`;
