import React from "react";

import { useHandleTableCreatorWrapperPositionInfo } from "@FillInTableContainer/shared/hooks/useHandleTableCreatorWrapperPositionInfo";
import { ArticleSectionType } from "@FillInTableContainer/types/fillInTable.types";

import FillInTableNavigation from "./components/FillInTableNavigation/FillInTableNavigation";
import { NAVIGATION_LIST } from "./constants/fillInTableNavigationList.constants";
import * as S from "./TableCreatorNavigationTableWrapper.style";

interface Props {
  classificationKey: ArticleSectionType;
  children: JSX.Element;
}

const TableCreatorNavigationTableWrapper = ({
  classificationKey,
  children,
}: Props) => {
  const { tableCreatorWrapperRef } = useHandleTableCreatorWrapperPositionInfo();

  const currentNavigationList = NAVIGATION_LIST[classificationKey];
  return (
    <S.TableCreatorWrapper>
      <FillInTableNavigation itemList={currentNavigationList} />
      <S.GroupTableWrapper ref={tableCreatorWrapperRef}>
        {children}
      </S.GroupTableWrapper>
    </S.TableCreatorWrapper>
  );
};

export default TableCreatorNavigationTableWrapper;
