import React, { useState } from "react";

import ClinicalTrialDesign2Table from "./components/ClinicalTrialDesign2Table";
import ClinicalTrialDesign2TableSwitched from "./components/ClinicalTrialDesign2TableSwitched";
import { ClinicalTrialDesign2 } from "../../utils/clinicalTrialDesign2";

interface Props {
  clinicalTrialDesign2: ClinicalTrialDesign2;
  designGroupTableIndex: number;
}

const ClinicalTrialsDesign2TableContainer = ({
  clinicalTrialDesign2,
  designGroupTableIndex,
}: Props) => {
  const [switchTable, setSwitchTable] = useState(
    clinicalTrialDesign2.armName.at(0)?.switch,
  );

  const changeSwitchTable = () => {
    setSwitchTable(!switchTable);
  };
  return (
    <>
      {switchTable ? (
        <ClinicalTrialDesign2TableSwitched
          clinicalTrialDesign2={clinicalTrialDesign2}
          designGroupTableIndex={designGroupTableIndex}
          switchTable={changeSwitchTable}
        />
      ) : (
        <>
          <ClinicalTrialDesign2Table
            clinicalTrialDesign2={clinicalTrialDesign2}
            designGroupTableIndex={designGroupTableIndex}
            switchTable={changeSwitchTable}
          />
        </>
      )}
    </>
  );
};

export default ClinicalTrialsDesign2TableContainer;
