import styled from "styled-components";

export const ClinicalTrialDesign1TableWrapper = styled.div`
  width: 100%;

  &.hide-row-true {
    display: none;
  }

  .hide-row-true {
    display: none;
  }

  .border-bottom-none-true {
    border-bottom: none;
  }
`;
