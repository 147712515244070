export const moveItemToPosition = <T>(
  targetList: T[],
  fromIndex: number,
  toIndex: number,
): T[] => {
  if (
    fromIndex >= 0 &&
    fromIndex < targetList.length &&
    toIndex >= 0 &&
    toIndex < targetList.length
  ) {
    const newItems = [...targetList];
    const [movedItem] = newItems.splice(fromIndex, 1);
    newItems.splice(toIndex, 0, movedItem);

    return newItems;
  }
  return targetList;
};
