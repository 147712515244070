"use client";

import styled from "styled-components";

export const TableWrapper = styled.div`
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 140%;
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
  .tablet {
    display: none;
  }

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    .mobile {
      display: block;
    }
    .tablet {
      display: none;
    }
    .desktop {
      display: none;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .mobile {
      display: none;
    }
    .tablet {
      display: block;
    }
    .desktop {
      display: none;
    }
  }
`;
