import { PressSearchFilterType } from "@PressListContainer/pressList.types";

export const EDITOR_INFO_FILTER: PressSearchFilterType[] = [
  {
    key: "allEditor",
    name: "All Editor",
    isAllInfo: true,
  },
  {
    key: "mcEditorName",
    name: "MC Editor",
    code: "S112",
    isAllInfo: false,
  },
  {
    key: "ftEditorName",
    name: "FT1 Editor",
    code: "S121",
    isAllInfo: false,
  },
  {
    key: "ftEditor2Name",
    name: "FT2 Editor",
    code: "S911",
    isAllInfo: false,
  },
  {
    key: "ftEditor",
    name: "FT Editor",
    code: "S911",
    isAllInfo: false,
  },
  {
    key: "pbEditorName",
    name: "Publisher",
    code: "S920",
    isAllInfo: false,
  },
];

export const PRESS_LIST_EDITOR_INFO_FILTER_KEY = [
  "allEditor",
  "mcEditorName",
  "ftEditorName",
  "ftEditor2Name",
  "ftEditor",
  "pbEditorName",
] as const;
