import styled, { css } from "styled-components";

export const PrimaryEndpointTag = styled.span`
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 0rem;
  left: 0rem;
  height: unset;

  border-radius: var(--Border-Radius-0, 0rem) var(--Border-Radius-0, 0rem)
    0.4rem var(--Border-Radius-0, 0rem);
  background: var(
    --Gradient-Color-NB-BBG06,
    linear-gradient(92deg, #0b6cff 25.97%, #25a6e4 100%)
  );

  color: var(--grayscale-spectrum-neural-blue-gray-02, #edf1f8);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  padding: 0.2rem 0.8rem;
`;

export const TableWrapper = styled.div`
  display: flex;
  gap: 1.2rem;
  flex-direction: column;
  width: 100%;
  .is-primary-title-true {
    background: var(
      --Color-Common-Bg-Interactive-Primary-Subtle-Pressed,
      #d1efff
    );
    font-size: 1.6rem;
  }

  .is-primary-value-true {
    position: relative;
    border-left: 2px solid
      var(--Color-Common-Border-Interactive-Primary, #0198e9);
    border-right: 2px solid
      var(--Color-Common-Border-Interactive-Primary, #0198e9) !important;
    background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #f0f9ff);
    color: var(--Color-Common-Text-Info, #004aeb);
    font-size: 1.6rem;
    font-weight: 600;

    &::before {
      position: absolute;
      top: -1px;
      left: -0.1rem;
      display: block;
      content: "";
      width: 0.1rem;
      height: 2px;
      background-color: var(--Color-Common-Border-Interactive-Primary, #0198e9);
    }
    &::after {
      position: absolute;
      top: -2px;
      right: -0.1rem;
      display: block;
      content: "";
      width: 0.1rem;
      height: 2px;
      background-color: var(--Color-Common-Border-Interactive-Primary, #0198e9);
    }

    &.is-last-row-true {
      border-bottom: 2px solid
        var(--Color-Common-Border-Interactive-Primary, #0198e9);
    }
  }

  #is-primary-shell-true {
    border-top: 2px solid
      var(--Color-Common-Border-Interactive-Primary, #0198e9);
    border-left: 2px solid
      var(--Color-Common-Border-Interactive-Primary, #0198e9);
    border-right: 2px solid
      var(--Color-Common-Border-Interactive-Primary, #0198e9);
  }
`;

export const RowWrapper = styled.div<{ $isNotTopPrimaryBorder?: boolean }>`
  position: relative;
  ${({ $isNotTopPrimaryBorder }) =>
    $isNotTopPrimaryBorder &&
    css`
      border-bottom: none !important;
    `}
`;

export const TableTitle = styled.div<{ $isDisplay: boolean }>`
  ${({ $isDisplay }) =>
    !$isDisplay &&
    css`
      display: none;
    `}

  & > div {
    width: 100%;
    color: var(--Color-Common-Text-Success-Bold, #015d8e);
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
`;

export const ShellTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  padding: 1rem;
  font-family: var(--font-pt-serif);
  white-space: pre-wrap;
`;
