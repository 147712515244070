import React, { ForwardedRef, forwardRef } from "react";

import * as S from "./WidthHandleBar.style";

type Props = React.InputHTMLAttributes<HTMLDivElement>;

const WidthHandleBar = forwardRef(
  ({ ...rest }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <S.WidthHandleBarWrapper>
        <S.WidthHandleBarMouseWrapper ref={ref} {...rest}>
          <S.WidthHandleBar />
          <S.WidthHandleBar />
        </S.WidthHandleBarMouseWrapper>
      </S.WidthHandleBarWrapper>
    );
  },
);

export default WidthHandleBar;
