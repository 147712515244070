import React, { Suspense } from "react";

import { IconImage } from "@frontend/common";

import { useInsertImageFilter } from "@InsertImageContainer/shared/contexts/InsertImageFilterContext";
import { useImageFilterKeyword } from "@InsertImageContainer/shared/hooks/useImageFilterKeyword";

import * as S from "./InsertImageFilter.style";
import InsertImageSearchInput from "./InsertImageSearchInput/InsertImageSearchInput";

const IMAGE_KEYWORD_TITLE = "Image Keyword";

const InsertImageFilter = () => {
  const { insertImageFilter } = useInsertImageFilter();
  const { keywordInfoListForImageList } = useImageFilterKeyword();

  const disabledKeywordLabels = !!(
    (insertImageFilter.filter as { search: string }).search || ""
  );
  return (
    <S.FilterWrapper>
      <S.FilterLabelBox $isDisabledKeywords={disabledKeywordLabels}>
        <strong>
          <IconImage />
          {IMAGE_KEYWORD_TITLE}
        </strong>
        <S.LabelWrapper>
          {keywordInfoListForImageList.map((keyword) => {
            if (keyword.value === "") return null;
            return (
              <S.FilterLabel key={keyword.value} entity={keyword.entity}>
                {keyword.value}
              </S.FilterLabel>
            );
          })}
        </S.LabelWrapper>
      </S.FilterLabelBox>
      <Suspense>
        <InsertImageSearchInput />
      </Suspense>
    </S.FilterWrapper>
  );
};

export default InsertImageFilter;
