import React, { CSSProperties } from "react";

import * as S from "./BorderBox.style";

import type { BorderRadius } from "../../../types/boxStyle.types";
import type { ValueOf } from "../../../types/util.types";

export const BORDER_BOX_STYLE = {
  default: "default",
  alert: "alert",
  unsuitable: "unsuitable",
  gray: "gray",
  gray02: "gray02",
  gray04: "gray04",
  sky: "sky",
  disabled: "disabled",
  backgroundShadow: "backgroundShadow",
} as const;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactElement | React.ReactElement[];
  radius?: BorderRadius;
  padding?: string;
  height?: CSSProperties["height"];
  borderBoxStyle?: ValueOf<typeof BORDER_BOX_STYLE>;
  className?: string;
  backgroundShadow?: boolean;
  backgroundColor?: string;
}

const BorderBox = ({
  children,
  height = "100%",
  radius = "10",
  padding = "40",
  borderBoxStyle = "default",
  className,
  backgroundShadow = false,
  backgroundColor = "white",
  ...rest
}: Props) => {
  return (
    <S.BorderBoxWrapper
      radius={radius}
      padding={padding}
      height={height}
      borderBoxStyle={borderBoxStyle}
      className={className}
      backgroundShadow={backgroundShadow}
      backgroundColor={backgroundColor}
      {...rest}
    >
      <div className="content">{children}</div>
    </S.BorderBoxWrapper>
  );
};

export default BorderBox;
