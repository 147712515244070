import React from "react";

import { useGenericBiosimilarCheckboxEvent } from "./hooks/useGenericBiosimilarCheckboxEvent";
import * as S from "./TableCreatorTextFieldCheckbox.style";
import TableCreatorTextFieldCheckboxInput from "../TableCreatorTextFieldCheckboxInput/TableCreatorTextFieldCheckboxInput";

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  shellKey: string;
  labelText: string;
  shellValue: string[];
  tableIndex: number;
}

const TableCreatorTextFieldCheckbox = ({
  shellKey,
  labelText,
  shellValue,
  tableIndex,
  ...rest
}: Props) => {
  const { checkGenericBiosimilar } = useGenericBiosimilarCheckboxEvent();
  const isChecked = shellValue.at(0) === "true";
  const handleCheckboxEvent = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (shellKey === "Generic / Biosimilar")
      checkGenericBiosimilar(isChecked, tableIndex);
  };

  return (
    <S.CheckboxWrapper>
      <S.CheckboxLabel>
        <TableCreatorTextFieldCheckboxInput
          checked={isChecked}
          onClick={handleCheckboxEvent}
          {...rest}
        />
        <S.CheckboxTextWrapper>
          <S.CheckboxText>{labelText}</S.CheckboxText>
        </S.CheckboxTextWrapper>
      </S.CheckboxLabel>
    </S.CheckboxWrapper>
  );
};

export default TableCreatorTextFieldCheckbox;
