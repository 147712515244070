import { RefObject } from "react";

import { convertHtmlToContent } from "@EditArticleContainer/shared/components/EditableTextContainer/utils/htmlConvert";

export const createContentFromHTML = (
  refMap: Map<string, RefObject<HTMLElement>>,
  refCode: string,
) => {
  return {
    value: [
      convertHtmlToContent(refMap.get(refCode)?.current?.innerHTML ?? ""),
    ],
  };
};

export const createContentFromHTMLForSwitchTable = (
  refMap: Map<string, RefObject<HTMLElement>>,
  refCode: string,
) => {
  return {
    value: [
      convertHtmlToContent(refMap.get(refCode)?.current?.innerHTML ?? ""),
    ],
    switch: Boolean(
      refMap.get(refCode)?.current?.getAttribute("data-switch") === "true",
    ),
  };
};

/*
  이 함수는 refCode의 후보가 여러가지 일때 사용합니다.
 */
export const findRefCodeAndCreateContentFromHTML = ({
  refMap,
  candidateRefCodeList,
}: {
  refMap: Map<string, RefObject<HTMLElement>>;
  candidateRefCodeList: string[];
}) => {
  const refCode = candidateRefCodeList.find(
    (code) => refMap.get(code)?.current,
  );

  return {
    value: [
      convertHtmlToContent(refMap.get(refCode ?? "")?.current?.innerHTML ?? ""),
    ],
  };
};

export const createEmptyContent = () => ({
  value: [convertHtmlToContent("")],
});
