import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import { useGetNumberOfSupGroupShell } from "./useGetNumberOfSubGroupShell";

interface Props {
  tableIndex: number;
  groupShellIndex: number;
  isDraggingSubGroupShell: boolean;
}

const useCheckIsDisplaySubGroupShellDragDivider = ({
  tableIndex,
  groupShellIndex,
  isDraggingSubGroupShell,
}: Props) => {
  const { subGroupShellCount } = useGetNumberOfSupGroupShell();

  const isFirstSubGroupShell = (subGroupShellIndex: number | null) =>
    subGroupShellIndex === 0;

  const isLastSubGroupShell = (
    subGroupShellIndex: number | null,
    subGroupShellKey: string | null,
  ) =>
    subGroupShellIndex ===
    newArrayToCountValue(
      subGroupShellCount(subGroupShellKey, tableIndex, groupShellIndex),
    ).length -
      1;

  const handleCheckDragDividerDisplay = ({
    moveDragIndex,
    subGroupShellIndex,
    lastIndex,
    subGroupShellKey,
    startDragIndex,
  }: {
    moveDragIndex: number | null;
    subGroupShellIndex: number;
    subGroupShellKey: string | null;
    lastIndex?: number;
    startDragIndex: number | null;
  }) => {
    if (moveDragIndex === null) return false;
    if (!isDraggingSubGroupShell) return false;

    if (isFirstSubGroupShell(startDragIndex) && !lastIndex) {
      return moveDragIndex + 1 === subGroupShellIndex;
    }

    if (isLastSubGroupShell(moveDragIndex, subGroupShellKey)) {
      return moveDragIndex + 1 === lastIndex;
    }

    return moveDragIndex === subGroupShellIndex;
  };

  return {
    isFirstSubGroupShell,
    isLastSubGroupShell,
    handleCheckDragDividerDisplay,
  };
};

export default useCheckIsDisplaySubGroupShellDragDivider;
