"use client";

import styled from "styled-components";

export const TitleInShell = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  min-height: 2.8rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 150%;
  padding: var(--Spacing-2, 0.2rem);
  margin-bottom: var(--Spacing-2, 0.4rem);

  &.pt-serif-font {
    font-family: var(--font-pt-serif);
  }

  &.editable-title-in-shell {
    padding: 0.2rem 1rem;
    margin-top: 0.8rem;
  }
`;
