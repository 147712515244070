"use client";

import styled from "styled-components";

export const PageOptionsWrapper = styled.div`
  display: flex;
  padding: 1.6rem 2rem;
  justify-content: space-between;
`;

export const ViewAndPaginationWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
`;
