import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { AUTHORITY_TYPE } from "@libs/roles/constants/authorityType.constants";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useAuthorityType } from "./useAuthorityType";
import { useEditorRoles } from "./useEditorRoles";
import { checkEditFullTableRoles } from "../util/checkEditFullTableRoles";
import { checkEditHalfTableRoles } from "../util/checkEditHalfTableRoles";
import { checkFullButtonRoles } from "../util/checkFullButtonRoles";
import { checkHalfButtonRoles } from "../util/checkHalfButtonRoles";
import { checkInsertImagePageAccessRoles } from "../util/checkInsertImagePageAccessRoles";
import { checkPressClassificationPageAccessRoles } from "../util/checkPressClassificationPageAccessRoles";

export const useFillInTableRoles = () => {
  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );
  const editorRoles = useEditorRoles();
  const authorityType = useAuthorityType();
  const isSuperAdmin = authorityType === AUTHORITY_TYPE.superAdmin;

  const status = articleInfo?.draftArticleStatus;
  const classification = articleInfo?.classificationManual;
  const fillInTableEditor1 = articleInfo?.ftEditorName;
  const fillInTableEditor2 = articleInfo?.ftEditor2Name;

  if (!status)
    return {
      hasEditHalfTableRoles: false,
      hasEditFullTableRoles: false,
      hasHalfButtonRoles: false,
      hasFullButtonRoles: false,
      hasNextPageAccessRoles: false,
      hasPrevPageAccessRoles: false,
    };

  const hasEditHalfTableRoles = checkEditHalfTableRoles(
    isSuperAdmin,
    editorRoles,
    status,
    classification,
    fillInTableEditor1,
  );

  const hasEditFullTableRoles = checkEditFullTableRoles(
    isSuperAdmin,
    editorRoles,
    status,
    classification,
    fillInTableEditor2,
  );

  const hasHalfButtonRoles = checkHalfButtonRoles(
    isSuperAdmin,
    editorRoles,
    status,
    classification,
    fillInTableEditor1,
  );

  const hasFullButtonRoles = checkFullButtonRoles(
    isSuperAdmin,
    editorRoles,
    status,
    classification,
    fillInTableEditor2,
  );

  const hasNextPageAccessRoles = checkInsertImagePageAccessRoles(
    isSuperAdmin,
    editorRoles,
    status,
    classification,
  );

  const hasPrevPageAccessRoles = checkPressClassificationPageAccessRoles(
    isSuperAdmin,
    editorRoles,
    status,
    classification,
  );

  return {
    hasEditHalfTableRoles,
    hasEditFullTableRoles,
    hasHalfButtonRoles,
    hasFullButtonRoles,
    hasNextPageAccessRoles,
    hasPrevPageAccessRoles,
    isSuperAdmin,
  };
};
