import React from "react";

import { useCurrentDealTypeValue } from "@FillInTableContainer/shared/context/CurrentDealTypeContext";

import DealTermsLicenseAgreementGroupTable from "./components/DealTermsLicenseAgreementGroupTable/DealTermsLicenseAgreementGroupTable";
import DealTermsMergeGroupTable from "./components/DealTermsMergeGroupTable/DealTermsMergeGroupTable";
import { DEAL_TERMS_LAYOUT } from "./constants/dealTermsLayout";

const GROUP_TABLE_INDEX = 2;

const DealsDealTermsTableCreatorContainer = () => {
  const licenseAgreementLayout = DEAL_TERMS_LAYOUT["License Agreement"];
  const mergeLayout = DEAL_TERMS_LAYOUT["M&A"];

  const { isMergeAndAcquisition } = useCurrentDealTypeValue();

  return (
    <>
      {isMergeAndAcquisition ? (
        <DealTermsMergeGroupTable
          key={`group-table-${String(GROUP_TABLE_INDEX)}`}
          groupTableKey={mergeLayout.groupTableKey}
          tableList={mergeLayout.tableList}
          groupTableIndex={GROUP_TABLE_INDEX}
        />
      ) : (
        <DealTermsLicenseAgreementGroupTable
          key={`group-table-${String(GROUP_TABLE_INDEX)}`}
          groupTableKey={licenseAgreementLayout.groupTableKey}
          tableList={licenseAgreementLayout.tableList}
          groupTableIndex={GROUP_TABLE_INDEX}
        />
      )}
    </>
  );
};

export default DealsDealTermsTableCreatorContainer;
