import useArticleSection from "@/components/business/FillInTableContainer/hooks/useArticleSection";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { GROUP_TABLE_PATH_TO_COUNT_TABLE } from "../constants/tableCreatorGroupTable.constants";
import { GroupTableKeyType } from "../types/tableCreator.types";

interface UseGetNumberOfTableReturn {
  tableCount: (groupTableKey: GroupTableKeyType) => number;
}

export const useGetNumberOfTable = (): UseGetNumberOfTableReturn => {
  const entryData = useEntryDataValue(getPressIdFromURL());
  const articleSection = useArticleSection();

  const tableCount = (groupTableKey: GroupTableKeyType) => {
    const groupTablePath = GROUP_TABLE_PATH_TO_COUNT_TABLE[articleSection];
    if (!groupTablePath) return 1;

    return (
      groupTablePath[groupTableKey as keyof typeof groupTablePath]?.(
        entryData,
      ) || 1
    );
  };

  return {
    tableCount,
  };
};
