import { useNavigate } from "react-router-dom";

import { ROUTE_PATHS } from "@routes/routePath";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export const useMovePage = () => {
  const navigate = useNavigate();

  return (
    page: keyof typeof ROUTE_PATHS,
    pressId: string = getPressIdFromURL(),
  ) =>
    page === "pressList"
      ? navigate({
          pathname: ROUTE_PATHS[page],
        })
      : navigate({
          pathname: ROUTE_PATHS[page],
          search: `id=${pressId}`,
        });
};
