interface ClassificationGroup {
  [key: string]: string;
}

export type StatusCode =
  | "S112"
  | "S300"
  | "S111"
  | "S120"
  | "S121"
  | "S910"
  | "S911"
  | "S210"
  | "S220"
  | "S920"
  | "S930";

export const STATUS_FILTER: { [key in StatusCode]: string } = {
  S112: "Not for AI Entry",
  S300: "AI Entry in Progress",
  S111: "AI Entry Done",
  S121: "Review in Progress (Half)",
  S120: "Review in Progress (Full)",
  S911: "DB Saved (Half)",
  S910: "DB Saved (Full)",
  S220: "Insert Image",
  S210: "Edit Article",
  S920: "Published",
  S930: "Unsuitable",
};

export const CLASSIFICATION_CODE: ClassificationGroup = {
  C000: "clinical, preclinical, R&D",
  C001: "clinical trial status",
  C003: "manufacture",
  C004: "conference schedule",
  C005: "conference, poster contents",
  C006: "deal, cooperation",
  C007: "human resource",
  C008: "publication, journal",
  C009: "business, financial update",
  C010: "patent, IP, lawsuit",
  C011: "shareholder, board",
  C012: "FI interaction, other financial news",
  C013: "approval process",
  C014: "stock option",
  C015: "regulatory meeting, committee, guideline",
  C016: "expedited program",
  C017: "reimbursement, coverage, PBM",
  C018: "launch",
  C019: "product performance",
  C020: "etc",
  C021: "venture capital",
  C022: "IPO, Listing",
  "": "none",
};

export const EDITOR_INFO_CODE: { [key in string]: StatusCode } = {
  mcEditorName: "S112",
  ftEditorName: "S121",
  ftEditor2Name: "S911",
  ftEditor: "S911",
  pbEditorName: "S920",
};
