import React from "react";

import * as S from "./DealTermsTableLayoutMobile.style";
import {
  useCheckWindowSize,
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
} from "../../../shared";

interface Props {
  items: (
    | {
        title: string;
        value: React.ReactNode;
      }[]
    | {
        title: string;
        value: React.ReactNode;
      }
  )[][];
}

const DealTermsTableLayoutMobile = ({ items }: Props) => {
  const { isMobileScreen } = useCheckWindowSize();
  const isExistTable = items?.length || 0;

  const combineItemTitleList = ["Key Asset(s)", "Asset(s)"];

  const transformItems = (
    data: (
      | {
          title: string;
          value: React.ReactNode;
        }[]
      | {
          title: string;
          value: React.ReactNode;
        }
    )[][],
  ) => {
    if (!Array.isArray(data?.[0])) return data;
    if (!Array.isArray(data?.[0]?.[0])) return data;

    const isTargetCombineItem = combineItemTitleList.includes(
      data?.[0]?.[0]?.[0]?.title || "",
    );
    if (!isTargetCombineItem) return data;

    return data.map((subItem) =>
      subItem.map((item, index) => {
        if (!Array.isArray(item)) return item;
        return [
          {
            title:
              index === 0 ? `${item?.[0]?.title} & ${item?.[1]?.title}` : "",
            value: (
              <>
                <S.EmphasizedText>{item?.[0]?.value}</S.EmphasizedText>
                {item?.[1]?.value}
              </>
            ),
          },
        ];
      }),
    );
  };

  if (!isExistTable) return null;
  if (!isMobileScreen) return null;
  return (
    <S.ContentsWrapper>
      {transformItems(items)?.map((tableItems) => (
        <TableContainer>
          {tableItems.map((item) => {
            if (!Array.isArray(item)) {
              return (
                <>
                  {item.title && (
                    <TableRowContainer>
                      <TableShellTitle>{item.title}</TableShellTitle>
                    </TableRowContainer>
                  )}
                  {item.value && (
                    <TableRowContainer>
                      <TableShellValue>{item.value}</TableShellValue>
                    </TableRowContainer>
                  )}
                </>
              );
            }
            return item.map((subItem) => {
              return (
                <>
                  {subItem?.title && (
                    <TableRowContainer>
                      <TableShellTitle>{subItem?.title}</TableShellTitle>
                    </TableRowContainer>
                  )}
                  {subItem?.value && (
                    <TableRowContainer>
                      <TableShellValue>{subItem?.value}</TableShellValue>
                    </TableRowContainer>
                  )}
                </>
              );
            });
          })}
        </TableContainer>
      ))}
    </S.ContentsWrapper>
  );
};

export default DealTermsTableLayoutMobile;
