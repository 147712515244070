import { useCallback } from "react";

import { APPROVAL_PROCESS_CONTENTS_KEY } from "@FillInTableContainer/shared/constants/regulatoryKey";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { TableInfoProps } from "../RegulatoryTableCreatorTable";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleApprovalProcessContentsTableReturn {
  handleClickApprovalProcessContentsDeleteButton: () => void;
  handleKeyDownToDeleteApprovalProcessContentsTable: (
    event: KeyboardEvent,
  ) => void;
}

export const useHandleApprovalProcessContentsTable = (
  tablePropsInfo: TableInfoProps,
): UseHandleApprovalProcessContentsTableReturn => {
  const { groupTableKey, tableKey, groupTableIndex, tableIndex } =
    tablePropsInfo;

  const { changeCurrentHistory } = useTableCreatorCurrentHistory();

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();

  const handleClearTable = useCallback(() => {
    const currentData = entryData.Regulatory?.["Approval Process Contents"];
    const key = APPROVAL_PROCESS_CONTENTS_KEY;

    const clearApprovalProcessContents = (): ArticleEntryData => {
      return {
        ...entryData,
        Regulatory: {
          ...entryData.Regulatory,
          "Approval Process Contents": {
            [key.additionalInformationOnApprovalProcess]: {
              ...currentData?.[key.additionalInformationOnApprovalProcess],
              value: [],
            },
            [key.ApprovalProcess]: currentData?.[key.ApprovalProcess]?.map(
              (groupShellInfo) => {
                return {
                  ...groupShellInfo,
                  [key.marketingStatus]: {
                    ...groupShellInfo?.[key.marketingStatus],
                    value: [],
                  },
                  [key.applicationType]: {
                    ...groupShellInfo?.[key.applicationType],
                    value: [],
                  },
                  [key.authorizedCompany]: {
                    ...groupShellInfo?.[key.authorizedCompany],
                    value: [],
                  },
                  [key.dosageAndAdministrationOnLabel]: {
                    ...groupShellInfo?.[key.dosageAndAdministrationOnLabel],
                    value: [],
                  },
                  [key.indicationOnLabel]: {
                    ...groupShellInfo?.[key.indicationOnLabel],
                    value: [],
                  },
                  [key.regulatoryAgency]: {
                    ...groupShellInfo?.[key.regulatoryAgency],
                    value: [],
                  },
                  [key.updateDate]: {
                    ...groupShellInfo?.[key.updateDate],
                    value: [],
                  },
                };
              },
            ),
          },
        },
      } as ArticleEntryData;
    };

    setEntryData(clearApprovalProcessContents());
    changeCurrentHistory({
      entryData: clearApprovalProcessContents(),
      changedDataInfo: {
        target: "table",
        action: "clear",
        position: {
          groupTableKey,
          groupTableIndex,
          tableKey,
          tableIndex,
          groupShellKey: null,
          groupShellIndex: null,
          shellKey: null,
          shellIndex: null,
          valueIndex: null,
          value: null,
        },
      },
    });

    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    entryData,
    groupTableIndex,
    groupTableKey,
    setEntryData,
    tableIndex,
    tableKey,
  ]);

  const handleClickApprovalProcessContentsDeleteButton = () => {
    if (focusStatus.type === "clear") {
      handleClearTable();
    }
  };

  const handleKeyDownToDeleteApprovalProcessContentsTable = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearTable();
      }

      changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    },
    [changeValidatedInfo, focusStatus.type, handleClearTable],
  );

  return {
    handleClickApprovalProcessContentsDeleteButton,
    handleKeyDownToDeleteApprovalProcessContentsTable,
  };
};
