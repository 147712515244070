import { useEffect } from "react";

import IconX from "@images/pressClassification/icon-unsuitable-x-mark.svg";

import { UNSUITABLE_REASON_LIST } from "./components/UnsuitableReasonCheckItem/constants/UnsuitableReason.constants";
import { useHandleUnsuitablePress } from "./components/UnsuitableReasonCheckItem/hooks/useHandleUnsuitablePress";
import UnsuitableReasonCheckItem from "./components/UnsuitableReasonCheckItem/UnsuitableReasonCheckItem";
import * as S from "./PressUnsuitableReasonContainer.style";

interface Props {
  closeModalUnsuitablePress: () => void;

  articleRejectReason: string | null;
  draftArticleId: number;
  mcEditorName: string | null;
  aiDone: boolean;
}

const PressUnsuitableReasonContainer = ({
  closeModalUnsuitablePress,

  articleRejectReason,
  draftArticleId,
  mcEditorName,
  aiDone,
}: Props) => {
  const { unsuitablePressReasonList, setUnsuitablePressReasonList } =
    useHandleUnsuitablePress();

  useEffect(() => {
    if (articleRejectReason) {
      const updateResponseDataList = unsuitablePressReasonList.map((item) => {
        if (item.reason === articleRejectReason && !!articleRejectReason) {
          return {
            ...item,
            isTarget: true,
          };
        }

        return {
          ...item,
          isTarget: false,
        };
      });
      setUnsuitablePressReasonList(updateResponseDataList);
    } else {
      setUnsuitablePressReasonList(UNSUITABLE_REASON_LIST);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleRejectReason]);

  return (
    <>
      <S.Background onClick={closeModalUnsuitablePress} />
      <S.PressUnsuitableReasonContainer>
        <S.TopWrapper>
          <S.Text>
            Please select only one reason why the article is not suitable.
          </S.Text>
          <S.CloseButton onClick={closeModalUnsuitablePress}>
            <img src={IconX} alt="close button" />
          </S.CloseButton>
        </S.TopWrapper>
        <S.ReasonCheckListBox>
          {unsuitablePressReasonList.map((item) => (
            <UnsuitableReasonCheckItem
              key={item.id}
              name="unsuitableReason"
              reasonId={item.id}
              reasonType={item.type}
              isTarget={item.isTarget}
              placeholder={item.placeholder}
              reason={item.reason}
              explanation={item.explanation}
              duplId={item.duplId}
              articleRejectReason={articleRejectReason}
              unsuitablePressReasonList={unsuitablePressReasonList}
              setUnsuitablePressReasonList={setUnsuitablePressReasonList}
              draftArticleId={draftArticleId}
              mcEditorName={mcEditorName}
              aiDone={aiDone}
              closeModalUnsuitablePress={closeModalUnsuitablePress}
            />
          ))}
        </S.ReasonCheckListBox>
      </S.PressUnsuitableReasonContainer>
    </>
  );
};

export default PressUnsuitableReasonContainer;
