import { useEntryDataQuery } from "@api/entryData/entryData";
import { DEAL_TYPE_ENTITY } from "@constants/labels.constants";

export const useDealTypeList = () => {
  const entryData = useEntryDataQuery();
  const DEAL_TYPE_LIST =
    entryData.data.Deals?.["Deal Type & Status"]?.["Deal Type"].value?.map(
      (item) => item.text.trim(),
    ) ?? [];

  const dealTypeList: { value: string; entity: string }[] = Array.from(
    new Set([...DEAL_TYPE_LIST]),
  ).map((text) => ({ value: text, entity: DEAL_TYPE_ENTITY }));

  return { dealTypeList };
};
