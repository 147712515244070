import styled, { css } from "styled-components";

import { SHELL_WIDTH } from "../constants/shellSize.constants";
import { ShellColumnSize } from "../types/shellSize.types";

export interface ShellWrapperProps {
  column: ShellColumnSize;
}

export const BorderWrapper = styled.div<{
  isFocused: boolean;
  isClearStatus: boolean;
  isDeleteStatus: boolean;
  isAlertStatus: boolean;
  isSeparatedShellTitle?: boolean;
  isTitleRowShell?: boolean;
  isDisableEdit: boolean;
  $isPressed: boolean;
  $isDragging?: boolean;
  column?: ShellColumnSize;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  border: 0.1rem solid ${({ theme }) => theme.color.neural.gray02};
  background-color: ${({ theme }) => theme.color.neural.white};
  padding: 0 0.8rem 0.8rem 0.8rem;
  cursor: pointer;

  ${({ isFocused }) =>
    isFocused &&
    css`
      border-radius: var(--Border-Radius-0, 0rem);
      border: 1px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);
      background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #e6f6ff);
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.16);

      .filled-text-field {
        border: 1px solid
          var(--Color-Common-Border-Interactive-Primary, #0198e9);
        background: var(--Color-Common-Bg-Primary, #fff);
      }
      .empty-text-field {
        border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
        background: var(--Color-Common-Bg-Secondary, #f0f2f4);
      }
    `}

  ${({ isSeparatedShellTitle }) =>
    isSeparatedShellTitle &&
    css`
      height: 100%;
      padding: 0.4rem;
    `}
  
  ${({ isTitleRowShell }) =>
    isTitleRowShell &&
    css`
      border: 0.1rem solid ${({ theme }) => theme.color.neural.gray03};
      border-radius: 0.4rem;
    `}
  
    ${({ isClearStatus }) =>
    isClearStatus &&
    css`
      border: 1px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);
      background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #e6f6ff);
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.16);

      .filled-text-field {
        border: 1px solid
          var(--Color-Common-Border-Interactive-Primary, #0198e9);
        background: var(--Color-Common-Bg-Primary, #fff);
      }
      .empty-text-field {
        border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
        background: var(--Color-Common-Bg-Secondary, #f0f2f4);
      }
      .last-empty-text-field {
        border: 1px dashed var(--Color-Common-Border-Tertiary, #e5e7eb);
        background: var(--Color-Common-Bg-Interactive-Secondary, #fff);
      }
    `}
  
    ${({ isDeleteStatus }) =>
    isDeleteStatus &&
    css`
      border: 1px solid var(--Color-Border-Error, #f9a9d0);
      background: var(--Color-Bg-Error, #fcd4e7);

      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.16);

      .filled-text-field {
        border: 1px solid
          var(--Color-Common-Border-Interactive-Primary, #0198e9);
        background: var(--Color-Common-Bg-Primary, #fff);
      }
      .empty-text-field {
        border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
        background: var(--Color-Common-Bg-Secondary, #f0f2f4);
      }
      .last-empty-text-field {
        border: 1px dashed var(--Color-Common-Border-Tertiary, #e5e7eb);
        background: var(--Color-Common-Bg-Interactive-Secondary, #fff);
      }
    `}
  
    ${({ isAlertStatus }) =>
    isAlertStatus &&
    css`
      border-radius: var(--Border-Radius-0, 0rem);
      border: 1px solid var(--Color-Common-Border-Warning-Subtle, #fdbba5);
      background: var(--Color-Common-Bg-Warning-Subtle, #feddd2);
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.16);

      .filled-text-field {
        border: 1px solid var(--Color-Common-Border-Warning-Subtle, #fdbba5);
        background: var(--Color-Common-Bg-Primary, #fff);
      }
      .empty-text-field {
        border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
        background: var(--Color-Common-Bg-Secondary, #f0f2f4);
      }
      .last-empty-text-field {
        border: 1px dashed var(--Color-Common-Border-Tertiary, #e5e7eb);
        background: var(--Color-Common-Bg-Interactive-Secondary, #fff);
      }
    `}
  
    ${({ isDisableEdit: isEnableEdit }) =>
    isEnableEdit &&
    css`
      cursor: not-allowed;
    `}
  
  
    &:hover {
    border-radius: var(--Border-Radius-0, 0rem);
    border: 1px solid
      var(--Color-Border-Interactive-Primary-Subtle-Hovered, #a4dfff);
    background: var(--Color-Bg-Primary, #fff);

    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
      0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  ${({ $isPressed, $isDragging }) =>
    $isPressed &&
    !$isDragging &&
    css`
      &:active {
        border-radius: var(--Border-Radius-0, 0rem);
        border: 1px solid
          var(--Color-Common-Border-Interactive-Primary, #0198e9);
        background: var(
          --Color-Common-Bg-Interactive-Primary-Subtle-Pressed,
          #a4dfff
        );
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
          0px 1px 2px 0px rgba(0, 0, 0, 0.16);
      }
    `}

  ${({ column }) =>
    column &&
    column === "regularWithValidation" &&
    css`
      width: ${SHELL_WIDTH.regular};
    `}
`;

export const DefaultShellWrapper = styled.div<ShellWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ column }) => SHELL_WIDTH[column]};
`;

export const DefaultDeleteTableButtonWrapper = styled.div`
  position: absolute;
  top: -2.3rem;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  transform: translateX(-50%);
`;
export const DefaultDeleteTableButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  height: 2.4rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  padding: 0.4rem;
  border-radius: 0.2rem;
  background: var(--Color-Common-Bg-Warning, #e53e06);
  white-space: nowrap;
  cursor: pointer;
  z-index: 10;
`;
