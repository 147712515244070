import styled, { css } from "styled-components";

import { SHELL_TITLE_FONTSIZE } from "@FillInTableContainer/shared/constants/shellSize.constants";

import type { ShellColumnSize } from "@FillInTableContainer/shared/types/shellSize.types";

export const ShellTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.4rem;
`;

export const ShellTitle = styled.h5<{
  columnSize: ShellColumnSize;
  isFocused: boolean;
  isClearShell: boolean;
  isDeleteShell?: boolean;
  isAlertShell: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  width: 100%;
  height: 2.4rem;
  font-size: ${({ columnSize }) => SHELL_TITLE_FONTSIZE[columnSize]};
  font-weight: 600;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  padding-top: 0.4rem;

  ${({ isFocused }) =>
    isFocused &&
    css`
      color: var(--Color-Common-Text-Primary, #2a2f37);
    `}

  ${({ isClearShell }) =>
    isClearShell &&
    css`
      color: var(--Color-Common-Text-Primary, #2a2f37);
    `}

  ${({ isDeleteShell }) =>
    isDeleteShell &&
    css`
      color: var(--Color-Common-Text-Primary, #2a2f37);
    `}

    ${({ isAlertShell }) =>
    isAlertShell &&
    css`
      color: var(--Color-Common-Text-Primary, #2a2f37);
    `}
`;

export const EmptyTitle = styled.div``;
