import React from "react";

import ReactDom from "react-dom";

interface Props {
  children: React.ReactNode;
}
const Portal = ({ children }: Props) => {
  const rootModal = document.getElementById("root-modal");

  return ReactDom.createPortal(children, rootModal as HTMLElement);
};

export default Portal;
