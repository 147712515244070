import {
  DealTermsTableContainer,
  PARAGRAPH_CODE,
  ARTICLE_CONTENTS_DEAL_TERMS_KEYS as DEAL_TERMS_KEY,
  ARTICLE_DETAIL_DEAL_TERMS_MA_SHELL_TITLE as SHELL_TITLE,
  ARTICLE_DETAIL_DEAL_TERMS_TABLE_TITLE as TABLE_TITLE,
  ARTICLE_DETAIL_DEAL_TERMS_TABLE_DESCRIPTION as TABLE_DESCRIPTION,
  ARTICLE_DETAIL_DEAL_TERMS_MA_GROUP_SHELL_TITLE as GROUP_SHELL_TITLE,
  ArticleDetailTableDescriptionTooltip,
} from "@frontend/common";

import { useCreateCompanyLogoComponentList } from "@EditArticleContainer/shared/hooks/useCreateCompanyLogoComponentList";
import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import {
  REF_CODE_ACQUIRED_COMPANY_NAME,
  REF_CODE_ACQUIRED_COMPANY_RESPONSIBILITY,
  REF_CODE_ACQUIRING_COMPANY_NAME,
  REF_CODE_ACQUIRING_COMPANY_RESPONSIBILITY,
  REF_CODE_DEAL_CONTINGENT_MILESTONE_MA,
  REF_CODE_DEAL_CONTINGENT_PAYMENT_GROUP,
  REF_CODE_DEAL_CONTINGENT_PAYMENT_MA,
  REF_CODE_DEAL_CONTINGENT_PRICE_PER_SHARE_MA,
  REF_CODE_DEAL_MERGER_FINANCIAL_TERMS_MA,
  REF_CODE_DEAL_UPFRONT_PAYMENT_MA,
  REF_CODE_DEAL_UPFRONT_PRICE_PER_SHARE_MA,
  REF_CODE_LICENSEE_DRUG,
  REF_CODE_LICENSEE_INDICATION,
  REF_CODE_NUMBER_OF_ACQUIRING_SHARES,
  REF_CODE_OTHER_FINANCIAL_TERMS,
  REF_CODE_PRICE_PER_SHARE,
  REF_CODE_RIGHTS_SCOPE,
  REF_CODE_TOTAL_PRICE,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createRefCode } from "@EditArticleContainer/shared/libs/refCode/utils/createRefCode";
import {
  createEditArticleShellList,
  createEditArticleSingleTableShellList,
} from "@EditArticleContainer/shared/utils/createEditArticleShellList";
import { createIndexList } from "@utils/createIndexList";
import { padStart } from "@utils/padStart/padStart";

import { checkEveryTextIsEmpty } from "./utils/checkEveryTextIsEmpty";
import {
  acquiredCompanyNameLabels,
  acquiredCompanyNameText,
  acquiredCompanyResponsibilityLabels,
  acquiredCompanyResponsibilityText,
  acquiringCompanyNameLabels,
  acquiringCompanyNameText,
  acquiringCompanyResponsibilityLabels,
  acquiringCompanyResponsibilityText,
  contingentMilestoneLabels,
  contingentMilestoneText,
  contingentPaymentLabels,
  contingentPaymentText,
  contingentPricePerShareLabels,
  contingentPricePerShareText,
  indicationText,
  keyAssetsLabels,
  keyAssetsText,
  mergerFinancialTermsLabels,
  mergerFinancialTermsText,
  numberOfAcquiringSharesLabels,
  numberOfAcquiringSharesText,
  otherFinancialTermsLabels,
  otherFinancialTermsText,
  pricePerShareLabels,
  pricePerShareText,
  totalPriceLabels,
  totalPriceText,
  upfrontPaymentLabels,
  upfrontPaymentText,
  upfrontPricePerShareLabels,
  upfrontPricePerShareText,
} from "./utils/getMergeAndAcquisitionShellValue";
import { returnGroupShellTitleItemList } from "./utils/returnGroupShellTitleItemList";
import { transformContingentTableLayout } from "./utils/transformContingentTableLayout";
import { useSellerBuyerByDealType } from "../../hooks/useSellerBuyerByDealType";
import { deduplicateRightsScopeForMA } from "../../utils/deduplicateRightsScope";

const MergeAndAcquisitionTable = () => {
  const { createCompanyLogoComponentList } =
    useCreateCompanyLogoComponentList();
  const dealTerms = useParagraphEditedData(PARAGRAPH_CODE.TY002.dealTerms);
  const { sellerName, buyerName } = useSellerBuyerByDealType();

  const SHELL_TEXT = {
    acquiredCompanyNameText: acquiredCompanyNameText(dealTerms),
    acquiredCompanyResponsibilityText:
      acquiredCompanyResponsibilityText(dealTerms),
    acquiringCompanyNameText: acquiringCompanyNameText(dealTerms),
    acquiringCompanyResponsibilityText:
      acquiringCompanyResponsibilityText(dealTerms),
    totalPriceText: totalPriceText(dealTerms),
    pricePerShareText: pricePerShareText(dealTerms),
    numberOfAcquiringSharesText: numberOfAcquiringSharesText(dealTerms),
    otherFinancialTermsText: otherFinancialTermsText(dealTerms),
    mergerFinancialTermsText: mergerFinancialTermsText(dealTerms),
    upfrontPaymentText: upfrontPaymentText(dealTerms),
    upfrontPricePerShareText: upfrontPricePerShareText(dealTerms),
  };

  const SHELL_LABELS = {
    acquiredCompanyNameLabels: acquiredCompanyNameLabels(dealTerms),
    acquiredCompanyResponsibilityLabels:
      acquiredCompanyResponsibilityLabels(dealTerms),
    acquiringCompanyNameLabels: acquiringCompanyNameLabels(dealTerms),
    acquiringCompanyResponsibilityLabels:
      acquiringCompanyResponsibilityLabels(dealTerms),
    totalPriceLabels: totalPriceLabels(dealTerms),
    pricePerShareLabels: pricePerShareLabels(dealTerms),
    numberOfAcquiringSharesLabels: numberOfAcquiringSharesLabels(dealTerms),
    otherFinancialTermsLabels: otherFinancialTermsLabels(dealTerms),
    keyAssetsLabels: keyAssetsLabels(dealTerms),
    mergerFinancialTermsLabels: mergerFinancialTermsLabels(dealTerms),
    upfrontPaymentLabels: upfrontPaymentLabels(dealTerms),
    upfrontPricePerShareLabels: upfrontPricePerShareLabels(dealTerms),
  };

  const originalRightsScope =
    dealTerms.text[DEAL_TERMS_KEY.dealTerms][DEAL_TERMS_KEY.licenseeTable].at(
      0,
    )?.[DEAL_TERMS_KEY.rightsScope] ?? [];
  const targetRightsScope = deduplicateRightsScopeForMA(originalRightsScope);
  const rightsScopeEmptyList = createIndexList(targetRightsScope?.length ?? 0);

  const targetContingentPayment =
    dealTerms.text[DEAL_TERMS_KEY.dealTerms][DEAL_TERMS_KEY.licenseeTable]?.at(
      0,
    )?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
      DEAL_TERMS_KEY.contingentPayment
    ] ?? [];

  const contingentPaymentEmptyList = createIndexList(
    dealTerms.text[DEAL_TERMS_KEY.dealTerms][DEAL_TERMS_KEY.licenseeTable].at(
      0,
    )?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
      DEAL_TERMS_KEY.contingentPayment
    ]?.length ?? 0,
  );

  const upfrontPaymentItems = [
    {
      title: SHELL_TITLE[DEAL_TERMS_KEY.upfrontPaymentMa],
      text: SHELL_TEXT.upfrontPaymentText,
      labels: SHELL_LABELS.upfrontPaymentLabels,
      refKey: REF_CODE_DEAL_UPFRONT_PAYMENT_MA,
      titleInShell: true,
    },
    {
      title: SHELL_TITLE[DEAL_TERMS_KEY.upfrontPricePerShareMa],
      text: SHELL_TEXT.upfrontPricePerShareText,
      labels: SHELL_LABELS.upfrontPricePerShareLabels,
      refKey: REF_CODE_DEAL_UPFRONT_PRICE_PER_SHARE_MA,
      titleInShell: true,
    },
  ]?.filter((item) => !!item.text);

  const isUpfrontPaymentEmpty = checkEveryTextIsEmpty(upfrontPaymentItems);
  const contingencyPaymentLength = contingentPaymentEmptyList.length;

  const contingentPaymentItems = [
    ...contingentPaymentEmptyList
      .map((rowIndex) => {
        if (
          !contingentPaymentText(rowIndex, targetContingentPayment) &&
          !contingentPricePerShareText(rowIndex, targetContingentPayment) &&
          !contingentMilestoneText(rowIndex, targetContingentPayment)
        ) {
          return [];
        }
        const isTitleWithNumber = contingencyPaymentLength > 1;
        return [
          {
            title: `${SHELL_TITLE[DEAL_TERMS_KEY.contingentPaymentMa]} ${
              isTitleWithNumber ? padStart(rowIndex + 1) : ""
            }`,
            text: contingentPaymentText(rowIndex, targetContingentPayment),
            labels: contingentPaymentLabels(rowIndex, targetContingentPayment),
            refKey: createRefCode(
              REF_CODE_DEAL_CONTINGENT_PAYMENT_GROUP,
              REF_CODE_DEAL_CONTINGENT_PAYMENT_MA,
              rowIndex,
            ),
            titleInShell: true,
          },
          {
            title: `${SHELL_TITLE[DEAL_TERMS_KEY.contingentPricePerShareMa]} ${
              isTitleWithNumber ? padStart(rowIndex + 1) : ""
            }`,
            text: contingentPricePerShareText(
              rowIndex,
              targetContingentPayment,
            ),
            labels: contingentPricePerShareLabels(
              rowIndex,
              targetContingentPayment,
            ),
            refKey: createRefCode(
              REF_CODE_DEAL_CONTINGENT_PAYMENT_GROUP,
              REF_CODE_DEAL_CONTINGENT_PRICE_PER_SHARE_MA,
              rowIndex,
            ),
            titleInShell: true,
          },
          {
            title: `${SHELL_TITLE[DEAL_TERMS_KEY.contingentMilestoneMa]} ${
              isTitleWithNumber ? padStart(rowIndex + 1) : ""
            }`,
            text: contingentMilestoneText(rowIndex, targetContingentPayment),
            labels: contingentMilestoneLabels(
              rowIndex,
              targetContingentPayment,
            ),
            refKey: createRefCode(
              REF_CODE_DEAL_CONTINGENT_PAYMENT_GROUP,
              REF_CODE_DEAL_CONTINGENT_MILESTONE_MA,
              rowIndex,
            ),
            titleInShell: true,
          },
        ];
      })
      .filter((item) => item.length > 0),
  ];

  const totalPaymentItems = [
    {
      title: SHELL_TITLE[DEAL_TERMS_KEY.totalPaymentMa],
      text: SHELL_TEXT.totalPriceText,
      labels: SHELL_LABELS.totalPriceLabels,
      refKey: REF_CODE_TOTAL_PRICE,
      titleInShell: true,
    },
    {
      title: SHELL_TITLE[DEAL_TERMS_KEY.pricePerShare],
      text: SHELL_TEXT.pricePerShareText,
      labels: SHELL_LABELS.pricePerShareLabels,
      refKey: REF_CODE_PRICE_PER_SHARE,
      titleInShell: true,
    },
  ]?.filter((item) => !!item.text);

  const isTotalPaymentEmpty = checkEveryTextIsEmpty(totalPaymentItems);

  const topTableItems = [
    createEditArticleShellList([
      {
        title: sellerName || SHELL_TITLE[DEAL_TERMS_KEY.licensorName],
        text: SHELL_TEXT.acquiredCompanyNameText,
        labels: SHELL_LABELS.acquiredCompanyNameLabels,
        refKey: REF_CODE_ACQUIRED_COMPANY_NAME,
        imagePosition: "left",
        imageNodeList: createCompanyLogoComponentList(
          SHELL_TEXT.acquiredCompanyNameText.replaceAll("- ", "").split(`\n`) ??
            "",
        ),
        isDisabled: true,
      },
      {
        title: SHELL_TITLE[DEAL_TERMS_KEY.licensorResponsibilityRights],
        text: SHELL_TEXT.acquiredCompanyResponsibilityText,
        labels: SHELL_LABELS.acquiredCompanyResponsibilityLabels,
        refKey: REF_CODE_ACQUIRED_COMPANY_RESPONSIBILITY,
      },
    ]),
    createEditArticleShellList([
      {
        title: buyerName || SHELL_TITLE[DEAL_TERMS_KEY.licenseeName],
        text: SHELL_TEXT.acquiringCompanyNameText,
        labels: SHELL_LABELS.acquiringCompanyNameLabels,
        refKey: REF_CODE_ACQUIRING_COMPANY_NAME,
        imagePosition: "left",
        imageNodeList: createCompanyLogoComponentList(
          SHELL_TEXT.acquiringCompanyNameText
            .replaceAll("- ", "")
            .split(`\n`) ?? "",
        ),
        isDisabled: true,
      },
      {
        title: SHELL_TITLE[DEAL_TERMS_KEY.licenseeResponsibilityRightsMa],
        text: SHELL_TEXT.acquiringCompanyResponsibilityText,
        labels: SHELL_LABELS.acquiringCompanyResponsibilityLabels,
        refKey: REF_CODE_ACQUIRING_COMPANY_RESPONSIBILITY,
      },
    ]),
  ];

  const isBottomTableItemsList =
    rightsScopeEmptyList
      .flatMap((rowIndex) => [
        keyAssetsText(rowIndex, targetRightsScope),
        indicationText(rowIndex, targetRightsScope),
      ])
      .filter((item) => !!item).length > 0;

  const bottomTableItemsList = [
    isBottomTableItemsList
      ? [
          createEditArticleShellList([
            ...rightsScopeEmptyList
              .map((rowIndex) => {
                if (
                  !keyAssetsText(rowIndex, targetRightsScope) &&
                  !indicationText(rowIndex, targetRightsScope)
                ) {
                  return [];
                }
                return [
                  {
                    title:
                      rowIndex === 0 ? SHELL_TITLE[DEAL_TERMS_KEY.drug] : "",
                    text: keyAssetsText(rowIndex, targetRightsScope),
                    labels: SHELL_LABELS.keyAssetsLabels,
                    refKey: createRefCode(
                      REF_CODE_RIGHTS_SCOPE,
                      REF_CODE_LICENSEE_DRUG,
                      rowIndex,
                      0,
                    ),
                  },
                  {
                    title:
                      rowIndex === 0
                        ? SHELL_TITLE[DEAL_TERMS_KEY.indication]
                        : "",
                    text: indicationText(rowIndex, targetRightsScope),
                    labels: SHELL_LABELS.keyAssetsLabels,
                    refKey: createRefCode(
                      REF_CODE_RIGHTS_SCOPE,
                      REF_CODE_LICENSEE_INDICATION,
                      rowIndex,
                      0,
                    ),
                  },
                ];
              })
              .filter((item) => item.length > 0),
          ]),
        ]
      : [],
    [
      !isUpfrontPaymentEmpty
        ? createEditArticleShellList([
            returnGroupShellTitleItemList(
              GROUP_SHELL_TITLE[DEAL_TERMS_KEY.upfrontPayment],
            ),
            upfrontPaymentItems,
          ])
        : [],

      contingentPaymentItems.length > 0
        ? createEditArticleShellList([
            returnGroupShellTitleItemList(
              GROUP_SHELL_TITLE[DEAL_TERMS_KEY.contingentPayment],
            ),

            ...contingentPaymentItems,
          ])
        : [],
      !isTotalPaymentEmpty
        ? createEditArticleShellList([
            returnGroupShellTitleItemList(
              GROUP_SHELL_TITLE[DEAL_TERMS_KEY.totalPaymentMa],
            ),
            totalPaymentItems,
          ])
        : [],

      createEditArticleShellList([
        {
          title: SHELL_TITLE[DEAL_TERMS_KEY.numberOfAcquiringShares],
          text: SHELL_TEXT.numberOfAcquiringSharesText,
          labels: SHELL_LABELS.numberOfAcquiringSharesLabels,
          refKey: REF_CODE_NUMBER_OF_ACQUIRING_SHARES,
        },
      ]),
    ],
  ];

  const bottomTableHeaderItems = [
    TABLE_TITLE.keyAssetsMainUsage,
    <>
      {TABLE_TITLE.financialTerms}
      <ArticleDetailTableDescriptionTooltip
        message={TABLE_DESCRIPTION.financialTerms}
      />
    </>,
  ];

  const otherTableItemsList = createEditArticleSingleTableShellList([
    {
      title: SHELL_TITLE[DEAL_TERMS_KEY.mergerFinancialTermsMa],
      text: SHELL_TEXT.mergerFinancialTermsText,
      labels: SHELL_LABELS.mergerFinancialTermsLabels,
      refKey: REF_CODE_DEAL_MERGER_FINANCIAL_TERMS_MA,
    },
    {
      title: SHELL_TITLE[DEAL_TERMS_KEY.otherFinancialTermsMa],
      text: SHELL_TEXT.otherFinancialTermsText,
      labels: SHELL_LABELS.otherFinancialTermsLabels,
      refKey: REF_CODE_OTHER_FINANCIAL_TERMS,
    },
  ]);

  return (
    <>
      <DealTermsTableContainer
        bottomTableHeaderItems={bottomTableHeaderItems}
        topTableItems={topTableItems}
        bottomTableItemsList={transformContingentTableLayout(
          bottomTableItemsList,
        )}
        otherTableItemsList={otherTableItemsList}
        isEditable
      />
    </>
  );
};

export default MergeAndAcquisitionTable;
