import React, { useState } from "react";

import { ENTRY_DATA_TO_CHANGE_GROUP_SHELL_ORDER } from "@FillInTableContainer/shared/constants/changeEntryDataOrder.constants";
import { GroupTableKeyType } from "@FillInTableContainer/shared/types/tableCreator.types";
import { ArticleSectionType } from "@FillInTableContainer/types/fillInTable.types";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export type DragInfoType = [number | null, number | null];

export const useHandleTableCreatorGroupShellDrag = (
  articleSection: ArticleSectionType,
  tableIndex: number,
) => {
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const [groupShellDraggedIndexInfo, setGroupShellDraggedIndexInfo] =
    useState<DragInfoType>([null, null]);

  const [isDraggingGroupShell, setIsDraggingGroupShell] =
    useState<boolean>(false);

  const handleGroupShellDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => {
    setGroupShellDraggedIndexInfo([index, null]);
  };

  const handleGroupShellDragOver = (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => {
    event.preventDefault();
    setGroupShellDraggedIndexInfo([groupShellDraggedIndexInfo[0], index]);
  };

  const handleGroupShellDragEnd = (groupTableKey: GroupTableKeyType) => {
    const activeGroupShellKeys = ["Licensee Table"];

    if (!activeGroupShellKeys.includes(groupTableKey)) return;

    setEntryData(
      ENTRY_DATA_TO_CHANGE_GROUP_SHELL_ORDER?.[articleSection]?.[groupTableKey](
        entryData,
        groupShellDraggedIndexInfo,
        tableIndex,
      ) || {},
    );
    setIsDraggingGroupShell(false);
  };

  const handleGroupShellDragOn = () => {
    setIsDraggingGroupShell(true);
  };
  return {
    handleGroupShellDragStart,
    handleGroupShellDragOver,
    handleGroupShellDragEnd,
    handleGroupShellDragOn,
    groupShellDraggedIndexInfo,
    isDraggingGroupShell,
  };
};
