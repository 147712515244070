"use client";

import styled from "styled-components";

export const ParagraphTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
  font-family: var(--font-pt-serif);
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  line-height: 160%;
  padding: 0.4rem 0.2rem;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 150%;
  }
`;
