import styled from "styled-components";

import { hideScrollbarY } from "@styles/mixin";

export const TitleContentsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  gap: 0.4rem;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.neural.gray00};
  border-radius: 0.4rem;
  border: 0.1rem solid ${({ theme }) => theme.color.neural.gray03};
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-4, 0.4rem)
    var(--Spacing-2, 0.2rem) var(--Spacing-8, 0.8rem);
`;

export const TitleShellText = styled.div`
  display: flex;
  flex: 1;
  max-width: 10.8rem;
  min-height: 1.2rem;
  height: fit-content;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.neural.gray08};
  line-height: 130%;
  background-color: transparent;
  outline: none;
  resize: none;
  cursor: text;

  ${hideScrollbarY}

  &:empty:before {
    content: attr(data-placeholder);
    color: ${({ theme }) => theme.color.neural.gray05};
  }
`;
