import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { AiImageStatus } from "@InsertImageContainer/shared/types/insertImage.types";

export type InsertImageWebSocketMessage = {
  aiImageStatus: AiImageStatus;
  createAt: number[];
  draftArticleId: number;
  email: string;
};

export const InsertImageWebSocketMessagesContext = createContext<{
  insertImageWebSocketMessages: InsertImageWebSocketMessage[];
  changeInsertImageWebSocketMessages: (
    newStatus: InsertImageWebSocketMessage,
  ) => void;
}>({
  insertImageWebSocketMessages: [],
  changeInsertImageWebSocketMessages: () => [],
});

interface Props {
  children: React.ReactNode;
}

export const InsertImageWebSocketMessagesProvider = ({ children }: Props) => {
  const [insertImageWebSocketMessages, setInsertImageWebSocketMessages] =
    useState<InsertImageWebSocketMessage[]>([]);

  const changeInsertImageWebSocketMessages = useCallback(
    (message: InsertImageWebSocketMessage): void => {
      setInsertImageWebSocketMessages([
        ...insertImageWebSocketMessages,
        message,
      ]);
    },
    [insertImageWebSocketMessages],
  );

  const insertImageWebSocketMessagesWrapper = useMemo(
    () => ({
      insertImageWebSocketMessages,
      changeInsertImageWebSocketMessages,
    }),
    [changeInsertImageWebSocketMessages, insertImageWebSocketMessages],
  );
  return (
    <InsertImageWebSocketMessagesContext.Provider
      value={insertImageWebSocketMessagesWrapper}
    >
      {children}
    </InsertImageWebSocketMessagesContext.Provider>
  );
};

export const useInsertImageWebSocketMessages = () => {
  const { insertImageWebSocketMessages, changeInsertImageWebSocketMessages } =
    useContext(InsertImageWebSocketMessagesContext);

  return { insertImageWebSocketMessages, changeInsertImageWebSocketMessages };
};
