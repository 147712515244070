import { useCallback, useEffect } from "react";

import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_INVESTORS_GROUP_TABLE_KEY as GROUP_TABLE_KEY,
  ENTRY_DATA_INVESTORS_SHELL_KEYS as SHELL_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export const useUpdateVentureCapitalInvestorEntryData = () => {
  const articleId = getPressIdFromURL();
  const articleSection = useArticleSection();
  const [entryData, setEntryData] = useEntryDataState(articleId);

  const updateInvestorsInEntryData = useCallback(
    (data: ArticleEntryData): ArticleEntryData => {
      if (articleSection !== SECTION_KEYS.ventureCapital) return data;
      return {
        ...data,
        [SECTION_KEYS.ventureCapital]: {
          ...data?.[SECTION_KEYS.ventureCapital],
          [GROUP_TABLE_KEY]: {
            ...data?.[SECTION_KEYS.ventureCapital]?.[GROUP_TABLE_KEY],
            [SHELL_KEYS.investorName]: {
              ...data?.[SECTION_KEYS.ventureCapital]?.[GROUP_TABLE_KEY]?.[
                SHELL_KEYS.investorName
              ],
              value:
                data?.[SECTION_KEYS.ventureCapital]?.[GROUP_TABLE_KEY]?.[
                  SHELL_KEYS.investorName
                ]?.value?.filter((value, index, self) => {
                  if (value.text === "") return true;
                  return index === self.findIndex((t) => t.text === value.text);
                }) || [],
              options:
                data?.[SECTION_KEYS.ventureCapital]?.[GROUP_TABLE_KEY]?.[
                  SHELL_KEYS.investorName
                ]?.options || [],
            },
          },
        },
      };
    },
    [articleSection],
  );
  const updateEntryData = useCallback(
    (data: ArticleEntryData) => {
      setEntryData(data);
    },
    [setEntryData],
  );

  useEffect(() => {
    const newEntryData = updateInvestorsInEntryData(entryData);
    if (JSON.stringify(newEntryData) !== JSON.stringify(entryData)) {
      updateEntryData(newEntryData);
    }
  }, [entryData, updateEntryData, updateInvestorsInEntryData]);
};
