import { useArticleInfoMutation } from "@api/articleInfo/useArticleInfo";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";

export const useUpdateArticleStatus = () => {
  const { mutateAsync: updateArticleInfo } = useArticleInfoMutation();

  return (articleStatus: keyof typeof DRAFT_STATUS_CODE) =>
    updateArticleInfo({
      draftArticleStatus: DRAFT_STATUS_CODE[articleStatus],
    });
};
