import React from "react";

import { RegulatoryAgencyIcon } from "@frontend/common/src/components";
import {
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
} from "@frontend/common/src/shared/components/core";

import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { useCreateCompanyLogoComponentList } from "@EditArticleContainer/shared/hooks/useCreateCompanyLogoComponentList";
import {
  REF_CODE_EXPEDITED_PROGRAM_ADDITIONAL_INFORMATION_ON_EXPEDITED_PROGRAM,
  REF_CODE_EXPEDITED_PROGRAM_DESIGNATED_COMPANY_NAME,
  REF_CODE_EXPEDITED_PROGRAM_DESIGNATED_INDICATION,
  REF_CODE_EXPEDITED_PROGRAM_EXPEDITED_PROGRAM,
  REF_CODE_EXPEDITED_PROGRAM_REGULATORY_AGENCY,
  REF_CODE_EXPEDITED_PROGRAM_UPDATE_DATE,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { ParagraphContent } from "@EditArticleContainer/shared/types/paragraphContents/paragraphContents.types";
import { formattedArticleDate } from "@EditArticleContainer/shared/utils/formattedArticleDate";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

import * as S from "./ExpeditedProgramTable.style";

interface Props {
  tableData: ParagraphContent["TC314"]["text"]["Expedited Program Contents"];
}

const COMMON_EDITABLE_TEXT_STYLE = {
  padding: "0.6rem",
  borderRadius: "0",
};
const VALUE_SHELL_PADDING = "0.2rem";

const ExpeditedProgramTable = ({ tableData }: Props) => {
  const { createCompanyLogoComponentList } =
    useCreateCompanyLogoComponentList();
  const expeditedProgramValueText = {
    "Update Date": tableData["Update Date"]?.value?.at(0)?.text || "",
    "Regulatory Agency":
      tableData["Regulatory Agency"]?.value?.at(0)?.text || "",
    "Designated Company name":
      tableData["Designated Company name"]?.value?.at(0)?.text || "",
    "Expedited Program": createMultiLineText({
      textList:
        tableData?.["Expedited Program"]?.value.map?.(
          (item) => item.text ?? "",
        ) ?? [],
      parseString: (text) => `- ${text?.trim()}`,
    }),
    "Designated Indication":
      tableData["Designated Indication"]?.value?.at(0)?.text || "",
    "Additional Information on Expedited Program": createMultiLineText({
      textList:
        tableData?.["Additional Information on Expedited Program"]?.value.map?.(
          (item) => item.text ?? "",
        ) ?? [],
      parseString: (text) => `- ${text?.trim()}`,
    }),
  };

  const expeditedProgramValueLabels = {
    "Update Date": tableData["Update Date"]?.value?.at(0)?.labels || [],
    "Regulatory Agency":
      tableData["Regulatory Agency"]?.value?.at(0)?.labels || [],
    "Designated Company name":
      tableData["Designated Company name"]?.value?.at(0)?.labels || [],
    "Expedited Program":
      tableData["Expedited Program"]?.value?.at(0)?.labels || [],
    "Designated Indication":
      tableData["Designated Indication"]?.value?.at(0)?.labels || [],
    "Additional Information on Expedited Program":
      tableData["Additional Information on Expedited Program"]?.value?.at(0)
        ?.labels || [],
  };
  return (
    <S.TablesWrapper>
      <TableContainer>
        <TableRowContainer>
          <TableShellTitle width="25rem">Update Date</TableShellTitle>
          <TableShellTitle width="25rem">Regulatory Agency</TableShellTitle>
          <TableShellTitle>Designated Company</TableShellTitle>
        </TableRowContainer>
        <TableRowContainer>
          <TableShellValue width="25rem" padding={VALUE_SHELL_PADDING}>
            <EditableTextContainer
              item={{
                text:
                  formattedArticleDate(
                    expeditedProgramValueText["Update Date"],
                  ) || "",
                labels: expeditedProgramValueLabels["Update Date"],
              }}
              refKey={REF_CODE_EXPEDITED_PROGRAM_UPDATE_DATE}
              canTextEdit
              style={COMMON_EDITABLE_TEXT_STYLE}
            />
          </TableShellValue>
          <TableShellValue width="25rem" padding={VALUE_SHELL_PADDING}>
            <EditableTextContainer
              item={{
                text: expeditedProgramValueText["Regulatory Agency"],
                labels: expeditedProgramValueLabels["Regulatory Agency"],
              }}
              refKey={REF_CODE_EXPEDITED_PROGRAM_REGULATORY_AGENCY}
              canTextEdit
              isDisabled
              imagePosition="left"
              imageNodeList={[
                <RegulatoryAgencyIcon
                  isTopMargin
                  agencyType={expeditedProgramValueText["Regulatory Agency"]}
                />,
              ]}
              style={COMMON_EDITABLE_TEXT_STYLE}
            />
          </TableShellValue>
          <TableShellValue padding={VALUE_SHELL_PADDING}>
            <EditableTextContainer
              item={{
                text: expeditedProgramValueText["Designated Company name"],
                labels: expeditedProgramValueLabels["Designated Company name"],
              }}
              refKey={REF_CODE_EXPEDITED_PROGRAM_DESIGNATED_COMPANY_NAME}
              canTextEdit
              isDisabled
              imagePosition="left"
              imageNodeList={createCompanyLogoComponentList([
                expeditedProgramValueText["Designated Company name"],
              ])}
              style={COMMON_EDITABLE_TEXT_STYLE}
            />
          </TableShellValue>
        </TableRowContainer>
        <TableRowContainer>
          <TableShellTitle>Expedited Program</TableShellTitle>
        </TableRowContainer>
        <TableRowContainer>
          <TableShellValue padding={VALUE_SHELL_PADDING}>
            <EditableTextContainer
              item={{
                text: expeditedProgramValueText["Expedited Program"],
                labels: expeditedProgramValueLabels["Expedited Program"],
              }}
              refKey={REF_CODE_EXPEDITED_PROGRAM_EXPEDITED_PROGRAM}
              canTextEdit
              style={COMMON_EDITABLE_TEXT_STYLE}
            />
          </TableShellValue>
        </TableRowContainer>

        <>
          <TableRowContainer
            isDisplay={!!expeditedProgramValueText["Designated Indication"]}
          >
            <TableShellTitle>Designated Indication</TableShellTitle>
          </TableRowContainer>
          <TableRowContainer
            isDisplay={!!expeditedProgramValueText["Designated Indication"]}
          >
            <TableShellValue padding={VALUE_SHELL_PADDING}>
              <EditableTextContainer
                item={{
                  text: expeditedProgramValueText["Designated Indication"],
                  labels: expeditedProgramValueLabels["Designated Indication"],
                }}
                refKey={REF_CODE_EXPEDITED_PROGRAM_DESIGNATED_INDICATION}
                canTextEdit
                style={COMMON_EDITABLE_TEXT_STYLE}
              />
            </TableShellValue>
          </TableRowContainer>
        </>
      </TableContainer>

      <TableContainer
        isDisplay={
          !!expeditedProgramValueText[
            "Additional Information on Expedited Program"
          ]
        }
      >
        <TableRowContainer>
          <TableShellTitle>
            Additional Information on the Expedited Program
          </TableShellTitle>
        </TableRowContainer>
        <TableRowContainer>
          <TableShellValue padding={VALUE_SHELL_PADDING}>
            <EditableTextContainer
              item={{
                text: expeditedProgramValueText[
                  "Additional Information on Expedited Program"
                ],
                labels:
                  expeditedProgramValueLabels[
                    "Additional Information on Expedited Program"
                  ],
              }}
              refKey={
                REF_CODE_EXPEDITED_PROGRAM_ADDITIONAL_INFORMATION_ON_EXPEDITED_PROGRAM
              }
              canTextEdit
              style={COMMON_EDITABLE_TEXT_STYLE}
            />
          </TableShellValue>
        </TableRowContainer>
      </TableContainer>
    </S.TablesWrapper>
  );
};

export default ExpeditedProgramTable;
