import React, { useEffect } from "react";

import { ClinicalTrialsSection } from "@frontend/common/src/types/entryData/clinicalTrials.types";

import {
  getShellColumnSize,
  isRequiredShell,
} from "@/components/business/FillInTableContainer/shared/utils/getOptionsFromData";
import RequiredMark from "@atoms/RequiredMark/RequiredMark";
import { useHandleClinicalTrialsEndpointsTableShell } from "@FillInTableContainer/components/FillInTable/components/TableCreator/components/ClinicalTrialsTableCreator/components/ClinicalTrialsTableCreatorGroupTable/components/FillInTableTableCreatorTable/components/ClinicalTrialsTableCreatorGroupShell/components/ClinicalTrialsTableCreatorShell/hooks/useHandleClinicalTrialsEndpointsTableShell";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import ColumnSelectButton from "@FillInTableContainer/shared/components/ColumnSelectButton/ColumnSelectButton";
import { useTableCreatorFocusStatus } from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorPositionInfo } from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { GroupTableKeyType } from "@FillInTableContainer/shared/types/tableCreator.types";
import { getShellTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";

import * as S from "./TableCreatorRowTitle.style";

interface Props {
  positionPropsInfo: {
    groupTableKey: GroupTableKeyType;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number;
    tableKey: string | null;
    tableIndex: number;
    shellKey: string;
    shellIndex: number;
  };
}

const TableCreatorRowTitle = ({ positionPropsInfo }: Props) => {
  const articleSection = useArticleSection();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const {
    shellKey,
    groupTableKey,
    groupTableIndex,
    tableIndex,
    groupShellKey,
    shellIndex,
  } = positionPropsInfo;

  const {
    handleClickToDeleteEndpointsTableColumn,
    handleKeyDownToDeleteEndpointsTableColumn,
  } = useHandleClinicalTrialsEndpointsTableShell({
    ...positionPropsInfo,
    groupTableKey: groupShellKey as ClinicalTrialsSection,
    shellCount: 1,
  });

  const isRegulatoryTable = groupTableKey === "Approval Process Contents";

  const isFocusedColumn =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === groupShellKey &&
    info.shellKey === shellKey &&
    info.shellIndex === shellIndex &&
    info.valueIndex === null;

  const handleFocusColumn = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    changeInfo({
      ...positionPropsInfo,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      valueIndex: null,
    });
    changeFocusStatus({ type: "clear", container: "column" });
  };

  useEffect(() => {
    if (focusStatus.container !== "column") return;
    window.addEventListener(
      "keydown",
      handleKeyDownToDeleteEndpointsTableColumn,
    );

    return () => {
      window.removeEventListener(
        "keydown",
        handleKeyDownToDeleteEndpointsTableColumn,
      );
    };
  }, [focusStatus, handleKeyDownToDeleteEndpointsTableColumn]);

  if (isRegulatoryTable) {
    return (
      <S.RowTitleWrapper column={getShellColumnSize(articleSection, shellKey)}>
        {isRequiredShell(articleSection, shellKey) && <RequiredMark text="*" />}{" "}
        {getShellTitle(articleSection, shellKey)}
      </S.RowTitleWrapper>
    );
  }

  return (
    <S.RowTitleWrapper column={getShellColumnSize(articleSection, shellKey)}>
      {isRequiredShell(articleSection, shellKey) && <RequiredMark text="*" />}{" "}
      {getShellTitle(articleSection, shellKey)}
      <ColumnSelectButton
        top="-1.6rem"
        left="0"
        clickEvent={handleFocusColumn}
      />
      {isFocusedColumn && focusStatus.container === "column" && (
        <S.ColumnDeleteButton onClick={handleClickToDeleteEndpointsTableColumn}>
          <img src={IconXMark} alt="" />
          {focusStatus.type === "clear" && "Clear"}
          {focusStatus.type === "delete" && "Delete"}
          &nbsp;{focusStatus.container === "column" && "Column"}
        </S.ColumnDeleteButton>
      )}
    </S.RowTitleWrapper>
  );
};

export default TableCreatorRowTitle;
