import React from "react";

import * as S from "./ParagraphSectionLayout.style";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const ParagraphSectionLayout = ({ children, ...rest }: Props) => {
  return (
    <S.ParagraphSectionWrapper {...rest}>{children}</S.ParagraphSectionWrapper>
  );
};

export default ParagraphSectionLayout;
