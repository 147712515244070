"use client";

import styled from "styled-components";

export const DBDetailRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  min-height: 4rem;
  background-color: var(--Color-Common-Border-Tertiary, #e5e7eb);
  border-bottom: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);

  & > div:last-child {
    border-right: none;
  }
`;
