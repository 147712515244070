import React, { useEffect, useRef } from "react";

import { ClinicalTrialStatusSection } from "@/types/clinicalTrialStatus.types";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import {
  TableDividerPlusBar,
  TableCreatorTableTitle,
} from "@FillInTableContainer/shared";
import {
  GroupTableKeyType,
  TableCreatorGroupShellList,
} from "@FillInTableContainer/shared/types/tableCreator.types";
import {
  isAddDeleteTable,
  isGroupShellScroll,
  isTableScroll,
} from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import * as S from "./ClinicalTrialStatusTableCreatorTable.style";
import ClinicalTrialStatusTableCreatorGroupShell from "./components/ClinicalTrialStatusTableCreatorGroupShell/ClinicalTrialStatusTableCreatorGroupShell";
import { useHandleClinicalTrialStatusTableCreatorTable } from "./hooks/useHandleClinicalTrialStatusTableCreatorTable";

export interface TableInfoProps {
  groupTableKey: ClinicalTrialStatusSection;
  tableKey: string | null;
  groupShellList: TableCreatorGroupShellList;
  groupTableIndex: number;
  tableIndex: number;
  tableCount: number;
}
interface Props extends TableInfoProps {
  handleAddTable: (tableIndex: number) => void;
  handleDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => void;
  handleDragEnd: (groupTableKey: GroupTableKeyType) => void;
  handleDragOn: () => void;
  isDraggableTable: boolean;
}

const ClinicalTrialStatusTableCreatorTable = ({
  groupTableKey,
  tableKey,
  groupTableIndex,
  tableIndex,
  tableCount,
  groupShellList,
  handleAddTable,
  handleDragEnd,
  handleDragOn,
  handleDragStart,
  isDraggableTable,
}: Props) => {
  const articleSection = useArticleSection();
  const tablePropsInfo: TableInfoProps = {
    groupTableKey,
    tableKey,
    groupTableIndex,
    tableIndex,
    tableCount,
    groupShellList,
  };

  const tableTitleRef = useRef<HTMLDivElement>(null);

  const {
    focusStatus,
    isFocused,
    isLastTable,
    tableWrapperRef,
    isFocusedAddButton,
    handleClickDeleteButton,
    focusEventToChangePositionInfo,
    numberOfGroupShellCount,
    changeFocusStatus,
    changePositionInfo,
    changePositionInfoToDefault,
    handleDefaultFocus,
  } = useHandleClinicalTrialStatusTableCreatorTable(tablePropsInfo);

  const isClearTable =
    isFocused &&
    focusStatus.type === "clear" &&
    focusStatus.container === "table";

  const isDeleteTable =
    isFocused &&
    focusStatus.type === "delete" &&
    focusStatus.container === "table";

  const isTableScrollWrapper = isTableScroll(articleSection, tableKey);

  const handleFocusTableTitle = () => {
    focusEventToChangePositionInfo({
      type: "clear",
      container: "table",
    });
  };

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;

        const clickSelectButton = tableTitleRef.current?.contains(target);

        if (clickSelectButton) return;
        if (target.dataset.button) return;

        handleDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleDefaultFocus, isFocused]);

  return (
    <>
      {isAddDeleteTable(articleSection, tableKey) && (
        <TableDividerPlusBar
          tableTitle={getTableTitle(articleSection, tableKey) || ""}
          isLastPosition={false}
          onClick={() => handleAddTable(tableIndex)}
          isVisibility={isFocusedAddButton}
        />
      )}
      <S.TableWrapper
        className="table-wrapper"
        isDeleteTable={isDeleteTable}
        isFocused={isFocused}
        isTableScroll={isTableScrollWrapper}
        isClearTable={isClearTable}
        isDraggingTable={isFocused && focusStatus.type === "dragging"}
      >
        {(isClearTable || isDeleteTable) && (
          <S.DeleteTableButtonWrapper>
            <S.DeleteTableButton
              data-button="delete-button"
              onClick={handleClickDeleteButton}
            >
              <img src={IconXMark} alt="button for clear table" />
              {focusStatus.type === "clear" ? `Clear` : null}
              {focusStatus.type === "delete" ? `Delete` : null}
            </S.DeleteTableButton>
          </S.DeleteTableButtonWrapper>
        )}
        <S.ScrollWrapper
          isTableScroll={isTableScrollWrapper}
          ref={tableWrapperRef}
        >
          <S.ScrollContentsWrapper>
            <div ref={tableTitleRef}>
              {getTableTitle(articleSection, tableKey) && (
                <TableCreatorTableTitle
                  tableKey={tableKey}
                  groupTableKey={groupTableKey}
                  groupTableIndex={groupTableIndex}
                  tableIndex={tableIndex}
                  isDragging={isFocused && focusStatus.type === "dragging"}
                  onClickHandler={handleFocusTableTitle}
                  handleDragStart={handleDragStart}
                  handleDragEnd={handleDragEnd}
                  handleDragOn={handleDragOn}
                  isDraggableTable={isDraggableTable}
                  changeFocusStatusForDragging={() => {
                    changePositionInfo();
                    changeFocusStatus({
                      type: "dragging",
                      container: "table",
                    });
                  }}
                  changeFocusStatusForDragEnd={() => {
                    changePositionInfoToDefault();
                    changeFocusStatus({
                      type: "default",
                      container: "noFocus",
                    });
                  }}
                />
              )}
            </div>
            {groupShellList.map(
              ({ groupShellKey, shellList }, groupShellListIndex) => (
                <S.GroupShellWrapper
                  key={`groupShellList-${String(groupShellListIndex)}`}
                >
                  {newArrayToCountValue(
                    numberOfGroupShellCount(groupShellKey),
                  ).map((_, groupShellIndex) => (
                    <>
                      <ClinicalTrialStatusTableCreatorGroupShell
                        key={`group-shell-${String(groupTableIndex)}-${String(
                          tableIndex,
                        )}-${String(groupShellIndex)}`}
                        shellList={shellList}
                        groupTableKey={groupTableKey}
                        groupTableIndex={groupTableIndex}
                        tableKey={tableKey}
                        tableIndex={tableIndex}
                        groupShellKey={groupShellKey}
                        groupShellIndex={groupShellIndex}
                        groupShellCount={numberOfGroupShellCount(groupShellKey)}
                        isTableScroll={
                          isTableScroll(articleSection, tableKey) ||
                          isGroupShellScroll(articleSection, groupShellKey)
                        }
                      />
                    </>
                  ))}
                </S.GroupShellWrapper>
              ),
            )}
          </S.ScrollContentsWrapper>
        </S.ScrollWrapper>
      </S.TableWrapper>
      {isAddDeleteTable(articleSection, tableKey) && (
        <TableDividerPlusBar
          tableTitle={getTableTitle(articleSection, tableKey) || ""}
          onClick={() => handleAddTable(tableIndex + 1)}
          isLastPosition
          isVisibility={isLastTable && isFocusedAddButton}
        />
      )}
    </>
  );
};

export default ClinicalTrialStatusTableCreatorTable;
