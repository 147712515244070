import React, { useEffect, useRef } from "react";

import { TargetListType } from "@FillInTableContainer/shared/hooks/useHandleTargetListIndex";

interface Props {
  targetListIndex: TargetListType;
}

interface UseHandleTableCreatorListBoxScrollReturn {
  targetItemRef: React.RefObject<HTMLLIElement>;
}

export const useHandleTableCreatorListBoxScroll = ({
  targetListIndex,
}: Props): UseHandleTableCreatorListBoxScrollReturn => {
  const targetItemRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (!targetItemRef.current) return;
    targetItemRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  }, [
    targetListIndex.mainIndex,
    targetListIndex.categoryIndex,
    targetListIndex.listIndex,
  ]);

  return {
    targetItemRef,
  };
};
