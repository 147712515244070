import React, { Component, ReactNode } from "react";

import { AxiosError } from "axios";

import { clearAuthorization } from "@libs/authorization/utils/clearAuthorization";

import ExpiredTokenModal from "./components/ExpiredTokenModal/ExpiredTokenModal";
import InternalServerError from "./components/InternalServerError";
import TimeoutError from "./components/TimeoutError";
import UnknownNetworkError from "./components/UnknownNetworkError";

interface Props {
  children?: ReactNode;
}

interface State {
  shouldHandleError: boolean;
  shouldRethrow: boolean;
  error: Error | null;
}

class ApiErrorBoundary extends Component<Props, State> {
  private static forceLogout = () => {
    clearAuthorization();
    window.location.reload();
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      shouldHandleError: false,
      shouldRethrow: false,
      error: null,
    };
  }

  public static getDerivedStateFromError(error: Error): State {
    if (error instanceof AxiosError) {
      return {
        shouldHandleError: true,
        shouldRethrow: false,
        error,
      };
    }
    return {
      shouldHandleError: false,
      shouldRethrow: true,
      error,
    };
  }

  public render() {
    const { error, shouldHandleError, shouldRethrow } = this.state;
    const { children } = this.props;

    if (shouldRethrow) {
      throw error;
    }
    if (!shouldHandleError) {
      return children;
    }

    if (error instanceof AxiosError) {
      if (error.response?.status === 500) {
        return (
          <InternalServerError
            errorStatus={error.response?.status}
            handleButtonClick={() =>
              this.setState({ shouldHandleError: false })
            }
          />
        );
      }
      if (error.response?.status === 504) {
        return (
          <TimeoutError
            errorStatus={error.response?.status}
            handleButtonClick={() =>
              this.setState({ shouldHandleError: false })
            }
          />
        );
      }
      if (error.response?.status === 401) {
        return <ExpiredTokenModal closeModal={ApiErrorBoundary.forceLogout} />;
      }
      return (
        <UnknownNetworkError
          handleButtonClick={() => this.setState({ shouldHandleError: false })}
          errorStatus={error.response?.status}
        />
      );
    }
    return children;
  }
}

export default ApiErrorBoundary;
