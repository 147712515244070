import React from "react";

import {
  GROUP_SHELL_PATH_TO_ADD_GROUP_SHELL,
  BASIC_DATA_FOR_ADDING_GROUP_SHELLS,
} from "@FillInTableContainer/shared/constants/tableCreatorGroupShell.constants";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useUpdateEntryData } from "@hooks/useUpdateArticleData";
import { useEntryDataState } from "@stores/entryData";
import { useInitEntryDataValue } from "@stores/initEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import type { ArticleEntryData } from "@/types/articleEntryData.types";
import type {
  ClinicalTrialsSection,
  EndpointsValue,
} from "@/types/clinicalTrials.types";

interface UseHandleGroupShellReturn {
  clickAddEndpointsTableGroupShellButton: (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
    },
  ) => void;
  handleClickToDeleteEndpointsTableGroupShell: () => void;
  handleKeyDownToDeleteEndpointsTableGroupShell: (event: KeyboardEvent) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: ClinicalTrialsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useHandleClinicalTrialsEndpointsTableGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const articleSection = "Clinical Trials";
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const initEntryData = useInitEntryDataValue(getPressIdFromURL());

  const updateEntryData = useUpdateEntryData();
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { changeValidatedInfo } = useValidatedInTableCreator();

  const handleAddGroupShell = (groupShellInfo: {
    tableIndexParameter: number;
    groupShellIndexParameter: number;
    groupShellKeyParameter: string | null;
  }) => {
    const {
      tableIndexParameter,
      groupShellIndexParameter,
      groupShellKeyParameter,
    } = groupShellInfo;

    if (groupShellKeyParameter === null) return;

    const targetShell = GROUP_SHELL_PATH_TO_ADD_GROUP_SHELL[
      groupShellKeyParameter
    ](entryData, tableIndexParameter, groupShellIndexParameter);

    if (targetShell === undefined) return;

    const newArrayLength = targetShell.length + 1 || 1;
    const emptyArray = new Array(newArrayLength).fill({});

    const addEndpointsGroupShellData = emptyArray.map((_, index) => {
      const endpointsGroupShellData = BASIC_DATA_FOR_ADDING_GROUP_SHELLS[
        articleSection
      ][groupShellKeyParameter](initEntryData) as EndpointsValue;
      if (groupShellKeyParameter !== "Endpoints Table Information") return;
      const endpointsGroupShellsLength = (
        targetShell as EndpointsValue[]
      )?.[0]?.["Endpoint Group Information"]?.length;

      if (index === groupShellIndexParameter) {
        /**
         * Description :
         * Endpoint Table의 경우 Group Shell을 추가할 때,
         * Group Name을 입력된 값으로 설정 필요.
         */
        const endpointsGroupShellDataFormat = {
          ...endpointsGroupShellData,
          "Endpoint Group Information": new Array(
            endpointsGroupShellsLength,
          ).fill(endpointsGroupShellData["Endpoint Group Information"]?.[0]),
        };

        const getTargetGroupNameText = (targetIndex: number) =>
          entryData?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[
            tableIndexParameter
          ]?.["Endpoints Table Information"]
            ?.at(0)
            ?.["Endpoint Group Information"]?.[targetIndex]?.[
              "Group Name"
            ]?.value?.at(0)?.text;

        const targetEndpointsGroupShell = {
          ...endpointsGroupShellDataFormat,
          "Endpoint Group Information": endpointsGroupShellDataFormat?.[
            "Endpoint Group Information"
          ].map((endpointInformation, endpointInformationIndex) => {
            return {
              ...endpointInformation,
              "Group Name": {
                ...endpointInformation?.["Group Name"],
                value: [
                  {
                    ...endpointInformation?.["Group Name"].value,
                    text: getTargetGroupNameText(endpointInformationIndex),
                  },
                ],
              },
            };
          }),
        };
        return { ...targetEndpointsGroupShell };
      }
      if (index > groupShellIndexParameter) {
        return targetShell[index - 1];
      }
      if (index < groupShellIndexParameter) {
        return targetShell[index];
      }
      return targetShell[index];
    });

    const changeGroupShellData: Record<string, ArticleEntryData> = {
      "Endpoints Table Information": {
        "Clinical Trials": {
          ...entryData["Clinical Trials"],
          "Clinical Endpoints (Table)": entryData["Clinical Trials"]?.[
            "Clinical Endpoints (Table)"
          ]?.map((tableData, tableDataIndex) => {
            if (tableDataIndex === tableIndexParameter) {
              return {
                ...tableData,
                "Endpoints Table Information": addEndpointsGroupShellData,
              };
            }
            return tableData;
          }),
        },
      } as ArticleEntryData,
    };

    setEntryData(changeGroupShellData[groupShellKeyParameter]);
    updateEntryData(changeGroupShellData[groupShellKeyParameter]);
    changeCurrentHistory({
      entryData: changeGroupShellData[groupShellKeyParameter],
      changedDataInfo: {
        target: "groupShell",
        action: "add",
        position: {
          groupShellKey,
          groupTableIndex,
          tableKey,
          tableIndex,
          groupTableKey,
          groupShellIndex: groupShellIndexParameter,
          shellKey: null,
          shellIndex: null,
          valueIndex: null,
          value: null,
        },
      },
    });

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  };

  const clickAddEndpointsTableGroupShellButton = (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
    },
  ) => {
    event.stopPropagation();
    handleAddGroupShell(groupShellInfo);
  };

  const handleClearGroupShell = () => {
    const clearEndpointsTableGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;
      const endpointGroupInformationLength =
        entryData?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[
          info.tableIndex
        ]?.["Endpoints Table Information"]?.[info.groupShellIndex]?.[
          "Endpoint Group Information"
        ]?.length || 0;
      const newEmptyArray = newArrayToCountValue(
        endpointGroupInformationLength,
      );
      return {
        ...entryData,
        "Clinical Trials": {
          ...entryData["Clinical Trials"],
          "Clinical Endpoints (Table)": entryData["Clinical Trials"]?.[
            "Clinical Endpoints (Table)"
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                "Endpoints Table Information": tableInfo[
                  "Endpoints Table Information"
                ]?.map((groupShellInfo, groupShellInfoIndex) => {
                  if (groupShellInfoIndex === info.groupShellIndex) {
                    return {
                      "Endpoint Classification": {
                        ...groupShellInfo["Endpoint Classification"],
                        value: [],
                      },
                      "Endpoint Name including Measurement Timing": {
                        ...groupShellInfo[
                          "Endpoint Name including Measurement Timing"
                        ],
                        value: [],
                      },
                      "Endpoint Group Information": newEmptyArray.map(
                        (_, index) => ({
                          "Group Name":
                            groupShellInfo["Endpoint Group Information"]?.[
                              index
                            ]["Group Name"],
                          "Group Data": {
                            ...groupShellInfo["Endpoint Group Information"]?.[
                              index
                            ]["Group Data"],
                            value: [],
                          },
                          "P-Value": {
                            ...groupShellInfo["Endpoint Group Information"]?.[
                              index
                            ]["P-Value"],
                            value: [],
                          },
                        }),
                      ),
                    };
                  }
                  return groupShellInfo;
                }),
              };
            }
            return tableInfo;
          }),
        },
      };
    };

    if (info.groupTableKey === "Clinical Endpoints (Table)") {
      const endpointRowLength =
        entryData?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[
          info.tableIndex
        ]?.["Endpoints Table Information"]?.length || 0;
      setEntryData(clearEndpointsTableGroupShell());
      changeCurrentHistory({
        entryData: clearEndpointsTableGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      if (endpointRowLength > 1)
        changeFocusStatus({
          type: "delete",
          container: "groupShell",
        });
      else {
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
        changeInfo(DEFAULT_POSITION_INFO);
      }
    }
  };

  const handleDeleteGroupShell = () => {
    if (info.groupTableKey === "Clinical Endpoints (Table)") {
      const deleteEndpointsTableGroupShell = (): ArticleEntryData => {
        return {
          ...entryData,
          "Clinical Trials": {
            ...entryData["Clinical Trials"],
            "Clinical Endpoints (Table)": entryData["Clinical Trials"]?.[
              "Clinical Endpoints (Table)"
            ]?.map((tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  "Endpoints Table Information": tableInfo[
                    "Endpoints Table Information"
                  ]?.filter(
                    (_, groupShellInfoIndex) =>
                      groupShellInfoIndex !== info.groupShellIndex,
                  ),
                };
              }
              return tableInfo;
            }),
          },
        };
      };
      setEntryData(deleteEndpointsTableGroupShell());
      changeCurrentHistory({
        entryData: deleteEndpointsTableGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "delete",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
    }
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  };

  const handleClickToDeleteEndpointsTableGroupShell = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShell();
    }
    if (focusStatus.type === "delete") {
      handleDeleteGroupShell();
    }
  };

  const handleKeyDownToDeleteEndpointsTableGroupShell = (
    event: KeyboardEvent,
  ) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearGroupShell();
    }
    if (focusStatus.type === "delete" && event.key === "Delete") {
      handleDeleteGroupShell();
    }
  };

  return {
    clickAddEndpointsTableGroupShellButton,
    handleClickToDeleteEndpointsTableGroupShell,
    handleKeyDownToDeleteEndpointsTableGroupShell,
  };
};
