import ComponentErrorBoundary from "@/components/shared/business/ComponentErrorBoundary/ComponentErrorBoundary";
import { ClinicalTrialsSection } from "@/types/clinicalTrials.types";
import { TableCreatorGroupTableTitle } from "@FillInTableContainer/shared";
import TableCreatorNavigationTableWrapper from "@FillInTableContainer/shared/components/TableCreatorNavigationTableWrapper/TableCreatorNavigationTableWrapper";
import {
  TableCreatorSubGroupShellTableList,
  TableCreatorTableList,
} from "@FillInTableContainer/shared/types/tableCreator.types";
import { getGroupTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";

import ClinicalTrialDesignGroupTable from "./components/ClinicalTrialDesignGroupTable/ClinicalTrialDesignGroupTable";
import ClinicalTrialsTableCreatorGroupTable from "./components/ClinicalTrialsTableCreatorGroupTable/ClinicalTrialsTableCreatorGroupTable";
import { CLINICAL_TRIALS_TABLE_LAYOUT } from "./constants/clinicalTrialsTableLayout.constants";

const ClinicalTrialsTableCreator = () => {
  const clinicalTrialsLayout = CLINICAL_TRIALS_TABLE_LAYOUT["Clinical Trials"];

  const errorWithComponent = (title: string) => (
    <TableCreatorGroupTableTitle title={title} isRequired={false} />
  );

  return (
    <TableCreatorNavigationTableWrapper classificationKey="Clinical Trials">
      <>
        {clinicalTrialsLayout.map(
          ({ groupTableKey, tableList }, groupTableIndex) => {
            if (groupTableKey === "Clinical Trial Design")
              return (
                <ComponentErrorBoundary
                  addComponents={errorWithComponent(
                    getGroupTableTitle("Clinical Trials", groupTableKey),
                  )}
                >
                  <ClinicalTrialDesignGroupTable
                    key={`group-table-${String(groupTableIndex)}`}
                    groupTableKey={groupTableKey}
                    tableList={tableList as TableCreatorSubGroupShellTableList}
                    groupTableIndex={groupTableIndex}
                  />
                </ComponentErrorBoundary>
              );

            return (
              <ComponentErrorBoundary
                addComponents={errorWithComponent(
                  getGroupTableTitle("Clinical Trials", groupTableKey),
                )}
              >
                <ClinicalTrialsTableCreatorGroupTable
                  key={`group-table-${String(groupTableIndex)}`}
                  groupTableKey={groupTableKey as ClinicalTrialsSection}
                  tableList={tableList as TableCreatorTableList}
                  groupTableIndex={groupTableIndex}
                />
              </ComponentErrorBoundary>
            );
          },
        )}
      </>
    </TableCreatorNavigationTableWrapper>
  );
};

export default ClinicalTrialsTableCreator;
