import React from "react";

import * as S from "./ArticleDetailTitleInShell.style";

type Props = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const ArticleDetailTitleInShell = ({ children, ...rest }: Props) => {
  return <S.TitleInShell {...rest}>{children}</S.TitleInShell>;
};

export default ArticleDetailTitleInShell;
