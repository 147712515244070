import styled, { css } from "styled-components";

export const ShellDividerWrapper = styled.div<{ isPositionRight: boolean }>`
  position: absolute;
  left: -0.8rem;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.8rem;
  height: 100%;
  cursor: pointer;

  &:hover * {
    opacity: 1;
  }

  ${({ isPositionRight }) =>
    isPositionRight &&
    css`
      left: 100%;
    `}
`;

export const ShellDividerPlusBar = styled.div`
  width: 0.6rem;
  height: 100%;
  border-radius: 0.2rem;
  background-color: rgba(1, 157, 246, 0.12);
  opacity: 0;
`;

export const PlusButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  height: 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.neural.white};
  padding: 0.4rem;
  border-radius: 0.2rem;
  outline: 0.4rem solid rgba(1, 157, 246, 0.12);
  background-color: rgba(1, 157, 246, 0.7);
  white-space: nowrap;
  cursor: pointer;
  opacity: 0;

  z-index: 10;
`;
