import React, { useEffect, useState } from "react";

import { useImageFilterKeyword } from "@/components/business/InsertImageContainer/shared/hooks/useImageFilterKeyword";
import {
  InsertImageFilterKeys,
  SelectedImageLabelList,
} from "@/components/business/InsertImageContainer/shared/types/filterKeyword.types";
import { ImageType } from "@/components/business/InsertImageContainer/shared/types/insertImage.types";

import * as S from "./SelectImageLabel.style";

interface Props {
  previewImage: ImageType;
  selectedLabelList: SelectedImageLabelList;
  handleImageLabel: (
    event: React.ChangeEvent<HTMLInputElement>,
    labelName: InsertImageFilterKeys,
  ) => void;
  changeSelectedLabelList: (labelName: SelectedImageLabelList) => void;
}

const SelectImageLabel = ({
  previewImage,
  selectedLabelList,
  handleImageLabel,
  changeSelectedLabelList,
}: Props) => {
  const { keywordLabelNameList, keywordInfoListForUploadLabel } =
    useImageFilterKeyword();

  const keywordList = keywordLabelNameList?.map((keyword) => {
    return {
      labelName: keyword,
      value: keywordInfoListForUploadLabel
        .filter((item) => item.labelName === keyword)
        .map((item) => item.value),
    };
  });

  const initialKeywordListWithInputValue = keywordList?.map((keyword) => {
    return {
      ...keyword,
      value: [...keyword.value, null],
    };
  });

  const [keywordListWithInputValue, setKeywordListWithInputValue] = useState(
    initialKeywordListWithInputValue,
  );

  const handleBlurImageKeywordInput = (
    event: React.FocusEvent<HTMLSpanElement>,
    labelName: InsertImageFilterKeys,
  ) => {
    const target = event.target as HTMLSpanElement;

    if (!target.innerText.trim()) {
      target.innerText = "";
      return;
    }

    const updatedKeywordListWithInputValue = keywordListWithInputValue.map(
      (keyword) => {
        if (keyword.labelName === labelName) {
          return {
            ...keyword,
            value: [
              ...new Set([
                ...keyword.value.filter((item) => item !== null),
                `INPUT__${target.innerText.trim()}`,
                null,
              ]),
            ],
          };
        }
        return keyword;
      },
    );

    const updatedSelectedLabelList: SelectedImageLabelList = [
      ...selectedLabelList,
      {
        directoryName: labelName,
        keyword: target.innerText.trim(),
      },
    ];

    setKeywordListWithInputValue([...updatedKeywordListWithInputValue]);
    changeSelectedLabelList(updatedSelectedLabelList);

    target.innerText = "";
  };

  const handleEnterImageKeywordInput = (
    event: React.KeyboardEvent<HTMLSpanElement>,
  ) => {
    const target = event.target as HTMLSpanElement;
    if (!target.innerText.trim()) return;
    if (event.key === "Enter") {
      target.blur();
    }
  };

  const handleDeleteKeyword = (
    keyword: string,
    labelName: InsertImageFilterKeys,
  ) => {
    const updatedKeywordListWithInputValue = keywordListWithInputValue.map(
      (keywordItem) => {
        if (keywordItem.labelName === labelName) {
          return {
            ...keywordItem,
            value: keywordItem.value.filter((item) => item !== keyword),
          };
        }
        return keywordItem;
      },
    );

    const updatedSelectedLabelList: SelectedImageLabelList =
      selectedLabelList.filter(
        (item) => item.keyword !== keyword.replace("INPUT__", ""),
      );

    setKeywordListWithInputValue([...updatedKeywordListWithInputValue]);
    changeSelectedLabelList(updatedSelectedLabelList);
  };

  useEffect(() => {
    setKeywordListWithInputValue(initialKeywordListWithInputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewImage]);

  return (
    <S.SelectImageLabelSection isDisabled={!previewImage}>
      <S.ImageLabelList>
        {keywordListWithInputValue.map((label) => {
          return (
            <S.KeywordWrapper>
              <S.KeywordLabelName>{label.labelName}</S.KeywordLabelName>
              <S.KeywordValueList>
                {label.value.map((keyword) => {
                  if (keyword === "") return null;
                  if (keyword === null) {
                    return (
                      <S.KeywordTextLabel>
                        <S.KeywordTextInput
                          key={`imageLabel-${label.labelName}-${keyword}`}
                          data-placeholder="Click to Enter Manually..."
                          contentEditable={!!previewImage}
                          onBlur={(event) =>
                            handleBlurImageKeywordInput(
                              event,
                              label.labelName as InsertImageFilterKeys,
                            )
                          }
                          onKeyDown={(event) =>
                            handleEnterImageKeywordInput(event)
                          }
                        />
                        <button type="button" className="add-keyword" />
                      </S.KeywordTextLabel>
                    );
                  }
                  return (
                    <S.ImageLabel
                      key={`imageLabel-${label.labelName}-${keyword}`}
                      htmlFor={`imageLabel-${label.labelName}-${keyword}`}
                      isSelected={
                        selectedLabelList.filter(
                          (item) =>
                            item.keyword === keyword.replace("INPUT__", ""),
                        ).length > 0
                      }
                      isDisabled={!previewImage}
                      isCloseButton={keyword.includes("INPUT__")}
                    >
                      {keyword.includes("INPUT__") && (
                        <S.DeleteButton
                          type="button"
                          onClick={() =>
                            handleDeleteKeyword(
                              keyword,
                              label.labelName as InsertImageFilterKeys,
                            )
                          }
                        />
                      )}
                      <input
                        type="checkbox"
                        id={`imageLabel-${label.labelName}-${keyword}`}
                        value={keyword}
                        name="imageLabel"
                        onChange={(event) =>
                          handleImageLabel(
                            event,
                            label.labelName as InsertImageFilterKeys,
                          )
                        }
                        disabled={!previewImage}
                        checked={
                          selectedLabelList.filter(
                            (item) =>
                              item.keyword === keyword.replace("INPUT__", ""),
                          ).length > 0
                        }
                      />
                      {keyword.replace("INPUT__", "")}
                    </S.ImageLabel>
                  );
                })}
              </S.KeywordValueList>
            </S.KeywordWrapper>
          );
        })}
      </S.ImageLabelList>
    </S.SelectImageLabelSection>
  );
};

export default SelectImageLabel;
