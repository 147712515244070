import { useRef, useEffect } from "react";

import { DealsSection } from "@/types/deals.types";
import { TableCreatorShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";

import DealsDealTypeShell from "./components/DealsDealTypeShell/DealsDealTypeShell";
import * as S from "./DealsDealTypeGroupShell.style";
import { useHandleDealTypeTableCreatorGroupShell } from "./hooks/useHandleDealTypeTableCreatorGroupShell";

interface Props {
  groupTableKey: DealsSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  shellList: TableCreatorShellList;
  tableKey: string | null;
  tableIndex: number;
  groupShellIndex: number;
}

const DealsDealTypeGroupShell = ({
  groupShellIndex,
  groupTableIndex,
  groupTableKey,
  groupShellKey,
  shellList,
  tableIndex,
  tableKey,
}: Props) => {
  const selectButtonRef = useRef<HTMLDivElement>(null);
  const positionPropsInfo = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  };

  const {
    focusStatus,
    isFocused,
    isClearGroupShell,
    isAlertGroupShell,
    isEnableEdit,
    handleDefaultFocus,
    handleClickToDeleteGroupShell,
    handleFocusGroupShell,
  } = useHandleDealTypeTableCreatorGroupShell({ positionPropsInfo });

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;
        const clickSelectButton = selectButtonRef.current?.contains(target);

        if (clickSelectButton) return;

        handleDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleDefaultFocus, isClearGroupShell, isFocused]);

  return (
    <S.GroupShellOutsideWrapper>
      {isClearGroupShell && isEnableEdit && (
        <S.FixedDeleteButtonWrapper>
          <S.DeleteTableButtonWrapper>
            <S.DeleteTableButton onClick={handleClickToDeleteGroupShell}>
              <img src={IconXMark} alt="button for clear Row" />
              {focusStatus.type === "clear" ? `Clear` : null}
            </S.DeleteTableButton>
          </S.DeleteTableButtonWrapper>
        </S.FixedDeleteButtonWrapper>
      )}

      <S.GroupShellWrapper
        isFocused={isFocused}
        isClearGroupShell={isClearGroupShell}
        isAlertGroupShell={isAlertGroupShell}
      >
        <S.SelectWrapper>
          <S.GroupShellSelectButtonDividerWrapper
            ref={selectButtonRef}
            onClick={handleFocusGroupShell}
            isPossibleSelected
          >
            <S.GroupShellSelectButtonDivider className="select-button divider" />
            <S.GroupShellSelectButton className="select-button button">
              SELECT
            </S.GroupShellSelectButton>
          </S.GroupShellSelectButtonDividerWrapper>
          <S.GroupShell>
            {shellList.map(({ shellKey }, shellListIndex) => (
              <DealsDealTypeShell
                key={`shellList-${String(shellListIndex)}`}
                groupTableKey={groupTableKey}
                groupTableIndex={groupTableIndex}
                groupShellKey={groupShellKey}
                groupShellIndex={groupShellIndex}
                shellKey={shellKey}
                shellIndex={0}
                tableKey={tableKey}
                tableIndex={tableIndex}
                isFocusedGroupShell={isFocused}
                isClearGroupShell={isClearGroupShell}
                isAlertGroupShell={isAlertGroupShell}
              />
            ))}
          </S.GroupShell>
        </S.SelectWrapper>
      </S.GroupShellWrapper>
    </S.GroupShellOutsideWrapper>
  );
};

export default DealsDealTypeGroupShell;
