"use client";

import styled from "styled-components";

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
`;
export const GroupTableWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
  width: 100%;
`;

export const GroupTableItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
`;
