import GlobalProvider from "./GlobalProvider";
import RoutesWrapper from "./routes/RoutesWrapper";

function App() {
  return (
    <GlobalProvider>
      <RoutesWrapper />
    </GlobalProvider>
  );
}

export default App;
