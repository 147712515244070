import React, { useCallback, useEffect, useRef, useState } from "react";

import { expandLabelData } from "@organisms/PressLabelOptionWindow/PressLabelOptionWindow.data";

import * as S from "./PressLabelElement.style";

import type { LabelCode } from "@/types/code.types";

interface Props {
  children: React.ReactNode;
  entity: LabelCode;
  handleClick?: React.MouseEventHandler<HTMLElement>;
  showLabelTooltip?: boolean;
  columnLabelViewerWidth?: number;
  columnLabelViewerTop?: number;
}

const PressLabelElement = ({
  children,
  entity,
  handleClick,
  showLabelTooltip,
  columnLabelViewerWidth,
  columnLabelViewerTop,
}: Props) => {
  const toolTipRef = useRef<HTMLDivElement>(null);
  const [offsetLeftLocation, setOffsetLeftLocation] = useState(0);
  const [offsetTopLocation, setOffsetTopLocation] = useState(0);
  const labelEntityData = expandLabelData.filter(
    (label) => label.entity === entity,
  )[0];

  const calculateOffsetLeftLocation = useCallback(() => {
    const columnWidth = columnLabelViewerWidth ?? 0;
    return columnWidth - 200;
  }, [columnLabelViewerWidth]);

  const calculateOffsetTopLocation = useCallback(() => {
    const columnHeight = columnLabelViewerTop ?? 0;
    return columnHeight;
  }, [columnLabelViewerTop]);

  useEffect(() => {
    if (toolTipRef.current) {
      setOffsetLeftLocation(toolTipRef.current.offsetLeft);
      setOffsetTopLocation(toolTipRef.current.getBoundingClientRect().y - 32);
    }
  }, [
    offsetLeftLocation,
    columnLabelViewerWidth,
    offsetTopLocation,
    setOffsetTopLocation,
  ]);

  return (
    <S.PressTextWrapper ref={toolTipRef}>
      <S.PressLabel entity={entity} onClick={handleClick}>
        {children}
      </S.PressLabel>
      <S.LabelTooltip
        className="tooltip"
        entity={entity}
        isEndLocation={offsetLeftLocation > calculateOffsetLeftLocation()}
        isTopLocation={offsetTopLocation - calculateOffsetTopLocation() < 0}
      >
        {labelEntityData?.text && <>{labelEntityData.text}</>}
      </S.LabelTooltip>
    </S.PressTextWrapper>
  );
};

export default PressLabelElement;
