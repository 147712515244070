import { apiClient } from "@api/apiClient";
import { EditorRoles } from "@libs/roles/types/roles.types";

const API_ENDPOINT = {
  roles: "/auth/roles",
};

export const editorRolesApis = {
  get: async () => {
    const { data } = await apiClient.get<EditorRoles>(API_ENDPOINT.roles, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    return {
      roles: data,
    };
  },
};
