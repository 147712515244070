import { SectionCode } from "@frontend/common";

import { apiClient } from "@api/apiClient";
import { ParagraphCode } from "@EditArticleContainer/shared/types/editArticle.types";
import { ParagraphContent } from "@EditArticleContainer/shared/types/paragraphContents/paragraphContents.types";

import {
  GetAllDataResponse,
  GetArticleContentDataResponse,
  RegenerateArticleContentDataArgs,
  RegenerateArticleContentDataResponse,
  UpdateArticleContentDataResponse,
} from "./articleContentData.interface";

const API_ENDPOINT = {
  getArticleContentData: "/des/article-content/data",
  getAllData: "/des/article-content/get-data",
  updateArticleContentData: "/des/article-content/update",
  settingArticleContentData: "/des/article-content/setting",
  reloadArticleContentData: "/des/article-content/reload",
  createArticleContentData: "/des/article-content/create",
  regenerateArticleContentData: "/des/article-content/regenerate",
  settingCompetitiveLandscape:
    "/des/article-content/setting-competitive-landscape",
};

export const articleContentDataApis = {
  getFillInTableData: async ({
    draftArticleId,
    sectionCode,
    paragraphCode,
  }: RegenerateArticleContentDataArgs) => {
    const { data } = await apiClient.post<RegenerateArticleContentDataResponse>(
      API_ENDPOINT.regenerateArticleContentData,
      {
        press_id: draftArticleId,
        press_type: sectionCode,
        section_type: paragraphCode,
      },
    );
    return data.data;
  },

  getEditArticleData: async (id: string, paragraphCode?: string) => {
    const URL = paragraphCode
      ? `${API_ENDPOINT.getArticleContentData}/${id}/${paragraphCode}`
      : `${API_ENDPOINT.getArticleContentData}/${id}`;

    const { data } = await apiClient.get<GetArticleContentDataResponse>(URL);
    return data;
  },

  getAllArticleData: async (
    id: string,
    sectionCode: string,
    paragraphCode: string,
  ) => {
    const { data } = await apiClient.post<GetAllDataResponse>(
      `${API_ENDPOINT.getAllData}`,
      {
        press_id: id,
        press_type: sectionCode,
        section_type: paragraphCode,
      },
    );
    return data;
  },

  updateOrigin: async (args: Partial<GetArticleContentDataResponse>) => {
    const { data } = await apiClient.post<
      Partial<UpdateArticleContentDataResponse>
    >(API_ENDPOINT.updateArticleContentData, args);
    return data;
  },

  update: async (args: Partial<ParagraphContent>) => {
    const { data } = await apiClient.post<
      Partial<UpdateArticleContentDataResponse>
    >(API_ENDPOINT.updateArticleContentData, args);
    return data;
  },

  setting: async (draftArticleId: string, sectionCode: SectionCode) => {
    const { data } = await apiClient.post(
      API_ENDPOINT.settingArticleContentData,
      {
        "Article Id": draftArticleId,
        "Item Id": sectionCode,
      },
    );
    return data;
  },

  settingCompetitiveLandscape: async (
    draftArticleId: string,
    sectionCode: SectionCode,
  ) => {
    const { data } = await apiClient.post(
      API_ENDPOINT.settingCompetitiveLandscape,
      {
        "Article Id": draftArticleId,
        "Item Id": sectionCode,
      },
    );
    return data;
  },

  reload: async (
    draftArticleId: string,
    sectionCode: SectionCode,
    paragraphCode: ParagraphCode,
  ) => {
    const { data } = await apiClient.post(
      API_ENDPOINT.reloadArticleContentData,
      {
        press_id: draftArticleId,
        press_type: sectionCode,
        section_type: paragraphCode,
      },
    );
    return data;
  },

  create: async (draftArticleId: string, sectionCode: SectionCode) => {
    const { data } = await apiClient.post(
      API_ENDPOINT.createArticleContentData,
      {
        "Article Id": draftArticleId,
        "Item Id": sectionCode,
      },
    );
    return data;
  },
};
