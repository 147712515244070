import { apiClient } from "@api/apiClient";

import type { TemporaryTokenApisResponse } from "./temporaryToken.interface";

export const TEMPORARY_TOKEN_API_ENDPOINT = {
  authorize: "/des/temporary-token/authorize",
};

export const temporaryTokenApis = {
  get: async (token: string, id: string, page: string) => {
    const { data } = await apiClient.get<TemporaryTokenApisResponse>(
      TEMPORARY_TOKEN_API_ENDPOINT.authorize,
      {
        params: {
          token,
          id,
          page,
        },
      },
    );

    return data;
  },
};
