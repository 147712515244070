import { useMutation, useSuspenseQuery } from "@tanstack/react-query";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import { queryClient } from "@api/queryClient";
import { QUERY_KEYS } from "@api/queryKeys";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { toastFail } from "@utils/toast";

import { entryDataApis } from "./entryData.service";

export const getEntryData = async (articleId: string) => {
  const { data } = await entryDataApis.get(articleId);
  return data;
};

export const useEntryDataQuery = (articleId: string = getPressIdFromURL()) => {
  return useSuspenseQuery({
    queryKey: [QUERY_KEYS.entryData, articleId],
    queryFn: () => entryDataApis.get(articleId),
    select: (data) => data.data,
  });
};

interface Props {
  entryData: ArticleEntryData;
  sectionCode: string;
  isDbSaved: boolean;
}
export const useUpdateEntryDataMutate = (
  articleId: string = getPressIdFromURL(),
) => {
  return useMutation({
    mutationFn: ({ entryData, sectionCode, isDbSaved }: Props) =>
      entryDataApis.update(articleId, entryData, isDbSaved, sectionCode),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [QUERY_KEYS.updateEntryData, articleId],
      });
    },
    onError: () => {
      toastFail("Failed to update entry data");
    },
  });
};
