import axios, { AxiosError, AxiosResponse } from "axios";

import { AUTHORIZE_API_ENDPOINT } from "@services/auth.service";
import { REFRESH_API_ENDPOINT } from "@services/refresh.service";
import tokenHelper from "@utils/tokenHelper";

import { reloadAccessToken } from "./utils/reloadAccessToken";

import type { DefaultErrorBody } from "./types/defaultErrorBody.types";

const PUBLIC_PATHS = [AUTHORIZE_API_ENDPOINT.post, REFRESH_API_ENDPOINT.post];

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_BASE_V2_URL,
  timeout: 1000 * 60,
  withCredentials: true,
});

apiClient.interceptors.request.use(async (config) => {
  const newConfig = { ...config };
  const requestUrl = newConfig.url ?? "";

  if (PUBLIC_PATHS.includes(requestUrl)) {
    return newConfig;
  }

  newConfig.headers.set(
    "Authorization",
    `Bearer ${tokenHelper.getAccessToken()}`,
  );

  return newConfig;
});

apiClient.interceptors.response.use(
  (response: AxiosResponse) => Promise.resolve(response),
  (error: AxiosError<DefaultErrorBody>) => {
    const responseUrl = error.response?.config.url ?? "";

    if (PUBLIC_PATHS.includes(responseUrl)) {
      return Promise.reject(error);
    }

    if (error.response?.status === 401) {
      try {
        return reloadAccessToken(error);
      } catch (updateAccessTokenError) {
        return Promise.reject(updateAccessTokenError);
      }
    }
    return Promise.reject(error);
  },
);

export { apiClient };
