import {
  CLINICAL_TRIAL_DESIGN_SHELL_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_SECTION_KEYS,
} from "@frontend/common";

import useArticleSection from "@/components/business/FillInTableContainer/hooks/useArticleSection";
import { ClinicalTrialDesign } from "@/types/clinicalTrials.types";
import {
  SHELL_PATH_TO_ADD_SHELL,
  BASIC_DATA_FOR_ADDING_SHELLS,
} from "@FillInTableContainer/shared/constants/tableCreatorShell.constants";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useUpdateEntryData } from "@hooks/useUpdateArticleData";
import { useEntryDataState } from "@stores/entryData";
import { useInitEntryDataValue } from "@stores/initEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { PositionInfoProps } from "../ClinicalTrialDesignShell";
import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface Returns {
  addDesignTwoShell: (groupShellInfoParams: {
    tableIndexParameter: number;
    groupShellIndexParameter: number;
    shellIndexParameter: number;
    shellKeyParameter: string;
    subGroupShellIndexParameter: number;
  }) => void;
}

export const useAddClinicalTrialsDesign2Shell = (
  positionPropsInfo: PositionInfoProps,
): Returns => {
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const initEntryData = useInitEntryDataValue(getPressIdFromURL());
  const updateEntryData = useUpdateEntryData();

  const articleSection = useArticleSection();

  const addDesignTwoShell = (groupShellInfoParams: {
    tableIndexParameter: number;
    groupShellIndexParameter: number;
    shellIndexParameter: number;
    shellKeyParameter: string;
    subGroupShellIndexParameter: number;
  }) => {
    const {
      tableIndexParameter,
      groupShellIndexParameter,
      shellIndexParameter,
      shellKeyParameter,
      subGroupShellIndexParameter,
    } = groupShellInfoParams;

    const targetShell = SHELL_PATH_TO_ADD_SHELL[shellKeyParameter](
      entryData,
      tableIndexParameter,
      groupShellIndexParameter,
      subGroupShellIndexParameter,
    );

    if (targetShell === undefined) return;

    const newArrayLength = targetShell.length + 1 || 1;
    const emptyArray = new Array(newArrayLength).fill({});

    const addShellData = emptyArray.map((_, index) => {
      if (index === shellIndexParameter) {
        return BASIC_DATA_FOR_ADDING_SHELLS[articleSection][shellKeyParameter](
          initEntryData,
        );
      }
      if (index > shellIndexParameter) {
        return targetShell[index - 1];
      }
      if (index < shellIndexParameter) {
        return targetShell[index];
      }
      return targetShell[index];
    });

    const changeShellData: Record<string, ArticleEntryData> = {
      [CLINICAL_TRIAL_DESIGN_SHELL_KEYS.designGroupInformation]: {
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: entryData[
            ENTRY_DATA_SECTION_KEYS.clinicalTrial
          ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableIndexParameter === tableInfoIndex) {
                return {
                  ...tableInfo,
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2]:
                    tableInfo[
                      ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
                    ].map((subGroupShellInfo, subGroupShellInfoIndex) => {
                      if (
                        subGroupShellInfoIndex === subGroupShellIndexParameter
                      ) {
                        return {
                          ...subGroupShellInfo,
                          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation]:
                            addShellData,
                        };
                      }
                      return subGroupShellInfo;
                    }),
                };
              }

              return tableInfo;
            },
          ) as ClinicalTrialDesign,
        },
      },
    };

    setEntryData(changeShellData[shellKeyParameter]);
    updateEntryData(changeShellData[shellKeyParameter]);
    changeCurrentHistory({
      entryData: changeShellData[shellKeyParameter],
      changedDataInfo: {
        target: "shell",
        action: "add",
        position: {
          ...positionPropsInfo,
          shellIndex: shellIndexParameter,
          valueIndex: null,
          value: null,
        },
      },
    });
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  return {
    addDesignTwoShell,
  };
};
