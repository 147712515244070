export const CLINICAL_TRIALS_TABLE_LAYOUT = {
  "Clinical Trials": [
    {
      groupTableKey: "Drug Profile",
      tableList: [
        {
          tableKey: "Drug",
          groupShellList: [
            {
              groupShellKey: "Drug Name",
              shellList: [
                {
                  shellKey: "Brand Name",
                },
                {
                  shellKey: "INN Name",
                },
                {
                  shellKey: "Code Name",
                },
              ],
            },
            {
              groupShellKey: null,
              shellList: [
                {
                  shellKey: "ROA",
                },
                {
                  shellKey: "Modality",
                },
                {
                  shellKey: "Target",
                },
                {
                  shellKey: "MOA",
                },
                {
                  shellKey: "Target Indication",
                },
                {
                  shellKey: "Therapeutic Area",
                },
                {
                  shellKey: "Other Characteristics",
                },
                {
                  shellKey: "Phase",
                },
                {
                  shellKey: "Generic / Biosimilar",
                },
                {
                  shellKey: "Ref.Product Name",
                },
                {
                  shellKey: "Originator/Developer",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      groupTableKey: "Clinical Trial Design",
      tableList: [
        {
          tableKey: "Clinical Trial Design",
          groupShellList: [
            {
              groupShellKey: "Clinical Design-1",
              subGroupShellList: [
                {
                  subGroupShellKey: null,
                  shellList: [
                    {
                      shellKey: "Clinical Design Phase",
                    },
                    {
                      shellKey: "Inclusion Criteria - Diagnostic",
                    },
                    {
                      shellKey: "Inclusion Criteria - Detail",
                    },
                    {
                      shellKey: "Total Patients Number",
                    },
                    {
                      shellKey: "Trial Name",
                    },
                    {
                      shellKey: "NCT Name",
                    },
                    {
                      shellKey: "Trial Intervention",
                    },
                  ],
                },
              ],
            },
            {
              groupShellKey: "Clinical Design-2",
              subGroupShellList: [
                {
                  subGroupShellKey: "Arm",
                  shellList: [
                    {
                      shellKey: "Arm Name",
                    },
                    {
                      shellKey: "Patients Number",
                    },
                    {
                      shellKey: "Design Group Information",
                      subShellList: [
                        {
                          subShellKey: "Drug Name",
                        },
                        {
                          subShellKey: "Dose",
                        },
                        {
                          subShellKey: "Duration",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      groupTableKey: "Clinical Results",
      tableList: [
        {
          tableKey: null,
          groupShellList: [
            {
              groupShellKey: "Clinical Results",
              shellList: [
                {
                  shellKey: "Results Classification",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      groupTableKey: "Clinical Endpoints (Table)",
      tableList: [
        {
          tableKey: "Data",
          groupShellList: [
            {
              groupShellKey: "Endpoints Table Information",
              shellList: [
                {
                  shellKey: "Endpoint Classification",
                },
                {
                  shellKey: "Endpoint Name including Measurement Timing",
                },
                {
                  shellKey: "Endpoint Group Information",
                  subShellList: [
                    {
                      subShellKey: "Group Data",
                    },
                    {
                      subShellKey: "P-Value",
                    },
                  ],
                },
              ],
            },
            {
              groupShellKey: "Table Notes",
              shellList: [
                {
                  shellKey: "Endpoints Table Notes",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      groupTableKey: "Clinical Endpoints (Description)",
      tableList: [
        {
          tableKey: null,
          groupShellList: [
            {
              groupShellKey: "Endpoint Description",
              shellList: [
                {
                  shellKey: "Endpoint Description",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      groupTableKey: "Key Comment",
      tableList: [
        {
          tableKey: null,
          groupShellList: [
            {
              groupShellKey: null,
              shellList: [
                {
                  shellKey: "Quote",
                },
                {
                  shellKey: "Spokesperson",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
