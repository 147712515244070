import React, { forwardRef } from "react";

import * as S from "./DefaultSearchInput.style";
import {
  PlaceholderInput,
  IconSearch,
} from "../../../../../../../../../../../../../components";

type Props = {
  keyDownEnterSearchArticles: (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  handleClickSearchArticles: () => void;
};

const DefaultSearchInput = forwardRef<HTMLInputElement, Props>(
  ({ keyDownEnterSearchArticles, handleClickSearchArticles }: Props, ref) => {
    return (
      <S.InputWrapper>
        <PlaceholderInput
          ref={ref}
          placeholder="Article Search..."
          styleType="line"
          status="default"
          width="28.8rem"
          height="3.6rem"
          onKeyDown={keyDownEnterSearchArticles}
          LeadingIcon={
            <button type="button" onClick={handleClickSearchArticles}>
              <IconSearch color="#53646d" width={24} height={24} />
            </button>
          }
        />
      </S.InputWrapper>
    );
  },
);

export default DefaultSearchInput;
