import { ReactNode, createContext, useContext, useMemo, useState } from "react";

type PressId = string;

export const TableCreatorFormatErrorPressIdContext = createContext<{
  errorPressId: PressId;
  changeErrorPressId: (newPressId: PressId) => void;
}>({
  errorPressId: "",
  changeErrorPressId: () => null,
});

export const TableCreatorFormatErrorPressIdProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [errorPressId, setCurrentPressId] = useState<string>("");

  const changeErrorPressId = (pressId: string) => {
    setCurrentPressId(pressId);
  };

  const pressIdWrapper = useMemo(
    () => ({ errorPressId, changeErrorPressId }),
    [errorPressId],
  );

  return (
    <TableCreatorFormatErrorPressIdContext.Provider value={pressIdWrapper}>
      {children}
    </TableCreatorFormatErrorPressIdContext.Provider>
  );
};

export const useTableCreatorFormatErrorPressId = () => {
  const { errorPressId, changeErrorPressId } = useContext(
    TableCreatorFormatErrorPressIdContext,
  );

  return { errorPressId, changeErrorPressId };
};
