export const ENTRY_DATA_CLINICAL_TRIAL_DESIGN = "Clinical Trial Design";

export const ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS = {
  clinicalTrialDesign1: "Clinical Trial Design-1",
  clinicalTrialDesign2: "Clinical Trial Design-2",
} as const;

export const ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS = {
  clinicalDesignPhase: "Clinical Design Phase",
  inclusionCriteriaDiagnostic: "Inclusion Criteria - Diagnostic",
  inclusionCriteriaDetail: "Inclusion Criteria - Detail",
  trialName: "Trial Name",
  nctName: "NCT Name",
  totalPatientsNumber: "Total Patients Number",
  trialIntervention: "Trial Intervention",
} as const;

export const ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS = {
  armName: "Arm Name",
  patientsNumber: "Patients Number",
  designGroupInformation: "Design Group Information",
} as const;

export const ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS = {
  drugName: "Drug Name",
  does: "Dose",
  duration: "Duration",
} as const;

export const ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS = {
  ...ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ...ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS,
  ...ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS,
  ...ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS,
} as const;
