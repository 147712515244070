import * as S from "./ArticleMainLabelContainer.style";
import { MAIN_LABEL_ENTITY_TO_COLOR } from "./constants/mainLabelType.constants";
import { useMainLabelList } from "./hooks/useMainLabelList";

const ArticleMainLabelReadableContainer = () => {
  const { savedMainLabelList } = useMainLabelList();

  return (
    <S.ArticleMainLabelWrapper>
      {savedMainLabelList.flatMap(({ entity, labelName, labelValue }) => (
        <S.ArticleMainLabelItem
          key={labelName}
          color={MAIN_LABEL_ENTITY_TO_COLOR[entity]}
        >
          {labelValue}
        </S.ArticleMainLabelItem>
      ))}
    </S.ArticleMainLabelWrapper>
  );
};

export default ArticleMainLabelReadableContainer;
