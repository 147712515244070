import { useLocation } from "react-router-dom";
import { AtomEffect, atom, useRecoilState, useRecoilValue } from "recoil";

import { PressListOrderingValue } from "@PressListContainer/pressList.types";

import { RECOIL_KEYS } from "./recoilKeys";

import type { ProcessStatusCode } from "@/types/code.types";

export interface PressListSearchFilter {
  allInfo?: string;
  companyName?: string;
  pressId?: string;
  pressTitle?: string;
  allEditor?: string;
  mcEditorName?: string;
  ftEditorName?: string;
  ftEditor2Name?: string;
  ftEditor?: string;
  pbEditorName?: string;
}

export interface PressListFilter {
  search?: PressListSearchFilter;
  pageLimit: number;
  page: number;
  pressListDataTotal: number;
  maxPage: number;
  confirmClassificationAi: string[];
  confirmClassificationManual: string[];
  status: ProcessStatusCode[];
  publishable?: "true" | "false";
  isTranslated?: "true" | "false";

  order: {
    pressId?: PressListOrderingValue;
    pressDate?: PressListOrderingValue;
    companyName?: PressListOrderingValue;
    pressTitle?: PressListOrderingValue;
    writerName?: PressListOrderingValue;
    mcEditorName?: PressListOrderingValue;
    ftEditorName?: PressListOrderingValue;
    ftEditor2Name?: PressListOrderingValue;
    pbEditorName?: PressListOrderingValue;
    publishable?: PressListOrderingValue;
    writeStartDt?: PressListOrderingValue;
    writeEndDt?: PressListOrderingValue;
    draftArticleStatusHighest?: PressListOrderingValue;
    classificationManual?: PressListOrderingValue;
    classificationAi?: PressListOrderingValue;
    articleRejectReason?: PressListOrderingValue;
  };
}

const handleSearchQuery =
  (): AtomEffect<PressListFilter> =>
  ({ setSelf, onSet }) => {
    const newSearchParams = new URLSearchParams();
    const location = useLocation();

    if (location.search) {
      const searchParams = new URLSearchParams(location.search);

      const {
        confirmClassificationAi,
        confirmClassificationManual,
        status,
        search,
      } = JSON.parse(Object.fromEntries(searchParams).filter);

      setSelf({
        page: 1,
        maxPage: 1,
        pageLimit: 20,
        pressListDataTotal: 0,
        confirmClassificationAi: confirmClassificationAi || [],
        confirmClassificationManual: confirmClassificationManual || [],
        status: status || [],
        order: {},
        search: search || {},
      });
    }
    onSet((newValue) => {
      newSearchParams.set("filter", JSON.stringify(newValue));
      window.history.pushState(
        "",
        "press-list",
        `press-list?${newSearchParams.toString()}`,
      );
    });
  };

const pressListFilterState = atom<PressListFilter>({
  key: RECOIL_KEYS.pressListFilterState,
  default: {
    page: 1,
    maxPage: 1,
    pageLimit: 20,
    pressListDataTotal: 0,
    confirmClassificationAi: [],
    confirmClassificationManual: [],
    status: [],
    order: {},
    search: {},
  },
  effects: [handleSearchQuery()],
});

export const usePressListFilterState = () =>
  useRecoilState(pressListFilterState);

export const usePressListFilterValue = () =>
  useRecoilValue(pressListFilterState);
