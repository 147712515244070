import styled, { css } from "styled-components";

import { ModalityCategoryStatus } from "./types/modalityCategory.types";

export const ModalityCategoryTextFieldWrapper = styled.div<{
  $status: ModalityCategoryStatus;
}>`
  position: relative;
  display: flex;
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-4, 0.4rem)
    var(--Spacing-2, 0.2rem) var(--Spacing-8, 0.8rem);
  align-items: center;
  gap: 0.4rem;
  width: 100%;
  height: 100%;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: var(--font-pretendard);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  border-radius: 0.4rem;
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);

  .required-mark {
    height: 100%;
    padding-top: 0.2rem;
  }

  ${({ $status }) => {
    if ($status === "confirmed") {
      return css`
        color: var(--Color-Common-Text-Disabled, #96a1b0);
        border: 1px solid var(--Color-Common-Border-Disabled, #b1b8c4);
        background: var(--Color-Common-Bg-Disabled, #cbd0d8);
        cursor: not-allowed;
      `;
    }
    if ($status === "userEditable") {
      return css`
        color: var(--Color-Common-Text-Primary, #2a2f37);
        background: var(--Color-Common-Bg-Secondary, #f0f2f4);
      `;
    }
    if ($status === "empty") {
      return css`
        border: 1px dashed var(--Color-Common-Border-Tertiary, #e5e7eb);
        background: var(--Color-Common-Bg-Interactive-Secondary, #fff);
        color: var(--Color-Common-Text-Tertiary, #7c899c);
        cursor: not-allowed;
      `;
    }
    if ($status === "defaultEmpty") {
      return css`
        color: var(--Color-Common-Text-Tertiary, #7c899c);
        border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
        background: var(--Color-Common-Bg-Secondary, #f0f2f4);
        cursor: not-allowed;
      `;
    }
    if ($status === "editable") {
      return css`
        color: var(--Color-Common-Text-Progress, #11ac45);
      `;
    }
    if ($status === "focused") {
      return css`
        color: var(--Color-Common-Text-Primary, #2a2f37);
        border: 1px solid
          var(--Color-Common-Border-Interactive-Primary, #0198e9);
        background: var(--Color-Common-Bg-Primary, #fff);
      `;
    }
  }}
`;
