import styled, { css } from "styled-components";

import { MainLabelItem } from "../../styles/mainLabel.style";
import { MainLabelColorType } from "../../types/articleMainLabel.types";

export const SingleEditableMainLabelWrapper = styled(MainLabelItem)`
  position: relative;
  border: none;
  padding: 0 0 0 2.8rem;
  cursor: pointer;
`;

export const LabelItem = styled.span<{ color: MainLabelColorType }>`
  background-color: ${({ theme, color }) =>
    theme.color.labelBackgroundTransparent[color]};
  border: 1px solid ${({ theme, color }) => theme.color.label[color]};
  border-radius: 0.4rem;
  padding: 0.2rem 0.8rem;
`;

export const IconDropDown = styled.img<{
  isOpen: boolean;
}>`
  position: absolute;
  left: 0;
  top: 0;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;
