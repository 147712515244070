import {
  ENTRY_DATA_INVESTORS_SHELL_KEYS as INVESTORS_SHELL_KEYS,
  MERGE_ACQUISITION_SHELL_KEYS,
} from "@frontend/common";

export const IS_DRAGGING_SHELL = [
  "Licensee Responsibility & Rights (MA)",
  "Licensee Responsibility & Rights (SC & LA)",
  "Licensor Responsibility & Rights",
  "Other Financial Terms (SC & LA)",
  "Other Financial Terms (MA)",
  "Additional Information",
  "Purpose of the Deal",
  INVESTORS_SHELL_KEYS.investorName,
  MERGE_ACQUISITION_SHELL_KEYS.mergerFinancialTermsMa,
];

export const DRAGGING_SHELL_CUSTOM_CLASS_NAME: Record<string, string> = {
  "Licensee Responsibility & Rights (MA)": "none",
  "Licensee Responsibility & Rights (SC & LA)": "none",
  "Licensor Responsibility & Rights": "none",
  "Other Financial Terms (SC & LA)": "none",
  "Other Financial Terms (MA)": "none",
  "Additional Information": "none",
  "Purpose of the Deal": "none",
  [INVESTORS_SHELL_KEYS.investorName]: "drag-button-value-investor-name",
};

export const VALUE_CONTAINER_DRAGGING_SHELL_KEYS: string[] = [
  INVESTORS_SHELL_KEYS.investorName,
];
