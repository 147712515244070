"use client";

import styled from "styled-components";

export const SkeletonBox = styled.div<{ $width?: string; $height?: string }>`
  width: ${({ $width }) => $width || "100%"};
  height: ${({ $height }) => $height || "100%"};
  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
`;
