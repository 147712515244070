import { ClinicalTrialDesign2Data } from "@EditArticleContainer/shared/types/paragraphContents/contents/clinicalTrialDesignContent.types";

export class ClinicalTrialDesign2 {
  private static combineGroupInformation = (
    drugName: string,
    dose?: string,
    duration?: string,
  ) => {
    return dose || duration
      ? `${drugName} (${[dose, duration].filter((item) => item).join(", ")})`
      : drugName;
  };

  private static createMultiLineText = (textList: string[]) => {
    if (textList.length === 0) {
      return "";
    }

    if (textList.length === 1) {
      return textList[0];
    }

    return textList
      .filter((item) => item)
      .map((item) => (item.startsWith("-") ? item : `- ${item}`))
      .join("\n");
  };

  private clinicalTrialDesign2Data: ClinicalTrialDesign2Data[];

  constructor(clinicalTrialDesign2Data: ClinicalTrialDesign2Data[]) {
    this.clinicalTrialDesign2Data = clinicalTrialDesign2Data;
  }

  get data() {
    return this.clinicalTrialDesign2Data;
  }

  get armName() {
    return this.data.map((item, index) => ({
      key: `TC004-Arm Name-${index}`,
      text: item["Arm Name"].value.at(0)?.text || "",
      labels: item["Arm Name"].value.at(0)?.labels || [],
      switch: item["Arm Name"].switch,
    }));
  }

  get patientsNumber() {
    return this.data.map((item, index) => ({
      key: `TC004-Patients Number-${index}`,
      text: item["Patients Number"].value.at(0)?.text || "",
      labels: item["Patients Number"].value.at(0)?.labels || [],
    }));
  }

  get designGroupInformation() {
    const drugNameTextList = this.data.map((row) =>
      row["Design Group Information"].map(
        (col) => col["Drug Name"].value.at(0)?.text || "",
      ),
    );

    const doseTextList = this.data.map((row) =>
      row["Design Group Information"].map(
        (col) => col.Dose.value.at(0)?.text || "",
      ),
    );

    const durationTextList = this.data.map((row) =>
      row["Design Group Information"].map(
        (col) => col.Duration.value.at(0)?.text || "",
      ),
    );

    const textList = drugNameTextList.map((drugNameRow, rowIndex) => {
      return ClinicalTrialDesign2.createMultiLineText(
        drugNameRow.map((drugNameText, colIndex) => {
          const doseText = doseTextList[rowIndex][colIndex];
          const durationText = durationTextList[rowIndex][colIndex];

          return ClinicalTrialDesign2.combineGroupInformation(
            drugNameText,
            doseText,
            durationText,
          );
        }),
      );
    });

    const labelsList =
      this.data
        .at(0)
        ?.["Design Group Information"].map(
          (col) => col["Drug Name"].value.at(0)?.labels || [],
        ) || [];

    const keyList = this.data.map(
      (_, rowIndex) => `TC004-Design Group Information-${rowIndex}`,
    );

    return textList.map((text, index) => ({
      key: keyList[index],
      text,
      labels: labelsList[index],
    }));
  }
}
