"use client";

import styled from "styled-components";

export const DBDetailSentenceEntryGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.2rem;
`;
