import React from "react";

type Props = {
  width?: number;
  height?: number;
  color?: string;
  svgDataId?: string;
};

const IconChevronDown = ({
  width = 24,
  height = 24,
  color = "black",
  svgDataId = "button",
}: Props) => {
  return (
    <svg
      data-button={svgDataId}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        data-button="chevron-down"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55806 9.05806C6.80214 8.81398 7.19786 8.81398 7.44194 9.05806L12 13.6161L16.5581 9.05806C16.8021 8.81398 17.1979 8.81398 17.4419 9.05806C17.686 9.30214 17.686 9.69786 17.4419 9.94194L12.4419 14.9419C12.1979 15.186 11.8021 15.186 11.5581 14.9419L6.55806 9.94194C6.31398 9.69786 6.31398 9.30214 6.55806 9.05806Z"
        fill={color}
      />
    </svg>
  );
};

IconChevronDown.displayName = "ChevronDown";
export default IconChevronDown;
