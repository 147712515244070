/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */

"use client";

import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useId,
  useState,
} from "react";

import * as S from "./EditableText.style";
import { createReadModeHtml } from "./utils/createReadModeHtml";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  html: string;

  canTextEdit?: boolean;
  isDisabled?: boolean;
  imagePosition?: "left" | "bottom";
  imageNodeList?: React.ReactNode[];
  isLabelWithLine?: boolean;
  imageColumnCount?: number;
  isClinicalTrialIdentifier?: boolean;
  isCapitalize?: boolean;
}

const EditableText = forwardRef(
  (
    {
      html,
      canTextEdit = false,
      isDisabled = false,
      imagePosition = undefined,
      imageNodeList = [],
      isLabelWithLine = false,
      imageColumnCount = 1,
      isClinicalTrialIdentifier = false,
      isCapitalize = false,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const editableTextId = useId();
    const [mode, setMode] = useState<"view" | "edit">("view");
    const [editableHtml, setEditableHtml] = useState(html);

    const changeToEditMode = () => {
      if (isDisabled) {
        return;
      }
      if (canTextEdit && mode === "view") {
        setMode("edit");
      }
    };

    const handleBlurEditModeTag = () => {
      setMode("view");
    };

    useEffect(() => {
      // mode가 edit이 될때 해당 element에 focus
      if (mode === "edit") {
        const element = document.getElementById(editableTextId);
        if (element) {
          element.focus();
        }
      }
    }, [editableTextId, mode]);

    useEffect(() => {
      // Editable Text의 html이 변경될때마다 editableHtml을 업데이트
      const element = document.getElementById(editableTextId);
      if (!element) {
        return;
      }

      const mutationObserver = new MutationObserver(() => {
        if (element) {
          setEditableHtml(element.innerHTML);
        }
      });

      mutationObserver.observe(element, {
        childList: true,
        subtree: true,
        characterData: true,
      });

      return () => {
        mutationObserver.disconnect();
      };
    }, [editableTextId]);

    const readModeTextIndex = canTextEdit ? 0 : undefined;

    return (
      <>
        <S.ReadModeText
          {...rest}
          dangerouslySetInnerHTML={{
            __html: createReadModeHtml(
              editableHtml,
              imagePosition,
              imageNodeList,
              imageColumnCount,
              isClinicalTrialIdentifier,
            ),
          }}
          onClick={changeToEditMode}
          onFocus={changeToEditMode}
          $isLabelWithLine={isLabelWithLine}
          $canTextEdit={canTextEdit && !isDisabled}
          $isDisabled={isDisabled}
          $isCapitalize={isCapitalize}
          style={{
            display: mode === "view" ? "block" : "none",
            ...rest.style,
          }}
          tabIndex={readModeTextIndex}
        />
        {canTextEdit && (
          <S.EditModeText
            {...rest}
            id={editableTextId}
            ref={ref}
            contentEditable={canTextEdit}
            suppressContentEditableWarning
            spellCheck={false}
            onBlur={handleBlurEditModeTag}
            dangerouslySetInnerHTML={{
              __html: html,
            }}
            style={{
              display: mode === "view" ? "none" : "block",
              ...rest.style,
            }}
          />
        )}
      </>
    );
  },
);

export default EditableText;
