import { ArticleEntryData } from "@/types/articleEntryData.types";
import { DealsSection } from "@/types/deals.types";
import { createRandomString } from "@organisms/TableCreatorComment/components/CommentModalContainer/components/CommentModalMessageComposer/utils/createRandomString";

export const BASIC_DATA_FOR_ADDING_TABLES = {
  Deals: {
    "Drug Profile": (data: ArticleEntryData) => {
      const drugProfileTableData = data?.Deals?.["Drug Profile"]?.[0];
      const createAttributionId = () => createRandomString(8);

      if (!drugProfileTableData) return;
      return {
        ROA: {
          value: [],
          options: drugProfileTableData?.ROA?.options,
          attributionId: createAttributionId(),
        },
        Modality: {
          value: [],
          options: drugProfileTableData?.Modality?.options,
          attributionId: createAttributionId(),
        },
        Target: {
          value: [],
          options: drugProfileTableData?.Target?.options,
          attributionId: createAttributionId(),
        },
        MOA: {
          value: [],
          options: drugProfileTableData?.MOA?.options,
          attributionId: createAttributionId(),
        },
        Phase: {
          value: [],
          options: drugProfileTableData?.Phase?.options,
          attributionId: createAttributionId(),
        },
        "Code Name": {
          value: [],
          options: drugProfileTableData?.["Code Name"]?.options,
          attributionId: createAttributionId(),
        },
        "INN Name": {
          value: [],
          options: drugProfileTableData?.["INN Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Brand Name": {
          value: [],
          options: drugProfileTableData?.["Brand Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Target Indication": {
          value: [],
          options: drugProfileTableData?.["Target Indication"]?.options,
          attributionId: createAttributionId(),
        },
        "Therapeutic Area": {
          value: [],
          options: drugProfileTableData?.["Therapeutic Area"]?.options,
          attributionId: createAttributionId(),
        },
        "Other Characteristics": {
          value: [],
          options: drugProfileTableData?.["Other Characteristics"]?.options,
          attributionId: createAttributionId(),
        },
        "Generic / Biosimilar": {
          value: ["false"],
          options: drugProfileTableData?.["Generic / Biosimilar"]?.options,
          attributionId: createAttributionId(),
        },
        "Ref.Product Name": {
          value: [],
          options: drugProfileTableData?.["Ref.Product Name"]?.options,
          attributionId: createAttributionId(),
        },
        "Originator/Developer": {
          value: [],
          options: drugProfileTableData?.["Originator/Developer"]?.options,
          attributionId: createAttributionId(),
        },
        attributionInfo: {
          attributionId: createAttributionId(),
        },
      };
    },
    "Additional Information (Deal)": () => null,
    "Deal Type & Status": () => null,
    "Licensee Table": () => null,
    "Licensor Table": () => null,
    "Key Comment": () => null,
  },
};

export const CHECK_TABLE_KEY_BY_GROUP_TABLE_KEY: {
  Deals: {
    [key in DealsSection]: string | null;
  };
} = {
  Deals: {
    "Additional Information (Deal)": null,
    "Deal Type & Status": null,
    "Drug Profile": "Drug",
    "Licensee Table": null,
    "Licensor Table": null,
    "Key Comment": null,
  },
};
