import { RefObject } from "react";

import { PARAGRAPH_CODE_CLINICAL_RESULTS } from "@frontend/common";
import dayjs from "dayjs";

import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import {
  REF_CODE_EMPTY,
  REF_CODE_ENDPOINTS_TABLE_GROUP_DATA,
  REF_CODE_ENDPOINTS_TABLE_GROUP_NAME,
  REF_CODE_ENDPOINTS_TABLE_MEASUREMENT_TIMING,
  REF_CODE_ENDPOINTS_TABLE_NOTES,
  REF_CODE_ENDPOINTS_TABLE_TITLE,
  REF_CODE_ENDPOINT_DESCRIPTION,
  REF_CODE_RESULTS_CLASSIFICATION,
  REF_CODE_SEPARATOR,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createRefCode } from "@EditArticleContainer/shared/libs/refCode/utils/createRefCode";
import { ClinicalResultsContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/clinicalResultsContent.types";
import {
  createContentFromHTML,
  createContentFromHTMLForSwitchTable,
} from "@EditArticleContainer/shared/utils/getValueFromRefMap";
import { useSectionCodeToClassification } from "@hooks/useSectionCode";

import { getParagraphEditedData } from "../../useParagraph";

const extractIndexList = (
  existRefEntries: [string, RefObject<HTMLElement>][],
  targetRefCode: string,
  index: number,
) => {
  return [
    ...new Set(
      existRefEntries
        .filter(([key]) => key.startsWith(targetRefCode))
        .map(([key]) => Number(key.split(REF_CODE_SEPARATOR)[index])),
    ),
  ];
};

export const useCreateClinicalResultsContent = () => {
  const refMap = useArticleContentRefMap();
  const sectionCode = useSectionCodeToClassification();

  return async () => {
    const paragraph = await getParagraphEditedData({
      sectionCode,
      paragraphCode: PARAGRAPH_CODE_CLINICAL_RESULTS,
    });
    if (paragraph === null) {
      throw new Error("Clinical Results Content is null");
    }

    const existRefEntries = [
      ...new Map([...refMap].filter(([, value]) => value.current)),
    ];

    const tableIndexList = extractIndexList(
      existRefEntries,
      REF_CODE_ENDPOINTS_TABLE_GROUP_NAME,
      1,
    );

    const clinicalEndpointsTableData: ClinicalResultsContent["text"]["Clinical Endpoints (Table)"] =
      tableIndexList.map((tableIndex) => {
        const rowIndexList = extractIndexList(
          existRefEntries,
          createRefCode(REF_CODE_ENDPOINTS_TABLE_GROUP_DATA, tableIndex),
          2,
        );

        const columnIndexList = extractIndexList(
          existRefEntries,
          createRefCode(REF_CODE_ENDPOINTS_TABLE_GROUP_DATA, tableIndex),
          3,
        );

        return {
          "Endpoints Table Title": createContentFromHTMLForSwitchTable(
            refMap,
            createRefCode(REF_CODE_ENDPOINTS_TABLE_TITLE, tableIndex),
          ),
          "Endpoints Table Notes": createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_ENDPOINTS_TABLE_NOTES, tableIndex),
          ),
          "Endpoints Table Information": rowIndexList.map((rowIndex) => {
            const endpointClassification =
              paragraph.text["Clinical Endpoints (Table)"][tableIndex][
                "Endpoints Table Information"
              ][rowIndex]["Endpoint Classification"];

            return {
              "Endpoint Classification": endpointClassification,
              "Endpoint Name including Measurement Timing":
                createContentFromHTML(
                  refMap,
                  createRefCode(
                    REF_CODE_ENDPOINTS_TABLE_MEASUREMENT_TIMING,
                    tableIndex,
                    rowIndex,
                  ),
                ),
              "Endpoint Group Information": columnIndexList.map(
                (columnIndex) => {
                  return {
                    "Group Name": createContentFromHTML(
                      refMap,
                      createRefCode(
                        REF_CODE_ENDPOINTS_TABLE_GROUP_NAME,
                        tableIndex,
                        columnIndex,
                      ),
                    ),
                    "Group Data": createContentFromHTML(
                      refMap,
                      createRefCode(
                        REF_CODE_ENDPOINTS_TABLE_GROUP_DATA,
                        tableIndex,
                        rowIndex,
                        columnIndex,
                      ),
                    ),
                    "P-Value": createContentFromHTML(
                      refMap,
                      createRefCode(
                        REF_CODE_EMPTY,
                        tableIndex,
                        rowIndex,
                        columnIndex,
                      ),
                    ),
                  };
                },
              ),
            };
          }),
        };
      });

    const clinicalResultsData: ClinicalResultsContent = {
      text: {
        "Clinical Results": {
          "Results Classification": createContentFromHTML(
            refMap,
            REF_CODE_RESULTS_CLASSIFICATION,
          ),
        },
        "Clinical Endpoints (Description)": {
          "Endpoint Description": createContentFromHTML(
            refMap,
            REF_CODE_ENDPOINT_DESCRIPTION,
          ),
        },
        "Clinical Endpoints (Table)": clinicalEndpointsTableData,
      },
      labels: [],
      updateDt: dayjs().toISOString(),
    };

    return clinicalResultsData;
  };
};
