import styled from "styled-components";

export const ImageListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  gap: 2.4rem 2.4rem;
  padding-bottom: 8rem;

  @media only screen and (min-width: 1px) and (max-width: 1520px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
