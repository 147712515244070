import ReactDOM from "react-dom/client";

import { initSentry } from "@libs/sentry/initSentry";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

initSentry();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(<App />);

reportWebVitals();
