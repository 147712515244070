import React, { useState } from "react";

import { IconCheck, IconXMark } from "@frontend/common";

import { ClassificationCode } from "@/types/code.types";
import {
  useArticleInfoMutation,
  useArticleInfoQuery,
} from "@api/articleInfo/useArticleInfo";
import FilterTag from "@atoms/FilterTag/FilterTag";
import Modal from "@atoms/Modal/Modal";
import { CLASSIFICATION_GROUP } from "@PressListContainer/classificationInfo.constants";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { trimCharacter } from "@utils/trimCharacter";

import * as S from "./SelectClassification.style";

interface Props {
  closeModal: () => void;
}

const SelectClassification = ({ closeModal }: Props) => {
  const draftArticleId = getPressIdFromURL();
  const { data: articleInfo } = useArticleInfoQuery(Number(draftArticleId));
  const { mutateAsync: updateArticleInfo } = useArticleInfoMutation(
    Number(draftArticleId),
  );

  const [classificationManual, setClassificationManual] = useState<
    ClassificationCode | ""
  >(articleInfo?.classificationManual ?? "");

  const handleConfirm: React.MouseEventHandler<
    HTMLButtonElement
  > = async () => {
    if (classificationManual === "" || classificationManual === null) return;

    const mcEditorName = trimCharacter(articleInfo?.mcEditorName);

    if (mcEditorName === null || mcEditorName === "") {
      await updateArticleInfo({
        classificationManual,
        isCheckStartDate: "true",
        type: "MC",
      });
    } else {
      await updateArticleInfo({
        classificationManual,
      });
    }

    closeModal();
  };

  const handleClassificationClick: React.MouseEventHandler<HTMLInputElement> = (
    event,
  ) => {
    if (classificationManual === event.currentTarget.value)
      setClassificationManual("");
  };

  const handleClassificationChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setClassificationManual(event.currentTarget.value as ClassificationCode);
  };

  const handleClose = () => {
    closeModal();
  };

  const confirmButtonClassification = classificationManual
    ? CLASSIFICATION_GROUP[classificationManual]
    : articleInfo?.classificationAiName;

  return (
    <Modal closeModal={closeModal}>
      <S.ClassificationWrapper>
        <S.ClassificationContentWrapper>
          <S.ClassificationRow>
            <S.ClassificationTitle>
              Select Manual Classification
            </S.ClassificationTitle>
            <S.ClassificationCloseButton type="button" onClick={handleClose}>
              <S.ClassificationCloseText>Close</S.ClassificationCloseText>
              <S.ClassificationXMark>
                <IconXMark color="#515C6C" width={20} height={20} />
              </S.ClassificationXMark>
            </S.ClassificationCloseButton>
          </S.ClassificationRow>
          <S.Divider />
          <S.ClassificationManualFieldset>
            {Object.entries(CLASSIFICATION_GROUP).map(([key, value]) => (
              <FilterTag
                key={key}
                tagType="radio"
                name="classification"
                value={key}
                checked={classificationManual === key}
                onClick={handleClassificationClick}
                onChange={handleClassificationChange}
                filterType="pressManualClassification"
                aiSelected={articleInfo?.classificationAi === key}
              >
                {value}
              </FilterTag>
            ))}
          </S.ClassificationManualFieldset>
          <S.Divider />
          <S.ConfirmButton
            onClick={handleConfirm}
            disabled={!classificationManual}
          >
            <>
              <IconCheck color="#fff" width={20} height={20} />
              <S.ConfirmButtonText>Request AI Input</S.ConfirmButtonText>
              <S.ConfirmButtonText className="checkedClassification">
                &quot;{confirmButtonClassification}&quot;
              </S.ConfirmButtonText>
            </>
          </S.ConfirmButton>
        </S.ClassificationContentWrapper>
      </S.ClassificationWrapper>
    </Modal>
  );
};

export default SelectClassification;
