import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useTableCreatorCommentListQuery } from "@api/tableCreatorCommentList/tableCreatorCommentList";

import { useHandleEntryDataToGetUnresolvedCommentInfos } from "../hooks/useHandleEntryDataToGetUnresolvedCommentInfos";
import { UnresolvedCommentInfos } from "../types/unresolvedComment.types";

const DEFAULT_UNRESOLVED_COMMENT_INFOS: UnresolvedCommentInfos = [];

export const UnresolvedCommentInfoWithPositionContext = createContext<{
  unresolvedCommentInfos: UnresolvedCommentInfos;
  changeUnresolvedCommentInfos: (newInfo: UnresolvedCommentInfos) => void;
}>({
  unresolvedCommentInfos: DEFAULT_UNRESOLVED_COMMENT_INFOS,
  changeUnresolvedCommentInfos: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const UnresolvedCommentInfoWithPositionProvider = ({
  children,
}: Props) => {
  const { data: commentList } = useTableCreatorCommentListQuery();
  const { filteringEntryDataToGetUnresolvedCommentInfos } =
    useHandleEntryDataToGetUnresolvedCommentInfos();

  const [unresolvedCommentInfos, setUnresolvedCommentInfos] =
    useState<UnresolvedCommentInfos>(DEFAULT_UNRESOLVED_COMMENT_INFOS);

  const changeUnresolvedCommentInfos = (
    newInfo: UnresolvedCommentInfos,
  ): void => {
    setUnresolvedCommentInfos(newInfo);
  };

  useEffect(() => {
    if (commentList) {
      const infos = filteringEntryDataToGetUnresolvedCommentInfos(commentList);
      changeUnresolvedCommentInfos(infos);
    }
  }, [commentList, filteringEntryDataToGetUnresolvedCommentInfos]);

  const unresolvedCommentInfoWrapper = useMemo(
    () => ({ unresolvedCommentInfos, changeUnresolvedCommentInfos }),
    [unresolvedCommentInfos],
  );
  return (
    <UnresolvedCommentInfoWithPositionContext.Provider
      value={unresolvedCommentInfoWrapper}
    >
      {children}
    </UnresolvedCommentInfoWithPositionContext.Provider>
  );
};

export const useUnresolvedCommentInfoWithPosition = () => {
  const { unresolvedCommentInfos } = useContext(
    UnresolvedCommentInfoWithPositionContext,
  );

  return { unresolvedCommentInfos };
};
