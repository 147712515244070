"use client";

import backgroundImageE032 from "../assets/images/background/background-image-E032.svg";
import backgroundImageE052 from "../assets/images/background/background-image-E052.svg";

const color = {
  node: {
    blue: "#0B6CFF",
    sky: "#019DF6",
    cyan: "#25D8FF",
  },
  live: {
    orange: "#FB6E52",
    crimson: "#F23C47",
  },
  neural: {
    gray10: "#11161e",
    gray09: "#1f2837",
    gray08: "#3c4757",
    gray07: "#687485",
    gray06: "#8c98aa",
    gray05: "#a8b3c5",
    gray04: "#cbd4e3",
    gray03: "#dee5ef",
    gray02: "#edf1f8",
    gray01: "#f7f9fd",
    gray00: "#fbfcfe",
    white: "#ffffff",
    black: "#000000",
  },
  bio: {
    blueGreen01: "#E2FAF7",
    blueGreen02: "#CEF6F2",
    blueGreen03: "#A7EFE7",
    blueGreen04: "#76E6D9",
    blueGreen05: "#37D1CE",
    blueGreen06: "#32C6D4",
  },

  gradient: {
    background1: "linear-gradient(91.92deg, #0B6CFF 25.97%, #25A6E4 100%)",
    background2: "linear-gradient(91.92deg, #0b6cff 16.07%, #fb6e52 100%)",
    background3: "linear-gradient(91.92deg, #019df6 28.99%, #25d8ff 100%)",
    background4: "linear-gradient(91.92deg, #f23c47 0%, #fb6e52 100%)",
    background5: "linear-gradient(91.92deg, #32c6d4 29.87%, #76e6d9 100%)",
    background6: "linear-gradient(91.92deg, #3c4757 24.5%, #fb6e52 100%)",
    background7: "linear-gradient(91.92deg, #0b6cff 31.08%, #8c98aa 100%)",
    background8: "linear-gradient(91.92deg, #3c4757 30.32%, #8c98aa 100%)",
    background9: "linear-gradient(91.92deg, #f7f9fd 0%, #edf1f8 100%)",
    background10:
      " linear-gradient(91.92deg, rgba(60, 71, 87, 0.1) 0%, rgba(11, 108, 255, 0.1) 100%), #3c4757",
    background11: " linear-gradient(180deg, #02236b 0%, #011a30 100%)",
    background12:
      " linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.4) 100%)",
    background13:
      " linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))",
    background14:
      "linear-gradient(0deg,rgba(251, 110, 82, 0.15),rgba(251, 110, 82, 0.15)), #ffffff",
  },

  labelBackgroundNormal: {
    blue: "#D1E3FF",
    orange: "#FFEDD1",
    purple: "#EAD6FA",
    pink: "#FDE7F2",
    yellow: "#FFFAA3",
    green: "#D9F4F7",
  },

  label: {
    blue: "rgba(0, 76, 190, 0.75)",
    orange: "rgba(255, 153, 0, 0.75)",
    purple: "rgba(138, 26, 222, 0.75)",
    pink: "rgba(242, 60, 147, 0.50)",
    yellow: "rgba(255, 244, 0, 0.75)",
    green: "rgba(50, 198, 212, 0.75)",
  },

  labelBackgroundTransparent: {
    blue: "rgba(0, 76, 190, 0.25)",
    orange: "rgba(255, 153, 0, 0.25)",
    purple: "rgba(138, 26, 222, 0.25)",
    pink: "rgba(242, 60, 147, 0.25) ",
    yellow: "rgba(255, 244, 0, 0.25)",
    green: "rgba(50, 198, 212, 0.25)",
  },

  labelBackground: {
    blue: "#D1E3FF",
    orange: "#FFEDD1",
    purple: "#EAD6FA",
    pink: "#FDE7F2",
    yellow: "#FFFAA3",
    green: "#D9F4F7",
  },

  labelStroke: {
    blue: "#75ACFF",
    orange: "#FFB647",
    purple: "#AD5BEC",
    pink: "#F67EB8",
    yellow: "#EBDF00",
    green: "#42CAD7",
  },

  labelText: {
    blue: "#00398F",
    orange: "#613A00",
    purple: "#4F0F80",
    pink: "#860945",
    yellow: "#2A2F37",
    green: "#196E76",
  },

  status: {
    todo: "#14CD53",
    dbSavedHalf: "#019DF6",
    dbSavedFull: "#019DF6",
    progress: "#FF9900",
    done: "#0B6CFF",
    disable: "#F23C93",
    etc: "#A8B3C5",
    draft: "#32C6D4",
  },
} as const;

const label = {
  background: {
    E010: color.labelBackground.blue,
    E020: color.labelBackground.orange,
    E030: color.labelBackground.purple,
    E031: color.labelBackground.purple,
    E032: color.labelBackground.purple,
    E040: color.labelBackground.pink,
    E050: color.labelBackground.yellow,
    E051: color.labelBackground.yellow,
    E052: color.labelBackground.yellow,
    E060: color.labelBackground.green,
  },
  backgroundImage: {
    E010: "",
    E020: "",
    E030: "",
    E031: "",
    E032: backgroundImageE032,
    E040: "",
    E050: "",
    E051: backgroundImageE052,
    E052: "",
    E060: "",
  },
  stroke: {
    E010: color.labelStroke.blue,
    E020: color.labelStroke.orange,
    E030: color.labelStroke.purple,
    E031: color.labelStroke.purple,
    E032: color.labelStroke.purple,
    E040: color.labelStroke.pink,
    E050: color.labelStroke.yellow,
    E051: color.labelStroke.yellow,
    E052: color.labelStroke.yellow,
    E060: color.labelStroke.green,
  },
  text: {
    E010: color.labelText.blue,
    E020: color.labelText.orange,
    E030: color.labelText.purple,
    E031: color.labelText.purple,
    E032: color.labelText.purple,
    E040: color.labelText.pink,
    E050: color.labelText.yellow,
    E051: color.labelText.yellow,
    E052: color.labelText.yellow,
    E060: color.labelText.green,
  },
} as const;

const draftStatus = {
  background: {
    S000: color.neural.gray06,
    S110: color.node.blue,
    S120: color.node.blue,
    S130: color.node.blue,
    S140: color.node.blue,
    S150: color.node.blue,
    S200: color.bio.blueGreen06,
    S210: color.bio.blueGreen06,
    S220: color.bio.blueGreen06,
    S300: color.live.orange,
  },
  textColor: {
    S000: color.neural.gray06,
    S110: color.node.blue,
    S120: color.node.blue,
    S130: color.node.blue,
    S140: color.node.blue,
    S150: color.node.blue,
    S200: color.bio.blueGreen06,
    S210: color.bio.blueGreen06,
    S220: color.bio.blueGreen06,
    S300: color.live.orange,
  },
} as const;

const status = {
  S110: color.status.todo,
  S112: color.status.todo,
  S300: color.status.progress,
  S111: color.status.todo,
  S120: color.status.progress,
  S121: color.status.progress,
  S910: color.status.dbSavedFull,
  S911: color.status.dbSavedHalf,
  S210: color.status.progress,
  S220: color.status.progress,
  S920: color.status.done,
  S930: color.status.disable,
  S800: color.status.etc,
  SystemError: color.status.etc,
} as const;

const font = {
  fontFamily: {
    pretendard: "var(--font-pretendard)",
    jost: "var(--font-jost)",
    roboto: "roboto",
    notoSerif: "var(--font-noto-serif)",
  },
} as const;

const size = {
  desktop: {
    1920: "1920px",
    1632: "1632px",
    1392: "1392px",
    1280: "1280px",
    1200: "1200px",
    1024: "1024px",
    990: "990px",
    768: "768px",
  },
  mobile: {
    989: "989px",
    767: "767px",
    450: "450px",
    360: "360px",
  },
} as const;

const mediaQuery = {
  desktop: {
    1920: `only screen and (min-width: ${size.desktop[1920]})`,
    1632: `only screen and (min-width: ${size.desktop[1632]})`,
    1392: `only screen and (min-width: ${size.desktop[1392]})`,
    1280: `only screen and (min-width: ${size.desktop[1280]}) and (max-width: calc(${size.desktop[1920]} - 0.1rem))`,
    "990-1199": `only screen and (min-width: ${size.desktop[990]}) and (max-width: calc(${size.desktop[1200]} - 0.1rem))`,
    "990-1024": `only screen and (min-width: ${size.desktop[990]}) and (max-width: calc(${size.desktop[1024]} - 0.1rem))`,
    990: `only screen and (min-width: ${size.desktop[990]}) and (max-width: calc(${size.desktop[1280]} - 0.1rem))`,
    768: `only screen and (min-width: ${size.desktop[768]}) and (max-width: calc(${size.desktop[990]} - 0.1rem))`,
  },
  mobile: {
    989: `only screen and (min-width: 1px) and (max-width: ${size.mobile[989]})`,
    767: `only screen and (min-width: 1px) and (max-width: ${size.mobile[767]})`,
    450: `only screen and (min-width: 1px) and (max-width: ${size.mobile[450]})`,
    360: `only screen and (min-width: 1px) and (max-width: ${size.mobile[360]})`,
  },
} as const;

export const theme = {
  color,
  label,
  draftStatus,
  status,
  font,
  mediaQuery,
} as const;
