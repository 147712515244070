import { useLocation } from "react-router-dom";

export const usePressIdFromURL = () => {
  const { search } = useLocation();
  const pressListId = Number(new URLSearchParams(search).get("id"));
  return pressListId;
};

export const getPressIdFromURL = () => {
  const { search } = window.location;
  const pressListId = new URLSearchParams(search).get("id") ?? "";
  return pressListId;
};

export const useStatusFromURL = () => {
  const { search } = useLocation();
  const status = new URLSearchParams(search).get("status");
  return status ?? undefined;
};

export const getTabTypeFromURL = () => {
  const { search } = window.location;
  const type = new URLSearchParams(search).get("type") ?? "";
  return type;
};
