import { LabelType } from "../DBResultsLabel";

type LabelColor = {
  [key in LabelType]: {
    primary: string;
    hover: string;
    active: string;
  };
};

export const LABEL_TYPE_COLOR: LabelColor = {
  normal: {
    primary: "var(--Color-Common-Bg-Interactive-Secondary, #FFF)",
    hover: "var(--Color-Common-Bg-Interactive-Secondary-Hovered, #F0F2F4)",
    active: "var(--Color-Common-Bg-Interactive-Secondary-Pressed, #E5E7EB)",
  },
  modality: {
    primary: "var(--Color-Label-Bg-Modality, #EAD6FA)",
    hover: "var(--Color-Label-Bg-Interactive-Modality-Hovered, #D6ADF5)",
    active: "var(--Color-Label-Bg-Interactive-Modality-Pressed, #C184F1)",
  },
  target: {
    primary: "var(--Color-Label-Bg-Target, #FFEDD1) ",
    hover: "var(--Color-Label-Bg-Interactive-Target-Hovered, #FFDAA3)",
    active: "var(--Color-Label-Bg-Interactive-Target-Pressed, #FFC875)",
  },
  medicine: {
    primary: "var(--Color-Label-Bg-Medicine, #D1E3FF)",
    hover: "var(--Color-Label-Bg-Interactive-Medicine-Hovered, #A3C8FF)",
    active: "var(--Color-Label-Bg-Interactive-Medicine-Pressed, #75ACFF)",
  },
  phase: {
    primary: "var(--Color-Label-Bg-Phase, #FFFAA3)",
    hover: "var(--Color-Label-Bg-Interactive-Phase-Hovered, #FFF875)",
    active: "var(--Color-Label-Bg-Interactive-Phase-Pressed, #FFF647)",
  },
  disease: {
    primary: "var(--Color-Label-Bg-Disease, #FDE7F2)",
    hover: "var(--Color-Label-Bg-Interactive-Disease-Hovered, #FCD4E7)",
    active: "var(--Color-Label-Bg-Interactive-Disease-Pressed, #F9A9D0)",
  },
  endpoint: {
    primary: "var(--Color-Label-Bg-Endpoint, #D9F4F7)",
    hover: "var(--Color-Label-Bg-Interactive-Endpoint-Hovered, #B3EAEF)",
    active: "var(--Color-Label-Bg-Interactive-Endpoint-Pressed, #8DDFE7)",
  },
};

export const LABEL_TEXT_COLOR = {
  normal: "var(--Color-Common-Text-Primary, #2A2F37)",
  modality: "var(--Color-Label-Text-Modality, #4F0F80)",
  target: "var(--Color-Label-Text-Target, #8F5600)",
  medicine: "var(--Color-Label-Text-Medicine, #00398F)",
  phase: "var(--Color-Label-Text-Phase, #615C00)",
  disease: "var(--Color-Label-Text-Disease, #860945)",
  endpoint: "",
};
