import styled from "styled-components";

export const CheckboxWrapper = styled.label<{
  checkboxStyle: "enabled" | "disabled";
}>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  strong {
    display: block;
    width: 2rem;
    height: 2rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    input {
      position: absolute;
      width: 0;
      height: 0;
      overflow: hidden;
    }
  }
  span {
    margin-left: 0.6rem;
    color: ${({ theme }) => theme.color.neural.gray05};
    font-weight: 600;
    font-size: 1.6rem;
  }
`;
