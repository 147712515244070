import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export const useArticleInfo = (pressId = Number(getPressIdFromURL())) => {
  const { data: articleInfo } = useArticleInfoQuery(pressId);

  return articleInfo;
};

export const useArticleInfoDraftArticleImg = (
  pressId = Number(getPressIdFromURL()),
) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.draftArticleImg;
};

export const useArticleInfoImagePath = (
  pressId = Number(getPressIdFromURL()),
) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.imagePath;
};

export const useArticleInfoStatus = (pressId = Number(getPressIdFromURL())) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.draftArticleStatus;
};

export const useArticleInfoStatusName = (
  pressId = Number(getPressIdFromURL()),
) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.draftArticleStatusName;
};

export const useArticleInfoCompanyName = (
  pressId = Number(getPressIdFromURL()),
) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.companyName;
};

export const useArticleInfoDate = (pressId = Number(getPressIdFromURL())) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.pressDate;
};

export const useArticleInfoPressUrl = (
  pressId = Number(getPressIdFromURL()),
) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.pressUrl;
};

export const useArticleInfoTitle = (pressId = Number(getPressIdFromURL())) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.pressTitle;
};

export const useClassificationAi = (pressId = Number(getPressIdFromURL())) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.classificationAi;
};

export const useArticleInfoClassificationAiName = (
  pressId = Number(getPressIdFromURL()),
) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.classificationAiName;
};

export const useClassificationManual = (
  pressId = Number(getPressIdFromURL()),
) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.classificationManual;
};

export const useArticleInfoClassificationManualName = (
  pressId = Number(getPressIdFromURL()),
) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.classificationManualName;
};

export const useArticleInfoMcEditorName = (
  pressId = Number(getPressIdFromURL()),
) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.mcEditorName;
};

export const useArticleInfoFtEditorName = (
  pressId = Number(getPressIdFromURL()),
) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.ftEditorName;
};

export const useArticleInfoFtEditor2Name = (
  pressId = Number(getPressIdFromURL()),
) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.ftEditor2Name;
};

export const useArticleInfoPbEditorName = (
  pressId = Number(getPressIdFromURL()),
) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.pbEditorName;
};

export const useArticleInfoArticleRejectReason = (
  pressId = Number(getPressIdFromURL()),
) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.articleRejectReason;
};

export const useArticleInfoPublishable = (
  pressId = Number(getPressIdFromURL()),
) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.publishable;
};

export const useArticleInfoAiDone = (pressId = Number(getPressIdFromURL())) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.aiDone;
};

export const useArticleInfoDuplArticleId = (
  pressId = Number(getPressIdFromURL()),
) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.duplArticleId;
};

export const useArticleInfoType = (pressId = Number(getPressIdFromURL())) => {
  const articleInfo = useArticleInfo(pressId);

  return articleInfo.type;
};
