import { css, styled } from "styled-components";

import {
  FixedWrapper,
  DeleteButtonWrapper,
  DeleteButton,
} from "@FillInTableContainer/shared/styles/deleteButton.style";
import { DefaultDraggableGroupShellWrapper } from "@FillInTableContainer/shared/styles/groupShell.style";
import { SectionOutsideWrapper } from "@FillInTableContainer/shared/styles/groupShellSection.style";

export const GroupShellSectionWrapper = styled(SectionOutsideWrapper)`
  margin-bottom: 1.6rem;
`;

export const DraggableGroupShellWrapper = styled(
  DefaultDraggableGroupShellWrapper,
)``;

export const FixedDeleteButtonWrapper = styled(FixedWrapper)<{
  isShellTitle?: boolean;
}>`
  top: -2.4rem;
  left: -0.2rem;
  right: auto;

  ${({ isShellTitle }) =>
    isShellTitle &&
    css`
      top: -2.6rem;
      left: -0.2rem;
    `}
`;

export const DeleteGroupShellSectionButtonWrapper = styled(
  DeleteButtonWrapper,
)``;

export const DeleteGroupShellSectionButton = styled(DeleteButton)``;
