import { removeCookie, setCookie, getCookie } from "./cookie";

export const TOKEN_KEYS: { [key: string]: string } = {
  accessToken: import.meta.env.VITE_ACCESS_TOKEN || "accessToken",
  refreshToken: import.meta.env.VITE_REFRESH_TOKEN || "refreshToken",
};

export const expiredCookieDate = () =>
  new Date(Date.now() + 1000 * 60 * 60 * 24 * 1);

const clearAllToken = () => {
  Object.keys(TOKEN_KEYS).forEach((key: string) => {
    removeCookie(TOKEN_KEYS[key]);
  });
};

const getAccessToken = () => getCookie(TOKEN_KEYS.accessToken);
const setAccessToken = (accessToken: string, isSessionCookie = true) =>
  setCookie(TOKEN_KEYS.accessToken, accessToken, {
    path: "/",
    expires: isSessionCookie ? undefined : expiredCookieDate(), // 구체적인 시간이 정해져있지 않아서 1일로 설정
  });

const getRefreshToken = () => getCookie(TOKEN_KEYS.refreshToken);
const setRefreshToken = (refreshToken: string, isSessionCookie = true) =>
  setCookie(TOKEN_KEYS.refreshToken, refreshToken, {
    path: "/",
    expires: isSessionCookie ? undefined : expiredCookieDate(), // 구체적인 시간이 정해져있지 않아서 1일로 설정
  });

const TokenHelper = {
  clearAllToken,
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
};

export default TokenHelper;
