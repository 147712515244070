import React from "react";

export interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconDefault = ({ width = 24, height = 24, color = "#515C6C" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="modality-icon-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.85946 5.25H7.47323C7.12805 5.25 6.84823 4.97018 6.84823 4.625C6.84823 4.27982 7.12805 4 7.47323 4H9.48446H14.5125H16.5238C16.8689 4 17.1488 4.27982 17.1488 4.625C17.1488 4.97018 16.8689 5.25 16.5238 5.25H15.1375V11.2578L19.2617 15.5598C20.8616 17.2288 19.6788 20.0013 17.3668 20.0013H6.63023C4.31822 20.0013 3.13535 17.2288 4.73532 15.5598L8.85946 11.2578V5.25ZM10.1095 5.25V11.509C10.1095 11.6702 10.0472 11.8251 9.93563 11.9415L5.63766 16.4248C4.79958 17.299 5.41918 18.7513 6.63023 18.7513H17.3668C18.5778 18.7513 19.1974 17.299 18.3593 16.4248L14.0614 11.9415C13.9498 11.8251 13.8875 11.6702 13.8875 11.509V5.25H10.1095Z"
        fill={color}
      />
    </svg>
  );
};

IconDefault.displayName = "Default";

export default IconDefault;
