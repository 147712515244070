import styled, { css } from "styled-components";

import {
  DeleteButton,
  DeleteButtonWrapper,
  FixedWrapper,
} from "@FillInTableContainer/shared/styles/deleteButton.style";
import {
  GroupShellTitleWrapper,
  OutsideWrapper,
} from "@FillInTableContainer/shared/styles/groupShell.style";
import {
  SelectButton,
  SelectButtonDivider,
  SelectButtonDividerWrapper,
  SelectContentWrapper,
} from "@FillInTableContainer/shared/styles/rowSelectButton.style";

export const GroupShellOutsideWrapper = styled(OutsideWrapper)``;

export const GroupShellWrapper = styled.div<{
  isTableScroll: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ isTableScroll }) =>
    isTableScroll &&
    css`
      min-width: max-content;
    `}
`;

export const GroupShell = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
  width: 100%;
`;

export const FixedDeleteButtonWrapper = styled(FixedWrapper)<{
  isShellTitle: string;
  groupShellIndex: number;
  isSeparatedTitleRow?: boolean;
  isSeparatedTitlePosition?: number;
}>`
  top: -2.6rem;
  left: -0.2rem;
`;

export const TitleWrapper = styled(GroupShellTitleWrapper)``;

export const DeleteTableButtonWrapper = styled(DeleteButtonWrapper)``;

export const DeleteTableButton = styled(DeleteButton)``;

export const SelectWrapper = styled(SelectContentWrapper)`
  position: relative;
  padding: 1.6rem 1.6rem 1.6rem 0;
`;

export const GroupShellSelectButton = styled(SelectButton)``;

export const GroupShellSelectButtonDivider = styled(SelectButtonDivider)``;

export const GroupShellSelectButtonDividerWrapper = styled(
  SelectButtonDividerWrapper,
)`
  position: sticky;
  top: 50%;
  left: 0;
`;
