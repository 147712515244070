import { EditArticleText } from "@EditArticleContainer/shared/types/editArticle.types";
import {
  Drug,
  Exclusivity,
  Indication,
  RightsScope,
  Territory,
} from "@EditArticleContainer/shared/types/paragraphContents/contents/dealTermsContent.types";
import { createIndexList } from "@utils/createIndexList";

const filteredEditArticleValueText = (value: EditArticleText[]) => {
  return (
    value
      ?.map((item) => ({
        text: item.text?.trim() ?? "",
      }))
      ?.filter((item) => item.text !== "") ?? []
  );
};

const deduplicateExclusivityTerritory = (rightsScope: RightsScope[]) => {
  const mergeRightsScopeToSameTerritoryAndExclusivity = (
    data: RightsScope[],
  ) => {
    const formattedData: {
      Drug: Drug[];
      Indication: Indication[];
      Exclusivity: Exclusivity;
      Territory: Territory;
    }[] = data.map((item) => ({
      Drug: Array.isArray(item.Drug) ? item.Drug : [item.Drug],
      Indication: Array.isArray(item.Indication)
        ? item.Indication
        : [item.Indication],
      Exclusivity: item.Exclusivity,
      Territory: item.Territory,
    }));

    const mergedData: {
      Drug: Drug[];
      Indication: Indication[];
      Exclusivity: Exclusivity;
      Territory: Territory;
    }[] = [];

    formattedData.forEach((item) => {
      const existingIndex = mergedData.findIndex(
        (mergedItem) =>
          JSON.stringify(mergedItem.Territory) ===
            JSON.stringify(item.Territory) &&
          JSON.stringify(mergedItem.Exclusivity) ===
            JSON.stringify(item.Exclusivity),
      );

      if (existingIndex !== -1) {
        mergedData[existingIndex].Drug.push(item.Drug[0]);
        mergedData[existingIndex].Indication.push(item.Indication[0]);
      } else {
        mergedData.push({ ...item });
      }
    });

    return mergedData;
  };

  return mergeRightsScopeToSameTerritoryAndExclusivity(rightsScope);
};

export const deduplicateRightsScope = (
  originalRightsScope: RightsScope[],
): {
  Drug: Drug[];
  Indication: Indication[];
  Exclusivity: Exclusivity;
  Territory: Territory;
}[] => {
  if (originalRightsScope.length === 0) return [];
  const drugLength = (tableIndex: number) =>
    Array.isArray(originalRightsScope[tableIndex]?.Drug)
      ? originalRightsScope[tableIndex]?.Drug?.length ?? 0
      : 1;
  const deduplicatedRightsScope = originalRightsScope?.map(
    (item, itemIndex) => {
      return JSON.stringify({
        Drug: Array.isArray(item.Drug)
          ? createIndexList(drugLength(itemIndex)).map((drugIndex) => ({
              value: filteredEditArticleValueText(
                item.Drug?.at(drugIndex)?.value ?? [],
              ),
            }))
          : [
              {
                value: filteredEditArticleValueText((item.Drug as Drug)?.value),
              },
            ],

        Indication: Array.isArray(item.Indication)
          ? createIndexList(drugLength(itemIndex)).map((drugIndex) => ({
              value: filteredEditArticleValueText(
                item.Indication.at(drugIndex)?.value ?? [],
              ),
            }))
          : [
              {
                value: filteredEditArticleValueText(
                  (item.Indication as Indication)?.value,
                ),
              },
            ],

        Exclusivity: {
          value: filteredEditArticleValueText(item.Exclusivity.value),
        },

        Territory: {
          value: filteredEditArticleValueText(item.Territory.value),
        },
      });
    },
  );

  const deduplicatedRightsScopeSet =
    [...new Set(deduplicatedRightsScope)].map((item) => JSON.parse(item)) ?? [];

  return deduplicateExclusivityTerritory(deduplicatedRightsScopeSet);
};

export const deduplicateRightsScopeForMA = (
  originalRightsScope: RightsScope[],
): {
  Drug: Drug[];
  Indication: Indication[];
  Exclusivity: Exclusivity;
  Territory: Territory;
}[] => {
  if (originalRightsScope.length === 0) return [];
  const drugLength = (tableIndex: number) =>
    Array.isArray(originalRightsScope[tableIndex]?.Drug)
      ? originalRightsScope[tableIndex]?.Drug?.length ?? 0
      : 1;
  const deduplicatedRightsScope = originalRightsScope?.map(
    (item, itemIndex) => {
      return JSON.stringify({
        Drug: Array.isArray(item.Drug)
          ? createIndexList(drugLength(itemIndex)).map((drugIndex) => ({
              value: filteredEditArticleValueText(
                item.Drug?.at(drugIndex)?.value ?? [],
              ),
            }))
          : [
              {
                value: filteredEditArticleValueText((item.Drug as Drug)?.value),
              },
            ],

        Indication: Array.isArray(item.Indication)
          ? createIndexList(drugLength(itemIndex)).map((drugIndex) => ({
              value: filteredEditArticleValueText(
                item.Indication.at(drugIndex)?.value ?? [],
              ),
            }))
          : [
              {
                value: filteredEditArticleValueText(
                  (item.Indication as Indication)?.value,
                ),
              },
            ],
      });
    },
  );

  const deduplicatedRightsScopeSet =
    [...new Set(deduplicatedRightsScope)].map((item) => JSON.parse(item)) ?? [];

  return deduplicatedRightsScopeSet;
};
