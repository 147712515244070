import { AIDropdownListFormat } from "../types/tableCreatorDropdown.types";

interface GetDefaultInfoOfAiListReturns {
  isAiList: boolean;
  aiListSet: AIDropdownListFormat[];
  aiListLength: number;
}

export const getDefaultInfoOfAiList = (
  aiList: AIDropdownListFormat[],
): GetDefaultInfoOfAiListReturns => {
  const currentAiList = aiList || [];
  const isAiList = currentAiList.length
    ? currentAiList[0]?.list?.length > 0
    : false;

  const aiListSet = [...new Set(currentAiList)];
  const aiListLength = aiListSet.length ? aiListSet?.length : 0;

  return {
    isAiList,
    aiListSet,
    aiListLength,
  };
};
