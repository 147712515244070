"use client";

import styled, { css } from "styled-components";

export const FooterMenuWrapper = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1.2rem 0;
  column-gap: auto;
  gap: 3.2rem;

  @media only screen and (min-width: 990px) and (max-width: calc(1280px - 0.1rem)),
    only screen and (min-width: 768px) and (max-width: calc(990px - 0.1rem)) {
    justify-content: space-between;
  }

  @media only screen and (min-width: 1px) and (max-width: 767px) {
    justify-content: space-between;
  }
`;

export const FooterMenuGroupWrapper = styled.div<{ $index: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.4rem;
  min-width: 16rem;
  padding: 0 0.8rem;
`;

export const FooterMenuGroupTitle = styled.h3`
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 150%; /* 2.7rem */
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
`;

export const FooterMenuList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const FooterMenuItem = styled.div<{ $isUpcoming?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  white-space: nowrap;
  cursor: pointer;
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);

  font-family: var(--font-pretendard);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 1.56rem */

  padding: var(--Spacing-8, 0.8rem) var(--Spacing-0, 0rem);
  align-items: flex-start;
  width: 100%;
`;

export const FooterMenuDisabledItem = styled.div<{ $isUpcoming?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1.4rem;
  line-height: 2.3rem;
  font-weight: 400;
  white-space: nowrap;
  color: ${({ theme }) => theme.color.neural.gray00};
  cursor: not-allowed;

  ${({ $isUpcoming }) => $isUpcoming && css``}
`;

export const DisabledMenuReason = styled.span`
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.color.neural.gray00};
  font-style: italic;
`;

export const FooterMenuTitleBox = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const FooterMenuConditionText = styled.span`
  position: absolute;
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-weight: 300;
  font-size: 1rem;
  font-style: italic;
  padding-left: 0.8rem;
  color: ${({ theme }) => theme.color.neural.white};
  position: absolute;
  left: -0.8rem;
  top: -1.6rem;
`;
