import React from "react";

import { CompanyImage, removeParentheses } from "@frontend/common";

import { useGetCompanyNameList } from "./useGetCompanyNameList";

export const useCreateCompanyLogoComponentList = () => {
  const { companyLogoInfos, mainCompanyLogos } = useGetCompanyNameList();

  const findTargetCompanyLogo = (name: string) => {
    const formattedName = [
      name?.toLowerCase()?.trim(),
      removeParentheses(name)?.toLowerCase()?.trim(),
    ];

    return companyLogoInfos
      ?.filter((info) =>
        formattedName.includes(info?.companyName?.toLowerCase()?.trim()),
      )
      ?.at(0)?.companyLogoUri;
  };

  const checkTargetGroupIsPerson = (name: string) => {
    const formattedName = [
      name?.toLowerCase()?.trim(),
      removeParentheses(name)?.toLowerCase()?.trim(),
    ];

    return companyLogoInfos
      ?.filter((info) =>
        formattedName.includes(info?.companyName?.toLowerCase()?.trim()),
      )
      ?.at(0)?.person;
  };

  const createCompanyLogoComponentList = (
    nameList: string[],
  ): React.ReactNode[] => {
    if (nameList.filter((item) => item).length === 0) return [];
    return nameList.map((name) => {
      const companyLogoUrl = findTargetCompanyLogo(name);

      return (
        <CompanyImage
          imageSize={30}
          containerSize={30}
          logoUrl={companyLogoUrl}
        />
      );
    });
  };

  return {
    createCompanyLogoComponentList,
    mainCompanyLogos,
    findTargetCompanyLogo,
    checkTargetGroupIsPerson,
  };
};
