export const RECOIL_KEYS = {
  articleDataState: "articleDataState",
  pressLabelsState: "pressLabelsState",
  sortedPressLabelsState: "sortedPressLabelsState",
  pressListFilterState: "pressListFilterState",
  articleEntryDataState: "articleFrame",
  unsavedArticleEntryDataState: "unsavedArticleFrame",
  classificationState: "classificationState",
  sectionByClassificationState: "sectionByClassificationState",
  pressContentState: "pressContentState",
  pressContentQuoteState: "pressContentQuoteState",
  mainLabelState: "mainLabelState",
  entryDataState: "entryDataState",
  initEntryDataState: "initEntryDataState",
  modalityState: "modalityState",
  modalityList: "modalityList",
  dataset: "dataset",
  displayDealType: "displayDealType",
  pressContentCurrencyKeyword: "pressContentCurrencyKeyword",
  pressContentCurrencyInfo: "pressContentCurrencyInfo",
};
