import React from "react";

import {
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL,
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS,
  ENTRY_DATA_CONTINGENT_PAYMENT_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_LICENSOR_TABLE,
  ENTRY_DATA_LICENSOR_TABLE_KEYS,
  ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS,
  ENTRY_DATA_RIGHTS_SCOPE_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  MERGE_ACQUISITION_GROUP_SHELL_KEYS,
  MERGE_ACQUISITION_TABLE_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import { KEY_DEALS_GROUP_SHELL } from "@FillInTableContainer/shared/constants/dealsKey.constants";
import {
  BASIC_DATA_FOR_ADDING_GROUP_SHELLS,
  GROUP_SHELL_PATH_TO_ADD_GROUP_SHELL,
} from "@FillInTableContainer/shared/constants/tableCreatorGroupShell.constants";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  DEFAULT_VALIDATED_INFO,
  useValidatedInTableCreator,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useCheckDisabledDelete } from "@FillInTableContainer/shared/hooks/useCheckDisabledDelete";
import { useUpdateEntryData } from "@hooks/useUpdateArticleData";
import { useEntryDataState } from "@stores/entryData";
import { useInitEntryDataValue } from "@stores/initEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  clickAddRightScopeGroupShellButton: (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
    },
  ) => void;
  handleClickToDeleteFinancialTermsGroupShell: () => void;
  handleKeyDownToDeleteFinancialTermsGroupShell: (event: KeyboardEvent) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: DealsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useHandleMergeLicenseeTableGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const initEntryData = useInitEntryDataValue(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const updateEntryData = useUpdateEntryData();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { checkDisabledDeleteGroupShell } = useCheckDisabledDelete();
  const articleSection = "Deals";

  const handleAddGroupShell = (groupShellInfo: {
    tableIndexParameter: number;
    groupShellIndexParameter: number;
    groupShellKeyParameter: string | null;
  }) => {
    const {
      tableIndexParameter,
      groupShellIndexParameter,
      groupShellKeyParameter,
    } = groupShellInfo;

    if (groupShellKeyParameter === null) return;

    const targetShell = GROUP_SHELL_PATH_TO_ADD_GROUP_SHELL[
      groupShellKeyParameter
    ](entryData, tableIndexParameter, groupShellIndexParameter);

    if (targetShell === undefined) return;

    const newArrayLength = targetShell.length + 1 || 1;
    const emptyArray = new Array(newArrayLength).fill({});

    const addRightsScopeGroupShellData = emptyArray.map((_, index) => {
      const RightScopeGroupShellData =
        BASIC_DATA_FOR_ADDING_GROUP_SHELLS[articleSection][
          groupShellKeyParameter
        ](initEntryData);

      if (groupShellKeyParameter !== KEY_DEALS_GROUP_SHELL.rightsScope) return;

      if (index === groupShellIndexParameter) {
        return RightScopeGroupShellData;
      }

      if (index > groupShellIndexParameter) {
        return targetShell[index - 1];
      }

      if (index < groupShellIndexParameter) {
        return targetShell[index];
      }

      return targetShell[index];
    });

    const changeGroupShellData: Record<string, ArticleEntryData> = {
      "Rights Scope": {
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableData, tableDataIndex) => {
              if (tableDataIndex === tableIndexParameter) {
                return {
                  ...tableData,
                  "Rights Scope": addRightsScopeGroupShellData,
                };
              }

              return tableData;
            },
          ),
        },
      } as ArticleEntryData,
    };

    setEntryData(changeGroupShellData[groupShellKeyParameter]);
    updateEntryData(changeGroupShellData[groupShellKeyParameter]);
    changeCurrentHistory({
      entryData: changeGroupShellData[groupShellKeyParameter],
      changedDataInfo: {
        target: "groupShell",
        action: "add",
        position: {
          groupShellKey,
          groupTableIndex,
          tableKey,
          tableIndex,
          groupTableKey,
          groupShellIndex: groupShellIndexParameter,
          subGroupShellIndex: null,
          subGroupShellKey: null,
          shellKey: null,
          shellIndex: null,
          valueIndex: null,
          value: null,
        },
      },
    });

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  };

  const clickAddRightScopeGroupShellButton = (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
    },
  ) => {
    event.stopPropagation();
    handleAddGroupShell(groupShellInfo);
  };

  const handleClearGroupShell = () => {
    const clearDealPurposeGroupShell = (): ArticleEntryData => {
      const additionalInformationTableData =
        entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL
        ];

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL]: {
            ...additionalInformationTableData,
            [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.purposeOfTheDeal]: {
              ...additionalInformationTableData?.[
                ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.purposeOfTheDeal
              ],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    const clearFinancialTermsGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;
      if (
        info.groupShellKey !== MERGE_ACQUISITION_GROUP_SHELL_KEYS.financialTerms
      )
        return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              const targetMergeData =
                tableInfo[ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms];

              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms]: {
                  ...targetMergeData,
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.otherFinancialTermsMa]:
                    {
                      ...targetMergeData[
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                          .otherFinancialTermsMa
                      ],
                      value: [],
                    },
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.mergerFinancialTermsMa]:
                    {
                      ...targetMergeData[
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                          .mergerFinancialTermsMa
                      ],
                      value: [],
                    },
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.numberOfAcquiringShares]:
                    {
                      ...targetMergeData[
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                          .numberOfAcquiringShares
                      ],
                      value: [],
                    },
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.pricePerShare]: {
                    ...targetMergeData[
                      ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.pricePerShare
                    ],
                    value: [],
                  },
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.totalPaymentMa]: {
                    ...targetMergeData[
                      ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.totalPaymentMa
                    ],
                    value: [],
                  },
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPaymentMa]: {
                    ...targetMergeData[
                      ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPaymentMa
                    ],
                    value: [],
                  },
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPricePerShareMa]:
                    {
                      ...targetMergeData[
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                          .upfrontPricePerShareMa
                      ],
                      value: [],
                    },
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumMa]:
                    {
                      ...targetMergeData[
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                          .disclosedPaymentsSumMa
                      ],
                      value: [],
                    },
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumPricePerShareMa]:
                    {
                      ...targetMergeData[
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                          .disclosedPaymentsSumPricePerShareMa
                      ],
                      value: [],
                    },
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment]:
                    tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                    ]?.[
                      ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment
                    ]?.map((subGroupShellInfo) => {
                      return {
                        ...subGroupShellInfo,
                        [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentMilestoneMa]:
                          {
                            ...subGroupShellInfo[
                              ENTRY_DATA_CONTINGENT_PAYMENT_KEYS
                                .contingentMilestoneMa
                            ],
                            value: [],
                          },
                        [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPaymentMa]:
                          {
                            ...subGroupShellInfo[
                              ENTRY_DATA_CONTINGENT_PAYMENT_KEYS
                                .contingentPaymentMa
                            ],
                            value: [],
                          },
                        [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPricePerShareMa]:
                          {
                            ...subGroupShellInfo[
                              ENTRY_DATA_CONTINGENT_PAYMENT_KEYS
                                .contingentPricePerShareMa
                            ],
                            value: [],
                          },
                      };
                    }),
                },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    const clearAcquiredCompanyGroupShell = (): ArticleEntryData => {
      const targetData =
        entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSOR_TABLE];
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSOR_TABLE]: {
            ...targetData,
            [ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorName]: {
              ...targetData?.[ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorName],
              value: [],
            },
            [ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorResponsibilityRights]: {
              ...targetData?.[
                ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorResponsibilityRights
              ],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    const clearAcquiringCompanyGroupShell = (): ArticleEntryData => {
      const targetData =
        entryData?.[ENTRY_DATA_SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSEE_TABLE];
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: targetData?.map((tableInfo) => {
            return {
              ...tableInfo,
              [ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName]: {
                ...tableInfo[ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName],
                value: [],
              },
              [ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms]: {
                ...tableInfo[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                ],
                [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.licenseResponsibilityRightsMa]:
                  {
                    ...tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                    ][
                      ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                        .licenseResponsibilityRightsMa
                    ],
                    value: [],
                  },
              },
            };
          }),
        },
      };
    };

    const clearRightScopeGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData.Deals?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope]: tableInfo[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope
                ]?.map((groupShellInfo, groupShellInfoIndex) => {
                  if (groupShellInfoIndex === info.groupShellIndex) {
                    return {
                      ...groupShellInfo,
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug]: {
                        ...groupShellInfo[ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug],
                        value: [],
                      },
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.exclusivity]: {
                        ...groupShellInfo[
                          ENTRY_DATA_RIGHTS_SCOPE_KEYS.exclusivity
                        ],
                        value: [],
                      },
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication]: {
                        ...groupShellInfo[
                          ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication
                        ],
                        value: [],
                      },
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory]: {
                        ...groupShellInfo[
                          ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory
                        ],
                        value: [],
                      },
                    };
                  }
                  return groupShellInfo;
                }),
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    const clearAdditionalInformationTable = (): ArticleEntryData => {
      const additionalInformationTableData =
        entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL
        ];
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL]: {
            ...additionalInformationTableData,
            [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.additionalInformation]:
              {
                ...additionalInformationTableData?.[
                  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS
                    .additionalInformation
                ],
                value: [],
              },
          },
        },
      } as ArticleEntryData;
    };

    if (
      info.tableKey === MERGE_ACQUISITION_TABLE_KEYS.dealPurpose &&
      info.groupShellKey === MERGE_ACQUISITION_GROUP_SHELL_KEYS.dealPurpose
    ) {
      setEntryData(clearDealPurposeGroupShell());
      changeCurrentHistory({
        entryData: clearDealPurposeGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.tableKey === MERGE_ACQUISITION_TABLE_KEYS.additionalInformation &&
      info.groupShellKey ===
        MERGE_ACQUISITION_GROUP_SHELL_KEYS.additionalInformation
    ) {
      setEntryData(clearAdditionalInformationTable());
      changeCurrentHistory({
        entryData: clearAdditionalInformationTable(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.tableKey === MERGE_ACQUISITION_TABLE_KEYS.acquiredCompany &&
      info.groupShellKey !==
        MERGE_ACQUISITION_GROUP_SHELL_KEYS.financialTerms &&
      info.groupShellKey !== MERGE_ACQUISITION_GROUP_SHELL_KEYS.rightsScope
    ) {
      setEntryData(clearAcquiredCompanyGroupShell());
      changeCurrentHistory({
        entryData: clearAcquiredCompanyGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.tableKey === MERGE_ACQUISITION_TABLE_KEYS.acquiringCompany &&
      info.groupShellKey !==
        MERGE_ACQUISITION_GROUP_SHELL_KEYS.financialTerms &&
      info.groupShellKey !== MERGE_ACQUISITION_GROUP_SHELL_KEYS.rightsScope
    ) {
      setEntryData(clearAcquiringCompanyGroupShell());
      changeCurrentHistory({
        entryData: clearAcquiringCompanyGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.groupShellKey === MERGE_ACQUISITION_GROUP_SHELL_KEYS.financialTerms
    ) {
      setEntryData(clearFinancialTermsGroupShell());
      changeCurrentHistory({
        entryData: clearFinancialTermsGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (info.groupShellKey === MERGE_ACQUISITION_GROUP_SHELL_KEYS.rightsScope) {
      const rightsScopeLength =
        entryData?.[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_LICENSEE_TABLE
        ]?.[info.tableIndex]?.[ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope]
          ?.length || 0;

      setEntryData(clearRightScopeGroupShell());
      changeCurrentHistory({
        entryData: clearRightScopeGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      if (rightsScopeLength > 1 && !checkDisabledDeleteGroupShell())
        changeFocusStatus({
          type: "delete",
          container: "groupShell",
        });
      else {
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
        changeInfo(DEFAULT_POSITION_INFO);
      }
    }
  };

  const handleDeleteGroupShell = () => {
    if (checkDisabledDeleteGroupShell()) return;

    const deleteRightScopeGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope]: tableInfo[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope
                ]?.filter(
                  (_, groupShellInfoIndex) =>
                    groupShellInfoIndex !== info.groupShellIndex,
                ),
              };
            }

            return tableInfo;
          }),
        },
      };
    };
    setEntryData(deleteRightScopeGroupShell());
    changeCurrentHistory({
      entryData: deleteRightScopeGroupShell(),
      changedDataInfo: {
        target: "groupShell",
        action: "delete",
        position: {
          groupShellKey,
          groupTableIndex,
          tableKey,
          tableIndex,
          groupTableKey,
          groupShellIndex,
          subGroupShellIndex: null,
          subGroupShellKey: null,
          shellKey: null,
          shellIndex: null,
          valueIndex: null,
          value: null,
        },
      },
    });

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  };

  const handleClickToDeleteFinancialTermsGroupShell = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShell();
    }
    if (focusStatus.type === "delete") {
      handleDeleteGroupShell();
    }
  };

  const handleKeyDownToDeleteFinancialTermsGroupShell = (
    event: KeyboardEvent,
  ) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearGroupShell();
    }
    if (focusStatus.type === "delete" && event.key === "Delete") {
      handleDeleteGroupShell();
    }
  };

  return {
    clickAddRightScopeGroupShellButton,
    handleClickToDeleteFinancialTermsGroupShell,
    handleKeyDownToDeleteFinancialTermsGroupShell,
  };
};
