import { ClassificationCode } from "@/types/code.types";
import { apiClient } from "@api/apiClient";

import {
  GetPressContentBoldKeywordResponse,
  GetPressContentCurrencyKeywordResponse,
  GetPressContentResponse,
} from "./pressContent.interface";

const API_ENDPOINT = {
  get: "des/content/by-id",
  getBoldKeyword: "des/bold-keyword/list-by-classification",
  getCurrencyKeyword: "des/bold-keyword/list-currency",
};

export const pressContentApis = {
  get: async (pressId: string) => {
    const response = await apiClient.get<GetPressContentResponse>(
      `${API_ENDPOINT.get}?id=${pressId}`,
    );

    return response;
  },
  getBoldKeyword: async (classificationCode: ClassificationCode) => {
    const response = await apiClient.get<GetPressContentBoldKeywordResponse>(
      `${API_ENDPOINT.getBoldKeyword}?classification=${classificationCode}`,
    );

    return response;
  },
  getCurrencyKeyword: async () => {
    const response =
      await apiClient.get<GetPressContentCurrencyKeywordResponse>(
        `${API_ENDPOINT.getCurrencyKeyword}`,
      );

    return response;
  },
};
