import styled from "styled-components";

export const PressTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
  padding: 0.6rem 0;
`;

export const PressTitleLabel = styled.h4`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 130%;
  color: var(--Color-Common-Text-Secondary, #515c6c);
`;

export const PressTitle = styled.h3`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  flex: 1;
  align-items: center;
  cursor: pointer;

  strong {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 145%;
    text-decoration: underline;
    color: var(--Color-Text-Interactive-Primary, #0198e9);
  }
`;
