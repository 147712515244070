import React, { HTMLAttributes } from "react";

import * as S from "./Radio.style";

interface Props extends HTMLAttributes<HTMLInputElement> {
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  checked?: boolean;
}

const Radio = ({ onChangeHandler, value, checked, ...props }: Props) => {
  return (
    <S.RadioButton
      type="radio"
      value={value}
      checked={checked}
      onChange={onChangeHandler}
      {...props}
    />
  );
};

export default Radio;
