import styled, { css } from "styled-components";

export const PressListTable = styled.table`
  width: 100%;
  background-color: ${({ theme }) => theme.color.neural.gray00};
  margin-top: 2.4rem;
  min-width: 140rem;
`;

export const PressListTableBody = styled.tbody`
  tr > td {
    position: relative;
    padding: 1rem;
    color: ${({ theme }) => theme.color.neural.gray08};
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.8rem;
    border: 0.1rem solid ${({ theme }) => theme.color.neural.gray02};
    vertical-align: top;

    &:first-child {
      width: 6.4rem;
      font-weight: 500;
      background-color: ${({ theme }) => theme.color.neural.gray01};
    }

    &:nth-child(5),
    &:nth-child(6) {
      width: 13.6rem;
    }

    &:nth-child(7) {
      width: 7.2rem;
      vertical-align: middle;
    }

    &:nth-child(2),
    &:nth-child(8),
    &:nth-child(9) {
      width: 10.4rem;
      font-weight: 600;
    }

    &:nth-child(10),
    &:nth-child(11),
    &:nth-child(12) {
      width: 8.8rem;
    }

    &:nth-child(14) {
      width: 8.8rem;
      text-align: center;
      vertical-align: middle;
    }

    &:nth-child(3) {
      font-weight: 500;
    }
  }
  tr.error-draft-row {
    background-color: ${({ theme }) => theme.color.neural.gray01};
    td,
    td > a {
      color: rgba(60, 71, 87, 0.5);

      span {
        color: rgba(60, 71, 87, 1);
      }
    }
  }
`;

export const StatusTableData = styled.td<{ code: string }>`
  text-align: center;
  button {
    display: block;
    margin: 0.6rem auto 0;
  }
`;

export const ClassificationTableData = styled.td<{
  status: string;
}>`
  color: ${({ theme }) => theme.color.neural.gray08};

  ${({ status, theme }) =>
    ["S300"].includes(status) &&
    css`
      background-color: ${theme.color.neural.gray01};
      opacity: 0.5;
    `}
`;

export const TextStatusComplete = styled.span`
  color: ${({ theme }) => theme.color.node.cyan};
  font-size: 1.2rem;
  font-weight: 700;
`;

export const TextStatusEditing = styled.span`
  color: ${({ theme }) => theme.color.node.blue};
  font-size: 1.2rem;
  font-weight: 700;
`;

export const TextStatusUnsuitable = styled.span`
  color: ${({ theme }) => theme.color.live.orange};
  font-size: 1.2rem;
  font-weight: 700;
`;

export const ColumnContainer = styled.div`
  color: ${({ theme }) => theme.color.neural.gray08};
  font-weight: 700;
  cursor: pointer;
`;

export const PublishableTableData = styled.td``;
