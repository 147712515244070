export const RESET_ALL_CHECKBOX: Record<string, boolean> = {
  ai: false,
  manual: false,
  all: false,
};

export const ARTICLE_CLASSIFICATION_CHECKBOX: Record<string, boolean> = {
  ai: false,
  manual: false,
};

export const FOR_ARTICLE_CLASSIFICATION_CODE = [
  "C000",
  "C005",
  "C006",
  "C013",
  "C016",
  "C021",
];
