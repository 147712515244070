import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_KEY_COMMENTS_GROUP_TABLE_KEY as KEY_COMMENT,
  ENTRY_DATA_KEY_COMMENTS_SHELL_KEYS as KEY_COMMENT_SHELL_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import {
  EntryDataValueInfo,
  KeyCommentSetOptions,
} from "@/types/clinicalTrials.types";
import { ArticleSectionType } from "@FillInTableContainer/types/fillInTable.types";

import {
  EXPEDITED_PROGRAM,
  EXPEDITED_PROGRAM_GROUP_KEY as eg,
  KEY_COMMENT as kc,
} from "./expeditedProgramKey.constants";
import { DefaultDataSetType } from "../components/TableCreatorListBox/types/tableCreatorDropdown.types";

export const DATA_SET_DROPDOWN_KEYS = ["Key Comment"];

export const GET_DATA_SET_DROPDOWN: Record<
  string,
  {
    [key: string]: (data: ArticleEntryData) => KeyCommentSetOptions;
  }
> = {
  "Clinical Trials": {
    "Key Comment": (data) =>
      data["Clinical Trials"]?.["Key Comment"]?.["Key Comment Set Options"] ||
      [],
  },
  Deals: {
    "Key Comment": (data) =>
      data.Deals?.["Key Comment"]?.["Key Comment Set Options"] || [],
  },
  Regulatory: {
    "Key Comment": (data) =>
      data.Regulatory?.["Key Comment"]?.["Key Comment Set Options"] || [],
  },
  [EXPEDITED_PROGRAM]: {
    [eg.keyComment]: (data) =>
      data[EXPEDITED_PROGRAM]?.[eg.keyComment]?.[kc.keCommentSetOptions] || [],
  },
  [SECTION_KEYS.ventureCapital]: {
    [KEY_COMMENT]: (data) =>
      data[SECTION_KEYS.ventureCapital]?.[KEY_COMMENT]?.[
        KEY_COMMENT_SHELL_KEYS.keyCommentSetOptions
      ] || [],
  },
};

const handleReturnDataSetEachValue = (
  updateDateSet: DefaultDataSetType,
  shellKey: string,
): EntryDataValueInfo[] => {
  return [{ text: updateDateSet[shellKey], is_modified: true }];
};

export const UPDATE_DATA_SET: Record<
  string,
  (
    articleSection: ArticleSectionType,
    data: ArticleEntryData,
    updateDataSet: DefaultDataSetType,
  ) => ArticleEntryData
> = {
  "Key Comment": (articleSection, data, updateDataSet) => {
    if (articleSection === "Clinical Trial Status") return data;

    return {
      [articleSection]: {
        ...data[articleSection],
        "Key Comment": {
          ...data[articleSection]?.["Key Comment"],
          Quote: {
            ...data[articleSection]?.["Key Comment"]?.Quote,
            value: handleReturnDataSetEachValue(updateDataSet, "Quote"),
          },
          Spokesperson: {
            ...data[articleSection]?.["Key Comment"]?.Spokesperson,
            value: handleReturnDataSetEachValue(updateDataSet, "Spokesperson"),
          },
        },
      },
    } as ArticleEntryData;
  },
};
