import React, { useEffect, useRef } from "react";

import { getGroupShellTitle } from "@/components/business/FillInTableContainer/shared/utils/getTextFromData";
import { VentureCapitalGroupTable } from "@/types/ventureCapital.types";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import {
  FillInTableTableCreatorTitleRowContainer,
  TableCreatorGroupShellTitle,
} from "@FillInTableContainer/shared";
import { useGetNumberOfShell } from "@FillInTableContainer/shared/hooks/useGetNumberOfShell";
import { isSeparatedShellTitle } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import VentureCapitalTableCreatorShell from "./components/VentureCapitalTableCreatorShell/VentureCapitalTableCreatorShell";
import { useHandleVentureCapitalGroupShell } from "./hooks/useHandleVentureCapitalGroupShell";
import * as S from "./VentureCapitalTableCreatorGroupShell.style";

import type { TableCreatorShellList } from "@FillInTableContainer/shared/types/tableCreator.types";

interface Props {
  groupTableKey: VentureCapitalGroupTable;
  groupTableIndex: number;
  groupShellKey: string | null;
  shellList: TableCreatorShellList;
  tableKey: string | null;
  tableIndex: number;
  groupShellIndex: number;
  groupShellCount: number;
  isTableScroll: boolean;
}

const VentureCapitalTableCreatorGroupShell = ({
  groupTableKey,
  groupTableIndex,
  groupShellKey,
  shellList,
  tableKey,
  tableIndex,
  groupShellIndex,
  groupShellCount,
  isTableScroll,
}: Props) => {
  const positionPropsInfo = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  };

  const titleRef = useRef<HTMLDivElement>(null);
  const selectButtonRef = useRef<HTMLDivElement>(null);
  const { shellCount } = useGetNumberOfShell();
  const articleSection = useArticleSection();
  const {
    focusStatus,
    isFocused,
    isClearGroupShell,
    isDeleteGroupShell,
    isAlertGroupShell,
    isEnableEdit,
    handleDefaultFocus,
    handleClickToDeleteGroupShell,
    handleFocusGroupShell,
  } = useHandleVentureCapitalGroupShell({
    positionPropsInfo,
    groupShellCount,
  });

  useEffect(() => {
    const handleCheckClickOutsideShell = (event: MouseEvent) => {
      if (isFocused) {
        const target = event.target as HTMLElement;
        const clickSelectButton = selectButtonRef.current?.contains(target);
        const clickGroupShellTitle = titleRef.current?.contains(target);

        if (clickSelectButton || clickGroupShellTitle) return;

        handleDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleDefaultFocus, isDeleteGroupShell, isFocused]);

  return (
    <S.GroupShellOutsideWrapper
      isFocused={isFocused}
      isClearGroupShell={isClearGroupShell}
      isDeleteGroupShell={isDeleteGroupShell}
      isAlertGroupShell={isAlertGroupShell}
    >
      {isSeparatedShellTitle(articleSection, groupShellKey) &&
        groupShellIndex === 0 && (
          <FillInTableTableCreatorTitleRowContainer
            positionPropsInfo={positionPropsInfo}
            shellList={shellList}
            isTableScroll={isTableScroll}
          />
        )}

      {(isClearGroupShell || isDeleteGroupShell) && isEnableEdit && (
        <S.FixedDeleteButtonWrapper
          isShellTitle={!!getGroupShellTitle(articleSection, groupShellKey)}
        >
          <S.DeleteTableButtonWrapper>
            <S.DeleteTableButton
              data-button="delete-button"
              onClick={handleClickToDeleteGroupShell}
            >
              <img src={IconXMark} alt="button for clear Row" />
              {focusStatus.type === "clear"
                ? `Clear ${
                    [
                      "Clinical Endpoints (Table)",
                      "Clinical Trial Design-2",
                    ].includes(groupTableKey)
                      ? "Row"
                      : ""
                  }`
                : null}
              {focusStatus.type === "delete"
                ? `Delete ${
                    [
                      "Clinical Endpoints (Table)",
                      "Clinical Trial Design-2",
                    ].includes(groupTableKey)
                      ? "Row"
                      : ""
                  }`
                : null}
            </S.DeleteTableButton>
          </S.DeleteTableButtonWrapper>
        </S.FixedDeleteButtonWrapper>
      )}

      <S.GroupShellWrapper>
        <S.TitleWrapper ref={titleRef}>
          {getGroupShellTitle(articleSection, groupShellKey) && (
            <TableCreatorGroupShellTitle
              groupShellKey={groupShellKey}
              groupShellCount={groupShellCount}
              groupShellIndex={groupShellIndex}
              isAlertGroupShell={isAlertGroupShell}
              onClickHandler={handleFocusGroupShell}
            />
          )}
        </S.TitleWrapper>
        <S.SelectWrapper>
          <S.GroupShellSelectButtonDividerWrapper
            ref={selectButtonRef}
            onClick={handleFocusGroupShell}
            isPossibleSelected
          >
            <S.GroupShellSelectButtonDivider className="select-button divider" />
            <S.GroupShellSelectButton className="select-button button">
              SELECT
            </S.GroupShellSelectButton>
          </S.GroupShellSelectButtonDividerWrapper>
          <S.GroupShell>
            {shellList.map(({ shellKey, subShellList }, shellListIndex) => (
              <React.Fragment key={`shellList-${String(shellListIndex)}`}>
                {newArrayToCountValue(
                  shellCount(shellKey, tableIndex, groupShellIndex),
                ).map((_, shellIndex) => (
                  <VentureCapitalTableCreatorShell
                    key={`shell-${String(groupTableIndex)}-${String(
                      tableIndex,
                    )}-${String(groupShellIndex)}-${String(shellIndex)}`}
                    groupTableKey={groupTableKey}
                    groupTableIndex={groupTableIndex}
                    groupShellKey={groupShellKey}
                    groupShellIndex={groupShellIndex}
                    shellKey={shellKey}
                    subShellList={subShellList}
                    shellIndex={shellIndex}
                    tableKey={tableKey}
                    tableIndex={tableIndex}
                    shellCount={shellCount(
                      shellKey,
                      tableIndex,
                      groupShellIndex,
                    )}
                    isShellOverOne={
                      shellCount(shellKey, tableIndex, groupShellIndex) > 1
                    }
                    isSeparatedShellTitle={isSeparatedShellTitle(
                      articleSection,
                      groupShellKey,
                    )}
                    isClearGroupShell={isClearGroupShell}
                    isDeleteGroupShell={isDeleteGroupShell}
                    isAlertGroupShell={isAlertGroupShell}
                  />
                ))}
              </React.Fragment>
            ))}
          </S.GroupShell>
        </S.SelectWrapper>
      </S.GroupShellWrapper>
    </S.GroupShellOutsideWrapper>
  );
};

export default VentureCapitalTableCreatorGroupShell;
