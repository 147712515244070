import styled, { css } from "styled-components";

import type { RequiredType } from "./RequiredMark";

export const RequiredMark = styled.span<{ type: RequiredType }>`
  font-size: 1.2rem;
  font-weight: 600;

  ${({ type }) => {
    if (type === "required") {
      return css`
        color: var(--accent-color-spectrum-live-orange, #fb6e52);
      `;
    }

    if (type === "conditional") {
      return css`
        color: ${({ theme }) => theme.color.bio.blueGreen06};
      `;
    }
  }}
`;
