import React, { useState, useRef, useEffect } from "react";

import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@/components/business/FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { ClinicalTrialsSection } from "@/types/clinicalTrials.types";
import { DATA_FOR_ADDING_TABLE_TITLES } from "@FillInTableContainer/shared/constants/tableCreatorTable.constants";
import { isTableCustomTitle } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import * as S from "./TableCreatorTableCustomTitleInput.style";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface Props {
  customTitle: string;
  changeInfoToCurrentTable: () => void;
  isClearTable: boolean;
}

const TableCreatorTableCustomTitleInput = ({
  customTitle,
  isClearTable,
  changeInfoToCurrentTable,
}: Props) => {
  const customTitleRef = useRef<HTMLInputElement>(null);
  const articleSection = "Clinical Trials";

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const [enterTitle, setEnterTitle] = useState<string>(customTitle);
  const handleEnterTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeInfoToCurrentTable();
    setEnterTitle(event.currentTarget.value);
  };

  const handleChangeTableTitleData = () => {
    const currentEntryData = DATA_FOR_ADDING_TABLE_TITLES?.[articleSection];

    const changedEntryData: ArticleEntryData = currentEntryData?.[
      info.groupTableKey as ClinicalTrialsSection
    ](entryData, info.tableIndex, enterTitle);

    setEntryData(changedEntryData);
  };

  const handleSavedTitle = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (info.groupTableKey === "") return;

    if (!isTableCustomTitle(articleSection, info.groupTableKey)) return;

    if (event.key !== "Enter") return;

    handleChangeTableTitleData();

    if (customTitleRef.current) customTitleRef.current.blur();

    changeInfo(DEFAULT_POSITION_INFO);
  };

  const handleFocusoutTitleInput = () => {
    if (info.groupTableKey === "") return;

    if (!isTableCustomTitle(articleSection, info.groupTableKey)) return;

    handleChangeTableTitleData();

    changeInfo(DEFAULT_POSITION_INFO);
  };
  useEffect(() => {
    if (customTitleRef.current) customTitleRef.current.value = customTitle;
  }, [customTitle]);

  return (
    <S.TitleBackground>
      <S.CustomTitleWrapper
        isClearTable={isClearTable && !!customTitleRef.current?.value}
      >
        <S.CustomTitleInput
          ref={customTitleRef}
          placeholder="Table Title"
          data-title="custom-title"
          defaultValue={customTitle}
          onChange={handleEnterTitle}
          onKeyDown={handleSavedTitle}
          onBlur={handleFocusoutTitleInput}
        />
      </S.CustomTitleWrapper>
    </S.TitleBackground>
  );
};

export default TableCreatorTableCustomTitleInput;
