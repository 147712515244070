import React from "react";

type Props = {
  color?: string;
  width?: number;
  height?: number;
};

const IconArrowVerticalSplit = ({
  color = "#515C6C",
  width = 16,
  height = 16,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29167 3.33334C7.29167 3.08055 7.13939 2.85265 6.90584 2.75591C6.6723 2.65917 6.40347 2.71264 6.22473 2.89139L3.55806 5.55806C3.31398 5.80214 3.31398 6.19787 3.55806 6.44194C3.80214 6.68602 4.19786 6.68602 4.44194 6.44194L6.04167 4.84222V12.6667C6.04167 13.0118 6.32149 13.2917 6.66667 13.2917C7.01185 13.2917 7.29167 13.0118 7.29167 12.6667V3.33334ZM8.70833 12.6667C8.70833 12.9195 8.86061 13.1474 9.09416 13.2441C9.3277 13.3408 9.59653 13.2874 9.77528 13.1086L12.4419 10.4419C12.686 10.1979 12.686 9.80214 12.4419 9.55806C12.1979 9.31398 11.8021 9.31398 11.5581 9.55806L9.95833 11.1578V3.33334C9.95833 2.98816 9.67851 2.70834 9.33333 2.70834C8.98816 2.70834 8.70833 2.98816 8.70833 3.33334V12.6667Z"
        fill={color}
      />
    </svg>
  );
};

IconArrowVerticalSplit.displayName = "ArrowVerticalSplit";
export default IconArrowVerticalSplit;
