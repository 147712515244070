import React, { ForwardedRef, forwardRef } from "react";

import * as S from "./DBFilterSearchInput.style";

interface Props {
  onKeyUpHandler?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocusHandler: (state: boolean) => void;
  onBlurHandler?: (state: boolean) => void;
  onChangeHandler?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}
const DBFilterSearchInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      onKeyUpHandler,
      onFocusHandler,
      onBlurHandler,
      onChangeHandler,
      placeholder,
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <S.SearchInput
        ref={ref}
        onKeyUp={onKeyUpHandler}
        onFocus={() => onFocusHandler(true)}
        onBlur={() => onBlurHandler && onBlurHandler(false)}
        onChange={onChangeHandler}
        placeholder={placeholder}
      />
    );
  },
);

export default DBFilterSearchInput;
