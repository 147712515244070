import { apiClient } from "@api/apiClient";

import {
  GetAiImageInfoResponse,
  GetAiImageListResponse,
  GetCreateAIImageResponse,
} from "./aiImages.interface";

const API_ENDPOINTS = {
  create: "/des/ai-image/create",
  getImages: "/des/ai-image/list",
  checkCreateImage: "/des/ai-image/data",
};
export const aiImagesApis = {
  create: async (articleId: string) => {
    const url = `${API_ENDPOINTS.create}?draftArticleId=${articleId}`;
    const response = await apiClient.get<GetCreateAIImageResponse>(url);

    return response.data;
  },
  getImages: async (articleId: string) => {
    const url = `${API_ENDPOINTS.getImages}?draftArticleId=${articleId}`;
    const response = await apiClient.get<GetAiImageListResponse>(url);

    return response.data;
  },
  checkCreateImage: async (articleId: string) => {
    // DESCRIPTION : 이미지 생성 API를 호출했는 지 확인하는 API
    const url = `${API_ENDPOINTS.checkCreateImage}?draftArticleId=${articleId}`;
    const response = await apiClient.get<GetAiImageInfoResponse>(url);

    return response.data;
  },
};
