import { TableTitle } from "@frontend/common";

import { ClinicalTrialDesignText } from "@EditArticleContainer/shared/types/paragraphContents/contents/clinicalTrialDesignContent.types";
import { padStart } from "@utils/padStart/padStart";

import * as S from "./ClinicalTrialDesignGroupTableContainer.style";
import { ClinicalTrialDesign } from "../../utils/clinicalTrialDesign";
import ClinicalTrialDesign1Table from "../ClinicalTrialDesign1Table/ClinicalTrialDesign1Table";
import ClinicalTrialsDesign2TableContainer from "../ClinicalTrialsDesign2TableContainer/ClinicalTrialsDesign2TableContainer";

type Props = {
  designGroupTableData: ClinicalTrialDesignText;
  designGroupTableIndex: number;
  designGroupTableLength: number;
};

const GROUP_TABLE_TITLE_STYLE = {
  height: "3.3rem",
};

const getClinicalTrialDesignGroupTableTitle = (index: number) => {
  return `Clinical Trial ${padStart(index + 1)} Design`;
};

const ClinicalTrialDesignGroupTableContainer = ({
  designGroupTableData,
  designGroupTableIndex,
  designGroupTableLength,
}: Props) => {
  const clinicalTrialDesign = new ClinicalTrialDesign(designGroupTableData);
  const clinicalTrialDesign1 = clinicalTrialDesign.getClinicalTrialDesign1();
  const clinicalTrialDesign2 = clinicalTrialDesign.getClinicalTrialDesign2();
  return (
    <>
      {designGroupTableLength > 1 && (
        <TableTitle
          title={getClinicalTrialDesignGroupTableTitle(designGroupTableIndex)}
          style={GROUP_TABLE_TITLE_STYLE}
        />
      )}
      <S.ParagraphClinicalTrialDesignContentWrapper disabled={false}>
        <ClinicalTrialDesign1Table
          clinicalTrialDesign1={clinicalTrialDesign1.data}
          designGroupTableIndex={designGroupTableIndex}
        />

        <ClinicalTrialsDesign2TableContainer
          clinicalTrialDesign2={clinicalTrialDesign2.data}
          designGroupTableIndex={designGroupTableIndex}
        />
      </S.ParagraphClinicalTrialDesignContentWrapper>
    </>
  );
};

export default ClinicalTrialDesignGroupTableContainer;
