"use client";

import styled, { css } from "styled-components";

export const DBDetailValueShell = styled.div<{
  $width?: string;
  $justifyContent?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  font-family: var(--font-pt-serif);
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 145%;
  background: var(--NEURAL-Gray-010, #fff);
  padding: 0.8rem;
  white-space: pre-wrap;
  border-right: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);

  ${({ $justifyContent }) =>
    $justifyContent &&
    css`
      justify-content: ${$justifyContent};
    `};

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
      max-width: ${$width};
    `};

  i {
    font-style: italic;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 165%;
  }

  b {
    font-weight: 600;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
  }

  sup {
    vertical-align: super;
    font-size: 1rem;
  }

  sub {
    vertical-align: sub;
    font-size: 1rem;
  }
`;
