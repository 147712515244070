import { AUTHORITY_TYPE } from "@libs/roles/constants/authorityType.constants";
import { AuthorityType } from "@libs/roles/types/roles.types";

interface Args {
  ftEditorName: string | null;
  authorityType: AuthorityType;
  hasLock: boolean | null;
}

export class FillInTableConcurrentAccessChecker {
  private ftEditorName: string | null;

  private authorityType: AuthorityType;

  private hasLock: boolean | null;

  constructor({ ftEditorName, authorityType, hasLock }: Args) {
    this.ftEditorName = ftEditorName;
    this.authorityType = authorityType;
    this.hasLock = hasLock;
  }

  private get isSuperAdmin(): boolean {
    return this.authorityType === AUTHORITY_TYPE.superAdmin;
  }

  private get emptyFtEditorName(): boolean {
    return !this.ftEditorName;
  }

  public get canAccess(): boolean {
    if (this.hasLock === null) {
      return true;
    }

    return this.isSuperAdmin || !this.emptyFtEditorName || !!this.hasLock;
  }
}
