import { ClassificationCode, ProcessStatusCode } from "@/types/code.types";

import { EDITOR_ROLES } from "../constants/editorRoles.constants";
import { FILL_IN_TABLE_MOVE_NEXT_ROLES_LIST } from "../constants/fillInTableRolesList.constants";
import { CLASSIFICATION_KEYS_CODE } from "../constants/returnRolesList.constants";
import { EditorRoleCode } from "../types/roles.types";

export const checkInsertImagePageAccessRoles = (
  isSuperAdmin: boolean,
  editorRoles: EditorRoleCode[],
  status: ProcessStatusCode,
  classification?: ClassificationCode | "",
) => {
  const checkEditorRolesAccess = (role: EditorRoleCode) => {
    return (
      editorRoles.includes(role) &&
      FILL_IN_TABLE_MOVE_NEXT_ROLES_LIST[role].includes(status)
    );
  };

  if (!classification) return false;
  if (isSuperAdmin) return true;

  const handleStatusClassificationMatch = () => {
    switch (classification) {
      case CLASSIFICATION_KEYS_CODE.clinicalTrials:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0204);
      case CLASSIFICATION_KEYS_CODE.clinicalTrialStatus:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0304);
      case CLASSIFICATION_KEYS_CODE.posterContents:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0404);
      case CLASSIFICATION_KEYS_CODE.deals:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0504);
      case CLASSIFICATION_KEYS_CODE.expeditedProgram:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0604);
      case CLASSIFICATION_KEYS_CODE.approvalProcess:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0704);
      case CLASSIFICATION_KEYS_CODE.ventureCapital:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0804);
      default:
        return false;
    }
  };

  return handleStatusClassificationMatch();
};
