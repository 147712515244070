"use client";

import styled from "styled-components";

export const SwapButton = styled.button`
  display: flex;
  height: 2.2rem;
  padding: 0 var(--Spacing-8, 0.8rem) 0 var(--Spacing-4, 0.4rem);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-0, 0rem);
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: var(--Border-Width-1, 1px) solid
    var(--Color-Common-Border-Interactive-Secondary, #b1b8c4);
  background: var(--Color-Common-Bg-Interactive-Secondary, #fff);
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 130%; /* 1.56rem */
  cursor: pointer;

  &:hover {
    color: #3d4551;
    border: 1px solid
      var(--Color-Common-Border-Interactive-Secondary-Hovered, #96a1b0);
    background: var(--Color-Common-Bg-Interactive-Secondary-Hovered, #f0f2f4);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);

    svg path {
      fill: #515c6c;
    }
  }
  &:active {
    color: #2a2f37;
    border: 1px solid
      var(--Color-Common-Border-Interactive-Secondary-Pressed, #7c899c);
    background: var(--Color-Common-Bg-Interactive-Secondary-Pressed, #e5e7eb);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset,
      0px 0px 2px 0px rgba(0, 0, 0, 0.06) inset;

    svg path {
      fill: #2a2f37;
    }
  }
`;
