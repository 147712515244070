import React from "react";

import * as S from "./UpdateButton.style";

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const UpdateButton = ({ ...rest }: Props) => {
  return <S.UpdateButton {...rest} tabIndex={-1} />;
};

export default UpdateButton;
