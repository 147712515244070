import React, { createContext, useContext, useMemo, useState } from "react";

export interface FocusStatus {
  type: "default" | "clear" | "delete" | "alert" | "focusInput" | "dragging";
  container:
    | "groupTable"
    | "table"
    | "groupShellSection"
    | "groupShell"
    | "subGroupShell"
    | "column"
    | "shell"
    | "value"
    | "textField"
    | "noFocus"
    | "checkbox";
}

export const DEFAULT_FOCUS_STATUS: FocusStatus = {
  type: "default",
  container: "noFocus",
};

export const TableCreatorFocusStatusContext = createContext<{
  focusStatus: FocusStatus;
  changeFocusStatus: (newStatus: FocusStatus) => void;
}>({
  focusStatus: DEFAULT_FOCUS_STATUS,
  changeFocusStatus: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const TableCreatorFocusStatusProvider = ({ children }: Props) => {
  const [focusStatus, setFocusStatus] =
    useState<FocusStatus>(DEFAULT_FOCUS_STATUS);

  const changeFocusStatus = (newStatus: FocusStatus): void => {
    setFocusStatus(newStatus);
  };

  const focusStatusWrapper = useMemo(
    () => ({ focusStatus, changeFocusStatus }),
    [focusStatus],
  );
  return (
    <TableCreatorFocusStatusContext.Provider value={focusStatusWrapper}>
      {children}
    </TableCreatorFocusStatusContext.Provider>
  );
};

export const useTableCreatorFocusStatus = () => {
  const { focusStatus, changeFocusStatus } = useContext(
    TableCreatorFocusStatusContext,
  );

  return { focusStatus, changeFocusStatus };
};
