import { setSentryUser } from "@libs/sentry/sentryUser";
import { setCookie } from "@utils/cookie";
import tokenHelper, { expiredCookieDate } from "@utils/tokenHelper";

interface Args {
  accessToken: string;
  refreshToken: string;
  accountName: string;
  accountEmail: string;
  checkedRememberMe?: boolean;
}

export const setAuthorization = async ({
  accessToken,
  refreshToken,
  accountName,
  accountEmail,
  checkedRememberMe = false,
}: Args) => {
  tokenHelper.setAccessToken(accessToken, !checkedRememberMe);
  tokenHelper.setRefreshToken(refreshToken, !checkedRememberMe);

  setSentryUser(accountEmail);
  setCookie("accountEmail", accountEmail, {
    path: "/",
    expires: !checkedRememberMe ? undefined : expiredCookieDate(), // 구체적인 시간이 정해져있지 않아서 1일로 설정
  });
  setCookie("accountName", accountName);
};
