import React from "react";

import PressLabelViewer from "@/components/shared/ui/PressLabelViewer/PressLabelViewer";
import { usePressInfoQuery } from "@/queries/hooks/usePressInfo";
import { getArticleInfoData } from "@api/articleInfo/useArticleInfo";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { parseJSON } from "@utils/parseJSON";

import * as S from "./FillInTablePress.style";
import { removeLineBreaksCopyText } from "./utils/removeLineBreaksCopyText/removeLineBreaksCopyText";

import type { ParsedJsonContentType } from "@/types/fillInTableContent.types";

interface Props {
  columnLabelViewerSize?: {
    width: number;
    y: number;
  };
}

const FillInTablePress = ({ columnLabelViewerSize }: Props) => {
  const draftArticleId = Number(getPressIdFromURL());
  const { data: pressInfo } = usePressInfoQuery(draftArticleId);
  const articleInfo = getArticleInfoData(draftArticleId);
  const classificationManual = articleInfo?.data.classificationManual || "";

  if (!pressInfo) return null;
  const rawPressContent = pressInfo.pressContent;
  const pressContent: ParsedJsonContentType[] = parseJSON(rawPressContent);

  return (
    <S.PressLabelViewerContainer onCopy={removeLineBreaksCopyText}>
      <PressLabelViewer
        parsedContentArray={pressContent}
        draftArticleId={draftArticleId}
        showLabelTooltip
        columnLabelViewerWidth={columnLabelViewerSize?.width}
        columnLabelViewerTop={columnLabelViewerSize?.y}
        classificationManual={classificationManual}
      />
    </S.PressLabelViewerContainer>
  );
};

export default FillInTablePress;
