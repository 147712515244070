"use client";

import styled from "styled-components";

export const TooltipWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  height: 2.4rem;
`;

export const InfoButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 2.4rem;
  padding: 0;

  &:hover {
    & + .message-container {
      display: flex;
    }
  }
`;

export const MessageWrapper = styled.div`
  position: absolute;
  left: 0.2rem;
  top: 100%;
  z-index: 20;

  display: none;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-4, 0.4rem);
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Primary, #fff);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  padding: var(--Spacing-4, 0.4rem) var(--Spacing-8, 0.8rem);

  @media only screen and (min-width: 1px) and (max-width: 470px) {
    left: initial;
    right: 0;
    width: min-content;
  }
`;

export const MessageContentsWrapper = styled.div`
  width: fit-content;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
`;
