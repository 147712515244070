"use client";

import React, { useId, useState } from "react";

import * as S from "./SignFormPasswordInput.style";
import { IconEye, IconEyeOff } from "../../../shared/Icons";
import { theme } from "../../../styles/theme";
import { NeuralColors } from "../types/themeColor.types";
import {
  FontSizes,
  FontTextAlign,
  FontWeights,
} from "../types/typography.types";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  inputColor?: NeuralColors;
  inputSize?: FontSizes;
  inputWeight?: FontWeights;
  inputAlign?: FontTextAlign;
  lineColor?: "gray" | "blue";
  disabled?: boolean;
  password?: string;
  isError?: boolean;
}

const lineColorMap = {
  gray: `var(--Color-Common-Border-Secondary, #B1B8C4)`,
  blue: theme.color.node.sky,
  null: "transparent",
};

const PasswordInput = React.forwardRef(
  (
    {
      label,
      inputColor = "gray08",
      inputSize = "14",
      inputWeight = "400",
      inputAlign = "center",
      lineColor = "gray",
      className: labelClassName,
      password,
      isError,
      ...rest
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const [inputType, setInputType] = useState<typeof rest.type>("password");
    const inputId = useId();

    const handleShowButtonClick = () => {
      if (inputType === "password") setInputType("text");
      else setInputType("password");
    };

    return (
      <S.LabelWrapper htmlFor={inputId}>
        <div>
          <S.LabelTextWrapper>
            {label && (
              <S.LabelText className={`${labelClassName} input-label`}>
                {label}
              </S.LabelText>
            )}
          </S.LabelTextWrapper>
        </div>
        <S.InputWrapper
          $lineColor={lineColorMap[lineColor]}
          $isError={!!isError}
        >
          <S.Input
            $inputColor={inputColor}
            $inputSize={inputSize}
            $inputWeight={inputWeight}
            $inputAlign={inputAlign}
            {...rest}
            ref={ref}
            id={inputId}
            type={inputType}
          />
          <S.ShowPasswordButton
            onClick={handleShowButtonClick}
            type="button"
            tabIndex={-1}
            hidden={!password}
          >
            {inputType === "password" ? (
              <IconEye width={20} height={20} color="#96A1B0" />
            ) : (
              <IconEyeOff width={20} height={20} color="#96A1B0" />
            )}
          </S.ShowPasswordButton>
        </S.InputWrapper>
      </S.LabelWrapper>
    );
  },
);

export default PasswordInput;
