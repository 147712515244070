import styled from "styled-components";

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${({ theme }) => theme.color.neural.gray03};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 201;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4.8rem;
  display: flex;
  width: 76rem;
  flex-shrink: 0;
`;

export const ExcludeIcon = styled.img`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.4rem;
  align-self: stretch;
`;
export const ErrorTitle = styled.h1`
  color: ${({ theme }) => theme.color.neural.gray08};
  font-size: 6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3;
`;
export const ErrorStatus = styled.p`
  color: ${({ theme }) => theme.color.neural.gray06};
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7.2rem;
  align-items: flex-end;
  align-self: stretch;
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.color.neural.gray09};
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  white-space: pre-line;
`;

export const ErrorDetails = styled.p`
  color: ${({ theme }) => theme.color.neural.gray09};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  white-space: pre-line;
`;

export const ActionButton = styled.button`
  display: flex;
  padding: 1.2rem 6.4rem 1.4rem 6.4rem;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  border-radius: 0.8rem;
  background: ${({ theme }) => theme.color.node.sky};
  box-shadow: 0px 4px 8px 0px rgba(7, 68, 161, 0.02);
  color: ${({ theme }) => theme.color.neural.gray01};
  cursor: pointer;
`;
