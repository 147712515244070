import { useCallback } from "react";

import {
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_SECTION_KEYS,
} from "@frontend/common";

import {
  DEFAULT_FOCUS_STATUS,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { TableInfoProps } from "../ClinicalTrialDesignTable";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorTableReturn {
  deleteClinicalTrialDesignTable: () => void;
}

export const useDeleteClinicalTrialsClinicalTrialDesignTable = (
  tablePropsInfo: TableInfoProps,
): UseHandleTableCreatorTableReturn => {
  const { groupTableKey, tableKey, groupTableIndex, tableIndex } =
    tablePropsInfo;

  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeFocusStatus } = useTableCreatorFocusStatus();

  const clinicalTrialDesignTableData =
    entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
      ENTRY_DATA_CLINICAL_TRIAL_DESIGN
    ];

  const clinicalTrialDesignTableLength =
    clinicalTrialDesignTableData?.length || 0;

  const deleteClinicalTrialDesignTable = useCallback(() => {
    if (clinicalTrialDesignTableLength > 1) {
      changeFocusStatus(DEFAULT_FOCUS_STATUS);

      const deleteDrugProfile = (): ArticleEntryData => {
        return {
          ...entryData,
          [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
            ...entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
            [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: entryData[
              ENTRY_DATA_SECTION_KEYS.clinicalTrial
            ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.filter(
              (tableInfo, tableInfoIndex) => tableInfoIndex !== info.tableIndex,
            ),
          },
        };
      };
      if (info.tableKey === ENTRY_DATA_CLINICAL_TRIAL_DESIGN) {
        setEntryData(deleteDrugProfile());
        changeCurrentHistory({
          entryData: deleteDrugProfile(),
          changedDataInfo: {
            target: "table",
            action: "delete",
            position: {
              groupTableKey,
              groupTableIndex,
              tableKey,
              tableIndex,
              groupShellKey: null,
              groupShellIndex: null,
              shellKey: null,
              shellIndex: null,
              valueIndex: null,
              value: null,
            },
          },
        });
        changeInfo(DEFAULT_POSITION_INFO);
      }
    }
  }, [
    clinicalTrialDesignTableLength,
    entryData,
    groupTableIndex,
    groupTableKey,
    info,
    tableIndex,
    tableKey,
    setEntryData,
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
  ]);

  return {
    deleteClinicalTrialDesignTable,
  };
};
