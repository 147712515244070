import { TableTypeKey } from "../../types/edbMain/tableType.types";

type DefaultType = {
  [key in TableTypeKey]: {
    [k in string]: {
      maxWidth: string;
      minWidth: string;
    };
  };
};

export const DRUG_DB_WIDTH = {
  drugName: {
    maxWidth: "14.8rem",
    minWidth: "10rem",
  },
  otherDrugNames: {
    maxWidth: "14.8rem",
    minWidth: "10rem",
  },
  companyName: {
    maxWidth: "24.6rem",
    minWidth: "12.4rem",
  },
  modality: {
    maxWidth: "16.4rem",
    minWidth: "10.8rem",
  },
  target: {
    maxWidth: "16.4rem",
    minWidth: "10.8rem",
  },
  moa: {
    maxWidth: "24.6rem",
    minWidth: "12.4rem",
  },
  roa: {
    maxWidth: "16.4rem",
    minWidth: "10.8rem",
  },
  targetIndicationDisplay: {
    maxWidth: "24.6rem",
    minWidth: "12.8rem",
  },
  genericBiosimilar: {
    maxWidth: "8.4rem",
    minWidth: "8.4rem",
  },
  therapeuticsArea: {
    maxWidth: "16.4rem",
    minWidth: "10.8rem",
  },
  stage: {
    maxWidth: "10.4rem",
    minWidth: "7.2rem",
  },
};

export const DEALS_TABLE_WIDTH = {
  pressDate: {
    maxWidth: "8.8rem",
    minWidth: "6.4rem",
  },
  dealType: {
    maxWidth: "16.4rem",
    minWidth: "12rem",
  },
  seller: {
    maxWidth: "45rem",
    minWidth: "20rem",
  },
  buyer: {
    maxWidth: "45rem",
    minWidth: "20rem",
  },
  companies: {
    maxWidth: "90rem",
    minWidth: "40rem",
  },
  rightsScopes: {
    maxWidth: "23.2rem",
    minWidth: "16.4rem",
  },
  totalPaymentDollars: {
    maxWidth: "24.8rem",
    minWidth: "26.4rem",
  },
  highlightLabel: {
    maxWidth: "32rem",
    minWidth: "20rem",
  },
};

export const DRUG_APPROVAL_TABLE_WIDTH = {
  updateDate: {
    maxWidth: "12rem",
    minWidth: "9.2rem",
  },
  licenseHolder: {
    maxWidth: "24rem",
    minWidth: "17rem",
  },
  drugName: {
    maxWidth: "24rem",
    minWidth: "11.6rem",
  },
  otherDrugNames: {
    maxWidth: "24rem",
    minWidth: "11.6rem",
  },
  regulatoryAgency: {
    maxWidth: "12rem",
    minWidth: "8rem",
  },
  applicationType: {
    maxWidth: "12rem",
    minWidth: "8rem",
  },
  marketingStatus: {
    maxWidth: "14.4rem",
    minWidth: "12.2rem",
  },
  conditionIndication: {
    maxWidth: "656rem",
    minWidth: "38.4rem",
  },
};
export const EXPEDITED_PROGRAM_TABLE_WIDTH = {
  updateDate: {
    maxWidth: "12rem",
    minWidth: "9.2rem",
  },
  designatedCompany: {
    maxWidth: "24rem",
    minWidth: "17rem",
  },
  drugName: {
    maxWidth: "30rem",
    minWidth: "11.6rem",
  },
  otherDrugNames: {
    maxWidth: "30rem",
    minWidth: "11.6rem",
  },
  agency: {
    maxWidth: "12rem",
    minWidth: "8.8rem",
  },
  expeditedProgram: {
    maxWidth: "18rem",
    minWidth: "14rem",
  },
  designationStatus: {
    maxWidth: "16rem",
    minWidth: "11.8rem",
  },
  designatedTreatment: {
    maxWidth: "46rem",
    minWidth: "32rem",
  },
};
export const GUIDELINE_TABLE_WIDTH = {
  fdaDate: {
    maxWidth: "12rem",
    minWidth: "9.2rem",
  },
  topic: {
    maxWidth: "27.6rem",
    minWidth: "15.2rem",
  },
  agency: {
    maxWidth: "10.4rem",
    minWidth: "9.2rem",
  },
  guidance: {
    maxWidth: "123.8rem",
    minWidth: "70.4rem",
  },
  status: {
    maxWidth: "14rem",
    minWidth: "12rem",
  },
};
export const CLINICAL_TRIALS_DB_WIDTH = {
  studyTitle: {
    maxWidth: "auto",
    minWidth: "auto",
  },
  nctNumber: {
    maxWidth: "10.4rem",
    minWidth: "10.4rem",
  },
  studyStatus: {
    maxWidth: "10.4rem",
    minWidth: "10.4rem",
  },
  inclusionCriteriaDiagnostic: {
    maxWidth: "24rem",
    minWidth: "auto",
  },
  interventions: {
    maxWidth: "29.6rem",
    minWidth: "auto",
  },
  sponsor: {
    maxWidth: "14.4rem",
    minWidth: "14.4rem",
  },
  normalizedPhase: {
    maxWidth: "7.2rem",
    minWidth: "8.8rem",
  },
};
export const TABLE_WIDTH: DefaultType = {
  drug: DRUG_DB_WIDTH,
  deals: DEALS_TABLE_WIDTH,
  drugApproval: DRUG_APPROVAL_TABLE_WIDTH,
  expeditedProgram: EXPEDITED_PROGRAM_TABLE_WIDTH,
  guideline: GUIDELINE_TABLE_WIDTH,
  clinicalTrials: CLINICAL_TRIALS_DB_WIDTH,
};
