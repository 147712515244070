import {
  REF_CODE_ENDPOINTS_TABLE_GROUP_DATA,
  REF_CODE_ENDPOINTS_TABLE_GROUP_NAME,
  REF_CODE_ENDPOINTS_TABLE_MEASUREMENT_TIMING,
  REF_CODE_ENDPOINTS_TABLE_NOTES,
  REF_CODE_ENDPOINTS_TABLE_TITLE,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createRefCode } from "@EditArticleContainer/shared/libs/refCode/utils/createRefCode";
import { ClinicalEndpointsTableData } from "@EditArticleContainer/shared/types/paragraphContents/contents/clinicalResultsContent.types";

export class ClinicalEndpointsTable {
  private static combineGroupData = (groupData: string, pValue?: string) =>
    pValue ? `${groupData}\n(${pValue})` : groupData;

  private clinicalEndpointsTableData: ClinicalEndpointsTableData;

  private tableIndex: number;

  constructor(
    clinicalEndpointsTableData: ClinicalEndpointsTableData,
    index = 0,
  ) {
    this.clinicalEndpointsTableData = clinicalEndpointsTableData;
    this.tableIndex = index;
  }

  public get data() {
    return this.clinicalEndpointsTableData;
  }

  public get title() {
    return {
      key: createRefCode(REF_CODE_ENDPOINTS_TABLE_TITLE, this.tableIndex),
      text:
        this.clinicalEndpointsTableData["Endpoints Table Title"].value.at(0)
          ?.text || "",
      labels:
        this.clinicalEndpointsTableData["Endpoints Table Title"].value.at(0)
          ?.labels || [],
      switch:
        this.clinicalEndpointsTableData["Endpoints Table Title"].switch ||
        false,
    };
  }

  public get notes() {
    return {
      key: createRefCode(REF_CODE_ENDPOINTS_TABLE_NOTES, this.tableIndex),
      text:
        this.clinicalEndpointsTableData["Endpoints Table Notes"].value.at(0)
          ?.text || "",
      labels:
        this.clinicalEndpointsTableData["Endpoints Table Notes"].value.at(0)
          ?.labels || [],
    };
  }

  public get groupNames() {
    const textList =
      this.clinicalEndpointsTableData["Endpoints Table Information"]
        ?.at(0)
        ?.["Endpoint Group Information"].map(
          (item) => item["Group Name"].value.at(0)?.text || "",
        ) || [];

    const labelsList =
      this.clinicalEndpointsTableData["Endpoints Table Information"]
        ?.at(0)
        ?.["Endpoint Group Information"].map(
          (item) => item["Group Name"].value.at(0)?.labels || [],
        ) || [];

    return textList.map((text, columnIndex) => ({
      key: createRefCode(
        REF_CODE_ENDPOINTS_TABLE_GROUP_NAME,
        this.tableIndex,
        columnIndex,
      ),
      text,
      labels: labelsList[columnIndex],
    }));
  }

  public get groupValues() {
    const measurementTimingKeyList =
      this.clinicalEndpointsTableData["Endpoints Table Information"]?.map(
        (_, rowIndex) =>
          createRefCode(
            REF_CODE_ENDPOINTS_TABLE_MEASUREMENT_TIMING,
            this.tableIndex,
            rowIndex,
          ),
      ) || [];

    const measurementTimingTextList =
      this.clinicalEndpointsTableData["Endpoints Table Information"]?.map(
        (item) =>
          item["Endpoint Name including Measurement Timing"].value.at(0)
            ?.text || "",
      ) || [];

    const measurementTimingLabelsList =
      this.clinicalEndpointsTableData["Endpoints Table Information"]?.map(
        (item) =>
          item["Endpoint Name including Measurement Timing"].value.at(0)
            ?.labels || [],
      ) || [];

    const groupDataKeyList = this.clinicalEndpointsTableData[
      "Endpoints Table Information"
    ]?.map((table, rowIndex) =>
      table["Endpoint Group Information"].map((_, colIndex) =>
        createRefCode(
          REF_CODE_ENDPOINTS_TABLE_GROUP_DATA,
          this.tableIndex,
          rowIndex,
          colIndex,
        ),
      ),
    );

    const groupDataTextList = this.clinicalEndpointsTableData[
      "Endpoints Table Information"
    ]?.map((table) =>
      table["Endpoint Group Information"].map(
        (group) => `${group["Group Data"].value.at(0)?.text || ""}`,
      ),
    );

    const groupDataLabelsList = this.clinicalEndpointsTableData[
      "Endpoints Table Information"
    ]?.map((table) =>
      table["Endpoint Group Information"].map(
        (group) => group["Group Data"].value.at(0)?.labels || [],
      ),
    );

    const pValueTextList = this.clinicalEndpointsTableData[
      "Endpoints Table Information"
    ]?.map((table) =>
      table["Endpoint Group Information"].map(
        (group) => `${group["P-Value"].value.at(0)?.text || ""}`,
      ),
    );

    const result = groupDataTextList.map((rowItem, rowIndex) => [
      {
        key: measurementTimingKeyList[rowIndex],
        text: measurementTimingTextList[rowIndex],
        labels: measurementTimingLabelsList[rowIndex],
      },
      ...rowItem.map((colItem, colIndex) => ({
        key: groupDataKeyList[rowIndex][colIndex],
        text: ClinicalEndpointsTable.combineGroupData(
          colItem,
          pValueTextList[rowIndex][colIndex],
        ),
        labels: groupDataLabelsList[rowIndex][colIndex],
      })),
    ]);

    return result;
  }

  public get endpointClassifications() {
    const textList =
      this.clinicalEndpointsTableData["Endpoints Table Information"]?.map(
        (table) => table["Endpoint Classification"].value.at(0)?.text || "",
      ) || [];

    const labelsList =
      this.clinicalEndpointsTableData["Endpoints Table Information"]?.map(
        (table) => table["Endpoint Classification"].value.at(0)?.labels || [],
      ) || [];

    return textList.map((text, index) => ({
      text,
      isPrimary: text?.toLowerCase().includes("primary endpoint"),
      labels: labelsList[index],
    }));
  }

  public isEmpty() {
    const isEmptyGroupNames = this.groupNames.length === 0;
    const isEmptyGroupValues = this.groupValues.length === 0;
    const isEmptyGroupNameText = this.groupNames.every(
      ({ text }) => text === "",
    );
    const isEmptyGroupValueText = this.groupValues.every((row) =>
      row.slice(1).every(({ text }) => text === ""),
    );

    return (
      isEmptyGroupNames ||
      isEmptyGroupValues ||
      isEmptyGroupNameText ||
      isEmptyGroupValueText
    );
  }
}
