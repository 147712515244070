import {
  ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE,
  ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS,
  ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS,
  ENTRY_DATA_ENDPOINT_GROUP_INFORMATION_KEYS,
  ENTRY_DATA_SECTION_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import { Endpoints } from "@/types/clinicalTrials.types";

export const checkEndpointTableGroupInformationLength = (
  endpointTable?: Endpoints,
) =>
  endpointTable?.[
    ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation
  ].map(
    (groupShellInfo) =>
      groupShellInfo[
        ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS.endpointGroupInformation
      ].length,
  ) || [0];

export const returnCurrentEndpointTableGroupInformationMaxLength = (
  endpointTable?: Endpoints,
) => {
  const groupInformationLengthList =
    checkEndpointTableGroupInformationLength(endpointTable);

  return Math.max(...groupInformationLengthList);
};

export const checkClinicalEndpointGroupInformationSameShellLength = (
  entryData: ArticleEntryData,
) => {
  const targetPath =
    entryData?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
      ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE
    ];

  const checkGroupInformationLength = targetPath?.map((tableInfo) => {
    const groupInformationLengthList =
      checkEndpointTableGroupInformationLength(tableInfo);

    const deduplicationGroupInformationList = [
      ...new Set(groupInformationLengthList),
    ];

    return !(deduplicationGroupInformationList.length > 1);
  });

  return checkGroupInformationLength?.every(
    (lengthItem) => lengthItem === true,
  );
};

export const returnCurrentColumnGroupName = (
  endpointTable: Endpoints,
  groupShellIndex: number,
) => {
  const currentMaxGroupInformationIndex =
    returnCurrentEndpointTableGroupInformationMaxLength(endpointTable);

  const currentMaxGroupInformationTable = endpointTable?.[
    ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation
  ].filter(
    (endpoint) =>
      endpoint[
        ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS.endpointGroupInformation
      ].length === currentMaxGroupInformationIndex,
  )[0];

  const currentColumnGroupNameData =
    currentMaxGroupInformationTable[
      ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS.endpointGroupInformation
    ][groupShellIndex][ENTRY_DATA_ENDPOINT_GROUP_INFORMATION_KEYS.groupName]
      .value;

  const defaultGroupNameData = [
    {
      text: "N/A",
      is_modified: false,
    },
  ];

  return currentColumnGroupNameData.length > 0
    ? currentColumnGroupNameData
    : defaultGroupNameData;
};
