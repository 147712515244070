import React from "react";

import { RegulatorySection } from "@/types/regulatory.types";
import { APPROVAL_PROCESS_CONTENTS_KEY } from "@FillInTableContainer/shared/constants/regulatoryKey";
import {
  GROUP_SHELL_PATH_TO_ADD_GROUP_SHELL,
  BASIC_DATA_FOR_ADDING_GROUP_SHELLS,
} from "@FillInTableContainer/shared/constants/tableCreatorGroupShell.constants";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useEntryDataState } from "@stores/entryData";
import { useInitEntryDataValue } from "@stores/initEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  clickAddGroupShellButton: (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
    },
  ) => void;
  handleClickToDeleteApprovalProcessGroupShell: () => void;
  handleKeyDownToDeleteApprovalProcessGroupShell: (
    event: KeyboardEvent,
  ) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: RegulatorySection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useHandleRegulatoryApprovalProcessGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const articleSection = "Regulatory";
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const initEntryData = useInitEntryDataValue(getPressIdFromURL());

  const handleAddGroupShell = (groupShellInfo: {
    tableIndexParameter: number;
    groupShellIndexParameter: number;
    groupShellKeyParameter: string | null;
  }) => {
    if (focusStatus.container !== "noFocus") return;

    const {
      tableIndexParameter,
      groupShellIndexParameter,
      groupShellKeyParameter,
    } = groupShellInfo;

    if (groupShellKeyParameter === null) return;
    const targetShell = GROUP_SHELL_PATH_TO_ADD_GROUP_SHELL[
      groupShellKeyParameter
    ](entryData, tableIndexParameter, groupShellIndexParameter);

    if (targetShell === undefined) return;

    const newArrayLength = targetShell.length + 1 || 1;
    const emptyArray = new Array(newArrayLength).fill({});

    const addApprovalProcessGroupShellData = emptyArray.map((_, index) => {
      const approvalProcessGroupShellData =
        BASIC_DATA_FOR_ADDING_GROUP_SHELLS[articleSection]?.[
          groupShellKeyParameter
        ](initEntryData);

      if (!approvalProcessGroupShellData) return;
      if (
        groupShellKeyParameter !== APPROVAL_PROCESS_CONTENTS_KEY.ApprovalProcess
      )
        return;

      if (index === groupShellIndexParameter) {
        return approvalProcessGroupShellData;
      }
      if (index > groupShellIndexParameter) {
        return targetShell[index - 1];
      }
      if (index < groupShellIndexParameter) {
        return targetShell[index];
      }
      return targetShell[index];
    });

    const changeGroupShellData: Record<string, ArticleEntryData> = {
      "Approval Process": {
        Regulatory: {
          ...entryData.Regulatory,
          "Approval Process Contents": {
            ...entryData.Regulatory?.["Approval Process Contents"],
            "Approval Process": [...addApprovalProcessGroupShellData],
          },
        },
      } as unknown as ArticleEntryData,
    };

    setEntryData(changeGroupShellData[groupShellKeyParameter]);
    changeCurrentHistory({
      entryData: changeGroupShellData[groupShellKeyParameter],
      changedDataInfo: {
        target: "groupShell",
        action: "add",
        position: {
          groupShellKey,
          groupTableIndex,
          tableKey,
          tableIndex,
          groupTableKey,
          groupShellIndex: groupShellIndexParameter,
          shellKey: null,
          shellIndex: null,
          valueIndex: null,
          value: null,
        },
      },
    });

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  };

  const clickAddGroupShellButton = (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
    },
  ) => {
    event.stopPropagation();
    handleAddGroupShell(groupShellInfo);
  };

  const handleClearGroupShell = () => {
    const clearApprovalProcessTableGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;
      if (info.groupShellKey === "Approval Process")
        return {
          ...entryData,
          Regulatory: {
            ...entryData.Regulatory,
            "Approval Process Contents": {
              ...entryData.Regulatory?.["Approval Process Contents"],
              "Approval Process": entryData.Regulatory?.[
                "Approval Process Contents"
              ]?.["Approval Process"]?.map(
                (groupShellInfo, groupShellInfoIndex) => {
                  if (groupShellInfoIndex === info.groupShellIndex) {
                    return {
                      ...groupShellInfo,
                      "Update Date": {
                        ...groupShellInfo["Update Date"],
                        value: [],
                      },
                      "Authorized Company": {
                        ...groupShellInfo["Authorized Company"],
                        value: [],
                      },
                      "Regulatory Agency": {
                        ...groupShellInfo["Regulatory Agency"],
                        value: [],
                      },
                      "Application Type": {
                        ...groupShellInfo["Application Type"],
                        value: [],
                      },
                      "Marketing Status": {
                        ...groupShellInfo["Marketing Status"],
                        value: [],
                      },
                      "Indication on Label": {
                        ...groupShellInfo["Indication on Label"],
                        value: [],
                      },
                      "Dosage and Administration on Label": {
                        ...groupShellInfo["Dosage and Administration on Label"],
                        value: [],
                      },
                    };
                  }
                  return groupShellInfo;
                },
              ),
            },
          },
        };

      return {
        ...entryData,
        Regulatory: {
          ...entryData.Regulatory,
          "Approval Process Contents": {
            ...entryData.Regulatory?.["Approval Process Contents"],
            "Additional Information on the Approval Process": {
              ...entryData.Regulatory?.["Approval Process Contents"]?.[
                "Additional Information on the Approval Process"
              ],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    const ApprovalProcessRowLength =
      entryData.Regulatory?.["Approval Process Contents"]?.["Approval Process"]
        ?.length || 0;
    setEntryData(clearApprovalProcessTableGroupShell());
    changeCurrentHistory({
      entryData: clearApprovalProcessTableGroupShell(),
      changedDataInfo: {
        target: "groupShell",
        action: "clear",
        position: {
          groupShellKey,
          groupTableIndex,
          tableKey,
          tableIndex,
          groupTableKey,
          groupShellIndex,
          shellKey: null,
          shellIndex: null,
          valueIndex: null,
          value: null,
        },
      },
    });

    if (
      ApprovalProcessRowLength > 1 &&
      info.groupShellKey === "Approval Process"
    )
      changeFocusStatus({
        type: "delete",
        container: "groupShell",
      });
    else {
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  };

  const handleDeleteGroupShell = () => {
    const deleteApprovalProcessTableGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        Regulatory: {
          ...entryData.Regulatory,
          "Approval Process Contents": {
            ...entryData.Regulatory?.["Approval Process Contents"],
            "Approval Process": entryData.Regulatory?.[
              "Approval Process Contents"
            ]?.["Approval Process"]?.filter(
              (_, groupShellInfoIndex) =>
                groupShellInfoIndex !== info.groupShellIndex,
            ),
          },
        },
      };
    };
    setEntryData(deleteApprovalProcessTableGroupShell());
    changeCurrentHistory({
      entryData: deleteApprovalProcessTableGroupShell(),
      changedDataInfo: {
        target: "groupShell",
        action: "delete",
        position: {
          groupShellKey,
          groupTableIndex,
          tableKey,
          tableIndex,
          groupTableKey,
          groupShellIndex,
          shellKey: null,
          shellIndex: null,
          valueIndex: null,
          value: null,
        },
      },
    });

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  };

  const handleClickToDeleteApprovalProcessGroupShell = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShell();
    }
    if (focusStatus.type === "delete") {
      handleDeleteGroupShell();
    }
  };

  const handleKeyDownToDeleteApprovalProcessGroupShell = (
    event: KeyboardEvent,
  ) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearGroupShell();
    }
    if (focusStatus.type === "delete" && event.key === "Delete") {
      handleDeleteGroupShell();
    }
  };

  return {
    clickAddGroupShellButton,
    handleClickToDeleteApprovalProcessGroupShell,
    handleKeyDownToDeleteApprovalProcessGroupShell,
  };
};
