"use client";

import styled from "styled-components";

export const CountTextWrapper = styled.div`
  display: flex;
  padding-right: var(--Spacing-16, 1.6rem);
  align-items: center;
  gap: var(--Spacing-6, 0.6rem);
`;

export const GuideText = styled.span`
  color: var(--Color-Common-Text-Secondary, #515c6c);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 145%;
`;

export const CountText = styled.span`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 145%;
`;
