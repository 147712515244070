import styled, { css } from "styled-components";

import { GroupShellTitleWrapper } from "@FillInTableContainer/shared/styles/groupShell.style";
import {
  SelectButton,
  SelectButtonDivider,
  SelectButtonDividerWrapper,
  SelectContentWrapper,
} from "@FillInTableContainer/shared/styles/rowSelectButton.style";

export const GroupShellOutsideWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-left: 0;
`;

export const GroupShellWrapper = styled.div<{
  isFocused?: boolean;
  isClearGroupShell?: boolean;
  isDeleteGroupShell?: boolean;
  isAlertGroupShell?: boolean;
  isTableScroll?: boolean;
  isDragging?: boolean;
}>`
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  width: 100%;
  border: 2px solid transparent;

  ${({ isFocused }) => isFocused && css``}

  ${({ isTableScroll }) =>
    isTableScroll &&
    css`
      min-width: max-content;
    `}

  ${({ isClearGroupShell }) => isClearGroupShell && css``};

  ${({ isDeleteGroupShell }) => isDeleteGroupShell && css``};

  ${({ isAlertGroupShell }) => isAlertGroupShell && css``};

  ${({ isDragging }) =>
    isDragging &&
    css`
      border: 2px solid var(--Color-Border-Draft, #b3eaef);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
        0px 0px 2px 0px rgba(0, 0, 0, 0.12);
    `}
`;

export const SubGroupShellListWrapper = styled.div<{ $isScrollRow: boolean }>`
  display: flex;
  gap: 0.8rem;
  width: 100%;

  ${({ $isScrollRow }) =>
    !$isScrollRow &&
    css`
      flex-wrap: wrap;
    `}
`;

export const DeleteTableButtonWrapper = styled.div<{ $isDragging?: boolean }>`
  position: absolute;
  left: 3.6rem;
  top: -2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  transform: translateX(-50%);
  z-index: 10;
`;
export const DeleteTableButton = styled.button`
  position: sticky;
  left: 1rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  height: 2.4rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.neural.white};
  padding: 0.4rem;
  border-radius: 0.2rem;
  background: var(--Color-Common-Bg-Warning, #e53e06);
  white-space: nowrap;
  cursor: pointer;
  z-index: 10;
`;

export const DragButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const SelectWrapper = styled(SelectContentWrapper)<{
  isPossibleSelected: boolean;
}>`
  padding: 0;
  width: 100%;
`;

export const GroupShellSelectButton = styled(SelectButton)``;

export const GroupShellSelectButtonDivider = styled(SelectButtonDivider)``;

export const GroupShellSelectButtonDividerWrapper = styled(
  SelectButtonDividerWrapper,
)`
  position: sticky;
  top: 50%;
  left: 0;

  ${({ isPossibleSelected }) =>
    !isPossibleSelected &&
    css`
      display: none;
    `}
`;

export const ShellWrapperForSeparateShell = styled.div`
  width: 100%;
`;

export const TitleWrapper = styled(GroupShellTitleWrapper)``;
