import React from "react";

import useArticleSection from "@/components/business/FillInTableContainer/hooks/useArticleSection";
import { isRequiredShell } from "@/components/business/FillInTableContainer/shared/utils/getOptionsFromData";
import RequiredMark from "@atoms/RequiredMark/RequiredMark";
import TableCreatorListBox from "@FillInTableContainer/shared/components/TableCreatorListBox/TableCreatorListBox";
import CopyButton from "@FillInTableContainer/shared/components/TableCreatorTextFieldContainer/components/TableCreatorTextField/components/TableCreatorTextFieldInput/components/CopyButton/CopyButton";
import DeleteButton from "@FillInTableContainer/shared/components/TableCreatorTextFieldContainer/components/TableCreatorTextField/components/TableCreatorTextFieldInput/components/DeleteButton/DeleteButton";
import { useTableCreatorFocusStatus } from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useHandlePasteText } from "@FillInTableContainer/shared/hooks/useHandlePasteText";
import { useHandleTextFieldInputEvent } from "@FillInTableContainer/shared/hooks/useHandleTextFieldInputEvent";
import { GroupTableKeyType } from "@FillInTableContainer/shared/types/tableCreator.types";

import * as S from "./TitleShellTextField.style";

import type { EntryDataValueAndOptions } from "@/types/clinicalTrials.types";

interface Props {
  isFocused: boolean;
  positionInfo: {
    groupTableKey: GroupTableKeyType;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    subGroupShellKey: string | null;
    subGroupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
    shellKey: string;
    shellIndex: number;
    valueIndex: number;
  };
  shellInfo: EntryDataValueAndOptions;
  changeTextFieldMarginBottom: (margin: number) => void;
}

const TitleShellTextField = ({
  isFocused,
  positionInfo,
  shellInfo,
  changeTextFieldMarginBottom,
}: Props) => {
  const {
    textFieldTextareaRef,
    selectedValue,
    aiList,
    dictionaryList,
    isDictionaryList,
    targetListIndex,
    valueInfo,
    copyStatus,
    copyCurrentTextValue,
    handleEnterValueKeyboard,
    handleChangedEnterValue,
    handleBlurTextField,
    changeSelectedValue,
    handleFocusTextField,
    checkIndexOfValueInDefaultAiList,
    deleteCurrentTextField,
  } = useHandleTextFieldInputEvent(positionInfo, shellInfo);
  const { pasteText } = useHandlePasteText();
  const { focusStatus } = useTableCreatorFocusStatus();
  const articleSection = useArticleSection();

  return (
    <>
      <S.TitleContentsWrapper
        className={selectedValue ? "filled-text-field" : "empty-text-field"}
      >
        {isRequiredShell(articleSection, positionInfo.shellKey) && (
          <RequiredMark text="*" />
        )}

        <S.TitleShellText
          ref={textFieldTextareaRef}
          data-placeholder={`${positionInfo.shellKey.split(" ")[0]} 0${
            positionInfo.shellIndex + 1
          } ${positionInfo.shellKey.split(" ")[1]}`}
          onFocus={handleFocusTextField}
          onBlur={handleBlurTextField}
          onKeyDown={handleEnterValueKeyboard}
          onInput={handleChangedEnterValue}
          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
            event.stopPropagation()
          }
          defaultValue={selectedValue}
          onPaste={pasteText}
          contentEditable
        />
        {valueInfo.text && (
          <CopyButton
            copyStatus={copyStatus}
            copyCurrentTextValue={copyCurrentTextValue}
          />
        )}
        {valueInfo.text && (
          <DeleteButton deleteCurrentTextField={deleteCurrentTextField} />
        )}

        {isFocused && focusStatus.container === "textField" && (
          <TableCreatorListBox
            aiList={aiList}
            isDataSetList={false}
            dataSetList={[]}
            handleChangeDataSetValue={() => null}
            shellKey={positionInfo.shellKey}
            changeSelectedValue={changeSelectedValue}
            changeTextFieldMarginBottom={changeTextFieldMarginBottom}
            targetListIndex={targetListIndex}
            dictionaryList={dictionaryList}
            isDictionaryList={isDictionaryList}
            checkIndexOfValueInDefaultAiList={checkIndexOfValueInDefaultAiList}
          />
        )}
      </S.TitleContentsWrapper>
    </>
  );
};

export default TitleShellTextField;
