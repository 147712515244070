export const ENTRY_DATA_INVESTORS_GROUP_TABLE_KEY = "Investors" as const;

export const ENTRY_DATA_INVESTORS_KEYS = {
  investorName: "Investor Name",
} as const;

export const ENTRY_DATA_INVESTORS_SHELL_KEYS = {
  investorName: "Investor Name",
} as const;

export const ENTRY_DATA_INVESTORS_GROUP_SHELL_KEYS = {
  none: null,
};

export const ENTRY_DATA_INVESTORS_TABLE_KEYS = {
  none: null,
};
