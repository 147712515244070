import styled from "styled-components";

export const InsertImageSearchInputWrapper = styled.div`
  display: flex;
  max-width: 36rem;
  min-width: 28rem;
  gap: 0.2rem;
  height: 3.2rem;
  padding: var(--Spacing-4, 0.4rem);
  align-items: center;
  gap: var(--Spacing-12, 1.2rem);
  flex: 1 0 0;
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Interactive-Secondary, #b1b8c4);
  background: var(--Color-Common-Bg-Interactive-Secondary, #fff);
  padding: 0.4rem 1.2rem;
`;

export const InsertImageSearchInput = styled.input`
  display: flex;
  flex: 1;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;

  &::placeholder {
    color: var(--Color-Common-Text-Tertiary, #7c899c);
  }

  &:focus {
    outline: none;
    .search-box:has(&) {
      border: 1px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);
      background: var(--Color-Common-Bg-Primary, #fff);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
        0px 0px 2px 0px rgba(0, 0, 0, 0.12);
    }
  }
`;
export const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 2rem;
  height: 2rem;
  outline: none;
`;

export const ResetButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.2rem;
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
  outline: none;
  border: none;
  background: none;
  padding: 0 0.4rem;
  margin: 0;
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
`;
