import styled, { css } from "styled-components";

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  margin-top: 3.2rem;
  gap: 1rem;
  padding-bottom: 6.4rem;
  min-width: 140rem;

  button {
    display: flex;
    justify-content: center;
    height: 100%;
    cursor: pointer;
  }
`;

export const NumberWrapper = styled.span`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
`;

export const PageNumber = styled.strong<{ isTarget?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.4rem;
  height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 1.2rem;
  margin-right: 0.2rem;
  padding: 0 0.8rem;
  cursor: pointer;

  ${({ isTarget }) =>
    isTarget
      ? css`
          color: ${({ theme }) => theme.color.node.blue};
          background-color: rgba(11, 108, 255, 0.1);
        `
      : css`
          color: ${({ theme }) => theme.color.neural.gray08};
        `};
`;

export const SearchPageNumber = styled.label`
  margin-left: 2.4rem;
  input {
    display: inline-block;
    min-width: 8rem;
    height: 2.4rem;
    color: ${({ theme }) => theme.color.neural.gray08};
    font-weight: 700;
    font-size: 1.4rem;
    text-align: center;
    border: 0.1rem solid ${({ theme }) => theme.color.neural.gray06};
    border-radius: 0.4rem;
    outline: none;

    &:focus {
      border: 0.1rem solid ${({ theme }) => theme.color.node.blue};
    }
    &::placeholder {
      text-align: center;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }

  span {
    margin-left: 0.8rem;
    color: ${({ theme }) => theme.color.neural.gray06};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;
