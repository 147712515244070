import React from "react";

export interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconVaccine = ({ width = 24, height = 24, color = "#515C6C" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        className="modality-icon-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1252 3.68515C15.8823 3.43992 15.4865 3.43805 15.2413 3.68098C14.9961 3.9239 14.9942 4.31962 15.2371 4.56485L16.7956 6.13807L15.6156 7.32923L13.1642 4.85458C12.9213 4.60936 12.5256 4.60749 12.2804 4.85041C12.0351 5.09333 12.0333 5.48906 12.2762 5.73428L12.7908 6.25376L5.06465 14.0532C4.82334 14.2968 4.82334 14.6893 5.06465 14.9329L5.55505 15.4279L4.54977 16.4427C4.30847 16.6863 4.30846 17.0788 4.54977 17.3224L5.04784 17.8252L3.43514 19.4301C3.19004 19.674 3.18804 20.0701 3.43069 20.3147C3.67333 20.5594 4.06873 20.56 4.31384 20.316L5.92597 18.7117L6.54693 19.3385C6.6643 19.457 6.82416 19.5237 6.99095 19.5237C7.15773 19.5237 7.31759 19.457 7.43497 19.3386L8.43196 18.3321L9.01261 18.9183C9.12998 19.0368 9.28985 19.1034 9.45663 19.1034C9.62341 19.1034 9.78328 19.0368 9.90066 18.9183L17.6185 11.1273L18.1981 11.7124C18.441 11.9576 18.8368 11.9595 19.082 11.7166C19.3272 11.4737 19.3291 11.0779 19.0862 10.8327L16.4938 8.21572L17.6737 7.02456L19.6786 9.04845C19.9215 9.29367 20.3172 9.29554 20.5625 9.05262C20.8077 8.8097 20.8096 8.41397 20.5666 8.16875L16.1252 3.68515ZM9.45663 17.5904L6.38841 14.493L13.5586 7.25478L16.6269 10.3521L15.0892 11.9043C15.0704 11.8783 15.0494 11.8533 15.026 11.8298L14.0391 10.833C13.7962 10.5877 13.4005 10.5857 13.1552 10.8286C12.9099 11.0714 12.9079 11.4672 13.1508 11.7125L14.1378 12.7093C14.164 12.7358 14.1921 12.7594 14.2215 12.7803L13.4494 13.5597L11.5683 11.6599C11.3254 11.4146 10.9297 11.4126 10.6844 11.6555C10.4391 11.8984 10.4371 12.2941 10.68 12.5394L12.5697 14.4478L11.8761 15.148L10.9465 14.2092C10.7037 13.9639 10.308 13.9619 10.0627 14.2048C9.81739 14.4477 9.81543 14.8434 10.0583 15.0887L10.9963 16.036L9.45663 17.5904ZM6.99096 18.0106L5.87354 16.8826L6.39097 16.3603L7.50839 17.4883L6.99096 18.0106Z"
        fill={color}
      />
    </svg>
  );
};

IconVaccine.displayName = "Vaccine";

export default IconVaccine;
