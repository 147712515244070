export const ARTICLE_CONTENTS_ROUND_AND_STATUS_PARAGRAPH_CODE =
  "TC515" as const;
export const ARTICLE_CONTENTS_ROUND_AND_STATUS_SECTION_CODE = "TY006" as const;
export const ARTICLE_CONTENTS_ROUND_AND_STATUS_PARAGRAPH_TITLE =
  "Round Name & Type" as const;

export const ARTICLE_CONTENTS_ROUND_AND_STATUS_PARAGRAPH_GROUP_KEY =
  "Round & Status";

export const ARTICLE_CONTENTS_ROUND_AND_STATUS_SHELL_KEYS = {
  roundName: "Round Name",
  roundType: "Round Type",
} as const;

export const ARTICLE_CONTENTS_ROUND_AND_STATUS_SHELL_TITLE = {
  roundName: "Round Name",
  roundType: "Round Type",
} as const;
