import {
  ArticleEntryData,
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_LICENSEE_TABLE as LICENSEE_TABLE_KEY,
} from "@frontend/common";

import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { checkTargetDealSwapRolesByTableKey } from "../utils/checkTargetDealSwapRolesByTableKey";

type Props = {
  tableKey: string | null;
};
export const useCheckDealSwapRoles = ({ tableKey }: Props) => {
  const pressId = getPressIdFromURL();
  const entryData = useEntryDataValue(pressId);

  const checkDealSwapRoleByEntryData = (currentEntryData: ArticleEntryData) => {
    if (!currentEntryData?.[SECTION_KEYS.deals]) return false;

    const countLicenseeTable =
      currentEntryData[SECTION_KEYS.deals]?.[LICENSEE_TABLE_KEY]?.length || 0;
    const LICENSEE_TABLE_COUNT_CRITERIA = 1;

    return countLicenseeTable === LICENSEE_TABLE_COUNT_CRITERIA;
  };

  const isTargetDealSwapRoles =
    checkTargetDealSwapRolesByTableKey(tableKey) &&
    checkDealSwapRoleByEntryData(entryData);

  return {
    isTargetDealSwapRoles,
  };
};
