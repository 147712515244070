/* eslint-disable @typescript-eslint/no-unused-vars */
import { title } from "process";

import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_KEY_COMMENTS_GROUP_TABLE_KEY as KEY_COMMENTS,
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_FUNDED_COMPANY_GROUP_TABLE_KEY as FUNDED_COMPANY,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_TABLE_KEY as ROUND_TYPE_AND_STATUS,
  ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY as FINANCING_INFORMATION,
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_TABLE_KEY as ADDITIONAL_INFORMATION_VENTURE_CAPITAL,
  ENTRY_DATA_INVESTORS_GROUP_TABLE_KEY as INVESTORS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  CLINICAL_TRIAL_DESIGN_GROUP_TABLE_KEY,
} from "@frontend/common";

import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";

import {
  EXPEDITED_PROGRAM,
  EXPEDITED_PROGRAM_GROUP_KEY as eg,
} from "./expeditedProgramKey.constants";
import { isEnterTableNotes } from "../utils/getOptionsFromData";

import type {
  GroupTableKeyType,
  SelectedGroupTableKeyType,
} from "../types/tableCreator.types";
import type { ArticleEntryData } from "@/types/articleEntryData.types";

export const TABLE_CREATOR_GROUP_TABLE = {
  "Clinical Trials": [
    {
      key: "Drug Profile",
      title: "Drug Profile",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: CLINICAL_TRIAL_DESIGN_GROUP_TABLE_KEY,
      title: "Clinical Trial Design",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: "Clinical Results",
      title: "Clinical Results",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: "Clinical Endpoints (Table)",
      title: "Clinical Endpoints (Data)",
      isCustomTitle: true,
      isRequired: true,
      isEnterTableNotes: true,
    },
    {
      key: "Clinical Endpoints (Description)",
      title: "Other Clinical Endpoints (Description)",
      isCustomTitle: false,
      isRequired: true,
      isEnterTableNotes: false,
    },
    {
      key: "Key Comment",
      title: "Key Comment",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
  ],
  "Clinical Trial Status": [
    {
      key: "Drug Profile",
      title: "Drug Profile",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: "Clinical Trial Design-1",
      title: "Clinical Trial Design-1",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
  ],
  Regulatory: [
    {
      key: "Drug Profile",
      title: "Drug Profile",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: "Approval Process Contents",
      title: "Approval Process",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: "Key Comment",
      title: "Key Comment",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
  ],
  Deals: [
    {
      key: "Deal Type & Status",
      title: "Deal Type & Status",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },

    {
      key: "Drug Profile",
      title: "Drug Profile",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: "Deal Type & Status",
      title: "Deal Type & Status",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: "Licensee Table",
      title: "Deal Terms & Additional Information",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: "Key Comment",
      title: "Key Comment",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: "Additional Information (Deal)",
      title: "Additional Information",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
  ],
  [EXPEDITED_PROGRAM]: [
    {
      key: eg.drugProfile,
      title: "Drug Profile",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: eg.expeditedProgramContents,
      title: "Expedited Program Contents",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: eg.keyComment,
      title: "Key Comment",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
  ],
  [SECTION_KEYS.ventureCapital]: [
    {
      key: DRUG_PROFILE,
      title: "Drug Profile",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: FUNDED_COMPANY,
      title: "Company",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: ROUND_TYPE_AND_STATUS,
      title: "Round Name & Type",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: FINANCING_INFORMATION,
      title: "Financing Information",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: INVESTORS,
      title: "Investors",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: ADDITIONAL_INFORMATION_VENTURE_CAPITAL,
      title: "Additional Information",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
    {
      key: KEY_COMMENTS,
      title: "Key Comment",
      isCustomTitle: false,
      isRequired: false,
      isEnterTableNotes: false,
    },
  ],
};

export const GROUP_TABLE_PATH_TO_GET_CUSTOM_TITLE: Record<
  string,
  {
    // key를 string으로 임시설정
    [key in string]: (data: ArticleEntryData, tableIndex: number) => string;
  }
> = {
  "Clinical Trials": {
    "Clinical Endpoints (Table)": (
      data: ArticleEntryData,
      tableIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[tableIndex]?.[
        "Endpoints Table Title"
      ].value[0]?.text || "",
    "Drug Profile": (data: ArticleEntryData, tableIndex: number) => "",
    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: (
      data: ArticleEntryData,
      tableIndex: number,
    ) => "",
    "Clinical Results": (data: ArticleEntryData, tableIndex: number) => "",
    "Clinical Endpoints (Description)": (
      data: ArticleEntryData,
      tableIndex: number,
    ) => "",
    "Key Comment": (data: ArticleEntryData, tableIndex: number) => "",
  },
  "Clinical Trial Status": {
    "Drug Profile": (data: ArticleEntryData, tableIndex: number) => "",
    "Clinical Trial Design-1": (data: ArticleEntryData, tableIndex: number) =>
      "",
  },
  Regulatory: {
    "Drug Profile": (data: ArticleEntryData, tableIndex: number) => "",
    "Approval Process Contents": (data: ArticleEntryData, tableIndex: number) =>
      "",
    "Key Comment": (data: ArticleEntryData, tableIndex: number) => "",
  },
  [EXPEDITED_PROGRAM]: {
    [eg.drugProfile]: (data: ArticleEntryData, tableIndex: number) => "",
    [eg.expeditedProgramContents]: (
      data: ArticleEntryData,
      tableIndex: number,
    ) => "",
    [eg.keyComment]: (data: ArticleEntryData, tableIndex: number) => "",
  },
  [SECTION_KEYS.ventureCapital]: {
    [DRUG_PROFILE]: (data: ArticleEntryData, tableIndex: number) => "",
    [FUNDED_COMPANY]: (data: ArticleEntryData, tableIndex: number) => "",
    [ROUND_TYPE_AND_STATUS]: (data: ArticleEntryData, tableIndex: number) => "",
    [FINANCING_INFORMATION]: (data: ArticleEntryData, tableIndex: number) => "",
    [INVESTORS]: (data: ArticleEntryData, tableIndex: number) => "",
    [KEY_COMMENTS]: (data: ArticleEntryData, tableIndex: number) => "",
  },
};

export const GROUP_TABLE_PATH_TO_COUNT_TABLE: Record<
  string,
  {
    [key in GroupTableKeyType]?: (data: ArticleEntryData) => number;
  }
> = {
  "Clinical Trials": {
    "Drug Profile": (data: ArticleEntryData) =>
      data?.["Clinical Trials"]?.["Drug Profile"]?.length || 1,
    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: (data: ArticleEntryData) =>
      data?.["Clinical Trials"]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.length ||
      1,
    "Clinical Endpoints (Table)": (data: ArticleEntryData) =>
      data?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.length || 1,
  },
  "Clinical Trial Status": {
    "Drug Profile": (data: ArticleEntryData) =>
      data?.["Clinical Trial Status"]?.["Drug Profile"]?.length || 1,
    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: (data: ArticleEntryData) =>
      data?.["Clinical Trial Status"]?.["Clinical Trial Design-1"]?.length || 1,
  },
  Regulatory: {
    "Drug Profile": (data: ArticleEntryData) =>
      data?.Regulatory?.["Drug Profile"]?.length || 1,
  },
  Deals: {
    "Licensee Table": (data: ArticleEntryData) =>
      data?.Deals?.["Licensee Table"]?.length || 1,
    "Drug Profile": (data: ArticleEntryData) =>
      data?.Deals?.["Drug Profile"]?.length || 1,
  },
  [EXPEDITED_PROGRAM]: {
    [eg.drugProfile]: (data: ArticleEntryData) =>
      data?.[EXPEDITED_PROGRAM]?.[eg.drugProfile]?.length || 1,
  },
  [SECTION_KEYS.ventureCapital]: {
    [DRUG_PROFILE]: (data: ArticleEntryData) =>
      data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.length || 1,
  },
};

type EnableGroupTableKeyList = {
  "Clinical Trials": {
    [key in SelectedGroupTableKeyType<"Clinical Trials">]: string[];
  };
  "Clinical Trial Status": {
    [key in SelectedGroupTableKeyType<"Clinical Trial Status">]: string[];
  };
  Regulatory: { [key in SelectedGroupTableKeyType<"Regulatory">]: string[] };
  Deals: { [key in SelectedGroupTableKeyType<"Deals">]: string[] };
  [EXPEDITED_PROGRAM]: {
    [key in SelectedGroupTableKeyType<"Expedited Program">]: string[];
  };
  [SECTION_KEYS.ventureCapital]: {
    [key in SelectedGroupTableKeyType<"Venture Capital">]: string[];
  };
};

export const ENABLE_GROUP_TABLE_KEY_LIST: EnableGroupTableKeyList = {
  "Clinical Trials": {
    "Drug Profile": [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgressHalf,
    ],
    [CLINICAL_TRIAL_DESIGN_GROUP_TABLE_KEY]: [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgressHalf,
      DRAFT_STATUS_CODE.dbSavedHalf,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    "Clinical Results": [
      DRAFT_STATUS_CODE.dbSavedHalf,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    "Clinical Endpoints (Table)": [
      DRAFT_STATUS_CODE.dbSavedHalf,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    "Clinical Endpoints (Description)": [
      DRAFT_STATUS_CODE.dbSavedHalf,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    "Key Comment": [
      DRAFT_STATUS_CODE.dbSavedHalf,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
  },
  "Clinical Trial Status": {
    "Drug Profile": [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgressHalf,
    ],
    "Clinical Trial Design-1": [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgressHalf,
    ],
  },
  Regulatory: {
    "Drug Profile": [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    "Approval Process Contents": [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],

    "Key Comment": [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
  },
  Deals: {
    "Additional Information (Deal)": [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    "Deal Type & Status": [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    "Drug Profile": [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    "Key Comment": [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    "Licensee Table": [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    "Licensor Table": [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
  },
  [EXPEDITED_PROGRAM]: {
    [eg.drugProfile]: [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    [eg.expeditedProgramContents]: [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],

    [eg.keyComment]: [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
  },
  [SECTION_KEYS.ventureCapital]: {
    [DRUG_PROFILE]: [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    [FUNDED_COMPANY]: [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    [ROUND_TYPE_AND_STATUS]: [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    [FINANCING_INFORMATION]: [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    [INVESTORS]: [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    [ADDITIONAL_INFORMATION_VENTURE_CAPITAL]: [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
    [KEY_COMMENTS]: [
      DRAFT_STATUS_CODE.aiEntryDone,
      DRAFT_STATUS_CODE.reviewInProgress,
    ],
  },
};
