import React from "react";

import * as S from "./AuthenticationErrorModal.style";

const AuthenticationErrorModal = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const handleDimmedClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.target !== e.currentTarget) return;
    closeModal();
  };

  return (
    <S.Dimmed onClick={handleDimmedClick}>
      <S.ModalWrapper>
        <S.ModalHeader>
          <S.ModalTitle>Authentication Error</S.ModalTitle>
          <S.ModalErrorCode>Error Code: 403</S.ModalErrorCode>
        </S.ModalHeader>
        <S.ModalText>
          <p>Unauthorized access.</p>
          <p>Please contact your administrator for assistance</p>
        </S.ModalText>
        <S.ModalButtonWrapper>
          <S.OkButton onClick={closeModal}>OK</S.OkButton>
        </S.ModalButtonWrapper>
      </S.ModalWrapper>
    </S.Dimmed>
  );
};

export default AuthenticationErrorModal;
