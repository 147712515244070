"use client";

import styled from "styled-components";

export const TableHeadWrapper = styled.div<{ $rowWidth: string }>`
  display: flex;

  border-radius: var(--Border-Radius-4, 0.4rem);
  border-bottom: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);

  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.16);

  height: 4rem;
  min-width: 116rem;
  max-width: 188rem;
`;
