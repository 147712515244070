import styled from "styled-components";

import { SHELL_WIDTH } from "@FillInTableContainer/shared/constants/shellSize.constants";
import { DeleteButton } from "@FillInTableContainer/shared/styles/deleteButton.style";

import type { ShellColumnSize } from "@FillInTableContainer/shared/types/shellSize.types";

export const RowTitleWrapper = styled.div<{
  column: ShellColumnSize;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.4rem;
  width: ${({ column }) => SHELL_WIDTH[column]};
  height: 100%;
  min-height: 3.6rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.neural.gray08};
  border: 0.1rem solid ${({ theme }) => theme.color.neural.gray02};
  background-color: ${({ theme }) => theme.color.neural.white};
  padding: 1.2rem 0.8rem;
`;

export const ColumnDeleteButton = styled(DeleteButton)`
  position: absolute;
  top: -4rem;

  &.button-for-shell {
    top: -2.4rem;
    left: 50%;
    transform: translateX(-50%);
  }
`;
