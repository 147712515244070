/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useState } from "react";

import { ClassificationCode } from "@/types/code.types";
import {
  type ParsedJsonContentType,
  TableOrImageContentType,
} from "@/types/fillInTableContent.types";
import { usePressLabelsValue } from "@stores/pressLabels";

import FillInTableArticleImageContainer from "./components/FillInTableArticleImageContainer/FillInTableArticleImageContainer";
import FillInTableArticleTableContainer from "./components/FillInTableArticleTableContainer/FillInTableArticleTableContainer";
import PressLabelElement from "./components/PressLabelElement/PressLabelElement";
import { useCheckBoldKeywords } from "./hooks/useCheckBoldKeywords";
import { useSliceLabelData } from "./hooks/useSliceLabelData";
import * as S from "./PressLabelViewer.style";

import type { PressLabel } from "@/types/pressLabel.type";

interface Props {
  parsedContentArray: ParsedJsonContentType[];
  draftArticleId: number;
  handleLabelClick?: (
    label: PressLabel,
  ) => React.MouseEventHandler<HTMLElement>;
  handleViewerClick?: React.MouseEventHandler<HTMLDivElement>;
  showLabelTooltip?: boolean;
  columnLabelViewerWidth?: number;
  columnLabelViewerTop?: number;
  classificationManual: ClassificationCode;
}

const PressLabelViewer = ({
  parsedContentArray,
  draftArticleId,
  handleLabelClick,
  handleViewerClick,
  showLabelTooltip,
  columnLabelViewerWidth,
  columnLabelViewerTop,
  classificationManual,
}: Props) => {
  const pressLabels = usePressLabelsValue(draftArticleId);
  const [innerNodes, setInnerNodes] = useState<React.ReactNode[]>([]);

  const { sliceLabelData, textContent } = useSliceLabelData(
    parsedContentArray,
    pressLabels,
  );

  const { checkBoldKeyword } = useCheckBoldKeywords(classificationManual);

  const handleAddTableNodes = (contentItem: TableOrImageContentType) => {
    if (contentItem.type === "table")
      return [
        <FillInTableArticleTableContainer
          key={contentItem.html}
          item={contentItem}
        />,
      ];
    if (contentItem.type === "image")
      return [
        <FillInTableArticleImageContainer
          key={contentItem.source}
          item={contentItem}
        />,
      ];
  };

  const handleAddTextNodes = useCallback(
    (nodeValue: string, labels: PressLabel[]) => {
      let tempInnerNodes: React.ReactNode[] = [];
      if (labels.length === 0) {
        tempInnerNodes = [<span key={nodeValue}>{nodeValue}</span>];
      } else {
        let currentNodeValue = nodeValue;
        labels.forEach((label) => {
          const start = currentNodeValue.slice(0, label.start) ?? "";
          const current = currentNodeValue.slice(label.start, label.end) ?? "";
          const end = currentNodeValue.slice(label.end) ?? "";

          tempInnerNodes = [
            <span
              key={`start-${label.key}`}
              dangerouslySetInnerHTML={{ __html: checkBoldKeyword(start) }}
            />,
            <PressLabelElement
              entity={label.entity}
              key={label.key}
              handleClick={handleLabelClick?.(label)}
              showLabelTooltip={showLabelTooltip}
              columnLabelViewerWidth={columnLabelViewerWidth}
              columnLabelViewerTop={columnLabelViewerTop}
            >
              {current}
            </PressLabelElement>,
            <span
              key={`end-${label.key}`}
              dangerouslySetInnerHTML={{ __html: checkBoldKeyword(end) }}
            />,
            ...tempInnerNodes.slice(1),
          ];

          currentNodeValue = start;
        });
      }
      return tempInnerNodes.filter((node) => node !== "");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleLabelClick,
      showLabelTooltip,
      columnLabelViewerWidth,
      columnLabelViewerTop,
    ],
  );

  const handleAddLabelNodes = useCallback(() => {
    let textIndex = 0;
    const newInnerNodes: React.ReactNode[] = [];

    parsedContentArray.forEach((contentItem) => {
      if (contentItem.type === "txt") {
        const firstNodeValue = textContent[textIndex];
        if (!firstNodeValue || contentItem.data === "") return;

        const tempInnerNodes = handleAddTextNodes(
          firstNodeValue,
          sliceLabelData[textIndex],
        );

        newInnerNodes.push(tempInnerNodes.filter((node) => node !== ""));
        textIndex++;
      } else {
        const tempInnerNodes = handleAddTableNodes(contentItem);
        newInnerNodes.push(tempInnerNodes);
      }
    });
    setInnerNodes(newInnerNodes);
  }, [parsedContentArray, textContent, handleAddTextNodes, sliceLabelData]);

  useEffect(() => {
    handleAddLabelNodes();
  }, [handleAddLabelNodes]);

  return (
    <S.PressLabelViewer onClick={handleViewerClick}>
      {innerNodes.map((node, index) => (
        <React.Fragment key={`${String(index)}`}>{node}</React.Fragment>
      ))}
    </S.PressLabelViewer>
  );
};

export default PressLabelViewer;
