import React from "react";

import * as S from "./AIRegenerateButton.style";

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const AIRegenerateButton = ({ ...rest }: Props) => {
  return <S.AIRegenerateButton {...rest} tabIndex={-1} />;
};

export default AIRegenerateButton;
