import ArticleDetailIndex from "@EditArticleContainer/shared/components/ArticleDetailParagraphIndex/ArticleDetailParagraphIndex";
import { PARAGRAPH_CODE_CLINICAL_TRIAL_DESIGN } from "@EditArticleContainer/shared/constants/editArticle.constants";
import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import { useUpdateParagraph } from "@EditArticleContainer/shared/hooks/useUpdateParagraph";
import { useSectionCodeToClassification } from "@hooks/useSectionCode";

import ClinicalTrialDesignGroupTableContainer from "./components/ClinicalTrialDesignGroupTableContainer/ClinicalTrialDesignGroupTableContainer";
import * as S from "./ParagraphClinicalTrialDesign.style";

const PARAGRAPH_TITLE = "Clinical Design";

const ParagraphClinicalTrialDesign = () => {
  const sectionCode = useSectionCodeToClassification();
  const paragraph = useParagraphEditedData(
    PARAGRAPH_CODE_CLINICAL_TRIAL_DESIGN,
  );

  const [
    updateClinicalTrialDesign1,
    loadingIndex,
    updateClinicalTrialDesign1KeyMap,
  ] = useUpdateParagraph({
    paragraphCode: PARAGRAPH_CODE_CLINICAL_TRIAL_DESIGN,
    sectionCode,
  });

  const designGroupTableData = paragraph.text?.["Clinical Trial Design"];
  const designGroupTableLength = designGroupTableData?.length;

  if (!paragraph) return null;
  if (!designGroupTableData || designGroupTableLength === 0) return null;

  return (
    <S.ParagraphClinicalTrialDesignWrapper>
      <ArticleDetailIndex title={PARAGRAPH_TITLE} />
      <S.ParagraphClinicalTrialDesignContentsWrapper
        key={updateClinicalTrialDesign1KeyMap.get(0)}
      >
        <S.ParagraphClinicalTrialDesignUpdateButton
          onClick={() => updateClinicalTrialDesign1(0)}
          disabled={loadingIndex === 0}
        />
        {designGroupTableData?.map(
          (designGroupTable, designGroupTableIndex) => (
            <S.ParagraphTableWrapper>
              <ClinicalTrialDesignGroupTableContainer
                designGroupTableData={designGroupTable}
                designGroupTableIndex={designGroupTableIndex}
                designGroupTableLength={designGroupTableLength}
              />
            </S.ParagraphTableWrapper>
          ),
        )}
      </S.ParagraphClinicalTrialDesignContentsWrapper>
    </S.ParagraphClinicalTrialDesignWrapper>
  );
};

export default ParagraphClinicalTrialDesign;
