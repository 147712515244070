import styled from "styled-components";

import { mq } from "@styles/mediaQuery";

export const BottomButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 92rem;
  background-color: #ffffff;
  padding: 1.2rem 0;
  border-top: 0.1rem solid #e5e7eb;
  z-index: 30;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100vw - 8rem);
  margin: 0 auto;

  ${[mq[0]]} {
    max-width: calc(100vw - 8rem);
  }

  ${[mq[1]]} {
    width: 100%;
    max-width: calc(100vw - 4rem);
  }
  ${[mq[2]]} {
    width: 100%;
    max-width: calc(100vw - 4rem);
    padding: 0 1rem;
  }
  ${[mq[3]]} {
    width: 100%;
    max-width: 100%;
    padding: 0 2rem;
  }
`;
