import { PAGINATION_LENGTH } from "./Pagination";

export const createPageList = (
  page: number,
  totalPage: number,
  paginationLength = PAGINATION_LENGTH,
) => {
  const paginationLengthHalf = Math.floor(paginationLength / 2);

  if (page >= 1 && page <= paginationLengthHalf)
    return Array(totalPage > paginationLength ? paginationLength : totalPage)
      .fill(0)
      .map((_, i) => i + 1);

  if (totalPage - page < paginationLengthHalf)
    return Array(totalPage > paginationLength ? paginationLength : totalPage)
      .fill(0)
      .map((_, i) => totalPage - i)
      .reverse();

  return Array(paginationLength)
    .fill(0)
    .map((_, i) => page + i - paginationLengthHalf);
};
