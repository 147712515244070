"use client";

import styled from "styled-components";

export const SearchBarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: var(--Spacing-6, 0.6rem) var(--Spacing-8, 0.8rem);
  gap: var(--Spacing-2, 0.2rem);

  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Interactive-Secondary, #b1b8c4);
  background: var(--Color-Common-Bg-Primary, #fff);
`;

export const SearchButton = styled.button`
  padding: 0;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
`;
