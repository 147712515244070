import React from "react";

import { Radio } from "@frontend/common";

import { useMainLabelRadioDropDown } from "./hooks/useMainLabelRadioDropDown";
import * as S from "./MainLabelRadioDropDown.style";
import { useMainLabelOptionList } from "../../../../hooks/useMainLabelOptionList";
import { FormattedMainLabelItemType } from "../../../../types/articleMainLabel.types";

interface Props {
  labelInfo: FormattedMainLabelItemType;
  targetValue: string;
}

const MainLabelRadioDropDown = ({ targetValue, labelInfo }: Props) => {
  const { changeTargetMainLabel } = useMainLabelRadioDropDown();
  const optionList = useMainLabelOptionList(labelInfo.labelName);

  return (
    <S.MainLabelRadioDropDownWrapper>
      {optionList.map((item) => (
        <S.RadioItemWrapper key={item}>
          <S.RadioButtonWrapper>
            <Radio
              value={item}
              checked={targetValue === item}
              onChangeHandler={(event) =>
                changeTargetMainLabel(event, labelInfo)
              }
            />
          </S.RadioButtonWrapper>
          <S.LabelWrapper>
            <S.LabelText>{item}</S.LabelText>
          </S.LabelWrapper>
        </S.RadioItemWrapper>
      ))}
    </S.MainLabelRadioDropDownWrapper>
  );
};

export default MainLabelRadioDropDown;
