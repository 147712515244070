import styled from "styled-components";

import { DefaultDraggableGroupShellWrapper } from "@FillInTableContainer/shared/styles/groupShell.style";
import {
  DefaultDeleteTableButton,
  DefaultDeleteTableButtonWrapper,
  DefaultGroupShellWrapper,
  TableContentWrapper,
} from "@FillInTableContainer/shared/styles/table.style";

export const TableWrapper = styled(TableContentWrapper)`
  gap: 0;
`;

export const GroupShellSpaceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.color.neural.white};
`;

export const GroupShellWrapper = styled(DefaultGroupShellWrapper)``;

export const DeleteTableButtonWrapper = styled(
  DefaultDeleteTableButtonWrapper,
)``;

export const DeleteTableButton = styled(DefaultDeleteTableButton)``;
export const DraggableGroupShellWrapper = styled(
  DefaultDraggableGroupShellWrapper,
)``;
