import { CATEGORY_DROP_DOWN_SHELL_KEYS } from "../constants/tableCreatorListBox.constants";

import type {
  DictionaryCategoryDropdownList,
  DictionaryDefaultDropdownList,
  DropDownListData,
  DropdownFromApiResponse,
} from "../types/tableCreatorDropdown.types";

export const BaseOnShellValueDropDownKey: string[] = [];

export const handleDropdownListWithCategory = (
  originalList: DropdownFromApiResponse,
) => {
  const filteringDropdownList = Object.entries(originalList).filter(([key]) =>
    CATEGORY_DROP_DOWN_SHELL_KEYS.includes(key),
  );

  if (!filteringDropdownList.length) return [];

  const formattedCategoryDropdownList: DictionaryCategoryDropdownList[] =
    filteringDropdownList.map((list) => {
      const dropdownKey = list[0];
      const dropdownList = list[1];

      const categorySortList = Object.entries(dropdownList).map(
        ([key, itemList]: [string, DropDownListData[]]) => {
          const sortDropdownValueList = itemList
            .sort((a, b) => a.viewOrd - b.viewOrd)
            .map((item) => item.name);

          return {
            categoryKey: key,
            list: sortDropdownValueList,
          };
        },
      );

      return {
        key: "DICTIONARY",
        isCategory: true,
        shellKey: dropdownKey,
        list: categorySortList.filter((listItem) => listItem.list.length > 0),
      };
    });

  return formattedCategoryDropdownList;
};

export const handleDropdownListWithoutCategory = (
  originalList: DropdownFromApiResponse,
  changeDealTypeTextToDisplayName: (
    shellKey: string | null,
    currentInputDealType: string,
  ) => string,
) => {
  // NOTE : Venture Capital에서 Round로 들어오는 경우, 동일한 Dropdown을 Round Name, Round Type에서 표출 필요
  //        -> 이후 변경이 각각 변경이 필요하면, BE팀 요청드려 ShellKey에 맞게 데이터 수정 필요
  const changedOriginalListForChangingKeyName = Object.entries(
    originalList,
  ).reduce((acc, [key, value]) => {
    if (key === "Round") {
      acc["Round Name"] = value;
      acc["Round Type"] = value;
    } else {
      acc[key] = value;
    }
    return acc;
  }, {} as DropdownFromApiResponse);

  const filteringDropdownList = Object.entries(
    changedOriginalListForChangingKeyName,
  ).filter(([, value]) => Array.isArray(value));

  if (!filteringDropdownList.length) return [];

  const formattedDropdownList: DictionaryDefaultDropdownList[] =
    filteringDropdownList.map((item) => {
      const filterKey = item[0];
      const dropdownList = item[1] as DropDownListData[];

      const sortDropdownValueList = dropdownList
        .sort((a, b) => a.viewOrd - b.viewOrd)
        .map((list) => changeDealTypeTextToDisplayName(filterKey, list.name));

      return {
        key: "DICTIONARY",
        isCategory: false,
        shellKey: filterKey,
        list: sortDropdownValueList,
      };
    });

  return formattedDropdownList;
};
