import styled, { css } from "styled-components";

export const FillInTableTableCreatorTitleRowWrapper = styled.div<{
  isTableScroll: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  width: 100%;
  padding: 1.6rem 1.6rem 0 1.6rem;
  background-color: white;

  ${({ isTableScroll }) =>
    isTableScroll &&
    css`
      min-width: max-content;
    `}
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
`;
