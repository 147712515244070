import React from "react";

import * as S from "./TableContainer.style";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  isDisplay?: boolean;
}

const TableContainer = ({ children, isDisplay = true, ...rest }: Props) => {
  return (
    <S.TableWrapper $isDisplay={isDisplay} {...rest}>
      {children}
    </S.TableWrapper>
  );
};

export default TableContainer;
