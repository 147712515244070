import { ClassificationCode } from "@/types/code.types";
import { CLASSIFICATION_CODE_FROM_NAME } from "@PressListContainer/classificationInfo.constants";

import { AUTHORITY_TYPE } from "../constants/authorityType.constants";

import type { AuthorityType, EditorRoleCode } from "../types/roles.types";

const ALLOWED_EDITOR_ROLES_AND_CLASSIFICATION: Partial<
  Record<EditorRoleCode, ClassificationCode[]>
> = {
  EDTK0201: [CLASSIFICATION_CODE_FROM_NAME["clinical, preclinical, R&D"]],
  EDTK0202: [CLASSIFICATION_CODE_FROM_NAME["clinical, preclinical, R&D"]],
  EDTK0204: [CLASSIFICATION_CODE_FROM_NAME["clinical, preclinical, R&D"]],
  EDTK0303: [CLASSIFICATION_CODE_FROM_NAME["clinical trial status"]],
  EDTK0304: [CLASSIFICATION_CODE_FROM_NAME["clinical trial status"]],
  EDTK0401: [CLASSIFICATION_CODE_FROM_NAME["conference, poster contents"]],
  EDTK0402: [CLASSIFICATION_CODE_FROM_NAME["conference, poster contents"]],
  EDTK0404: [CLASSIFICATION_CODE_FROM_NAME["conference, poster contents"]],
  EDTK0503: [CLASSIFICATION_CODE_FROM_NAME["deal, cooperation"]],
  EDTK0504: [CLASSIFICATION_CODE_FROM_NAME["deal, cooperation"]],
  EDTK0603: [CLASSIFICATION_CODE_FROM_NAME["expedited program"]],
  EDTK0604: [CLASSIFICATION_CODE_FROM_NAME["expedited program"]],
  EDTK0703: [CLASSIFICATION_CODE_FROM_NAME["approval process"]],
  EDTK0704: [CLASSIFICATION_CODE_FROM_NAME["approval process"]],
  EDTK0803: [CLASSIFICATION_CODE_FROM_NAME["venture capital"]],
  EDTK0804: [CLASSIFICATION_CODE_FROM_NAME["venture capital"]],
};

interface Args {
  editorRoles: EditorRoleCode[];
  authorityType: AuthorityType;
  classificationAi: ClassificationCode;
  classificationManual: ClassificationCode;
}

export class FillInTableRoleChecker {
  private editorRoles: EditorRoleCode[];

  private authorityType: AuthorityType;

  private classificationAi: ClassificationCode;

  private classificationManual: ClassificationCode;

  constructor({
    editorRoles,
    authorityType,
    classificationAi,
    classificationManual,
  }: Args) {
    this.editorRoles = editorRoles;
    this.authorityType = authorityType;
    this.classificationAi = classificationAi;
    this.classificationManual = classificationManual;
  }

  private get hasValidRoles(): boolean {
    if (!this.classificationManual)
      return this.editorRoles.some((role) =>
        ALLOWED_EDITOR_ROLES_AND_CLASSIFICATION[role]?.includes(
          this.classificationAi,
        ),
      );
    return this.editorRoles.some((role) =>
      ALLOWED_EDITOR_ROLES_AND_CLASSIFICATION[role]?.includes(
        this.classificationManual,
      ),
    );
  }

  private get isSuperAdmin(): boolean {
    return this.authorityType === AUTHORITY_TYPE.superAdmin;
  }

  public get canAccess(): boolean {
    return this.isSuperAdmin || this.hasValidRoles;
  }
}
