import React from "react";

import { RegulatoryAgencyIcon } from "@frontend/common/src/components";
import {
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
} from "@frontend/common/src/shared/components/core";

import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { useCreateCompanyLogoComponentList } from "@EditArticleContainer/shared/hooks/useCreateCompanyLogoComponentList";
import {
  REF_CODE_APPROVAL_PROCESS_APPLICATION_TYPE,
  REF_CODE_APPROVAL_PROCESS_AUTHORIZED_COMPANY,
  REF_CODE_APPROVAL_PROCESS_DOSAGE_AND_ADMINISTRATION_ON_LABEL,
  REF_CODE_APPROVAL_PROCESS_INDICATION_ON_LABEL,
  REF_CODE_APPROVAL_PROCESS_MARKETING_STATUS,
  REF_CODE_APPROVAL_PROCESS_REGULATORY_AGENCY,
  REF_CODE_APPROVAL_PROCESS_UPDATE_DATE,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createRefCode } from "@EditArticleContainer/shared/libs/refCode/utils/createRefCode";
import { ParagraphContent } from "@EditArticleContainer/shared/types/paragraphContents/paragraphContents.types";
import { formattedArticleDate } from "@EditArticleContainer/shared/utils/formattedArticleDate";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

import * as S from "./ApprovalProcessTable.style";

interface Props {
  tableData: ParagraphContent["TC213"]["text"]["Approval Process"]["Approval Process"][0];
  tableIndex: number;
}

const COMMON_EDITABLE_TEXT_STYLE = {
  padding: "0.8rem",
  borderRadius: "0",
  fontWeight: 500,
};

const VALUE_SHELL_PADDING = "0.2rem";

const ApprovalProcessTable = ({ tableData, tableIndex }: Props) => {
  const { createCompanyLogoComponentList } =
    useCreateCompanyLogoComponentList();

  const approvalProcessValueText = {
    "Update Date": tableData["Update Date"]?.value.at(0)?.text || "",
    "Marketing Status": tableData["Marketing Status"]?.value.at(0)?.text || "",
    "Regulatory Agency":
      tableData["Regulatory Agency"]?.value.at(0)?.text || "",
    "Application Type": tableData["Application Type"]?.value.at(0)?.text || "",
    "Authorized Company": createMultiLineText({
      textList:
        tableData?.["Authorized Company"]?.value.map?.(
          (item) => item.text ?? "",
        ) ?? [],
      parseString: (text) => `- ${text?.trim()}`,
    }),
    "Indication on Label": createMultiLineText({
      textList:
        tableData?.["Indication on Label"]?.value.map?.(
          (item) => item.text ?? "",
        ) ?? [],
      parseString: (text) => `- ${text?.trim()}`,
    }),
    "Dosage and Administration on Label":
      tableData["Dosage and Administration on Label"]?.value.at(0)?.text || "",
  };

  const approvalProcessValueLabels = {
    "Update Date": tableData["Update Date"]?.value.at(0)?.labels || [],
    "Marketing Status":
      tableData["Marketing Status"]?.value.at(0)?.labels || [],
    "Regulatory Agency":
      tableData["Regulatory Agency"]?.value.at(0)?.labels || [],
    "Application Type":
      tableData["Application Type"]?.value.at(0)?.labels || [],
    "Authorized Company":
      tableData?.["Authorized Company"]?.value.at(0)?.labels || [],
    "Indication on Label":
      tableData["Indication on Label"]?.value.at(0)?.labels || [],
    "Dosage and Administration on Label":
      tableData["Dosage and Administration on Label"]?.value.at(0)?.labels ||
      [],
  };

  const isDisplayTable = Object.values(approvalProcessValueText).some(
    (text) => !!text,
  );
  if (!isDisplayTable) return null;

  return (
    <S.TablesWrapper>
      <TableContainer>
        <TableRowContainer>
          <TableShellTitle width="14.4rem">Update Date</TableShellTitle>
          <TableShellTitle width="14.4rem">Marketing Status</TableShellTitle>
          <TableShellTitle width="18rem">Regulatory Agency</TableShellTitle>
          <TableShellTitle width="14.4rem">Application Type</TableShellTitle>
          <TableShellTitle>Authorized Company</TableShellTitle>
        </TableRowContainer>
        <TableRowContainer>
          <TableShellValue width="14.4rem" padding={VALUE_SHELL_PADDING}>
            <EditableTextContainer
              item={{
                text:
                  formattedArticleDate(
                    approvalProcessValueText["Update Date"],
                  ) || "",
                labels: approvalProcessValueLabels["Update Date"],
              }}
              refKey={createRefCode(
                REF_CODE_APPROVAL_PROCESS_UPDATE_DATE,
                tableIndex,
              )}
              canTextEdit
              style={COMMON_EDITABLE_TEXT_STYLE}
            />
          </TableShellValue>
          <TableShellValue width="14.4rem" padding={VALUE_SHELL_PADDING}>
            <EditableTextContainer
              item={{
                text: approvalProcessValueText["Marketing Status"],
                labels: approvalProcessValueLabels["Marketing Status"],
              }}
              refKey={createRefCode(
                REF_CODE_APPROVAL_PROCESS_MARKETING_STATUS,
                tableIndex,
              )}
              canTextEdit
              style={COMMON_EDITABLE_TEXT_STYLE}
            />
          </TableShellValue>
          <TableShellValue width="18rem" padding={VALUE_SHELL_PADDING}>
            <EditableTextContainer
              item={{
                text: approvalProcessValueText["Regulatory Agency"],
                labels: approvalProcessValueLabels["Regulatory Agency"],
              }}
              refKey={createRefCode(
                REF_CODE_APPROVAL_PROCESS_REGULATORY_AGENCY,
                tableIndex,
              )}
              canTextEdit
              isDisabled
              imagePosition="left"
              imageNodeList={[
                <RegulatoryAgencyIcon
                  agencyType={approvalProcessValueText["Regulatory Agency"]}
                  isTopMargin
                />,
              ]}
              style={COMMON_EDITABLE_TEXT_STYLE}
            />
          </TableShellValue>
          <TableShellValue width="14.4rem" padding={VALUE_SHELL_PADDING}>
            <EditableTextContainer
              item={{
                text: approvalProcessValueText["Application Type"],
                labels: approvalProcessValueLabels["Application Type"],
              }}
              refKey={createRefCode(
                REF_CODE_APPROVAL_PROCESS_APPLICATION_TYPE,
                tableIndex,
              )}
              canTextEdit
              style={COMMON_EDITABLE_TEXT_STYLE}
            />
          </TableShellValue>
          <TableShellValue padding={VALUE_SHELL_PADDING}>
            <EditableTextContainer
              item={{
                text: approvalProcessValueText["Authorized Company"],
                labels: approvalProcessValueLabels["Authorized Company"],
              }}
              refKey={createRefCode(
                REF_CODE_APPROVAL_PROCESS_AUTHORIZED_COMPANY,
                tableIndex,
              )}
              canTextEdit
              isDisabled
              imagePosition="left"
              imageNodeList={createCompanyLogoComponentList(
                tableData?.["Authorized Company"]?.value?.flatMap?.(
                  (item) => item.text?.replaceAll("- ", "").split(`\n`) ?? "",
                ) ?? [],
              )}
              imageColumnCount={2}
              style={COMMON_EDITABLE_TEXT_STYLE}
            />
          </TableShellValue>
        </TableRowContainer>

        <>
          <TableRowContainer
            isDisplay={!!approvalProcessValueText["Indication on Label"]}
          >
            <TableShellTitle>Indication on Label</TableShellTitle>
          </TableRowContainer>
          <TableRowContainer
            isDisplay={!!approvalProcessValueText["Indication on Label"]}
          >
            <TableShellValue padding={VALUE_SHELL_PADDING}>
              <EditableTextContainer
                item={{
                  text: approvalProcessValueText["Indication on Label"],
                  labels: approvalProcessValueLabels["Indication on Label"],
                }}
                refKey={createRefCode(
                  REF_CODE_APPROVAL_PROCESS_INDICATION_ON_LABEL,
                  tableIndex,
                )}
                canTextEdit
                style={COMMON_EDITABLE_TEXT_STYLE}
              />
            </TableShellValue>
          </TableRowContainer>
        </>

        <>
          <TableRowContainer
            isDisplay={
              !!approvalProcessValueText["Dosage and Administration on Label"]
            }
          >
            <TableShellTitle>
              Dosage and Administration on Label
            </TableShellTitle>
          </TableRowContainer>
          <TableRowContainer
            isDisplay={
              !!approvalProcessValueText["Dosage and Administration on Label"]
            }
          >
            <TableShellValue padding={VALUE_SHELL_PADDING}>
              <EditableTextContainer
                item={{
                  text: approvalProcessValueText[
                    "Dosage and Administration on Label"
                  ],
                  labels:
                    approvalProcessValueLabels[
                      "Dosage and Administration on Label"
                    ],
                }}
                refKey={createRefCode(
                  REF_CODE_APPROVAL_PROCESS_DOSAGE_AND_ADMINISTRATION_ON_LABEL,
                  tableIndex,
                )}
                canTextEdit
                style={COMMON_EDITABLE_TEXT_STYLE}
              />
            </TableShellValue>
          </TableRowContainer>
        </>
      </TableContainer>
    </S.TablesWrapper>
  );
};

export default ApprovalProcessTable;
