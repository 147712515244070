import styled from "styled-components";

export const PressLabelViewer = styled.div`
  overflow-wrap: break-word;
  white-space: pre-wrap;
  margin-top: 0.1rem;
  width: 100%;
  padding-bottom: 10rem;

  & span:focus {
    outline: none;
  }

  b {
    color: #000;
    font-weight: 700;
  }
`;
