import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { useEditorRoles } from "@libs/roles/hooks/useEditorRoles";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { checkSuperAdmin, useAuthorityType } from "./useAuthorityType";
import { checkFillInTablePageAccessRoles } from "../util/checkFillInTablePageAccessRoles";
import { checkManualClassificationRoles } from "../util/checkManualClassificationRoles";
import { checkUnsuitablePressRoles } from "../util/checkUnsuitablePressRoles";

export const usePressClassificationRoles = () => {
  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );
  const editorRoles = useEditorRoles();
  const authorityType = useAuthorityType();
  const isSuperAdmin = checkSuperAdmin(authorityType);

  const status = articleInfo?.draftArticleStatusHighest;
  const classification = articleInfo?.classificationManual;

  if (!status) return { hasManualClassificationRoles: false };

  const hasManualClassificationRoles = checkManualClassificationRoles(
    isSuperAdmin,
    editorRoles,
    status,
    classification,
  );

  const hasUnsuitablePressRoles = checkUnsuitablePressRoles(
    isSuperAdmin,
    editorRoles,
    status,
    classification,
  );

  const hasNextPageAccessRoles = checkFillInTablePageAccessRoles(
    isSuperAdmin,
    editorRoles,
    status,
    classification,
  );

  return {
    hasManualClassificationRoles,
    hasUnsuitablePressRoles,
    hasNextPageAccessRoles,
  };
};
