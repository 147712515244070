import { apiClient } from "@api/apiClient";

import type { PressInfoResponse } from "@interface/pressInfo.interface";

const GET_PRESS_INFO = `/des/press/info`;

export const pressInfoApis = {
  get: async (pressProgressId: number) => {
    const { data } = await apiClient.get<PressInfoResponse>(
      `${GET_PRESS_INFO}/${pressProgressId}`,
    );
    return data;
  },
};
