import { useNavigate } from "react-router-dom";

import { FillInTableConcurrentAccessChecker } from "@libs/concurrentAccess/services/fillInTableConcurrentAccessChecker";
import { checkFillInTableLock } from "@libs/concurrentAccess/utils/checkFillInTableLock";
import { useAuthorityType } from "@libs/roles/hooks/useAuthorityType";
import { useEditorRoles } from "@libs/roles/hooks/useEditorRoles";
import { EditArticleRoleChecker } from "@libs/roles/services/editArticleRoleChecker";
import { FillInTableRoleChecker } from "@libs/roles/services/fillInTableRoleChecker";
import { InsertImageRoleChecker } from "@libs/roles/services/insertImageRoleChecker";
import { PressClassificationRoleChecker } from "@libs/roles/services/pressClassificationRoleChecker";
import { DRAFT_STATUS_PATH } from "@PressListContainer/draftStatusInfo.constants";
import { ROUTE_PATHS } from "@routes/routePath";

import type { ClassificationCode, ProcessStatusCode } from "@/types/code.types";

interface Args {
  draftArticleId: string;
  draftArticleStatus: ProcessStatusCode;
  ftEditorName: string | null;
  classificationAi: ClassificationCode;
  classificationManual: ClassificationCode;
  onAccessDenied: () => void;
  onConcurrentAccessDenied: () => void;
}

export const useMovePressDetailPage = ({
  draftArticleId,
  draftArticleStatus,
  ftEditorName,
  classificationAi,
  classificationManual,
  onAccessDenied,
  onConcurrentAccessDenied,
}: Args) => {
  const navigate = useNavigate();
  const editorRoles = useEditorRoles();
  const authorityType = useAuthorityType();

  const movePressDetailPage = async () => {
    const pathname = DRAFT_STATUS_PATH[draftArticleStatus];
    const url = `${pathname}?id=${draftArticleId}`;

    if (pathname === ROUTE_PATHS.pressClassification) {
      const roleChecker = new PressClassificationRoleChecker({
        authorityType,
        editorRoles,
      });

      if (roleChecker.canAccess) {
        navigate(url);
      } else {
        onAccessDenied();
      }
    } else if (pathname === ROUTE_PATHS.fillInTable) {
      const roleChecker = new FillInTableRoleChecker({
        authorityType,
        editorRoles,
        classificationAi,
        classificationManual,
      });

      if (roleChecker.canAccess) {
        // 동시 접근 체크를 할때 get을 이용하여 체크하지 않고 post로 바로 선점하고 있음.
        const hasLock = await checkFillInTableLock({
          url: `${window.location.origin}${url}`,
          draftArticleId: draftArticleId.toString(),
          ftEditorName,
        });
        const fillInTableConcurrentAccessChecker =
          new FillInTableConcurrentAccessChecker({
            authorityType,
            ftEditorName,
            hasLock,
          });

        if (fillInTableConcurrentAccessChecker.canAccess) {
          navigate(url);
        } else {
          onConcurrentAccessDenied();
        }
      } else {
        onAccessDenied();
      }
    } else if (pathname === ROUTE_PATHS.insertImage) {
      const roleChecker = new InsertImageRoleChecker({
        authorityType,
        editorRoles,
        classification: classificationAi,
      });

      if (roleChecker.canAccess) {
        navigate(url);
      } else {
        onAccessDenied();
      }
    } else if (pathname === ROUTE_PATHS.editArticle) {
      const roleChecker = new EditArticleRoleChecker({
        authorityType,
        editorRoles,
        classification: classificationAi,
      });

      if (roleChecker.canAccess) {
        navigate(url);
      } else {
        onAccessDenied();
      }
    } else {
      throw new Error(`${pathname}는 잘못된 경로입니다.`);
    }
  };

  return {
    movePressDetailPage,
  };
};
