import { useCallback } from "react";

import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { PositionInfoProps } from "../ClinicalTrialsTableCreatorShell";
import type { ArticleEntryData } from "@/types/articleEntryData.types";
import type { ClinicalTrialsClinicalResultsShellKey } from "@/types/clinicalTrials.types";

interface UseHandleTableCreatorShellReturn {
  handleClickToClearResultsShell: () => void;
  handleKeyDownToClearResultsShell: (event: KeyboardEvent) => void;
}

export const useHandleClinicalTrialsResultsShell = (
  positionPropsInfo: PositionInfoProps,
): UseHandleTableCreatorShellReturn => {
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const handleClearShell = useCallback(() => {
    const clearClinicalResultsShell = (): ArticleEntryData => {
      const targetData =
        entryData["Clinical Trials"]?.["Clinical Results"]?.[
          "Results Classification"
        ];

      return {
        ...entryData,
        "Clinical Trials": {
          ...entryData["Clinical Trials"],
          "Clinical Results": {
            ["Results Classification" as ClinicalTrialsClinicalResultsShellKey]:
              {
                ...targetData,
                value: [],
              },
          },
        },
      } as ArticleEntryData;
    };

    if (info.groupTableKey === "Clinical Results") {
      setEntryData(clearClinicalResultsShell());
      changeCurrentHistory({
        entryData: clearClinicalResultsShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  const handleClickToClearResultsShell = () => {
    if (focusStatus.type === "clear") {
      handleClearShell();
    }
  };

  const handleKeyDownToClearResultsShell = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearShell();
      }
    },
    [focusStatus.type, handleClearShell],
  );

  return {
    handleClickToClearResultsShell,
    handleKeyDownToClearResultsShell,
  };
};
