import { useScroll } from "@FillInTableContainer/components/FillInTable/hooks/useScroll";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { TableCreatorGroupTableTitle } from "@FillInTableContainer/shared";
import TableDragDivider from "@FillInTableContainer/shared/components/TableDragDivider/TableDragDivider";
import { useCanEditGroupTable } from "@FillInTableContainer/shared/hooks/useCanEditGroupTable";
import useCheckIsDisplayTableDragDivider from "@FillInTableContainer/shared/hooks/useCheckIsDisplayTableDragDivider";
import { useGetNumberOfTable } from "@FillInTableContainer/shared/hooks/useGetNumberOfTable";
import { useHandleTableCreatorTableDrag } from "@FillInTableContainer/shared/hooks/useHandleTableCreatorTableDrag";
import { TableCreatorTableList } from "@FillInTableContainer/shared/types/tableCreator.types";
import { isRequiredGroupTable } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getGroupTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import RegulatoryTableCreatorTable from "./components/RegulatoryTableCreatorTable/RegulatoryTableCreatorTable";
import { useHandleRegulatoryTableCreatorGroupTable } from "./hooks/useHandleRegulatoryTableCreatorGroupTable";
import * as S from "./RegulatoryTableCreatorGroupTable.style";

import type { RegulatorySection } from "@/types/regulatory.types";

interface Props {
  groupTableKey: RegulatorySection;
  tableList: TableCreatorTableList;
  groupTableIndex: number;
}

const ARTICLE_SECTION = "Regulatory";

const RegulatoryTableCreatorGroupTable = ({
  tableList,
  groupTableKey,
  groupTableIndex,
}: Props) => {
  const { register } = useScroll();
  const { tableCount } = useGetNumberOfTable();
  const articleSection = useArticleSection();

  const { handleAddTable } = useHandleRegulatoryTableCreatorGroupTable({
    groupTableKey,
  });

  const { checkDisableEdit } = useCanEditGroupTable();

  const {
    handleDragEnd,
    handleDragOn,
    handleDragOver,
    handleDragStart,
    draggedIndexInfo,
    isDraggingTable,
  } = useHandleTableCreatorTableDrag(ARTICLE_SECTION);

  const { isLastTable, handleCheckDragDividerDisplay } =
    useCheckIsDisplayTableDragDivider();

  return (
    <S.GroupTableWrapper
      isDisableEdit={checkDisableEdit(groupTableKey)}
      {...register(groupTableKey)}
    >
      <TableCreatorGroupTableTitle
        title={getGroupTableTitle(articleSection, groupTableKey)}
        isRequired={isRequiredGroupTable(articleSection, groupTableKey)}
      />
      <S.TableCreatorTableListWrapper
        isDisableEdit={checkDisableEdit(groupTableKey)}
      >
        {newArrayToCountValue(tableCount(groupTableKey)).map(
          (_, tableIndex) => (
            <S.DraggableTableWrapper
              key={`table-${String(groupTableIndex)}-${String(tableIndex)}`}
              isTargetDraggingTable={
                isDraggingTable && draggedIndexInfo[0] !== tableIndex
              }
              onDragOver={(e) => handleDragOver(e, tableIndex)}
            >
              <TableDragDivider
                isDraggingEndPosition={handleCheckDragDividerDisplay({
                  moveDragIndex: draggedIndexInfo[1],
                  startDragIndex: draggedIndexInfo[0],
                  tableIndex,
                  currentGroupTableKey: groupTableKey,
                  isDraggingTable,
                })}
              />
              {tableList.map(({ tableKey, groupShellList }) => (
                <RegulatoryTableCreatorTable
                  key={`table-${String(groupTableIndex)}-${String(tableIndex)}`}
                  tableKey={tableKey}
                  groupShellList={groupShellList}
                  groupTableKey={groupTableKey}
                  groupTableIndex={groupTableIndex}
                  tableIndex={tableIndex}
                  tableCount={tableCount(groupTableKey)}
                  handleAddTable={handleAddTable}
                  handleDragStart={handleDragStart}
                  handleDragEnd={handleDragEnd}
                  handleDragOn={handleDragOn}
                  isDraggableTable={isDraggingTable}
                />
              ))}
              {isLastTable(tableIndex, groupTableKey) && (
                <TableDragDivider
                  isDraggingEndPosition={handleCheckDragDividerDisplay({
                    moveDragIndex: draggedIndexInfo[1],
                    startDragIndex: draggedIndexInfo[0],
                    tableIndex,
                    currentGroupTableKey: groupTableKey,
                    isDraggingTable,
                    lastIndex: newArrayToCountValue(tableCount(groupTableKey))
                      .length,
                  })}
                />
              )}
            </S.DraggableTableWrapper>
          ),
        )}
      </S.TableCreatorTableListWrapper>
    </S.GroupTableWrapper>
  );
};

export default RegulatoryTableCreatorGroupTable;
