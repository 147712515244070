"use client";

import styled from "styled-components";

export const ItemsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
`;

export const Item = styled.li`
  position: relative;
  width: 100%;
  max-height: 3.2rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 130%;
  padding-left: 1.1rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  text-overflow: ellipsis;
`;

export const BulletWrapper = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.8rem;
  height: 1.4rem;
  font-size: 1.6rem;
  font-weight: 800;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  line-height: 100%;
`;
