"use client";

import styled from "styled-components";

export const DBDetailSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  padding-bottom: 4rem;
`;
