import React, { useState } from "react";

import { IconEdit } from "@frontend/common";

import { separateTextAndTranslateCode } from "@/components/shared/utils/separateTextAndTranslatedCode/separateTextAndTranslatedCode";

import * as S from "./TopFixInfoItem.style";
import EditPressDateInput from "../EditPressDateInput/EditPressDateInput";
import PressDuplicateButton from "../PressDuplicateButton/PressDuplicateButton";
import TextHoverTooltip from "../TextHoverTooltip/TextHoverTooltip";

interface Props {
  title: string;
  content: string;
  subContent?: string;
  maxWidth: string;
  minWidth: string;
  width?: string;
  isFlex?: boolean;
}

const TopFixInfoItem = ({
  title,
  content,
  subContent,
  maxWidth,
  minWidth,
  width,
  isFlex,
}: Props) => {
  const [targetShell, setTargetShell] = useState("none");
  const [isEditPressDate, setIsEditPressDate] = useState(false);
  const handleCheckTextOverflow = (e: React.MouseEvent) => {
    if (e.currentTarget.clientHeight >= e.currentTarget.scrollHeight) {
      return false;
    }

    return true;
  };

  const handleChangeHoverCompanyShell = (
    e: React.MouseEvent,
    target: string,
  ) => {
    if (!handleCheckTextOverflow(e)) return;
    setTargetShell(target);
  };

  const { text, code } = separateTextAndTranslateCode(content);

  if (title === "Press Date") {
    return (
      <S.TopFixInfoItemWrapper
        $maxWidth={maxWidth}
        $minWidth={minWidth}
        $width={width}
        $isFlex={isFlex}
        className="press-date-item"
        onClick={() => {
          setIsEditPressDate(true);
        }}
      >
        <S.TopFixInfoTitle className="press-date-item-title">
          {title}
          <IconEdit width={16} height={16} color="#515C6C" />
        </S.TopFixInfoTitle>
        <S.TopFixInfoContent
          className="press-date-item-content"
          onMouseOver={(e) => {
            handleChangeHoverCompanyShell(e, title);
          }}
          onMouseLeave={(e) => {
            handleChangeHoverCompanyShell(e, "none");
          }}
        >
          {text || "-"}
          {isEditPressDate ? (
            <EditPressDateInput
              manualPressDate={subContent}
              changeStatus={() => {
                setIsEditPressDate(false);
              }}
            />
          ) : subContent ? (
            <S.ManualPressDateWrapper>
              {`(${subContent})`}
            </S.ManualPressDateWrapper>
          ) : (
            <></>
          )}
        </S.TopFixInfoContent>
        {targetShell === title && (
          <TextHoverTooltip
            text={subContent ? `${content} (${subContent})` : content}
          />
        )}
      </S.TopFixInfoItemWrapper>
    );
  }

  return (
    <S.TopFixInfoItemWrapper
      $maxWidth={maxWidth}
      $minWidth={minWidth}
      $width={width}
      $isFlex={isFlex}
    >
      <S.TopFixInfoTitle>
        {title}
        <PressDuplicateButton title={title} />
      </S.TopFixInfoTitle>
      <S.TopFixInfoContent
        onMouseOver={(e) => {
          handleChangeHoverCompanyShell(e, title);
        }}
        onMouseLeave={(e) => {
          handleChangeHoverCompanyShell(e, "none");
        }}
      >
        {text || "-"}
        {code && <S.TranslatedId> {code}</S.TranslatedId>}
      </S.TopFixInfoContent>
      {targetShell === title && <TextHoverTooltip text={content} />}
    </S.TopFixInfoItemWrapper>
  );
};

export default TopFixInfoItem;
