import styled, { css } from "styled-components";

import IconCheck from "@images/pressClassification/icon-checkbox.svg";

export const CheckItemLabelWrapper = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 0.4rem;
  padding: 0.4rem;
  cursor: pointer;
`;

export const Checkbox = styled.input<{ isTarget: boolean }>`
  all: unset;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: var(--Border-Radius-2, 0.2rem);
  border: 1.5px solid var(--Color-Common-Border-Interactive-Secondary, #b1b8c4);

  ${({ isTarget }) =>
    isTarget &&
    css`
      border-radius: var(--Border-Radius-2, 0.2rem);
      border: 1.5px solid var(--Color-Bg-Inverse-Error, #db0f72);
      background: var(--Color-Bg-Inverse-Error, #db0f72);
      background-image: url(${IconCheck});
      background-repeat: no-repeat;
      background-position: center;
    `}
`;

export const CheckItemLabel = styled.span<{ isTarget: boolean }>`
  display: inline-block;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: left;
  color: var(--Color-Common-Text-Primary, #2a2f37);
`;
