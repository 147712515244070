import { apiClient } from "@api/apiClient";

import { PostCompanyProfileResponse } from "./companyProfile.interface";

const API_ENDPOINT = {
  post: "/official/company/profile-by-name",
};

export const companyProfileApis = {
  post: async (companyNameList: string[]) => {
    const response = await apiClient.post<PostCompanyProfileResponse>(
      API_ENDPOINT.post,
      companyNameList,
    );

    return response;
  },
};
