import {
  CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS,
  ENTRY_DATA_SECTION_KEYS,
} from "@frontend/common";

import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";
import type { ClinicalTrialsSection } from "@/types/clinicalTrials.types";

interface Returns {
  clearDesign2SubGroupShell: () => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: ClinicalTrialsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useClearClinicalTrialDesignSubGroupShell = ({
  positionPropsInfo,
}: Props): Returns => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();

  const clearDesign2SubGroupShell = () => {
    const clearClinicalTrialDesignTwoGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: entryData?.[
            ENTRY_DATA_SECTION_KEYS.clinicalTrial
          ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex)
                return {
                  ...tableInfo,
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2]:
                    tableInfo[
                      ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
                    ].map((subGroupShellInfo, subGroupShellInfoIndex) => {
                      if (subGroupShellInfoIndex === info.subGroupShellIndex)
                        return {
                          ...subGroupShellInfo,
                          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.armName]: {
                            ...subGroupShellInfo[
                              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.armName
                            ],
                            value: [],
                          },
                          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.patientsNumber]:
                            {
                              ...subGroupShellInfo[
                                ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS
                                  .patientsNumber
                              ],
                              value: [],
                            },
                          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation]:
                            subGroupShellInfo[
                              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS
                                .designGroupInformation
                            ].map((shellListInfo) => {
                              return {
                                ...shellListInfo,
                                [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.does]:
                                  {
                                    ...shellListInfo[
                                      ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS
                                        .does
                                    ],
                                    value: [],
                                  },
                                [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.drugName]:
                                  {
                                    ...shellListInfo[
                                      ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS
                                        .drugName
                                    ],
                                    value: [],
                                  },
                                [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.duration]:
                                  {
                                    ...shellListInfo[
                                      ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS
                                        .duration
                                    ],
                                    value: [],
                                  },
                              };
                            }),
                        };

                      return subGroupShellInfo;
                    }),
                };

              return tableInfo;
            },
          ),
        },
      };
    };

    if (
      info.subGroupShellKey === CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS.arm
    ) {
      const armGroupShellLength =
        entryData?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN
        ]?.[info.tableIndex][
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
        ]?.length || 0;
      setEntryData(clearClinicalTrialDesignTwoGroupShell());
      changeCurrentHistory({
        entryData: clearClinicalTrialDesignTwoGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      if (armGroupShellLength > 1)
        changeFocusStatus({
          type: "delete",
          container: "subGroupShell",
        });
      else {
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
        changeInfo(DEFAULT_POSITION_INFO);
      }
    }
  };

  return {
    clearDesign2SubGroupShell,
  };
};
