import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

type TargetArticleSection =
  | "Expedited Program"
  | "Clinical Trials"
  | "Clinical Trial Status"
  | "Regulatory"
  | "Deals";
export const useGenericBiosimilarCheckboxEvent = () => {
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const articleSection = useArticleSection();

  const checkGenericBiosimilar = (isChecked: boolean, tableIndex: number) => {
    const newGenericBiosimilarValue =
      isChecked === false ? ["true"] : ["false"];

    const changedEntryData = {
      [articleSection as TargetArticleSection]: {
        ...entryData[articleSection],
        "Drug Profile": entryData[articleSection]?.["Drug Profile"]?.map(
          (tableItems, tableItemsIndex) => {
            if (tableItemsIndex === tableIndex) {
              return {
                ...tableItems,
                "Generic / Biosimilar": {
                  ...tableItems["Generic / Biosimilar"],
                  value: newGenericBiosimilarValue,
                },
              };
            }
            return tableItems;
          },
        ),
      },
    };

    setEntryData(changedEntryData);
  };
  return { checkGenericBiosimilar };
};
