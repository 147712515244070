import { useEffect, useRef } from "react";

import { LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS } from "@frontend/common";
import { DealsSection } from "@frontend/common/src/types/entryData/deals.types";

import GroupShellTitleDescriptionContainer from "@FillInTableContainer/shared/components/GroupShellTitleDescriptionContainer/GroupShellTitleDescriptionContainer";
import TableDragDivider from "@FillInTableContainer/shared/components/TableDragDivider/TableDragDivider";
import useCheckIsDisplayGroupShellDragDivider from "@FillInTableContainer/shared/hooks/useCheckIsDisplayGroupShellDragDivider";
import { useHandleTableCreatorGroupShellDrag } from "@FillInTableContainer/shared/hooks/useHandleTableCreatorGroupShellDrag";
import { TableCreatorSubGroupGroupShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import { isAddDeleteGroupShell } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getGroupShellTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import DealTermsLicenseAgreementGroupShell from "./components/DealTermsLicenseAgreementGroupShell/DealTermsLicenseAgreementGroupShell";
import * as S from "./DealTermsLicenseAgreementGroupShellSection.style";
import useDealTermsLicenseeAgreementTableCreatorGroupShellSection from "./hooks/useDealTermsLicenseeAgreementTableCreatorGroupShellSection";

interface Props {
  groupTableKey: DealsSection;
  groupTableIndex: number;
  groupShellSectionKey: string | null;
  groupShellList: TableCreatorSubGroupGroupShellList;
  tableKey: string | null;
  tableIndex: number;
  groupShellSectionIndex: number;
  sellerByDealType: string;
  buyerByDealType: string;
}

const ARTICLE_SECTION = "Deals";

const isDraggingGroupShellKey = "Rights Scope";

const DealTermsLicenseAgreementGroupShellSection = ({
  groupShellSectionIndex,
  groupShellSectionKey,
  groupTableIndex,
  groupTableKey,
  groupShellList,
  tableIndex,
  tableKey,
  sellerByDealType,
  buyerByDealType,
}: Props) => {
  const groupShellSectionProps = {
    groupShellSectionIndex,
    groupShellSectionKey,
    groupTableIndex,
    groupTableKey,
    tableIndex,
    tableKey,
  };

  const getGroupShellDescription = (targetKey: string | null) => {
    if (!targetKey) return "";
    if (
      targetKey === LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS.financialTerms
    ) {
      return `(${buyerByDealType} -> ${sellerByDealType})`;
    }
    if (targetKey === LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS.rightsScope) {
      return `(${sellerByDealType} -> ${buyerByDealType})`;
    }
    return "";
  };

  const {
    numberOfGroupShellCount,
    handleFocusGroupShellSection,
    handleClickToClearGroupShellSection,
    handleDefaultFocus,
    isGroupShellSectionFocus,
    isClearGroupShellSection,
  } = useDealTermsLicenseeAgreementTableCreatorGroupShellSection(
    groupShellSectionProps,
  );

  const {
    handleGroupShellDragEnd,
    handleGroupShellDragOn,
    handleGroupShellDragOver,
    handleGroupShellDragStart,
    groupShellDraggedIndexInfo,
    isDraggingGroupShell,
  } = useHandleTableCreatorGroupShellDrag(ARTICLE_SECTION, tableIndex);

  const { isLastGroupShell, handleCheckDragDividerDisplay } =
    useCheckIsDisplayGroupShellDragDivider(
      tableIndex,
      isDraggingGroupShell,
      isDraggingGroupShellKey,
    );

  const titleRef = useRef<HTMLDivElement>(null);
  const clearButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleCheckClickOutside = (e: MouseEvent) => {
      if (isGroupShellSectionFocus) {
        const target = e.target as HTMLElement;
        const clickSelectButton = clearButtonRef.current?.contains(target);
        const clickGroupShellTitle = titleRef.current?.contains(target);

        if (clickSelectButton || clickGroupShellTitle) return;
        if (target.dataset.button) return;

        handleDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutside);

    return () => window.removeEventListener("click", handleCheckClickOutside);
  }, [handleDefaultFocus, isGroupShellSectionFocus]);

  return (
    <S.GroupShellSectionWrapper
      key={`groupShellList-${String(groupShellSectionIndex)}`}
      isFocused={isGroupShellSectionFocus}
    >
      {isClearGroupShellSection && (
        <S.FixedDeleteButtonWrapper
          isShellTitle={
            !!getGroupShellTitle(ARTICLE_SECTION, groupShellSectionKey)
          }
          ref={clearButtonRef}
        >
          <S.DeleteGroupShellSectionButtonWrapper>
            <S.DeleteGroupShellSectionButton
              data-button="delete-button"
              onClick={handleClickToClearGroupShellSection}
            >
              <img src={IconXMark} alt="button for clear Row" />
              Clear
            </S.DeleteGroupShellSectionButton>
          </S.DeleteGroupShellSectionButtonWrapper>
        </S.FixedDeleteButtonWrapper>
      )}
      <div ref={titleRef}>
        <GroupShellTitleDescriptionContainer
          mainGroupShellTitle={getGroupShellTitle(
            ARTICLE_SECTION,
            groupShellSectionKey,
          )}
          description={getGroupShellDescription(groupShellSectionKey)}
          onClickHandler={handleFocusGroupShellSection}
        />
      </div>
      {groupShellList.map(({ groupShellKey, subGroupShellList }) => (
        <>
          {newArrayToCountValue(numberOfGroupShellCount(groupShellKey)).map(
            (_, groupShellIndex) => (
              <S.DraggableGroupShellWrapper
                isTargetDraggingTable={
                  isDraggingGroupShell &&
                  groupShellDraggedIndexInfo[0] !== groupShellIndex
                }
                onDragOver={(e) => handleGroupShellDragOver(e, groupShellIndex)}
              >
                {isAddDeleteGroupShell(ARTICLE_SECTION, groupShellKey) && (
                  <TableDragDivider
                    isDraggingEndPosition={handleCheckDragDividerDisplay({
                      moveDragIndex: groupShellDraggedIndexInfo[1],
                      startDragIndex: groupShellDraggedIndexInfo[0],
                      groupShellIndex,
                      groupShellKey,
                    })}
                  />
                )}
                <DealTermsLicenseAgreementGroupShell
                  key={`group-shell-${String(groupTableIndex)}-${String(
                    tableIndex,
                  )}-${String(groupShellIndex)}`}
                  subGroupShellList={subGroupShellList}
                  groupTableKey={groupTableKey}
                  groupTableIndex={groupTableIndex}
                  groupShellSectionIndex={groupShellSectionIndex}
                  groupShellSectionKey={groupShellSectionKey}
                  tableKey={tableKey}
                  tableIndex={tableIndex}
                  groupShellKey={groupShellKey}
                  groupShellIndex={groupShellIndex}
                  groupShellCount={numberOfGroupShellCount(groupShellKey)}
                  handleDragEnd={handleGroupShellDragEnd}
                  handleDragOn={handleGroupShellDragOn}
                  handleDragStart={handleGroupShellDragStart}
                  isDraggableGroupShell={isDraggingGroupShell}
                  isFocusGroupShellContainer={isGroupShellSectionFocus}
                />
                {isAddDeleteGroupShell(ARTICLE_SECTION, groupShellKey) &&
                  isLastGroupShell(groupShellIndex, groupShellKey) && (
                    <TableDragDivider
                      isDraggingEndPosition={handleCheckDragDividerDisplay({
                        moveDragIndex: groupShellDraggedIndexInfo[1],
                        startDragIndex: groupShellDraggedIndexInfo[0],
                        groupShellIndex,
                        groupShellKey,
                        lastIndex: newArrayToCountValue(
                          numberOfGroupShellCount(groupShellKey),
                        ).length,
                      })}
                    />
                  )}
              </S.DraggableGroupShellWrapper>
            ),
          )}
        </>
      ))}
    </S.GroupShellSectionWrapper>
  );
};

export default DealTermsLicenseAgreementGroupShellSection;
