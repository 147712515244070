import { useAuthorityTypeQuery } from "@/queries/hooks/useAuthorityType";

import { AUTHORITY_TYPE } from "../constants/authorityType.constants";

import type { AuthorityType } from "../types/roles.types";

type UseGetAuthorityTypeReturn = AuthorityType;

export const useAuthorityType = (): UseGetAuthorityTypeReturn => {
  const { data: isSuperAdmin } = useAuthorityTypeQuery();

  return isSuperAdmin ? AUTHORITY_TYPE.superAdmin : AUTHORITY_TYPE.empty;
};

export const checkSuperAdmin = (authorityType: AuthorityType) =>
  authorityType === AUTHORITY_TYPE.superAdmin;
