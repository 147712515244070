import styled, { css } from "styled-components";

export const TopFixInfoListWrapper = styled.div<{
  $minWidth: string;
  $maxWidth: string;
  $isPressClassification: boolean;
}>`
  display: flex;
  min-width: ${({ $minWidth }) => $minWidth};
  max-width: ${({ $maxWidth }) => $maxWidth};
  height: 100%;

  ${({ $isPressClassification }) =>
    !$isPressClassification &&
    css`
      width: 100%;
    `}
`;
