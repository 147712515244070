import styled, { css } from "styled-components";

export const SelectBoxWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  z-index: 1;
`;

export const SelectBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 6rem;
  height: 2.4rem;
  border: 1px solid ${({ theme }) => theme.color.neural.gray02};
  background-color: ${({ theme }) => theme.color.neural.gray00};
  border-radius: 0.4rem;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  z-index: 10;
`;

export const SelectBoxText = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 130%;
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  color: ${({ theme }) => theme.color.neural.gray08};
`;

export const DefaultText = styled.span`
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 130%;
  color: ${({ theme }) => theme.color.neural.gray08};
`;

export const OptionBox = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 3.2rem;
  width: 6rem;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.color.neural.gray00};

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      box-shadow: 0px 8px 8px 1px rgba(0, 0, 0, 0.04);
    `}

  z-index: 10;
  overflow: hidden;
`;

export const DropDown = styled.div<{ $isOpen: boolean }>`
  width: 6rem;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          height: auto;
          border: 0.1rem solid ${({ theme }) => theme.color.neural.gray03};
        `
      : css`
          height: 0px;
        `}

  button {
    display: flex;
    align-items: center;
    width: 100%;
    font-family: ${({ theme }) => theme.font.fontFamily.jost};
    font-size: 1.4rem;
    font-weight: 600;
    color: ${({ theme }) => theme.color.neural.gray08};
    text-align: left;
    line-height: 130%;
    padding: 0.8rem;
    cursor: pointer;

    &:first-child {
      height: 2.6rem;
      padding-top: 1rem;
    }
  }
`;

export const Background = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;
