import React from "react";

import {
  DEFAULT_IMAGE_FILTER,
  useInsertImageFilter,
} from "@InsertImageContainer/shared/contexts/InsertImageFilterContext";
import { useInsertImageHistoryInfo } from "@InsertImageContainer/shared/contexts/InsertImageHistoryInfoContext";

import * as S from "./InsertImageNoDataContainer.style";

const InsertImageNoDataContainer = () => {
  const { changeInsertImageFilter } = useInsertImageFilter();
  const { insertImageHistoryInfo } = useInsertImageHistoryInfo();

  const handleResetSearchFilter = () => {
    changeInsertImageFilter(
      insertImageHistoryInfo[0].type === "select"
        ? { ...insertImageHistoryInfo[0]?.imageFilter }
        : DEFAULT_IMAGE_FILTER,
    );
  };
  return (
    <S.Wrapper>
      <S.Description>
        No images found matching the keyword.
        <br />
        Please try another keyword.
      </S.Description>
      <S.ResetButton type="button" onClick={handleResetSearchFilter}>
        Reset Search
      </S.ResetButton>
    </S.Wrapper>
  );
};

export default InsertImageNoDataContainer;
