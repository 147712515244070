import styled from "styled-components";

import {
  DefaultDraggableTableWrapper,
  DefaultGroupTableWrapper,
  DefaultTableListWrapper,
} from "@FillInTableContainer/shared/styles/groupTable.style";

export const GroupTableWrapper = styled(DefaultGroupTableWrapper)``;

export const TableCreatorTableListWrapper = styled(DefaultTableListWrapper)``;

export const DraggableTableWrapper = styled(DefaultDraggableTableWrapper)``;
