import dayjs from "dayjs";

import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import {
  REF_CODE_APPROVAL_PROCESS_ADDITIONAL_INFORMATION_ON_APPROVAL_PROCESS,
  REF_CODE_APPROVAL_PROCESS_APPLICATION_TYPE,
  REF_CODE_APPROVAL_PROCESS_AUTHORIZED_COMPANY,
  REF_CODE_APPROVAL_PROCESS_DOSAGE_AND_ADMINISTRATION_ON_LABEL,
  REF_CODE_APPROVAL_PROCESS_INDICATION_ON_LABEL,
  REF_CODE_APPROVAL_PROCESS_MARKETING_STATUS,
  REF_CODE_APPROVAL_PROCESS_REGULATORY_AGENCY,
  REF_CODE_APPROVAL_PROCESS_UPDATE_DATE,
  REF_CODE_SEPARATOR,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createRefCode } from "@EditArticleContainer/shared/libs/refCode/utils/createRefCode";
import { ApprovalProcessContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/approvalProcessContent.types";
import { createContentFromHTML } from "@EditArticleContainer/shared/utils/getValueFromRefMap";

export const useCreateApprovalProcessContent = () => {
  const refMap = useArticleContentRefMap();

  const indexList = [...refMap.keys()]
    .filter(
      (key) => key.indexOf(REF_CODE_APPROVAL_PROCESS_MARKETING_STATUS) !== -1,
    ) // MarketingStatus를 토대로 Drug Profile의 개수를 파악합니다.
    .map((key) => key.split(REF_CODE_SEPARATOR)[1]);

  return () => {
    const content: ApprovalProcessContent = {
      text: {
        "Approval Process": {
          "Approval Process": indexList.map((index) => ({
            "Marketing Status": createContentFromHTML(
              refMap,
              createRefCode(REF_CODE_APPROVAL_PROCESS_MARKETING_STATUS, index),
            ),
            "Indication on Label": createContentFromHTML(
              refMap,
              createRefCode(
                REF_CODE_APPROVAL_PROCESS_INDICATION_ON_LABEL,
                index,
              ),
            ),
            "Dosage and Administration on Label": createContentFromHTML(
              refMap,
              createRefCode(
                REF_CODE_APPROVAL_PROCESS_DOSAGE_AND_ADMINISTRATION_ON_LABEL,
                index,
              ),
            ),
            "Regulatory Agency": createContentFromHTML(
              refMap,
              createRefCode(REF_CODE_APPROVAL_PROCESS_REGULATORY_AGENCY, index),
            ),
            "Application Type": createContentFromHTML(
              refMap,
              createRefCode(REF_CODE_APPROVAL_PROCESS_APPLICATION_TYPE, index),
            ),
            "Authorized Company": createContentFromHTML(
              refMap,
              createRefCode(
                REF_CODE_APPROVAL_PROCESS_AUTHORIZED_COMPANY,
                index,
              ),
            ),
            "Update Date": createContentFromHTML(
              refMap,
              createRefCode(REF_CODE_APPROVAL_PROCESS_UPDATE_DATE, index),
            ),
          })),
          "Additional Information on the Approval Process":
            createContentFromHTML(
              refMap,
              REF_CODE_APPROVAL_PROCESS_ADDITIONAL_INFORMATION_ON_APPROVAL_PROCESS,
            ),
        },
      },
      labels: [],
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
