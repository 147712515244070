import { SectionCode } from "@frontend/common";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";

import { queryClient } from "@api/queryClient";
import { QUERY_KEYS } from "@api/queryKeys";
import { ParagraphCode } from "@EditArticleContainer/shared/types/editArticle.types";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { GetAllDataResponse } from "./articleContentData.interface";
import { articleContentDataApis } from "./articleContentData.service";

export const useArticleContentDataQuery = (
  options: {
    refetchInterval?: number | false;
    enabled?: boolean;
  } = {},
) => {
  return useSuspenseQuery({
    queryKey: [QUERY_KEYS.articleContentData, getPressIdFromURL()],
    queryFn: () =>
      articleContentDataApis.getEditArticleData(getPressIdFromURL()),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    ...options,
  });
};

export const invalidateArticleContentDataQuery = (articleId: string) => {
  return queryClient.invalidateQueries({
    queryKey: [QUERY_KEYS.articleContentData, articleId],
  });
};

export const useUpdateArticleContentDataMutation = () => {
  return useMutation({ mutationFn: articleContentDataApis.update });
};

export const useUpdateArticleContentOriginDataMutation = () => {
  return useMutation({ mutationFn: articleContentDataApis.updateOrigin });
};

export const useRegenerateArticleContentDataMutation = () => {
  return useMutation({ mutationFn: articleContentDataApis.getFillInTableData });
};

export const useCreateArticleContentDataMutation = () => {
  return useMutation({
    mutationFn: (args: { pressId: string; sectionCode: SectionCode }) =>
      articleContentDataApis.create(args.pressId, args.sectionCode),
  });
};

export const useGetAllArticleDataMutation = () => {
  return useMutation({
    mutationFn: (args: {
      articleId: string;
      pressType: string;
      paragraphCode: string;
    }) =>
      articleContentDataApis.getAllArticleData(
        args.articleId,
        args.pressType,
        args.paragraphCode,
      ),
  });
};

interface GetAllDataArgs {
  articleId: string;
  sectionCode: string;
  paragraphCode: string;
}

export const useAllArticleDataQuery = ({
  articleId,
  sectionCode,
  paragraphCode,
}: GetAllDataArgs) => {
  return useSuspenseQuery({
    queryKey: [QUERY_KEYS.allArticleData, getPressIdFromURL(), paragraphCode],
    queryFn: () =>
      articleContentDataApis.getAllArticleData(
        articleId,
        sectionCode,
        paragraphCode,
      ),
  });
};

export const getAllArticleData = (paragraphCode: string) => {
  return queryClient.getQueryData<GetAllDataResponse>([
    QUERY_KEYS.allArticleData,
    getPressIdFromURL(),
    paragraphCode,
  ]);
};

export const fetchAllArticleData = async ({
  articleId,
  sectionCode,
  paragraphCode,
}: GetAllDataArgs) => {
  return queryClient.fetchQuery<GetAllDataResponse>({
    queryKey: [QUERY_KEYS.allArticleData, getPressIdFromURL(), paragraphCode],
    queryFn: () =>
      articleContentDataApis.getAllArticleData(
        articleId,
        sectionCode,
        paragraphCode,
      ),
  });
};

export const invalidateAllArticleDataQuery = (
  articleId: string,
  paragraphCode?: ParagraphCode,
) => {
  if (!paragraphCode) {
    return queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.allArticleData, articleId],
    });
  }

  return queryClient.invalidateQueries({
    queryKey: [QUERY_KEYS.allArticleData, articleId, paragraphCode],
  });
};
