import styled from "styled-components";

export const TooltipWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  border: 1px solid #e5e7eb;
  background: white;
  white-space: nowrap;

  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.16);

  color: #2a2f37;
  font-family: ${({ theme }) => theme.font.fontFamily.pretendard};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 130%;
  z-index: 100;
`;
