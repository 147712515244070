import { useMutation } from "@tanstack/react-query";

import { queryClient } from "@api/queryClient";
import { QUERY_KEYS } from "@api/queryKeys";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { toastFail } from "@utils/toast";

import { UpdatePressInfoRequest } from "./pressInfo.interface";
import { draftPressInfoApis } from "./pressInfo.service";

export const useUpdatePressInfoMutate = () => {
  const pressId = getPressIdFromURL();
  const mutate = useMutation({
    mutationFn: (updatedPressInfo: UpdatePressInfoRequest) =>
      draftPressInfoApis.put({
        ...updatedPressInfo,
      }),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [QUERY_KEYS.articleInfo, Number(pressId)],
      });
    },
    onError: () => {
      toastFail("Failed to update press date");
    },
  });

  return mutate;
};
