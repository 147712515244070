import { ForwardedRef, forwardRef } from "react";

import useArticleSection from "@/components/business/FillInTableContainer/hooks/useArticleSection";
import { TargetListType } from "@FillInTableContainer/shared/hooks/useHandleTargetListIndex";
import { getShellColumnSize } from "@FillInTableContainer/shared/utils/getOptionsFromData";

import TableCreatorAiDropdown from "./components/TableCreatorAiDropdown/TableCreatorAiDropdown";
import TableCreatorDataSetDropdown from "./components/TableCreatorDataSetDropdown/TableCreatorDataSetDropdown";
import TableCreatorDictionaryDropdown from "./components/TableCreatorDictionaryDropdown/TableCreatorDictionaryDropdown";
import { useClickDropdownItem } from "./hooks/useClickDropdownItem";
import { useHandleTableCreatorListBoxScroll } from "./hooks/useHandleTableCreatorListBoxScroll";
import * as S from "./TableCreatorListBox.style";
import {
  AIDropdownListFormat,
  DataSetDropdownList,
  DefaultDataSetType,
  DictionaryList,
} from "./types/tableCreatorDropdown.types";
import { getDefaultInfoOfAiList } from "./utils/getDefaultInfoOfAiList";

interface Props {
  shellKey: string;
  targetListIndex: TargetListType;
  isDictionaryList: boolean;
  isDataSetList: boolean;
  dataSetList: DataSetDropdownList[];
  aiList: AIDropdownListFormat[];
  dictionaryList: DictionaryList;
  changeSelectedValue: (value: string) => void;
  handleChangeDataSetValue: (value: DefaultDataSetType) => void;
  changeTextFieldMarginBottom: (margin: number) => void;
  checkIndexOfValueInDefaultAiList: () => number;
}

const TableCreatorListBox = forwardRef(
  (
    {
      shellKey,
      targetListIndex,
      isDictionaryList,
      aiList,
      dictionaryList,
      isDataSetList,
      dataSetList,
      changeSelectedValue,
      changeTextFieldMarginBottom,
      handleChangeDataSetValue,
      checkIndexOfValueInDefaultAiList,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const articleSection = useArticleSection();
    const { targetItemRef } = useHandleTableCreatorListBoxScroll({
      targetListIndex,
    });

    const {
      handleClickAIItem,
      handleClickDictionaryItem,
      handleClickDataSetItem,
    } = useClickDropdownItem({
      changeSelectedValue,
      changeTextFieldMarginBottom,
      handleChangeDataSetValue,
    });

    const { isAiList, aiListSet } = getDefaultInfoOfAiList(aiList);

    if (!isAiList && !isDictionaryList && !isDataSetList) return null;

    return (
      <S.ListBoxWrapper
        columnSize={getShellColumnSize(articleSection, shellKey)}
        ref={ref}
      >
        <S.ListScrollWrapper>
          {isDataSetList && (
            <TableCreatorDataSetDropdown
              dataSetList={dataSetList}
              targetItemRef={targetItemRef}
              targetListIndex={targetListIndex}
              handleClickDataSetItem={handleClickDataSetItem}
            />
          )}
          {isAiList && (
            <TableCreatorAiDropdown
              aiListSet={aiListSet}
              targetListIndex={targetListIndex}
              handleClickAIItem={handleClickAIItem}
              targetItemRef={targetItemRef}
              checkIndexOfValueInDefaultAiList={
                checkIndexOfValueInDefaultAiList
              }
            />
          )}
          {isDictionaryList && (
            <TableCreatorDictionaryDropdown
              dictionaryList={dictionaryList}
              targetListIndex={targetListIndex}
              targetItemRef={targetItemRef}
              handleClickDictionaryItem={handleClickDictionaryItem}
            />
          )}
        </S.ListScrollWrapper>
      </S.ListBoxWrapper>
    );
  },
);

export default TableCreatorListBox;
