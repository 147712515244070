import { PARAGRAPH_CODE_CLINICAL_RESULTS } from "@EditArticleContainer/shared/constants/editArticle.constants";
import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import { useUpdateParagraph } from "@EditArticleContainer/shared/hooks/useUpdateParagraph";
import { useSectionCodeToClassification } from "@hooks/useSectionCode";

import ClinicalEndpointsDescriptionContent from "./components/ClinicalEndpointsDescriptionContent/ClinicalEndpointsDescriptionContent";
import ClinicalEndpointsTableContainer from "./components/ClinicalEndpointsTableContainer/ClinicalEndpointsTableContainer";
import * as S from "./ParagraphClinicalResults.style";
import { ClinicalResults } from "./utils/clinicalResults";

const ParagraphClinicalResults = () => {
  const paragraph = useParagraphEditedData(PARAGRAPH_CODE_CLINICAL_RESULTS);
  const sectionCode = useSectionCodeToClassification();
  const [
    updateClinicalEndpointsTable,
    loadingClinicalEndpointsTableIndex,
    clinicalEndpointsTableUpdateKeyMap,
  ] = useUpdateParagraph({
    paragraphCode: PARAGRAPH_CODE_CLINICAL_RESULTS,
    sectionCode,
  });
  const [
    updateClinicalEndpointsDescription,
    loadingClinicalEndpointsDescriptionTableIndex,
    clinicalEndpointsDescriptionUpdateKeyMap,
  ] = useUpdateParagraph({
    paragraphCode: PARAGRAPH_CODE_CLINICAL_RESULTS,
    sectionCode,
  });

  if (!paragraph) return null;
  const clinicalResults = new ClinicalResults(paragraph);

  return (
    <S.ParagraphClinicalResultsWrapper>
      <S.ParagraphClinicalResultsIndexWrapper>
        <S.ParagraphClinicalResultsIndex title={ClinicalResults.title} />
      </S.ParagraphClinicalResultsIndexWrapper>
      <S.ParagraphClinicalResultsContentsWrapper
        disabled={loadingClinicalEndpointsTableIndex === 0}
      >
        {clinicalResults.tables
          .filter(({ data }) => !data.isEmpty())
          .map(({ data, key }, index) => (
            <S.ParagraphClinicalResultsContentWrapper
              key={`${key}-${clinicalEndpointsTableUpdateKeyMap.get(index)}`}
              disabled={loadingClinicalEndpointsTableIndex === index}
            >
              <S.ParagraphClinicalResultsUpdateButton
                onClick={() => updateClinicalEndpointsTable(index)}
                disabled={loadingClinicalEndpointsTableIndex === index}
              />
              <ClinicalEndpointsTableContainer
                clinicalEndpointsTable={data}
                tableIndex={index}
              />
            </S.ParagraphClinicalResultsContentWrapper>
          ))}
        {!clinicalResults.description.isEmpty() && (
          <S.ParagraphClinicalResultsContentWrapper
            disabled={loadingClinicalEndpointsDescriptionTableIndex === 0}
          >
            <S.ParagraphClinicalResultsUpdateButton
              onClick={() => updateClinicalEndpointsDescription(0)}
              disabled={loadingClinicalEndpointsDescriptionTableIndex === 0}
            />
            <ClinicalEndpointsDescriptionContent
              key={`${
                clinicalResults.descriptionKey
              }-${clinicalEndpointsDescriptionUpdateKeyMap.get(0)}`}
              clinicalEndpointsDescription={clinicalResults.description}
            />
          </S.ParagraphClinicalResultsContentWrapper>
        )}
      </S.ParagraphClinicalResultsContentsWrapper>
    </S.ParagraphClinicalResultsWrapper>
  );
};

export default ParagraphClinicalResults;
