import { apiClient } from "@api/apiClient";

import {
  PostImageSearchFilterRequest,
  PostImageSearchFilterResponse,
} from "./imageSearch.interface";

const API_ENDPOINTS = {
  search: "des/image/search",
};

export const imageSearchApis = {
  search: async (filter: PostImageSearchFilterRequest) => {
    const { data } = await apiClient.post<PostImageSearchFilterResponse>(
      API_ENDPOINTS.search,
      filter,
    );

    return data;
  },
};
