import { DEAL_TYPE_SHELL_KEYS as SHELL_KEYS } from "@frontend/common";

import { DealsLayoutValue } from "@FillInTableContainer/shared/types/tableCreator.types";

export const DEAL_TYPE_AND_STATUS_LAYOUT: DealsLayoutValue = {
  groupTableKey: "Deal Type & Status",
  tableList: [
    {
      tableKey: null,
      groupShellList: [
        {
          groupShellKey: null,
          shellList: [
            {
              shellKey: SHELL_KEYS.dealName,
            },
            {
              shellKey: SHELL_KEYS.dealType,
            },
            {
              shellKey: SHELL_KEYS.dealStatus,
            },
          ],
        },
      ],
    },
  ],
};
