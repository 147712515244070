"use client";

import styled from "styled-components";

export const ContentsWrapper = styled.div`
  &:last-child {
    margin-bottom: 0;
  }
  .default-table-container {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
    border-left: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
    border-right: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  }
  .top-radius {
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    border-top: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);

    overflow: hidden;
  }
  .bottom-radius {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;

    overflow: hidden;
  }
  .deal-terms-table-value {
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    .value-index-0 {
      font-weight: 600;

      & > div:empty {
        background-color: red;
        padding: 0 !important;
        min-height: 0;
      }

      & > div:empty::before {
        content: "";
      }

      ul {
        padding-left: 0;
      }

      li {
        padding-left: 0rem;
        list-style-type: none;

        &::marker {
          content: "";
        }
      }
    }
    .value-index-1 {
      padding-left: 0.4rem;

      & > div:empty {
        background-color: red;
        padding: 0 !important;
        min-height: 0;
      }

      & > div:empty::before {
        content: "";
      }
    }
  }
`;
