import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconSearch = ({ width = 16, height = 16, color = "#2A2F37" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.44444 3.625C5.33502 3.625 3.625 5.33502 3.625 7.44444C3.625 9.55387 5.33502 11.2639 7.44444 11.2639C8.47167 11.2639 9.40418 10.8584 10.0906 10.1987C10.1062 10.1788 10.1231 10.1597 10.1414 10.1414C10.1597 10.1231 10.1788 10.1062 10.1987 10.0906C10.8584 9.40418 11.2639 8.47167 11.2639 7.44444C11.2639 5.33502 9.55387 3.625 7.44444 3.625ZM11.4438 10.56C12.1144 9.7004 12.5139 8.61908 12.5139 7.44444C12.5139 4.64467 10.2442 2.375 7.44444 2.375C4.64467 2.375 2.375 4.64467 2.375 7.44444C2.375 10.2442 4.64467 12.5139 7.44444 12.5139C8.61908 12.5139 9.7004 12.1144 10.56 11.4438L12.5581 13.4419C12.8021 13.686 13.1979 13.686 13.4419 13.4419C13.686 13.1979 13.686 12.8021 13.4419 12.5581L11.4438 10.56Z"
        fill={color}
      />
    </svg>
  );
};

IconSearch.displayName = "Search";

export default IconSearch;
