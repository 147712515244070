import React, { createContext, useContext, useMemo, useState } from "react";

export interface WrapperPosition {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const DEFAULT_WRAPPER_POSITION_INFO: WrapperPosition = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};

export const TableCreatorWrapperPositionInfoContext = createContext<{
  wrapperPositionInfo: WrapperPosition;
  changeWrapperPositionInfo: (newInfo: WrapperPosition) => void;
}>({
  wrapperPositionInfo: DEFAULT_WRAPPER_POSITION_INFO,
  changeWrapperPositionInfo: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const TableCreatorWrapperPositionInfoProvider = ({
  children,
}: Props) => {
  const [wrapperPositionInfo, setWrapperPositionInfo] =
    useState<WrapperPosition>(DEFAULT_WRAPPER_POSITION_INFO);

  const changeWrapperPositionInfo = (newInfo: WrapperPosition): void => {
    setWrapperPositionInfo(newInfo);
  };

  const positionInfoWrapper = useMemo(
    () => ({ wrapperPositionInfo, changeWrapperPositionInfo }),
    [wrapperPositionInfo],
  );
  return (
    <TableCreatorWrapperPositionInfoContext.Provider
      value={positionInfoWrapper}
    >
      {children}
    </TableCreatorWrapperPositionInfoContext.Provider>
  );
};

export const useTableCreatorWrapperPositionInfo = () => {
  const { wrapperPositionInfo, changeWrapperPositionInfo } = useContext(
    TableCreatorWrapperPositionInfoContext,
  );

  return {
    wrapperPositionInfo,
    changeWrapperPositionInfo,
  };
};
