import React, { useId } from "react";

import {
  useArticleInfoMutation,
  useArticleInfoQuery,
} from "@api/articleInfo/useArticleInfo";
import { useCreateEntryDataMutation } from "@api/createEntryData/createEntryData";
import { useUnsuitablePressCancelMutation } from "@api/unsuitablePress/unsuitablePress";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import {
  checkSuperAdmin,
  useAuthorityType,
} from "@libs/roles/hooks/useAuthorityType";
import { useEditorRoles } from "@libs/roles/hooks/useEditorRoles";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import * as S from "./PublishablePressCheckbox.style";

const PUBLISHABLE_PRESS_TEXT = "Publishable Press";

export type PublishablePressCheckboxStyle = "modal" | "default";

interface Props {
  styleType?: PublishablePressCheckboxStyle;
}

const PublishablePressCheckbox = ({ styleType = "default" }: Props) => {
  const checkboxId = useId();
  const draftArticleId = getPressIdFromURL();

  const { data: articleInfo } = useArticleInfoQuery(Number(draftArticleId));
  const { mutateAsync: createEntryData } = useCreateEntryDataMutation();
  const { mutateAsync: updateArticleInfo } = useArticleInfoMutation();
  const { mutateAsync: turnOffUnsuitable } = useUnsuitablePressCancelMutation();

  const editorRoles = useEditorRoles();
  const authorityType = useAuthorityType();
  const isSuperAdmin = checkSuperAdmin(authorityType);

  const checkDisabledPublishableByRoles = () => {
    const publishEditorCodes = [
      "EDTK0204",
      "EDTK0304",
      "EDTK0404",
      "EDTK0504",
      "EDTK0604",
      "EDTK0704",
      "EDTK0804",
    ];

    const isPublishEditor =
      editorRoles?.filter((role) => publishEditorCodes.includes(role)).length >
      0;
    if (isSuperAdmin) return false;
    if (isPublishEditor) return false;
    return true;
  };

  const handlePublishablePressCheckbox = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (
      event.currentTarget.checked === true &&
      articleInfo.draftArticleStatusHighest === DRAFT_STATUS_CODE.notForAiEntry
    ) {
      await updateArticleInfo({
        publishable: event.currentTarget.checked,
      });
      await createEntryData(Number(draftArticleId));
      return;
    }
    if (
      event.currentTarget.checked === true &&
      articleInfo.draftArticleStatusHighest === DRAFT_STATUS_CODE.unsuitable
    ) {
      await updateArticleInfo({
        publishable: event.currentTarget.checked,
      });
      await turnOffUnsuitable(Number(draftArticleId));
      if (!articleInfo.aiDone) await createEntryData(Number(draftArticleId));
      return;
    }
    await updateArticleInfo({
      publishable: event.currentTarget.checked,
    });
  };

  return (
    <S.PublishableCheckboxLabel $styleType={styleType} htmlFor={checkboxId}>
      <S.PublishableCheckboxInput
        id={checkboxId}
        type="checkbox"
        checked={articleInfo?.publishable}
        onChange={handlePublishablePressCheckbox}
        $styleType={styleType}
        disabled={checkDisabledPublishableByRoles()}
      />
      {PUBLISHABLE_PRESS_TEXT}
    </S.PublishableCheckboxLabel>
  );
};

export default PublishablePressCheckbox;
