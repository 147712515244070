import { VentureCapitalParagraphContents } from "@EditArticleContainer/shared/types/paragraphContents/paragraphContents.types";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useCreateDrugProfileContent } from "./hooks/useCreateDrugProfileContent";
import { useCreateKeyCommentsContent } from "./hooks/useCreateKeyCommentsContent";
import { useCreateTitleContent } from "./hooks/useCreateTitleContent";
import { useCreateTopicContent } from "./hooks/useCreateTopicContent";
import { useCreateVentureCapitalAdditionalInformationContent } from "./hooks/useCreateVentureCapitalAdditionalInformationContent";
import { useCreateVentureCapitalCompanyProfileContent } from "./hooks/useCreateVentureCapitalCompanyProfileContent";
import { useCreateVentureCapitalFinancingInformationContent } from "./hooks/useCreateVentureCapitalFinancingInformationContent";
import { useCreateVentureCapitalInvestorsContent } from "./hooks/useCreateVentureCapitalInvestorsContent";
import { useCreateVentureCapitalRoundAndStatusContent } from "./hooks/useCreateVentureCapitalRoundAndStatusContent";

export const useCreateVentureCapitalContent = () => {
  const createTitle = useCreateTitleContent();
  const createTopic = useCreateTopicContent();
  const createRoundAndStatus = useCreateVentureCapitalRoundAndStatusContent();
  const createCompanyProfile = useCreateVentureCapitalCompanyProfileContent();
  const createInvestors = useCreateVentureCapitalInvestorsContent();
  const createFinancingInformation =
    useCreateVentureCapitalFinancingInformationContent();
  const createDrugProfile = useCreateDrugProfileContent();
  const createAdditionalInformation =
    useCreateVentureCapitalAdditionalInformationContent();
  const createKeyComments = useCreateKeyCommentsContent();

  return async (): Promise<VentureCapitalParagraphContents> => {
    const title = createTitle();
    const topic = createTopic();
    const roundAndStatus = createRoundAndStatus();
    const companyProfile = createCompanyProfile();
    const investors = createInvestors();
    const financingInformation = createFinancingInformation();
    const drugProfile = createDrugProfile();
    const additionalInformation = createAdditionalInformation();
    const keyComments = createKeyComments();

    return {
      "Article Id": Number(getPressIdFromURL()),
      TC501: title,
      TC502: topic,
      TC515: roundAndStatus,
      TC516: companyProfile,
      TC517: financingInformation,
      TC518: investors,
      TC503: drugProfile,
      TC512: additionalInformation,
      TC506: keyComments,
    };
  };
};
