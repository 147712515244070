"use client";

import styled from "styled-components";

export const FooterFnbList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 2.4rem;

  @media only screen and (min-width: 1px) and (max-width: 767px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const FooterFnb = styled.li`
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  font-family: var(--font-pretendard);
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
`;
