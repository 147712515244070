import { padStart } from "@utils/padStart/padStart";

import { TABLE_CREATOR_GROUP_SHELL } from "../constants/tableCreatorGroupShell.constants";
import {
  TABLE_CREATOR_GROUP_TABLE,
  GROUP_TABLE_PATH_TO_GET_CUSTOM_TITLE,
} from "../constants/tableCreatorGroupTable.constants";
import { TABLE_CREATOR_LAYOUT } from "../constants/tableCreatorLayout.constants";
import { TABLE_CREATOR_SHELL } from "../constants/tableCreatorShell.constants";
import { TABLE_CREATOR_TABLE } from "../constants/tableCreatorTable.constants";

import type { GroupTableKeyType } from "../types/tableCreator.types";
import type { ArticleEntryData } from "@/types/articleEntryData.types";
import type { ArticleSectionType } from "@FillInTableContainer/types/fillInTable.types";

export const getGroupTableTitle = (
  articleSection: ArticleSectionType,
  groupTableKey: string | null,
) =>
  TABLE_CREATOR_GROUP_TABLE[articleSection].find(
    ({ key }) => key === groupTableKey,
  )?.title || "None";

export const getTableTitle = (
  articleSection: ArticleSectionType,
  tableKey: string | null,
) =>
  TABLE_CREATOR_TABLE[articleSection].find(({ key }) => key === tableKey)
    ?.title || null;

export const getTableCustomTitle = (
  articleSection: ArticleSectionType,
  entryData: ArticleEntryData,
  groupTableKey: GroupTableKeyType,
  tableIndex: number,
) =>
  GROUP_TABLE_PATH_TO_GET_CUSTOM_TITLE[articleSection][groupTableKey](
    entryData,
    tableIndex,
  ) || "";

export const getDealsGroupShellDescription = (groupShellKey: string | null) =>
  TABLE_CREATOR_GROUP_SHELL.Deals.find(({ key }) => key === groupShellKey)
    ?.description || "";

export const getGroupShellTitle = (
  articleSection: ArticleSectionType,
  groupShellKey: string | null,
) =>
  TABLE_CREATOR_GROUP_SHELL[articleSection].find(
    ({ key }) => key === groupShellKey,
  )?.title || "";

export const getAddedGroupShellTitle = (
  articleSection: ArticleSectionType,
  groupShellKey: string | null,
) =>
  TABLE_CREATOR_GROUP_SHELL[articleSection].find(
    ({ key, isReviseGroupShell }) =>
      key === groupShellKey && isReviseGroupShell,
  )?.title || "";

export const getGroupShellKey = (
  articleSection: ArticleSectionType,
  groupShellKey: string | null,
) =>
  TABLE_CREATOR_GROUP_SHELL[articleSection].find(
    ({ key }) => key === groupShellKey,
  )?.key || "";

export const getShellTitle = (
  articleSection: ArticleSectionType,
  shellKey: string | null,
) =>
  TABLE_CREATOR_SHELL[articleSection].find(({ key }) => key === shellKey)
    ?.title || "None";

export const getLayoutFromData = (
  articleSection: "Clinical Trials" | "Regulatory" | "Clinical Trial Status",
) => TABLE_CREATOR_LAYOUT[articleSection];

export const getClearDeleteButtonText = ({
  groupTableKey,
  articleSection,
  tableKey,
  tableIndex,
  tableCount,
}: {
  groupTableKey: string;
  articleSection: ArticleSectionType;
  tableKey: string | null;
  tableIndex: number;
  tableCount: number;
}) => {
  const title =
    tableCount > 1
      ? `${getTableTitle(articleSection, tableKey)} ${padStart(tableIndex + 1)}`
      : getTableTitle(articleSection, tableKey);

  const buttonText: { [key in string]: string } = {
    "Drug Profile": `${title} Table`,
    "Approval Process Contents": `Approval Process`,
  };

  return buttonText[groupTableKey];
};
