import { useCallback } from "react";

import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_FUNDED_COMPANY_GROUP_TABLE_KEY as FUNDED_COMPANY,
} from "@frontend/common";

import {
  FundedCompany,
  VentureCapitalFundedCompanyShellKeys,
} from "@/types/ventureCapital.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { PositionInfoProps } from "../VentureCapitalFundedCompanyCreatorShell";
import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorShellReturn {
  handleKeyDownToClearKeyCommentShell: (event: KeyboardEvent) => void;
  handleClickToClearKeyCommentShell: () => void;
}

export const useHandleVentureCapitalKeyCommentCreatorShell = (
  positionPropsInfo: PositionInfoProps,
): UseHandleTableCreatorShellReturn => {
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const handleClearShell = useCallback(() => {
    const clearKeyCommentShell = (): ArticleEntryData => {
      return {
        ...entryData,
        [SECTION_KEYS.ventureCapital]: {
          ...entryData[SECTION_KEYS.ventureCapital],
          [FUNDED_COMPANY]: {
            ...entryData[SECTION_KEYS.ventureCapital]?.[FUNDED_COMPANY],
            [info.shellKey as VentureCapitalFundedCompanyShellKeys]: {
              ...entryData[SECTION_KEYS.ventureCapital]?.[FUNDED_COMPANY]?.[
                info.shellKey as VentureCapitalFundedCompanyShellKeys
              ],
              value: [],
            },
          } as FundedCompany,
        },
      };
    };

    if (info.groupTableKey === FUNDED_COMPANY) {
      setEntryData(clearKeyCommentShell());
      changeCurrentHistory({
        entryData: clearKeyCommentShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  const handleClickToClearKeyCommentShell = () => {
    if (focusStatus.type === "clear") {
      handleClearShell();
    }
  };

  const handleKeyDownToClearKeyCommentShell = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearShell();
      }
    },
    [focusStatus.type, handleClearShell],
  );

  return {
    handleClickToClearKeyCommentShell,
    handleKeyDownToClearKeyCommentShell,
  };
};
