import styled, { css } from "styled-components";

import { LabelCode } from "@/types/code.types";

export const PressLabelInfoListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
  align-items: center;
  width: 100%;
  padding: 0.6rem 0;
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1;
  color: var(--Color-Common-Text-Secondary, #515c6c);
`;

export const PressLabelInfoList = styled.div`
  display: flex;
  height: 2rem;
  gap: 1rem;
`;

export const PressLabelInfo = styled.div<{
  code: LabelCode;
  background: string;
  border: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  line-height: 1;
  border-radius: 0;
  padding: 0 0.4rem;
  white-space: nowrap;

  ${({ background, border }) =>
    background &&
    border &&
    css`
      background: ${background};
      border: ${border};
    `}
`;
