import { RegulatoryAgencyType } from "../types/regulatoryAgency.types";

export const REGULATORY_AGENCY_CATEGORY_TYPE: Record<
  string,
  RegulatoryAgencyType
> = {
  FDA: "FDA",
  EMA: "EMA",
  "Health Canada": "HealthCanada",
  PMDA: "PMDA",
  MFDS: "MFDS",
  NMPA: "NMPA",
  MHRA: "MHRA",
  TGA: "TGA",
};
