import React from "react";

import PublishablePressCheckbox from "@organisms/PublishablePressCheckbox/PublishablePressCheckbox";
import SetStatusAsDBSaveButton from "@organisms/SetStatusAsDBSaveButton/SetStatusAsDBSaveButton";

import * as S from "./UpdateStatusButtons.style";

const UpdateStatusButtons = () => {
  return (
    <S.Wrapper>
      <PublishablePressCheckbox />
      <div className="separator" />
      <SetStatusAsDBSaveButton />
    </S.Wrapper>
  );
};

export default UpdateStatusButtons;
