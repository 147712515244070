import { useArticleInfoMutation } from "@api/articleInfo/useArticleInfo";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { toastSetDBSaved } from "@utils/toast";

import * as S from "./SetStatusAsDBSaveButton.style";

const SetStatusAsDBSaveButton = () => {
  const draftArticleId = getPressIdFromURL();

  const { mutate: updateArticleInfo } = useArticleInfoMutation(
    Number(draftArticleId),
  );

  const handleSetStatusAsDBSave = async () => {
    await updateArticleInfo({
      draftArticleStatus: DRAFT_STATUS_CODE.dbSaved,
    });

    toastSetDBSaved();
  };

  return (
    <S.Button onClick={handleSetStatusAsDBSave}>Set Status As DB Save</S.Button>
  );
};

export default SetStatusAsDBSaveButton;
