import { ReactNode } from "react";

import { useMovePage } from "@hooks/useMovePage";
import IconArrowLeft from "@images/common/icon-arrow-left.svg";
import { ROUTE_PATHS } from "@routes/routePath";

import * as S from "./MovePrevStepButton.style";

interface Props {
  page: keyof typeof ROUTE_PATHS;
  label: ReactNode;
}

const MovePrevStepButton = ({ page, label }: Props) => {
  const movePage = useMovePage();

  const movePrev = () => {
    movePage(page);
  };

  return (
    <S.PrevButton onClick={movePrev}>
      <img src={IconArrowLeft} alt="go to prev step page" />
      {label}
    </S.PrevButton>
  );
};

export default MovePrevStepButton;
