/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from "react";

import {
  useGetMainLabelQuery,
  usePostMainLabelMutation,
} from "@api/mainLabel/mainLabel";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useHandleMainLabelList } from "./useHandleMainLabelList";
import { useMainLabelInfoList } from "./useMainLabelInfoList";
import { filteredMainLabelForUpdate } from "../../utils/filteredMainLabelForUpdate";

export const useCheckInitialMainLabelList = () => {
  const { mutateAsync: updateMainLabelList } = usePostMainLabelMutation();
  const { data } = useGetMainLabelQuery();

  const entryData = useEntryDataValue(getPressIdFromURL());
  const mainLabelDefaultInfo = useMainLabelInfoList();
  const mainLabelInfo = useHandleMainLabelList();

  const newMainLabelList = mainLabelInfo.createMainLabelList(
    mainLabelDefaultInfo,
    entryData,
  );

  const updatedMainLabelList =
    mainLabelInfo.mainLabelListForEntryDataCompatibility(
      mainLabelDefaultInfo,
      data?.data || [],
      entryData,
    );

  const checkInitialMainLabelList = useCallback(() => {
    if (data === undefined) return;
    if (data.data === undefined) return;
    if (data.data.length === 0) {
      updateMainLabelList(filteredMainLabelForUpdate(newMainLabelList));
      return;
    }
    if (updatedMainLabelList !== null) {
      updateMainLabelList(filteredMainLabelForUpdate(updatedMainLabelList));
    }
  }, [data]);

  useEffect(() => {
    checkInitialMainLabelList();
  }, [checkInitialMainLabelList]);
};
