import IconAiComplete from "@images/toast/icon-toast-ai-complete.svg";
import IconAlertTriangle from "@images/toast/icon-toast-alert-triangle.svg";
import IconCopy from "@images/toast/icon-toast-copy.svg";
import IconEdit from "@images/toast/icon-toast-edit.svg";
import IconError from "@images/toast/icon-toast-error.svg";
import IconLink from "@images/toast/icon-toast-link.svg";
import IconSave from "@images/toast/icon-toast-save.svg";
import { theme } from "@styles/theme";

export type ToastBackgroundColor =
  | "red"
  | "green"
  | "blue"
  | "sky"
  | "blueGreen"
  | "orange"
  | "hotPink";
export type ToastIcon =
  | "error"
  | "link"
  | "edit"
  | "save"
  | "alertTriangle"
  | "aiComplete"
  | "copy";
export type ToastPosition =
  | "topLeft"
  | "topCenter"
  | "topRight"
  | "bottomLeft"
  | "bottomCenter"
  | "bottomRight";

export const TOAST_COLORS: Record<ToastBackgroundColor, string> = {
  red: "#FB6E52",
  green: "#14CD53",
  blueGreen: theme.color.bio.blueGreen06,
  blue: "#0B6CFF",
  sky: "#019DF6",
  orange: "#FF9900",
  hotPink: "#DB0F72",
};

export const TOAST_ICONS: Record<ToastIcon, string> = {
  error: IconError,
  link: IconLink,
  edit: IconEdit,
  save: IconSave,
  alertTriangle: IconAlertTriangle,
  aiComplete: IconAiComplete,
  copy: IconCopy,
};

export const TOAST_POSITIONS: Record<ToastPosition, string> = {
  topLeft: "top-left",
  topCenter: "top-center",
  topRight: "top-right",
  bottomLeft: "bottom-left",
  bottomCenter: "bottom-center",
  bottomRight: "bottom-right",
};
