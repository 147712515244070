import React, { useState } from "react";

import {
  UNSUITABLE_REASON_LIST,
  UnsuitableReasonList,
} from "@/components/business/PressClassificationContainer/components/UnsuitableButton/components/PressUnsuitableReasonContainer/UnsuitableReason.constants";

interface UseHandleUnsuitablePressReturn {
  isOpenModalUnsuitablePress: boolean;
  closeModalUnsuitablePress: () => void;
  openModalUnsuitablePress: () => void;
  draftArticleStatus?: string;
  selectedUnsuitablePressReason: string | null;
  setSelectedUnsuitablePressReason: React.Dispatch<
    React.SetStateAction<string>
  >;
  unsuitablePressReasonList: UnsuitableReasonList[];
  setUnsuitablePressReasonList: React.Dispatch<
    React.SetStateAction<UnsuitableReasonList[]>
  >;
}

export const useHandleUnsuitablePress = (): UseHandleUnsuitablePressReturn => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const [selectedUnsuitablePressReason, setSelectedUnsuitablePressReason] =
    useState<string>("");

  const [unsuitablePressReasonList, setUnsuitablePressReasonList] = useState<
    UnsuitableReasonList[]
  >(UNSUITABLE_REASON_LIST);

  return {
    isOpenModalUnsuitablePress: isOpenModal,
    closeModalUnsuitablePress: () => setIsOpenModal(false),
    openModalUnsuitablePress: () => setIsOpenModal(true),
    selectedUnsuitablePressReason,
    setSelectedUnsuitablePressReason,
    unsuitablePressReasonList,
    setUnsuitablePressReasonList,
  };
};
