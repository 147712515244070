import styled from "styled-components";

import UpdateButton from "@EditArticleContainer/shared/components/UpdateButton/UpdateButton";

export const ParagraphAdditionalInformationWrapper = styled.div`
  position: relative;
`;
export const ParagraphAdditionalInformationUpdateButton = styled(UpdateButton)`
  position: absolute;
  left: -1.6rem;
  top: 0;
  transform: translateX(-100%);
`;

export const InvestorsWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 1.6rem;
  column-gap: 1.6rem;
`;

export const InvestorItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  width: 100%;

  .investor-logo {
    border: 0;
  }
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Primary, #fff);
  padding: 0.8rem;
`;

export const InvestorNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  align-items: flex-start;
  justify-content: center;
  min-height: 4.8rem;
  height: fit-content;
`;

export const InvestorNameText = styled.div`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 150%;
`;

export const InvestorLeadLabel = styled.div`
  display: flex;
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--Border-Radius-2, 0.2rem);
  background: linear-gradient(92deg, #0b6cff 25.97%, #25a6e4 100%);

  color: var(--grayscale-spectrum-neural-blue-gray-02, #edf1f8);
  text-align: center;
  font-family: var(--font-pt-serif);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
`;

export const InvestorLogoWrapper = styled.div`
  padding: 0.6rem;
`;

export const Divider = styled.div`
  width: 0.1rem;
  height: 100%;
  background-color: var(--Color-Common-Border-Tertiary, #e5e7eb);
`;
