import styled from "styled-components";

export const GroupTableTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  height: 3.2rem;
`;

export const Title = styled.h2`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.2rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  padding: 0 0.6rem;
`;
