import { DealsParagraphContents } from "@EditArticleContainer/shared/types/paragraphContents/paragraphContents.types";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useCreateDealTypeAndStatusContent } from "./hooks/useCreateAdditionalInformationContent";
import { useCreateCompetitiveLandscapeContent } from "./hooks/useCreateCompetitiveLandscapeContent";
import { useCreateDealTermsContent } from "./hooks/useCreateDealTermsContent";
import { useCreateAdditionalInformationContent } from "./hooks/useCreateDealTypeAndStatusContent";
import { useCreateDeepInsightContent } from "./hooks/useCreateDeepInsightContent";
import { useCreateDrugProfileContent } from "./hooks/useCreateDrugProfileContent";
import { useCreateKeyCommentsContent } from "./hooks/useCreateKeyCommentsContent";
import { useCreateTitleContent } from "./hooks/useCreateTitleContent";
import { useCreateTopicContent } from "./hooks/useCreateTopicContent";

export const useCreateDealsContent = () => {
  const createTitle = useCreateTitleContent();
  const createTopic = useCreateTopicContent();
  const createDrugProfile = useCreateDrugProfileContent();
  const createCompetitiveLandscape = useCreateCompetitiveLandscapeContent();
  const createDeepInsight = useCreateDeepInsightContent();
  const createKeyComments = useCreateKeyCommentsContent();
  const createDealTypeAndStatus = useCreateDealTypeAndStatusContent();
  const createAdditionalInformation = useCreateAdditionalInformationContent();
  const createDealTerms = useCreateDealTermsContent();

  return async (): Promise<DealsParagraphContents> => {
    const title = createTitle();
    const topic = createTopic();
    const drugProfile = createDrugProfile();
    const keyComments = createKeyComments();
    const competitiveLandscape = createCompetitiveLandscape();
    const deepInsight = createDeepInsight();
    const dealTypeAndStatus = createDealTypeAndStatus();
    const dealTerms = await createDealTerms();
    const additionalInformation = createAdditionalInformation();

    return {
      "Article Id": Number(getPressIdFromURL()),
      TC101: title,
      TC102: topic,
      TC103: drugProfile,
      TC107: competitiveLandscape,
      TC108: deepInsight,
      TC110: dealTypeAndStatus,
      TC111: dealTerms,
      TC112: additionalInformation,
      TC106: keyComments,
    };
  };
};
