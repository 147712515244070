import {
  CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS,
  ENTRY_DATA_SECTION_KEYS,
} from "@frontend/common";

import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";
import type { ClinicalTrialsSection } from "@/types/clinicalTrials.types";

interface UseHandleGroupShellReturn {
  clearClinicalDesign2GroupShell: () => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: ClinicalTrialsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useClearClinicalTrialsDesign2GroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();

  const articleSection = ENTRY_DATA_SECTION_KEYS.clinicalTrial;

  const clearClinicalDesign2GroupShell = () => {
    const clearClinicalTrialDesignTwoGroupShell = (): ArticleEntryData => {
      const targetGroupShell =
        entryData?.[articleSection]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN];

      return {
        ...entryData,
        [articleSection]: {
          ...entryData[articleSection],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: targetGroupShell?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2]:
                    tableInfo[
                      ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
                    ].map((groupShellInfo) => {
                      return {
                        ...groupShellInfo,
                        [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.armName]: {
                          ...groupShellInfo?.[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.armName
                          ],
                          value: [],
                        },
                        [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.patientsNumber]:
                          {
                            ...groupShellInfo?.[
                              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.armName
                            ],
                            value: [],
                          },
                        [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation]:
                          groupShellInfo[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS
                              .designGroupInformation
                          ].map((shellListInfo) => {
                            return {
                              ...shellListInfo,
                              [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.does]: {
                                ...shellListInfo[
                                  ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.does
                                ],
                                value: [],
                              },
                              [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.drugName]:
                                {
                                  ...shellListInfo[
                                    ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS
                                      .drugName
                                  ],
                                  value: [],
                                },
                              [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.duration]:
                                {
                                  ...shellListInfo[
                                    ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS
                                      .duration
                                  ],
                                  value: [],
                                },
                            };
                          }),
                      };
                    }),
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    if (
      info.groupShellKey ===
      CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign2
    ) {
      setEntryData(clearClinicalTrialDesignTwoGroupShell());
      changeCurrentHistory({
        entryData: clearClinicalTrialDesignTwoGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  };

  return {
    clearClinicalDesign2GroupShell,
  };
};
