import { REF_CODE_SPOKESPERSON } from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { SpokespersonContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/keyCommentsContent.types";

export class Spokesperson {
  #key = REF_CODE_SPOKESPERSON;

  private spokespersonData: SpokespersonContent;

  constructor(spokespersonData: SpokespersonContent) {
    this.spokespersonData = spokespersonData;
  }

  public get data() {
    return this.spokespersonData;
  }

  public get key() {
    return this.#key;
  }

  public get text() {
    return this.spokespersonData.value.at(0)?.text || "";
  }

  public get labels() {
    return this.spokespersonData.value.at(0)?.labels || [];
  }
}
