import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/queryKeys";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { initEntryDataApis } from "./initEntryData.service";

export const getInitEntryData = async (articleId: string) => {
  const data = await initEntryDataApis.get(articleId);
  return data["Init Data"];
};

export const useInitEntryDataQuery = (
  articleId: string = getPressIdFromURL(),
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.initEntryData, articleId],
    queryFn: () => initEntryDataApis.get(articleId),
    select: (data) => data["Init Data"],
  });
};
