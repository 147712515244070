import { useCallback, useState } from "react";

import { CopyPressButton } from "@frontend/common";

import TableCreatorErrorBoundary from "@/components/shared/business/TableCreatorErrorBoundary/TableCreatorErrorBoundary";
import PressTitle from "@/components/shared/ui/PressTitle/PressTitle";
import TopFixInfo from "@/components/shared/ui/TopFixInfo/TopFixInfo";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { useCopyPress } from "@hooks/useCopyPress/useCopyPress";

import EntryTableCreatorContainer from "./components/EntryTableCreatorContainer/EntryTableCreatorContainer";
import FillInTableBottomButtonContainer from "./components/FillInTableBottomButtonContainer/FillInTableBottomButtonContainer";
import FillInTablePress from "./components/FillInTablePress/FillInTablePress";
import WidthHandleBar from "./components/WidthHandleBar/WidthHandleBar";
import * as S from "./FillInTable.style";
import { useHandleResizeWidth } from "./hooks/useHandleResizeWidth";

const FillInTable = () => {
  const articleSection = useArticleSection();
  const { copyPress } = useCopyPress();

  const [currentSize, setCurrentSize] = useState({
    width: 0,
    y: 0,
  });

  const {
    mouseDownWidthHandleBar,
    columnRef,
    widthHandleBarRef,
    columnWidth,
    columnWrapperWidth,
    columnWrapperRef,
  } = useHandleResizeWidth();

  const handleCurrentPressSize = useCallback(
    (element: HTMLDivElement) => {
      if (!element) return;

      setCurrentSize({
        width: element.getBoundingClientRect().width,
        y: element.getBoundingClientRect().y + 82,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnWidth],
  );

  return (
    <S.FillInTableWrapper>
      <TopFixInfo />
      <S.ColumnWrapper ref={columnWrapperRef}>
        <S.Column
          ref={columnRef}
          columnWidth={columnWidth}
          columnWrapperWidth={columnWrapperWidth}
          className="first-column"
        >
          <S.ColumnContentsWrapper ref={handleCurrentPressSize}>
            <S.LabelInfoViewContainer>
              <PressTitle isLabel={false} />
              <CopyPressButton
                className="copy-press-button"
                onClick={copyPress}
              />
            </S.LabelInfoViewContainer>
            <FillInTablePress columnLabelViewerSize={currentSize} />
          </S.ColumnContentsWrapper>
        </S.Column>
        <WidthHandleBar
          ref={widthHandleBarRef}
          onMouseDown={mouseDownWidthHandleBar}
        />
        <S.Column
          columnWidth={columnWidth}
          columnWrapperWidth={columnWrapperWidth}
          className="second-column"
        >
          <TableCreatorErrorBoundary>
            <EntryTableCreatorContainer articleSection={articleSection} />
          </TableCreatorErrorBoundary>
        </S.Column>
        <S.ContentsFabContainer />
      </S.ColumnWrapper>
      <FillInTableBottomButtonContainer articleSection={articleSection} />
    </S.FillInTableWrapper>
  );
};

export default FillInTable;
