import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type ModalityCategoryData = Record<string, string>;

export const ModalityCategoryContext = createContext<{
  originalModalityCategory: ModalityCategoryData;
  updateModalityCategory: ModalityCategoryData;
  changeOriginalModalityCategory: (
    modality: string,
    modalityCategory: string,
  ) => void;
  changeUpdateModalityCategory: (
    modality: string,
    modalityCategory: string,
  ) => void;
}>({
  originalModalityCategory: {},
  updateModalityCategory: {},
  changeOriginalModalityCategory: () => null,
  changeUpdateModalityCategory: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const ModalityCategoryProvider = ({ children }: Props) => {
  const [originalModalityCategory, setOriginalModalityCategory] =
    useState<ModalityCategoryData>({});
  const [updateModalityCategory, setUpdateModalityCategory] =
    useState<ModalityCategoryData>({});

  const changeOriginalModalityCategory = useCallback(
    (modality: string, modalityCategory: string) => {
      setOriginalModalityCategory((prevState) => ({
        ...prevState,
        [modality]: modalityCategory,
      }));
    },
    [],
  );

  const changeUpdateModalityCategory = useCallback(
    (modality: string, modalityCategory: string) => {
      setUpdateModalityCategory((prevState) => ({
        ...prevState,
        [modality]: modalityCategory,
      }));
    },
    [],
  );

  const modalityCategoryWrapper = useMemo(
    () => ({
      originalModalityCategory,
      updateModalityCategory,
      changeOriginalModalityCategory,
      changeUpdateModalityCategory,
    }),
    [
      changeOriginalModalityCategory,
      changeUpdateModalityCategory,
      originalModalityCategory,
      updateModalityCategory,
    ],
  );

  return (
    <ModalityCategoryContext.Provider value={modalityCategoryWrapper}>
      {children}
    </ModalityCategoryContext.Provider>
  );
};

export const useModalityCategoryValue = () => {
  const {
    originalModalityCategory,
    updateModalityCategory,
    changeOriginalModalityCategory,
    changeUpdateModalityCategory,
  } = useContext(ModalityCategoryContext);

  return {
    originalModalityCategory,
    updateModalityCategory,
    changeOriginalModalityCategory,
    changeUpdateModalityCategory,
  };
};
