export const PARAGRAPH_CODE_TITLE = "TC001";
export const PARAGRAPH_CODE_TOPIC = "TC002";
export const PARAGRAPH_CODE_DRUG_PROFILE = "TC003";
export const PARAGRAPH_CODE_CLINICAL_TRIAL_DESIGN = "TC004";
export const PARAGRAPH_CODE_CLINICAL_RESULTS = "TC005";
export const PARAGRAPH_CODE_KEY_COMMENTS = "TC006";
export const PARAGRAPH_CODE_COMPETITIVE_LANDSCAPE = "TC007";
export const PARAGRAPH_CODE_DEEP_INSIGHT = "TC008";

export const CLINICAL_TRIALS_PARAGRAPH_CODE = {
  title: "TC001",
  topic: "TC002",
  drugProfile: "TC003",
  clinicalTrialDesign: "TC004",
  clinicalResults: "TC005",
  keyComments: "TC006",
  competitiveLandscape: "TC007",
  deepInsights: "TC008",
} as const;

export const DEALS_PARAGRAPH_CODE = {
  title: "TC101",
  topic: "TC102",
  dealTypeAndStatus: "TC110",
  dealTerms: "TC111",
  additionalInformation: "TC112",
  keyComments: "TC106",
  drugProfile: "TC103",
  competitiveLandscape: "TC107",
  deepInsights: "TC108",
} as const;

export const REGULATORY_PARAGRAPH_CODE = {
  title: "TC201",
  topic: "TC202",
  drugProfile: "TC203",
  keyComments: "TC206",
  approvalProcess: "TC213",
} as const;

export const EXPEDITED_PROGRAM_PARAGRAPH_CODE = {
  title: "TC301",
  topic: "TC302",
  drugProfile: "TC303",
  keyComments: "TC306",
  expeditedProgram: "TC314",
} as const;

export const PARAGRAPH_CODE = {
  TY001: {
    ...CLINICAL_TRIALS_PARAGRAPH_CODE,
  },
  TY002: {
    ...DEALS_PARAGRAPH_CODE,
  },
  TY003: {
    ...REGULATORY_PARAGRAPH_CODE,
  },
  TY004: {
    ...EXPEDITED_PROGRAM_PARAGRAPH_CODE,
  },
} as const;

export type ParagraphCodeName =
  | keyof typeof CLINICAL_TRIALS_PARAGRAPH_CODE
  | keyof typeof DEALS_PARAGRAPH_CODE
  | keyof typeof REGULATORY_PARAGRAPH_CODE
  | keyof typeof EXPEDITED_PROGRAM_PARAGRAPH_CODE;
