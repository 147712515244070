import { toastFail } from "@utils/toast";

export const failByDuplicatedUploadImageAlert = (message: string) => {
  toastFail(message);
};

export const failByApiCallUploadImageAlert = () => {
  const message = "Failed to upload image.";
  toastFail(message);
};
