import { useCallback, useEffect, useMemo, useState } from "react";

import { useTableCreatorDropdown } from "@FillInTableContainer/shared/context/TableCreatorDropdownContext";

import useGetDataSetDropdown from "./useGetDataSetDropdown";
import { CATEGORY_DROP_DOWN_SHELL_KEYS } from "../components/TableCreatorListBox/constants/tableCreatorListBox.constants";
import {
  AIDropdownListFormat,
  DictionaryList,
} from "../components/TableCreatorListBox/types/tableCreatorDropdown.types";
import { AUTO_COMPANY_SHELL } from "../constants/autoCompleteListShell";
import { changeFormatAiDropdownList } from "../utils/changeFormatAiDropdownList";

import type { EntryDataValueAndOptions } from "@/types/clinicalTrials.types";

interface Args {
  shellValue: EntryDataValueAndOptions;
  shellKey: string | null;
  groupTableKey: string;
}

export const useHandleInfoOfDropdownLists = ({
  shellValue,
  shellKey,
  groupTableKey,
}: Args) => {
  const [aiList, setAiList] = useState<AIDropdownListFormat[]>(
    changeFormatAiDropdownList(shellValue.options, shellKey),
  );

  useEffect(() => {
    setAiList(changeFormatAiDropdownList(shellValue.options, shellKey));
  }, [shellValue.options, shellKey]);

  const { defaultDropdownList, categoryDropdownList } =
    useTableCreatorDropdown();

  const { initialDataSetList, dataSetList, changeCurrentRecommendDatasetList } =
    useGetDataSetDropdown(groupTableKey, shellKey);

  const currentShellDefaultList = useMemo(
    () =>
      defaultDropdownList.filter((dropdown) => dropdown.shellKey === shellKey),
    [defaultDropdownList, shellKey],
  );

  const currentShellCategoryList = useMemo(
    () =>
      categoryDropdownList.filter(
        (categoryItem) => categoryItem.shellKey === shellKey,
      ),
    [categoryDropdownList, shellKey],
  );

  const initialDropdownList: DictionaryList = useMemo(
    () => [...currentShellCategoryList, ...currentShellDefaultList],
    [currentShellCategoryList, currentShellDefaultList],
  );

  const [dictionaryList, setDictionaryList] =
    useState<DictionaryList>(initialDropdownList);
  const [dictionaryListLength, setDictionaryListLength] = useState<number>(
    dictionaryList.length,
  );

  const currentDictionaryStringList = CATEGORY_DROP_DOWN_SHELL_KEYS.includes(
    shellKey || "",
  )
    ? categoryDropdownList.flatMap((dictionary) =>
        dictionary.list.flatMap((category) => category.list),
      )
    : defaultDropdownList.flatMap((dropdown) => dropdown.list);

  const currentAiStringList = shellValue.options;

  const currentShellKeyDatasetList = useMemo(
    () =>
      dataSetList.filter((dropdownItem) => dropdownItem.shellKey === shellKey),
    [dataSetList, shellKey],
  );
  const isOriginalAiList = shellValue.options?.length > 0 ?? false;
  const isOriginalDictionaryList =
    (initialDropdownList.length > 0 ||
      AUTO_COMPANY_SHELL.includes(shellKey as string)) ??
    false;

  const isDictionaryList = dictionaryListLength > 0;
  const isDataSetList =
    dataSetList.reduce((prev, cur) => prev + cur.list.length, 0) > 0;

  const changeDictionaryList = useCallback((list: DictionaryList) => {
    const totalDictionaryListLength = list.reduce(
      (prev, cur) => prev + cur.list.length,
      0,
    );
    setDictionaryList(list);
    setDictionaryListLength(totalDictionaryListLength ?? 0);
  }, []);

  const changeAiList = useCallback((list: AIDropdownListFormat[]) => {
    setAiList(list);
  }, []);

  return {
    initialDropdownList,
    dictionaryList,
    aiList,
    isDictionaryList,
    isDataSetList,
    isOriginalAiList,
    isOriginalDictionaryList,
    isCategoryList: !!currentShellCategoryList.length,
    changeDictionaryList,
    changeAiList,
    changeDatasetDropdownList: changeCurrentRecommendDatasetList,
    dataSetList,
    initialDataSetList,
    currentDictionaryStringList,
    currentAiStringList,
    currentShellKeyDatasetList,
  };
};
