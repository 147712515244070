import { ClinicalTrialDesign1Data } from "@EditArticleContainer/shared/types/paragraphContents/contents/clinicalTrialDesignContent.types";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

export class ClinicalTrialDesign1 {
  private clinicalTrialDesign1Data: ClinicalTrialDesign1Data;

  constructor(clinicalTrialDesign1Data: ClinicalTrialDesign1Data) {
    this.clinicalTrialDesign1Data = clinicalTrialDesign1Data;
  }

  public get data() {
    return this.clinicalTrialDesign1Data;
  }

  public get trialName() {
    return {
      key: "TC004-Trial Name",
      text: createMultiLineText({
        textList:
          this.data?.["Trial Name"]?.value.map?.((item) => item.text ?? "") ??
          [],
        parseString: (text) => `- ${text?.trim()}`,
      }),
      labels: this.data?.["Trial Name"].value.at(0)?.labels || [],
    };
  }

  public get clinicalTrialIdentifier() {
    return {
      key: "TC004-NCT Name",
      text: createMultiLineText({
        textList:
          this.data?.["NCT Name"]?.value.map?.((item) => item.text ?? "") ?? [],
        parseString: (text) => `- ${text?.trim()}`,
      }),
      labels: this.data?.["NCT Name"].value.at(0)?.labels || [],
    };
  }

  public get trialNameTitle() {
    return this.trialName.text.match(/\((.*)\)/)
      ? "Trial Name (Clinical Trial Identifier)"
      : "Trial Name";
  }

  public get clinicalDesignPhase() {
    return {
      key: "TC004-Phase",
      text: createMultiLineText({
        textList:
          this.data?.["Clinical Design Phase"]?.value?.map?.(
            (item) => item.text ?? "",
          ) ?? [],
        parseString: (text) => `- ${text?.trim()}`,
      }),
      labels: this.data?.["Clinical Design Phase"].value.at(0)?.labels || [],
    };
  }

  public get totalPatientsNumber() {
    return {
      key: "TC004-Total Patients Number",
      text: this.data?.["Total Patients Number"]?.value.at(0)?.text || "",
      labels: this.data?.["Total Patients Number"]?.value.at(0)?.labels || [],
    };
  }

  public get inclusionCriteriaDiagnostic() {
    return {
      key: "TC004-Inclusion Criteria - Diagnostic",
      text: createMultiLineText({
        textList:
          this.data?.["Inclusion Criteria - Diagnostic"].value.map?.(
            (item) => item.text ?? "",
          ) ?? [],
        parseString: (text) => `- ${text?.trim()}`,
      }),
      labels:
        this.data?.["Inclusion Criteria - Diagnostic"].value.at(0)?.labels ||
        [],
    };
  }

  public get inclusionCriteriaDetail() {
    return {
      key: "TC004-Inclusion Criteria - Detail",
      text: createMultiLineText({
        textList:
          this.data?.["Inclusion Criteria - Detail"].value.map?.(
            (item) => item.text ?? "",
          ) ?? [],
        parseString: (text) => `- ${text?.trim()}`,
      }),
      labels:
        this.data?.["Inclusion Criteria - Detail"].value.at(0)?.labels || [],
    };
  }
}
