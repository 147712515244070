"use client";

import styled, { css } from "styled-components";

import { TABLE_WIDTH } from "../../../constants/edbMain/tableWidth.constants";
import { TableTypeKey } from "../../../types/edbMain/tableType.types";

export const BodyShell = styled.div<{
  $titleKey: string;
  $tableTypeKey: TableTypeKey;
}>`
  padding: var(--Spacing-12, 1.2rem) var(--Spacing-8, 0.8rem);
  gap: var(--Spacing-8, 0.8rem);
  overflow: hidden;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  border-left: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 130%;
  width: 100%;
  flex-grow: 1;
  min-width: ${({ $tableTypeKey, $titleKey }) =>
    TABLE_WIDTH[$tableTypeKey][$titleKey].minWidth};
  max-width: ${({ $tableTypeKey, $titleKey }) =>
    TABLE_WIDTH[$tableTypeKey][$titleKey].maxWidth};

  &:first-child {
    border: none;
  }
`;

export const Content = styled.div<{ $isBreakAll?: boolean }>`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  word-break: keep-all;
  word-wrap: break-word;
  width: 100%;

  ${({ $isBreakAll }) =>
    $isBreakAll &&
    css`
      word-break: break-all;
    `}
`;
