"use client";

import styled, { css } from "styled-components";

import { NeuralColors } from "../types/themeColor.types";

interface InputProps {
  $inputColor: NeuralColors;
  $inputSize: string;
  $inputWeight: string;
  $inputAlign: string;
}

export const LabelWrapper = styled.label`
  position: relative;
  width: 100%;

  .input-label {
    display: none;
    font-family: ${({ theme }) => theme.font.fontFamily.jost};
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 130%;
    color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  }

  &:focus-within {
    .input-label {
      display: block;
    }
  }
`;

export const LabelTextWrapper = styled.div`
  position: absolute;
  left: 0.8rem;
  top: -0.8rem;
  padding: 0 0.4rem;
  background-color: white;
  z-index: 2;
`;

export const LabelText = styled.p`
  color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 100%;
`;

export const InputWrapper = styled.div<{
  $lineColor: string;
  $isError?: boolean;
}>`
  display: flex;
  justify-content: space-between;

  ${({ $lineColor }) =>
    $lineColor !== "null" &&
    css`
      border: 0.1rem solid ${$lineColor};
      border-radius: 0.4rem;
      &:focus-within {
        border: 0.1rem solid ${({ theme }) => theme.color.node.sky};
      }
    `}

  ${({ $isError }) =>
    $isError &&
    css`
      border: 1px solid var(--Color-Common-Border-Warning, #e53e06);
    `}
`;

export const Input = styled.input<InputProps>`
  all: unset;
  width: 100%;
  height: 3.8rem;

  font-size: ${({ $inputSize }) => `${Number($inputSize) / 10}rem`};
  font-weight: ${({ $inputWeight }) => $inputWeight};
  text-align: ${({ $inputAlign }) => $inputAlign};
  color: var(--Color-Common-Text-Primary, #2a2f37);
  line-height: 145%;
  box-sizing: border-box;
  border-radius: 0.3rem;
  padding: var(--Spacing-8, 0.8rem);
  cursor: text;

  &::placeholder {
    color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
    opacity: 0.5;
  }

  &:disabled {
    background: var(--Color-Common-Bg-Disabled, #cbd0d8);
    color: var(--Color-Common-Text-Disabled, #96a1b0);
    cursor: not-allowed;

    &::placeholder {
      color: var(--Color-Common-Text-Disabled, #96a1b0);
      opacity: 1;
    }
  }

  &[type="password"]::-ms-reveal,
  &[type="password"]::-ms-clear {
    display: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const ShowPasswordButton = styled.button`
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-weight: 400;
  font-size: 1.4rem;
  padding-right: 0.8rem;
  line-height: 1;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.color.node.sky};
  cursor: pointer;
  padding-left: 0;
`;
