import { useUpdateParagraph } from "@EditArticleContainer/shared/hooks/useUpdateParagraph";
import { ParagraphContent } from "@EditArticleContainer/shared/types/paragraphContents/paragraphContents.types";

import DrugProfileTable from "./components/DrugProfileTable/DrugProfileTable";
import * as S from "./DrugProfileTableGroup.style";

interface Props {
  drugProfile: ParagraphContent["TC103"];
}

const DrugProfileTableGroup = ({ drugProfile }: Props) => {
  const [updateDrugProfileTable, loadingDrugProfileTableIndex] =
    useUpdateParagraph({
      paragraphCode: "TC103",
      sectionCode: "TY002",
    });

  return (
    <S.DrugProfileTableWrapper>
      <S.ParagraphDrugProfileUpdateButton
        onClick={() => updateDrugProfileTable(0)}
        disabled={loadingDrugProfileTableIndex === 0}
      />

      <>
        {drugProfile?.text?.["Drug profile"]?.map?.((row, index) => {
          return (
            <DrugProfileTable
              key={`deal-drug-profile-${String(index)}`}
              row={row}
              index={index}
            />
          );
        })}
      </>
    </S.DrugProfileTableWrapper>
  );
};

export default DrugProfileTableGroup;
