import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconXMark = ({ width = 16, height = 16, color = "#2A2F37" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6882 5.0203C11.8835 4.82504 11.8835 4.50846 11.6882 4.31319C11.4929 4.11793 11.1763 4.11793 10.9811 4.31319L8.0013 7.29297L5.02152 4.31319C4.82626 4.11793 4.50968 4.11793 4.31442 4.31319C4.11915 4.50846 4.11915 4.82504 4.31442 5.0203L7.29419 8.00008L4.31442 10.9799C4.11915 11.1751 4.11915 11.4917 4.31442 11.687C4.50968 11.8822 4.82626 11.8822 5.02152 11.687L8.0013 8.70719L10.9811 11.687C11.1763 11.8822 11.4929 11.8822 11.6882 11.687C11.8835 11.4917 11.8835 11.1751 11.6882 10.9799L8.70841 8.00008L11.6882 5.0203Z"
        fill={color}
      />
    </svg>
  );
};

IconXMark.displayName = "XMark";
export default IconXMark;
