import styled, { css } from "styled-components";

export const ImageSourceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 4rem;
  padding: 1.6rem;
`;

export const ImageSourceColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 44.2rem;
  height: 100%;
  gap: 1.6rem;
`;
export const ImageSourceFlexible = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.6rem;
  width: 100%;
  height: 100%;
  flex: 1;
`;

export const FixedSize = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 23.8rem;
  overflow: hidden;
`;
export const ImageDropSection = styled.div<{
  selectImage: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 23.8rem;
  background-color: ${({ selectImage }) =>
    selectImage ? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 0)"};
  border-radius: 0.8rem;
  border: 0.1rem solid #b1b8c4;
  box-shadow: 0px 4px 8px 0px rgba(7, 68, 161, 0.02);
  cursor: pointer;

  ${({ selectImage }) =>
    !selectImage
      ? css`
          & > h3,
          & > p {
            color: ${({ theme }) => theme.color.neural.gray07};
          }
        `
      : css`
          & > h3,
          & > p {
            color: ${({ theme }) => theme.color.neural.gray00};
          }
        `};

  p {
    font-weight: 500;
    font-size: 1.4rem;
  }

  input {
    width: 0;
    height: 0;
  }
`;

export const ImageDropButton = styled.div`
  position: absolute;
  top: 0.8rem;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.8rem;

  button {
    cursor: pointer;
  }
`;

export const PreviewImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SelectImageLabelButton = styled.button`
  font-size: 1.4rem;
  font-weight: 500;
  text-align: left;
  color: ${({ theme }) => theme.color.neural.gray05};
  margin-top: 0.8rem;
  cursor: pointer;
`;

export const AddImageTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.8rem;
  font-weight: 600;
  font-size: 2rem;
  line-height: 120%;
  color: #ffffff;

  &.add-image-text {
    color: #0198e9;
  }
`;

export const StepTitle = styled.h3`
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.2rem;
  color: #2a2f37;
  font-size: 1.8rem;
  font-weight: 600;
`;
export const Divider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: #e5e7eb;
`;

export const ImageInfoInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.6rem;
  width: 100%;
  padding-bottom: 4rem;
`;
