import { useSuspenseQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/queryKeys";

import { PostSellerAndBuyerByDealTypeRequest } from "./sellerAndBuyerByDealType.interface";
import { sellerAndBuyerByDealTypeApis } from "./sellerAndBuyerByDealType.service";

export const useSellerAndBuyerByDealTypeQuery = (
  dealTypes: PostSellerAndBuyerByDealTypeRequest,
) => {
  return useSuspenseQuery({
    queryKey: [QUERY_KEYS.sellerAndBuyerByDealType, dealTypes],
    queryFn: () => sellerAndBuyerByDealTypeApis.post(dealTypes),
  });
};
