"use client";

import styled, { css } from "styled-components";

export const EditModeText = styled.div`
  white-space: pre-wrap;
  width: 100%;
  height: 100%;
  min-height: 1.8rem;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-family: var(--font-pt-serif);
  font-weight: inherit;
  font-style: inherit;
  text-decoration: inherit;
  text-align: inherit;
  overflow-wrap: break-word;

  mark {
    border-style: none;
    all: unset;
  }

  mark[data-entity="E010"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E010};
    background-image: ${({ theme }) => theme.label.backgroundImage.E010};
  }

  mark[data-entity="E020"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E020};
    background-image: ${({ theme }) => theme.label.backgroundImage.E020};
  }

  mark[data-entity="E030"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E030};
    background-image: ${({ theme }) => theme.label.backgroundImage.E030};
  }
  mark[data-entity="E031"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E031};
    background-image: ${({ theme }) => theme.label.backgroundImage.E031};
  }
  mark[data-entity="E032"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E032};
    background-image: ${({ theme }) => theme.label.backgroundImage.E032};
  }

  mark[data-entity="E040"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E040};
    background-image: ${({ theme }) => theme.label.backgroundImage.E040};
  }

  mark[data-entity="E050"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E050};
    background-image: ${({ theme }) => theme.label.backgroundImage.E050};
  }
  mark[data-entity="E051"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E051};
    background-image: ${({ theme }) => theme.label.backgroundImage.E051};
  }
  mark[data-entity="E052"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E052};
    background-image: ${({ theme }) => theme.label.backgroundImage.E052};
  }

  mark[data-entity="E060"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E060};
    background-image: ${({ theme }) => theme.label.backgroundImage.E060};
  }

  &:empty::before {
    content: "-";
  }

  &:focus {
    outline: 0.2rem solid
      var(--Color-Common-Border-Interactive-Primary-Hovered, #017abc);
    border-radius: var(--Border-Radius-4, 0.4rem);
    background: var(
      --Color-Common-Bg-Interactive-Primary-Subtle-Hovered,
      #e0f4ff
    );
    /* Elevation/Light/Shadow8 */
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12),
      0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  }
  &:hover {
    outline: 2px solid
      var(--Color-Common-Border-Interactive-Primary-Hovered, #017abc);
    border-radius: var(--Border-Radius-4, 0.4rem);
    background: var(
      --Color-Common-Bg-Interactive-Primary-Subtle-Pressed,
      #d1efff
    );

    /* Elevation/Light/Shadow8 */
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12),
      0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  }
`;

export const ReadModeText = styled.div<{
  $isLabelWithLine: boolean;
  $canTextEdit: boolean;
  $isDisabled: boolean;
  $isCapitalize: boolean;
}>`
  white-space: pre-wrap;
  width: 100%;
  height: 100%;
  min-height: 1.8rem;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-family: var(--font-pt-serif);
  font-weight: inherit;
  font-style: inherit;
  text-decoration: inherit;
  text-align: inherit;
  overflow-wrap: break-word;

  ${({ $isCapitalize }) =>
    $isCapitalize &&
    css`
      text-transform: capitalize;
    `}

  mark {
    border-style: none;
    all: unset;

    ${({ $isLabelWithLine }) =>
      $isLabelWithLine &&
      css`
        border-radius: 0.4rem;
      `}
  }

  mark[data-entity="E010"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E010};
    background-image: ${({ theme }) => theme.label.backgroundImage.E010};

    ${({ $isLabelWithLine }) =>
      $isLabelWithLine &&
      css`
        border-radius: 0.4rem;
        padding: 0.1rem 0.6rem;

        border: 1px solid ${({ theme }) => theme.label.stroke.E010};
      `}
  }

  mark[data-entity="E020"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E020};
    background-image: ${({ theme }) => theme.label.backgroundImage.E020};

    ${({ $isLabelWithLine }) =>
      $isLabelWithLine &&
      css`
        border-radius: 0.4rem;
        padding: 0.1rem 0.6rem;

        border: 1px solid ${({ theme }) => theme.label.stroke.E020};
      `}
  }

  mark[data-entity="E030"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E030};
    background-image: ${({ theme }) => theme.label.backgroundImage.E030};

    ${({ $isLabelWithLine }) =>
      $isLabelWithLine &&
      css`
        border-radius: 0.4rem;
        padding: 0.1rem 0.6rem;

        border: 1px solid ${({ theme }) => theme.label.stroke.E030};
      `}
  }
  mark[data-entity="E031"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E031};
    background-image: ${({ theme }) => theme.label.backgroundImage.E031};

    ${({ $isLabelWithLine }) =>
      $isLabelWithLine &&
      css`
        border-radius: 0.4rem;
        padding: 0.1rem 0.6rem;

        border: 1px solid ${({ theme }) => theme.label.stroke.E031};
      `}
  }
  mark[data-entity="E032"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E032};
    background-image: ${({ theme }) => theme.label.backgroundImage.E032};

    ${({ $isLabelWithLine }) =>
      $isLabelWithLine &&
      css`
        border-radius: 0.4rem;
        padding: 0.1rem 0.6rem;

        border: 1px solid ${({ theme }) => theme.label.stroke.E032};
      `}
  }

  mark[data-entity="E040"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E040};
    background-image: ${({ theme }) => theme.label.backgroundImage.E040};

    ${({ $isLabelWithLine }) =>
      $isLabelWithLine &&
      css`
        border-radius: 0.4rem;
        padding: 0.1rem 0.6rem;

        border: 1px solid ${({ theme }) => theme.label.stroke.E040};
      `}
  }

  mark[data-entity="E050"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E050};
    background-image: ${({ theme }) => theme.label.backgroundImage.E050};

    ${({ $isLabelWithLine }) =>
      $isLabelWithLine &&
      css`
        border-radius: 0.4rem;
        padding: 0.1rem 0.6rem;

        border: 1px solid ${({ theme }) => theme.label.stroke.E050};
      `}
  }
  mark[data-entity="E051"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E051};
    background-image: ${({ theme }) => theme.label.backgroundImage.E051};

    ${({ $isLabelWithLine }) =>
      $isLabelWithLine &&
      css`
        border-radius: 0.4rem;
        padding: 0.1rem 0.6rem;

        border: 1px solid ${({ theme }) => theme.label.stroke.E051};
      `}
  }
  mark[data-entity="E052"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E052};
    background-image: ${({ theme }) => theme.label.backgroundImage.E052};
  }

  ${({ $isLabelWithLine }) =>
    $isLabelWithLine &&
    css`
      border-radius: 0.4rem;
      padding: 0.1rem 0.6rem;
    `}

  mark[data-entity="E060"] {
    padding: 0.1rem 0;
    background-color: ${({ theme }) => theme.label.background.E060};
    background-image: ${({ theme }) => theme.label.backgroundImage.E060};

    ${({ $isLabelWithLine }) =>
      $isLabelWithLine &&
      css`
        border-radius: 0.4rem;
        padding: 0.1rem 0.6rem;

        border: 1px solid ${({ theme }) => theme.label.stroke.E060};
      `}
  }

  ${({ $canTextEdit }) =>
    !$canTextEdit &&
    css`
      &:has(.bullet-list) {
        display: flex !important;
        flex-direction: column;
        gap: 0.4rem;
      }
    `}

  ul.bullet-list {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    text-decoration: inherit;
    text-align: inherit;
    padding-left: 1.2rem;

    li {
      list-style-type: "・ ";
      list-style-position: outside;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      font-family: inherit;
      font-weight: inherit;
      font-style: inherit;
      text-decoration: inherit;
      text-align: inherit;
      padding-left: 0.1rem;
    }
  }

  ul.with-image-list {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    text-decoration: inherit;
    text-align: inherit;
    padding-bottom: 0.4rem;

    li {
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      font-family: inherit;
      font-weight: inherit;
      font-style: inherit;
      text-decoration: inherit;
      text-align: inherit;
      flex-wrap: wrap;
      a {
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        gap: 0.4rem;
        color: var(--Color-Common-Text-Primary, #2a2f37);
        font-weight: 500;

        svg.external-link-icon {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
      div {
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        font-family: inherit;
        font-weight: inherit;
        font-style: inherit;
        text-decoration: inherit;
        text-align: inherit;

        &.link {
          text-decoration: underline;
        }
      }
      .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        border-radius: var(--Border-Radius-2, 0.2rem);
        border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
        background-color: #fff;
        padding: 0.15rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &.row-container {
      & > li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 0.6rem;
        width: 100%;
        flex-wrap: nowrap;

        div:nth-child(2) {
          padding: 0.2rem 0;
        }
      }
    }
  }

  &:has(.row-container) {
  }
  &:has(.image-column-1) {
    grid-template-columns: 1fr;
  }
  &:has(.image-column-2) {
    grid-template-columns: repeat(2, 1fr);
  }

  &:empty::before {
    content: "-";
  }

  ${({ $canTextEdit }) =>
    $canTextEdit &&
    css`
      cursor: text;
      &:hover {
        outline: 0.2rem solid
          var(--Color-Common-Border-Interactive-Primary-Hovered, #017abc);
        border-radius: var(--Border-Radius-4, 0.4rem);
        background: var(
          --Color-Common-Bg-Interactive-Primary-Subtle-Pressed,
          #d1efff
        );
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12),
          0px 4px 8px 0px rgba(0, 0, 0, 0.16);
      }

      &:active {
        outline: 0.2rem solid
          var(--Color-Common-Border-Interactive-Primary-Pressed, #015d8e);
        background: var(
          --Color-Common-Bg-Interactive-Primary-Subtle-Pressed,
          #d1efff
        );
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
          0px 1px 2px 0px rgba(0, 0, 0, 0.16);
      }
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      &:hover {
        outline: 0.2rem solid var(--Color-Common-Border-Disabled, #cbd0d8);
        background: var(--Color-Common-Bg-Disabled, #cbd0d8);

        cursor: not-allowed;
      }
    `}
`;
