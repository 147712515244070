import DeletedPressBoundary from "@/components/shared/business/DeletedPressBoundary/DeletedPressBoundary";
import { DesDealTypeDropdownDisplayNameProvider } from "@/contexts/desDealTypeDropdownDisplayNameContext";
import FillInTableContainer from "@FillInTableContainer/FillInTableContainer";
import { useSafeLeavePage } from "@hooks/useSafeLeavePage";

import FillInTableErrorBoundary from "./errors/components/FillInTableErrorBoundary/FillInTableErrorBoundary";

const FillInTablePage = () => {
  useSafeLeavePage();

  return (
    <DesDealTypeDropdownDisplayNameProvider>
      <FillInTableErrorBoundary>
        <DeletedPressBoundary>
          <FillInTableContainer />
        </DeletedPressBoundary>
      </FillInTableErrorBoundary>
    </DesDealTypeDropdownDisplayNameProvider>
  );
};

export default FillInTablePage;
