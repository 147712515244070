import styled, { css } from "styled-components";

import { LabelCode } from "@/types/code.types";

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 1.2rem;
  flex: 1;
`;

export const FilterLabelBox = styled.div<{ $isDisabledKeywords: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.2rem;
  padding: 0.4rem 0;
  flex-wrap: wrap;

  strong {
    display: flex;
    align-items: center;
    height: 2.4rem;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2.4rem;
    color: var(--Color-Common-Text-Secondary, #515c6c);
  }

  ${({ $isDisabledKeywords }) =>
    $isDisabledKeywords &&
    css`
      opacity: 0.5;
    `}
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
  flex-wrap: wrap;
`;

export const FilterLabel = styled.span<{ entity: LabelCode | null }>`
  display: inline-flex;
  height: 2.3rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  line-height: 2rem;
  border-radius: 0.4rem;
  padding: 0 0.8rem;
  white-space: nowrap;
  cursor: default;

  ${({ theme, entity }) =>
    entity
      ? css`
          background-color: ${theme.label.background[entity]};
          border: 0.1rem solid ${theme.label.stroke[entity]};
        `
      : css`
          background-color: ${theme.color.neural.gray01};
          border: 0.1rem solid ${theme.color.neural.gray05};
        `}
`;
