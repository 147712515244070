"use client";

import { useEffect, useRef } from "react";

import MarketTooltip from "./components/MarketTooltip/MarketTooltip";
import { useChangeTooltipStatus } from "./hooks/useChangeTooltipStatus";
import IconTrendingDown from "./icons/IconTrendingDown";
import IconTrendingUp from "./icons/IconTrendingUp";
import * as S from "./StatusChip.style";
import { IconMinus } from "../../../../components";
import { CompanyInfo } from "../../types/marketInfo.types";

type Props = {
  marketInfo: CompanyInfo;
  chipStatus: "up" | "keep" | "down";
  size?: "md" | "lg";
  isInteractive?: boolean;
  isMobileTextColumn?: boolean;
};

const StatusChip = ({
  marketInfo,
  chipStatus,
  size = "md",
  isInteractive = true,
  isMobileTextColumn = false,
}: Props) => {
  const chipRef = useRef<HTMLDivElement>(null);

  const {
    tooltipRef,
    changeTooltipPosition,
    resetTooltipPosition,
    touchToChangeTooltipStatus,
    showTooltip,
    tooltipPosition,
  } = useChangeTooltipStatus();

  useEffect(() => {
    const checkIsOutsideTouch = (event: TouchEvent) => {
      const currentTarget = event.target as HTMLElement;
      const isOutside = !chipRef.current?.contains(currentTarget);

      if (isOutside && showTooltip) {
        event.preventDefault();

        return resetTooltipPosition();
      }
    };
    window.addEventListener("touchend", checkIsOutsideTouch);

    return () => {
      window.removeEventListener("touchend", checkIsOutsideTouch);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTooltip]);

  const { exchange, symbol, roc, pre } = marketInfo;

  const returnCurrentIcon = () => {
    const iconSize = size === "md" ? 16 : 24;

    switch (chipStatus) {
      case "up":
        return <IconTrendingUp width={iconSize} height={iconSize} />;
      case "down":
        return <IconTrendingDown width={iconSize} height={iconSize} />;
      default:
        return <IconMinus width={iconSize} height={iconSize} color="#515C6C" />;
    }
  };

  const statusSymbol = (() => {
    if (chipStatus === "up") return "+";

    return "";
  })();

  const preMarketText = pre ? "(pre-market)" : "";
  const chipText = `${exchange}: ${symbol} | ${statusSymbol} ${
    roc === 0 ? "0.00" : roc?.toFixed(2)
  }%`;

  return (
    <S.ChipWrapper>
      <S.StatusChip
        ref={chipRef}
        $size={size}
        $chipStatus={chipStatus}
        $isInteractive={isInteractive}
        onMouseEnter={changeTooltipPosition}
        onMouseLeave={resetTooltipPosition}
        onTouchEnd={touchToChangeTooltipStatus}
        className="stock-chip"
      >
        <S.StockIconWrapper className="stock-icon">
          {returnCurrentIcon()}
        </S.StockIconWrapper>
        <S.ChipText
          $size={size}
          $chipStatus={chipStatus}
          $isMobileTextColumn={isMobileTextColumn}
          className="stock-chip-text-wrapper"
        >
          <S.StockText className="stock-chip-text">{chipText}</S.StockText>
          {preMarketText && (
            <S.PreMarketTextWrapper>
              &nbsp;{preMarketText}
            </S.PreMarketTextWrapper>
          )}
        </S.ChipText>
      </S.StatusChip>
      {isInteractive && (
        <MarketTooltip
          marketInfo={marketInfo}
          chipStatus={chipStatus}
          showTooltip={showTooltip}
          tooltipPosition={tooltipPosition}
          ref={tooltipRef}
        />
      )}
    </S.ChipWrapper>
  );
};

export default StatusChip;
