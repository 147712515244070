import styled from "styled-components";

export const SignInInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

export const SignInInputLabel = styled.label`
  color: ${({ theme }) => theme.color.neural.gray09};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.4;
`;

export const SignInInput = styled.input`
  display: flex;
  padding: 1.6rem 0.8rem 1.5rem 0.8rem;
  align-items: flex-start;
  align-self: stretch;

  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1;

  // TODO: 인풋 텍스트 색상이 아직 정해지지 않았습니다. 확인 후 변경해야합니다.
  color: ${({ theme }) => theme.color.neural.gray09};
  &::placeholder {
    color: ${({ theme }) => theme.color.neural.gray05};
  }

  outline: none;

  border-width: 0 0 0.1rem 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.color.neural.gray05};
  &:focus {
    border-color: ${({ theme }) => theme.color.node.blue};
  }
`;

export const SignInInputErrorMessage = styled.div`
  padding-top: 0.8rem;
  color: ${({ theme }) => theme.color.live.orange};
  height: 0.56rem; // TODO: 기본 값을 넣어두었는데, 이 부분도 확인이 필요한 부분입니다.

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
`;
