import { useCurrentDealTypeValue } from "@FillInTableContainer/shared/context/CurrentDealTypeContext";
import { useModalityCategoryValue } from "@FillInTableContainer/shared/context/ModalityCategoryContext";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  ValidatedInfoList,
  useValidatedInTableCreator,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

interface UseCheckValidationInTableCreatorReturn {
  handleCheckValidationFull: () => boolean;
}

export const useCheckValidationInDealsTableCreator =
  (): UseCheckValidationInTableCreatorReturn => {
    const entryData = useEntryDataValue(getPressIdFromURL());
    const { changeValidatedInfo } = useValidatedInTableCreator();
    const { changeInfo } = useTableCreatorPositionInfo();
    const { changeFocusStatus } = useTableCreatorFocusStatus();
    const { isMergeAndAcquisition } = useCurrentDealTypeValue();
    const { updateModalityCategory } = useModalityCategoryValue();

    const checkModalityCategory = () => {
      const drugProfileTableList = entryData.Deals?.["Drug Profile"];
      const modalityValues = drugProfileTableList?.map((table) =>
        table.Modality.value?.map((shell) => shell.text),
      );

      const valueInfo = modalityValues?.flatMap((table, tableIndex) =>
        table.map((shell) => {
          if (updateModalityCategory[shell] === "") {
            return {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex,
              groupShellKey: null,
              groupShellIndex: 0,
              shellKey: "Modality",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            };
          }
          return null;
        }),
      );
      return valueInfo?.filter((item) => item !== null);
    };

    const dealTermsLicenseeTableKey = isMergeAndAcquisition
      ? "Acquiring Company"
      : "Licensee";

    const checkDealTypeAndStatusTable = () => {
      const dealTypeAndStatusData = entryData.Deals?.["Deal Type & Status"];

      const sortedValidatedInfo = () => {
        const validatedInfoTemp: ValidatedInfoList = [];

        const isValidatedDealType =
          !dealTypeAndStatusData?.["Deal Type"].value.length;

        const isValidatedDealStatus =
          !dealTypeAndStatusData?.["Deal Status"].value.length;

        if (isValidatedDealType)
          validatedInfoTemp.push({
            groupTableKey: "Deal Type & Status",
            groupTableIndex: 1,
            tableKey: null,
            tableIndex: 0,
            groupShellKey: null,
            groupShellIndex: 0,
            shellKey: "Deal Type",
            shellIndex: 0,
            valueIndex: null,
            type: "alert",
            container: "shell",
          });

        if (isValidatedDealStatus)
          validatedInfoTemp.push({
            groupTableKey: "Deal Type & Status",
            groupTableIndex: 1,
            tableKey: null,
            tableIndex: 0,
            groupShellKey: null,
            groupShellIndex: 0,
            shellKey: "Deal Status",
            shellIndex: 0,
            valueIndex: null,
            type: "alert",
            container: "shell",
          });

        return validatedInfoTemp;
      };

      return sortedValidatedInfo();
    };

    const checkLicenseeTable = () => {
      const licensorData = entryData.Deals?.["Licensee Table"];

      const sortedValidatedInfo = () => {
        const validatedInfoTemp: ValidatedInfoList = [];

        licensorData?.forEach((tableInfo, tableInfoIndex) => {
          const isValidatedLicenseeName =
            !tableInfo["Licensee Name"].value.length;

          if (isValidatedLicenseeName)
            validatedInfoTemp.push({
              groupTableKey: "Licensee Table",
              groupTableIndex: 2,
              tableKey: dealTermsLicenseeTableKey,
              tableIndex: tableInfoIndex,
              groupShellKey: null,
              groupShellIndex: 0,
              shellKey: "Licensee Name",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            });
        });

        return validatedInfoTemp;
      };

      const mAndAFilterValidatedInfo = sortedValidatedInfo().filter(
        (maTable) => maTable.tableIndex === 0,
      );

      return isMergeAndAcquisition
        ? mAndAFilterValidatedInfo
        : sortedValidatedInfo();
    };

    const handleCheckValidationFull = () => {
      if (
        !checkModalityCategory()?.length &&
        !checkDealTypeAndStatusTable().length &&
        // !checkLicensorTable().length &&
        !checkLicenseeTable().length
      ) {
        changeInfo(DEFAULT_POSITION_INFO);
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
        return true;
      }

      const validatedList = [
        ...(checkModalityCategory() || []),
        ...(checkDealTypeAndStatusTable() || []),
        ...(checkLicenseeTable() || []),
      ];

      changeValidatedInfo(validatedList as ValidatedInfoList);
      return false;
    };

    return {
      handleCheckValidationFull,
    };
  };
