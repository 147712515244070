import { apiClient } from "@api/apiClient";
import { AuthorityType } from "@libs/roles/types/roles.types";

const API_ENDPOINT = {
  getSuperAdminRole: "/auth/is-super",
};

export const authorityTypeApis = {
  get: async () => {
    const { data } = await apiClient.get<AuthorityType>(
      API_ENDPOINT.getSuperAdminRole,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );

    return {
      isSuperAdmin: data,
    };
  },
};
