import React, { useEffect, useRef } from "react";

import { IconSearch } from "@frontend/common";

import {
  DEFAULT_IMAGE_FILTER,
  useInsertImageFilter,
} from "@InsertImageContainer/shared/contexts/InsertImageFilterContext";
import { useInsertImageHistoryInfo } from "@InsertImageContainer/shared/contexts/InsertImageHistoryInfoContext";

import * as S from "./InsertImageSearchInput.style";

const InsertImageSearchInput = () => {
  const imageSearchRef = useRef<HTMLInputElement>(null);
  const { insertImageFilter, changeInsertImageFilter } = useInsertImageFilter();
  const { insertImageHistoryInfo } = useInsertImageHistoryInfo();

  const handleSearchFilter = () => {
    if (!imageSearchRef.current) return;
    if (imageSearchRef.current.value.trim()) {
      changeInsertImageFilter({
        ...DEFAULT_IMAGE_FILTER,
        filter: {
          search: imageSearchRef.current.value,
        },
      });
    }
    if (!imageSearchRef.current.value.trim()) {
      changeInsertImageFilter({
        ...DEFAULT_IMAGE_FILTER,
        filter:
          insertImageHistoryInfo[0].type === "select"
            ? {
                ...insertImageHistoryInfo[0]?.imageFilter?.filter,
              }
            : {
                ...DEFAULT_IMAGE_FILTER.filter,
              },
      });
    }
  };

  const handleResetSearchFilter = () => {
    if (imageSearchRef.current) {
      imageSearchRef.current.value = "";
    }
    handleSearchFilter();
  };

  const handleKeyDownSearchFilter = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      event.currentTarget.blur();
    }
  };

  const isSearchKeyword = !!(
    (insertImageFilter.filter as { search: string }).search || ""
  );

  useEffect(() => {
    if (!imageSearchRef.current) return;
    if (!isSearchKeyword) {
      imageSearchRef.current.value = "";
    }
    if (isSearchKeyword) {
      imageSearchRef.current.value = (
        insertImageFilter.filter as { search: string }
      ).search;
    }
  }, [insertImageFilter, isSearchKeyword]);

  return (
    <>
      <S.InsertImageSearchInputWrapper className="search-box">
        <S.InsertImageSearchInput
          ref={imageSearchRef}
          type="text"
          placeholder="Search by keyword..."
          onBlur={handleSearchFilter}
          onKeyDown={handleKeyDownSearchFilter}
        />
        <S.SearchButton type="button">
          <IconSearch width={20} height={20} color="#0198E9" />
        </S.SearchButton>
      </S.InsertImageSearchInputWrapper>
      {isSearchKeyword && (
        <S.ResetButton type="button" onClick={handleResetSearchFilter}>
          Reset Search
        </S.ResetButton>
      )}
    </>
  );
};

export default InsertImageSearchInput;
