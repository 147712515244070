import { useLayoutEffect } from "react";

import { setDealTypeDisplayName } from "@utils/returnDealTypeDisplayName/returnDealTypeDisplayName";

import * as S from "./ArticleMainLabelContainer.style";
import MultipleEditableMainLabel from "./components/MultipleEditableMainLabel/MultipleEditableMainLabel";
import SingleEditableMainLabel from "./components/SingleEditableMainLabel/SingleEditableMainLabel";
import {
  MAIN_LABEL_ENTITY_TO_COLOR,
  MAIN_LABEL_FORMAT_TYPES,
} from "./constants/mainLabelType.constants";
import { useCheckInitialMainLabelList } from "./hooks/useCheckInitialMainLabelList";
import { useMainLabelDropDownToggleEvent } from "./hooks/useMainLabelDropDownToggleEvent";
import { useMainLabelList } from "./hooks/useMainLabelList";

interface Props {
  editable?: boolean;
}

const ArticleMainLabelContainer = ({ editable = false }: Props) => {
  useCheckInitialMainLabelList();
  const { mainLabelList } = useMainLabelList();

  const {
    openTargetDropdownLabelName,
    isOpenDropDown,
    changeTargetDropdown,
    closeTargetDropdown,
  } = useMainLabelDropDownToggleEvent();
  const dropDownEvents = {
    isOpenDropDown,
    changeTargetDropdown,
    closeTargetDropdown,
  };

  useLayoutEffect(() => {
    setDealTypeDisplayName();
  }, []);

  if (editable)
    return (
      <S.ArticleMainLabelWrapper>
        {mainLabelList.map((label) => {
          if (MAIN_LABEL_FORMAT_TYPES[label.type] === "singleEditable") {
            return (
              <SingleEditableMainLabel
                key={label.labelName}
                labelInfo={label}
                dropDownEvents={dropDownEvents}
              />
            );
          }
          if (MAIN_LABEL_FORMAT_TYPES[label.type] === "multipleEditable") {
            return (
              <MultipleEditableMainLabel
                key={label.labelName}
                labelInfo={label}
                dropDownEvents={dropDownEvents}
              />
            );
          }
          return (
            label.value.length > 0 && (
              <>
                {label.value.map((labelValue) => (
                  <S.ArticleMainLabelItem
                    key={label.labelName}
                    color={MAIN_LABEL_ENTITY_TO_COLOR[label.entity]}
                    onClick={closeTargetDropdown}
                  >
                    {labelValue}
                  </S.ArticleMainLabelItem>
                ))}
              </>
            )
          );
        })}
        {openTargetDropdownLabelName !== null && (
          <S.Overlay onClick={closeTargetDropdown} />
        )}
      </S.ArticleMainLabelWrapper>
    );
  else
    return (
      <S.ArticleMainLabelWrapper>
        {mainLabelList.flatMap(({ entity, labelName, value: valueList }) =>
          valueList.map((value, index) => (
            <S.ArticleMainLabelItem
              key={`${labelName}-${String(index)}`}
              color={MAIN_LABEL_ENTITY_TO_COLOR[entity]}
              onClick={closeTargetDropdown}
            >
              {value}
            </S.ArticleMainLabelItem>
          )),
        )}
      </S.ArticleMainLabelWrapper>
    );
};

export default ArticleMainLabelContainer;
