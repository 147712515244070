import React from "react";

import * as S from "./TableGridColumnLayout.style";

interface Props {
  children: React.ReactNode;
  columnCount: number;
  gap?: number;
}

const TableGridColumnLayout = ({ children, columnCount, gap }: Props) => {
  return (
    <S.TableGridColumnLayoutWrapper $columnCount={columnCount} $gap={gap}>
      {children}
    </S.TableGridColumnLayoutWrapper>
  );
};

export default TableGridColumnLayout;
