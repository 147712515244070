/**
 * NOTE :
 * translated id를 title에서 분리하기 위한 함수
 * return {text, code}
 */
export const separateTextAndTranslateCode = (
  input: string,
): {
  text: string;
  code: string | null;
} => {
  const regex = /\[translate:\s*([^\]]+)\]/;
  const match = input.match(regex);

  if (match) {
    const text = input.slice(0, match.index).trim();
    const code = `[translate:${match[1]}]`;
    return { text, code };
  }
  return { text: input.trim(), code: null };
};
