export const getQueryParamsForRedirect = () => {
  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");
  const page = params.get("page");
  const token = params.get("token");

  if (!id || !page) {
    throw new Error("Missing query parameters");
  }

  return { id, page, token: token || "" };
};
