import React from "react";

import InsertImageContainer from "@/components/business/InsertImageContainer/InsertImageContainer";
import DeletedPressBoundary from "@/components/shared/business/DeletedPressBoundary/DeletedPressBoundary";
import { useSafeLeavePage } from "@hooks/useSafeLeavePage";
import InsertImageRoleGuard from "@libs/roles/components/InsertImageRoleGuard";

const InsertImagePage = () => {
  useSafeLeavePage();

  return (
    <InsertImageRoleGuard>
      <DeletedPressBoundary>
        <InsertImageContainer />
      </DeletedPressBoundary>
    </InsertImageRoleGuard>
  );
};

export default InsertImagePage;
