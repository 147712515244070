import styled, { css } from "styled-components";

export const ValidationMessageWrapper = styled.div<{
  $isMultipleValue: boolean;
}>`
  position: absolute;
  top: 0;
  left: ${({ $isMultipleValue }) =>
    $isMultipleValue ? "calc(100% + 4.2rem)" : "calc(100% + 1.6rem)"};

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 29.6rem;
  height: 100%;
  cursor: default;

  .shell:has(&:hover) {
    border: 1px solid var(--grayscale-spectrum-neural-blue-gray-02, #edf1f8);
    background: #fff;
    box-shadow: none;
  }
`;

export const MessageText = styled.div<{ $isPassed: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  color: var(--Color-Common-Text-Warning, #e53e06);
  font-size: 1.2rem;
  font-weight: 500;

  ${({ $isPassed }) =>
    $isPassed &&
    css`
      color: var(--Color-Common-Text-Interactive-Primary, #0198e9);

      path {
        fill: var(--Color-Common-Text-Interactive-Primary, #0198e9);
      }
    `}
`;

export const ValidationInfoButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
`;
