import { useCallback, useEffect, useRef } from "react";

import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { ARTICLE_SECTION_BY_CLASSIFICATION } from "@FillInTableContainer/shared/constants/articleSection.constants";
import { useUpdateEntryData } from "@hooks/useUpdateArticleData";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import {
  CHECK_ATTRIBUTION_ID_IN_ENTRY_DATA,
  CHECK_ENTRY_DATA_FORMAT,
} from "../constants/entryDataFormat.constants";

export const useEnterAttributionIdInEntryData = () => {
  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );

  const articleSection =
    (articleInfo &&
      ARTICLE_SECTION_BY_CLASSIFICATION[articleInfo?.classificationManual]) ||
    "none";

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const updateEntryData = useUpdateEntryData();

  const entryDataRef = useRef(entryData);

  const enterAttributionIdInEntryData = useCallback(() => {
    if (articleSection === "none") return;

    const checkAttributionIdInEntryData =
      CHECK_ATTRIBUTION_ID_IN_ENTRY_DATA[articleSection](entryData);

    if (checkAttributionIdInEntryData) return;
    const entryDataWithAttributionId = CHECK_ENTRY_DATA_FORMAT[articleSection](
      entryDataRef.current,
    );

    setEntryData(entryDataWithAttributionId);
    updateEntryData(entryDataWithAttributionId);
  }, [articleSection, entryData, setEntryData, updateEntryData]);

  useEffect(() => {
    enterAttributionIdInEntryData();
    // DESCRIPTIONㄴ: 처음 렌더링 된 경우만 실행
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
