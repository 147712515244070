import { VentureCapitalGroupTable } from "@/types/ventureCapital.types";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { TableCreatorTableTitle } from "@FillInTableContainer/shared";
import { TableCreatorGroupShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import {
  isGroupShellScroll,
  isTableScroll,
} from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import VentureCapitalKeyCommentCreatorGroupShell from "./components/VentureCapitalKeyCommentCreatorGroupShell/VentureCapitalKeyCommentCreatorGroupShell";
import * as S from "./VentureCapitalKeyCommentCreatorTable.style";
import { useHandleVentureCapitalTableCreatorTable } from "../../../VentureCapitalDrugProfileGroupTable/components/DealsTableCreatorTable/hooks/useHandleVentureCapitalTableCreatorTable";

export interface TableInfoProps {
  groupTableKey: VentureCapitalGroupTable;
  tableKey: string | null;
  groupShellList: TableCreatorGroupShellList;
  groupTableIndex: number;
  tableIndex: number;
  tableCount: number;
}
type Props = TableInfoProps;

const VentureCapitalKeyCommentCreatorTable = ({
  groupTableKey,
  tableKey,
  groupTableIndex,
  tableIndex,
  tableCount,
  groupShellList,
}: Props) => {
  const articleSection = useArticleSection();
  const tablePropsInfo: TableInfoProps = {
    groupTableKey,
    tableKey,
    groupTableIndex,
    tableIndex,
    tableCount,
    groupShellList,
  };

  const {
    info,
    focusStatus,
    isFocused,
    tableWrapperRef,
    handleClickDeleteButton,
    focusEventToChangePositionInfo,
    numberOfGroupShellCount,
  } = useHandleVentureCapitalTableCreatorTable(tablePropsInfo);

  const isClearTable =
    isFocused &&
    info.groupShellKey === null &&
    info.groupShellIndex === null &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null;

  const isTableScrollWrapper = isTableScroll(articleSection, tableKey);

  return (
    <S.TableWrapper
      isFocused={isFocused}
      isTableScroll={isTableScrollWrapper}
      isClearTable={isClearTable}
    >
      {isClearTable && focusStatus.type !== "default" && (
        <S.DeleteTableButtonWrapper>
          <S.DeleteTableButton onClick={handleClickDeleteButton}>
            <img src={IconXMark} alt="button for clear table" />
            {focusStatus.type === "clear"
              ? `Clear ${
                  [
                    "Clinical Endpoints (Table)",
                    "Clinical Trial Design-2",
                  ].includes(groupTableKey)
                    ? "Table"
                    : ""
                }`
              : null}
            {focusStatus.type === "delete"
              ? `Delete ${
                  [
                    "Clinical Endpoints (Table)",
                    "Clinical Trial Design-2",
                  ].includes(groupTableKey)
                    ? "Table"
                    : ""
                }`
              : null}
          </S.DeleteTableButton>
        </S.DeleteTableButtonWrapper>
      )}
      <S.ScrollWrapper
        isTableScroll={isTableScrollWrapper}
        ref={tableWrapperRef}
      >
        <S.ScrollContentsWrapper>
          {getTableTitle(articleSection, tableKey) && (
            <TableCreatorTableTitle
              tableKey={tableKey}
              groupTableKey={groupTableKey}
              groupTableIndex={groupTableIndex}
              tableIndex={tableIndex}
              onClick={() =>
                focusEventToChangePositionInfo({
                  type: "clear",
                  container: "table",
                })
              }
            />
          )}

          {groupShellList.map(
            ({ groupShellKey, shellList }, groupShellListIndex) => (
              <S.GroupShellWrapper
                key={`groupShellList-${String(groupShellListIndex)}`}
              >
                {newArrayToCountValue(
                  numberOfGroupShellCount(groupShellKey),
                ).map((_, groupShellIndex) => (
                  <VentureCapitalKeyCommentCreatorGroupShell
                    key={`group-shell-${String(groupTableIndex)}-${String(
                      tableIndex,
                    )}-${String(groupShellIndex)}`}
                    shellList={shellList}
                    groupTableKey={groupTableKey}
                    groupTableIndex={groupTableIndex}
                    tableKey={tableKey}
                    tableIndex={tableIndex}
                    groupShellKey={groupShellKey}
                    groupShellIndex={groupShellIndex}
                    groupShellCount={numberOfGroupShellCount(groupShellKey)}
                    isTableScroll={
                      isTableScroll(articleSection, tableKey) ||
                      isGroupShellScroll(articleSection, groupShellKey)
                    }
                  />
                ))}
              </S.GroupShellWrapper>
            ),
          )}
        </S.ScrollContentsWrapper>
      </S.ScrollWrapper>
    </S.TableWrapper>
  );
};

export default VentureCapitalKeyCommentCreatorTable;
