"use client";

import styled, { css } from "styled-components";

export const FilterItemWrapper = styled.label<{ $isCategory: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  padding-right: var(--Spacing-8, 0.8rem);
  gap: 0.2rem;
  cursor: pointer;

  ${({ $isCategory }) =>
    $isCategory &&
    css`
      padding: var(--Spacing-0, 0rem) var(--Spacing-8, 0.8rem)
        var(--Spacing-0, 0rem) var(--Spacing-16, 1.6rem);
    `}

  &:hover {
    background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #e6f6ff);
  }
  &:active {
    background: var(
      --Color-Common-Bg-Interactive-Primary-Subtle-Pressed,
      #a4dfff
    );
  }
`;

export const FilterCheckbox = styled.input<{ $imageUrl: string }>`
  all: unset;
  display: inline-block;
  margin: var(--Spacing-6, 0.48rem);
  width: 1.44rem;
  height: 1.44rem;
  flex-shrink: 0;
  border-radius: var(--Border-Radius-2, 0.12rem);
  border: 1.2px solid
    var(--Color-Common-Border-Interactive-Secondary-Hovered, #96a1b0);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  box-sizing: border-box;
  &:checked {
    background: var(--Color-Common-Bg-Interactive-Primary-Hovered, #0198e9);
    border: 1.2px solid
      var(--Color-Common-Bg-Interactive-Primary-Hovered, #0198e9);
    background-image: url(${({ $imageUrl }) => $imageUrl});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.44rem 1.44rem;

    ~ span {
      color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
    }
  }
`;

export const FilterText = styled.span`
  width: 100%;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-weight: 400;
`;

export const CategoryAccordionButton = styled.button`
  cursor: pointer;
  padding: 0;
  width: 2.4rem;
  height: 2.4rem;
  > svg {
    pointer-events: none;
  }
`;
