import { SectionCode } from "@frontend/common";

import { apiClient } from "@api/apiClient";

import { GetImageKeywordResponse } from "./imageKeyword.interface";

const API_ENDPOINTS = {
  get: "/des/article-entry-data/image-keyword",
};

export const imageKeywordApis = {
  get: async (pressId: string, sectionCode: SectionCode) => {
    const url = `${API_ENDPOINTS.get}?id=${pressId}&section=${sectionCode}`;
    const response = await apiClient.get<GetImageKeywordResponse>(url);

    return response;
  },
};
