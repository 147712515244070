import { useGetPressCopiedList } from "@api/pressCopy/pressCopy";
import { DRAFT_STATUS_PATH } from "@PressListContainer/draftStatusInfo.constants";

import { PressCopiedList } from "../types/pressCopiedList.types";

export const usePressCopiedList = () => {
  const { data: pressCopiedList, isLoading } = useGetPressCopiedList();

  const formatPressCopiedList = (data: PressCopiedList) => {
    return data.map(
      ({
        draftArticleId,
        classificationManualName,
        draftArticleStatusHighest,
      }) => {
        const pressIdText = `#${String(draftArticleId)}`;
        const classificationText = `(${classificationManualName})`;
        const targetUrl = `${DRAFT_STATUS_PATH[draftArticleStatusHighest]}?id=${draftArticleId}`;
        return {
          pressIdText,
          classificationText,
          targetUrl,
        };
      },
    );
  };

  return {
    pressCopiedList: formatPressCopiedList(pressCopiedList?.data || []),
    isLoading,
  };
};
