"use client";

import styled, { css } from "styled-components";

const YOY_COLOR = {
  increase: "var(--Color-Common-Text-Interactive-Primary, #0198e9)",
  decrease: "var(--Color-Common-Text-Interactive-Destructive, #e53e06)",
  keep: " var(--Color-Common-Text-Disabled, #96a1b0)",
};

export const TOP_INFO_TEXT = {
  main: css`
    color: var(--Color-Common-Text-Primary, #2a2f37);

    font-family: var(--font-pt-serif);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 145%;

    .total-value {
      font-size: 1.1rem;
    }

    .yoy-text {
      color: var(--Color-Common-Text-Secondary, #515c6c);
      font-size: 1.2rem;
    }
  `,

  sub: css`
    color: var(--Color-Common-Text-Secondary, #515c6c);

    font-family: var(--font-pt-serif);
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 130%;

    .yoy-text {
      color: var(--Color-Common-Text-Tertiary, #7c899c);
      font-size: 1.1rem;
    }

    .total-value {
      font-size: 1rem;
    }
  `,
};

export const ChartContentWrapper = styled.div`
  border-radius: var(--Border-Radius-8, 0.8rem);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Primary, #fff);

  display: flex;
  padding: 0.6rem;
  flex-direction: column;
  gap: var(--Spacing-8, 0.8rem);

  &:hover {
    border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const ChartTopInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const YearToDateWrapper = styled.div`
  display: flex;
  padding: var(--Spacing-4, 0.4rem);
  flex-direction: column;
  justify-content: center;
  gap: var(--Spacing-2, 0.2rem);
`;

export const RightTextWrapper = styled.div`
  display: flex;
  padding: var(--Spacing-4, 0.4rem);
  flex-direction: column;
  align-items: flex-end;
  gap: var(--Spacing-4, 0.4rem);

  @media only screen and (max-width: 1199px) {
    padding-right: 0.2rem;
  }
`;

export const YearToDateText = styled.div`
  color: var(--Color-Common-Text-Tertiary, #7c899c);

  font-family: var(--font-pt-serif);
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 130%;
`;

export const TopInfoText = styled.div<{ $type: "main" | "sub" }>`
  ${({ $type }) => TOP_INFO_TEXT[$type]}
`;

export const MainChartText = styled(TopInfoText)`
  display: flex;
  align-items: center;
  gap: var(--Spacing-6, 0.6rem);

  @media only screen and (max-width: 1199px) {
    gap: 0.2rem;
  }
`;

export const MainChartLeftText = styled.div`
  display: flex;
  justify-content: center;
  gap: var(--Spacing-4, 0.4rem);

  @media only screen and (max-width: 1199px) {
    gap: 0.2rem;
  }
`;

export const ROCText = styled.div<{
  $yoy: "increase" | "decrease" | "keep";
  $isHighlightYoyChip?: boolean;
  $type: "main" | "sub";
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $isHighlightYoyChip, $type }) =>
    $isHighlightYoyChip &&
    css`
      border-radius: var(--Border-Radius-4, 0.4rem);
      background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #f0f9ff);
      padding: var(--Spacing-2, 0.2rem) var(--Spacing-4, 0.4rem);
      height: ${$type === "main" ? "2rem" : "1.6rem"};
    `}

  ${({ $yoy }) => css`
    .yoy {
      color: ${YOY_COLOR[$yoy]};
    }
  `}
`;

export const SubChartTextWrapper = styled(TopInfoText)`
  display: flex;
  padding-bottom: var(--Spacing-2, 0.2rem);
  align-items: center;
  gap: var(--Spacing-4, 0.4rem);

  @media only screen and (max-width: 1199px) {
    gap: 0.2rem;
  }
`;

export const SubChartLeftText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;

  @media only screen and (max-width: 1199px) {
    gap: 0.2rem;
  }
`;
export const LegendWrapper = styled.div<{ $legendSort: "row" | "column" }>`
  display: flex;
  flex-direction: ${({ $legendSort }) => $legendSort};
  align-items: center;
  padding: 0.3rem var(--Spacing-0, 0rem);
  gap: 0.4rem;

  @media only screen and (max-width: 1199px) {
    flex-direction: column;
  }
`;

export const Legend = styled.div`
  display: flex;
  padding: 0rem var(--Spacing-4, 0.4rem);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-4, 0.4rem);

  color: var(--Color-Common-Text-Tertiary, #7c899c);
  text-align: right;

  font-family: var(--font-pt-serif);
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 130%;
`;

export const LegendDot = styled.div<{ $backgroundColor: string }>`
  width: 1.2rem;
  height: 1.2rem;

  border-radius: 50%;

  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;
