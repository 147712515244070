"use client";

import styled, { css } from "styled-components";

export const ValueShell = styled.div<{
  $width?: string;
  $justifyContent?: string;
  $padding?: string;
  $isDisabled?: boolean;
  $fontSize: string;
  $isCapitalize?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  flex: 1;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: ${({ $fontSize }) => $fontSize};
  font-weight: 500;
  line-height: 150%;
  background: var(--NEURAL-Gray-010, #fff);
  padding: 1rem;
  white-space: pre-wrap;
  border-right: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);

  ${({ $isCapitalize }) =>
    $isCapitalize &&
    css`
      text-transform: capitalize;
    `}

  ${({ $justifyContent }) =>
    $justifyContent &&
    css`
      justify-content: ${$justifyContent};
    `};

  ${({ $padding }) =>
    $padding &&
    css`
      padding: ${$padding};
    `};

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
      max-width: ${$width};
    `};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      &:hover {
        color: var(--Color-Common-Text-Primary, #2a2f37);
        background: var(--Color-Common-Bg-Disabled, #cbd0d8);
        cursor: not-allowed;
      }
    `}

  i {
    font-style: italic;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 165%;
  }

  b {
    font-weight: 600;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
  }

  sup {
    vertical-align: super;
    font-size: 1rem;
  }

  sub {
    vertical-align: sub;
    font-size: 1rem;
  }
`;
