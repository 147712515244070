"use client";

import styled, { css } from "styled-components";

export const PagePathTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  width: fit-content;
  height: 100%;
`;

export const PagePathText = styled.div<{ $isPath: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 2.8rem;
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 100%;
  white-space: nowrap;
  border-radius: 0.4rem;
  padding: 0 0.4rem;

  ${({ $isPath }) =>
    $isPath &&
    css`
      cursor: pointer;

      &:hover {
        background: var(
          --Color-Common-Bg-Interactive-Secondary-Hovered,
          #f0f2f4
        );
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
          0px 2px 4px 0px rgba(0, 0, 0, 0.16);
      }
    `}

  &::before {
    position: absolute;
    left: -0.8rem;
    top: 0;
    content: "";
    display: block;
    width: 0.1rem;
    height: 2.8rem;
    background-color: var(--Color-Common-Border-Secondary, #b1b8c4);
  }
`;
