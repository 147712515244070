import { apiClient } from "@api/apiClient";

import {
  GetMainLabelInfoResponse,
  GetMainLabelsResponse,
  MainLabelArticleSectionCode,
  MainLabelListForUpdateType,
  PostMainLabelResponse,
} from "./mainLabel.interface";

const API_ENDPOINTS = {
  getInfo: "/des/main-label/list",
  getLabels: "/des/main-label/entity-data",
  post: "/des/main-label/entity-save",
};

export const mainLabelApis = {
  getInfo: async (sectionType: MainLabelArticleSectionCode) => {
    const API_URL = `${API_ENDPOINTS.getInfo}/${sectionType}`;
    const response = await apiClient.get<GetMainLabelInfoResponse>(API_URL);

    return response.data;
  },
  getLabels: async (draftArticleId: string) => {
    const API_URL = `${API_ENDPOINTS.getLabels}`;
    const response = await apiClient.get<GetMainLabelsResponse>(API_URL, {
      params: {
        id: draftArticleId,
      },
    });

    return response.data;
  },
  post: async (
    draftArticleId: string,
    mainLabelData: MainLabelListForUpdateType,
  ) => {
    const API_URL = `${API_ENDPOINTS.post}`;
    const response = await apiClient.post<PostMainLabelResponse>(API_URL, {
      draftArticleId: Number(draftArticleId),
      mainLabelData,
    });

    return response.data;
  },
};
