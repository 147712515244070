import React, { HTMLAttributes, forwardRef } from "react";

import * as S from "./PlaceholderInput.style";

export interface Props extends HTMLAttributes<HTMLInputElement> {
  placeholder: string;
  styleType: "line" | "transparent";
  status: "focus" | "error" | "disabled" | "default";
  width?: string;
  height?: string;
  LeadingIcon?: React.ReactNode;
  TrailingIcon?: React.ReactNode;
  isDefaultShadow?: boolean;
}

const PlaceholderInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder = "Placeholder",
      styleType = "line",
      status = "default",
      width,
      height,
      LeadingIcon,
      TrailingIcon,
      isDefaultShadow = false,
      ...rest
    }: Props,
    ref,
  ) => {
    return (
      <S.PlaceholderInputWrapper
        $status={status}
        $styleType={styleType}
        $width={width}
        $height={height}
        $isDefaultShadow={isDefaultShadow}
      >
        {LeadingIcon && <>{LeadingIcon}</>}
        <S.DefaultInput
          placeholder={placeholder}
          disabled={status === "disabled"}
          ref={ref}
          {...rest}
        />
        {TrailingIcon && <>{TrailingIcon}</>}
      </S.PlaceholderInputWrapper>
    );
  },
);

export default PlaceholderInput;
