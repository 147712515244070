import React from "react";

import { useScroll } from "@FillInTableContainer/components/FillInTable/hooks/useScroll";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { TableCreatorGroupTableTitle } from "@FillInTableContainer/shared";
import { useCanEditGroupTable } from "@FillInTableContainer/shared/hooks/useCanEditGroupTable";
import { useGetNumberOfTable } from "@FillInTableContainer/shared/hooks/useGetNumberOfTable";
import { isRequiredGroupTable } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getGroupTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import VentureCapitalFundedCompanyCreatorTable from "./components/VentureCapitalFundedCompanyCreatorTable/VentureCapitalFundedCompanyCreatorTable";
import { VENTURE_CAPITAL_FUNDED_COMPANY_GROUP_TABLE_LAYOUT } from "./constants/ventureCapitalFundedCompanyGroupTableLayout.constants";
import * as S from "./VentureCapitalFundedCompanyGroupTable.style";

const { groupTableKey, tableList } =
  VENTURE_CAPITAL_FUNDED_COMPANY_GROUP_TABLE_LAYOUT;
const groupTableIndex = 1;

const VentureCapitalFundedCompanyGroupTable = () => {
  const { register } = useScroll();
  const { tableCount } = useGetNumberOfTable();
  const articleSection = useArticleSection();

  const { checkDisableEdit } = useCanEditGroupTable();

  return (
    <S.GroupTableWrapper
      isDisableEdit={checkDisableEdit(groupTableKey)}
      {...register(groupTableKey)}
    >
      <TableCreatorGroupTableTitle
        title={getGroupTableTitle(articleSection, groupTableKey)}
        isRequired={isRequiredGroupTable(articleSection, groupTableKey)}
      />
      <S.TableCreatorTableListWrapper
        isDisableEdit={checkDisableEdit(groupTableKey)}
      >
        {newArrayToCountValue(tableCount(groupTableKey)).map(
          (_, tableIndex) => (
            <div key={`table-${String(groupTableIndex)}-${String(tableIndex)}`}>
              {tableList.map(({ tableKey, groupShellList }) => (
                <VentureCapitalFundedCompanyCreatorTable
                  key={`table-${String(groupTableIndex)}-${String(tableIndex)}`}
                  tableKey={tableKey}
                  groupShellList={groupShellList}
                  groupTableKey={groupTableKey}
                  groupTableIndex={groupTableIndex}
                  tableIndex={tableIndex}
                  tableCount={tableCount(groupTableKey)}
                />
              ))}
            </div>
          ),
        )}
      </S.TableCreatorTableListWrapper>
    </S.GroupTableWrapper>
  );
};

export default VentureCapitalFundedCompanyGroupTable;
