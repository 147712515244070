import { useCallback, useEffect } from "react";

import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_INVESTORS_GROUP_TABLE_KEY as INVESTORS,
  ENTRY_DATA_INVESTORS_SHELL_KEYS as INVESTORS_SHELL_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import { EntryDataInvestorValue } from "@/types/ventureCapital.types";
import { useTableCreatorFocusStatus } from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

const sectionKey = SECTION_KEYS.ventureCapital;
const groupTableKey = INVESTORS;
const shellKey = INVESTORS_SHELL_KEYS.investorName;
export const useInvestorTableValueTextClear = () => {
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();

  const clearInvestorTableValueText = useCallback(
    (valueIndex: number) => {
      const updatedEntryData = {
        ...entryData,
        [sectionKey]: {
          ...entryData[sectionKey],
          [groupTableKey]: {
            ...entryData?.[sectionKey]?.[groupTableKey],
            [shellKey]: {
              ...entryData?.[sectionKey]?.[groupTableKey]?.[shellKey],
              value: entryData?.[sectionKey]?.[groupTableKey]?.[
                shellKey
              ]?.value?.map((value, index) =>
                index === valueIndex
                  ? {
                      ...value,
                      text: "",
                    }
                  : value,
              ) as EntryDataInvestorValue[],
            },
          },
        },
      };
      changeFocusStatus({ type: "default", container: "noFocus" });
      changeInfo(DEFAULT_POSITION_INFO);
      setEntryData(updatedEntryData as ArticleEntryData);
    },
    [changeFocusStatus, changeInfo, entryData, setEntryData],
  );

  const clearInvestorTableValueCheckbox = useCallback(
    (valueIndex: number) => {
      const updatedEntryData = {
        ...entryData,
        [sectionKey]: {
          ...entryData[sectionKey],
          [groupTableKey]: {
            ...entryData?.[sectionKey]?.[groupTableKey],
            [shellKey]: {
              ...entryData?.[sectionKey]?.[groupTableKey]?.[shellKey],
              value: entryData?.[sectionKey]?.[groupTableKey]?.[
                shellKey
              ]?.value?.map((value, index) =>
                index === valueIndex
                  ? {
                      ...value,
                      is_lead_investor: false,
                    }
                  : value,
              ) as EntryDataInvestorValue[],
            },
          },
        },
      };

      setEntryData(updatedEntryData as ArticleEntryData);
      changeFocusStatus({ type: "default", container: "noFocus" });
      changeInfo(DEFAULT_POSITION_INFO);
    },
    [changeFocusStatus, changeInfo, entryData, setEntryData],
  );

  const keyDownDeleteToClearInvestorValueText = useCallback(
    (event: KeyboardEvent) => {
      const { valueIndex } = info;
      if (focusStatus.type === "clear" && event.key === "Delete") {
        clearInvestorTableValueText(valueIndex as number);
      }
    },
    [info, focusStatus.type, clearInvestorTableValueText],
  );

  const keyDownDeleteToClearInvestorValueCheckbox = useCallback(
    (event: KeyboardEvent) => {
      const { valueIndex } = info;
      if (focusStatus.type === "clear" && event.key === "Delete") {
        clearInvestorTableValueCheckbox(valueIndex as number);
      }
    },
    [info, focusStatus.type, clearInvestorTableValueCheckbox],
  );

  useEffect(() => {
    if (focusStatus.container !== "textField") return;

    window.addEventListener("keydown", keyDownDeleteToClearInvestorValueText);

    return () => {
      window.removeEventListener(
        "keydown",
        keyDownDeleteToClearInvestorValueText,
      );
    };
  }, [focusStatus, keyDownDeleteToClearInvestorValueText]);

  useEffect(() => {
    if (focusStatus.container !== "checkbox") return;

    window.addEventListener(
      "keydown",
      keyDownDeleteToClearInvestorValueCheckbox,
    );

    return () => {
      window.removeEventListener(
        "keydown",
        keyDownDeleteToClearInvestorValueCheckbox,
      );
    };
  }, [focusStatus, keyDownDeleteToClearInvestorValueCheckbox]);

  return { clearInvestorTableValueText, clearInvestorTableValueCheckbox };
};
