import styled, { css } from "styled-components";

export const InsertImageBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.2rem;
`;

export const Image = styled.div<{ isSelectedImage: boolean }>`
  position: relative;
  width: 100%;
  height: 20rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.neural.gray00};
  border-radius: 0.8rem;
  box-shadow: 0px 4px 8px 0px rgba(7, 68, 161, 0.02);
  border: 0.1rem solid #b1b8c4;

  &:hover {
    ${({ isSelectedImage }) =>
      !isSelectedImage &&
      css`
        .image-background {
          display: flex;
        }
      `}
  }

  ${({ isSelectedImage }) =>
    isSelectedImage &&
    css`
      .image-background {
        display: flex;
      }
    `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ZoomInButton = styled.button`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  z-index: 1;
  width: 3.33rem;
  height: 3.33rem;
  cursor: pointer;
`;

export const ImageBackground = styled.div<{ isSelectedImage: boolean }>`
  position: absolute;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  cursor: pointer;

  ${({ isSelectedImage }) =>
    isSelectedImage &&
    css`
      background-color: rgba(1, 152, 233, 0.5);
      cursor: not-allowed;
    `}

  h3,
  p {
    color: ${({ theme }) => theme.color.neural.gray00};
  }
  h3 {
    font-weight: 600;
    font-size: 2rem;
  }
  p {
    font-weight: 500;
    font-size: 1.4rem;
  }
`;

export const SelectedImageText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.neural.gray00};
  font-size: 2rem;
  font-weight: 600;
  line-height: 120%;
  gap: 0.8rem;
  img {
    width: 5.6rem;
    height: 5.6rem;
  }
`;

export const ImageKeyword = styled.h4`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.neural.gray08};
  line-height: 1.6rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: #e5e7eb;
`;

export const KeywordWrapper = styled.div`
  position: relative;
  width: 100%;
  line-height: 2.4rem;
  padding-right: 2.4rem;
`;

export const Keyword = styled.div`
  display: inline;
  font-size: 1.4rem;
  font-weight: 500;
  color: #3c4757;
  padding: 0.2rem 0.4rem;
  margin-right: 0.8rem;
  border-radius: 0.2rem;
  background-color: #f0f2f4;
`;

export const InfoButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  width: 2rem;
  height: 2rem;
  padding: 0;
  cursor: pointer;

  &:hover {
    path {
      fill: #0198e9;
    }
    .image-tooltip {
      display: flex;
    }
  }
`;

export const InfoContainer = styled.div`
  position: absolute;
  right: -0.02rem;
  bottom: calc(100% + 1rem);
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  gap: 0.4rem;
  max-width: 32.2rem;
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Primary, #fff);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.16),
    0px 0px 8px 0px rgba(0, 0, 0, 0.12);
  white-space: nowrap;
  padding: var(--Spacing-6, 0.6rem) var(--Spacing-12, 1.2rem);
  z-index: 1;
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 0.4rem;
`;

export const InfoTitle = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
  color: var(--grayscale-spectrum-neural-blue-gray-07, #687485);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
`;

export const InfoValue = styled.div`
  flex: 1;
  width: 32rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  word-break: break-all;
  white-space: pre-wrap;
`;
