import React, { useCallback } from "react";

export const useHandlePasteText = () => {
  const pasteText = useCallback(
    (event: React.ClipboardEvent<HTMLDivElement>) => {
      event.preventDefault();

      const text = event.clipboardData.getData("text/plain");
      const selection = window.getSelection();
      const range = selection?.getRangeAt(0);
      range?.deleteContents();

      const textNode = document.createTextNode(text);
      range?.insertNode(textNode);
      range?.collapse(false);
      selection?.removeAllRanges();

      if (range) selection?.addRange(range);

      const inputEvent = new Event("input", { bubbles: true });
      textNode.parentElement?.dispatchEvent(inputEvent);
    },
    [],
  );

  return { pasteText };
};
