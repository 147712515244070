import React, { useEffect, useRef } from "react";

import {
  CLINICAL_TRIAL_DESIGN_SHELL_KEYS,
  CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { ClinicalTrialsSection } from "@/types/clinicalTrials.types";
import SubGroupShellDividerPlusBar from "@FillInTableContainer/components/FillInTable/components/TableCreator/components/DealsTableCreator/components/DealsDealTermsTableCreatorContainer/components/DealTermsLicenseAgreementGroupTable/components/DealTermsLicenseAgreementTable/components/DealTermsLicenseAgreementGroupShellSection/components/DealTermsLicenseAgreementGroupShell/components/DealTermsLicenseAgreementSubGroupShell/components/SubGroupShellDividerPlusBar/SubGroupShellDividerPlusBar";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import SubGroupShellTitle from "@FillInTableContainer/shared/components/SubGroupShellTitle/SubGroupShellTitle";
import { useGetNumberOfShell } from "@FillInTableContainer/shared/hooks/useGetNumberOfShell";
import { TableCreatorShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import {
  getHaveSubGroupShellTitle,
  isAddDeleteSubGroupShell,
} from "@FillInTableContainer/shared/utils/getOptionsFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import * as S from "./ClinicalTrialDEsignSubGroupShell.style";
import ClinicalTrialDesignShell from "./components/ClinicalTrialDesignShell/ClinicalTrialDesignShell";
import { useTableCreatorClinicalTrialDesignSubGroupShell } from "./hooks/useTableCreatorClinicalTrialDesignSubGroupShell";

export interface PositionInfoProps {
  groupTableKey: ClinicalTrialsSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  groupShellIndex: number;
  tableKey: string | null;
  tableIndex: number;
  subGroupShellKey: string | null;
  subGroupShellIndex: number;
  subGroupShellCount: number;
}

interface Props extends PositionInfoProps {
  subGroupShellCount: number;
  shellList: TableCreatorShellList;
  isFocusedGroupShell: boolean;
  isClearGroupShell: boolean;
  isDeleteGroupShell: boolean;
  isAlertGroupShell: boolean;
  isDraggingSubGroupShell: boolean;
  handleDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => void;
  handleDragEnd: (groupTableKey: string) => void;
  handleDragOn: () => void;
}

const isFocusSubGroupShellGroupShellKeyList: string[] = [
  CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS.arm,
];

const ClinicalTrialDesignSubGroupShell = ({
  groupShellIndex,
  groupShellKey,
  groupTableIndex,
  groupTableKey,
  tableIndex,
  tableKey,
  subGroupShellCount,
  subGroupShellIndex,
  subGroupShellKey,
  shellList,
  isAlertGroupShell,
  isClearGroupShell,
  isDeleteGroupShell,
  isFocusedGroupShell,
  isDraggingSubGroupShell,
  handleDragEnd,
  handleDragOn,
  handleDragStart,
}: Props) => {
  const positionPropsInfo = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    subGroupShellKey,
    subGroupShellIndex,
    tableKey,
    tableIndex,
  };

  const { shellCount } = useGetNumberOfShell();
  const articleSection = useArticleSection();
  const selectButtonRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const isPossibleSelected =
    !!groupShellKey &&
    isFocusSubGroupShellGroupShellKeyList.includes(subGroupShellKey || "");

  const {
    focusStatus,
    isFocused,
    isEnableEdit,
    isClearSubGroupShell,
    isDeleteSubGroupShell,
    isLastSubGroupShell,
    isFocusedAddButton,
    handleClickToDeleteSubGroupShell,
    handleFocusSubGroupShell,
    clickAddSubGroupShellButton,
    handleDefaultFocus,
    changeFocusStatus,
    changePositionInfo,
    changePositionInfoToDefault,
  } = useTableCreatorClinicalTrialDesignSubGroupShell({
    positionPropsInfo,
    subGroupShellCount,
  });

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;
        const clickSelectButton = selectButtonRef.current?.contains(target);
        const clickGroupShellTitle = titleRef.current?.contains(target);

        if (clickSelectButton || clickGroupShellTitle) return;
        if (target.dataset.button) return;

        handleDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleDefaultFocus, isFocused]);

  const changeFocusStatusForDragging = () => {
    changePositionInfo();
    changeFocusStatus({
      type: "dragging",
      container: "subGroupShell",
    });
  };

  const changeFocusStatusForDragEnd = () => {
    changePositionInfoToDefault();
    changeFocusStatus({
      type: "default",
      container: "noFocus",
    });
  };

  const isDisplayClearDeleteButton =
    (isClearSubGroupShell || isDeleteSubGroupShell) && isEnableEdit;

  return (
    <>
      {isAddDeleteSubGroupShell(articleSection, subGroupShellKey) && (
        <SubGroupShellDividerPlusBar
          onClick={(event) => {
            clickAddSubGroupShellButton(event, {
              tableIndexParameter: tableIndex,
              groupShellIndexParameter: groupShellIndex,
              groupShellKeyParameter: groupShellKey,
              subGroupShellIndexParameter: subGroupShellIndex,
              subGroupShellKeyParameter: subGroupShellKey,
            });
          }}
          isLastPosition={false}
          isVisibility={isFocusedAddButton}
          subGroupShellKey={subGroupShellKey}
        />
      )}
      <S.GroupShellOutsideWrapper>
        {isDisplayClearDeleteButton && (
          <S.DeleteTableButtonWrapper $isDragging={false}>
            <S.DeleteTableButton
              data-button="delete-button"
              onClick={handleClickToDeleteSubGroupShell}
            >
              <img src={IconXMark} alt="button for clear Row" />
              {focusStatus.type === "clear" ? `Clear` : null}
              {focusStatus.type === "delete" ? `Delete` : null}
            </S.DeleteTableButton>
          </S.DeleteTableButtonWrapper>
        )}
        <S.GroupShellWrapper
          isFocused={isFocused}
          isDragging={isFocused && focusStatus.type === "dragging"}
        >
          <S.TitleWrapper ref={titleRef}>
            {getHaveSubGroupShellTitle(articleSection, subGroupShellKey) && (
              <SubGroupShellTitle
                tableIndex={tableIndex}
                tableKey={tableKey}
                subGroupShellKey={subGroupShellKey}
                subGroupShellIndex={subGroupShellIndex}
                groupShellIndex={groupShellIndex}
                onClickHandler={handleFocusSubGroupShell}
                handleDragStart={handleDragStart}
                handleDragEnd={handleDragEnd}
                handleDragOn={handleDragOn}
                isDragging={isFocused && focusStatus.type === "dragging"}
                isDraggingSubGroupShell={isDraggingSubGroupShell}
                changeFocusStatusForDragging={changeFocusStatusForDragging}
                changeFocusStatusForDragEnd={changeFocusStatusForDragEnd}
              />
            )}
          </S.TitleWrapper>
          <S.SelectWrapper isPossibleSelected={isPossibleSelected}>
            <S.GroupShellSelectButtonDividerWrapper
              ref={selectButtonRef}
              onClick={handleFocusSubGroupShell}
              isPossibleSelected={isPossibleSelected}
            >
              <S.GroupShellSelectButtonDivider className="select-button divider" />
              <S.GroupShellSelectButton className="select-button button">
                SELECT
              </S.GroupShellSelectButton>
            </S.GroupShellSelectButtonDividerWrapper>
            <S.SubGroupShellListWrapper
              $isScrollRow={isFocusSubGroupShellGroupShellKeyList.includes(
                subGroupShellKey || "",
              )}
            >
              {shellList.map(({ shellKey, subShellList }, shellListIndex) => (
                <React.Fragment key={`shellList-${String(shellListIndex)}`}>
                  {newArrayToCountValue(
                    shellCount(
                      shellKey,
                      tableIndex,
                      groupShellIndex,
                      subGroupShellIndex,
                    ),
                  ).map((_, shellIndex) => {
                    if (
                      shellKey ===
                      CLINICAL_TRIAL_DESIGN_SHELL_KEYS.trialIntervention
                    ) {
                      return (
                        <S.ShellWrapperForSeparateShell>
                          <ClinicalTrialDesignShell
                            key={`shell-${String(groupTableIndex)}-${String(
                              tableIndex,
                            )}-${String(groupShellIndex)}-${String(
                              shellIndex,
                            )}`}
                            groupTableKey={groupTableKey}
                            groupTableIndex={groupTableIndex}
                            groupShellKey={groupShellKey}
                            groupShellIndex={groupShellIndex}
                            subGroupShellIndex={subGroupShellIndex}
                            subGroupShellKey={subGroupShellKey}
                            shellKey={shellKey}
                            subShellList={subShellList}
                            shellIndex={shellIndex}
                            tableKey={tableKey}
                            tableIndex={tableIndex}
                            shellCount={shellCount(
                              shellKey,
                              tableIndex,
                              groupShellIndex,
                              subGroupShellIndex,
                            )}
                            isShellOverOne={
                              shellCount(
                                shellKey,
                                tableIndex,
                                groupShellIndex,
                                subGroupShellIndex,
                              ) > 1
                            }
                            isFocusedGroupShell={isFocused}
                            isClearGroupShell={isClearGroupShell}
                            isDeleteGroupShell={isDeleteGroupShell}
                            isAlertGroupShell={isAlertGroupShell}
                            isClearSubGroupShell={isClearSubGroupShell}
                            isDeleteSubGroupShell={isDeleteSubGroupShell}
                          />
                        </S.ShellWrapperForSeparateShell>
                      );
                    }
                    return (
                      <ClinicalTrialDesignShell
                        key={`shellList-${String(shellIndex)}`}
                        subShellList={subShellList}
                        groupTableKey={groupTableKey}
                        groupTableIndex={groupTableIndex}
                        groupShellKey={groupShellKey}
                        groupShellIndex={groupShellIndex}
                        subGroupShellIndex={subGroupShellIndex}
                        subGroupShellKey={subGroupShellKey}
                        shellKey={shellKey}
                        shellIndex={shellIndex}
                        shellCount={shellCount(
                          shellKey,
                          tableIndex,
                          groupShellIndex,
                          subGroupShellIndex,
                        )}
                        tableKey={tableKey}
                        tableIndex={tableIndex}
                        isShellOverOne={
                          shellCount(
                            shellKey,
                            tableIndex,
                            groupShellIndex,
                            subGroupShellIndex,
                          ) > 1
                        }
                        isFocusedGroupShell={isFocusedGroupShell}
                        isClearGroupShell={isClearGroupShell}
                        isDeleteGroupShell={isDeleteGroupShell}
                        isAlertGroupShell={isAlertGroupShell}
                        isClearSubGroupShell={isClearSubGroupShell}
                        isDeleteSubGroupShell={isDeleteSubGroupShell}
                      />
                    );
                  })}
                </React.Fragment>
              ))}
            </S.SubGroupShellListWrapper>
          </S.SelectWrapper>
        </S.GroupShellWrapper>
      </S.GroupShellOutsideWrapper>
      {isAddDeleteSubGroupShell(articleSection, subGroupShellKey) &&
        isLastSubGroupShell && (
          <SubGroupShellDividerPlusBar
            onClick={(event) => {
              clickAddSubGroupShellButton(event, {
                tableIndexParameter: tableIndex,
                groupShellIndexParameter: groupShellIndex,
                groupShellKeyParameter: groupShellKey,
                subGroupShellIndexParameter: subGroupShellIndex + 1,
                subGroupShellKeyParameter: subGroupShellKey,
              });
            }}
            isLastPosition
            isVisibility={isFocusedAddButton}
            subGroupShellKey={subGroupShellKey}
          />
        )}
    </>
  );
};

export default ClinicalTrialDesignSubGroupShell;
