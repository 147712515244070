"use client";

import styled from "styled-components";

export const FlagWrapper = styled.div<{ $isTopMargin?: boolean }>`
  width: 2.8rem;
  height: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
  background: var(--Color-Common-Bg-Primary, #fff);
  border: var(--Border-Width-1, 1px) solid
    var(--Color-Common-Border-Tertiary, #e5e7eb);
  padding: 0.1rem;
  margin: ${({ $isTopMargin }) => ($isTopMargin ? "0.15rem 0rem" : "0rem")};
`;
