import {
  PressListOrderingKey,
  PressListOrderingValue,
} from "@PressListContainer/pressList.types";
import { usePressListFilterValue } from "@stores/pressList";

export const useGetPressListFilterData = () => {
  const pressListFilter = usePressListFilterValue();

  const {
    search,
    page,
    pageLimit,
    confirmClassificationAi,
    confirmClassificationManual,
    status,
    order,
    publishable,
    isTranslated,
  } = pressListFilter;

  const updateOrder = Object.entries(order).map(
    ([key, value]): {
      sortBy: PressListOrderingKey;
      sortOrder: PressListOrderingValue;
    } => ({
      sortBy: key as PressListOrderingKey,
      sortOrder: value as unknown as PressListOrderingValue,
    }),
  );

  const pressFilterListForUpdate = {
    page,
    size: pageLimit,
    filter: {
      classificationAi: confirmClassificationAi,
      classificationManual: confirmClassificationManual,
      draftArticleStatusHighest: status,
      ...(publishable === "true" && { publishable }),
      ...(isTranslated === "true" && { hasTranslateParentId: isTranslated }),
      ...(search && { search }),
    },
    order: [...updateOrder],
  };

  return pressFilterListForUpdate;
};
