import React, { useId } from "react";

import * as S from "./PublishableCheckboxIcon.style";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  isChecked: boolean;
}

const PublishableCheckboxIcon = ({ isChecked, ...rest }: Props) => {
  const checkboxId = useId();
  return (
    <S.CheckboxLabel htmlFor={checkboxId}>
      <S.CheckboxInput
        id={checkboxId}
        type="checkbox"
        checked={isChecked}
        {...rest}
      />
    </S.CheckboxLabel>
  );
};

export default PublishableCheckboxIcon;
