"use client";

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";

import * as S from "./DealTermsTableLayout.style";
import {
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
} from "../../../shared/components/core";
import TableGridColumnLayout from "../TableGridColumnLayout/TableGridColumnLayout";

interface Props {
  items: (
    | {
        title: string;
        value: React.ReactNode;
      }[]
    | {
        title: string;
        value: React.ReactNode;
      }
  )[][];
  isEditable?: boolean;
}

const VALUE_SHELL_PADDING = "0.2rem";
const DealTermsTableLayout = ({ items, isEditable = false }: Props) => {
  const columnCount = items?.length || 0;
  const rowCount = Math.max(
    ...(Array(columnCount)
      .fill("")
      ?.map((_, index) => items[index]?.length) || []),
  );

  const windowWidth = window.innerWidth;
  const [currentWindowWidth, setCurrentWindowWidth] = useState(windowWidth);

  useEffect(() => {
    const changeCurrentWindowWidth = () => {
      const currenWidth = window.innerWidth;
      setCurrentWindowWidth(currenWidth);
    };
    changeCurrentWindowWidth();
    window.addEventListener("resize", changeCurrentWindowWidth);
    return () => window.removeEventListener("resize", changeCurrentWindowWidth);
  }, []);

  const isMobileScreen = currentWindowWidth < 767;

  if (!columnCount || !rowCount) return null;
  return (
    <S.ContentsWrapper>
      <TableGridColumnLayout columnCount={columnCount}>
        {Array(rowCount)
          .fill("ROW")
          .map((_, rowIndex) => (
            <React.Fragment key={`item-row-${String(rowIndex)}`}>
              {Array(columnCount)
                .fill("COLUMN")
                ?.map((__, columnIndex) =>
                  items[columnIndex]?.[rowIndex] ? (
                    <TableContainer
                      className={`${"default-table-container"} ${
                        rowIndex === 0 && "top-radius"
                      } ${
                        rowIndex === items[columnIndex].length - 1 &&
                        "bottom-radius"
                      }`}
                      key={`item-shell-${String(columnIndex)}-${String(
                        rowIndex,
                      )}`}
                    >
                      {/* desktop title */}
                      {!isMobileScreen &&
                        Array.isArray(items[columnIndex][rowIndex]) && (
                          <>
                            {(items[columnIndex][rowIndex] as any)?.at(0)
                              ?.title && (
                              <TableRowContainer>
                                {(items[columnIndex][rowIndex] as any)?.map(
                                  (item: any) => (
                                    <TableShellTitle>
                                      {item.title}
                                    </TableShellTitle>
                                  ),
                                )}
                              </TableRowContainer>
                            )}
                          </>
                        )}
                      {/* mobile title */}
                      {isMobileScreen &&
                        Array.isArray(items[columnIndex][rowIndex]) && (
                          <>
                            {(items[columnIndex][rowIndex] as any)?.at(0)
                              ?.title && (
                              <TableRowContainer>
                                <TableShellTitle>
                                  {(items[columnIndex][rowIndex] as any)
                                    .map((item: any) => item.title)
                                    .join(" & ")}
                                </TableShellTitle>
                              </TableRowContainer>
                            )}
                          </>
                        )}
                      {/* all title */}
                      {!Array.isArray(items[columnIndex][rowIndex]) && (
                        <TableRowContainer>
                          <TableShellTitle>
                            {(items[columnIndex][rowIndex] as any)?.title || ""}
                          </TableShellTitle>
                        </TableRowContainer>
                      )}
                      {/* desktop value */}
                      {!isMobileScreen &&
                        Array.isArray(items[columnIndex][rowIndex]) && (
                          <TableRowContainer className="deal-terms-table-value">
                            {(items[columnIndex][rowIndex] as any)?.map(
                              (
                                subItem: {
                                  title: React.ReactNode;
                                  value: React.ReactNode;
                                }[],
                              ) => (
                                <TableShellValue
                                  padding={
                                    isEditable ? VALUE_SHELL_PADDING : "0.8rem"
                                  }
                                >
                                  {(subItem as any)?.value || ""}
                                </TableShellValue>
                              ),
                            )}
                          </TableRowContainer>
                        )}
                      {/* mobile value */}
                      {isMobileScreen &&
                        Array.isArray(items[columnIndex][rowIndex]) && (
                          <TableRowContainer
                            style={{ backgroundColor: "#f8f9fa" }}
                          >
                            <TableShellValue
                              padding={
                                isEditable ? VALUE_SHELL_PADDING : "0.8rem"
                              }
                            >
                              {(items[columnIndex][rowIndex] as any)?.map(
                                (item: any, index: number) => (
                                  <div className={`value-index-${index}`}>
                                    {index === 0 ? item.value : item.value}
                                  </div>
                                ),
                              )}
                            </TableShellValue>
                          </TableRowContainer>
                        )}
                      {/* all value */}
                      {!Array.isArray(items[columnIndex][rowIndex]) && (
                        <TableRowContainer className="deal-terms-table-value">
                          <TableShellValue
                            padding={
                              isEditable ? VALUE_SHELL_PADDING : "0.8rem"
                            }
                          >
                            {(items[columnIndex][rowIndex] as any)?.value}
                          </TableShellValue>
                        </TableRowContainer>
                      )}
                    </TableContainer>
                  ) : (
                    <div
                      key={`item-shell-${String(columnIndex)}-${String(
                        rowIndex,
                      )}`}
                    />
                  ),
                )}
            </React.Fragment>
          ))}
      </TableGridColumnLayout>
    </S.ContentsWrapper>
  );
};

export default DealTermsTableLayout;
