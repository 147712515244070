import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_INVESTORS_GROUP_TABLE_KEY as INVESTORS,
  ENTRY_DATA_INVESTORS_SHELL_KEYS as INVESTORS_SHELL_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import { EntryDataInvestorValue } from "@/types/ventureCapital.types";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

const sectionKey = SECTION_KEYS.ventureCapital;
const groupTableKey = INVESTORS;
const shellKey = INVESTORS_SHELL_KEYS.investorName;

export const useInvestorTableValueAdd = () => {
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const addEmptyValue = (
    valueIndex: number,
    valueList: EntryDataInvestorValue[],
  ) => {
    const emptyArray = Array.from({ length: valueList.length + 1 }, () => ({
      text: "",
      is_lead_investor: false,
      is_modified: true,
    }));

    return emptyArray.map((emptyValue, emptyValueIndex) => {
      if (emptyValueIndex === valueIndex) {
        return emptyValue;
      }
      if (emptyValueIndex > valueIndex) {
        return valueList[emptyValueIndex - 1];
      }
      return valueList[emptyValueIndex];
    });
  };

  const addInvestorTableValue = (valueIndex: number) => {
    const updatedEntryData = {
      ...entryData,
      [sectionKey]: {
        ...entryData[sectionKey],
        [groupTableKey]: {
          ...entryData?.[sectionKey]?.[groupTableKey],
          [shellKey]: {
            ...entryData?.[sectionKey]?.[groupTableKey]?.[shellKey],
            value: addEmptyValue(
              valueIndex,
              entryData?.[sectionKey]?.[groupTableKey]?.[shellKey]?.value || [],
            ) as EntryDataInvestorValue[],
          },
        },
      },
    };

    setEntryData(updatedEntryData as ArticleEntryData);
  };

  return { addInvestorTableValue };
};
