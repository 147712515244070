import React from "react";

import { Checkbox } from "@frontend/common";

import CheckboxIcon from "@images/common/icon-checkbox.svg";

import { useMainLabelCheckboxDropDown } from "./hooks/useMainLabelCheckboxDropDown";
import * as S from "./MainLabelCheckboxDropDown.style";
import { useMainLabelOptionList } from "../../../../hooks/useMainLabelOptionList";
import { FormattedMainLabelItemType } from "../../../../types/articleMainLabel.types";

interface Props {
  labelInfo: FormattedMainLabelItemType;
  targetValue: string[];
}

const MainLabelCheckboxDropDown = ({ targetValue, labelInfo }: Props) => {
  const { changeTargetMainLabel } = useMainLabelCheckboxDropDown();
  const optionList = useMainLabelOptionList(labelInfo.labelName);

  return (
    <S.MainLabelCheckboxDropDownWrapper>
      {optionList.map((item) => (
        <S.CheckboxItemWrapper key={item}>
          <S.CheckboxButtonWrapper>
            <Checkbox
              value={item}
              defaultChecked={targetValue.includes(item)}
              onChangeHandler={(event) =>
                changeTargetMainLabel(event, labelInfo)
              }
              checkImageUrl={CheckboxIcon}
              isChangedTextColor={false}
            />
          </S.CheckboxButtonWrapper>
          <S.LabelWrapper>
            <S.LabelText>{item}</S.LabelText>
          </S.LabelWrapper>
        </S.CheckboxItemWrapper>
      ))}
    </S.MainLabelCheckboxDropDownWrapper>
  );
};

export default MainLabelCheckboxDropDown;
