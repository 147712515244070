import React, { useCallback, useEffect, useState } from "react";

export interface UseResizeModalReturn {
  modalSizeWidth: number;
  onMouseDownResize: (e: React.MouseEvent, direct?: "left" | "right") => void;
}

const WIDTH = {
  min: 300,
};

export const useResizeModal = (targetRef: React.RefObject<HTMLDivElement>) => {
  const [isResizing, setIsResizing] = useState(false);
  const [mouseDownWidth, setMouseDownWidth] = useState(0);
  const [modalSizeWidth, setModalSizeWidth] = useState(1000);
  const [isLeftResize, setIsLeftResize] = useState(false);

  const onMouseDownResize = (
    e: React.MouseEvent,
    direct?: "left" | "right",
  ) => {
    e.preventDefault();
    const isLeft = direct === "left";
    const distanceX = isLeft
      ? e.clientX + modalSizeWidth
      : e.clientX - modalSizeWidth;

    setIsResizing(true);
    setIsLeftResize(isLeft);
    setMouseDownWidth(distanceX);
  };

  const onMouseMoveResize = useCallback(
    (e: MouseEvent) => {
      const ref = targetRef;
      const distanceX = isLeftResize
        ? mouseDownWidth - e.clientX
        : e.clientX - mouseDownWidth;

      if (distanceX < WIDTH.min || !isResizing) return;
      setModalSizeWidth(distanceX);

      if (isLeftResize && ref.current) {
        ref.current.style.left = `${e.clientX}px`;
      }

      document.body.style.cursor = "col-resize";
    },
    [isLeftResize, isResizing, mouseDownWidth, targetRef],
  );

  const onMouseUpResize = () => {
    setIsResizing(false);
    setIsLeftResize(false);
  };

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMoveResize);
    document.addEventListener("mouseup", onMouseUpResize);

    return () => {
      document.removeEventListener("mousemove", onMouseMoveResize);
      document.removeEventListener("mouseup", onMouseUpResize);
      document.body.style.cursor = "default";
    };
  }, [onMouseMoveResize]);

  return {
    modalSizeWidth,
    onMouseDownResize,
  };
};
