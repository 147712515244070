import { type EditableText } from "../types/editableText.types";

export const convertContentToHtml = (editableText: EditableText) => {
  let html = "";
  let index = 0;
  const { text, labels } = editableText;

  labels
    ?.filter(({ position }) => position[0] !== position[1])
    .sort(({ position: [startA] }, { position: [startB] }) => startA - startB)
    .forEach((label) => {
      const [start, end] = label.position;
      const frontText = text.slice(index, start);
      const markNode = document.createElement("mark");
      markNode.dataset.entity = label.entity;
      markNode.innerHTML = editableText.text.slice(start, end);

      html += frontText;
      html += markNode.outerHTML;
      index = end;
    });

  if (index < text?.length) {
    html += text.slice(index);
  }

  return html;
};
