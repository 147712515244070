import React, { CSSProperties } from "react";

import * as S from "./Button.style";

import type { ValueOf } from "../../../types/util.types";

export const BUTTON_STYLE = {
  line: "line",
  lineGray: "lineGray",
  lineLightGray: "lineLightGray",
  lineSky: "lineSky",
  lineStatusProgress: "lineStatusProgress",
  solid: "solid",
  solidOrange: "solidOrange",
  solidNodeSky: "solidNodeSky",
  solidSky: "solidSky",
  solidBlueGreen: "solidBlueGreen",
  solidStatusUnsuitable: "solidStatusUnsuitable",
  solidBlue: "solidBlue",
  solidGray: "solidGray",
  orange: "orange",
  disabled: "disabled",
  text: "text",
  textGray: "textGray",
  textOrange: "textOrange",
  textDarkGray: "textDarkGray",
  textSkyBlue: "textSkyBlue",
  textBioBlue06: "textBioBlue06",
} as const;

export const BUTTON_SIZE = {
  xSmall: "xSmall",
  small: "small",
  medium: "medium",
  default: "default",
  full: "full",
} as const;

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  buttonStyle?: ValueOf<typeof BUTTON_STYLE>;
  buttonSize?: ValueOf<typeof BUTTON_SIZE>;
  gap?: CSSProperties["gap"];
  opacity?: CSSProperties["opacity"];
  borderRadius?: CSSProperties["borderRadius"];
}

const Button = ({
  children,
  buttonStyle = BUTTON_STYLE.solid,
  buttonSize = BUTTON_SIZE.default,
  gap = "0",
  opacity = "1",
  borderRadius = "0.8rem",
  ...rest
}: Props) => {
  return (
    <S.ButtonWrapper
      buttonStyle={buttonStyle}
      buttonSize={buttonSize}
      gap={gap}
      opacity={opacity}
      borderRadius={borderRadius}
      {...rest}
    >
      {children}
    </S.ButtonWrapper>
  );
};

export default Button;
