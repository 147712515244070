import styled from "styled-components";

export const ModalContentsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.4rem 0;
  width: 51.2rem;
  height: 20.3rem;
  background-color: ${({ theme }) => theme.color.neural.white};
  padding: 2rem;
  border-radius: 0.4rem;
`;

export const ModalTitle = styled.h1`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  height: 2.6rem;
  color: var(--Color-Common-Text-Warning, #e53e06);
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
`;

export const ModalText = styled.p`
  position: relative;
  flex: 1;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;

  strong {
    color: var(--Color-Common-Text-Primary, #2a2f37);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
  }
`;

export const PressClassificationLink = styled.button`
  text-decoration: underline !important;
  cursor: pointer;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 21.4rem;
  height: 4rem;
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  border-radius: 0.4rem;
  padding: 1.2rem 0;
  margin-right: 2rem;
  cursor: pointer;
`;

export const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 21.4rem;
  height: 4rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 100%;
  color: ${({ theme }) => theme.color.neural.white};
  padding: 1.2rem 0;
  border-radius: 0.4rem;
  background: var(--Color-Common-Bg-Interactive-Destructive, #e53e06);
  cursor: pointer;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
