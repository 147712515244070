"use client";

import styled from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
`;

export const GoHomeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc((100% - 16px) / 2);
  padding: 0;
  gap: 0.4rem;
  height: 3.6rem;
  border: 1px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);
  border-radius: 0.4rem;
  cursor: pointer;

  & span {
    color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 100%;
  }
`;

export const LinkButton = styled.a`
  display: flex;
  gap: 0.4rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: calc((100% - 16px) / 2);
  height: 3.6rem;
  border-radius: 0.4rem;
  background: var(--Color-Common-Bg-Interactive-Primary, #1baefe);
  cursor: pointer;

  & span {
    color: var(--Color-Common-Text-Interactive-Inverse, #fff);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 100%;
  }
`;
