import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import { useGetNumberOfGroupShell } from "./useGetNumberOfGroupShell";

const useCheckIsDisplayGroupShellDragDivider = (
  tableIndex: number,
  isDraggingGroupShell: boolean,
  isDraggingGroupShellKey?: string,
) => {
  const { groupShellCount } = useGetNumberOfGroupShell();

  const isFirstGroupShell = (groupShellIndex: number | null) =>
    groupShellIndex === 0;

  const isLastGroupShell = (
    groupShellIndex: number | null,
    groupShellKey: string | null,
  ) =>
    groupShellIndex ===
    newArrayToCountValue(groupShellCount(groupShellKey, tableIndex)).length - 1;

  const handleCheckDragDividerDisplay = ({
    moveDragIndex,
    groupShellIndex,
    groupShellKey,
    lastIndex,
    startDragIndex,
  }: {
    moveDragIndex: number | null;
    groupShellIndex: number;
    groupShellKey: string | null;
    lastIndex?: number;
    startDragIndex: number | null;
  }) => {
    if (moveDragIndex === null) return false;
    if (!isDraggingGroupShell) return false;
    if (groupShellKey !== isDraggingGroupShellKey) return false;

    if (isFirstGroupShell(startDragIndex) && !lastIndex) {
      return moveDragIndex + 1 === groupShellIndex;
    }

    if (isLastGroupShell(moveDragIndex, groupShellKey)) {
      return moveDragIndex + 1 === lastIndex;
    }

    return moveDragIndex === groupShellIndex;
  };

  return { isFirstGroupShell, isLastGroupShell, handleCheckDragDividerDisplay };
};

export default useCheckIsDisplayGroupShellDragDivider;
