import React from "react";

import { ModalityIcon } from "@frontend/common";
import { matchModalityKeyTypeForEditArticle } from "@frontend/common/src/shared/components/ModalityIcon/utils/matchModalityKeyType";

import { useGetModalityCategoryQueries } from "@api/modalityCategory/modalityCategory";
import { EditArticleText } from "@EditArticleContainer/shared/types/editArticle.types";

type Props = {
  modalityValue: EditArticleText[];
};

export const useDrugProfileModalityIconList = ({ modalityValue }: Props) => {
  const modalityValues =
    modalityValue?.flatMap?.(
      (item) => item.text?.replaceAll("- ", "").split(`\n`) ?? "",
    ) || [];

  const { data } = useGetModalityCategoryQueries(modalityValues);

  const modalityIconList = data?.map((item) => {
    if (item.categoryName === "") return null;

    return (
      <ModalityIcon
        modalityType={matchModalityKeyTypeForEditArticle(item.categoryName)}
        backgroundType="default"
        width={30}
        height={30}
      />
    );
  });

  return {
    modalityIconList,
  };
};
