import styled from "styled-components";

export const ModalContentsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.2rem 0;
  width: 48rem;
  height: 16.7rem;
  background-color: ${({ theme }) => theme.color.neural.white};
  padding: 2rem;
  border-radius: 0.4rem;
`;

export const ModalTitle = styled.h1`
  display: flex;
  align-items: center;
  height: 2.6rem;
  color: var(--Color-Common-Text-Warning, #e53e06);
  font-size: 2rem;
  font-weight: 600;
`;

export const ModalText = styled.p`
  position: relative;
  flex: 1;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  padding: 0.4rem 0;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 0.8rem;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21rem;
  height: 4rem;
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 100%;
  padding: 1.2rem 0;
  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Interactive-Destructive, #e53e06);
  cursor: pointer;
`;
