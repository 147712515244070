import { DealsSection } from "@/types/deals.types";
import { TableCreatorTableList } from "@FillInTableContainer/shared/types/tableCreator.types";

interface DealsKeyCommentLayout {
  groupTableKey: DealsSection;
  tableList: TableCreatorTableList;
}
export const DEALS_KEY_COMMENT_GROUP_TABLE_LAYOUT: DealsKeyCommentLayout = {
  groupTableKey: "Key Comment",
  tableList: [
    {
      tableKey: null,
      groupShellList: [
        {
          groupShellKey: null,
          shellList: [
            {
              shellKey: "Quote",
            },
            {
              shellKey: "Spokesperson",
            },
          ],
        },
      ],
    },
  ],
};
