import styled, { css } from "styled-components";

export const SelectContentWrapper = styled.div<{
  $isAddedGroupShell?: boolean;
}>`
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: white;
  padding: ${({ $isAddedGroupShell }) =>
    $isAddedGroupShell ? "0.8rem 0" : "1.6rem 1.6rem 1.6rem 0"};
`;

export const SelectButton = styled.button`
  position: absolute;
  top: calc(50% - 3.4rem);

  padding: var(--Spacing-4, 0.4rem) var(--Spacing-2, 0.2rem);
  border-radius: var(--Border-Radius-2, 0.2rem);
  outline: 2px solid var(--Color-Border-Interactive-Tertiary-Hovered, #2ae5fe);
  background: var(--Color-Node-Cyan-600, #01abc1);

  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.16);

  display: none;
  color: #fff;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 90%;
  writing-mode: vertical-lr;
  text-orientation: upright;
  letter-spacing: -0.3rem;

  z-index: 10;
  cursor: pointer;
`;

export const SelectButtonDivider = styled.div`
  display: none;
  position: relative;
  width: 0.8rem;
  left: 0.4rem;
  height: 100%;
  border-radius: var(--Border-Radius-circle, 99999.9rem);
  background: var(--Color-Bg-Interactive-Tertiary-Hovered, #8ff2ff);
  margin-right: 0.2rem;
  z-index: 10;
`;

export const SelectButtonDividerWrapper = styled.div<{
  isPossibleSelected?: boolean;
}>`
  position: relative;
  z-index: 20;
  cursor: pointer;
  width: 1.6rem;

  &:hover {
    background-color: white;
    .select-button {
      display: block;
    }
  }

  &:active {
    .divider {
      background: var(--Color-Bg-Interactive-Tertiary-Pressed, #5cebfe);
    }
    .button {
      background: var(--Color-Node-Cyan-700, #017e8e);
      outline: solid var(--Color-Border-Interactive-Tertiary-Pressed, #01d9f6);
    }
  }

  ${({ isPossibleSelected }) =>
    !isPossibleSelected &&
    css`
      visibility: hidden;
    `}
`;
