import React from "react";

import * as S from "./Layout.style";

interface Props {
  children: React.ReactNode;
}
const LayoutFull = ({ children }: Props) => {
  return <S.LayoutFullWrapper>{children}</S.LayoutFullWrapper>;
};

export default LayoutFull;
