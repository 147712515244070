import * as S from "./WaitForAiButton.style";

interface Props {
  taskTime?: string;
}

const WaitForAiButton = ({ taskTime }: Props) => {
  return (
    <S.Button type="button" disabled>
      {taskTime && (
        <>
          <div className="message">AI Task Time {taskTime}</div>
          <div className="triangle" />
        </>
      )}
      <span>AI Entry in Progress...</span>
    </S.Button>
  );
};

export default WaitForAiButton;
