import styled from "styled-components";

export const FilterSelectWrapper = styled.div`
  position: relative;
`;

export const SelectButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    margin-left: 0.6rem;
  }
`;

export const FilterListTitle = styled.p`
  display: flex;
  align-items: center;
  height: 0.8rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 130%;
  color: ${({ theme }) => theme.color.neural.gray05};
`;

export const DropDown = styled.ul`
  display: flex;
  position: absolute;
  flex-direction: column;
  z-index: 20;
  gap: 1.2rem;
  width: 10.4rem;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.color.neural.gray05};
  border-radius: 0.4rem;
  padding: 1.2rem 0.8rem;
  margin-top: 0.4rem;
`;
