import { clearSentryUser } from "@libs/sentry/sentryUser";
import { removeAuthorityType } from "@queries/hooks/useAuthorityType";
import { removeEditorRoles } from "@queries/hooks/useEditorRoles";
import { removeCookie } from "@utils/cookie";
import { TOKEN_KEYS } from "@utils/tokenHelper";

export const clearAuthorization = () => {
  removeCookie(TOKEN_KEYS.accessToken);
  removeCookie(TOKEN_KEYS.refreshToken);
  removeCookie("accountName");
  removeCookie("accountEmail");
  removeAuthorityType();
  removeEditorRoles();
  clearSentryUser();
};
