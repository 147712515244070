import { DRAFT_STATUS_LIST } from "@PressListContainer/draftStatusInfo.constants";
import { PressListData } from "@PressListContainer/pressList.types";

export const handleDraftError = (data: PressListData): [boolean, string] => {
  const { pressId, pressTitle, companyName, draftArticleStatus } = data;
  if (!pressId) {
    return [true, "Press Id is empty"];
  }
  if (!pressTitle) {
    return [true, "Press Title is empty"];
  }
  if (!companyName) {
    return [true, "Company Name is empty"];
  }
  if (!draftArticleStatus) {
    return [true, "Article Status is empty"];
  }
  if (!DRAFT_STATUS_LIST.includes(draftArticleStatus)) {
    return [true, "Article Status is not valid"];
  }
  return [false, ""];
};
