import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_TABLE_KEY as ADDITIONAL_INFORMATION,
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_KEYS as ADDITIONAL_INFORMATION_SHELL_KEYS,
} from "@frontend/common";

import { VentureCapitalGroupTable } from "@/types/ventureCapital.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  handleClickToClearAdditionalInformationGroupShell: () => void;
  handleKeyDownToClearAdditionalInformationGroupShell: (
    event: KeyboardEvent,
  ) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: VentureCapitalGroupTable;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

const articleSection = SECTION_KEYS.ventureCapital;
export const useHandleVentureCapitalAdditionalInformationGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();

  const handleClearGroupShell = () => {
    const clearAdditionalInformationGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        [articleSection]: {
          ...entryData[articleSection],
          [ADDITIONAL_INFORMATION]: {
            ...entryData[articleSection]?.[ADDITIONAL_INFORMATION],
            [ADDITIONAL_INFORMATION_SHELL_KEYS.additionalInformation]: {
              ...entryData[articleSection]?.[ADDITIONAL_INFORMATION]?.[
                ADDITIONAL_INFORMATION_SHELL_KEYS.additionalInformation
              ],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    if (info.groupTableKey === ADDITIONAL_INFORMATION) {
      setEntryData(clearAdditionalInformationGroupShell());
      changeCurrentHistory({
        entryData: clearAdditionalInformationGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  };

  const handleClickToClearAdditionalInformationGroupShell = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShell();
    }
  };

  const handleKeyDownToClearAdditionalInformationGroupShell = (
    event: KeyboardEvent,
  ) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearGroupShell();
    }
  };

  return {
    handleClickToClearAdditionalInformationGroupShell,
    handleKeyDownToClearAdditionalInformationGroupShell,
  };
};
