import React from "react";

import {
  getMainLabelData,
  usePostMainLabelMutation,
} from "@api/mainLabel/mainLabel";

import { FormattedMainLabelItemType } from "../../../../../types/articleMainLabel.types";

export const useMainLabelCheckboxDropDown = () => {
  const mainLabelList = getMainLabelData();
  const { mutateAsync: updateMainLabel } = usePostMainLabelMutation();

  const checkSelectedLabel = (targetValue: string) =>
    mainLabelList.filter((label) => label.labelValue === targetValue).length >
    0;
  const getTargetMainLabelId = (targetValue: string) =>
    checkSelectedLabel(targetValue)
      ? String(
          mainLabelList.filter((label) => label.labelValue === targetValue)?.[0]
            .id,
        )
      : "";

  const changeTargetMainLabel = (
    event: React.ChangeEvent<HTMLInputElement>,
    labelInfo: FormattedMainLabelItemType,
  ) => {
    const changedMainLabel = event.target.value;

    const updatedMainLabelList = checkSelectedLabel(changedMainLabel)
      ? mainLabelList.map((label) => {
          return {
            id: String(label.id),
            labelName: label.labelName,
            type: label.type,
            articleSection: label.articleSection,
            entity: label.entity,
            value:
              String(label.id) === getTargetMainLabelId(changedMainLabel)
                ? ""
                : label.labelValue,
          };
        })
      : [
          ...mainLabelList.map((label) => ({
            id: String(label.id),
            labelName: label.labelName,
            type: label.type,
            articleSection: label.articleSection,
            entity: label.entity,
            value: label.labelValue,
          })),
          {
            id: "",
            labelName: labelInfo.labelName,
            type: labelInfo.type,
            articleSection: labelInfo.articleSection,
            entity: labelInfo.entity,
            value: changedMainLabel,
          },
        ];

    updateMainLabel(updatedMainLabelList);
  };

  return {
    changeTargetMainLabel,
  };
};
