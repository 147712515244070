import React from "react";

import ReactDOMServer from "react-dom/server";

import { splitMultipleStringList } from "../../../../business/DatabaseTable/utils/splitMultipleStringList";
import {
  checkClinicalTrialsIdentifierCode,
  checkClinicalTrialsIdentifierExternalLink,
} from "../../../../shared/components/ClinicalTrialIdentifierIcon/utils/checkClinicalTrialsIdentifierCode";
import { externalLinkIcon } from "../images/externalLinkIcon";

const removeTagFromString = (inputString: string) => {
  return inputString.replace(/<mark[^>]*>(.*?)<\/mark>/gi, "$1");
};

export const createReadModeHtml = (
  html: string,
  imagePosition: "left" | "bottom" | undefined,
  imageNodeList: React.ReactNode[],
  imageColumnCount: number,
  isClinicalTrialIdentifier: boolean,
) => {
  const preProcessHtml = (newHtml: string) => {
    const lineList = newHtml.split("\n");
    const imageNodeListLength = imageNodeList.length;

    const targetColumnCount = () => {
      if (imageNodeListLength === 0) return 1;
      if (imageNodeListLength <= imageColumnCount) return imageNodeListLength;
      return imageColumnCount;
    };

    return lineList
      .map((line, index) => {
        const targetValue = line.startsWith("- ") ? line.slice(2) : line;
        const removeTagTargetValue = removeTagFromString(targetValue);
        const externalLink =
          checkClinicalTrialsIdentifierExternalLink(removeTagTargetValue);
        const displayCountryCode =
          splitMultipleStringList(
            checkClinicalTrialsIdentifierCode(removeTagTargetValue),
          )?.at(0) || "";

        if (line.startsWith("- ")) {
          if (imagePosition && !imageNodeList[index]) {
            return `<ul class="with-image-list"><li>${targetValue}</li></ul>`;
          }
          if (imagePosition && imageNodeList[index]) {
            const imageNodeString = ReactDOMServer.renderToString(
              imageNodeList[index] as React.ReactElement,
            );
            if (imagePosition === "left") {
              if (isClinicalTrialIdentifier) {
                if (externalLink !== null) {
                  return `<ul class="with-image-list row-container image-column-${targetColumnCount()}"><li><a href="${externalLink}" target="_blank"><div>${imageNodeString}</div><div class="link">${targetValue}</div><div>(${displayCountryCode})</div>${externalLinkIcon}</a></li></ul>`;
                }
                return `<ul class="with-image-list row-container image-column-${targetColumnCount()}"><li><div>${imageNodeString}</div><div>${targetValue}</div><div>(${displayCountryCode})</div></li></ul>`;
              }
              return `<ul class="with-image-list row-container image-column-${targetColumnCount()}"><li><div>${imageNodeString}</div><div>${targetValue}</div></li></ul>`;
            }
            return `<ul class="with-image-list"><li>${targetValue}<div>${imageNodeString}</div></li></ul>`;
          }
          return `<ul class="bullet-list"><li>${targetValue}</li></ul>`;
        }
        if (imagePosition && !imageNodeList[index]) {
          return line;
        }
        if (imagePosition && imageNodeList[index]) {
          const imageNodeString = ReactDOMServer.renderToString(
            imageNodeList[index] as React.ReactElement,
          );
          if (imagePosition === "left") {
            if (isClinicalTrialIdentifier) {
              if (externalLink !== null) {
                return `<ul class="with-image-list row-container image-column-${targetColumnCount()}"><li><a href="${externalLink}" target="_blank"><div>${imageNodeString}</div><div class="link">${targetValue}</div><div>(${displayCountryCode})</div>${externalLinkIcon}</a></li></ul>`;
              }
              return `<ul class="with-image-list row-container image-column-${targetColumnCount()}"><li><div>${imageNodeString}</div><div>${targetValue}</div><div>(${displayCountryCode})</div></li></ul>`;
            }
            return `<ul class="with-image-list row-container image-column-${targetColumnCount()}"><li><div>${imageNodeString}</div><div>${targetValue}</div></li></ul>`;
          }
          return `<ul class="with-image-list"><li>${targetValue}${imageNodeString}</li></ul>`;
        }
        return targetValue;
      })
      .join("\n");
  };

  const postProcessHtml = (newHtml: string) => {
    return newHtml.replaceAll("\n<ul>", "<ul>").replaceAll("</ul>\n", "</ul>");
  };

  return postProcessHtml(preProcessHtml(html));
};
