import {
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS,
  ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSOR_TABLE_KEYS,
} from "../../../constants";

export const LICENSE_AGREEMENT_KEY = "License Agreement";

export const LICENSE_AGREEMENT_GROUP_TABLE_KEY = ENTRY_DATA_LICENSEE_TABLE;
export const LICENSE_AGREEMENT_TABLE_KEYS = {
  dealPurpose: "Deal Purpose",
  licensor: "Licensor",
  licensee: "Licensee",
  additionalInformation: "Additional Information",
  none: null,
} as const;

export const LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS = {
  rightsScope: "Rights Scope",
  financialTerms: "Financial terms SC & LA",
  otherFinancialTerms: "Other Financial Terms",
  none: null,
} as const;

export const LICENSE_AGREEMENT_GROUP_SHELL_KEYS = {
  dealPurpose: "Deal Purpose",
  licensor: "Licensor",
  rightsScope: "Rights Scope",
  financialTerms: "Financial terms SC & LA",
  otherFinancialTerms: "Other Financial Terms",
  additionalInformation: "Additional Information",
  none: null,
} as const;

export const LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS = {
  upfront: "Upfront",
  milestone: "Milestone",
  total: "Total",
  otherFinancialTerms: "Other Financial Terms",
  otherPaymentAndDetail: "Other Payment and Detail",
  none: null,
} as const;

export const LICENSE_AGREEMENT_SHELL_KEYS = {
  licensorName: ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorName,
  licensorResponsibilityRights:
    ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorResponsibilityRights,
  licenseeName: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.licenseeName,
  licenseeResponsibilityRightsScLa:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.licenseeResponsibilityAndRightsScLa,
  upfrontPayment: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.upfrontPayment,
  milestonePayment: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.milestonePayment,
  milestoneConditionTitle:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.milestoneConditionTitle,
  otherPaymentScLa: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.otherPaymentScLa,
  otherPaymentDetailScLa:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.otherPaymentDetailScLa,
  royalty: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.royalty,
  totalPaymentScLa: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.totalPaymentScLa,
  otherFinancialTermsScLa:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.otherFinancialTermsScLa,
  drug: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.drug,
  Indication: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.indication,
  exclusivity: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.exclusivity,
  territory: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.territory,
  additionalInformation:
    ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.additionalInformation,
  purposeOfTheDeal:
    ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.purposeOfTheDeal,
  disclosedPaymentsSumScLa:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.disclosedPaymentsSumScLa,
} as const;
