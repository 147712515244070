import type { EditableText, Label } from "../types/editableText.types";

const getText = (node: Node): string => {
  let text = "";

  if (node.nodeType === Node.TEXT_NODE) {
    text += node.textContent ?? "";
  }

  node.childNodes.forEach((childNode) => {
    text += getText(childNode);
  });

  return text;
};

const getLabels = (rootNode: Node): Label[] => {
  const newLabels: Label[] = [];
  let index = 0;

  rootNode.childNodes.forEach((childNode) => {
    if (childNode.nodeName === "MARK") {
      const htmlNode = childNode as HTMLElement;

      const textNode = childNode.firstChild;
      const value = textNode?.textContent ?? "";
      const start = index;
      const end = index + value.length;
      const position: [number, number] = [start, end];
      const entity = htmlNode.dataset.entity ?? "";
      const attributionId = htmlNode.dataset.attributionId ?? "";
      const type = "Normal";

      newLabels.push({
        value,
        position,
        entity,
        attributionId,
        type,
      });
      index += value.length;
    } else {
      index += childNode.textContent?.length ?? 0;
    }
  });

  return newLabels;
};

export const convertHtmlToContent = (html: string): EditableText => {
  const tempDivNode = document.createElement("div");
  tempDivNode.innerHTML = html;

  const text = getText(tempDivNode);
  // const labels = getLabels(tempDivNode);

  return {
    text,
    // labels,
  };
};
