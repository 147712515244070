import React from "react";

import { DefaultInvestorPerson } from "../../graphicIcon";
import { IconDefaultCompanyImage } from "../../Icons";
import AvatarBackground from "../core/AvatarBackground/AvatarBackground";

interface Props {
  imageSize: number | string;
  containerSize: number;
  borderRadius?: string;
  logoUrl?: string | null;
  className?: string;
  imageComponent?: JSX.Element;
  isPerson?: boolean;
}

const CompanyImage = ({
  imageSize,
  containerSize,
  borderRadius,
  logoUrl,
  className,
  imageComponent,
  isPerson,
}: Props) => {
  const isHaveImageComponent = imageComponent && imageComponent?.props.src;

  const isNotHaveImage = !logoUrl && !isHaveImageComponent;

  const isPersonImage = isNotHaveImage && isPerson;

  const returnCompanyLogoComponent = () => {
    if (isPersonImage) return <DefaultInvestorPerson />;

    if (isNotHaveImage) return <IconDefaultCompanyImage />;

    if (isHaveImageComponent) return imageComponent;

    if (logoUrl)
      return (
        <img
          src={logoUrl}
          alt="company logo"
          width={imageSize}
          height={imageSize}
        />
      );

    return <></>;
  };

  return (
    <AvatarBackground
      containerSize={`${containerSize / 10}rem`}
      borderRadius={borderRadius}
      className={className}
    >
      <>{returnCompanyLogoComponent()}</>
    </AvatarBackground>
  );
};

export default CompanyImage;
