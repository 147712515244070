import React from "react";

import * as S from "./Layout.style";

interface Props {
  children: React.ReactNode;
}
const LayoutResponsiveCenter = ({ children }: Props) => {
  return (
    <S.LayoutResponsiveCenterWrapper>
      {children}
    </S.LayoutResponsiveCenterWrapper>
  );
};

export default LayoutResponsiveCenter;
