import React, { createContext, useContext, useMemo, useState } from "react";

export interface InsertImageFilter {
  page: number;
  size: number;
  filter:
    | {
        keyword: string[];
      }
    | {
        search: string;
      };
  order: object;
}

export const DEFAULT_IMAGE_FILTER: InsertImageFilter = {
  page: 1,
  size: 10,
  filter: {
    keyword: [],
  },

  order: [],
};

export const InsertImageFilterContext = createContext<{
  insertImageFilter: InsertImageFilter;
  changeInsertImageFilter: (updatedFilter: InsertImageFilter) => void;
}>({
  insertImageFilter: DEFAULT_IMAGE_FILTER,
  changeInsertImageFilter: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const InsertImageFilterProvider = ({ children }: Props) => {
  const [insertImageFilter, setInsertImageFilter] =
    useState<InsertImageFilter>(DEFAULT_IMAGE_FILTER);

  const changeInsertImageFilter = (updatedFilter: InsertImageFilter): void => {
    setInsertImageFilter(updatedFilter);
  };

  const insertImageFilterWrapper = useMemo(
    () => ({ insertImageFilter, changeInsertImageFilter }),
    [insertImageFilter],
  );
  return (
    <InsertImageFilterContext.Provider value={insertImageFilterWrapper}>
      {children}
    </InsertImageFilterContext.Provider>
  );
};

export const useInsertImageFilter = () => {
  const { insertImageFilter, changeInsertImageFilter } = useContext(
    InsertImageFilterContext,
  );

  return { insertImageFilter, changeInsertImageFilter };
};
