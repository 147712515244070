import React from "react";

import { toast } from "react-toastify";

import {
  TOAST_ICONS,
  TOAST_POSITIONS,
  type ToastBackgroundColor,
  type ToastIcon,
  type ToastPosition,
} from "./constants/toast.constants";
import * as S from "./Toast.style";

interface Props {
  backgroundColor?: ToastBackgroundColor;
  icon?: ToastIcon;
  position?: ToastPosition;
  text?: string;
  customButton?: { name: string; action: () => void }[];
  component?: React.ReactNode;
  autoClose?: number | false;
}

const DEFAULT_TOAST_TIME = 4000;

export const showToast = ({
  backgroundColor = "red",
  icon,
  text,
  customButton,
  position = "topCenter",
  component,
  autoClose = DEFAULT_TOAST_TIME,
}: Props) => {
  const TOAST_OPTIONS = {
    position: TOAST_POSITIONS[position],
    draggable: false,
    autoClose,
    closeButton: false,
  };

  toast(
    component || (
      <S.Toast backgroundColor={backgroundColor}>
        <S.ContentsWrapper>
          <S.Content>
            {icon && <img src={TOAST_ICONS[icon]} alt={icon} />}
            <S.Text>{text || ""}</S.Text>
          </S.Content>
          {customButton && customButton.length > 0 && (
            <S.ButtonWrapper>
              {customButton.map(({ name, action }) => (
                <S.Button onClick={action}>{name}</S.Button>
              ))}
            </S.ButtonWrapper>
          )}
        </S.ContentsWrapper>
        <S.CloseButton />
      </S.Toast>
    ),
    { ...TOAST_OPTIONS },
  );
};

const Toast = () => {
  return <S.Container />;
};

export default Toast;
