export const ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY = "Drug Profile";

export const ENTRY_DATA_DRUG_PROFILE_KEYS = {
  brandName: "Brand Name",
  codeName: "Code Name",
  innName: "INN Name",
  roa: "ROA",
  modality: "Modality",
  moa: "MOA",
  therapeuticArea: "Therapeutic Area",
  target: "Target",
  targetIndication: "Target Indication",
  originatorDeveloper: "Originator/Developer",
  otherCharacteristics: "Other Characteristics",
  genericBiosimilar: "Generic / Biosimilar",
  refProductName: "Ref.Product Name",
  phase: "Phase",
} as const;

export const ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS = {
  brandName: ENTRY_DATA_DRUG_PROFILE_KEYS.brandName,
  codeName: ENTRY_DATA_DRUG_PROFILE_KEYS.codeName,
  innName: ENTRY_DATA_DRUG_PROFILE_KEYS.innName,
  roa: ENTRY_DATA_DRUG_PROFILE_KEYS.roa,
  modality: ENTRY_DATA_DRUG_PROFILE_KEYS.modality,
  moa: ENTRY_DATA_DRUG_PROFILE_KEYS.moa,
  therapeuticArea: ENTRY_DATA_DRUG_PROFILE_KEYS.therapeuticArea,
  target: ENTRY_DATA_DRUG_PROFILE_KEYS.target,
  targetIndication: ENTRY_DATA_DRUG_PROFILE_KEYS.targetIndication,
  originatorDeveloper: ENTRY_DATA_DRUG_PROFILE_KEYS.originatorDeveloper,
  otherCharacteristics: ENTRY_DATA_DRUG_PROFILE_KEYS.otherCharacteristics,
  genericBiosimilar: ENTRY_DATA_DRUG_PROFILE_KEYS.genericBiosimilar,
  refProductName: ENTRY_DATA_DRUG_PROFILE_KEYS.refProductName,
  phase: ENTRY_DATA_DRUG_PROFILE_KEYS.phase,
} as const;

export const ENTRY_DATA_DRUG_PROFILE_GROUP_SHELL_KEYS = {
  drugName: "Drug Name",
  none: null,
} as const;

export const ENTRY_DATA_DRUG_PROFILE_TABLE_KEYS = {
  drug: "Drug",
  none: null,
} as const;
