import { useChangeBackgroundColor } from "@hooks/useChangeBackgroundColor";
import PressListContainer from "@PressListContainer/PressListContainer";
import { theme } from "@styles/theme";
import { removeDealTypeDisplayName } from "@utils/returnDealTypeDisplayName/returnDealTypeDisplayName";

const PressListPage = () => {
  useChangeBackgroundColor(theme.color.neural.gray00);

  // NOTE: Press List로 접근 혹은 새로고침 시 다시 API를 호출하기 위해 remove 합니다.
  removeDealTypeDisplayName();

  return <PressListContainer />;
};

export default PressListPage;
