import { ToastContainer } from "react-toastify";
import styled from "styled-components";

import IconClose from "@images/toast/icon-toast-close.svg";

import "react-toastify/dist/ReactToastify.css";
import {
  TOAST_COLORS,
  type ToastBackgroundColor,
} from "./constants/toast.constants";

export const Container = styled(ToastContainer)`
  position: fixed;
  width: 33.2rem;
  padding: 0;
  z-index: 200;
  background: transparent;

  .Toastify__toast {
    border-radius: 0.8rem;
    background-color: transparent;
    padding: 0;
  }
  .Toastify__toast-body {
    padding: 0;
  }
  .Toastify__progress-bar {
    background: rgba(255, 255, 255, 0.7);
  }

  &.Toastify__toast-container--bottom-left {
    bottom: 8.4em !important;
    left: 4em;
  }
`;

export const Toast = styled.div<{
  backgroundColor?: ToastBackgroundColor;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 6.4rem;
  border-radius: 0.8rem;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? TOAST_COLORS[backgroundColor] : "none"};
  padding: 0.8rem 0rem 0.8rem 1.6rem;
  box-shadow: 0px 4px 8px rgba(7, 68, 162, 0.02);
`;

export const Button = styled.button`
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 400;
  text-decoration: underline;
  color: ${({ theme }) => theme.color.neural.gray00};
  cursor: pointer;
  white-space: nowrap;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
  height: 100%;
`;

export const ContentsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;
  height: 100%;
  width: 100%;
  border-right: 0.1rem solid rgba(251, 252, 254, 0.3);
  padding-right: 1.6rem;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const Text = styled.p`
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.color.neural.gray00};
  line-height: 145%;
  white-space: pre-wrap;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  background-image: url(${IconClose});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;
