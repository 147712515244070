export const EXPEDITED_PROGRAM = "Expedited Program";

export const EXPEDITED_PROGRAM_GROUP_KEY = {
  drugProfile: "Drug Profile",
  expeditedProgramContents: "Expedited Program Contents",
  keyComment: "Key Comment",
} as const;

export const DRUG_PROFILE_KEY = {
  roa: "ROA",
  modality: "Modality",
  moa: "MOA",
  codeName: "Code Name",
  innName: "INN Name",
  brandName: "Brand Name",
  therapeuticArea: "Therapeutic Area",
  target: "Target",
  targetIndication: "Target Indication",
  otherCharacteristics: "Other Characteristics",
} as const;

export const EXPEDITED_PROGRAM_CONTENTS = {
  updateDate: "Update Date",
  designatedCompanyName: "Designated Company name",
  regulatoryAgency: "Regulatory Agency",
  expeditedProgram: "Expedited Program",
  designatedIndication: "Designated Indication",
  AdditionalInformation: "Additional Information on Expedited Program",
} as const;

export const KEY_COMMENT = {
  quote: "Quote",
  spokesperson: "Spokesperson",
  keCommentSetOptions: "Key Comment Set Options",
} as const;
