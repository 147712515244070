"use client";

import styled from "styled-components";

export const LoginDivider = styled.div<{ $width?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: ${({ $width }) => ($width ? `${$width / 10}rem` : "100%")};
`;

export const LoginDividerBorder = styled.div`
  width: calc((100% - 3.8rem) / 2);
  max-width: 60rem;
  height: 0.1rem;
  border-top: 0.1rem solid var(--Color-Common-Border-Secondary, #b1b8c4);
`;

export const LoginDividerText = styled.div`
  color: var(--Color-Common-Border-Secondary, #b1b8c4);
  font-family: "roboto";
  font-size: 1.4rem;
  font-weight: 500;
`;
