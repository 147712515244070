import React, { Component, ReactNode } from "react";

import NotCorrectDataFormat from "./components/NotCorrectDataFormat/NotCorrectDataFormat";
import { NotCorrectDataFormatError } from "../../classes/notCorrectDataFormat";
import { FillInTableError } from "../../fillInTableError";

interface Props {
  children?: ReactNode;
}

interface State {
  shouldHandleError: boolean;
  shouldRethrow: boolean;
  error: Error | null;
}

class FillInTableErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shouldHandleError: false,
      shouldRethrow: false,
      error: null,
    };
  }

  public static getDerivedStateFromError(error: Error): State {
    if (error instanceof FillInTableError) {
      return {
        shouldHandleError: true,
        shouldRethrow: false,
        error,
      };
    }
    return {
      shouldHandleError: false,
      shouldRethrow: true,
      error,
    };
  }

  public render() {
    const { error, shouldHandleError, shouldRethrow } = this.state;
    const { children } = this.props;

    if (shouldRethrow) {
      throw error;
    }
    if (!shouldHandleError) {
      return children;
    }

    if (error instanceof FillInTableError) {
      if (error instanceof NotCorrectDataFormatError) {
        return <NotCorrectDataFormat message={error.message} />;
      } else {
        throw error;
      }
    }
    return children;
  }
}

export default FillInTableErrorBoundary;
