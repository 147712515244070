import {
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_TABLE_KEY as ROUND_TYPE_AND_STATUS,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_TABLE_KEYS as ROUND_TYPE_AND_STATUS_TABLE_KEYS,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_SHELL_KEYS as ROUND_TYPE_AND_STATUS_GROUP_SHELL_KEYS,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_SHELL_KEYS as ROUND_TYPE_AND_STATUS_SHELL_KEYS,
} from "@frontend/common";

import { VentureCapitalGroupTable } from "@/types/ventureCapital.types";
import { TableCreatorTableList } from "@FillInTableContainer/shared/types/tableCreator.types";

interface VentureCapitalFundedCompanyLayout {
  groupTableKey: VentureCapitalGroupTable;
  tableList: TableCreatorTableList;
}

export const VENTURE_CAPITAL_ROUND_TYPE_AND_STATUS_GROUP_TABLE_LAYOUT: VentureCapitalFundedCompanyLayout =
  {
    groupTableKey: ROUND_TYPE_AND_STATUS,
    tableList: [
      {
        tableKey: ROUND_TYPE_AND_STATUS_TABLE_KEYS.none,
        groupShellList: [
          {
            groupShellKey: ROUND_TYPE_AND_STATUS_GROUP_SHELL_KEYS.none,
            shellList: [
              {
                shellKey: ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundName,
              },
              {
                shellKey: ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundType,
              },
            ],
          },
        ],
      },
    ],
  };
