import dayjs from "dayjs";

import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import { REF_CODE_ADDITIONAL_INFORMATION } from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { VentureCapitalAdditionalInformationContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/ventureCapitalAdditionalInformationContent.types";

import { createContentFromHTML } from "../../../utils/getValueFromRefMap";

export const useCreateVentureCapitalAdditionalInformationContent = () => {
  const refMap = useArticleContentRefMap();

  return () => {
    const content: VentureCapitalAdditionalInformationContent = {
      text: {
        "Additional Information": {
          "Additional Information": createContentFromHTML(
            refMap,
            REF_CODE_ADDITIONAL_INFORMATION,
          ),
        },
      },
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
