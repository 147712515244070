import React from "react";

import * as S from "./Layout.style";

interface Props {
  children: React.ReactNode;
}
const LayoutWide = ({ children }: Props) => {
  return <S.LayoutWideWrapper>{children}</S.LayoutWideWrapper>;
};

export default LayoutWide;
