import React from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

import TokenHelper from "@utils/tokenHelper";

import { PageAccess } from "./pageList";
import { ROUTE_PATHS } from "./routePath";

interface Props {
  access: PageAccess;
}

export default function RouteSwitch({ access }: Props): React.ReactElement {
  // TODO: AccessToken을 확인하는 로직을 추가해야 합니다.
  const accessToken = TokenHelper.getAccessToken();
  const isSignIn = !!accessToken;
  const { search, pathname } = useLocation();

  if (access === "public") {
    return <Outlet />;
  }

  if (access === "requireSignIn") {
    return isSignIn ? (
      <Outlet />
    ) : (
      <Navigate to={ROUTE_PATHS.signIn} state={{ search, pathname }} />
    );
  }

  if (access === "requireSignOut") {
    return isSignIn ? <Navigate to={ROUTE_PATHS.pressList} /> : <Outlet />;
  }

  throw new Error(`${access}는 잘못된 route access입니다.`);
}
