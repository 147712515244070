import { CSSProperties } from "react";

import styled, { css } from "styled-components";

import { disabledTarget } from "@styles/mixin";

import { BUTTON_STYLE, BUTTON_SIZE } from "./Button";

import type { ValueOf } from "../../../types/util.types";

export const ButtonWrapper = styled.button<{
  buttonStyle: ValueOf<typeof BUTTON_STYLE>;
  buttonSize: ValueOf<typeof BUTTON_SIZE>;
  gap: CSSProperties["gap"];
  opacity: CSSProperties["opacity"];
  borderRadius: CSSProperties["borderRadius"];
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 600;
  border-radius: 0.8rem;
  outline: none;
  cursor: pointer;

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `}
  ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}
  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius};
    `}
  
  ${disabledTarget}

  ${({ buttonSize }) =>
    buttonSize === BUTTON_SIZE.default &&
    css`
      height: 4.2rem;
      padding: 0 6.4rem;
    `};

  ${({ buttonSize }) =>
    buttonSize === BUTTON_SIZE.full &&
    css`
      width: 100%;
      height: 4.2rem;
      padding: 0 1rem;
    `};

  ${({ buttonSize }) =>
    buttonSize === BUTTON_SIZE.medium &&
    css`
      min-width: 16.9rem;
      height: 4.2rem;
      padding: 0 1.6rem;
    `};

  ${({ buttonSize }) =>
    buttonSize === BUTTON_SIZE.small &&
    css`
      height: 3rem;
      padding: 0 2rem;
    `};

  ${({ buttonSize }) =>
    buttonSize === BUTTON_SIZE.xSmall &&
    css`
      height: 2.4rem;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0 1.2rem;
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.solid &&
    css`
      color: ${({ theme }) => theme.color.neural.gray01};
      background: ${({ theme }) => theme.color.gradient.background1};
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.orange &&
    css`
      color: ${({ theme }) => theme.color.neural.gray01};
      background: #ff9900;
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.solidOrange &&
    css`
      color: ${({ theme }) => theme.color.neural.gray01};
      background: ${({ theme }) => theme.color.gradient.background4};
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.solidSky &&
    css`
      color: ${({ theme }) => theme.color.neural.gray00};
      background: ${({ theme }) => theme.color.node.sky};
      box-shadow: 0px 4px 8px rgba(7, 68, 162, 0.02);
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.solidBlue &&
    css`
      color: ${({ theme }) => theme.color.neural.white};
      background: ${({ theme }) => theme.color.node.blue};
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.solidBlueGreen &&
    css`
      color: ${({ theme }) => theme.color.neural.gray00};
      background: ${({ theme }) => theme.color.bio.blueGreen06};
      box-shadow: 0px 4px 8px rgba(7, 68, 162, 0.02);
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.solidStatusUnsuitable &&
    css`
      color: ${({ theme }) => theme.color.neural.gray01};
      background: ${({ theme }) => theme.color.status.disable};
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.line &&
    css`
      color: ${({ theme }) => theme.color.node.blue};
      border-color: ${({ theme }) => theme.color.node.blue};
      border-style: solid;
      border-width: 0.1rem;
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.lineGray &&
    css`
      color: ${({ theme }) => theme.color.neural.gray07};
      border-color: ${({ theme }) => theme.color.neural.gray07};
      border-style: solid;
      border-width: 0.1rem;
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.lineLightGray &&
    css`
      color: ${({ theme }) => theme.color.neural.gray03};
      border-color: ${({ theme }) => theme.color.neural.gray03};
      border-style: solid;
      border-width: 0.1rem;
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.lineSky &&
    css`
      color: ${({ theme }) => theme.color.node.sky};
      border-color: ${({ theme }) => theme.color.node.sky};
      border-style: solid;
      border-width: 0.1rem;
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.lineStatusProgress &&
    css`
      color: ${({ theme }) => theme.color.status.progress};
      border-color: ${({ theme }) => theme.color.status.progress};
      border-style: solid;
      border-width: 0.1rem;
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.disabled &&
    css`
      color: ${({ theme }) => theme.color.neural.gray00};
      background: ${({ theme }) => theme.color.neural.gray04};
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.text &&
    css`
      height: auto;
      color: ${({ theme }) => theme.color.node.blue};
      padding: 0;
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.textGray &&
    css`
      height: auto;
      color: ${({ theme }) => theme.color.neural.gray07};
      padding: 0;
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.textOrange &&
    css`
      height: auto;
      color: ${({ theme }) => theme.color.live.orange};
      padding: 0;
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.textDarkGray &&
    css`
      height: auto;
      color: ${({ theme }) => theme.color.neural.gray08};
      padding: 0;
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.textSkyBlue &&
    css`
      height: auto;
      color: ${({ theme }) => theme.color.node.sky};
      padding: 0;
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.textBioBlue06 &&
    css`
      height: auto;
      color: ${({ theme }) => theme.color.bio.blueGreen06};
      padding: 0;
    `};

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.solidNodeSky &&
    css`
      background-color: ${({ theme }) => theme.color.node.sky};
      color: ${({ theme }) => theme.color.neural.white};
      padding: 0 1.6rem;
      gap: 0.5rem;
    `}

  ${({ buttonStyle }) =>
    buttonStyle === BUTTON_STYLE.solidGray &&
    css`
      color: ${({ theme }) => theme.color.neural.gray00};
      background: ${({ theme }) => theme.color.neural.gray07};
    `}
`;
