import styled, { css } from "styled-components";

import { FocusStatus } from "@/components/business/FillInTableContainer/shared/context/TableCreatorFocusStatusContext";

export const DrugAndDropInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const TextFieldWrapper = styled.div<{
  isFocused: boolean;
  isMultipleLastValue: boolean;
  textFieldMargin: number;
  focusStatus: FocusStatus;
  isDragging?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 2.8rem;
  width: 100%;
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
  border-radius: 0.4rem;
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-4, 0.4rem)
    var(--Spacing-2, 0.2rem) var(--Spacing-8, 0.8rem);

  ${({ isMultipleLastValue }) =>
    isMultipleLastValue &&
    css`
      border: 1px dashed var(--Color-Common-Border-Tertiary, #e5e7eb);
      background: var(--Color-Common-Bg-Interactive-Secondary, #fff);
    `}

  ${({ focusStatus, isFocused }) =>
    focusStatus.container === "textField" &&
    focusStatus.type === "focusInput" &&
    isFocused &&
    css`
      border: 1px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);
      background: var(--Color-Common-Bg-Primary, #fff);
    `}

  ${({ textFieldMargin }) =>
    textFieldMargin &&
    css`
      margin-bottom: ${textFieldMargin / 10}rem;
    `}

    ${({ isDragging }) =>
    isDragging &&
    css`
      border-radius: 0.4rem;
      border: 1px solid
        var(--Color-Common-Border-Interactive-Secondary-Pressed, #7c899c);
      background: var(--Color-Bg-Draft, #d9f4f7);
      /* Elevation/Light/Shadow8 */
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16),
        0px 0px 4px 0px rgba(0, 0, 0, 0.12);
    `}
`;
