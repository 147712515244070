import React from "react";

import { useGetAiImageInfoData } from "@api/aiImages/aiImages";

import * as S from "./InsertImageTabMenu.style";
import { SelectImageType } from "../../shared/types/insertImage.types";

interface Props {
  tabMenuType: SelectImageType;
  handleTabMenuType: (type: SelectImageType) => void;
}

const InsertImageTabMenu = ({ handleTabMenuType, tabMenuType }: Props) => {
  const aiImageInfo = useGetAiImageInfoData();
  const aiStatus = aiImageInfo?.data?.aiImageStatus;

  const aiImageStatusFlag = () => {
    switch (aiStatus) {
      case "SUCCESS":
        return (
          <S.AiStatusButton aiStatus={aiStatus}>Generated</S.AiStatusButton>
        );
      case "GENERATING":
        return (
          <S.AiStatusButton aiStatus={aiStatus}>Generating..</S.AiStatusButton>
        );
      case "FAIL":
        return <S.AiStatusButton aiStatus={aiStatus}>Error</S.AiStatusButton>;
      case "PENDING":
        return (
          <S.AiStatusButton aiStatus={aiStatus}>Generating..</S.AiStatusButton>
        );
      default:
        return <></>;
    }
  };
  return (
    <S.TabMenuWrapper>
      <S.TabMenuButton
        onClick={() => handleTabMenuType("select")}
        isFocused={tabMenuType === "select"}
      >
        DB Image Library
      </S.TabMenuButton>
      <S.TabMenuButton
        onClick={() => handleTabMenuType("upload")}
        isFocused={tabMenuType === "upload"}
      >
        User Upload
      </S.TabMenuButton>
      <S.TabMenuButton
        onClick={() => handleTabMenuType("ai")}
        isFocused={tabMenuType === "ai"}
      >
        AI Image
        {aiImageStatusFlag()}
      </S.TabMenuButton>
    </S.TabMenuWrapper>
  );
};

export default InsertImageTabMenu;
