import PressListTableBody from "./components/PressListTableBody/PressListTableBody";
import PressListTableHead from "./components/PressListTableHead/PressListTableHead";
import * as S from "./PressListTable.style";

const PressListTable = () => {
  return (
    <S.PressListTable>
      <PressListTableHead />
      <PressListTableBody />
    </S.PressListTable>
  );
};

export default PressListTable;
