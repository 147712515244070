"use client";

import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-6, 0.6rem);
  width: 100%;
  flex: 1 0 0;
`;

export const UpcomingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 27rem;
`;

export const ImageWrapper = styled.div`
  width: 14.6rem;
  height: 17.3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const BlurImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  border-radius: var(--Border-Radius-8, 0.8rem);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Primary, #fff);
  padding: var(--Spacing-8, 0.8rem);
`;

export const XAxisList = styled.div`
  display: flex;
  justify-content: space-between;

  opacity: 0.5;
  border-top: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  padding: 0 0.2rem;
  padding-top: 0.8rem;
  margin-top: -0.2rem;
  margin-bottom: -0.3rem;
`;

export const XAxisItem = styled.div`
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);

  font-family: var(--font-pt-serif);
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 130%;
`;
