import styled from "styled-components";

export const PressListEntryModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.4rem 0;
  width: 51.2rem;
  height: 20.3rem;
  background-color: ${({ theme }) => theme.color.neural.white};
  padding: 2.8rem 3.2rem 2.4rem 3.2rem;
  border-radius: 2rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ModalTitle = styled.h1`
  display: flex;
  align-items: center;
  height: 1.4rem;
  color: ${({ theme }) => theme.status.S930};
  font-size: 2rem;
  font-weight: 600;
`;

export const ModalText = styled.p<{
  isEnableRedirect: boolean;
}>`
  position: relative;
  bottom: 6px;
  height: ${({ isEnableRedirect }) => (isEnableRedirect ? "5.4rem" : "3.2rem")};
  color: ${({ theme }) => theme.color.neural.gray09};
  font-size: 1.4rem;
  line-height: 160%;
  font-weight: 400;
`;

export const PressClassificationLink = styled.button`
  text-decoration: underline !important;
  cursor: pointer;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 21.4rem;
  height: 3.5rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 100%;
  border-radius: 0.5rem;
  padding: 1.2rem 0;
  margin-right: 2rem;
  cursor: pointer;
`;

export const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 21.4rem;
  height: 3.5rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 100%;
  color: ${({ theme }) => theme.color.neural.white};
  padding: 1.2rem 0;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.status.S930};
  cursor: pointer;
`;
