import { useCallback, useEffect } from "react";

import { useTableCreatorFocusStatus } from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorPositionInfo } from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";

import { useInvestorTableValueClear } from "./useInvestorTableValueClear";
import { useInvestorTableValueDelete } from "./useInvestorTableValueDelete";

export const useInvestorTableValueClearDeleteButton = () => {
  const { focusStatus } = useTableCreatorFocusStatus();
  const { info } = useTableCreatorPositionInfo();
  const { clearInvestorTableValue } = useInvestorTableValueClear();
  const { deleteInvestorTableValue } = useInvestorTableValueDelete();

  const clearDeleteInvestorValue = (valueIndex: number) => {
    if (focusStatus.type === "clear") clearInvestorTableValue(valueIndex);
    if (focusStatus.type === "delete") deleteInvestorTableValue(valueIndex);
  };

  const keyDownDeleteToClearOrDeleteInvestorValue = useCallback(
    (event: KeyboardEvent) => {
      const { valueIndex } = info;
      if (focusStatus.type === "clear" && event.key === "Delete") {
        clearInvestorTableValue(valueIndex as number);
      }
      if (focusStatus.type === "delete" && event.key === "Delete") {
        deleteInvestorTableValue(valueIndex as number);
      }
    },
    [info, focusStatus.type, clearInvestorTableValue, deleteInvestorTableValue],
  );

  useEffect(() => {
    if (focusStatus.container !== "value") return;

    window.addEventListener(
      "keydown",
      keyDownDeleteToClearOrDeleteInvestorValue,
    );

    return () => {
      window.removeEventListener(
        "keydown",
        keyDownDeleteToClearOrDeleteInvestorValue,
      );
    };
  }, [focusStatus, keyDownDeleteToClearOrDeleteInvestorValue]);

  return {
    clearDeleteInvestorValue,
    keyDownDeleteToClearOrDeleteInvestorValue,
  };
};
