export const ARTICLE_CONTENTS_DEAL_PURPOSE_PARAGRAPH_TITLE =
  "Deal Purpose" as const;

export const ARTICLE_CONTENTS_DEAL_PURPOSE_SHELL_KEYS = {
  dealPurpose: "Purpose of the Deal",
} as const;

export const ARTICLE_CONTENTS_DEAL_PURPOSE_SHELL_TITLE = {
  dealPurpose: "Deal Purpose",
} as const;
