import { useCallback } from "react";

import { ClinicalTrialStatusDrugProfileShellKey } from "@/types/clinicalTrialStatus.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { PositionInfoProps } from "../ClinicalTrialStatusTableCreatorShell";
import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorShellReturn {
  handleClickToClearDrugProfileShell: () => void;
  handleKeyDownToClearDrugProfileShell: (event: KeyboardEvent) => void;
}

export const useHandleClinicalTrialStatusDrugProfileShell = (
  positionPropsInfo: PositionInfoProps,
): UseHandleTableCreatorShellReturn => {
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const handleClearShell = useCallback(() => {
    const clearDrugProfileShell = (): ArticleEntryData => {
      return {
        ...entryData,
        "Clinical Trial Status": {
          ...entryData["Clinical Trial Status"],
          "Drug Profile": entryData["Clinical Trial Status"]?.[
            "Drug Profile"
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [info.shellKey as ClinicalTrialStatusDrugProfileShellKey]: {
                  ...tableInfo[
                    info.shellKey as ClinicalTrialStatusDrugProfileShellKey
                  ],
                  value:
                    info.shellKey === "Generic / Biosimilar" ? ["false"] : [],
                },
              };
            }
            return tableInfo;
          }),
        },
      };
    };

    if (info.groupTableKey === "Drug Profile") {
      setEntryData(clearDrugProfileShell());
      changeCurrentHistory({
        entryData: clearDrugProfileShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  const handleClickToClearDrugProfileShell = () => {
    if (focusStatus.type === "clear") {
      handleClearShell();
    }
  };

  const handleKeyDownToClearDrugProfileShell = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearShell();
      }
    },
    [focusStatus.type, handleClearShell],
  );

  return {
    handleClickToClearDrugProfileShell,
    handleKeyDownToClearDrugProfileShell,
  };
};
