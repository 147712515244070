export const ARTICLE_CONTENTS_COMPANY_PROFILE_PARAGRAPH_CODE = "TC516" as const;
export const ARTICLE_CONTENTS_COMPANY_PROFILE_SECTION_CODE = "TY006" as const;
export const ARTICLE_CONTENTS_COMPANY_PROFILE_PARAGRAPH_TITLE =
  "Company Profile" as const;

export const ARTICLE_CONTENTS_COMPANY_PROFILE_PARAGRAPH_GROUP_KEY =
  "Company Profile";

export const ARTICLE_CONTENTS_COMPANY_PROFILE_SHELL_KEYS = {
  companyDescription: "Company Description",
  companyName: "Company Name",
} as const;

export const ARTICLE_CONTENTS_COMPANY_PROFILE_SHELL_TITLE = {
  companyDescription: "Summary",
  companyName: "Company Name",
} as const;
