import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_FUNDED_COMPANY_GROUP_TABLE_KEY as FUNDED_COMPANY,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_TABLE_KEY as ROUND_TYPE_AND_STATUS,
  ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY as FINANCING_INFORMATION,
  ENTRY_DATA_INVESTORS_GROUP_TABLE_KEY as INVESTORS,
  ENTRY_DATA_KEY_COMMENTS_GROUP_TABLE_KEY as KEY_COMMENTS,
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_TABLE_KEY as ADDITIONAL_INFORMATION,
} from "@frontend/common";

import ComponentErrorBoundary from "@/components/shared/business/ComponentErrorBoundary/ComponentErrorBoundary";
import { TableCreatorGroupTableTitle } from "@FillInTableContainer/shared";
import TableCreatorNavigationTableWrapper from "@FillInTableContainer/shared/components/TableCreatorNavigationTableWrapper/TableCreatorNavigationTableWrapper";
import { getGroupShellTitle } from "@FillInTableContainer/shared/utils/getTextFromData";

import VentureCapitalAdditionalInformationGroupTable from "./components/VentureCapitalAdditionalInformationGroupTable/VentureCapitalInformationGroupTable";
import VentureCapitalDrugProfileGroupTable from "./components/VentureCapitalDrugProfileGroupTable/VentureCapitalDrugProfileGroupTable";
import VentureCapitalFinancingInformationGroupTable from "./components/VentureCapitalFinancingInformationGroupTable/VentureCapitalFinancingInformationGroupTable";
import VentureCapitalFundedCompanyGroupTable from "./components/VentureCapitalFundedCompanyGroupTable/VentureCapitalFundedCompanyGroupTable";
import VentureCapitalInvestorsGroupTable from "./components/VentureCapitalInvestorsGroupTable/VentureCapitalInvestorsGroupTable";
import VentureCapitalKeyCommentGroupTable from "./components/VentureCapitalKeyCommentGroupTable/VentureCapitalKeyCommentGroupTable";
import VentureCapitalRoundTypeAndStatusGroupTable from "./components/VentureCapitalRoundTypeAndStatusGroupTable/VentureCapitalRoundTypeAndStatusGroupTable";

const VentureCapitalTableCreator = () => {
  const errorWithComponent = (title: string) => (
    <TableCreatorGroupTableTitle title={title} isRequired={false} />
  );
  return (
    <TableCreatorNavigationTableWrapper
      classificationKey={SECTION_KEYS.ventureCapital}
    >
      <>
        <ComponentErrorBoundary
          addComponents={errorWithComponent(
            getGroupShellTitle(SECTION_KEYS.ventureCapital, DRUG_PROFILE),
          )}
        >
          <VentureCapitalDrugProfileGroupTable />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary
          addComponents={errorWithComponent(
            getGroupShellTitle(
              SECTION_KEYS.ventureCapital,
              ROUND_TYPE_AND_STATUS,
            ),
          )}
        >
          <VentureCapitalRoundTypeAndStatusGroupTable />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary
          addComponents={errorWithComponent(
            getGroupShellTitle(SECTION_KEYS.ventureCapital, FUNDED_COMPANY),
          )}
        >
          <VentureCapitalFundedCompanyGroupTable />
        </ComponentErrorBoundary>

        <ComponentErrorBoundary
          addComponents={errorWithComponent(
            getGroupShellTitle(
              SECTION_KEYS.ventureCapital,
              FINANCING_INFORMATION,
            ),
          )}
        >
          <VentureCapitalFinancingInformationGroupTable />
        </ComponentErrorBoundary>

        <ComponentErrorBoundary
          addComponents={errorWithComponent(
            getGroupShellTitle(SECTION_KEYS.ventureCapital, INVESTORS),
          )}
        >
          <VentureCapitalInvestorsGroupTable />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary
          addComponents={errorWithComponent(
            getGroupShellTitle(
              SECTION_KEYS.ventureCapital,
              ADDITIONAL_INFORMATION,
            ),
          )}
        >
          <VentureCapitalAdditionalInformationGroupTable />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary
          addComponents={errorWithComponent(
            getGroupShellTitle(SECTION_KEYS.ventureCapital, KEY_COMMENTS),
          )}
        >
          <VentureCapitalKeyCommentGroupTable />
        </ComponentErrorBoundary>
      </>
    </TableCreatorNavigationTableWrapper>
  );
};

export default VentureCapitalTableCreator;
