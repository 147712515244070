import React, { useId, forwardRef } from "react";

import * as S from "./Checkbox.style";
import checkboxIconDisabled from "../../../assets/images/common/checkbox-disable.svg";
import checkboxIconEnabled from "../../../assets/images/common/checkbox-enable.svg";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  children: React.ReactElement | React.ReactElement[] | string;
  checkboxStyle?: "enabled" | "disabled";
}

const Checkbox = forwardRef(
  (
    { children, checkboxStyle = "enabled", ...rest }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const checkboxId = useId();

    return (
      <S.CheckboxWrapper htmlFor={checkboxId} checkboxStyle={checkboxStyle}>
        <strong>
          <input
            type="checkbox"
            id={checkboxId}
            disabled={checkboxStyle === "disabled"}
            {...rest}
            ref={ref}
          />
          {checkboxStyle === "disabled" ? (
            <img src={checkboxIconDisabled} alt="checkbox icon" />
          ) : (
            <img
              src={rest.checked ? checkboxIconEnabled : checkboxIconDisabled}
              alt="checkbox icon"
            />
          )}
        </strong>

        <span>{children}</span>
      </S.CheckboxWrapper>
    );
  },
);

export default Checkbox;
