import { ProcessStatusCode } from "@/types/code.types";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";

export const PRESS_CLASSIFICATION_ROLES_LIST: Record<
  string,
  ProcessStatusCode[]
> = {
  EDTK0100: [
    DRAFT_STATUS_CODE.notForAiEntry,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.unsuitable,
  ],
  EDTK0201: [
    DRAFT_STATUS_CODE.unsuitable,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
  ],
  EDTK0202: [
    DRAFT_STATUS_CODE.unsuitable,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
  ],
  EDTK0303: [
    DRAFT_STATUS_CODE.unsuitable,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
  ],
  EDTK0401: [
    DRAFT_STATUS_CODE.unsuitable,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
  ],
  EDTK0402: [
    DRAFT_STATUS_CODE.unsuitable,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
  ],
  EDTK0503: [
    DRAFT_STATUS_CODE.unsuitable,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
  ],
  EDTK0603: [
    DRAFT_STATUS_CODE.unsuitable,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
  ],
  EDTK0703: [
    DRAFT_STATUS_CODE.unsuitable,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
  ],
  EDTK0803: [
    DRAFT_STATUS_CODE.unsuitable,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
  ],
};

export const PRESS_CLASSIFICATION_MOVE_NEXT_ROLES_LIST: Record<
  string,
  ProcessStatusCode[]
> = {
  EDTK0201: [
    DRAFT_STATUS_CODE.notForAiEntry,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0202: [
    DRAFT_STATUS_CODE.notForAiEntry,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0204: [DRAFT_STATUS_CODE.dbSaved],
  EDTK0303: [
    DRAFT_STATUS_CODE.notForAiEntry,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0304: [DRAFT_STATUS_CODE.dbSaved],
  EDTK0401: [
    DRAFT_STATUS_CODE.notForAiEntry,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0402: [
    DRAFT_STATUS_CODE.notForAiEntry,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgressHalf,
    DRAFT_STATUS_CODE.dbSavedHalf,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0404: [DRAFT_STATUS_CODE.dbSaved],
  EDTK0503: [
    DRAFT_STATUS_CODE.notForAiEntry,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0504: [DRAFT_STATUS_CODE.dbSaved],
  EDTK0603: [
    DRAFT_STATUS_CODE.notForAiEntry,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0604: [DRAFT_STATUS_CODE.dbSaved],
  EDTK0703: [
    DRAFT_STATUS_CODE.notForAiEntry,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0704: [DRAFT_STATUS_CODE.dbSaved],
  EDTK0803: [
    DRAFT_STATUS_CODE.notForAiEntry,
    DRAFT_STATUS_CODE.aiEntryDone,
    DRAFT_STATUS_CODE.reviewInProgress,
    DRAFT_STATUS_CODE.dbSaved,
    DRAFT_STATUS_CODE.insertImage,
    DRAFT_STATUS_CODE.editArticle,
  ],
  EDTK0804: [DRAFT_STATUS_CODE.dbSaved],
};
