import styled from "styled-components";

export const UploadImageModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;
  width: 51.2rem;
  transform: translate(-50%, -50%);
  border-radius: 2rem;
  border: 0.1rem solid transparent;
  background: linear-gradient(#ffffff, #ffffff),
    ${({ theme }) => theme.color.gradient.background1};
  background-origin: padding-box, border-box;
  background-clip: padding-box, border-box;
  box-shadow: 0 0.4rem 0.8rem rgba(7, 68, 162, 0.02);
  padding: 3.2rem 4rem;
`;

export const UploadImageModalContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  text-align: center;
`;

export const UploadImageModalTitle = styled.h3`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.color.neural.gray08};
`;

export const UploadImageModalText = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.96rem;
  color: ${({ theme }) => theme.color.neural.gray06};
`;

export const UploadImageModalButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.4rem;
  width: 100%;

  & > button {
    width: 100%;
    padding: 0;
  }
`;
