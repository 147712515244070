"use client";

import styled, { css } from "styled-components";

export const TabMenuWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 4rem;
`;

export const TabMenuButton = styled.button<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: ${({ theme }) => theme.font.fontFamily.pretendard};
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;

  cursor: pointer;

  &:hover {
    color: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);
    background: var(--Color-Common-Bg-Interactive-Secondary-Hovered, #f0f2f4);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);

    path {
      fill: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);
    }
  }
  &:active {
    color: var(--Color-Common-Text-Interactive-Primary-Pressed, #015d8e);
    background: var(--Color-Common-Bg-Interactive-Secondary-Pressed, #e5e7eb);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16),
      0px 0px 1px 0px rgba(0, 0, 0, 0.12);

    path {
      fill: var(--Color-Common-Text-Interactive-Primary-Pressed, #015d8e);
    }
  }

  &:disabled {
    color: var(--Color-Common-Text-Disabled, #96a1b0);
    background: transparent;
    box-shadow: none;
    cursor: not-allowed;

    path {
      fill: var(--Color-Common-Text-Disabled, #96a1b0);
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
      background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #f0f9ff);

      path {
        fill: var(--Color-Common-Text-Interactive-Primary, #0198e9);
      }
    `}
`;
