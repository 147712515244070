import styled from "styled-components";

import { PRESS_LABEL_INFO_LIST } from "@/components/business/PressClassificationContainer/components/PressLabelInfoList/PressLabelInfoList";
import { LabelCode } from "@/types/code.types";

export const PressTextWrapper = styled.span`
  position: relative;

  &:hover {
    .tooltip {
      display: flex;
      height: 2.7rem;
    }
  }
`;
export const PressLabel = styled.div<{
  entity: LabelCode;
}>`
  all: unset;
  background: ${({ entity }) =>
    PRESS_LABEL_INFO_LIST.filter((item) => item.code === entity)?.[0]
      ?.background};
  outline: ${({ entity }) =>
    PRESS_LABEL_INFO_LIST.filter((item) => item.code === entity)?.[0]?.border};
  padding: 0;
  ${({ onClick }) => (onClick ? "cursor: pointer;" : "")}
  z-index: 20;
`;

export const LabelTooltip = styled.div<{
  entity: LabelCode;
  isEndLocation?: boolean;
  isTopLocation?: boolean;
}>`
  position: absolute;
  top: ${({ isTopLocation }) => (!isTopLocation ? "-3.1rem" : "2.4rem")};
  left: ${({ isEndLocation }) => !isEndLocation && "-0.2rem"};
  right: ${({ isEndLocation }) => isEndLocation && "0"};
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: 0;
  overflow: hidden;
  background: ${({ entity }) =>
    PRESS_LABEL_INFO_LIST.filter((item) => item.code === entity)?.[0]
      ?.tooltipBackground};
  border-radius: 0.4rem;
  z-index: 10;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.16);

  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  padding: 0 0.8rem;

  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
`;
