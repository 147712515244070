import React, { ReactElement } from "react";

import {
  ARTICLE_DETAIL_DEAL_TERMS_MA_GROUP_SHELL_TITLE as GROUP_SHELL_TITLE,
  ARTICLE_CONTENTS_DEAL_TERMS_KEYS as DEAL_TERMS_KEY,
} from "@frontend/common";

const checkIsReactElement = (value: unknown): value is ReactElement => {
  return React.isValidElement(value);
};

/**
 * contingent 테이블의 경우, Payment, Price Per Share, Milestone이
 * 한 행에 모두 표시되어 있어, 이를 조건ㅇ에 따라 분리하여 표시하기 위한 함수
 * 또한, 빈 값을 가진 행은 제거
 */
export const transformContingentTableLayout = (
  targetData: (
    | {
        title: string;
        value: React.ReactNode;
      }[]
    | {
        title: string;
        value: React.ReactNode;
      }
  )[][][],
) => {
  return targetData.map((item) => {
    return item.map((subItem) => {
      if (
        (
          subItem as {
            title: string;
            value: React.ReactNode;
          }[][]
        )?.[0]?.[0]?.title ===
        GROUP_SHELL_TITLE[DEAL_TERMS_KEY.contingentPayment]
      ) {
        return subItem.flatMap((contingencyItems: unknown, index) => {
          if (!contingencyItems) return contingencyItems;
          if (index === 0) return contingencyItems;

          const getElementText = (element: React.ReactNode) => {
            if (checkIsReactElement(element)) {
              return element.props?.children?.[1]?.props?.item?.text;
            }
            return null;
          };

          const isExistContingentPaymentText = getElementText(
            (
              contingencyItems as { title: string; value: React.ReactNode }[]
            )?.[0]?.value,
          );

          const isExistContingentPricePerShareText = getElementText(
            (
              contingencyItems as { title: string; value: React.ReactNode }[]
            )?.[1]?.value,
          );

          const isExistContingentMilestoneText = getElementText(
            (
              contingencyItems as { title: string; value: React.ReactNode }[]
            )?.[2]?.value,
          );

          if (
            !isExistContingentPaymentText &&
            !isExistContingentPricePerShareText &&
            !isExistContingentMilestoneText
          ) {
            return [];
          }

          return [
            [
              (
                contingencyItems as {
                  title: string;
                  value: React.ReactNode;
                }[]
              )?.[0],
              (
                contingencyItems as {
                  title: string;
                  value: React.ReactNode;
                }[]
              )?.[1],
            ],
            [
              (
                contingencyItems as {
                  title: string;
                  value: React.ReactNode;
                }[]
              )?.[2],
            ],
          ];
        });
      }
      return subItem;
    });
  }) as (
    | {
        title: string;
        value: React.ReactNode;
      }[]
    | {
        title: string;
        value: React.ReactNode;
      }
  )[][][];
};
