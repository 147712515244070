import React from "react";

import * as S from "./PrimaryEndpointTag.style";
import { formatPrimaryEndpointTag } from "./utils/formatPrimaryEndpointTag/formatPrimaryEndpointTag";

type Props = {
  primaryEndpointText: string;
  isSwitchTable: boolean;
};

const PrimaryEndpointTag = ({ primaryEndpointText, isSwitchTable }: Props) => {
  return (
    <S.PrimaryEndpointTag $isSwitchTable={isSwitchTable}>
      {formatPrimaryEndpointTag(primaryEndpointText)}
    </S.PrimaryEndpointTag>
  );
};

export default PrimaryEndpointTag;
