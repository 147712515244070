"use client";

import styled from "styled-components";

export const ResizeBar = styled.div`
  width: 100%;
  height: 1.2rem;
  background: var(--Color-Common-Bg-Primary, #fff);

  &:hover {
    cursor: row-resize;
    > div {
      display: flex;
    }
  }

  &:active {
    background: var(--Color-Common-Bg-Tertiary, #cbd0d8);
    cursor: row-resize;
    > div {
      display: flex;
    }
  }
`;

export const ResizeButtonWrapper = styled.div`
  display: none;
  justify-content: center;
  position: relative;
  top: -1rem;
`;
