import { CompanyInfo } from "../../types";

type Props = {
  companyData: CompanyInfo[] | [];
  articleId?: number;
  realTimeCompanyData?: CompanyInfo[];
};

export const checkHasValidStockData = ({
  companyData,
  articleId,
  realTimeCompanyData,
}: Props) => {
  const currentArticleRealTimeData =
    realTimeCompanyData?.filter((company) => company.articleId === articleId) ||
    [];

  const isNotHaveDefaultCompanyData = !companyData || companyData.length === 0;
  const isNotHaveRealTimeCompanyData =
    !currentArticleRealTimeData || currentArticleRealTimeData.length === 0;

  if (isNotHaveDefaultCompanyData && isNotHaveRealTimeCompanyData) return false;

  return [...companyData, ...currentArticleRealTimeData].some(
    (data) => data.roc !== null && data.symbol,
  );
};
