import styled from "styled-components";

import IconChecked from "@images/common/icon-check-publishable-white.svg";

export const PressListFilterLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  gap: 0.8rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.color.neural.gray08};
  cursor: pointer;
`;

export const PressListFilterInput = styled.input`
  all: unset;
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.2rem;
  border: 0.1rem solid ${({ theme }) => theme.color.status.todo};
  overflow: hidden;

  &:checked {
    background-image: url(${IconChecked});
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${({ theme }) => theme.color.status.todo};
  }
`;
