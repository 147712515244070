"use client";

import styled from "styled-components";

interface BackgroundProps {
  $width: string;
  $height: string;
  $borderRadius: string;
}

export const Background = styled.div<BackgroundProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  padding: 0.1rem;
  flex-shrink: 0;
  border-radius: ${({ $borderRadius }) => $borderRadius};
  background-color: #fff;
  border: 1px solid #e5e7eb;

  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
`;
