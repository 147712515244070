"use client";

import styled from "styled-components";

export const KeyAsset = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-4, 0.4rem);
`;

export const DrugName = styled.div`
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
  font-weight: 700;
`;

export const ListItem = styled.div`
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
  position: relative;
  width: 100%;
  padding-left: 1.2rem;

  &::before {
    position: absolute;
    left: 0.4rem;
    top: 0;
    display: block;
    content: "•";
    color: ${({ theme }) => theme.color.neural.gray10};
    font-size: 1rem;
  }
`;
