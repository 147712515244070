"use client";

import styled from "styled-components";

export const HeaderWrapper = styled.header`
  position: sticky;
  top: 0rem;
  width: 100%;
  z-index: 50;

  @media only screen and (min-width: 1px) and (max-width: 989px) {
    top: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
`;
