import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";

import TopFixInfoList from "./components/TopFixInfoList/TopFixInfoList";
import { TopFixInfoData } from "./components/types/topFixInfo.types";
import {
  CLASSIFICATION_NAME_FROM_CODE,
  DEFAULT_TOP_FIX_INFO_VALUE,
  TOP_FIX_INFO_DEFAULT,
} from "./constants/topFixInfo.constants";
import * as S from "./TopFixInfo.style";
import { separateTextAndTranslateCode } from "../../utils/separateTextAndTranslatedCode/separateTextAndTranslatedCode";

interface Props {
  disabledClassificationManual?: boolean;
  hasManualClassificationRoles?: boolean;
}

const TopFixInfo = ({
  disabledClassificationManual,
  hasManualClassificationRoles,
}: Props) => {
  const { data: articleInfo } = useArticleInfoQuery();

  const articleSection = useArticleSection();
  const isClinical = articleSection === "Clinical Trials";
  const isClinicalTrialStatus = articleSection === "Clinical Trial Status";

  const pressTitle = articleInfo?.pressTitle;
  const { code } = separateTextAndTranslateCode(pressTitle);

  const {
    classificationManual,
    classificationAi,
    draftArticleId,
    pressDate,
    manualPressDate,
    companyName,
    mcEditorName,
    ftEditorName,
    ftEditor2Name,
    pbEditorName,
  } = articleInfo || {};

  const PRESS_INFO: TopFixInfoData[] = [
    {
      content: `#${String(draftArticleId)}${code ? ` ${code}` : ""}`,
      ...TOP_FIX_INFO_DEFAULT.draftArticleId,
    },
    {
      content: pressDate,
      subContent: manualPressDate,
      ...TOP_FIX_INFO_DEFAULT.pressDate,
    },
    {
      content: companyName,
      ...TOP_FIX_INFO_DEFAULT.companyName,
    },
  ];

  const CLASSIFICATION_INFO: TopFixInfoData[] = [
    {
      content:
        CLASSIFICATION_NAME_FROM_CODE[classificationAi] ||
        DEFAULT_TOP_FIX_INFO_VALUE,
      ...TOP_FIX_INFO_DEFAULT.classificationAiName,
    },
    {
      content: CLASSIFICATION_NAME_FROM_CODE[classificationManual],
      ...TOP_FIX_INFO_DEFAULT.classificationManualName,
    },
  ];

  const DEFAULT_EDITOR_INFO: TopFixInfoData[] = [
    {
      content: mcEditorName || DEFAULT_TOP_FIX_INFO_VALUE,
      ...TOP_FIX_INFO_DEFAULT.mcEditorName,
    },
    {
      content: ftEditor2Name || DEFAULT_TOP_FIX_INFO_VALUE,
      ...TOP_FIX_INFO_DEFAULT.ftEditor2Name,
    },
    {
      content: pbEditorName || DEFAULT_TOP_FIX_INFO_VALUE,
      ...TOP_FIX_INFO_DEFAULT.pbEditorName,
    },
  ];

  const CLINICAL_TRIAL_STATUS_EDITOR_INFO: TopFixInfoData[] = [
    {
      content: mcEditorName || DEFAULT_TOP_FIX_INFO_VALUE,
      ...TOP_FIX_INFO_DEFAULT.mcEditorName,
    },
    {
      content: ftEditorName || DEFAULT_TOP_FIX_INFO_VALUE,
      ...TOP_FIX_INFO_DEFAULT.ftEditorName,
    },
    {
      content: pbEditorName || DEFAULT_TOP_FIX_INFO_VALUE,
      ...TOP_FIX_INFO_DEFAULT.pbEditorName,
    },
  ];

  const CLINICAL_EDITOR_INFO: TopFixInfoData[] = [
    {
      content: mcEditorName || DEFAULT_TOP_FIX_INFO_VALUE,
      ...TOP_FIX_INFO_DEFAULT.mcEditorName,
    },
    {
      content: ftEditorName || DEFAULT_TOP_FIX_INFO_VALUE,
      ...TOP_FIX_INFO_DEFAULT.ftEditorName,
    },
    {
      content: ftEditor2Name || DEFAULT_TOP_FIX_INFO_VALUE,
      ...TOP_FIX_INFO_DEFAULT.ftEditor2Name,
    },
    {
      content: pbEditorName || DEFAULT_TOP_FIX_INFO_VALUE,
      ...TOP_FIX_INFO_DEFAULT.pbEditorName,
    },
  ];

  const targetEditorInfo = () => {
    if (isClinicalTrialStatus) {
      return CLINICAL_TRIAL_STATUS_EDITOR_INFO;
    }
    if (isClinical) {
      return CLINICAL_EDITOR_INFO;
    }
    return DEFAULT_EDITOR_INFO;
  };

  return (
    <S.TopFixInfoWrapper>
      <S.ListWrapper $minWidth="24rem">
        <TopFixInfoList list={PRESS_INFO} maxWidth="none" minWidth="none" />
      </S.ListWrapper>
      <S.ListWrapper $maxWidth="48rem" $minWidth="24rem">
        <TopFixInfoList
          list={CLASSIFICATION_INFO}
          maxWidth="48rem"
          minWidth="24rem"
          disabledClassificationManual={disabledClassificationManual}
          hasManualClassificationRoles={hasManualClassificationRoles}
        />
      </S.ListWrapper>
      <S.ListWrapper $maxWidth={`${targetEditorInfo().length * 20}rem`}>
        <TopFixInfoList
          list={targetEditorInfo()}
          maxWidth="80rem"
          minWidth="none"
        />
      </S.ListWrapper>
    </S.TopFixInfoWrapper>
  );
};

export default TopFixInfo;
