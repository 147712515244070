"use client";

import styled, { css } from "styled-components";

export const PrimaryEndpointTag = styled.span<{ $isSwitchTable: boolean }>`
  position: absolute;

  ${({ $isSwitchTable }) =>
    $isSwitchTable
      ? css`
          top: 0;
          left: 0;
        `
      : css`
          top: 0rem;
          left: -0.2rem;
        `}

  display: flex;
  align-items: flex-start;
  width: auto;
  min-width: 0;
  height: auto;
  padding: 0.2rem 0.8rem !important;

  border-radius: 0rem 0rem 0.4rem 0rem;
  background: linear-gradient(92deg, #0b6cff 25.97%, #25a6e4 100%);

  color: ${({ theme }) => theme.color.neural.gray02};
  font-family: var(--font-pt-serif);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  box-sizing: border-box;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    font-size: 1rem;
    width: fit-content;
    height: fit-content;
  }
`;
