import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  getShellColumnSize,
  isAddDeleteShell,
} from "@/components/business/FillInTableContainer/shared/utils/getOptionsFromData";
import { useHandleClinicalTrialsEndpointsTableShell } from "@FillInTableContainer/components/FillInTable/components/TableCreator/components/ClinicalTrialsTableCreator/components/ClinicalTrialsTableCreatorGroupTable/components/FillInTableTableCreatorTable/components/ClinicalTrialsTableCreatorGroupShell/components/ClinicalTrialsTableCreatorShell/hooks/useHandleClinicalTrialsEndpointsTableShell";
import { useScroll } from "@FillInTableContainer/components/FillInTable/hooks/useScroll";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import ColumnSelectButton from "@FillInTableContainer/shared/components/ColumnSelectButton/ColumnSelectButton";
import ShellDividerPlusBar from "@FillInTableContainer/shared/components/ShellDividerPlusBar/ShellDividerPlusBar";
import {
  DEFAULT_FOCUS_STATUS,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  DEFAULT_VALIDATED_INFO,
  useValidatedInTableCreator,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { GroupTableKeyType } from "@FillInTableContainer/shared/types/tableCreator.types";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";

import TitleShellTextField from "./components/TitleShellTextField/TitleShellTextField";
import * as S from "./FillInTableTableCreatorTitleShell.style";

import type {
  ClinicalTrialsSection,
  EntryDataValueAndOptions,
} from "@/types/clinicalTrials.types";

interface Props {
  positionPropsInfo: {
    groupTableKey: GroupTableKeyType;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number;
    tableKey: string | null;
    tableIndex: number;
    shellKey: string;
    shellIndex: number;
  };
  valueInfo: EntryDataValueAndOptions;
  shellCount: number;
}

const FillInTableTableCreatorTitleShell = ({
  positionPropsInfo,
  valueInfo: shellInfo,
  shellCount,
}: Props) => {
  const {
    groupTableKey,
    groupTableIndex,
    tableKey,
    tableIndex,
    groupShellKey,
    groupShellIndex,
    shellKey,
    shellIndex,
  } = positionPropsInfo;

  const articleSection = useArticleSection();
  const { register } = useScroll();

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();

  const { validatedInfo, changeValidatedInfo } = useValidatedInTableCreator();
  const [marginBottom, setMarginBottom] = useState<number>(0);
  const titleShellRef = useRef<HTMLDivElement>(null);

  const changeTextFieldMarginBottom = (margin: number) => {
    setMarginBottom(margin);
  };

  const {
    handleAddEndpointsTableShell,
    handleClickToDeleteEndpointsTableShell,
    handleClickToDeleteEndpointsTableColumn,
    handleKeyDownToDeleteEndpointsTableColumn,
    handleKeyDownToDeleteEndpointsTableShell,
  } = useHandleClinicalTrialsEndpointsTableShell({
    ...positionPropsInfo,
    groupTableKey: groupShellKey as ClinicalTrialsSection,
    shellCount: 1,
  });

  const positionInfo = {
    groupTableKey: groupTableKey as GroupTableKeyType,
    groupTableIndex,
    tableKey,
    tableIndex,
    groupShellKey,
    groupShellIndex: null,
    subGroupShellKey: null,
    subGroupShellIndex: null,
    shellKey,
    shellIndex,
    valueIndex: 0,
  };

  const isFocusedShell =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === groupShellKey &&
    info.shellKey === shellKey &&
    info.shellIndex === shellIndex &&
    info.valueIndex === null &&
    focusStatus.container === "shell";

  const isFocusedTextfield =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === groupShellKey &&
    info.shellKey === shellKey &&
    info.shellIndex === shellIndex &&
    info.valueIndex === 0;

  const isFocusedColumn =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === groupShellKey &&
    info.shellKey === shellKey &&
    info.shellIndex === shellIndex &&
    info.valueIndex === null;

  const isAlertShell =
    validatedInfo.filter(
      (validatedItem) =>
        validatedItem.groupTableKey === groupTableKey &&
        validatedItem.groupTableIndex === groupTableIndex &&
        validatedItem.tableIndex === tableIndex &&
        validatedItem.groupShellKey === groupShellKey &&
        validatedItem.groupShellIndex === groupShellIndex &&
        validatedItem.shellKey === shellKey &&
        validatedItem.shellIndex === shellIndex &&
        validatedItem.valueIndex === null &&
        validatedItem.type === "alert" &&
        validatedItem.container === "shell",
    ).length > 0;

  const isLastShell = shellCount === shellIndex + 1;

  const handleFocusColumn = () => {
    changeInfo({
      ...positionPropsInfo,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      valueIndex: null,
    });
    changeFocusStatus({ type: "clear", container: "column" });
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleFocusShell = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    changeInfo({
      ...positionPropsInfo,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      valueIndex: null,
    });
    changeFocusStatus({ type: "clear", container: "shell" });
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleFocusOut = useCallback(() => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  }, [changeFocusStatus, changeInfo]);

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocusedShell || isFocusedColumn || isFocusedTextfield) {
        const target = e.target as HTMLElement;
        const titleShellFocus = titleShellRef.current?.contains(target);

        if (titleShellFocus) return;

        handleFocusOut();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleFocusOut, isFocusedShell, isFocusedColumn, isFocusedTextfield]);

  useEffect(() => {
    if (focusStatus.container !== "shell") return;
    window.addEventListener(
      "keydown",
      handleKeyDownToDeleteEndpointsTableShell,
    );

    return () => {
      window.removeEventListener(
        "keydown",
        handleKeyDownToDeleteEndpointsTableShell,
      );
    };
  }, [focusStatus, handleKeyDownToDeleteEndpointsTableShell]);

  useEffect(() => {
    if (focusStatus.container !== "column") return;
    window.addEventListener(
      "keydown",
      handleKeyDownToDeleteEndpointsTableColumn,
    );

    return () => {
      window.removeEventListener(
        "keydown",
        handleKeyDownToDeleteEndpointsTableColumn,
      );
    };
  }, [focusStatus, handleKeyDownToDeleteEndpointsTableColumn]);
  return (
    <S.RelativeWrapper ref={titleShellRef}>
      {isAddDeleteShell(
        articleSection,
        shellKey === "Group Name" ? "Endpoint Group Information" : shellKey,
      ) && (
        <ShellDividerPlusBar
          isPositionRight={false}
          onClick={() =>
            handleAddEndpointsTableShell({
              tableIndexParameter: tableIndex,
              groupShellIndexParameter: groupShellIndex,
              shellIndexParameter: shellIndex,
              shellKeyParameter:
                shellKey === "Group Name"
                  ? "Endpoint Group Information"
                  : shellKey,
            })
          }
        />
      )}
      <ColumnSelectButton
        top="-1.6rem"
        left="0"
        clickEvent={handleFocusColumn}
      />
      {(isFocusedColumn || isFocusedShell) &&
        focusStatus.container === "column" && (
          <S.ColumnDeleteButton
            onClick={handleClickToDeleteEndpointsTableColumn}
          >
            <img src={IconXMark} alt="" />
            {focusStatus.type === "clear" && "Clear"}
            {focusStatus.type === "delete" && "Delete"}
            &nbsp;{focusStatus.container === "column" && "Column"}
          </S.ColumnDeleteButton>
        )}
      {(isFocusedColumn || isFocusedShell) &&
        focusStatus.container === "shell" && (
          <S.ColumnDeleteButton
            className="button-for-shell"
            onClick={handleClickToDeleteEndpointsTableShell}
          >
            <img src={IconXMark} alt="" />
            {focusStatus.type === "clear" && "Clear"}
          </S.ColumnDeleteButton>
        )}
      <S.TitleShellWrapper
        onClick={handleFocusShell}
        onFocus={(event: React.FocusEvent<HTMLDivElement>) =>
          event.stopPropagation()
        }
        column={getShellColumnSize(articleSection, shellKey)}
        isFocused={isFocusedShell || isFocusedColumn || isFocusedTextfield}
        isAlertShell={isAlertShell}
        isDeleteShell={isFocusedColumn && focusStatus.type === "delete"}
        marginBottom={marginBottom}
        {...register(
          `${groupTableKey}__${groupTableIndex}__${tableKey}__${tableIndex}__${groupShellKey}__${groupShellIndex}__${shellKey}__${shellIndex}`,
        )}
      >
        <TitleShellTextField
          isFocused={isFocusedTextfield}
          positionInfo={positionInfo}
          shellInfo={shellInfo}
          changeTextFieldMarginBottom={changeTextFieldMarginBottom}
        />
      </S.TitleShellWrapper>
      {isAddDeleteShell(
        articleSection,
        shellKey === "Group Name" ? "Endpoint Group Information" : shellKey,
      ) &&
        isLastShell && (
          <ShellDividerPlusBar
            isPositionRight
            onClick={() =>
              handleAddEndpointsTableShell({
                tableIndexParameter: tableIndex,
                groupShellIndexParameter: groupShellIndex,
                shellIndexParameter: shellIndex + 1,
                shellKeyParameter:
                  shellKey === "Group Name"
                    ? "Endpoint Group Information"
                    : shellKey,
              })
            }
          />
        )}
    </S.RelativeWrapper>
  );
};

export default FillInTableTableCreatorTitleShell;
