import { ArticleEntryData } from "@/types/articleEntryData.types";

import {
  ClinicalTrialsMainLabelNames,
  DealsMainLabelNames,
  ExpeditedProgramMainLabelNames,
  FormattedMainLabelListType,
  MainLabelInfoData,
  MainLabelListForUpdateType,
  MainLabelListType,
  MainLabelNames,
  RegulatoryMainLabelNames,
  VentureCapitalMainLabelNames,
} from "../../types/articleMainLabel.types";

export abstract class ArticleMainLabelList {
  public abstract getArticleMainLabelList(): MainLabelListType;

  public abstract getFormattedMainLabelList(
    defaultMainLabelList: MainLabelInfoData,
    mainLabelList: MainLabelListType,
  ): FormattedMainLabelListType;

  public abstract getTargetOptionList(
    labelName: MainLabelNames,
    entryData: ArticleEntryData,
  ): string[];

  public abstract createMainLabelList(
    defaultMainLabelList: MainLabelInfoData,
    entryData: ArticleEntryData,
  ): MainLabelListForUpdateType;

  public abstract entryDataCompatibilityWithMainLabelsData(
    defaultMainLabelList: MainLabelInfoData,
    mainLabelList: MainLabelListType,
    entryData: ArticleEntryData,
  ):
    | Record<ClinicalTrialsMainLabelNames, boolean>
    | Record<DealsMainLabelNames, boolean>
    | Record<RegulatoryMainLabelNames, boolean>
    | Record<ExpeditedProgramMainLabelNames, boolean>
    | Record<VentureCapitalMainLabelNames, boolean>;

  public abstract mainLabelListForEntryDataCompatibility(
    defaultMainLabelList: MainLabelInfoData,
    mainLabelList: MainLabelListType,
    entryData: ArticleEntryData,
  ): MainLabelListForUpdateType | null;
}
