export const returnGroupShellTitleItemList = (groupShellTitle: string) => {
  return [
    {
      title: groupShellTitle,
      text: "",
      labels: [],
      refKey: "",
      displayForTitle: true,
    },
  ];
};
