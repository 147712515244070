import React from "react";

import Header from "@organisms/Header/Header";

import LayoutCenter from "./LayoutCenter";
import LayoutFull from "./LayoutFull";
import LayoutResponsiveCenter from "./LayoutResponsiveCenter";
import LayoutWide from "./LayoutWide";

import type { LayoutType } from "@routes/pageList";

interface Props {
  layout: LayoutType;
  header: boolean;
  children: React.ReactNode;
}

const LAYOUTS = {
  none: React.Fragment,
  center: LayoutCenter,
  wide: LayoutWide,
  full: LayoutFull,
  responsive: LayoutResponsiveCenter,
};

const Layout = ({ layout, children, header }: Props) => {
  const Main = LAYOUTS[layout];

  return (
    <>
      {header && <Header />}
      <Main>{children}</Main>
    </>
  );
};

export default Layout;
