import { removeModalityEtc } from "../../../utils";
import { MODALITY_KEY_FROM_CATEGORY } from "../constants/modality.constants";

export const matchModalityKeyType = (
  modalityCategoryInfo: Record<string, string>,
  modality: string,
) => {
  const formatModality = removeModalityEtc(modality).trim();
  const modalityCategory = modalityCategoryInfo[formatModality];

  return MODALITY_KEY_FROM_CATEGORY[modalityCategory] || "empty";
};

export const matchModalityKeyTypeForEditArticle = (
  modalityCategory: string,
) => {
  if (!modalityCategory) return "empty";
  return MODALITY_KEY_FROM_CATEGORY[modalityCategory] || "empty";
};
