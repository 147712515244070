import Modal from "@atoms/Modal/Modal";
import SelectBox from "@atoms/SelectBox/SelectBox";
import useModal from "@hooks/useModal";
import IconFilterClassification from "@images/pressList/icon-filter-classification-white.svg";
import IconFilterStatus from "@images/pressList/icon-filter-status-white.svg";
import { usePressListFilterState } from "@stores/pressList";

import ClassificationFilter from "./components/ClassificationFilter/ClassificationFilter";
import PressListCheckboxFilter from "./components/PressListCheckboxFilter/PressListCheckboxFilter";
import PressListSearch from "./components/PressListSearch/PressListSearch";
import PressListSearchResult from "./components/PressListSearchResult/PressListSearchResult";
import StatusFilter from "./components/StatusFilter/StatusFilter";
import * as S from "./PressListTableFilter.style";

const LIMIT_NUMBER_LIST = [20, 40, 60, 80, 100];

const PressListTableFilter = () => {
  const [pressListFilter, setPressListFilter] = usePressListFilterState();

  const {
    pageLimit,
    confirmClassificationAi,
    confirmClassificationManual,
    status,
    publishable,
    isTranslated,
  } = pressListFilter;

  const {
    isModal: isClassificationFilterModalOpen,
    openModal: openClassificationFilterModal,
    closeModal: closeClassificationFilterModal,
  } = useModal();
  const {
    isModal: isStatusFilterModalOpen,
    openModal: openStatusFilterModal,
    closeModal: closeStatusFilterModal,
  } = useModal();

  const selectedClassificationNumber =
    confirmClassificationAi.length + confirmClassificationManual.length;
  const selectedStatusNumber = status.length;

  const handleSelectLimitNumber = (value: string) => {
    setPressListFilter({
      ...pressListFilter,
      pageLimit: Number(value),
      page: 1,
    });
  };

  const handleCheckPublishable = (checked?: "true" | "false") => {
    setPressListFilter({
      ...pressListFilter,
      publishable: checked === "true" ? "false" : "true",
    });
  };

  const handleCheckTranslated = (checked?: "true" | "false") => {
    setPressListFilter({
      ...pressListFilter,
      isTranslated: checked === "true" ? "false" : "true",
    });
  };

  return (
    <>
      <S.PressListTableFilterWrapper>
        <PressListSearch />
        <S.TableFilterColumn>
          <S.FilterButton
            buttonWidth={14.8}
            buttonSize="xSmall"
            buttonStyle="solidSky"
            onClick={openClassificationFilterModal}
          >
            <img src={IconFilterClassification} alt="classification filter" />
            Classification Filter
            {selectedClassificationNumber > 0 &&
              `(${selectedClassificationNumber})`}
          </S.FilterButton>
          <S.FilterButton
            buttonWidth={10.8}
            buttonSize="xSmall"
            buttonStyle="solidBlueGreen"
            onClick={openStatusFilterModal}
          >
            <img src={IconFilterStatus} alt="status filter" />
            Status Filter
            {selectedStatusNumber > 0 && `(${selectedStatusNumber})`}
          </S.FilterButton>

          <PressListCheckboxFilter
            filterText="Publishable Press"
            isChecked={publishable === "true"}
            onClick={() => handleCheckPublishable(publishable)}
          />
          <PressListCheckboxFilter
            filterText="Translated Press"
            isChecked={isTranslated === "true"}
            onClick={() => handleCheckTranslated(isTranslated)}
          />

          <SelectBox
            optionList={LIMIT_NUMBER_LIST}
            defaultText=" accounts per page"
            selectValue={pageLimit}
            onChangeOption={handleSelectLimitNumber}
          />
          {isClassificationFilterModalOpen && (
            <Modal closeModal={closeClassificationFilterModal}>
              <ClassificationFilter
                closeModal={closeClassificationFilterModal}
              />
            </Modal>
          )}
          {isStatusFilterModalOpen && (
            <Modal closeModal={closeStatusFilterModal}>
              <StatusFilter closeModal={closeStatusFilterModal} />
            </Modal>
          )}
        </S.TableFilterColumn>
      </S.PressListTableFilterWrapper>
      <PressListSearchResult />
    </>
  );
};

export default PressListTableFilter;
