import React from "react";

import * as S from "./ClinicalTrialsIdentifierValidationInfo.style";
import { CLINICAL_TRIALS_IDENTIFIER_VALIDATION_INFOS } from "./constants/clinicalTrialsIdentifiers.constants";

interface Props {
  closeValidationInfo: () => void;
}

const IconClose = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9744 7.08551C16.2673 6.79261 16.2673 6.31774 15.9744 6.02485C15.6815 5.73195 15.2066 5.73195 14.9137 6.02485L10.9996 9.93896L7.08551 6.02485C6.79261 5.73195 6.31774 5.73195 6.02485 6.02485C5.73195 6.31774 5.73195 6.79261 6.02485 7.08551L9.93896 10.9996L6.02485 14.9137C5.73195 15.2066 5.73195 15.6815 6.02485 15.9744C6.31774 16.2673 6.79261 16.2673 7.08551 15.9744L10.9996 12.0603L14.9137 15.9744C15.2066 16.2673 15.6815 16.2673 15.9744 15.9744C16.2673 15.6815 16.2673 15.2066 15.9744 14.9137L12.0603 10.9996L15.9744 7.08551Z"
      fill="#2A2F37"
    />
  </svg>
);

const ClinicalTrialsIdentifierValidationInfo = ({
  closeValidationInfo,
}: Props) => {
  const handleCloseValidationInfo = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    closeValidationInfo();
  };
  return (
    <S.ValidationInfoWrapper
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <S.CloseButton type="button" onClick={handleCloseValidationInfo}>
        {IconClose}
      </S.CloseButton>
      <S.ValidationTitle>Clinical Trial Codes</S.ValidationTitle>
      {CLINICAL_TRIALS_IDENTIFIER_VALIDATION_INFOS.map((item) => (
        <S.ValidationItem key={item.format}>
          <S.FormatText>{item.format}</S.FormatText>
          <S.CountryText>/ {item.country}</S.CountryText>
        </S.ValidationItem>
      ))}
    </S.ValidationInfoWrapper>
  );
};

export default ClinicalTrialsIdentifierValidationInfo;
