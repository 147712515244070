import styled, { css } from "styled-components";

export const GroupShellTitleWrapper = styled.div`
  background-color: white;
`;

export const OutsideWrapper = styled.div<{
  isSeparatedTitleRow?: boolean;
  isFirstClinicalTrialsDesignTwoGroupShell?: boolean;
  isFocused: boolean;
  isClearGroupShell: boolean;
  isDeleteGroupShell?: boolean;
  isAlertGroupShell: boolean;
  isDragging?: boolean;
}>`
  position: relative;
  border: 2px solid transparent;

  ${({ isSeparatedTitleRow }) =>
    isSeparatedTitleRow &&
    css`
      background-color: ${({ theme }) => theme.color.neural.white};
    `};

  ${({ isFirstClinicalTrialsDesignTwoGroupShell }) =>
    isFirstClinicalTrialsDesignTwoGroupShell &&
    css`
      margin-top: 1rem;
    `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      border: 2px solid
        var(--Color-Common-Border-Interactive-Primary-Subtle-Pressed, #76cffe);
      background: var(
        --Color-Common-Bg-Interactive-Primary-Subtle-Pressed,
        #a4dfff
      );
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.16);
    `}

  ${({ isDeleteGroupShell }) =>
    isDeleteGroupShell &&
    css`
      border: 2px solid var(--Color-Border-Error, #f9a9d0);
      background: var(--Color-Bg-Error, #fcd4e7);
    `};

  ${({ isDragging }) =>
    isDragging &&
    css`
      border: 2px solid var(--Color-Border-Draft, #b3eaef);
      background: var(--Color-Common-Bg-Primary, #fff);

      /* Elevation/Light/Shadow4 */
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
        0px 0px 2px 0px rgba(0, 0, 0, 0.12);
    `}
`;

export const DefaultDraggableGroupShellWrapper = styled.div<{
  isTargetDraggingTable: boolean;
}>`
  opacity: 1;
  ${({ isTargetDraggingTable }) =>
    isTargetDraggingTable &&
    css`
      opacity: 0.5;
    `}
`;
