import React, { HTMLAttributes } from "react";

import TooltipBreakImage from "./components/TooltipBreakImage/TooltipBreakImage";
import * as S from "./Tooltip.style";
import TooltipBase from "../TooltipBase/TooltipBase";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  position: "top" | "bottom" | "left" | "right";
  alignment: "left" | "center" | "right";
  padding?: string;
}

const Tooltip = ({
  children,
  position,
  alignment,
  padding,
  ...props
}: Props) => {
  return (
    <S.TooltipWrapper>
      <TooltipBase padding={padding}>{children}</TooltipBase>
      <S.BreakImageWrapper position={position} alignment={alignment} {...props}>
        <TooltipBreakImage />
      </S.BreakImageWrapper>
    </S.TooltipWrapper>
  );
};

export default Tooltip;
