import React, { createContext, useContext, useMemo, useState } from "react";

import { GroupTableKeyType } from "../types/tableCreator.types";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

export type TableCreatorHistoryPosition = {
  groupTableKey: GroupTableKeyType | "";
  groupTableIndex: number;
  tableKey: string | null;
  tableIndex: number;
  groupShellSectionKey?: string | null;
  groupShellSectionIndex?: number | null;
  groupShellKey: string | null;
  groupShellIndex: number | null;
  subGroupShellIndex?: number | null;
  subGroupShellKey?: string | null;
  shellKey: string | null;
  shellIndex: number | null;
  valueIndex: number | null;
  value: string | null;
};

export type TableCreatorHistory = {
  entryData: ArticleEntryData;
  changedDataInfo: {
    target:
      | "value"
      | "shell"
      | "groupShell"
      | "groupShellSection"
      | "table"
      | "groupTable"
      | "subGroupShell"
      | "column"
      | "all";
    position: TableCreatorHistoryPosition;
    action: "clear" | "delete" | "add" | "update" | "initial";
  };
} | null;

export const DEFAULT_TABLE_CREATOR_HISTORY_POSITION: TableCreatorHistoryPosition =
  {
    groupTableKey: "",
    groupTableIndex: 0,
    tableKey: null,
    tableIndex: 0,
    groupShellSectionKey: null,
    groupShellSectionIndex: null,
    groupShellKey: null,
    groupShellIndex: null,
    subGroupShellIndex: null,
    subGroupShellKey: null,
    shellKey: null,
    shellIndex: null,
    value: null,
    valueIndex: null,
  };

export const DEFAULT_TABLE_CREATOR_HISTORY: TableCreatorHistory = {
  entryData: {},
  changedDataInfo: {
    target: "value",
    position: DEFAULT_TABLE_CREATOR_HISTORY_POSITION,
    action: "add",
  },
};

export const TableCreatorHistoryContext = createContext<{
  currentHistory: TableCreatorHistory;
  changeCurrentHistory: (newStatus: TableCreatorHistory) => void;
}>({
  currentHistory: null,
  changeCurrentHistory: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const TableCreatorHistoryProvider = ({ children }: Props) => {
  const [currentHistory, setCurrentHistory] =
    useState<TableCreatorHistory>(null);

  const changeCurrentHistory = (newStatus: TableCreatorHistory): void => {
    setCurrentHistory(newStatus);
  };

  const currentHistoryWrapper = useMemo(
    () => ({ currentHistory, changeCurrentHistory }),
    [currentHistory],
  );
  return (
    <TableCreatorHistoryContext.Provider value={currentHistoryWrapper}>
      {children}
    </TableCreatorHistoryContext.Provider>
  );
};

export const useTableCreatorCurrentHistory = () => {
  const { currentHistory, changeCurrentHistory } = useContext(
    TableCreatorHistoryContext,
  );

  return { currentHistory, changeCurrentHistory };
};
