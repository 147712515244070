"use client";

import styled from "styled-components";

export const ParagraphButtonWrapper = styled.button<{
  $top?: number;
  $left?: number;
}>`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 2.8rem;
  height: 2.8rem;

  top: ${({ $top }) => ($top ? `${$top}px` : "48px")};
  left: ${({ $left }) => ($left ? `${$left}px` : "-40px")};
  cursor: pointer;
  padding: 0;
`;
