"use client";
import styled from "styled-components";

export const ActiveFlag = styled.div`
  height: 2rem;
  color: var(--Color-Common-Text-Success, #0198e9);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  padding: 0 0.6rem;
  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Success-Subtle, #e6f6ff);

  &.status-active {
    color: var(--Color-Common-Text-Success, #0198e9);
    background: var(--Color-Common-Bg-Success-Subtle, #e6f6ff);
  }
  &.status-inactive {
  }
`;
