import styled from "styled-components";

export const PaginationWrapper = styled.div`
  position: fixed;
  bottom: 8rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding: var(--Spacing-8, 0.8rem) var(--Spacing-16, 1.6rem);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--Border-Radius-circle, 99999.9rem);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Primary, #fff);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16),
    0px 0px 4px 0px rgba(0, 0, 0, 0.12);
  z-index: 2;
`;
