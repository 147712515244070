import { useRef, useCallback, useEffect } from "react";

import { TableCreatorHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import { useUnresolvedCommentInfoWithPosition } from "@FillInTableContainer/shared/context/UnresolvedCommentInfoWithPositionContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export const useUndoRedo = ({ state }: { state: TableCreatorHistory }) => {
  const [, setEntryData] = useEntryDataState(getPressIdFromURL());
  const canUpdate = useRef(true);
  const history = useRef<TableCreatorHistory[]>([]);
  const currentIndex = useRef(-1);

  const { unresolvedCommentInfos } = useUnresolvedCommentInfoWithPosition();

  const checkDisabledUndoRedo = useCallback(
    (nextIndex: number) => {
      if (history.current[nextIndex]?.changedDataInfo.action !== "add")
        return false;

      const target = history.current[nextIndex]?.changedDataInfo.target;
      const targetPosition =
        history.current[nextIndex]?.changedDataInfo.position;

      if (target === "shell") {
        return (
          unresolvedCommentInfos.filter(
            (info) =>
              targetPosition?.groupShellKey === info.groupShellKey &&
              targetPosition?.shellKey === info.shellKey &&
              targetPosition?.tableKey === info.tableKey &&
              targetPosition?.groupTableKey === info.groupTableKey &&
              targetPosition?.groupShellIndex === info.groupShellIndex &&
              targetPosition?.shellIndex === info.shellIndex &&
              targetPosition?.tableIndex === info.tableIndex,
          ).length > 0
        );
      }
      if (target === "groupShell") {
        return (
          unresolvedCommentInfos.filter(
            (info) =>
              targetPosition?.groupTableKey === info.groupTableKey &&
              targetPosition?.tableKey === info.tableKey &&
              targetPosition?.tableIndex === info.tableIndex &&
              targetPosition?.groupShellKey === info.groupShellKey &&
              targetPosition?.groupShellIndex === info.groupShellIndex,
          ).length > 0
        );
      }
      if (target === "table") {
        return (
          unresolvedCommentInfos.filter(
            (info) =>
              targetPosition?.groupTableKey === info.groupTableKey &&
              targetPosition?.tableKey === info.tableKey &&
              targetPosition?.tableIndex === info.tableIndex,
          ).length > 0
        );
      }
      return true;
    },
    [unresolvedCommentInfos],
  );

  const undo = useCallback(() => {
    if (currentIndex.current <= 0) return;
    const nextIndex = currentIndex.current - 1;

    if (checkDisabledUndoRedo(nextIndex)) return;
    currentIndex.current = nextIndex;
    setEntryData(history.current[nextIndex]?.entryData ?? {});
    canUpdate.current = false;
  }, [checkDisabledUndoRedo, setEntryData]);

  const redo = useCallback(() => {
    if (currentIndex.current >= history.current.length - 1) return;
    const nextIndex = currentIndex.current + 1;

    if (checkDisabledUndoRedo(nextIndex)) return;
    currentIndex.current = nextIndex;
    setEntryData(history.current[nextIndex]?.entryData ?? {});
    canUpdate.current = false;
  }, [checkDisabledUndoRedo, setEntryData]);

  const updatePresent = useCallback((newState: TableCreatorHistory) => {
    if (newState === null) return;
    if (canUpdate.current) {
      history.current.length = currentIndex.current + 1;
      history.current.push(newState);
      currentIndex.current = history.current.length - 1;
    } else {
      canUpdate.current = true;
    }
  }, []);

  const isDisabledRedo =
    checkDisabledUndoRedo(currentIndex.current + 1) ||
    currentIndex.current >= history.current.length - 1;
  const isDisabledUndo =
    checkDisabledUndoRedo(currentIndex.current - 1) ||
    currentIndex.current <= 0;

  const resetHistory = useCallback(() => {
    history.current = [];
    currentIndex.current = -1;
  }, []);

  useEffect(() => {
    updatePresent(state);
  }, [state, updatePresent]);

  return {
    undo,
    redo,
    isDisabledUndo,
    isDisabledRedo,
    resetHistory,
  };
};
