import {
  ParagraphSectionLayout,
  ParagraphTitle,
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
  ARTICLE_CONTENTS_FINANCING_INFORMATION_PARAGRAPH_TITLE as PARAGRAPH_TITLE,
  ARTICLE_CONTENTS_FINANCING_INFORMATION_PARAGRAPH_GROUP_KEY as PARAGRAPH_GROUP_KEY,
  ARTICLE_CONTENTS_FINANCING_INFORMATION_SHELL_KEYS as SHELL_KEYS,
  ARTICLE_CONTENTS_FINANCING_INFORMATION_SHELL_TITLE as SHELL_TITLE,
  ARTICLE_CONTENTS_FINANCING_INFORMATION_PARAGRAPH_CODE as PARAGRAPH_CODE,
  ARTICLE_CONTENTS_FINANCING_INFORMATION_SECTION_CODE as SECTION_CODE,
} from "@frontend/common";

import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import { useUpdateParagraph } from "@EditArticleContainer/shared/hooks/useUpdateParagraph";
import {
  REF_CODE_VENTURE_CAPITAL_AMOUNT,
  REF_CODE_VENTURE_CAPITAL_FINANCING_INFORMATION_DETAIL,
  REF_CODE_VENTURE_CAPITAL_FINANCING_PURPOSE,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

import * as S from "./VentureCapitalParagraphFinancingInformation.styles";

const COMMON_EDITABLE_TEXT_STYLE = {
  padding: "0.8rem",
  borderRadius: "0",
};
const VALUE_SHELL_PADDING = "0.2rem";

const VentureCapitalParagraphFinancingInformation = () => {
  const paragraph = useParagraphEditedData(PARAGRAPH_CODE);
  const [updateParagraph, loadingIndex, updateKeyMap] = useUpdateParagraph({
    paragraphCode: PARAGRAPH_CODE,
    sectionCode: SECTION_CODE,
  });

  const amountText = createMultiLineText({
    textList: paragraph.text[PARAGRAPH_GROUP_KEY][SHELL_KEYS.amount].value.map(
      (shell) => shell.text ?? "",
    ),
    parseString: (text) => `- ${text?.trim()}`,
  });
  const financingInformationPurposeText = createMultiLineText({
    textList: paragraph.text[PARAGRAPH_GROUP_KEY][
      SHELL_KEYS.financingPurpose
    ].value.map((shell) => shell.text ?? ""),
    parseString: (text) => `- ${text?.trim()}`,
  });
  const financingInformationDetailText = createMultiLineText({
    textList: paragraph.text[PARAGRAPH_GROUP_KEY][
      SHELL_KEYS.financingInformationDetail
    ].value.map((shell) => shell.text ?? ""),
    parseString: (text) => `- ${text?.trim()}`,
  });

  const financingInformationTableData = [
    {
      title: SHELL_TITLE.amount,
      text: amountText,
      refKey: REF_CODE_VENTURE_CAPITAL_AMOUNT,
    },
    {
      title: SHELL_TITLE.financingPurpose,
      text: financingInformationPurposeText,
      refKey: REF_CODE_VENTURE_CAPITAL_FINANCING_PURPOSE,
    },
    {
      title: SHELL_TITLE.financingInformationDetail,
      text: financingInformationDetailText,
      refKey: REF_CODE_VENTURE_CAPITAL_FINANCING_INFORMATION_DETAIL,
    },
  ].filter((data) => data.text.trim() !== "");

  if (financingInformationTableData.length === 0) {
    return null;
  }
  return (
    <ParagraphSectionLayout>
      <ParagraphTitle title={PARAGRAPH_TITLE} />
      <S.ParagraphAdditionalInformationWrapper key={updateKeyMap.get(0)}>
        <S.ParagraphAdditionalInformationUpdateButton
          onClick={() => updateParagraph(0)}
          disabled={loadingIndex === 0}
        />
        <TableContainer>
          {financingInformationTableData?.map((data) => (
            <>
              <TableRowContainer>
                <TableShellTitle>{data.title}</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  <EditableTextContainer
                    refKey={data.refKey}
                    item={{
                      text: data.text,
                      labels: [],
                    }}
                    canTextEdit
                    style={COMMON_EDITABLE_TEXT_STYLE}
                  />
                </TableShellValue>
              </TableRowContainer>
            </>
          ))}
        </TableContainer>
      </S.ParagraphAdditionalInformationWrapper>
    </ParagraphSectionLayout>
  );
};

export default VentureCapitalParagraphFinancingInformation;
