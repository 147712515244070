import IconDropDown from "@images/mainLabel/icon-dropdown.svg";

import MainLabelRadioDropDown from "./components/MainLabelRadioDropDown/MainLabelRadioDropDown";
import * as S from "./SingleEditableMainLabel.style";
import { MAIN_LABEL_ENTITY_TO_COLOR } from "../../constants/mainLabelType.constants";
import {
  FormattedMainLabelItemType,
  MainLabelNames,
} from "../../types/articleMainLabel.types";

interface Props {
  labelInfo: FormattedMainLabelItemType;
  dropDownEvents: {
    isOpenDropDown: (labelName: MainLabelNames) => boolean;
    changeTargetDropdown: (labelName: MainLabelNames) => void;
    closeTargetDropdown: () => void;
  };
}

const SingleEditableMainLabel = ({ labelInfo, dropDownEvents }: Props) => {
  const { entity, value, labelName } = labelInfo;

  if (labelInfo.value.length === 0) return null;
  return (
    <S.SingleEditableMainLabelWrapper
      color={MAIN_LABEL_ENTITY_TO_COLOR[entity]}
    >
      <S.LabelItem
        color={MAIN_LABEL_ENTITY_TO_COLOR[entity]}
        onClick={() => dropDownEvents.changeTargetDropdown(labelName)}
      >
        {value}
      </S.LabelItem>

      <S.IconDropDown
        onClick={() => dropDownEvents.changeTargetDropdown(labelName)}
        isOpen={dropDownEvents.isOpenDropDown(labelName)}
        src={IconDropDown}
        alt="click dropdown to edit label"
      />
      {dropDownEvents.isOpenDropDown(labelName) && (
        <MainLabelRadioDropDown targetValue={value[0]} labelInfo={labelInfo} />
      )}
    </S.SingleEditableMainLabelWrapper>
  );
};

export default SingleEditableMainLabel;
