import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY as FINANCING_INFORMATION,
  ENTRY_DATA_FINANCING_INFORMATION_GROUP_SHELL_KEYS as FINANCING_INFORMATION_GROUP_SHELL_KEYS,
  ENTRY_DATA_FINANCING_INFORMATION_SHELL_KEYS as FINANCING_INFORMATION_SHELL_KEYS,
} from "@frontend/common";

import { VentureCapitalGroupTable } from "@/types/ventureCapital.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  handleClickToClearFinancingInformationGroupShell: () => void;
  handleKeyDownToClearFinancingInformationGroupShell: (
    event: KeyboardEvent,
  ) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: VentureCapitalGroupTable;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

const articleSection = SECTION_KEYS.ventureCapital;
export const useHandleVentureCapitalFinancingInformationGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();

  const handleClearGroupShell = () => {
    const clearFinancingInformationGroupShell = (): ArticleEntryData => {
      if (
        groupShellKey ===
        FINANCING_INFORMATION_GROUP_SHELL_KEYS.financingSummaryInformation
      ) {
        return {
          ...entryData,
          [articleSection]: {
            ...entryData[articleSection],
            [FINANCING_INFORMATION]: {
              ...entryData[articleSection]?.[FINANCING_INFORMATION],
              [FINANCING_INFORMATION_SHELL_KEYS.financingPurpose]: {
                ...entryData[articleSection]?.[FINANCING_INFORMATION]?.[
                  FINANCING_INFORMATION_SHELL_KEYS.financingPurpose
                ],
                value: [],
              },
              [FINANCING_INFORMATION_SHELL_KEYS.financingInformationDetail]: {
                ...entryData[articleSection]?.[FINANCING_INFORMATION]?.[
                  FINANCING_INFORMATION_SHELL_KEYS.financingInformationDetail
                ],
                value: [],
              },
            },
          },
        } as ArticleEntryData;
      }
      return {
        ...entryData,
        [articleSection]: {
          ...entryData[articleSection],
          [FINANCING_INFORMATION]: {
            ...entryData[articleSection]?.[FINANCING_INFORMATION],
            [FINANCING_INFORMATION_SHELL_KEYS.amount]: {
              ...entryData[articleSection]?.[FINANCING_INFORMATION]?.[
                FINANCING_INFORMATION_SHELL_KEYS.amount
              ],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    if (info.groupTableKey === FINANCING_INFORMATION) {
      setEntryData(clearFinancingInformationGroupShell());
      changeCurrentHistory({
        entryData: clearFinancingInformationGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  };

  const handleClickToClearFinancingInformationGroupShell = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShell();
    }
  };

  const handleKeyDownToClearFinancingInformationGroupShell = (
    event: KeyboardEvent,
  ) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearGroupShell();
    }
  };

  return {
    handleClickToClearFinancingInformationGroupShell,
    handleKeyDownToClearFinancingInformationGroupShell,
  };
};
