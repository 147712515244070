import React from "react";

import * as S from "./GroupShellTitleDescriptionContainer.style";

interface Props {
  mainGroupShellTitle: string | null;
  description?: string;
  onClickHandler?: () => void;
}

const GroupShellTitleDescriptionContainer = ({
  mainGroupShellTitle,
  description,
  onClickHandler,
}: Props) => {
  if (!mainGroupShellTitle) return null;

  return (
    <S.TitleDescriptionWrapper>
      <S.MainGroupShellTitle onClick={onClickHandler}>
        {mainGroupShellTitle}
      </S.MainGroupShellTitle>
      {description && <S.Description>{description}</S.Description>}
    </S.TitleDescriptionWrapper>
  );
};

export default GroupShellTitleDescriptionContainer;
