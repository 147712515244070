import React, { useEffect } from "react";

import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";

import { useTableCreatorFormatErrorPressId } from "@FillInTableContainer/shared/context/TableCreatorFormatErrorPressIdContext";
import { useEntryDataValue } from "@stores/entryData";

import * as S from "./DataFormatErrorMessageContainer.styles";

type Props = {
  message: string;
  title: string;
  articleId: string;
};

const DataFormatErrorMessageContainer = ({
  message,
  title,
  articleId,
}: Props) => {
  const navigate = useNavigate();
  const { changeErrorPressId } = useTableCreatorFormatErrorPressId();
  const moveToPressList = () => {
    navigate("/press-list");
  };
  const entryData = useEntryDataValue(articleId);

  useEffect(() => {
    changeErrorPressId(articleId);

    Sentry.captureMessage(`[#${articleId}] Entry Data Format Error`, {
      level: "error",
      tags: {
        pressId: articleId,
        type: "entry-data-format-error",
      },
      extra: {
        entryData: JSON.stringify(entryData),
        missingData: message,
        title: `[#${articleId}] Entry Data Format Error`,
      },
    });
  }, [articleId, changeErrorPressId, entryData, message]);
  return (
    <S.MessageWrapper>
      <S.Title>{title}</S.Title>
      <S.ArticleId>{`(Press ID: #${articleId})`}</S.ArticleId>
      <S.Line />
      <S.Description>
        {`Data format doesn’t match.\nPlease contact the administrator for assistance.`}
      </S.Description>
      <S.DetailDescription>{message}</S.DetailDescription>
      <S.Button onClick={moveToPressList}>Back to Press List</S.Button>
    </S.MessageWrapper>
  );
};

export default DataFormatErrorMessageContainer;
