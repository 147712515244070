import styled, { css } from "styled-components";

import ArticleDetailIndex from "@EditArticleContainer/shared/components/ArticleDetailParagraphIndex/ArticleDetailParagraphIndex";
import UpdateButton from "@EditArticleContainer/shared/components/UpdateButton/UpdateButton";
import {
  ParagraphContentsWrapper,
  ParagraphWrapper,
} from "@EditArticleContainer/shared/styles/paragraph.style";

export const ParagraphClinicalResultsWrapper = styled(ParagraphWrapper)``;
export const ParagraphClinicalResultsContentsWrapper = styled(
  ParagraphContentsWrapper,
)<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;

      & * {
        pointer-events: none;
      }
    `}
`;

export const ParagraphClinicalResultsIndexWrapper = styled.div`
  position: relative;
`;

export const ParagraphClinicalResultsIndex = styled(ArticleDetailIndex)``;

export const ParagraphClinicalResultsContentWrapper = styled.div<{
  disabled: boolean;
}>`
  position: relative;
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;

      & * {
        pointer-events: none;
      }
    `}
`;

export const ParagraphClinicalResultsUpdateButton = styled(UpdateButton)`
  position: absolute;
  left: -1.6rem;
  top: 0px;
  transform: translateX(-100%);
`;
