import React from "react";

import DealTermsBottomTableLayout from "../DealTermsBottomTableLayout/DealTermsBottomTableLayout";
import DealTermsOtherTable from "../DealTermsOtherTable/DealTermsOtherTable";
import DealTermsTableLayout from "../DealTermsTableLayout/DealTermsTableLayout";
import DealTermsTableMobileContainer from "../DealTermsTableMobileContainer/DealTermsTableMobileContainer";
import ParagraphSectionLayout from "../ParagraphSectionLayout/ParagraphSectionLayout";

type Props = {
  topTableItems: (
    | {
        title: string;
        value: React.ReactNode;
      }[]
    | {
        title: string;
        value: React.ReactNode;
      }
  )[][];
  bottomTableHeaderItems: React.ReactNode[];
  bottomTableItemsList: (
    | {
        title: string;
        value: React.ReactNode;
      }[]
    | {
        title: string;
        value: React.ReactNode;
      }
  )[][][];
  noBackgroundArrow?: boolean;
  isEditable?: boolean;
  otherTableItemsList?: {
    title: string;
    value: React.ReactNode;
  }[];
};

const DealTermsTableContainer = ({
  topTableItems,
  bottomTableItemsList,
  bottomTableHeaderItems,
  otherTableItemsList,
  noBackgroundArrow,
  isEditable = false,
}: Props) => {
  const maxBottomTableLength = Math.max(
    bottomTableItemsList.at(0)?.length ?? 0,
    bottomTableItemsList.at(1)?.length ?? 0,
  );

  const transformedBottomTableItemsList = Array.from(
    { length: maxBottomTableLength },
    (_, index) => [
      bottomTableItemsList.at(0)?.at(index) ?? [],
      bottomTableItemsList.at(1)?.at(index) ?? [],
    ],
  );

  const isBottomTableItemsList =
    transformedBottomTableItemsList
      ?.flatMap((items) => (items.length > 0 ? items : []))
      .filter((item) => item.length > 0).length > 0;

  return (
    <ParagraphSectionLayout>
      <DealTermsTableLayout items={topTableItems} isEditable={isEditable} />

      {isBottomTableItemsList && (
        <>
          <DealTermsBottomTableLayout
            headerItems={bottomTableHeaderItems}
            tableItems={transformedBottomTableItemsList}
            isEditable={isEditable}
            isBackgroundArrow={!noBackgroundArrow}
          />
          <DealTermsTableMobileContainer
            bottomTableHeaderItems={bottomTableHeaderItems}
            bottomTableItemsList={bottomTableItemsList}
          />
        </>
      )}
      <DealTermsOtherTable
        otherTableItemsList={otherTableItemsList}
        isEditable={isEditable}
      />
    </ParagraphSectionLayout>
  );
};

export default DealTermsTableContainer;
