import React, { forwardRef } from "react";

import * as S from "./DBDetailSectionContainer.style";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const DBDetailSectionContainer = forwardRef(
  ({ children, ...rest }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
      <S.DBDetailSectionWrapper ref={ref} {...rest}>
        {children}
      </S.DBDetailSectionWrapper>
    );
  },
);

export default DBDetailSectionContainer;
