import { SectionCode } from "@frontend/common";

import { apiClient } from "@api/apiClient";

import { PostCompanyLogoInfoResponse } from "./companyLogo.interface";

const API_ENDPOINT = {
  post: "des/draft-article/company-logo",
};

export const companyLogoApis = {
  post: async ({
    articleId,
    articleSection,
  }: {
    articleId: number;
    articleSection: SectionCode;
  }) => {
    // NOTE: x-www-form-urlencoded 형태로 보내기 위함
    const params = new URLSearchParams();
    params.append("id", String(articleId));
    params.append("articleSection", articleSection);

    const response = await apiClient.post<PostCompanyLogoInfoResponse>(
      API_ENDPOINT.post,
      params,
    );

    return response;
  },
};
