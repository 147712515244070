import React from "react";

import * as S from "./ArticleDetailParagraphIndex.style";

export interface Props {
  title: string;
}

const ArticleDetailIndex = ({ title }: Props) => {
  return (
    <S.ArticleDetailIndexWrapper>
      <S.IndexTitle>{title}</S.IndexTitle>
    </S.ArticleDetailIndexWrapper>
  );
};
export default ArticleDetailIndex;
