import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/queryKeys";

import { temporaryTokenApis } from "./temporaryToken.service";

interface Args {
  token: string;
  id: string;
  page: string;
}

export const useTemporaryTokenQuery = ({ token, id, page }: Args) => {
  return useQuery({
    queryKey: [QUERY_KEYS.temporaryToken, token, id, page],
    queryFn: () => temporaryTokenApis.get(token, id, page),
    enabled: token.length === 20,
  });
};
