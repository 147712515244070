"use client";

import styled from "styled-components";

export const MyAccountContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
  max-width: 60rem;
  width: 100%;
`;
