import { checkClinicalTrialsIdentifierCode } from "./utils/checkClinicalTrialsIdentifierCode";
import { splitMultipleStringList } from "../../../business/DatabaseTable/utils/splitMultipleStringList";
import CountryFlag from "../CountryFlag/CountryFlag";

interface Props {
  value: string;
}

const ClinicalTrialIdentifierIcon = ({ value }: Props) => {
  const code = checkClinicalTrialsIdentifierCode(value);
  const countryCode = splitMultipleStringList(code)?.at(0) || "";

  return (
    <CountryFlag
      countryCode={countryCode}
      style={{
        width: "auto",
        height: "1.7rem",
      }}
    />
  );
};

export default ClinicalTrialIdentifierIcon;
