import React, { Dispatch, SetStateAction, useState } from "react";

import SearchInput from "@atoms/SearchInput/SearchInput";

import { usePressListFilterState } from "@stores/pressList";

import {
  PRESS_INFO_FILTER,
  PRESS_LIST_PRESS_INFO_FILTER_KEY,
} from "./constants/pressInfoFilter.constants";
import * as S from "./PressListPressInfoSearch.style";

import PressListSearchFilterSelect from "./components/PressListSearchFilterSelect/PressListSearchFilterSelect";
import { PressSearchFilterType } from "@PressListContainer/pressList.types";

interface Props {
  currentPressFilter: PressSearchFilterType;
  setCurrentFilter: Dispatch<SetStateAction<PressSearchFilterType>>;
}

const PressListPressInfoSearch = ({
  currentPressFilter,
  setCurrentFilter,
}: Props) => {
  const [pressListFilter, setPressListFilter] = usePressListFilterState();
  const [typing, setTyping] = useState("");

  const { search } = pressListFilter;

  const handleSearchKeyboard = () => {
    const currentInput = typing.trim();

    const newSearch = { ...search };
    PRESS_LIST_PRESS_INFO_FILTER_KEY.forEach((key) => delete newSearch[key]);
    newSearch[currentPressFilter.key] = currentInput;

    if (currentInput) {
      setPressListFilter({
        ...pressListFilter,
        search: newSearch,
        page: 1,
      });
    }
  };

  const handleSearchButton = () => {
    const currentInput = typing || search?.[currentPressFilter.key];

    const newSearch = { ...search };
    PRESS_LIST_PRESS_INFO_FILTER_KEY.forEach((key) => delete newSearch[key]);
    newSearch[currentPressFilter.key] = currentInput;

    if (currentInput) {
      setPressListFilter({
        ...pressListFilter,
        search: newSearch,
        page: 1,
      });
    }
  };

  return (
    <S.PressInfoSearchWrapper>
      <PressListSearchFilterSelect
        subTitle="Info Filter"
        filterList={PRESS_INFO_FILTER}
        currentFilter={currentPressFilter}
        setCurrentFilter={setCurrentFilter}
      />
      <SearchInput
        placeholder="Enter text for Press Information search"
        onSearchButton={handleSearchButton}
        handleSearchKeyboard={handleSearchKeyboard}
        typing={typing}
        setTyping={setTyping}
        widthResize
      />
    </S.PressInfoSearchWrapper>
  );
};

export default PressListPressInfoSearch;
