import React from "react";

import RequiredMark from "@atoms/RequiredMark/RequiredMark";
import { useGetNumberOfSupGroupShell } from "@FillInTableContainer/components/FillInTable/components/TableCreator/components/DealsTableCreator/components/DealsDealTermsTableCreatorContainer/components/DealTermsLicenseAgreementGroupTable/components/DealTermsLicenseAgreementTable/components/DealTermsLicenseAgreementGroupShellSection/components/DealTermsLicenseAgreementGroupShell/hooks/useGetNumberOfSubGroupShell";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { padStart } from "@utils/padStart/padStart";

import * as S from "./SubGroupShellTitle.style";
import { getSubGroupShellTitle } from "./utils/getSubGroupShellTitle";
import { isRequiredTable } from "../../utils/getOptionsFromData";
import TableCreatorDragButton from "../TableCreatorDragButton/TableCreatorDragButton";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  tableKey: string | null;
  tableIndex: number;
  groupShellIndex: number;
  subGroupShellKey: string | null;
  subGroupShellIndex: number;
  changeFocusStatusForDragging?: () => void;
  changeFocusStatusForDragEnd?: () => void;
  handleDragStart?: (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => void;
  handleDragEnd?: (subGroupShellKey: string) => void;
  handleDragOn?: () => void;
  onClickHandler?: (e: React.MouseEvent) => void;
  isDraggingSubGroupShell?: boolean;
  isDragging?: boolean;
}

const DISABLED_DRAG_BUTTON_SUB_GROUP_SHELL: string[] = [];

const SubGroupShellTitle = ({
  tableKey,
  tableIndex,
  groupShellIndex,
  subGroupShellKey = "",
  subGroupShellIndex,
  isDraggingSubGroupShell = false,
  isDragging = false,
  handleDragEnd,
  handleDragOn,
  handleDragStart,
  onClickHandler,
  changeFocusStatusForDragEnd,
  changeFocusStatusForDragging,
  ...rest
}: Props) => {
  const articleSection = useArticleSection();
  const { subGroupShellCount } = useGetNumberOfSupGroupShell();

  const tableLength = tableKey
    ? subGroupShellCount(subGroupShellKey, tableIndex, groupShellIndex)
    : 1;

  const title =
    tableLength > 1
      ? `${getSubGroupShellTitle(subGroupShellKey)} ${padStart(
          subGroupShellIndex + 1,
        )}`
      : getSubGroupShellTitle(subGroupShellKey);

  const isRequired = isRequiredTable(articleSection, tableKey);

  const isDisabledDragButton = DISABLED_DRAG_BUTTON_SUB_GROUP_SHELL.includes(
    tableKey || "",
  );

  return (
    <>
      <S.TableTitleWrapper {...rest}>
        <S.RelativeWrapper>
          <S.TitleContentsWrapper
            isDragging={isDragging}
            onClick={onClickHandler}
            onDragStart={(e) => {
              if (handleDragStart && changeFocusStatusForDragging) {
                handleDragStart(e, subGroupShellIndex);
                changeFocusStatusForDragging();
              }
            }}
            onDragEnd={() => {
              if (handleDragEnd && changeFocusStatusForDragEnd) {
                handleDragEnd(subGroupShellKey || "");
                changeFocusStatusForDragEnd();
              }
            }}
            onDrag={() => handleDragOn && handleDragOn()}
            draggable={isDraggingSubGroupShell}
          >
            <S.Title isDragging={isDragging}>
              {isRequired && <RequiredMark text="*" />} {title}
            </S.Title>
            {tableLength > 1 && !isDisabledDragButton && (
              <TableCreatorDragButton
                isDragging={isDragging}
                dragContainer="table"
                onClick={(e) => {
                  e.stopPropagation();
                  if (changeFocusStatusForDragging) {
                    changeFocusStatusForDragging();
                  }
                }}
              />
            )}
          </S.TitleContentsWrapper>
        </S.RelativeWrapper>
      </S.TableTitleWrapper>
    </>
  );
};
export default SubGroupShellTitle;
