import { useState } from "react";

import { SectionCode } from "@frontend/common";

import { invalidateAllArticleDataQuery } from "@api/articleContentData/articleContentData";
import { articleContentDataApis } from "@api/articleContentData/articleContentData.service";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { toastFail, toastSuccess } from "@utils/toast";

import { getParagraphData } from "./useParagraph";
import { ParagraphCode } from "../types/editArticle.types";

export const useUpdateParagraph = ({
  paragraphCode,
  sectionCode,
}: {
  sectionCode: SectionCode;
  paragraphCode: ParagraphCode;
}) => {
  const [loadingParagraphIndex, setLoadingParagraphIndex] =
    useState<number>(-1);
  const [updateKeyMap, setUpdateKeyMap] = useState<Map<number, number>>(
    new Map(),
  );

  const updateParagraph = async (index: number) => {
    try {
      setLoadingParagraphIndex(index);
      const data = await getParagraphData({
        paragraphCode,
        sectionCode,
      });

      await articleContentDataApis.update({
        "Article Id": Number(getPressIdFromURL()),
        [paragraphCode]: data,
      });
      await invalidateAllArticleDataQuery(getPressIdFromURL(), paragraphCode);
      toastSuccess("Update completed!", "blueGreen");
      setUpdateKeyMap(
        new Map(updateKeyMap.set(index, (updateKeyMap.get(index) ?? 0) + 1)),
      );
    } catch {
      toastFail("Failed to update");
    } finally {
      setLoadingParagraphIndex(-1);
    }
  };

  const isDisabled = (index: number) =>
    loadingParagraphIndex !== -1 && loadingParagraphIndex !== index;

  const getUpdateKey = (index: number) => updateKeyMap.get(index) ?? 0;

  return [
    updateParagraph,
    loadingParagraphIndex,
    updateKeyMap,
    isDisabled,
    getUpdateKey,
  ] as const;
};
