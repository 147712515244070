import styled from "styled-components";

import UpdateButton from "@EditArticleContainer/shared/components/UpdateButton/UpdateButton";

export const ParagraphContentsWrapper = styled.div`
  position: relative;
`;

export const ParagraphUpdateButton = styled(UpdateButton)`
  position: absolute;
  left: -1.6rem;
  top: 0;
  transform: translateX(-100%);
`;

export const TableLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;
