import React, { createContext, useContext, useMemo, useState } from "react";

import { GroupTableKeyType } from "../types/tableCreator.types";

export interface ValidatedInfo {
  groupTableKey: GroupTableKeyType;
  groupTableIndex: number;
  tableKey: string | null;
  tableIndex: number;
  groupShellKey: string | null;
  groupShellIndex: number | null;
  subGroupShellKey?: string | null;
  subGroupShellIndex?: number;
  shellKey: string | null;
  shellIndex: number | null;
  valueIndex: number | null;
  type: "alert" | "default";
  container:
    | "groupTable"
    | "table"
    | "groupShell"
    | "shell"
    | "textField"
    | "subGroupShell"
    | "noFocus";
  conditions?: {
    [key: string]: number | undefined;
  };
}

export type ValidatedInfoList = ValidatedInfo[];

export const DEFAULT_VALIDATED_INFO: ValidatedInfoList = [];

export const ValidatedInTableCreatorContext = createContext<{
  validatedInfo: ValidatedInfoList;
  changeValidatedInfo: (newInfo: ValidatedInfoList) => void;
}>({
  validatedInfo: DEFAULT_VALIDATED_INFO,
  changeValidatedInfo: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const ValidatedInTableCreatorProvider = ({ children }: Props) => {
  const [validatedInfo, setValidatedInfo] = useState<ValidatedInfoList>(
    DEFAULT_VALIDATED_INFO,
  );

  const changeValidatedInfo = (newInfo: ValidatedInfoList): void => {
    setValidatedInfo(newInfo);
  };

  const positionInfoWrapper = useMemo(
    () => ({ validatedInfo, changeValidatedInfo }),
    [validatedInfo],
  );
  return (
    <ValidatedInTableCreatorContext.Provider value={positionInfoWrapper}>
      {children}
    </ValidatedInTableCreatorContext.Provider>
  );
};

export const useValidatedInTableCreator = () => {
  const { validatedInfo, changeValidatedInfo } = useContext(
    ValidatedInTableCreatorContext,
  );

  return { validatedInfo, changeValidatedInfo };
};
