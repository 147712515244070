/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import {
  ArticleEntryData,
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_LICENSEE_TABLE as LICENSEE_TABLE_KEY,
  ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS as LICENSEE_KEYS,
  ENTRY_DATA_LICENSOR_TABLE as LICENSOR_TABLE_KEY,
  ENTRY_DATA_LICENSOR_TABLE_KEYS as LICENSOR_KEYS,
} from "@frontend/common";

import { useCurrentDealTypeValue } from "@FillInTableContainer/shared/context/CurrentDealTypeContext";
import { useEntryDataState } from "@stores/entryData";
// import { useInitEntryDataState } from "@stores/initEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

const DEFAULT_ENTRY_DATA_VALUES = {
  value: [],
  options: [],
};

export const useDealSwapRoles = () => {
  const pressId = getPressIdFromURL();
  const [entryData, setEntryData] = useEntryDataState(pressId);
  // const [initEntryData, setInitEntryData] = useInitEntryDataState(pressId);

  const changeEntryData = (data: ArticleEntryData) => {
    setEntryData(data as any);
  };
  // const changeInitEntryData = (data: ArticleEntryData) => {
  //   setInitEntryData(data as any);
  // };

  const { isMergeAndAcquisition } = useCurrentDealTypeValue();

  const swapDealRoles = (
    currentEntryData: ArticleEntryData,
    changeData: (data: ArticleEntryData) => void,
  ) => {
    if (!currentEntryData) return;
    if (!currentEntryData[SECTION_KEYS.deals]) return;

    // NOTE: Licensor Name & R&R
    const licensorTable =
      currentEntryData[SECTION_KEYS.deals]?.[LICENSOR_TABLE_KEY];
    const targetLicensorName =
      licensorTable?.[LICENSOR_KEYS.licensorName] || DEFAULT_ENTRY_DATA_VALUES;
    const targetLicensorRAndR =
      licensorTable?.[LICENSOR_KEYS.licensorResponsibilityRights] ||
      DEFAULT_ENTRY_DATA_VALUES;

    // NOTE: Licensee Name & R&R
    const licenseeTable =
      currentEntryData[SECTION_KEYS.deals]?.[LICENSEE_TABLE_KEY];
    const targetLicenseeName =
      licenseeTable?.at(0)?.[LICENSEE_KEYS.licenseeName] ||
      DEFAULT_ENTRY_DATA_VALUES;
    const targetLicenseeRAndR = isMergeAndAcquisition
      ? licenseeTable?.at(0)?.[LICENSEE_KEYS.mergeAcquisitionTerms]?.[
          LICENSEE_KEYS.licenseResponsibilityRightsMa
        ] || {}
      : licenseeTable?.at(0)?.[
          LICENSEE_KEYS.strategicCollaborationLicenseAgreementTerms
        ]?.[LICENSEE_KEYS.licenseeResponsibilityAndRightsScLa] || {
          value: [],
          options: [],
        };

    const changedLicensorTable = {
      ...licensorTable,
      [LICENSOR_KEYS.licensorName]: targetLicenseeName,
      [LICENSOR_KEYS.licensorResponsibilityRights]: targetLicenseeRAndR,
    };
    const changedLicenseeTable = isMergeAndAcquisition
      ? [
          {
            ...licenseeTable?.at(0),
            [LICENSEE_KEYS.licenseeName]: targetLicensorName,
            [LICENSEE_KEYS.mergeAcquisitionTerms]: {
              ...licenseeTable?.at(0)?.[LICENSEE_KEYS.mergeAcquisitionTerms],
              [LICENSEE_KEYS.licenseResponsibilityRightsMa]:
                targetLicensorRAndR,
            },
          },
        ]
      : [
          {
            ...licenseeTable?.at(0),
            [LICENSEE_KEYS.licenseeName]: targetLicensorName,
            [LICENSEE_KEYS.strategicCollaborationLicenseAgreementTerms]: {
              ...licenseeTable?.at(0)?.[
                LICENSEE_KEYS.strategicCollaborationLicenseAgreementTerms
              ],
              [LICENSEE_KEYS.licenseeResponsibilityAndRightsScLa]:
                targetLicensorRAndR,
            },
          },
        ];

    const swapDealEntryData = {
      ...currentEntryData,
      [SECTION_KEYS.deals]: {
        ...currentEntryData[SECTION_KEYS.deals],
        [LICENSOR_TABLE_KEY]: changedLicensorTable,
        [LICENSEE_TABLE_KEY]: changedLicenseeTable,
      },
    };

    changeData(swapDealEntryData as any);
  };

  const clickDealSwapButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    swapDealRoles(entryData, changeEntryData);
    // TODO : init_data도 함께 변경이 필요 (Update API 필요 / queryKey 수정 필요)
  };

  return {
    clickDealSwapButton,
  };
};
