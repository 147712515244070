import {
  EXPEDITED_PROGRAM,
  EXPEDITED_PROGRAM_GROUP_KEY as eg,
  EXPEDITED_PROGRAM_CONTENTS as ec,
} from "@FillInTableContainer/shared/constants/expeditedProgramKey.constants";
import { useModalityCategoryValue } from "@FillInTableContainer/shared/context/ModalityCategoryContext";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  ValidatedInfoList,
  useValidatedInTableCreator,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

interface UseCheckValidationInTableCreatorReturn {
  handleCheckValidationFull: () => boolean;
}

export const useCheckValidationInExpeditedProgram =
  (): UseCheckValidationInTableCreatorReturn => {
    const entryData = useEntryDataValue(getPressIdFromURL());
    const { changeValidatedInfo } = useValidatedInTableCreator();
    const { changeInfo } = useTableCreatorPositionInfo();
    const { changeFocusStatus } = useTableCreatorFocusStatus();
    const { updateModalityCategory } = useModalityCategoryValue();

    const checkDrugProfileTable = () => {
      const drugProfileTableList =
        entryData?.[EXPEDITED_PROGRAM]?.[eg.drugProfile];

      const valueInfo = drugProfileTableList?.flatMap(
        (tableInfo, tableInfoIndex) => {
          const checkDrugName =
            tableInfo?.["Brand Name"].value.length === 0 &&
            tableInfo?.["INN Name"].value.length === 0 &&
            tableInfo?.["Code Name"].value.length === 0;

          if (!checkDrugName) return null;
          return [
            {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex: tableInfoIndex,
              groupShellKey: "Drug Name",
              groupShellIndex: 0,
              shellKey: "Brand Name",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            },
            {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex: tableInfoIndex,
              groupShellKey: "Drug Name",
              groupShellIndex: 0,
              shellKey: "INN Name",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            },
            {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex: tableInfoIndex,
              groupShellKey: "Drug Name",
              groupShellIndex: 0,
              shellKey: "Code Name",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            },
          ];
        },
      );

      const sortedValidatedInfo =
        valueInfo?.filter((info) => info !== null) || [];

      return sortedValidatedInfo;
    };

    const checkModalityCategory = () => {
      const drugProfileTableList =
        entryData?.[EXPEDITED_PROGRAM]?.[eg.drugProfile];
      const modalityValues = drugProfileTableList?.map((table) =>
        table.Modality.value?.map((shell) => shell.text),
      );

      const valueInfo = modalityValues?.flatMap((table, tableIndex) =>
        table.map((shell) => {
          if (updateModalityCategory[shell] === "") {
            return {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex,
              groupShellKey: null,
              groupShellIndex: 0,
              shellKey: "Modality",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            };
          }
          return null;
        }),
      );
      return valueInfo?.filter((item) => item !== null);
    };

    const checkExpeditedProgramContentsTable = () => {
      const expeditedProgramContentsTableData =
        entryData?.[EXPEDITED_PROGRAM]?.[eg.expeditedProgramContents];

      const sortedValidatedInfo = () => {
        const validatedInfoTemp: ValidatedInfoList = [];

        const isValidatedRegulatoryAgency =
          !expeditedProgramContentsTableData?.[ec.regulatoryAgency].value
            .length;

        const isValidatedExpeditedProgram =
          !expeditedProgramContentsTableData?.[ec.expeditedProgram].value
            .length;

        if (isValidatedRegulatoryAgency)
          validatedInfoTemp.push({
            groupTableKey: eg.expeditedProgramContents,
            groupTableIndex: 1,
            tableKey: null,
            tableIndex: 0,
            groupShellKey: "first",
            groupShellIndex: 0,
            shellKey: ec.regulatoryAgency,
            shellIndex: 0,
            valueIndex: null,
            type: "alert",
            container: "shell",
          });

        if (isValidatedExpeditedProgram)
          validatedInfoTemp.push({
            groupTableKey: eg.expeditedProgramContents,
            groupTableIndex: 1,
            tableKey: null,
            tableIndex: 0,
            groupShellKey: "first",
            groupShellIndex: 0,
            shellKey: ec.expeditedProgram,
            shellIndex: 0,
            valueIndex: null,
            type: "alert",
            container: "shell",
          });

        return validatedInfoTemp;
      };

      return sortedValidatedInfo();
    };

    const handleCheckValidationFull = () => {
      if (
        checkDrugProfileTable().length === 0 &&
        checkExpeditedProgramContentsTable().length === 0
      ) {
        changeInfo(DEFAULT_POSITION_INFO);
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
        return true;
      }

      const validatedList = [
        ...(checkDrugProfileTable() || []),
        ...(checkModalityCategory() || []),
        ...(checkExpeditedProgramContentsTable() || []),
      ];

      changeValidatedInfo(validatedList as ValidatedInfoList);
      return false;
    };

    return {
      handleCheckValidationFull,
    };
  };
