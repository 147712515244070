import { apiClient } from "@api/apiClient";

import { ResponseGetModalityCategory } from "./modalityCategory.interface";

const API_ENDPOINTS = {
  get: "/des/modality-category/get-by-name",
  post: "/des/modality-category/update",
};

export const modalityCategoryApis = {
  get: async (modalityValue: string) => {
    const API_URL = `${API_ENDPOINTS.get}?name=${encodeURIComponent(
      modalityValue,
    )}`;
    const response = await apiClient.get<ResponseGetModalityCategory>(API_URL);

    return response.data;
  },
  post: async (data: {
    name: string;
    categoryName: string;
    pressId: number;
  }) => {
    const response = await apiClient.post(`${API_ENDPOINTS.post}`, data);
    return response.data;
  },
};
