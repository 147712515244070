import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconLoader = ({ width = 40, height = 40, color = "#ffffff" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6 6.11111C19.6 5.55883 20.0477 5.11111 20.6 5.11111C21.1523 5.11111 21.6 5.55883 21.6 6.11111V11.6667C21.6 12.219 21.1523 12.6667 20.6 12.6667C20.0477 12.6667 19.6 12.219 19.6 11.6667V6.11111ZM19.6 28.3333C19.6 27.781 20.0477 27.3333 20.6 27.3333C21.1523 27.3333 21.6 27.781 21.6 28.3333V33.8889C21.6 34.4412 21.1523 34.8889 20.6 34.8889C20.0477 34.8889 19.6 34.4412 19.6 33.8889V28.3333ZM31.1265 9.47344C30.736 9.08292 30.1028 9.08292 29.7123 9.47344L25.7818 13.404C25.3912 13.7945 25.3912 14.4277 25.7818 14.8182C26.1723 15.2087 26.8055 15.2087 27.196 14.8182L31.1265 10.8877C31.5171 10.4971 31.5171 9.86397 31.1265 9.47344ZM14.004 25.1817C14.3945 24.7912 15.0277 24.7912 15.4182 25.1817C15.8087 25.5723 15.8087 26.2054 15.4182 26.596L11.4876 30.5265C11.0971 30.917 10.464 30.917 10.0734 30.5265C9.68291 30.136 9.68291 29.5028 10.0734 29.1123L14.004 25.1817ZM35.4889 20C35.4889 19.4477 35.0411 19 34.4889 19H28.9333C28.381 19 27.9333 19.4477 27.9333 20C27.9333 20.5523 28.381 21 28.9333 21H34.4889C35.0411 21 35.4889 20.5523 35.4889 20ZM12.2666 19C12.8189 19 13.2666 19.4477 13.2666 20C13.2666 20.5523 12.8189 21 12.2666 21H6.71108C6.1588 21 5.71108 20.5523 5.71108 20C5.71108 19.4477 6.1588 19 6.71108 19H12.2666ZM25.7818 25.1817C25.3912 25.5723 25.3912 26.2054 25.7818 26.596L29.7123 30.5265C30.1028 30.917 30.736 30.917 31.1265 30.5265C31.5171 30.136 31.5171 29.5028 31.1265 29.1123L27.196 25.1817C26.8055 24.7912 26.1723 24.7912 25.7818 25.1817ZM10.0734 10.8877C9.68291 10.4971 9.68291 9.86397 10.0734 9.47344C10.464 9.08292 11.0971 9.08292 11.4876 9.47344L15.4182 13.404C15.8087 13.7945 15.8087 14.4277 15.4182 14.8182C15.0277 15.2087 14.3945 15.2087 14.004 14.8182L10.0734 10.8877Z"
        fill={color}
      />
    </svg>
  );
};

IconLoader.displayName = "Loader";
export default IconLoader;
