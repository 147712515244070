import { ExpeditedProgramSection } from "@/types/expeditedProgram.types";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { EXPEDITED_PROGRAM } from "@FillInTableContainer/shared/constants/expeditedProgramKey.constants";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import {
  FocusStatus,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  PositionInfo,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useGetNumberOfGroupShell } from "@FillInTableContainer/shared/hooks/useGetNumberOfGroupShell";
import { TableCreatorGroupShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import { isTableCustomTitle } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export interface TableInfoProps {
  groupTableKey: ExpeditedProgramSection;
  tableKey: string | null;
  groupShellList: TableCreatorGroupShellList;
  groupTableIndex: number;
  tableIndex: number;
  tableCount: number;
}
interface UseHandleTableCreatorTableReturn {
  info: PositionInfo;
  focusStatus: FocusStatus;
  isFocused: boolean;
  isLastTable: boolean;
  isVisibleTableCustomTitle: boolean;
  isEnableEdit: boolean;
  numberOfGroupShellCount: (groupShellKey: string | null) => number;
}

export const useExpeditedProgramTableCreator = (
  tablePropsInfo: TableInfoProps,
): UseHandleTableCreatorTableReturn => {
  const { groupTableKey, tableKey, tableIndex, tableCount, groupShellList } =
    tablePropsInfo;

  const articleSection = EXPEDITED_PROGRAM;

  const { info } = useTableCreatorPositionInfo();
  const { focusStatus } = useTableCreatorFocusStatus();
  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );

  const isEnableEdit = articleInfo
    ? ENABLE_GROUP_TABLE_KEY_LIST[articleSection][groupTableKey].includes(
        articleInfo.draftArticleStatus,
      )
    : false;

  const { groupShellCount } = useGetNumberOfGroupShell();

  const isLastTable = tableIndex === tableCount - 1;

  const isFocused =
    info.groupTableKey === groupTableKey &&
    info.tableKey === tableKey &&
    info.tableIndex === tableIndex &&
    isEnableEdit;

  const isVisibleTableCustomTitle =
    isTableCustomTitle(articleSection, groupTableKey) &&
    groupShellCount(groupShellList[0].groupShellKey, tableIndex) > 1;

  const numberOfGroupShellCount = (groupShellKey: string | null) =>
    groupShellCount(groupShellKey, tableIndex);

  return {
    info,
    focusStatus,
    isFocused,
    isLastTable,
    isVisibleTableCustomTitle,
    isEnableEdit,
    numberOfGroupShellCount,
  };
};
