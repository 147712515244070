"use client";

import styled from "styled-components";

export const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.4rem;
  width: 100%;
  height: 2.8rem;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 2rem;
  font-weight: 600;
`;

export const Count = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: var(--Color-Common-Text-Tertiary, #7c899c);
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
`;
