import dayjs from "dayjs";

import { convertHtmlToContent } from "@EditArticleContainer/shared/components/EditableTextContainer/utils/htmlConvert";
import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import { REF_CODE_TITLE } from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { TitleContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/titleContent.types";

export const useCreateTitleContent = () => {
  const refMap = useArticleContentRefMap();

  return () => {
    const object = convertHtmlToContent(
      refMap.get(REF_CODE_TITLE)?.current?.innerHTML ?? "",
    );

    const content: TitleContent = {
      text: object.text,
      labels: object.labels,
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
