import styled, { css } from "styled-components";

export const DropdownListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
`;
export const ListItem = styled.li<{
  $isTarget: boolean;
  $isCategory?: boolean;
  $isDefaultAiValue?: boolean;
}>`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-weight: 600;
  padding: var(--Spacing-12, 1.2rem) var(--Spacing-6, 0.6rem);
  margin: 0 0.8rem;
  border-bottom: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  white-space: pre-wrap;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: var(
      --Color-Common-Bg-Interactive-Primary-Subtle-Hovered,
      #e0f4ff
    );
  }

  &:active {
    background: var(
      --Color-Common-Bg-Interactive-Primary-Subtle-Pressed,
      #d1efff
    );
  }

  ${({ $isTarget }) =>
    $isTarget &&
    css`
      background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #f0f9ff);
    `};

  ${({ $isCategory }) =>
    $isCategory &&
    css`
      padding: var(--Spacing-12, 1.2rem);
    `}

  ${({ $isCategory, $isTarget }) =>
    $isCategory &&
    $isTarget &&
    css`
      color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
    `}

  ${({ $isDefaultAiValue }) =>
    $isDefaultAiValue &&
    css`
      color: ${({ theme }) => theme.color.status.todo};
    `}
`;

export const SectionTitle = styled.h3`
  display: flex;
  padding: var(--Spacing-8, 0.8rem);
  border-bottom: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);

  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-weight: 600;
`;
