import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_KEY_COMMENTS_GROUP_TABLE_KEY as KEY_COMMENT,
  ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS as DRUG_PROFILE_SHELL_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import { VentureCapitalGroupTable } from "@/types/ventureCapital.types";
import { createAttributionId } from "@FillInTableContainer/shared/utils/createAttributionId";

export const BASIC_DATA_FOR_ADDING_TABLES = {
  [SECTION_KEYS.ventureCapital]: {
    [DRUG_PROFILE]: (data: ArticleEntryData) => {
      const drugProfileTableData =
        data?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.[0];

      if (!drugProfileTableData) return;
      return {
        [DRUG_PROFILE_SHELL_KEYS.roa]: {
          value: [],
          options: drugProfileTableData?.[DRUG_PROFILE_SHELL_KEYS.roa]?.options,
          attributionId: createAttributionId(),
        },
        [DRUG_PROFILE_SHELL_KEYS.modality]: {
          value: [],
          options:
            drugProfileTableData?.[DRUG_PROFILE_SHELL_KEYS.modality]?.options,
          attributionId: createAttributionId(),
        },
        [DRUG_PROFILE_SHELL_KEYS.target]: {
          value: [],
          options:
            drugProfileTableData?.[DRUG_PROFILE_SHELL_KEYS.target]?.options,
          attributionId: createAttributionId(),
        },
        [DRUG_PROFILE_SHELL_KEYS.moa]: {
          value: [],
          options: drugProfileTableData?.[DRUG_PROFILE_SHELL_KEYS.moa]?.options,
          attributionId: createAttributionId(),
        },
        [DRUG_PROFILE_SHELL_KEYS.phase]: {
          value: [],
          options:
            drugProfileTableData?.[DRUG_PROFILE_SHELL_KEYS.phase]?.options,
          attributionId: createAttributionId(),
        },
        [DRUG_PROFILE_SHELL_KEYS.codeName]: {
          value: [],
          options:
            drugProfileTableData?.[DRUG_PROFILE_SHELL_KEYS.codeName]?.options,
          attributionId: createAttributionId(),
        },
        [DRUG_PROFILE_SHELL_KEYS.innName]: {
          value: [],
          options:
            drugProfileTableData?.[DRUG_PROFILE_SHELL_KEYS.innName]?.options,
          attributionId: createAttributionId(),
        },
        [DRUG_PROFILE_SHELL_KEYS.brandName]: {
          value: [],
          options:
            drugProfileTableData?.[DRUG_PROFILE_SHELL_KEYS.brandName]?.options,
          attributionId: createAttributionId(),
        },
        [DRUG_PROFILE_SHELL_KEYS.targetIndication]: {
          value: [],
          options:
            drugProfileTableData?.[DRUG_PROFILE_SHELL_KEYS.targetIndication]
              ?.options,
          attributionId: createAttributionId(),
        },
        [DRUG_PROFILE_SHELL_KEYS.therapeuticArea]: {
          value: [],
          options:
            drugProfileTableData?.[DRUG_PROFILE_SHELL_KEYS.therapeuticArea]
              ?.options,
          attributionId: createAttributionId(),
        },
        [DRUG_PROFILE_SHELL_KEYS.otherCharacteristics]: {
          value: [],
          options:
            drugProfileTableData?.[DRUG_PROFILE_SHELL_KEYS.otherCharacteristics]
              ?.options,
          attributionId: createAttributionId(),
        },
        [DRUG_PROFILE_SHELL_KEYS.genericBiosimilar]: {
          value: ["false"],
          options:
            drugProfileTableData?.[DRUG_PROFILE_SHELL_KEYS.genericBiosimilar]
              ?.options,
          attributionId: createAttributionId(),
        },
        [DRUG_PROFILE_SHELL_KEYS.refProductName]: {
          value: [],
          options:
            drugProfileTableData?.[DRUG_PROFILE_SHELL_KEYS.refProductName]
              ?.options,
          attributionId: createAttributionId(),
        },
        [DRUG_PROFILE_SHELL_KEYS.originatorDeveloper]: {
          value: [],
          options:
            drugProfileTableData?.[DRUG_PROFILE_SHELL_KEYS.originatorDeveloper]
              ?.options,
          attributionId: createAttributionId(),
        },
        attributionInfo: {
          attributionId: createAttributionId(),
        },
      };
    },
    [KEY_COMMENT]: () => null,
  },
};

export const CHECK_TABLE_KEY_BY_GROUP_TABLE_KEY: {
  [SECTION_KEYS.ventureCapital]: {
    [key in VentureCapitalGroupTable]: string | null;
  };
} = {
  [SECTION_KEYS.ventureCapital]: {
    [DRUG_PROFILE]: "Drug",
    [KEY_COMMENT]: null,
  },
};
