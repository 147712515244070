"use client";

import styled from "styled-components";

export const MyAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 120rem;
  padding: var(--Spacing-40, 4rem) var(--Spacing-40, 4rem)
    var(--Spacing-64, 6.4rem) var(--Spacing-40, 4rem);
  gap: 1.6rem;

  @media only screen and (min-width: 768px) and (max-width: 1279px) {
    padding: var(--Spacing-20, 2rem) var(--Spacing-16, 1.6rem)
      var(--Spacing-64, 6.4rem) var(--Spacing-16, 1.6rem);
  }

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    padding: 2rem 1.6rem var(--Spacing-64, 6.4rem) 1.6rem;
  }
`;
