import BottomButtonContainer from "@/components/shared/ui/BottomButtonContainer/BottomButtonContainer";
import MovePrevStepButton from "@/components/shared/ui/MovePrevStepButton/MovePrevStepButton";
import TopFixInfo from "@/components/shared/ui/TopFixInfo/TopFixInfo";
import UpdateStatusButtons from "@/components/shared/ui/UpdateStatusButtons/UpdateStatusButtons";

import ArticleDetailBody from "./components/ArticleDetailBody/ArticleDetailBody";
import ArticleDetailFabContentBox from "./components/ArticleDetailFabContentBox/ArticleDetailFabContentBox";
import ArticleHeader from "./components/ArticleHeader/ArticleHeader";
import PublishButton from "./components/PublishButton/PublishButton";
import * as S from "./EditArticle.style";
import EditArticleProvider from "../EditArticleProvider/EditArticleProvider";

const EditArticle = () => {
  return (
    <EditArticleProvider>
      <S.EditArticleWrapper>
        <S.EditArticleScrollSection>
          <S.TopFixInfoWrapper>
            <TopFixInfo />
          </S.TopFixInfoWrapper>
          <ArticleHeader />
          <S.ContentsWrapper>
            <ArticleDetailBody />
          </S.ContentsWrapper>
        </S.EditArticleScrollSection>
        <S.CenterContainer>
          <BottomButtonContainer>
            <S.ButtonLeftButtonWrapper>
              <MovePrevStepButton label="Insert Image" page="insertImage" />
              <UpdateStatusButtons />
            </S.ButtonLeftButtonWrapper>
            <S.RightButtonWrapper>
              <ArticleDetailFabContentBox />
              <PublishButton />
            </S.RightButtonWrapper>
          </BottomButtonContainer>
        </S.CenterContainer>
      </S.EditArticleWrapper>
    </EditArticleProvider>
  );
};

export default EditArticle;
