"use client";

import styled from "styled-components";

import { DESKTOP_HEADER_HEIGHT } from "../../../constants";

export const StickyWrapper = styled.div`
  position: sticky;
  top: ${DESKTOP_HEADER_HEIGHT};
  z-index: 30;
`;
