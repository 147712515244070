import React, { HTMLAttributes } from "react";

import * as S from "./DBResultsLabel.style";
import { removeModalityEtc } from "../../../shared";
import { IconXMark } from "../../../shared/Icons";

export type LabelType =
  | "normal"
  | "modality"
  | "target"
  | "medicine"
  | "phase"
  | "disease"
  | "endpoint";

interface Props extends HTMLAttributes<HTMLSpanElement> {
  labelText: string;
  onClickHandler: (label: string) => void;
  labelType?: LabelType;
  isGroupLabel?: boolean;
  totalLabelCount?: number;
}

const DBResultsLabel = ({
  labelText,
  onClickHandler,
  labelType = "normal",
  isGroupLabel = false,
  totalLabelCount = 0,
  ...rest
}: Props) => {
  return (
    <S.LabelWrapper
      onClick={() => onClickHandler(labelText)}
      $isGroupLabel={isGroupLabel}
      $labelType={labelType}
      className={isGroupLabel ? "group-label" : ""}
    >
      <IconXMark />
      <S.LabelText {...rest}>{removeModalityEtc(labelText)}</S.LabelText>
      {isGroupLabel && (
        <S.CountText $labelType={labelType}>{totalLabelCount}</S.CountText>
      )}
    </S.LabelWrapper>
  );
};

export default DBResultsLabel;
