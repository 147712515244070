import { EntireDropdownListType } from "../components/TableCreatorListBox/types/tableCreatorDropdown.types";

export const returnCurrentLastTargetIndex = (
  dropdownList: EntireDropdownListType,
) => {
  const dropdownLastMainList = dropdownList[dropdownList.length - 1];

  const lastDropdownIsCategory =
    dropdownLastMainList.key === "DICTIONARY" &&
    dropdownLastMainList.isCategory;

  const dropdownLastListIndex = dropdownLastMainList.list.length - 1;

  const dropdownLastCategoryIndex = lastDropdownIsCategory
    ? dropdownLastMainList.list.length - 1
    : 0;

  const dropdownLastSubListIndex = lastDropdownIsCategory
    ? dropdownLastMainList.list[dropdownLastListIndex].list.length - 1
    : dropdownLastListIndex;

  return {
    lastMainIndex: dropdownList.length - 1,
    lastCategoryIndex: dropdownLastCategoryIndex,
    lastListIndex: dropdownLastSubListIndex,
    lastKey: dropdownList[dropdownList.length - 1].key,
  };
};
