import { useEffect } from "react";

import { ClinicalTrialStatusSection } from "@/types/clinicalTrialStatus.types";
import { useScroll } from "@FillInTableContainer/components/FillInTable/hooks/useScroll";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import {
  DefaultShellTitle,
  TableCreatorTextFieldContainer,
} from "@FillInTableContainer/shared";
import useCheckPressedShell from "@FillInTableContainer/shared/hooks/useCheckPressedShell";
import { useGetInfoOfShell } from "@FillInTableContainer/shared/hooks/useGetInfoOfShell";
import { TableCreatorSubShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import {
  isRequiredShell,
  getShellPlaceholder,
} from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getShellTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { padStart } from "@utils/padStart/padStart";

import * as S from "./ClinicalTrialStatusTableCreatorShell.style";
import { useHandleClinicalTrialsTableCreatorShell } from "./hooks/useHandleClinicalTrialsTableCreatorShell";

export interface PositionInfoProps {
  groupTableKey: ClinicalTrialStatusSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  groupShellIndex: number;
  tableKey: string | null;
  tableIndex: number;
  shellKey: string;
  shellIndex: number;
  shellCount: number;
}

interface Props extends PositionInfoProps {
  shellCount: number;
  subShellList?: TableCreatorSubShellList;
  isShellOverOne: boolean;
  isSeparatedShellTitle: boolean;
  isTitleRowShell?: boolean;
  isFocusedGroupShell: boolean;
  isClearGroupShell: boolean;
  isDeleteGroupShell: boolean;
  isAlertGroupShell: boolean;
}

const ClinicalTrialStatusTableCreatorShell = ({
  groupTableKey,
  groupTableIndex,
  groupShellKey,
  groupShellIndex,
  tableKey,
  shellKey,
  tableIndex,
  shellIndex,
  shellCount,
  subShellList,
  isShellOverOne,
  isSeparatedShellTitle,
  isTitleRowShell = false,
  isClearGroupShell,
  isDeleteGroupShell,
  isAlertGroupShell,
}: Props) => {
  const positionPropsInfo: PositionInfoProps = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
    shellKey,
    shellIndex,
    shellCount,
  };

  const {
    isFocused,
    isClearShell,
    isDeleteShell,
    isEnableEdit,
    isAlertShell,
    focusStatus,
    columnSize,
    isClearTable,
    isDeleteTable,
    handleFocusTextFieldWrapper,
    handleClickToDeleteShell,
    handleFocusOutShell,
  } = useHandleClinicalTrialsTableCreatorShell(positionPropsInfo);
  const { isPressed, handleChangePressedStatus } = useCheckPressedShell();

  const articleSection = useArticleSection();
  const entryData = useEntryDataValue(getPressIdFromURL());
  const { shellValue } = useGetInfoOfShell();
  const { register } = useScroll();
  const clinicalTrialDesign1TableLength =
    entryData?.[articleSection as "Clinical Trial Status"]?.[
      "Clinical Trial Design-1"
    ]?.length || 0;
  const drugProfileTableLength =
    entryData?.[articleSection as "Clinical Trial Status"]?.["Drug Profile"]
      ?.length || 0;

  useEffect(() => {
    const handleCheckClickOutsideShell = () => {
      if (isFocused) handleFocusOutShell();
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleFocusOutShell, isFocused]);

  if (shellKey === "Modality") {
    const modalityShellTitleColumnClassName = `is-modality-shell-value-empty-${
      shellValue(shellKey, tableIndex, groupShellIndex, shellIndex).value
        .length === 0
    }`;
    return (
      <S.ShellWrapper
        column={columnSize}
        isHideComponent={false}
        {...register(
          `${groupTableKey}__${groupTableIndex}__${tableKey}__${tableIndex}__${groupShellKey}__${groupShellIndex}__${shellKey}__${shellIndex}`,
        )}
      >
        {(isClearShell || isDeleteShell) && isEnableEdit && (
          <S.DeleteTableButtonWrapper>
            <S.DeleteTableButton
              type="button"
              onClick={handleClickToDeleteShell}
            >
              <img src={IconXMark} alt="button for clear" />
              {focusStatus.type === "clear" ? "Clear" : null}
              {focusStatus.type === "delete" ? "Delete" : null}
            </S.DeleteTableButton>
          </S.DeleteTableButtonWrapper>
        )}
        <S.ShellBorderWrapper
          isFocused={isFocused && focusStatus.type !== "dragging"}
          $isDragging={isFocused && focusStatus.type === "dragging"}
          isClearStatus={isClearShell || isClearGroupShell || isClearTable}
          isDeleteStatus={isDeleteShell || isDeleteGroupShell || isDeleteTable}
          isAlertStatus={isAlertShell || isAlertGroupShell}
          isDisableEdit={!isEnableEdit}
          isSeparatedShellTitle={isSeparatedShellTitle}
          isTitleRowShell={isTitleRowShell}
          $isPressed={isPressed}
          onClick={handleFocusTextFieldWrapper}
          onMouseDown={(e) => handleChangePressedStatus(e, true)}
          onMouseUp={(e) => handleChangePressedStatus(e, false)}
        >
          <S.ModalityShellRow>
            <S.ModalityShellColumn
              className={modalityShellTitleColumnClassName}
              $isMultipleValue={
                shellValue(shellKey, tableIndex, groupShellIndex, shellIndex)
                  .value.length > 1
              }
            >
              <DefaultShellTitle
                isFocused={isFocused}
                isClearShell={isClearShell}
                isDeleteShell={isDeleteShell}
                isAlertShell={isAlertShell}
                isRequiredShell={isRequiredShell(articleSection, shellKey)}
                shellTitle={getShellTitle(articleSection, shellKey)}
                shellTitleNumbering=""
                isSeparatedShellTitle={isSeparatedShellTitle}
                columnSize={columnSize}
              />
            </S.ModalityShellColumn>
            <S.ModalityShellColumn>
              <DefaultShellTitle
                isFocused={isFocused}
                isClearShell={isClearShell}
                isDeleteShell={isDeleteShell}
                isAlertShell={isAlertShell}
                isRequiredShell={isRequiredShell(articleSection, shellKey)}
                shellTitle="Modality Category"
                shellTitleNumbering=""
                isSeparatedShellTitle={isSeparatedShellTitle}
                columnSize={columnSize}
              />
            </S.ModalityShellColumn>
          </S.ModalityShellRow>

          <>
            <TableCreatorTextFieldContainer
              placeholder={
                getShellPlaceholder(articleSection, shellKey) ||
                getShellTitle(articleSection, shellKey)
              }
              shellValue={shellValue(
                shellKey,
                tableIndex,
                groupShellIndex,
                shellIndex,
              )}
              shellKey={shellKey}
              shellIndex={shellIndex}
              groupTableKey={groupTableKey}
              groupTableIndex={groupTableIndex}
              groupShellKey={groupShellKey}
              groupShellIndex={groupShellIndex}
              tableKey={tableKey}
              tableIndex={tableIndex}
            />
          </>
        </S.ShellBorderWrapper>
      </S.ShellWrapper>
    );
  }

  return (
    <S.ShellWrapper
      column={columnSize}
      {...register(
        `${groupTableKey}__${groupTableIndex}__${tableKey}__${tableIndex}__${groupShellKey}__${groupShellIndex}__${shellKey}__${shellIndex}`,
      )}
      isHideComponent={
        (clinicalTrialDesign1TableLength === 1 ||
          drugProfileTableLength === 1) &&
        shellKey === "Trial Intervention"
      }
    >
      {(isClearShell || isDeleteShell) && isEnableEdit && (
        <S.DeleteTableButtonWrapper>
          <S.DeleteTableButton type="button" onClick={handleClickToDeleteShell}>
            <img src={IconXMark} alt="button for clear" />
            {focusStatus.type === "clear" ? "Clear" : null}
            {focusStatus.type === "delete" ? "Delete" : null}
          </S.DeleteTableButton>
        </S.DeleteTableButtonWrapper>
      )}
      <S.ShellBorderWrapper
        isFocused={isFocused && focusStatus.type !== "dragging"}
        $isDragging={isFocused && focusStatus.type === "dragging"}
        isClearStatus={isClearShell || isClearGroupShell || isClearTable}
        isDeleteStatus={isDeleteShell || isDeleteGroupShell || isDeleteTable}
        isAlertStatus={isAlertGroupShell || isAlertShell}
        isDisableEdit={!isEnableEdit}
        isSeparatedShellTitle={isSeparatedShellTitle}
        isTitleRowShell={isTitleRowShell}
        $isPressed={isPressed}
        onClick={handleFocusTextFieldWrapper}
        onMouseDown={(e) => handleChangePressedStatus(e, true)}
        onMouseUp={(e) => handleChangePressedStatus(e, false)}
      >
        <DefaultShellTitle
          isFocused={isFocused}
          isClearShell={isClearShell}
          isDeleteShell={isDeleteShell}
          isAlertShell={isAlertShell}
          isRequiredShell={isRequiredShell(articleSection, shellKey)}
          shellTitle={getShellTitle(articleSection, shellKey)}
          shellTitleNumbering={
            isShellOverOne ? `${padStart(shellIndex + 1)}` : ""
          }
          isSeparatedShellTitle={isSeparatedShellTitle}
          columnSize={columnSize}
        />

        {!isTitleRowShell && (
          <>
            {subShellList ? (
              subShellList.map(({ subShellKey }) => (
                <TableCreatorTextFieldContainer
                  key={`sub-shell-${String(shellIndex)}-${String(subShellKey)}`}
                  placeholder={
                    getShellPlaceholder(articleSection, subShellKey) ||
                    getShellTitle(articleSection, subShellKey)
                  }
                  shellValue={shellValue(
                    subShellKey,
                    tableIndex,
                    groupShellIndex,
                    shellIndex,
                  )}
                  shellKey={subShellKey}
                  shellIndex={shellIndex}
                  groupTableKey={groupTableKey}
                  groupTableIndex={groupTableIndex}
                  groupShellKey={groupShellKey}
                  groupShellIndex={groupShellIndex}
                  tableKey={tableKey}
                  tableIndex={tableIndex}
                />
              ))
            ) : (
              <TableCreatorTextFieldContainer
                placeholder={
                  getShellPlaceholder(articleSection, shellKey) ||
                  getShellTitle(articleSection, shellKey)
                }
                shellValue={shellValue(
                  shellKey,
                  tableIndex,
                  groupShellIndex,
                  shellIndex,
                )}
                shellKey={shellKey}
                shellIndex={shellIndex}
                groupTableKey={groupTableKey}
                groupTableIndex={groupTableIndex}
                groupShellKey={groupShellKey}
                groupShellIndex={groupShellIndex}
                tableKey={tableKey}
                tableIndex={tableIndex}
              />
            )}
          </>
        )}
      </S.ShellBorderWrapper>
    </S.ShellWrapper>
  );
};

export default ClinicalTrialStatusTableCreatorShell;
