import React from "react";

import DeletedPressBoundary from "@/components/shared/business/DeletedPressBoundary/DeletedPressBoundary";
import EditArticleArticleIdGuard from "@EditArticleContainer/components/EditArticleArticleIdGuard/EditArticleArticleIdGuard";
import EditArticleRoleGuard from "@libs/roles/components/EditArticleRoleGuard";

import EditArticleContainer from "../components/business/EditArticleContainer/EditArticleContainer";

const EditArticlePage = () => {
  return (
    <EditArticleArticleIdGuard>
      <EditArticleRoleGuard>
        <DeletedPressBoundary>
          <EditArticleContainer />
        </DeletedPressBoundary>
      </EditArticleRoleGuard>
    </EditArticleArticleIdGuard>
  );
};

export default EditArticlePage;
