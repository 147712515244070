import { apiClient } from "@api/apiClient";

const API_ENDPOINTS = {
  put: "/des/draft-article/unsuitable-cancel",
};

export const unsuitablePressApis = {
  put: async (articleId: string) => {
    const API_URL = `${API_ENDPOINTS.put}?id=${articleId}`;
    const response = await apiClient.put(API_URL);

    return response.data;
  },
};
