import { ExpeditedProgramSection } from "@/types/expeditedProgram.types";
import { TableCreatorGroupShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import ExpeditedProgramSingleTypeGroupShell from "./components/ExpeditedProgramSingleTypeGroupShell/ExpeditedProgramSingleTypeGroupShell";
import * as S from "./ExpeditedProgramSingleTypeTable.style";
import { useExpeditedProgramTableCreator } from "./hooks/useExpeditedProgramTableCreator";

export interface TableInfoProps {
  groupTableKey: ExpeditedProgramSection;
  tableKey: string | null;
  groupShellList: TableCreatorGroupShellList;
  groupTableIndex: number;
  tableIndex: number;
  tableCount: number;
}
type Props = TableInfoProps;

const ExpeditedProgramSingleTypeTable = ({
  groupTableKey,
  tableKey,
  groupTableIndex,
  tableIndex,
  tableCount,
  groupShellList,
}: Props) => {
  const tablePropsInfo: TableInfoProps = {
    groupTableKey,
    tableKey,
    groupTableIndex,
    tableIndex,
    tableCount,
    groupShellList,
  };

  const { info, isFocused, numberOfGroupShellCount } =
    useExpeditedProgramTableCreator(tablePropsInfo);

  const isClearTable =
    isFocused &&
    info.groupShellKey === null &&
    info.groupShellIndex === null &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null;

  return (
    <S.TableWrapper isFocused={isFocused} isClearTable={isClearTable}>
      {groupShellList.map(
        ({ groupShellKey, shellList }, groupShellListIndex) => (
          <S.GroupShellWrapper
            key={`groupShellList-${String(groupShellListIndex)}`}
          >
            {newArrayToCountValue(numberOfGroupShellCount(groupShellKey)).map(
              (_, groupShellIndex) => (
                <ExpeditedProgramSingleTypeGroupShell
                  key={`group-shell-${String(groupTableIndex)}-${String(
                    tableIndex,
                  )}-${String(groupShellIndex)}`}
                  shellList={shellList}
                  groupTableKey={groupTableKey}
                  groupTableIndex={groupTableIndex}
                  tableKey={tableKey}
                  tableIndex={tableIndex}
                  groupShellKey={groupShellKey}
                  groupShellIndex={groupShellIndex}
                  groupShellCount={numberOfGroupShellCount(groupShellKey)}
                />
              ),
            )}
          </S.GroupShellWrapper>
        ),
      )}
    </S.TableWrapper>
  );
};

export default ExpeditedProgramSingleTypeTable;
