"use client";

import styled, { css } from "styled-components";

export const ItemsWrapper = styled.ul<{ $multiStage?: number }>`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;

  ${({ $multiStage }) =>
    $multiStage &&
    $multiStage > 0 &&
    css`
      display: block;
      width: 100%;
      column-count: ${$multiStage};
      column-gap: 1.2rem;
    `}
`;

export const Item = styled.li<{ $multiStage?: number }>`
  position: relative;
  width: 100%;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 145%;
  padding-left: 0.9rem;

  ${({ $multiStage }) =>
    $multiStage &&
    $multiStage > 0 &&
    css`
      break-inside: avoid-column;
      margin-bottom: 0.4rem;

      &:last-child {
        margin-bottom: 0;
      }
    `}

  &::before {
    position: absolute;
    top: 0.85rem;
    left: 0.2rem;
    content: "";
    width: 0.3rem;
    height: 0.3rem;
    background-color: var(--Color-Common-Icon-Tertiary, #7c899c);
    border-radius: 50%;
  }
`;
