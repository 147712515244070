import React from "react";

const IconQuotes = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.77556 14.6207C0.620268 13.3409 0 11.9055 0 9.57871C0 5.48424 2.75587 1.81443 6.7635 0L7.76513 1.61205C4.02445 3.72245 3.29314 6.46107 3.00151 8.18776C3.60384 7.86254 4.39235 7.74907 5.16516 7.82394C7.1886 8.0193 8.78358 9.75185 8.78358 11.9055C8.78358 12.9915 8.36998 14.0329 7.63376 14.8008C6.89753 15.5686 5.89901 16 4.85783 16C4.28205 15.9948 3.71301 15.8701 3.18385 15.6333C2.65469 15.3965 2.17597 15.0523 1.77556 14.6207ZM12.992 14.6207C11.8367 13.3409 11.2164 11.9055 11.2164 9.57871C11.2164 5.48424 13.9723 1.81443 17.9799 0L18.9816 1.61205C15.2409 3.72245 14.5096 6.46107 14.2179 8.18776C14.8203 7.86254 15.6088 7.74907 16.3816 7.82394C18.405 8.0193 20 9.75185 20 11.9055C20 12.9915 19.5864 14.0329 18.8502 14.8008C18.114 15.5686 17.1154 16 16.0743 16C15.4985 15.9948 14.9294 15.8701 14.4003 15.6333C13.8711 15.3965 13.3924 15.0523 12.992 14.6207Z"
        fill="#CBD0D8"
      />
    </svg>
  );
};

IconQuotes.displayName = "Quotes";
export default IconQuotes;
