import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";
import type { ClinicalTrialsSection } from "@/types/clinicalTrials.types";

interface UseHandleGroupShellReturn {
  handleClickToClearResultsGroupShell: () => void;
  handleKeyDownToClearResultsGroupShell: (event: KeyboardEvent) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: ClinicalTrialsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useHandleClinicalTrialsResultsGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();

  const handleClearGroupShell = () => {
    const clearClinicalResultsGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        "Clinical Trials": {
          ...entryData["Clinical Trials"],
          "Clinical Results": {
            "Results Classification": {
              ...entryData["Clinical Trials"]?.["Clinical Results"]?.[
                "Results Classification"
              ],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    if (info.groupTableKey === "Clinical Results") {
      setEntryData(clearClinicalResultsGroupShell());
      changeCurrentHistory({
        entryData: clearClinicalResultsGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  };

  const handleClickToClearResultsGroupShell = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShell();
    }
  };

  const handleKeyDownToClearResultsGroupShell = (event: KeyboardEvent) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearGroupShell();
    }
  };

  return {
    handleClickToClearResultsGroupShell,
    handleKeyDownToClearResultsGroupShell,
  };
};
