import { Suspense } from "react";

import Dim from "@/components/shared/ui/Dim/Dim";
import Loader from "@/components/shared/ui/Loader/Loader";
import { setDealTypeDisplayName } from "@utils/returnDealTypeDisplayName/returnDealTypeDisplayName";

import Pagination from "./components/Pagination/Pagination";
import PressListTable from "./components/PressListTable/PressListTable";
import PressListTableFilter from "./components/PressListTableFilter/PressListTableFilter";
import { useSetScrollPosition } from "./hooks/useSetScrollPosition";
import * as S from "./PressListContainer.style";

const PressListContainer = () => {
  useSetScrollPosition();
  setDealTypeDisplayName();

  return (
    <S.PressListWrapper>
      <Suspense
        fallback={
          <Dim>
            <Loader />
          </Dim>
        }
      >
        <PressListTableFilter />
        <PressListTable />
        <Pagination />
      </Suspense>
    </S.PressListWrapper>
  );
};

export default PressListContainer;
