import {
  ReactNode,
  RefObject,
  createContext,
  createRef,
  useCallback,
  useContext,
  useMemo,
} from "react";

export const ArticleContentRefMapContext = createContext<Map<
  string,
  RefObject<HTMLElement>
> | null>(null);

export const GetArticleContentRefContext = createContext<
  ((refKey: string) => RefObject<HTMLElement>) | null
>(null);

interface Props {
  children: ReactNode;
}

export const ArticleContentRefMapProvider = ({ children }: Props) => {
  const articleRefMap = useMemo(
    () => new Map<string, RefObject<HTMLElement>>(),
    [],
  );

  const getArticleContentRef = useCallback(
    (refCode: string) => {
      if (articleRefMap.has(refCode) === false) {
        articleRefMap.set(refCode, createRef());
      }

      const ref = articleRefMap.get(refCode);

      if (ref === undefined) {
        throw new Error("Cannot find ref");
      }

      return ref;
    },
    [articleRefMap],
  );

  return (
    <GetArticleContentRefContext.Provider value={getArticleContentRef}>
      <ArticleContentRefMapContext.Provider value={articleRefMap}>
        {children}
      </ArticleContentRefMapContext.Provider>
    </GetArticleContentRefContext.Provider>
  );
};

export const useArticleContentRefMap = () => {
  const articleRefMap = useContext(ArticleContentRefMapContext);

  if (articleRefMap === null) {
    throw new Error("Cannot find ArticleRefMapProvider");
  }

  return articleRefMap;
};

export const useGetArticleContentRef = (refKey: string) => {
  const getArticleContentRef = useContext(GetArticleContentRefContext);

  if (getArticleContentRef === null) {
    throw new Error("Cannot find ArticleRefMapProvider");
  }

  return getArticleContentRef(refKey);
};
