import React, { useEffect, useRef } from "react";

import { MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS } from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import TableCreatorDragButton from "@FillInTableContainer/shared/components/TableCreatorDragButton/TableCreatorDragButton";
import { TableCreatorShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import { isAddDeleteSubGroupShell } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";

import DealTermsMergeShell from "./components/DealTermsMergeShell/DealTermsMergeShell";
import * as S from "./DealTermsMergeSubGroupShell.style";
import { useTableCreatorMergeAcquisitionSubGroupShell } from "./hooks/useTableCreatorMergeAcquisitionSubGroupShell";
import SubGroupShellDividerPlusBar from "../../../../../../../DealTermsLicenseAgreementGroupTable/components/DealTermsLicenseAgreementTable/components/DealTermsLicenseAgreementGroupShellSection/components/DealTermsLicenseAgreementGroupShell/components/DealTermsLicenseAgreementSubGroupShell/components/SubGroupShellDividerPlusBar/SubGroupShellDividerPlusBar";

export interface PositionInfoProps {
  groupTableKey: DealsSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  groupShellIndex: number;
  tableKey: string | null;
  tableIndex: number;
  subGroupShellKey: string | null;
  subGroupShellIndex: number;
  subGroupShellCount: number;
}

interface Props extends PositionInfoProps {
  subGroupShellCount: number;
  shellList: TableCreatorShellList;
  isFocusedGroupShell: boolean;
  isClearGroupShell: boolean;
  isDeleteGroupShell: boolean;
  isAlertGroupShell: boolean;
  handleDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => void;
  handleDragEnd: (groupTableKey: string) => void;
  handleDragOn: () => void;
}

const isFocusSubGroupShellGroupShellKeyList: string[] = [
  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.upfrontPayment,
  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.contingentPayment,
  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.totalPayment,
  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.mergerFinancialTerms,
  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.otherFinancialTerms,
  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.disclosedPaymentsSum,
];
const isDragSubGroupShellList: string[] = [
  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.contingentPayment,
];

const DealTermsMergeSubGroupShell = ({
  groupShellIndex,
  groupShellKey,
  groupTableIndex,
  groupTableKey,
  tableIndex,
  tableKey,
  subGroupShellCount,
  subGroupShellIndex,
  subGroupShellKey,
  shellList,
  isAlertGroupShell,
  isClearGroupShell,
  isDeleteGroupShell,
  isFocusedGroupShell,
  handleDragEnd,
  handleDragOn,
  handleDragStart,
}: Props) => {
  const positionPropsInfo = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    subGroupShellKey,
    subGroupShellIndex,
    tableKey,
    tableIndex,
  };

  const articleSection = useArticleSection();
  const selectButtonRef = useRef<HTMLDivElement>(null);

  const isPossibleSelected =
    !!groupShellKey &&
    isFocusSubGroupShellGroupShellKeyList.includes(subGroupShellKey || "");

  const {
    focusStatus,
    isFocused,
    isEnableEdit,
    isClearSubGroupShell,
    isDeleteSubGroupShell,
    isLastSubGroupShell,
    isFocusedAddButton,
    handleClickToDeleteSubGroupShell,
    handleFocusSubGroupShell,
    clickAddSubGroupShellButton,
    handleDefaultFocus,
    changeFocusStatus,
    changePositionInfo,
    changePositionInfoToDefault,
    keydownClearOrDeleteSubGroupShellData,
  } = useTableCreatorMergeAcquisitionSubGroupShell({
    positionPropsInfo,
    subGroupShellCount,
  });

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;
        const clickSelectButton = selectButtonRef.current?.contains(target);

        if (clickSelectButton) return;
        if (target.dataset.button) return;

        handleDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleDefaultFocus, isFocused]);

  const changeFocusStatusForDragging = () => {
    changePositionInfo();
    changeFocusStatus({
      type: "dragging",
      container: "subGroupShell",
    });
  };

  const changeFocusStatusForDragEnd = () => {
    changePositionInfoToDefault();
    changeFocusStatus({
      type: "default",
      container: "noFocus",
    });
  };

  const isDisplayClearDeleteButton =
    (isClearSubGroupShell || isDeleteSubGroupShell) && isEnableEdit;

  const isDraggingSubGroupShell =
    isEnableEdit &&
    isDragSubGroupShellList.includes(subGroupShellKey || "") &&
    subGroupShellCount > 1;

  return (
    <>
      {isAddDeleteSubGroupShell(articleSection, subGroupShellKey) && (
        <SubGroupShellDividerPlusBar
          onClick={(event) => {
            clickAddSubGroupShellButton(event, {
              tableIndexParameter: tableIndex,
              groupShellIndexParameter: groupShellIndex,
              groupShellKeyParameter: groupShellKey,
              subGroupShellIndexParameter: subGroupShellIndex,
              subGroupShellKeyParameter: subGroupShellKey,
            });
          }}
          isLastPosition={false}
          isVisibility={isFocusedAddButton}
          subGroupShellKey={subGroupShellKey}
        />
      )}
      <S.GroupShellOutsideWrapper
        onKeyDown={keydownClearOrDeleteSubGroupShellData}
      >
        {isDisplayClearDeleteButton && (
          <S.DeleteTableButtonWrapper $isDragging={isDraggingSubGroupShell}>
            <S.DeleteTableButton
              data-button="delete-button"
              onClick={handleClickToDeleteSubGroupShell}
            >
              <img src={IconXMark} alt="button for clear Row" />
              {focusStatus.type === "clear" ? `Clear` : null}
              {focusStatus.type === "delete" ? `Delete` : null}
            </S.DeleteTableButton>
          </S.DeleteTableButtonWrapper>
        )}
        <S.GroupShellWrapper
          isFocused={isFocused}
          isDragging={isFocused && focusStatus.type === "dragging"}
        >
          <S.SelectWrapper isPossibleSelected={isPossibleSelected}>
            <S.GroupShellSelectButtonDividerWrapper
              ref={selectButtonRef}
              onClick={handleFocusSubGroupShell}
              isPossibleSelected={isPossibleSelected}
            >
              <S.GroupShellSelectButtonDivider className="select-button divider" />
              <S.GroupShellSelectButton className="select-button button">
                SELECT
              </S.GroupShellSelectButton>
            </S.GroupShellSelectButtonDividerWrapper>
            {isDraggingSubGroupShell && (
              <S.DragButton
                data-button="drag-button"
                onClick={(e) => {
                  e.stopPropagation();
                  changeFocusStatusForDragging();
                }}
                onDragStart={(e) => {
                  handleDragStart(e, subGroupShellIndex);
                  changeFocusStatusForDragging();
                }}
                onDragEnd={() => {
                  if (changeFocusStatusForDragEnd) {
                    handleDragEnd(subGroupShellKey || "");
                    changeFocusStatusForDragEnd();
                  }
                }}
                onDrag={handleDragOn}
                draggable
              >
                <TableCreatorDragButton
                  dragContainer="subGroupShell"
                  isDragging={isFocused && focusStatus.type === "dragging"}
                  onClick={(e) => {
                    e.stopPropagation();
                    changeFocusStatusForDragging();
                  }}
                />
              </S.DragButton>
            )}
            <S.GroupShell>
              {shellList.map(({ shellKey }, shellListIndex) => {
                return (
                  <DealTermsMergeShell
                    key={`shellList-${String(shellListIndex)}`}
                    groupTableKey={groupTableKey}
                    groupTableIndex={groupTableIndex}
                    groupShellKey={groupShellKey}
                    groupShellIndex={groupShellIndex}
                    subGroupShellIndex={subGroupShellIndex}
                    subGroupShellKey={subGroupShellKey}
                    shellKey={shellKey}
                    shellIndex={0}
                    tableKey={tableKey}
                    tableIndex={tableIndex}
                    isFocusedGroupShell={isFocusedGroupShell}
                    isClearGroupShell={isClearGroupShell}
                    isDeleteGroupShell={isDeleteGroupShell}
                    isAlertGroupShell={isAlertGroupShell}
                    isClearSubGroupShell={isClearSubGroupShell}
                    isDeleteSubGroupShell={isDeleteSubGroupShell}
                  />
                );
              })}
            </S.GroupShell>
          </S.SelectWrapper>
        </S.GroupShellWrapper>
      </S.GroupShellOutsideWrapper>
      {isAddDeleteSubGroupShell(articleSection, subGroupShellKey) &&
        isLastSubGroupShell && (
          <SubGroupShellDividerPlusBar
            onClick={(event) => {
              clickAddSubGroupShellButton(event, {
                tableIndexParameter: tableIndex,
                groupShellIndexParameter: groupShellIndex,
                groupShellKeyParameter: groupShellKey,
                subGroupShellIndexParameter: subGroupShellIndex + 1,
                subGroupShellKeyParameter: subGroupShellKey,
              });
            }}
            isLastPosition
            isVisibility={isFocusedAddButton}
            subGroupShellKey={subGroupShellKey}
          />
        )}
    </>
  );
};

export default DealTermsMergeSubGroupShell;
