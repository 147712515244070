import { useModalityCategoryValue } from "@FillInTableContainer/shared/context/ModalityCategoryContext";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  ValidatedInfoList,
  useValidatedInTableCreator,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

interface UseCheckValidationInTableCreatorReturn {
  handleCheckValidationHalf: () => boolean;
  handleCheckValidationFull: () => boolean;
}

export const useCheckValidationInClinicalTrialStatusTableCreator =
  (): UseCheckValidationInTableCreatorReturn => {
    const entryData = useEntryDataValue(getPressIdFromURL());
    const { changeValidatedInfo } = useValidatedInTableCreator();
    const { changeInfo } = useTableCreatorPositionInfo();
    const { changeFocusStatus } = useTableCreatorFocusStatus();
    const { updateModalityCategory } = useModalityCategoryValue();

    const checkModalityCategory = () => {
      const drugProfileTableList =
        entryData?.["Clinical Trial Status"]?.["Drug Profile"];
      const modalityValues = drugProfileTableList?.map((table) =>
        table.Modality.value?.map((shell) => shell.text),
      );

      const valueInfo = modalityValues?.flatMap((table, tableIndex) =>
        table.map((shell) => {
          if (updateModalityCategory[shell] === "") {
            return {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex,
              groupShellKey: null,
              groupShellIndex: 0,
              shellKey: "Modality",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            };
          }
          return null;
        }),
      );
      return valueInfo?.filter((item) => item !== null);
    };

    const checkDrugProfileTable = () => {
      const drugProfileTableList =
        entryData?.["Clinical Trial Status"]?.["Drug Profile"];

      const valueInfo = drugProfileTableList?.flatMap(
        (tableInfo, tableInfoIndex) => {
          const checkDrugName =
            tableInfo?.["Brand Name"].value.length === 0 &&
            tableInfo?.["INN Name"].value.length === 0 &&
            tableInfo?.["Code Name"].value.length === 0;

          if (!checkDrugName) return null;
          return [
            {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex: tableInfoIndex,
              groupShellKey: "Drug Name",
              groupShellIndex: 0,
              shellKey: "Brand Name",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            },
            {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex: tableInfoIndex,
              groupShellKey: "Drug Name",
              groupShellIndex: 0,
              shellKey: "INN Name",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            },
            {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex: tableInfoIndex,
              groupShellKey: "Drug Name",
              groupShellIndex: 0,
              shellKey: "Code Name",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            },
          ];
        },
      );

      const sortedValidatedInfo =
        valueInfo?.filter((info) => info !== null) || [];

      return sortedValidatedInfo;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const checkClinicalTrialDesignOneTable = () => {
      const clinicalTrialDesignOneTableList =
        entryData?.["Clinical Trial Status"]?.["Clinical Trial Design-1"];

      const clinicalTrialDesignOneTableListLength =
        clinicalTrialDesignOneTableList?.length || 0;

      if (clinicalTrialDesignOneTableListLength === 0) return [];

      const valueInfo = clinicalTrialDesignOneTableList?.flatMap(
        (tableInfo, tableInfoIndex) => {
          const checkTrialIntervention =
            tableInfo["Trial Intervention"]?.length === 0;

          if (!checkTrialIntervention) return null;
          return [
            {
              groupTableKey: "Clinical Trial Design-1",
              groupTableIndex: 1,
              tableKey: "Clinical Design",
              tableIndex: tableInfoIndex,
              groupShellKey: null,
              groupShellIndex: 0,
              shellKey: "Trial Intervention",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            },
          ];
        },
      );

      const sortedValidatedInfo =
        valueInfo?.filter((info) => info !== null) || [];

      return sortedValidatedInfo;
    };

    const handleCheckValidationHalf = () => {
      if (
        checkDrugProfileTable().length === 0 &&
        checkModalityCategory()?.length === 0
        // checkClinicalTrialDesignOneTable().length === 0
      ) {
        changeInfo(DEFAULT_POSITION_INFO);
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
        return true;
      }

      const validatedList = [
        ...(checkDrugProfileTable() || []),
        ...(checkModalityCategory() || []),
        // ...(checkClinicalTrialDesignOneTable() || []),
      ];

      changeValidatedInfo(validatedList as ValidatedInfoList);
      return false;
    };

    const handleCheckValidationFull = () => {
      if (
        checkDrugProfileTable().length === 0 &&
        checkModalityCategory()?.length === 0
        // checkClinicalTrialDesignOneTable().length === 0
      ) {
        changeInfo(DEFAULT_POSITION_INFO);
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
        return true;
      }

      const validatedList = [
        ...(checkDrugProfileTable() || []),
        ...(checkModalityCategory() || []),
        // ...(checkClinicalTrialDesignOneTable() || []),
      ];

      changeValidatedInfo(validatedList as ValidatedInfoList);
      return false;
    };
    return {
      handleCheckValidationHalf,
      handleCheckValidationFull,
    };
  };
