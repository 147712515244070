import styled from "styled-components";

import {
  DropdownListWrapper,
  ListItem,
  SectionTitle,
} from "@FillInTableContainer/shared/styles/dropdown.style";

export const ListWrapper = styled(DropdownListWrapper)``;
export const ListSectionTitle = styled(SectionTitle)``;

export const DictionaryItem = styled(ListItem)``;

export const CategorySubTitle = styled.div`
  display: flex;
  padding: var(--Spacing-8, 0.8rem);
  color: var(--Color-Common-Text-Secondary, #515c6c);
  font-size: 1.2rem;
  font-weight: 600;

  border-top: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  border-bottom: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
`;
