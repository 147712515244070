import styled from "styled-components";

import {
  BorderWrapper,
  DefaultDeleteTableButton,
  DefaultDeleteTableButtonWrapper,
  DefaultShellWrapper,
} from "@FillInTableContainer/shared/styles/shell.style";

export const ShellWrapper = styled(DefaultShellWrapper)``;

export const ShellBorderWrapper = styled(BorderWrapper)``;

export const DeleteTableButtonWrapper = styled(
  DefaultDeleteTableButtonWrapper,
)``;
export const DeleteTableButton = styled(DefaultDeleteTableButton)``;
