import React from "react";

import * as S from "./Layout.style";

interface Props {
  children: React.ReactNode;
}
const LayoutCenter = ({ children }: Props) => {
  return <S.LayoutCenterWrapper>{children}</S.LayoutCenterWrapper>;
};

export default LayoutCenter;
