import React from "react";

import * as S from "./TableSwitchButton.style";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  width?: string;
  height?: string;
}

const SwitchIcon = ({ width, height }: { width: string; height: string }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="18.5"
      height="18.5"
      rx="9.25"
      fill="#1BAEFE"
    />
    <rect
      x="0.75"
      y="0.75"
      width="18.5"
      height="18.5"
      rx="9.25"
      stroke="#0198E9"
      strokeWidth="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2247 4.22481C12.4688 3.98073 12.8646 3.98073 13.1086 4.22481L15.7753 6.89147C16.0194 7.13555 16.0194 7.53128 15.7753 7.77536L13.1086 10.442C12.8646 10.6861 12.4688 10.6861 12.2247 10.442C11.9807 10.1979 11.9807 9.80222 12.2247 9.55814L13.8245 7.95841H10C8.87244 7.95841 7.95835 8.8725 7.95835 10.0001V13.8245L9.55808 12.2248C9.80216 11.9807 10.1979 11.9807 10.442 12.2248C10.686 12.4689 10.686 12.8646 10.442 13.1087L7.7753 15.7754C7.53122 16.0194 7.13549 16.0194 6.89141 15.7754L4.22475 13.1087C3.98067 12.8646 3.98067 12.4689 4.22475 12.2248C4.46882 11.9807 4.86455 11.9807 5.10863 12.2248L6.70835 13.8245V10.0001C6.70835 8.18214 8.18208 6.70841 10 6.70841H13.8245L12.2247 5.10869C11.9807 4.86461 11.9807 4.46888 12.2247 4.22481Z"
      fill="white"
    />
  </svg>
);

const TableSwitchButton = ({
  width = "2rem",
  height = "2rem",
  ...rest
}: Props) => {
  return (
    <S.SwitchButton type="button" $width={width} $height={height} {...rest}>
      {SwitchIcon({ width, height })}
    </S.SwitchButton>
  );
};

export default TableSwitchButton;
