import { DISABLED_PHASE_VALUES } from "@frontend/common";
import {
  ParagraphSectionLayout,
  ParagraphTitle,
} from "@frontend/common/src/components/ArticleContent";

import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";

import DrugProfileTableGroup from "./components/DrugProfileTableGroup/DrugProfileTableGroup";
import { checkDrugProfileValue } from "./components/DrugProfileTableGroup/utils/checkDrugProfileValue";
import { convertPhaseToDevelopmentStage } from "./components/DrugProfileTableGroup/utils/convertPhaseToDevelopmentStage";
import * as S from "./ExpeditedProgramParagraphDrugProfile.style";

const PARAGRAPH_TITLE = "Drug Profile";
const PARAGRAPH_CODE = "TC303";

const ExpeditedProgramParagraphDrugProfile = () => {
  const paragraph = useParagraphEditedData(PARAGRAPH_CODE);

  const isCheckValue = checkDrugProfileValue(paragraph);

  if (!paragraph) return null;
  if (!isCheckValue) return null;

  const isHideRowByStage = paragraph.text["Drug profile"]
    .map(
      (drugProfileRow) =>
        drugProfileRow?.["Development Stage"]?.value?.map(
          (item) => item.text ?? "",
        ) ||
        drugProfileRow?.Phase?.value?.map(
          (item) => convertPhaseToDevelopmentStage(item.text ?? "") ?? "",
        ) ||
        [],
    )
    .map((itemList) =>
      itemList.filter((item) =>
        DISABLED_PHASE_VALUES.includes(item.toLocaleLowerCase()),
      ),
    )
    .every((item) => item.length > 0);

  return (
    <S.ParagraphDrugProfileWrapper
      className={`is-hide-drug-profile-table-${isHideRowByStage}`}
    >
      <ParagraphSectionLayout>
        <ParagraphTitle title={PARAGRAPH_TITLE} />
        <S.ParagraphDrugProfileContentWrapper>
          <DrugProfileTableGroup
            key={paragraph.updateDt}
            drugProfile={paragraph}
          />
        </S.ParagraphDrugProfileContentWrapper>
      </ParagraphSectionLayout>
    </S.ParagraphDrugProfileWrapper>
  );
};

export default ExpeditedProgramParagraphDrugProfile;
