import styled from "styled-components";

import IconCheck from "@images/fillInTable/tableCreator/icon-check-white.svg";

export const TrialInterventionDrugListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.2rem;
  border-radius: 0.4rem;
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Primary, #fff);
`;

export const DrugNameLabel = styled.label`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.68rem;
  width: 100%;
  min-height: 2.4rem;
  padding: 0.48rem;
  cursor: pointer;
`;

export const DrugNameCheckbox = styled.input`
  display: flex;
  all: unset;
  width: 1.44rem;
  height: 1.44rem;
  border-radius: var(--Border-Radius-2, 0.2rem);
  border: 0.1rem solid var(--Color-Common-Border-Interactive-Secondary, #b1b8c4);

  &:checked {
    border: 0.1rem solid var(--Color-Common-Border-Interactive-Primary, #1baefe);
    background: url(${IconCheck}) no-repeat center center
      var(--Color-Common-Bg-Interactive-Primary, #1baefe);
  }

  &:hover {
    border: 0.1rem solid
      var(--Color-Common-Bg-Interactive-Primary-Hovered, #0198e9);
    background: url(${IconCheck}) no-repeat center center
      var(--Color-Common-Bg-Interactive-Primary-Hovered, #0198e9);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
      0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }
`;

export const DrugNameTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.2rem;
  flex: 1;
  min-height: 1.6rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-weight: 400;
`;

export const DrugNameNumber = styled.span`
  display: flex;
  align-items: center;
  min-height: 1.6rem;
`;

export const DrugNameText = styled.span`
  display: flex;
  align-items: center;
  min-height: 1.6rem;
`;
