import Modal from "@atoms/Modal/Modal";
import { ROUTE_PATHS } from "@routes/routePath";

import * as S from "./UrlPageEntryGuide.style";

const UrlPageEntryGuide = () => {
  return (
    <Modal>
      <S.UrlPageEntryModalWrapper>
        <S.ModalTitle>Another editor is currently working</S.ModalTitle>
        <S.ModalText>
          Access is not available because there is another editor who currently
          working on the press you tried to access. Please try again after the
          editor has completed the task.
        </S.ModalText>
        <S.ModalButtonWrapper>
          <S.PreviousPageButton
            onClick={() => {
              window.history.back();
            }}
          >
            Go Back to Previous Page
          </S.PreviousPageButton>
          <S.MovePressListButton to={ROUTE_PATHS.pressList}>
            Return to Press List
          </S.MovePressListButton>
        </S.ModalButtonWrapper>
      </S.UrlPageEntryModalWrapper>
    </Modal>
  );
};

export default UrlPageEntryGuide;
