import React from "react";

import * as S from "./CompanyImageGroup.style";
import { CompanyImageGroupType } from "./types/companyImageGroup.type";
import CompanyImage from "../CompanyImage/CompanyImage";

interface Props {
  type: CompanyImageGroupType;
  containerSize: number;
  imageSize: number | string;
  imageUrlList?: string[];
  imageComponentList?: JSX.Element[];
  className?: string;
}

const CompanyImageGroup = ({
  type,
  imageSize,
  containerSize,
  imageUrlList,
  imageComponentList,
  className,
}: Props) => {
  const currentImageList = imageComponentList ?? imageUrlList ?? [];

  const currentSize =
    type === "vertical" && currentImageList?.length > 1 ? 28 : imageSize;
  const currentContainerSize =
    type === "vertical" && currentImageList?.length > 1 ? 32 : containerSize;

  return (
    <S.GroupWrapper $type={type} $logoLength={currentImageList.length > 1}>
      {imageComponentList &&
        imageComponentList.map((imageComponent, index) => (
          <CompanyImage
            key={`image-${String(index)}`}
            imageSize={currentSize}
            containerSize={currentContainerSize}
            imageComponent={imageComponent}
            borderRadius="0.6rem"
            className={`company-image company-${index} ${className}`}
          />
        ))}
      {imageUrlList &&
        imageUrlList.map((imageUrl, index) => (
          <CompanyImage
            key={`image-${String(index)}`}
            imageSize={currentSize}
            containerSize={currentContainerSize}
            logoUrl={imageUrl}
            borderRadius="0.6rem"
            className={`company-image company-${index} ${className}`}
          />
        ))}
    </S.GroupWrapper>
  );
};

export default CompanyImageGroup;
