import { useCallback } from "react";

import { useTableCreatorPositionInfo } from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useUnresolvedCommentInfoWithPosition } from "@FillInTableContainer/shared/context/UnresolvedCommentInfoWithPositionContext";

export const useCheckDisabledDelete = () => {
  const { unresolvedCommentInfos } = useUnresolvedCommentInfoWithPosition();
  const { info } = useTableCreatorPositionInfo();

  const changeShellKey = (initialShellKey: string | null): string => {
    if (initialShellKey === null) return "";
    if (initialShellKey === "Design Group Information") return "Drug Name";
    if (initialShellKey === "Endpoint Group Information") return "Group Data";
    return initialShellKey;
  };

  const checkDisabledDeleteTable = useCallback((): boolean => {
    return (
      unresolvedCommentInfos.filter(
        (item) =>
          item.groupTableKey === info.groupTableKey &&
          item.tableIndex === info.tableIndex,
      ).length > 0
    );
  }, [info, unresolvedCommentInfos]);

  const checkDisabledDeleteGroupShell = useCallback((): boolean => {
    return (
      unresolvedCommentInfos.filter(
        (item) =>
          item.groupTableKey === info.groupTableKey &&
          item.tableIndex === info.tableIndex &&
          item.groupShellKey === info.groupShellKey &&
          item.groupShellIndex === info.groupShellIndex,
      ).length > 0
    );
  }, [info, unresolvedCommentInfos]);

  const checkDisabledDeleteShell = useCallback((): boolean => {
    if (info.shellKey === "Endpoint Group Information") {
      return (
        unresolvedCommentInfos.filter(
          (item) =>
            item.groupTableKey === info.groupTableKey &&
            item.groupTableIndex === info.groupTableIndex &&
            item.tableIndex === info.tableIndex &&
            item.tableKey === info.tableKey &&
            item.groupShellKey === info.groupShellKey &&
            item.shellKey === changeShellKey(info.shellKey) &&
            item.shellIndex === info.shellIndex,
        ).length > 0
      );
    }
    return (
      unresolvedCommentInfos.filter(
        (item) =>
          item.groupTableKey === info.groupTableKey &&
          item.groupTableIndex === info.groupTableIndex &&
          item.tableIndex === info.tableIndex &&
          item.tableKey === info.tableKey &&
          item.groupShellKey === info.groupShellKey &&
          item.groupShellIndex === info.groupShellIndex &&
          item.shellKey === changeShellKey(info.shellKey) &&
          item.shellIndex === info.shellIndex,
      ).length > 0
    );
  }, [info, unresolvedCommentInfos]);

  return {
    checkDisabledDeleteTable,
    checkDisabledDeleteGroupShell,
    checkDisabledDeleteShell,
  };
};
