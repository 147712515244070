import styled, { css } from "styled-components";

import {
  BorderWrapper,
  DefaultDeleteTableButton,
  DefaultDeleteTableButtonWrapper,
  DefaultShellWrapper,
} from "@FillInTableContainer/shared/styles/shell.style";

export const ShellWrapper = styled(DefaultShellWrapper)``;

export const ShellBorderWrapper = styled(BorderWrapper)``;

export const ShellTitle = styled.h5<{
  isFocused: boolean;
  isClearShell: boolean;
  isDeleteShell: boolean;
  isAlertShell: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  width: 100%;
  height: 2.4rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--Color-Common-Text-Primary, #2a2f37);

  ${({ isFocused }) =>
    isFocused &&
    css`
      color: var(--Color-Common-Text-Primary, #2a2f37);
    `}

  ${({ isClearShell }) =>
    isClearShell &&
    css`
      color: var(--Color-Common-Text-Primary, #2a2f37);
    `}

  ${({ isDeleteShell }) =>
    isDeleteShell &&
    css`
      color: var(--Color-Common-Text-Primary, #2a2f37);
    `}

    ${({ isAlertShell }) =>
    isAlertShell &&
    css`
      color: var(--Color-Common-Text-Primary, #2a2f37);
    `}
`;

export const DeleteTableButtonWrapper = styled(
  DefaultDeleteTableButtonWrapper,
)``;
export const DeleteTableButton = styled(DefaultDeleteTableButton)``;
