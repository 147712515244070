import { ExpeditedProgramParagraphContents } from "@EditArticleContainer/shared/types/paragraphContents/paragraphContents.types";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useCreateDrugProfileContent } from "./hooks/useCreateDrugProfileContent";
import { useCreateExpeditedProgramTableContent } from "./hooks/useCreateExpeditedProgramTableContent";
import { useCreateKeyCommentsContent } from "./hooks/useCreateKeyCommentsContent";
import { useCreateTitleContent } from "./hooks/useCreateTitleContent";
import { useCreateTopicContent } from "./hooks/useCreateTopicContent";

export const useCreateExpeditedProgramContent = () => {
  const createTitle = useCreateTitleContent();
  const createTopic = useCreateTopicContent();
  const createDrugProfile = useCreateDrugProfileContent();
  const createKeyComments = useCreateKeyCommentsContent();
  const createExpeditedProgram = useCreateExpeditedProgramTableContent();

  return async (): Promise<ExpeditedProgramParagraphContents> => {
    const title = createTitle();
    const topic = createTopic();
    const drugProfile = createDrugProfile();
    const keyComments = createKeyComments();
    const expeditedProgram = createExpeditedProgram();

    return {
      "Article Id": Number(getPressIdFromURL()),
      TC301: title,
      TC302: topic,
      TC303: drugProfile,
      TC306: keyComments,
      TC314: expeditedProgram,
    };
  };
};
