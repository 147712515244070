import { ARTICLE_CONTENTS_DEAL_TERMS_KEYS as DEAL_TERMS_KEYS } from "../../../constants";

export const ARTICLE_DETAIL_DEAL_TERMS_PARAGRAPH_TITLE = "Deal Terms";
export const ARTICLE_DETAIL_DEAL_TERMS_TABLE_TITLE = {
  rightsScope: "Rights Scope",
  financialTerms: "Financial Terms",
  keyAssetsMainUsage: "Key Assets / Main Usage",
} as const;

export const ARTICLE_DETAIL_DEAL_TERMS_TABLE_DESCRIPTION = {
  financialTerms: "Total Payment = Upfront + Contingent Payment",
} as const;

export const ARTICLE_DETAIL_DEAL_TERMS_SC_LA_SHELL_TITLE = {
  [DEAL_TERMS_KEYS.licensorName]: "Licensor / Originator",
  [DEAL_TERMS_KEYS.licensorResponsibilityRights]: "Responsibility & Rights",
  [DEAL_TERMS_KEYS.licenseeName]: "Licensee / Partner",
  [DEAL_TERMS_KEYS.licenseeResponsibilityRightsScLa]: "Responsibility & Rights",
  [DEAL_TERMS_KEYS.upfrontPayment]: "Upfront",
  [DEAL_TERMS_KEYS.milestonePayment]: "Milestones",
  [DEAL_TERMS_KEYS.otherPaymentScLa]: "Other Payment",
  [DEAL_TERMS_KEYS.totalPaymentScLa]: "Total Payment",
  [DEAL_TERMS_KEYS.royalty]: "Royalty",
  [DEAL_TERMS_KEYS.otherFinancialTermsScLa]: "Other Financial Terms",
} as const;

export const ARTICLE_DETAIL_DEAL_TERMS_MA_SHELL_TITLE = {
  [DEAL_TERMS_KEYS.licensorName]: "Acquired Company",
  [DEAL_TERMS_KEYS.licenseeName]: "Acquiring Company",
  [DEAL_TERMS_KEYS.licensorResponsibilityRights]: "Responsibility & Rights",
  [DEAL_TERMS_KEYS.licenseeResponsibilityRightsMa]: "Responsibility & Rights",
  [DEAL_TERMS_KEYS.drug]: "Key Asset(s)",
  [DEAL_TERMS_KEYS.indication]: "Main Usage",
  [DEAL_TERMS_KEYS.totalPaymentMa]: "Total Payment",
  [DEAL_TERMS_KEYS.pricePerShare]: "Price per Share",
  [DEAL_TERMS_KEYS.numberOfAcquiringShares]: "Number of Acquiring Shares",
  [DEAL_TERMS_KEYS.otherFinancialTermsMa]: "Financial Term Details",
  [DEAL_TERMS_KEYS.mergerFinancialTermsMa]: "Merger Financial Terms",
  [DEAL_TERMS_KEYS.upfrontPaymentMa]: "Upfront Payment",
  [DEAL_TERMS_KEYS.upfrontPricePerShareMa]: "Price per Share",
  [DEAL_TERMS_KEYS.contingentPaymentMa]: "Contingent Payment",
  [DEAL_TERMS_KEYS.contingentPricePerShareMa]: "Price per Share",
  [DEAL_TERMS_KEYS.contingentMilestoneMa]: "Milestone",
} as const;

export const ARTICLE_DETAIL_DEAL_TERMS_MA_GROUP_SHELL_TITLE = {
  [DEAL_TERMS_KEYS.upfrontPayment]: "Upfront",
  [DEAL_TERMS_KEYS.contingentPayment]: "Contingency",
  [DEAL_TERMS_KEYS.totalPaymentMa]: "Total",
};
