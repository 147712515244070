import React from "react";

import ErrorPageView from "@/components/shared/ui/ErrorPageView/ErrorPageView";

interface Props {
  errorStatus: number;
  handleButtonClick: () => void;
}

const InternalServerError = ({ errorStatus, handleButtonClick }: Props) => {
  return (
    <ErrorPageView
      title="Internal Server Error"
      message="Sorry, we're experiencing technical difficulties.
  Please try again later or contact our support team for assistance."
      buttonLabel="Reload page"
      handleButtonClick={handleButtonClick}
      errorStatus={errorStatus}
    />
  );
};

export default InternalServerError;
