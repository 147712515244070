import React from "react";

import * as S from "./LoginErrorModal.style";

const LoginErrorModal = ({ closeModal }: { closeModal: () => void }) => {
  const handleDimmedClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.target !== e.currentTarget) return;
    closeModal();
  };

  return (
    <S.Dimmed onClick={handleDimmedClick}>
      <S.ModalWrapper>
        <S.ModalTitle>Login Error</S.ModalTitle>
        <S.ModalText>
          <p>You entered an incorrect Email Address or password.</p>
          <p>Please double-check your input</p>
        </S.ModalText>
        <S.ModalButtonWrapper>
          <S.OkButton onClick={closeModal}>OK</S.OkButton>
        </S.ModalButtonWrapper>
      </S.ModalWrapper>
    </S.Dimmed>
  );
};

export default LoginErrorModal;
