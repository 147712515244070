"use client";

import styled, { css } from "styled-components";

import { TABLE_WIDTH } from "../../../constants/edbMain/tableWidth.constants";
import { TableTypeKey } from "../../../types/edbMain/tableType.types";

export const HeadShellWrapper = styled.div<{
  $titleKey: string;
  $tableTypeKey: TableTypeKey;
}>`
  width: 100%;
  flex-grow: 1;
  min-width: ${({ $tableTypeKey, $titleKey }) =>
    TABLE_WIDTH[$tableTypeKey][$titleKey].minWidth};
  max-width: ${({ $tableTypeKey, $titleKey }) =>
    TABLE_WIDTH[$tableTypeKey][$titleKey].maxWidth};
`;
export const HeadShell = styled.div<{
  $isOrdering: boolean;
  $isNotOrderingKey: boolean;
}>`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  padding: var(--Spacing-8, 0.8rem);
  gap: var(--Spacing-4, 0.4rem);

  border-left: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 130%;
  white-space: normal;
  word-break: keep-all;

  ${({ $isNotOrderingKey }) =>
    !$isNotOrderingKey &&
    css`
      cursor: pointer;

      &:active {
        background: var(--Color-Common-Bg-Tertiary, #cbd0d8);
      }
    `}

  &:first-child {
    border: none;
  }

  ${({ $isOrdering }) =>
    $isOrdering &&
    css`
      color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
    `}
`;

export const OrderingIcon = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  > svg {
    width: 1.2rem;
    height: 1.2rem;
    path {
      fill: #0198e9;
    }
  }
`;

export const HoverOrderingIcon = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  > svg {
    width: 1.2rem;
    height: 1.2rem;
    path {
      fill: #7c899c;
    }
  }
`;
