import DealsParagraphDealPurpose from "../components/DealsParagraphDealPurpose/DealsParagraphDealPurpose";
import DealsParagraphDrugProfile from "../components/DealsParagraphDrugProfile/DealsParagraphDrugProfile";
import DealsParagraphKeyComments from "../components/DealsParagraphKeyComments/DealsParagraphKeyComments";
import DealsParagraphTopic from "../components/DealsParagraphTopic/DealsParagraphTopic";
import ExpeditedProgramParagraphDrugProfile from "../components/ExpeditedProgramParagraphDrugProfile/ExpeditedProgramParagraphDrugProfile";
import ExpeditedProgramParagraphExpeditedProgram from "../components/ExpeditedProgramParagraphExpeditedProgram/ExpeditedProgramParagraphExpeditedProgram";
import ExpeditedProgramParagraphKeyComments from "../components/ExpeditedProgramParagraphKeyComments/ExpeditedProgramParagraphKeyComments";
import ExpeditedProgramParagraphTopic from "../components/ExpeditedProgramParagraphTopic/ExpeditedProgramParagraphTopic";
import ParagraphAdditionalInformation from "../components/ParagraphAdditionalInformation/ParagraphAdditionalInformation";
import ParagraphClinicalResults from "../components/ParagraphClinicalResults/ParagraphClinicalResults";
import ParagraphClinicalTrialDesign from "../components/ParagraphClinicalTrialDesign/ParagraphClinicalTrialDesign";
import ParagraphCompanyProfile from "../components/ParagraphCompanyProfile/ParagraphCompanyProfile";
import ParagraphDealTerms from "../components/ParagraphDealTerms/ParagraphDealTerms";
import ParagraphDealTypeAndStatus from "../components/ParagraphDealTypeAndStatus/ParagraphDealTypeAndStatus";
import ParagraphDrugProfile from "../components/ParagraphDrugProfile/ParagraphDrugProfile";
import ParagraphKeyComments from "../components/ParagraphKeyComments/ParagraphKeyComments";
import ParagraphTopic from "../components/ParagraphTopic/ParagraphTopic";
import RegulatoryParagraphApprovalProcess from "../components/RegulatoryParagraphApprovalProcess /RegulatoryParagraphApprovalProcess";
import RegulatoryParagraphDrugProfile from "../components/RegulatoryParagraphDrugProfile/RegulatoryParagraphDrugProfile";
import RegulatoryParagraphKeyComments from "../components/RegulatoryParagraphKeyComments/RegulatoryParagraphKeyComments";
import RegulatoryParagraphTopic from "../components/RegulatoryParagraphTopic/RegulatoryParagraphTopic";
import VentureCapitalParagraphAdditionalInformation from "../components/VentureCapitalParagraph/VentureCapitalParagraphAdditionalInformation/VentureCapitalParagraphAdditionalInformation";
import VentureCapitalParagraphCompanyProfile from "../components/VentureCapitalParagraph/VentureCapitalParagraphCompanyProfile/VentureCapitalParagraphCompanyProfile";
import VentureCapitalParagraphDrugProfile from "../components/VentureCapitalParagraph/VentureCapitalParagraphDrugProfile/VentureCapitalParagraphDrugProfile";
import VentureCapitalParagraphFinancingInformation from "../components/VentureCapitalParagraph/VentureCapitalParagraphFinancingInformation/VentureCapitalParagraphFinancingInformation";
import VentureCapitalParagraphInvestors from "../components/VentureCapitalParagraph/VentureCapitalParagraphInvestors/VentureCapitalParagraphInvestors";
import VentureCapitalParagraphKeyComments from "../components/VentureCapitalParagraph/VentureCapitalParagraphKeyComments/VentureCapitalParagraphKeyComments";
import VentureCapitalParagraphRoundAndStatus from "../components/VentureCapitalParagraph/VentureCapitalParagraphRoundAndStatus/VentureCapitalParagraphRoundAndStatus";
import VentureCapitalParagraphTopic from "../components/VentureCapitalParagraph/VentureCapitalParagraphTopic/VentureCapitalParagraphTopic";

export const PARAGRAPH_CLINICAL_TRIALS = [
  { id: 0, component: ParagraphTopic },
  { id: 1, component: ParagraphClinicalResults },
  { id: 2, component: ParagraphClinicalTrialDesign },
  { id: 3, component: ParagraphDrugProfile },
  { id: 5, component: ParagraphKeyComments },
  { id: 4, component: ParagraphCompanyProfile },
];

export const PARAGRAPH_DEALS = [
  { id: 0, component: DealsParagraphTopic },
  { id: 2, component: ParagraphDealTypeAndStatus },
  { id: 7, component: DealsParagraphDealPurpose },
  { id: 3, component: ParagraphDealTerms },
  { id: 4, component: ParagraphAdditionalInformation },
  { id: 1, component: DealsParagraphDrugProfile },
  { id: 6, component: DealsParagraphKeyComments },
  { id: 5, component: ParagraphCompanyProfile },
];

export const PARAGRAPH_VENTURE_CAPITAL = [
  { id: 0, component: VentureCapitalParagraphTopic },
  { id: 1, component: VentureCapitalParagraphRoundAndStatus },
  { id: 2, component: VentureCapitalParagraphCompanyProfile },
  { id: 3, component: VentureCapitalParagraphFinancingInformation },
  { id: 4, component: VentureCapitalParagraphInvestors },
  { id: 5, component: VentureCapitalParagraphAdditionalInformation },
  { id: 6, component: VentureCapitalParagraphDrugProfile },
  { id: 7, component: VentureCapitalParagraphKeyComments },
];

export const PARAGRAPH_REGULATORY = [
  { id: 0, component: RegulatoryParagraphTopic },
  { id: 1, component: RegulatoryParagraphApprovalProcess },
  { id: 2, component: RegulatoryParagraphDrugProfile },
  { id: 3, component: RegulatoryParagraphKeyComments },
  { id: 4, component: ParagraphCompanyProfile },
];

export const PARAGRAPH_EXPEDITED_PROGRAM = [
  { id: 0, component: ExpeditedProgramParagraphTopic },
  { id: 1, component: ExpeditedProgramParagraphExpeditedProgram },
  { id: 2, component: ExpeditedProgramParagraphDrugProfile },
  { id: 3, component: ExpeditedProgramParagraphKeyComments },
  { id: 4, component: ParagraphCompanyProfile },
];
