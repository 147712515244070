import styled from "styled-components";

import { mq } from "@styles/mediaQuery";

export const HeaderWrapper = styled.header`
  position: fixed;
  width: 100%;
  height: 4rem;
  border: 1px solid ${({ theme }) => theme.color.neural.gray02};
  background-color: #fff;
  padding: 0;
  z-index: 30;
`;

export const HeaderCenterBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  transition: all 0.7s ease-in-out;
  width: 100%;
  max-width: calc(100% - 8rem);

  ${[mq[1]]} {
    max-width: calc(100% - 4rem);
  }
`;

export const HeaderColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  h1 > a {
    display: flex;
    gap: 1rem;
    align-items: center;
    color: ${({ theme }) => theme.color.neural.gray09};

    span {
      font-weight: 600;
      font-size: 1.8rem;
    }
  }
`;

export const Date = styled.strong`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.neural.gray05};
  margin-left: 2rem;
`;

export const UserName = styled.span`
  font-size: 1.2rem;
  text-align: right;
  color: ${({ theme }) => theme.color.neural.gray09};
  margin-right: 1.6rem;
`;

export const UserIconBox = styled.div`
  display: none; // flex
  flex-direction: row-reverse;
  margin-right: 4rem;
  padding-top: 0.3rem;
`;

export const SignOutButton = styled.button`
  color: var(--grayscale-spectrum-neural-blue-gray-09, #1f2837);
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0;
  cursor: pointer;
`;
