import { ArticleSection } from "@/types/articleEntryData.types";

import { ClinicalTrialsArticleMainLabelList } from "./clinicalTrialsMainLabelList";
import { DealsArticleMainLabelList } from "./dealsMainLabelList";
import { ExpeditedProgramMainLabelList } from "./expeditedProgramMainLabelList";
import { RegulatoryMainLabelList } from "./regulatoryMainLabelList";
import { VentureCapitalMainLabelList } from "./ventureCapitalMainLabelList";

export class ArticleMainLabelListFactory {
  public static createArticleMainLabelList(articleSection: ArticleSection) {
    if (articleSection === "Clinical Trials") {
      return new ClinicalTrialsArticleMainLabelList();
    }
    if (articleSection === "Deals") {
      return new DealsArticleMainLabelList();
    }
    if (articleSection === "Regulatory") {
      return new RegulatoryMainLabelList();
    }
    if (articleSection === "Expedited Program") {
      return new ExpeditedProgramMainLabelList();
    }
    if (articleSection === "Venture Capital") {
      return new VentureCapitalMainLabelList();
    }

    throw new Error("Invalid target");
  }
}
