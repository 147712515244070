import React from "react";

import excludeIcon from "@images/error/icon-exclude.svg";

import * as S from "./ErrorPageView.style";

interface Props {
  title: string;
  message: string;
  buttonLabel: string;
  handleButtonClick: () => void;
  errorStatus?: number;
  details?: string;
}

const ErrorPageView = ({
  title,
  message,
  buttonLabel,
  handleButtonClick,
  errorStatus,
  details,
}: Props) => {
  return (
    <S.Background>
      <S.Wrapper>
        <S.Header>
          <S.ErrorStatus>
            {errorStatus ? `Error Code : ${errorStatus}` : "Error"}
          </S.ErrorStatus>
          <S.ErrorTitle>{title}</S.ErrorTitle>
          <S.ErrorMessage>{message}</S.ErrorMessage>
          {details && <S.ErrorDetails>{details}</S.ErrorDetails>}
        </S.Header>
        <S.Body>
          <S.ActionButton onClick={handleButtonClick}>
            {buttonLabel}
          </S.ActionButton>
        </S.Body>
        <S.ExcludeIcon src={excludeIcon} alt="exclude" />
      </S.Wrapper>
    </S.Background>
  );
};

export default ErrorPageView;
