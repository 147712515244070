import React, { useRef, useState } from "react";

interface UseDragScrollReturn {
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  handleMouseDownToDragScroll: (
    event: React.MouseEvent<HTMLDivElement>,
  ) => void;
}

export const useDragScroll = (): UseDragScrollReturn => {
  const scrollContainerRef = useRef(null);

  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(true);

  const handleMouseMoveToDragScroll = (event: Event) => {
    if (!isDragging) return;

    if (scrollContainerRef.current === null) return;

    const x =
      (event as MouseEvent).pageX -
      (scrollContainerRef.current as HTMLDivElement).offsetLeft;
    const dragDistance = x - startX;
    (scrollContainerRef.current as HTMLDivElement).scrollLeft =
      scrollLeft - dragDistance;
  };

  const handleMouseUpToDragScroll = () => {
    window.removeEventListener("mousemove", handleMouseMoveToDragScroll);
    window.removeEventListener("mouseup", handleMouseUpToDragScroll);
    setIsDragging(true);
  };

  const handleMouseDownToDragScroll = (
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    setTimeout(() => {
      setIsDragging(false);
    }, 100);

    if (scrollContainerRef.current === null) return;
    setStartX(
      event.pageX - (scrollContainerRef.current as HTMLDivElement).offsetLeft,
    );
    setScrollLeft((scrollContainerRef.current as HTMLDivElement).scrollLeft);

    window.addEventListener("mousemove", handleMouseMoveToDragScroll);
    window.addEventListener("mouseup", handleMouseUpToDragScroll);
  };

  return {
    scrollContainerRef,
    handleMouseDownToDragScroll,
  };
};
