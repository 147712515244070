import styled from "styled-components";

export const ImageViewWrapper = styled.div`
  position: relative;
`;

export const ImageViewImage = styled.div`
  max-width: 80rem;
  max-height: 45rem;
  border: 1px solid ${({ theme }) => theme.color.neural.gray05};
  border-radius: 0.8rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ImageViewCloseButton = styled.button`
  position: absolute;
  top: -4rem;
  left: 50%;
  width: 3.2rem;
  height: 3.2rem;
  margin-left: -1.6rem;
  cursor: pointer;
`;
