import { useCallback } from "react";

import { getEntryData } from "@api/entryData/entryData";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export const useGetEntryData = () => {
  const [, setEntryData] = useEntryDataState(getPressIdFromURL());

  const getEntryDataAndStored = useCallback(async () => {
    const data = await getEntryData(getPressIdFromURL());
    setEntryData(data);
  }, [setEntryData]);

  return getEntryDataAndStored;
};
