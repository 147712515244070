import styled from "styled-components";

export const LeftButtonContainer = styled.div`
  display: flex;
  gap: 1.6rem;
`;

export const PrevButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  min-height: 4rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #0198e9;
  border-radius: 0.4rem;
  border: 0.1rem solid #0198e9;
  padding: 0rem 2.4rem 0rem 1.6rem;
  cursor: pointer;
`;

export const BottomRightButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.6rem;
`;
