/**
 * NOTE
 * [translate: 문자] 또는 [reupload: 문자] 패턴을 찾아 제거
 */
export const removeCodeInTitle = (title: string): string => {
  const cleanedTitle = title
    .replaceAll(/\[(translate|reupload):[^\]]*\]/g, "")
    .trim();
  return cleanedTitle;
};
