import React, { HTMLAttributes } from "react";

import * as S from "./AvatarBackground.style";

interface Props extends HTMLAttributes<HTMLDivElement> {
  containerSize: string;
  className?: string;
  borderRadius?: string;
  children: JSX.Element;
}

const AvatarBackground = ({
  containerSize,
  className,
  borderRadius = "0.2rem",
  children,
  ...props
}: Props) => {
  return (
    <S.Background
      $width={containerSize}
      $height={containerSize}
      $borderRadius={borderRadius}
      className={className}
      {...props}
    >
      {children}
    </S.Background>
  );
};

export default AvatarBackground;
