import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconCopy = ({ width = 24, height = 24, color = "black" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.59675 4.59677C4.7921 4.40142 5.05706 4.29167 5.33332 4.29167H12.8333C13.1096 4.29167 13.3745 4.40142 13.5699 4.59677C13.7652 4.79212 13.875 5.05707 13.875 5.33334V6.16667C13.875 6.51185 14.1548 6.79167 14.5 6.79167C14.8452 6.79167 15.125 6.51185 15.125 6.16667V5.33334C15.125 4.72555 14.8835 4.14266 14.4538 3.71289C14.024 3.28311 13.4411 3.04167 12.8333 3.04167H5.33332C4.72554 3.04167 4.14264 3.28311 3.71287 3.71289C3.2831 4.14266 3.04166 4.72555 3.04166 5.33334V12.8333C3.04166 13.4411 3.2831 14.024 3.71287 14.4538C4.14264 14.8836 4.72554 15.125 5.33332 15.125H6.16666C6.51183 15.125 6.79166 14.8452 6.79166 14.5C6.79166 14.1548 6.51183 13.875 6.16666 13.875H5.33332C5.05706 13.875 4.7921 13.7653 4.59675 13.5699C4.4014 13.3746 4.29166 13.1096 4.29166 12.8333V5.33334C4.29166 5.05707 4.4014 4.79212 4.59675 4.59677ZM10.125 11.1667C10.125 10.5914 10.5914 10.125 11.1667 10.125H18.6667C19.242 10.125 19.7083 10.5914 19.7083 11.1667V18.6667C19.7083 19.242 19.242 19.7083 18.6667 19.7083H11.1667C10.5914 19.7083 10.125 19.242 10.125 18.6667V11.1667ZM11.1667 8.875C9.90101 8.875 8.875 9.90102 8.875 11.1667V18.6667C8.875 19.9323 9.90101 20.9583 11.1667 20.9583H18.6667C19.9323 20.9583 20.9583 19.9323 20.9583 18.6667V11.1667C20.9583 9.90102 19.9323 8.875 18.6667 8.875H11.1667Z"
        fill={color}
      />
    </svg>
  );
};

IconCopy.displayName = "Copy";

export default IconCopy;
