export const ENTRY_DATA_FUNDED_COMPANY_GROUP_TABLE_KEY =
  "Funded Company" as const;

export const ENTRY_DATA_FUNDED_COMPANY_KEYS = {
  companyDescription: "Company Description",
  companyName: "Company Name",
} as const;

export const ENTRY_DATA_FUNDED_COMPANY_SHELL_KEYS = {
  companyDescription: "Company Description",
  companyName: "Company Name",
} as const;

export const ENTRY_DATA_FUNDED_COMPANY_GROUP_SHELL_KEYS = {
  companyInformation: "Company Information",
  none: null,
};

export const ENTRY_DATA_FUNDED_COMPANY_TABLE_KEYS = {
  none: null,
};
