"use client";

import styled from "styled-components";

export const SubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--Spacing-2, 0.2rem);
  align-items: center;
  align-content: center;
  gap: 0.4rem var(--Spacing-4, 0.4rem);
  width: 100%;
`;

export const SubTitleText = styled.div`
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  width: 100%;
`;
