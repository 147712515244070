import React from "react";

const TooltipBreakImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="7"
      viewBox="0 0 24 7"
      fill="none"
    >
      <g clipPath="url(#clip0_1872_12834)">
        <path
          d="M10.5858 5.58579C11.3668 6.36683 12.6332 6.36683 13.4142 5.58579L18 1H6L10.5858 5.58579Z"
          fill="white"
        />
        <path d="M18.5 0.5L5.5 0.5" stroke="white" />
        <path
          d="M19 1.43051e-06L13.4142 5.58579C12.6332 6.36683 11.3668 6.36684 10.5858 5.58579L5 0"
          stroke="#E5E7EB"
        />
      </g>
      <defs>
        <clipPath id="clip0_1872_12834">
          <rect
            width="24"
            height="7"
            fill="white"
            transform="matrix(1 0 0 -1 0 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TooltipBreakImage;
