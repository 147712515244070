import styled from "styled-components";

export const SignInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

export const SignInHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 4rem;
  margin-bottom: 6.6rem;

  img {
    width: 34.2rem;
    height: 4.8rem;
  }

  h2 {
    color: ${({ theme }) => theme.color.neural.gray09};

    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4;
    white-space: nowrap;
  }
`;
