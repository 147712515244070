export const ENTRY_DATA_APPROVAL_PROCESS_CONTENTS = "Approval Process Contents";

export const ENTRY_DATA_APPROVAL_PROCESS_CONTENTS_KEYS = {
  approvalProcess: "Approval Process",
  additionalInformationOnTheApprovalProcess:
    "Additional Information on the Approval Process",
} as const;

export const ENTRY_DATA_APPROVAL_PROCESS_KEYS = {
  updateDate: "Update Date",
  marketingStatus: "Marketing Status",
  indicationOnLabel: "Indication on Label",
  dosageAndAdministrationOnLabel: "Dosage and Administration on Label",
  regulatoryAgency: "Regulatory Agency",
  applicationType: "Application Type",
  authorizedCompany: "Authorized Company",
} as const;
