import { useEffect } from "react";

import { useScroll } from "@FillInTableContainer/components/FillInTable/hooks/useScroll";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import {
  DefaultShellTitle,
  ShellDividerPlusBar,
  TableCreatorTextFieldContainer,
} from "@FillInTableContainer/shared";
import useCheckPressedShell from "@FillInTableContainer/shared/hooks/useCheckPressedShell";
import { useGetInfoOfShell } from "@FillInTableContainer/shared/hooks/useGetInfoOfShell";
import { TableCreatorSubShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import {
  isAddDeleteShell,
  isRequiredShell,
  getShellPlaceholder,
} from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getShellTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { padStart } from "@utils/padStart/padStart";

import * as S from "./ClinicalTrialsTableCreatorShell.style";
import { useHandleClinicalTrialsTableCreatorShell } from "./hooks/useHandleClinicalTrialsTableCreatorShell";

import type { ClinicalTrialsSection } from "@/types/clinicalTrials.types";

export interface PositionInfoProps {
  groupTableKey: ClinicalTrialsSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  groupShellIndex: number;
  tableKey: string | null;
  tableIndex: number;
  shellKey: string;
  shellIndex: number;
  shellCount: number;
}

interface Props extends PositionInfoProps {
  shellCount: number;
  subShellList?: TableCreatorSubShellList;
  isShellOverOne: boolean;
  isSeparatedShellTitle: boolean;
  isTitleRowShell?: boolean;
  isFocusedGroupShell: boolean;
  isClearGroupShell: boolean;
  isDeleteGroupShell: boolean;
  isAlertGroupShell: boolean;
}

const ClinicalTrialsTableCreatorShell = ({
  groupTableKey,
  groupTableIndex,
  groupShellKey,
  groupShellIndex,
  tableKey,
  shellKey,
  tableIndex,
  shellIndex,
  shellCount,
  subShellList,
  isShellOverOne,
  isSeparatedShellTitle,
  isTitleRowShell = false,
  isClearGroupShell,
  isDeleteGroupShell,
  isAlertGroupShell,
}: Props) => {
  const positionPropsInfo: PositionInfoProps = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
    shellKey,
    shellIndex,
    shellCount,
  };

  const {
    isFocused,
    isFocusedColumn,
    isClearShell,
    isDeleteShell,
    isDeleteColumn,
    isEnableEdit,
    isAlertShell,
    isLastShell,
    focusStatus,
    columnSize,
    isFocusedAddButton,
    isDeleteTable,
    isClearTable,
    handleAddShell,
    handleFocusTextFieldWrapper,
    handleClickToDeleteShell,
    handleFocusOutShell,
  } = useHandleClinicalTrialsTableCreatorShell(positionPropsInfo);
  const { isPressed, handleChangePressedStatus } = useCheckPressedShell();

  const { register } = useScroll();

  const articleSection = useArticleSection();

  const { shellValue } = useGetInfoOfShell();

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.dataset.button) return;

      if (isFocused) {
        handleFocusOutShell();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleFocusOutShell, isFocused]);

  if (shellKey === "Modality") {
    const modalityShellTitleColumnClassName = `is-modality-shell-value-empty-${
      shellValue(shellKey, tableIndex, groupShellIndex, shellIndex).value
        .length === 0
    }`;

    return (
      <S.ShellWrapper
        column={columnSize}
        {...register(
          `${groupTableKey}__${groupTableIndex}__${tableKey}__${tableIndex}__${groupShellKey}__${groupShellIndex}__${shellKey}__${shellIndex}`,
        )}
      >
        {(isClearShell || isDeleteShell) && isEnableEdit && (
          <S.DeleteTableButtonWrapper>
            <S.DeleteTableButton
              type="button"
              onClick={handleClickToDeleteShell}
            >
              <img src={IconXMark} alt="button for clear" />
              {focusStatus.type === "clear" ? "Clear" : null}
              {focusStatus.type === "delete" ? "Delete" : null}
            </S.DeleteTableButton>
          </S.DeleteTableButtonWrapper>
        )}
        <S.ShellBorderWrapper
          isFocused={isFocused && focusStatus.type !== "dragging"}
          $isDragging={isFocused && focusStatus.type === "dragging"}
          isClearStatus={isClearShell || isClearGroupShell || isClearTable}
          isDeleteStatus={isDeleteShell || isDeleteGroupShell || isDeleteTable}
          isAlertStatus={isAlertShell || isAlertGroupShell}
          isDisableEdit={!isEnableEdit}
          isSeparatedShellTitle={isSeparatedShellTitle}
          isTitleRowShell={isTitleRowShell}
          $isPressed={isPressed}
          onClick={handleFocusTextFieldWrapper}
          onMouseDown={(e) => handleChangePressedStatus(e, true)}
          onMouseUp={(e) => handleChangePressedStatus(e, false)}
        >
          <S.ModalityShellRow>
            <S.ModalityShellColumn
              className={modalityShellTitleColumnClassName}
              $isMultipleValue={
                shellValue(shellKey, tableIndex, groupShellIndex, shellIndex)
                  .value.length > 1
              }
            >
              <DefaultShellTitle
                isFocused={isFocused}
                isClearShell={isClearShell}
                isDeleteShell={isDeleteShell}
                isAlertShell={isAlertShell}
                isRequiredShell={isRequiredShell(articleSection, shellKey)}
                shellTitle={getShellTitle(articleSection, shellKey)}
                shellTitleNumbering=""
                isSeparatedShellTitle={isSeparatedShellTitle}
                columnSize={columnSize}
              />
            </S.ModalityShellColumn>
            <S.ModalityShellColumn>
              <DefaultShellTitle
                isFocused={isFocused}
                isClearShell={isClearShell}
                isDeleteShell={isDeleteShell}
                isAlertShell={isAlertShell}
                isRequiredShell={isRequiredShell(articleSection, shellKey)}
                shellTitle="Modality Category"
                shellTitleNumbering=""
                isSeparatedShellTitle={isSeparatedShellTitle}
                columnSize={columnSize}
              />
            </S.ModalityShellColumn>
          </S.ModalityShellRow>

          <>
            <TableCreatorTextFieldContainer
              placeholder={
                getShellPlaceholder(articleSection, shellKey) ||
                getShellTitle(articleSection, shellKey)
              }
              shellValue={shellValue(
                shellKey,
                tableIndex,
                groupShellIndex,
                shellIndex,
              )}
              shellKey={shellKey}
              shellIndex={shellIndex}
              groupTableKey={groupTableKey}
              groupTableIndex={groupTableIndex}
              groupShellKey={groupShellKey}
              groupShellIndex={groupShellIndex}
              tableKey={tableKey}
              tableIndex={tableIndex}
            />
          </>
        </S.ShellBorderWrapper>
      </S.ShellWrapper>
    );
  }
  return (
    <S.ShellAddWrapper
      {...register(
        `${groupTableKey}__${groupTableIndex}__${tableKey}__${tableIndex}__${groupShellKey}__${groupShellIndex}__${shellKey}__${shellIndex}`,
      )}
    >
      {isAddDeleteShell(articleSection, shellKey) && isFocusedAddButton && (
        <ShellDividerPlusBar
          isPositionRight={false}
          onClick={() =>
            handleAddShell({
              tableIndexParameter: tableIndex,
              groupShellIndexParameter: groupShellIndex,
              shellIndexParameter: shellIndex,
              shellKeyParameter: shellKey,
            })
          }
        />
      )}
      <S.ShellWrapper column={columnSize}>
        {(isClearShell || isDeleteShell) && isEnableEdit && (
          <S.DeleteTableButtonWrapper
            className={`button-reposition-medium-shell-${
              shellKey === "NCT Name"
            }`}
          >
            <S.DeleteTableButton
              type="button"
              data-button="delete-button"
              onClick={handleClickToDeleteShell}
            >
              <img src={IconXMark} alt="button for clear" />
              {focusStatus.type === "clear" ? "Clear" : null}
              {focusStatus.type === "delete" ? "Delete" : null}
            </S.DeleteTableButton>
          </S.DeleteTableButtonWrapper>
        )}
        <S.ShellBorderWrapper
          className={`shell is-shell-title-${!!getShellTitle(
            articleSection,
            shellKey,
          )}`}
          isFocused={
            (isFocused || isFocusedColumn) && focusStatus.type !== "dragging"
          }
          $isDragging={isFocused && focusStatus.type === "dragging"}
          isClearStatus={isClearShell || isClearGroupShell || isClearTable}
          isDeleteStatus={
            isDeleteShell ||
            isDeleteGroupShell ||
            isDeleteTable ||
            isDeleteColumn
          }
          isAlertStatus={isAlertShell || isAlertGroupShell}
          isDisableEdit={!isEnableEdit}
          isSeparatedShellTitle={isSeparatedShellTitle}
          isTitleRowShell={isTitleRowShell}
          $isPressed={isPressed}
          column={columnSize}
          onClick={handleFocusTextFieldWrapper}
          onMouseDown={(e) => handleChangePressedStatus(e, true)}
          onMouseUp={(e) => handleChangePressedStatus(e, false)}
        >
          <S.EmptyContentEditable contentEditable={isSeparatedShellTitle} />
          <DefaultShellTitle
            isFocused={isFocused}
            isClearShell={isClearShell}
            isDeleteShell={isDeleteShell}
            isAlertShell={isAlertShell}
            isRequiredShell={isRequiredShell(articleSection, shellKey)}
            shellTitle={getShellTitle(articleSection, shellKey)}
            shellTitleNumbering={
              isShellOverOne ? `${padStart(shellIndex + 1)}` : ""
            }
            isSeparatedShellTitle={isSeparatedShellTitle}
            columnSize={columnSize}
          />

          {!isTitleRowShell && (
            <>
              {subShellList ? (
                subShellList.map(({ subShellKey }) => (
                  <TableCreatorTextFieldContainer
                    key={`sub-shell-${String(shellIndex)}-${String(
                      subShellKey,
                    )}`}
                    placeholder={
                      getShellPlaceholder(articleSection, subShellKey) ||
                      getShellTitle(articleSection, subShellKey)
                    }
                    shellValue={shellValue(
                      subShellKey,
                      tableIndex,
                      groupShellIndex,
                      shellIndex,
                    )}
                    shellKey={subShellKey}
                    shellIndex={shellIndex}
                    groupTableKey={groupTableKey}
                    groupTableIndex={groupTableIndex}
                    groupShellKey={groupShellKey}
                    groupShellIndex={groupShellIndex}
                    tableKey={tableKey}
                    tableIndex={tableIndex}
                  />
                ))
              ) : (
                <TableCreatorTextFieldContainer
                  placeholder={
                    getShellPlaceholder(articleSection, shellKey) ||
                    getShellTitle(articleSection, shellKey)
                  }
                  shellValue={shellValue(
                    shellKey,
                    tableIndex,
                    groupShellIndex,
                    shellIndex,
                  )}
                  shellKey={shellKey}
                  shellIndex={shellIndex}
                  groupTableKey={groupTableKey}
                  groupTableIndex={groupTableIndex}
                  groupShellKey={groupShellKey}
                  groupShellIndex={groupShellIndex}
                  tableKey={tableKey}
                  tableIndex={tableIndex}
                />
              )}
            </>
          )}
        </S.ShellBorderWrapper>
      </S.ShellWrapper>
      {isAddDeleteShell(articleSection, shellKey) &&
        isLastShell &&
        isFocusedAddButton && (
          <ShellDividerPlusBar
            isPositionRight
            onClick={() =>
              handleAddShell({
                tableIndexParameter: tableIndex,
                groupShellIndexParameter: groupShellIndex,
                shellIndexParameter: shellIndex + 1,
                shellKeyParameter: shellKey,
              })
            }
          />
        )}
    </S.ShellAddWrapper>
  );
};

export default ClinicalTrialsTableCreatorShell;
