import {
  ENTRY_DATA_CONTINGENT_PAYMENT_KEYS as CONTINGENT_PAYMENT_SHELL_KEYS,
  ENTRY_DATA_DEAL_TYPE_AND_STATUS as DEAL_TYPE_AND_STATUS,
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS as DRUG_PROFILE_SHELL_KEYS,
  ENTRY_DATA_KEY_COMMENTS_GROUP_TABLE_KEY as KEY_COMMENTS,
  ENTRY_DATA_KEY_COMMENTS_SHELL_KEYS as KEY_COMMENTS_SHELL_KEYS,
  ENTRY_DATA_LICENSOR_TABLE_KEYS as LICENSOR_TABLE_SHELL_KEYS,
  ENTRY_DATA_LICENSOR_TABLE as LICENSOR_TABLE,
  ENTRY_DATA_LICENSEE_TABLE as LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS as LICENSEE_TABLE_GROUP_SHELL_KEYS,
  ENTRY_DATA_RIGHTS_SCOPE_KEYS as RIGHTS_SCOPE_SHELL_KEYS,
  ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS as MERGE_ACQUISITION_TERMS_KEYS,
  ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS as MILESTONE_PAYMENT_CONDITION_SHELL_KEYS,
  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS as OTHER_PAYMENT_DETAIL_SHELL_KEYS,
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS as STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL as ADDITIONAL_INFORMATION,
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS as ADDITIONAL_INFORMATION_SHELL_KEYS,
  ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS as DEAL_TYPE_AND_STATUS_SHELL_KEYS,
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";

const getDrugProfileFormatCheckList = (entryData: ArticleEntryData) => {
  const targetPath = entryData?.[SECTION_KEYS.deals]?.[DRUG_PROFILE];
  if (!targetPath) return [`${DRUG_PROFILE} data is missing`];
  if (!Array.isArray(targetPath)) return [`${DRUG_PROFILE} data is missing`];
  if (targetPath.length === 0) return [`${DRUG_PROFILE} data is missing`];

  const checkDrugProfileTableValues = targetPath.flatMap(
    (tableItem, tableIndex) => {
      const requiredFields = [
        {
          key: DRUG_PROFILE_SHELL_KEYS.brandName,
          label: DRUG_PROFILE_SHELL_KEYS.brandName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.innName,
          label: DRUG_PROFILE_SHELL_KEYS.innName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.codeName,
          label: DRUG_PROFILE_SHELL_KEYS.codeName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.moa,
          label: DRUG_PROFILE_SHELL_KEYS.moa,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.modality,
          label: DRUG_PROFILE_SHELL_KEYS.modality,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
          label: DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.roa,
          label: DRUG_PROFILE_SHELL_KEYS.roa,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.target,
          label: DRUG_PROFILE_SHELL_KEYS.target,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.targetIndication,
          label: DRUG_PROFILE_SHELL_KEYS.targetIndication,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.therapeuticArea,
          label: DRUG_PROFILE_SHELL_KEYS.therapeuticArea,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.phase,
          label: DRUG_PROFILE_SHELL_KEYS.phase,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
          label: DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.genericBiosimilar,
          label: DRUG_PROFILE_SHELL_KEYS.genericBiosimilar,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.refProductName,
          label: DRUG_PROFILE_SHELL_KEYS.refProductName,
        },
      ];

      return requiredFields
        .map(({ key, label }) =>
          !tableItem[key]?.value
            ? `${label} is missing in ${DRUG_PROFILE} table ${tableIndex}`
            : "",
        )
        .filter((message) => !!message);
    },
  );

  return [...checkDrugProfileTableValues];
};

const getDealTypeAndStatusFormatCheckList = (entryData: ArticleEntryData) => {
  const targetPath = entryData?.[SECTION_KEYS.deals]?.[DEAL_TYPE_AND_STATUS];
  if (!targetPath) return [`${DEAL_TYPE_AND_STATUS} data is missing`];

  const requiredFields = [
    {
      key: DEAL_TYPE_AND_STATUS_SHELL_KEYS.dealStatus,
      label: DEAL_TYPE_AND_STATUS_SHELL_KEYS.dealStatus,
    },
    {
      key: DEAL_TYPE_AND_STATUS_SHELL_KEYS.dealType,
      label: DEAL_TYPE_AND_STATUS_SHELL_KEYS.dealType,
    },
    {
      key: DEAL_TYPE_AND_STATUS_SHELL_KEYS.dealName,
      label: DEAL_TYPE_AND_STATUS_SHELL_KEYS.dealName,
    },
  ];

  return (
    requiredFields
      ?.map(({ key, label }) =>
        !targetPath[key]?.value
          ? `${label} is missing in ${DEAL_TYPE_AND_STATUS}`
          : "",
      )
      ?.filter((message) => !!message) || []
  );
};

const getLicensorTableFormatCheckList = (entryData: ArticleEntryData) => {
  const targetPath = entryData?.[SECTION_KEYS.deals]?.[LICENSOR_TABLE];
  if (!targetPath) return [`${LICENSOR_TABLE} data is missing`];

  const requiredFields = [
    {
      key: LICENSOR_TABLE_SHELL_KEYS.licensorName,
      label: LICENSOR_TABLE_SHELL_KEYS.licensorName,
    },
    {
      key: LICENSOR_TABLE_SHELL_KEYS.licensorResponsibilityRights,
      label: LICENSOR_TABLE_SHELL_KEYS.licensorResponsibilityRights,
    },
  ];

  return (
    requiredFields
      ?.map(({ key, label }) =>
        !targetPath[key]?.value
          ? `${label} is missing in ${LICENSOR_TABLE}`
          : "",
      )
      ?.filter((message) => !!message) || []
  );
};

const getLicenseeTableFormatCheckList = (entryData: ArticleEntryData) => {
  const targetPath = entryData?.[SECTION_KEYS.deals]?.[LICENSEE_TABLE];
  if (!targetPath) return [`${LICENSEE_TABLE} data is missing`];
  if (!Array.isArray(targetPath)) return [`${LICENSEE_TABLE} data is missing`];
  if (targetPath.length === 0) return [`${LICENSEE_TABLE} data is missing`];

  const checkLicenseeTableValues = targetPath.flatMap(
    (tableItem, tableIndex) => {
      const requiredSingleFields = [
        {
          key: LICENSEE_TABLE_GROUP_SHELL_KEYS.licenseeName,
          label: LICENSEE_TABLE_GROUP_SHELL_KEYS.licenseeName,
        },
      ];

      const singleFieldsList = requiredSingleFields
        .map(({ key, label }) =>
          !tableItem[key]
            ? `${label} is missing in ${LICENSEE_TABLE} ${tableIndex}`
            : "",
        )
        .filter((message) => !!message);

      const rightsScope =
        tableItem?.[LICENSEE_TABLE_GROUP_SHELL_KEYS.rightsScope];

      const rightsScopeGroupList =
        rightsScope?.length === 0
          ? [
              `${LICENSEE_TABLE_GROUP_SHELL_KEYS.rightsScope} is missing in ${LICENSEE_TABLE} table ${tableIndex}`,
            ]
          : [];

      const rightsScopeFieldsList = rightsScope.flatMap(
        (groupShellItem, groupIndex) => {
          const requiredFields = [
            {
              key: RIGHTS_SCOPE_SHELL_KEYS.drug,
              label: RIGHTS_SCOPE_SHELL_KEYS.drug,
            },
            {
              key: RIGHTS_SCOPE_SHELL_KEYS.exclusivity,
              label: RIGHTS_SCOPE_SHELL_KEYS.exclusivity,
            },
            {
              key: RIGHTS_SCOPE_SHELL_KEYS.indication,
              label: RIGHTS_SCOPE_SHELL_KEYS.indication,
            },
            {
              key: RIGHTS_SCOPE_SHELL_KEYS.territory,
              label: RIGHTS_SCOPE_SHELL_KEYS.territory,
            },
          ];

          return requiredFields
            .map(({ key, label }) =>
              !groupShellItem[key]?.value
                ? `${label} is missing in ${LICENSEE_TABLE} table ${tableIndex} ${
                    LICENSEE_TABLE_GROUP_SHELL_KEYS.rightsScope
                  } group ${groupIndex + 1}`
                : "",
            )
            .filter((message) => !!message);
        },
      );

      const strategicCollaborationLicenseAgreementTerms =
        tableItem?.[
          LICENSEE_TABLE_GROUP_SHELL_KEYS
            .strategicCollaborationLicenseAgreementTerms
        ];

      const requiredScLaTermsSingleFields = [
        {
          key: STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherFinancialTermsScLa,
          label:
            STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherFinancialTermsScLa,
        },
        {
          key: STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.licenseeResponsibilityAndRightsScLa,
          label:
            STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.licenseeResponsibilityAndRightsScLa,
        },
        {
          key: STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.royalty,
          label: STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.royalty,
        },
        {
          key: STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.totalPaymentScLa,
          label:
            STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.totalPaymentScLa,
        },
        {
          key: STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.upfrontPayment,
          label:
            STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.upfrontPayment,
        },
        {
          key: STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.disclosedPaymentsSumScLa,
          label:
            STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.disclosedPaymentsSumScLa,
        },
      ];

      const singleFieldsListScLa = requiredScLaTermsSingleFields
        .map(({ key, label }) =>
          !strategicCollaborationLicenseAgreementTerms[key]?.value
            ? `${label} is missing in ${LICENSEE_TABLE} table ${tableIndex}`
            : "",
        )
        .filter((message) => !!message);

      const milestonePaymentAndCondition =
        strategicCollaborationLicenseAgreementTerms?.[
          STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
            .milestonePaymentAndCondition
        ];

      const milestonePaymentAndConditionGroupList =
        milestonePaymentAndCondition?.length === 0
          ? [
              `${STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.milestonePaymentAndCondition} is missing in ${LICENSEE_TABLE} table ${tableIndex}`,
            ]
          : [];

      const milestonePaymentAndConditionFieldsList =
        milestonePaymentAndCondition?.flatMap((groupShellItem, groupIndex) => {
          const requiredFields = [
            {
              key: MILESTONE_PAYMENT_CONDITION_SHELL_KEYS.milestoneConditionTitle,
              label:
                MILESTONE_PAYMENT_CONDITION_SHELL_KEYS.milestoneConditionTitle,
            },
            {
              key: MILESTONE_PAYMENT_CONDITION_SHELL_KEYS.milestonePayment,
              label: MILESTONE_PAYMENT_CONDITION_SHELL_KEYS.milestonePayment,
            },
          ];

          return requiredFields
            .map(({ key, label }) =>
              !groupShellItem[key]?.value
                ? `${label} is missing in ${LICENSEE_TABLE} table ${tableIndex} ${STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.milestonePaymentAndCondition} group ${groupIndex}`
                : "",
            )
            .filter((message) => !!message);
        });

      const otherPaymentAndDetailScLa =
        strategicCollaborationLicenseAgreementTerms?.[
          STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
            .otherPaymentAndDetailScLa
        ];

      const otherPaymentAndDetailGroupList =
        otherPaymentAndDetailScLa?.length === 0
          ? [
              `${STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa} is missing in ${LICENSEE_TABLE} table ${tableIndex}`,
            ]
          : [];

      const otherPaymentAndDetailFieldsList =
        otherPaymentAndDetailScLa?.flatMap((groupShellItem, groupIndex) => {
          const requiredFields = [
            {
              key: OTHER_PAYMENT_DETAIL_SHELL_KEYS.otherPaymentDetailScLa,
              label: OTHER_PAYMENT_DETAIL_SHELL_KEYS.otherPaymentDetailScLa,
            },
            {
              key: OTHER_PAYMENT_DETAIL_SHELL_KEYS.otherPaymentScLa,
              label: OTHER_PAYMENT_DETAIL_SHELL_KEYS.otherPaymentScLa,
            },
          ];

          return requiredFields
            .map(({ key, label }) =>
              !groupShellItem[key]?.value
                ? `${label} is missing in ${LICENSEE_TABLE} table ${tableIndex} ${STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa} group ${groupIndex}`
                : "",
            )
            .filter((message) => !!message);
        });

      const requiredSingleMergeAcquisitionTermsFields = [
        {
          key: MERGE_ACQUISITION_TERMS_KEYS.licenseResponsibilityRightsMa,
          label: MERGE_ACQUISITION_TERMS_KEYS.licenseResponsibilityRightsMa,
        },
        {
          key: MERGE_ACQUISITION_TERMS_KEYS.totalPaymentMa,
          label: MERGE_ACQUISITION_TERMS_KEYS.totalPaymentMa,
        },
        {
          key: MERGE_ACQUISITION_TERMS_KEYS.upfrontPaymentMa,
          label: MERGE_ACQUISITION_TERMS_KEYS.upfrontPaymentMa,
        },
        {
          key: MERGE_ACQUISITION_TERMS_KEYS.upfrontPricePerShareMa,
          label: MERGE_ACQUISITION_TERMS_KEYS.upfrontPricePerShareMa,
        },
        {
          key: MERGE_ACQUISITION_TERMS_KEYS.mergerFinancialTermsMa,
          label: MERGE_ACQUISITION_TERMS_KEYS.mergerFinancialTermsMa,
        },
        {
          key: MERGE_ACQUISITION_TERMS_KEYS.pricePerShare,
          label: MERGE_ACQUISITION_TERMS_KEYS.pricePerShare,
        },
        {
          key: MERGE_ACQUISITION_TERMS_KEYS.numberOfAcquiringShares,
          label: MERGE_ACQUISITION_TERMS_KEYS.numberOfAcquiringShares,
        },
        {
          key: MERGE_ACQUISITION_TERMS_KEYS.otherFinancialTermsMa,
          label: MERGE_ACQUISITION_TERMS_KEYS.otherFinancialTermsMa,
        },
        {
          key: MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumMa,
          label: MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumMa,
        },
        {
          key: MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumPricePerShareMa,
          label:
            MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumPricePerShareMa,
        },
      ];

      const singleFieldsListMa = requiredSingleMergeAcquisitionTermsFields
        .map(({ key, label }) =>
          !tableItem[LICENSEE_TABLE_GROUP_SHELL_KEYS.mergeAcquisitionTerms][key]
            ?.value
            ? `${label} is missing in ${LICENSEE_TABLE} table ${tableIndex}`
            : "",
        )
        .filter((message) => !!message);

      const contingentPayment =
        tableItem?.[LICENSEE_TABLE_GROUP_SHELL_KEYS.mergeAcquisitionTerms]?.[
          MERGE_ACQUISITION_TERMS_KEYS.contingentPayment
        ];
      const contingentPaymentGroupList =
        contingentPayment?.length === 0
          ? [
              `${MERGE_ACQUISITION_TERMS_KEYS.contingentPayment} is missing in ${LICENSEE_TABLE} table ${tableIndex}`,
            ]
          : [];

      const checkContingentPaymentFields = contingentPayment?.flatMap(
        (subGroupShellItem, subGroupIndex) => {
          const requiredFields = [
            {
              key: CONTINGENT_PAYMENT_SHELL_KEYS.contingentMilestoneMa,
              label: CONTINGENT_PAYMENT_SHELL_KEYS.contingentMilestoneMa,
            },
            {
              key: CONTINGENT_PAYMENT_SHELL_KEYS.contingentPaymentMa,
              label: CONTINGENT_PAYMENT_SHELL_KEYS.contingentPaymentMa,
            },
            {
              key: CONTINGENT_PAYMENT_SHELL_KEYS.contingentPricePerShareMa,
              label: CONTINGENT_PAYMENT_SHELL_KEYS.contingentPricePerShareMa,
            },
          ];

          return requiredFields
            .map(({ key, label }) =>
              !subGroupShellItem[key]?.value
                ? `${label} is missing in ${LICENSEE_TABLE} table ${tableIndex} ${MERGE_ACQUISITION_TERMS_KEYS.contingentPayment} group ${subGroupIndex}`
                : "",
            )
            .filter((message) => !!message);
        },
      );

      return [
        ...(singleFieldsList || []),
        ...(rightsScopeGroupList || []),
        ...(rightsScopeFieldsList || []),
        ...(singleFieldsListScLa || []),
        ...(milestonePaymentAndConditionGroupList || []),
        ...(milestonePaymentAndConditionFieldsList || []),
        ...(otherPaymentAndDetailGroupList || []),
        ...(otherPaymentAndDetailFieldsList || []),
        ...(singleFieldsListMa || []),
        ...(contingentPaymentGroupList || []),
        ...(checkContingentPaymentFields || []),
      ];
    },
  );

  return [...checkLicenseeTableValues];
};

const getAdditionalInformationFormatCheckList = (
  entryData: ArticleEntryData,
) => {
  const targetPath = entryData?.[SECTION_KEYS.deals]?.[ADDITIONAL_INFORMATION];
  if (!targetPath) return [`${ADDITIONAL_INFORMATION} data is missing`];

  const requiredFields = [
    {
      key: ADDITIONAL_INFORMATION_SHELL_KEYS.additionalInformation,
      label: ADDITIONAL_INFORMATION_SHELL_KEYS.additionalInformation,
    },
    {
      key: ADDITIONAL_INFORMATION_SHELL_KEYS.purposeOfTheDeal,
      label: ADDITIONAL_INFORMATION_SHELL_KEYS.purposeOfTheDeal,
    },
  ];

  return (
    requiredFields
      .map(({ key, label }) =>
        !targetPath[key]?.value
          ? `${label} is missing in ${ADDITIONAL_INFORMATION}`
          : "",
      )
      .filter((message) => !!message) || []
  );
};

const getKeyCommentFormatCheckList = (entryData: ArticleEntryData) => {
  const targetPath = entryData?.[SECTION_KEYS.deals]?.[KEY_COMMENTS];
  if (!targetPath) return [`${KEY_COMMENTS} data is missing`];

  const requiredFields = [
    {
      key: KEY_COMMENTS_SHELL_KEYS.quote,
      label: KEY_COMMENTS_SHELL_KEYS.quote,
    },
    {
      key: KEY_COMMENTS_SHELL_KEYS.spokesperson,
      label: KEY_COMMENTS_SHELL_KEYS.spokesperson,
    },
  ];

  return (
    requiredFields
      ?.map(({ key, label }) =>
        !targetPath[key]?.value ? `${label} is missing in ${KEY_COMMENTS}` : "",
      )
      ?.filter((message) => !!message) || []
  );
};

export const getDealEntryDataFormatCheckList = (
  entryData: ArticleEntryData,
) => [
  ...getDrugProfileFormatCheckList(entryData),
  ...getDealTypeAndStatusFormatCheckList(entryData),
  ...getLicensorTableFormatCheckList(entryData),
  ...getLicenseeTableFormatCheckList(entryData),
  ...getAdditionalInformationFormatCheckList(entryData),
  ...getKeyCommentFormatCheckList(entryData),
];
