"use client";

import styled from "styled-components";

export const ParagraphSectionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
  margin-bottom: 6.4rem;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    margin-bottom: 4rem;
  }
`;
