import { EDITOR_ROLES } from "./editorRoles.constants";
import { EditorRoleCode } from "../types/roles.types";

export const CLASSIFICATION_KEYS_CODE = {
  clinicalTrials: "C000",
  clinicalTrialStatus: "C001",
  posterContents: "C005",
  deals: "C006",
  expeditedProgram: "C016",
  approvalProcess: "C013",
  ventureCapital: "C021",
};

export const CLASSIFICATION_RETURN_ROLES_LIST: Record<
  string,
  EditorRoleCode[]
> = {
  [CLASSIFICATION_KEYS_CODE.clinicalTrials]: [
    EDITOR_ROLES.EDTK0100,
    EDITOR_ROLES.EDTK0201,
    EDITOR_ROLES.EDTK0202,
    EDITOR_ROLES.EDTK0204,
  ],
  [CLASSIFICATION_KEYS_CODE.clinicalTrialStatus]: [
    EDITOR_ROLES.EDTK0100,
    EDITOR_ROLES.EDTK0303,
    EDITOR_ROLES.EDTK0304,
  ],
  [CLASSIFICATION_KEYS_CODE.posterContents]: [
    EDITOR_ROLES.EDTK0100,
    EDITOR_ROLES.EDTK0401,
    EDITOR_ROLES.EDTK0402,
    EDITOR_ROLES.EDTK0404,
  ],
  [CLASSIFICATION_KEYS_CODE.deals]: [
    EDITOR_ROLES.EDTK0100,
    EDITOR_ROLES.EDTK0503,
    EDITOR_ROLES.EDTK0504,
  ],
  [CLASSIFICATION_KEYS_CODE.expeditedProgram]: [
    EDITOR_ROLES.EDTK0100,
    EDITOR_ROLES.EDTK0603,
    EDITOR_ROLES.EDTK0604,
  ],
  [CLASSIFICATION_KEYS_CODE.approvalProcess]: [
    EDITOR_ROLES.EDTK0100,
    EDITOR_ROLES.EDTK0703,
    EDITOR_ROLES.EDTK0704,
  ],
  [CLASSIFICATION_KEYS_CODE.ventureCapital]: [
    EDITOR_ROLES.EDTK0100,
    EDITOR_ROLES.EDTK0803,
    EDITOR_ROLES.EDTK0804,
  ],
};

export const PUBLISH_RETURN_ROLES_LIST: Record<string, EditorRoleCode> = {
  [CLASSIFICATION_KEYS_CODE.clinicalTrials]: EDITOR_ROLES.EDTK0204,
  [CLASSIFICATION_KEYS_CODE.clinicalTrialStatus]: EDITOR_ROLES.EDTK0304,
  [CLASSIFICATION_KEYS_CODE.posterContents]: EDITOR_ROLES.EDTK0404,
  [CLASSIFICATION_KEYS_CODE.deals]: EDITOR_ROLES.EDTK0504,
  [CLASSIFICATION_KEYS_CODE.expeditedProgram]: EDITOR_ROLES.EDTK0604,
  [CLASSIFICATION_KEYS_CODE.approvalProcess]: EDITOR_ROLES.EDTK0704,
  [CLASSIFICATION_KEYS_CODE.ventureCapital]: EDITOR_ROLES.EDTK0804,
};
