import React, { useCallback, useEffect } from "react";

import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { REGULATORY_GROUP_KEY } from "@FillInTableContainer/shared/constants/regulatoryKey";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import {
  DEFAULT_FOCUS_STATUS,
  FocusStatus,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useHandleRegulatoryApprovalProcessShell } from "./useHandleRegulatoryApprovalProcessShell";
import { useHandleRegulatoryDrugProfileShell } from "./useHandleRegulatoryDrugProfileShell";
import { useHandleRegulatoryKeyCommentShell } from "./useHandleRegulatoryKeyCommentShell";

import type { PositionInfoProps } from "../RegulatoryTableCreatorShell";

interface UseHandleTableCreatorShellReturn {
  handleFocusTextFieldWrapper: (event: React.MouseEvent) => void;
  handleClickToDeleteShell: () => void;
  handleFocusOutShell: () => void;
  isFocused: boolean;
  isClearShell: boolean;
  isDeleteShell: boolean;
  isEnableEdit: boolean;
  isAlertShell: boolean;
  focusStatus: FocusStatus;
  isClearTable: boolean;
  isDeleteTable: boolean;
}

export const useHandleRegulatoryTableCreatorShell = (
  positionPropsInfo: PositionInfoProps,
): UseHandleTableCreatorShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
    shellKey,
    shellIndex,
  } = positionPropsInfo;

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { validatedInfo, changeValidatedInfo } = useValidatedInTableCreator();
  const {
    handleClickToDeleteDrugProfileShell,
    handleKeyDownToDeleteDrugProfileShell,
  } = useHandleRegulatoryDrugProfileShell(positionPropsInfo);
  const {
    handleClickToDeleteApprovalProcessShell,
    handleKeyDownToDeleteApprovalProcessShell,
  } = useHandleRegulatoryApprovalProcessShell(positionPropsInfo);
  const {
    handleClickToDeleteKeyCommentShell,
    handleKeyDownToDeleteKeyCommentShell,
  } = useHandleRegulatoryKeyCommentShell(positionPropsInfo);

  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );
  const isEnableEdit = articleInfo
    ? ENABLE_GROUP_TABLE_KEY_LIST.Regulatory[groupTableKey].includes(
        articleInfo.draftArticleStatus,
      )
    : false;

  const isFocused =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableIndex === tableIndex &&
    info.tableKey === tableKey &&
    info.groupShellKey === groupShellKey &&
    info.groupShellIndex === groupShellIndex &&
    info.shellKey === shellKey &&
    info.shellIndex === shellIndex &&
    isEnableEdit;

  const isClearShell =
    isFocused &&
    focusStatus.type === "clear" &&
    focusStatus.container === "shell";
  const isDeleteShell =
    isFocused &&
    focusStatus.type === "delete" &&
    focusStatus.container === "shell";

  const isAlertShell =
    validatedInfo.filter(
      (validatedItem) =>
        validatedItem.groupTableKey === groupTableKey &&
        validatedItem.groupTableIndex === groupTableIndex &&
        validatedItem.tableIndex === tableIndex &&
        validatedItem.groupShellKey === groupShellKey &&
        validatedItem.groupShellIndex === groupShellIndex &&
        validatedItem.shellKey === shellKey &&
        validatedItem.shellIndex === shellIndex &&
        validatedItem.valueIndex === null &&
        validatedItem.type === "alert" &&
        validatedItem.container === "shell",
    ).length > 0;

  const isTableFocus =
    info.groupTableKey === groupTableKey &&
    info.tableKey === tableKey &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === null &&
    info.groupShellIndex === null &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    isEnableEdit;

  const isClearTable =
    isTableFocus &&
    focusStatus.type === "clear" &&
    focusStatus.container === "table";

  const isDeleteTable =
    isTableFocus &&
    focusStatus.type === "delete" &&
    focusStatus.container === "table";

  const handleFocusTextFieldWrapper = (event: React.MouseEvent) => {
    event.stopPropagation();
    changeInfo({
      ...positionPropsInfo,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      valueIndex: null,
    });
    changeFocusStatus({
      type: "clear",
      container: "shell",
    });
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleFocusOutShell = () => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  };

  const handleClickToDeleteShell = () => {
    if (info.groupTableKey === REGULATORY_GROUP_KEY.drugProfile) {
      handleClickToDeleteDrugProfileShell();
    }

    if (info.groupTableKey === REGULATORY_GROUP_KEY.approvalProcessContents) {
      handleClickToDeleteApprovalProcessShell();
    }

    if (info.groupTableKey === REGULATORY_GROUP_KEY.keyComment) {
      handleClickToDeleteKeyCommentShell();
    }
  };

  const handleKeyDownToDeleteShell = useCallback(
    (event: KeyboardEvent) => {
      if (info.groupTableKey === REGULATORY_GROUP_KEY.drugProfile) {
        handleKeyDownToDeleteDrugProfileShell(event);
      }

      if (info.groupTableKey === REGULATORY_GROUP_KEY.approvalProcessContents) {
        handleKeyDownToDeleteApprovalProcessShell(event);
      }

      if (info.groupTableKey === REGULATORY_GROUP_KEY.keyComment) {
        handleKeyDownToDeleteKeyCommentShell(event);
      }
    },
    [
      handleKeyDownToDeleteApprovalProcessShell,
      handleKeyDownToDeleteDrugProfileShell,
      handleKeyDownToDeleteKeyCommentShell,
      info.groupTableKey,
    ],
  );

  useEffect(() => {
    if (focusStatus.container !== "shell") return;
    window.addEventListener("keydown", handleKeyDownToDeleteShell);

    return () => {
      window.removeEventListener("keydown", handleKeyDownToDeleteShell);
    };
  }, [focusStatus, handleKeyDownToDeleteShell]);

  return {
    handleFocusTextFieldWrapper,
    handleClickToDeleteShell,
    handleFocusOutShell,
    isFocused,
    isClearShell,
    isDeleteShell,
    isEnableEdit,
    isAlertShell,
    focusStatus,
    isClearTable,
    isDeleteTable,
  };
};
