import styled, { css } from "styled-components";

export const TableContentWrapper = styled.div<{
  isFocused: boolean;
  isClearTable: boolean;
  isDeleteTable?: boolean;
  isTableScroll?: boolean;
  isAddTable?: boolean;
  isDraggingTable?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
  padding: 0;
  border: 2px solid transparent;
  background: var(--Color-Common-Bg-Primary, #fff);

  ${({ isFocused }) => isFocused && css``}

  ${({ isClearTable }) =>
    isClearTable &&
    css`
      border-radius: var(--Border-Radius-0, 0rem);
      border: 2px solid
        var(--Color-Common-Border-Interactive-Primary-Subtle-Pressed, #76cffe);

      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
        0px 2px 4px 0px rgba(0, 0, 0, 0.16);
    `}
  
    ${({ isDeleteTable }) =>
    isDeleteTable &&
    css`
      border: 2px solid var(--Color-Border-Error, #f9a9d0);
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
        0px 2px 4px 0px rgba(0, 0, 0, 0.16);
    `}
  
      ${({ isDraggingTable }) =>
    isDraggingTable &&
    css`
      border: 2px solid var(--Color-Border-Draft, #b3eaef);
      background: var(--Color-Common-Bg-Primary, #fff);

      /* Elevation/Light/Shadow4 */
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
        0px 0px 2px 0px rgba(0, 0, 0, 0.12);
    `}
`;

export const DefaultGroupShellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.color.neural.white};
`;

export const DefaultDeleteTableButtonWrapper = styled.div`
  position: absolute;
  top: -2.6rem;
  left: -0.2rem;
  display: flex;
  width: 100%;
  height: 2rem;
`;

export const DefaultDeleteTableButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  height: 2.4rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.neural.white};
  padding: 0.4rem;
  border-radius: var(--Border-Radius-2, 0.2rem);
  background: var(--Color-Common-Bg-Warning, #e53e06);
  white-space: nowrap;
  cursor: pointer;
  z-index: 25;
`;
