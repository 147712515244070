import React, { useState } from "react";

const useCheckPressedShell = () => {
  const [isPressed, setIsPressed] = useState(false);
  const handleChangePressedStatus = (
    e: React.MouseEvent,
    isPressedStatus: boolean,
  ) => {
    const target = e.target as HTMLElement;

    if (target.dataset.dropdown) return setIsPressed(false);

    setIsPressed(isPressedStatus);
  };

  return {
    isPressed,
    handleChangePressedStatus,
  };
};

export default useCheckPressedShell;
