import React from "react";

import ErrorPageView from "@/components/shared/ui/ErrorPageView/ErrorPageView";

interface Props {
  errorStatus: number;
  handleButtonClick: () => void;
}

const TimeoutError = ({ errorStatus, handleButtonClick }: Props) => {
  return (
    <ErrorPageView
      title="Gateway Timeout"
      message="Sorry, we couldn't process your request in time. 
    Please check your internet connection and try again later."
      buttonLabel="Reload page"
      handleButtonClick={handleButtonClick}
      errorStatus={errorStatus}
    />
  );
};

export default TimeoutError;
