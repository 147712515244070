import React from "react";

import { IconXCircle } from "@frontend/common";

import * as S from "./DeleteButton.style";

type Props = {
  deleteCurrentTextField: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const DeleteButton = ({ deleteCurrentTextField }: Props) => {
  return (
    <S.DeleteButton
      type="button"
      onClick={deleteCurrentTextField}
      className="delete-value-button"
    >
      <IconXCircle width={24} height={24} color="#515C6C" />
    </S.DeleteButton>
  );
};

export default DeleteButton;
