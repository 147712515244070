import { useSuspenseQuery } from "@tanstack/react-query";

import { queryClient } from "@api/queryClient";
import { QUERY_KEYS } from "@api/queryKeys";

import { ResponseDisplayDealTypeName } from "./dropDownList.interface";
import { dropDownList } from "./dropDownList.service";

export const useDisplayDealTypeNameQuery = () => {
  const query = useSuspenseQuery<ResponseDisplayDealTypeName>({
    queryKey: [QUERY_KEYS.displayDealTypeName],
    queryFn: () => dropDownList.display(),
    gcTime: 1000 * 60 * 60 * 24,
  });

  return query;
};

export const useDesDealTypeDropdownDisplayNameQuery = () => {
  const query = useSuspenseQuery<ResponseDisplayDealTypeName>({
    queryKey: [QUERY_KEYS.desDealTypeName],
    queryFn: () => dropDownList.desDisplayDealType(),
    gcTime: 1000 * 60 * 60 * 24,
  });

  return query;
};

export const fetchDisplayDealTypeName = async () => {
  const response = queryClient.fetchQuery<ResponseDisplayDealTypeName>({
    queryKey: [QUERY_KEYS.displayDealTypeName],
    queryFn: () => dropDownList.display(),
    gcTime: 1000 * 60 * 60 * 24,
  });

  return response;
};
