export const ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_TABLE_KEY =
  "Round Type & Status" as const;

export const ENTRY_DATA_ROUND_TYPE_AND_STATUS_KEYS = {
  roundType: "Round Type",
  roundName: "Round Name",
} as const;

export const ENTRY_DATA_ROUND_TYPE_AND_STATUS_SHELL_KEYS = {
  roundType: "Round Type",
  roundName: "Round Name",
} as const;

export const ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_SHELL_KEYS = {
  none: null,
};

export const ENTRY_DATA_ROUND_TYPE_AND_STATUS_TABLE_KEYS = {
  none: null,
};
