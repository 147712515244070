import { fetchDisplayDealTypeName } from "@api/dropDownList/dropdownList";
import { DEAL_TYPE_DISPLAY_NAME } from "@constants/dealTypeDisplayName.constants";

export const setDealTypeDisplayName = async () => {
  const getSavedDealTypeDisplayData = sessionStorage.getItem(
    DEAL_TYPE_DISPLAY_NAME,
  );

  if (getSavedDealTypeDisplayData) return;
  const dealTypeDisplayNameData = await fetchDisplayDealTypeName();

  sessionStorage.setItem(
    DEAL_TYPE_DISPLAY_NAME,
    JSON.stringify(dealTypeDisplayNameData.data),
  );
};

export const returnDealTypeDisplayName = (currentDealType: string) => {
  const dealTypeDisplayNameData = sessionStorage.getItem(
    DEAL_TYPE_DISPLAY_NAME,
  );

  if (!dealTypeDisplayNameData) setDealTypeDisplayName();

  const displayNameData = sessionStorage.getItem(DEAL_TYPE_DISPLAY_NAME);

  if (!displayNameData) return currentDealType;

  return JSON.parse(displayNameData)[currentDealType] || currentDealType;
};

export const removeDealTypeDisplayName = () =>
  sessionStorage.removeItem(DEAL_TYPE_DISPLAY_NAME);
