import styled, { keyframes } from "styled-components";

export const PreviewLoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const PreviewLoadingText = styled.div`
  color: var(--Color-Common-Text-Tertiary, #7c899c);
  font-size: 1.4rem;
  font-weight: 500;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const IconLoader = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  justify-content: center;
  align-items: center;
  animation: ${rotate} 1.5s linear infinite;
`;
