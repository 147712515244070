import React from "react";

import * as S from "./BottomButtonContainer.style";

interface Props {
  children: React.ReactNode;
}

const BottomButtonContainer = ({ children }: Props) => {
  return (
    <S.BottomButtonContainer>
      <S.CenterContainer>{children}</S.CenterContainer>
    </S.BottomButtonContainer>
  );
};

export default BottomButtonContainer;
