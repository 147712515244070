import { atomFamily, selectorFamily, useRecoilValue } from "recoil";

import { CurrencyKeywordInfo } from "@api/pressContent/pressContent.interface";
import { pressContentApis } from "@api/pressContent/pressContent.service";

import { RECOIL_KEYS } from "./recoilKeys";

const pressContentCurrencyState = atomFamily<CurrencyKeywordInfo, "currency">({
  key: RECOIL_KEYS.pressContentCurrencyKeyword,

  default: async () => {
    const response = await pressContentApis.getCurrencyKeyword();
    return response.data;
  },
});

const currencyInfoState = selectorFamily<
  {
    paymentKeyword: string[];
    currency: string[];
    unit: string[];
  },
  "currency"
>({
  key: RECOIL_KEYS.pressContentCurrencyInfo,
  get:
    () =>
    ({ get }) => {
      const originalList = get(pressContentCurrencyState("currency"));
      const paymentKeywordData = originalList.paymentKeyword.map((item) =>
        item.name.trim(),
      );
      const currencyData = Object.keys(originalList.currency)
        .map((item) => item.replace("$", "\\$"))
        .filter((item) => !!item);
      const currencyValuesData = Object.values(originalList.currency)
        .map((item) => item.replace("$", "\\$"))
        .filter((item) => !!item);
      const unitData = Object.keys(originalList.unit)
        .map((item) => item.trim())
        .filter((item) => !!item);
      const currencyInfo = {
        paymentKeyword: paymentKeywordData,
        currency: [...new Set([...currencyData, ...currencyValuesData])],
        unit: unitData,
      };

      return currencyInfo;
    },
});

export const useCurrencyInfoValue = () =>
  useRecoilValue(currencyInfoState("currency"));
