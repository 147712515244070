export const ARTICLE_CONTENTS_DEAL_TERMS_KEYS = {
  contingentMilestoneMa: "Contingent Milestone (MA)",
  contingentPayment: "Contingent Payment",
  contingentPaymentMa: "Contingent Payment (MA)",
  contingentPricePerShareMa: "Contingent Price per share (MA)",
  dealTerms: "Deal Terms",
  drug: "Drug",
  exclusivity: "Exclusivity",
  indication: "Indication",
  keyAssetsAndIndication: "Key Assets And Indication",
  licenseeName: "Licensee Name",
  licenseeResponsibilityRightsMa: "Licensee Responsibility & Rights (MA)",
  licenseeResponsibilityRightsScLa:
    "Licensee Responsibility & Rights (SC & LA)",
  licenseeTable: "Licensee Table",
  licensorName: "Licensor Name",
  licensorResponsibilityRights: "Licensor Responsibility & Rights",
  licensorRights: "Licensor Rights",
  licensorTable: "Licensor Table",
  mergeAcquisitionTerms: "Merge & Acquisition terms",
  mergerFinancialTermsMa: "Merger Financial Terms (MA)",
  milestoneConditionTitle: "Milestone Condition Title",
  milestonePayment: "Milestone Payment",
  milestonePaymentAndCondition: "Milestone Payment and Condition",
  numberOfAcquiringShares: "Number of acquiring shares",
  otherFinancialTermsMa: "Other Financial Terms (MA)",
  otherFinancialTermsScLa: "Other Financial Terms (SC & LA)",
  otherPaymentAndDetailScLa: "Other Payment and Detail (SC & LA)",
  otherPaymentDetailScLa: "Other Payment Detail (SC & LA)",
  otherPaymentScLa: "Other Payment (SC & LA)",
  pricePerShare: "Price Per Share",
  rightsScope: "Rights Scope",
  royalty: "Royalty",
  strategicCollaborationLicenseAgreementTerms:
    "Strategic Collaboration & License Agreement terms",
  territory: "Territory",
  totalPaymentMa: "Total Payment (MA)",
  totalPaymentScLa: "Total Payment (SC & LA)",
  upfrontPayment: "Upfront Payment",
  upfrontPaymentMa: "Upfront Payment (MA)",
  upfrontPricePerShareMa: "Upfront Price Per Share (MA)",
} as const;
