"use client";

import { css } from "styled-components";

export const rootStyles = css`
  :root {
    --Color-Text-Primary: #2a2f37;
    --Color-Text-Secondary: #515c6c;
    --Color-Text-Tertiary: #7c899c;
    --Color-Text-Info-Bold: #002d8f;
    --Color-Text-Info: #004aeb;
    --Color-Text-Warning-Bold: #8b2504;
    --Color-Text-Warning: #e53e06;
    --Color-Text-Success-Bold: #015d8e;
    --Color-Text-Success: #0198e9;
    --Color-Text-Danger-Bold: #860912;
    --Color-Text-Danger: #db0f1d;
    --Color-Text-Disabled: #96a1b0;
    --Color-Text-Interactive-Primary: #0198e9;
    --Color-Text-Interactive-Primary-Hovered: #017abc;
    --Color-Text-Interactive-Primary-Pressed: #015d8e;
    --Color-Text-Interactive-Secondary: #515c6c;
    --Color-Text-Interactive-Secondary-Hovered: #3d4551;
    --Color-Text-Interactive-Secondary-Pressed: #2a2f37;
    --Color-Text-Interactive-Selected: #0198e9;
    --Color-Text-Interactive-Visited: #017abc;
    --Color-Text-Interactive-Inverse: #ffffff;
    --Color-Bg-Primary: #ffffff;
    --Color-Bg-Secondary: #f0f2f4;
    --Color-Bg-Tertiary: #cbd0d8;
    --Color-Bg-Brand: #2ab1fe;
    --Color-Bg-Info-Subtle: #d1e0ff;
    --Color-Bg-Info: #004aeb;
    --Color-Bg-Warning-Subtle: #feddd2;
    --Color-Bg-Warning: #e53e06;
    --Color-Bg-Success-Subtle: #e6f6ff;
    --Color-Bg-Success: #1baefe;
    --Color-Bg-Danger-Subtle: #fcd4d7;
    --Color-Bg-Danger: #db0f1d;
    --Color-Bg-Inverse: #515c6c;
    --Color-Bg-Inverse-Bold: #3d4551;
    --Color-Bg-Disabled: #cbd0d8;
    --Color-Bg-Interactive-Primary: #1baefe;
    --Color-Bg-Interactive-Primary-Hovered: #0198e9;
    --Color-Bg-Interactive-Primary-Pressed: #017abc;
    --Color-Bg-Interactive-Primary-Subtle: #e6f6ff;
    --Color-Bg-Interactive-Primary-Subtle-Hovered: #d1efff;
    --Color-Bg-Interactive-Primary-Subtle-Pressed: #a4dfff;
    --Color-Bg-Interactive-Secondary: #ffffff;
    --Color-Bg-Interactive-Secondary-Hovered: #e5e7eb;
    --Color-Bg-Interactive-Secondary-Pressed: #cbd0d8;
    --Color-Bg-Interactive-Tertiary: #c2f8ff;
    --Color-Bg-Interactive-Tertiary-Hovered: #8ff2ff;
    --Color-Bg-Interactive-Tertiary-Pressed: #5cebfe;
    --Color-Bg-Interactive-Selected: #e6f6ff;
    --Color-Bg-Interactive-Selected-Hovered: #a4dfff;
    --Color-Bg-Interactive-Selected-Pressed: #76cffe;
    --Color-Bg-Interactive-Destructive: #db0f1d;
    --Color-Bg-Interactive-Destructive-Hovered: #b00c17;
    --Color-Bg-Interactive-Destructive-Pressed: #860912;
    --Color-Bg-Interactive-Inverse: #3d4551;
    --Color-Icon-Primary: #2a2f37;
    --Color-Icon-Secondary: #515c6c;
    --Color-Icon-Tertiary: #7c899c;
    --Color-Icon-Brand: #0198e9;
    --Color-Icon-Info: #004aeb;
    --Color-Icon-Info-Subtle: #d1e0ff;
    --Color-Icon-Warning: #e53e06;
    --Color-Icon-Warning-Subtle: #feddd2;
    --Color-Icon-Success: #0198e9;
    --Color-Icon-Success-Subtle: #d1efff;
    --Color-Icon-Danger: #db0f1d;
    --Color-Icon-Danger-Subtle: #fcd4d7;
    --Color-Icon-Disabled: #96a1b0;
    --Color-Icon-Interactive-Primary: #0198e9;
    --Color-Icon-Interactive-Primary-Hovered: #017abc;
    --Color-Icon-Interactive-Primary-Pressed: #015d8e;
    --Color-Icon-Interactive-Secondary: #515c6c;
    --Color-Icon-Interactive-Secondary-Hovered: #3d4551;
    --Color-Icon-Interactive-Secondary-Pressed: #2a2f37;
    --Color-Icon-Interactive-Tertiary: #01abc1;
    --Color-Icon-Interactive-Tertiary-Hovered: #017e8e;
    --Color-Icon-Interactive-Tertiary-Pressed: #00515b;
    --Color-Icon-Interactive-Visited: #017abc;
    --Color-Icon-Interactive-Inverse: #ffffff;
    --Color-Border-Primary: #7c899c;
    --Color-Border-Secondary: #b1b8c4;
    --Color-Border-Tertiary: #e5e7eb;
    --Color-Border-Focus-Ring: #017abc;
    --Color-Border-Info-Subtle: #a3c0ff;
    --Color-Border-Info: #004aeb;
    --Color-Border-Warning-Subtle: #fdbba5;
    --Color-Border-Warning: #e53e06;
    --Color-Border-Success-Subtle: #a4dfff;
    --Color-Border-Success: #0198e9;
    --Color-Border-Danger-Subtle: #f9a9ae;
    --Color-Border-Danger: #db0f1d;
    --Color-Border-Disabled: #b1b8c4;
    --Color-Border-Interactive-Primary: #0198e9;
    --Color-Border-Interactive-Primary-Hovered: #017abc;
    --Color-Border-Interactive-Primary-Pressed: #015d8e;
    --Color-Border-Interactive-Primary-Subtle: #d1efff;
    --Color-Border-Interactive-Primary-Subtle-Hovered: #a4dfff;
    --Color-Border-Interactive-Primary-Subtle-Pressed: #76cffe;
    --Color-Border-Interactive-Secondary: #b1b8c4;
    --Color-Border-Interactive-Secondary-Hovered: #96a1b0;
    --Color-Border-Interactive-Secondary-Pressed: #7c899c;
    --Color-Border-Interactive-Tertiary: #5cebfe;
    --Color-Border-Interactive-Tertiary-Hovered: #2ae5fe;
    --Color-Border-Interactive-Tertiary-Pressed: #01d9f6;
    --Color-Brand-50: #e6f6ff;
    --Color-Brand-100: #d1efff;
    --Color-Brand-200: #a4dfff;
    --Color-Brand-300: #76cffe;
    --Color-Brand-400: #48befe;
    --Color-Brand-500: #1baefe;
    --Color-Brand-600: #0198e9;
    --Color-Brand-700: #017abc;
    --Color-Brand-800: #015d8e;
    --Color-Brand-900: #003f60;
    --Color-Brand-Base: #2ab1fe;
    --Color-Information-50: #e5eeff;
    --Color-Information-100: #d1e0ff;
    --Color-Information-200: #a3c0ff;
    --Color-Information-300: #75a1ff;
    --Color-Information-400: #4782ff;
    --Color-Information-500: #1a62ff;
    --Color-Information-600: #004aeb;
    --Color-Information-700: #003cbd;
    --Color-Information-800: #002d8f;
    --Color-Information-900: #001f61;
    --Color-Information-Base: #125aff;
    --Color-Success-50: #e6f6ff;
    --Color-Success-100: #d1efff;
    --Color-Success-200: #a4dfff;
    --Color-Success-300: #76cffe;
    --Color-Success-400: #48befe;
    --Color-Success-500: #1baefe;
    --Color-Success-600: #0198e9;
    --Color-Success-700: #017abc;
    --Color-Success-800: #015d8e;
    --Color-Success-900: #003f60;
    --Color-Success-Base: #2ab1fe;
    --Color-Warning-50: #feece6;
    --Color-Warning-100: #feddd2;
    --Color-Warning-200: #fdbba5;
    --Color-Warning-300: #fc9979;
    --Color-Warning-400: #fa784c;
    --Color-Warning-500: #f9561f;
    --Color-Warning-600: #e53e06;
    --Color-Warning-700: #b83205;
    --Color-Warning-800: #8b2504;
    --Color-Warning-900: #5e1902;
    --Color-Warning-Base: #fa612e;
    --Color-Danger-50: #fde7e9;
    --Color-Danger-100: #fcd4d7;
    --Color-Danger-200: #f9a9ae;
    --Color-Danger-300: #f67e86;
    --Color-Danger-400: #f3535e;
    --Color-Danger-500: #f02836;
    --Color-Danger-600: #db0f1d;
    --Color-Danger-700: #b00c17;
    --Color-Danger-800: #860912;
    --Color-Danger-900: #5b060c;
    --Color-Danger-Base: #f23c47;
    --Color-Neutral-50: #f0f2f4;
    --Color-Neutral-100: #e5e7eb;
    --Color-Neutral-200: #cbd0d8;
    --Color-Neutral-300: #b1b8c4;
    --Color-Neutral-400: #96a1b0;
    --Color-Neutral-500: #7c899c;
    --Color-Neutral-600: #657286;
    --Color-Neutral-700: #515c6c;
    --Color-Neutral-800: #3d4551;
    --Color-Neutral-900: #2a2f37;
    --Color-Neutral-Base: #8c98aa;
    --Color-Neural-Gray-900: #2a2f37;
    --Color-Neural-Gray-700: #515c6c;
    --Color-Neural-Gray-500: #7c899c;
    --Color-Neural-BW-White: #ffffff;
    --Color-Neural-Gray-050: #f0f2f4;
    --Color-Neural-Gray-200: #cbd0d8;
    --Color-Node-Cyan-100: #c2f8ff;
    --Color-Node-Cyan-200: #8ff2ff;
    --Color-Node-Cyan-300: #5cebfe;
    --Color-Neural-Gray-800: #3d4551;
    --Color-Node-Cyan-600: #01abc1;
    --Color-Node-Cyan-700: #017e8e;
    --Color-Node-Cyan-800: #00515b;
    --Color-Neural-Gray-300: #b1b8c4;
    --Color-Neural-Gray-100: #e5e7eb;
    --Color-Node-Cyan-400: #2ae5fe;
    --Color-Node-Cyan-500: #01d9f6;
    --Color-Node-Sky-50: #e6f6ff;
    --Color-Node-Sky-100: #d1efff;
    --Color-Node-Sky-200: #a4dfff;
    --Color-Node-Sky-300: #76cffe;
    --Color-Node-Sky-400: #48befe;
    --Color-Node-Sky-500: #1baefe;
    --Color-Node-Sky-600: #0198e9;
    --Color-Node-Sky-700: #017abc;
    --Color-Node-Sky-800: #015d8e;
    --Color-Node-Sky-900: #003f60;
    --Color-Node-Sky-sky-base: #2ab1fe;
    --Color-Node-Blue-50: #e5eeff;
    --Color-Node-Blue-100: #d1e0ff;
    --Color-Node-Blue-200: #a3c0ff;
    --Color-Node-Blue-300: #75a1ff;
    --Color-Node-Blue-400: #4782ff;
    --Color-Node-Blue-500: #1a62ff;
    --Color-Node-Blue-600: #004aeb;
    --Color-Node-Blue-700: #003cbd;
    --Color-Node-Blue-800: #002d8f;
    --Color-Node-Blue-900: #001f61;
    --Color-Node-Blue-blue-base: #125aff;
    --Color-Live-Mandarin-50: #feece6;
    --Color-Live-Mandarin-100: #feddd2;
    --Color-Live-Mandarin-200: #fdbba5;
    --Color-Live-Mandarin-300: #fc9979;
    --Color-Live-Mandarin-400: #fa784c;
    --Color-Live-Mandarin-500: #f9561f;
    --Color-Live-Mandarin-600: #e53e06;
    --Color-Live-Mandarin-700: #b83205;
    --Color-Live-Mandarin-800: #8b2504;
    --Color-Live-Mandarin-900: #5e1902;
    --Color-Live-Mandarin-Base: #fa612e;
    --Color-Live-Crimson-50: #fde7e9;
    --Color-Live-Crimson-100: #fcd4d7;
    --Color-Live-Crimson-200: #f9a9ae;
    --Color-Live-Crimson-300: #f67e86;
    --Color-Live-Crimson-400: #f3535e;
    --Color-Live-Crimson-500: #f02836;
    --Color-Live-Crimson-600: #db0f1d;
    --Color-Live-Crimson-700: #b00c17;
    --Color-Live-Crimson-800: #860912;
    --Color-Live-Crimson-900: #5b060c;
    --Color-Live-Crimson-crimson-base: #f23c47;
    --Color-Neural-Gray-400: #96a1b0;
    --Color-Neural-Gray-600: #657286;
    --Color-Neural-Gray-Base: #8c98aa;
  }
`;
