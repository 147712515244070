import { useEffect } from "react";

import { useSaveEditedArticle } from "./useSaveEditedArticle";

export const useSavedAsDraftContentsWithShortCut = () => {
  const { saveArticleContent } = useSaveEditedArticle();
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        event.preventDefault();
        saveArticleContent();
      }
    };

    document.addEventListener("keydown", handleKeyDown, true);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, true);
    };
  }, [saveArticleContent]);
};
