import { useCallback } from "react";

import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS as DRUG_PROFILE_SHELL_KEYS,
} from "@frontend/common";

import { VentureCapitalDrugProfileShellKeys } from "@/types/ventureCapital.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { PositionInfoProps } from "../VentureCapitalTableCreatorShell";
import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorShellReturn {
  handleClickToClearDrugProfileShell: () => void;
  handleKeyDownToClearDrugProfileShell: (event: KeyboardEvent) => void;
}

export const useHandleVentureCapitalDrugProfileShell = (
  positionPropsInfo: PositionInfoProps,
): UseHandleTableCreatorShellReturn => {
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const handleClearShell = useCallback(() => {
    const clearDrugProfileShell = (): ArticleEntryData => {
      return {
        ...entryData,
        [SECTION_KEYS.ventureCapital]: {
          ...entryData?.[SECTION_KEYS.ventureCapital],
          [DRUG_PROFILE]: entryData?.[SECTION_KEYS.ventureCapital]?.[
            DRUG_PROFILE
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [info.shellKey as VentureCapitalDrugProfileShellKeys]: {
                  ...tableInfo[
                    info.shellKey as VentureCapitalDrugProfileShellKeys
                  ],
                  value:
                    info.shellKey === DRUG_PROFILE_SHELL_KEYS.genericBiosimilar
                      ? ["false"]
                      : [],
                },
              };
            }
            return tableInfo;
          }),
        },
      };
    };

    if (info.groupTableKey === DRUG_PROFILE) {
      setEntryData(clearDrugProfileShell());
      changeCurrentHistory({
        entryData: clearDrugProfileShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  const handleClickToClearDrugProfileShell = () => {
    if (focusStatus.type === "clear") {
      handleClearShell();
    }
  };

  const handleKeyDownToClearDrugProfileShell = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearShell();
      }
    },
    [focusStatus.type, handleClearShell],
  );

  return {
    handleClickToClearDrugProfileShell,
    handleKeyDownToClearDrugProfileShell,
  };
};
