import Modal from "@atoms/Modal/Modal";

import * as S from "./DeletedPressModal.style";

type Props = {
  closeModal: () => void;
};

const DeletedPressModal = ({ closeModal }: Props) => {
  return (
    <Modal closeModal={closeModal}>
      <S.ModalContentsWrapper>
        <S.ModalTitle>Deleted Press</S.ModalTitle>
        <S.ModalText>
          This press has been deleted. Please check other press items.
        </S.ModalText>
        <S.ModalButtonWrapper>
          <S.CancelButton type="button" onClick={closeModal}>
            OK
          </S.CancelButton>
        </S.ModalButtonWrapper>
      </S.ModalContentsWrapper>
    </Modal>
  );
};

export default DeletedPressModal;
