import * as S from "./TextHoverTooltip.style";

interface Props {
  text: string;
}
const TextHoverTooltip = ({ text }: Props) => {
  return <S.TooltipWrapper>{text}</S.TooltipWrapper>;
};

export default TextHoverTooltip;
