import {
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS as DRUG_PROFILE_SHELL_KEYS,
  ENTRY_DATA_KEY_COMMENTS_SHELL_KEYS as KEY_COMMENTS_SHELL_KEYS,
  ENTRY_DATA_KEY_COMMENTS_GROUP_TABLE_KEY as KEY_COMMENTS,
  ENTRY_DATA_INVESTORS_GROUP_TABLE_KEY as INVESTORS,
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_TABLE_KEY as ADDITIONAL_INFORMATION_VENTURE_CAPITAL,
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_KEYS as ADDITIONAL_INFORMATION_VENTURE_CAPITAL_SHELL_KEYS,
  ENTRY_DATA_INVESTORS_SHELL_KEYS as INVESTORS_SHELL_KEYS,
  ENTRY_DATA_FUNDED_COMPANY_GROUP_TABLE_KEY as FUNDED_COMPANY,
  ENTRY_DATA_FUNDED_COMPANY_SHELL_KEYS as FUNDED_COMPANY_SHELL_KEYS,
  ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY as FINANCING_INFORMATION,
  ENTRY_DATA_FINANCING_INFORMATION_SHELL_KEYS as FINANCING_INFORMATION_SHELL_KEYS,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_TABLE_KEY as ROUND_TYPE_AND_STATUS,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_SHELL_KEYS as ROUND_TYPE_AND_STATUS_SHELL_KEYS,
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  DEAL_TYPE_SHELL_KEYS,
  ENTRY_DATA_DEAL_TYPE_AND_STATUS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
  ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS,
  ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS,
  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS,
  ENTRY_DATA_CONTINGENT_PAYMENT_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import { ClinicalTrials } from "@/types/clinicalTrials.types";
import { ClinicalTrialStatus } from "@/types/clinicalTrialStatus.types";
import { Deals } from "@/types/deals.types";
import { ExpeditedProgram } from "@/types/expeditedProgram.types";
import { Regulatory } from "@/types/regulatory.types";
import { VentureCapital } from "@/types/ventureCapital.types";
import {
  EXPEDITED_PROGRAM,
  EXPEDITED_PROGRAM_CONTENTS as k,
} from "@FillInTableContainer/shared/constants/expeditedProgramKey.constants";
import { createAttributionId } from "@FillInTableContainer/shared/utils/createAttributionId";
import { ArticleSectionType } from "@FillInTableContainer/types/fillInTable.types";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import {
  checkClinicalEndpointGroupInformationSameShellLength,
  returnCurrentColumnGroupName,
  returnCurrentEndpointTableGroupInformationMaxLength,
} from "../utils/checkClinicalEndpointGroupInformationSameShellLength";

export const CHECK_ATTRIBUTION_ID_IN_ENTRY_DATA: {
  [key in ArticleSectionType]: (data: ArticleEntryData) => boolean;
} = {
  "Clinical Trials": (data: ArticleEntryData): boolean => {
    const ENTRY_DATA_BASIC_PATH = {
      "Drug Profile": data["Clinical Trials"]?.["Drug Profile"],
      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]:
        data["Clinical Trials"]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN],
      "Clinical Results": data["Clinical Trials"]?.["Clinical Results"],
      "Clinical Endpoints (Table)":
        data["Clinical Trials"]?.["Clinical Endpoints (Table)"],
      "Clinical Endpoints (Description)":
        data["Clinical Trials"]?.["Clinical Endpoints (Description)"],
      "Key Comment": data["Clinical Trials"]?.["Key Comment"],
    };

    const checkDrugProfile = () => {
      return ENTRY_DATA_BASIC_PATH["Drug Profile"]?.every((tableItem) => {
        return (
          tableItem.attributionInfo?.attributionId &&
          tableItem["Brand Name"]?.attributionId &&
          tableItem["INN Name"]?.attributionId &&
          tableItem["Code Name"]?.attributionId &&
          tableItem.MOA?.attributionId &&
          tableItem.Modality?.attributionId &&
          tableItem["Other Characteristics"]?.attributionId &&
          tableItem.ROA?.attributionId &&
          tableItem.Target?.attributionId &&
          tableItem["Target Indication"]?.attributionId &&
          tableItem["Therapeutic Area"]?.attributionId &&
          tableItem["Originator/Developer"]?.attributionId &&
          tableItem.Phase?.attributionId
        );
      });
    };

    const checkClinicalTrialDesign1 = () => {
      const targetPath =
        ENTRY_DATA_BASIC_PATH[ENTRY_DATA_CLINICAL_TRIAL_DESIGN];

      return targetPath?.every((tableItem) => {
        return (
          tableItem[ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]
            .attributionInfo?.attributionId &&
          tableItem[ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1][
            "Inclusion Criteria - Detail"
          ]?.attributionId !== undefined &&
          tableItem[ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1][
            "Inclusion Criteria - Diagnostic"
          ]?.attributionId !== undefined &&
          tableItem[ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1][
            "NCT Name"
          ]?.attributionId !== undefined &&
          tableItem[ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1][
            "Clinical Design Phase"
          ]?.attributionId !== undefined &&
          tableItem[ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1][
            "Total Patients Number"
          ]?.attributionId !== undefined &&
          tableItem[ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1][
            "Trial Name"
          ]?.attributionId !== undefined
        );
      });
    };

    const checkClinicalTrialDesign2 = () => {
      return ENTRY_DATA_BASIC_PATH[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.every(
        (tableInfo) =>
          tableInfo["Clinical Trial Design-2"]?.every((groupShellItem) => {
            return (
              groupShellItem["Arm Name"]?.attributionId &&
              groupShellItem["Patients Number"]?.attributionId &&
              groupShellItem["Design Group Information"]?.every((shellItem) => {
                return (
                  shellItem["Drug Name"]?.attributionId &&
                  shellItem.Dose?.attributionId &&
                  shellItem.Duration?.attributionId
                );
              })
            );
          }),
      );
    };

    const checkClinicalResults = () => {
      const targetPath = ENTRY_DATA_BASIC_PATH["Clinical Results"];
      if (!targetPath) return false;
      return targetPath["Results Classification"]?.attributionId !== undefined;
    };

    const checkClinicalEndpointsTable = () => {
      return ENTRY_DATA_BASIC_PATH["Clinical Endpoints (Table)"]?.every(
        (tableItem) => {
          return (
            tableItem.attributionInfo?.attributionId &&
            tableItem["Endpoints Table Title"]?.attributionId &&
            tableItem["Endpoints Table Notes"]?.attributionId &&
            tableItem["Endpoints Table Information"]?.every(
              (groupShellItem) => {
                return (
                  groupShellItem["Endpoint Classification"]?.attributionId &&
                  groupShellItem["Endpoint Name including Measurement Timing"]
                    ?.attributionId &&
                  groupShellItem["Endpoint Group Information"]?.every(
                    (shellItem) => {
                      return (
                        shellItem["Group Name"]?.attributionId &&
                        shellItem["Group Data"]?.attributionId &&
                        shellItem["P-Value"]?.attributionId
                      );
                    },
                  )
                );
              },
            )
          );
        },
      );
    };

    const checkClinicalEndpointsTableGroupInformationLength = () => {
      return checkClinicalEndpointGroupInformationSameShellLength(data);
    };

    const checkClinicalEndpointsDescription = () => {
      return (
        ENTRY_DATA_BASIC_PATH["Clinical Endpoints (Description)"]?.[
          "Endpoint Description"
        ]?.attributionId !== undefined
      );
    };

    const checkKeyComment = () => {
      const targetPath = ENTRY_DATA_BASIC_PATH["Key Comment"];
      if (!targetPath) return false;
      return (
        targetPath.Quote?.attributionId !== undefined &&
        targetPath.Spokesperson?.attributionId !== undefined
      );
    };

    if (
      !checkDrugProfile() ||
      !checkClinicalTrialDesign1() ||
      !checkClinicalTrialDesign2() ||
      !checkClinicalResults() ||
      !checkClinicalEndpointsTable() ||
      !checkClinicalEndpointsDescription() ||
      !checkKeyComment() ||
      !checkClinicalEndpointsTableGroupInformationLength()
    ) {
      return false;
    }

    return true;
  },
  "Clinical Trial Status": (data: ArticleEntryData): boolean => {
    const ENTRY_DATA_BASIC_PATH = {
      "Drug Profile": data["Clinical Trial Status"]?.["Drug Profile"],
      "Clinical Trial Design-1":
        data["Clinical Trial Status"]?.["Clinical Trial Design-1"],
    };

    const checkDrugProfile = () => {
      return ENTRY_DATA_BASIC_PATH["Drug Profile"]?.every((tableItem) => {
        return (
          tableItem.attributionInfo?.attributionId &&
          tableItem["Brand Name"]?.attributionId &&
          tableItem["INN Name"]?.attributionId &&
          tableItem["Code Name"]?.attributionId &&
          tableItem.MOA?.attributionId &&
          tableItem.Modality?.attributionId &&
          tableItem["Other Characteristics"]?.attributionId &&
          tableItem.ROA?.attributionId &&
          tableItem.Target?.attributionId &&
          tableItem["Target Indication"]?.attributionId &&
          tableItem["Therapeutic Area"]?.attributionId &&
          tableItem["Originator/Developer"]?.attributionId &&
          tableItem.Phase?.attributionId
        );
      });
    };
    const checkClinicalTrialDesign1 = () => {
      const targetPath = ENTRY_DATA_BASIC_PATH["Clinical Trial Design-1"] ?? [];

      return targetPath?.every((tableItem) => {
        return (
          tableItem["Inclusion Criteria - Detail"]?.attributionId !==
            undefined &&
          tableItem["Inclusion Criteria - Diagnostic"]?.attributionId !==
            undefined &&
          tableItem["NCT Name"]?.attributionId !== undefined &&
          tableItem["Clinical Design Phase"]?.attributionId !== undefined &&
          tableItem["Total Patients Number"]?.attributionId !== undefined &&
          tableItem["Trial Name"]?.attributionId !== undefined
        );
      });
    };

    if (!checkDrugProfile() || !checkClinicalTrialDesign1()) {
      return false;
    }

    return true;
  },
  Regulatory: (data: ArticleEntryData): boolean => {
    const ENTRY_DATA_BASIC_PATH = {
      "Drug Profile": data.Regulatory?.["Drug Profile"],
      "Approval Process Contents":
        data.Regulatory?.["Approval Process Contents"],
      "Key Comment": data.Regulatory?.["Key Comment"],
    };

    const checkDrugProfile = () => {
      return ENTRY_DATA_BASIC_PATH["Drug Profile"]?.every((tableItem) => {
        return (
          tableItem.attributionInfo?.attributionId &&
          tableItem["Brand Name"]?.attributionId &&
          tableItem["INN Name"]?.attributionId &&
          tableItem["Code Name"]?.attributionId &&
          tableItem.MOA?.attributionId &&
          tableItem.Modality?.attributionId &&
          tableItem["Other Characteristics"]?.attributionId &&
          tableItem.ROA?.attributionId &&
          tableItem.Target?.attributionId &&
          tableItem["Target Indication"]?.attributionId &&
          tableItem["Therapeutic Area"]?.attributionId
        );
      });
    };

    const checkRegulatoryApprovalProcess = () => {
      return (
        ENTRY_DATA_BASIC_PATH["Approval Process Contents"]?.[
          "Approval Process"
        ]?.every((tableItem) => {
          return (
            tableItem["Application Type"]?.attributionId &&
            tableItem["Authorized Company"]?.attributionId &&
            tableItem["Dosage and Administration on Label"]?.attributionId &&
            tableItem["Indication on Label"]?.attributionId &&
            tableItem["Marketing Status"]?.attributionId &&
            tableItem["Regulatory Agency"]?.attributionId &&
            tableItem["Update Date"]?.attributionId
          );
        }) &&
        ENTRY_DATA_BASIC_PATH["Approval Process Contents"][
          "Additional Information on the Approval Process"
        ]?.attributionId !== undefined
      );
    };

    const checkKeyComment = () => {
      const targetPath = ENTRY_DATA_BASIC_PATH["Key Comment"];
      if (!targetPath) return false;
      return (
        targetPath.Quote?.attributionId !== undefined &&
        targetPath.Spokesperson?.attributionId !== undefined
      );
    };

    if (
      !checkDrugProfile() ||
      !checkKeyComment() ||
      !checkRegulatoryApprovalProcess()
    ) {
      return false;
    }

    return true;
  },
  Deals: (data: ArticleEntryData): boolean => {
    const ENTRY_DATA_BASIC_PATH = {
      "Drug Profile": data.Deals?.["Drug Profile"],
      "Additional Information (Deal)":
        data.Deals?.["Additional Information (Deal)"],
      "Deal Type & Status": data.Deals?.["Deal Type & Status"],
      [ENTRY_DATA_LICENSEE_TABLE]: data.Deals?.[ENTRY_DATA_LICENSEE_TABLE],
      "Licensor Table": data.Deals?.["Licensor Table"],
      "Key Comment": data.Deals?.["Key Comment"],
    };

    const checkDrugProfile = () => {
      return ENTRY_DATA_BASIC_PATH["Drug Profile"]?.every((tableItem) => {
        return (
          tableItem.attributionInfo?.attributionId &&
          tableItem["Brand Name"]?.attributionId &&
          tableItem["INN Name"]?.attributionId &&
          tableItem["Code Name"]?.attributionId &&
          tableItem.MOA?.attributionId &&
          tableItem.Modality?.attributionId &&
          tableItem["Other Characteristics"]?.attributionId &&
          tableItem.ROA?.attributionId &&
          tableItem.Target?.attributionId &&
          tableItem["Target Indication"]?.attributionId &&
          tableItem["Therapeutic Area"]?.attributionId &&
          tableItem.Phase?.attributionId &&
          tableItem["Originator/Developer"]?.attributionId
        );
      });
    };

    const checkAdditionalInformation = () => {
      const targetPath = ENTRY_DATA_BASIC_PATH["Additional Information (Deal)"];
      if (!targetPath) return false;
      return (
        targetPath["Additional Information"]?.attributionId &&
        targetPath["Purpose of the Deal"]?.attributionId
      );
    };

    const checkDealTypeAndStatus = () => {
      const targetPath = ENTRY_DATA_BASIC_PATH["Deal Type & Status"];
      if (!targetPath) return false;

      return (
        targetPath[DEAL_TYPE_SHELL_KEYS.dealType]?.attributionId &&
        targetPath[DEAL_TYPE_SHELL_KEYS.dealStatus]?.attributionId &&
        targetPath[DEAL_TYPE_SHELL_KEYS.dealName]?.attributionId
      );
    };

    const checkLicenseeTable = () => {
      if (!ENTRY_DATA_BASIC_PATH[ENTRY_DATA_LICENSEE_TABLE]?.length)
        return false;

      return ENTRY_DATA_BASIC_PATH[ENTRY_DATA_LICENSEE_TABLE]?.every(
        (tableItem) => {
          if (
            !tableItem[
              ENTRY_DATA_LICENSEE_TABLE_KEYS
                .strategicCollaborationLicenseAgreementTerms
            ][
              ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                .milestonePaymentAndCondition
            ].length
          )
            return false;

          if (
            !tableItem[
              ENTRY_DATA_LICENSEE_TABLE_KEYS
                .strategicCollaborationLicenseAgreementTerms
            ][
              ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                .otherPaymentAndDetailScLa
            ].length
          )
            return false;

          if (
            !tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
              ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment
            ].length
          )
            return false;

          if (!tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope].length)
            return false;

          return (
            tableItem.attributionInfo?.attributionId &&
            tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName]
              ?.attributionId &&
            tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
              ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.numberOfAcquiringShares
            ]?.attributionId &&
            tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
              ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.otherFinancialTermsMa
            ]?.attributionId &&
            tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
              ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.pricePerShare
            ]?.attributionId &&
            tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
              ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                .licenseResponsibilityRightsMa
            ]?.attributionId &&
            tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
              ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.totalPaymentMa
            ]?.attributionId &&
            tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
              ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumMa
            ]?.attributionId &&
            tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
              ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                .disclosedPaymentsSumPricePerShareMa
            ]?.attributionId &&
            tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
              ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPaymentMa
            ]?.attributionId &&
            tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
              ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPricePerShareMa
            ]?.attributionId &&
            tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
              ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.mergerFinancialTermsMa
            ]?.attributionId &&
            tableItem[
              ENTRY_DATA_LICENSEE_TABLE_KEYS
                .strategicCollaborationLicenseAgreementTerms
            ][
              ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                .otherFinancialTermsScLa
            ]?.attributionId &&
            tableItem[
              ENTRY_DATA_LICENSEE_TABLE_KEYS
                .strategicCollaborationLicenseAgreementTerms
            ][
              ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                .licenseeResponsibilityAndRightsScLa
            ]?.attributionId &&
            tableItem[
              ENTRY_DATA_LICENSEE_TABLE_KEYS
                .strategicCollaborationLicenseAgreementTerms
            ].Royalty?.attributionId &&
            tableItem[
              ENTRY_DATA_LICENSEE_TABLE_KEYS
                .strategicCollaborationLicenseAgreementTerms
            ][
              ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                .disclosedPaymentsSumScLa
            ]?.attributionId &&
            tableItem[
              ENTRY_DATA_LICENSEE_TABLE_KEYS
                .strategicCollaborationLicenseAgreementTerms
            ][
              ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                .totalPaymentScLa
            ]?.attributionId &&
            tableItem[
              ENTRY_DATA_LICENSEE_TABLE_KEYS
                .strategicCollaborationLicenseAgreementTerms
            ][
              ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                .upfrontPayment
            ]?.attributionId &&
            tableItem[
              ENTRY_DATA_LICENSEE_TABLE_KEYS
                .strategicCollaborationLicenseAgreementTerms
            ][
              ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                .milestonePaymentAndCondition
            ].every((groupShellItem) => {
              return (
                groupShellItem[
                  ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS
                    .milestoneConditionTitle
                ]?.attributionId &&
                groupShellItem[
                  ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS.milestonePayment
                ]?.attributionId
              );
            }) &&
            tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope]?.every(
              (groupShellItem) => {
                return (
                  groupShellItem.Drug?.attributionId &&
                  groupShellItem.Exclusivity?.attributionId &&
                  groupShellItem.Indication?.attributionId &&
                  groupShellItem.Territory?.attributionId
                );
              },
            ) &&
            tableItem[
              ENTRY_DATA_LICENSEE_TABLE_KEYS
                .strategicCollaborationLicenseAgreementTerms
            ][
              ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                .otherPaymentAndDetailScLa
            ].every((groupShellItem) => {
              return (
                groupShellItem[
                  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentDetailScLa
                ]?.attributionId &&
                groupShellItem[
                  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentScLa
                ]?.attributionId
              );
            }) &&
            tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
              ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment
            ].every((groupShellItem) => {
              return (
                groupShellItem[
                  ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentMilestoneMa
                ]?.attributionId &&
                groupShellItem[
                  ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPaymentMa
                ]?.attributionId &&
                groupShellItem[
                  ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPricePerShareMa
                ]?.attributionId
              );
            })
          );
        },
      );
    };

    const checkLicensorTable = () => {
      const targetPath = ENTRY_DATA_BASIC_PATH["Licensor Table"];
      if (!targetPath) return false;
      return (
        targetPath.attributionInfo?.attributionId !== undefined &&
        targetPath["Licensor Name"]?.attributionId !== undefined &&
        targetPath["Licensor Responsibility & Rights"]?.attributionId !==
          undefined
      );
    };

    const checkKeyComment = () => {
      const targetPath = ENTRY_DATA_BASIC_PATH["Key Comment"];
      if (!targetPath) return false;
      return (
        targetPath.Quote?.attributionId !== undefined &&
        targetPath.Spokesperson?.attributionId !== undefined
      );
    };

    if (
      !checkDrugProfile() ||
      !checkKeyComment() ||
      !checkAdditionalInformation() ||
      !checkDealTypeAndStatus() ||
      !checkLicenseeTable() ||
      !checkLicensorTable()
    ) {
      return false;
    }

    return true;
  },
  [EXPEDITED_PROGRAM]: (data: ArticleEntryData): boolean => {
    const ENTRY_DATA_BASIC_PATH = {
      "Drug Profile": data[EXPEDITED_PROGRAM]?.["Drug Profile"],
      "Expedited Program Contents":
        data[EXPEDITED_PROGRAM]?.["Expedited Program Contents"],
      "Key Comment": data[EXPEDITED_PROGRAM]?.["Key Comment"],
    };

    const checkDrugProfile = () => {
      return ENTRY_DATA_BASIC_PATH["Drug Profile"]?.every((tableItem) => {
        return (
          tableItem.attributionInfo?.attributionId &&
          tableItem["Brand Name"]?.attributionId &&
          tableItem["INN Name"]?.attributionId &&
          tableItem["Code Name"]?.attributionId &&
          tableItem.MOA?.attributionId &&
          tableItem.Modality?.attributionId &&
          tableItem["Other Characteristics"]?.attributionId &&
          tableItem.ROA?.attributionId &&
          tableItem.Target?.attributionId &&
          tableItem["Target Indication"]?.attributionId &&
          tableItem.Phase?.attributionId &&
          tableItem["Therapeutic Area"]?.attributionId
        );
      });
    };

    const checkExpeditedProgramContents = () => {
      const targetPath = ENTRY_DATA_BASIC_PATH["Expedited Program Contents"];

      if (!targetPath) return;

      return (
        targetPath[k.updateDate].attributionId &&
        targetPath[k.regulatoryAgency].attributionId &&
        targetPath[k.expeditedProgram].attributionId &&
        targetPath[k.designatedIndication].attributionId &&
        targetPath[k.designatedCompanyName].attributionId &&
        targetPath[k.AdditionalInformation].attributionId
      );
    };

    const checkKeyComment = () => {
      const targetPath = ENTRY_DATA_BASIC_PATH["Key Comment"];
      if (!targetPath) return false;
      return (
        targetPath.Quote?.attributionId !== undefined &&
        targetPath.Spokesperson?.attributionId !== undefined
      );
    };

    if (
      !checkDrugProfile() ||
      !checkKeyComment() ||
      !checkExpeditedProgramContents()
    ) {
      return false;
    }

    return true;
  },
  [SECTION_KEYS.ventureCapital]: (data: ArticleEntryData): boolean => {
    const ventureCapital = data[SECTION_KEYS.ventureCapital];
    const BASIC_PATH = {
      [DRUG_PROFILE]: ventureCapital?.[DRUG_PROFILE],
      [KEY_COMMENTS]: ventureCapital?.[KEY_COMMENTS],
      [FUNDED_COMPANY]: ventureCapital?.[FUNDED_COMPANY],
      [ROUND_TYPE_AND_STATUS]: ventureCapital?.[ROUND_TYPE_AND_STATUS],
      [FINANCING_INFORMATION]: ventureCapital?.[FINANCING_INFORMATION],
      [ADDITIONAL_INFORMATION_VENTURE_CAPITAL]:
        ventureCapital?.[ADDITIONAL_INFORMATION_VENTURE_CAPITAL],
      [INVESTORS]: ventureCapital?.[INVESTORS],
    };

    const checkDrugProfile = () => {
      return BASIC_PATH[DRUG_PROFILE]?.every((tableItem) => {
        return (
          tableItem.attributionInfo?.attributionId &&
          tableItem[DRUG_PROFILE_SHELL_KEYS.brandName]?.attributionId &&
          tableItem[DRUG_PROFILE_SHELL_KEYS.innName]?.attributionId &&
          tableItem[DRUG_PROFILE_SHELL_KEYS.codeName]?.attributionId &&
          tableItem[DRUG_PROFILE_SHELL_KEYS.moa]?.attributionId &&
          tableItem[DRUG_PROFILE_SHELL_KEYS.modality]?.attributionId &&
          tableItem[DRUG_PROFILE_SHELL_KEYS.otherCharacteristics]
            ?.attributionId &&
          tableItem[DRUG_PROFILE_SHELL_KEYS.roa]?.attributionId &&
          tableItem[DRUG_PROFILE_SHELL_KEYS.target]?.attributionId &&
          tableItem[DRUG_PROFILE_SHELL_KEYS.targetIndication]?.attributionId &&
          tableItem[DRUG_PROFILE_SHELL_KEYS.phase]?.attributionId &&
          tableItem[DRUG_PROFILE_SHELL_KEYS.therapeuticArea]?.attributionId &&
          tableItem[DRUG_PROFILE_SHELL_KEYS.originatorDeveloper]
            ?.attributionId &&
          tableItem[DRUG_PROFILE_SHELL_KEYS.genericBiosimilar]?.attributionId &&
          tableItem[DRUG_PROFILE_SHELL_KEYS.refProductName]?.attributionId
        );
      });
    };

    const checkFundedCompany = () => {
      const targetPath = BASIC_PATH[FUNDED_COMPANY];
      if (!targetPath) return false;
      return (
        targetPath[FUNDED_COMPANY_SHELL_KEYS.companyName]?.attributionId !==
          undefined &&
        targetPath[FUNDED_COMPANY_SHELL_KEYS.companyDescription]
          ?.attributionId !== undefined
      );
    };

    const checkRoundTypeAndStatus = () => {
      const targetPath = BASIC_PATH[ROUND_TYPE_AND_STATUS];
      if (!targetPath) return false;
      return (
        targetPath[ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundType]
          ?.attributionId !== undefined &&
        targetPath[ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundName]
          ?.attributionId !== undefined
      );
    };

    const checkFinancingInformation = () => {
      const targetPath = BASIC_PATH[FINANCING_INFORMATION];
      if (!targetPath) return false;
      return (
        targetPath[FINANCING_INFORMATION_SHELL_KEYS.financingInformationDetail]
          ?.attributionId !== undefined &&
        targetPath[FINANCING_INFORMATION_SHELL_KEYS.amount]?.attributionId !==
          undefined &&
        targetPath[FINANCING_INFORMATION_SHELL_KEYS.financingPurpose]
          ?.attributionId !== undefined
      );
    };

    const checkInvestors = () => {
      const targetPath = BASIC_PATH[INVESTORS];
      if (!targetPath) return false;
      return (
        targetPath[INVESTORS_SHELL_KEYS.investorName]?.attributionId !==
        undefined
      );
    };

    const checkAdditionalInformation = () => {
      const targetPath = BASIC_PATH[ADDITIONAL_INFORMATION_VENTURE_CAPITAL];
      if (!targetPath) return false;
      return (
        targetPath[
          ADDITIONAL_INFORMATION_VENTURE_CAPITAL_SHELL_KEYS
            .additionalInformation
        ]?.attributionId !== undefined
      );
    };

    const checkKeyComment = () => {
      const targetPath = BASIC_PATH[KEY_COMMENTS];
      if (!targetPath) return false;
      return (
        targetPath[KEY_COMMENTS_SHELL_KEYS.quote]?.attributionId !==
          undefined &&
        targetPath[KEY_COMMENTS_SHELL_KEYS.spokesperson]?.attributionId !==
          undefined
      );
    };

    if (
      !checkDrugProfile() ||
      !checkFundedCompany() ||
      !checkRoundTypeAndStatus() ||
      !checkFinancingInformation() ||
      !checkInvestors() ||
      !checkAdditionalInformation() ||
      !checkKeyComment()
    ) {
      return false;
    }
    return true;
  },
};

export const CHECK_ENTRY_DATA_FORMAT: {
  [key in ArticleSectionType]: (data: ArticleEntryData) => ArticleEntryData;
} = {
  "Clinical Trials": (data: ArticleEntryData): ArticleEntryData => {
    const clinicalTrials = data["Clinical Trials"] as ClinicalTrials;
    const ENTRY_DATA_BASIC_PATH = {
      "Drug Profile": clinicalTrials["Drug Profile"],
      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]:
        clinicalTrials[ENTRY_DATA_CLINICAL_TRIAL_DESIGN],
      "Clinical Results": clinicalTrials["Clinical Results"],
      "Clinical Endpoints (Table)":
        clinicalTrials["Clinical Endpoints (Table)"],
      "Clinical Endpoints (Description)":
        clinicalTrials["Clinical Endpoints (Description)"],
      "Key Comment": clinicalTrials["Key Comment"],
    };

    return {
      ...data,
      "Clinical Trials": {
        ...clinicalTrials,
        "Drug Profile": ENTRY_DATA_BASIC_PATH["Drug Profile"]?.map(
          (tableItem) => {
            return {
              ...tableItem,
              attributionInfo: {
                attributionId:
                  tableItem.attributionInfo?.attributionId ??
                  createAttributionId(),
              },
              "Brand Name": {
                ...tableItem["Brand Name"],
                attributionId:
                  tableItem["Brand Name"]?.attributionId ??
                  createAttributionId(),
              },
              "INN Name": {
                ...tableItem["INN Name"],
                attributionId:
                  tableItem["INN Name"]?.attributionId ?? createAttributionId(),
              },
              "Code Name": {
                ...tableItem["Code Name"],
                attributionId:
                  tableItem["Code Name"]?.attributionId ??
                  createAttributionId(),
              },
              MOA: {
                ...tableItem.MOA,
                attributionId:
                  tableItem.MOA?.attributionId ?? createAttributionId(),
              },
              Modality: {
                ...tableItem.Modality,
                attributionId:
                  tableItem.Modality?.attributionId ?? createAttributionId(),
              },
              "Other Characteristics": {
                ...tableItem["Other Characteristics"],
                attributionId:
                  tableItem["Other Characteristics"]?.attributionId ??
                  createAttributionId(),
              },
              ROA: {
                ...tableItem.ROA,
                attributionId:
                  tableItem.ROA?.attributionId ?? createAttributionId(),
              },
              Target: {
                ...tableItem.Target,
                attributionId:
                  tableItem.Target?.attributionId ?? createAttributionId(),
              },
              "Target Indication": {
                ...tableItem["Target Indication"],
                attributionId:
                  tableItem["Target Indication"]?.attributionId ??
                  createAttributionId(),
              },
              "Therapeutic Area": {
                ...tableItem["Therapeutic Area"],
                attributionId:
                  tableItem["Therapeutic Area"]?.attributionId ??
                  createAttributionId(),
              },
              "Originator/Developer": {
                ...tableItem["Originator/Developer"],
                attributionId:
                  tableItem["Originator/Developer"]?.attributionId ??
                  createAttributionId(),
              },
              Phase: {
                ...tableItem.Phase,
                attributionId:
                  tableItem.Phase?.attributionId ?? createAttributionId(),
              },
            };
          },
        ),
        [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: ENTRY_DATA_BASIC_PATH[
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN
        ]?.map((tableItem) => {
          return {
            ...tableItem,
            [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]: {
              attributionInfo: {
                attributionId:
                  tableItem[
                    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                  ].attributionInfo?.attributionId ?? createAttributionId(),
              },
              "Inclusion Criteria - Detail": {
                ...tableItem[
                  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                ]["Inclusion Criteria - Detail"],
                attributionId:
                  tableItem[
                    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                  ]["Inclusion Criteria - Detail"]?.attributionId ??
                  createAttributionId(),
              },
              "Inclusion Criteria - Diagnostic": {
                ...tableItem[
                  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                ]["Inclusion Criteria - Diagnostic"],
                attributionId:
                  tableItem[
                    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                  ]["Inclusion Criteria - Diagnostic"]?.attributionId ??
                  createAttributionId(),
              },
              "NCT Name": {
                ...tableItem[
                  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                ]["NCT Name"],
                attributionId:
                  tableItem[
                    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                  ]["NCT Name"]?.attributionId ?? createAttributionId(),
              },
              "Clinical Design Phase": {
                ...tableItem[
                  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                ]["Clinical Design Phase"],
                attributionId:
                  tableItem[
                    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                  ]["Clinical Design Phase"]?.attributionId ??
                  createAttributionId(),
              },

              "Total Patients Number": {
                ...tableItem[
                  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                ]["Total Patients Number"],
                attributionId:
                  tableItem[
                    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                  ]["Total Patients Number"]?.attributionId ??
                  createAttributionId(),
              },
              "Trial Name": {
                ...tableItem[
                  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                ]["Trial Name"],
                attributionId:
                  tableItem[
                    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                  ]["Trial Name"]?.attributionId ?? createAttributionId(),
              },
              "Trial Intervention":
                [
                  ...tableItem[
                    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                  ]["Trial Intervention"],
                ] ?? [],
            },
            [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2]:
              tableItem["Clinical Trial Design-2"]?.map((groupShellItem) => {
                return {
                  ...groupShellItem,
                  "Arm Name": {
                    ...groupShellItem["Arm Name"],
                    attributionId:
                      groupShellItem["Arm Name"]?.attributionId ??
                      createAttributionId(),
                  },
                  "Patients Number": {
                    ...groupShellItem["Patients Number"],
                    attributionId:
                      groupShellItem["Patients Number"]?.attributionId ??
                      createAttributionId(),
                  },
                  "Design Group Information": groupShellItem[
                    "Design Group Information"
                  ].map((shellItem) => {
                    return {
                      ...shellItem,
                      "Drug Name": {
                        ...shellItem["Drug Name"],
                        attributionId:
                          shellItem["Drug Name"]?.attributionId ??
                          createAttributionId(),
                      },
                      Dose: {
                        ...shellItem.Dose,
                        attributionId:
                          shellItem.Dose?.attributionId ??
                          createAttributionId(),
                      },
                      Duration: {
                        ...shellItem.Duration,
                        attributionId:
                          shellItem.Duration?.attributionId ??
                          createAttributionId(),
                      },
                    };
                  }),
                };
              }),
          };
        }),
        "Clinical Results": {
          ...ENTRY_DATA_BASIC_PATH["Clinical Results"],
          "Results Classification": {
            ...ENTRY_DATA_BASIC_PATH["Clinical Results"]?.[
              "Results Classification"
            ],
            attributionId:
              ENTRY_DATA_BASIC_PATH["Clinical Results"]?.[
                "Results Classification"
              ]?.attributionId ?? createAttributionId(),
          },
        },
        "Clinical Endpoints (Table)": ENTRY_DATA_BASIC_PATH[
          "Clinical Endpoints (Table)"
        ]?.map((tableItem) => {
          return {
            ...tableItem,
            "Endpoints Table Title": {
              ...tableItem["Endpoints Table Title"],
              attributionId:
                tableItem["Endpoints Table Title"]?.attributionId ??
                createAttributionId(),
            },
            "Endpoints Table Notes": {
              ...tableItem["Endpoints Table Notes"],
              attributionId:
                tableItem["Endpoints Table Notes"]?.attributionId ??
                createAttributionId(),
            },
            attributionInfo: {
              attributionId:
                tableItem.attributionInfo?.attributionId ??
                createAttributionId(),
            },
            "Endpoints Table Information": tableItem[
              "Endpoints Table Information"
            ].map((groupShellItem) => {
              return {
                ...groupShellItem,
                "Endpoint Classification": {
                  ...groupShellItem["Endpoint Classification"],
                  attributionId:
                    groupShellItem["Endpoint Classification"]?.attributionId ??
                    createAttributionId(),
                },
                "Endpoint Name including Measurement Timing": {
                  ...groupShellItem[
                    "Endpoint Name including Measurement Timing"
                  ],
                  attributionId:
                    groupShellItem["Endpoint Name including Measurement Timing"]
                      ?.attributionId ?? createAttributionId(),
                },
                "Endpoint Group Information":
                  checkClinicalEndpointGroupInformationSameShellLength(data)
                    ? groupShellItem["Endpoint Group Information"].map(
                        (shellItem) => {
                          return {
                            ...shellItem,
                            "Group Name": {
                              ...shellItem["Group Name"],
                              attributionId:
                                shellItem["Group Name"]?.attributionId ??
                                createAttributionId(),
                            },
                            "Group Data": {
                              ...shellItem["Group Data"],
                              attributionId:
                                shellItem["Group Data"]?.attributionId ??
                                createAttributionId(),
                            },
                            "P-Value": {
                              ...shellItem["P-Value"],
                              attributionId:
                                shellItem["P-Value"]?.attributionId ??
                                createAttributionId(),
                            },
                          };
                        },
                      )
                    : newArrayToCountValue(
                        returnCurrentEndpointTableGroupInformationMaxLength(
                          tableItem,
                        ),
                      ).map((_, infoIndex) => {
                        return {
                          "Group Name": {
                            value:
                              groupShellItem["Endpoint Group Information"]?.[
                                infoIndex
                              ]?.["Group Name"]?.value.length > 0
                                ? groupShellItem[
                                    "Endpoint Group Information"
                                  ]?.[infoIndex]?.["Group Name"]?.value
                                : returnCurrentColumnGroupName(
                                    tableItem,
                                    infoIndex,
                                  ),
                            options:
                              groupShellItem["Endpoint Group Information"]?.[
                                infoIndex
                              ]?.["Group Name"].options || [],
                            attributionId: createAttributionId(),
                          },
                          "Group Data": {
                            value: groupShellItem[
                              "Endpoint Group Information"
                            ]?.[infoIndex]?.["Group Data"].value || [
                              {
                                text: "N/A",
                                is_modified: false,
                              },
                            ],
                            options:
                              groupShellItem["Endpoint Group Information"]?.[
                                infoIndex
                              ]?.["Group Data"].options || [],
                            attributionId: createAttributionId(),
                          },
                          "P-Value": {
                            value:
                              groupShellItem["Endpoint Group Information"]?.[
                                infoIndex
                              ]?.["P-Value"].value || [],
                            options:
                              groupShellItem["Endpoint Group Information"]?.[
                                infoIndex
                              ]?.["P-Value"].options || [],
                            attributionId: createAttributionId(),
                          },
                        };
                      }),
              };
            }),
          };
        }),
        "Clinical Endpoints (Description)": {
          ...ENTRY_DATA_BASIC_PATH["Clinical Endpoints (Description)"],
          "Endpoint Description": {
            ...ENTRY_DATA_BASIC_PATH["Clinical Endpoints (Description)"]?.[
              "Endpoint Description"
            ],
            attributionId:
              ENTRY_DATA_BASIC_PATH["Clinical Endpoints (Description)"]?.[
                "Endpoint Description"
              ]?.attributionId ?? createAttributionId(),
          },
        },
        "Key Comment": {
          ...ENTRY_DATA_BASIC_PATH["Key Comment"],
          Quote: {
            ...ENTRY_DATA_BASIC_PATH["Key Comment"]?.Quote,
            attributionId:
              ENTRY_DATA_BASIC_PATH["Key Comment"]?.Quote?.attributionId ??
              createAttributionId(),
          },
          Spokesperson: {
            ...ENTRY_DATA_BASIC_PATH["Key Comment"]?.Spokesperson,
            attributionId:
              ENTRY_DATA_BASIC_PATH["Key Comment"]?.Spokesperson
                ?.attributionId ?? createAttributionId(),
          },
        },
      },
    };
  },
  "Clinical Trial Status": (data: ArticleEntryData): ArticleEntryData => {
    const clinicalTrialStatus = data[
      "Clinical Trial Status"
    ] as ClinicalTrialStatus;
    const ENTRY_DATA_BASIC_PATH = {
      "Drug Profile": clinicalTrialStatus["Drug Profile"],
      "Clinical Trial Design-1": clinicalTrialStatus["Clinical Trial Design-1"],
    };

    return {
      ...data,
      "Clinical Trial Status": {
        ...clinicalTrialStatus,
        "Drug Profile": ENTRY_DATA_BASIC_PATH["Drug Profile"]?.map(
          (tableItem) => {
            return {
              ...tableItem,
              attributionInfo: {
                attributionId:
                  tableItem.attributionInfo?.attributionId ??
                  createAttributionId(),
              },
              "Brand Name": {
                ...tableItem["Brand Name"],
                attributionId:
                  tableItem["Brand Name"]?.attributionId ??
                  createAttributionId(),
              },
              "INN Name": {
                ...tableItem["INN Name"],
                attributionId:
                  tableItem["INN Name"]?.attributionId ?? createAttributionId(),
              },
              "Code Name": {
                ...tableItem["Code Name"],
                attributionId:
                  tableItem["Code Name"]?.attributionId ??
                  createAttributionId(),
              },
              MOA: {
                ...tableItem.MOA,
                attributionId:
                  tableItem.MOA?.attributionId ?? createAttributionId(),
              },
              Modality: {
                ...tableItem.Modality,
                attributionId:
                  tableItem.Modality?.attributionId ?? createAttributionId(),
              },
              "Other Characteristics": {
                ...tableItem["Other Characteristics"],
                attributionId:
                  tableItem["Other Characteristics"]?.attributionId ??
                  createAttributionId(),
              },
              ROA: {
                ...tableItem.ROA,
                attributionId:
                  tableItem.ROA?.attributionId ?? createAttributionId(),
              },
              Target: {
                ...tableItem.Target,
                attributionId:
                  tableItem.Target?.attributionId ?? createAttributionId(),
              },
              "Target Indication": {
                ...tableItem["Target Indication"],
                attributionId:
                  tableItem["Target Indication"]?.attributionId ??
                  createAttributionId(),
              },
              "Therapeutic Area": {
                ...tableItem["Therapeutic Area"],
                attributionId:
                  tableItem["Therapeutic Area"]?.attributionId ??
                  createAttributionId(),
              },
              "Originator/Developer": {
                ...tableItem["Originator/Developer"],
                attributionId:
                  tableItem["Originator/Developer"]?.attributionId ??
                  createAttributionId(),
              },
              Phase: {
                ...tableItem.Phase,
                attributionId:
                  tableItem.Phase?.attributionId ?? createAttributionId(),
              },
            };
          },
        ),
        "Clinical Trial Design-1": ENTRY_DATA_BASIC_PATH[
          "Clinical Trial Design-1"
        ]?.map((tableItem) => {
          return {
            "Inclusion Criteria - Detail": {
              ...tableItem["Inclusion Criteria - Detail"],
              attributionId:
                tableItem["Inclusion Criteria - Detail"]?.attributionId ??
                createAttributionId(),
            },
            "Inclusion Criteria - Diagnostic": {
              ...tableItem["Inclusion Criteria - Diagnostic"],
              attributionId:
                tableItem["Inclusion Criteria - Diagnostic"]?.attributionId ??
                createAttributionId(),
            },
            "NCT Name": {
              ...tableItem["NCT Name"],
              attributionId:
                tableItem["NCT Name"]?.attributionId ?? createAttributionId(),
            },
            "Clinical Design Phase": {
              ...tableItem["Clinical Design Phase"],
              attributionId:
                tableItem["Clinical Design Phase"]?.attributionId ??
                createAttributionId(),
            },

            "Total Patients Number": {
              ...tableItem["Total Patients Number"],
              attributionId:
                tableItem["Total Patients Number"]?.attributionId ??
                createAttributionId(),
            },
            "Trial Name": {
              ...tableItem["Trial Name"],
              attributionId:
                tableItem["Trial Name"]?.attributionId ?? createAttributionId(),
            },
            "Trial Intervention": [...tableItem["Trial Intervention"]] ?? [],
          };
        }),
      },
    };
  },
  Regulatory: (data: ArticleEntryData): ArticleEntryData => {
    const regulatory = data.Regulatory as Regulatory;

    const ENTRY_DATA_BASIC_PATH = {
      "Drug Profile": regulatory["Drug Profile"],
      "Approval Process Contents": regulatory["Approval Process Contents"],
      "Key Comment": regulatory["Key Comment"],
    };

    return {
      ...data,
      Regulatory: {
        ...regulatory,
        "Drug Profile": ENTRY_DATA_BASIC_PATH["Drug Profile"]?.map(
          (tableItem) => {
            return {
              ...tableItem,
              attributionInfo: {
                attributionId:
                  tableItem.attributionInfo?.attributionId ??
                  createAttributionId(),
              },
              "Brand Name": {
                ...tableItem["Brand Name"],
                attributionId:
                  tableItem["Brand Name"]?.attributionId ??
                  createAttributionId(),
              },
              "INN Name": {
                ...tableItem["INN Name"],
                attributionId:
                  tableItem["INN Name"]?.attributionId ?? createAttributionId(),
              },
              "Code Name": {
                ...tableItem["Code Name"],
                attributionId:
                  tableItem["Code Name"]?.attributionId ??
                  createAttributionId(),
              },
              MOA: {
                ...tableItem.MOA,
                attributionId:
                  tableItem.MOA?.attributionId ?? createAttributionId(),
              },
              Modality: {
                ...tableItem.Modality,
                attributionId:
                  tableItem.Modality?.attributionId ?? createAttributionId(),
              },
              "Other Characteristics": {
                ...tableItem["Other Characteristics"],
                attributionId:
                  tableItem["Other Characteristics"]?.attributionId ??
                  createAttributionId(),
              },
              ROA: {
                ...tableItem.ROA,
                attributionId:
                  tableItem.ROA?.attributionId ?? createAttributionId(),
              },
              Target: {
                ...tableItem.Target,
                attributionId:
                  tableItem.Target?.attributionId ?? createAttributionId(),
              },
              "Target Indication": {
                ...tableItem["Target Indication"],
                attributionId:
                  tableItem["Target Indication"]?.attributionId ??
                  createAttributionId(),
              },
              "Therapeutic Area": {
                ...tableItem["Therapeutic Area"],
                attributionId:
                  tableItem["Therapeutic Area"]?.attributionId ??
                  createAttributionId(),
              },
            };
          },
        ),
        "Approval Process Contents": {
          ...ENTRY_DATA_BASIC_PATH["Approval Process Contents"],
          "Approval Process": ENTRY_DATA_BASIC_PATH[
            "Approval Process Contents"
          ]?.["Approval Process"]?.map((groupShellItem) => {
            return {
              ...groupShellItem,
              "Application Type": {
                ...groupShellItem["Application Type"],
                attributionId:
                  groupShellItem["Application Type"]?.attributionId ??
                  createAttributionId(),
              },
              "Authorized Company": {
                ...groupShellItem["Authorized Company"],
                attributionId:
                  groupShellItem["Authorized Company"]?.attributionId ??
                  createAttributionId(),
              },
              "Dosage and Administration on Label": {
                ...groupShellItem["Dosage and Administration on Label"],
                attributionId:
                  groupShellItem["Dosage and Administration on Label"]
                    ?.attributionId ?? createAttributionId(),
              },
              "Indication on Label": {
                ...groupShellItem["Indication on Label"],
                attributionId:
                  groupShellItem["Indication on Label"]?.attributionId ??
                  createAttributionId(),
              },
              "Marketing Status": {
                ...groupShellItem["Marketing Status"],
                attributionId:
                  groupShellItem["Marketing Status"]?.attributionId ??
                  createAttributionId(),
              },
              "Regulatory Agency": {
                ...groupShellItem["Regulatory Agency"],
                attributionId:
                  groupShellItem["Regulatory Agency"]?.attributionId ??
                  createAttributionId(),
              },
              "Update Date": {
                ...groupShellItem["Update Date"],
                attributionId:
                  groupShellItem["Update Date"]?.attributionId ??
                  createAttributionId(),
              },
            };
          }),

          "Additional Information on the Approval Process": {
            ...ENTRY_DATA_BASIC_PATH["Approval Process Contents"][
              "Additional Information on the Approval Process"
            ],
            attributionId:
              ENTRY_DATA_BASIC_PATH["Approval Process Contents"]?.[
                "Additional Information on the Approval Process"
              ]?.attributionId ?? createAttributionId(),
          },
        },
        "Key Comment": {
          ...ENTRY_DATA_BASIC_PATH["Key Comment"],
          Quote: {
            ...ENTRY_DATA_BASIC_PATH["Key Comment"]?.Quote,
            attributionId:
              ENTRY_DATA_BASIC_PATH["Key Comment"]?.Quote?.attributionId ??
              createAttributionId(),
          },
          Spokesperson: {
            ...ENTRY_DATA_BASIC_PATH["Key Comment"]?.Spokesperson,
            attributionId:
              ENTRY_DATA_BASIC_PATH["Key Comment"]?.Spokesperson
                ?.attributionId ?? createAttributionId(),
          },
        },
      },
    } as ArticleEntryData;
  },
  Deals: (data: ArticleEntryData): ArticleEntryData => {
    const deals = data.Deals as Deals;
    const ENTRY_DATA_BASIC_PATH = {
      "Drug Profile": deals["Drug Profile"],
      "Additional Information (Deal)": deals["Additional Information (Deal)"],
      "Deal Type & Status": deals["Deal Type & Status"],
      [ENTRY_DATA_LICENSEE_TABLE]: deals[ENTRY_DATA_LICENSEE_TABLE],
      "Licensor Table": deals["Licensor Table"],
      "Key Comment": deals["Key Comment"],
    };

    return {
      ...data,
      Deals: {
        ...deals,
        "Drug Profile": ENTRY_DATA_BASIC_PATH["Drug Profile"]?.map(
          (tableItem) => {
            return {
              ...tableItem,
              attributionInfo: {
                attributionId:
                  tableItem.attributionInfo?.attributionId ??
                  createAttributionId(),
              },
              "Brand Name": {
                ...tableItem["Brand Name"],
                attributionId:
                  tableItem["Brand Name"]?.attributionId ??
                  createAttributionId(),
              },
              "INN Name": {
                ...tableItem["INN Name"],
                attributionId:
                  tableItem["INN Name"]?.attributionId ?? createAttributionId(),
              },
              "Code Name": {
                ...tableItem["Code Name"],
                attributionId:
                  tableItem["Code Name"]?.attributionId ??
                  createAttributionId(),
              },
              MOA: {
                ...tableItem.MOA,
                attributionId:
                  tableItem.MOA?.attributionId ?? createAttributionId(),
              },
              Modality: {
                ...tableItem.Modality,
                attributionId:
                  tableItem.Modality?.attributionId ?? createAttributionId(),
              },
              "Other Characteristics": {
                ...tableItem["Other Characteristics"],
                attributionId:
                  tableItem["Other Characteristics"]?.attributionId ??
                  createAttributionId(),
              },
              ROA: {
                ...tableItem.ROA,
                attributionId:
                  tableItem.ROA?.attributionId ?? createAttributionId(),
              },
              Target: {
                ...tableItem.Target,
                attributionId:
                  tableItem.Target?.attributionId ?? createAttributionId(),
              },
              "Target Indication": {
                ...tableItem["Target Indication"],
                attributionId:
                  tableItem["Target Indication"]?.attributionId ??
                  createAttributionId(),
              },
              "Therapeutic Area": {
                ...tableItem["Therapeutic Area"],
                attributionId:
                  tableItem["Therapeutic Area"]?.attributionId ??
                  createAttributionId(),
              },
              Phase: {
                ...tableItem.Phase,
                attributionId:
                  tableItem.Phase?.attributionId ?? createAttributionId(),
              },
              "Originator/Developer": {
                ...tableItem["Originator/Developer"],
                attributionId:
                  tableItem["Originator/Developer"]?.attributionId ??
                  createAttributionId(),
              },
            };
          },
        ),
        "Additional Information (Deal)": {
          ...ENTRY_DATA_BASIC_PATH["Additional Information (Deal)"],
          "Additional Information": {
            ...ENTRY_DATA_BASIC_PATH["Additional Information (Deal)"][
              "Additional Information"
            ],
            attributionId:
              ENTRY_DATA_BASIC_PATH["Additional Information (Deal)"]?.[
                "Additional Information"
              ]?.attributionId ?? createAttributionId(),
          },
          "Purpose of the Deal": {
            ...ENTRY_DATA_BASIC_PATH["Additional Information (Deal)"][
              "Purpose of the Deal"
            ],
            attributionId:
              ENTRY_DATA_BASIC_PATH["Additional Information (Deal)"]?.[
                "Purpose of the Deal"
              ]?.attributionId ?? createAttributionId(),
          },
        },
        "Deal Type & Status": {
          ...ENTRY_DATA_BASIC_PATH["Deal Type & Status"],
          "Deal Status": {
            ...ENTRY_DATA_BASIC_PATH["Deal Type & Status"]?.["Deal Status"],
            attributionId:
              ENTRY_DATA_BASIC_PATH["Deal Type & Status"]?.["Deal Status"]
                ?.attributionId ?? createAttributionId(),
          },
          "Deal Type": {
            ...ENTRY_DATA_BASIC_PATH["Deal Type & Status"]?.["Deal Type"],
            attributionId:
              ENTRY_DATA_BASIC_PATH["Deal Type & Status"]?.["Deal Type"]
                ?.attributionId ?? createAttributionId(),
          },
          [DEAL_TYPE_SHELL_KEYS.dealName]: {
            ...ENTRY_DATA_BASIC_PATH[ENTRY_DATA_DEAL_TYPE_AND_STATUS]?.[
              DEAL_TYPE_SHELL_KEYS.dealName
            ],
            attributionId:
              ENTRY_DATA_BASIC_PATH[ENTRY_DATA_DEAL_TYPE_AND_STATUS]?.[
                DEAL_TYPE_SHELL_KEYS.dealName
              ].attributionId ?? createAttributionId(),
          },
        },
        [ENTRY_DATA_LICENSEE_TABLE]: ENTRY_DATA_BASIC_PATH[
          ENTRY_DATA_LICENSEE_TABLE
        ]?.map((tableItem) => {
          return {
            ...tableItem,
            attributionInfo: {
              attributionId:
                tableItem.attributionInfo?.attributionId ??
                createAttributionId(),
            },
            [ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName]: {
              ...tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName],
              attributionId:
                tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName]
                  ?.attributionId ?? createAttributionId(),
            },
            [ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms]: {
              ...tableItem[
                ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
              ],
              [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.numberOfAcquiringShares]:
                {
                  ...tableItem[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ][
                    ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                      .numberOfAcquiringShares
                  ],
                  attributionId:
                    tableItem[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                    ][
                      ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                        .numberOfAcquiringShares
                    ]?.attributionId ?? createAttributionId(),
                },
              [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.otherFinancialTermsMa]: {
                ...tableItem[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                ][
                  ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.otherFinancialTermsMa
                ],
                attributionId:
                  tableItem[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ][
                    ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                      .otherFinancialTermsMa
                  ]?.attributionId ?? createAttributionId(),
              },
              [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.pricePerShare]: {
                ...tableItem[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                ][ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.pricePerShare],
                attributionId:
                  tableItem[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ][ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.pricePerShare]
                    ?.attributionId ?? createAttributionId(),
              },
              [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.licenseResponsibilityRightsMa]:
                {
                  ...tableItem[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ][
                    ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                      .licenseResponsibilityRightsMa
                  ],
                  attributionId:
                    tableItem[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                    ][
                      ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                        .licenseResponsibilityRightsMa
                    ]?.attributionId ?? createAttributionId(),
                },
              [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPaymentMa]: {
                ...tableItem[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                ][ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPaymentMa],
                attributionId:
                  tableItem[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ][ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPaymentMa]
                    ?.attributionId ?? createAttributionId(),
              },
              [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPricePerShareMa]:
                {
                  ...tableItem[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ][
                    ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                      .upfrontPricePerShareMa
                  ],
                  attributionId:
                    tableItem[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                    ][
                      ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                        .upfrontPricePerShareMa
                    ]?.attributionId ?? createAttributionId(),
                },
              [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.mergerFinancialTermsMa]:
                {
                  ...tableItem[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ][
                    ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                      .mergerFinancialTermsMa
                  ],
                  attributionId:
                    tableItem[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                    ][
                      ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                        .mergerFinancialTermsMa
                    ]?.attributionId ?? createAttributionId(),
                },
              [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.totalPaymentMa]: {
                ...tableItem[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                ][ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.totalPaymentMa],
                attributionId:
                  tableItem[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ][ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.totalPaymentMa]
                    ?.attributionId ?? createAttributionId(),
              },
              [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumMa]:
                {
                  ...tableItem[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ][
                    ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                      .disclosedPaymentsSumMa
                  ],
                  attributionId:
                    tableItem[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                    ][
                      ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                        .disclosedPaymentsSumMa
                    ]?.attributionId ?? createAttributionId(),
                },
              [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumPricePerShareMa]:
                {
                  ...tableItem[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ][
                    ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                      .disclosedPaymentsSumPricePerShareMa
                  ],
                  attributionId:
                    tableItem[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                    ][
                      ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                        .disclosedPaymentsSumPricePerShareMa
                    ]?.attributionId ?? createAttributionId(),
                },
              [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment]:
                tableItem[ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms][
                  ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment
                ].map((subGroupShellItem) => {
                  return {
                    ...subGroupShellItem,
                    [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentMilestoneMa]:
                      {
                        ...subGroupShellItem[
                          ENTRY_DATA_CONTINGENT_PAYMENT_KEYS
                            .contingentMilestoneMa
                        ],
                        attributionId:
                          subGroupShellItem[
                            ENTRY_DATA_CONTINGENT_PAYMENT_KEYS
                              .contingentMilestoneMa
                          ]?.attributionId ?? createAttributionId(),
                      },
                    [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPaymentMa]: {
                      ...subGroupShellItem[
                        ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPaymentMa
                      ],
                      attributionId:
                        subGroupShellItem[
                          ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPaymentMa
                        ]?.attributionId ?? createAttributionId(),
                    },
                    [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPricePerShareMa]:
                      {
                        ...subGroupShellItem[
                          ENTRY_DATA_CONTINGENT_PAYMENT_KEYS
                            .contingentPricePerShareMa
                        ],
                        attributionId:
                          subGroupShellItem[
                            ENTRY_DATA_CONTINGENT_PAYMENT_KEYS
                              .contingentPricePerShareMa
                          ]?.attributionId ?? createAttributionId(),
                      },
                  };
                }),
            },
            [ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope]: tableItem[
              ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope
            ].map((groupShellItem) => {
              return {
                ...groupShellItem,
                Drug: {
                  ...groupShellItem.Drug,
                  attributionId:
                    groupShellItem.Drug?.attributionId ?? createAttributionId(),
                },
                Exclusivity: {
                  ...groupShellItem.Exclusivity,
                  attributionId:
                    groupShellItem.Exclusivity?.attributionId ??
                    createAttributionId(),
                },
                Indication: {
                  ...groupShellItem.Indication,
                  attributionId:
                    groupShellItem.Indication?.attributionId ??
                    createAttributionId(),
                },
                Territory: {
                  ...groupShellItem.Territory,
                  attributionId:
                    groupShellItem.Territory?.attributionId ??
                    createAttributionId(),
                },
              };
            }),
            [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
              {
                ...tableItem[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS
                    .strategicCollaborationLicenseAgreementTerms
                ],
                [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.milestonePaymentAndCondition]:
                  tableItem[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS
                      .strategicCollaborationLicenseAgreementTerms
                  ][
                    ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                      .milestonePaymentAndCondition
                  ]?.map((groupShellItem) => {
                    return {
                      ...groupShellItem,
                      [ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS.milestoneConditionTitle]:
                        {
                          ...groupShellItem[
                            ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS
                              .milestoneConditionTitle
                          ],
                          attributionId:
                            groupShellItem[
                              ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS
                                .milestoneConditionTitle
                            ]?.attributionId ?? createAttributionId(),
                        },
                      [ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS.milestonePayment]:
                        {
                          ...groupShellItem[
                            ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS
                              .milestonePayment
                          ],
                          attributionId:
                            groupShellItem[
                              ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS
                                .milestonePayment
                            ]?.attributionId ?? createAttributionId(),
                        },
                    };
                  }),
                [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherFinancialTermsScLa]:
                  {
                    ...tableItem[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ][
                      ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                        .otherFinancialTermsScLa
                    ],
                    attributionId:
                      tableItem[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ][
                        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                          .otherFinancialTermsScLa
                      ]?.attributionId ?? createAttributionId(),
                  },
                [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.licenseeResponsibilityAndRightsScLa]:
                  {
                    ...tableItem[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ][
                      ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                        .licenseeResponsibilityAndRightsScLa
                    ],
                    attributionId:
                      tableItem[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ][
                        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                          .licenseeResponsibilityAndRightsScLa
                      ]?.attributionId ?? createAttributionId(),
                  },
                [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.royalty]:
                  {
                    ...tableItem[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ][
                      ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                        .royalty
                    ],
                    attributionId:
                      tableItem[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ][
                        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                          .royalty
                      ]?.attributionId ?? createAttributionId(),
                  },
                [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.totalPaymentScLa]:
                  {
                    ...tableItem[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ][
                      ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                        .totalPaymentScLa
                    ],
                    attributionId:
                      tableItem[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ][
                        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                          .totalPaymentScLa
                      ]?.attributionId ?? createAttributionId(),
                  },
                [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.disclosedPaymentsSumScLa]:
                  {
                    ...tableItem[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ][
                      ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                        .disclosedPaymentsSumScLa
                    ],
                    attributionId:
                      tableItem[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ][
                        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                          .disclosedPaymentsSumScLa
                      ]?.attributionId ?? createAttributionId(),
                  },
                [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.upfrontPayment]:
                  {
                    ...tableItem[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ][
                      ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                        .upfrontPayment
                    ],
                    attributionId:
                      tableItem[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ][
                        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                          .upfrontPayment
                      ]?.attributionId ?? createAttributionId(),
                  },
                [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa]:
                  tableItem[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS
                      .strategicCollaborationLicenseAgreementTerms
                  ][
                    ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                      .otherPaymentAndDetailScLa
                  ]?.map((groupShellItem) => {
                    return {
                      ...groupShellItem,
                      [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentDetailScLa]:
                        {
                          ...groupShellItem[
                            ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                              .otherPaymentDetailScLa
                          ],
                          attributionId:
                            groupShellItem[
                              ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                                .otherPaymentDetailScLa
                            ]?.attributionId ?? createAttributionId(),
                        },
                      [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentScLa]: {
                        ...groupShellItem[
                          ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentScLa
                        ],
                        attributionId:
                          groupShellItem[
                            ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                              .otherPaymentScLa
                          ]?.attributionId ?? createAttributionId(),
                      },
                    };
                  }),
              },
          };
        }),
        "Licensor Table": {
          ...ENTRY_DATA_BASIC_PATH["Licensor Table"],
          attributionInfo: {
            attributionId:
              ENTRY_DATA_BASIC_PATH["Licensor Table"]?.attributionInfo
                ?.attributionId ?? createAttributionId(),
          },
          "Licensor Name": {
            ...ENTRY_DATA_BASIC_PATH["Licensor Table"]?.["Licensor Name"],
            attributionId:
              ENTRY_DATA_BASIC_PATH["Licensor Table"]?.["Licensor Name"]
                ?.attributionId ?? createAttributionId(),
          },
          "Licensor Responsibility & Rights": {
            ...ENTRY_DATA_BASIC_PATH["Licensor Table"]?.[
              "Licensor Responsibility & Rights"
            ],
            attributionId:
              ENTRY_DATA_BASIC_PATH["Licensor Table"]?.[
                "Licensor Responsibility & Rights"
              ]?.attributionId ?? createAttributionId(),
          },
        },

        "Key Comment": {
          ...ENTRY_DATA_BASIC_PATH["Key Comment"],
          Quote: {
            ...ENTRY_DATA_BASIC_PATH["Key Comment"]?.Quote,
            attributionId:
              ENTRY_DATA_BASIC_PATH["Key Comment"]?.Quote?.attributionId ??
              createAttributionId(),
          },
          Spokesperson: {
            ...ENTRY_DATA_BASIC_PATH["Key Comment"]?.Spokesperson,
            attributionId:
              ENTRY_DATA_BASIC_PATH["Key Comment"]?.Spokesperson
                ?.attributionId ?? createAttributionId(),
          },
        },
      },
    };
  },
  [EXPEDITED_PROGRAM]: (data: ArticleEntryData): ArticleEntryData => {
    const expeditedProgram = data[EXPEDITED_PROGRAM] as ExpeditedProgram;
    const ENTRY_DATA_BASIC_PATH = {
      "Drug Profile": expeditedProgram["Drug Profile"],
      "Expedited Program Contents":
        expeditedProgram["Expedited Program Contents"],
      "Key Comment": expeditedProgram["Key Comment"],
    };

    return {
      ...data,
      [EXPEDITED_PROGRAM]: {
        ...expeditedProgram,
        "Drug Profile": ENTRY_DATA_BASIC_PATH["Drug Profile"]?.map(
          (tableItem) => {
            return {
              ...tableItem,
              attributionInfo: {
                attributionId:
                  tableItem.attributionInfo?.attributionId ??
                  createAttributionId(),
              },
              "Brand Name": {
                ...tableItem["Brand Name"],
                attributionId:
                  tableItem["Brand Name"]?.attributionId ??
                  createAttributionId(),
              },
              "INN Name": {
                ...tableItem["INN Name"],
                attributionId:
                  tableItem["INN Name"]?.attributionId ?? createAttributionId(),
              },
              "Code Name": {
                ...tableItem["Code Name"],
                attributionId:
                  tableItem["Code Name"]?.attributionId ??
                  createAttributionId(),
              },
              MOA: {
                ...tableItem.MOA,
                attributionId:
                  tableItem.MOA?.attributionId ?? createAttributionId(),
              },
              Modality: {
                ...tableItem.Modality,
                attributionId:
                  tableItem.Modality?.attributionId ?? createAttributionId(),
              },
              "Other Characteristics": {
                ...tableItem["Other Characteristics"],
                attributionId:
                  tableItem["Other Characteristics"]?.attributionId ??
                  createAttributionId(),
              },
              ROA: {
                ...tableItem.ROA,
                attributionId:
                  tableItem.ROA?.attributionId ?? createAttributionId(),
              },
              Target: {
                ...tableItem.Target,
                attributionId:
                  tableItem.Target?.attributionId ?? createAttributionId(),
              },
              Phase: {
                ...tableItem.Phase,
                attributionId:
                  tableItem.Phase?.attributionId ?? createAttributionId(),
              },
              "Target Indication": {
                ...tableItem["Target Indication"],
                attributionId:
                  tableItem["Target Indication"]?.attributionId ??
                  createAttributionId(),
              },
              "Therapeutic Area": {
                ...tableItem["Therapeutic Area"],
                attributionId:
                  tableItem["Therapeutic Area"]?.attributionId ??
                  createAttributionId(),
              },
            };
          },
        ),
        "Expedited Program Contents": {
          ...ENTRY_DATA_BASIC_PATH["Expedited Program Contents"],
          [k.updateDate]: {
            ...ENTRY_DATA_BASIC_PATH["Expedited Program Contents"][
              k.updateDate
            ],
            attributionId:
              ENTRY_DATA_BASIC_PATH["Expedited Program Contents"][k.updateDate]
                .attributionId ?? createAttributionId(),
          },
          [k.regulatoryAgency]: {
            ...ENTRY_DATA_BASIC_PATH["Expedited Program Contents"][
              k.regulatoryAgency
            ],
            attributionId:
              ENTRY_DATA_BASIC_PATH["Expedited Program Contents"][
                k.regulatoryAgency
              ].attributionId ?? createAttributionId(),
          },
          [k.expeditedProgram]: {
            ...ENTRY_DATA_BASIC_PATH["Expedited Program Contents"][
              k.expeditedProgram
            ],
            attributionId:
              ENTRY_DATA_BASIC_PATH["Expedited Program Contents"][
                k.expeditedProgram
              ].attributionId ?? createAttributionId(),
          },
          [k.designatedIndication]: {
            ...ENTRY_DATA_BASIC_PATH["Expedited Program Contents"][
              k.designatedIndication
            ],
            attributionId:
              ENTRY_DATA_BASIC_PATH["Expedited Program Contents"][
                k.designatedIndication
              ].attributionId ?? createAttributionId(),
          },
          [k.designatedCompanyName]: {
            ...ENTRY_DATA_BASIC_PATH["Expedited Program Contents"][
              k.designatedCompanyName
            ],
            attributionId:
              ENTRY_DATA_BASIC_PATH["Expedited Program Contents"][
                k.designatedCompanyName
              ].attributionId ?? createAttributionId(),
          },
          [k.AdditionalInformation]: {
            ...ENTRY_DATA_BASIC_PATH["Expedited Program Contents"][
              k.AdditionalInformation
            ],
            attributionId:
              ENTRY_DATA_BASIC_PATH["Expedited Program Contents"][
                k.AdditionalInformation
              ].attributionId ?? createAttributionId(),
          },
        },
        "Key Comment": {
          ...ENTRY_DATA_BASIC_PATH["Key Comment"],
          Quote: {
            ...ENTRY_DATA_BASIC_PATH["Key Comment"]?.Quote,
            attributionId:
              ENTRY_DATA_BASIC_PATH["Key Comment"]?.Quote?.attributionId ??
              createAttributionId(),
          },
          Spokesperson: {
            ...ENTRY_DATA_BASIC_PATH["Key Comment"]?.Spokesperson,
            attributionId:
              ENTRY_DATA_BASIC_PATH["Key Comment"]?.Spokesperson
                ?.attributionId ?? createAttributionId(),
          },
        },
      },
    } as ArticleEntryData;
  },
  [SECTION_KEYS.ventureCapital]: (data: ArticleEntryData): ArticleEntryData => {
    const ventureCapital = data[SECTION_KEYS.ventureCapital] as VentureCapital;
    const BASIC_PATH = {
      [DRUG_PROFILE]: ventureCapital[DRUG_PROFILE],
      [FUNDED_COMPANY]: ventureCapital[FUNDED_COMPANY],
      [ROUND_TYPE_AND_STATUS]: ventureCapital[ROUND_TYPE_AND_STATUS],
      [FINANCING_INFORMATION]: ventureCapital[FINANCING_INFORMATION],
      [INVESTORS]: ventureCapital[INVESTORS],
      [ADDITIONAL_INFORMATION_VENTURE_CAPITAL]:
        ventureCapital[ADDITIONAL_INFORMATION_VENTURE_CAPITAL],
      [KEY_COMMENTS]: ventureCapital[KEY_COMMENTS],
    };

    return {
      ...data,
      [SECTION_KEYS.ventureCapital]: {
        ...ventureCapital,
        [DRUG_PROFILE]: BASIC_PATH[DRUG_PROFILE]?.map((tableItem) => {
          return {
            ...tableItem,
            attributionInfo: {
              attributionId:
                tableItem.attributionInfo?.attributionId ??
                createAttributionId(),
            },
            [DRUG_PROFILE_SHELL_KEYS.brandName]: {
              ...tableItem[DRUG_PROFILE_SHELL_KEYS.brandName],
              attributionId:
                tableItem[DRUG_PROFILE_SHELL_KEYS.brandName]?.attributionId ??
                createAttributionId(),
            },
            [DRUG_PROFILE_SHELL_KEYS.innName]: {
              ...tableItem[DRUG_PROFILE_SHELL_KEYS.innName],
              attributionId:
                tableItem[DRUG_PROFILE_SHELL_KEYS.innName]?.attributionId ??
                createAttributionId(),
            },
            [DRUG_PROFILE_SHELL_KEYS.codeName]: {
              ...tableItem[DRUG_PROFILE_SHELL_KEYS.codeName],
              attributionId:
                tableItem[DRUG_PROFILE_SHELL_KEYS.codeName]?.attributionId ??
                createAttributionId(),
            },
            [DRUG_PROFILE_SHELL_KEYS.moa]: {
              ...tableItem[DRUG_PROFILE_SHELL_KEYS.moa],
              attributionId:
                tableItem[DRUG_PROFILE_SHELL_KEYS.moa]?.attributionId ??
                createAttributionId(),
            },
            [DRUG_PROFILE_SHELL_KEYS.modality]: {
              ...tableItem[DRUG_PROFILE_SHELL_KEYS.modality],
              attributionId:
                tableItem[DRUG_PROFILE_SHELL_KEYS.modality]?.attributionId ??
                createAttributionId(),
            },
            [DRUG_PROFILE_SHELL_KEYS.otherCharacteristics]: {
              ...tableItem[DRUG_PROFILE_SHELL_KEYS.otherCharacteristics],
              attributionId:
                tableItem[DRUG_PROFILE_SHELL_KEYS.otherCharacteristics]
                  ?.attributionId ?? createAttributionId(),
            },
            [DRUG_PROFILE_SHELL_KEYS.roa]: {
              ...tableItem[DRUG_PROFILE_SHELL_KEYS.roa],
              attributionId:
                tableItem[DRUG_PROFILE_SHELL_KEYS.roa]?.attributionId ??
                createAttributionId(),
            },
            [DRUG_PROFILE_SHELL_KEYS.target]: {
              ...tableItem[DRUG_PROFILE_SHELL_KEYS.target],
              attributionId:
                tableItem[DRUG_PROFILE_SHELL_KEYS.target]?.attributionId ??
                createAttributionId(),
            },
            [DRUG_PROFILE_SHELL_KEYS.phase]: {
              ...tableItem[DRUG_PROFILE_SHELL_KEYS.phase],
              attributionId:
                tableItem[DRUG_PROFILE_SHELL_KEYS.phase]?.attributionId ??
                createAttributionId(),
            },
            [DRUG_PROFILE_SHELL_KEYS.targetIndication]: {
              ...tableItem[DRUG_PROFILE_SHELL_KEYS.targetIndication],
              attributionId:
                tableItem[DRUG_PROFILE_SHELL_KEYS.targetIndication]
                  ?.attributionId ?? createAttributionId(),
            },
            [DRUG_PROFILE_SHELL_KEYS.therapeuticArea]: {
              ...tableItem[DRUG_PROFILE_SHELL_KEYS.therapeuticArea],
              attributionId:
                tableItem[DRUG_PROFILE_SHELL_KEYS.therapeuticArea]
                  ?.attributionId ?? createAttributionId(),
            },
            [DRUG_PROFILE_SHELL_KEYS.genericBiosimilar]: {
              ...tableItem[DRUG_PROFILE_SHELL_KEYS.genericBiosimilar],
              attributionId:
                tableItem[DRUG_PROFILE_SHELL_KEYS.genericBiosimilar]
                  ?.attributionId ?? createAttributionId(),
            },
            [DRUG_PROFILE_SHELL_KEYS.refProductName]: {
              ...tableItem[DRUG_PROFILE_SHELL_KEYS.refProductName],
              attributionId:
                tableItem[DRUG_PROFILE_SHELL_KEYS.refProductName]
                  ?.attributionId ?? createAttributionId(),
            },
          };
        }),
        [FUNDED_COMPANY]: {
          ...BASIC_PATH[FUNDED_COMPANY],
          [FUNDED_COMPANY_SHELL_KEYS.companyName]: {
            ...BASIC_PATH[FUNDED_COMPANY]?.[
              FUNDED_COMPANY_SHELL_KEYS.companyName
            ],
            attributionId:
              BASIC_PATH[FUNDED_COMPANY]?.[
                FUNDED_COMPANY_SHELL_KEYS.companyName
              ]?.attributionId ?? createAttributionId(),
          },
          [FUNDED_COMPANY_SHELL_KEYS.companyDescription]: {
            ...BASIC_PATH[FUNDED_COMPANY]?.[
              FUNDED_COMPANY_SHELL_KEYS.companyDescription
            ],
            attributionId:
              BASIC_PATH[FUNDED_COMPANY]?.[
                FUNDED_COMPANY_SHELL_KEYS.companyDescription
              ]?.attributionId ?? createAttributionId(),
          },
        },
        [ROUND_TYPE_AND_STATUS]: {
          ...BASIC_PATH[ROUND_TYPE_AND_STATUS],
          [ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundType]: {
            ...BASIC_PATH[ROUND_TYPE_AND_STATUS]?.[
              ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundType
            ],
            attributionId:
              BASIC_PATH[ROUND_TYPE_AND_STATUS]?.[
                ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundType
              ]?.attributionId ?? createAttributionId(),
          },
          [ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundName]: {
            ...BASIC_PATH[ROUND_TYPE_AND_STATUS]?.[
              ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundName
            ],
            attributionId:
              BASIC_PATH[ROUND_TYPE_AND_STATUS]?.[
                ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundName
              ]?.attributionId ?? createAttributionId(),
          },
        },
        [INVESTORS]: {
          // TODO(유진) : INVESTOR VALUE 초기값 추가 / 분리 필요
          ...BASIC_PATH[INVESTORS],
          [INVESTORS_SHELL_KEYS.investorName]: {
            ...BASIC_PATH[INVESTORS]?.[INVESTORS_SHELL_KEYS.investorName],
            value:
              BASIC_PATH[INVESTORS]?.[INVESTORS_SHELL_KEYS.investorName]?.value
                ?.length === 0
                ? [
                    ...(BASIC_PATH[INVESTORS]?.[
                      INVESTORS_SHELL_KEYS.investorName
                    ]?.value ?? []),
                    { text: "", is_lead_investor: false, is_modified: true },
                  ]
                : BASIC_PATH[INVESTORS]?.[INVESTORS_SHELL_KEYS.investorName]
                    ?.value,
            attributionId:
              BASIC_PATH[INVESTORS]?.[INVESTORS_SHELL_KEYS.investorName]
                ?.attributionId ?? createAttributionId(),
          },
        },
        [FINANCING_INFORMATION]: {
          ...BASIC_PATH[FINANCING_INFORMATION],
          [FINANCING_INFORMATION_SHELL_KEYS.financingInformationDetail]: {
            ...BASIC_PATH[FINANCING_INFORMATION]?.[
              FINANCING_INFORMATION_SHELL_KEYS.financingInformationDetail
            ],
            attributionId:
              BASIC_PATH[FINANCING_INFORMATION]?.[
                FINANCING_INFORMATION_SHELL_KEYS.financingInformationDetail
              ]?.attributionId ?? createAttributionId(),
          },
          [FINANCING_INFORMATION_SHELL_KEYS.amount]: {
            ...BASIC_PATH[FINANCING_INFORMATION]?.[
              FINANCING_INFORMATION_SHELL_KEYS.amount
            ],
            attributionId:
              BASIC_PATH[FINANCING_INFORMATION]?.[
                FINANCING_INFORMATION_SHELL_KEYS.amount
              ]?.attributionId ?? createAttributionId(),
          },
          [FINANCING_INFORMATION_SHELL_KEYS.financingPurpose]: {
            ...BASIC_PATH[FINANCING_INFORMATION]?.[
              FINANCING_INFORMATION_SHELL_KEYS.financingPurpose
            ],
            attributionId:
              BASIC_PATH[FINANCING_INFORMATION]?.[
                FINANCING_INFORMATION_SHELL_KEYS.financingPurpose
              ]?.attributionId ?? createAttributionId(),
          },
        },
        [KEY_COMMENTS]: {
          ...BASIC_PATH[KEY_COMMENTS],
          Quote: {
            ...BASIC_PATH[KEY_COMMENTS]?.[KEY_COMMENTS_SHELL_KEYS.quote],
            attributionId:
              BASIC_PATH[KEY_COMMENTS]?.[KEY_COMMENTS_SHELL_KEYS.quote]
                ?.attributionId ?? createAttributionId(),
          },
          Spokesperson: {
            ...BASIC_PATH[KEY_COMMENTS]?.[KEY_COMMENTS_SHELL_KEYS.spokesperson],
            attributionId:
              BASIC_PATH[KEY_COMMENTS]?.[KEY_COMMENTS_SHELL_KEYS.spokesperson]
                ?.attributionId ?? createAttributionId(),
          },
        },
      },
    } as ArticleEntryData;
  },
};
