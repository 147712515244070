import React from "react";

import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_INVESTORS_GROUP_TABLE_KEY as INVESTORS,
  ENTRY_DATA_INVESTORS_SHELL_KEYS as INVESTORS_SHELL_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import { EntryDataInvestorValue } from "@/types/ventureCapital.types";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

const sectionKey = SECTION_KEYS.ventureCapital;
const groupTableKey = INVESTORS;
const shellKey = INVESTORS_SHELL_KEYS.investorName;
export const useCheckLeadInvestorName = () => {
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const checkLeadInvestorName = (
    event: React.ChangeEvent<HTMLInputElement>,
    valueIndex: number,
  ) => {
    const { checked } = event.target;

    const updatedEntryData = {
      ...entryData,
      [sectionKey]: {
        ...entryData[sectionKey],
        [groupTableKey]: {
          ...entryData?.[sectionKey]?.[groupTableKey],
          [shellKey]: {
            ...entryData?.[sectionKey]?.[groupTableKey]?.[shellKey],
            value: entryData?.[sectionKey]?.[groupTableKey]?.[
              shellKey
            ]?.value?.map((value, index) =>
              index === valueIndex
                ? {
                    ...value,
                    is_lead_investor: checked,
                  }
                : value,
            ) as EntryDataInvestorValue[],
          },
        },
      },
    };

    setEntryData(updatedEntryData as ArticleEntryData);
  };

  return { checkLeadInvestorName };
};
