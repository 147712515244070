import React from "react";

import * as S from "./TableRowContainer.style";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  isDisplay?: boolean;
}

const TableRowContainer = ({ children, isDisplay = true, ...rest }: Props) => {
  return (
    <S.RowWrapper $isDisplay={isDisplay} {...rest}>
      {children}
    </S.RowWrapper>
  );
};

export default TableRowContainer;
