import React from "react";

import ErrorPageView from "@/components/shared/ui/ErrorPageView/ErrorPageView";

interface Props {
  error: Error | null;
  handleButtonClick: () => void;
}

const UncaughtError = ({ error, handleButtonClick }: Props) => {
  return (
    <ErrorPageView
      title="Something went wrong"
      message={error?.message ?? "Please try again later"}
      buttonLabel="Reload page"
      handleButtonClick={handleButtonClick}
    />
  );
};

export default UncaughtError;
