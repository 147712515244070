"use client";

import styled, { css } from "styled-components";

export const HeaderShell = styled.div<{
  $shellMinWidth: string;
  $shellMaxWidth: string;
  $isCurrentOrdering: boolean;
  $isOrderingShell: boolean;
}>`
  width: 100%;
  min-height: 3.6rem;
  min-width: ${({ $shellMinWidth }) => $shellMinWidth};
  max-width: ${({ $shellMaxWidth }) => $shellMaxWidth};

  display: flex;
  align-items: center;
  gap: var(--Spacing-2, 0.2rem);

  padding: var(--Spacing-8, 0.8rem) var(--Spacing-2, 0.2rem);
  cursor: default;

  ${({ $isOrderingShell }) =>
    $isOrderingShell &&
    css`
      cursor: pointer;

      &:hover {
        .header-text {
          color: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);
        }

        .ordering-button {
          display: block;

          > svg {
            path {
              fill: #017abc;
            }
          }
        }
      }

      &:active {
        .header-text {
          color: var(--Color-Common-Text-Interactive-Primary-Pressed, #015d8e);
        }

        .ordering-button {
          display: block;

          > svg {
            path {
              fill: #015d8e;
            }
          }
        }
      }
    `}

  ${({ $isCurrentOrdering }) =>
    $isCurrentOrdering &&
    css`
      .header-text {
        color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
      }

      .ordering-button {
        display: block;

        > svg {
          path {
            fill: #0198e9;
          }
        }
      }
    `}
`;

export const HeaderShellText = styled.div`
  display: flex;
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);

  color: var(--Color-Common-Text-Tertiary, #7c899c);
  font-family: var(--font-pt-serif);
  font-size: 1.2rem;
  font-weight: 700;
`;

export const OrderIcon = styled.button`
  display: none;
  width: 2rem;
  height: 2rem;
`;
