import { ClassificationCode, ProcessStatusCode } from "@/types/code.types";

import { EDITOR_ROLES } from "../constants/editorRoles.constants";
import { PRESS_CLASSIFICATION_ROLES_LIST } from "../constants/pressClassificationRolesList.constants";
import { CLASSIFICATION_KEYS_CODE } from "../constants/returnRolesList.constants";
import { EditorRoleCode } from "../types/roles.types";

export const checkManualClassificationRoles = (
  isSuperAdmin: boolean,
  editorRoles: EditorRoleCode[],
  status: ProcessStatusCode,
  classification?: ClassificationCode | "",
) => {
  const isEnableManualClassificationRole =
    PRESS_CLASSIFICATION_ROLES_LIST.EDTK0100.includes(status) &&
    editorRoles.includes(EDITOR_ROLES.EDTK0100);

  const checkEditorRolesAccess = (role: EditorRoleCode) => {
    return (
      (editorRoles.includes(role) &&
        PRESS_CLASSIFICATION_ROLES_LIST[role].includes(status)) ||
      isEnableManualClassificationRole
    );
  };

  if (isSuperAdmin) return true;

  if (!classification) {
    return isEnableManualClassificationRole;
  }

  const isManualClassificationRole = editorRoles.includes(
    EDITOR_ROLES.EDTK0100,
  );

  const handleStatusClassificationMatch = () => {
    switch (classification) {
      case CLASSIFICATION_KEYS_CODE.clinicalTrials:
        return (
          checkEditorRolesAccess(EDITOR_ROLES.EDTK0201) ||
          checkEditorRolesAccess(EDITOR_ROLES.EDTK0202)
        );
      case CLASSIFICATION_KEYS_CODE.clinicalTrialStatus:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0303);
      case CLASSIFICATION_KEYS_CODE.posterContents:
        return (
          checkEditorRolesAccess(EDITOR_ROLES.EDTK0401) ||
          checkEditorRolesAccess(EDITOR_ROLES.EDTK0402)
        );
      case CLASSIFICATION_KEYS_CODE.deals:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0503);
      case CLASSIFICATION_KEYS_CODE.expeditedProgram:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0603);
      case CLASSIFICATION_KEYS_CODE.approvalProcess:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0703);
      case CLASSIFICATION_KEYS_CODE.ventureCapital:
        return checkEditorRolesAccess(EDITOR_ROLES.EDTK0803);
      default:
        return true;
    }
  };

  return isManualClassificationRole && handleStatusClassificationMatch();
};
