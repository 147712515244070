const API_RESPONSE_CODE_DES = {
  duplicatedUploadImage: 12004,
} as const;

export const API_RESPONSE_CODE = {
  edb: {},
  efo: {},
  account: {},
  des: { ...API_RESPONSE_CODE_DES },
} as const;
