"use client";

import styled, { css } from "styled-components";

export type RightMenuStyle = "text" | "line";

export const HeaderRightMenuDesktopWrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1.6rem;
`;

export const RightMenu = styled.li<{ $menuStyle: RightMenuStyle }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  width: 11.2rem;
  height: 3.6rem;
  min-height: var(--Size-Btn-Sm, 2.8rem);
  max-height: var(--Size-Btn-Lg, 4.8rem);
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 145%;
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  cursor: pointer;

  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Interactive-Secondary, #b1b8c4);
  background: var(--Color-Common-Bg-Interactive-Secondary, #fff);

  &:hover {
    color: var(--Color-Common-Text-Interactive-Secondary-Hovered, #3d4551);
  }

  &:active {
    color: var(--Color-Common-Text-Interactive-Secondary-Pressed, #2a2f37);
  }

  ${({ $menuStyle }) =>
    $menuStyle === "line" &&
    css`
      color: var(--Color-Common-Text-Interactive-Inverse, #fff);
      background: var(--Color-Common-Bg-Interactive-Primary, #1baefe);
      border: none;

      &:hover {
        color: var(--Color-Common-Text-Interactive-Inverse, #fff);
        background: var(--Color-Common-Bg-Interactive-Primary-Hovered, #0198e9);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
          0px 0px 2px 0px rgba(0, 0, 0, 0.12);
      }

      &:active {
        color: var(--Color-Common-Text-Interactive-Inverse, #fff);
        background: var(--Color-Common-Bg-Interactive-Primary-Pressed, #017abc);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset,
          0px 0px 2px 0px rgba(0, 0, 0, 0.06) inset;
      }
    `};

  @media only screen and (min-width: 990px) and (max-width: calc(1200px - 0.1rem)) {
    width: 9.6rem;
  }
`;
