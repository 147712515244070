import styled from "styled-components";

import { UpdateButton } from "@EditArticleContainer/shared/components/UpdateButton/UpdateButton.style";

export const TableGroupWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;
`;

export const ParagraphUpdateButton = styled(UpdateButton)`
  position: absolute;
  left: -1.6rem;
  top: 0;
  transform: translateX(-100%);
`;
