import { useEffect, useRef } from "react";

import { MERGE_ACQUISITION_TABLE_KEYS } from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import { useSellerAndBuyerByDealTypeQuery } from "@api/sellerAndBuyerByDealType/sellerAndBuyerByDealType";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { TableCreatorTableTitle } from "@FillInTableContainer/shared";
import TableDragDivider from "@FillInTableContainer/shared/components/TableDragDivider/TableDragDivider";
import { useCurrentDealTypeValue } from "@FillInTableContainer/shared/context/CurrentDealTypeContext";
import useCheckIsDisplayGroupShellDragDivider from "@FillInTableContainer/shared/hooks/useCheckIsDisplayGroupShellDragDivider";
import { useHandleTableCreatorGroupShellDrag } from "@FillInTableContainer/shared/hooks/useHandleTableCreatorGroupShellDrag";
import { TableCreatorSubGroupGroupShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import { getTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import DealTermsMergeGroupShell from "./components/DealTermsMergeGroupShell/DealTermsMergeGroupShell";
import * as S from "./DealTermsMergeTable.style";
import { useHandleDealTermsMergeTableCreatorTable } from "./hooks/useHandleDealTermsMergeTableCreatorTable";

interface Props {
  groupTableKey: DealsSection;
  tableKey: string | null;
  groupShellList: TableCreatorSubGroupGroupShellList;
  groupTableIndex: number;
  tableIndex: number;
}

const ARTICLE_SECTION = "Deals";

const isDraggingGroupShellKey = "Rights Scope";

const DealTermsMergeTable = ({
  groupShellList,
  groupTableIndex,
  tableIndex,
  tableKey,
  groupTableKey,
}: Props) => {
  const articleSection = useArticleSection();
  const tableTitleRef = useRef<HTMLDivElement>(null);

  const tablePropsInfo: Props = {
    groupTableKey,
    tableKey,
    groupTableIndex,
    tableIndex,
    groupShellList,
  };

  const {
    focusStatus,
    isFocused,
    handleClickClearButton,
    numberOfGroupShellCount,
    focusEventToChangePositionInfo,
    handleChangeDefaultFocus,
  } = useHandleDealTermsMergeTableCreatorTable(tablePropsInfo);

  const {
    handleGroupShellDragEnd,
    handleGroupShellDragOn,
    handleGroupShellDragOver,
    handleGroupShellDragStart,
    groupShellDraggedIndexInfo,
    isDraggingGroupShell,
  } = useHandleTableCreatorGroupShellDrag(ARTICLE_SECTION, tableIndex);

  const { currentDealTypeList } = useCurrentDealTypeValue();
  const { data: sellerBuyerInfo } =
    useSellerAndBuyerByDealTypeQuery(currentDealTypeList);

  const { isLastGroupShell, handleCheckDragDividerDisplay } =
    useCheckIsDisplayGroupShellDragDivider(
      tableIndex,
      isDraggingGroupShell,
      isDraggingGroupShellKey,
    );

  const customTableTitle = () => {
    if (tableKey === MERGE_ACQUISITION_TABLE_KEYS.acquiredCompany)
      return sellerBuyerInfo.data.seller;

    if (tableKey === MERGE_ACQUISITION_TABLE_KEYS.acquiringCompany)
      return sellerBuyerInfo.data.buyer;

    return "";
  };

  const isClearTable =
    isFocused &&
    focusStatus.type === "clear" &&
    focusStatus.container === "table";

  const title = getTableTitle(articleSection, tableKey);

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;

        const clickSelectButton = tableTitleRef.current?.contains(target);

        if (clickSelectButton) return;
        if (target.dataset.button) return;

        handleChangeDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleChangeDefaultFocus, isFocused]);

  const handleFocusTableTitle = () => {
    focusEventToChangePositionInfo({
      type: "clear",
      container: "table",
    });
  };

  return (
    <S.TableWrapper isFocused={isFocused} isClearTable={isClearTable}>
      {isClearTable && (
        <S.DeleteTableButtonWrapper>
          <S.DeleteTableButton
            data-button="delete-button"
            onClick={handleClickClearButton}
          >
            <img src={IconXMark} alt="button for clear table" />
            {focusStatus.type === "clear" ? `Clear ${title} Table` : null}
            {focusStatus.type === "delete" ? `Delete ${title} Table` : null}
          </S.DeleteTableButton>
        </S.DeleteTableButtonWrapper>
      )}

      <div ref={tableTitleRef}>
        {getTableTitle(articleSection, tableKey) && (
          <TableCreatorTableTitle
            tableKey={tableKey}
            groupTableKey={groupTableKey}
            groupTableIndex={groupTableIndex}
            tableIndex={tableIndex}
            onClickHandler={handleFocusTableTitle}
            tableTitle={customTableTitle()}
          />
        )}
      </div>
      <S.GroupShellSpaceWrapper>
        {groupShellList.map(
          ({ groupShellKey, subGroupShellList }, groupShellListIndex) => (
            <S.GroupShellWrapper
              key={`groupShellList-${String(groupShellListIndex)}`}
            >
              {newArrayToCountValue(numberOfGroupShellCount(groupShellKey)).map(
                (_, groupShellIndex) => (
                  <S.DraggableGroupShellWrapper
                    isTargetDraggingTable={
                      isDraggingGroupShell &&
                      groupShellDraggedIndexInfo[0] !== groupShellIndex
                    }
                    onDragOver={(e) =>
                      handleGroupShellDragOver(e, groupShellIndex)
                    }
                  >
                    <TableDragDivider
                      isDraggingEndPosition={handleCheckDragDividerDisplay({
                        moveDragIndex: groupShellDraggedIndexInfo[1],
                        startDragIndex: groupShellDraggedIndexInfo[0],
                        groupShellIndex,
                        groupShellKey,
                      })}
                    />
                    <DealTermsMergeGroupShell
                      key={`${String(groupShellListIndex)}-${String(
                        groupShellIndex,
                      )}`}
                      subGroupShellList={subGroupShellList}
                      groupTableKey={groupTableKey}
                      groupTableIndex={groupTableIndex}
                      tableKey={tableKey}
                      tableIndex={tableIndex}
                      groupShellKey={groupShellKey}
                      groupShellIndex={groupShellIndex}
                      groupShellCount={numberOfGroupShellCount(groupShellKey)}
                      handleDragEnd={handleGroupShellDragEnd}
                      handleDragOn={handleGroupShellDragOn}
                      handleDragStart={handleGroupShellDragStart}
                      isDraggableGroupShell={isDraggingGroupShell}
                    />
                    {isLastGroupShell(groupShellIndex, groupShellKey) && (
                      <TableDragDivider
                        isDraggingEndPosition={handleCheckDragDividerDisplay({
                          moveDragIndex: groupShellDraggedIndexInfo[1],
                          startDragIndex: groupShellDraggedIndexInfo[0],
                          groupShellIndex,
                          groupShellKey,
                          lastIndex: newArrayToCountValue(
                            numberOfGroupShellCount(groupShellKey),
                          ).length,
                        })}
                      />
                    )}
                  </S.DraggableGroupShellWrapper>
                ),
              )}
            </S.GroupShellWrapper>
          ),
        )}
      </S.GroupShellSpaceWrapper>
    </S.TableWrapper>
  );
};

export default DealTermsMergeTable;
