import styled, { css } from "styled-components";

export const TableTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  width: 100%;
  height: 3.2rem;
  padding: 0 0.8rem;
  background-color: white;
`;

export const RelativeWrapper = styled.div`
  position: relative;
`;

export const TitleContentsWrapper = styled.div<{
  isDragging: boolean;
}>`
  height: 3.2rem;
  min-width: 6.4rem;
  max-width: 60rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--Spacing-16, 1.6rem);

  justify-content: space-between;
  background-color: #fff;
  padding: 0rem var(--Spacing-16, 1.6rem);
  margin-left: -0.8rem;
  cursor: pointer;

  ${({ isDragging }) =>
    isDragging &&
    css`
      border-radius: var(--Border-Radius-0, 0rem);
      background: var(--Color-Bg-Draft, #d9f4f7);
    `}
`;

export const Title = styled.h3<{
  isDragging: boolean;
}>`
  color: var(--Color-Common-Text-Secondary, #515c6c);
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 130%
    ${({ isDragging }) =>
      isDragging &&
      css`
        color: var(--Color-Common-Text-Primary, #2a2f37);
      `};
`;

export const RightButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  height: 100%;
`;

export const DragButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
`;
