import React from "react";

import { ImageType } from "@/components/business/InsertImageContainer/shared/types/insertImage.types";
import IconClose from "@images/insertImage/icon-x-mark-darkgray.svg";

import * as S from "./ImageView.style";

interface Props {
  selectImage: ImageType;
  closeModal: () => void;
}

const ImageView = ({ selectImage, closeModal }: Props) => {
  return (
    <S.ImageViewWrapper>
      <S.ImageViewImage>
        {selectImage && (
          <img
            src={
              typeof selectImage === "string"
                ? selectImage
                : Buffer.from(selectImage).toString()
            }
            alt="zoom in target"
          />
        )}
      </S.ImageViewImage>
      <S.ImageViewCloseButton type="button" onClick={closeModal}>
        <img src={IconClose} alt="zoom out" />
      </S.ImageViewCloseButton>
    </S.ImageViewWrapper>
  );
};

export default ImageView;
