import styled, { css } from "styled-components";

import { SHELL_WIDTH } from "@FillInTableContainer/shared/constants/shellSize.constants";
import { DeleteButton } from "@FillInTableContainer/shared/styles/deleteButton.style";

import type { ShellColumnSize } from "@FillInTableContainer/shared/types/shellSize.types";

export const RelativeWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const TitleShellWrapper = styled.div<{
  column: ShellColumnSize;
  isFocused: boolean;
  isAlertShell: boolean;
  marginBottom: number;
  isDeleteShell: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.4rem;
  width: ${({ column }) => SHELL_WIDTH[column]};
  min-height: 3.6rem;
  border: 0.1rem solid ${({ theme }) => theme.color.neural.gray02};
  background-color: ${({ theme }) => theme.color.neural.white};
  padding: 2.4rem 0.8rem 0.8rem 0.8rem;
  height: 100%;

  ${({ isFocused }) =>
    isFocused &&
    css`
      border-radius: var(--Border-Radius-0, 0rem);
      border: 1px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);
      background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #e6f6ff);
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.16);

      .filled-text-field {
        border: 1px solid
          var(--Color-Common-Border-Interactive-Primary, #0198e9);
        background: var(--Color-Common-Bg-Primary, #fff);
      }
      .empty-text-field {
        border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
        background: var(--Color-Common-Bg-Secondary, #f0f2f4);
      }
    `}

  ${({ isAlertShell }) =>
    isAlertShell &&
    css`
      border: 1px solid var(--Color-Common-Border-Warning-Subtle, #fdbba5);
      background: var(--Color-Common-Bg-Warning-Subtle, #feddd2);
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.16);
    `}

    ${({ isDeleteShell }) =>
    isDeleteShell &&
    css`
      border: 1px solid var(--Color-Border-Error, #f9a9d0);
      background: var(--Color-Bg-Error, #fcd4e7);

      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.16);

      .filled-text-field {
        border: 1px solid
          var(--Color-Common-Border-Interactive-Primary, #0198e9);
        background: var(--Color-Common-Bg-Primary, #fff);
      }
      .empty-text-field {
        border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
        background: var(--Color-Common-Bg-Secondary, #f0f2f4);
      }
      .last-empty-text-field {
        border: 1px dashed var(--Color-Common-Border-Tertiary, #e5e7eb);
        background: var(--Color-Common-Bg-Interactive-Secondary, #fff);
      }
    `}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom / 10}rem;
    `}
`;

export const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.8rem;
  width: 100%;
  padding: 0 0.4rem;
`;

export const ColumnDeleteButton = styled(DeleteButton)`
  position: absolute;
  top: -4rem;

  &.button-for-shell {
    top: -2.4rem;
    left: 50%;
    transform: translateX(-50%);
  }
`;
