"use client";

import styled from "styled-components";

export const InputContainerWrapper = styled.div`
  display: flex;
  width: 56rem;
  min-width: 32rem;
  max-width: 56rem;
  padding: var(--Spacing-4, 0.4rem) var(--Spacing-4, 0.4rem)
    var(--Spacing-4, 0.4rem) var(--Spacing-8, 0.8rem);
  align-items: center;
  gap: var(--Spacing-8, 0.8rem);

  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Interactive-Secondary, #b1b8c4);
  background: var(--Color-Common-Bg-Interactive-Secondary, #fff);

  &:hover {
    border: 1px solid
      var(--Color-Common-Border-Interactive-Secondary-Hovered, #96a1b0);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
      0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  &:active {
    border: 1px solid
      var(--Color-Common-Border-Interactive-Secondary-Pressed, #7c899c);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
      0px 1px 2px 0px rgba(0, 0, 0, 0.16);
  }

  &:focus-within {
    border: 1px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);

    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
      0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }
`;

export const SearchButton = styled.div`
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  > svg {
    width: 2rem;
    height: 2rem;
    > path {
      fill: #0198e9;
    }
  }
`;

export const Divider = styled.div`
  width: 0.1rem;
  height: 2.2rem;
  border-left: 0.1rem solid var(--Color-Common-Border-Success-Subtle, #a4dfff);
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.2rem;
`;
