import React from "react";

import RequiredMark from "@atoms/RequiredMark/RequiredMark";

import * as S from "./DefaultShellTitle.style";

import type { ShellColumnSize } from "@FillInTableContainer/shared/types/shellSize.types";

interface Props {
  isFocused: boolean;
  isClearShell: boolean;
  isDeleteShell?: boolean;
  isAlertShell: boolean;
  isRequiredShell: boolean;
  isSeparatedShellTitle?: boolean;
  shellTitle: string;
  shellTitleNumbering?: string;

  columnSize: ShellColumnSize;
}

const DefaultShellTitle = ({
  isFocused,
  isClearShell,
  isDeleteShell,
  isAlertShell,
  isRequiredShell,
  isSeparatedShellTitle,
  shellTitle,
  shellTitleNumbering,
  columnSize,
  ...rest
}: Props) => {
  if (isSeparatedShellTitle) return null;
  return (
    <S.ShellTitleWrapper>
      {!isSeparatedShellTitle && (
        <S.ShellTitle
          columnSize={columnSize}
          isFocused={isFocused}
          isClearShell={isClearShell}
          isDeleteShell={isDeleteShell}
          isAlertShell={isAlertShell}
          {...rest}
        >
          {isRequiredShell && <RequiredMark text="*" />}
          {shellTitle} {shellTitleNumbering}
        </S.ShellTitle>
      )}
    </S.ShellTitleWrapper>
  );
};

export default DefaultShellTitle;
