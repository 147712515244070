import { useCallback, useEffect } from "react";

import { DealsSection } from "@/types/deals.types";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import {
  DEFAULT_FOCUS_STATUS,
  FocusStatus,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  PositionInfo,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  DEFAULT_VALIDATED_INFO,
  useValidatedInTableCreator,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useGetNumberOfGroupShell } from "@FillInTableContainer/shared/hooks/useGetNumberOfGroupShell";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useHandleMergeLicenseeTable } from "./useHandleMergeLicenseeTable";

interface Props {
  groupTableKey: DealsSection;
  tableKey: string | null;
  groupTableIndex: number;
  tableIndex: number;
}

interface UseHandleTableCreatorTableReturn {
  info: PositionInfo;
  focusStatus: FocusStatus;
  isFocused: boolean;
  focusEventToChangePositionInfo: (focusEnterType: FocusStatus) => void;
  numberOfGroupShellCount: (groupShellKey: string | null) => number;
  handleClickClearButton: () => void;
  handleChangeDefaultFocus: () => void;
}

export const useHandleDealTermsMergeTableCreatorTable = (
  tablePropsInfo: Props,
): UseHandleTableCreatorTableReturn => {
  const { groupTableKey, groupTableIndex, tableKey, tableIndex } =
    tablePropsInfo;

  const articleSection = "Deals";

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { groupShellCount } = useGetNumberOfGroupShell();

  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );

  const {
    handleClickLicenseeTableClearButton,
    handleKeyDownToClearLicenseeTable,
  } = useHandleMergeLicenseeTable(tablePropsInfo);

  const isEnableEdit = articleInfo
    ? ENABLE_GROUP_TABLE_KEY_LIST[articleSection][groupTableKey].includes(
        articleInfo.draftArticleStatus,
      )
    : false;

  const isFocused =
    info.groupTableKey === groupTableKey &&
    info.tableKey === tableKey &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === null &&
    info.groupShellIndex === null &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    isEnableEdit;

  const numberOfGroupShellCount = (groupShellKey: string | null) =>
    groupShellCount(groupShellKey, tableIndex);

  const focusEventToChangePositionInfo = (focusEnterType: FocusStatus) => {
    changeInfo({
      groupTableKey,
      groupTableIndex,
      tableKey,
      tableIndex,
      groupShellKey: null,
      groupShellIndex: null,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
    changeFocusStatus({ type: "clear", container: "table" });

    if (focusEnterType.type === "clear") {
      changeFocusStatus({ type: "clear", container: "table" });
    }
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleChangeDefaultFocus = () => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  };

  const handleClickClearButton = () => {
    handleClickLicenseeTableClearButton();
  };

  const handleKeyDownToClearTable = useCallback(
    (event: KeyboardEvent) => {
      handleKeyDownToClearLicenseeTable(event);
    },
    [handleKeyDownToClearLicenseeTable],
  );

  useEffect(() => {
    if (focusStatus.container !== "table") return;
    window.addEventListener("keydown", handleKeyDownToClearTable);

    return () => {
      window.removeEventListener("keydown", handleKeyDownToClearTable);
    };
  }, [focusStatus, handleKeyDownToClearTable]);

  return {
    info,
    focusStatus,
    isFocused,
    handleChangeDefaultFocus,
    handleClickClearButton,
    numberOfGroupShellCount,
    focusEventToChangePositionInfo,
  };
};
