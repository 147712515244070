import React from "react";

/**  Press 본문 내용을 복사할 때 의도치 않은 개행이 포함되어 있는 경우를 대비하여 복사 시 개행을 제거하는 역할을 합니다. */
export const removeLineBreaksCopyText = (
  event: React.ClipboardEvent<HTMLDivElement>,
) => {
  const currentCopyText = window.getSelection()?.toString() || "";
  const removeNewLineText = currentCopyText.replaceAll("\n", "");

  event.clipboardData.setData("text/plain", removeNewLineText);

  event.preventDefault();
};
