"use client";

import styled from "styled-components";

export const ViewMoreButton = styled.button`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 2rem;
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  font-size: 1.2rem;
  font-weight: 500;

  &:hover {
    color: var(--Color-Common-Text-Interactive-Secondary-Hovered, #3d4551);
    > svg {
      path {
        fill: #3d4551;
      }
    }
  }

  &:active {
    color: var(--Color-Common-Text-Interactive-Secondary-Pressed, #2a2f37);
    > svg {
      path {
        fill: #2a2f37;
      }
    }
  }
`;

export const ViewMoreIcon = styled.div`
  width: 1.6rem;
  height: 1.6rem;
`;
