import styled, { css } from "styled-components";

export const DragButton = styled.button<{ $isDragging: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  border: 1px solid transparent;
  width: 2.4rem;
  height: 2.4rem;

  &:hover {
    border-radius: var(--Border-Radius-4, 0.4rem);
    border: 1px solid var(--Color-Border-Draft-Bold, #29b5c2);
    background: var(--Color-Bg-Draft, #d9f4f7);

    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12),
      0px 4px 8px 0px rgba(0, 0, 0, 0.16);

    > svg {
      > path {
        fill: #29b5c2;
      }
    }
  }

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      border-radius: var(--Border-Radius-4, 0.4rem);
      border: 1px solid var(--Color-Border-Draft-Bold, #29b5c2);
      background: var(--Color-Bg-Draft, #d9f4f7);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16),
        0px 0px 1px 0px rgba(0, 0, 0, 0.12);
    `}
`;
