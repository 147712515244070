import { PressListOrderingKey } from "@PressListContainer/pressList.types";
import { usePressListFilterState } from "@stores/pressList";

import {
  ORDERING_TABLE,
  Ordering,
  TABLE_HEADER_LIST,
  TableHeaderList,
} from "../../../constants/pressListTable.constants";

interface UseHandlePressListTableOrderingReturn {
  tableHeaderList: TableHeaderList[];
  handleOrdering: (
    orderingTitle: PressListOrderingKey,
    ordering: Ordering,
  ) => void;
  getOrderingIcon: (ordering: Ordering) => string;
  checkIsOrdering: (ordering: Ordering) => boolean;
}

export const useHandlePressListTableOrdering =
  (): UseHandlePressListTableOrderingReturn => {
    const [pressListFilter, setPressListFilter] = usePressListFilterState();
    const { order } = pressListFilter;

    const tableHeaderList: TableHeaderList[] = TABLE_HEADER_LIST.map(
      (tableHeader) => {
        const orderingValue = order[tableHeader.orderingTitle] || "none";
        return {
          ...tableHeader,
          ordering: orderingValue,
        };
      },
    );

    const handleOrdering = (
      orderingTitle: PressListOrderingKey,
      ordering: Ordering,
    ) => {
      const updatedOrdering = ORDERING_TABLE[ordering];
      let updatedOrderObject = { ...order };

      if (orderingTitle in order) {
        delete updatedOrderObject[orderingTitle];
      }

      updatedOrderObject = {
        ...updatedOrderObject,
        [orderingTitle]: updatedOrdering,
      };

      if (updatedOrdering === "none") {
        delete updatedOrderObject[orderingTitle];
      }
      setPressListFilter({
        ...pressListFilter,
        order: updatedOrderObject,
      });
    };

    const getOrderingIcon = (ordering: Ordering) => {
      if (ordering === "asc") return "▲";
      if (ordering === "desc") return "▼";
      return "";
    };

    const checkIsOrdering = (ordering: Ordering) => {
      if (ordering === "none") return false;
      return true;
    };

    return {
      tableHeaderList,
      handleOrdering,
      getOrderingIcon,
      checkIsOrdering,
    };
  };
