import React, { useState } from "react";

import {
  CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS,
  LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { ENTRY_DATA_TO_CHANGE_SUB_GROUP_SHELL_ORDER } from "@FillInTableContainer/shared/constants/changeEntryDataOrder.constants";
import { ArticleSectionType } from "@FillInTableContainer/types/fillInTable.types";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export type DragInfoType = [number | null, number | null];

export const useHandleTableCreatorSubGroupShellDrag = (
  articleSection: ArticleSectionType,
  tableIndex: number,
) => {
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const [subGroupShellDraggedIndexInfo, setSubGroupShellDraggedIndexInfo] =
    useState<DragInfoType>([null, null]);

  const [isDraggingSubGroupShell, setIsDraggingSubGroupShell] =
    useState<boolean>(false);

  const handleSubGroupShellDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => {
    setSubGroupShellDraggedIndexInfo([index, null]);
  };

  const handleSubGroupShellDragOver = (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => {
    event.preventDefault();

    setSubGroupShellDraggedIndexInfo([subGroupShellDraggedIndexInfo[0], index]);
  };

  const handleSubGroupShellDragEnd = (subGroupShellKey: string) => {
    const activeSubGroupShellKeys: string[] = [
      LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.milestone,
      LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail,
      MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.contingentPayment,
      CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS.arm,
    ];

    if (!activeSubGroupShellKeys.includes(subGroupShellKey)) return;

    setEntryData(
      ENTRY_DATA_TO_CHANGE_SUB_GROUP_SHELL_ORDER?.[articleSection]?.[
        subGroupShellKey
      ](entryData, subGroupShellDraggedIndexInfo, tableIndex) || {},
    );
    setIsDraggingSubGroupShell(false);
  };

  const handleSubGroupShellDragOn = () => {
    setIsDraggingSubGroupShell(true);
  };
  return {
    handleSubGroupShellDragStart,
    handleSubGroupShellDragOver,
    handleSubGroupShellDragEnd,
    handleSubGroupShellDragOn,
    subGroupShellDraggedIndexInfo,
    isDraggingSubGroupShell,
  };
};
