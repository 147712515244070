import styled, { css } from "styled-components";

export const UnsuitableButtonWrapper = styled.div<{
  isEnableUnsuitablePress: boolean;
  disabled: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
`;

export const UnsuitableButton = styled.button<{
  isEnableUnsuitablePress: boolean;
  disabled: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  text-shadow: 0px 8px 24px rgba(7, 68, 161, 0.05);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 130%;
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  cursor: pointer;

  height: 4rem;
  min-height: var(--Size-Btn-Minimum, 2.8rem);
  max-height: var(--Size-Btn-Maximum, 4.8rem);

  padding: 0.8rem 1.6rem 0.8rem 1.6rem;
  border-radius: 0.4rem;
  border: 1px solid var(--Color-Border-Disabled, #b1b8c4);
  background-color: ${({ theme }) => theme.color.neural.white};
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.16);

  &:hover {
    border: 1px solid var(--Color-Border-Interactive-Secondary-Hovered, #96a1b0);
    background: var(--Color-Bg-Interactive-Secondary-Hovered, #f0f2f4);
    color: var(--Color-Text-Interactive-Secondary-Hovered, #3d4551);
  }

  &:active {
    border-radius: var(--Border-Radius-4, 0.4rem);
    border: 1px solid var(--Color-Border-Interactive-Secondary-Pressed, #7c899c);
    background: var(--Color-Bg-Interactive-Secondary-Pressed, #e5e7eb);
    color: var(--Color-Text-Interactive-Secondary-Pressed, #2a2f37);
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--Color-Common-Text-Disabled, #96a1b0);
    border: 1px solid var(--Color-Common-Border-Disabled, #b1b8c4);
    background: var(--Color-Common-Bg-Disabled, #cbd0d8);
  }

  ${({ isEnableUnsuitablePress }) =>
    isEnableUnsuitablePress &&
    css`
      border: 1px solid var(--PressStatus-Error, #f23c93);
      background: var(--PressStatus-Error, #f23c93);
      color: var(--Color-Text-Interactive-Inverse, #fff);
    `}

  ${({ disabled }) => disabled && css``}
`;
