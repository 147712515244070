import { DealsSection } from "@/types/deals.types";
import { useScroll } from "@FillInTableContainer/components/FillInTable/hooks/useScroll";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { TableCreatorGroupTableTitle } from "@FillInTableContainer/shared";
import { useCanEditGroupTable } from "@FillInTableContainer/shared/hooks/useCanEditGroupTable";
import { TableCreatorSubGroupShellTableList } from "@FillInTableContainer/shared/types/tableCreator.types";
import { isRequiredGroupTable } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getGroupTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";

import DealTermsMergeTable from "./components/DealTermsMergeTable/DealTermsMergeTable";
import * as S from "./DealTermsMergeGroupTable.style";

interface Props {
  groupTableKey: DealsSection;
  tableList: TableCreatorSubGroupShellTableList;
  groupTableIndex: number;
}

const DealTermsMergeGroupTable = ({
  groupTableIndex,
  groupTableKey,
  tableList,
}: Props) => {
  const { register } = useScroll();
  const articleSection = useArticleSection();

  const { checkDisableEdit } = useCanEditGroupTable();

  return (
    <S.GroupTableWrapper
      isDisableEdit={checkDisableEdit(groupTableKey)}
      {...register(groupTableKey)}
    >
      <TableCreatorGroupTableTitle
        title={getGroupTableTitle(articleSection, groupTableKey)}
        isRequired={isRequiredGroupTable(articleSection, groupTableKey)}
      />
      <S.TableCreatorTableListWrapper
        isDisableEdit={checkDisableEdit(groupTableKey)}
      >
        {tableList.map(({ tableKey, groupShellList }, tableIndex) => {
          return (
            <DealTermsMergeTable
              key={`table-${String(groupTableIndex)}-${String(tableIndex)}`}
              tableKey={tableKey}
              groupShellList={groupShellList}
              groupTableKey={groupTableKey}
              groupTableIndex={groupTableIndex}
              tableIndex={0}
            />
          );
        })}
      </S.TableCreatorTableListWrapper>
    </S.GroupTableWrapper>
  );
};

export default DealTermsMergeGroupTable;
