import React from "react";

import useModal from "@hooks/useModal";

import SelectClassificationForDuplicatePress from "./components/SelectClassificationForDuplicatePress/SelectClassificationForDuplicatePress";
import * as S from "./PressDuplicateButton.style";

type Props = {
  title: string;
};

const PressDuplicateButton = ({ title }: Props) => {
  const { openModal, closeModal, isModal } = useModal();
  const clickDuplicateButton = () => {
    openModal();
  };
  if (title !== "Press ID") return null;
  return (
    <>
      <S.Wrapper>
        <S.DuplicateButton onClick={clickDuplicateButton}>
          Duplicate
        </S.DuplicateButton>
      </S.Wrapper>
      {isModal && (
        <SelectClassificationForDuplicatePress closeModal={closeModal} />
      )}
    </>
  );
};

export default PressDuplicateButton;
