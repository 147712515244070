import styled, { css } from "styled-components";

export const TopFixInfoItemWrapper = styled.div<{ $isDisabled: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.4rem;

  background: #d1e0ff;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      background-color: #f0f2f4;
    `}

  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  border: 1px solid #b1b8c4;
`;

export const TopFixInfoTitle = styled.h6<{ $isDisabled: boolean }>`
  color: #004aeb;
  font-family: ${({ theme }) => theme.font.fontFamily.pretendard};
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 130%;
  padding: 0.3rem 0.4rem;
  display: flex;
  align-items: center;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: #657286;
    `}
`;

export const TopFixInfoContent = styled.div`
  color: #2a2f37;
  font-family: ${({ theme }) => theme.font.fontFamily.pretendard};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 130%;
  padding: 0.3rem 0.4rem;

  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: keep-all;
`;

export const ChangeClassificationButton = styled.button<{
  $isSelected: boolean;
}>`
  display: flex;
  min-height: 2rem;
  height: 100%;
  padding: 0.2rem 0.3rem;
  border-radius: 0.4rem;
  background: #1baefe;
  border: 0.1rem solid #1baefe;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  color: white;

  font-family: ${({ theme }) => theme.font.fontFamily.pretendard};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 130%;
  text-align: left;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden;

  cursor: pointer;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border: 0.1rem solid #1baefe;
      background-color: transparent;
      color: #2a2f37;
    `}

  &:disabled {
    background-color: #cbd0d8;
    color: #2a2f37;
    border: 0.1rem solid #cbd0d8;
    cursor: not-allowed;
  }

  &:hover:not([disabled]) {
    background-color: #0198e9;
    color: white;
    border: 0.1rem solid #0198e9;
  }

  &:active:not([disabled]) {
    background-color: #017abc;
    color: white;
    border: 0.1rem solid #017abc;
  }
`;

export const ButtonText = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden;
`;
