import styled from "styled-components";

import IconClear from "@images/insertImage/icon-search-clear.svg";

export const InputWrapper = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.8rem;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 600;
  color: #657286;

  input {
    min-width: 100%;
    min-height: 3.7rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: #2a2f37;
    line-height: 1.4rem;
    border-radius: 0.4rem;
    border: 0.1rem solid #f0f2f4;
    background-color: #f0f2f4;
    padding: 0rem 0.8rem;
    outline: none;

    &:focus {
      border: 0.1rem solid #0198e9;
      background-color: #ffffff;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
        0px 2px 4px 0px rgba(0, 0, 0, 0.16);
    }

    &::placeholder {
      color: #b1b8c4;
    }

    &:disabled {
      color: #96a1b0;
      border: 0.1rem solid #cbd0d8;
      background-color: #cbd0d8;
    }
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      width: 2rem;
      height: 2rem;
      background-image: url(${IconClear});
      background-size: 2rem 2rem;
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;
    }
  }
`;
