import {
  ParagraphSectionLayout,
  ParagraphTitle,
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
} from "@frontend/common";

import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { PARAGRAPH_CODE } from "@EditArticleContainer/shared/constants/editArticle.constants";
import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import { useUpdateParagraph } from "@EditArticleContainer/shared/hooks/useUpdateParagraph";
import {
  REF_CODE_DEAL_NAME,
  REF_CODE_DEAL_STATUS,
  REF_CODE_DEAL_TYPE,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

import * as S from "./ParagraphDealTypeAndStatus.styles";

const COMMON_EDITABLE_TEXT_STYLE = {
  padding: "0.8rem",
  borderRadius: "0",
};
const VALUE_SHELL_PADDING = "0.2rem";

const PARAGRAPH_TITLE = "Deal Type & Status";

const ParagraphDealTypeAndStatus = () => {
  const paragraph = useParagraphEditedData(
    PARAGRAPH_CODE.TY002.dealTypeAndStatus,
  );
  const [updateParagraph, loadingIndex] = useUpdateParagraph({
    paragraphCode: "TC110",
    sectionCode: "TY002",
  });

  const dealTypeToSplit = [
    "strategic collaboration & license agreement",
    "license agreement & strategic collaboration",
  ];

  const DEAL_TYPE_AND_STATUS_TEXT = {
    dealType:
      createMultiLineText({
        textList: paragraph.text["Deal Type & Status"][
          "Deal Type"
        ].value.flatMap((item) => {
          if (!item.text) return "";
          const transformedDealType = item.text.replaceAll("r&d", "R&D");

          if (dealTypeToSplit.includes(item.text.trim())) {
            return transformedDealType.split(" & ");
          }

          return transformedDealType;
        }),
        parseString: (text) => `- ${text?.trim()}`,
      }) ?? "",
    dealStatus:
      paragraph.text["Deal Type & Status"]["Deal Status"].value.at(0)?.text ??
      "",
    dealName: createMultiLineText({
      textList:
        paragraph.text["Deal Type & Status"]?.["Deal Name"]?.value.map?.(
          (item) => item.text ?? "",
        ) ?? [],
      parseString: (text) => `- ${text?.trim()}`,
    }),
  };

  const DEAL_TYPE_AND_STATUS_LABELS = {
    dealType:
      paragraph.text["Deal Type & Status"]?.["Deal Type"]?.value.at(0)
        ?.labels ?? [],
    dealStatus:
      paragraph.text["Deal Type & Status"]?.["Deal Status"]?.value.at(0)
        ?.labels ?? [],
    dealName:
      paragraph.text["Deal Type & Status"]?.["Deal Name"]?.value.at(0)
        ?.labels ?? [],
  };

  const isExistDealNameText = DEAL_TYPE_AND_STATUS_TEXT.dealName.trim() !== "";

  return (
    <ParagraphSectionLayout>
      <ParagraphTitle title={PARAGRAPH_TITLE} />
      <S.ParagraphDealTypeStatusTableLayoutWrapper>
        <S.ParagraphDealTypeStatusUpdateButton
          disabled={loadingIndex === 0}
          onClick={() => updateParagraph(0)}
        />
        <TableContainer>
          <TableRowContainer>
            <TableShellTitle
              className={`is-display-shell-${isExistDealNameText}`}
            >
              Deal Name
            </TableShellTitle>
            <TableShellTitle>Deal Type</TableShellTitle>
            <TableShellTitle>Deal Status</TableShellTitle>
          </TableRowContainer>
          <TableRowContainer>
            <TableShellValue
              padding={VALUE_SHELL_PADDING}
              className={`is-display-shell-${isExistDealNameText}`}
            >
              <EditableTextContainer
                item={{
                  text: DEAL_TYPE_AND_STATUS_TEXT.dealName,
                  labels: DEAL_TYPE_AND_STATUS_LABELS.dealName,
                }}
                refKey={REF_CODE_DEAL_NAME}
                canTextEdit
                style={COMMON_EDITABLE_TEXT_STYLE}
              />
            </TableShellValue>

            <TableShellValue padding={VALUE_SHELL_PADDING}>
              <EditableTextContainer
                item={{
                  text: DEAL_TYPE_AND_STATUS_TEXT.dealType,
                  labels: DEAL_TYPE_AND_STATUS_LABELS.dealType,
                }}
                refKey={REF_CODE_DEAL_TYPE}
                canTextEdit
                style={COMMON_EDITABLE_TEXT_STYLE}
              />
            </TableShellValue>
            <TableShellValue padding={VALUE_SHELL_PADDING}>
              <EditableTextContainer
                item={{
                  text: DEAL_TYPE_AND_STATUS_TEXT.dealStatus,
                  labels: DEAL_TYPE_AND_STATUS_LABELS.dealStatus,
                }}
                refKey={REF_CODE_DEAL_STATUS}
                canTextEdit
                style={COMMON_EDITABLE_TEXT_STYLE}
              />
            </TableShellValue>
          </TableRowContainer>
        </TableContainer>
      </S.ParagraphDealTypeStatusTableLayoutWrapper>
    </ParagraphSectionLayout>
  );
};

export default ParagraphDealTypeAndStatus;
