import styled from "styled-components";

export const MoveNextButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  height: 4rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #ffffff;
  padding: 0 1.6rem 0 2.4rem;
  background-color: #1baefe;
  border-radius: 0.4rem;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    color: #96a1b0;
    background-color: #cbd0d8;
  }
`;
