import { deepCopy } from "@utils/deepCopy";

import { EditableText, Label } from "../../types/editableText.types";

export class EditArticleText {
  private labelBuffer: Label[] = [];

  private static checkLabelOverlay = (
    startIndex: number,
    endIndex: number,
    prevLabels: Label[],
  ) => {
    /**
      NOTE: "라벨이 겹친다"는 "라벨이 겹치지 않는다"의 반대와 동일하기 때문에, 아래와 같이 표현할 수 있습니다.
      ~은 임의의 문장을 표현합니다.

      예시
      ~~~(startIndex, endIndex)~~~(prevStartIndex, prevEndIndex)~~~
      ~~~(prevStartIndex, prevEndIndex)~~~(startIndex, endIndex)~~~
      위 경우와 반대의 경우에 겹친다고 판단합니다.
    */
    const isOverlay = prevLabels.some(
      ({ position: [prevStartIndex, prevEndIndex] }) =>
        !(endIndex <= prevStartIndex || startIndex >= prevEndIndex),
    );

    return isOverlay;
  };

  private textItemData: EditableText;

  constructor(textItemData: EditableText) {
    this.textItemData = deepCopy(textItemData);
  }

  public pushLabels = (labels: Partial<Label>[]) => {
    labels.forEach(({ entity, value }) => {
      if (entity === undefined || value === undefined) {
        return;
      }

      const { text } = this.textItemData;

      // NOTE: value에 "(", ")"가 포함되어 있을 경우, 정규표현식에서 사용할 수 있도록 escape 처리합니다.
      const parsedText = text
        ?.replaceAll(/[“]/g, '"')
        ?.replaceAll(/[‘]/g, "'")
        ?.replaceAll(/[’]/g, "'");

      const parsedLabelValue = value
        ?.replaceAll(/[“]/g, '"')
        ?.replaceAll(/[‘]/g, "'")
        ?.replaceAll(/[’]/g, "'")
        ?.replaceAll(/([.*+?^${}()|[\]\\])/g, "\\$1"); // TODO: *이 DealType에 표시되어 있는 경우가 있어서 제거하였습니다. 추후 DealType 검수가 완료되면, 제거가 필요합니다. // ?.replaceAll(/([()])/g, "\\$1");
      // ?.replaceAll(/([()])/g, "\\$1");

      // NOTE: 단어 경계를 기준으로 라벨 값을 찾기 위한 정규표현식을 생성합니다.
      const labelPattern = `\\b${parsedLabelValue}\\b`;
      const regExp = new RegExp(labelPattern, "gi");

      let match = regExp.exec(parsedText);

      while (match !== null) {
        const startIndex = match.index;
        const endIndex = match.index + value.length;

        this.labelBuffer.push({
          entity,
          value: match[0],
          position: [startIndex, endIndex],
          type: "Normal",
        });

        match = regExp.exec(parsedText);
      }
    });

    return this;
  };

  public getEditArticleText = () => {
    const sortedLabelBuffer = this.labelBuffer
      .filter(({ value, entity, position }) => value && entity && position)
      .sort((a, b) => (b.value?.length ?? 0) - (a.value?.length ?? 0)); // 위 조건에 의해 value, entity, position은 undefined일 수 없습니다.

    const notOverlayLabels = sortedLabelBuffer.filter(
      ({ position: [startIndex, endIndex] }, index) =>
        !EditArticleText.checkLabelOverlay(
          startIndex,
          endIndex,
          sortedLabelBuffer.slice(0, index),
        ),
    );

    return {
      ...this.textItemData,
      labels: notOverlayLabels,
    };
  };
}
