import { SECTION_CODE_ACCORDING_TO_CLASSIFICATION } from "@frontend/common";

import { useClassificationManual } from "./useArticleInfo";

export const useSectionCodeToClassification = () => {
  const classificationManual = useClassificationManual();

  if (!classificationManual) {
    throw new Error("classificationManual is not defined");
  }

  return SECTION_CODE_ACCORDING_TO_CLASSIFICATION[classificationManual];
};
