import { KeyCommentsContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/keyCommentsContent.types";
import { parseJSON } from "@utils/parseJSON";

import { Quote } from "./quote";
import { Spokesperson } from "./spokesperson";

export class KeyComments {
  public static fromString(keyCommentsData: string) {
    return new KeyComments(parseJSON<KeyCommentsContent>(keyCommentsData));
  }

  private keyCommentsData: KeyCommentsContent;

  constructor(keyCommentsData: KeyCommentsContent) {
    this.keyCommentsData = keyCommentsData;
  }

  public get data() {
    return this.keyCommentsData;
  }

  public get spokesperson() {
    return new Spokesperson(
      this.keyCommentsData.text["Key Comments"].Spokesperson,
    );
  }

  public get spokespersonKey() {
    return `${this.data.updateDt}-spokesperson`;
  }

  public get spokespersonData() {
    return this.spokesperson.data;
  }

  public get quote() {
    return new Quote(this.keyCommentsData.text["Key Comments"].Quote);
  }

  public get quoteKey() {
    return `${this.data.updateDt}-quote`;
  }

  public get quoteData() {
    return this.quote.data;
  }

  public isEmpty() {
    return this.quote.text === "";
  }
}
