import React from "react";

import iconPlus from "@images/fillInTable/tableCreator/icon-plus-white.svg";

import * as S from "./GroupShellDividerPlusBar.style";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isLastPosition: boolean;
  buttonText?: string;
  isVisibility: boolean;
}

const GroupShellDividerPlusBar = ({
  onClick,
  isLastPosition,
  buttonText = "Add Row",
  isVisibility,
  ...rest
}: Props) => {
  return (
    <S.GroupShellDividerWrapper
      isLastPosition={isLastPosition}
      isVisibility={isVisibility}
      onClick={onClick}
      {...rest}
    >
      <S.GroupShellDividerPlusBar className="divider-plus-bar">
        <S.GroupShellPlusButton className="divider-plus-button">
          <img src={iconPlus} alt="add groupShell" />
          {buttonText}
        </S.GroupShellPlusButton>
      </S.GroupShellDividerPlusBar>
    </S.GroupShellDividerWrapper>
  );
};

export default GroupShellDividerPlusBar;
