import React, { useCallback, useEffect } from "react";

import useArticleSection from "@/components/business/FillInTableContainer/hooks/useArticleSection";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { KEY_CLINICAL_TRIALS } from "@FillInTableContainer/components/FillInTable/constants/clinicalTrials.constants";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import {
  DEFAULT_FOCUS_STATUS,
  FocusStatus,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { ShellColumnSize } from "@FillInTableContainer/shared/types/shellSize.types";
import { getShellColumnSize } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useHandleClinicalTrialsDrugProfileShell } from "./useHandleClinicalTrialsDrugProfileShell";
import { useHandleClinicalTrialsEndpointsDescriptionShell } from "./useHandleClinicalTrialsEndpointsDescriptionShell";
import { useHandleClinicalTrialsEndpointsTableShell } from "./useHandleClinicalTrialsEndpointsTableShell";
import { useHandleClinicalTrialsKeyCommentShell } from "./useHandleClinicalTrialsKeyCommentShell";
import { useHandleClinicalTrialsResultsShell } from "./useHandleClinicalTrialsResultsShell";

import type { PositionInfoProps } from "../ClinicalTrialsTableCreatorShell";

interface UseHandleTableCreatorShellReturn {
  handleFocusTextFieldWrapper: (event: React.MouseEvent) => void;
  handleClickToDeleteShell: () => void;
  handleFocusOutShell: () => void;
  handleAddShell: (groupShellInfoParams: {
    tableIndexParameter: number;
    groupShellIndexParameter: number;
    shellIndexParameter: number;
    shellKeyParameter: string;
  }) => void;
  isFocused: boolean;
  isFocusedColumn: boolean;
  isClearShell: boolean;
  isDeleteShell: boolean;
  isDeleteColumn: boolean;
  isEnableEdit: boolean;
  isAlertShell: boolean;
  isLastShell: boolean;
  focusStatus: FocusStatus;
  columnSize: ShellColumnSize;
  isFocusedAddButton: boolean;
  isClearTable: boolean;
  isDeleteTable: boolean;
}

export const useHandleClinicalTrialsTableCreatorShell = (
  positionPropsInfo: PositionInfoProps,
): UseHandleTableCreatorShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
    shellKey,
    shellIndex,
    shellCount,
  } = positionPropsInfo;

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { validatedInfo, changeValidatedInfo } = useValidatedInTableCreator();

  const {
    handleClickToClearDrugProfileShell,
    handleKeyDownToClearDrugProfileShell,
  } = useHandleClinicalTrialsDrugProfileShell(positionPropsInfo);

  const {
    handleAddEndpointsTableShell,
    handleClickToDeleteEndpointsTableShell,
    handleKeyDownToDeleteEndpointsTableShell,
  } = useHandleClinicalTrialsEndpointsTableShell(positionPropsInfo);
  const {
    handleClickToClearEndpointsDescriptionShell,
    handleKeyDownToClearEndpointsDescriptionShell,
  } = useHandleClinicalTrialsEndpointsDescriptionShell(positionPropsInfo);
  const {
    handleClickToClearKeyCommentShell,
    handleKeyDownToClearKeyCommentShell,
  } = useHandleClinicalTrialsKeyCommentShell(positionPropsInfo);
  const { handleClickToClearResultsShell, handleKeyDownToClearResultsShell } =
    useHandleClinicalTrialsResultsShell(positionPropsInfo);

  const articleSection = useArticleSection();

  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );
  const isEnableEdit = articleInfo
    ? ENABLE_GROUP_TABLE_KEY_LIST["Clinical Trials"][groupTableKey].includes(
        articleInfo.draftArticleStatus,
      )
    : false;

  const columnSize = getShellColumnSize(articleSection, shellKey);
  const isLastShell = shellCount === shellIndex + 1;

  const isFocusedColumn =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableIndex === tableIndex &&
    info.tableKey === tableKey &&
    info.groupShellKey === groupShellKey &&
    info.shellKey ===
      (shellKey === "Endpoint Group Information" ? "Group Name" : shellKey) &&
    info.shellIndex === shellIndex &&
    info.subGroupShellIndex === null &&
    info.subGroupShellKey === null &&
    info.valueIndex === null &&
    focusStatus.container === "column" &&
    isEnableEdit;

  const isFocused =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableIndex === tableIndex &&
    info.tableKey === tableKey &&
    info.groupShellKey === groupShellKey &&
    info.groupShellIndex === groupShellIndex &&
    info.shellKey === shellKey &&
    info.shellIndex === shellIndex &&
    isEnableEdit;

  const isTableFocus =
    info.groupTableKey === groupTableKey &&
    info.tableKey === tableKey &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === null &&
    info.groupShellIndex === null &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    isEnableEdit;

  const isClearTable =
    isTableFocus &&
    focusStatus.type === "clear" &&
    focusStatus.container === "table";

  const isDeleteTable =
    isTableFocus &&
    focusStatus.type === "delete" &&
    focusStatus.container === "table";

  const isClearShell =
    isFocused &&
    focusStatus.type === "clear" &&
    focusStatus.container === "shell";

  const isDeleteShell =
    isFocused &&
    focusStatus.type === "delete" &&
    focusStatus.container === "shell";

  const isDeleteColumn =
    isFocusedColumn &&
    focusStatus.type === "delete" &&
    focusStatus.container === "column";

  const isFocusedAddButton =
    focusStatus.container === "noFocus" && isEnableEdit;

  const isAlertShell =
    validatedInfo.filter(
      (validatedItem) =>
        validatedItem.groupTableKey === groupTableKey &&
        validatedItem.groupTableIndex === groupTableIndex &&
        validatedItem.tableIndex === tableIndex &&
        validatedItem.groupShellKey === groupShellKey &&
        validatedItem.groupShellIndex === groupShellIndex &&
        validatedItem.shellKey === shellKey &&
        validatedItem.shellIndex === shellIndex &&
        validatedItem.valueIndex === null &&
        validatedItem.type === "alert" &&
        validatedItem.container === "shell",
    ).length > 0;

  const handleFocusTextFieldWrapper = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (
      focusStatus.type !== "clear" ||
      (focusStatus.type === "clear" && focusStatus.container !== "shell")
    ) {
      changeFocusStatus({
        type: "clear",
        container: "shell",
      });
    }

    changeInfo({
      ...positionPropsInfo,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      valueIndex: null,
    });

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleFocusOutShell = () => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  };

  const handleAddShell = (groupShellInfoParams: {
    tableIndexParameter: number;
    groupShellIndexParameter: number;
    shellIndexParameter: number;
    shellKeyParameter: string;
  }) => {
    if (focusStatus.container !== "noFocus") return;

    if (groupTableKey === KEY_CLINICAL_TRIALS.clinicalEndpointsTable)
      handleAddEndpointsTableShell(groupShellInfoParams);
  };

  const handleClickToDeleteShell = () => {
    switch (info.groupTableKey) {
      case KEY_CLINICAL_TRIALS.drugProfile:
        handleClickToClearDrugProfileShell();
        break;
      case KEY_CLINICAL_TRIALS.clinicalEndpointsTable:
        handleClickToDeleteEndpointsTableShell();
        break;
      case KEY_CLINICAL_TRIALS.clinicalEndpointsDescriptions:
        handleClickToClearEndpointsDescriptionShell();
        break;
      case KEY_CLINICAL_TRIALS.keyComment:
        handleClickToClearKeyCommentShell();
        break;
      case KEY_CLINICAL_TRIALS.clinicalResults:
        handleClickToClearResultsShell();
        break;
      default:
        break;
    }
  };

  const handleKeyDownToDeleteShell = useCallback(
    (event: KeyboardEvent) => {
      switch (info.groupTableKey) {
        case KEY_CLINICAL_TRIALS.drugProfile:
          handleKeyDownToClearDrugProfileShell(event);
          break;
        case KEY_CLINICAL_TRIALS.clinicalEndpointsTable:
          handleKeyDownToDeleteEndpointsTableShell(event);
          break;
        case KEY_CLINICAL_TRIALS.clinicalEndpointsDescriptions:
          handleKeyDownToClearEndpointsDescriptionShell(event);
          break;
        case KEY_CLINICAL_TRIALS.keyComment:
          handleKeyDownToClearKeyCommentShell(event);
          break;
        case KEY_CLINICAL_TRIALS.clinicalResults:
          handleKeyDownToClearResultsShell(event);
          break;
        default:
          break;
      }
    },
    [
      handleKeyDownToClearDrugProfileShell,
      handleKeyDownToClearEndpointsDescriptionShell,
      handleKeyDownToClearKeyCommentShell,
      handleKeyDownToClearResultsShell,
      handleKeyDownToDeleteEndpointsTableShell,
      info.groupTableKey,
    ],
  );

  useEffect(() => {
    if (focusStatus.container !== "shell") return;
    window.addEventListener("keydown", handleKeyDownToDeleteShell);

    return () => {
      window.removeEventListener("keydown", handleKeyDownToDeleteShell);
    };
  }, [focusStatus, handleKeyDownToDeleteShell]);

  return {
    handleFocusOutShell,
    handleFocusTextFieldWrapper,
    handleClickToDeleteShell,
    handleAddShell,
    isClearTable,
    isDeleteTable,
    isFocused,
    isFocusedColumn,
    isClearShell,
    isDeleteShell,
    isDeleteColumn,
    isEnableEdit,
    focusStatus,
    isAlertShell,
    isLastShell,
    columnSize,
    isFocusedAddButton,
  };
};
