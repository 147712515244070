import { ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS } from "../../../constants";

export const CLINICAL_TRIAL_DESIGN_GROUP_TABLE_KEY = "Clinical Trial Design";

export const CLINICAL_TRIAL_DESIGN_TABLE_KEY = "Clinical Trial Design";

export const CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS = {
  clinicalDesign1: "Clinical Design-1",
  clinicalDesign2: "Clinical Design-2",
} as const;

export const CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS = {
  arm: "Arm",
  none: null,
} as const;

export const CLINICAL_TRIAL_DESIGN_1_SHELL_KEYS = {
  phase: ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.clinicalDesignPhase,
  inclusionCriteriaDiagnostic:
    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.inclusionCriteriaDiagnostic,
  inclusionCriteriaDetail:
    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.inclusionCriteriaDetail,
  totalPatientsNumber:
    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.totalPatientsNumber,
  trialName: ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.trialName,
  nctName: ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.nctName,
  trialIntervention:
    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.trialIntervention,
} as const;

export const CLINICAL_TRIAL_DESIGN_2_SHELL_KEYS = {
  armName: ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.armName,
  patientsNumber: ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.patientsNumber,
  designGroupInformation:
    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.designGroupInformation,
} as const;

export const CLINICAL_TRIAL_DESIGN_SUB_SHELL_KEYS = {
  drugName: ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.drugName,
  does: ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.does,
  duration: ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.duration,
} as const;

export const CLINICAL_TRIAL_DESIGN_SHELL_KEYS = {
  phase: ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.clinicalDesignPhase,
  inclusionCriteriaDiagnostic:
    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.inclusionCriteriaDiagnostic,
  inclusionCriteriaDetail:
    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.inclusionCriteriaDetail,
  totalPatientsNumber:
    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.totalPatientsNumber,
  trialName: ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.trialName,
  trialIntervention:
    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.trialIntervention,
  armName: ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.armName,
  patientsNumber: ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.patientsNumber,
  designGroupInformation:
    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_TABLE_KEYS.designGroupInformation,
} as const;
