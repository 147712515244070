"use client";

import styled from "styled-components";

export const NavigationWrapper = styled.div`
  position: sticky;
  top: 5.6rem;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4rem;
  background: var(--Color-Common-Bg-Primary, #fff);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
    0px 1px 2px 0px rgba(0, 0, 0, 0.16);
  padding: 0.4rem 0;
  z-index: 30;
`;

export const NavigationContentsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
  width: calc(100% - 8rem);
  max-width: 120rem;
  height: 100%;
`;
