import React, { useEffect, useRef } from "react";

import { LICENSE_AGREEMENT_TABLE_KEYS } from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import { useSellerAndBuyerByDealTypeQuery } from "@api/sellerAndBuyerByDealType/sellerAndBuyerByDealType";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import {
  TableCreatorTableTitle,
  TableDividerPlusBar,
} from "@FillInTableContainer/shared";
import { useCurrentDealTypeValue } from "@FillInTableContainer/shared/context/CurrentDealTypeContext";
import {
  GroupTableKeyType,
  TableCreatorGroupShellSectionList,
} from "@FillInTableContainer/shared/types/tableCreator.types";
import { isAddDeleteTable } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { padStart } from "@utils/padStart/padStart";

import DealTermsLicenseAgreementGroupShellSection from "./components/DealTermsLicenseAgreementGroupShellSection/DealTermsLicenseAgreementGroupShellSection";
import * as S from "./DealTermsLicenseAgreementTable.style";
import { useHandleLicenseAgreementTableCreatorTable } from "./hooks/useHandleLicenseAgreementTableCreatorTable";

export interface TableInfoProps {
  groupTableKey: DealsSection;
  tableKey: string | null;
  groupShellSectionList: TableCreatorGroupShellSectionList;
  groupTableIndex: number;
  tableIndex: number;
  tableCount: number;
}
interface Props extends TableInfoProps {
  handleAddTable: (tableIndex: number) => void;
  handleDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => void;
  handleDragEnd: (groupTableKey: GroupTableKeyType) => void;
  handleDragOn: () => void;
  isDraggableTable: boolean;
}

const DealTermsLicenseAgreementTable = ({
  groupShellSectionList,
  groupTableIndex,
  groupTableKey,
  tableCount,
  tableIndex,
  tableKey,
  handleAddTable,
  handleDragEnd,
  handleDragOn,
  handleDragStart,
  isDraggableTable,
}: Props) => {
  const tableTitleRef = useRef<HTMLDivElement>(null);
  const articleSection = useArticleSection();
  const { currentDealTypeList } = useCurrentDealTypeValue();
  const { data: sellerBuyerInfo } =
    useSellerAndBuyerByDealTypeQuery(currentDealTypeList);

  const tablePropsInfo: TableInfoProps = {
    groupTableKey,
    tableKey,
    groupTableIndex,
    tableIndex,
    tableCount,
    groupShellSectionList,
  };

  const sellerByDealType = sellerBuyerInfo.data.seller;
  const buyerByDealType = sellerBuyerInfo.data.buyer;

  const customTableTitle = () => {
    if (tableKey === LICENSE_AGREEMENT_TABLE_KEYS.additionalInformation)
      return "Additional Information";

    if (tableKey === LICENSE_AGREEMENT_TABLE_KEYS.licensor)
      return sellerByDealType;

    if (tableKey === LICENSE_AGREEMENT_TABLE_KEYS.licensee)
      return buyerByDealType;

    return "";
  };

  const {
    focusStatus,
    isFocused,
    isLastTable,
    isFocusedAddButton,
    handleClickDeleteButton,
    focusEventToChangePositionInfo,
    changeFocusStatus,
    changePositionInfo,
    changePositionInfoToDefault,
    handleChangeDefaultFocus,
  } = useHandleLicenseAgreementTableCreatorTable(tablePropsInfo);

  const isClearTable =
    isFocused &&
    focusStatus.container === "table" &&
    focusStatus.type === "clear";

  const isDeleteTable =
    isFocused &&
    focusStatus.container === "table" &&
    focusStatus.type === "delete";

  const title =
    tableCount > 1
      ? `${getTableTitle(articleSection, tableKey)} ${padStart(tableIndex + 1)}`
      : getTableTitle(articleSection, tableKey);

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;

        const clickSelectButton = tableTitleRef.current?.contains(target);

        if (clickSelectButton) return;
        if (target.dataset.button) return;

        handleChangeDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleChangeDefaultFocus, isFocused]);

  const handleFocusTableTitle = () => {
    focusEventToChangePositionInfo({
      type: "clear",
      container: "table",
    });
  };

  return (
    <>
      {isAddDeleteTable(articleSection, tableKey) && (
        <TableDividerPlusBar
          tableTitle={getTableTitle(articleSection, tableKey) || ""}
          isLastPosition={false}
          onClick={() => handleAddTable(tableIndex)}
          isVisibility={isFocusedAddButton}
        />
      )}
      <S.TableWrapper
        isFocused={isFocused}
        isClearTable={isClearTable}
        isDeleteTable={isDeleteTable}
        isDraggingTable={isFocused && focusStatus.type === "dragging"}
      >
        {(isClearTable || isDeleteTable) && (
          <S.DeleteTableButtonWrapper>
            <S.DeleteTableButton
              data-button="delete-button"
              onClick={handleClickDeleteButton}
            >
              <img src={IconXMark} alt="button for clear table" />
              {focusStatus.type === "clear" ? `Clear ${title} Table` : null}
              {focusStatus.type === "delete" ? `Delete ${title} Table` : null}
            </S.DeleteTableButton>
          </S.DeleteTableButtonWrapper>
        )}
        <div ref={tableTitleRef}>
          {getTableTitle(articleSection, tableKey) && (
            <TableCreatorTableTitle
              tableKey={tableKey}
              groupTableKey={groupTableKey}
              groupTableIndex={groupTableIndex}
              tableIndex={tableIndex}
              isDragging={isFocused && focusStatus.type === "dragging"}
              onClickHandler={handleFocusTableTitle}
              handleDragStart={handleDragStart}
              handleDragEnd={handleDragEnd}
              handleDragOn={handleDragOn}
              isDraggableTable={isDraggableTable}
              changeFocusStatusForDragging={() => {
                changePositionInfo();
                changeFocusStatus({
                  type: "dragging",
                  container: "table",
                });
              }}
              changeFocusStatusForDragEnd={() => {
                changePositionInfoToDefault();
                changeFocusStatus({
                  type: "default",
                  container: "noFocus",
                });
              }}
              tableTitle={customTableTitle()}
            />
          )}
        </div>
        {groupShellSectionList.map(
          (
            { groupShellSectionKey, groupShellList },
            groupShellSectionIndex,
          ) => (
            <DealTermsLicenseAgreementGroupShellSection
              key={`group-shell-${String(groupTableIndex)}-${String(
                tableIndex,
              )}-${String(groupShellSectionIndex)}`}
              groupShellList={groupShellList}
              groupTableKey={groupTableKey}
              groupTableIndex={groupTableIndex}
              tableKey={tableKey}
              tableIndex={tableIndex}
              groupShellSectionKey={groupShellSectionKey}
              groupShellSectionIndex={groupShellSectionIndex}
              sellerByDealType={sellerByDealType}
              buyerByDealType={buyerByDealType}
            />
          ),
        )}
      </S.TableWrapper>
      {isAddDeleteTable(articleSection, tableKey) && (
        <TableDividerPlusBar
          tableTitle={getTableTitle(articleSection, tableKey) || ""}
          onClick={() => handleAddTable(tableIndex + 1)}
          isLastPosition
          isVisibility={isLastTable && isFocusedAddButton}
        />
      )}
    </>
  );
};

export default DealTermsLicenseAgreementTable;
