import { DealsSection } from "@/types/deals.types";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import {
  DEFAULT_FOCUS_STATUS,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  DEFAULT_VALIDATED_INFO,
  useValidatedInTableCreator,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useGetNumberOfGroupShell } from "@FillInTableContainer/shared/hooks/useGetNumberOfGroupShell";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import useDealTermsLicenseeAgreementGroupShellSection from "./useDealTermsLicenseeAgreementGroupShellSection";

export interface GroupShellSectionInfoProps {
  groupTableKey: DealsSection;
  tableKey: string | null;
  groupTableIndex: number;
  tableIndex: number;
  groupShellSectionKey: string | null;
  groupShellSectionIndex: number;
}

const ARTICLE_SECTION = "Deals";

const useDealTermsLicenseeAgreementTableCreatorGroupShellSection = (
  groupShellSectionInfoProps: GroupShellSectionInfoProps,
) => {
  const {
    groupTableKey,
    tableKey,
    groupTableIndex,
    tableIndex,
    groupShellSectionIndex,
    groupShellSectionKey,
  } = groupShellSectionInfoProps;

  const { groupShellCount } = useGetNumberOfGroupShell();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );

  const { handleClickToClearLicenseeTableGroupShellSection } =
    useDealTermsLicenseeAgreementGroupShellSection(groupShellSectionInfoProps);

  const numberOfGroupShellCount = (groupShellKey: string | null) =>
    groupShellCount(groupShellKey, tableIndex);

  const handleFocusGroupShellSection = () => {
    changeInfo({
      ...groupShellSectionInfoProps,
      groupShellIndex: null,
      groupShellKey: null,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
    changeFocusStatus({ type: "clear", container: "groupShellSection" });
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleClickToClearGroupShellSection = () => {
    handleClickToClearLicenseeTableGroupShellSection();
  };

  const handleDefaultFocus = () => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const isEnableEdit = articleInfo
    ? ENABLE_GROUP_TABLE_KEY_LIST[ARTICLE_SECTION][groupTableKey].includes(
        articleInfo.draftArticleStatus,
      )
    : false;

  const isGroupShellSectionFocus =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableKey === tableKey &&
    info.tableIndex === tableIndex &&
    info.groupShellSectionKey === groupShellSectionKey &&
    info.groupShellSectionIndex === groupShellSectionIndex &&
    info.groupShellKey === null &&
    info.groupShellIndex === null &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    isEnableEdit;

  const isClearGroupShellSection =
    isGroupShellSectionFocus &&
    focusStatus.container === "groupShellSection" &&
    focusStatus.type === "clear";

  return {
    numberOfGroupShellCount,
    handleFocusGroupShellSection,
    handleClickToClearGroupShellSection,
    handleDefaultFocus,
    isGroupShellSectionFocus,
    isClearGroupShellSection,
  };
};

export default useDealTermsLicenseeAgreementTableCreatorGroupShellSection;
