import { ClassificationCode } from "@/types/code.types";
import { CLASSIFICATION_CODE_FROM_NAME } from "@PressListContainer/classificationInfo.constants";

import { AUTHORITY_TYPE } from "../constants/authorityType.constants";

import type { AuthorityType, EditorRoleCode } from "../types/roles.types";

const ALLOWED_EDITOR_ROLES_AND_CLASSIFICATION: Partial<
  Record<EditorRoleCode, ClassificationCode[]>
> = {
  EDTK0204: [CLASSIFICATION_CODE_FROM_NAME["clinical, preclinical, R&D"]],
  EDTK0304: [CLASSIFICATION_CODE_FROM_NAME["clinical trial status"]],
  EDTK0404: [CLASSIFICATION_CODE_FROM_NAME["conference, poster contents"]],
  EDTK0504: [CLASSIFICATION_CODE_FROM_NAME["deal, cooperation"]],
  EDTK0604: [CLASSIFICATION_CODE_FROM_NAME["expedited program"]],
  EDTK0704: [CLASSIFICATION_CODE_FROM_NAME["approval process"]],
  EDTK0804: [CLASSIFICATION_CODE_FROM_NAME["venture capital"]],
};

interface Args {
  editorRoles: EditorRoleCode[];
  authorityType: AuthorityType;
  classification: ClassificationCode;
}

export class InsertImageRoleChecker {
  private editorRoles: EditorRoleCode[];

  private authorityType: AuthorityType;

  private classification: ClassificationCode;

  constructor({ editorRoles, authorityType, classification }: Args) {
    this.editorRoles = editorRoles;
    this.authorityType = authorityType;
    this.classification = classification;
  }

  private get hasValidRoles(): boolean {
    return this.editorRoles.some((role) =>
      ALLOWED_EDITOR_ROLES_AND_CLASSIFICATION[role]?.includes(
        this.classification,
      ),
    );
  }

  private get isSuperAdmin(): boolean {
    return this.authorityType === AUTHORITY_TYPE.superAdmin;
  }

  public get canAccess(): boolean {
    return this.isSuperAdmin || this.hasValidRoles;
  }
}
