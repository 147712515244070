import { ARTICLE_CONTENTS_DEAL_TERMS_KEYS as DEAL_TERMS_KEY } from "@frontend/common";

import {
  ContingentPayment,
  DealTermsContent,
  RightsScope,
} from "@EditArticleContainer/shared/types/paragraphContents/contents/dealTermsContent.types";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

export const acquiredCompanyNameText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEY.dealTerms][DEAL_TERMS_KEY.licensorTable][
        DEAL_TERMS_KEY.licensorName
      ]?.value?.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const acquiredCompanyNameLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEY.dealTerms][DEAL_TERMS_KEY.licensorTable][
    DEAL_TERMS_KEY.licensorName
  ].value.at(0)?.labels ?? [];

export const acquiredCompanyResponsibilityText = (
  dealTerms: DealTermsContent,
) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEY.dealTerms][DEAL_TERMS_KEY.licensorTable][
        DEAL_TERMS_KEY.licensorResponsibilityRights
      ]?.value?.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const acquiredCompanyResponsibilityLabels = (
  dealTerms: DealTermsContent,
) =>
  dealTerms.text[DEAL_TERMS_KEY.dealTerms][DEAL_TERMS_KEY.licensorTable][
    DEAL_TERMS_KEY.licensorResponsibilityRights
  ]?.value?.at(0)?.labels ?? [];

export const acquiringCompanyNameText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEY.dealTerms][DEAL_TERMS_KEY.licenseeTable]
        ?.at(0)
        ?.[DEAL_TERMS_KEY.licenseeName]?.value?.map(
          (item) => item.text ?? "",
        ) ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const acquiringCompanyNameLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEY.dealTerms][DEAL_TERMS_KEY.licenseeTable]
    .at(0)
    ?.[DEAL_TERMS_KEY.licenseeName]?.value.at(0)?.labels ?? [];

export const acquiringCompanyResponsibilityText = (
  dealTerms: DealTermsContent,
) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEY.dealTerms][DEAL_TERMS_KEY.licenseeTable]
        .at(0)
        ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms][
          DEAL_TERMS_KEY.licenseeResponsibilityRightsMa
        ]?.value?.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const acquiringCompanyResponsibilityLabels = (
  dealTerms: DealTermsContent,
) =>
  dealTerms.text[DEAL_TERMS_KEY.dealTerms][DEAL_TERMS_KEY.licenseeTable]
    .at(0)
    ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms][
      DEAL_TERMS_KEY.licenseeResponsibilityRightsMa
    ]?.value?.at(0)?.labels ?? [];

export const totalPriceText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEY.dealTerms]?.[DEAL_TERMS_KEY.licenseeTable]
        ?.at(0)
        ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
          DEAL_TERMS_KEY.totalPaymentMa
        ]?.value?.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
    bulletStartIndex: 1,
  });

export const totalPriceLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEY.dealTerms]?.[DEAL_TERMS_KEY.licenseeTable]
    ?.at(0)
    ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
      DEAL_TERMS_KEY.totalPaymentMa
    ]?.value?.at(0)?.labels ?? [];

export const pricePerShareText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEY.dealTerms]?.[DEAL_TERMS_KEY.licenseeTable]
        ?.at(0)
        ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
          DEAL_TERMS_KEY.pricePerShare
        ]?.value?.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
    bulletStartIndex: 1,
  });

export const pricePerShareLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEY.dealTerms]?.[DEAL_TERMS_KEY.licenseeTable]
    ?.at(0)
    ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
      DEAL_TERMS_KEY.pricePerShare
    ]?.value?.at(0)?.labels ?? [];

export const numberOfAcquiringSharesText = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEY.dealTerms]?.[DEAL_TERMS_KEY.licenseeTable]
    ?.at(0)
    ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
      DEAL_TERMS_KEY.numberOfAcquiringShares
    ]?.value?.at(0)?.text ?? "";

export const numberOfAcquiringSharesLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEY.dealTerms]?.[DEAL_TERMS_KEY.licenseeTable]
    ?.at(0)
    ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
      DEAL_TERMS_KEY.numberOfAcquiringShares
    ]?.value?.at(0)?.labels ?? [];

export const otherFinancialTermsText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEY.dealTerms]?.[DEAL_TERMS_KEY.licenseeTable]
        ?.at(0)
        ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
          DEAL_TERMS_KEY.otherFinancialTermsMa
        ].value.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const otherFinancialTermsLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEY.dealTerms]?.[DEAL_TERMS_KEY.licenseeTable]
    ?.at(0)
    ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
      DEAL_TERMS_KEY.otherFinancialTermsMa
    ]?.value?.at(0)?.labels ?? [];

export const mergerFinancialTermsText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEY.dealTerms]?.[DEAL_TERMS_KEY.licenseeTable]
        ?.at(0)
        ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
          DEAL_TERMS_KEY.mergerFinancialTermsMa
        ]?.value?.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const mergerFinancialTermsLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEY.dealTerms]?.[DEAL_TERMS_KEY.licenseeTable]
    ?.at(0)
    ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
      DEAL_TERMS_KEY.mergerFinancialTermsMa
    ]?.value?.at(0)?.labels ?? [];

export const keyAssetsText = (
  rowIndex: number,
  targetRightsScope: RightsScope[],
) =>
  createMultiLineText({
    textList:
      targetRightsScope
        ?.at(rowIndex)
        ?.[DEAL_TERMS_KEY.drug]?.at(0)
        ?.value?.map((drug) => drug.text?.trim() ?? "") || [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const indicationText = (
  rowIndex: number,
  targetRightsScope: RightsScope[],
) =>
  createMultiLineText({
    textList:
      targetRightsScope
        ?.at(rowIndex)
        ?.[DEAL_TERMS_KEY.indication]?.at(0)
        ?.value?.map((indication) => indication.text?.trim() ?? "") || [],
    parseString: (text) => `- ${text?.trim()}`,
    bulletStartIndex: 1,
  });

export const keyAssetsLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEY.dealTerms][DEAL_TERMS_KEY.licenseeTable]
    .at(0)
    ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms][
      DEAL_TERMS_KEY.keyAssetsAndIndication
    ]?.value?.at(0)?.labels ?? [];

export const upfrontPaymentText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEY.dealTerms]?.[DEAL_TERMS_KEY.licenseeTable]
        ?.at(0)
        ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
          DEAL_TERMS_KEY.upfrontPaymentMa
        ]?.value?.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
    bulletStartIndex: 1,
  });

export const upfrontPaymentLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEY.dealTerms]?.[DEAL_TERMS_KEY.licenseeTable]
    ?.at(0)
    ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
      DEAL_TERMS_KEY.upfrontPaymentMa
    ]?.value?.at(0)?.labels ?? [];

export const upfrontPricePerShareText = (dealTerms: DealTermsContent) =>
  createMultiLineText({
    textList:
      dealTerms.text[DEAL_TERMS_KEY.dealTerms]?.[DEAL_TERMS_KEY.licenseeTable]
        ?.at(0)
        ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
          DEAL_TERMS_KEY.upfrontPricePerShareMa
        ]?.value?.map((item) => `${item?.text}` ?? "") ?? [],

    parseString: (text) => `- ${text?.trim()}`,
    bulletStartIndex: 1,
  });

export const upfrontPricePerShareLabels = (dealTerms: DealTermsContent) =>
  dealTerms.text[DEAL_TERMS_KEY.dealTerms]?.[DEAL_TERMS_KEY.licenseeTable]
    ?.at(0)
    ?.[DEAL_TERMS_KEY.mergeAcquisitionTerms]?.[
      DEAL_TERMS_KEY.upfrontPricePerShareMa
    ]?.value?.at(0)?.labels ?? [];

export const contingentPaymentText = (
  rowIndex: number,
  targetContingent: ContingentPayment[],
) =>
  createMultiLineText({
    textList:
      targetContingent
        ?.at(rowIndex)
        ?.[DEAL_TERMS_KEY.contingentPaymentMa]?.value?.map(
          (contingentPayment) => contingentPayment.text?.trim() ?? "",
        ) || [],
    parseString: (text) => `- ${text?.trim()}`,
    bulletStartIndex: 1,
  });

export const contingentPaymentLabels = (
  rowIndex: number,
  targetContingent: ContingentPayment[],
) =>
  targetContingent
    ?.at(rowIndex)
    ?.[DEAL_TERMS_KEY.contingentPaymentMa]?.value?.at(0)?.labels ?? [];

export const contingentPricePerShareText = (
  rowIndex: number,
  targetContingent: ContingentPayment[],
) =>
  createMultiLineText({
    textList:
      targetContingent
        ?.at(rowIndex)
        ?.[DEAL_TERMS_KEY.contingentPricePerShareMa]?.value?.map(
          (contingentPricePerShare) =>
            `${contingentPricePerShare.text?.trim()}` ?? "",
        ) || [],
    parseString: (text) => `- ${text?.trim()}`,
    bulletStartIndex: 1,
  });

export const contingentPricePerShareLabels = (
  rowIndex: number,
  targetContingent: ContingentPayment[],
) =>
  targetContingent
    ?.at(rowIndex)
    ?.[DEAL_TERMS_KEY.contingentPricePerShareMa]?.value?.at(0)?.labels ?? [];

export const contingentMilestoneText = (
  rowIndex: number,
  targetContingent: ContingentPayment[],
) =>
  createMultiLineText({
    textList:
      targetContingent
        ?.at(rowIndex)
        ?.[DEAL_TERMS_KEY.contingentMilestoneMa]?.value?.map(
          (contingentPayment) => contingentPayment.text?.trim() ?? "",
        ) || [],
    parseString: (text) => `- ${text?.trim()}`,
    bulletStartIndex: 1,
  });

export const contingentMilestoneLabels = (
  rowIndex: number,
  targetContingent: ContingentPayment[],
) =>
  targetContingent
    ?.at(rowIndex)
    ?.[DEAL_TERMS_KEY.contingentMilestoneMa]?.value?.at(0)?.labels ?? [];
