import styled from "styled-components";

import {
  BorderWrapper,
  DefaultDeleteTableButton,
  DefaultDeleteTableButtonWrapper,
  DefaultShellWrapper,
} from "@FillInTableContainer/shared/styles/shell.style";

export const ShellWrapper = styled(DefaultShellWrapper)``;

export const ShellBorderWrapper = styled(BorderWrapper)``;
export const DeleteTableButtonWrapper = styled(
  DefaultDeleteTableButtonWrapper,
)``;
export const DeleteTableButton = styled(DefaultDeleteTableButton)``;

export const ModalityShellRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 0.8rem;
`;

export const ModalityShellColumn = styled.div<{ $isMultipleValue?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;

  &:first-child {
    max-width: ${({ $isMultipleValue }) =>
      $isMultipleValue ? "25.9rem" : "27.4rem"};
    .filled-text-field {
      height: 100%;
    }
  }

  &.is-modality-shell-value-empty-true {
    max-width: none;
  }
`;
