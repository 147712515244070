import { Suspense, useEffect, useRef } from "react";

import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { usePressClassificationRoles } from "@libs/roles/hooks/usePressClassificationRoles";
import { usePressIdFromURL } from "@utils/getStateFromURL";

import PressUnsuitableReasonContainer from "./components/PressUnsuitableReasonContainer/PressUnsuitableReasonContainer";
import * as S from "./UnsuitableButton.style";
import { useHandleUnsuitablePress } from "../../hooks/useHandleUnsuitablePress";

const UnsuitableButton = () => {
  const draftArticleId = usePressIdFromURL();
  const { data: articleInfo } = useArticleInfoQuery(Number(draftArticleId));
  const unsuitableRef = useRef<HTMLDivElement>(null);

  const { hasUnsuitablePressRoles } = usePressClassificationRoles();

  const duplArticleId = articleInfo?.duplArticleId || null;
  const articleRejectReason = articleInfo?.articleRejectReason || null;
  const isRejectedReason = !!articleRejectReason;

  const targetRejectReason = articleRejectReason
    ? `${articleRejectReason}${duplArticleId ? ` (${duplArticleId})` : ""}`
    : null;

  const {
    openModalUnsuitablePress,
    closeModalUnsuitablePress,
    isOpenModalUnsuitablePress,
  } = useHandleUnsuitablePress();

  const handleUnsuitableButtonActive = async () => {
    if (isOpenModalUnsuitablePress) return closeModalUnsuitablePress();

    openModalUnsuitablePress();
  };

  useEffect(() => {
    const handleCheckClickOutsideUnsuitable = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const clickReasonButton = unsuitableRef.current?.contains(target);
      const deleteReasonButton = target.dataset.button;

      if (clickReasonButton) return;
      if (deleteReasonButton) return;

      closeModalUnsuitablePress();
    };

    window.addEventListener("click", handleCheckClickOutsideUnsuitable);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideUnsuitable);
  }, [closeModalUnsuitablePress]);

  return (
    <>
      <S.UnsuitableButtonWrapper
        ref={unsuitableRef}
        isEnableUnsuitablePress={isRejectedReason}
        disabled={!hasUnsuitablePressRoles}
      >
        <S.UnsuitableButton
          type="button"
          onClick={handleUnsuitableButtonActive}
          isEnableUnsuitablePress={isRejectedReason}
          disabled={!hasUnsuitablePressRoles}
        >
          <>{isRejectedReason ? targetRejectReason : "The Unsuitable Press"}</>
        </S.UnsuitableButton>
        <Suspense fallback={<></>}>
          <PressUnsuitableReasonContainer
            closeModalUnsuitablePress={closeModalUnsuitablePress}
            isOpenModalUnsuitablePress={isOpenModalUnsuitablePress}
            articleRejectReason={articleRejectReason}
          />
        </Suspense>
      </S.UnsuitableButtonWrapper>
    </>
  );
};

export default UnsuitableButton;
