"use client";

import styled, { css } from "styled-components";

export const AbsoluteWrapper = styled.div`
  position: relative;
  display: none;

  @media only screen and (min-width: 990px) and (max-width: 1439px) {
    display: block;
  }
`;

export const SearchButton = styled.button<{ $isInputOpen: boolean }>`
  display: flex;
  width: 3.2rem;
  height: 3.2rem;
  justify-content: center;
  align-items: center;
  border-radius: var(--Border-Radius-circle, 99999.9rem);
  background: var(--Color-Common-Bg-Interactive-Secondary, #fff);

  &:hover {
    background: var(--Color-Common-Bg-Interactive-Secondary-Hovered, #f0f2f4);

    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);

    svg {
      path {
        fill: #017abc;
      }
    }
  }

  &:active {
    background: var(--Color-Common-Bg-Interactive-Secondary-Pressed, #e5e7eb);

    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset,
      0px 0px 2px 0px rgba(0, 0, 0, 0.06) inset;

    svg {
      path {
        fill: #015d8e;
      }
    }
  }

  ${({ $isInputOpen }) =>
    $isInputOpen &&
    css`
      background: var(--Color-Common-Bg-Interactive-Primary, #1baefe);

      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08) inset,
        0px 0px 1px 0px rgba(0, 0, 0, 0.06) inset;

      svg {
        path {
          fill: #ffffff;
        }
      }
    `}
`;

export const InputWrapper = styled.div`
  position: absolute;
  display: flex;
  max-width: 120rem;
  padding: var(--Spacing-8, 0.8rem);
  top: 4.3rem;

  border-radius: 0rem 0rem var(--Border-Radius-6, 0.6rem)
    var(--Border-Radius-6, 0.6rem);
  background: var(--Color-Common-Bg-Primary, #fff);

  /* Elevation/Light/Shadow4 */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  z-index: 10;
`;
