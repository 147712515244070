"use client";

import styled, { css } from "styled-components";

type KeyCommentsTypeProps = {
  $type: "single" | "insideTable";
};

export const KeyCommentWrapper = styled.div<KeyCommentsTypeProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;

  ${({ $type }) =>
    $type === "insideTable" &&
    css`
      gap: 0.8rem;
    `}
`;

export const SpokespersonWrapper = styled.div<KeyCommentsTypeProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 9.2rem;
  padding: 0.2rem 0;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    min-height: 6.8rem;
  }

  ${({ $type }) =>
    $type === "insideTable" &&
    css`
      min-height: 4.8rem;
    `}
`;

export const ProfileImageWrapper = styled.div<KeyCommentsTypeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.8rem;
  height: 8.8rem;

  ${({ $type }) =>
    $type === "insideTable" &&
    css`
      width: 4.8rem;
      height: 4.8rem;

      svg {
        width: 4.8rem;
        height: 4.8rem;
      }
    `}
`;

export const SpokespersonText = styled.div<KeyCommentsTypeProps>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 2rem;
  font-weight: 500;
  line-height: 160%;

  ${({ $type }) =>
    $type === "insideTable" &&
    css`
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 145%;
    `}
`;

export const QuoteWrapper = styled.div<KeyCommentsTypeProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 2rem;
  font-weight: 500;
  line-height: 160%;

  padding: 3.2rem 4.4rem;
  border-radius: var(--Border-Radius-16, 1.6rem);
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    line-height: 140%;
    padding: 3.2rem 4rem;
  }

  ${({ $type }) =>
    $type === "insideTable" &&
    css`
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 145%;
      padding: 1.2rem 2.7rem;
      border-radius: 0.8rem;
    `}
`;

export const IconRectangleWrapper = styled.div<KeyCommentsTypeProps>`
  position: absolute;
  top: -2.2rem;
  left: 2.2rem;
  width: 4.4rem;
  height: 2rem;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    top: -2.1rem;
  }

  ${({ $type }) =>
    $type === "insideTable" &&
    css`
      width: 2.4rem;
      height: 1rem;
      top: -1.4rem;
      left: 1.2rem;

      svg {
        width: 2.4rem;
        height: 1rem;
      }
    `}
`;

export const IconQuotesLeftWrapper = styled.div<KeyCommentsTypeProps>`
  position: absolute;
  top: 1.6rem;
  left: 1.6rem;
  width: 2rem;
  height: 1.6rem;

  ${({ $type }) =>
    $type === "insideTable" &&
    css`
      top: 0.8rem;
      left: 0.8rem;
      svg {
        width: 1.5rem;
        height: 1.2rem;
      }
    `}
`;

export const IconQuotesRightWrapper = styled.div<KeyCommentsTypeProps>`
  position: absolute;
  bottom: 1.6rem;
  right: 1.6rem;
  width: 2rem;
  height: 1.6rem;
  transform: rotate(180deg);

  ${({ $type }) =>
    $type === "insideTable" &&
    css`
      right: 0.8rem;
      bottom: 0.8rem;
      svg {
        width: 1.5rem;
        height: 1.2rem;
      }
    `}
`;
