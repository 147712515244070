import {
  ParagraphSectionLayout,
  ParagraphTitle,
  PARAGRAPH_CODE,
  DEALS_PARAGRAPH_CODE,
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
} from "@frontend/common";

import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import { useUpdateParagraph } from "@EditArticleContainer/shared/hooks/useUpdateParagraph";
import { REF_CODE_ADDITIONAL_INFORMATION } from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

import * as S from "./ParagraphAdditionalInformation.styles";

const COMMON_EDITABLE_TEXT_STYLE = {
  padding: "0.8rem",
  borderRadius: "0",
};
const VALUE_SHELL_PADDING = "0.2rem";

const ParagraphAdditionalInformation = () => {
  const title = "Additional Information";

  const paragraph = useParagraphEditedData(
    PARAGRAPH_CODE.TY002.additionalInformation,
  );
  const [updateParagraph, loadingIndex, updateKeyMap] = useUpdateParagraph({
    paragraphCode: DEALS_PARAGRAPH_CODE.additionalInformation,
    sectionCode: "TY002",
  });

  const additionalInformationText = createMultiLineText({
    textList: paragraph.text["Additional Information"][
      "Additional Information"
    ].value.map((shell) => shell.text ?? ""),
    parseString: (text) => `- ${text?.trim()}`,
  });

  const additionalInformationLabels =
    paragraph.text["Additional Information"]["Additional Information"].value.at(
      0,
    )?.labels ?? [];

  if (additionalInformationText.trim() === "") {
    return null;
  }

  return (
    <ParagraphSectionLayout>
      <ParagraphTitle title={title} />
      <S.ParagraphAdditionalInformationWrapper key={updateKeyMap.get(0)}>
        <S.ParagraphAdditionalInformationUpdateButton
          onClick={() => updateParagraph(0)}
          disabled={loadingIndex === 0}
        />
        <TableContainer>
          <TableRowContainer>
            <TableShellTitle>{title}</TableShellTitle>
          </TableRowContainer>
          <TableRowContainer>
            <TableShellValue padding={VALUE_SHELL_PADDING}>
              <EditableTextContainer
                refKey={REF_CODE_ADDITIONAL_INFORMATION}
                item={{
                  text: additionalInformationText,
                  labels: additionalInformationLabels,
                }}
                canTextEdit
                style={COMMON_EDITABLE_TEXT_STYLE}
              />
            </TableShellValue>
          </TableRowContainer>
        </TableContainer>
      </S.ParagraphAdditionalInformationWrapper>
    </ParagraphSectionLayout>
  );
};

export default ParagraphAdditionalInformation;
