"use client";

import styled from "styled-components";

export const AccordionText = styled.span`
  color: ${({ theme }) => theme.color.node.sky};
  font-family: ${({ theme }) => theme.font.fontFamily.pretendard};
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 165%;

  &:hover {
    text-decoration-line: underline;
  }
`;

export const AccordionButton = styled.button`
  padding: 0;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  cursor: pointer;
`;
