import React from "react";

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const IconMinus = ({ width = 24, height = 24, color = "#2A2F37" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16797 11.375C5.82279 11.375 5.54297 11.6548 5.54297 12C5.54297 12.3452 5.82279 12.625 6.16797 12.625H17.8346C18.1798 12.625 18.4596 12.3452 18.4596 12C18.4596 11.6548 18.1798 11.375 17.8346 11.375H6.16797Z"
        fill={color}
      />
    </svg>
  );
};

IconMinus.displayName = "Minus";
export default IconMinus;
