import styled, { css } from "styled-components";

import { ProcessStatusCode } from "@/types/code.types";

export const ButtonCompleteStatus = styled.button`
  color: ${({ theme }) => theme.color.neural.gray08};
  font-weight: 300;
  font-size: 1.2rem;
  text-decoration: underline;
  cursor: pointer;
`;

export const StatusIcon = styled.span<{
  code: ProcessStatusCode | null;
  disabled?: boolean;
  onClick?: () => void;
}>`
  position: relative;
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  ${({ code, disabled }) =>
    css`
      cursor: ${disabled ? "not-allowed" : "pointer"};
      &:hover {
        .tooltip {
          display: block;
        }
      }
      & .tooltip {
        color: ${({ theme }) => code && theme.status[code]};

        span {
          opacity: ${disabled ? 0.5 : 1};
        }
      }
    `}

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const StatusTooltip = styled.div`
  display: none;
  position: absolute;
  bottom: calc(100% + 1.2rem);
  left: 50%;
  z-index: 10;
  white-space: pre;
  text-align: left;
  padding: 0.4rem 0.8rem;
  border-radius: var(--Border-Radius-8, 0.8rem) var(--Border-Radius-8, 0.8rem)
    var(--Border-Radius-8, 0.8rem) var(--Border-Radius-0, 0rem);
  background: #fff;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  box-shadow: 0px 4px 8px 0px rgba(7, 68, 161, 0.08);

  &::before {
    position: absolute;
    bottom: -1rem;
    left: 0;
    z-index: 11;
    display: block;
    border-top: 0.5rem solid ${({ theme }) => theme.color.neural.white};
    border-right: 0.8rem solid ${({ theme }) => theme.color.neural.white};
    border-bottom: 0.5rem solid transparent;
    border-left: 0.8rem solid transparent;
    transform: matrix(-1, 0, 0, 1, 0, 0);

    content: "";
  }

  span {
    font-weight: 600;
  }

  b {
    font-weight: 700;
  }
`;
