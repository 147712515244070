"use client";

import styled, { css } from "styled-components";

export const FilterTitleWrapper = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: var(--Spacing-4, 0.4rem);

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      > svg > path {
        width: 1.6rem;
        height: 1.6rem;
        fill: var(--Color-Common-Text-Interactive-Primary-Pressed, #015d8e);
        fill: var(--Color-Common-Text-Interactive-Primary, #0198e9);
      }

      > div {
        color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
      }
    `}
`;
export const FilterTitleItem = styled.div`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
`;
