import React, { ForwardedRef, forwardRef } from "react";

import * as S from "./DBSearchInput.style";

interface Props {
  onKeyUpHandler?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder: string;
}
const DBSearchInput = forwardRef<HTMLInputElement, Props>(
  (
    { onKeyUpHandler, placeholder }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <S.SearchInput
        ref={ref}
        onKeyUp={onKeyUpHandler}
        placeholder={placeholder}
      />
    );
  },
);

export default DBSearchInput;
