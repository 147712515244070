import { useSuspenseQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/queryKeys";
import { authorityTypeApis } from "@services/authorityType.service";

import { queryClient } from "../../api/queryClient";

export const useAuthorityTypeQuery = () => {
  const query = useSuspenseQuery({
    queryKey: [QUERY_KEYS.authorityType],
    queryFn: () => authorityTypeApis.get(),
    select: ({ isSuperAdmin }) => isSuperAdmin,
    staleTime: 5000, // FIXME: 해당 시간은 임시 값으로, 추후에 정확한 값을 넣어야 함
  });

  return query;
};

export const removeAuthorityType = () =>
  queryClient.removeQueries({ queryKey: [QUERY_KEYS.authorityType] });
