"use client";

import styled from "styled-components";

export const DBDetailInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  border-radius: var(--Border-Radius-6, 0.6rem);
  border: 1px solid var(--NEURAL-Gray-030, #dee5ef);
  background: var(--NEURAL-Gray-000, #fff);
  padding: var(--Spacing-16, 1.6rem);
`;

export const DBDetailSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
  width: 100%;
`;
