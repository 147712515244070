import { SectionCode } from "@frontend/common";

import { useAllArticleDataQuery } from "@api/articleContentData/articleContentData";
import { GetArticleContentDataResponse } from "@api/articleContentData/articleContentData.interface";
import { articleContentDataApis } from "@api/articleContentData/articleContentData.service";
import { ParagraphCode } from "@EditArticleContainer/shared/types/editArticle.types";
import { ParagraphContent } from "@EditArticleContainer/shared/types/paragraphContents/paragraphContents.types";
import { useSectionCodeToClassification } from "@hooks/useSectionCode";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { parseJSON } from "@utils/parseJSON";

export const useParagraphData = <T extends ParagraphCode>(paragraphCode: T) => {
  const sectionCode = useSectionCodeToClassification();
  const { data: allArticleData } = useAllArticleDataQuery({
    articleId: getPressIdFromURL(),
    sectionCode,
    paragraphCode,
  });

  return parseJSON<ParagraphContent[T]>(allArticleData.data.data);
};

export const useParagraphEditedData = <T extends ParagraphCode>(
  paragraphCode: T,
) => {
  const sectionCode = useSectionCodeToClassification();
  const { data: allArticleData } = useAllArticleDataQuery({
    articleId: getPressIdFromURL(),
    sectionCode,
    paragraphCode,
  });

  return parseJSON<ParagraphContent[T]>(allArticleData.data.editedData);
};

export const useParagraph = <T extends ParagraphCode>(paragraphCode: T) => {
  const sectionCode = useSectionCodeToClassification();
  const { data: allArticleData } = useAllArticleDataQuery({
    articleId: getPressIdFromURL(),
    sectionCode,
    paragraphCode,
  });

  const editedData = parseJSON<ParagraphContent[T]>(
    allArticleData.data.editedData,
  );
  const data = parseJSON<ParagraphContent[T]>(allArticleData.data.data);

  return {
    ...allArticleData.data,
    data,
    editedData,
  };
};

export const getParagraphData = async <T extends ParagraphCode>({
  pressId = getPressIdFromURL(),
  sectionCode,
  paragraphCode,
}: {
  pressId?: string;
  sectionCode: SectionCode;
  paragraphCode: T;
}) => {
  const data = await articleContentDataApis.getAllArticleData(
    pressId,
    sectionCode,
    paragraphCode,
  );

  return parseJSON<GetArticleContentDataResponse[T]>(data.data.data);
};

export const getParagraphEditedData = async <T extends ParagraphCode>({
  pressId = getPressIdFromURL(),
  sectionCode,
  paragraphCode,
}: {
  pressId?: string;
  sectionCode: SectionCode;
  paragraphCode: T;
}) => {
  const data = await articleContentDataApis.getAllArticleData(
    pressId,
    sectionCode,
    paragraphCode,
  );

  return parseJSON<ParagraphContent[T]>(data.data.editedData);
};

export const getParagraph = async <T extends ParagraphCode>({
  pressId = getPressIdFromURL(),
  sectionCode,
  paragraphCode,
}: {
  pressId?: string;
  sectionCode: SectionCode;
  paragraphCode: T;
}) => {
  const response = await articleContentDataApis.getAllArticleData(
    pressId,
    sectionCode,
    paragraphCode,
  );

  const editedData = parseJSON<ParagraphContent[T]>(response.data.editedData);
  const data = parseJSON<ParagraphContent[T]>(response.data.data);

  return {
    ...data,
    data,
    editedData,
  };
};
