import { apiClient } from "@api/apiClient";

import {
  PostCreateEntryDataRequest,
  PostCreateEntryDataResponse,
} from "./createEntryData.interface";

const API_ENDPOINT = {
  post: "des/ai/entry",
};

export const createEntryDataApis = {
  post: async ({ pressId }: PostCreateEntryDataRequest) => {
    const response = await apiClient.post<PostCreateEntryDataResponse>(
      API_ENDPOINT.post,
      {
        press_id: String(pressId),
      },
    );

    return response;
  },
};
