import { useQuery, useSuspenseQuery } from "@tanstack/react-query";

import { queryClient } from "@api/queryClient";
import { QUERY_KEYS } from "@api/queryKeys";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import {
  GetAiImageInfoResponse,
  GetAiImageListResponse,
} from "./aiImages.interface";
import { aiImagesApis } from "./aiImages.service";

export const useGetAiImagesQuery = () => {
  const draftArticleId = getPressIdFromURL();
  return useSuspenseQuery({
    queryKey: [QUERY_KEYS.aiImages, draftArticleId],
    queryFn: () => aiImagesApis.getImages(draftArticleId),
    refetchInterval: 1000 * 30, // DESCRIBE : Websocket이 끊기는 경우가 있어 30초마다 갱신
  });
};

export const useGetAiImageData = () => {
  const draftArticleId = getPressIdFromURL();

  return queryClient.getQueryData<GetAiImageListResponse>([
    QUERY_KEYS.aiImages,
    draftArticleId,
  ]);
};

export const useGetAiImageInfoQuery = () => {
  const draftArticleId = getPressIdFromURL();
  return useQuery({
    queryKey: [QUERY_KEYS.aiImagesInfo, draftArticleId],
    queryFn: () => aiImagesApis.checkCreateImage(draftArticleId),
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 30, // DESCRIBE : Websocket이 끊기는 경우가 있어 30초마다 갱신
  });
};

export const useGetAiImageInfoData = () => {
  const draftArticleId = getPressIdFromURL();
  return queryClient.getQueryData<GetAiImageInfoResponse>([
    QUERY_KEYS.aiImagesInfo,
    draftArticleId,
  ]);
};
