import React from "react";

import Pagination from "./components/Pagination/Pagination";
import * as S from "./InsertImagePagination.style";

const InsertImagePagination = () => {
  return (
    <S.PaginationWrapper>
      <Pagination />
    </S.PaginationWrapper>
  );
};

export default InsertImagePagination;
