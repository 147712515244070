"use client";

import styled, { css } from "styled-components";

import { CompanyImageGroupType } from "./types/companyImageGroup.type";

interface ImageProps {
  $type: CompanyImageGroupType;
  $logoLength: boolean;
}

export const GroupWrapper = styled.div<ImageProps>`
  flex-shrink: 0;
  z-index: 5;

  ${({ $type }) =>
    $type === "horizon" &&
    css`
      position: absolute;
      bottom: -1.2rem;
      left: 1.2rem;
      display: flex;
      gap: 0.8rem;

      .company-image {
        > img {
          border-radius: 0.4rem;
        }
      }
    `}

  ${({ $type }) =>
    $type === "vertical" &&
    css`
      border: 0.4rem;
      .company-image {
        > img {
          border-radius: 0.4rem;
        }
      }
    `}

  
    ${({ $type }) =>
    $type === "newsletter" &&
    css`
      display: flex;
      align-items: center;
      gap: 0.8rem;
      border-radius: 0.4rem;
      .company-image {
        > img {
          border-radius: 0.4rem;
        }
      }
    `}


  ${({ $type, $logoLength }) =>
    $type === "vertical" &&
    $logoLength &&
    css`
      width: 4.8rem;
      height: 4.8rem;
      position: relative;

      .company-image {
        > img {
          border-radius: 0.4rem;
          width: 2.8rem;
          height: 2.8rem;
        }
      }

      .company-0 {
        position: absolute;
        top: 0;
        left: 0;
      }

      .company-1 {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    `}
`;
