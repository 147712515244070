import styled from "styled-components";

export const StatusFilterWrapper = styled.div`
  position: absolute;
  top: 12rem;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 80rem;
  box-shadow: 0 0.4rem 1.2rem 0 rgba(7, 68, 161, 0.04);
  transform: translate(-50%, 0%);
  z-index: 30;
`;

export const StatusFilterContentWrapper = styled.div`
  padding: 0 0.8rem 0.4rem 0.8rem;
`;

export const StatusFilterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 0.1rem solid ${({ theme }) => theme.color.neural.gray02};
  padding: 2rem 0;
  &:first-child {
    border-top: none;
    padding-top: 0;
    padding-bottom: 2rem;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export const StatusFilterSectionWrapper = styled.div`
  border-top: 0.1rem solid ${({ theme }) => theme.color.neural.gray02};
  padding: 2rem 0;

  :last-child {
    padding-bottom: 0;
  }
`;

export const StatusFilterTitle = styled.h3`
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.color.neural.gray09};
  padding: 0;
  gap: 2rem;
  gap: 2rem;
`;

export const CloseButton = styled.button`
  position: absolute;
  bottom: -6.4rem;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  transform: translateX(-50%);
  font-size: 1.6rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.neural.gray08};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.neural.white};
  cursor: pointer;
  img {
    width: 1rem;
    height: 1rem;
  }
`;

export const ClassificationCloseButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ClassificationXMark = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
`;

export const ClassificationCloseText = styled.span`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 100%;
  color: ${({ theme }) => theme.color.live.orange};
`;

export const FilterResetButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  cursor: pointer;

  &:disabled {
    cursor: no-drop;
    span {
      color: ${({ theme }) => theme.color.neural.gray05};
    }
  }
`;

export const FilterResetButtonText = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 100%;
  color: ${({ theme }) => theme.color.neural.gray09};
`;

export const ConfirmButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  height: 3.2rem;
  margin-top: 2rem;
  background-color: ${({ theme }) => theme.color.node.sky};
  border-radius: 0.8rem;
  padding: 0 1.6rem;
  cursor: pointer;
`;

export const ConfirmButtonText = styled.span`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 100%;
  color: white;
`;
