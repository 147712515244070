import React, { useRef } from "react";

import { useSetUnsuitableArticleInfoMutation } from "@api/articleInfo/useArticleInfo";
import { useUnsuitablePressCancelMutation } from "@api/unsuitablePress/unsuitablePress";
import { trimCharacter } from "@utils/trimCharacter";

import {
  DEFAULT_VALUE_FOR_ADD_ID_TYPE,
  UNSUITABLE_REASON_TYPE_INFO,
  UnsuitableReasonList,
  UnsuitableReasonType,
} from "../constants/UnsuitableReason.constants";
import { InfoForSelectedRejectReason } from "../UnsuitableReasonCheckItem";

interface UseHandleUnsuitableReasonItemReturn {
  handleCheckTargetReason: (type: UnsuitableReasonType) => void;
  handleDeleteReasonInput: (type: UnsuitableReasonType) => void;
  handleKeyDownEnterSavedReason: (e: React.KeyboardEvent) => void;
  handleFocusOutAddIdReason: (type: UnsuitableReasonType) => void;
  handleClickCheckBox: (type: UnsuitableReasonType) => void;
  inputRef: React.RefObject<HTMLInputElement>;
}

export const useHandleUnsuitableReasonItem = (
  infoForSelectedRejectReason: InfoForSelectedRejectReason,
): UseHandleUnsuitableReasonItemReturn => {
  const {
    reasonId,
    unsuitablePressReasonList,
    setUnsuitablePressReasonList,
    draftArticleId,
    mcEditorName,
    closeModalUnsuitablePress,
  } = infoForSelectedRejectReason;

  const { mutateAsync: turnOnUnsuitable } =
    useSetUnsuitableArticleInfoMutation();
  const { mutateAsync: turnOffUnsuitable } = useUnsuitablePressCancelMutation();

  const inputRef = useRef<HTMLInputElement>(null);

  const submitUnsuitableReason = async (
    unsuitableReason: string | null,
    overlappedPressId: string | null,
  ) => {
    const isUnsuitableReasonToAddId = unsuitableReason?.includes(
      DEFAULT_VALUE_FOR_ADD_ID_TYPE,
    );

    if (isUnsuitableReasonToAddId && !overlappedPressId) return;

    const mcEditorNameData = trimCharacter(mcEditorName);

    const unsuitablePressRequestData =
      mcEditorNameData === null || mcEditorNameData === ""
        ? {
            draftArticleId,
            articleRejectReason: unsuitableReason ?? "",
            type: "MC",
            isCheckStartDate: "true",
            publishable: false,
          }
        : {
            draftArticleId,
            articleRejectReason: unsuitableReason ?? "",
            publishable: false,
          };

    if (!!unsuitableReason && unsuitableReason.length > 0) {
      await turnOnUnsuitable({
        ...unsuitablePressRequestData,
      });
      closeModalUnsuitablePress();

      return;
    }
    await turnOffUnsuitable(draftArticleId);
  };

  const handleCheckDefaultReason = (reasonItem: UnsuitableReasonList) => {
    const targetChange = {
      ...reasonItem,
      duplId: null,
      isTarget: !reasonItem.isTarget,
    };

    const newTargetUnsuitableReasonList = unsuitablePressReasonList.map(
      (item) => {
        if (item.id === reasonItem.id) {
          return targetChange;
        }

        return {
          ...item,
          isTarget: false,
          duplId: null,
        };
      },
    );

    setUnsuitablePressReasonList(newTargetUnsuitableReasonList);
    submitUnsuitableReason(
      targetChange.isTarget ? targetChange.reason : "",
      null,
    );
  };

  const handleCheckWriteReason = (reasonItem: UnsuitableReasonList) => {
    const targetChange = {
      ...reasonItem,
      reason: inputRef.current?.value || "",
      duplId: null,
      isTarget: !!inputRef.current?.value,
    };

    const newTargetUnsuitableReasonList = unsuitablePressReasonList.map(
      (item) => {
        if (item.id === reasonItem.id) {
          return targetChange;
        }

        return {
          ...item,
          duplId: null,
          isTarget: false,
        };
      },
    );

    setUnsuitablePressReasonList(newTargetUnsuitableReasonList);
  };

  const handleFocusOutAddIdReason = () => {
    const [currentTarget] = unsuitablePressReasonList.filter(
      (reasonItem) => reasonItem.id === reasonId,
    );

    const currentReason = currentTarget.isTarget ? currentTarget.reason : "";

    submitUnsuitableReason(currentReason, null);
  };

  const handleCheckTargetReason = (type: UnsuitableReasonType) => {
    const [currentTarget] = unsuitablePressReasonList.filter(
      (reasonItem) => reasonItem.id === reasonId,
    );

    if (type === UNSUITABLE_REASON_TYPE_INFO.default) {
      return handleCheckDefaultReason(currentTarget);
    }
  };

  const handleKeyDownEnterSavedReason = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") inputRef.current?.blur();
  };

  const handleDeleteReasonInput = () => {
    inputRef.current?.blur();

    if (!inputRef.current) return;

    inputRef.current.value = "";

    const [currentTarget] = unsuitablePressReasonList.filter(
      (reasonItem) => reasonItem.id === reasonId,
    );

    handleCheckWriteReason(currentTarget);
  };

  const handleClickCheckBox = (type: UnsuitableReasonType) => {
    if (type === "default") return;

    const [currentTarget] = unsuitablePressReasonList.filter(
      (reasonItem) => reasonItem.id === reasonId,
    );

    if (currentTarget.isTarget && inputRef.current) inputRef.current.value = "";
  };

  return {
    handleCheckTargetReason,
    handleDeleteReasonInput,
    handleKeyDownEnterSavedReason,
    handleFocusOutAddIdReason,
    handleClickCheckBox,
    inputRef,
  };
};
