import {
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
  TableSwitchButton,
} from "@frontend/common";

import { Table } from "@EditArticleContainer/shared/components/ArticleTable/ArticleTable";
import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";

import { ClinicalTrialDesign2 } from "../../../utils/clinicalTrialDesign2";

interface Props {
  clinicalTrialDesign2: ClinicalTrialDesign2;
  designGroupTableIndex: number;
  switchTable: () => void;
}

const COMMON_EDITABLE_TEXT_STYLE = {
  padding: "0.8rem",
  borderRadius: "0",
};
const VALUE_SHELL_PADDING = "0.2rem";

const ClinicalTrialDesign2Table = ({
  clinicalTrialDesign2,
  designGroupTableIndex,
  switchTable,
}: Props) => {
  const GROUP_TABLE_INDEX_KEY = `||${designGroupTableIndex}`;
  const tableItems: Table["tableItems"] = [];
  tableItems.push([
    {
      refKey: "[Title] TC004-Arm Name Title",
      value: "Arm Name",
    },
    ...clinicalTrialDesign2.armName.map(({ key, text, labels }) => ({
      refKey: `${key}${GROUP_TABLE_INDEX_KEY}`,
      value: text,
      labels,
      canTextEdit: true,
      canLabelEdit: true,
    })),
  ]);

  if (
    clinicalTrialDesign2.patientsNumber &&
    clinicalTrialDesign2.patientsNumber.some((item) => item.text !== "")
  ) {
    tableItems.push([
      {
        refKey: "[Title] TC004-Patients Number Title",
        value: "Patients Number",
      },
      ...clinicalTrialDesign2.patientsNumber.map(({ key, text, labels }) => ({
        refKey: `${key}${GROUP_TABLE_INDEX_KEY}`,
        value: text,
        labels,
        canTextEdit: true,
        canLabelEdit: true,
      })),
    ]);
  }

  tableItems.push([
    {
      refKey: "[Title] TC004-Design Group Information Title",
      value: "Design Group Information",
    },
    ...clinicalTrialDesign2.designGroupInformation.map(
      ({ key, text, labels }) => ({
        refKey: `${key}${GROUP_TABLE_INDEX_KEY}`,
        canTextEdit: true,
        canLabelEdit: true,
        value: text,
        labels,
      }),
    ),
  ]);

  const checkIsTitle = (targetKey: string) => {
    return targetKey.includes("[Title]");
  };

  return (
    <TableContainer>
      {tableItems.map((row, rowIndex) => {
        if (rowIndex === 0) {
          return (
            <TableRowContainer key={`row-${String(rowIndex)}`}>
              {row.map((shell) => {
                const isTitle = checkIsTitle(shell.refKey);
                if (isTitle) {
                  return (
                    <TableShellTitle key={shell.refKey}>
                      <TableSwitchButton onClick={switchTable} />
                      {shell.value}
                    </TableShellTitle>
                  );
                }
                return (
                  <TableShellTitle
                    padding={VALUE_SHELL_PADDING}
                    key={shell.refKey}
                  >
                    <EditableTextContainer
                      item={{
                        text: shell.value,
                        labels: shell.labels ?? [],
                      }}
                      canTextEdit
                      canLabelEdit
                      refKey={shell.refKey}
                      style={COMMON_EDITABLE_TEXT_STYLE}
                    />
                  </TableShellTitle>
                );
              })}
            </TableRowContainer>
          );
        }
        return (
          <TableRowContainer key={`row-${String(rowIndex)}`}>
            {row.map((shell) => {
              const isTitle = checkIsTitle(shell.refKey);
              if (isTitle) {
                return (
                  <TableShellTitle key={shell.refKey}>
                    {shell.value}
                  </TableShellTitle>
                );
              }
              return (
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  key={shell.refKey}
                >
                  <EditableTextContainer
                    item={{
                      text: shell.value,
                      labels: shell.labels ?? [],
                    }}
                    canTextEdit
                    canLabelEdit
                    refKey={shell.refKey}
                    style={COMMON_EDITABLE_TEXT_STYLE}
                  />
                </TableShellValue>
              );
            })}
          </TableRowContainer>
        );
      })}
    </TableContainer>
  );
};

export default ClinicalTrialDesign2Table;
