import { ClassificationCode } from "@/types/code.types";
import { apiClient } from "@api/apiClient";

import {
  GetPressCopiedResponse,
  PostPressCopyResponse,
} from "./pressCopy.interface";

const API_ENDPOINT = {
  getPressCopiedList: "/des/draft-article-copy/list",
  postPressCopy: "/des/draft-article-copy/copy",
};

export const pressCopyApis = {
  getPressCopiedList: async (pressId: string) => {
    const url = `${API_ENDPOINT.getPressCopiedList}?id=${pressId}`;
    const response = await apiClient.get<GetPressCopiedResponse>(url);

    return response;
  },
  postPressCopy: async (
    pressId: string,
    classificationCode: ClassificationCode,
  ) => {
    const url = `${API_ENDPOINT.postPressCopy}?id=${pressId}&classification=${classificationCode}`;
    const response = await apiClient.post<PostPressCopyResponse>(url);
    return response;
  },
};
