"use client";

import { styled } from "styled-components";

export const TitleWrapper = styled.div`
  display: flex;
  gap: 0.4rem;

  @media only screen and (min-width: 990px) and (max-width: 1365px) {
    gap: 0.1rem;
  }
`;

export const CountTitle = styled.div`
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 145%;

  @media only screen and (min-width: 990px) and (max-width: 1365px) {
    font-size: 1.2rem;
    line-height: 130%;
    letter-spacing: -0.024rem;
  }
`;
export const PaymentTitle = styled.div`
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  color: var(--Color-Label-Text-Interactive-Payment, #597181);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 145%; /* 2.03rem */

  @media only screen and (min-width: 990px) and (max-width: 1365px) {
    font-size: 1.2rem;
    line-height: 130%;
    letter-spacing: -0.024rem;
  }
`;
