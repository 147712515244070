import styled, { css } from "styled-components";

import AIRegenerateButton from "@EditArticleContainer/shared/components/AIRegenerateButton/AIRegenerateButton";
import {
  ParagraphContentsWrapper,
  ParagraphContent,
} from "@EditArticleContainer/shared/styles/paragraph.style";

export const ParagraphTopicContentWrapper = styled(ParagraphContentsWrapper)<{
  disabled: boolean;
}>`
  position: relative;
  padding: 0.4rem 0.2rem;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: not-allowed;

      & * {
        pointer-events: none !important;
        color: ${theme.color.neural.gray03} !important;
      }
    `}
`;

export const ParagraphTopicContent = styled(ParagraphContent)`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
`;

export const ParagraphTopicRegenerateButton = styled(AIRegenerateButton)`
  position: absolute;
  top: 1.2rem;
  left: -3.8rem;
`;

export const ParagraphTopicContentsWrapper = styled.div`
  position: relative;
`;
