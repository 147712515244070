import React from "react";

import {
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
  LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import {
  BASIC_DATA_FOR_ADDING_SUB_GROUP_SHELLS,
  SUB_GROUP_SHELL_PATH_TO_ADD_SUB_GROUP_SHELL,
} from "@FillInTableContainer/shared/constants/tableCreatorSubGroupShell.constants";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useCheckDisabledDelete } from "@FillInTableContainer/shared/hooks/useCheckDisabledDelete";
import { useUpdateEntryData } from "@hooks/useUpdateArticleData";
import { useEntryDataState } from "@stores/entryData";
import { useInitEntryDataValue } from "@stores/initEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  clickAddMilestoneSubGroupShellButton: (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
      subGroupShellIndexParameter: number;
      subGroupShellKeyParameter: string | null;
    },
  ) => void;
  handleClickToDeleteMilestoneSubGroupShell: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  handleKeyDownToDeleteMilestoneSubGroupShell: (event: KeyboardEvent) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: DealsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    subGroupShellIndex: number;
    subGroupShellKey: string | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useHandleDealsLicenseeTableSubGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    subGroupShellIndex,
    subGroupShellKey,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const articleSection = "Deals";
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const initEntryData = useInitEntryDataValue(getPressIdFromURL());
  const updateEntryData = useUpdateEntryData();
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { checkDisabledDeleteGroupShell } = useCheckDisabledDelete();

  const handleAddSubGroupShell = (groupShellInfo: {
    tableIndexParameter: number;
    groupShellIndexParameter: number;
    groupShellKeyParameter: string | null;
    subGroupShellIndexParameter: number;
    subGroupShellKeyParameter: string | null;
  }) => {
    const {
      tableIndexParameter,
      groupShellIndexParameter,
      subGroupShellIndexParameter,
      subGroupShellKeyParameter,
    } = groupShellInfo;

    if (subGroupShellKeyParameter === null) return;

    const targetShell = SUB_GROUP_SHELL_PATH_TO_ADD_SUB_GROUP_SHELL[
      subGroupShellKeyParameter
    ](entryData, tableIndexParameter, groupShellIndexParameter);

    if (targetShell === undefined) return;

    const newArrayLength = targetShell.length + 1 || 1;
    const emptyArray = new Array(newArrayLength).fill({});

    const addMilestoneSubGroupShellData = emptyArray.map((_, index) => {
      const milestoneSubGroupShellData =
        BASIC_DATA_FOR_ADDING_SUB_GROUP_SHELLS[articleSection][
          subGroupShellKeyParameter
        ](initEntryData);

      if (index === subGroupShellIndexParameter) {
        return milestoneSubGroupShellData;
      }

      if (index > subGroupShellIndexParameter) {
        return targetShell[index - 1];
      }

      if (index < subGroupShellIndexParameter) {
        return targetShell[index];
      }

      return targetShell[index];
    });

    const changeSubGroupShellData: Record<string, ArticleEntryData> = {
      Milestone: {
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableData, tableDataIndex) => {
              if (tableDataIndex === tableIndexParameter) {
                return {
                  ...tableData,
                  "Strategic Collaboration & License Agreement terms": {
                    ...tableData[
                      "Strategic Collaboration & License Agreement terms"
                    ],
                    "Milestone Payment and Condition":
                      addMilestoneSubGroupShellData,
                  },
                };
              }

              return tableData;
            },
          ),
        },
      } as ArticleEntryData,
      [LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail]: {
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableData, tableDataIndex) => {
            if (tableDataIndex === tableIndexParameter) {
              return {
                ...tableData,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                  {
                    ...tableData[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ],
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa]:
                      addMilestoneSubGroupShellData,
                  },
              };
            }

            return tableData;
          }),
        },
      } as ArticleEntryData,
    };

    setEntryData(changeSubGroupShellData[subGroupShellKeyParameter]);
    updateEntryData(changeSubGroupShellData[subGroupShellKeyParameter]);
    changeCurrentHistory({
      entryData: changeSubGroupShellData[subGroupShellKeyParameter],
      changedDataInfo: {
        target: "subGroupShell",
        action: "add",
        position: {
          groupShellKey,
          groupTableIndex,
          tableKey,
          tableIndex,
          groupTableKey,
          groupShellIndex,
          subGroupShellIndex,
          subGroupShellKey,
          shellKey: null,
          shellIndex: null,
          valueIndex: null,
          value: null,
        },
      },
    });

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  };

  const clickAddMilestoneSubGroupShellButton = (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
      subGroupShellIndexParameter: number;
      subGroupShellKeyParameter: string | null;
    },
  ) => {
    event.stopPropagation();
    handleAddSubGroupShell(groupShellInfo);
  };

  const handleClearSubGroupShell = () => {
    const clearMilestoneSubGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  "Strategic Collaboration & License Agreement terms": {
                    ...tableInfo[
                      "Strategic Collaboration & License Agreement terms"
                    ],
                    "Milestone Payment and Condition": tableInfo[
                      "Strategic Collaboration & License Agreement terms"
                    ]["Milestone Payment and Condition"].map(
                      (subGroupShellInfo, subGroupShellInfoIndex) => {
                        if (
                          subGroupShellInfoIndex === info.subGroupShellIndex
                        ) {
                          return {
                            ...subGroupShellInfo,
                            "Milestone Condition Title": {
                              ...subGroupShellInfo["Milestone Condition Title"],
                              value: [],
                            },
                            "Milestone Payment": {
                              ...subGroupShellInfo["Milestone Payment"],
                              value: [],
                            },
                          };
                        }

                        return subGroupShellInfo;
                      },
                    ),
                  },
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const clearUpfrontSupGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  "Strategic Collaboration & License Agreement terms": {
                    ...tableInfo[
                      "Strategic Collaboration & License Agreement terms"
                    ],
                    "Upfront Payment": {
                      ...tableInfo[
                        "Strategic Collaboration & License Agreement terms"
                      ]["Upfront Payment"],
                      value: [],
                    },
                    Royalty: {
                      ...tableInfo[
                        "Strategic Collaboration & License Agreement terms"
                      ].Royalty,
                      value: [],
                    },
                  },
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const clearTotalSupGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  "Strategic Collaboration & License Agreement terms": {
                    ...tableInfo[
                      "Strategic Collaboration & License Agreement terms"
                    ],
                    "Total Payment (SC & LA)": {
                      ...tableInfo[
                        "Strategic Collaboration & License Agreement terms"
                      ]["Total Payment (SC & LA)"],
                      value: [],
                    },
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.disclosedPaymentsSumScLa]:
                      {
                        ...tableInfo[
                          ENTRY_DATA_LICENSEE_TABLE_KEYS
                            .strategicCollaborationLicenseAgreementTerms
                        ][
                          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                            .disclosedPaymentsSumScLa
                        ],
                        value: [],
                      },
                  },
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const clearOtherPaymentAndDetailSubGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData.Deals?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                  {
                    ...tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ],
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa]:
                      tableInfo[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ][
                        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                          .otherPaymentAndDetailScLa
                      ].map((subGroupShellInfo, subGroupShellInfoIndex) => {
                        if (
                          subGroupShellInfoIndex === info.subGroupShellIndex
                        ) {
                          return {
                            ...subGroupShellInfo,
                            [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentScLa]:
                              {
                                ...subGroupShellInfo[
                                  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                                    .otherPaymentScLa
                                ],
                                value: [],
                              },
                            [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentDetailScLa]:
                              {
                                ...subGroupShellInfo[
                                  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                                    .otherPaymentDetailScLa
                                ],
                                value: [],
                              },
                          };
                        }

                        return subGroupShellInfo;
                      }),
                  },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    if (
      info.subGroupShellKey === LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.milestone
    ) {
      const milestoneLength =
        entryData?.Deals?.["Licensee Table"]?.[info.tableIndex]?.[
          "Strategic Collaboration & License Agreement terms"
        ]["Milestone Payment and Condition"]?.length || 0;

      setEntryData(clearMilestoneSubGroupShell());
      changeCurrentHistory({
        entryData: clearMilestoneSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(
        milestoneLength > 1 && !checkDisabledDeleteGroupShell()
          ? {
              type: "delete",
              container: "subGroupShell",
            }
          : DEFAULT_FOCUS_STATUS,
      );
    }

    if (
      info.subGroupShellKey ===
      LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail
    ) {
      const otherPaymentAndDetailLength =
        entryData?.Deals?.[ENTRY_DATA_LICENSEE_TABLE]?.[info.tableIndex]?.[
          ENTRY_DATA_LICENSEE_TABLE_KEYS
            .strategicCollaborationLicenseAgreementTerms
        ][
          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
            .otherPaymentAndDetailScLa
        ]?.length || 0;

      setEntryData(clearOtherPaymentAndDetailSubGroupShell());
      changeCurrentHistory({
        entryData: clearOtherPaymentAndDetailSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(
        otherPaymentAndDetailLength > 1 && !checkDisabledDeleteGroupShell()
          ? {
              type: "delete",
              container: "subGroupShell",
            }
          : DEFAULT_FOCUS_STATUS,
      );
    }

    if (
      info.subGroupShellKey === LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.upfront
    ) {
      setEntryData(clearUpfrontSupGroupShell());
      changeCurrentHistory({
        entryData: clearUpfrontSupGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (
      info.subGroupShellKey === LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.total
    ) {
      setEntryData(clearTotalSupGroupShell());
      changeCurrentHistory({
        entryData: clearTotalSupGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }
  };

  const handleDeleteSubGroupShell = () => {
    if (checkDisabledDeleteGroupShell()) return;

    const deleteMilestoneSubGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  "Strategic Collaboration & License Agreement terms": {
                    ...tableInfo[
                      "Strategic Collaboration & License Agreement terms"
                    ],
                    "Milestone Payment and Condition": tableInfo[
                      "Strategic Collaboration & License Agreement terms"
                    ]["Milestone Payment and Condition"].filter(
                      (_, subGroupShellInfoIndex) =>
                        subGroupShellInfoIndex !== info.subGroupShellIndex,
                    ),
                  },
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const deleteOtherPaymentSubGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData.Deals?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                  {
                    ...tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ],
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa]:
                      tableInfo[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ][
                        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                          .otherPaymentAndDetailScLa
                      ].filter(
                        (_, subGroupShellInfoIndex) =>
                          subGroupShellInfoIndex !== info.subGroupShellIndex,
                      ),
                  },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    if (
      info.subGroupShellKey === LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.milestone
    ) {
      setEntryData(deleteMilestoneSubGroupShell());
      changeCurrentHistory({
        entryData: deleteMilestoneSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "delete",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
    }

    if (
      info.subGroupShellKey ===
      LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail
    ) {
      setEntryData(deleteOtherPaymentSubGroupShell());
      changeCurrentHistory({
        entryData: deleteOtherPaymentSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "delete",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
    }

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  };

  const handleClickToDeleteMilestoneSubGroupShell = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    if (focusStatus.type === "clear") {
      handleClearSubGroupShell();
    }
    if (focusStatus.type === "delete") {
      handleDeleteSubGroupShell();
    }
  };

  const handleKeyDownToDeleteMilestoneSubGroupShell = (
    event: KeyboardEvent,
  ) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearSubGroupShell();
    }
    if (focusStatus.type === "delete" && event.key === "Delete") {
      handleDeleteSubGroupShell();
    }
  };

  return {
    clickAddMilestoneSubGroupShellButton,
    handleClickToDeleteMilestoneSubGroupShell,
    handleKeyDownToDeleteMilestoneSubGroupShell,
  };
};
