import { useQueries } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/queryKeys";

import { clinicalTrialsIdentifierApis } from "./clinicalTrialsIdentifier.service";

export const useClinicalTrialsIdentifierAllCheckQueries = (
  valuesList: string[][],
) => {
  const queries = useQueries({
    queries: valuesList.map((valueList) => ({
      queryKey: [QUERY_KEYS.clinicalTrialsIdentifierAllCheck, valueList],
      queryFn: () => clinicalTrialsIdentifierApis.post(valueList),
    })),
    combine: (results) => {
      return {
        info: results.map((result) => result.data?.data || false),
      };
    },
  });
  return queries;
};
