"use client";

import styled from "styled-components";

import { MOBILE_HEADER_HEIGHT } from "../../../../../../../constants";

export const HeaderMobileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${MOBILE_HEADER_HEIGHT};
  background-color: ${({ theme }) => theme.color.neural.white};
  padding: 1.6rem;
`;

export const HeaderMobileMenuSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
  height: 100%;
`;

export const HeaderMobileLogo = styled.h1`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const MobileMenuButton = styled.button`
  padding: 0;
  outline: none;
  cursor: pointer;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const SignInButtonWrapper = styled.div``;

export const SignInButton = styled.button`
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);

  font-size: 1.4rem;
  font-weight: 500;
  line-height: 145%; /* 2.03rem */
  cursor: pointer;

  display: flex;
  width: 9.6rem;
  height: 3.2rem;
  min-height: var(--Size-Btn-Sm, 2.8rem);
  max-height: var(--Size-Btn-Lg, 4.8rem);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-Btn-4, 0.4rem);

  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Interactive-Primary, #1baefe);
`;
