import styled, { css } from "styled-components";

import type { LabelCode } from "@/types/code.types";
import type { ArticleContentsLabelingType } from "@EditArticleContainer/shared/types/articleContentsData.types";

export const PressLabelOptionWindowWrapper = styled.div<{
  top: number;
  left: number;
}>`
  position: fixed;
  display: grid;
  grid-template-areas:
    "a b c"
    "d e f"
    "g h i";
  z-index: 20;

  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  width: 67.2rem;
  padding: 2.4rem;
  gap: 2.4rem;

  background-color: white;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid ${({ theme }) => theme.color.neural.gray01};

  box-shadow: 0px 0.4rem 0.8rem rgba(7, 68, 162, 0.02);
  border-radius: 1.6rem;

  & > *:nth-child(1) {
    grid-area: a;
  }
  & > *:nth-child(2) {
    grid-area: b;
  }
  & > *:nth-child(3) {
    grid-area: c;
  }
  & > *:nth-child(4) {
    grid-area: d;
  }
  & > *:nth-child(5) {
    grid-area: e;
  }
  & > *:nth-child(6) {
    grid-area: f;
  }
  & > *:nth-child(7) {
    grid-area: g;
  }
  & > *:nth-child(8) {
    grid-area: h;
  }
  & > *:nth-child(9) {
    grid-area: i;
  }
`;

export const PressLabelCircle = styled.div<{
  entity: LabelCode | "";
  type?: ArticleContentsLabelingType;
}>`
  width: 2.4rem;
  height: 2.4rem;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  background-color: ${({ theme, entity }) => {
    if (entity === "") return "";
    return theme.label.background[entity];
  }};
  background-image: ${({ theme, entity }) => {
    if (entity === "") return "";
    return `url(${theme.label.backgroundImage[entity]})`;
  }};
  border-color: ${({ theme, entity }) => {
    if (entity === "") return theme.color.neural.gray01;
    return theme.label.stroke[entity];
  }};
  ${({ theme, type }) => {
    if (!type) return "";
    if (type === "Bold")
      return css`
        font-weight: 700;
      `;
    if (type === "BlueBold")
      return css`
        font-weight: 700;
        color: ${theme.color.node.blue};
      `;
  }};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PressLabelButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.8rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5;

  & > span {
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.color.live.orange};
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 600;
  gap: 0.4rem;
`;

export const PressLabelText = styled.p`
  max-width: 11rem;
  text-align: start;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
`;

export const Number = styled.span`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5;

  color: ${({ theme }) => theme.color.neural.gray06};
`;
