import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";
import type { ClinicalTrialsSection } from "@/types/clinicalTrials.types";

interface UseHandleGroupShellReturn {
  handleClickToClearDrugProfileGroupShell: () => void;
  handleKeyDownToClearDrugProfileGroupShell: (event: KeyboardEvent) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: ClinicalTrialsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useHandleClinicalTrialStatusDrugProfileGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const articleSection = "Clinical Trial Status";
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();

  const handleClearGroupShell = () => {
    const clearDrugProfileGroupShell = (): ArticleEntryData => {
      if (info.groupShellKey === "Drug Name")
        return {
          ...entryData,
          [articleSection]: {
            ...entryData[articleSection],
            "Drug Profile": entryData[articleSection]?.["Drug Profile"]?.map(
              (tableInfo, tableInfoIndex) => {
                if (tableInfoIndex === info.tableIndex) {
                  return {
                    ...tableInfo,
                    "Brand Name": {
                      ...tableInfo["Brand Name"],
                      value: [],
                    },
                    "Code Name": {
                      ...tableInfo["Code Name"],
                      value: [],
                    },
                    "INN Name": {
                      ...tableInfo["INN Name"],
                      value: [],
                    },
                  };
                }
                return tableInfo;
              },
            ),
          },
        };

      return {
        ...entryData,
        [articleSection]: {
          ...entryData[articleSection],
          "Drug Profile": entryData[articleSection]?.["Drug Profile"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  MOA: {
                    ...tableInfo.MOA,
                    value: [],
                  },
                  Modality: {
                    ...tableInfo.Modality,
                    value: [],
                  },
                  "Other Characteristics": {
                    ...tableInfo["Other Characteristics"],
                    value: [],
                  },
                  ROA: {
                    ...tableInfo.ROA,
                    value: [],
                  },
                  Target: {
                    ...tableInfo.Target,
                    value: [],
                  },
                  "Target Indication": {
                    ...tableInfo["Target Indication"],
                    value: [],
                  },
                  "Therapeutic Area": {
                    ...tableInfo["Therapeutic Area"],
                    value: [],
                  },
                  "Originator/Developer": {
                    ...tableInfo["Originator/Developer"],
                    value: [],
                  },
                  "Generic / Biosimilar": {
                    ...tableInfo?.["Generic / Biosimilar"],
                    value: ["false"],
                  },
                  "Ref.Product Name": {
                    ...tableInfo?.["Ref.Product Name"],
                    value: [],
                  },
                  Phase: {
                    ...tableInfo?.Phase,
                    value: [],
                  },
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    if (info.groupTableKey === "Drug Profile") {
      setEntryData(clearDrugProfileGroupShell());
      changeCurrentHistory({
        entryData: clearDrugProfileGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  };

  const handleClickToClearDrugProfileGroupShell = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShell();
    }
  };

  const handleKeyDownToClearDrugProfileGroupShell = (event: KeyboardEvent) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearGroupShell();
    }
  };

  return {
    handleClickToClearDrugProfileGroupShell,
    handleKeyDownToClearDrugProfileGroupShell,
  };
};
