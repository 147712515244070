import InsertImageContents from "./components/InsertImageContents/InsertImageContents";
import InsertImageProvider from "./components/InsertImageProvider/InsertImageProvider";
import { useCreateArticleContent } from "./hooks/useCreateArticleContent";

const InsertImageContainer = () => {
  useCreateArticleContent();

  return (
    <InsertImageProvider>
      <InsertImageContents />
    </InsertImageProvider>
  );
};

export default InsertImageContainer;
