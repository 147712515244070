"use client";

import styled from "styled-components";

export const PageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Spacing-4, 0.4rem);
`;

export const PageTitle = styled.h2`
  color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 2rem;
  font-weight: 400;
  line-height: 120%;
`;
