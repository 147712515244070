import styled, { css } from "styled-components";

import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import UpdateButton from "@EditArticleContainer/shared/components/UpdateButton/UpdateButton";
import {
  ParagraphContentsWrapper,
  ParagraphWrapper,
} from "@EditArticleContainer/shared/styles/paragraph.style";

interface DisabledType {
  disabled: boolean;
}

export const ParagraphKeyCommentsWrapper = styled(
  ParagraphWrapper,
)<DisabledType>`
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;

      & * {
        pointer-events: none;
      }
    `}
`;

export const ParagraphKeyCommentsContentsWrapper = styled(
  ParagraphContentsWrapper,
)`
  position: relative;
`;

export const KeyCommentsContentWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  position: relative;
  display: flex;
  gap: 1.6rem;

  ${({ disabled }) =>
    disabled &&
    css`
      & * {
        color: ${({ theme }) => theme.color.neural.gray03} !important;
      }
    `}
`;

export const SpokespersonContent = styled(EditableTextContainer)`
  flex: 1;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 3.24rem */
`;

export const QuoteContent = styled(EditableTextContainer)`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 2rem;
  font-weight: 500;
  line-height: 160%;
  align-self: stretch;
`;

export const ParagraphKeyCommentsUpdateButton = styled(UpdateButton)`
  position: absolute;
  left: -1.6rem;
  top: 3.2rem;
  transform: translateX(-100%);
`;
