"use client";

import styled from "styled-components";

export const ExpandButton = styled.button`
  display: flex;
  width: 2.6667rem;
  height: 2.6667rem;
  padding: var(--Spacing-0, 0.2667rem);
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Interactive-Secondary, #fff);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
    0px 1px 2px 0px rgba(0, 0, 0, 0.16);

  cursor: pointer;

  &:hover {
    border: 1px solid
      var(--Color-Common-Border-Interactive-Primary-Hovered, #017abc);
    background: var(--Color-Common-Bg-Interactive-Primary-Hovered, #0198e9);
    > svg > path {
      fill: white;
    }
  }

  &:active {
    border: 1px solid
      var(--Color-Common-Border-Interactive-Primary-Pressed, #015d8e);
    background: var(--Color-Common-Bg-Interactive-Primary-Pressed, #017abc);
    > svg > path {
      fill: white;
    }
  }
`;
