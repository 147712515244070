import React from "react";

import { useGetPressContentQuery } from "@api/pressContent/pressContent";

import * as S from "./PressPreviewModal.style";
import PreviewLoading from "./PreviewLoading/PreviewLoading";
import { preventPropagation } from "./utils/preventPropagation";

interface Props {
  pressId: number;
}

const PressPreviewModal = ({ pressId }: Props) => {
  const { data: previewPress, isLoading } = useGetPressContentQuery(pressId);
  return (
    <S.PressContentHoverModal
      className="press-hover-modal"
      onClick={preventPropagation}
    >
      <S.PressContents>
        {isLoading ? <PreviewLoading /> : previewPress?.data.data.trim() ?? ""}
      </S.PressContents>
    </S.PressContentHoverModal>
  );
};

export default PressPreviewModal;
