"use client";

import styled, { css } from "styled-components";

export const DBDetailSentenceEntryContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DBDetailSentenceEntryTitle = styled.div`
  display: flex;
  align-items: center;
  height: 3.2rem;
  color: var(--Color-Common-Text-Secondary, #515c6c);
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0.4rem 0.2rem;
`;
export const DBDetailSentenceEntryData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 0.8rem;

  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--NEURAL-Gray-030, #dee5ef);
  background: var(--NEURAL-Gray-000, #fff);
  padding: 0.8rem;
  white-space: pre-wrap;
`;

export const SentenceEntryTextWrapper = styled.div<{
  $isFoldable?: boolean;
  $isMore: boolean;
}>`
  width: 100%;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 145%;

  ${({ $isFoldable, $isMore }) =>
    $isFoldable &&
    !$isMore &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 10;
      text-overflow: ellipsis;
      word-break: keep-all;
      overflow: hidden;
    `}
`;
