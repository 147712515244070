import React from "react";

import ErrorPageView from "@/components/shared/ui/ErrorPageView/ErrorPageView";

const PageNotFoundError = () => {
  return (
    <ErrorPageView
      title="Page Not Found"
      message="Oops! It seems like the page you are looking for doesn't exist.
          Please check the URL and try again"
      buttonLabel="Back to Previous Page"
      handleButtonClick={() => window.history.go(-1)}
      errorStatus={404}
    />
  );
};

export default PageNotFoundError;
