import { useGetMainLabelQuery } from "@api/mainLabel/mainLabel";

import { useHandleMainLabelList } from "./useHandleMainLabelList";
import { useMainLabelInfoList } from "./useMainLabelInfoList";
import { MainLabelEntityTypes } from "../types/articleMainLabel.types";

export const useMainLabelList = () => {
  const { data: mainLabelData } = useGetMainLabelQuery();
  const mainLabelDefaultInfo = useMainLabelInfoList();

  const sortedMainLabelData =
    mainLabelData?.data
      ?.slice()
      .sort((a, b) => {
        const entityOrder = mainLabelDefaultInfo.reduce<
          Record<MainLabelEntityTypes, number>
        >((acc, item, index) => {
          acc[item.entity] = index;
          return acc;
        }, {} as Record<MainLabelEntityTypes, number>);

        return (entityOrder[a.entity] || 0) - (entityOrder[b.entity] || 0);
      })
      .filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.labelValue === item.labelValue),
      ) || [];

  const mainLabelInfo = useHandleMainLabelList();
  const mainLabelList = mainLabelInfo.getFormattedMainLabelList(
    mainLabelDefaultInfo,
    mainLabelData?.data || [],
  );

  return {
    savedMainLabelList: sortedMainLabelData || [],
    mainLabelList,
  };
};
