"use client";

import styled from "styled-components";

export const TableShellValue = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  width: 100%;

  line-height: 160%;
  font-size: 1.6rem;
  font-weight: 500;
  color: ${({ theme }) => theme.color.neural.gray10};
  background-color: ${({ theme }) => theme.color.neural.white};
  padding: 1.6rem;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    font-size: 1.2rem;
    padding: 1.2rem 0.8rem;
  }
`;
