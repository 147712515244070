import React from "react";

import { FillInTableRefMapProvider } from "@FillInTableContainer/components/FillInTable/contexts/FillInTableRefMapContext";
import { CheckCurrentDealTypeProvider } from "@FillInTableContainer/shared/context/CurrentDealTypeContext";
import { ModalityCategoryProvider } from "@FillInTableContainer/shared/context/ModalityCategoryContext";
import { TableCreatorDropdownProvider } from "@FillInTableContainer/shared/context/TableCreatorDropdownContext";
import { TableCreatorFocusStatusProvider } from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { TableCreatorFormatErrorPressIdProvider } from "@FillInTableContainer/shared/context/TableCreatorFormatErrorPressIdContext";
import { TableCreatorHistoryProvider } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import { TableCreatorPositionInfoProvider } from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { TableCreatorWrapperPositionInfoProvider } from "@FillInTableContainer/shared/context/TableCreatorWrapperPositionContext";
import { TrialInterventionDrugListProvider } from "@FillInTableContainer/shared/context/TrialInterventionDrugListContext";
import { ValidatedInTableCreatorProvider } from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";

type Props = {
  children: React.ReactNode;
};

const FillInTableProvider = ({ children }: Props) => {
  return (
    <TableCreatorFormatErrorPressIdProvider>
      <FillInTableRefMapProvider>
        <TableCreatorPositionInfoProvider>
          <TableCreatorFocusStatusProvider>
            <ValidatedInTableCreatorProvider>
              <TableCreatorDropdownProvider>
                <TableCreatorWrapperPositionInfoProvider>
                  <TableCreatorHistoryProvider>
                    <CheckCurrentDealTypeProvider>
                      <TrialInterventionDrugListProvider>
                        <ModalityCategoryProvider>
                          {children}
                        </ModalityCategoryProvider>
                      </TrialInterventionDrugListProvider>
                    </CheckCurrentDealTypeProvider>
                  </TableCreatorHistoryProvider>
                </TableCreatorWrapperPositionInfoProvider>
              </TableCreatorDropdownProvider>
            </ValidatedInTableCreatorProvider>
          </TableCreatorFocusStatusProvider>
        </TableCreatorPositionInfoProvider>
      </FillInTableRefMapProvider>
    </TableCreatorFormatErrorPressIdProvider>
  );
};

export default FillInTableProvider;
