"use client";

import styled from "styled-components";

export const OrganizedDrugProfileWrapper = styled.div`
  width: 100rem;
`;

export const FixedWidthShell = styled.div<{ $width: string }>`
  width: ${({ $width }) => $width};
`;

export const OrganizedDrugProfileTableWrapper = styled.div<{
  $rowCount: number;
  $columnWidthList: string[];
}>`
  position: relative;
  display: grid;
  grid-template-columns: ${({ $columnWidthList }) =>
    $columnWidthList.join(" ")};
  grid-template-rows: ${({ $rowCount }) =>
    `repeat(${$rowCount}, minmax(5.6rem, auto))`};
  gap: 0.1rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.neural.gray03};

  &:last-child {
    border-bottom: none;
  }
`;

export const CompetitiveLandScapeTitle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 3.6rem;

  color: ${({ theme }) => theme.color.neural.gray07};
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 100%;
  background-color: ${({ theme }) => theme.color.neural.gray02};
  padding: 0rem 1.2rem;
`;
