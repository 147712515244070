import styled from "styled-components";

import Button from "@atoms/Button/Button";
import { mq } from "@styles/mediaQuery";

export const PressListTableFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 2.4rem;
  column-gap: 4rem;

  ${[mq[1]]} {
    min-width: 91rem;
  }
`;

export const TableFilterColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.4rem;

  &:first-child {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FilterButton = styled(Button)<{ buttonWidth?: number }>`
  flex-shrink: 0;
  gap: 0.2rem;
  min-width: ${({ buttonWidth }) => buttonWidth}rem;
  font-weight: 600;
  padding: 0 0.95rem;
`;
