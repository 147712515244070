import ComponentErrorBoundary from "@/components/shared/business/ComponentErrorBoundary/ComponentErrorBoundary";
import { TableCreatorGroupTableTitle } from "@FillInTableContainer/shared";
import TableCreatorNavigationTableWrapper from "@FillInTableContainer/shared/components/TableCreatorNavigationTableWrapper/TableCreatorNavigationTableWrapper";

import DealsDealTermsTableCreatorContainer from "./components/DealsDealTermsTableCreatorContainer/DealsDealTermsTableCreatorContainer";
import DealsDealTypeTableCreatorContainer from "./components/DealsDealTypeTableCreatorContainer/DealsDealTypeTableCreatorContainer";
import DealsDrugProfileGroupTable from "./components/DealsDrugProfileGroupTable/DealsDrugProfileGroupTable";
import DealsKeyCommentGroupTable from "./components/DealsKeyCommentGroupTable/DealsKeyCommentGroupTable";

const DealsTableCreator = () => {
  const errorWithComponent = (title: string) => (
    <TableCreatorGroupTableTitle title={title} isRequired={false} />
  );
  return (
    <TableCreatorNavigationTableWrapper classificationKey="Deals">
      <>
        <ComponentErrorBoundary
          addComponents={errorWithComponent("Drug Profile")}
        >
          <DealsDrugProfileGroupTable />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary
          addComponents={errorWithComponent("Deal Type & Status")}
        >
          <DealsDealTypeTableCreatorContainer />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary
          addComponents={errorWithComponent("Deal Terms")}
        >
          <DealsDealTermsTableCreatorContainer />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary
          addComponents={errorWithComponent("Key Comments")}
        >
          <DealsKeyCommentGroupTable />
        </ComponentErrorBoundary>
      </>
    </TableCreatorNavigationTableWrapper>
  );
};

export default DealsTableCreator;
