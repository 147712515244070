import { useSuspenseQuery } from "@tanstack/react-query";

import { queryClient } from "@api/queryClient";
import { QUERY_KEYS } from "@api/queryKeys";
import {
  InsertImageFilter,
  useInsertImageFilter,
} from "@InsertImageContainer/shared/contexts/InsertImageFilterContext";

import { PostImageSearchFilterResponse } from "./imageSearch.interface";
import { imageSearchApis } from "./imageSearch.service";

export const useImageSearchQuery = () => {
  const { insertImageFilter: filter } = useInsertImageFilter();

  const query = useSuspenseQuery({
    queryKey: [QUERY_KEYS.imageSearch, filter],
    queryFn: async () => imageSearchApis.search(filter),
  });

  return query;
};

export const getImageSearchData = (filter: InsertImageFilter) => {
  return queryClient.getQueryData<PostImageSearchFilterResponse>([
    QUERY_KEYS.imageSearch,
    filter,
  ]);
};
