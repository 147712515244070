import { useCallback } from "react";

import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS as DRUG_PROFILE_SHELL_KEYS,
} from "@frontend/common";

import {
  DEFAULT_FOCUS_STATUS,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useCheckDisabledDelete } from "@FillInTableContainer/shared/hooks/useCheckDisabledDelete";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { TableInfoProps } from "../VentureCapitalTableCreatorTable";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorTableReturn {
  handleClickDrugProfileDeleteButton: () => void;
  handleKeyDownToDeleteDrugProfileTable: (event: KeyboardEvent) => void;
}

const articleSection = SECTION_KEYS.ventureCapital;
export const useHandleVentureCapitalDrugProfileTable = (
  tablePropsInfo: TableInfoProps,
): UseHandleTableCreatorTableReturn => {
  const { groupTableKey, tableKey, groupTableIndex, tableIndex } =
    tablePropsInfo;

  const { changeCurrentHistory } = useTableCreatorCurrentHistory();

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { checkDisabledDeleteTable } = useCheckDisabledDelete();

  const drugProfileTableLength =
    entryData[articleSection]?.[DRUG_PROFILE]?.length || 0;

  const handleClearTable = useCallback(() => {
    const clearDrugProfile = (): ArticleEntryData => {
      return {
        ...entryData,
        [SECTION_KEYS.ventureCapital]: {
          ...entryData?.[SECTION_KEYS.ventureCapital],
          [DRUG_PROFILE]: entryData?.[SECTION_KEYS.ventureCapital]?.[
            DRUG_PROFILE
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [DRUG_PROFILE_SHELL_KEYS.brandName]: {
                  ...tableInfo?.[DRUG_PROFILE_SHELL_KEYS.brandName],
                  value: [],
                },
                [DRUG_PROFILE_SHELL_KEYS.codeName]: {
                  ...tableInfo?.[DRUG_PROFILE_SHELL_KEYS.codeName],
                  value: [],
                },
                [DRUG_PROFILE_SHELL_KEYS.innName]: {
                  ...tableInfo?.[DRUG_PROFILE_SHELL_KEYS.innName],
                  value: [],
                },
                [DRUG_PROFILE_SHELL_KEYS.moa]: {
                  ...tableInfo?.[DRUG_PROFILE_SHELL_KEYS.moa],
                  value: [],
                },
                [DRUG_PROFILE_SHELL_KEYS.therapeuticArea]: {
                  ...tableInfo?.[DRUG_PROFILE_SHELL_KEYS.therapeuticArea],
                  value: [],
                },
                [DRUG_PROFILE_SHELL_KEYS.modality]: {
                  ...tableInfo?.[DRUG_PROFILE_SHELL_KEYS.modality],
                  value: [],
                },
                [DRUG_PROFILE_SHELL_KEYS.otherCharacteristics]: {
                  ...tableInfo?.[DRUG_PROFILE_SHELL_KEYS.otherCharacteristics],
                  value: [],
                },
                [DRUG_PROFILE_SHELL_KEYS.roa]: {
                  ...tableInfo?.[DRUG_PROFILE_SHELL_KEYS.roa],
                  value: [],
                },
                [DRUG_PROFILE_SHELL_KEYS.target]: {
                  ...tableInfo?.[DRUG_PROFILE_SHELL_KEYS.target],
                  value: [],
                },
                [DRUG_PROFILE_SHELL_KEYS.targetIndication]: {
                  ...tableInfo?.[DRUG_PROFILE_SHELL_KEYS.targetIndication],
                  value: [],
                },
                [DRUG_PROFILE_SHELL_KEYS.phase]: {
                  ...tableInfo?.[DRUG_PROFILE_SHELL_KEYS.phase],
                  value: [],
                },
                [DRUG_PROFILE_SHELL_KEYS.originatorDeveloper]: {
                  ...tableInfo?.[DRUG_PROFILE_SHELL_KEYS.originatorDeveloper],
                  value: [],
                },
                [DRUG_PROFILE_SHELL_KEYS.genericBiosimilar]: {
                  ...tableInfo?.[DRUG_PROFILE_SHELL_KEYS.genericBiosimilar],
                  value: ["false"],
                },
                [DRUG_PROFILE_SHELL_KEYS.refProductName]: {
                  ...tableInfo?.[DRUG_PROFILE_SHELL_KEYS.refProductName],
                  value: [],
                },
              };
            }
            return tableInfo;
          }),
        },
      };
    };

    if (info.groupTableKey === DRUG_PROFILE) {
      setEntryData(clearDrugProfile());
      changeCurrentHistory({
        entryData: clearDrugProfile(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      if (drugProfileTableLength > 1 && !checkDisabledDeleteTable()) {
        changeFocusStatus({ type: "delete", container: "table" });
      } else {
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
      }
    }
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    checkDisabledDeleteTable,
    drugProfileTableLength,
    entryData,
    groupTableIndex,
    groupTableKey,
    info.groupTableKey,
    info.tableIndex,
    setEntryData,
    tableIndex,
    tableKey,
  ]);

  const handleDeleteTable = useCallback(() => {
    if (checkDisabledDeleteTable()) {
      return;
    }
    if (drugProfileTableLength > 1) {
      changeFocusStatus(DEFAULT_FOCUS_STATUS);

      const deleteDrugProfile = (): ArticleEntryData => {
        return {
          ...entryData,
          [SECTION_KEYS.ventureCapital]: {
            ...entryData?.[SECTION_KEYS.ventureCapital],
            [DRUG_PROFILE]: entryData?.[SECTION_KEYS.ventureCapital]?.[
              DRUG_PROFILE
            ]?.filter(
              (tableInfo, tableInfoIndex) => tableInfoIndex !== info.tableIndex,
            ),
          },
        };
      };
      if (info.groupTableKey === DRUG_PROFILE) {
        setEntryData(deleteDrugProfile());
        changeCurrentHistory({
          entryData: deleteDrugProfile(),
          changedDataInfo: {
            target: "table",
            action: "delete",
            position: {
              groupTableKey,
              groupTableIndex,
              tableKey,
              tableIndex,
              groupShellKey: null,
              groupShellIndex: null,
              shellKey: null,
              shellIndex: null,
              valueIndex: null,
              value: null,
            },
          },
        });
        changeInfo(DEFAULT_POSITION_INFO);
      }
    }
  }, [
    drugProfileTableLength,
    entryData,
    groupTableIndex,
    groupTableKey,
    info,
    tableIndex,
    tableKey,
    setEntryData,
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    checkDisabledDeleteTable,
  ]);

  const handleClickDrugProfileDeleteButton = () => {
    if (focusStatus.type === "clear") handleClearTable();
    if (focusStatus.type === "delete") handleDeleteTable();
  };

  const handleKeyDownToDeleteDrugProfileTable = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearTable();
      }
      if (focusStatus.type === "delete" && event.key === "Delete") {
        handleDeleteTable();
      }
      changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    },
    [
      focusStatus.type,
      changeValidatedInfo,
      handleClearTable,
      handleDeleteTable,
    ],
  );

  return {
    handleClickDrugProfileDeleteButton,
    handleKeyDownToDeleteDrugProfileTable,
  };
};
