"use client";

import styled, { css } from "styled-components";

export const ModalityIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Spacing-4, 0.4rem);
`;

export const IconBackground = styled.div<{
  $backgroundColor?: string;
  $backgroundType: "gray" | "blue" | "default" | "transparent";
}>`
  ${({ $backgroundType }) =>
    $backgroundType === "gray" &&
    css`
      .modality-icon-background {
        background: var(--Color-Common-Bg-Secondary, #f0f2f4);
        border: none;
      }
    `}

  ${({ $backgroundType }) =>
    $backgroundType === "blue" &&
    css`
      .modality-icon-background {
        border: 0.1rem solid
          var(--Color-Common-Border-Interactive-Primary-Subtle, #d1efff);
        background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #e6f6ff);
      }
    `}

    

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      .modality-icon-background {
        background-color: ${$backgroundColor};
      }
    `}
`;
