import React from "react";

import * as S from "./TableShellTitle.style";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  width?: string;
  padding?: string;
  children: React.ReactNode;
}

const TableShellTitle = ({ width, children, padding, ...rest }: Props) => {
  return (
    <S.TitleShell $width={width} $padding={padding} {...rest}>
      {children}
    </S.TitleShell>
  );
};

export default TableShellTitle;
