import React from "react";

import Modal from "@atoms/Modal/Modal";
import useModal from "@hooks/useModal";
import UploadImageModalContainer from "@organisms/UploadImageModalContainer/UploadImageModalContainer";

import * as S from "./InsertImageUploadButton.style";

interface Props {
  canUploadDB: boolean;
  isDisabledImagePreview: boolean;
  uploadImageToStorage: () => void;
  handleArticleImagePreview: () => void;
  checkedUploadedImage: () => boolean;
}

const UPLOAD_IMAGE_BUTTON_TEXT = "Upload Image to DB Library";
const UPLOADED_IMAGE_BUTTON_TEXT = "Image Uploaded";

const InsertImageUploadButton = ({
  canUploadDB,
  isDisabledImagePreview,
  uploadImageToStorage,
  handleArticleImagePreview,
  checkedUploadedImage,
}: Props) => {
  const { isModal, openModal, closeModal } = useModal();

  return (
    <S.ImageSourceButtonBox>
      <S.PreviewButton
        onClick={handleArticleImagePreview}
        disabled={isDisabledImagePreview}
      >
        Article Image Preview
      </S.PreviewButton>
      <S.UploadButton
        onClick={openModal}
        disabled={!canUploadDB || checkedUploadedImage()}
      >
        {checkedUploadedImage()
          ? UPLOADED_IMAGE_BUTTON_TEXT
          : UPLOAD_IMAGE_BUTTON_TEXT}
      </S.UploadButton>
      {isModal && (
        <Modal closeModal={closeModal}>
          <UploadImageModalContainer
            closeModal={closeModal}
            uploadImageToStorage={uploadImageToStorage}
          />
        </Modal>
      )}
    </S.ImageSourceButtonBox>
  );
};

export default InsertImageUploadButton;
