import styled, { css } from "styled-components";

export const DividerWrapper = styled.button<{
  isLastPosition: boolean;
  isVisibility: boolean;
}>`
  /* position: absolute;
  top: -2rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1.6rem;
  padding: 0;
  cursor: pointer;
  z-index: 25;

  &:hover * {
    opacity: 1;
  }

  /* ${({ isLastPosition }) =>
    isLastPosition &&
    css`
      top: 100%;
    `} */

  ${({ isVisibility }) =>
    !isVisibility &&
    css`
      visibility: hidden;
    `}

  &:active {
    .divider-plus-bar {
      border-radius: var(--Border-Radius-circle, 99999.9rem);
      background: var(--Color-Bg-Interactive-Selected-Pressed, #76cffe);
    }

    .divider-plus-button {
      background: var(--Color-Bg-Interactive-Primary-Pressed, #017abc);
      outline: 2px solid
        var(--Color-Border-Interactive-Primary-Subtle-Pressed, #76cffe);
    }
  }
`;

export const PlusBar = styled.div`
  position: relative;
  top: 0;
  width: calc(100% - 1.6rem);
  height: 0.8rem;
  border-radius: var(--Border-Radius-circle, 99999.9rem);
  background: var(--Color-Bg-Interactive-Selected-Hovered, #a4dfff);
  opacity: 0;
`;

export const PlusButton = styled.button`
  position: absolute;
  top: -0.3rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 0.4rem;
  height: 1.6rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--Color-Text-Interactive-Inverse, #fff);
  padding: 0.2rem 0.4rem;
  border-radius: 0.2rem;
  outline: 2px solid
    var(--Color-Border-Interactive-Primary-Subtle-Hovered, #a4dfff);
  background: var(--Color-Bg-Interactive-Primary-Hovered, #0198e9);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.16);

  white-space: nowrap;
  cursor: pointer;
  opacity: 0;
  text-transform: uppercase;
`;
