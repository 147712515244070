import { useEffect } from "react";

export const useChangeBackgroundColor = (color: string) => {
  useEffect(() => {
    const prevColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = color;

    return () => {
      document.body.style.backgroundColor = prevColor;
    };
  });
};
