import { hideScrollbarY } from "@frontend/common/src/styles/mixin";
import styled from "styled-components";

import { ShellColumnSize } from "@FillInTableContainer/shared/types/shellSize.types";

export const ListBoxWrapper = styled.div<{ columnSize: ShellColumnSize }>`
  position: absolute;
  left: -0.1rem;
  top: calc(100% + 0.1rem);
  width: calc(100% + 0.2rem);
  max-height: 32rem;
  background-color: ${({ theme }) => theme.color.neural.white};
  border: 0.1rem solid ${({ theme }) => theme.color.neural.gray03};
  border-radius: 0 0 0.4rem 0.4rem;
  padding: 0rem 0rem 1.6rem;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.08);
  z-index: 12;
`;

export const ListScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 28rem;

  ${hideScrollbarY};
`;

export const DropDownTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 130%;
  border-bottom: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
  padding: var(--Spacing-8, 0.8rem);
`;

export const DropDownContentsWrapper = styled.div`
  width: 100%;
  padding: 0 0.8rem;
`;
export const DropDownItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  width: 100%;
  background-color: var(--Color-Common-Border-Tertiary, #e5e7eb);
`;

export const DropDownItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.8rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  background-color: #fff;
  padding: 0.8rem 0.6rem;
`;
