import { useCallback } from "react";

import {
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL,
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_LICENSOR_TABLE,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
  LICENSE_AGREEMENT_GROUP_SHELL_KEYS,
  LICENSE_AGREEMENT_SHELL_KEYS,
  LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
  LICENSE_AGREEMENT_TABLE_KEYS,
} from "@frontend/common";

import {
  DealsSection,
  LicensorTableKey,
  MilestoneKey,
  OtherPaymentAndDetailKeys,
  RightsScopeKey,
  StrategicCollaborationAndLicenseAgreementTermsKey,
} from "@/types/deals.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorShellReturn {
  handleClickToClearLicenseeTableShell: () => void;
  handleKeyDownToClearLicenseeTableShell: (event: KeyboardEvent) => void;
}

interface PositionInfoProps {
  groupTableKey: DealsSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  groupShellIndex: number;

  subGroupShellIndex: number | null;
  subGroupShellKey: string | null;
  tableKey: string | null;
  tableIndex: number;
  shellKey: string;
  shellIndex: number;
}

export const useHandleDealsLicenseeTableShell = (
  positionPropsInfo: PositionInfoProps,
): UseHandleTableCreatorShellReturn => {
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const handleClearShell = useCallback(() => {
    const clearLicensorTable = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData?.[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSOR_TABLE]: {
            ...entryData?.Deals?.[ENTRY_DATA_LICENSOR_TABLE],
            [info.shellKey as LicensorTableKey]: {
              ...entryData?.Deals?.[ENTRY_DATA_LICENSOR_TABLE]?.[
                info.shellKey as LicensorTableKey
              ],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    const clearLicenseeNameShell = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName]: {
                  ...tableInfo[ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName],
                  value: [],
                },
              };
            }
            return tableInfo;
          }),
        },
      } as ArticleEntryData;
    };

    const clearRightScopeShell = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope]: tableInfo[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope
                ].map((groupShellInfo, groupShellIndex) => {
                  if (groupShellIndex === info.groupShellIndex) {
                    return {
                      ...groupShellInfo,
                      [info.shellKey as RightsScopeKey]: {
                        ...groupShellInfo?.[info.shellKey as RightsScopeKey],
                        value: [],
                      },
                    };
                  }

                  return groupShellInfo;
                }),
              };
            }
            return tableInfo;
          }),
        },
      } as ArticleEntryData;
    };

    const clearStrategicCollaborationAndLicense = (): ArticleEntryData => {
      return {
        ...entryData,
        Deals: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                  {
                    ...tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ],
                    [info.shellKey as StrategicCollaborationAndLicenseAgreementTermsKey]:
                      {
                        ...tableInfo[
                          ENTRY_DATA_LICENSEE_TABLE_KEYS
                            .strategicCollaborationLicenseAgreementTerms
                        ]?.[
                          info.shellKey as StrategicCollaborationAndLicenseAgreementTermsKey
                        ],
                        value: [],
                      },
                  },
              };
            }
            return tableInfo;
          }),
        },
      } as ArticleEntryData;
    };

    const clearMilestone = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                  {
                    ...tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ],
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.milestonePaymentAndCondition]:
                      tableInfo[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ][
                        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                          .milestonePaymentAndCondition
                      ]?.map((subGroupShellInfo, subGroupShellIndex) => {
                        if (subGroupShellIndex === info.subGroupShellIndex)
                          return {
                            ...subGroupShellInfo,
                            [info.shellKey as MilestoneKey]: {
                              ...subGroupShellInfo[
                                info.shellKey as MilestoneKey
                              ],
                              value: [],
                            },
                          };

                        return subGroupShellInfo;
                      }),
                  },
              };
            }
            return tableInfo;
          }),
        },
      } as ArticleEntryData;
    };

    const clearOtherPayment = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData.Deals?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                  {
                    ...tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ],
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa]:
                      tableInfo[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ][
                        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                          .otherPaymentAndDetailScLa
                      ]?.map((subGroupShellInfo, subGroupShellIndex) => {
                        if (subGroupShellIndex === info.subGroupShellIndex)
                          return {
                            ...subGroupShellInfo,
                            [info.shellKey as OtherPaymentAndDetailKeys]: {
                              ...subGroupShellInfo[
                                info.shellKey as OtherPaymentAndDetailKeys
                              ],
                              value: [],
                            },
                          };

                        return subGroupShellInfo;
                      }),
                  },
              };
            }
            return tableInfo;
          }),
        },
      } as ArticleEntryData;
    };

    const clearAdditionalInformationTable = (): ArticleEntryData => {
      const additionalInformationTableData =
        entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL
        ];

      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL]: {
            ...additionalInformationTableData,
            [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.additionalInformation]:
              {
                ...additionalInformationTableData?.[
                  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS
                    .additionalInformation
                ],
                value: [],
              },
          },
        },
      } as ArticleEntryData;
    };

    const clearDealPurposeTable = (): ArticleEntryData => {
      const dealPurposeTableData =
        entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL
        ];

      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL]: {
            ...dealPurposeTableData,
            [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.purposeOfTheDeal]: {
              ...dealPurposeTableData?.[
                ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.purposeOfTheDeal
              ],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    if (info.tableKey === LICENSE_AGREEMENT_TABLE_KEYS.licensor) {
      setEntryData(clearLicensorTable());
      changeCurrentHistory({
        entryData: clearLicensorTable(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    if (info.tableKey === LICENSE_AGREEMENT_TABLE_KEYS.dealPurpose) {
      setEntryData(clearDealPurposeTable());
      changeCurrentHistory({
        entryData: clearDealPurposeTable(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    if (info.tableKey === LICENSE_AGREEMENT_TABLE_KEYS.additionalInformation) {
      setEntryData(clearAdditionalInformationTable());
      changeCurrentHistory({
        entryData: clearAdditionalInformationTable(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    if (info.shellKey === LICENSE_AGREEMENT_SHELL_KEYS.licenseeName) {
      setEntryData(clearLicenseeNameShell());
      changeCurrentHistory({
        entryData: clearLicenseeNameShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    if (info.groupShellKey === LICENSE_AGREEMENT_GROUP_SHELL_KEYS.rightsScope) {
      setEntryData(clearRightScopeShell());
      changeCurrentHistory({
        entryData: clearRightScopeShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    const isClearStrategicCollaborationAndLicenseAgreementTable =
      ((info.groupShellKey ===
        LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTerms ||
        info.groupShellKey ===
          LICENSE_AGREEMENT_GROUP_SHELL_KEYS.otherFinancialTerms) &&
        info.subGroupShellKey !==
          LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.milestone) ||
      info.shellKey ===
        LICENSE_AGREEMENT_SHELL_KEYS.licenseeResponsibilityRightsScLa;

    if (isClearStrategicCollaborationAndLicenseAgreementTable) {
      setEntryData(clearStrategicCollaborationAndLicense());
      changeCurrentHistory({
        entryData: clearStrategicCollaborationAndLicense(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    if (
      info.subGroupShellKey === LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.milestone
    ) {
      setEntryData(clearMilestone());
      changeCurrentHistory({
        entryData: clearMilestone(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    if (
      info.subGroupShellKey ===
      LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail
    ) {
      setEntryData(clearOtherPayment());
      changeCurrentHistory({
        entryData: clearOtherPayment(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  const handleClickToClearLicenseeTableShell = () => {
    if (focusStatus.type === "clear") {
      handleClearShell();
    }
  };

  const handleKeyDownToClearLicenseeTableShell = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearShell();
      }
    },
    [focusStatus.type, handleClearShell],
  );

  return {
    handleClickToClearLicenseeTableShell,
    handleKeyDownToClearLicenseeTableShell,
  };
};
