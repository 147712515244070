"use client";

import { css } from "styled-components";

export const gradient1Text = css`
  background: ${({ theme }) => theme.color.gradient.background1};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const scrollBarStyle = css`
  &::-webkit-scrollbar {
    width: 0.8rem;
    background-color: var(--Color-Common-Bg-Secondary, #f0f2f4);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: var(--Color-Common-Bg-Tertiary, #cbd0d8);

    &:hover {
      background: var(--Color-Common-Icon-Interactive-Primary-Hovered, #017abc);
    }

    &:active {
      background: var(--Color-Common-Icon-Interactive-Primary-Pressed, #015d8e);
    }
  }
`;

export const hideScrollbar = css`
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: contain;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const hideScrollbarX = css`
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: contain;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const hideScrollbarY = css`
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: contain;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const disabledTarget = css`
  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

export const fillInTableTableContainer = css`
  padding: 0.8rem;

  &.active-blue-table {
    background: rgba(11, 108, 255, 0.1);
    border-radius: 1.6rem;
  }

  &.active-red-table {
    background: rgba(251, 110, 82, 0.1);
    border-radius: 1.6rem;
  }
`;

export const fillInTableSectionBody = css`
  & > .droppable-container {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
`;

export const preventImgSelect = css`
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

export const preventImgDrag = css`
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
`;
