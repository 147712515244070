import React from "react";

import iconPlus from "@images/fillInTable/tableCreator/icon-plus-white.svg";

import * as S from "./ShellDividerPlusBar.style";

interface Props {
  isPositionRight: boolean;
  onClick: () => void;
}

const ShellDividerPlusBar = ({ isPositionRight, onClick }: Props) => {
  return (
    <S.ShellDividerWrapper isPositionRight={isPositionRight} onClick={onClick}>
      <S.ShellDividerPlusBar className="table-divider-plus-bar" />
      <S.PlusButton>
        <img src={iconPlus} alt="add shell" />
      </S.PlusButton>
    </S.ShellDividerWrapper>
  );
};

export default ShellDividerPlusBar;
