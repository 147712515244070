/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import * as S from "./DealTermsBottomTableLayout.style";
import { useCompareHeightOfTableContainer } from "./hooks/useCompareHeightOfTableContainer";
import { ARTICLE_DETAIL_DEAL_TERMS_MA_GROUP_SHELL_TITLE as GROUP_SHELL_TITLE } from "../../../business/ArticleDetail";
import { ARTICLE_CONTENTS_DEAL_TERMS_KEYS as DEAL_TERMS_KEYS } from "../../../constants/articleContents";
import {
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
} from "../../../shared/components/core";
import { useCheckWindowSize } from "../../../shared/hooks/useCheckWindowSize";
import { EDITABLE_SHELL_PADDING } from "../../EditArticle";
import DealTermArrow from "../DealTermArrow/DealTermArrow";
import TableTitle from "../TableTitle/TableTitle";

type TableItem = { title: React.ReactNode; value: React.ReactNode };
type Props = {
  tableItems: (TableItem[] | TableItem)[][][];
  headerItems: React.ReactNode[];
  isEditable?: boolean;
  isBackgroundArrow?: boolean;
};

const DealTermsBottomTableLayout = ({
  tableItems,
  headerItems,
  isEditable = false,
  isBackgroundArrow = true,
}: Props) => {
  const { isMobileScreen } = useCheckWindowSize();
  const { leftContainerRef, rightContainerRef, smallerTable } =
    useCompareHeightOfTableContainer();

  const leftTableItems = tableItems
    .map((tableItem) => tableItem[0])
    .filter((tableItem) => tableItem.length > 0);
  const rightTableItems = tableItems
    .map((tableItem) => tableItem[1])
    .filter((tableItem) => tableItem.length > 0);

  const isLeftTableEmpty = leftTableItems.length === 0;
  const isRightTableEmpty = rightTableItems.length === 0;

  const checkTypeArray = (items: TableItem | TableItem[]) => {
    return Array.isArray(items);
  };

  const checkArrowType = () => {
    if (!isLeftTableEmpty && !isRightTableEmpty) return "both";
    if (isLeftTableEmpty && !isRightTableEmpty) return "right";
    if (!isLeftTableEmpty && isRightTableEmpty) return "left";
    return "both";
  };

  const arrowType = checkArrowType();
  const isArrowVisible =
    arrowType === "both" &&
    isBackgroundArrow &&
    !isLeftTableEmpty &&
    !isRightTableEmpty;

  if (isMobileScreen) return null;
  return (
    <S.TableContentsWrapper $containerDirection={arrowType}>
      {!isLeftTableEmpty && (
        <S.TableGroupWrapper
          ref={leftContainerRef}
          $isArrowVisible={isArrowVisible}
        >
          <TableTitle title={headerItems[0]} />
          <S.TableWrapper>
            {leftTableItems.map((groupTable) => (
              <TableContainer>
                {(groupTable as TableItem[] | TableItem[][]).map(
                  (tableItem) => {
                    if (checkTypeArray(tableItem)) {
                      return (
                        <>
                          {(tableItem as TableItem[])?.[0]?.title && (
                            <TableRowContainer>
                              {(tableItem as TableItem[]).map((item) => (
                                <TableShellTitle key={String(item.title)}>
                                  {item.title}
                                </TableShellTitle>
                              ))}
                            </TableRowContainer>
                          )}
                          <TableRowContainer>
                            {(tableItem as TableItem[]).map((item) => (
                              <TableShellValue
                                key={String(item.value)}
                                padding={
                                  isEditable ? EDITABLE_SHELL_PADDING : "0.8rem"
                                }
                              >
                                {item.value}
                              </TableShellValue>
                            ))}
                          </TableRowContainer>
                        </>
                      );
                    }

                    return (
                      <>
                        {!!(tableItem as TableItem).title && (
                          <TableRowContainer key={String(tableItem)}>
                            <TableShellTitle>
                              {(tableItem as TableItem)?.title}
                            </TableShellTitle>
                          </TableRowContainer>
                        )}
                        {(tableItem as TableItem).value !== null && (
                          <TableRowContainer key={String(tableItem)}>
                            <TableShellValue
                              padding={
                                isEditable ? EDITABLE_SHELL_PADDING : "0.8rem"
                              }
                            >
                              {(tableItem as TableItem).value}
                            </TableShellValue>
                          </TableRowContainer>
                        )}
                      </>
                    );
                  },
                )}
              </TableContainer>
            ))}
          </S.TableWrapper>
          {smallerTable === "left" && isBackgroundArrow && (
            <S.ArrowContainer
              style={{
                left: "100%",
              }}
            >
              <DealTermArrow arrowType={arrowType} />
            </S.ArrowContainer>
          )}
        </S.TableGroupWrapper>
      )}
      {!isRightTableEmpty && (
        <S.TableGroupWrapper
          ref={rightContainerRef}
          $isArrowVisible={isArrowVisible}
        >
          <TableTitle title={headerItems[1]} />
          <S.TableWrapper>
            {rightTableItems.map((groupTable) => (
              <TableContainer>
                {(groupTable as TableItem[] | TableItem[][])?.map(
                  (tableItem) => {
                    if (checkTypeArray(tableItem)) {
                      return (
                        <>
                          {(tableItem as TableItem[])?.[0]?.title && (
                            <TableRowContainer className="border-bottom-none">
                              {(tableItem as TableItem[]).map((item) => (
                                <TableShellTitle key={String(item.title)}>
                                  {item.title}
                                </TableShellTitle>
                              ))}
                            </TableRowContainer>
                          )}
                          {(() => {
                            const hasValueText = (item: TableItem) =>
                              React.isValidElement(item?.value) &&
                              !!item?.value?.props?.children?.[1]?.props?.item
                                ?.text;

                            const itemInRowClass = `item-in-row-${(
                              tableItem as TableItem[]
                            ).some(hasValueText)}`;
                            const itemInRowCountClass = `item-in-row-count-${
                              (tableItem as TableItem[]).filter(hasValueText)
                                .length
                            }`;

                            return (
                              (tableItem as TableItem[])?.at(0)?.value !==
                                null && (
                                <TableRowContainer
                                  className={`${itemInRowClass} ${itemInRowCountClass} border-bottom-none`}
                                >
                                  {(tableItem as TableItem[]).map(
                                    (item, index) => (
                                      <TableShellValue
                                        key={String(item?.value)}
                                        padding={
                                          isEditable
                                            ? EDITABLE_SHELL_PADDING
                                            : "0.8rem"
                                        }
                                        className={`existed-value-text-${hasValueText(
                                          item,
                                        )} existed-value-text-${index}`}
                                      >
                                        {item?.value}
                                      </TableShellValue>
                                    ),
                                  )}
                                </TableRowContainer>
                              )
                            );
                          })()}
                        </>
                      );
                    }

                    return (
                      <>
                        {!!(tableItem as TableItem).title && (
                          <TableRowContainer
                            key={String(tableItem)}
                            className={`border-bottom-none-${
                              (tableItem as TableItem)?.title ===
                              GROUP_SHELL_TITLE[
                                DEAL_TERMS_KEYS.contingentPayment
                              ]
                            }`}
                          >
                            <TableShellTitle>
                              {(tableItem as TableItem)?.title}
                            </TableShellTitle>
                          </TableRowContainer>
                        )}
                        {(tableItem as TableItem).value !== null && (
                          <TableRowContainer key={String(tableItem)}>
                            <TableShellValue
                              padding={
                                isEditable ? EDITABLE_SHELL_PADDING : "0.8rem"
                              }
                            >
                              {(tableItem as TableItem).value}
                            </TableShellValue>
                          </TableRowContainer>
                        )}
                      </>
                    );
                  },
                )}
              </TableContainer>
            ))}
          </S.TableWrapper>
          {smallerTable === "right" && isBackgroundArrow && (
            <S.ArrowContainer>
              <DealTermArrow arrowType={arrowType} />
            </S.ArrowContainer>
          )}
        </S.TableGroupWrapper>
      )}
    </S.TableContentsWrapper>
  );
};

export default DealTermsBottomTableLayout;
