import {
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
} from "@frontend/common";

import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";

import { ClinicalEndpointsDescription } from "../../utils/clinicalEndpointsDescription";

interface Props {
  clinicalEndpointsDescription: ClinicalEndpointsDescription;
}

const COMMON_EDITABLE_TEXT_STYLE = {
  padding: "0.8rem",
  borderRadius: "0",
  lineHeight: "150%",
};
const VALUE_SHELL_PADDING = "0.2rem";

const ClinicalEndpointsDescriptionContent = ({
  clinicalEndpointsDescription,
}: Props) => {
  return (
    <TableContainer>
      <TableRowContainer>
        <TableShellTitle>Endpoint Description & Conclusion</TableShellTitle>
      </TableRowContainer>
      <TableRowContainer>
        <TableShellValue padding={VALUE_SHELL_PADDING}>
          <EditableTextContainer
            item={{
              text: clinicalEndpointsDescription.description.text,
              labels: clinicalEndpointsDescription.description.labels ?? [],
            }}
            canTextEdit
            canLabelEdit
            refKey={clinicalEndpointsDescription.description.key}
            style={COMMON_EDITABLE_TEXT_STYLE}
          />
        </TableShellValue>
      </TableRowContainer>
    </TableContainer>
  );
};

export default ClinicalEndpointsDescriptionContent;
