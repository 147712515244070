import styled from "styled-components";

import { DefaultDraggableGroupShellWrapper } from "@FillInTableContainer/shared/styles/groupShell.style";
import {
  SelectButton,
  SelectButtonDivider,
  SelectButtonDividerWrapper,
} from "@FillInTableContainer/shared/styles/rowSelectButton.style";
import {
  BorderWrapper,
  DefaultDeleteTableButton,
  DefaultDeleteTableButtonWrapper,
} from "@FillInTableContainer/shared/styles/shell.style";

export const ValueRelativeWrapper = styled.div`
  position: relative;
  display: flex;

  .investor-data-divider-plus-bar {
    position: absolute;
    left: 0;
    top: -1.4rem;
    width: 100%;
    z-index: 1;
  }
  .investor-data-divider-plus-bar-last {
    position: absolute;
    left: 0;
    top: calc(100% + -0.1rem);
    width: 100%;
    z-index: 1;
  }
`;

export const DeleteValueButtonWrapper = styled(DefaultDeleteTableButtonWrapper)`
  top: -1.9rem;
  left: 0rem;
  z-index: 10;
  transform: none;
`;

export const DeleteValueButton = styled(DefaultDeleteTableButton)``;

export const DeleteTextButtonWrapper = styled(DefaultDeleteTableButtonWrapper)`
  top: -2.4rem;
  left: 50%;
  z-index: 10;
`;

export const DeleteTextButton = styled(DefaultDeleteTableButton)``;

export const DraggableValueWrapper = styled(
  DefaultDraggableGroupShellWrapper,
)``;

export const TextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
  padding-left: 5.6rem;
`;

export const InvestorValuesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  min-width: 100%;
`;
export const ValueBorderWrapper = styled(BorderWrapper)`
  width: 29.6rem;
  .drag-button-value-investor-name {
    position: absolute;
    top: 50%;
    left: -3.2rem;
    transform: translateY(-50%);
    z-index: 10;
  }
`;

export const CheckboxBorderWrapper = styled(BorderWrapper)`
  width: 4.4rem;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ValueSelectButton = styled(SelectButton)``;

export const ValueSelectButtonDivider = styled(SelectButtonDivider)``;
export const ValueSelectButtonDividerWrapper = styled(
  SelectButtonDividerWrapper,
)`
  position: absolute;
  top: 0;
  left: -5.6rem;
  display: flex;
  width: 1.6rem;
  height: 100%;
`;
