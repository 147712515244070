import { apiClient } from "@api/apiClient";
import {
  GetEntryDataResponse,
  PutEntryDataResponse,
} from "@api/entryData/entryData.interface";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

const API_ENDPOINT = {
  get: "/des/article-entry-data/info",
  update: "/des/article-entry-data/update",
};

export const entryDataApis = {
  get: async (id: string) => {
    const { data } = await apiClient.get<GetEntryDataResponse>(
      `${API_ENDPOINT.get}/${id}`,
    );

    return data;
  },
  update: async (
    id: string,
    entryData: ArticleEntryData,
    isDbSaved: boolean,
    sectionCode: string,
  ) => {
    const { data } = await apiClient.put<PutEntryDataResponse>(
      API_ENDPOINT.update,
      {
        draftArticleId: id,
        entryData,
        articleSection: sectionCode,
        isDbSaved,
      },
    );

    return data;
  },
};
