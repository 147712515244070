import { useRef } from "react";

import {
  DefaultValue,
  atomFamily,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

import { articleEntryDataApis } from "@services/articleEntryData.service";

import { RECOIL_KEYS } from "./recoilKeys";

import type { ArticleData } from "@/types/articleData.types";
import type { ArticleEntryData } from "@/types/articleEntryData.types";

type ArticleId = string;

const articleDataState = atomFamily<ArticleData, ArticleId>({
  key: RECOIL_KEYS.articleDataState,

  default: async (articleId: string) => {
    const { data } = await articleEntryDataApis.get(articleId);
    return data;
  },
});

const articleEntryDataState = selectorFamily<ArticleEntryData, ArticleId>({
  key: RECOIL_KEYS.articleEntryDataState,
  get:
    (articleId) =>
    ({ get }) => {
      const entryData = get(articleDataState(articleId));
      return entryData["Entry Data"];
    },
  set:
    (articleId) =>
    ({ get, set, reset }, newValue) => {
      const entryData = { ...get(articleDataState(articleId)) };

      if (newValue instanceof DefaultValue) {
        reset(articleDataState(articleId));
      } else {
        entryData["Entry Data"] = newValue;
        set(articleDataState(articleId), entryData);
      }
    },
});

export const useArticleDataAtomValue = (articleId: string) =>
  useRecoilValue(articleDataState(articleId));

export const useArticleDataAtomState = (articleId: string) =>
  useRecoilState(articleDataState(articleId));

export const useArticleDataAtomValueRef = (articleId: string) => {
  const articleData = useRecoilValue(articleDataState(articleId));
  const articleDataRef = useRef(articleData);
  articleDataRef.current = articleData;

  return articleDataRef;
};

export const useArticleEntryDataAtomState = (articleId: string) =>
  useRecoilState(articleEntryDataState(articleId));

export const useArticleEntryDataAtomValue = (articleId: string) =>
  useRecoilValue(articleEntryDataState(articleId));

export const useSetArticleEntryDataAtomState = (articleId: string) =>
  useSetRecoilState(articleEntryDataState(articleId));
