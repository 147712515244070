import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS as DRUG_PROFILE_SHELL_KEYS,
  ENTRY_DATA_DRUG_PROFILE_GROUP_SHELL_KEYS as DRUG_PROFILE_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { VentureCapitalGroupTable } from "@/types/ventureCapital.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  handleClickToClearDrugProfileGroupShell: () => void;
  handleKeyDownToClearDrugProfileGroupShell: (event: KeyboardEvent) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: VentureCapitalGroupTable;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}
const articleSection = SECTION_KEYS.ventureCapital;
export const useHandleVentureCapitalDrugProfileGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();

  const handleClearGroupShell = () => {
    const clearDrugProfileGroupShell = (): ArticleEntryData => {
      if (info.groupShellKey === DRUG_PROFILE_GROUP_SHELL_KEYS.drugName)
        return {
          ...entryData,
          [articleSection]: {
            ...entryData[articleSection],
            [DRUG_PROFILE]: entryData[articleSection]?.[DRUG_PROFILE]?.map(
              (tableInfo, tableInfoIndex) => {
                if (tableInfoIndex === info.tableIndex) {
                  return {
                    ...tableInfo,
                    [DRUG_PROFILE_SHELL_KEYS.brandName]: {
                      ...tableInfo[DRUG_PROFILE_SHELL_KEYS.brandName],
                      value: [],
                    },
                    [DRUG_PROFILE_SHELL_KEYS.codeName]: {
                      ...tableInfo[DRUG_PROFILE_SHELL_KEYS.codeName],
                      value: [],
                    },
                    [DRUG_PROFILE_SHELL_KEYS.innName]: {
                      ...tableInfo[DRUG_PROFILE_SHELL_KEYS.innName],
                      value: [],
                    },
                  };
                }
                return tableInfo;
              },
            ),
          },
        };

      return {
        ...entryData,
        [articleSection]: {
          ...entryData[articleSection],
          [DRUG_PROFILE]: entryData[articleSection]?.[DRUG_PROFILE]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  [DRUG_PROFILE_SHELL_KEYS.moa]: {
                    ...tableInfo[DRUG_PROFILE_SHELL_KEYS.moa],
                    value: [],
                  },
                  [DRUG_PROFILE_SHELL_KEYS.modality]: {
                    ...tableInfo[DRUG_PROFILE_SHELL_KEYS.modality],
                    value: [],
                  },
                  [DRUG_PROFILE_SHELL_KEYS.otherCharacteristics]: {
                    ...tableInfo[DRUG_PROFILE_SHELL_KEYS.otherCharacteristics],
                    value: [],
                  },
                  [DRUG_PROFILE_SHELL_KEYS.roa]: {
                    ...tableInfo[DRUG_PROFILE_SHELL_KEYS.roa],
                    value: [],
                  },
                  [DRUG_PROFILE_SHELL_KEYS.target]: {
                    ...tableInfo[DRUG_PROFILE_SHELL_KEYS.target],
                    value: [],
                  },
                  [DRUG_PROFILE_SHELL_KEYS.targetIndication]: {
                    ...tableInfo[DRUG_PROFILE_SHELL_KEYS.targetIndication],
                    value: [],
                  },
                  [DRUG_PROFILE_SHELL_KEYS.therapeuticArea]: {
                    ...tableInfo[DRUG_PROFILE_SHELL_KEYS.therapeuticArea],
                    value: [],
                  },
                  [DRUG_PROFILE_SHELL_KEYS.phase]: {
                    ...tableInfo[DRUG_PROFILE_SHELL_KEYS.phase],
                    value: [],
                  },
                  [DRUG_PROFILE_SHELL_KEYS.originatorDeveloper]: {
                    ...tableInfo[DRUG_PROFILE_SHELL_KEYS.originatorDeveloper],
                    value: [],
                  },
                  [DRUG_PROFILE_SHELL_KEYS.genericBiosimilar]: {
                    ...tableInfo?.[DRUG_PROFILE_SHELL_KEYS.genericBiosimilar],
                    value: ["false"],
                  },
                  [DRUG_PROFILE_SHELL_KEYS.refProductName]: {
                    ...tableInfo[DRUG_PROFILE_SHELL_KEYS.refProductName],
                    value: [],
                  },
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    if (info.groupTableKey === DRUG_PROFILE) {
      setEntryData(clearDrugProfileGroupShell());
      changeCurrentHistory({
        entryData: clearDrugProfileGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  };

  const handleClickToClearDrugProfileGroupShell = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShell();
    }
  };

  const handleKeyDownToClearDrugProfileGroupShell = (event: KeyboardEvent) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearGroupShell();
    }
  };

  return {
    handleClickToClearDrugProfileGroupShell,
    handleKeyDownToClearDrugProfileGroupShell,
  };
};
