import { useUpdateParagraph } from "@EditArticleContainer/shared/hooks/useUpdateParagraph";
import { ParagraphContent } from "@EditArticleContainer/shared/types/paragraphContents/paragraphContents.types";

import DrugProfileTable from "./components/DrugProfileTable/DrugProfileTable";
import * as S from "./DrugProfileTableGroup.style";

interface Props {
  drugProfile: ParagraphContent["TC203"];
}

const DrugProfileTableGroup = ({ drugProfile }: Props) => {
  const [updateDrugProfileTable, loadingDrugProfileTableIndex] =
    useUpdateParagraph({
      paragraphCode: "TC203",
      sectionCode: "TY003",
    });

  return (
    <S.GroupTableWrapper>
      <S.ParagraphDrugProfileUpdateButton
        onClick={() => updateDrugProfileTable(0)}
        disabled={loadingDrugProfileTableIndex === 0}
      />
      {drugProfile?.text?.["Drug profile"]?.map?.((row, index) => {
        return (
          <DrugProfileTable
            key={`regulatory-drug-profile-${String(index)}`}
            row={row}
            index={index}
          />
        );
      })}
    </S.GroupTableWrapper>
  );
};

export default DrugProfileTableGroup;
