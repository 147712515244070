interface Props {
  isDraggingValue: boolean;
  valueListCount: number;
}

const useCheckDisplayValueDragDivider = ({
  isDraggingValue,
  valueListCount,
}: Props) => {
  const isFirstValue = (valueIndex: number | null) => valueIndex === 0;

  const isLastValue = (valueIndex: number | null) =>
    valueIndex === valueListCount;

  const handleCheckDragDividerDisplay = ({
    moveDragIndex,
    valueIndex,
    lastIndex,
    startDragIndex,
  }: {
    moveDragIndex: number | null;
    valueIndex: number;
    lastIndex?: number;
    startDragIndex: number | null;
  }) => {
    if (moveDragIndex === null) return false;
    if (!isDraggingValue) return false;

    if (isFirstValue(startDragIndex) && !lastIndex) {
      return moveDragIndex + 1 === valueIndex;
    }

    if (isLastValue(moveDragIndex)) {
      return moveDragIndex + 1 === lastIndex;
    }

    return moveDragIndex === valueIndex;
  };

  return {
    isFirstValue,
    isLastValue,
    handleCheckDragDividerDisplay,
  };
};

export default useCheckDisplayValueDragDivider;
