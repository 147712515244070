import {
  ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY as FINANCING_INFORMATION,
  ENTRY_DATA_FINANCING_INFORMATION_TABLE_KEYS as FINANCING_INFORMATION_TABLE_KEYS,
  ENTRY_DATA_FINANCING_INFORMATION_GROUP_SHELL_KEYS as FINANCING_INFORMATION_GROUP_SHELL_KEYS,
  ENTRY_DATA_FINANCING_INFORMATION_SHELL_KEYS as FINANCING_INFORMATION_SHELL_KEYS,
} from "@frontend/common";

import { VentureCapitalGroupTable } from "@/types/ventureCapital.types";
import { TableCreatorTableList } from "@FillInTableContainer/shared/types/tableCreator.types";

interface VentureCapitalFundedCompanyLayout {
  groupTableKey: VentureCapitalGroupTable;
  tableList: TableCreatorTableList;
}

export const VENTURE_CAPITAL_FINANCING_INFORMATION_GROUP_TABLE_LAYOUT: VentureCapitalFundedCompanyLayout =
  {
    groupTableKey: FINANCING_INFORMATION,
    tableList: [
      {
        tableKey: FINANCING_INFORMATION_TABLE_KEYS.none,
        groupShellList: [
          {
            groupShellKey: FINANCING_INFORMATION_GROUP_SHELL_KEYS.none,
            shellList: [
              {
                shellKey: FINANCING_INFORMATION_SHELL_KEYS.amount,
              },
            ],
          },
          {
            groupShellKey:
              FINANCING_INFORMATION_GROUP_SHELL_KEYS.financingSummaryInformation,
            shellList: [
              {
                shellKey: FINANCING_INFORMATION_SHELL_KEYS.financingPurpose,
              },
              {
                shellKey:
                  FINANCING_INFORMATION_SHELL_KEYS.financingInformationDetail,
              },
            ],
          },
        ],
      },
    ],
  };
