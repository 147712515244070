import React from "react";

import { IconRotate } from "@frontend/common";

import { useGetAiImageInfoQuery } from "@api/aiImages/aiImages";

import * as S from "./AiImageFilter.style";
import { handleAiImageRegenerate } from "./utils/handleAiImageRegenerate";

const AiImageFilter = () => {
  const { data: aiImageInfo, refetch: refetchAiImageInfo } =
    useGetAiImageInfoQuery();
  const aiStatus = aiImageInfo?.data?.aiImageStatus || "NOT_GENERATED";

  const clickImageRegenerate = () => {
    handleAiImageRegenerate();

    setTimeout(() => {
      refetchAiImageInfo();
    }, 1000);
  };
  return (
    <S.AiImageFilterWrapper>
      <S.AiImageRegenerateButton
        type="button"
        onClick={clickImageRegenerate}
        disabled={["GENERATING", "PENDING"].includes(aiStatus)}
      >
        <IconRotate />
        AI Image Regenerate
      </S.AiImageRegenerateButton>
    </S.AiImageFilterWrapper>
  );
};

export default AiImageFilter;
