import React from "react";

import * as S from "./TableDragDivider.style";

interface Props {
  isDraggingEndPosition: boolean;
}

const TableDragDivider = ({ isDraggingEndPosition }: Props) => {
  return <S.TableDividerBar isDraggingEndPosition={isDraggingEndPosition} />;
};

export default TableDragDivider;
