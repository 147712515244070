import { Suspense } from "react";

import RequiredMark from "@atoms/RequiredMark/RequiredMark";

import ModalityCategoryDropDown from "./components/ModalityCategoryDropDown/ModalityCategoryDropDown";
import { useModalityCategory } from "./hooks/useModalityCategory";
import * as S from "./ModalityCategoryTextFieldContainer.style";

type Props = {
  modalityValue: string;
  modalityValueIndex: number;
};
const ADD_PLACEHOLDER_TEXT = "Add Modality Category";
const DEFAULT_PLACEHOLDER_TEXT = "Modality Category";

const ModalityCategoryTextFieldContainer = ({
  modalityValue,
  modalityValueIndex,
}: Props) => {
  const {
    status,
    modalityCategoryDropdownRef,
    modalityCategoryListForDropDown,
    textFieldValue,
    editableStatus,
    changeCategoryValueList,
    modalityCategoryDropDownOpenControl,
  } = useModalityCategory(modalityValue, modalityValueIndex);

  return (
    <Suspense>
      <S.ModalityCategoryTextFieldWrapper
        $status={status}
        onClick={modalityCategoryDropDownOpenControl}
      >
        {editableStatus.includes(status) && (
          <RequiredMark text="*" className="required-mark" />
        )}
        {editableStatus.includes(status) && textFieldValue}
        {status === "empty" && ADD_PLACEHOLDER_TEXT}
        {status === "defaultEmpty" && DEFAULT_PLACEHOLDER_TEXT}
        {status === "confirmed" && textFieldValue}

        {status === "focused" && (
          <ModalityCategoryDropDown
            ref={modalityCategoryDropdownRef}
            modalityCategoryList={modalityCategoryListForDropDown}
            changeCategoryValueList={changeCategoryValueList}
          />
        )}
      </S.ModalityCategoryTextFieldWrapper>
    </Suspense>
  );
};

export default ModalityCategoryTextFieldContainer;
