import styled from "styled-components";

import { mq } from "@styles/mediaQuery";

export const LayoutCenterWrapper = styled.div`
  width: calc(100vw - 8rem);
  min-height: 100vh;
  padding-top: 4.8rem;
  margin: 0 auto;

  ${[mq[0]]} {
    max-width: calc(100vw - 8rem);
    min-width: 144rem;
  }

  ${[mq[1]]} {
    min-width: 140.8rem;
    width: calc(100vw - 8rem);
    padding: 5.6rem 2rem 0;
  }
`;

export const LayoutFullWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 6rem);
`;

export const LayoutWideWrapper = styled.div`
  min-width: 140rem;
  width: calc(100% - 16rem);
  min-height: calc(100vh - 6rem);
  padding-top: 7.2rem;
  margin: 0 auto;

  ${[mq[1]]} {
    padding: 7.2rem 2rem;
    margin: 0;
    min-width: auto;
    width: 100%;
  }
`;

export const LayoutResponsiveCenterWrapper = styled.div`
  width: calc(100vw - 8rem);
  min-height: calc(100vh - 3.2rem);
  padding-top: 4.8rem;
  margin: 0 auto;

  @media (max-width: 1280px) {
    min-width: 1280px;
    padding: 4.8rem 4rem;
  }
`;
