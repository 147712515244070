import React, { useState } from "react";

import IconArrowDown from "@images/common/icon-select-box-arrow-down.svg";

import * as S from "./SelectBox.style";

interface Props {
  optionList: number[] | string[];
  defaultText?: string;
  onChangeOption?: (value: string) => void;
  selectValue: string | number;
}

const SelectBox = ({
  optionList,
  defaultText,
  onChangeOption,
  selectValue,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDropDown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (
    event: React.MouseEvent<HTMLButtonElement>,
    value: string | number,
  ) => {
    if (onChangeOption) {
      onChangeOption(String(value));
      setIsOpen(false);
    }
  };
  return (
    <S.SelectBoxWrapper>
      <S.SelectBox isOpen={isOpen} onClick={handleOpenDropDown}>
        <span>
          {selectValue} {defaultText}
        </span>
        <img src={IconArrowDown} alt="icon arrow down" />
      </S.SelectBox>
      <S.OptionBox>
        <S.DropDown isOpen={isOpen}>
          {optionList.map((option) => (
            <button
              key={option}
              type="button"
              onClick={(event) => handleSelectOption(event, option)}
            >
              {option}
              {defaultText ?? ""}
            </button>
          ))}
        </S.DropDown>
      </S.OptionBox>
      <S.Background isOpen={isOpen} onClick={() => setIsOpen(false)} />
    </S.SelectBoxWrapper>
  );
};

export default SelectBox;
