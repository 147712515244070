import Modal from "@atoms/Modal/Modal";
import { ROUTE_PATHS } from "@routes/routePath";

import * as S from "./AccessDeniedGuide.style";

const AccessDeniedGuide = () => {
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <Modal>
      <S.UrlPageEntryModalWrapper>
        <S.ModalTitle>Access is not available</S.ModalTitle>
        <S.ModalText>
          Your current permissions do not allow access to the selected press.
          Please contact an administrator to request the necessary permissions.
        </S.ModalText>
        <S.ModalButtonWrapper>
          <S.PreviousPageButton onClick={handleGoBack}>
            Go Back to Previous Page
          </S.PreviousPageButton>
          <S.MovePressListButton to={ROUTE_PATHS.pressList}>
            Return to Press List
          </S.MovePressListButton>
        </S.ModalButtonWrapper>
      </S.UrlPageEntryModalWrapper>
    </Modal>
  );
};

export default AccessDeniedGuide;
