import { useCallback, useEffect, useState } from "react";

import { ParsedJsonContentType } from "@/types/fillInTableContent.types";
import { PressLabel } from "@/types/pressLabel.type";

export const useSliceLabelData = (
  parsedContentArray: ParsedJsonContentType[],
  pressLabels: PressLabel[],
) => {
  const [sliceLabelData, setSliceLabelData] = useState<PressLabel[][]>([]);
  const [textContent, setTextContent] = useState<string[]>([]);

  const findLabelIndex = useCallback(
    (labelLength: number) => {
      return pressLabels.findIndex((label) => labelLength > label.end);
    },
    [pressLabels],
  );

  useEffect(() => {
    const tempSliceLabelData: PressLabel[][] = [];
    const tempTextArray: string[] = [];
    let tempTextLength = 0;

    parsedContentArray.forEach((content) => {
      if (content.type !== "txt" || content.data.length === 0) return;

      const textLength = content.data.length;
      const specificCondition =
        content.data[0] === "\n" &&
        content.data[1] === "\n" &&
        content.data[2] === " ";

      if (tempSliceLabelData.length === 0) {
        if (specificCondition) {
          tempSliceLabelData.push(
            pressLabels.slice(findLabelIndex(textLength)).map((data) => ({
              ...data,
              end: data.end - 1,
              start: data.start - 1,
            })),
          );
        } else {
          tempSliceLabelData.push(
            pressLabels.slice(findLabelIndex(textLength)),
          );
        }
        tempTextLength = textLength;
      } else {
        const calculatedLabelIndexArray = pressLabels
          .slice(
            findLabelIndex(tempTextLength + textLength),
            findLabelIndex(tempTextLength),
          )
          .map((data) => ({
            ...data,
            end: data.end - tempTextLength - 1,
            start: data.start - tempTextLength - 1,
          }));
        tempTextLength += textLength + 1;
        tempSliceLabelData.push(calculatedLabelIndexArray);
      }
      tempTextArray.push(content.data);
    });
    setSliceLabelData(tempSliceLabelData);
    setTextContent(tempTextArray);
  }, [findLabelIndex, parsedContentArray, pressLabels]);

  return { sliceLabelData, textContent };
};
