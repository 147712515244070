import React from "react";

export interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconGeneTherapy = ({
  width = 24,
  height = 24,
  color = "#515C6C",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        className="modality-icon-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5661 4.07161C15.8106 3.82802 15.8114 3.43229 15.5678 3.18773C15.3242 2.94316 14.9285 2.94237 14.6839 3.18596L13.8043 4.06206L12.9247 4.93815C12.6802 5.18174 12.6794 5.57747 12.923 5.82203C13.1666 6.0666 13.5623 6.06739 13.8069 5.8238L14.6865 4.9477L15.5661 4.07161ZM11.1633 8.45677C11.4079 8.21318 11.4087 7.81745 11.1651 7.57289C10.9215 7.32832 10.5258 7.32753 10.2812 7.57112L9.98646 7.86471C7.66458 10.1773 7.66458 13.9279 9.98646 16.2405L14.5821 20.8178C14.8267 21.0614 15.2224 21.0606 15.466 20.8161C15.7096 20.5715 15.7088 20.1758 15.4642 19.9322L10.8686 15.3549C10.7393 15.2261 10.6192 15.0917 10.5082 14.9525H13.4035C13.3164 15.0558 13.224 15.1561 13.1265 15.2533L12.8317 15.5469C12.5871 15.7905 12.5863 16.1862 12.8299 16.4308C13.0735 16.6753 13.4693 16.6761 13.7138 16.4325L14.0086 16.139C16.3305 13.8263 16.3305 10.0758 14.0086 7.76316L9.41295 3.18584C9.16838 2.94225 8.77266 2.94304 8.52907 3.18761C8.28548 3.43217 8.28627 3.8279 8.53083 4.07149L13.1265 8.64881C13.2557 8.77747 13.3757 8.91177 13.4867 9.05091L10.5917 9.05091C10.6788 8.94774 10.7711 8.84745 10.8686 8.75036L11.1633 8.45677ZM9.83617 10.3009C9.69504 10.6488 9.59771 11.0101 9.54416 11.3766H14.4646C14.4193 11.011 14.3307 10.6497 14.1987 10.3009L9.83617 10.3009ZM9.5304 12.6266C9.57562 12.9923 9.66424 13.3537 9.79626 13.7025H14.159C14.3001 13.3546 14.3974 12.9931 14.451 12.6266H9.5304ZM8.42723 20.8159C8.18364 20.5714 8.18443 20.1756 8.42899 19.9321L9.3086 19.056L10.1882 18.1799C10.4328 17.9363 10.8285 17.9371 11.0721 18.1816C11.3157 18.4262 11.3149 18.8219 11.0703 19.0655L10.1907 19.9416L9.31111 20.8177C9.06655 21.0613 8.67082 21.0605 8.42723 20.8159Z"
        fill={color}
      />
    </svg>
  );
};

IconGeneTherapy.displayName = "GeneTherapy";

export default IconGeneTherapy;
