import { postPageEntry } from "@/queries/hooks/usePageEntry";

export interface CheckConcurrentAccessArgs {
  url: string;
  draftArticleId: string;
  ftEditorName: string | null;
}

export const checkFillInTableLock = async ({
  url,
  draftArticleId,
  ftEditorName,
}: CheckConcurrentAccessArgs) => {
  if (ftEditorName) {
    return null;
  }

  return postPageEntry(url, draftArticleId);
};
