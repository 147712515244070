import {
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS,
  ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSOR_TABLE_KEYS,
  ENTRY_DATA_RIGHTS_SCOPE_KEYS,
} from "../../../constants";

export const MERGE_ACQUISITION_KEY = "M&A";
export const MERGE_ACQUISITION_GROUP_TABLE_KEY = ENTRY_DATA_LICENSEE_TABLE;

export const MERGE_ACQUISITION_TABLE_KEYS = {
  acquiredCompany: "Acquired Company",
  acquiringCompany: "Acquiring Company",
  additionalInformation: "Additional Information",
  dealPurpose: "Deal Purpose",
  none: null,
} as const;

export const MERGE_ACQUISITION_GROUP_SHELL_KEYS = {
  acquiredCompany: "Acquired Company",
  rightsScope: "Rights Scope",
  financialTerms: "Financial terms M&A",
  upfrontPayment: "Upfront Payment",
  contingentPayment: "Contingent Payment",
  additionalInformation: "Additional Information",
  dealPurpose: "Deal Purpose",
  none: null,
} as const;

export const MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS = {
  totalPayment: "TotalPayment M&A",
  upfrontPayment: "Upfront Payment",
  contingentPayment: "Contingent Payment",
  mergerFinancialTerms: "Merger Financial terms",
  otherFinancialTerms: "Other Financial Terms",
  disclosedPaymentsSum: "Disclosed Payments Sum",
  none: null,
};

export const MERGE_ACQUISITION_SHELL_KEYS = {
  licensorName: ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorName,
  licensorResponsibilityRights:
    ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorResponsibilityRights,
  licenseeName: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.licenseeName,
  licenseeResponsibilityAndRightsMa:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.licenseResponsibilityRightsMa,
  drug: ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug,
  indication: ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication,
  numberOfAcquiringShares:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.numberOfAcquiringShares,
  pricePerShare: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.pricePerShare,
  totalPaymentMa: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.totalPaymentMa,
  upfrontPaymentMa: ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.upfrontPaymentMa,
  upfrontPricePerShareMa:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.upfrontPricePerShareMa,
  contingentPaymentMa:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentPaymentMa,
  contingentPricePerShareMa:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentPricePerShareMa,
  contingentMilestoneMa:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.contingentMilestoneMa,
  mergerFinancialTermsMa:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.mergerFinancialTermsMa,
  otherFinancialTermsMa:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.otherFinancialTermsMa,
  additionalInformation:
    ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.additionalInformation,
  purposeOfTheDeal:
    ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.purposeOfTheDeal,
  disclosedPaymentsSumMa:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.disclosedPaymentsSumMa,
  disclosedPaymentsSumPricePerShareMa:
    ENTRY_DATA_DEAL_TERMS_LICENSEE_TABLE_KEYS.disclosedPaymentsSumPricePerShareMa,
} as const;
