import { PARAGRAPH_CODE } from "@frontend/common";

import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import {
  REF_CODE_LICENSEE_DRUG,
  REF_CODE_LICENSEE_EXCLUSIVITY,
  REF_CODE_LICENSEE_INDICATION,
  REF_CODE_LICENSEE_TERRITORY,
  REF_CODE_RIGHTS_SCOPE,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createRefCode } from "@EditArticleContainer/shared/libs/refCode/utils/createRefCode";
import { createEditArticleShellList } from "@EditArticleContainer/shared/utils/createEditArticleShellList";
import {
  createIndexList,
  createIndexListByArray,
} from "@utils/createIndexList";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

import { deduplicateRightsScope } from "../utils/deduplicateRightsScope";

export const RIGHTS_SCOPE_INDEX_POS = 2;
export const useCreateRightsTables = () => {
  const dealTerms = useParagraphEditedData(PARAGRAPH_CODE.TY002.dealTerms);

  const originalRightsScope =
    dealTerms.text["Deal Terms"]["Licensee Table"].at(0)?.["Rights Scope"] ??
    [];
  const targetRightsScope = deduplicateRightsScope(originalRightsScope);
  const rightsScopeIndexList = createIndexListByArray(targetRightsScope);

  const createDefaultRightsTables = (index: number) => {
    const drugText = (valueIndex: number) =>
      createMultiLineText({
        textList:
          targetRightsScope
            ?.at(index)
            ?.Drug?.at(valueIndex)
            ?.value?.map((item) => item.text ?? "") ?? [],
        parseString: (text) => `- ${text?.trim()}`,
      });

    const isDrugArray = Array.isArray(
      dealTerms.text["Deal Terms"]["Licensee Table"]
        .at(0)
        ?.["Rights Scope"]?.at(index)?.Drug,
    );
    const drugLabels = (valueIndex: number) =>
      isDrugArray
        ? dealTerms.text["Deal Terms"]["Licensee Table"]
            .at(0)
            ?.["Rights Scope"]?.at(index)
            ?.Drug?.at(valueIndex)
            ?.value.at(0)?.labels ?? []
        : [
            dealTerms.text["Deal Terms"]["Licensee Table"]
              .at(0)
              ?.["Rights Scope"]?.at(index)?.Drug,
          ]
            ?.at(valueIndex)
            ?.value?.at(0)?.labels ?? [];

    const indicationText = (valueIndex: number) =>
      createMultiLineText({
        textList:
          targetRightsScope
            ?.at(index)
            ?.Indication?.at(valueIndex)
            ?.value?.map((item) => item.text ?? "") ?? [],
        parseString: (text) => `- ${text?.trim()}`,
        bulletStartIndex: 1,
      });

    const isIndicationArray = Array.isArray(
      dealTerms.text["Deal Terms"]["Licensee Table"]
        .at(0)
        ?.["Rights Scope"]?.at(index)?.Indication,
    );
    const indicationLabels = (valueIndex: number) =>
      isIndicationArray
        ? dealTerms.text["Deal Terms"]["Licensee Table"]
            .at(0)
            ?.["Rights Scope"]?.at(index)
            ?.Indication?.at(valueIndex)
            ?.value.at(0)?.labels ?? []
        : [
            dealTerms.text["Deal Terms"]["Licensee Table"]
              .at(0)
              ?.["Rights Scope"]?.at(index)?.Indication,
          ]
            ?.at(valueIndex)
            ?.value?.at(0)?.labels ?? [];

    const exclusivityText =
      targetRightsScope.at(index)?.Exclusivity.value.at(0)?.text ?? "";

    const exclusivityLabels =
      dealTerms.text["Deal Terms"]["Licensee Table"]
        .at(0)
        ?.["Rights Scope"].at(index)
        ?.Exclusivity.value.at(0)?.labels ?? [];

    // 저장 주의: 저장할때 주의 필요함.
    const territoryText = createMultiLineText({
      textList:
        targetRightsScope
          .at(index)
          ?.Territory.value.map((item) => item.text ?? "") ?? [],
      parseString: (text) => `- ${text?.trim()}`,
    });

    const territoryLabels =
      dealTerms.text["Deal Terms"]["Licensee Table"]
        .at(0)
        ?.["Rights Scope"].at(index)
        ?.Territory.value.at(0)?.labels ?? [];

    const drugRowCount = targetRightsScope.at(index)?.Drug.length ?? 0;
    const isDrugIndicationValue =
      createIndexList(drugRowCount)
        .flatMap((rowIndex) => [drugText(rowIndex), indicationText(rowIndex)])
        .filter((item) => !!item).length > 0;

    if (!isDrugIndicationValue) {
      return createEditArticleShellList([
        {
          title: "Exclusivity",
          text: exclusivityText,
          labels: exclusivityLabels,
          refKey: createRefCode(
            REF_CODE_RIGHTS_SCOPE,
            REF_CODE_LICENSEE_EXCLUSIVITY,
            index,
          ),
        },
        {
          title: "Territory",
          text: territoryText,
          labels: territoryLabels,
          refKey: createRefCode(
            REF_CODE_RIGHTS_SCOPE,
            REF_CODE_LICENSEE_TERRITORY,
            index,
          ),
        },
      ]);
    }

    return createEditArticleShellList([
      ...createIndexList(drugRowCount)
        .map((_, rowIndex) => {
          if (!drugText(rowIndex) && !indicationText(rowIndex)) return [];
          return [
            {
              title: rowIndex === 0 ? "Asset(s)" : "",
              text: drugText(rowIndex),
              labels: drugLabels(rowIndex),
              refKey: createRefCode(
                REF_CODE_RIGHTS_SCOPE,
                REF_CODE_LICENSEE_DRUG,
                index,
                rowIndex,
              ),
            },
            {
              title: rowIndex === 0 ? "Main Usage" : "",
              text: indicationText(rowIndex),
              labels: indicationLabels(rowIndex),
              refKey: createRefCode(
                REF_CODE_RIGHTS_SCOPE,
                REF_CODE_LICENSEE_INDICATION,
                index,
                rowIndex,
              ),
            },
          ];
        })
        .filter((item) => item.length > 0),

      {
        title: "Exclusivity",
        text: exclusivityText,
        labels: exclusivityLabels,
        refKey: createRefCode(
          REF_CODE_RIGHTS_SCOPE,
          REF_CODE_LICENSEE_EXCLUSIVITY,
          index,
        ),
      },
      {
        title: "Territory",
        text: territoryText,
        labels: territoryLabels,
        refKey: createRefCode(
          REF_CODE_RIGHTS_SCOPE,
          REF_CODE_LICENSEE_TERRITORY,
          index,
        ),
      },
    ]);
  };

  const rightsScopeTables = () =>
    rightsScopeIndexList.map((index) => createDefaultRightsTables(index));

  return rightsScopeTables;
};
