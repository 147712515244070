"use client";

import styled from "styled-components";

export const SwitchButton = styled.button<{
  $width: string;
  $height: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  cursor: pointer;
`;
