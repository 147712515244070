import { EFO_ROUTE_PATHS } from "..";
import { IconLogIn, IconLogOut } from "../../shared/Icons";
import {
  FooterList,
  HeaderRightMenuInfo,
  MenuInfoEnabledWithoutSubMenu,
  MenuInfoList,
  MenuWithSubMenu,
} from "../../types/efoMenuInfoList/menuInfoList.types";

type HeaderType = {
  Article: MenuWithSubMenu;
  Database: MenuWithSubMenu;
  Report: MenuInfoEnabledWithoutSubMenu;
  Newsletter: MenuInfoEnabledWithoutSubMenu;
  SearchArticles: MenuInfoEnabledWithoutSubMenu;
};

export const HEADER_INFO: HeaderType = {
  Article: {
    key: "article",
    text: "Article",
    isEnabled: true,
    isOpenSubMenu: false,
    subMenu: [
      {
        text: "Clinical Trials",
        path: `${EFO_ROUTE_PATHS.articleSection}/clinical-trials`,
        isEnabled: true,
      },
      {
        text: "Deals",
        path: `${EFO_ROUTE_PATHS.articleSection}/deals`,
        isEnabled: true,
      },
      {
        text: "Venture Capital",
        path: `${EFO_ROUTE_PATHS.articleSection}/venture-capital`,
        isEnabled: true,
      },
      {
        text: "Regulatory",
        path: `${EFO_ROUTE_PATHS.articleSection}/regulatory`,
        isEnabled: true,
      },
    ],
  },
  SearchArticles: {
    key: "searchArticles",
    text: "Search Articles",
    path: EFO_ROUTE_PATHS.searchArticles,
    isEnabled: true,
    isOpenSubMenu: false,
    subMenu: [],
  },
  Database: {
    key: "database",
    text: "Database",
    isEnabled: true,
    isOpenSubMenu: false,
    path: EFO_ROUTE_PATHS.introduction,
    subMenu: [],
  },
  Newsletter: {
    key: "newsletter",
    text: "Newsletter",
    path: EFO_ROUTE_PATHS.subscribe,
    isEnabled: true,
    isOpenSubMenu: false,
    subMenu: [],
  },
  Report: {
    key: "report",
    text: "Report",
    path: EFO_ROUTE_PATHS.efortlessReport,
    isEnabled: true,
    isOpenSubMenu: false,
    subMenu: [],
  },
};

export const DEFAULT_HEADER_INFO: MenuInfoList = Object.values(HEADER_INFO);

export const MENU_FOR_SIGN_IN_LIST: MenuInfoList = [
  ...DEFAULT_HEADER_INFO,
  {
    key: "account",
    text: "Account",
    isEnabled: true,
    isOpenSubMenu: false,
    subMenu: [
      {
        text: "Sign in",
        path: [EFO_ROUTE_PATHS.signIn],
        isEnabled: true,
      },
      {
        text: "Sign up",
        path: [EFO_ROUTE_PATHS.signUp],
        isEnabled: true,
      },
    ],
  },
];

export const MENU_FOR_SIGN_OUT_LIST: MenuInfoList = [
  ...DEFAULT_HEADER_INFO,
  {
    key: "account",
    text: "Account",
    isEnabled: true,
    isOpenSubMenu: false,
    subMenu: [
      {
        text: "My Account",
        path: [EFO_ROUTE_PATHS.myAccount],
        isEnabled: true,
      },
      {
        text: "Sign out",
        path: [EFO_ROUTE_PATHS.signIn],
        isEnabled: true,
      },
    ],
  },
];

export const FOOTER_DEFAULT_MENU_LIST = [
  {
    title: "Article",
    menuList: HEADER_INFO.Article.subMenu,
  },
  {
    title: "Search Articles",
    menuList: [{ ...HEADER_INFO.SearchArticles, text: "Article List" }],
  },
  {
    title: "Database",
    menuList: [{ ...HEADER_INFO.Database, text: "Enter Database" }],
  },
  {
    title: "Newsletter",
    menuList: [{ ...HEADER_INFO.Newsletter, text: "Newsletter Subscription" }],
  },
  {
    title: "Report",
    menuList: [{ ...HEADER_INFO.Report, text: "Download Reports" }],
  },
];

export const FOOTER_FOR_SIGN_IN_LIST: FooterList[] = [
  ...FOOTER_DEFAULT_MENU_LIST,
  {
    title: "Account",
    menuList: [
      {
        text: "Sign in",
        path: [EFO_ROUTE_PATHS.signIn],
        isEnabled: true,
      },
      {
        text: "Create an Account",
        path: [EFO_ROUTE_PATHS.signUp],
        isEnabled: true,
      },
    ],
  },
];

export const FOOTER_FOR_SIGN_OUT_LIST: FooterList[] = [
  ...FOOTER_DEFAULT_MENU_LIST,
  {
    title: "Account",
    menuList: [
      {
        text: "My Account",
        path: [EFO_ROUTE_PATHS.myAccount],
        isEnabled: true,
      },
      {
        text: "Sign out",
        path: [EFO_ROUTE_PATHS.articleMain],
        isEnabled: true,
      },
    ],
  },
];

export const HEADER_RIGHT_MENU_INFO_SIGN_OUT: HeaderRightMenuInfo = [
  {
    text: "Sign in",
    path: EFO_ROUTE_PATHS.signIn,
    style: "text",
    icon: <IconLogIn width={20} height={20} color="#515C6C" />,
  },
  {
    text: "Sign up",
    path: EFO_ROUTE_PATHS.signUp,
    style: "line",
  },
];

export const HEADER_RIGHT_MENU_INFO_SIGN_IN: HeaderRightMenuInfo = [
  {
    text: "Sign out",
    path: EFO_ROUTE_PATHS.articleMain,
    style: "text",
    icon: <IconLogOut width={20} height={20} color="#515C6C" />,
  },
  {
    text: "My Account",
    path: EFO_ROUTE_PATHS.myAccount,
    style: "line",
  },
];
