import { useEffect, useState } from "react";

import { useArticleContentDataQuery } from "@api/articleContentData/articleContentData";
import { useSectionCodeToClassification } from "@hooks/useSectionCode";

const POLLING_INTERVAL = 3000;
export const useAIEntryInProgress = () => {
  const { data: articleContent, refetch } = useArticleContentDataQuery();
  const sectionCode = useSectionCodeToClassification();
  const [ticker, setTicker] = useState<number>(0);

  const aiEntryInProgress =
    (sectionCode === "TY001" &&
      !(
        Object.keys(articleContent).includes("TC001") &&
        Object.keys(articleContent).includes("TC002")
      )) ||
    (sectionCode === "TY002" &&
      !(
        Object.keys(articleContent).includes("TC101") &&
        Object.keys(articleContent).includes("TC102")
      )) ||
    (sectionCode === "TY003" &&
      !(
        Object.keys(articleContent).includes("TC201") &&
        Object.keys(articleContent).includes("TC202")
      )) ||
    (sectionCode === "TY004" &&
      !(
        Object.keys(articleContent).includes("TC301") &&
        Object.keys(articleContent).includes("TC302")
      )) ||
    (sectionCode === "TY006" &&
      !(
        Object.keys(articleContent).includes("TC501") &&
        Object.keys(articleContent).includes("TC502")
      ));

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (aiEntryInProgress) {
        await refetch();
        setTicker(ticker + 1);
      }
    }, POLLING_INTERVAL);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [aiEntryInProgress, refetch, ticker]);

  return aiEntryInProgress;
};
