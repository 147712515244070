import React from "react";

type Props = {
  arrowType: "left" | "right" | "both";
};

const DealTermArrow = ({ arrowType }: Props) => {
  switch (arrowType) {
    case "left":
      return (
        <svg
          width="34"
          height="122"
          viewBox="0 0 34 122"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 22.5H11.3333V1L30 61.0001L11.3333 121V99.5H0V22.5Z"
            fill="#F8F9FA"
          />
          <path
            d="M1 22.5H11.3333V1L30 61.0001L11.3333 121V99.5H1"
            stroke="#E5E7EB"
          />
          <path d="M1.5 23V99" stroke="#F8F9FA" />
        </svg>
      );
    case "right":
      return (
        <svg
          width="34"
          height="122"
          viewBox="0 0 34 122"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34 22.5H22.6667V1L4 61.0001L22.6667 121V99.5H34V22.5Z"
            fill="#F8F9FA"
          />
          <path
            d="M33 22.5H22.6667V1L4 61.0001L22.6667 121V99.5H33"
            stroke="#E5E7EB"
          />
          <path d="M32.5 23V99" stroke="#F8F9FA" />
        </svg>
      );
    case "both":
      return (
        <svg
          width="36"
          height="242"
          viewBox="0 0 36 242"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 22.5H11.3333V1L30 61.0001L11.3333 121V99.5H0V22.5Z"
            fill="#F8F9FA"
          />
          <path
            d="M1 22.5H11.3333V1L30 61.0001L11.3333 121V99.5H1"
            stroke="#E5E7EB"
          />
          <path d="M1.5 23V99" stroke="#F8F9FA" />
          <path
            d="M36 142.5H24.6667V121L6 181L24.6667 241V219.5H36V142.5Z"
            fill="#F8F9FA"
          />
          <path
            d="M35 142.5H24.6667V121L6 181L24.6667 241V219.5H35"
            stroke="#E5E7EB"
          />
          <path d="M34.5 143V219" stroke="#F8F9FA" />
        </svg>
      );
    default:
      return null;
  }
};

export default DealTermArrow;
