"use client";

import styled from "styled-components";

export const FilterSelectWrapper = styled.div`
  position: relative;
`;

export const FilterSelectButton = styled.button`
  display: flex;
  white-space: nowrap;
  min-width: 9.6rem;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  cursor: pointer;
`;

export const CurrentFilter = styled.div`
  color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 145%;
`;

export const DropdownButton = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  > svg > path {
    fill: #0198e9;
  }
`;

export const FilterDropdownList = styled.div`
  position: absolute;
  top: 3.1rem;
  left: -0.78rem;
  z-index: 50;

  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: var(--Spacing-8, 0.8rem) var(--Spacing-4, 0.4rem);
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Primary, #fff);
`;

export const FilterItem = styled.button<{ $isSelect: boolean }>`
  display: flex;
  height: 2.4rem;
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-4, 0.4rem);
  align-items: center;
  color: ${({ $isSelect }) =>
    $isSelect
      ? "var(--Color-Common-Text-Interactive-Primary, #0198E9)"
      : "var(--Color-Common-Text-Primary, #2a2f37)"};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 145%;
  white-space: nowrap;
  cursor: pointer;
`;
