import { REF_CODE_ENDPOINT_DESCRIPTION } from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { ClinicalEndpointsDescriptionData } from "@EditArticleContainer/shared/types/paragraphContents/contents/clinicalResultsContent.types";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

export class ClinicalEndpointsDescription {
  private clinicalEndpointsDescriptionData: ClinicalEndpointsDescriptionData;

  constructor(
    clinicalEndpointsDescriptionData: ClinicalEndpointsDescriptionData,
  ) {
    this.clinicalEndpointsDescriptionData = clinicalEndpointsDescriptionData;
  }

  public get data() {
    return this.clinicalEndpointsDescriptionData;
  }

  public get description() {
    return {
      key: REF_CODE_ENDPOINT_DESCRIPTION,
      text: createMultiLineText({
        textList: this.clinicalEndpointsDescriptionData[
          "Endpoint Description"
        ].value?.map(({ text }) => text ?? ""),
        parseString: (text) => `- ${text}`,
      }),
      labels:
        this.clinicalEndpointsDescriptionData["Endpoint Description"].value.at(
          0,
        )?.labels || [],
    };
  }

  public isEmpty() {
    return this.description.text === "";
  }
}
