import React, { Suspense, forwardRef } from "react";

import { getShellColumnSize } from "@FillInTableContainer/shared/utils/getOptionsFromData";

import * as S from "./ModalityCategoryDropDown.style";
import TableCreatorTextFieldCheckboxInput from "../../../TableCreatorTextField/components/TableCreatorTextFieldCheckboxInput/TableCreatorTextFieldCheckboxInput";

type Props = {
  modalityCategoryList: {
    categoryName: string;
    checked: boolean;
  }[];
  changeCategoryValueList: (value: string[]) => void;
};

const ModalityCategoryDropDown = forwardRef(
  (
    { modalityCategoryList, changeCategoryValueList }: Props,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const changeModalityCategoryList = (category: string, checked: boolean) => {
      const categoryNameList = modalityCategoryList
        .filter((item) => item.checked)
        .map((item) => item.categoryName);
      if (!checked) {
        changeCategoryValueList([...categoryNameList, category]);
      } else {
        changeCategoryValueList(
          categoryNameList.filter((categoryName) => categoryName !== category),
        );
      }
    };
    return (
      <Suspense>
        <S.ListBoxWrapper
          ref={ref}
          columnSize={getShellColumnSize("Clinical Trials", "modality")}
        >
          <S.ListScrollWrapper>
            <S.DropDownTitle>Dictionary</S.DropDownTitle>
            <S.DropDownContentsWrapper>
              <S.DropDownItemsWrapper>
                {modalityCategoryList.map((modalityCategory) => (
                  <S.DropDownItem
                    key={modalityCategory.categoryName}
                    data-dropdown="list-box"
                    onClick={() =>
                      changeModalityCategoryList(
                        modalityCategory.categoryName,
                        modalityCategory.checked,
                      )
                    }
                  >
                    <TableCreatorTextFieldCheckboxInput
                      data-dropdown="list-box"
                      checked={modalityCategory.checked}
                    />
                    {modalityCategory.categoryName}
                  </S.DropDownItem>
                ))}
              </S.DropDownItemsWrapper>
            </S.DropDownContentsWrapper>
          </S.ListScrollWrapper>
        </S.ListBoxWrapper>
      </Suspense>
    );
  },
);

export default ModalityCategoryDropDown;
