interface LabelInfo {
  [key: string]: "blue" | "orange" | "purple" | "pink" | "yellow" | "green";
}
export const MAIN_LABEL_INFO: LabelInfo = {
  E010: "blue",
  E020: "orange",
  E030: "purple",
  E031: "purple",
  E032: "purple",
  E040: "pink",
  E050: "yellow",
  E051: "yellow",
  E052: "yellow",
  E060: "green",
};
