import {
  ReactNode,
  Ref,
  createContext,
  createRef,
  useCallback,
  useContext,
  useMemo,
} from "react";

type FillInTableRefMapContextType = Map<string, Ref<HTMLDivElement>>;

type FillInTableRefRegisterContextType = <T extends HTMLDivElement>(
  position: string,
) => { ref: Ref<T> };

const FillInTableRefMapContext =
  createContext<FillInTableRefMapContextType | null>(null);

const FillInTableRefRegisterContext =
  createContext<FillInTableRefRegisterContextType | null>(null);

export const FillInTableRefMapProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const refMap = useMemo(() => new Map<string, Ref<HTMLDivElement>>(), []);
  const register = useCallback(
    <T extends HTMLDivElement>(position: string) => {
      const ref = createRef<T>();
      refMap.set(position, ref);

      return {
        ref,
      };
    },
    [refMap],
  );

  return (
    <FillInTableRefRegisterContext.Provider value={register}>
      <FillInTableRefMapContext.Provider value={refMap}>
        {children}
      </FillInTableRefMapContext.Provider>
    </FillInTableRefRegisterContext.Provider>
  );
};

export const useFillInTableRefMap = () => {
  const refMap = useContext(FillInTableRefMapContext);
  const register = useContext(FillInTableRefRegisterContext);

  if (refMap === null) {
    throw new Error(
      "useFillInTableRefMap must be used within a FillInTableRefMapProvider",
    );
  }

  if (register === null) {
    throw new Error(
      "useFillInTableRefMap must be used within a FillInTableRefMapProvider",
    );
  }

  return { refMap, register };
};
