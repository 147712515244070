import { useEffect } from "react";

import { VentureCapitalGroupTable } from "@/types/ventureCapital.types";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import {
  DefaultShellTitle,
  TableCreatorTextFieldContainer,
} from "@FillInTableContainer/shared";
import useCheckPressedShell from "@FillInTableContainer/shared/hooks/useCheckPressedShell";
import { useGetInfoOfShell } from "@FillInTableContainer/shared/hooks/useGetInfoOfShell";
import { TableCreatorSubShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import {
  isRequiredShell,
  getShellPlaceholder,
} from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getShellTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { padStart } from "@utils/padStart/padStart";

import { useHandleVentureCapitalCreatorShell } from "./hooks/useHandleVentureCapitalCreatorShell";
import * as S from "./VentureCapitalKeyCommentCreatorShell.style";

export interface PositionInfoProps {
  groupTableKey: VentureCapitalGroupTable;
  groupTableIndex: number;
  groupShellKey: string | null;
  groupShellIndex: number;
  tableKey: string | null;
  tableIndex: number;
  shellKey: string;
  shellIndex: number;
  shellCount: number;
}

interface Props extends PositionInfoProps {
  shellCount: number;
  subShellList?: TableCreatorSubShellList;
  isShellOverOne: boolean;
  isSeparatedShellTitle: boolean;
  isTitleRowShell?: boolean;
  isClearGroupShell: boolean;
  isDeleteGroupShell: boolean;
  isAlertGroupShell: boolean;
}

const VentureCapitalTableCreatorShell = ({
  groupTableKey,
  groupTableIndex,
  groupShellKey,
  groupShellIndex,
  tableKey,
  shellKey,
  tableIndex,
  shellIndex,
  shellCount,
  subShellList,
  isShellOverOne,
  isSeparatedShellTitle,
  isTitleRowShell = false,
  isClearGroupShell,
  isDeleteGroupShell,
  isAlertGroupShell,
}: Props) => {
  const positionPropsInfo: PositionInfoProps = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
    shellKey,
    shellIndex,
    shellCount,
  };

  const {
    isFocused,
    isClearShell,
    isDeleteShell,
    isEnableEdit,
    isAlertShell,
    focusStatus,
    columnSize,
    handleFocusTextFieldWrapper,
    handleClickToDeleteShell,
    handleFocusOutShell,
  } = useHandleVentureCapitalCreatorShell(positionPropsInfo);
  const { isPressed, handleChangePressedStatus } = useCheckPressedShell();

  const articleSection = useArticleSection();
  const { shellValue } = useGetInfoOfShell();

  useEffect(() => {
    const handleCheckClickOutsideShell = () => {
      if (isFocused) handleFocusOutShell();
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleFocusOutShell, isFocused]);

  return (
    <S.ShellWrapper column={columnSize}>
      {(isClearShell || isDeleteShell) && isEnableEdit && (
        <S.DeleteTableButtonWrapper>
          <S.DeleteTableButton type="button" onClick={handleClickToDeleteShell}>
            <img src={IconXMark} alt="button for clear" />
            {focusStatus.type === "clear" ? "Clear" : null}
            {focusStatus.type === "delete" ? "Delete" : null}
          </S.DeleteTableButton>
        </S.DeleteTableButtonWrapper>
      )}
      <S.ShellBorderWrapper
        isFocused={isFocused}
        isClearStatus={isClearShell || isClearGroupShell}
        isDeleteStatus={isDeleteShell || isDeleteGroupShell}
        isAlertStatus={isAlertShell || isAlertGroupShell}
        isDisableEdit={!isEnableEdit}
        isSeparatedShellTitle={isSeparatedShellTitle}
        isTitleRowShell={isTitleRowShell}
        $isPressed={isPressed}
        onClick={handleFocusTextFieldWrapper}
        onMouseDown={(e) => handleChangePressedStatus(e, true)}
        onMouseUp={(e) => handleChangePressedStatus(e, false)}
      >
        <DefaultShellTitle
          isFocused={isFocused}
          isClearShell={isClearShell}
          isDeleteShell={isDeleteShell}
          isAlertShell={isAlertShell}
          isRequiredShell={isRequiredShell(articleSection, shellKey)}
          shellTitle={getShellTitle(articleSection, shellKey)}
          shellTitleNumbering={
            isShellOverOne ? `${padStart(shellIndex + 1)}` : ""
          }
          isSeparatedShellTitle={isSeparatedShellTitle}
          columnSize={columnSize}
        />

        {!isTitleRowShell && (
          <>
            {subShellList ? (
              subShellList.map(({ subShellKey }) => (
                <TableCreatorTextFieldContainer
                  key={`sub-shell-${String(shellIndex)}-${String(subShellKey)}`}
                  placeholder={
                    getShellPlaceholder(articleSection, subShellKey) ||
                    getShellTitle(articleSection, subShellKey)
                  }
                  shellValue={shellValue(
                    subShellKey,
                    tableIndex,
                    groupShellIndex,
                    shellIndex,
                  )}
                  shellKey={subShellKey}
                  shellIndex={shellIndex}
                  groupTableKey={groupTableKey}
                  groupTableIndex={groupTableIndex}
                  groupShellKey={groupShellKey}
                  groupShellIndex={groupShellIndex}
                  tableKey={tableKey}
                  tableIndex={tableIndex}
                />
              ))
            ) : (
              <TableCreatorTextFieldContainer
                placeholder={
                  getShellPlaceholder(articleSection, shellKey) ||
                  getShellTitle(articleSection, shellKey)
                }
                shellValue={shellValue(
                  shellKey,
                  tableIndex,
                  groupShellIndex,
                  shellIndex,
                )}
                shellKey={shellKey}
                shellIndex={shellIndex}
                groupTableKey={groupTableKey}
                groupTableIndex={groupTableIndex}
                groupShellKey={groupShellKey}
                groupShellIndex={groupShellIndex}
                tableKey={tableKey}
                tableIndex={tableIndex}
              />
            )}
          </>
        )}
      </S.ShellBorderWrapper>
    </S.ShellWrapper>
  );
};

export default VentureCapitalTableCreatorShell;
