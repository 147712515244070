import { matchRegulatoryAgencyKeyType } from "./utils/matchRegulatoryAgencyKeyType";
import CountryFlag from "../CountryFlag/CountryFlag";

interface Props {
  agencyType: string;
  isTopMargin?: boolean;
}

const RegulatoryAgencyIcon = ({ agencyType, isTopMargin }: Props) => {
  const currentType = matchRegulatoryAgencyKeyType(agencyType);

  const agencyIconCountryCode = {
    FDA: "US",
    EMA: "EU",
    HealthCanada: "CA",
    PMDA: "JP",
    MFDS: "KR",
    NMPA: "CN",
    MHRA: "GB",
    TGA: "AU",
    empty: "empty",
  };

  const countryCode = agencyIconCountryCode[currentType];

  return (
    <CountryFlag
      isTopMargin={isTopMargin}
      countryCode={countryCode}
      style={{
        width: "auto",
        height: "1.7rem",
      }}
    />
  );
};

export default RegulatoryAgencyIcon;
