import React from "react";

import Button from "@atoms/Button/Button";

import * as S from "./UploadImageModalContainer.style";

interface Props {
  closeModal: () => void;
  uploadImageToStorage: () => void;
}

const UploadImageModalContainer = ({
  closeModal,
  uploadImageToStorage,
}: Props) => {
  return (
    <S.UploadImageModalWrapper>
      <S.UploadImageModalContents>
        <S.UploadImageModalTitle>
          Upload Image to DB Library
        </S.UploadImageModalTitle>
        <S.UploadImageModalText>
          Would you like to upload an image to the DB Library?
          <br />
          Once uploaded to the DB Library, the image cannot be deleted.
        </S.UploadImageModalText>
      </S.UploadImageModalContents>
      <S.UploadImageModalButtonWrapper>
        <Button buttonStyle="lineGray" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          buttonStyle="solidBlue"
          onClick={() => {
            uploadImageToStorage();
            closeModal();
          }}
        >
          Upload Image
        </Button>
      </S.UploadImageModalButtonWrapper>
    </S.UploadImageModalWrapper>
  );
};

export default UploadImageModalContainer;
