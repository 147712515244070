import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { SignInFormType } from "../types/signIn.types";

const signInFormSchema = yup.object().shape({
  email: yup
    .string()
    .required("The email field is required")
    .email("The email field must be a valid email."),
  password: yup.string().required("The Password field is required"),
});

export const useSignInForm = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SignInFormType>({
    resolver: yupResolver(signInFormSchema),
    mode: "all",
  });

  return {
    handleSubmit,
    register,
    errors,
  };
};
