export const createMultiLineText = ({
  textList,
  parseString = (text) => text,
  separator = "\n",
  bulletStartIndex = 2,
}: {
  textList: string[];
  parseString?: (text: string) => string;
  separator?: string;
  bulletStartIndex?: number;
}) => {
  const filteredTextList = textList?.filter((text) => text?.trim() !== "");
  if (filteredTextList?.length === 0) {
    return "";
  }

  if (bulletStartIndex === 1 && filteredTextList?.length === 1) {
    return filteredTextList
      ?.map((text) => (text.startsWith("- ") ? text : `${parseString(text)}`))
      .join(separator);
  }

  if (bulletStartIndex !== 1 && filteredTextList?.length === 1) {
    return filteredTextList[0];
  }

  return filteredTextList
    ?.map((text) => `${parseString(text)}`)
    .join(separator);
};
