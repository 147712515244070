import React from "react";

import { PRODUCT_NAME } from "@constants/productDefault.constants";
import { useChangeBackgroundColor } from "@hooks/useChangeBackgroundColor";
import logoImage from "@images/common/logo-horizontal.svg";
import { theme } from "@styles/theme";

import SignInForm from "./components/SignInForm/SignInForm";
import * as S from "./SignInContainer.style";

const SignInContainer = () => {
  useChangeBackgroundColor(theme.color.neural.gray00);

  return (
    <S.SignInWrapper>
      <S.SignInHeader>
        <img src={logoImage} alt="Bio Research Ai Database Entry System" />
        <h2>{PRODUCT_NAME}</h2>
      </S.SignInHeader>
      <SignInForm />
    </S.SignInWrapper>
  );
};

export default SignInContainer;
