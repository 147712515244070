"use client";

import styled, { css } from "styled-components";

export const TableShellWrapper = styled.div<{
  $isTopLeftRadius?: boolean;
  $isTopRightRadius?: boolean;
  $isBottomLeftRadius?: boolean;
  $isBottomRightRadius?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 0.1rem solid ${({ theme }) => theme.color.neural.gray03};

  ${({ $isTopLeftRadius }) =>
    $isTopLeftRadius &&
    css`
      border-top-left-radius: 1.2rem;
      overflow: hidden;
    `}

  ${({ $isTopRightRadius }) =>
    $isTopRightRadius &&
    css`
      border-top-right-radius: 1.2rem;
      overflow: hidden;
    `}

  ${({ $isBottomLeftRadius }) =>
    $isBottomLeftRadius &&
    css`
      border-bottom-left-radius: 1.2rem;
      overflow: hidden;
    `}

  ${({ $isBottomRightRadius }) =>
    $isBottomRightRadius &&
    css`
      border-bottom-right-radius: 1.2rem;
      overflow: hidden;
    `}
`;

export const TableShellTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 4.2rem;

  line-height: 160%;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.neural.gray10};
  background-color: ${({ theme }) => theme.color.neural.gray02};
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.neural.gray03};
  padding: 0.8rem 1.6rem;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    font-size: 1.2rem;
    padding: 1.2rem 0.8rem;
  }
`;

export const TableShellValue = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  width: 100%;

  line-height: 160%;
  font-size: 1.6rem;
  font-weight: 500;
  color: ${({ theme }) => theme.color.neural.gray10};
  background-color: ${({ theme }) => theme.color.neural.white};
  padding: 1.6rem;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    font-size: 1.2rem;
    padding: 1.2rem 0.8rem;
  }
`;
