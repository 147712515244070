import styled, { css } from "styled-components";

import IconClose from "@images/insertImage/icon-close-keyword-input.svg";
import IconPlus from "@images/insertImage/icon-plus-keyword-input.svg";
import { hideScrollbar } from "@styles/mixin";

export const SelectImageLabelSection = styled.div<{ isDisabled: boolean }>`
  width: 100%;
  height: 100%;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `};
  ${hideScrollbar}
`;

export const ImageLabelList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 15.8rem;
  gap: 1.6rem;
`;

export const ImageLabel = styled.label<{
  isSelected: boolean;
  isDisabled: boolean;
  isCloseButton: boolean;
}>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  min-height: 2.8rem;
  line-height: 130%;
  font-size: 1.6rem;
  font-weight: 500;
  color: #2a2f37;
  border-radius: 4.2rem;
  border: 1px solid #e5e7eb;
  background: #ffffff;
  padding: 0.4rem 1.2rem;
  cursor: pointer;

  input {
    width: 0;
    height: 0;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: #e6f6ff;
    `}
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: #b1b8c4;
      cursor: not-allowed;
    `};

  ${({ isCloseButton }) =>
    isCloseButton &&
    css`
      padding: 0 1.2rem 0 0.8rem;
    `}
`;

export const KeywordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
`;

export const KeywordLabelName = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 1.8rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #657286;
`;

export const KeywordValueList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.2rem;
`;

export const KeywordTextInput = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-height: 2.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  border-radius: 4.2rem;
  color: #2a2f37;
  outline: none;
  padding: 0.2rem 1.2rem;
  background-color: #f0f2f4;

  &:focus {
    border: 1px solid #0198e9;
    background: #ffffff;
    padding: 0.2rem 3rem 0.2rem 1.2rem;
  }
  &:empty:before {
    content: attr(data-placeholder);
    color: #b1b8c4;
  }
`;

export const KeywordTextLabel = styled.div`
  position: relative;
  display: inline-flex;

  & > span:focus + .add-keyword {
    position: absolute;
    top: 0.4rem;
    right: 0.8rem;
    width: 2rem;
    height: 2rem;
    background-image: url(${IconPlus});
    background-size: 2.4rem 2.4rem;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
  }
`;

export const DeleteButton = styled.button`
  display: flex;
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(${IconClose});
  background-size: 2.4rem 2.4rem;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
`;
