import { apiClient } from "@api/apiClient";

import {
  GetClinicalTrialsIdentifierValidationInfoResponse,
  PostClinicalTrialsIdentifierValidationInfoResponse,
} from "./clinicalTrialsIdentifier.interface";

const API_ENDPOINT = {
  get: "/des/clinical-trial-identifier/type-check",
  post: "/des/clinical-trial-identifier/validate",
};

export const clinicalTrialsIdentifierApis = {
  get: async (text: string) => {
    const { data } =
      await apiClient.get<GetClinicalTrialsIdentifierValidationInfoResponse>(
        `${API_ENDPOINT.get}?text=${text}`,
      );

    return data;
  },
  post: async (valueList: string[]) => {
    const { data } =
      await apiClient.post<PostClinicalTrialsIdentifierValidationInfoResponse>(
        `${API_ENDPOINT.post}`,
        valueList,
      );

    return data;
  },
};
