import React from "react";

import { useHandleSignOut } from "@libs/authorization/hooks/useHandleSignOut";

import * as S from "./ExpiredTokenModal.style";

interface Props {
  closeModal: () => void;
}

const ExpiredTokenModal = ({ closeModal }: Props) => {
  const { handleSignOut } = useHandleSignOut();

  const handleOkButtonClick = () => {
    handleSignOut();
    closeModal();
  };

  return (
    <S.Dimmed>
      <S.ModalWrapper>
        <S.ModalTitle>Your login session has expired.</S.ModalTitle>
        <S.ModalText>
          <p>Please log in again.</p>
        </S.ModalText>
        <S.ModalButtonWrapper>
          <S.OkButton onClick={handleOkButtonClick}>OK</S.OkButton>
        </S.ModalButtonWrapper>
      </S.ModalWrapper>
    </S.Dimmed>
  );
};

export default ExpiredTokenModal;
