import React from "react";

import * as S from "./Skeleton.style";

/**
 * @todo 스켈레톤 애니메이션이 필요한 경우 Props로 애니메이션 여부 확인
 */

interface Props {
  width?: string;
  height?: string;
  borderRadius?: string;
  enableBorder?: boolean;
  className?: string;
  isRatio?: boolean;
  isAnimation?: boolean;
}

const Skeleton = ({
  width = "100%",
  height = "100%",
  borderRadius = "0.4rem",
  enableBorder = true,
  isRatio = false,
  isAnimation = true,
  className,
}: Props) => {
  return (
    <S.SkeletonBox
      className={className}
      $width={width}
      $height={height}
      $borderRadius={borderRadius}
      $enableBorder={enableBorder}
      $isRatio={isRatio}
      $isAnimation={isAnimation}
    />
  );
};

export default Skeleton;
