export const ARTICLE_CONTENTS_FINANCING_INFORMATION_PARAGRAPH_CODE =
  "TC517" as const;
export const ARTICLE_CONTENTS_FINANCING_INFORMATION_SECTION_CODE =
  "TY006" as const;
export const ARTICLE_CONTENTS_FINANCING_INFORMATION_PARAGRAPH_TITLE =
  "Financing Information" as const;

export const ARTICLE_CONTENTS_FINANCING_INFORMATION_PARAGRAPH_GROUP_KEY =
  "Financing Information";

export const ARTICLE_CONTENTS_FINANCING_INFORMATION_SHELL_KEYS = {
  amount: "Amount",
  financingInformationDetail: "Financing Information Detail",
  financingPurpose: "Financing Purpose",
} as const;

export const ARTICLE_CONTENTS_FINANCING_INFORMATION_SHELL_TITLE = {
  amount: "Amount",
  financingInformationDetail: "Financing Information",
  financingPurpose: "Financing Purpose",
} as const;
