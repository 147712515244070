import { DealsSection } from "@/types/deals.types";

export const CHECK_DEAL_TABLE_KEY_BY_GROUP_TABLE_KEY: {
  Deals: {
    [key in DealsSection]: string | null;
  };
} = {
  Deals: {
    "Drug Profile": "Drug",
    "Deal Type & Status": null,
    "Licensee Table": "Licensee",
    "Licensor Table": null,
    "Additional Information (Deal)": null,
    "Key Comment": null,
  },
};
