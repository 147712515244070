"use client";

import styled, { css } from "styled-components";

export const ClearButton = styled.button<{
  $isSelected: boolean;
}>`
  color: var(--Color-Common-Text-Disabled, #96a1b0);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 130%;
  text-decoration-line: underline;
  cursor: pointer;
  padding: 0 0.4rem;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
    `}
`;
