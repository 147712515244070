import { ClinicalTrialStatusSection } from "@/types/clinicalTrialStatus.types";

export const CHECK_TABLE_KEY_BY_GROUP_TABLE_KEY: {
  "Clinical Trial Status": {
    [key in ClinicalTrialStatusSection]: string | null;
  };
} = {
  "Clinical Trial Status": {
    "Drug Profile": "Drug",
    "Clinical Trial Design-1": "Clinical Design",
  },
};
