import useArticleSection from "@/components/business/FillInTableContainer/hooks/useArticleSection";
import { useEntryDataValue } from "@stores/entryData";
import { useInitEntryDataValue } from "@stores/initEntryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { SHELL_PATH_TO_READ_SHELL_INFO } from "../constants/tableCreatorShell.constants";

import type { EntryDataValueInfo } from "@/types/clinicalTrials.types";

const SHELL_KEYS_OPTIONS_WITH_ENTRY_DATA = [
  "Licensor Name",
  "Licensor Responsibility & Rights",
  "Licensee Name",
  "Licensee Responsibility & Rights (SC & LA)",
  "Licensee Responsibility & Rights (MA)",
];

interface UseGetInfoOfShellReturn {
  shellValue: (
    shellKey: string,
    tableIndex: number,
    groupShellIndex: number,
    shellIndex: number,
    subGroupShellIndex?: number,
  ) => {
    value: EntryDataValueInfo[];
    options: string[];
    attributionId?: string;
  };
}

export const useGetInfoOfShell = (): UseGetInfoOfShellReturn => {
  const entryData = useEntryDataValue(getPressIdFromURL());
  const initEntryData = useInitEntryDataValue(getPressIdFromURL());
  const articleSection = useArticleSection();

  const shellValue = (
    shellKey: string,
    tableIndex: number,
    groupShellIndex: number,
    shellIndex: number,
    subGroupShellIndex?: number,
  ) => {
    if (!SHELL_PATH_TO_READ_SHELL_INFO[articleSection][shellKey]) {
      return {
        value: [],
        options: [],
      };
    }
    const currentValue = SHELL_PATH_TO_READ_SHELL_INFO[articleSection][
      shellKey
    ](
      entryData,
      tableIndex,
      groupShellIndex,
      shellIndex,
      subGroupShellIndex || 0,
    ).value;
    const isWithEntryDataOptions =
      SHELL_KEYS_OPTIONS_WITH_ENTRY_DATA.includes(shellKey);
    const initOptions = SHELL_PATH_TO_READ_SHELL_INFO[articleSection][shellKey](
      isWithEntryDataOptions ? entryData : initEntryData,
      0,
      0,
      0,
      0,
    )?.options;

    return {
      value: currentValue,
      options: initOptions || [],
    };
  };
  return {
    shellValue,
  };
};
