export const convertPhaseToDevelopmentStage = (phase: string) => {
  switch (phase.toLowerCase()) {
    case "preclinical":
      return "Preclinical";
    case "phase 1":
    case "phase 1a":
    case "phase 1b":
    case "phase 1a/1b":
      return "Phase 1";
    case "phase 1/2":
    case "phase 1/2a":
    case "phase 1/2b":
    case "phase 1b/2":
    case "phase 1b/2a":
    case "phase 2":
    case "phase 2a":
    case "phase 2a/b":
    case "phase 2b":
      return "Phase 2";
    case "phase 1/2/3":
    case "phase 2b/3":
    case "phase 2/3":
    case "phase 2/3a":
    case "phase 3":
    case "phase 3a":
    case "phase 3b":
      return "Phase 3";
    case "phase 1/2/3/4":
    case "phase 3b/4":
    case "phase 3/4":
    case "phase 4":
      return "Approved";
    case "n/a":
      return "Not applicable";
    default:
      return phase;
  }
};
