import { Table } from "@EditArticleContainer/shared/components/ArticleTable/ArticleTable";

export const prunedArticleTable = (
  rawTableItems: Table["tableItems"],
  rawStyles: Table["styles"],
  rawComponents: Table["components"],
) => {
  if (!rawTableItems) return [];
  if (!rawComponents) return [];
  if (!rawStyles) return [];

  // 채워진 행 인덱스 찾기
  const filledRowIndexList: number[] = [];
  for (let rowIndex = 0; rowIndex < rawTableItems.length; rowIndex += 1) {
    const isFilledRow = rawTableItems[rowIndex].some(
      ({ value }) => value.trim() !== "",
    );
    if (isFilledRow) {
      filledRowIndexList.push(rowIndex);
    }
  }

  // 채워진 열 인덱스 찾기
  const emptyColumnIndexList: number[] = [];
  for (let colIndex = 0; colIndex < rawTableItems[0].length; colIndex += 1) {
    const isFilledColumn = rawTableItems.some(
      (row) => row[colIndex].value.trim() !== "",
    );
    if (isFilledColumn) {
      emptyColumnIndexList.push(colIndex);
    }
  }

  const tableItems: Table["tableItems"] = [];
  const styles: Table["styles"] = [];
  const components: Table["components"] = [];

  // 채워진 행만 추출
  filledRowIndexList.forEach((rowIndex) => {
    tableItems.push(rawTableItems[rowIndex]);
    styles.push(rawStyles[rowIndex]);
    components.push(rawComponents[rowIndex]);
  });

  // 채워진 열이 아니면 삭제
  tableItems.forEach((row) => {
    const newRow = row.filter((_, index) =>
      emptyColumnIndexList.includes(index),
    );
    row.splice(0, row.length, ...newRow);
  });

  return [tableItems, styles, components] as const;
};
