"use client";

import styled, { css } from "styled-components";

export const InputWrapper = styled.div<{ $isSearchArticlesPage: boolean }>`
  ${({ $isSearchArticlesPage }) =>
    $isSearchArticlesPage &&
    css`
      display: none;
    `}
`;
