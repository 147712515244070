import styled, { css } from "styled-components";

import {
  DeleteButton,
  DeleteButtonWrapper,
  FixedWrapper,
} from "@FillInTableContainer/shared/styles/deleteButton.style";
import { OutsideWrapper } from "@FillInTableContainer/shared/styles/groupShell.style";
import {
  SelectButton,
  SelectButtonDivider,
  SelectButtonDividerWrapper,
  SelectContentWrapper,
} from "@FillInTableContainer/shared/styles/rowSelectButton.style";

export const GroupShellOutsideWrapper = styled(OutsideWrapper)`
  padding-left: 0;
`;

export const GroupShellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const GroupShell = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  width: 100%;
`;

export const FixedDeleteButtonWrapper = styled(FixedWrapper)`
  top: -2.6rem;
  left: -0.2rem;

  ${({ isShellTitle }) =>
    isShellTitle &&
    css`
      top: -2.6rem;
      left: -0.2rem;
    `}
`;

export const DeleteTableButtonWrapper = styled(DeleteButtonWrapper)``;

export const DeleteTableButton = styled(DeleteButton)``;

export const SelectWrapper = styled(SelectContentWrapper)``;

export const GroupShellSelectButton = styled(SelectButton)``;

export const GroupShellSelectButtonDivider = styled(SelectButtonDivider)``;
export const GroupShellSelectButtonDividerWrapper = styled(
  SelectButtonDividerWrapper,
)``;
