import { useEditorRolesQuery } from "@/queries/hooks/useEditorRoles";
import { EDITOR_ROLES_KEYS } from "@libs/roles/constants/editorRoles.constants";

import { type EditorRoleCode } from "../types/roles.types";

type UseGetEditorRolesReturn = EditorRoleCode[];

export const useEditorRoles = (): UseGetEditorRolesReturn => {
  const { data: editorRoles } = useEditorRolesQuery();

  if (!editorRoles) return [];
  return EDITOR_ROLES_KEYS.filter((role) => editorRoles[role]);
};
