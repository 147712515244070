export const ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_TABLE_KEY =
  "Additional Information (Venture Capital)";

export const ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_TABLE_KEYS = {
  additionalInformationVentureCapital:
    "Additional Information (Venture Capital)",
} as const;

export const ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_SHELL_KEYS =
  {
    none: "none",
  } as const;

export const ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_KEYS = {
  additionalInformation: "Additional Information",
} as const;
