import React from "react";

import BackgroundModality from "@images/mainLabel/background-modality.svg";
import BackgroundPhase from "@images/mainLabel/background-phase.svg";
import BackgroundTooltipModality from "@images/mainLabel/background-tooltip-modality.svg";
import BackgroundTooltipPhase from "@images/mainLabel/background-tooltip-phase.svg";

import * as S from "./PressLabelInfoList.style";

export const PRESS_LABEL_INFO_LIST = [
  {
    id: 3,
    value: "Modality",
    code: "E031",
    border: "1px solid var(--Color-Label-Border-Modality, #AD5BEC)",
    background: " var(--Color-Label-Bg-Modality, #D6ADF5)",
    tooltipBackground: "var(--Color-Label-Bg-Inverse-Modality, #8219D2)",
  },
  {
    id: 4,
    value: "ROA",
    code: "E032",
    border: "1px solid var(--Color-Label-Border-Modality, #AD5BEC)",
    background: `url(${BackgroundModality}) var(--Color-Label-Bg-Modality, #D6ADF5)`,
    tooltipBackground: `url(${BackgroundTooltipModality}) var(--Color-Label-Bg-Inverse-Modality, #8219D2)`,
  },
  {
    id: 2,
    value: "Target",
    code: "E020",
    border: "1px solid var(--Color-Label-Border-Target, #FFB647)",
    background: "var(--Color-Label-Bg-Target, #FFDAA3)",
    tooltipBackground: "var(--Color-Label-Bg-Inverse-Target, #EB8D00)",
  },
  {
    id: 1,
    value: "Drug name",
    code: "E010",
    border: "1px solid var(--Color-Label-Border-Medicine, #4791FF)",
    background: "var(--Color-Label-Bg-Medicine, #A3C8FF)",
    tooltipBackground: "var(--Color-Label-Bg-Inverse-Medicine, #005EEB)",
  },
  {
    id: 6,
    value: "Phase",
    code: "E052",
    border: "1px solid var(--Color-Label-Border-Phase, #EBDF00)",
    background: "var(--Color-Label-Bg-Phase, #FFF875)",
    tooltipBackground: "var(--Color-Label-Bg-Inverse-Phase, #EBDF00)",
  },
  {
    id: 7,
    value: "Design/Status",
    code: "E051",
    border: "1px solid var(--Color-Label-Border-Phase, #EBDF00)",
    background: `url(${BackgroundPhase}) var(--Color-Label-Bg-Phase, #FFF875)`,
    tooltipBackground: `url(${BackgroundTooltipPhase}) var(--Color-Label-Bg-Inverse-Phase, #EBDF00)`,
  },
  {
    id: 5,
    value: "Disease",
    code: "E040",
    border: "1px solid var(--Color-Label-Border-Disease, #F353A1)",
    background: "var(--Color-Label-Bg-Disease, #FCD4E7)",
    tooltipBackground: "var(--Color-Label-Bg-Inverse-Disease, #DB0F72)",
  },
  {
    id: 8,
    value: "Endpoints",
    code: "E060",
    border: "1px solid var(--Color-Label-Border-Endpoint, #42CAD7)",
    background: "var(--Color-Label-Bg-Endpoint, #B3EAEF)",
    tooltipBackground: "var(--Color-Label-Bg-Inverse-Endpoint, #29B5C2)",
  },
] as const;

interface Props {
  title?: string;
}

const PressLabelInfoList = ({ title }: Props) => {
  return (
    <S.PressLabelInfoListWrapper>
      {title && <S.Title>{title}</S.Title>}
      <S.PressLabelInfoList>
        {PRESS_LABEL_INFO_LIST.map((item) => (
          <React.Fragment key={item.id}>
            <S.PressLabelInfo
              key={item.id}
              code={item.code}
              background={item.background}
              border={item.border}
            >
              {item.value}
            </S.PressLabelInfo>
          </React.Fragment>
        ))}
      </S.PressLabelInfoList>
    </S.PressLabelInfoListWrapper>
  );
};

export default PressLabelInfoList;
