import {
  CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_SECTION_KEYS,
} from "@frontend/common";

import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";
import type { ClinicalTrialsSection } from "@/types/clinicalTrials.types";

interface UseHandleGroupShellReturn {
  clearClinicalDesign1GroupShell: () => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: ClinicalTrialsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useClearClinicalTrialDesign1GroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();

  const articleSection = ENTRY_DATA_SECTION_KEYS.clinicalTrial;

  const clearClinicalDesign1GroupShell = () => {
    const clearClinicalTrialDesignOneGroupShell = (): ArticleEntryData => {
      const targetGroupShell =
        entryData?.[articleSection]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN];

      return {
        ...entryData,
        [articleSection]: {
          ...entryData[articleSection],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: targetGroupShell?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]:
                    {
                      ...tableInfo,
                      "Clinical Design Phase": {
                        ...tableInfo?.[
                          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                            .clinicalTrialDesign1
                        ]["Clinical Design Phase"],
                        value: [],
                      },
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDiagnostic]:
                        {
                          ...tableInfo?.[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                              .clinicalTrialDesign1
                          ]?.[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                              .inclusionCriteriaDiagnostic
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDetail]:
                        {
                          ...tableInfo?.[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                              .clinicalTrialDesign1
                          ]?.[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                              .inclusionCriteriaDetail
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.totalPatientsNumber]:
                        {
                          ...tableInfo?.[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                              .clinicalTrialDesign1
                          ]?.[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                              .totalPatientsNumber
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialName]: {
                        ...tableInfo?.[
                          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                            .clinicalTrialDesign1
                        ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialName],
                        value: [],
                      },
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.nctName]: {
                        ...tableInfo?.[
                          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                            .clinicalTrialDesign1
                        ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.nctName],
                        value: [],
                      },
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialIntervention]:
                        [],
                    },
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    if (
      info.groupShellKey ===
      CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign1
    ) {
      setEntryData(clearClinicalTrialDesignOneGroupShell());
      changeCurrentHistory({
        entryData: clearClinicalTrialDesignOneGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  };

  return {
    clearClinicalDesign1GroupShell,
  };
};
