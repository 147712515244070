"use client";

import styled from "styled-components";

export const FooterBottomWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--Spacing-12, 1.2rem) 0rem;
`;

export const FooterBottomContentsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;

  @media only screen and (min-width: 1px) and (max-width: 767px) {
    align-items: flex-start;
    gap: 2.4rem;
  }
`;

export const CopyRights = styled.p`
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  font-family: var(--font-jost);
  font-size: 1.2rem;
  font-weight: 500;
`;

export const FooterFnbWrapper = styled.div``;
