import React from "react";

import { Routes, Route } from "react-router-dom";

import PageNotFoundError from "@/components/shared/business/GlobalErrorBoundary/components/PageNotFoundError";
import Dim from "@/components/shared/ui/Dim/Dim";
import Loader from "@/components/shared/ui/Loader/Loader";
import Layout from "@templates/Layout/Layout";

import { pageList } from "./pageList";
import RouteSwitch from "./RouteSwitch";

function RoutesWrapper() {
  return (
    <Routes>
      {pageList.map(
        ({ path, component: Component, header, layout, access }) => (
          <Route
            path={path}
            key={path}
            element={<RouteSwitch access={access} />}
          >
            <Route
              path={path}
              element={
                <Layout layout={layout} header={header}>
                  <React.Suspense
                    fallback={
                      <Dim>
                        <Loader />
                      </Dim>
                    }
                  >
                    <Component />
                  </React.Suspense>
                </Layout>
              }
            />
          </Route>
        ),
      )}
      <Route path="*" element={<PageNotFoundError />} />
    </Routes>
  );
}

export default RoutesWrapper;
