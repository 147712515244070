"use client";

import styled from "styled-components";

export const InputChipsWrapper = styled.div`
  display: flex;
  height: 3.2rem;
  width: auto;
  padding: 0 1.2rem;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  border-radius: 1.6rem;
  border: 0.1rem solid ${({ theme }) => theme.color.neural.gray03};
  background-color: white;

  &:focus-within {
    border: 0.1rem solid #5cc0f9;

    svg {
      path {
        stroke: #019df6;
      }
    }
  }
`;

export const PlusIcon = styled.div<{ $isFocus?: boolean }>`
  svg {
    path {
      stroke: #a8b3c5;
    }
  }
`;

export const Input = styled.span`
  outline: none;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  &:empty:before {
    content: attr(data-placeholder);
    color: ${({ theme }) => theme.color.neural.gray05};
    font-family: ${({ theme }) => theme.font.fontFamily.pretendard};
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 100%;
  }
`;
