import { REF_CODE_QUOTE } from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { QuoteContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/keyCommentsContent.types";

export class Quote {
  #key = REF_CODE_QUOTE;

  private quoteData: QuoteContent;

  constructor(quoteData: QuoteContent) {
    this.quoteData = quoteData;
  }

  public get data() {
    return this.quoteData;
  }

  public get key() {
    return this.#key;
  }

  public get text() {
    return this.quoteData.value.at(0)?.text || "";
  }

  public get labels() {
    return this.quoteData.value.at(0)?.labels || [];
  }
}
