import React, { useCallback, useState } from "react";

import useCheckIsActiveDropdownKeyboardNavigation from "./useCheckIsActiveDropdownKeyboardNavigation";
import {
  DefaultDataSetType,
  DropdownKey,
  EntireDropdownListType,
} from "../components/TableCreatorListBox/types/tableCreatorDropdown.types";
import { useTableCreatorPositionInfo } from "../context/TableCreatorPositionInfoContext";
import { returnCurrentLastTargetIndex } from "../utils/returnCurrentLastTargetIndex";

export interface TargetListType {
  mainIndex: number;
  listIndex: number;
  categoryIndex: number;
  key: DropdownKey | "DEFAULT";
}

interface KeyDownEventParams {
  currentTextareaInput: string;
  entireDropdownList: EntireDropdownListType;
  handleChangeFocusDropdownValue: (
    currentValue: string | DefaultDataSetType,
  ) => void;
  event: React.KeyboardEvent<HTMLDivElement>;
  currentCaretSelectionStatus: {
    isHaveNextBottomCaretLine: boolean;
    isHaveNextTopCaretLine: boolean;
  };
}

export const DEFAULT_TARGET_INDEX: TargetListType = {
  mainIndex: 0,
  listIndex: -1,
  categoryIndex: 0,
  key: "DEFAULT",
};

export const useHandleTargetListIndex = () => {
  const { removeTextfieldCaret, updateTextfieldCaretPosition } =
    useCheckIsActiveDropdownKeyboardNavigation();
  const [targetListIndex, setTargetListIndex] =
    useState<TargetListType>(DEFAULT_TARGET_INDEX);
  const { info } = useTableCreatorPositionInfo();

  const changeTargetListIndex = useCallback(
    (newTarget: TargetListType) => {
      setTargetListIndex(newTarget);
    },

    // 간헐적으로 TargetIndex가 업데이트되지 않는 현상을 해결하기 위해 임의로 의존성 배열을 추가
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [info.shellKey],
  );

  const arrowDownChangeIndex = ({
    event,
    entireDropdownList,
    currentTextareaInput,
    currentCaretSelectionStatus,
    handleChangeFocusDropdownValue,
  }: KeyDownEventParams) => {
    const { mainIndex, listIndex, categoryIndex } = targetListIndex;
    const { isHaveNextBottomCaretLine, isHaveNextTopCaretLine } =
      currentCaretSelectionStatus;

    const currentIndexList = entireDropdownList[mainIndex];
    const nextMainIndex = mainIndex + 1;
    const nextListIndex = listIndex + 1;

    const isCategoryDropdown =
      currentIndexList.key === "DICTIONARY" && currentIndexList.isCategory;

    if (!isHaveNextBottomCaretLine && currentTextareaInput)
      removeTextfieldCaret();

    if (isCategoryDropdown) {
      if (currentIndexList.list[categoryIndex].list[nextListIndex]) {
        const nextInput =
          currentIndexList.list[categoryIndex].list[nextListIndex] || "";
        handleChangeFocusDropdownValue(nextInput);
        return changeTargetListIndex({
          ...targetListIndex,
          listIndex: nextListIndex,
          key: "DICTIONARY",
        });
      }

      if (
        currentIndexList.list[categoryIndex + 1] &&
        currentIndexList.list[categoryIndex + 1].list.length
      ) {
        const nextInput =
          currentIndexList.list[categoryIndex + 1].list[0] || "";
        handleChangeFocusDropdownValue(nextInput);

        return changeTargetListIndex({
          ...targetListIndex,
          categoryIndex: categoryIndex + 1,
          listIndex: 0,
        });
      }

      if (entireDropdownList[nextMainIndex]) {
        const nextInput = (entireDropdownList[nextMainIndex].list[0] ||
          "") as string;
        handleChangeFocusDropdownValue(nextInput);

        return changeTargetListIndex({
          ...targetListIndex,
          mainIndex: nextMainIndex,
          categoryIndex: 0,
          listIndex: 0,
          key: entireDropdownList[nextMainIndex].key,
        });
      }
    }

    if (!isCategoryDropdown) {
      if (currentIndexList.list[nextListIndex]) {
        const nextInput = (currentIndexList.list[nextListIndex] ||
          "") as string;
        handleChangeFocusDropdownValue(nextInput);

        return changeTargetListIndex({
          ...targetListIndex,
          listIndex: nextListIndex,
          key: currentIndexList.key,
        });
      }

      if (entireDropdownList[nextMainIndex]) {
        const nextMainList = entireDropdownList[nextMainIndex];
        const nextInput =
          nextMainList.key === "DICTIONARY" && nextMainList.isCategory
            ? nextMainList.list[0].list[0]
            : nextMainList.list[0];

        handleChangeFocusDropdownValue(nextInput);

        return changeTargetListIndex({
          ...targetListIndex,
          mainIndex: nextMainIndex,
          listIndex: 0,
          key: entireDropdownList[nextMainIndex].key,
        });
      }
    }

    const nextMainDropdownList = entireDropdownList[0];

    const nextDropdownTypeInput =
      nextMainDropdownList.key === "DICTIONARY" &&
      nextMainDropdownList.isCategory
        ? nextMainDropdownList.list[0].list[0]
        : nextMainDropdownList.list[0];

    const isCaretBottomPosition =
      !isHaveNextBottomCaretLine && isHaveNextTopCaretLine;

    const { lastMainIndex, lastCategoryIndex, lastListIndex } =
      returnCurrentLastTargetIndex(entireDropdownList);

    const isUpdateDefaultTarget =
      targetListIndex.mainIndex === lastMainIndex &&
      targetListIndex.categoryIndex === lastCategoryIndex &&
      targetListIndex.listIndex === lastListIndex &&
      !isCaretBottomPosition;

    if (isUpdateDefaultTarget) {
      if (currentTextareaInput) updateTextfieldCaretPosition(event, "head");
      handleChangeFocusDropdownValue(currentTextareaInput);

      return changeTargetListIndex({
        ...targetListIndex,
        mainIndex: 0,
        categoryIndex: 0,
        listIndex: -1,
        key: entireDropdownList[0].key,
      });
    }

    handleChangeFocusDropdownValue(nextDropdownTypeInput);
    if (currentTextareaInput) removeTextfieldCaret();

    return changeTargetListIndex({
      ...targetListIndex,
      mainIndex: 0,
      categoryIndex: 0,
      listIndex: 0,
      key: entireDropdownList[0].key,
    });
  };

  const arrowUpChangeIndex = ({
    event,
    entireDropdownList,
    currentCaretSelectionStatus,
    currentTextareaInput,
    handleChangeFocusDropdownValue,
  }: KeyDownEventParams) => {
    const { mainIndex, listIndex, categoryIndex } = targetListIndex;
    const { isHaveNextTopCaretLine, isHaveNextBottomCaretLine } =
      currentCaretSelectionStatus;

    const currentIndexList = entireDropdownList[mainIndex];
    const prevMainIndex = mainIndex - 1;
    const prevListIndex = listIndex - 1;

    const isCategoryDropdown =
      currentIndexList.key === "DICTIONARY" && currentIndexList.isCategory;

    if (!isHaveNextTopCaretLine && currentTextareaInput) removeTextfieldCaret();

    if (isCategoryDropdown) {
      if (currentIndexList.list[categoryIndex].list[prevListIndex]) {
        const nextInput =
          currentIndexList.list[categoryIndex].list[prevListIndex] || "";
        handleChangeFocusDropdownValue(nextInput);

        return changeTargetListIndex({
          ...targetListIndex,
          listIndex: prevListIndex,
        });
      }

      if (currentIndexList.list[categoryIndex - 1]) {
        const lastListIndex =
          currentIndexList.list[categoryIndex - 1].list.length - 1;

        const nextInput =
          currentIndexList.list[categoryIndex - 1].list[lastListIndex] || "";
        handleChangeFocusDropdownValue(nextInput);

        return changeTargetListIndex({
          ...targetListIndex,
          categoryIndex: categoryIndex - 1,
          listIndex: lastListIndex,
        });
      }

      if (entireDropdownList[prevMainIndex]) {
        const lastListIndex = entireDropdownList[prevMainIndex].list.length - 1;

        const nextInput = (entireDropdownList[prevMainIndex].list[
          lastListIndex
        ] || "") as string | DefaultDataSetType;

        handleChangeFocusDropdownValue(nextInput);

        return changeTargetListIndex({
          ...targetListIndex,
          mainIndex: prevMainIndex,
          categoryIndex: entireDropdownList[mainIndex].list.length - 1,
          listIndex: entireDropdownList[prevMainIndex].list.length - 1,
          key: entireDropdownList[prevMainIndex].key,
        });
      }
    }

    if (!isCategoryDropdown) {
      if (entireDropdownList[mainIndex].list[prevListIndex]) {
        const nextInput = (entireDropdownList[mainIndex].list[prevListIndex] ||
          "") as string | DefaultDataSetType;
        handleChangeFocusDropdownValue(nextInput);

        return changeTargetListIndex({
          ...targetListIndex,
          listIndex: prevListIndex,
          key: entireDropdownList[mainIndex].key,
        });
      }

      if (entireDropdownList[prevMainIndex]) {
        const lastListIndex = entireDropdownList[prevMainIndex].list.length - 1;
        const prevMainList = entireDropdownList[prevMainIndex];

        const nextInput =
          prevMainList.key === "DICTIONARY" && prevMainList.isCategory
            ? prevMainList.list[lastListIndex].list[
                prevMainList.list[lastListIndex].list.length - 1
              ]
            : prevMainList.list[lastListIndex];

        handleChangeFocusDropdownValue(nextInput);

        return changeTargetListIndex({
          ...targetListIndex,
          mainIndex: prevMainIndex,
          listIndex: lastListIndex,
          key: entireDropdownList[prevMainIndex].key,
        });
      }
    }

    const dropdownLastMainList =
      entireDropdownList[entireDropdownList.length - 1];

    const dropdownLastListIndex = dropdownLastMainList.list.length - 1;

    const lastDropdownInput =
      dropdownLastMainList.key === "DICTIONARY" &&
      dropdownLastMainList.isCategory
        ? dropdownLastMainList.list[dropdownLastListIndex].list[
            dropdownLastMainList.list[dropdownLastListIndex].list.length - 1
          ]
        : dropdownLastMainList.list[dropdownLastListIndex];

    const { lastMainIndex, lastCategoryIndex, lastListIndex, lastKey } =
      returnCurrentLastTargetIndex(entireDropdownList);

    const isCaretTopPosition =
      !isHaveNextTopCaretLine && isHaveNextBottomCaretLine;

    const isUpdateDefaultTarget =
      targetListIndex.listIndex === 0 && !isCaretTopPosition;

    if (isUpdateDefaultTarget) {
      if (currentTextareaInput) updateTextfieldCaretPosition(event, "end");
      handleChangeFocusDropdownValue(currentTextareaInput);

      return changeTargetListIndex({
        ...targetListIndex,
        mainIndex: 0,
        categoryIndex: 0,
        listIndex: -1,
        key: entireDropdownList[0].key,
      });
    }

    handleChangeFocusDropdownValue(lastDropdownInput);
    if (currentTextareaInput) removeTextfieldCaret();

    return changeTargetListIndex({
      ...targetListIndex,
      mainIndex: lastMainIndex,
      categoryIndex: lastCategoryIndex,
      listIndex: lastListIndex,
      key: lastKey,
    });
  };

  return {
    targetListIndex,
    changeTargetListIndex,
    arrowUpChangeIndex,
    arrowDownChangeIndex,
  };
};
