import styled from "styled-components";

import {
  DefaultDeleteTableButton,
  DefaultDeleteTableButtonWrapper,
  DefaultGroupShellWrapper,
  TableContentWrapper,
} from "@FillInTableContainer/shared/styles/table.style";

export const TableWrapper = styled(TableContentWrapper)``;

export const GroupShellListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

export const ScrollContentsWrapper = styled.div`
  min-width: 100%;
`;

export const GroupShellWrapper = styled(DefaultGroupShellWrapper)``;

export const DeleteTableButtonWrapper = styled(
  DefaultDeleteTableButtonWrapper,
)``;

export const DeleteTableButton = styled(DefaultDeleteTableButton)``;

export const TableDivider = styled.div`
  width: 100%;
  height: 0.2rem;
  margin-top: var(--Spacing-8, 0.8rem);
  background: var(--Color-Common-Border-Tertiary, #e5e7eb);
`;
