import React from "react";

import * as S from "./CopyPressButton.style";
import { IconCopy } from "../../Icons";

const CopyPressButton = ({
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <S.Wrapper type="button" {...props}>
      <S.IconWrapper className="icon-wrapper">
        <IconCopy />
      </S.IconWrapper>
      <S.Text>Copy Press</S.Text>
    </S.Wrapper>
  );
};

export default CopyPressButton;
