export const labelData = [
  {
    entity: "E031",
    text: "Modality",
  },
  {
    entity: "E020",
    text: "Target",
  },
  {
    entity: "E010",
    text: "Drug name",
  },
  {
    entity: "E052",
    text: "Phase",
  },
  {
    entity: "E040",
    text: "Disease",
  },
  {
    entity: "E060",
    text: "Endpoints",
  },
] as const;
