import React, { useState } from "react";

import ArticleMainLabelContainer from "@/components/shared/business/components/ArticleMainLabelContainer/ArticleMainLabelContainer";
import { useArticleContentDataQuery } from "@api/articleContentData/articleContentData";
import IconShrink from "@images/insertImage/icon-title-shrink.svg";
import IconWide from "@images/insertImage/icon-title-wide.svg";

import * as S from "./ArticleTopHeaderInfo.style";
import { ImageType } from "../../shared/types/insertImage.types";

interface Props {
  targetImagePath: string | ImageType;
}

const INSERT_IMAGE_DUMMY_TITLE =
  "(The AI is currently generating the Article Title, and this area will be filled with the generated title once it's complete)";

const ArticleTopHeaderInfo = ({ targetImagePath }: Props) => {
  const { data: articleContent } = useArticleContentDataQuery();
  const [isWide, setIsWide] = useState(false);

  const title =
    articleContent.TC001?.text ||
    articleContent.TC101?.text ||
    articleContent.TC201?.text ||
    articleContent.TC301?.text ||
    articleContent.TC501?.text ||
    INSERT_IMAGE_DUMMY_TITLE;

  return (
    <S.HeaderTitleWrapper>
      <S.TopHeaderInfo image={targetImagePath} $isWide={isWide}>
        <S.TopHeaderInfoContents>
          <S.ArticleTitle>{title}</S.ArticleTitle>
          <ArticleMainLabelContainer editable />
        </S.TopHeaderInfoContents>
        <S.Background />
      </S.TopHeaderInfo>
      <S.WideButton onClick={() => setIsWide(!isWide)}>
        <img src={isWide ? IconShrink : IconWide} alt="title wide icon" />
      </S.WideButton>
    </S.HeaderTitleWrapper>
  );
};

export default ArticleTopHeaderInfo;
