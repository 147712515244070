"use client";

import { createGlobalStyle, css } from "styled-components";

export const resetStyles = css`
  @import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
  @import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable-dynamic-subset.min.css");

  :root {
    --font-pt-serif: "PT Serif", serif;
    --font-jost: "Jost", sans-serif;
    --font-pretendard: "Pretendard Variable", Pretendard, -apple-system,
      BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
      "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  }

  body {
    background-color: white;
  }
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
  }

  ol,
  ul,
  li {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  button {
    vertical-align: baseline;
    border: 0;
    background: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }

  a,
  a:active,
  a:focus,
  a:hover,
  a:link,
  a:visited {
    color: ${({ theme }) => theme.color.neural.gray08};
    text-decoration: none;
    font-family: inherit;
  }
  a::before {
    content: "";
    position: absolute;
    font-size: 0;
  }
  * {
    font-family: var(--font-pretendard);
    font-style: normal;
    font-weight: 400;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
  }
  html {
    font-size: 62.5%;
  }
`;

const GlobalStyle = createGlobalStyle`
 ${resetStyles}
	  
`;
export default GlobalStyle;
