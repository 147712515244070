import React from "react";

import { TARGET_GROUP_FOR_DISPLAY } from "./constants/companyTargetGroup.constants";

type Props = {
  companyName: string;
  targetGroup: string | null;
};

const PressListCompanyShell = ({ companyName, targetGroup }: Props) => {
  const targetGroupValue = TARGET_GROUP_FOR_DISPLAY[targetGroup || ""] || "";
  const companyShellValue = targetGroupValue
    ? `[${targetGroupValue}] ${companyName}`
    : companyName;
  return <>{companyShellValue}</>;
};

export default PressListCompanyShell;
