import {
  ENTRY_DATA_CONTINGENT_PAYMENT_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import { useTableCreatorPositionInfo } from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  clearSubGroupShellData: () => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: DealsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    subGroupShellIndex: number;
    subGroupShellKey: string | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useClearMergeAcquisitionSubGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    subGroupShellIndex,
    subGroupShellKey,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { changeFocusStatus } = useTableCreatorFocusStatus();
  const { info } = useTableCreatorPositionInfo();

  const clearSubGroupShellData = () => {
    const clearUpfrontPaymentSubGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms]: {
                  ...tableInfo[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ],
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPaymentMa]: {
                    ...tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                    ][ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPaymentMa],
                    value: [],
                  },
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPricePerShareMa]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                      ][
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                          .upfrontPricePerShareMa
                      ],
                      value: [],
                    },
                },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    const clearContingentSubGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms]: {
                  ...tableInfo[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ],
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment]:
                    tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                    ][
                      ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment
                    ].map((subGroupShellInfo, subGroupShellInfoIndex) => {
                      if (subGroupShellInfoIndex === info.subGroupShellIndex) {
                        return {
                          ...subGroupShellInfo,
                          [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentMilestoneMa]:
                            {
                              ...subGroupShellInfo[
                                ENTRY_DATA_CONTINGENT_PAYMENT_KEYS
                                  .contingentMilestoneMa
                              ],
                              value: [],
                            },
                          [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPaymentMa]:
                            {
                              ...subGroupShellInfo[
                                ENTRY_DATA_CONTINGENT_PAYMENT_KEYS
                                  .contingentPaymentMa
                              ],
                              value: [],
                            },
                          [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPricePerShareMa]:
                            {
                              ...subGroupShellInfo[
                                ENTRY_DATA_CONTINGENT_PAYMENT_KEYS
                                  .contingentPricePerShareMa
                              ],
                              value: [],
                            },
                        };
                      }

                      return subGroupShellInfo;
                    }),
                },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    const clearTotalPaymentSubGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms]: {
                  ...tableInfo[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ],
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.totalPaymentMa]: {
                    ...tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                    ][ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.totalPaymentMa],
                    value: [],
                  },
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.pricePerShare]: {
                    ...tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                    ][ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.pricePerShare],
                    value: [],
                  },
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.numberOfAcquiringShares]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                      ][
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                          .numberOfAcquiringShares
                      ],
                      value: [],
                    },
                },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    const clearDisclosedPaymentsSumSubGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms]: {
                  ...tableInfo[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ],
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumMa]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                      ][
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                          .disclosedPaymentsSumMa
                      ],
                      value: [],
                    },
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumPricePerShareMa]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                      ][
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                          .disclosedPaymentsSumPricePerShareMa
                      ],
                      value: [],
                    },
                },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    const clearMergerFinancialTermsSubGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms]: {
                  ...tableInfo[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ],
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.mergerFinancialTermsMa]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                      ][
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                          .mergerFinancialTermsMa
                      ],
                      value: [],
                    },
                },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    const clearOtherFinancialTermsSubGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms]: {
                  ...tableInfo[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ],
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.otherFinancialTermsMa]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                      ][
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                          .otherFinancialTermsMa
                      ],
                      value: [],
                    },
                },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    if (
      info.subGroupShellKey ===
      MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.upfrontPayment
    ) {
      setEntryData(clearUpfrontPaymentSubGroupShell());
      changeCurrentHistory({
        entryData: clearUpfrontPaymentSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (
      info.subGroupShellKey ===
      MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.disclosedPaymentsSum
    ) {
      setEntryData(clearDisclosedPaymentsSumSubGroupShell());
      changeCurrentHistory({
        entryData: clearDisclosedPaymentsSumSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (
      info.subGroupShellKey ===
      MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.contingentPayment
    ) {
      const contingentPaymentLength =
        entryData?.[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_LICENSEE_TABLE
        ]?.[info.tableIndex][
          ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
        ][ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment]?.length ||
        0;

      setEntryData(clearContingentSubGroupShell());
      changeCurrentHistory({
        entryData: clearContingentSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(
        contingentPaymentLength > 1
          ? {
              type: "delete",
              container: "subGroupShell",
            }
          : DEFAULT_FOCUS_STATUS,
      );
    }

    if (
      info.subGroupShellKey ===
      MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.totalPayment
    ) {
      setEntryData(clearTotalPaymentSubGroupShell());
      changeCurrentHistory({
        entryData: clearTotalPaymentSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (
      info.subGroupShellKey ===
      MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.mergerFinancialTerms
    ) {
      setEntryData(clearMergerFinancialTermsSubGroupShell());
      changeCurrentHistory({
        entryData: clearMergerFinancialTermsSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (
      info.subGroupShellKey ===
      MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.otherFinancialTerms
    ) {
      setEntryData(clearOtherFinancialTermsSubGroupShell());
      changeCurrentHistory({
        entryData: clearOtherFinancialTermsSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }
  };

  return {
    clearSubGroupShellData,
  };
};
