import React, { useCallback, useEffect, useRef } from "react";

import { ENTRY_DATA_SECTION_KEYS as SECTION_KEYS } from "@frontend/common";

import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { KEY_CLINICAL_TRIALS } from "@FillInTableContainer/components/FillInTable/constants/clinicalTrials.constants";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import {
  DEFAULT_FOCUS_STATUS,
  FocusStatus,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  PositionInfo,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useGetNumberOfGroupShell } from "@FillInTableContainer/shared/hooks/useGetNumberOfGroupShell";
import { isTableCustomTitle } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useHandleVentureCapitalDrugProfileTable } from "./useHandleVentureCapitalDrugProfileTable";
import { TableInfoProps } from "../VentureCapitalTableCreatorTable";

interface UseHandleTableCreatorTableReturn {
  info: PositionInfo;
  focusStatus: FocusStatus;
  isFocused: boolean;
  isLastTable: boolean;
  isVisibleTableCustomTitle: boolean;
  tableWrapperRef: React.RefObject<HTMLDivElement>;
  isEnableEdit: boolean;
  isFocusedAddButton: boolean;
  focusEventToChangePositionInfo: (focusEnterType: FocusStatus) => void;
  numberOfGroupShellCount: (groupShellKey: string | null) => number;
  handleClickDeleteButton: () => void;
  changePositionInfo: () => void;
  changePositionInfoToDefault: () => void;
  handleChangeDefaultFocus: () => void;
  changeFocusStatus: (focusStatus: FocusStatus) => void;
}

export const useHandleVentureCapitalTableCreatorTable = (
  tablePropsInfo: TableInfoProps,
): UseHandleTableCreatorTableReturn => {
  const {
    groupTableKey,
    tableKey,
    groupTableIndex,
    tableIndex,
    tableCount,
    groupShellList,
  } = tablePropsInfo;

  const articleSection = SECTION_KEYS.ventureCapital;

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );

  const {
    handleClickDrugProfileDeleteButton,
    handleKeyDownToDeleteDrugProfileTable,
  } = useHandleVentureCapitalDrugProfileTable(tablePropsInfo);

  const isEnableEdit = articleInfo
    ? ENABLE_GROUP_TABLE_KEY_LIST[articleSection][groupTableKey].includes(
        articleInfo.draftArticleStatus,
      )
    : false;

  const { groupShellCount } = useGetNumberOfGroupShell();
  const tableWrapperRef = useRef<HTMLDivElement>(null);

  const isLastTable = tableIndex === tableCount - 1;

  const isFocused =
    info.groupTableKey === groupTableKey &&
    info.tableKey === tableKey &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === null &&
    info.groupShellIndex === null &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    isEnableEdit;

  const isFocusedAddButton =
    focusStatus.container === "noFocus" && isEnableEdit;

  const isVisibleTableCustomTitle =
    isTableCustomTitle(articleSection, groupTableKey) &&
    groupShellCount(groupShellList[0].groupShellKey, tableIndex) > 1;

  const numberOfGroupShellCount = (groupShellKey: string | null) =>
    groupShellCount(groupShellKey, tableIndex);

  const changePositionInfo = () => {
    changeInfo({
      groupTableKey,
      groupTableIndex,
      tableKey,
      tableIndex,
      groupShellKey: null,
      groupShellIndex: null,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
  };

  const changePositionInfoToDefault = () => {
    changeInfo(DEFAULT_POSITION_INFO);
  };

  const handleChangeDefaultFocus = () => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  };

  const focusEventToChangePositionInfo = (focusEnterType: FocusStatus) => {
    changeInfo({
      groupTableKey,
      groupTableIndex,
      tableKey,
      tableIndex,
      groupShellKey: null,
      groupShellIndex: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
      subGroupShellKey: null,
      subGroupShellIndex: null,
    });
    changeFocusStatus({ type: "clear", container: "table" });

    if (focusEnterType.type === "clear") {
      changeFocusStatus({ type: "clear", container: "table" });
    }
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleClickDeleteButton = () => {
    if (info.groupTableKey === KEY_CLINICAL_TRIALS.drugProfile)
      handleClickDrugProfileDeleteButton();
  };

  const handleKeyDownToDeleteTable = useCallback(
    (event: KeyboardEvent) => {
      if (info.groupTableKey === KEY_CLINICAL_TRIALS.drugProfile)
        handleKeyDownToDeleteDrugProfileTable(event);
    },
    [info.groupTableKey, handleKeyDownToDeleteDrugProfileTable],
  );

  useEffect(() => {
    if (focusStatus.container !== "table") return;
    window.addEventListener("keydown", handleKeyDownToDeleteTable);

    return () => {
      window.removeEventListener("keydown", handleKeyDownToDeleteTable);
    };
  }, [focusStatus, handleKeyDownToDeleteTable]);

  return {
    tableWrapperRef,
    info,
    focusStatus,
    isFocused,
    isLastTable,
    isVisibleTableCustomTitle,
    isEnableEdit,
    isFocusedAddButton,
    numberOfGroupShellCount,
    focusEventToChangePositionInfo,
    handleClickDeleteButton,
    changePositionInfo,
    changeFocusStatus,
    changePositionInfoToDefault,
    handleChangeDefaultFocus,
  };
};
