import styled from "styled-components";

export const PressUnsuitableReasonContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40rem;
  overflow: hidden;
  border-radius: 0.4rem;
  border: 1px solid var(--Color-Border-Secondary, #b1b8c4);
  background: var(--Color-Bg-Primary, #fff);
  padding: 2rem;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  z-index: 100;
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.6rem;
`;

export const Text = styled.p`
  color: var(--grayscale-spectrum-neural-blue-gray-06, #8c98aa);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  text-align: left;
`;

export const ReasonCheckListBox = styled.div`
  flex: 1;
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  cursor: pointer;
`;
