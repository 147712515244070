"use client";

import styled, { css } from "styled-components";

import { DESKTOP_HEADER_HEIGHT } from "../../../../../../../constants";
import {
  hideScrollbar,
  hideScrollbarY,
} from "../../../../../../../styles/mixin";

export const DefaultListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 0.8rem;
  width: 100%;
  height: 4.8rem;
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 145%;
  background: var(--Color-Common-Bg-Interactive-Secondary, #fff);
  padding: var(--Spacing-Btn-8, 0.8rem) var(--Spacing-Btn-16, 1.6rem)
    var(--Spacing-Btn-8, 0.8rem) var(--Spacing-32, 3.2rem);
  cursor: pointer;
  white-space: nowrap;
`;

export const listInteractionStyle = css`
  &:hover {
    z-index: 1;
    color: var(--Color-Common-Text-Interactive-Secondary-Hovered, #3d4551);
    background: var(--Color-Common-Bg-Interactive-Secondary-Hovered, #f0f2f4);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  }

  &:active {
    color: var(--Color-Common-Text-Interactive-Secondary-Pressed, #2a2f37);
    background: var(--Color-Common-Bg-Interactive-Secondary-Hovered, #f0f2f4);

    /* Pushed/Light/Inner Shadow4 */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset,
      0px 0px 2px 0px rgba(0, 0, 0, 0.06) inset;
  }
`;

export const listSelectedStyle = css`
  border-radius: var(--Border-Radius-0, 0.4rem);
  color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  border: 1px solid var(--Color-Common-Border-Interactive-Selected, #e0f4ff);
  background: var(
    --Color-Common-Bg-Interactive-Primary-Subtle-Hovered,
    #e0f4ff
  );
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08) inset,
    0px 0px 1px 0px rgba(0, 0, 0, 0.06) inset;

  &:hover {
    z-index: 1;
    border-radius: 0.4rem;
    background: var(
      --Color-Common-Bg-Interactive-Primary-Subtle-Hovered,
      #e0f4ff
    );
    color: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);

    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  }

  &:active {
    color: var(--Color-Common-Text-Interactive-Primary-Pressed, #015d8e);
    background: var(
      --Color-Common-Bg-Interactive-Primary-Subtle-Pressed,
      #d1efff
    );
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset,
      0px 0px 2px 0px rgba(0, 0, 0, 0.06) inset;
  }
`;

export const HamburgerMenuWrapper = styled.div`
  position: fixed;
  top: ${DESKTOP_HEADER_HEIGHT};
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-height: calc(100vh - 6.8rem);
  background: ${({ theme }) => theme.color.neural.white};
  backdrop-filter: blur(8px);
  z-index: 60;

  ${hideScrollbar}
`;

export const MenuTitleBox = styled.div`
  display: flex;
  width: 100%;
  height: 4.8rem;
  padding: 0.4rem 0;
  cursor: pointer;
`;

export const HamburgerMenuScrollWrapper = styled.div`
  width: 100%;
  max-height: 56.7rem;

  ${hideScrollbarY}
`;

export const MenuSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const MenuTitle = styled.h3<{
  $isSelected?: boolean;
  $isEnabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.4rem;
  width: 100%;
  padding: var(--Spacing-Btn-8, 0.8rem) var(--Spacing-Btn-16, 1.6rem);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 145%; /* 2.03rem */
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);

  ${({ $isEnabled }) =>
    !$isEnabled &&
    css`
      cursor: not-allowed;
    `}

  ${listInteractionStyle}

  ${({ $isSelected }) => $isSelected && listSelectedStyle}
`;

export const MenuOpacityLine = styled.div`
  width: 100%;
  border-bottom: 0.1rem solid var(--Color-Common-Border-Tertiary, #e5e7eb);
`;

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  border-bottom: 0.1rem solid var(--Color-Common-Border-Tertiary, #e5e7eb);
`;

export const MenuItem = styled(DefaultListItem)<{
  $isSelected?: boolean;
  $isUpcoming?: boolean;
}>`
  ${listInteractionStyle}

  ${({ $isSelected }) => $isSelected && listSelectedStyle}
`;

export const MenuDisabledItem = styled(DefaultListItem)<{
  $isSelected?: boolean;
  $isUpcoming?: boolean;
}>`
  cursor: not-allowed;
`;

export const BetaText = styled.span`
  color: ${({ theme }) => theme.color.neural.gray05};
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1rem;
  font-style: italic;
  font-weight: 500;
  line-height: 100%;
`;
export const DisabledMenuReason = styled.span`
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.color.neural.gray10};
`;

export const BottomMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.4rem;
`;

export const MenuConditionText = styled.span`
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-weight: 500;
  font-size: 1.4rem;
  font-style: italic;
  line-height: 1;
  padding-top: 0.3rem;
  padding-left: 0.8rem;
`;

export const UpcomingTag = styled.span`
  color: ${({ theme }) => theme.color.neural.black};
  text-align: left;
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1.1rem;
  font-style: italic;
  font-weight: 300;
  line-height: 100%;
`;
