import React from "react";

import BottomButtonContainer from "@/components/shared/ui/BottomButtonContainer/BottomButtonContainer";
import { ArticleSection } from "@/types/articleEntryData.types";
import { FILL_IN_TABLE_KEY } from "@FillInTableContainer/shared/constants/fillInTableKey";

import ClinicalTrialsBottomButtonContainer from "./components/ClinicalTrialsBottomButtonContainer/ClinicalTrialsBottomButtonContainer";
import ClinicalTrialStatusBottomButtonContainer from "./components/ClinicalTrialStatusBottomButtonContainer/ClinicalTrialStatusBottomButtonContainer";
import DealsBottomButtonContainer from "./components/DealsBottomButtonContainer/DealsBottomButtonContainer";
import ExpeditedProgramBottomButtonContainer from "./components/ExpeditedProgramBottomButtonContainer/ExpeditedProgramBottomButtonContainer";
import RegulatoryBottomButtonContainer from "./components/RegulatoryBottomButtonContainer/RegulatoryBottomButtonContainer";
import VentureCapitalBottomButtonContainer from "./components/VentureCapitalBottomButtonContainer/VentureCapitalBottomButtonContainer";

interface Props {
  articleSection: ArticleSection;
}

const FillInTableBottomButtonContainer = ({ articleSection }: Props) => {
  const TABLE_CREATOR_CONTAINER = {
    [FILL_IN_TABLE_KEY.ClinicalTrials]: (
      <BottomButtonContainer>
        <ClinicalTrialsBottomButtonContainer />
      </BottomButtonContainer>
    ),
    [FILL_IN_TABLE_KEY.ClinicalTrialStatus]: (
      <BottomButtonContainer>
        <ClinicalTrialStatusBottomButtonContainer />
      </BottomButtonContainer>
    ),
    [FILL_IN_TABLE_KEY.Regulatory]: (
      <BottomButtonContainer>
        <RegulatoryBottomButtonContainer />
      </BottomButtonContainer>
    ),
    [FILL_IN_TABLE_KEY.Deals]: (
      <BottomButtonContainer>
        <DealsBottomButtonContainer />
      </BottomButtonContainer>
    ),
    [FILL_IN_TABLE_KEY.ExpeditedProgram]: (
      <BottomButtonContainer>
        <ExpeditedProgramBottomButtonContainer />
      </BottomButtonContainer>
    ),
    [FILL_IN_TABLE_KEY.VentureCapital]: (
      <BottomButtonContainer>
        <VentureCapitalBottomButtonContainer />
      </BottomButtonContainer>
    ),
  } satisfies Record<string, React.ReactElement>;

  return TABLE_CREATOR_CONTAINER[articleSection];
};

export default FillInTableBottomButtonContainer;
