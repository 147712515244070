import React from "react";

import { useScroll } from "@FillInTableContainer/components/FillInTable/hooks/useScroll";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { TableCreatorGroupTableTitle } from "@FillInTableContainer/shared";
import { useCanEditGroupTable } from "@FillInTableContainer/shared/hooks/useCanEditGroupTable";
import { useGetNumberOfTable } from "@FillInTableContainer/shared/hooks/useGetNumberOfTable";
import { isRequiredGroupTable } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getGroupTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import FillInTableTableCreatorTable from "./components/DealsKeyCommentCreatorTable/DealsKeyCommentCreatorTable";
import { DEALS_KEY_COMMENT_GROUP_TABLE_LAYOUT } from "./constants/dealsKeyCommentGroupTableLayout";
import * as S from "./DealsKeyCommentGroupTable.style";

const { groupTableKey, tableList } = DEALS_KEY_COMMENT_GROUP_TABLE_LAYOUT;
const groupTableIndex = 3;

const DealsKeyCommentGroupTable = () => {
  const { register } = useScroll();
  const { tableCount } = useGetNumberOfTable();
  const articleSection = useArticleSection();

  const { checkDisableEdit } = useCanEditGroupTable();

  return (
    <S.GroupTableWrapper
      isDisableEdit={checkDisableEdit(groupTableKey)}
      {...register(groupTableKey)}
    >
      <TableCreatorGroupTableTitle
        title={getGroupTableTitle(articleSection, groupTableKey)}
        isRequired={isRequiredGroupTable(articleSection, groupTableKey)}
      />
      <S.TableCreatorTableListWrapper
        isDisableEdit={checkDisableEdit(groupTableKey)}
      >
        {newArrayToCountValue(tableCount(groupTableKey)).map(
          (_, tableIndex) => (
            <div key={`table-${String(groupTableIndex)}-${String(tableIndex)}`}>
              {tableList.map(({ tableKey, groupShellList }) => (
                <FillInTableTableCreatorTable
                  key={`table-${String(groupTableIndex)}-${String(tableIndex)}`}
                  tableKey={tableKey}
                  groupShellList={groupShellList}
                  groupTableKey={groupTableKey}
                  groupTableIndex={groupTableIndex}
                  tableIndex={tableIndex}
                  tableCount={tableCount(groupTableKey)}
                />
              ))}
            </div>
          ),
        )}
      </S.TableCreatorTableListWrapper>
    </S.GroupTableWrapper>
  );
};

export default DealsKeyCommentGroupTable;
