import styled from "styled-components";

import { MainLabelColorType } from "../types/articleMainLabel.types";

export const MainLabelItem = styled.span<{
  color: MainLabelColorType;
}>`
  display: inline-flex;
  font-size: 1.4rem;
  font-weight: 500;
  color: ${({ theme }) => theme.color.neural.gray00};
  border-radius: 0.4rem;
  margin-right: 0.8rem;
  background-color: ${({ theme, color }) =>
    theme.color.labelBackgroundTransparent[color]};
  border: 1px solid ${({ theme, color }) => theme.color.label[color]};
  padding: 0.2rem 0.8rem;
  margin-bottom: 0.8rem;
`;
