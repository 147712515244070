import { useCallback } from "react";

import {
  ClinicalTrialDesign1,
  ClinicalTrialStatusSection,
} from "@/types/clinicalTrialStatus.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  DEFAULT_VALIDATED_INFO,
  useValidatedInTableCreator,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useCheckDisabledDelete } from "@FillInTableContainer/shared/hooks/useCheckDisabledDelete";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  handleClickToClearDesignOneGroupShell: () => void;
  handleKeyDownToClearDesignOneGroupShell: (event: KeyboardEvent) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: ClinicalTrialStatusSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useHandleClinicalTrialStatusDesignOneGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { checkDisabledDeleteTable } = useCheckDisabledDelete();

  const articleSection = "Clinical Trial Status";

  const clinicalTrialDesign1TableLength =
    entryData[articleSection]?.["Clinical Trial Design-1"]?.length || 0;

  const handleClearGroupShell = () => {
    const clearClinicalTrialDesignOneGroupShell = (): ArticleEntryData => {
      const targetGroupShell = entryData?.[articleSection]?.[
        "Clinical Trial Design-1"
      ] as ClinicalTrialDesign1;

      return {
        ...entryData,
        [articleSection]: {
          ...entryData[articleSection],
          "Clinical Trial Design-1": targetGroupShell?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  "Clinical Design Phase": {
                    ...tableInfo?.["Clinical Design Phase"],
                    value: [],
                  },

                  "Inclusion Criteria - Diagnostic": {
                    ...tableInfo?.["Inclusion Criteria - Diagnostic"],
                    value: [],
                  },
                  "Inclusion Criteria - Detail": {
                    ...tableInfo?.["Inclusion Criteria - Detail"],
                    value: [],
                  },
                  "Total Patients Number": {
                    ...tableInfo?.["Total Patients Number"],
                    value: [],
                  },
                  "Trial Name": {
                    ...tableInfo?.["Trial Name"],
                    value: [],
                  },
                  "NCT Name": {
                    ...tableInfo?.["NCT Name"],
                    value: [],
                  },
                  "Trial Intervention": [],
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    if (info.groupTableKey === "Clinical Trial Design-1") {
      setEntryData(clearClinicalTrialDesignOneGroupShell());
      changeCurrentHistory({
        entryData: clearClinicalTrialDesignOneGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      if (clinicalTrialDesign1TableLength > 1 && !checkDisabledDeleteTable()) {
        changeFocusStatus({ type: "delete", container: "table" });
      } else {
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
        changeInfo(DEFAULT_POSITION_INFO);
      }
    }
  };

  const handleDeleteTable = useCallback(() => {
    if (checkDisabledDeleteTable()) {
      return;
    }

    if (clinicalTrialDesign1TableLength > 1) {
      changeFocusStatus(DEFAULT_FOCUS_STATUS);

      const deleteDrugProfile = (): ArticleEntryData => {
        return {
          ...entryData,
          "Clinical Trial Status": {
            ...entryData["Clinical Trial Status"],
            "Clinical Trial Design-1": entryData["Clinical Trial Status"]?.[
              "Clinical Trial Design-1"
            ]?.filter(
              (tableInfo, tableInfoIndex) => tableInfoIndex !== info.tableIndex,
            ),
          },
        };
      };
      if (info.groupTableKey === "Clinical Trial Design-1") {
        setEntryData(deleteDrugProfile());
        changeCurrentHistory({
          entryData: deleteDrugProfile(),
          changedDataInfo: {
            target: "table",
            action: "delete",
            position: {
              groupTableKey,
              groupTableIndex,
              tableKey,
              tableIndex,
              groupShellKey: null,
              groupShellIndex: null,
              shellKey: null,
              shellIndex: null,
              valueIndex: null,
              value: null,
            },
          },
        });
        changeInfo(DEFAULT_POSITION_INFO);
      }
    }
  }, [
    clinicalTrialDesign1TableLength,
    entryData,
    groupTableIndex,
    groupTableKey,
    info,
    tableIndex,
    tableKey,
    setEntryData,
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    checkDisabledDeleteTable,
  ]);

  const handleClickToClearDesignOneGroupShell = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShell();
    }
    if (focusStatus.type === "delete") handleDeleteTable();
  };

  const handleKeyDownToClearDesignOneGroupShell = (event: KeyboardEvent) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearGroupShell();
    }
    if (focusStatus.type === "delete" && event.key === "Delete") {
      handleDeleteTable();
    }
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  return {
    handleClickToClearDesignOneGroupShell,
    handleKeyDownToClearDesignOneGroupShell,
  };
};
