import React from "react";

// eslint-disable-next-line import/no-extraneous-dependencies
import ReactCountryFlag from "react-country-flag";

import * as S from "./CountryFlag.style";
import IconDefault from "./images/IconDefault/IconDefault";

type Props = {
  countryCode: string;
  isTopMargin?: boolean;
} & React.ComponentProps<typeof ReactCountryFlag>;

const CountryFlag = ({ countryCode, isTopMargin = false, ...rest }: Props) => {
  if (countryCode === "empty")
    return (
      <S.FlagWrapper $isTopMargin={isTopMargin}>
        <IconDefault />
      </S.FlagWrapper>
    );

  return (
    <S.FlagWrapper $isTopMargin={isTopMargin}>
      <ReactCountryFlag countryCode={countryCode} svg {...rest} />
    </S.FlagWrapper>
  );
};

export default CountryFlag;
