import styled, { css } from "styled-components";

export const FixedWrapper = styled.div<{
  isShellTitle: boolean;
}>`
  position: absolute;
  left: 2rem;
  right: 0;
  z-index: 25;

  ${({ isShellTitle }) =>
    isShellTitle
      ? css`
          top: 0.2rem;
        `
      : css`
          top: -2rem;
        `}
`;
export const DeleteButtonWrapper = styled.div`
  position: sticky;
  height: 3rem;
  z-index: 10;
`;

export const DeleteButton = styled.button`
  position: sticky;
  left: 0;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  height: 2.4rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.neural.white};
  padding: 0.4rem;
  border-radius: 0.2rem;
  background: var(--Color-Common-Bg-Warning, #e53e06);
  white-space: nowrap;
  cursor: pointer;
  z-index: 10;
`;
