import React from "react";

import { IconMenu } from "@frontend/common";

import * as S from "./TableCreatorDragButton.style";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  isDragging?: boolean;
  dragContainer?:
    | "groupTable"
    | "table"
    | "groupShell"
    | "shell"
    | "subGroupShell"
    | "value";
}

const TableCreatorDragButton = ({
  isDragging = false,
  dragContainer = "shell",
  ...rest
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isShellContainer = ["shell"].includes(dragContainer);

  return (
    <S.DragButton type="button" draggable $isDragging={isDragging} {...rest}>
      <IconMenu color="#515C6C" />
    </S.DragButton>
  );
};

export default TableCreatorDragButton;
