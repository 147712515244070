import {
  ShellColumnWidthSizes,
  ShellTitleFontSizes,
} from "../types/shellSize.types";

export const SHELL_WIDTH: ShellColumnWidthSizes = {
  extraSmall: "12.8rem",
  small: "16rem",
  medium: "21.6rem",
  regular: "29.6rem",
  large: "44.8rem",
  extraLarge: "60rem",
  jumbo: "65.6rem",
  regularWithValidation: "90.4rem",
};

export const SHELL_TITLE_FONTSIZE: ShellTitleFontSizes = {
  extraSmall: "1.1rem",
  small: "1.1rem",
  medium: "1.2rem",
  regular: "1.2rem",
  large: "1.2rem",
  extraLarge: "1.2rem",
  jumbo: "1.2rem",
  regularWithValidation: "1.2rem",
};
