import {
  ParagraphSectionLayout,
  ParagraphTitle,
} from "@frontend/common/src/components/ArticleContent";

import { useParagraph } from "@EditArticleContainer/shared/hooks/useParagraph";
import { useRegenerate } from "@EditArticleContainer/shared/hooks/useRegenerate";
import { REF_CODE_TOPIC } from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";

import * as S from "./DealsParagraphTopic.style";

const PARAGRAPH_TITLE = "Topic";

const DealsParagraphTopic = () => {
  const paragraph = useParagraph("TC102");
  const { isLoading, regenerate } = useRegenerate("TC102");

  return (
    <ParagraphSectionLayout>
      <ParagraphTitle title={PARAGRAPH_TITLE} />
      <S.ParagraphTopicContentsWrapper>
        <S.ParagraphTopicRegenerateButton
          onClick={regenerate}
          disabled={isLoading}
        />
        <S.ParagraphTopicContentWrapper disabled={isLoading}>
          <S.ParagraphTopicContent
            key={paragraph.updateDt}
            item={paragraph.editedData}
            refKey={REF_CODE_TOPIC}
            canLabelEdit
            canTextEdit
          />
        </S.ParagraphTopicContentWrapper>
      </S.ParagraphTopicContentsWrapper>
    </ParagraphSectionLayout>
  );
};

export default DealsParagraphTopic;
