import styled from "styled-components";

export const ListOfDuplicatePressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
`;

export const ListOfDuplicateTitle = styled.div`
  height: 2rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 100%;
`;

export const ListOfDuplicateLabelListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem;
  width: 100%;
`;

export const ListOfDuplicateLabel = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  height: var(--Size-Icon-24, 2.4rem);
  border-radius: 0.2rem;
  background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #f0f9ff);
  padding: 0rem var(--Spacing-6, 0.6rem);
  cursor: pointer;
`;

export const ListOfDuplicateTextId = styled.span`
  display: flex;
  align-items: center;
  color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
`;

export const ListOfDuplicateTextClassification = styled.span`
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  font-size: 1.4rem;
  font-weight: 500;
`;

export const NotFoundText = styled.div`
  height: 2rem;
  color: var(--Color-Common-Text-Disabled, #96a1b0);
  font-size: 1.4rem;
  font-weight: 500;
`;
