"use client";

import React, { useId } from "react";

import * as S from "./SignFormInput.style";
import { NeuralColors } from "../types/themeColor.types";

import type {
  FontSizes,
  FontTextAlign,
  FontWeights,
} from "../types/typography.types";

export interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  inputColor?: NeuralColors;
  inputSize?: FontSizes;
  inputWeight?: FontWeights;
  inputAlign?: FontTextAlign;
  lineColor?: "gray" | "blue" | "null";
  disabled?: boolean;
  labelClassName?: string;
  isError?: boolean;
}

const lineColorMap = {
  gray: `var(--Color-Common-Border-Secondary, #B1B8C4)`,
  blue: `var(--Color-Common-Border-Interactive-Primary, #0198E9)`,
  null: "transparent",
};

const Input = React.forwardRef(
  (
    {
      label,
      inputColor = "gray08",
      inputSize = "14",
      inputWeight = "500",
      inputAlign = "center",
      lineColor = "gray",
      className,
      labelClassName,
      isError,
      ...rest
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const inputId = useId();

    return (
      <S.LabelWrapper htmlFor={inputId}>
        <div>
          <S.LabelTextWrapper>
            {label && (
              <S.LabelText className={`${labelClassName} input-label`}>
                {label}
              </S.LabelText>
            )}
          </S.LabelTextWrapper>
        </div>
        <span>
          <S.InputWrapper>
            <S.Input
              $inputColor={inputColor || "gray08"}
              $inputSize={inputSize}
              $inputWeight={inputWeight}
              $inputAlign={inputAlign}
              className={`${className} input`}
              {...rest}
              ref={ref}
              id={inputId}
              $lineColor={lineColorMap[lineColor]}
              $isError={!!isError}
            />
          </S.InputWrapper>
        </span>
      </S.LabelWrapper>
    );
  },
);

export default Input;
