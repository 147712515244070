import { useCallback } from "react";

import {
  CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS,
  CLINICAL_TRIAL_DESIGN_SHELL_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_SECTION_KEYS,
} from "@frontend/common";

import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { PositionInfoProps } from "../ClinicalTrialDesignShell";
import type { ArticleEntryData } from "@/types/articleEntryData.types";
import type { ClinicalTrialDesign1ShellKeysWithoutTrialIntervention } from "@/types/clinicalTrials.types";

interface Returns {
  clearClinicalDesign1Shell: () => void;
}

export const useClearClinicalTrialsDesign1Shell = (
  positionPropsInfo: PositionInfoProps,
): Returns => {
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { changeFocusStatus } = useTableCreatorFocusStatus();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const clearClinicalDesign1Shell = useCallback(() => {
    const clearClinicalTrialDesignOneShell = (): ArticleEntryData => {
      const defaultDataPath =
        entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN
        ];

      if (
        info.shellKey === CLINICAL_TRIAL_DESIGN_SHELL_KEYS.trialIntervention
      ) {
        return {
          ...entryData,
          [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
            ...entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
            [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: defaultDataPath?.map(
              (tableInfo, tableInfoIndex) => {
                if (tableInfoIndex === info.tableIndex) {
                  return {
                    ...tableInfo,
                    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]:
                      {
                        ...tableInfo[
                          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                            .clinicalTrialDesign1
                        ],
                        [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialIntervention]:
                          [],
                      },
                  };
                }
                return tableInfo;
              },
            ),
          },
        };
      }

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: defaultDataPath?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                          .clinicalTrialDesign1
                      ],
                      [info.shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention]:
                        {
                          ...tableInfo[
                            ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                              .clinicalTrialDesign1
                          ][
                            info.shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention
                          ],
                          value: [],
                        },
                    },
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    if (
      info.groupShellKey ===
      CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign1
    ) {
      setEntryData(clearClinicalTrialDesignOneShell());
      changeCurrentHistory({
        entryData: clearClinicalTrialDesignOneShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  return {
    clearClinicalDesign1Shell,
  };
};
