import React from "react";

import { useTrialInterventionDrugList } from "@FillInTableContainer/shared/context/TrialInterventionDrugListContext";

import {
  useChangeTrialInterventionAccordingToDrugName,
  useCheckTrialIntervention,
  useGetTrialInterventionValue,
} from "./hooks/useGetTrialInterventionValue";
import * as S from "./TrialInterventionDrugList.style";

interface Props {
  tableIndex: number;
}

const TrialInterventionDrugList = ({ tableIndex }: Props) => {
  const { trialInterventionDrugList } = useTrialInterventionDrugList();
  const trialInterventionIdList = useGetTrialInterventionValue(tableIndex);
  const { handleCheckTrialIntervention } =
    useCheckTrialIntervention(tableIndex);
  useChangeTrialInterventionAccordingToDrugName();

  return (
    <S.TrialInterventionDrugListWrapper>
      {trialInterventionDrugList.map((item) => (
        <S.DrugNameLabel>
          <S.DrugNameCheckbox
            type="checkbox"
            checked={trialInterventionIdList.includes(item.interventionId)}
            onClick={() =>
              handleCheckTrialIntervention(item.interventionId, item.drugNames)
            }
          />
          <S.DrugNameTextWrapper>
            <S.DrugNameNumber>({item.drugProfileIndex + 1})</S.DrugNameNumber>
            <S.DrugNameText>{item.drugNames}</S.DrugNameText>
          </S.DrugNameTextWrapper>
        </S.DrugNameLabel>
      ))}
    </S.TrialInterventionDrugListWrapper>
  );
};

export default TrialInterventionDrugList;
