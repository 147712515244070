import {
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_KEY_COMMENTS_GROUP_TABLE_KEY as KEY_COMMENT,
  ENTRY_DATA_FUNDED_COMPANY_GROUP_TABLE_KEY as FUNDED_COMPANY,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_TABLE_KEY as ROUND_TYPE_AND_STATUS,
  ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY as FINANCING_INFORMATION,
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_TABLE_KEY as ADDITIONAL_INFORMATION,
  ENTRY_DATA_INVESTORS_GROUP_TABLE_KEY as INVESTORS,
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  CLINICAL_TRIAL_DESIGN_TABLE_KEY,
} from "@frontend/common";

import { ClinicalTrialsSection } from "@/types/clinicalTrials.types";
import { ClinicalTrialStatusSection } from "@/types/clinicalTrialStatus.types";
import { DealsSection } from "@/types/deals.types";
import { ExpeditedProgramSection } from "@/types/expeditedProgram.types";
import { RegulatorySection } from "@/types/regulatory.types";
import { VentureCapitalGroupTable } from "@/types/ventureCapital.types";
import { KEY_CLINICAL_TRIALS } from "@FillInTableContainer/components/FillInTable/constants/clinicalTrials.constants";
import { DEALS_GROUP_KEY } from "@FillInTableContainer/shared/constants/dealsKey.constants";
import { EXPEDITED_PROGRAM_GROUP_KEY } from "@FillInTableContainer/shared/constants/expeditedProgramKey.constants";
import { REGULATORY_GROUP_KEY } from "@FillInTableContainer/shared/constants/regulatoryKey";

interface NavigationType<T> {
  title: string;
  sectionKey: T;
}

interface NavigationListType {
  [SECTION_KEYS.deals]: NavigationType<DealsSection>[];
  [SECTION_KEYS.clinicalTrial]: NavigationType<ClinicalTrialsSection>[];
  [SECTION_KEYS.clinicalTrialStatus]: NavigationType<ClinicalTrialStatusSection>[];
  [SECTION_KEYS.regulatory]: NavigationType<RegulatorySection>[];
  [SECTION_KEYS.expeditedProgram]: NavigationType<ExpeditedProgramSection>[];
  [SECTION_KEYS.ventureCapital]: NavigationType<VentureCapitalGroupTable>[];
}

const DEALS_NAVIGATION_LIST: NavigationType<DealsSection>[] = [
  {
    sectionKey: DEALS_GROUP_KEY.drugProfile,
    title: "Drug Profile",
  },
  {
    sectionKey: DEALS_GROUP_KEY.dealTypeAndStatus,
    title: "Deal Type & Status",
  },
  {
    sectionKey: DEALS_GROUP_KEY.dealTerms,
    title: "Deal Terms & Additional Information",
  },
  {
    sectionKey: DEALS_GROUP_KEY.keyComment,
    title: "Key Comment",
  },
];

const CLINICAL_TRIALS_NAVIGATION_LIST: NavigationType<ClinicalTrialsSection>[] =
  [
    {
      sectionKey: KEY_CLINICAL_TRIALS.drugProfile,
      title: "Drug Profile",
    },
    {
      sectionKey: CLINICAL_TRIAL_DESIGN_TABLE_KEY,
      title: "Clinical Trial Design",
    },
    {
      sectionKey: KEY_CLINICAL_TRIALS.clinicalResults,
      title: "Clinical Results",
    },
    {
      sectionKey: KEY_CLINICAL_TRIALS.clinicalEndpointsTable,
      title: "Clinical Endpoint",
    },
    {
      sectionKey: KEY_CLINICAL_TRIALS.clinicalEndpointsDescriptions,
      title: "Other Clinical Endpoints (Description)",
    },
    {
      sectionKey: KEY_CLINICAL_TRIALS.keyComment,
      title: "Key Comment",
    },
  ];

const CLINICAL_TRIAL_STATUS_NAVIGATION_LIST: NavigationType<ClinicalTrialStatusSection>[] =
  [
    {
      sectionKey: KEY_CLINICAL_TRIALS.drugProfile,
      title: "Drug Profile",
    },
    // {
    //   sectionKey: KEY_CLINICAL_TRIALS.clinicalTrialDesign1,
    //   title: "Clinical Trial Design-1",
    // },
  ];

const REGULATORY_NAVIGATION_LIST: NavigationType<RegulatorySection>[] = [
  {
    sectionKey: REGULATORY_GROUP_KEY.drugProfile,
    title: "Drug Profile",
  },
  {
    sectionKey: REGULATORY_GROUP_KEY.approvalProcessContents,
    title: "Approval Process Contents",
  },
  {
    sectionKey: REGULATORY_GROUP_KEY.keyComment,
    title: "Key Comment",
  },
];

const EXPEDITED_PROGRAM_NAVIGATION_LIST: NavigationType<ExpeditedProgramSection>[] =
  [
    {
      sectionKey: EXPEDITED_PROGRAM_GROUP_KEY.drugProfile,
      title: "Drug Profile",
    },
    {
      sectionKey: EXPEDITED_PROGRAM_GROUP_KEY.expeditedProgramContents,
      title: "Expedited Program Contents",
    },
    {
      sectionKey: EXPEDITED_PROGRAM_GROUP_KEY.keyComment,
      title: "Key Comment",
    },
  ];

const VENTURE_CAPITAL_NAVIGATION_LIST: NavigationType<VentureCapitalGroupTable>[] =
  [
    {
      sectionKey: DRUG_PROFILE,
      title: "Drug Profile",
    },
    {
      sectionKey: ROUND_TYPE_AND_STATUS,
      title: "Round Name & Type",
    },
    {
      sectionKey: FUNDED_COMPANY,
      title: "Company",
    },
    {
      sectionKey: FINANCING_INFORMATION,
      title: "Financing Information",
    },
    {
      sectionKey: INVESTORS,
      title: "Investors",
    },
    {
      sectionKey: ADDITIONAL_INFORMATION,
      title: "Additional Information",
    },
    {
      sectionKey: KEY_COMMENT,
      title: "Key Comment",
    },
  ];

export const NAVIGATION_LIST: NavigationListType = {
  [SECTION_KEYS.deals]: DEALS_NAVIGATION_LIST,
  [SECTION_KEYS.clinicalTrial]: CLINICAL_TRIALS_NAVIGATION_LIST,
  [SECTION_KEYS.clinicalTrialStatus]: CLINICAL_TRIAL_STATUS_NAVIGATION_LIST,
  [SECTION_KEYS.regulatory]: REGULATORY_NAVIGATION_LIST,
  [SECTION_KEYS.expeditedProgram]: EXPEDITED_PROGRAM_NAVIGATION_LIST,
  [SECTION_KEYS.ventureCapital]: VENTURE_CAPITAL_NAVIGATION_LIST,
};
