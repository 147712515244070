import { useMainLabelList } from "@/components/shared/business/components/ArticleMainLabelContainer/hooks/useMainLabelList";
import {
  REF_CODE_COMPETITIVE_LANDSCAPE_COMPANY_NAME,
  REF_CODE_COMPETITIVE_LANDSCAPE_DEVELOPMENT_STAGE,
  REF_CODE_COMPETITIVE_LANDSCAPE_DRUG_NAME,
  REF_CODE_COMPETITIVE_LANDSCAPE_MOA,
  REF_CODE_COMPETITIVE_LANDSCAPE_MODALITY,
  REF_CODE_COMPETITIVE_LANDSCAPE_ROA,
  REF_CODE_COMPETITIVE_LANDSCAPE_TARGET,
  REF_CODE_COMPETITIVE_LANDSCAPE_TARGET_INDICATION,
  REF_CODE_TITLE,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";

import { EditArticleText } from "./editArticleText";
import { EditableText } from "../../types/editableText.types";
import { useDealTypeList } from "../useDealTypeList/useDealTypeList";
import { useDrugNameList } from "../useDrugNameList/useDrugNameList";

type MainLabel = {
  entity: string;
  labelValue: string;
};

const parsedMainLabelList = (mainLabelList: MainLabel[]) => {
  return mainLabelList.map(({ entity, labelValue }) => ({
    entity,
    value: labelValue,
  }));
};

export const useInitLabels = (refKey: string) => {
  const { savedMainLabelList } = useMainLabelList();
  const { drugNameList } = useDrugNameList();
  const { dealTypeList } = useDealTypeList();

  const splittedMainLabelList = savedMainLabelList.map((mainLabelInfo) => {
    return {
      ...mainLabelInfo,
    };
  });
  const mainLabelListParsed = parsedMainLabelList(splittedMainLabelList);

  const initLabels = (item: EditableText) => {
    // NOTE: Labeling이 필요 없는 경우
    const isNotNeedLabeling =
      refKey?.includes(REF_CODE_TITLE) ||
      refKey?.includes(REF_CODE_COMPETITIVE_LANDSCAPE_COMPANY_NAME) ||
      refKey?.includes(REF_CODE_COMPETITIVE_LANDSCAPE_DEVELOPMENT_STAGE) ||
      refKey?.includes(REF_CODE_COMPETITIVE_LANDSCAPE_DRUG_NAME) ||
      refKey?.includes(REF_CODE_COMPETITIVE_LANDSCAPE_MOA) ||
      refKey?.includes(REF_CODE_COMPETITIVE_LANDSCAPE_MODALITY) ||
      refKey?.includes(REF_CODE_COMPETITIVE_LANDSCAPE_ROA) ||
      refKey?.includes(REF_CODE_COMPETITIVE_LANDSCAPE_TARGET) ||
      refKey?.includes(REF_CODE_COMPETITIVE_LANDSCAPE_TARGET_INDICATION);

    if (isNotNeedLabeling) return item;

    const editArticleText = new EditArticleText(item);

    return editArticleText
      .pushLabels(mainLabelListParsed)
      .pushLabels(drugNameList)
      .pushLabels(dealTypeList)
      .getEditArticleText();
  };

  return { initLabels };
};
