import React from "react";

import ErrorPageView from "@/components/shared/ui/ErrorPageView/ErrorPageView";

interface Props {
  errorStatus?: number;
  handleButtonClick: () => void;
}

const UnknownNetworkError = ({ errorStatus, handleButtonClick }: Props) => {
  return (
    <ErrorPageView
      title="Unknown Network Error"
      message="Please try again later"
      buttonLabel="Reload page"
      handleButtonClick={handleButtonClick}
      errorStatus={errorStatus}
    />
  );
};

export default UnknownNetworkError;
