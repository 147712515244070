"use client";

import styled from "styled-components";

export const RadioButton = styled.input`
  all: inherit;
  position: relative;
  border: 1.5px solid var(--Color-Common-Border-Interactive-Secondary, #b1b8c4);
  background: var(--Color-Common-Bg-Primary, #fff);
  box-sizing: border-box;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  padding: 0;

  &:hover {
    border: 1.5px solid
      var(--Color-Common-Border-Interactive-Secondary-Hovered, #96a1b0);
  }
  &:active {
    border: 1.5px solid
      var(--Color-Common-Border-Interactive-Secondary-Pressed, #7c899c);
  }

  &:checked {
    border: 1.5px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);
    border-radius: 50%;

    &:hover {
      border: 1.5px solid
        var(--Color-Common-Border-Interactive-Primary-Hovered, #017abc);
    }

    &:active {
      border: 1.5px solid
        var(--Color-Common-Border-Interactive-Primary-Pressed, #015d8e);
    }
  }
  &:checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 0.8rem;
    height: 0.8rem;
    background: var(--Color-Common-Bg-Interactive-Primary, #1baefe);
    border-radius: 50%;
    box-shadow: none;
  }

  &:checked:hover::after {
    background: var(--Color-Common-Bg-Interactive-Primary-Hovered, #0198e9);
  }
  &:checked:active::after {
    background: var(--Color-Common-Bg-Interactive-Primary-Pressed, #017abc);
  }
`;
