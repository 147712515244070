import React from "react";

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const IconPlus = ({ width = 12, height = 12, color = "black" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="TrailingIcon">
        <g id="Plus">
          <path
            id="Plus_2"
            d="M6.00024 2V10"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            id="Plus_3"
            d="M2 6H10"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </g>
      </g>
    </svg>
  );
};

IconPlus.displayName = "Plus";
export default IconPlus;
