import RequiredMark from "@atoms/RequiredMark/RequiredMark";

import * as S from "./TableCreatorGroupTableTitle.style";

interface Props {
  title: string;
  isRequired: boolean;
  onClickHandler?: () => void;
}

const TableCreatorGroupTableTitle = ({
  title,
  isRequired,
  onClickHandler,
}: Props) => {
  return (
    <S.GroupTableTitle onClick={onClickHandler}>
      {isRequired && <RequiredMark text="*" />}
      <S.Title>{title}</S.Title>
    </S.GroupTableTitle>
  );
};

export default TableCreatorGroupTableTitle;
