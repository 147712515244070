import { useSafeLeavePage } from "@hooks/useSafeLeavePage";

import EditArticle from "./components/EditArticle/EditArticle";

const EditArticleContainer = () => {
  useSafeLeavePage();

  return <EditArticle />;
};

export default EditArticleContainer;
