import dayjs from "dayjs";

import { convertHtmlToContent } from "@EditArticleContainer/shared/components/EditableTextContainer/utils/htmlConvert";
import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import { REF_CODE_DEEP_INSIGHTS } from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { DeepInsightContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/deepInsightContent.types";

export const useCreateDeepInsightContent = () => {
  const refMap = useArticleContentRefMap();

  return () => {
    const deepInsightObject = convertHtmlToContent(
      refMap.get(REF_CODE_DEEP_INSIGHTS)?.current?.innerHTML ?? "",
    );

    const content: DeepInsightContent = {
      ...deepInsightObject,
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
