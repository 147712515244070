import React from "react";

import AccessDeniedGuide from "@organisms/AccessDeniedGuide/AccessDeniedGuide";

import { useAuthorityType } from "../hooks/useAuthorityType";
import { useEditorRoles } from "../hooks/useEditorRoles";
import { PressClassificationRoleChecker } from "../services/pressClassificationRoleChecker";

interface Props {
  children: React.ReactNode;
}

const PressClassificationRoleGuard = ({ children }: Props) => {
  const authorityType = useAuthorityType();
  const editorRoles = useEditorRoles();

  const roleChecker = new PressClassificationRoleChecker({
    authorityType,
    editorRoles,
  });

  if (roleChecker.canAccess) {
    return <>{children}</>;
  } else {
    return <AccessDeniedGuide />;
  }
};

export default PressClassificationRoleGuard;
