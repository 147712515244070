"use client";

import React, { useEffect, useRef, useState } from "react";

type Returns = {
  smallerTable: "left" | "right" | null;
  leftContainerRef: React.RefObject<HTMLDivElement>;
  rightContainerRef: React.RefObject<HTMLDivElement>;
  leftHeight: number | null;
  rightHeight: number | null;
};
export const useCompareHeightOfTableContainer = (): Returns => {
  const leftContainerRef = useRef<HTMLDivElement>(null);
  const rightContainerRef = useRef<HTMLDivElement>(null);

  const [smallerTable, setSmallerTable] = useState<"left" | "right" | null>(
    null,
  );

  const compareHeightOfTableContainer = (
    leftHeight: number | null,
    rightHeight: number | null,
  ) => {
    // DESCRIPTION : Table이 둘 중 하나라도 없는 경우, 화살표 표출을 하지 않음
    if (leftHeight === null || rightHeight === null) return null;
    if (leftHeight > rightHeight) return "right";
    if (leftHeight < rightHeight) return "left";

    return null;
  };

  useEffect(() => {
    if (!leftContainerRef.current && !rightContainerRef.current) return;

    const leftHeight = leftContainerRef?.current?.clientHeight || null;
    const rightHeight = rightContainerRef?.current?.clientHeight || null;

    setSmallerTable(
      compareHeightOfTableContainer(leftHeight, rightHeight) || null,
    );
  }, []);

  return {
    smallerTable,
    leftContainerRef,
    rightContainerRef,
    leftHeight: leftContainerRef.current?.clientHeight || 0,
    rightHeight: rightContainerRef.current?.clientHeight || 0,
  };
};
