import styled, { css } from "styled-components";

import { AiImageStatus } from "@InsertImageContainer/shared/types/insertImage.types";

export const InsertImageBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.2rem;
`;

export const Image = styled.div<{
  isSelectedImage?: boolean;
  imageStatus: AiImageStatus;
}>`
  position: relative;
  width: 100%;
  height: 20rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.neural.gray00};
  border-radius: 0.4rem;
  box-shadow: 0px 4px 8px 0px rgba(7, 68, 161, 0.02);
  border: 0.1rem solid #b1b8c4;

  &::before {
    content: "AI Image";
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    display: flex;
    align-items: center;
    height: 2rem;
    color: var(--Color-Common-Text-Interactive-Inverse, #fff);
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: var(--Border-Radius-4, 0.4rem);
    padding: 0 0.6rem;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);
    z-index: 10;
  }

  ${({ imageStatus }) => {
    switch (imageStatus) {
      case "GENERATING":
        return css`
          border: 1px solid var(--Color-Border-Progress-Bold, #eb8d00);

          &::before {
            border: 1px solid var(--Color-Border-Progress, #ffdaa3);
            background: var(--Color-Bg-Inverse-Progress, #eb8d00);
          }
        `;
      case "FAIL":
        return css`
          border: 1px solid var(--Color-Common-Border-Disabled, #b1b8c4);

          &::before {
            border: 1px solid var(--Color-Common-Border-Disabled, #b1b8c4);
            background: var(--Color-Common-Bg-Disabled, #cbd0d8);
          }
        `;
      case "SUCCESS":
        return css`
          border: 1px solid var(--Color-Border-ToDo-Bold, #15d555);

          &::before {
            border: 1px solid var(--Color-Border-ToDo, #abf7c5);
            background: var(--Color-Bg-Inverse-ToDo, #15d555);
          }
        `;
      default:
        return null;
    }
  }}

  &:hover {
    ${({ isSelectedImage }) =>
      isSelectedImage === false &&
      css`
        .image-background {
          display: flex;
        }
      `}
  }

  ${({ isSelectedImage }) =>
    isSelectedImage &&
    css`
      .image-background {
        display: flex;
      }
    `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ZoomInButton = styled.button`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  z-index: 1;
  width: 3.33rem;
  height: 3.33rem;
  cursor: pointer;
`;

export const NoneImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  cursor: not-allowed;
`;

export const ImageBackground = styled.div<{ isSelectedImage: boolean }>`
  position: absolute;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  cursor: pointer;

  ${({ isSelectedImage }) =>
    isSelectedImage &&
    css`
      background-color: rgba(1, 152, 233, 0.5);
      cursor: not-allowed;
    `}

  h3,
  p {
    color: ${({ theme }) => theme.color.neural.gray00};
  }
  h3 {
    font-weight: 600;
    font-size: 2rem;
  }
  p {
    font-weight: 500;
    font-size: 1.4rem;
  }
`;

export const SelectedImageText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.neural.gray00};
  font-size: 2rem;
  font-weight: 600;
  line-height: 135%;
  gap: 0.8rem;
  img {
    width: 5.6rem;
    height: 5.6rem;
  }
`;

export const ImageKeyword = styled.h4`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.neural.gray08};
  line-height: 1.6rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: #e5e7eb;
`;

export const KeywordWrapper = styled.div`
  position: relative;
  width: 100%;
  line-height: 2.4rem;
  padding-right: 2.4rem;
`;

export const Keyword = styled.div`
  display: inline;
  font-size: 1.4rem;
  font-weight: 500;
  color: #3c4757;
  padding: 0.2rem 0.4rem;
  margin-right: 0.8rem;
  border-radius: 0.2rem;
  background-color: #f0f2f4;
`;

export const InfoButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  width: 2rem;
  height: 2rem;
  padding: 0;
  cursor: pointer;

  &:hover {
    path {
      fill: #0198e9;
    }
    .image-tooltip {
      display: flex;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  gap: 0.4rem;
  width: 100%;
  white-space: nowrap;
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 0.4rem;
`;

export const InfoTitle = styled.div`
  display: flex;
  align-items: center;
  height: 2.4rem;
  color: var(--grayscale-spectrum-neural-blue-gray-07, #687485);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
`;

export const InfoValue = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 0;
  height: 2.4rem;
  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Disabled, #cbd0d8);
  color: var(--Color-Common-Text-Disabled, #96a1b0);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  word-break: break-all;
  white-space: pre-wrap;
  padding: 0 0.8rem;
`;
