export const KEY_CLINICAL_TRIALS = {
  clinicalTrials: "Clinical Trials",
  drugProfile: "Drug Profile",
  clinicalTrialDesign1: "Clinical Trial Design-1",
  clinicalTrialDesign2: "Clinical Trial Design-2",
  clinicalResults: "Clinical Results",
  clinicalEndpointsTable: "Clinical Endpoints (Table)",
  clinicalEndpointsDescriptions: "Clinical Endpoints (Description)",
  groupInformation: "Group Information",
  arm: "Arm",
  endpoints: "Endpoints",
  options: "options",
  value: "value",
  roa: "ROA",
  modality: "Modality",
  moa: "MOA",
  drugNameShell: "Drug Name Shell",
  codeName: "Code Name",
  innName: "INN Name",
  brandName: "Brand Name",
  therapeuticArea: "Therapeutic Area",
  target: "Target",
  targetIndication: "Target Indication",
  otherCharacteristics: "Other Characteristics",
  phase: "Phase",
  disease: "Disease",
  inclusionCriteria: "Inclusion Criteria",
  inclusionCriteriaDiagnostic: "Inclusion Criteria - Diagnostic",
  inclusionCriteriaDetail: "Inclusion Criteria - Detail",
  diseaseClass: "Disease Class",
  trialName: "Trial Name",
  nctName: "NCT Name",
  totalPatientsNumber: "Total Patients Number",
  armName: "Arm Name",
  patientsNumber: "Patients Number",
  drugName: "Drug Name",
  dose: "Dose",
  duration: "Duration",
  resultsClassification: "Results Classification",
  endpointClassification: "Endpoint Classification",
  endpointNameIncludingMeasureTiming:
    "Endpoint Name including Measurement Timing",
  groupName: "Group Name",
  groupData: "Group Data",
  pValue: "P-Value",
  endpointDescription: "Endpoint Description",
  quote: "Quote",
  spokesperson: "Spokesperson",
  keyComment: "Key Comment",
  empty: "",
} as const;
