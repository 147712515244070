import React, { useState } from "react";

import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import useModal from "@hooks/useModal";

import * as S from "./ClassificationItem.style";
import SelectClassification from "./components/SelectClassification/SelectClassification";
import TextHoverTooltip from "../TextHoverTooltip/TextHoverTooltip";

interface Props {
  title: string;
  content: string;
  disabledClassificationManual?: boolean;
  hasManualClassificationRoles?: boolean;
}

const ClassificationItem = ({
  title,
  content,
  disabledClassificationManual,
  hasManualClassificationRoles,
}: Props) => {
  const { data: articleInfo } = useArticleInfoQuery();
  const [targetShell, setTargetShell] = useState("none");

  const {
    isModal: isModalOpen,
    openModal: openSelectClassificationManualModal,
    closeModal,
  } = useModal();

  const handleCheckTextOverflow = (e: React.MouseEvent) => {
    if (e.currentTarget.clientHeight >= e.currentTarget.scrollHeight) {
      return false;
    }

    return true;
  };

  const handleChangeHoverCompanyShell = (
    e: React.MouseEvent,
    target: string,
  ) => {
    if (!handleCheckTextOverflow(e)) return;
    setTargetShell(target);
  };

  const isUnsuitableArticle =
    articleInfo?.draftArticleStatus === DRAFT_STATUS_CODE.unsuitable;

  const ableRejectReason = ["overlapped press"].includes(
    articleInfo.articleRejectReason?.toLowerCase() || "",
  );

  const isDisabled =
    (!ableRejectReason && isUnsuitableArticle) ||
    disabledClassificationManual ||
    !hasManualClassificationRoles;
  return (
    <>
      <S.TopFixInfoItemWrapper $isDisabled={isDisabled}>
        <S.TopFixInfoTitle $isDisabled={isDisabled}>{title}</S.TopFixInfoTitle>
        <S.ChangeClassificationButton
          onClick={openSelectClassificationManualModal}
          $isSelected={!!content}
          disabled={isDisabled}
        >
          <S.ButtonText
            onMouseOver={(e) => {
              handleChangeHoverCompanyShell(e, title);
            }}
            onMouseLeave={(e) => {
              handleChangeHoverCompanyShell(e, "none");
            }}
          >
            {content || "Click to Select Classification"}
          </S.ButtonText>
        </S.ChangeClassificationButton>
        {targetShell === title && <TextHoverTooltip text={content} />}
      </S.TopFixInfoItemWrapper>
      {isModalOpen && <SelectClassification closeModal={closeModal} />}
    </>
  );
};

export default ClassificationItem;
