import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";

import { SECTION_ACCORDING_TO_CLASSIFICATION } from "@FillInTableContainer/components/FillInTable/constants/common.constants";

import { RECOIL_KEYS } from "./recoilKeys";

import type { ClassificationCode } from "@/types/code.types";

const classificationState = atom<ClassificationCode>({
  key: RECOIL_KEYS.classificationState,
  default: "C000",
});

const sectionByClassificationState = selector({
  key: RECOIL_KEYS.sectionByClassificationState,
  get: ({ get }) => {
    const classification = get(classificationState);
    return SECTION_ACCORDING_TO_CLASSIFICATION[classification];
  },
});

export const useClassificationState = () =>
  useSetRecoilState(classificationState);
export const useSectionByClassificationValue = () =>
  useRecoilValue(sectionByClassificationState);
