"use client";

import styled from "styled-components";

export const Shell = styled.div<{ $minWidth: string; $maxWidth: string }>`
  padding: var(--Spacing-8, 0.8rem) var(--Spacing-2, 0.2rem)
    var(--Spacing-12, 1.2rem) var(--Spacing-2, 0.2rem);

  min-width: ${({ $minWidth }) => $minWidth};
  max-width: ${({ $maxWidth }) => $maxWidth};

  color: var(--Color-Common-Text-Primary, #2a2f37);

  font-family: var(--font-pt-serif);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  width: 100%;

  overflow-wrap: anywhere;

  @media only screen and (max-width: 1339px) {
    padding: var(--Spacing-4, 0.4rem) var(--Spacing-2, 0.2rem)
      var(--Spacing-6, 0.6rem) var(--Spacing-2, 0.2rem);
  }
`;
