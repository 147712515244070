"use client";

import styled, { css } from "styled-components";

export const FilterButtonWrapper = styled.button<{
  $isFilterOpen: boolean;
}>`
  display: flex;
  height: 3.2rem;
  min-height: var(--Size-Btn-Minimum, 2.8rem);
  max-height: var(--Size-Btn-Maximum, 4.8rem);
  padding: var(--Spacing-Btn-8, 0.8rem) var(--Spacing-12, 1.2rem);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-Btn-4, 0.4rem);

  border-radius: 0.4rem;
  border: 1px solid
    var(--Color-Common-Border-Interactive-Primary-Subtle, #d1efff);
  background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #e6f6ff);

  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
    0px 1px 2px 0px rgba(0, 0, 0, 0.16);
  cursor: pointer;

  &:hover {
    border: 1px solid
      var(--Color-Common-Border-Interactive-Primary-Subtle-Hovered, #a4dfff);
    background: var(
      --Color-Common-Bg-Interactive-Primary-Subtle-Hovered,
      #d1efff
    );
    > svg > path {
      fill: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);
    }

    > span {
      color: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);
    }
  }

  &:active {
    border: 1px solid
      var(--Color-Common-Border-Interactive-Primary-Subtle-Pressed, #76cffe);
    background: var(
      --Color-Common-Bg-Interactive-Primary-Subtle-Pressed,
      #a4dfff
    );

    > svg > path {
      fill: var(--Color-Common-Text-Interactive-Primary-Pressed, #015d8e);

      > span {
        color: var(--Color-Common-Text-Interactive-Primary-Pressed, #015d8e);
      }
    }
  }

  ${({ $isFilterOpen }) =>
    $isFilterOpen &&
    css`
      border: 1px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);
      background: var(--Color-Common-Bg-Interactive-Primary, #1baefe);

      > svg > path {
        fill: white;
      }

      > span {
        color: white;
      }
    `}
`;

export const FilterButtonText = styled.span`
  color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 145%;
`;
