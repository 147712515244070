import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_TABLE_KEY as ROUND_TYPE_AND_STATUS,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_SHELL_KEYS as ROUND_TYPE_AND_STATUS_SHELL_KEYS,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_TABLE_KEYS as ROUND_TYPE_AND_STATUS_TABLE_KEYS,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_SHELL_KEYS as ROUND_TYPE_AND_STATUS_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import { useModalityCategoryValue } from "@FillInTableContainer/shared/context/ModalityCategoryContext";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  ValidatedInfoList,
  useValidatedInTableCreator,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

interface UseCheckValidationInTableCreatorReturn {
  handleCheckValidationFull: () => boolean;
}

export const useCheckValidationInVentureCapitalTableCreator =
  (): UseCheckValidationInTableCreatorReturn => {
    const entryData = useEntryDataValue(getPressIdFromURL());
    const { changeValidatedInfo } = useValidatedInTableCreator();
    const { changeInfo } = useTableCreatorPositionInfo();
    const { changeFocusStatus } = useTableCreatorFocusStatus();
    const { updateModalityCategory } = useModalityCategoryValue();

    const checkModalityCategory = () => {
      const drugProfileTableList =
        entryData?.[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE];
      const modalityValues = drugProfileTableList?.map((table) =>
        table.Modality.value?.map((shell) => shell.text),
      );

      const valueInfo = modalityValues?.flatMap((table, tableIndex) =>
        table.map((shell) => {
          if (updateModalityCategory[shell] === "") {
            return {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex,
              groupShellKey: null,
              groupShellIndex: 0,
              shellKey: "Modality",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            };
          }
          return null;
        }),
      );

      return valueInfo?.filter((item) => item !== null);
    };

    const checkRoundTypeAndStatusTable = (data: ArticleEntryData) => {
      const roundTypeAndStatusData =
        data[SECTION_KEYS.ventureCapital]?.[ROUND_TYPE_AND_STATUS];
      const roundValue =
        roundTypeAndStatusData?.[
          ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundType
        ]?.value?.at(0)?.text || "";

      const emptyValueKeys = [
        roundValue === "" ? ROUND_TYPE_AND_STATUS_SHELL_KEYS.roundType : "",
      ].filter((shellKey) => shellKey !== "");

      if (emptyValueKeys.length === 0) return [];

      return emptyValueKeys.map((shellKey) => ({
        groupTableKey: ROUND_TYPE_AND_STATUS,
        groupTableIndex: 2,
        tableKey: ROUND_TYPE_AND_STATUS_TABLE_KEYS.none,
        tableIndex: 0,
        groupShellKey: ROUND_TYPE_AND_STATUS_GROUP_SHELL_KEYS.none,
        groupShellIndex: 0,
        shellKey,
        shellIndex: 0,
        valueIndex: null,
        type: "alert",
        container: "shell",
      }));
    };

    const handleCheckValidationFull = () => {
      if (
        checkRoundTypeAndStatusTable(entryData).length === 0 &&
        checkModalityCategory()?.length === 0
      ) {
        changeInfo(DEFAULT_POSITION_INFO);
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
        return true;
      }

      const validatedList = [
        ...(checkModalityCategory() || []),
        ...(checkRoundTypeAndStatusTable(entryData) || []),
      ];

      changeValidatedInfo(validatedList as ValidatedInfoList);
      return false;
    };

    return {
      handleCheckValidationFull,
    };
  };
