export const COMMON_EDITABLE_TEXT_STYLE = {
  padding: "0.8rem 0.6rem",
  borderRadius: "0",
  fontSize: "1.6rem",
  fontWeight: 500,
  lineHeight: "150%",
};

export const DRUG_NAME_EDITABLE_TEXT_STYLE = {
  padding: "0rem 0.4rem",
  fontSize: "1.8rem",
  fontWeight: 500,
  lineHeight: "150%",
};
