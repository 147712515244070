import { ProcessStatusCode } from "@/types/code.types";
import DeleteButton from "@images/pressList/icon-filter-delete.svg";

import * as S from "./SearchFilterTag.style";

interface Props {
  type: "search" | "result";
  code?: ProcessStatusCode;
  isAllInfo: boolean;
  isClassification?: boolean;
  isUserInput?: boolean;
  text: string;
  onClickDeleteButton?: () => void;
  aiSelected?: boolean;
}

const SearchFilterTag = ({
  type = "search",
  isAllInfo,
  code,
  text,
  isClassification,
  isUserInput,
  onClickDeleteButton,
  aiSelected,
}: Props) => {
  return (
    <S.FilterTagWrapper
      type={type}
      code={code}
      isAllInfo={isAllInfo}
      isUserInput={isUserInput}
      isClassification={isClassification}
      aiSelected={aiSelected}
      onClick={onClickDeleteButton}
    >
      <S.FilterTagText code={code} type={type} isUserInput={isUserInput}>
        {text}
      </S.FilterTagText>
      {type === "result" && (
        <S.FilterDeleteButton type="button">
          <img src={DeleteButton} alt="filter delete icon" />
        </S.FilterDeleteButton>
      )}
    </S.FilterTagWrapper>
  );
};

export default SearchFilterTag;
