import styled from "styled-components";

import UpdateButton from "@EditArticleContainer/shared/components/UpdateButton/UpdateButton";

export const ParagraphDealTypeStatusTableLayoutWrapper = styled.div`
  position: relative;

  .is-display-shell-false {
    display: none;
  }
`;

export const ParagraphDealTypeStatusUpdateButton = styled(UpdateButton)`
  position: absolute;
  left: -1.6rem;
  top: 0;
  transform: translateX(-100%);
`;
