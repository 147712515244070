export const ARTICLE_CONTENTS_INVESTORS_PARAGRAPH_CODE = "TC518" as const;
export const ARTICLE_CONTENTS_INVESTORS_SECTION_CODE = "TY006" as const;
export const ARTICLE_CONTENTS_INVESTORS_PARAGRAPH_TITLE = "Investors" as const;

export const ARTICLE_CONTENTS_INVESTORS_PARAGRAPH_GROUP_KEY = "Investors";

export const ARTICLE_CONTENTS_INVESTORS_SHELL_KEYS = {
  investorName: "Investor Name",
} as const;

export const ARTICLE_CONTENTS_INVESTORS_SHELL_TITLE = {
  investorName: "Investor Name",
} as const;
