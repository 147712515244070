import { useSuspenseQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/queryKeys";

import { companyProfileApis } from "./companyProfile.service";

export const useCompanyProfileQuery = (companyNameList: string[]) => {
  return useSuspenseQuery({
    queryKey: [QUERY_KEYS.companyProfile, companyNameList],
    queryFn: () => companyProfileApis.post(companyNameList),
  });
};
