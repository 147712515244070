import {
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS,
  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS,
  ENTRY_DATA_RIGHTS_SCOPE_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
  LICENSE_AGREEMENT_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import { DealsSection } from "@/types/deals.types";
import {
  DEFAULT_FOCUS_STATUS,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

interface Props {
  groupTableKey: DealsSection;
  groupTableIndex: number;
  groupShellSectionKey: string | null;
  groupShellSectionIndex: number | null;
  tableKey: string | null;
  tableIndex: number;
}

const useDealTermsLicenseeAgreementGroupShellSection = ({
  groupShellSectionIndex,
  groupShellSectionKey,
  groupTableIndex,
  groupTableKey,
  tableIndex,
  tableKey,
}: Props) => {
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();

  const { info, changeInfo } = useTableCreatorPositionInfo();

  const handleClearGroupShellSection = () => {
    const clearRightScopeGroupShellSection = (): ArticleEntryData => {
      if (info.groupShellSectionIndex === null) return entryData;
      if (
        info.groupShellSectionKey !==
        LICENSE_AGREEMENT_GROUP_SHELL_KEYS.rightsScope
      )
        return entryData;

      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          [ENTRY_DATA_LICENSEE_TABLE]: entryData.Deals?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope]: tableInfo[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope
                ].map((groupShellInfo) => {
                  return {
                    ...groupShellInfo,
                    [ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug]: {
                      ...groupShellInfo[ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug],
                      value: [],
                    },
                    [ENTRY_DATA_RIGHTS_SCOPE_KEYS.exclusivity]: {
                      ...groupShellInfo[
                        ENTRY_DATA_RIGHTS_SCOPE_KEYS.exclusivity
                      ],
                      value: [],
                    },
                    [ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication]: {
                      ...groupShellInfo[
                        ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication
                      ],
                      value: [],
                    },
                    [ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory]: {
                      ...groupShellInfo[ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory],
                      value: [],
                    },
                  };
                }),
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    const clearFinancialTermsGroupShell = (): ArticleEntryData => {
      if (info.groupShellSectionIndex === null) return entryData;
      if (
        info.groupShellSectionKey !==
        LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTerms
      )
        return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              const targetSCandLaData =
                tableInfo?.[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS
                    .strategicCollaborationLicenseAgreementTerms
                ];
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                  {
                    ...targetSCandLaData,
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.milestonePaymentAndCondition]:
                      targetSCandLaData[
                        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                          .milestonePaymentAndCondition
                      ].map((subGroupShellInfo) => {
                        return {
                          ...subGroupShellInfo,
                          [ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS.milestoneConditionTitle]:
                            {
                              ...subGroupShellInfo[
                                ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS
                                  .milestoneConditionTitle
                              ],
                              value: [],
                            },
                          [ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS.milestonePayment]:
                            {
                              ...subGroupShellInfo?.[
                                ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS
                                  .milestonePayment
                              ],
                              value: [],
                            },
                        };
                      }),
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa]:
                      targetSCandLaData[
                        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                          .otherPaymentAndDetailScLa
                      ].map((subGroupShellInfo) => {
                        return {
                          ...subGroupShellInfo,
                          [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentDetailScLa]:
                            {
                              ...subGroupShellInfo[
                                ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                                  .otherPaymentDetailScLa
                              ],
                              value: [],
                            },
                          [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentScLa]:
                            {
                              ...subGroupShellInfo?.[
                                ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                                  .otherPaymentScLa
                              ],
                              value: [],
                            },
                        };
                      }),
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.royalty]:
                      {
                        ...targetSCandLaData[
                          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                            .royalty
                        ],
                        value: [],
                      },
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.totalPaymentScLa]:
                      {
                        ...targetSCandLaData[
                          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                            .totalPaymentScLa
                        ],
                        value: [],
                      },
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.disclosedPaymentsSumScLa]:
                      {
                        ...targetSCandLaData[
                          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                            .disclosedPaymentsSumScLa
                        ],
                        value: [],
                      },
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.upfrontPayment]:
                      {
                        ...targetSCandLaData[
                          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                            .upfrontPayment
                        ],
                        value: [],
                      },
                  },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    const clearOtherFinancialTermsGroupShell = (): ArticleEntryData => {
      if (info.groupShellSectionIndex === null) return entryData;
      if (
        info.groupShellSectionKey !==
        LICENSE_AGREEMENT_GROUP_SHELL_KEYS.otherFinancialTerms
      )
        return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData.Deals,
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              const targetSCandLaData =
                tableInfo?.[
                  ENTRY_DATA_LICENSEE_TABLE_KEYS
                    .strategicCollaborationLicenseAgreementTerms
                ];
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                  {
                    ...targetSCandLaData,
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherFinancialTermsScLa]:
                      {
                        ...targetSCandLaData[
                          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                            .otherFinancialTermsScLa
                        ],
                        value: [],
                      },
                  },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    if (
      info.groupShellSectionKey ===
      LICENSE_AGREEMENT_GROUP_SHELL_KEYS.rightsScope
    ) {
      setEntryData(clearRightScopeGroupShellSection());
      changeCurrentHistory({
        entryData: clearRightScopeGroupShellSection(),
        changedDataInfo: {
          target: "groupShellSection",
          action: "clear",
          position: {
            groupTableIndex,
            groupTableKey,
            tableKey,
            tableIndex,
            groupShellSectionKey,
            groupShellSectionIndex,
            groupShellIndex: null,
            groupShellKey: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.groupShellSectionKey ===
      LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTerms
    ) {
      setEntryData(clearFinancialTermsGroupShell());
      changeCurrentHistory({
        entryData: clearFinancialTermsGroupShell(),
        changedDataInfo: {
          target: "groupShellSection",
          action: "clear",
          position: {
            groupTableIndex,
            groupTableKey,
            tableKey,
            tableIndex,
            groupShellSectionKey,
            groupShellSectionIndex,
            groupShellIndex: null,
            groupShellKey: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.groupShellSectionKey ===
      LICENSE_AGREEMENT_GROUP_SHELL_KEYS.otherFinancialTerms
    ) {
      setEntryData(clearOtherFinancialTermsGroupShell());
      changeCurrentHistory({
        entryData: clearOtherFinancialTermsGroupShell(),
        changedDataInfo: {
          target: "groupShellSection",
          action: "clear",
          position: {
            groupTableIndex,
            groupTableKey,
            tableKey,
            tableIndex,
            groupShellSectionKey,
            groupShellSectionIndex,
            groupShellIndex: null,
            groupShellKey: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  };

  const handleClickToClearLicenseeTableGroupShellSection = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShellSection();
    }
  };

  return { handleClickToClearLicenseeTableGroupShellSection };
};

export default useDealTermsLicenseeAgreementGroupShellSection;
