import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconLogOut = ({ width = 24, height = 24, color = "black" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16667 5.125C5.8904 5.125 5.62545 5.23475 5.4301 5.4301C5.23475 5.62545 5.125 5.8904 5.125 6.16667V17.8333C5.125 18.1096 5.23475 18.3746 5.4301 18.5699C5.62545 18.7653 5.8904 18.875 6.16667 18.875H9.5C9.84518 18.875 10.125 19.1548 10.125 19.5C10.125 19.8452 9.84518 20.125 9.5 20.125H6.16667C5.55888 20.125 4.97598 19.8836 4.54621 19.4538C4.11644 19.024 3.875 18.4411 3.875 17.8333V6.16667C3.875 5.55888 4.11644 4.97598 4.54621 4.54621C4.97598 4.11644 5.55888 3.875 6.16667 3.875H9.5C9.84518 3.875 10.125 4.15482 10.125 4.5C10.125 4.84518 9.84518 5.125 9.5 5.125H6.16667ZM14.8914 7.39139C15.1355 7.14731 15.5312 7.14731 15.7753 7.39139L19.9419 11.5581C20.0009 11.617 20.0456 11.6848 20.0761 11.7572C20.1076 11.8319 20.125 11.9139 20.125 12C20.125 12.0874 20.107 12.1707 20.0746 12.2463C20.0474 12.3097 20.0092 12.3696 19.9601 12.4231C19.9535 12.4302 19.9467 12.4372 19.9398 12.4441L15.7753 16.6086C15.5312 16.8527 15.1355 16.8527 14.8914 16.6086C14.6473 16.3645 14.6473 15.9688 14.8914 15.7247L17.9911 12.625H9.5C9.15482 12.625 8.875 12.3452 8.875 12C8.875 11.6548 9.15482 11.375 9.5 11.375H17.9911L14.8914 8.27527C14.6473 8.03119 14.6473 7.63546 14.8914 7.39139Z"
        fill={color}
      />
    </svg>
  );
};

IconLogOut.displayName = "LogOut";

export default IconLogOut;
