import React, { useEffect } from "react";

import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import useModal from "@hooks/useModal";

import DeletedPressModal from "../DeletedPressModal/DeletedPressModal";

type Props = {
  children: React.ReactNode;
};

const DeletedPressBoundary = ({ children }: Props) => {
  const { data: articleInfo } = useArticleInfoQuery();
  const { closeModal, openModal, isModal } = useModal();
  const deletedArticle = articleInfo?.deleted || false;

  useEffect(() => {
    if (deletedArticle) {
      openModal();
    }
  }, [deletedArticle, openModal]);
  return (
    <>
      {children}
      {isModal && <DeletedPressModal closeModal={closeModal} />}
    </>
  );
};

export default DeletedPressBoundary;
