export const PARAGRAPH_CODE_TITLE = "TC001";
export const PARAGRAPH_CODE_TOPIC = "TC002";
export const PARAGRAPH_CODE_DRUG_PROFILE = "TC003";
export const PARAGRAPH_CODE_CLINICAL_TRIAL_DESIGN = "TC004";
export const PARAGRAPH_CODE_CLINICAL_RESULTS = "TC005";
export const PARAGRAPH_CODE_KEY_COMMENTS = "TC006";
export const PARAGRAPH_CODE_COMPETITIVE_LANDSCAPE = "TC007";
export const PARAGRAPH_CODE_DEEP_INSIGHT = "TC008";

export const CLINICAL_TRIALS_PARAGRAPH_CODE = {
  title: "TC001",
  topic: "TC002",
  drugProfile: "TC003",
  clinicalTrialDesign: "TC004",
  clinicalResults: "TC005",
  keyComments: "TC006",
  competitiveLandscape: "TC007",
  deepInsights: "TC008",
} as const;

export const DEALS_PARAGRAPH_CODE = {
  title: "TC101",
  topic: "TC102",
  dealTypeAndStatus: "TC110",
  dealTerms: "TC111",
  additionalInformation: "TC112",
  keyComments: "TC106",
  drugProfile: "TC103",
  competitiveLandscape: "TC107",
  deepInsights: "TC108",
} as const;

export const REGULATORY_PARAGRAPH_CODE = {
  title: "TC201",
  topic: "TC202",
  drugProfile: "TC203",
  keyComments: "TC206",
  approvalProcess: "TC213",
} as const;

export const EXPEDITED_PROGRAM_PARAGRAPH_CODE = {
  title: "TC301",
  topic: "TC302",
  drugProfile: "TC303",
  keyComments: "TC306",
  expeditedProgram: "TC314",
} as const;

export const VENTURE_CAPITAL_PARAGRAPH_CODE = {
  title: "TC501",
  topic: "TC502",
  roundAndStatus: "TC515",
  companyProfile: "TC516",
  financingInformation: "TC517",
  investors: "TC518",
  drugProfile: "TC503",
  additionalInformation: "TC512",
  keyComments: "TC506",
} as const;

export const PARAGRAPH_CODE = {
  TY001: {
    ...CLINICAL_TRIALS_PARAGRAPH_CODE,
  },
  TY002: {
    ...DEALS_PARAGRAPH_CODE,
  },
  TY003: {
    ...REGULATORY_PARAGRAPH_CODE,
  },
  TY004: {
    ...EXPEDITED_PROGRAM_PARAGRAPH_CODE,
  },
  TY006: {
    ...VENTURE_CAPITAL_PARAGRAPH_CODE,
  },
} as const;

export type ParagraphCodeName =
  | keyof typeof CLINICAL_TRIALS_PARAGRAPH_CODE
  | keyof typeof DEALS_PARAGRAPH_CODE
  | keyof typeof REGULATORY_PARAGRAPH_CODE
  | keyof typeof EXPEDITED_PROGRAM_PARAGRAPH_CODE
  | keyof typeof VENTURE_CAPITAL_PARAGRAPH_CODE;

export type SectionType =
  | "TC001"
  | "TC002"
  | "TC003"
  | "TC004"
  | "TC005"
  | "TC006"
  | "TC007"
  | "TC008"
  | "TC101"
  | "TC102"
  | "TC103"
  | "TC106"
  | "TC107"
  | "TC108"
  | "TC110"
  | "TC111"
  | "TC112"
  | "TC201"
  | "TC202"
  | "TC203"
  | "TC206"
  | "TC213"
  | "TC301"
  | "TC302"
  | "TC303"
  | "TC306"
  | "TC314"
  | "TC501"
  | "TC502"
  | "TC503"
  | "TC506"
  | "TC512"
  | "TC515"
  | "TC516"
  | "TC517"
  | "TC518";
