"use client";

import styled, { css } from "styled-components";

export const HeaderMobileWrapper = styled.div<{ $isTransparent: boolean }>`
  display: none;

  @media only screen and (min-width: 1px) and (max-width: 989px) {
    display: flex;
    border-bottom: 0.1rem solid ${({ theme }) => theme.color.neural.gray02};

    ${({ $isTransparent }) =>
      $isTransparent &&
      css`
        border: none;
      `}
  }
`;
