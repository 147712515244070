import { RegulatorySection } from "@/types/regulatory.types";

export const CHECK_TABLE_KEY_BY_GROUP_TABLE_KEY: {
  Regulatory: {
    [key in RegulatorySection]: string | null;
  };
} = {
  Regulatory: {
    "Drug Profile": "Drug",
    "Approval Process Contents": null,
    "Key Comment": null,
  },
};
