import styled, { css } from "styled-components";

export const SelectBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 17.6rem;
  z-index: 10;
`;

export const SelectBox = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 17.6rem;
  height: 2.4rem;
  border: 1px solid ${({ theme }) => theme.color.neural.gray03};
  padding: 0 1.2rem 0 1.6rem;
  cursor: pointer;
  z-index: 10;

  ${({ isOpen }) =>
    isOpen
      ? css`
          border-radius: 1.6rem 1.6rem 0 0;
        `
      : css`
          border-radius: 1.6rem;
        `};

  span {
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.color.neural.gray08};
  }
`;

export const OptionBox = styled.div`
  position: absolute;
  top: 2.4rem;
  width: 100%;
  border-radius: 0 0 1.6rem 1.6rem;
  background-color: ${({ theme }) => theme.color.neural.gray02};
  z-index: 10;
  overflow: hidden;
`;

export const DropDown = styled.div<{ isOpen: boolean }>`
  width: 100%;

  ${({ isOpen }) =>
    isOpen
      ? css`
          height: auto;
          transition: all 0.3s ease;
        `
      : css`
          height: 0px;
          transition: all 0.3s ease;
        `}

  button {
    width: 100%;
    height: 1.6rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.color.neural.gray08};
    text-align: left;
    line-height: 1.6rem;
    margin-bottom: 2rem;
    padding: 0 1.8rem;
    cursor: pointer;

    &:first-child {
      height: 2.6rem;
      padding-top: 1rem;
    }
  }
`;

export const Background = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;

  ${({ isOpen }) =>
    isOpen
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;
