import styled, { css } from "styled-components";

import {
  BorderWrapper,
  DefaultDeleteTableButton,
  DefaultDeleteTableButtonWrapper,
  DefaultShellWrapper,
} from "@FillInTableContainer/shared/styles/shell.style";

export const ShellAddWrapper = styled.div<{ isHideComponent: boolean }>`
  position: relative;

  ${({ isHideComponent }) =>
    isHideComponent &&
    css`
      width: 0;
      height: 0;
      overflow: hidden;
    `}
`;
export const ShellWrapper = styled(DefaultShellWrapper)``;

export const ShellBorderWrapper = styled(BorderWrapper)`
  ${({ isSeparatedShellTitle }) =>
    isSeparatedShellTitle &&
    css`
      padding: 2.4rem 0.8rem 0.8rem 0.8rem;
    `}
`;

export const DeleteTableButtonWrapper = styled(DefaultDeleteTableButtonWrapper)`
  &.button-reposition-medium-shell-true {
    left: 148px;
  }
`;
export const DeleteTableButton = styled(DefaultDeleteTableButton)``;

export const EmptyContentEditable = styled.div`
  width: 100%;
  height: 1px;
  outline: none;
  padding: 0;
  opacity: 0;
`;
