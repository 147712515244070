import {
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  deleteSubGroupShellData: () => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: DealsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    subGroupShellIndex: number;
    subGroupShellKey: string | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useDeleteMergeAcquisitionSubGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    subGroupShellIndex,
    subGroupShellKey,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { changeValidatedInfo } = useValidatedInTableCreator();

  const deleteSubGroupShellData = () => {
    const deleteContingentSubGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData[
            ENTRY_DATA_SECTION_KEYS.deals
          ]?.[ENTRY_DATA_LICENSEE_TABLE]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms]: {
                  ...tableInfo[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ],
                  [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment]:
                    tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                    ][
                      ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment
                    ].filter(
                      (_, subGroupShellInfoIndex) =>
                        subGroupShellInfoIndex !== info.subGroupShellIndex,
                    ),
                },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    if (
      info.subGroupShellKey ===
      MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.contingentPayment
    ) {
      setEntryData(deleteContingentSubGroupShell());
      changeCurrentHistory({
        entryData: deleteContingentSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "delete",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
    }

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  };

  return {
    deleteSubGroupShellData,
  };
};
