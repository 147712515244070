import React from "react";

import { useLocation } from "react-router-dom";

import { ROUTE_PATHS } from "@routes/routePath";

import ClassificationItem from "./components/ClassificationItem/ClassificationItem";
import TopFixInfoItem from "./components/TopFixInfoItem/TopFixInfoItem";
import * as S from "./TopFixInfoList.style";
import { TopFixInfoData } from "../types/topFixInfo.types";

interface Props {
  list: TopFixInfoData[];
  maxWidth: string;
  minWidth: string;
  disabledClassificationManual?: boolean;
  hasManualClassificationRoles?: boolean;
  isFlex?: boolean;
}

const TopFixInfoList = ({
  list,
  maxWidth,
  minWidth,
  disabledClassificationManual,
  hasManualClassificationRoles,
  isFlex,
}: Props) => {
  const location = useLocation();
  const pathName = location.pathname;
  const isPressClassification = pathName === ROUTE_PATHS.pressClassification;

  return (
    <S.TopFixInfoListWrapper
      $maxWidth={maxWidth}
      $minWidth={minWidth}
      $isPressClassification={isPressClassification}
    >
      {list.map(
        ({
          title,
          content,
          subContent,
          maxWidth: itemMaxWidth,
          minWidth: itemMinWidth,
          width: itemWidth,
        }) =>
          title === "Manual Classification" && isPressClassification ? (
            <ClassificationItem
              key={title}
              title={title}
              content={content}
              disabledClassificationManual={disabledClassificationManual}
              hasManualClassificationRoles={hasManualClassificationRoles}
            />
          ) : (
            <TopFixInfoItem
              key={title}
              title={title}
              content={content}
              maxWidth={itemMaxWidth}
              minWidth={itemMinWidth}
              width={itemWidth}
              isFlex={isFlex}
              subContent={subContent}
            />
          ),
      )}
    </S.TopFixInfoListWrapper>
  );
};

export default TopFixInfoList;
