import { useEffect } from "react";

import { CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS } from "@frontend/common";

import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { ENABLE_EDIT_GROUP_SHELL_STATUS_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupShell.constants";
import {
  DEFAULT_FOCUS_STATUS,
  FocusStatus,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useClearClinicalTrialDesign1GroupShell } from "./useClearClinicalTrialDesign1GroupShell";
import { useClearClinicalTrialsDesign2GroupShell } from "./useClearClinicalTrialDesign2GroupShell";

import type { ClinicalTrialsSection } from "@/types/clinicalTrials.types";

interface UseHandleGroupShellReturn {
  focusStatus: FocusStatus;
  isFocused: boolean;
  isClearGroupShell: boolean;
  isDeleteGroupShell: boolean;
  isAlertGroupShell: boolean;
  isEnableEdit: boolean;
  isFocusedAddButton: boolean;
  handleDefaultFocus: () => void;
  handleFocusGroupShell: () => void;
  clickForClearGroupShell: () => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: ClinicalTrialsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

const isNotFocusGroupShell = [
  "Table Notes",
  "Clinical Results",
  "Endpoint Description",
];

export const useHandleClinicalTrialsGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableIndex,
  } = positionPropsInfo;

  const articleSection = "Clinical Trials";
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { validatedInfo, changeValidatedInfo } = useValidatedInTableCreator();

  const { clearClinicalDesign1GroupShell } =
    useClearClinicalTrialDesign1GroupShell({ positionPropsInfo });
  const { clearClinicalDesign2GroupShell } =
    useClearClinicalTrialsDesign2GroupShell({ positionPropsInfo });
  const { data: articleData } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );

  const isEnableEdit = articleData
    ? ENABLE_EDIT_GROUP_SHELL_STATUS_LIST[articleSection][
        groupShellKey || ""
      ].includes(articleData.draftArticleStatus)
    : false;

  const isFocused =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === groupShellKey &&
    info.groupShellIndex === groupShellIndex &&
    info.subGroupShellIndex === null &&
    info.subGroupShellKey === null &&
    info.shellKey === null &&
    isEnableEdit;

  const isFocusedAddButton =
    focusStatus.container === "noFocus" && isEnableEdit;

  const handleFocusGroupShell = () => {
    if (!!groupShellKey && isNotFocusGroupShell.includes(groupShellKey)) return;

    changeInfo({
      ...positionPropsInfo,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
    changeFocusStatus({ type: "clear", container: "groupShell" });
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleDefaultFocus = () => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const isClearGroupShell =
    isFocused &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    focusStatus.type === "clear" &&
    focusStatus.container === "groupShell";

  const isDeleteGroupShell =
    isFocused &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    focusStatus.type === "delete" &&
    focusStatus.container === "groupShell";

  const isAlertGroupShell =
    validatedInfo.filter(
      (validatedItem) =>
        validatedItem.groupTableKey === groupTableKey &&
        validatedItem.groupTableIndex === groupTableIndex &&
        validatedItem.tableIndex === tableIndex &&
        validatedItem.groupShellKey === groupShellKey &&
        validatedItem.groupShellIndex === groupShellIndex &&
        validatedItem.shellKey === null &&
        validatedItem.shellIndex === null &&
        validatedItem.valueIndex === null &&
        validatedItem.type === "alert" &&
        validatedItem.container === "groupShell",
    ).length > 0;

  const clickForClearGroupShell = () => {
    if (
      info.groupShellKey ===
      CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign1
    )
      clearClinicalDesign1GroupShell();

    if (
      info.groupShellKey ===
      CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign2
    )
      clearClinicalDesign2GroupShell();
  };

  const handleKeyDownToDeleteGroupShell = (event: KeyboardEvent) => {
    if (event.key !== "Delete") return;

    clickForClearGroupShell();
  };

  useEffect(() => {
    if (focusStatus.container !== "groupShell") return;
    window.addEventListener("keydown", handleKeyDownToDeleteGroupShell);

    return () => {
      window.removeEventListener("keydown", handleKeyDownToDeleteGroupShell);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusStatus]);

  return {
    focusStatus,
    isFocused,
    isClearGroupShell,
    isDeleteGroupShell,
    isAlertGroupShell,
    isEnableEdit,
    isFocusedAddButton,
    handleDefaultFocus,
    handleFocusGroupShell,
    clickForClearGroupShell,
  };
};
