import {
  CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_SECTION_KEYS,
} from "@frontend/common";

import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";
import type { ClinicalTrialsSection } from "@/types/clinicalTrials.types";

interface Returns {
  deleteDesign2SubGroupShell: () => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: ClinicalTrialsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useDeleteClinicalTrialDesignSubGroupShell = ({
  positionPropsInfo,
}: Props): Returns => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { changeValidatedInfo } = useValidatedInTableCreator();

  const deleteDesign2SubGroupShell = () => {
    if (
      info.subGroupShellKey === CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS.arm
    ) {
      const deleteClinicalTrialDesignTwoSubGroupShell =
        (): ArticleEntryData => {
          return {
            ...entryData,
            [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
              ...entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
              [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: entryData[
                ENTRY_DATA_SECTION_KEYS.clinicalTrial
              ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.map(
                (tableInfo, tableInfoIndex) => {
                  if (tableInfoIndex === info.tableIndex)
                    return {
                      ...tableInfo,
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2]:
                        tableInfo[
                          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                            .clinicalTrialDesign2
                        ].filter(
                          (_, subGroupShellInfoIndex) =>
                            subGroupShellInfoIndex !== info.subGroupShellIndex,
                        ),
                    };

                  return tableInfo;
                },
              ),
            },
          };
        };
      setEntryData(deleteClinicalTrialDesignTwoSubGroupShell());
      changeCurrentHistory({
        entryData: deleteClinicalTrialDesignTwoSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "delete",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
    }

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  };

  return {
    deleteDesign2SubGroupShell,
  };
};
