"use client";

import styled from "styled-components";

export const SearchInput = styled.input`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  width: 100%;
  outline: none;
  border: none;
  color: var(--Color-Common-Text-Primary, #2a2f37);

  &::placeholder {
    color: var(--Color-Common-Text-Tertiary, #7c899c);
  }
  &:focus::placeholder {
    color: transparent;
  }
`;
