import ComponentErrorBoundary from "@/components/shared/business/ComponentErrorBoundary/ComponentErrorBoundary";
import { ExpeditedProgramSection } from "@/types/expeditedProgram.types";
import { useScroll } from "@FillInTableContainer/components/FillInTable/hooks/useScroll";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { TableCreatorGroupTableTitle } from "@FillInTableContainer/shared";
import { useCanEditGroupTable } from "@FillInTableContainer/shared/hooks/useCanEditGroupTable";
import { useGetNumberOfTable } from "@FillInTableContainer/shared/hooks/useGetNumberOfTable";
import { TableCreatorTableList } from "@FillInTableContainer/shared/types/tableCreator.types";
import { isRequiredGroupTable } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getGroupTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import ExpeditedProgramSingleTypeTable from "./components/ExpeditedProgramSingleTypeTable/ExpeditedProgramSingleTypeTable";
import * as S from "./ExpeditedProgramSingleTypeGroupTable.style";

interface Props {
  groupTableKey: ExpeditedProgramSection;
  tableList: TableCreatorTableList;
  groupTableIndex: number;
}

const ExpeditedProgramSingleTypeGroupTable = ({
  groupTableIndex,
  tableList,
  groupTableKey,
}: Props) => {
  const { register } = useScroll();
  const { tableCount } = useGetNumberOfTable();
  const articleSection = useArticleSection();

  const { checkDisableEdit } = useCanEditGroupTable();

  return (
    <ComponentErrorBoundary>
      <S.GroupTableWrapper
        isDisableEdit={checkDisableEdit(groupTableKey)}
        {...register(groupTableKey)}
      >
        <TableCreatorGroupTableTitle
          title={getGroupTableTitle(articleSection, groupTableKey)}
          isRequired={isRequiredGroupTable(articleSection, groupTableKey)}
        />
        <S.TableCreatorTableListWrapper
          isDisableEdit={checkDisableEdit(groupTableKey)}
        >
          {newArrayToCountValue(tableCount(groupTableKey)).map(
            (_, tableIndex) => (
              <div
                key={`table-${String(groupTableIndex)}-${String(tableIndex)}`}
              >
                {tableList.map(({ tableKey, groupShellList }) => (
                  <ExpeditedProgramSingleTypeTable
                    key={`table-${String(groupTableIndex)}-${String(
                      tableIndex,
                    )}`}
                    tableKey={tableKey}
                    groupShellList={groupShellList}
                    groupTableKey={groupTableKey}
                    groupTableIndex={groupTableIndex}
                    tableIndex={tableIndex}
                    tableCount={tableCount(groupTableKey)}
                  />
                ))}
              </div>
            ),
          )}
        </S.TableCreatorTableListWrapper>
      </S.GroupTableWrapper>
    </ComponentErrorBoundary>
  );
};

export default ExpeditedProgramSingleTypeGroupTable;
