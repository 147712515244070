import styled, { css } from "styled-components";

import { ParagraphContentsWrapper } from "@EditArticleContainer/shared/styles/paragraph.style";

export const ParagraphClinicalTrialDesignContentWrapper = styled(
  ParagraphContentsWrapper,
)<{ disabled: boolean }>`
  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
      & * {
        pointer-events: none;
      }
    `}
`;
