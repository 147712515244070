import React from "react";

import ReactDOM from "react-dom/client";
import { ThemeProvider } from "styled-components";

import GlobalFont from "@styles/GlobalFont";
import GlobalStyle from "@styles/GlobalStyle";
import { theme } from "@styles/theme";

export const openModal = (
  render: ({ closeModal }: { closeModal: () => void }) => JSX.Element,
) => {
  const modalRoot = ReactDOM.createRoot(
    document.getElementById("root-modal") as HTMLElement,
  );

  const closeModal = () => {
    modalRoot.unmount();
  };

  modalRoot.render(
    <ThemeProvider theme={theme}>
      <GlobalFont />
      <GlobalStyle />
      {render({ closeModal })}
    </ThemeProvider>,
  );

  return closeModal;
};
