import styled from "styled-components";

export const ErrorComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 0.8rem;
`;
export const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;
  width: 100%;
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: var(--Border-Width-2, 2px) solid
    var(--Color-Common-Border-Warning-Subtle, #fdbba5);
  background: var(--Color-Common-Bg-Warning-Subtle, #feddd2);
  padding: 2rem;
`;

export const ErrorMessageTitle = styled.div`
  width: 100%;
  color: var(--Color-Common-Text-Warning-Bold, #8b2504);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

export const ErrorMessageContent = styled.div`
  width: 100%;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
`;
