import React from "react";

import ComponentErrorBoundary from "@/components/shared/business/ComponentErrorBoundary/ComponentErrorBoundary";
import { TableCreatorGroupTableTitle } from "@FillInTableContainer/shared";
import TableCreatorNavigationTableWrapper from "@FillInTableContainer/shared/components/TableCreatorNavigationTableWrapper/TableCreatorNavigationTableWrapper";
import { EXPEDITED_PROGRAM } from "@FillInTableContainer/shared/constants/expeditedProgramKey.constants";
import { getGroupTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";

import ExpeditedProgramDrugProfileGroupTable from "./components/ExpeditedProgramDrugProfileGroupTable/ExpeditedProgramDrugProfileGroupTable";
import ExpeditedProgramSingleGroupTable from "./components/ExpeditedProgramSingleGroupTable/ExpeditedProgramSingleTypeGroupTable";
import { EXPEDITED_PROGRAM_SINGLE_TABLE_LAYOUT } from "./constants/expeditedProgramTableLayout.constants";

const ExpeditedProgramTableCreator = () => {
  const singleTableLayout = EXPEDITED_PROGRAM_SINGLE_TABLE_LAYOUT;

  const errorWithComponent = (title: string) => (
    <TableCreatorGroupTableTitle title={title} isRequired={false} />
  );

  return (
    <TableCreatorNavigationTableWrapper classificationKey={EXPEDITED_PROGRAM}>
      <>
        <ComponentErrorBoundary
          addComponents={errorWithComponent(
            getGroupTableTitle(EXPEDITED_PROGRAM, "Drug Profile"),
          )}
        >
          <ExpeditedProgramDrugProfileGroupTable />
        </ComponentErrorBoundary>

        {singleTableLayout.map(
          ({ groupTableKey, tableList }, groupTableIndex) => (
            <ComponentErrorBoundary
              addComponents={errorWithComponent(
                getGroupTableTitle(EXPEDITED_PROGRAM, groupTableKey),
              )}
            >
              <ExpeditedProgramSingleGroupTable
                groupTableKey={groupTableKey}
                tableList={tableList}
                groupTableIndex={groupTableIndex + 1}
              />
            </ComponentErrorBoundary>
          ),
        )}
      </>
    </TableCreatorNavigationTableWrapper>
  );
};

export default ExpeditedProgramTableCreator;
