import styled from "styled-components";

export const TopFixInfoWrapper = styled.div`
  display: flex;
  min-width: 120rem;
  gap: 0.4rem;
  width: 100%;
  margin-bottom: 0.8rem;
`;

export const ListWrapper = styled.div<{
  $maxWidth?: string;
  $minWidth?: string;
}>`
  flex: 1 0 0;
  max-width: ${({ $maxWidth }) => $maxWidth};
  min-width: ${({ $minWidth }) => $minWidth};
`;

export const TranslatedId = styled.span`
  color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 145%;
`;
