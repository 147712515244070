import { useCallback, useEffect } from "react";

import {
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY,
  ENTRY_DATA_SECTION_KEYS,
} from "@frontend/common";

import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { useTrialInterventionDrugList } from "@FillInTableContainer/shared/context/TrialInterventionDrugListContext";
import { useEntryDataState, useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

export const useGetTrialInterventionValue = (tableIndex: number): string[] => {
  const entryData = useEntryDataValue(getPressIdFromURL());
  const articleSection = useArticleSection();

  if (!["Clinical Trials", "Clinical Trial Status"].includes(articleSection))
    return [];

  if (articleSection === ENTRY_DATA_SECTION_KEYS.clinicalTrial) {
    const targetTrialInterventionValue =
      entryData[articleSection]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.[
        tableIndex
      ][ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialIntervention
      ] ?? [];

    return targetTrialInterventionValue.map((item) => item.interventionId);
  }

  if (articleSection === ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus) {
    const targetTrialInterventionValue =
      entryData[articleSection]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
      ]?.[tableIndex]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialIntervention
      ] ?? [];

    return targetTrialInterventionValue.map((item) => item.interventionId);
  }

  return [];
};

export const useCheckTrialIntervention = (tableIndex: number) => {
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const articleSection = useArticleSection();

  const handleCheckTrialIntervention = (id: string, drugNames: string) => {
    const newTrialInterventionValue = {
      interventionId: id,
      drugNames,
    };

    if (articleSection === ENTRY_DATA_SECTION_KEYS.clinicalTrial) {
      const changedEntryData = {
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: entryData[
            ENTRY_DATA_SECTION_KEYS.clinicalTrial
          ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === tableIndex) {
                const isDelete =
                  tableInfo[
                    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                  ][
                    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialIntervention
                  ].filter((item) => item.interventionId === id).length > 0;

                return {
                  ...tableInfo,
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]:
                    {
                      ...tableInfo[
                        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                          .clinicalTrialDesign1
                      ],
                      [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialIntervention]:
                        isDelete
                          ? tableInfo[
                              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                                .clinicalTrialDesign1
                            ][
                              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                                .trialIntervention
                            ].filter((item) => item.interventionId !== id)
                          : [
                              ...tableInfo[
                                ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                                  .clinicalTrialDesign1
                              ][
                                ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS
                                  .trialIntervention
                              ],
                              newTrialInterventionValue,
                            ],
                    },
                };
              }
              return tableInfo;
            },
          ),
        },
      };

      setEntryData(changedEntryData);
    }

    if (articleSection === ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus) {
      const changedEntryData = {
        [articleSection]: {
          ...entryData[articleSection],
          "Clinical Trial Design-1": entryData[articleSection]?.[
            "Clinical Trial Design-1"
          ]?.map((tableItem, tableItemIndex) => {
            if (tableItemIndex === tableIndex) {
              const isDelete =
                tableItem["Trial Intervention"].filter(
                  (item) => item.interventionId === id,
                ).length > 0;
              return {
                ...tableItem,
                "Trial Intervention": isDelete
                  ? tableItem["Trial Intervention"].filter(
                      (item) => item.interventionId !== id,
                    )
                  : [
                      ...tableItem["Trial Intervention"],
                      newTrialInterventionValue,
                    ],
              };
            }
            return tableItem;
          }),
        },
      };

      setEntryData(changedEntryData);
    }
  };
  return { handleCheckTrialIntervention };
};

export const useChangeTrialInterventionAccordingToDrugName = () => {
  const { trialInterventionDrugList: drugList } =
    useTrialInterventionDrugList();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const articleSection = useArticleSection();

  const updateClinicalTrialsIntervention = useCallback(() => {
    if (articleSection !== ENTRY_DATA_SECTION_KEYS.clinicalTrial) return;

    const changedEntryData = () => {
      const clinicalTrialDesign1TableLength =
        entryData[articleSection]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.length ??
        0;

      const drugProfileLength =
        entryData[articleSection]?.[ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY]
          ?.length ?? 0;

      if (clinicalTrialDesign1TableLength === 1 || drugProfileLength === 1) {
        return {
          [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
            ...entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
            [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: entryData[
              ENTRY_DATA_SECTION_KEYS.clinicalTrial
            ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.map((tableItem) => {
              const newTrialIntervention = drugList?.map((item) => ({
                interventionId: item.interventionId,
                drugNames: item.drugNames,
              }));

              return {
                ...tableItem,
                [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]: {
                  ...tableItem[
                    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                  ],
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialIntervention]:
                    newTrialIntervention,
                },
              };
            }),
          },
        };
      }
      return {
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: entryData[
            ENTRY_DATA_SECTION_KEYS.clinicalTrial
          ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.map((tableItem) => {
            const clinicalDesign1Table =
              tableItem[
                ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
              ];

            if (
              clinicalDesign1Table[
                ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialIntervention
              ].length === 0
            )
              return tableItem;

            const newTrialIntervention = clinicalDesign1Table[
              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialIntervention
            ].map((item) => {
              const targetDrug = drugList.find(
                (drugItem) => drugItem.interventionId === item.interventionId,
              );

              if (!targetDrug) return item;

              return {
                ...item,
                drugNames: targetDrug.drugNames,
              };
            });

            return {
              ...tableItem,
              [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]: {
                ...clinicalDesign1Table,
                [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS.trialIntervention]:
                  newTrialIntervention,
              },
            };
          }),
        },
      };
    };

    if (JSON.stringify(changedEntryData()) !== JSON.stringify(entryData)) {
      setEntryData(changedEntryData());
    }
  }, [articleSection, drugList, entryData, setEntryData]);

  const updateClinicalTrialStatusTrialIntervention = useCallback(() => {
    if (articleSection !== ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus) return;

    const changedEntryData = () => {
      const clinicalTrialDesign1TableLength =
        entryData[articleSection]?.["Clinical Trial Design-1"]?.length ?? 0;

      const drugProfileLength =
        entryData[articleSection]?.["Drug Profile"]?.length ?? 0;

      if (clinicalTrialDesign1TableLength === 1 || drugProfileLength === 1) {
        return {
          [articleSection]: {
            ...entryData[articleSection],
            "Clinical Trial Design-1": entryData[articleSection]?.[
              "Clinical Trial Design-1"
            ]?.map((tableItem) => {
              const newTrialIntervention = drugList?.map((item) => ({
                interventionId: item.interventionId,
                drugNames: item.drugNames,
              }));

              return {
                ...tableItem,
                "Trial Intervention": newTrialIntervention,
              };
            }),
          },
        };
      }
      return {
        [articleSection]: {
          ...entryData[articleSection],
          "Clinical Trial Design-1": entryData[articleSection]?.[
            "Clinical Trial Design-1"
          ]?.map((tableItem) => {
            if (tableItem["Trial Intervention"].length === 0) return tableItem;

            const newTrialIntervention = tableItem["Trial Intervention"].map(
              (item) => {
                const targetDrug = drugList.find(
                  (drugItem) => drugItem.interventionId === item.interventionId,
                );

                if (!targetDrug) return item;

                return {
                  ...item,
                  drugNames: targetDrug.drugNames,
                };
              },
            );

            return {
              ...tableItem,
              "Trial Intervention": newTrialIntervention,
            };
          }),
        },
      };
    };

    if (JSON.stringify(changedEntryData()) !== JSON.stringify(entryData)) {
      setEntryData(changedEntryData());
    }
  }, [articleSection, drugList, entryData, setEntryData]);

  useEffect(() => {
    if (articleSection === ENTRY_DATA_SECTION_KEYS.clinicalTrial)
      updateClinicalTrialsIntervention();

    if (articleSection === ENTRY_DATA_SECTION_KEYS.clinicalTrialStatus)
      updateClinicalTrialStatusTrialIntervention();
  }, [
    updateClinicalTrialStatusTrialIntervention,
    drugList,
    articleSection,
    updateClinicalTrialsIntervention,
  ]);
};
