import styled from "styled-components";

import { ParagraphWrapper } from "@EditArticleContainer/shared/styles/paragraph.style";

export const ParagraphDrugProfileWrapper = styled(ParagraphWrapper)`
  position: relative;

  &.is-hide-drug-profile-table-true {
    display: none;
  }
`;

export const ParagraphDrugProfileContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;
