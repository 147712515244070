import React from "react";

import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import AccessDeniedGuide from "@organisms/AccessDeniedGuide/AccessDeniedGuide";

import { useAuthorityType } from "../hooks/useAuthorityType";
import { useEditorRoles } from "../hooks/useEditorRoles";
import { InsertImageRoleChecker } from "../services/insertImageRoleChecker";

interface Props {
  children: React.ReactNode;
}

const InsertImageRoleGuard = ({ children }: Props) => {
  const authorityType = useAuthorityType();
  const editorRoles = useEditorRoles();
  const { data: articleInfo } = useArticleInfoQuery();

  if (!articleInfo) {
    throw new Error("articleInfo is not defined");
  }

  const roleChecker = new InsertImageRoleChecker({
    classification: articleInfo.classificationManual,
    authorityType,
    editorRoles,
  });

  if (roleChecker.canAccess) {
    return <>{children}</>;
  } else {
    return <AccessDeniedGuide />;
  }
};

export default InsertImageRoleGuard;
