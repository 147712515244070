import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const IconTrendingUp = ({ width = 16, height = 16 }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.84007 4.6665C9.84007 4.14874 10.2598 3.729 10.7776 3.729H14.1109C14.6287 3.729 15.0484 4.14874 15.0484 4.6665L15.0484 7.99984C15.0484 8.5176 14.6287 8.93734 14.1109 8.93734C13.5931 8.93734 13.1734 8.51761 13.1734 7.99984L13.1734 6.92982L9.49603 10.6072C9.12991 10.9733 8.53632 10.9733 8.1702 10.6072L6.05534 8.49233L2.55158 11.9961C2.18547 12.3622 1.59188 12.3622 1.22576 11.9961C0.859643 11.63 0.859643 11.0364 1.22576 10.6703L5.39243 6.50359C5.75854 6.13747 6.35213 6.13747 6.71825 6.50359L8.83312 8.61846L11.8476 5.604H10.7776C10.2598 5.604 9.84007 5.18427 9.84007 4.6665Z"
        fill="white"
      />
    </svg>
  );
};

export default IconTrendingUp;
