"use client";

import styled, { css } from "styled-components";

export const PlaceholderInputWrapper = styled.div<{
  $width?: string;
  $height?: string;
  $styleType: "line" | "transparent";
  $status: "focus" | "error" | "disabled" | "default";
  $isDefaultShadow: boolean;
}>`
  display: flex;
  width: ${({ $width }) => $width || "24rem"};
  height: ${({ $height }) => $height || "var(--Size-Btn-Placeholder-Md, 4rem)"};
  padding: 0 var(--Spacing-8, 0.8rem);
  align-items: center;
  gap: var(--Spacing-4, 0.4rem);
  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Primary, #fff);

  ${({ $styleType, $status }) =>
    $styleType === "line" &&
    css`
      border: 1px solid var(--Color-Common-Border-Secondary, #b1b8c4);

      ${$status !== "disabled" &&
      css`
        &:hover {
          border: 1px solid
            var(--Color-Common-Border-Interactive-Secondary-Hovered, #96a1b0);
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16),
            0px 0px 4px 0px rgba(0, 0, 0, 0.12);
        }

        &:active {
          border: 1px solid
            var(--Color-Common-Border-Interactive-Secondary-Pressed, #7c899c);
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16),
            0px 0px 1px 0px rgba(0, 0, 0, 0.12);
        }
      `}
    `}

  ${({ $styleType, $isDefaultShadow }) =>
    $styleType === "transparent" &&
    css`
      border: none;

      ${$isDefaultShadow &&
      css`
        border-radius: var(--Border-Radius-12, 1.2rem);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
          0px 0px 2px 0px rgba(0, 0, 0, 0.12);
      `}

      &:hover {
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16),
          0px 0px 4px 0px rgba(0, 0, 0, 0.12);
      }

      &:active {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset,
          0px 0px 2px 0px rgba(0, 0, 0, 0.06) inset;
      }
    `}

  ${({ $status }) =>
    $status === "error" &&
    css`
      border: 1px solid var(--Color-Common-Border-Warning, #e53e06);

      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
        0px 0px 2px 0px rgba(0, 0, 0, 0.12);
    `}
    

    &:focus-within {
    border: 1px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  }

  ${({ $status }) =>
    $status === "disabled" &&
    css`
      border: 1px solid var(--Color-Common-Border-Disabled, #b1b8c4);
      background: var(--Color-Common-Bg-Disabled, #cbd0d8);
      cursor: not-allowed;
    `}
`;

export const DefaultInput = styled.input`
  color: var(--Color-Common-Text-Interactive-Secondary-Pressed, #2a2f37);
  height: 100%;
  background-color: transparent;
  font-size: 1.4rem;
  font-weight: 500;
  outline: none;
  border: none;
  width: 100%;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 500px white inset !important;
  }

  &::placeholder {
    color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
    opacity: 0.5;
  }
`;
