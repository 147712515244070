export const labelData = [
  {
    entity: "E010",
    text: "Drug name, Pipeline, Brand name",
  },
  {
    entity: "E020",
    text: "Target protein/gene, Target cell",
  },
  {
    entity: "E031",
    text: "Modality, Technology, Drug administration",
  },
  {
    entity: "E032",
    text: "Route of Administration",
  },
  {
    entity: "E040",
    text: "Disease, Symptoms",
  },
  {
    entity: "E052",
    text: "Clinical/preclinical Phase",
  },
  {
    entity: "E051",
    text: "Clinical/preclinical design, Status",
  },
  {
    entity: "E060",
    text: "Clinical/preclinical endpoints (Quantitative data for results)",
  },
] as const;

export const expandLabelData = [
  {
    entity: "E031",
    text: "Modality",
    type: "Normal",
    circleText: "",
  },
  {
    entity: "E020",
    text: "Target",
    type: "Normal",
    circleText: "",
  },
  {
    entity: "E010",
    text: "Drug name",
    type: "Normal",
    circleText: "",
  },
  {
    entity: "E052",
    text: "Phase",
    type: "Normal",
    circleText: "",
  },
  {
    entity: "E040",
    text: "Disease",
    type: "Normal",
    circleText: "",
  },
  {
    entity: "E060",
    text: "Endpoints",
    type: "Normal",
    circleText: "",
  },
  {
    entity: "",
    text: "Primary Endpoint",
    type: "BlueBold",
    circleText: "Aa",
  },
  {
    entity: "",
    text: "Endpoint",
    type: "Bold",
    circleText: "Aa",
  },
  {
    entity: "E051",
    text: "Design",
    type: "Normal",
    circleText: "",
  },
  {
    entity: "E032",
    text: "ROA",
    type: "Normal",
    circleText: "",
  },
] as const;
