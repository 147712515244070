import React, { RefObject } from "react";

import { convertContentToHtml, EditableText } from "@frontend/common";

import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { useGetArticleContentRef } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import { useArticleInfoStatus } from "@hooks/useArticleInfo";
import { useUpdateArticleStatus } from "@hooks/useUpdateArticleStatus";

import PressLabelOptionWindow, {
  MousePosition,
} from "./components/PressLabelOptionWindow/PressLabelOptionWindow";
import { useInitLabels } from "./hooks/useInitLabels/useInitLabels";
import { EditableText as EditableTextType } from "./types/editableText.types";
import { getRange } from "./utils/getRange";
import { addLabel, removeLabel } from "./utils/handleLabel";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  item: EditableTextType;
  refKey: string;
  attributionId?: string;
  canTextEdit?: boolean;
  canLabelEdit?: boolean;
  isDisabled?: boolean;
  imagePosition?: "left" | "bottom";
  imageNodeList?: React.ReactNode[];
  isLabelWithLine?: boolean;
  imageColumnCount?: number;
  isClinicalTrialIdentifier?: boolean;
}

interface OptionWindowItem {
  mousePosition: MousePosition;
}

const EditableTextContainer = ({
  item,
  canTextEdit = false,
  canLabelEdit = false,
  isDisabled = false,
  imagePosition,
  imageNodeList = [],
  imageColumnCount = 1,
  isLabelWithLine = false,
  refKey,
  attributionId,
  isClinicalTrialIdentifier,

  ...rest
}: Props) => {
  const ref = useGetArticleContentRef(refKey) as RefObject<HTMLDivElement>; // FIXME: 추후에 변경해야함.
  const [optionWindowItem, setOptionWindowItem] =
    React.useState<OptionWindowItem | null>(null);
  const { initLabels } = useInitLabels(refKey);
  const updateArticleStatus = useUpdateArticleStatus();
  const articleStatus = useArticleInfoStatus();

  const handleUpdateArticleStatus = () => {
    if (
      articleStatus === DRAFT_STATUS_CODE.published ||
      articleStatus === DRAFT_STATUS_CODE.editArticle
    )
      return;
    updateArticleStatus("editArticle");
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (canLabelEdit === true) return;
    if (canLabelEdit === false) return; // 라벨 기능 잠금
    if (getRange().collapsed === true) return;
    handleUpdateArticleStatus();
    setOptionWindowItem({
      mousePosition: {
        top: e.clientY,
        left: e.clientX,
      },
    });
  };

  const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (canTextEdit === false) return;
    handleUpdateArticleStatus();

    if (optionWindowItem !== null) {
      e.preventDefault();
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      document.execCommand("insertHTML", false, "\n");
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    if (canTextEdit === false) return;
    e.preventDefault();
    document.execCommand(
      "insertHTML",
      false,
      e.clipboardData.getData("text/plain"),
    );
  };

  const closeOptionWindow = () => {
    setOptionWindowItem(null);
  };

  return (
    <>
      {optionWindowItem && (
        <PressLabelOptionWindow
          addLabel={(entity: string) => addLabel(entity, attributionId)}
          modifyLabel={(entity: string) => addLabel(entity, attributionId)}
          deleteLabel={removeLabel}
          closeWindow={closeOptionWindow}
          mousePosition={optionWindowItem.mousePosition}
        />
      )}
      <EditableText
        {...rest}
        html={convertContentToHtml(initLabels(item))}
        ref={ref}
        onPaste={handlePaste}
        onMouseUp={handleMouseUp}
        onKeyDown={handleKeydown}
        canTextEdit={canTextEdit}
        isDisabled={isDisabled}
        imagePosition={imagePosition}
        imageNodeList={imageNodeList}
        isLabelWithLine={isLabelWithLine}
        imageColumnCount={imageColumnCount}
        isClinicalTrialIdentifier={isClinicalTrialIdentifier}
      />
    </>
  );
};

export default EditableTextContainer;
