import styled from "styled-components";

import {
  DropdownListWrapper,
  ListItem,
  SectionTitle,
} from "@FillInTableContainer/shared/styles/dropdown.style";

export const ListWrapper = styled(DropdownListWrapper)``;

export const ListSectionTitle = styled(SectionTitle)``;

export const AiItem = styled(ListItem)``;
