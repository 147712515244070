import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { checkIsMergeAndAcquisitionLayout } from "@frontend/common";
import { DEAL_TYPE_KEYS } from "@frontend/common/src/business/FillInTable/constants/dealTypeKeys.constants";

import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

type CurrentDealType = {
  currentDealTypeList: string[];
  isMergeAndAcquisition: boolean;
};

const CheckCurrentDealTypeContext = createContext<CurrentDealType>({
  currentDealTypeList: [],
  isMergeAndAcquisition: false,
});

export const CheckCurrentDealTypeProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const draftArticleId = getPressIdFromURL();
  const [entryData] = useEntryDataState(draftArticleId);
  const [currentChoiceDealType, setCurrentChoiceDealType] = useState<string[]>([
    DEAL_TYPE_KEYS.licenseAgreement,
  ]);
  const currentDealType = useMemo(
    () =>
      entryData?.Deals?.["Deal Type & Status"]?.["Deal Type"].value.map(
        (dealTypeItem) => dealTypeItem.text,
      ) || [],
    [entryData?.Deals],
  );

  useEffect(() => {
    const isMAndAType = checkIsMergeAndAcquisitionLayout(currentDealType);

    if (isMAndAType)
      return setCurrentChoiceDealType([DEAL_TYPE_KEYS.mergeAndAcquisition]);

    setCurrentChoiceDealType(
      currentDealType.map((dealType) => dealType.toLocaleLowerCase()),
    );
  }, [currentDealType]);

  const returnCurrentDealType = useMemo(() => {
    return {
      currentDealTypeList: currentChoiceDealType,
      isMergeAndAcquisition: checkIsMergeAndAcquisitionLayout(
        currentChoiceDealType,
      ),
    };
  }, [currentChoiceDealType]);

  return (
    <CheckCurrentDealTypeContext.Provider value={returnCurrentDealType}>
      {children}
    </CheckCurrentDealTypeContext.Provider>
  );
};

export const useCurrentDealTypeValue = () => {
  const { currentDealTypeList, isMergeAndAcquisition } = useContext(
    CheckCurrentDealTypeContext,
  );

  return {
    currentDealTypeList,
    isMergeAndAcquisition,
  };
};
