import React, { ForwardedRef, forwardRef } from "react";

import DBSearchFilterSelect from "./DBSearchFilterSelect/DBSearchFilterSelect";
import DBSearchInput from "./DBSearchInput/DBSearchInput";
import * as S from "./DBSearchInputContainer.style";
import { IconSearch } from "../../../shared/Icons";
import DBResultsLabel, { LabelType } from "../DBResultsLabel/DBResultsLabel";

interface FilterType {
  key: string;
  name: string;
}

interface Props {
  placeholder: string;
  handleSearchKeyword: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleClickSearchButton: () => void;
  currentFilter: string;
  handleChangeFilter: (filter: FilterType) => void;
  filterList: FilterType[];
  currentSearch?: string;
  handleRemoveSearch: () => void;
  labelType?: LabelType;
}

const DBSearchInputContainer = forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder,
      handleClickSearchButton,
      handleSearchKeyword,
      currentFilter,
      handleChangeFilter,
      filterList,
      currentSearch,
      handleRemoveSearch,
      labelType = "normal",
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <S.InputContainerWrapper>
        <DBSearchFilterSelect
          currentFilter={currentFilter}
          handleChangeFilter={handleChangeFilter}
          filterList={filterList}
        />
        <S.Divider />
        {currentSearch ? (
          <DBResultsLabel
            labelText={currentSearch}
            onClickHandler={handleRemoveSearch}
            labelType={labelType}
            style={{
              maxWidth: "30rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          />
        ) : (
          <S.InputWrapper>
            <DBSearchInput
              onKeyUpHandler={handleSearchKeyword}
              ref={ref}
              placeholder={placeholder}
            />
            <S.SearchButton onClick={handleClickSearchButton}>
              <IconSearch />
            </S.SearchButton>
          </S.InputWrapper>
        )}
      </S.InputContainerWrapper>
    );
  },
);

export default DBSearchInputContainer;
