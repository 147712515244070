"use client";

import styled from "styled-components";

export const ExternalAnchor = styled.a`
  display: flex;
  gap: 0.2rem;
  width: 100%;
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  text-decoration-line: underline;
  cursor: pointer;

  svg {
    display: inline;
  }

  &:hover {
    color: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);

    path {
      fill: #017abc;
    }
  }

  &:active {
    color: var(--Color-Common-Text-Interactive-Primary-Pressed, #015d8e);
    outline: none;

    path {
      fill: #015d8e;
    }
  }
`;
