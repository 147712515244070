import React from "react";

import Dim from "@/components/shared/ui/Dim/Dim";
import Loader from "@/components/shared/ui/Loader/Loader";

interface Props {
  isLoading: boolean;
}
const SignInLoader = ({ isLoading }: Props) => {
  if (!isLoading) return null;
  return (
    <Dim>
      <Loader />
    </Dim>
  );
};

export default SignInLoader;
