import React from "react";

import * as S from "./ArticleDetailTableTitleDrugName.style";

type Props = {
  children: React.ReactNode;
};

const ArticleDetailTableTitleDrugName = ({ children }: Props) => {
  return (
    <S.DrugNameWrapper>
      <S.DrugNameTitle>Drug Name : </S.DrugNameTitle>
      {children}
    </S.DrugNameWrapper>
  );
};

export default ArticleDetailTableTitleDrugName;
