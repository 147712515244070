import { useState } from "react";

import { useModalityCategoryValue } from "@FillInTableContainer/shared/context/ModalityCategoryContext";

import { ModalityCategoryStatus } from "../types/modalityCategory.types";

/** Modality Category Textfield 상태
 * 1. modalityValue가 없는 상태 + Add Modality Category placeholder가 함께 표출  : empty
 * 2. 확정된 입력값이 있는 경우에 수정 불가능한 상태 : confirmed
 * 3. 수정 가능한 상태 + db 데이터가 바로 호출 된 상태: editable
 * 4. 수정 가능한 상태 + 유저가 수정을 진행한 상태 : userEditable
 * 5. 수정 가능한 상태에서 텍스트 입력 중인 상태 : focused
 * 6. empty지만 1번째인 경우 default형태로 표출 : defaultEmpty
 * 7. 상태 값에 따라 confirmed가 아닌 상태에 대해서 빈값으로 표출하기 위한 상태 : none
 *
 * type ModalityCategoryStatus =
  | "empty"
  | "confirmed"
  | "editable"
  | "focused"
  | "clear"
  | "defaultEmpty"
  | "userEditable"
  | "none";

 */
type Returns = {
  status: ModalityCategoryStatus;
  editableStatus: ModalityCategoryStatus[];
  changeFocusedStatus: (focusStatus: boolean) => void;
};
export const useModalityCategoryStatus = (
  modalityValue: string,
  isConfirmed: boolean,
  modalityValueIndex: number,
): Returns => {
  const [isFocused, setIsFocused] = useState(false);
  const { originalModalityCategory, updateModalityCategory } =
    useModalityCategoryValue();

  const checkModalityCategoryStatus = (
    value: string,
    confirmed: boolean,
  ): Extract<
    ModalityCategoryStatus,
    | "empty"
    | "confirmed"
    | "editable"
    | "focused"
    | "defaultEmpty"
    | "userEditable"
    | "none"
  > => {
    if (!value && modalityValueIndex === 0) return "defaultEmpty";
    if (!value) return "empty";
    if (confirmed) return "confirmed";
    if (isFocused) return "focused";
    if (
      originalModalityCategory[modalityValue] !==
      updateModalityCategory[modalityValue]
    )
      return "userEditable";
    return "editable";
  };

  const changeFocusedStatus = (focusStatus: boolean) => {
    setIsFocused(focusStatus);
  };
  const editableStatus: ModalityCategoryStatus[] = [
    "editable",
    "focused",
    "userEditable",
  ];
  return {
    status: checkModalityCategoryStatus(modalityValue, isConfirmed),
    editableStatus,
    changeFocusedStatus,
  };
};
