import React, { useEffect, useRef } from "react";

import { getGroupShellTitle } from "@/components/business/FillInTableContainer/shared/utils/getTextFromData";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import {
  GroupShellDividerPlusBar,
  TableCreatorGroupShellTitle,
} from "@FillInTableContainer/shared";
import { useGetNumberOfShell } from "@FillInTableContainer/shared/hooks/useGetNumberOfShell";
import {
  isSeparatedShellTitle,
  isAddDeleteGroupShell,
} from "@FillInTableContainer/shared/utils/getOptionsFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import * as S from "./ClinicalTrialsTableCreatorGroupShell.style";
import ClinicalTrialsTableCreatorShell from "./components/ClinicalTrialsTableCreatorShell/ClinicalTrialsTableCreatorShell";
import { useHandleClinicalTrialsGroupShell } from "./hooks/useHandleClinicalTrialsGroupShell";

import type { ClinicalTrialsSection } from "@/types/clinicalTrials.types";
import type { TableCreatorShellList } from "@FillInTableContainer/shared/types/tableCreator.types";

interface Props {
  groupTableKey: ClinicalTrialsSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  shellList: TableCreatorShellList;
  tableKey: string | null;
  tableIndex: number;
  groupShellIndex: number;
  groupShellCount: number;
  isTableScroll: boolean;
}
const isNotFocusGroupShell = [
  "Table Notes",
  "Clinical Results",
  "Endpoint Description",
];

const ClinicalTrialsTableCreatorGroupShell = ({
  groupTableKey,
  groupTableIndex,
  groupShellKey,
  shellList,
  tableKey,
  tableIndex,
  groupShellIndex,
  groupShellCount,
  isTableScroll,
}: Props) => {
  const positionPropsInfo = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  };
  const { shellCount } = useGetNumberOfShell();
  const articleSection = useArticleSection();
  const selectButtonRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const {
    focusStatus,
    isFocused,
    isClearGroupShell,
    isDeleteGroupShell,
    isAlertGroupShell,
    isEnableEdit,
    isLastGroupShell,
    isFocusedAddButton,
    handleFocusGroupShell,
    handleDefaultFocus,
    clickAddGroupShellButton,
    handleClickToDeleteGroupShell,
  } = useHandleClinicalTrialsGroupShell({ positionPropsInfo, groupShellCount });

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;
        const clickSelectButton = selectButtonRef.current?.contains(target);
        const clickTitle = titleRef.current?.contains(target);

        if (clickSelectButton || clickTitle) return;
        if (target.dataset.button) return;

        handleDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleDefaultFocus, isDeleteGroupShell, isFocused, isFocusedAddButton]);

  const isPossibleSelected = groupShellKey
    ? !isNotFocusGroupShell.includes(groupShellKey)
    : true;

  return (
    <>
      {isAddDeleteGroupShell(articleSection, groupShellKey) && (
        <GroupShellDividerPlusBar
          onClick={(event) => {
            clickAddGroupShellButton(event, {
              tableIndexParameter: tableIndex,
              groupShellIndexParameter: groupShellIndex,
              groupShellKeyParameter: groupShellKey,
            });
          }}
          isLastPosition={false}
          isVisibility={isFocusedAddButton}
        />
      )}
      <S.GroupShellOutsideWrapper
        isSeparatedTitleRow={isSeparatedShellTitle(
          articleSection,
          groupShellKey,
        )}
        isFocused={isFocused}
        isClearGroupShell={isClearGroupShell}
        isDeleteGroupShell={isDeleteGroupShell}
        isAlertGroupShell={isAlertGroupShell}
      >
        {(isClearGroupShell || isDeleteGroupShell) && isEnableEdit && (
          <S.FixedDeleteButtonWrapper
            isShellTitle={!!getGroupShellTitle(articleSection, groupShellKey)}
          >
            <S.DeleteTableButtonWrapper>
              <S.DeleteTableButton
                data-button="delete-button"
                onClick={handleClickToDeleteGroupShell}
              >
                <img src={IconXMark} alt="button for clear Row" />
                {focusStatus.type === "clear"
                  ? `Clear ${
                      [
                        "Clinical Endpoints (Table)",
                        "Clinical Trial Design-2",
                      ].includes(groupTableKey)
                        ? "Row"
                        : ""
                    }`
                  : null}
                {focusStatus.type === "delete"
                  ? `Delete ${
                      [
                        "Clinical Endpoints (Table)",
                        "Clinical Trial Design-2",
                      ].includes(groupTableKey)
                        ? "Row"
                        : ""
                    }`
                  : null}
              </S.DeleteTableButton>
            </S.DeleteTableButtonWrapper>
          </S.FixedDeleteButtonWrapper>
        )}
        <S.GroupShellWrapper
          isFocused={isFocused}
          isClearGroupShell={isClearGroupShell}
          isDeleteGroupShell={isDeleteGroupShell}
          isAlertGroupShell={isAlertGroupShell}
          isTableScroll={isTableScroll}
        >
          <S.TitleWrapper ref={titleRef}>
            {getGroupShellTitle(articleSection, groupShellKey) && (
              <TableCreatorGroupShellTitle
                groupShellKey={groupShellKey}
                groupShellCount={groupShellCount}
                groupShellIndex={groupShellIndex}
                isAlertGroupShell={isAlertGroupShell}
                onClickHandler={handleFocusGroupShell}
              />
            )}
          </S.TitleWrapper>
          <S.SelectWrapper>
            <S.GroupShellSelectButtonDividerWrapper
              ref={selectButtonRef}
              onClick={handleFocusGroupShell}
              isPossibleSelected={isPossibleSelected}
            >
              <S.GroupShellSelectButtonDivider className="select-button divider" />
              <S.GroupShellSelectButton className="select-button button">
                SELECT
              </S.GroupShellSelectButton>
            </S.GroupShellSelectButtonDividerWrapper>
            <S.GroupShell>
              {shellList.map(({ shellKey, subShellList }, shellListIndex) => (
                <React.Fragment
                  key={`shellList-${String(shellListIndex)}-${shellKey}`}
                >
                  {newArrayToCountValue(
                    shellCount(shellKey, tableIndex, groupShellIndex),
                  ).map((_, shellIndex) => {
                    return (
                      <ClinicalTrialsTableCreatorShell
                        key={`shell-${String(groupTableIndex)}-${String(
                          tableIndex,
                        )}-${String(groupShellIndex)}-${String(shellIndex)}`}
                        groupTableKey={groupTableKey}
                        groupTableIndex={groupTableIndex}
                        groupShellKey={groupShellKey}
                        groupShellIndex={groupShellIndex}
                        shellKey={shellKey}
                        subShellList={subShellList}
                        shellIndex={shellIndex}
                        tableKey={tableKey}
                        tableIndex={tableIndex}
                        shellCount={shellCount(
                          shellKey,
                          tableIndex,
                          groupShellIndex,
                        )}
                        isShellOverOne={
                          shellCount(shellKey, tableIndex, groupShellIndex) > 1
                        }
                        isSeparatedShellTitle={isSeparatedShellTitle(
                          articleSection,
                          groupShellKey,
                        )}
                        isFocusedGroupShell={isFocused}
                        isClearGroupShell={isClearGroupShell}
                        isDeleteGroupShell={isDeleteGroupShell}
                        isAlertGroupShell={isAlertGroupShell}
                      />
                    );
                  })}
                </React.Fragment>
              ))}
            </S.GroupShell>
          </S.SelectWrapper>
        </S.GroupShellWrapper>
      </S.GroupShellOutsideWrapper>
      {isAddDeleteGroupShell(articleSection, groupShellKey) &&
        isLastGroupShell && (
          <GroupShellDividerPlusBar
            onClick={(event) => {
              clickAddGroupShellButton(event, {
                tableIndexParameter: tableIndex,
                groupShellIndexParameter: groupShellIndex + 1,
                groupShellKeyParameter: groupShellKey,
              });
            }}
            isLastPosition
            isVisibility={isFocusedAddButton}
          />
        )}
    </>
  );
};

export default ClinicalTrialsTableCreatorGroupShell;
