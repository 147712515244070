"use client";

import styled, { css } from "styled-components";

export const Checkbox = styled.input<{
  $imageUrl: string;
  $isChangedTextColor: boolean;
  $borderWidth: string;
}>`
  all: unset;
  display: inline-block;
  margin: var(--Spacing-6, 0.48rem);
  width: 1.44rem;
  height: 1.44rem;
  flex-shrink: 0;
  border-radius: var(--Border-Radius-2, 0.12rem);
  border: ${({ $borderWidth }) => $borderWidth} solid
    var(--Color-Common-Border-Interactive-Secondary-Hovered, #96a1b0);
  background: var(--Color-Common-Bg-Primary, #fff);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  box-sizing: border-box;

  &:checked {
    background: var(--Color-Common-Bg-Interactive-Primary-Hovered, #0198e9);
    border: ${({ $borderWidth }) => $borderWidth} solid
      var(--Color-Common-Bg-Interactive-Primary-Hovered, #0198e9);
    background-image: url(${({ $imageUrl }) => $imageUrl});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.44rem 1.44rem;

    ${({ $isChangedTextColor }) =>
      $isChangedTextColor &&
      css`
        ~ span {
          color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
        }
      `}
  }
`;
