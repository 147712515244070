import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useHandleMainLabelList } from "./useHandleMainLabelList";
import { MainLabelNames } from "../types/articleMainLabel.types";

export const useMainLabelOptionList = (labelName: MainLabelNames) => {
  const entryData = useEntryDataValue(getPressIdFromURL());
  const mainLabelInfo = useHandleMainLabelList();

  return mainLabelInfo.getTargetOptionList(labelName, entryData);
};
