import {
  ARTICLE_CONTENTS_FINANCING_INFORMATION_PARAGRAPH_GROUP_KEY as PARAGRAPH_GROUP_KEY,
  ARTICLE_CONTENTS_FINANCING_INFORMATION_SHELL_KEYS as SHELL_KEYS,
} from "@frontend/common";
import dayjs from "dayjs";

import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import {
  REF_CODE_VENTURE_CAPITAL_AMOUNT,
  REF_CODE_VENTURE_CAPITAL_FINANCING_INFORMATION_DETAIL,
  REF_CODE_VENTURE_CAPITAL_FINANCING_PURPOSE,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { VentureCapitalFinancingInformationContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/ventureCapitalFinancingInformationContent.types";

import { createContentFromHTML } from "../../../utils/getValueFromRefMap";

export const useCreateVentureCapitalFinancingInformationContent = () => {
  const refMap = useArticleContentRefMap();

  return () => {
    const content: VentureCapitalFinancingInformationContent = {
      text: {
        [PARAGRAPH_GROUP_KEY]: {
          [SHELL_KEYS.amount]: createContentFromHTML(
            refMap,
            REF_CODE_VENTURE_CAPITAL_AMOUNT,
          ),
          [SHELL_KEYS.financingPurpose]: createContentFromHTML(
            refMap,
            REF_CODE_VENTURE_CAPITAL_FINANCING_PURPOSE,
          ),
          [SHELL_KEYS.financingInformationDetail]: createContentFromHTML(
            refMap,
            REF_CODE_VENTURE_CAPITAL_FINANCING_INFORMATION_DETAIL,
          ),
        },
      },
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
