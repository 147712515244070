import { ParagraphContent } from "@EditArticleContainer/shared/types/paragraphContents/paragraphContents.types";

export const isExistDrugProfileTableValue = (
  tableData: ParagraphContent["TC103"]["text"]["Drug profile"][0],
) => {
  const brandNameValue = tableData?.["Brand Name"]?.value?.at(0)?.text;
  const codeNameValue = tableData?.["Code Name"]?.value?.at(0)?.text;
  const innNameValue = tableData?.["INN Name"]?.value?.at(0)?.text;
  const companyValue = tableData?.["Originator/Developer"]?.value?.at(0)?.text;
  const modalityValue = tableData?.Modality?.value?.at(0)?.text;
  const targetValue = tableData?.Target?.value?.at(0)?.text;
  const moaValue = tableData?.MOA?.value?.at(0)?.text;
  const targetIndicationValue =
    tableData?.["Target Indication"]?.value?.at(0)?.text;
  const roaValue = tableData?.ROA?.value?.at(0)?.text;
  const otherCharacteristicsValue =
    tableData?.["Other Characteristics"]?.value?.at(0)?.text;

  return [
    brandNameValue,
    codeNameValue,
    innNameValue,
    companyValue,
    modalityValue,
    targetValue,
    moaValue,
    targetIndicationValue,
    roaValue,
    otherCharacteristicsValue,
  ].some((item) => !!item);
};
