import styled from "styled-components";

export const Wrapper = styled.span`
  display: inline-flex;
  padding-left: 0.6rem;
`;
export const DuplicateButton = styled.button`
  display: flex;
  width: 6rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-Btn-4, 0.4rem);

  font-size: 1.2rem;
  line-height: 100%;
  font-weight: 500;
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);

  border-radius: var(--Border-Radius-4, 0.4rem);
  border: var(--Border-Width-1, 1px) solid
    var(--Color-Common-Border-Interactive-Secondary, #b1b8c4);
  background: var(--Color-Common-Bg-Interactive-Secondary, #fff);
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-4, 0.4rem);
  cursor: pointer;

  &:hover {
    color: var(--Color-Common-Text-Interactive-Secondary-Hovered, #3d4551);
    border: 1px solid
      var(--Color-Common-Border-Interactive-Secondary-Hovered, #96a1b0);
    background: var(--Color-Common-Bg-Interactive-Secondary-Hovered, #f0f2f4);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  }

  &:active {
    color: var(--Color-Common-Text-Interactive-Secondary-Pressed, #2a2f37);
    border-radius: var(--Border-Radius-4, 0.4rem);
    border: 1px solid
      var(--Color-Common-Border-Interactive-Secondary-Pressed, #7c899c);
    background: var(--Color-Common-Bg-Interactive-Secondary-Pressed, #e5e7eb);

    /* Pushed/Light/Inner Shadow4 */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset,
      0px 0px 2px 0px rgba(0, 0, 0, 0.06) inset;
  }
`;
