import { ValueOf } from "@/types/util.types";
import IconAIComplete from "@images/pressList/status/icon-ai-complete.svg";
import IconAIProgress from "@images/pressList/status/icon-ai-progress.svg";
import IconDBSaved from "@images/pressList/status/icon-db-saved.svg";
import IconEditing from "@images/pressList/status/icon-editing.svg";
import IconNotAI from "@images/pressList/status/icon-not-ai.svg";
import IconPublished from "@images/pressList/status/icon-published.svg";
import IconReviewProgress from "@images/pressList/status/icon-review-progress.svg";
import IconSystemError from "@images/pressList/status/icon-system-error.svg";
import IconUnsuitable from "@images/pressList/status/icon-unsuitable.svg";

import { ROUTE_PATHS } from "../../../routes/routePath";

import type { ProcessStatusCode } from "@/types/code.types";

export const DRAFT_STATUS_LIST: ProcessStatusCode[] = [
  "S110",
  "S112",
  "S300",
  "S111",
  "S120",
  "S121",
  "S910",
  "S911",
  "S210",
  "S220",
  "S920",
  "S930",
];

export const DRAFT_STATUS_PATH: Record<
  ProcessStatusCode,
  ValueOf<typeof ROUTE_PATHS> | null
> = {
  S110: ROUTE_PATHS.pressClassification,
  S112: ROUTE_PATHS.pressClassification,
  S300: ROUTE_PATHS.pressClassification,
  S111: ROUTE_PATHS.pressClassification,
  S120: ROUTE_PATHS.fillInTable,
  S121: ROUTE_PATHS.fillInTable,
  S910: ROUTE_PATHS.fillInTable,
  S911: ROUTE_PATHS.fillInTable,
  S210: ROUTE_PATHS.editArticle,
  S220: ROUTE_PATHS.insertImage,
  S920: ROUTE_PATHS.editArticle,
  S930: ROUTE_PATHS.pressClassification,
  S800: null,
  SystemError: null,
};

export const DRAFT_STATUS_INFO: Record<
  ProcessStatusCode,
  { text: string; path: string | null; icon: string }
> = {
  S110: {
    text: "Not for Ai Entry Or AI Entry Done",
    path: DRAFT_STATUS_PATH.S110,
    icon: IconNotAI,
  },
  S112: {
    text: "Not for Ai Entry",
    path: DRAFT_STATUS_PATH.S112,
    icon: IconNotAI,
  },
  S300: {
    text: "AI Entry in Progress",
    path: DRAFT_STATUS_PATH.S300,
    icon: IconAIProgress,
  },
  S111: {
    text: "AI Entry Done",
    path: DRAFT_STATUS_PATH.S111,
    icon: IconAIComplete,
  },
  S120: {
    text: "Review in Progress (Full)",
    path: DRAFT_STATUS_PATH.S120,
    icon: IconReviewProgress,
  },
  S121: {
    text: "Review in Progress (Half)",
    path: DRAFT_STATUS_PATH.S121,
    icon: IconReviewProgress,
  },
  S910: {
    text: "DB Saved (Full)",
    path: DRAFT_STATUS_PATH.S910,
    icon: IconDBSaved,
  },
  S911: {
    text: "DB Saved (Half)",
    path: DRAFT_STATUS_PATH.S911,
    icon: IconDBSaved,
  },
  S210: {
    text: "Edit Article",
    path: DRAFT_STATUS_PATH.S210,
    icon: IconEditing,
  },
  S220: {
    text: "Insert Image",
    path: DRAFT_STATUS_PATH.S220,
    icon: IconEditing,
  },
  S920: {
    text: "Published",
    path: DRAFT_STATUS_PATH.S920,
    icon: IconPublished,
  },
  S930: {
    text: "Unsuitable",
    path: DRAFT_STATUS_PATH.S930,
    icon: IconUnsuitable,
  },
  S800: {
    text: "System Error",
    path: DRAFT_STATUS_PATH.S800,
    icon: IconSystemError,
  },
  SystemError: {
    text: "System Error",
    path: DRAFT_STATUS_PATH.SystemError,
    icon: IconSystemError,
  },
};
