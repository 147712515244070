export const ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE = "Clinical Endpoints (Table)";

export const ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS = {
  endpointsTableTitle: "Endpoints Table Title",
  endpointsTableInformation: "Endpoints Table Information",
  endpointsTableNotes: "Endpoints Table Notes",
} as const;

export const ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS = {
  endpointClassification: "Endpoint Classification",
  endpointNameIncludingMeasurementTiming:
    "Endpoint Name including Measurement Timing",
  endpointGroupInformation: "Endpoint Group Information",
} as const;

export const ENTRY_DATA_ENDPOINT_GROUP_INFORMATION_KEYS = {
  groupName: "Group Name",
  groupData: "Group Data",
  pValue: "P-Value",
} as const;
