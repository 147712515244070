"use client";

import styled, { css } from "styled-components";

export const TitleShell = styled.div<{ $width?: string; $padding?: string }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  flex: 1;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: var(--font-pt-serif);
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 150%;
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
  padding: 1rem;
  border-right: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
      max-width: ${$width};
    `};

  ${({ $padding }) =>
    $padding &&
    css`
      padding: ${$padding};
    `};
`;
