export const EFO_ROUTE_PATHS = {
  articleMain: "/",
  signIn: "/sign-in",
  signUp: "/sign-up",
  aboutUs: "/about-us",
  ourTeam: "/about-us#ourTeam",
  contactUs: "/contact-us",
  helpCenter: "/help-center",
  articleSection: "/section",
  subscribe: "/subscribe",
  myAccount: "/my-account",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  checkEmail: "/check-email",
  resendEmail: "/resend-email",
  expiredEmail: "/expired-email",
  privacyPolicy: "/privacy-policy",
  termsOfUse: "/terms-of-use",
  authentication: "/authentication",
  efortlessDB: "/drug-profile-db",
  socialSignUp: "/social-sign-up",
  dealRankings: "/deal-rankings",
  dealsDB: "/deals-db",
  efortlessReport: "/efortless-report",
  clinicalTrialsDB: "/clinical-trials-db",
  regulatory: "/regulatory",
  regulatoryDB: "/regulatory-db",
  updateDaily: "/update/daily",
  searchArticles: "/search-articles",
  database: "/database",
  introduction: "/introduction",
} as const;
