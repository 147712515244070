"use client";

import styled, { css } from "styled-components";

export const TableWidthSettingWrapper = styled.div`
  width: 100rem;

  .table-title {
    > div {
    }
  }

  .title,
  .shell-value {
    position: relative;
    font-size: 1.6rem;
    line-height: 150%;
  }

  .is-focus-shell-title-true {
    background: var(
      --Color-Common-Bg-Interactive-Primary-Subtle-Pressed,
      #d1efff
    );
    border-top: 2px solid
      var(--Color-Common-Border-Interactive-Primary, #0198e9);
    border-left: 2px solid
      var(--Color-Common-Border-Interactive-Primary, #0198e9);
    border-right: 2px solid
      var(--Color-Common-Border-Interactive-Primary, #0198e9) !important;
  }

  .is-focus-shell-value-true {
    background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #f0f9ff);
    border-left: 0.2rem solid
      var(--Color-Common-Border-Interactive-Primary, #0198e9);
    border-right: 0.2rem solid
      var(--Color-Common-Border-Interactive-Primary, #0198e9) !important;

    &::before {
      position: absolute;
      top: -0.2rem;
      left: -0.1rem;
      display: block;
      content: "";
      width: 0.1rem;
      height: 0.2rem;

      background-color: var(--Color-Common-Border-Interactive-Primary, #0198e9);
    }
    &::after {
      position: absolute;
      top: -0.2rem;
      right: -0.1rem;
      display: block;
      content: "";
      width: 0.1rem;
      height: 0.2rem;

      background-color: var(--Color-Common-Border-Interactive-Primary, #0198e9);
    }

    &.is-endpoints-last-row-true {
      border-bottom: 2px solid
        var(--Color-Common-Border-Interactive-Primary, #0198e9);
    }
  }
`;

export const TableRowContentWrapper = styled.div`
  width: 100%;

  #row-true {
    border-bottom: 2px solid
      var(--Color-Common-Border-Interactive-Primary, #0198e9);
  }
`;

export const TableRowWrapper = styled.div<{
  $columnLength: number;
  $isFocusRowBlue?: boolean;
  $isNotBlueTopBorder?: boolean;
}>`
  width: 100%;

  &:last-of-type {
    > div {
      border-bottom: none;
    }
  }

  ${({ $isFocusRowBlue, $isNotBlueTopBorder }) =>
    $isFocusRowBlue &&
    css`
      border: 2px solid var(--Color-Common-Border-Interactive-Primary, #0198e9);

      .is-row-title-blue-true {
        position: relative;
        background: var(
          --Color-Common-Bg-Interactive-Primary-Subtle-Pressed,
          #d1efff
        );
      }

      .is-row-value-blue-true {
        color: var(--Color-Common-Text-Info, #004aeb);
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 150%;

        background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #f0f9ff);
      }
      ${$isNotBlueTopBorder &&
      css`
        border-top: none;
      `}
    `}
`;

export const RowValueShell = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
`;

export const RowTitleShell = styled.div`
  flex: 1;
  position: relative;
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
`;
