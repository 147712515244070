"use client";

import styled from "styled-components";

export const TableHeaderList = styled.div`
  display: flex;
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);

  border-top-right-radius: var(--Border-Radius-8, 0.8rem);
  border-top-left-radius: var(--Border-Radius-8, 0.8rem);

  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  border-bottom: none;
`;
