import { useCallback, useRef } from "react";

import { DealsSection } from "@/types/deals.types";
import { TableCreatorGroupShellList } from "@FillInTableContainer/shared/types/tableCreator.types";

import DealsDealTypeGroupShell from "./components/DealsDealTypeGroupShell/DealsDealTypeGroupShell";
import * as S from "./DealsDealTypeTable.style";
import { useHandleDealTypeTableCreatorTable } from "./hooks/useHandleDealTypeTableCreatorTable";

interface Props {
  groupTableKey: DealsSection;
  tableKey: string | null;
  groupShellList: TableCreatorGroupShellList;
  groupTableIndex: number;
  tableIndex: number;
}

const DealsDealTypeTable = ({
  groupShellList,
  groupTableIndex,
  tableIndex,
  tableKey,
  groupTableKey,
}: Props) => {
  const groupShellRef = useRef<HTMLDivElement[]>([]);

  const tablePropsInfo: Props = {
    groupTableKey,
    tableKey,
    groupTableIndex,
    tableIndex,
    groupShellList,
  };

  const { focusStatus, isFocused } =
    useHandleDealTypeTableCreatorTable(tablePropsInfo);

  const addToRefs = useCallback((element: HTMLDivElement) => {
    groupShellRef.current.push(element);
  }, []);

  const isClearTable =
    isFocused &&
    focusStatus.type === "clear" &&
    focusStatus.container === "table";

  return (
    <S.TableWrapper isFocused={isFocused} isClearTable={isClearTable}>
      {groupShellList.map(
        ({ groupShellKey, shellList }, groupShellListIndex) => (
          <S.GroupShellWrapper
            ref={addToRefs}
            key={`groupShellList-${String(groupShellListIndex)}`}
          >
            <DealsDealTypeGroupShell
              shellList={shellList}
              groupTableKey={groupTableKey}
              groupTableIndex={groupTableIndex}
              tableKey={tableKey}
              tableIndex={tableIndex}
              groupShellKey={groupShellKey}
              groupShellIndex={groupShellListIndex}
            />
          </S.GroupShellWrapper>
        ),
      )}
    </S.TableWrapper>
  );
};

export default DealsDealTypeTable;
