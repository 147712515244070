import { useFillInTableRefMap } from "../contexts/FillInTableRefMapContext";

export const useScroll = () => {
  const { refMap, register } = useFillInTableRefMap();

  const scroll = (sectionKey: string) => {
    setTimeout(() => {
      const targetRef = refMap.get(sectionKey);
      if (targetRef && typeof targetRef !== "function") {
        targetRef.current?.scrollIntoView({
          behavior: "smooth",
          inline: "start",
          block: "start",
        });
      }
    }, 100);
  };

  return {
    scroll,
    register,
  };
};
