"use client";

import styled from "styled-components";

export const ResetButtonWrapper = styled.button`
  display: flex;
  height: 2.8rem;
  min-height: var(--Size-Btn-Minimum, 2.8rem);
  max-height: var(--Size-Btn-Maximum, 4.8rem);
  padding: var(--Spacing-Btn-8, 0.8rem);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-Btn-4, 0.4rem);
  cursor: pointer;

  &:hover {
    border-radius: var(--Border-Radius-4, 0.4rem);
    background: var(--Color-Common-Bg-Interactive-Secondary-Hovered, #f0f2f4);

    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
      0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  &:active {
    border-radius: var(--Border-Radius-4, 0.4rem);
    background: var(--Color-Common-Bg-Interactive-Secondary-Pressed, #e5e7eb);

    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
      0px 1px 2px 0px rgba(0, 0, 0, 0.16);
  }
`;

export const ResetButtonText = styled.span`
  color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  font-weight: 500;
  line-height: 145%;
`;

export const IconXMark = styled.div`
  > svg {
    width: 2rem;
    height: 2rem;
  }
`;
