"use client";

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 0.8rem;
  position: relative;
`;

export const LabelListWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 7.6rem;
  padding-bottom: 0.4rem;
  overflow: hidden;
  position: absolute;
  top: 0.8rem;
`;

export const ResultLabelList = styled.div`
  display: flex;
  min-height: var(--Size-Btn-Minimum, 2.8rem);
  max-height: 7.6rem;
  gap: 0.4rem var(--Spacing-4, 0.4rem);
  flex-wrap: wrap;
  width: 100%;
`;

export const HiddenCountResultWrapper = styled.div`
  visibility: hidden;
  width: 100%;
  max-height: 7.6rem;
  overflow: hidden;
  position: relative;
`;

export const LastLabelItem = styled.div``;

export const moreLabelCountWrapper = styled.span``;
export const moreLabelCount = styled.span`
  color: var(--Color-Common-Text-Tertiary, #7c899c);

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 130%;
`;

export const LabelItem = styled.div`
  display: inline-flex;
  gap: 0.4rem;
  align-items: center;
`;
