import { useSavedAsDraftContentsWithShortCut } from "@EditArticleContainer/shared/hooks/useSavedAsDraftContentsWithShortCut";
import { useClassificationManual } from "@hooks/useArticleInfo";

import * as S from "./ArticleDetailBody.style";
import { PARAGRAPH_ACCORDING_TO_CLASSIFICATION } from "./constants/common.constants";

const ArticleDetailBody = () => {
  useSavedAsDraftContentsWithShortCut();
  const classificationManual = useClassificationManual();
  const paragraphList =
    PARAGRAPH_ACCORDING_TO_CLASSIFICATION[classificationManual];

  if (!paragraphList) {
    throw new Error(
      `There is no paragraph list for classification ${classificationManual}`,
    );
  }

  return (
    <S.ArticleDetailBodyWrapper>
      {paragraphList.map(({ component: Component, id }) => (
        <Component key={id} />
      ))}
    </S.ArticleDetailBodyWrapper>
  );
};

export default ArticleDetailBody;
