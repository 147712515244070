import { useCallback } from "react";

import {
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL,
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS,
  ENTRY_DATA_CONTINGENT_PAYMENT_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_LICENSOR_TABLE,
  ENTRY_DATA_LICENSOR_TABLE_KEYS,
  ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS,
  ENTRY_DATA_RIGHTS_SCOPE_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  MERGE_ACQUISITION_TABLE_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import {
  DEFAULT_FOCUS_STATUS,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import { useTableCreatorPositionInfo } from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleTableCreatorTableReturn {
  handleClickLicenseeTableClearButton: () => void;
  handleKeyDownToClearLicenseeTable: (event: KeyboardEvent) => void;
}

export interface TableInfoProps {
  groupTableKey: DealsSection;
  tableKey: string | null;
  groupTableIndex: number;
  tableIndex: number;
}

export const useHandleMergeLicenseeTable = (
  tablePropsInfo: TableInfoProps,
): UseHandleTableCreatorTableReturn => {
  const { groupTableKey, tableKey, groupTableIndex, tableIndex } =
    tablePropsInfo;

  const { changeCurrentHistory } = useTableCreatorCurrentHistory();

  const { info } = useTableCreatorPositionInfo();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();

  const handleClearTable = useCallback(() => {
    const targetLicenseeData =
      entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSEE_TABLE];

    const clearDealPurposeTable = (): ArticleEntryData => {
      const additionalInformationTableData =
        entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL
        ];
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL]: {
            ...additionalInformationTableData,
            [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.purposeOfTheDeal]: {
              ...additionalInformationTableData?.[
                ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.purposeOfTheDeal
              ],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    const clearLicensorTable = (): ArticleEntryData => {
      const licensorTableData =
        entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[ENTRY_DATA_LICENSOR_TABLE];
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSOR_TABLE]: {
            ...licensorTableData,
            [ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorName]: {
              ...licensorTableData?.[
                ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorName
              ],
              value: [],
            },
            [ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorResponsibilityRights]: {
              ...licensorTableData?.[
                ENTRY_DATA_LICENSOR_TABLE_KEYS.licensorResponsibilityRights
              ],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    const clearLicenseeTable = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: targetLicenseeData?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                const mergeData =
                  tableInfo?.[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                  ];
                return {
                  ...tableInfo,
                  [ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName]: {
                    ...tableInfo?.[ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName],
                    value: [],
                  },
                  [ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope]: tableInfo?.[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope
                  ]?.map((groupShellInfo) => {
                    return {
                      ...groupShellInfo,
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug]: {
                        ...groupShellInfo?.[ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug],
                        value: [],
                      },
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.exclusivity]: {
                        ...groupShellInfo?.[
                          ENTRY_DATA_RIGHTS_SCOPE_KEYS.exclusivity
                        ],
                        value: [],
                      },
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication]: {
                        ...groupShellInfo?.[
                          ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication
                        ],
                        value: [],
                      },
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory]: {
                        ...groupShellInfo?.[
                          ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory
                        ],
                        value: [],
                      },
                    };
                  }),
                  [ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms]: {
                    [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.licenseResponsibilityRightsMa]:
                      {
                        ...mergeData[
                          ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                            .licenseResponsibilityRightsMa
                        ],
                        value: [],
                      },
                    [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.numberOfAcquiringShares]:
                      {
                        ...mergeData[
                          ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                            .numberOfAcquiringShares
                        ],
                        value: [],
                      },
                    [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.otherFinancialTermsMa]:
                      {
                        ...mergeData[
                          ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                            .otherFinancialTermsMa
                        ],
                        value: [],
                      },
                    [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.pricePerShare]: {
                      ...mergeData[
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.pricePerShare
                      ],
                      value: [],
                    },
                    [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.totalPaymentMa]: {
                      ...mergeData[
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.totalPaymentMa
                      ],
                      value: [],
                    },
                    [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumMa]:
                      {
                        ...mergeData[
                          ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                            .disclosedPaymentsSumMa
                        ],
                        value: [],
                      },
                    [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.disclosedPaymentsSumPricePerShareMa]:
                      {
                        ...mergeData[
                          ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                            .disclosedPaymentsSumPricePerShareMa
                        ],
                        value: [],
                      },
                    [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPaymentMa]:
                      {
                        ...mergeData[
                          ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                            .upfrontPaymentMa
                        ],
                        value: [],
                      },
                    [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.upfrontPricePerShareMa]:
                      {
                        ...mergeData[
                          ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                            .upfrontPricePerShareMa
                        ],
                        value: [],
                      },
                    [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.mergerFinancialTermsMa]:
                      {
                        ...mergeData[
                          ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                            .mergerFinancialTermsMa
                        ],
                        value: [],
                      },
                    [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment]:
                      mergeData[
                        ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                          .contingentPayment
                      ].map((subGroupShellInfo) => {
                        return {
                          [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentMilestoneMa]:
                            {
                              ...subGroupShellInfo[
                                ENTRY_DATA_CONTINGENT_PAYMENT_KEYS
                                  .contingentMilestoneMa
                              ],
                              value: [],
                            },
                          [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPaymentMa]:
                            {
                              ...subGroupShellInfo[
                                ENTRY_DATA_CONTINGENT_PAYMENT_KEYS
                                  .contingentPaymentMa
                              ],
                              value: [],
                            },
                          [ENTRY_DATA_CONTINGENT_PAYMENT_KEYS.contingentPricePerShareMa]:
                            {
                              ...subGroupShellInfo[
                                ENTRY_DATA_CONTINGENT_PAYMENT_KEYS
                                  .contingentPricePerShareMa
                              ],
                              value: [],
                            },
                        };
                      }),
                  },
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    const clearAdditionalInformationTable = (): ArticleEntryData => {
      const additionalInformationTableData =
        entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL
        ];
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData.Deals,
          [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL]: {
            ...additionalInformationTableData,
            [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.additionalInformation]:
              {
                ...additionalInformationTableData?.[
                  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS
                    .additionalInformation
                ],
                value: [],
              },
          },
        },
      } as ArticleEntryData;
    };

    if (info.tableKey === MERGE_ACQUISITION_TABLE_KEYS.dealPurpose) {
      setEntryData(clearDealPurposeTable());
      changeCurrentHistory({
        entryData: clearDealPurposeTable(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (info.tableKey === MERGE_ACQUISITION_TABLE_KEYS.acquiredCompany) {
      setEntryData(clearLicensorTable());
      changeCurrentHistory({
        entryData: clearLicensorTable(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (info.tableKey === MERGE_ACQUISITION_TABLE_KEYS.acquiringCompany) {
      setEntryData(clearLicenseeTable());
      changeCurrentHistory({
        entryData: clearLicenseeTable(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (info.tableKey === MERGE_ACQUISITION_TABLE_KEYS.additionalInformation) {
      setEntryData(clearAdditionalInformationTable());
      changeCurrentHistory({
        entryData: clearAdditionalInformationTable(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    entryData,
    groupTableIndex,
    groupTableKey,
    info.tableIndex,
    info.tableKey,
    setEntryData,
    tableIndex,
    tableKey,
  ]);

  const handleClickLicenseeTableClearButton = () => {
    if (focusStatus.type === "clear") handleClearTable();
  };

  const handleKeyDownToClearLicenseeTable = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearTable();
      }
      changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    },
    [focusStatus.type, changeValidatedInfo, handleClearTable],
  );

  return {
    handleClickLicenseeTableClearButton,
    handleKeyDownToClearLicenseeTable,
  };
};
