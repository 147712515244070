import { returnTableLayoutAffectingDealType } from "@frontend/common";

import { Deals } from "@/types/deals.types";

export const getDealType = (
  entryData: Partial<{
    Deals: Partial<Deals>;
  }>,
) => {
  const currentDealTypeList =
    entryData?.Deals?.["Deal Type & Status"]?.["Deal Type"].value.map(
      (dealTypeItem) => dealTypeItem.text,
    ) || [];

  return returnTableLayoutAffectingDealType(currentDealTypeList);
};
