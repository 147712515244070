import { usePageEntryQuery } from "@/queries/hooks/usePageEntry";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import {
  checkSuperAdmin,
  useAuthorityType,
} from "@libs/roles/hooks/useAuthorityType";
import { ROUTE_PATHS } from "@routes/routePath";
import { getCookie } from "@utils/cookie";
import { usePressIdFromURL } from "@utils/getStateFromURL";

export const useCheckPageEntryInPressClassification = () => {
  const draftArticleId = usePressIdFromURL();
  const pathForCheckingWritingFillInTable = window.location.href.replace(
    ROUTE_PATHS.pressClassification,
    ROUTE_PATHS.fillInTable,
  );
  const { data: articleInfo } = useArticleInfoQuery(Number(draftArticleId));
  const { data: pageEntryData } = usePageEntryQuery(
    pathForCheckingWritingFillInTable,
  );

  const authorityType = useAuthorityType();
  const isSuperAdmin = checkSuperAdmin(authorityType);

  const checkCanEntryPage = () => {
    if (!articleInfo) return false;

    if (isSuperAdmin) return true;

    if (!articleInfo.ftEditorName) {
      if (!pageEntryData) return true;
      if (pageEntryData.userId === getCookie("accountEmail")) return true;
      return false;
    }

    return true;
  };

  return {
    canEntryFillInTablePage: checkCanEntryPage(),
  };
};
