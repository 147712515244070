import styled from "styled-components";

export const SelectButtonWrapper = styled.div<{
  $top: string;
  $left: string;
}>`
  position: absolute;
  top: ${({ $top }) => $top};
  left: ${({ $left }) => $left};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1.6rem;
  z-index: 10;
  cursor: pointer;

  &:hover {
    & > div {
      display: flex;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.8rem;
  border-radius: 0.4rem;
  background: var(--Color-Common-Bg-Interactive-Tertiary-Hovered, #8ff2ff);
  display: none;
`;

export const SelectButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  align-items: center;
  justify-content: center;
  width: 5.3rem;
  height: 1.6rem;
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;

  border-radius: var(--Border-Radius-2, 0.2rem);
  outline: 2px solid
    var(--Color-Common-Border-Interactive-Tertiary-Hovered, #2ae5fe);
  background: var(--Color-Node-Cyan-600, #01abc1);
  /* Elevation/Light/Shadow4 */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);
`;
