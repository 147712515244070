import React from "react";

import {
  PrimaryEndpointTag,
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
} from "@frontend/common";
import SwitchButton from "@frontend/common/src/shared/components/core/TableSwitchButton/TableSwitchButton";

import { Table } from "@EditArticleContainer/shared/components/ArticleTable/ArticleTable";
import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { theme } from "@styles/theme";

import * as S from "./ClinicalEndpointsTableContent.style";
import { prunedArticleTable } from "./utils/prunedArticleTable";
import { ClinicalEndpointsTable } from "../../../../utils/clinicalEndpointsTable";

interface Props {
  clinicalEndpointsTable: ClinicalEndpointsTable;
  tableIndex: number;
  switchTable: () => void;
}

const COMMON_EDITABLE_TEXT_STYLE = {
  padding: "0.8rem",
  borderRadius: "0",
  fontSize: "1.6rem",
  fontWeight: "600",
  lineHeight: "150%",
};

const COMMON_EDITABLE_TEXT_STYLE_TITLE = {
  ...COMMON_EDITABLE_TEXT_STYLE,
};

const VALUE_SHELL_PADDING = "0.2rem";

const TABLE_SHELL_PADDING = {
  padding: "0.8rem",
  borderRadius: "0",
  fontSize: "1.6rem",
  fontWeight: "600",
  lineHeight: "150%",
};

const TABLE_SHELL_PADDING_PRIMARY_ENDPOINT = {
  ...TABLE_SHELL_PADDING,
  padding: "1.4rem 0.8rem",
};

const NOTE_SHELL_PADDING = {
  padding: "1rem",
  borderRadius: "0",
  fontSize: "1.6rem",
  fontWeight: "600",
  lineHeight: "150%",
};

const headerStyle = {
  background: theme.color.neural.gray02,
  fontWeight: 600,
};

const primaryEndpointTitleStyle = {
  background:
    "linear-gradient(0deg, rgba(11, 108, 255, 0.08) 0%, rgba(11, 108, 255, 0.08) 100%), #FFF",
  fontWeight: 600,
  padding: "2.4rem 1.6rem",
};

const normalEndpointTitleStyle = {
  background:
    "linear-gradient(0deg, rgba(11, 108, 255, 0.08) 0%, rgba(11, 108, 255, 0.08) 100%), #FFF",
  fontWeight: 600,
  padding: "2.4rem 1.6rem",
};

const primaryEndpointBodyStyle = {
  background:
    "linear-gradient(0deg, rgba(11, 108, 255, 0.04) 0%, rgba(11, 108, 255, 0.04) 100%), #FFF;",
  color: "#004AEB",
  fontWeight: 600,
};

const normalEndpointBodyStyle = {};

const ClinicalEndpointsTableContent = ({
  clinicalEndpointsTable,
  tableIndex,
  switchTable,
}: Props) => {
  const groupNameList = clinicalEndpointsTable.groupNames;

  const groupNameTitleItem = {
    refKey: `[Title] TC005-Dummy-${tableIndex}`,
    value: "Endpoints /\nGroup Name",
  };

  const groupNameItemList = clinicalEndpointsTable.groupNames.map(
    ({ text, labels, key }) => ({
      value: text,
      labels,
      canTextEdit: true,
      canLabelEdit: true,
      refKey: key,
    }),
  );

  const groupValuesList = clinicalEndpointsTable.groupValues.map((row) =>
    row.map(({ text, labels, key }) => ({
      value: text,
      labels,
      canTextEdit: true,
      canLabelEdit: true,
      refKey: key,
    })),
  );

  const rawTableItems: Table["tableItems"] = [
    [groupNameTitleItem, ...groupNameItemList],
    ...groupValuesList,
  ];

  const groupNameStyleList = rawTableItems[0].map(() => headerStyle);
  const groupValuesStyleList =
    clinicalEndpointsTable.endpointClassifications.map(({ isPrimary }) => [
      isPrimary ? primaryEndpointTitleStyle : normalEndpointTitleStyle,
      ...groupNameList.map(() =>
        isPrimary ? primaryEndpointBodyStyle : normalEndpointBodyStyle,
      ),
    ]);

  const checkPrimaryEndpointList =
    clinicalEndpointsTable.endpointClassifications.map(({ isPrimary }) =>
      [isPrimary, ...groupNameList.map(() => isPrimary)].every(
        (item) => item === true,
      ),
    );

  const endpointClassificationList =
    clinicalEndpointsTable.endpointClassifications || [];

  const rawStyles: Table["styles"] = [
    [...groupNameStyleList],
    ...groupValuesStyleList,
  ];

  const groupNameTitleComponent = <></>;
  const endpointClassificationTitleComponentList =
    clinicalEndpointsTable.endpointClassifications.map(({ isPrimary }) => [
      isPrimary ? (
        <S.PrimaryEndpointTag>Primary Endpoint</S.PrimaryEndpointTag>
      ) : (
        <></>
      ),
    ]);

  const rawComponents: Table["components"] = [
    [groupNameTitleComponent],
    ...endpointClassificationTitleComponentList,
  ];

  const tableNoteItem =
    clinicalEndpointsTable.notes.text === ""
      ? []
      : [
          {
            value: clinicalEndpointsTable.notes.text,
            canLabelEdit: true,
            canTextEdit: true,
            labels: clinicalEndpointsTable.notes.labels,
            refKey: clinicalEndpointsTable.notes.key,
          },
        ];

  const [tableItems] = prunedArticleTable(
    rawTableItems,
    rawStyles,
    rawComponents,
  );

  const checkIsTitle = (targetKey: string) => {
    return targetKey.includes("[Title]");
  };

  return (
    <S.TableWrapper>
      <S.TableTitle $isDisplay={!!(clinicalEndpointsTable.title.text !== "")}>
        <EditableTextContainer
          item={{
            text: clinicalEndpointsTable.title.text,
            labels: clinicalEndpointsTable.title.labels,
          }}
          refKey={clinicalEndpointsTable.title.key}
          canTextEdit
          data-switch="false"
        />
      </S.TableTitle>

      <TableContainer>
        {tableItems.map((row, rowIndex) => {
          if (rowIndex === 0) {
            return (
              <S.RowWrapper>
                <TableRowContainer>
                  {row.map((shell) => {
                    const isTitle = checkIsTitle(shell.refKey);
                    if (isTitle) {
                      return (
                        <TableShellTitle
                          key={shell.refKey}
                          padding={VALUE_SHELL_PADDING}
                        >
                          <S.ShellTitleWrapper className="endpoint-title">
                            <SwitchButton onClick={switchTable} />
                            {shell.value}
                          </S.ShellTitleWrapper>
                        </TableShellTitle>
                      );
                    }
                    return (
                      <TableShellTitle
                        padding={VALUE_SHELL_PADDING}
                        key={shell.refKey}
                      >
                        <EditableTextContainer
                          item={{
                            text: shell.value,
                            labels: shell.labels ?? [],
                          }}
                          canTextEdit
                          canLabelEdit
                          refKey={shell.refKey}
                          style={COMMON_EDITABLE_TEXT_STYLE_TITLE}
                        />
                      </TableShellTitle>
                    );
                  })}
                </TableRowContainer>
              </S.RowWrapper>
            );
          }
          return (
            <S.RowWrapper
              id={`is-primary-row-${!!checkPrimaryEndpointList[rowIndex - 1]}`}
              $isNotTopPrimaryBorder={!!checkPrimaryEndpointList[rowIndex]}
            >
              <TableRowContainer>
                {row.map((shell, shellIndex) => {
                  if (shellIndex === 0) {
                    return (
                      <>
                        <TableShellTitle
                          key={shell.refKey}
                          padding="0.1rem"
                          className={`is-primary-title-${!!checkPrimaryEndpointList[
                            rowIndex - 1
                          ]}`}
                        >
                          <EditableTextContainer
                            item={{
                              text: shell.value,
                              labels: shell.labels ?? [],
                            }}
                            canTextEdit
                            canLabelEdit
                            refKey={shell.refKey}
                            style={
                              checkPrimaryEndpointList[rowIndex - 1]
                                ? TABLE_SHELL_PADDING_PRIMARY_ENDPOINT
                                : TABLE_SHELL_PADDING
                            }
                          />
                        </TableShellTitle>
                        {!!checkPrimaryEndpointList[rowIndex - 1] && (
                          <PrimaryEndpointTag
                            primaryEndpointText={
                              endpointClassificationList[rowIndex - 1].text
                            }
                            isSwitchTable={false}
                          />
                        )}
                      </>
                    );
                  }
                  return (
                    <TableShellValue
                      key={shell.refKey}
                      padding="0.1rem"
                      className={`is-primary-value-${!!checkPrimaryEndpointList[
                        rowIndex - 1
                      ]}`}
                    >
                      <EditableTextContainer
                        item={{
                          text: shell.value,
                          labels: shell.labels ?? [],
                        }}
                        canTextEdit
                        canLabelEdit
                        refKey={shell.refKey}
                        style={TABLE_SHELL_PADDING}
                      />
                    </TableShellValue>
                  );
                })}
              </TableRowContainer>
            </S.RowWrapper>
          );
        })}
        {tableNoteItem.length > 0 && (
          <TableRowContainer>
            <TableShellValue padding="0.2rem" fontSize="1.4rem">
              <EditableTextContainer
                item={{
                  text: tableNoteItem[0].value,
                  labels: tableNoteItem[0].labels ?? [],
                }}
                canTextEdit
                canLabelEdit
                refKey={tableNoteItem[0].refKey}
                style={NOTE_SHELL_PADDING}
              />
            </TableShellValue>
          </TableRowContainer>
        )}
      </TableContainer>
    </S.TableWrapper>
  );
};

export default ClinicalEndpointsTableContent;
