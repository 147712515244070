import { ClassificationCode, ProcessStatusCode } from "@/types/code.types";
import { useEditorRoles } from "@libs/roles/hooks/useEditorRoles";

import { ROLES_FROM_ROUTE_LIST } from "../constants/rolesFromRouteList.constants";
import { handlePressListRedirectRoles } from "../util/handlePressListRedirectRoles";

export const usePressListRedirectRoles = () => {
  const editorRoles = useEditorRoles();

  const checkPressListRedirect = (
    status: ProcessStatusCode,
    classification: ClassificationCode,
  ) => {
    const redirectRoute = editorRoles.find((role) => {
      return handlePressListRedirectRoles(role, status, classification);
    });
    if (!redirectRoute) return "";
    return ROLES_FROM_ROUTE_LIST[redirectRoute];
  };
  return { checkPressListRedirect };
};
