import React from "react";

import {
  ParagraphSectionLayout,
  ParagraphTitle,
} from "@frontend/common/src/components/ArticleContent";

import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import { useUpdateParagraph } from "@EditArticleContainer/shared/hooks/useUpdateParagraph";

import ExpeditedProgramTable from "./components/ExpeditedProgramTable/ExpeditedProgramTable";
import * as S from "./ExpeditedProgramParagraphExpeditedProgram.style";

const PARAGRAPH_TITLE = "Expedited Program";
const PARAGRAPH_CODE = "TC314";

const ExpeditedProgramParagraphExpeditedProgram = () => {
  const paragraph = useParagraphEditedData(PARAGRAPH_CODE);
  const [updateApprovalProcessTable, loadingParagraphIndex] =
    useUpdateParagraph({
      paragraphCode: PARAGRAPH_CODE,
      sectionCode: "TY004",
    });

  if (!paragraph) return null;

  const expeditedProgramData = paragraph.text["Expedited Program Contents"];

  return (
    <ParagraphSectionLayout>
      <ParagraphTitle title={PARAGRAPH_TITLE} />
      <S.ParagraphContentsWrapper>
        <S.ParagraphUpdateButton
          disabled={loadingParagraphIndex === 1}
          onClick={() => updateApprovalProcessTable(1)}
        />
        <S.TableLayout>
          <ExpeditedProgramTable tableData={expeditedProgramData} />
        </S.TableLayout>
      </S.ParagraphContentsWrapper>
    </ParagraphSectionLayout>
  );
};

export default ExpeditedProgramParagraphExpeditedProgram;
