"use client";

import styled from "styled-components";

export const Tooltip = styled.div<{ $padding?: string }>`
  display: flex;
  padding: ${({ $padding }) => $padding};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--Spacing-8, 0.8rem);

  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Primary, #fff);

  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);

  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-weight: 500;
`;
