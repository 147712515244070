import React from "react";

import * as S from "./ArticleDetailTableDescriptionTooltip.style";
import { IconInfo } from "../../../../shared/Icons";

type Props = {
  message: React.ReactNode;
};

const ArticleDetailTableDescriptionTooltip = ({ message }: Props) => {
  return (
    <S.TooltipWrapper>
      <S.InfoButton type="button">
        <IconInfo width={24} height={24} color="#2A2F37" />
      </S.InfoButton>
      <S.MessageWrapper className="message-container">
        <S.MessageContentsWrapper>{message}</S.MessageContentsWrapper>
      </S.MessageWrapper>
    </S.TooltipWrapper>
  );
};

export default ArticleDetailTableDescriptionTooltip;
