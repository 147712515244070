import React, { Dispatch, SetStateAction, useState } from "react";

import SearchInput from "@atoms/SearchInput/SearchInput";
import { usePressListFilterState } from "@stores/pressList";

import {
  EDITOR_INFO_FILTER,
  PRESS_LIST_EDITOR_INFO_FILTER_KEY,
} from "./constants/editorInfoFilter.constants";
import * as S from "./PressListEditorInfoSearch.style";
import { PressSearchFilterType } from "@PressListContainer/pressList.types";
import PressListSearchFilterSelect from "../PressListPressInfoSearch/components/PressListSearchFilterSelect/PressListSearchFilterSelect";

interface Props {
  currentEditorFilter: PressSearchFilterType;
  setCurrentFilter: Dispatch<SetStateAction<PressSearchFilterType>>;
}

const PressListEditorInfoSearch = ({
  currentEditorFilter,
  setCurrentFilter,
}: Props) => {
  const [pressListFilter, setPressListFilter] = usePressListFilterState();
  const [typing, setTyping] = useState("");

  const { search } = pressListFilter;

  const handleSearchKeyboard = () => {
    const currentInput = typing.trim();

    const newSearch = { ...search };
    PRESS_LIST_EDITOR_INFO_FILTER_KEY.forEach((key) => delete newSearch[key]);
    newSearch[currentEditorFilter.key] = currentInput;

    if (currentInput) {
      setPressListFilter({
        ...pressListFilter,
        search: newSearch,
        page: 1,
      });
    }
  };

  const handleSearchButton = () => {
    const currentInput = typing || search?.[currentEditorFilter.key];

    const newSearch = { ...search };
    PRESS_LIST_EDITOR_INFO_FILTER_KEY.forEach((key) => delete newSearch[key]);
    newSearch[currentEditorFilter.key] = currentInput;

    if (currentInput) {
      setPressListFilter({
        ...pressListFilter,
        search: newSearch,
        page: 1,
      });
    }
  };

  return (
    <S.EditorInfoSearchWrapper>
      <PressListSearchFilterSelect
        subTitle="Editor Filter"
        filterList={EDITOR_INFO_FILTER}
        currentFilter={currentEditorFilter}
        setCurrentFilter={setCurrentFilter}
      />
      <SearchInput
        placeholder="Search Editors"
        inputWidth={20.8}
        onSearchButton={handleSearchButton}
        handleSearchKeyboard={handleSearchKeyboard}
        typing={typing}
        setTyping={setTyping}
      />
    </S.EditorInfoSearchWrapper>
  );
};

export default PressListEditorInfoSearch;
