import dayjs from "dayjs";

import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import {
  REF_CODE_COMPETITIVE_LANDSCAPE_COMPANY_NAME,
  REF_CODE_COMPETITIVE_LANDSCAPE_DEVELOPMENT_STAGE,
  REF_CODE_COMPETITIVE_LANDSCAPE_DRUG_NAME,
  REF_CODE_COMPETITIVE_LANDSCAPE_MOA,
  REF_CODE_COMPETITIVE_LANDSCAPE_MODALITY,
  REF_CODE_COMPETITIVE_LANDSCAPE_ROA,
  REF_CODE_COMPETITIVE_LANDSCAPE_TARGET,
  REF_CODE_COMPETITIVE_LANDSCAPE_TARGET_INDICATION,
  REF_CODE_SEPARATOR,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createRefCode } from "@EditArticleContainer/shared/libs/refCode/utils/createRefCode";
import { CompetitiveLandscapeContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/competitiveLandscapeContent.types";
import { createContentFromHTML } from "@EditArticleContainer/shared/utils/getValueFromRefMap";

export const useCreateCompetitiveLandscapeContent = () => {
  const refMap = useArticleContentRefMap();

  return () => {
    const indexList = [...refMap.keys()]
      .filter(
        (key) =>
          key.includes(REF_CODE_COMPETITIVE_LANDSCAPE_COMPANY_NAME) &&
          refMap.get(key)?.current,
      )
      .map((key) => key.split(REF_CODE_SEPARATOR)[1]);

    const content: CompetitiveLandscapeContent = {
      "Competitive Landscape": indexList.map((index) => ({
        type: "competitive", // 임시 값
        text: {
          "Drug Name": createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_COMPETITIVE_LANDSCAPE_DRUG_NAME, index),
          ),
          "Company Name": createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_COMPETITIVE_LANDSCAPE_COMPANY_NAME, index),
          ),
          ROA: createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_COMPETITIVE_LANDSCAPE_ROA, index),
          ),
          Modality: createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_COMPETITIVE_LANDSCAPE_MODALITY, index),
          ),
          Target: createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_COMPETITIVE_LANDSCAPE_TARGET, index),
          ),
          MOA: createContentFromHTML(
            refMap,
            createRefCode(REF_CODE_COMPETITIVE_LANDSCAPE_MOA, index),
          ),
          "Target Indication": createContentFromHTML(
            refMap,
            createRefCode(
              REF_CODE_COMPETITIVE_LANDSCAPE_TARGET_INDICATION,
              index,
            ),
          ),
          "Development Stage": createContentFromHTML(
            refMap,
            createRefCode(
              REF_CODE_COMPETITIVE_LANDSCAPE_DEVELOPMENT_STAGE,
              index,
            ),
          ),
        },
        label: [],
        createDt: dayjs().toISOString(),
      })),
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
