import { useModalityCategoryValue } from "@FillInTableContainer/shared/context/ModalityCategoryContext";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  ValidatedInfoList,
  useValidatedInTableCreator,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

interface UseCheckValidationInTableCreatorReturn {
  handleCheckValidationFull: () => boolean;
}

export const useCheckValidationInRegulatoryTableCreator =
  (): UseCheckValidationInTableCreatorReturn => {
    const entryData = useEntryDataValue(getPressIdFromURL());
    const { changeValidatedInfo } = useValidatedInTableCreator();
    const { changeInfo } = useTableCreatorPositionInfo();
    const { changeFocusStatus } = useTableCreatorFocusStatus();

    const checkDrugProfileTable = () => {
      const drugProfileTableList = entryData?.Regulatory?.["Drug Profile"];

      const valueInfo = drugProfileTableList?.flatMap(
        (tableInfo, tableInfoIndex) => {
          const checkDrugName =
            tableInfo?.["Brand Name"].value.length === 0 &&
            tableInfo?.["INN Name"].value.length === 0 &&
            tableInfo?.["Code Name"].value.length === 0;

          if (!checkDrugName) return null;
          return [
            {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex: tableInfoIndex,
              groupShellKey: "Drug Name",
              groupShellIndex: 0,
              shellKey: "Brand Name",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            },
            {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex: tableInfoIndex,
              groupShellKey: "Drug Name",
              groupShellIndex: 0,
              shellKey: "INN Name",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            },
            {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex: tableInfoIndex,
              groupShellKey: "Drug Name",
              groupShellIndex: 0,
              shellKey: "Code Name",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            },
          ];
        },
      );

      const sortedValidatedInfo =
        valueInfo?.filter((info) => info !== null) || [];

      return sortedValidatedInfo;
    };

    const { updateModalityCategory } = useModalityCategoryValue();

    const checkModalityCategory = () => {
      const drugProfileTableList = entryData?.Regulatory?.["Drug Profile"];
      const modalityValues = drugProfileTableList?.map((table) =>
        table.Modality.value?.map((shell) => shell.text),
      );

      const valueInfo = modalityValues?.flatMap((table, tableIndex) =>
        table.map((shell) => {
          if (updateModalityCategory[shell] === "") {
            return {
              groupTableKey: "Drug Profile",
              groupTableIndex: 0,
              tableKey: "Drug",
              tableIndex,
              groupShellKey: null,
              groupShellIndex: 0,
              shellKey: "Modality",
              shellIndex: 0,
              valueIndex: null,
              type: "alert",
              container: "shell",
            };
          }
          return null;
        }),
      );
      return valueInfo?.filter((item) => item !== null);
    };

    const checkApprovalProcessTable = () => {
      const approvalProcessData =
        entryData.Regulatory?.["Approval Process Contents"];

      const sortedValidatedInfo = () => {
        const validatedInfoTemp: ValidatedInfoList = [];

        approvalProcessData?.["Approval Process"]?.forEach(
          (groupShellInfo, groupShellInfoIndex) => {
            const isValidatedAuthorizedCompany =
              groupShellInfo["Authorized Company"].value.length === 0;

            const isValidatedRegulatoryAgency =
              groupShellInfo["Regulatory Agency"].value.length === 0;

            const isValidatedApplicationType =
              groupShellInfo["Application Type"].value.length === 0;

            const isValidatedMarketingStatus =
              groupShellInfo["Marketing Status"].value.length === 0;

            if (
              isValidatedApplicationType ||
              isValidatedAuthorizedCompany ||
              isValidatedMarketingStatus ||
              isValidatedRegulatoryAgency
            ) {
              if (isValidatedMarketingStatus)
                validatedInfoTemp.push({
                  groupTableKey: "Approval Process Contents",
                  groupTableIndex: 1,
                  tableKey: "Approval Process",
                  tableIndex: 0,
                  groupShellKey: "Approval Process",
                  groupShellIndex: groupShellInfoIndex,
                  shellKey: "Marketing Status",
                  shellIndex: 0,
                  valueIndex: null,
                  type: "alert",
                  container: "shell",
                });

              if (isValidatedRegulatoryAgency)
                validatedInfoTemp.push({
                  groupTableKey: "Approval Process Contents",
                  groupTableIndex: 1,
                  tableKey: "Approval Process",
                  tableIndex: 0,
                  groupShellKey: "Approval Process",
                  groupShellIndex: groupShellInfoIndex,
                  shellKey: "Regulatory Agency",
                  shellIndex: 0,
                  valueIndex: null,
                  type: "alert",
                  container: "shell",
                });

              if (isValidatedApplicationType)
                validatedInfoTemp.push({
                  groupTableKey: "Approval Process Contents",
                  groupTableIndex: 1,
                  tableKey: "Approval Process",
                  tableIndex: 0,
                  groupShellKey: "Approval Process",
                  groupShellIndex: groupShellInfoIndex,
                  shellKey: "Application Type",
                  shellIndex: 0,
                  valueIndex: null,
                  type: "alert",
                  container: "shell",
                });

              if (isValidatedAuthorizedCompany)
                validatedInfoTemp.push({
                  groupTableKey: "Approval Process Contents",
                  groupTableIndex: 1,
                  tableKey: "Approval Process",
                  tableIndex: 0,
                  groupShellKey: "Approval Process",
                  groupShellIndex: groupShellInfoIndex,
                  shellKey: "Authorized Company",
                  shellIndex: 0,
                  valueIndex: null,
                  type: "alert",
                  container: "shell",
                });
            }
          },
        );

        return validatedInfoTemp;
      };

      return sortedValidatedInfo();
    };
    const handleCheckValidationFull = () => {
      if (
        checkDrugProfileTable().length === 0 &&
        checkModalityCategory()?.length === 0 &&
        checkApprovalProcessTable().length === 0
      ) {
        changeInfo(DEFAULT_POSITION_INFO);
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
        return true;
      }

      const validatedList = [
        ...(checkDrugProfileTable() || []),
        ...(checkModalityCategory() || []),
        ...(checkApprovalProcessTable() || []),
      ];

      changeValidatedInfo(validatedList as ValidatedInfoList);
      return false;
    };

    return {
      handleCheckValidationFull,
    };
  };
