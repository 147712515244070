import { ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE } from "@frontend/common";
import {
  DrugProfileShellKeysNotIncludeAttributionId,
  EntryDataValueInfo,
} from "@frontend/common/src/types/entryData/clinicalTrialStatus.types";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import { DrugProfileShellKeysNotIncludeAttributionId as DealsDrugProfileKeys } from "@/types/deals.types";
import { ArticleSectionType } from "@FillInTableContainer/types/fillInTable.types";

import { moveItemToPosition } from "./changeEntryDataOrder";
import { DragInfoType } from "../hooks/useHandleTableCreatorValueDrag";

type Props = {
  data: ArticleEntryData;
  draggedIndexInfo: DragInfoType;
  articleSectionKey: ArticleSectionType;
  shellKey: DealsDrugProfileKeys | DrugProfileShellKeysNotIncludeAttributionId;
  tableIndex?: number;
};

export const dragChangeDrugProfileValueOrder = ({
  data,
  draggedIndexInfo,
  articleSectionKey,
  shellKey,
  tableIndex,
}: Props) => {
  if (
    articleSectionKey === "Deals" ||
    articleSectionKey === "Expedited Program" ||
    articleSectionKey === "Venture Capital"
  )
    return {
      ...data,
      [articleSectionKey]: {
        ...data[articleSectionKey],
        [DRUG_PROFILE]: data[articleSectionKey]?.[DRUG_PROFILE]?.map(
          (tableData, currentTableIndex) => {
            if (currentTableIndex === tableIndex) {
              return {
                ...tableData,
                [shellKey]: {
                  ...tableData[shellKey],
                  value: moveItemToPosition(
                    tableData[shellKey].value as EntryDataValueInfo[],
                    draggedIndexInfo[0] || 0,
                    draggedIndexInfo[1] || 0,
                  ),
                },
              };
            }

            return tableData;
          },
        ),
      },
    };

  return {
    ...data,
    [articleSectionKey]: {
      ...data[articleSectionKey],
      [DRUG_PROFILE]: data[articleSectionKey]?.[DRUG_PROFILE]?.map(
        (tableData, currentTableIndex) => {
          if (currentTableIndex === tableIndex) {
            return {
              ...tableData,
              [shellKey]: {
                ...tableData[
                  shellKey as DrugProfileShellKeysNotIncludeAttributionId
                ],
                value: moveItemToPosition(
                  tableData[
                    shellKey as DrugProfileShellKeysNotIncludeAttributionId
                  ].value as EntryDataValueInfo[],
                  draggedIndexInfo[0] || 0,
                  draggedIndexInfo[1] || 0,
                ),
              },
            };
          }

          return tableData;
        },
      ),
    },
  };
};
