import React, { useEffect } from "react";

import { ExpeditedProgramSection } from "@/types/expeditedProgram.types";
import { useScroll } from "@FillInTableContainer/components/FillInTable/hooks/useScroll";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import {
  DefaultShellTitle,
  TableCreatorTextFieldContainer,
} from "@FillInTableContainer/shared";
import useCheckPressedShell from "@FillInTableContainer/shared/hooks/useCheckPressedShell";
import { useGetInfoOfShell } from "@FillInTableContainer/shared/hooks/useGetInfoOfShell";
import {
  isRequiredShell,
  getShellPlaceholder,
} from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getShellTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { padStart } from "@utils/padStart/padStart";

import * as S from "./ExpeditedProgramDrugProfileShell.style";
import { useExpeditedProgramShellCreator } from "./hooks/useExpeditedProgramShellCreator";

export interface PositionInfoProps {
  groupTableKey: ExpeditedProgramSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  groupShellIndex: number;
  tableKey: string | null;
  tableIndex: number;
  shellKey: string;
  shellIndex: number;
  shellCount: number;
}

interface Props extends PositionInfoProps {
  shellCount: number;
  isShellOverOne: boolean;
  isTitleRowShell?: boolean;
  isClearGroupShell: boolean;
  isDeleteGroupShell: boolean;
  isAlertGroupShell: boolean;
}

const ExpeditedProgramDrugProfileShell = ({
  groupTableKey,
  groupTableIndex,
  groupShellKey,
  groupShellIndex,
  tableKey,
  shellKey,
  tableIndex,
  shellIndex,
  shellCount,
  isShellOverOne,
  isTitleRowShell = false,
  isClearGroupShell,
  isDeleteGroupShell,
  isAlertGroupShell,
}: Props) => {
  const positionPropsInfo: PositionInfoProps = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
    shellKey,
    shellIndex,
    shellCount,
  };

  const {
    isFocused,
    isClearShell,
    isDeleteShell,
    isEnableEdit,
    isAlertShell,
    focusStatus,
    columnSize,
    isDeleteTable,
    isClearTable,
    handleFocusTextFieldWrapper,
    handleClickToDeleteShell,
    handleFocusOutShell,
  } = useExpeditedProgramShellCreator(positionPropsInfo);

  const { isPressed, handleChangePressedStatus } = useCheckPressedShell();

  const articleSection = useArticleSection();
  const { shellValue } = useGetInfoOfShell();
  const { register } = useScroll();

  useEffect(() => {
    const handleCheckClickOutsideShell = () => {
      if (isFocused) handleFocusOutShell();
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleFocusOutShell, isFocused]);

  if (shellKey === "Modality") {
    const modalityShellTitleColumnClassName = `is-modality-shell-value-empty-${
      shellValue(shellKey, tableIndex, groupShellIndex, shellIndex).value
        .length === 0
    }`;
    return (
      <S.ShellWrapper
        column={columnSize}
        {...register(
          `${groupTableKey}__${groupTableIndex}__${tableKey}__${tableIndex}__${groupShellKey}__${groupShellIndex}__${shellKey}__${shellIndex}`,
        )}
      >
        {(isClearShell || isDeleteShell) && isEnableEdit && (
          <S.DeleteTableButtonWrapper>
            <S.DeleteTableButton
              type="button"
              onClick={handleClickToDeleteShell}
            >
              <img src={IconXMark} alt="button for clear" />
              {focusStatus.type === "clear" ? "Clear" : null}
              {focusStatus.type === "delete" ? "Delete" : null}
            </S.DeleteTableButton>
          </S.DeleteTableButtonWrapper>
        )}
        <S.ShellBorderWrapper
          isFocused={isFocused && focusStatus.type !== "dragging"}
          $isDragging={isFocused && focusStatus.type === "dragging"}
          isClearStatus={isClearShell || isClearGroupShell || isClearTable}
          isDeleteStatus={isDeleteShell || isDeleteGroupShell || isDeleteTable}
          isAlertStatus={isAlertShell || isAlertGroupShell}
          isDisableEdit={!isEnableEdit}
          isSeparatedShellTitle={false}
          isTitleRowShell={isTitleRowShell}
          $isPressed={isPressed}
          onClick={handleFocusTextFieldWrapper}
          onMouseDown={(e) => handleChangePressedStatus(e, true)}
          onMouseUp={(e) => handleChangePressedStatus(e, false)}
        >
          <S.ModalityShellRow>
            <S.ModalityShellColumn
              className={modalityShellTitleColumnClassName}
              $isMultipleValue={
                shellValue(shellKey, tableIndex, groupShellIndex, shellIndex)
                  .value.length > 1
              }
            >
              <DefaultShellTitle
                isFocused={isFocused}
                isClearShell={isClearShell}
                isDeleteShell={isDeleteShell}
                isAlertShell={isAlertShell}
                isRequiredShell={isRequiredShell(articleSection, shellKey)}
                shellTitle={getShellTitle(articleSection, shellKey)}
                shellTitleNumbering=""
                isSeparatedShellTitle={false}
                columnSize={columnSize}
              />
            </S.ModalityShellColumn>
            <S.ModalityShellColumn>
              <DefaultShellTitle
                isFocused={isFocused}
                isClearShell={isClearShell}
                isDeleteShell={isDeleteShell}
                isAlertShell={isAlertShell}
                isRequiredShell={isRequiredShell(articleSection, shellKey)}
                shellTitle="Modality Category"
                shellTitleNumbering=""
                isSeparatedShellTitle={false}
                columnSize={columnSize}
              />
            </S.ModalityShellColumn>
          </S.ModalityShellRow>

          <>
            <TableCreatorTextFieldContainer
              placeholder={
                getShellPlaceholder(articleSection, shellKey) ||
                getShellTitle(articleSection, shellKey)
              }
              shellValue={shellValue(
                shellKey,
                tableIndex,
                groupShellIndex,
                shellIndex,
              )}
              shellKey={shellKey}
              shellIndex={shellIndex}
              groupTableKey={groupTableKey}
              groupTableIndex={groupTableIndex}
              groupShellKey={groupShellKey}
              groupShellIndex={groupShellIndex}
              tableKey={tableKey}
              tableIndex={tableIndex}
            />
          </>
        </S.ShellBorderWrapper>
      </S.ShellWrapper>
    );
  }

  return (
    <S.ShellWrapper
      column={columnSize}
      {...register(
        `${groupTableKey}__${groupTableIndex}__${tableKey}__${tableIndex}__${groupShellKey}__${groupShellIndex}__${shellKey}__${shellIndex}`,
      )}
    >
      {(isClearShell || isDeleteShell) && isEnableEdit && (
        <S.DeleteTableButtonWrapper>
          <S.DeleteTableButton type="button" onClick={handleClickToDeleteShell}>
            <img src={IconXMark} alt="button for clear" />
            {focusStatus.type === "clear" ? "Clear" : null}
            {focusStatus.type === "delete" ? "Delete" : null}
          </S.DeleteTableButton>
        </S.DeleteTableButtonWrapper>
      )}
      <S.ShellBorderWrapper
        isFocused={isFocused && focusStatus.type !== "dragging"}
        $isDragging={isFocused && focusStatus.type === "dragging"}
        isClearStatus={isClearShell || isClearGroupShell || isClearTable}
        isDeleteStatus={isDeleteShell || isDeleteGroupShell || isDeleteTable}
        isAlertStatus={isAlertShell || isAlertGroupShell}
        isDisableEdit={!isEnableEdit}
        isTitleRowShell={isTitleRowShell}
        $isPressed={isPressed}
        onClick={handleFocusTextFieldWrapper}
        onMouseDown={(e) => handleChangePressedStatus(e, true)}
        onMouseUp={(e) => handleChangePressedStatus(e, false)}
      >
        <DefaultShellTitle
          isFocused={isFocused}
          isClearShell={isClearShell}
          isDeleteShell={isDeleteShell}
          isAlertShell={isAlertShell}
          isRequiredShell={isRequiredShell(articleSection, shellKey)}
          shellTitle={getShellTitle(articleSection, shellKey)}
          shellTitleNumbering={
            isShellOverOne ? `${padStart(shellIndex + 1)}` : ""
          }
          columnSize={columnSize}
        />

        {!isTitleRowShell && (
          <TableCreatorTextFieldContainer
            placeholder={
              getShellPlaceholder(articleSection, shellKey) ||
              getShellTitle(articleSection, shellKey)
            }
            shellValue={shellValue(
              shellKey,
              tableIndex,
              groupShellIndex,
              shellIndex,
            )}
            shellKey={shellKey}
            shellIndex={shellIndex}
            groupTableKey={groupTableKey}
            groupTableIndex={groupTableIndex}
            groupShellKey={groupShellKey}
            groupShellIndex={groupShellIndex}
            tableKey={tableKey}
            tableIndex={tableIndex}
          />
        )}
      </S.ShellBorderWrapper>
    </S.ShellWrapper>
  );
};

export default ExpeditedProgramDrugProfileShell;
