const ROUTE_PATHS = {
  signIn: "/",
  pressList: "/press-list",
  pressClassification: "/press-classification",
  fillInTable: "/fill-in-table",
  editArticle: "/edit-article",
  insertImage: "/insert-image",
  redirect: "/redirect",
} as const;

export { ROUTE_PATHS };
