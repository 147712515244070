import React from "react";

import { ClassificationList } from "@PressListContainer/classificationInfo.constants";

export const changeCheckedAllList = (
  event: React.ChangeEvent<HTMLInputElement>,
  list: ClassificationList[],
) => {
  const changeList = list.map((classification) => ({
    code: classification.code,
    name: classification.name,
    isCheck: event.currentTarget.checked,
  }));
  return changeList;
};
