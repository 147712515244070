"use client";

import styled, { css } from "styled-components";

export const TableShellWrapper = styled.div<{
  $isTopLeftRadius?: boolean;
  $isTopRightRadius?: boolean;
  $isBottomLeftRadius?: boolean;
  $isBottomRightRadius?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${({ $isTopLeftRadius }) =>
    $isTopLeftRadius &&
    css`
      border-top-left-radius: 1.2rem;
      overflow: hidden;
    `}

  ${({ $isTopRightRadius }) =>
    $isTopRightRadius &&
    css`
      border-top-right-radius: 1.2rem;
      overflow: hidden;
    `}

  ${({ $isBottomLeftRadius }) =>
    $isBottomLeftRadius &&
    css`
      border-bottom-left-radius: 1.2rem;
      overflow: hidden;
    `}

  ${({ $isBottomRightRadius }) =>
    $isBottomRightRadius &&
    css`
      border-bottom-right-radius: 1.2rem;
      overflow: hidden;
    `}
`;

export const TableShellTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 5rem;

  line-height: 160%;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.neural.gray10};
  background-color: ${({ theme }) => theme.color.neural.gray02};
  padding: 0rem 1rem;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    font-size: 1.2rem;
    padding: 0rem 0.8rem;
  }
`;

export const TableShellValue = styled.div<{
  $isDrugProfile?: boolean;
  $isTextBreakAll?: boolean;
  $isTextBreakWord?: boolean;
}>`
  display: flex;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  height: 100%;

  line-height: 160%;
  font-size: 1.4rem;
  font-weight: 500;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.color.neural.gray10};
  /**
    * TODO: Competitive Landscape 활성화 되면 주석 해제 필요
    * background: ${({ theme, $isDrugProfile }) =>
    $isDrugProfile
      ? "linear-gradient(0deg, rgba(11, 108, 255, 0.04) 0%, rgba(11, 108, 255, 0.04) 100%), rgb(255, 255, 255)"
      : theme.color.neural.white}; */
  background: ${({ theme }) => theme.color.neural.white};
  padding: 1.2rem 1rem;

  ${({ $isTextBreakAll }) =>
    $isTextBreakAll &&
    css`
      word-break: break-all;
    `}

  ${({ $isTextBreakWord }) =>
    $isTextBreakWord &&
    css`
      overflow-wrap: break-word;
    `}

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    font-size: 1.2rem;
    padding: 1.2rem 0.8rem;
  }
`;

export const ValueTextWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
