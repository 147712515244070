"use client";

import styled, { css } from "styled-components";

export const DBDetailTitleShell = styled.div<{ $width?: string }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 145%;
  font-family: var(--font-pt-serif);
  background: #f0f2f4;
  padding: 0.8rem;
  border-right: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
      max-width: ${$width};
    `};
`;
