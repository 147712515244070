import React from "react";

interface Props {
  width?: number;
  height?: number;
}

const DefaultInvestorPerson = ({ width = 40, height = 40 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.1">
        <path
          d="M33.3337 34.5278V31.1058C33.3337 29.2906 32.6313 27.5498 31.381 26.2663C30.1308 24.9828 28.4351 24.2617 26.667 24.2617H13.3337C11.5655 24.2617 9.86986 24.9828 8.61961 26.2663C7.36937 27.5498 6.66699 29.2906 6.66699 31.1058V34.5278"
          fill="#0198E9"
        />
        <path
          d="M20.0005 19.4087C23.8528 19.4087 26.9757 16.2829 26.9757 12.427C26.9757 8.57113 23.8528 5.44531 20.0005 5.44531C16.1483 5.44531 13.0254 8.57113 13.0254 12.427C13.0254 16.2829 16.1483 19.4087 20.0005 19.4087Z"
          fill="#0198E9"
        />
      </g>
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7529 12.4281C13.7529 8.97351 16.5506 6.17414 20.0004 6.17414C23.4501 6.17414 26.2478 8.97351 26.2478 12.4281C26.2478 15.8828 23.4501 18.6822 20.0004 18.6822C16.5506 18.6822 13.7529 15.8828 13.7529 12.4281ZM20.0004 4.71875C15.7456 4.71875 12.2975 8.17101 12.2975 12.4281C12.2975 16.6853 15.7456 20.1375 20.0004 20.1375C24.2552 20.1375 27.7032 16.6853 27.7032 12.4281C27.7032 8.17101 24.2552 4.71875 20.0004 4.71875ZM13.3336 23.515C11.3604 23.515 9.47217 24.3199 8.08306 25.7459C6.69461 27.1713 5.91797 29.1005 5.91797 31.108V34.53C5.91797 34.9437 6.25331 35.279 6.66698 35.279C7.08065 35.279 7.41599 34.9437 7.41599 34.53V31.108C7.41599 29.4853 8.04411 27.9328 9.15614 26.7912C10.2675 25.6502 11.7706 25.013 13.3336 25.013H26.667C28.23 25.013 29.7331 25.6502 30.8445 26.7912C31.9565 27.9328 32.5846 29.4853 32.5846 31.108V34.53C32.5846 34.9437 32.92 35.279 33.3336 35.279C33.7473 35.279 34.0827 34.9437 34.0827 34.53V31.108C34.0827 29.1005 33.306 27.1713 31.9176 25.7459C30.5285 24.3199 28.6402 23.515 26.667 23.515H13.3336Z"
        fill="#0198E9"
      />
    </svg>
  );
};

DefaultInvestorPerson.displayName = "DefaultInvestorPerson";

export default DefaultInvestorPerson;
