import dayjs from "dayjs";

import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import { REF_CODE_TOPIC } from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";

import { convertHtmlToContent } from "../../../components/EditableTextContainer/utils/htmlConvert";
import { TopicContent } from "../../../types/paragraphContents/contents/topicContent.types";

export const useCreateTopicContent = () => {
  const refMap = useArticleContentRefMap();

  return () => {
    const topic = convertHtmlToContent(
      refMap.get(REF_CODE_TOPIC)?.current?.innerHTML ?? "",
    );

    const content: TopicContent = {
      ...topic,
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
