"use client";

import styled, { css } from "styled-components";

export const ScrollBox = styled.div<{
  $isScrollX?: boolean;
  $maxWidth?: string;
}>`
  max-width: ${({ $maxWidth }) => $maxWidth ?? "100%"};
  width: 100%;
  height: 100%;
  padding-bottom: 0.8rem;

  ${({ $isScrollX }) =>
    $isScrollX &&
    css`
      overflow-x: auto;

      &::-webkit-scrollbar {
        height: 0.8rem;
        background: var(--Color-Common-Bg-Secondary, #f0f2f4);
      }

      &::-webkit-scrollbar-track {
        background: var(--Color-Common-Bg-Secondary, #f0f2f4);

        border-radius: 1rem;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: var(--Border-Radius-circle, 99999.9rem);
        background: var(--Color-Common-Bg-Tertiary, #cbd0d8);
        cursor: pointer;

        &:hover {
          background: var(
            --Color-Common-Icon-Interactive-Primary-Hovered,
            #017abc
          );
          box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
            0px 2px 4px 0px rgba(0, 0, 0, 0.16);
        }
        &:active {
          background: var(
            --Color-Common-Icon-Interactive-Primary-Pressed,
            #015d8e
          );

          box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
            0px 1px 2px 0px rgba(0, 0, 0, 0.16);
        }
      }
    `}
`;

export const ShadowBox = styled.div<{
  $scrollLeft?: number;
  $maxScrollLeft?: number;
}>`
  position: relative;
  width: 100%;

  ${({ $scrollLeft, $maxScrollLeft }) =>
    $scrollLeft !== undefined &&
    $maxScrollLeft !== undefined &&
    $scrollLeft > 3 &&
    css`
      &::before {
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        width: 4rem;
        height: calc(100% - 1.2rem);
        content: "";
        z-index: 10;
        background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(0, 0, 0, 0.2) 100%
        );
      }
    `}

  ${({ $scrollLeft, $maxScrollLeft }) =>
    $scrollLeft !== undefined &&
    $maxScrollLeft !== undefined &&
    $scrollLeft >= 0 &&
    $scrollLeft + 3 < $maxScrollLeft &&
    css`
      &::after {
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        right: 0;
        width: 4rem;
        height: calc(100% - 1.2rem);
        content: "";
        z-index: 10;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(0, 0, 0, 0.2) 100%
        );
      }
    `}
`;
