import React from "react";

import * as S from "./ParagraphTitle.style";

interface Props {
  title: React.ReactNode;
}

const ParagraphTitle = ({ title }: Props) => {
  return <S.ParagraphTitleWrapper>{title}</S.ParagraphTitleWrapper>;
};

export default ParagraphTitle;
