import styled from "styled-components";

export const ClassificationWrapper = styled.div`
  position: absolute;
  top: 12rem;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: calc(100% - 8rem);
  min-width: 88rem;
  max-width: 104rem;
  transform: translate(-50%, 0%);
  z-index: 30;
`;

export const ClassificationContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.2rem 1.6rem 1.2rem 1.6rem;
  background-color: white;
  border-radius: 0.4rem;
  box-shadow: 0 0.4rem 1.2rem 0 rgba(7, 68, 161, 0.04);
`;

export const Divider = styled.div`
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.neural.gray02};
  margin: 1.2rem 0;
`;

export const ClassificationTitle = styled.h3`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 100%;
  color: var(--Color-Common-Text-Primary, #2a2f37);
`;

export const ClassificationManualFieldset = styled.fieldset`
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
`;

export const ClassificationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ClassificationCloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ClassificationXMark = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
`;

export const ClassificationCloseText = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 100%;
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  min-width: 24.7rem;
  height: 3.2rem;
  background: var(--Color-Common-Bg-Interactive-Primary, #1baefe);
  border-radius: 0.4rem;
  padding: 0.8rem 1.6rem 0.8rem 0.8rem;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    background: var(--Color-Common-Bg-Interactive-Primary-Hovered, #0198e9);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  }

  &:active {
    background: var(--Color-Common-Bg-Interactive-Primary-Pressed, #017abc);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset,
      0px 0px 2px 0px rgba(0, 0, 0, 0.06) inset;
  }

  &:disabled {
    background: var(--Color-Common-Bg-Disabled, #cbd0d8);
    box-shadow: none;
    cursor: not-allowed;

    svg path {
      fill: #96a1b0;
    }

    span {
      color: var(--Color-Common-Text-Disabled, #96a1b0);
    }
  }
`;

export const ConfirmButtonText = styled.span`
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 100%;
`;
