import { ClinicalResultsContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/clinicalResultsContent.types";
import { parseJSON } from "@utils/parseJSON";

import { ClinicalEndpointsDescription } from "./clinicalEndpointsDescription";
import { ClinicalEndpointsTable } from "./clinicalEndpointsTable";

export class ClinicalResults {
  public static fromString(data: string) {
    return new ClinicalResults(parseJSON<ClinicalResultsContent>(data));
  }

  private static PARAGRAPH_TITLE = "Clinical Results";

  private clinicalResultsContentData: ClinicalResultsContent;

  constructor(clinicalResultsContentData: ClinicalResultsContent) {
    this.clinicalResultsContentData = clinicalResultsContentData;
  }

  public get data() {
    return this.clinicalResultsContentData;
  }

  public static get title() {
    return ClinicalResults.PARAGRAPH_TITLE;
  }

  public get clinicalEndpointsTableData() {
    return this.clinicalResultsContentData.text["Clinical Endpoints (Table)"];
  }

  public get clinicalEndpointsDescriptionData() {
    return this.clinicalResultsContentData.text[
      "Clinical Endpoints (Description)"
    ];
  }

  public get tables() {
    return this.clinicalEndpointsTableData.map((item, index) => ({
      key: `${this.data.updateDt}-${index}`,
      data: new ClinicalEndpointsTable(item, index),
    }));
  }

  public get description() {
    return new ClinicalEndpointsDescription(
      this.clinicalEndpointsDescriptionData,
    );
  }

  public get descriptionData() {
    return this.description.data;
  }

  public get descriptionKey() {
    return `${this.data.updateDt}-description`;
  }
}
