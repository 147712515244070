import styled, { css } from "styled-components";

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.2rem;

  button {
    display: flex;
    justify-content: center;
    height: 100%;
    cursor: pointer;
  }

  path {
    fill: #515c6c;
  }
`;

export const NumberWrapper = styled.span`
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
`;

export const PageNumber = styled.strong<{ isTarget?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.4rem;
  height: 2.4rem;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 1.2rem;
  margin-right: 0.2rem;
  padding: 0 0.8rem;
  cursor: pointer;

  ${({ isTarget }) =>
    isTarget
      ? css`
          color: var(--Color-Common-Text-Interactive-Selected, #0198e9);
          background: var(--Color-Common-Bg-Success-Subtle, #e6f6ff);
        `
      : css`
          color: var(--Color-Common-Text-Primary, #2a2f37);
        `};
`;

export const MoveButton = styled.button`
  cursor: pointer;

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
`;
