import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;

  & > .separator {
    height: 1.6rem;
    border-right: 0.1rem solid ${({ theme }) => theme.color.neural.gray03};
  }
`;
