import {
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_TABLE_KEY as ADDITIONAL_INFORMATION,
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_SHELL_KEYS as ADDITIONAL_INFORMATION_GROUP_SHELL_KEYS,
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_TABLE_KEYS as ADDITIONAL_INFORMATION_TABLE_KEYS,
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_KEYS as ADDITIONAL_INFORMATION_SHELL_KEYS,
} from "@frontend/common";

import { VentureCapitalGroupTable } from "@/types/ventureCapital.types";
import { TableCreatorTableList } from "@FillInTableContainer/shared/types/tableCreator.types";

interface VentureCapitalAdditionalInformationLayout {
  groupTableKey: VentureCapitalGroupTable;
  tableList: TableCreatorTableList;
}

export const VENTURE_CAPITAL_ADDITIONAL_INFORMATION_GROUP_TABLE_LAYOUT: VentureCapitalAdditionalInformationLayout =
  {
    groupTableKey: ADDITIONAL_INFORMATION,
    tableList: [
      {
        tableKey:
          ADDITIONAL_INFORMATION_TABLE_KEYS.additionalInformationVentureCapital,
        groupShellList: [
          {
            groupShellKey: ADDITIONAL_INFORMATION_GROUP_SHELL_KEYS.none,
            shellList: [
              {
                shellKey:
                  ADDITIONAL_INFORMATION_SHELL_KEYS.additionalInformation,
              },
            ],
          },
        ],
      },
    ],
  };
