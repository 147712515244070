"use client";

import styled from "styled-components";

export const TableTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.4rem;
  width: 100%;
  height: 4.8rem;

  font-family: var(--font-pt-serif);
  color: var(--Color-Common-Text-Success-Bold, #015d8e);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
`;
