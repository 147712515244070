import FillInTableProvider from "@/components/business/FillInTableContainer/components/FillInTableProvider/FillInTableProvider";
import { useSafeLeavePage } from "@hooks/useSafeLeavePage";
import FillInTableConcurrentAccessGuard from "@libs/concurrentAccess/components/FillInTableConcurrentAccessGuard";
import FillInTableRoleGuard from "@libs/roles/components/FillInTableRoleGuard";

import FillInTable from "./components/FillInTable/FillInTable";

const FillInTableContainer = () => {
  useSafeLeavePage();

  return (
    <FillInTableRoleGuard>
      <FillInTableConcurrentAccessGuard>
        <FillInTableProvider>
          <FillInTable />
        </FillInTableProvider>
      </FillInTableConcurrentAccessGuard>
    </FillInTableRoleGuard>
  );
};

export default FillInTableContainer;
