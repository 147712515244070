import React, { Suspense } from "react";

import ComponentErrorBoundary from "@/components/shared/business/ComponentErrorBoundary/ComponentErrorBoundary";
import { ArticleSection } from "@/types/articleEntryData.types";

import { useCheckInitialEntryDataFormat } from "../../hooks/useCheckInitialEntryDataFormat";
import { useEntryFillInTablePageEffect } from "../../hooks/useEntryFillInTablePageEffect";
import { useLeaveFillInTablePageEffect } from "../../hooks/useLeaveFillInTablePageEffect";
import TableCreatorContainer from "../TableCreator/TableCreatorContainer";

type Props = {
  articleSection: ArticleSection;
};

const EntryTableCreatorContainer = ({ articleSection }: Props) => {
  useCheckInitialEntryDataFormat(); // TODO: 데이터를 검사할 수 있는 page와 components 사이의 컴포넌트가 필요함.
  useEntryFillInTablePageEffect();
  useLeaveFillInTablePageEffect();
  return (
    <ComponentErrorBoundary>
      <Suspense>
        <TableCreatorContainer articleSection={articleSection} />
      </Suspense>
    </ComponentErrorBoundary>
  );
};

export default EntryTableCreatorContainer;
