import {
  ArticleContentMainBanner,
  SECTION_NAME_FOR_DISPLAY_ARTICLE_SECTION,
} from "@frontend/common";

import ArticleMainLabelReadableContainer from "@/components/shared/business/components/ArticleMainLabelContainer/ArticleMainLabelReadableContainer";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { useCreateCompanyLogoComponentList } from "@EditArticleContainer/shared/hooks/useCreateCompanyLogoComponentList";
import { useParagraph } from "@EditArticleContainer/shared/hooks/useParagraph";
import { useRegenerate } from "@EditArticleContainer/shared/hooks/useRegenerate";
import { REF_CODE_TITLE } from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { formattedArticleDate } from "@EditArticleContainer/shared/utils/formattedArticleDate";
import { useSectionCodeToClassification } from "@hooks/useSectionCode";

import * as S from "./ArticleHeader.style";

const TITLE_PARAGRAPH_CODE = {
  TY001: "TC001",
  TY002: "TC101",
  TY003: "TC201",
  TY004: "TC301",
  TY006: "TC501",
} as const;

const ArticleHeader = () => {
  const { mainCompanyLogos } = useCreateCompanyLogoComponentList();
  const sectionCode = useSectionCodeToClassification();
  const paragraphCode =
    TITLE_PARAGRAPH_CODE[sectionCode as keyof typeof TITLE_PARAGRAPH_CODE];
  const paragraph = useParagraph(paragraphCode);

  const { data: articleInfo } = useArticleInfoQuery();

  const { isLoading, regenerate } = useRegenerate(paragraphCode);

  const date = formattedArticleDate(articleInfo.pressDate);
  const { imagePath } = articleInfo;
  const classification = articleInfo.classificationManual;

  return (
    <ArticleContentMainBanner
      title={
        <S.ArticleTitleWrapper>
          <S.ArticleTitleRegenerateButton
            onClick={regenerate}
            disabled={isLoading}
          />
          <S.ArticleTitle disabled={isLoading}>
            <EditableTextContainer
              key={paragraph.updateDt}
              item={{
                text: paragraph.editedData.text ?? "",
                labels: [],
              }}
              refKey={REF_CODE_TITLE}
              canTextEdit
              style={{
                backgroundColor: "transparent",
              }}
            />
          </S.ArticleTitle>
        </S.ArticleTitleWrapper>
      }
      date={date}
      image={imagePath}
      articleSection={SECTION_NAME_FOR_DISPLAY_ARTICLE_SECTION[classification]}
      mainLabels={<ArticleMainLabelReadableContainer />}
      mainCompanyLogos={mainCompanyLogos}
      pressUrl={articleInfo.pressUrl}
    />
  );
};

export default ArticleHeader;
