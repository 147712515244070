"use client";

import styled, { css } from "styled-components";

export const FilterWrapper = styled.div<{
  $isWide: boolean;
  $isDropdownOpen: boolean;
  $isDefault?: boolean;
  $defaultHeight?: string;
}>`
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  z-index: 50;

  transition: all 0.6s ease-in-out;

  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Primary, #fff);

  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.16);

  &::-webkit-scrollbar {
    width: 0.8rem;
    background-color: var(--Color-Common-Bg-Secondary, #f0f2f4);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: var(--Color-Common-Bg-Tertiary, #cbd0d8);

    &:hover {
      background: var(
        --Color-Common-Icon-Interactive-Secondary-Hovered,
        #3d4551
      );
    }

    &:active {
      background: var(
        --Color-Common-Icon-Interactive-Secondary-Pressed,
        #2a2f37
      );
    }
  }

  ${({ $isDefault }) =>
    $isDefault &&
    css`
      padding-left: 0.8rem;
    `}

  ${({ $isWide }) =>
    !$isWide &&
    css`
      width: 100%;
      position: absolute;
      top: 3.1rem;
    `}

  ${({ $isDropdownOpen, $defaultHeight }) =>
    $isDropdownOpen &&
    $defaultHeight &&
    css`
      opacity: 1;
      height: ${$defaultHeight};
      max-height: ${$defaultHeight};
      visibility: visible;
    `}
`;
