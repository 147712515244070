"use client";

import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  padding: 3.2rem 4rem;
  flex-direction: column;
  align-items: center;
  border-radius: var(--Border-Radius-8, 0.8rem);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Primary, #fff);
  width: 100%;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    padding: 3.2rem 1.6rem;
  }
`;
