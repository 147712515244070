import PressClassificationContainer from "@/components/business/PressClassificationContainer/PressClassificationContainer";
import DeletedPressBoundary from "@/components/shared/business/DeletedPressBoundary/DeletedPressBoundary";
import { useSafeLeavePage } from "@hooks/useSafeLeavePage";
import PressClassificationRoleGuard from "@libs/roles/components/PressClassificationRoleGuard";

const PressClassificationPage = () => {
  useSafeLeavePage();

  return (
    <PressClassificationRoleGuard>
      <DeletedPressBoundary>
        <PressClassificationContainer />
      </DeletedPressBoundary>
    </PressClassificationRoleGuard>
  );
};

export default PressClassificationPage;
