import { apiClient } from "@api/apiClient";

import {
  PostSellerAndBuyerByDealTypeRequest,
  PostSellerAndBuyerByDealTypeResponse,
} from "./sellerAndBuyerByDealType.interface";

const API_ENDPOINT = {
  post: "/des/deal-type/seller-buyer",
};

export const sellerAndBuyerByDealTypeApis = {
  post: async (dealTypes: PostSellerAndBuyerByDealTypeRequest) => {
    const { data } = await apiClient.post<PostSellerAndBuyerByDealTypeResponse>(
      API_ENDPOINT.post,
      dealTypes,
    );

    return data;
  },
};
