import { useEffect } from "react";

import { LabelCode } from "@/types/code.types";
import Portal from "@atoms/Modal/Portal";
import iconXMark from "@images/common/icon-red-x-mark.svg";

import { labelData } from "./PressLabelOptionWindow.data";
import * as S from "./PressLabelOptionWindow.style";
import { Label } from "../../types/editableText.types";

export interface MousePosition {
  top: number;
  left: number;
}

interface Props {
  mousePosition: MousePosition;
  addLabel: (entity: Label["entity"]) => void;
  deleteLabel: () => void;
  modifyLabel: (entity: Label["entity"]) => void;
  closeWindow: () => void;
}

const OPTION_WINDOW_WIDTH = 672;
const OPTION_WINDOW_WIDTH_SPACE = 20;
const OPTION_WINDOW_HEIGHT = 159;
const OPTION_WINDOW_HEIGHT_SPACE = 20;

const PressLabelOptionWindow = ({
  mousePosition,
  closeWindow,
  addLabel,
  deleteLabel,
  modifyLabel,
}: Props) => {
  const { top, left } = mousePosition;

  const handleColorClick = (entity: LabelCode | "") => {
    if (entity === "") modifyLabel("E010");
    else addLabel(entity);

    closeWindow();
  };

  const handleDeleteClick = () => {
    deleteLabel();

    closeWindow();
  };

  const handleOverlayClick = () => {
    closeWindow();
  };

  const movedLeft =
    left + OPTION_WINDOW_WIDTH > window.innerWidth
      ? window.innerWidth - OPTION_WINDOW_WIDTH - OPTION_WINDOW_WIDTH_SPACE
      : left;
  const movedTop =
    top + OPTION_WINDOW_HEIGHT > window.innerHeight
      ? window.innerHeight - OPTION_WINDOW_HEIGHT - OPTION_WINDOW_HEIGHT_SPACE
      : top;

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "1") handleColorClick("E031");
      if (e.key === "2") handleColorClick("E020");
      if (e.key === "3") handleColorClick("E010");
      if (e.key === "4") handleColorClick("E052");
      if (e.key === "5") handleColorClick("E040");
      if (e.key === "6") handleColorClick("E060");
      if (e.key === "Backspace") handleDeleteClick();
      if (e.key === "Escape") closeWindow();
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  return (
    <Portal>
      <S.Overlay onClick={handleOverlayClick}>
        <S.PressLabelOptionWindowWrapper top={movedTop} left={movedLeft}>
          {labelData.map(({ entity, text }, index) => (
            <S.PressLabelButton
              key={`${entity}-${text}`}
              type="button"
              onClick={() => handleColorClick(entity)}
            >
              <S.PressLabelCircle entity={entity} />
              <span>
                <S.PressLabelText>{text}</S.PressLabelText>
                <S.Number>({index + 1})</S.Number>
              </span>
            </S.PressLabelButton>
          ))}
          <S.CancelButton type="button" onClick={() => handleDeleteClick()}>
            <S.PressLabelCircle entity="">
              <img src={iconXMark} alt="delete button" />
            </S.PressLabelCircle>
            <p>Label Delete (DEL)</p>
          </S.CancelButton>
        </S.PressLabelOptionWindowWrapper>
      </S.Overlay>
    </Portal>
  );
};

export default PressLabelOptionWindow;
