import React from "react";

export interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconRadiopharmaceutical = ({
  width = 24,
  height = 24,
  color = "#515C6C",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        className="modality-icon-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2498 12C19.2498 16.0041 16.0039 19.25 11.9998 19.25C7.99575 19.25 4.74982 16.0041 4.74982 12C4.74982 7.99594 7.99575 4.75 11.9998 4.75C16.0039 4.75 19.2498 7.99594 19.2498 12ZM20.4998 12C20.4998 16.6944 16.6942 20.5 11.9998 20.5C7.3054 20.5 3.49982 16.6944 3.49982 12C3.49982 7.30558 7.3054 3.5 11.9998 3.5C16.6942 3.5 20.4998 7.30558 20.4998 12ZM9.91942 13.0449C9.97993 13.1651 10.0505 13.2794 10.1301 13.3866L8.80543 15.6817C8.53393 16.1417 8.69014 16.7406 9.16156 17.0093C10.8801 18.0069 13.1229 18.0063 14.8408 17.0077L14.8408 17.0077L14.8486 17.003C15.2731 16.748 15.4796 16.1653 15.194 15.6817L13.8694 13.3867C13.9491 13.2795 14.0197 13.1652 14.0802 13.0449H16.7233C17.2739 13.0449 17.6895 12.5913 17.6895 12.0782C17.6895 10.0528 16.6026 8.15907 14.8416 7.1492C14.6616 7.04341 14.4776 7.02152 14.3563 7.02152C14.0205 7.02152 13.6991 7.1999 13.5216 7.49618L13.5215 7.49614L13.5165 7.50495L12.2604 9.68124C12.1748 9.67171 12.0879 9.66681 11.9998 9.66681C11.9137 9.66681 11.8288 9.67149 11.7451 9.68059L10.4833 7.49427L10.4833 7.49423L10.4781 7.4855C10.3006 7.18922 9.97917 7.01084 9.6434 7.01084C9.52211 7.01084 9.3381 7.03273 9.15815 7.13852C7.39714 8.14839 6.31019 10.0421 6.31019 12.0675C6.31019 12.5915 6.7367 13.0449 7.27638 13.0449H9.91942ZM10.5428 10.098C10.011 10.4945 9.63771 11.0992 9.55715 11.7949H7.56862C7.65438 10.41 8.38924 9.13206 9.54436 8.36814L10.5428 10.098ZM16.4304 11.7949C16.3416 10.4141 15.6075 9.14078 14.4554 8.37882L13.4608 10.1019C13.9971 10.4963 14.3635 11.1028 14.4427 11.7949H16.4304ZM13.968 16.0581L12.9729 14.3339C12.6747 14.465 12.3461 14.5401 11.9997 14.5401C11.6575 14.5401 11.3263 14.4666 11.0257 14.3355L10.0314 16.0581C11.2451 16.6567 12.7544 16.6567 13.968 16.0581ZM11.9998 13.0784C12.5943 13.0784 13.078 12.5954 13.078 11.9976C13.078 11.3998 12.5943 10.9168 11.9998 10.9168C11.4053 10.9168 10.9217 11.3998 10.9217 11.9976C10.9217 12.5954 11.4053 13.0784 11.9998 13.0784Z"
        fill={color}
      />
    </svg>
  );
};

IconRadiopharmaceutical.displayName = "radiopharmaceutical";
export default IconRadiopharmaceutical;
