"use client";

import styled, { css } from "styled-components";

interface AlertType {
  $type: "error" | "success" | "default" | "text";
  $buttonColor?: string;
}

export const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

export const ModalContents = styled.div`
  z-index: 32;
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100% - 32px);
  max-width: 48rem;
  transform: translate(-50%, -50%);
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.64);
`;

export const AlertWrapper = styled.div<AlertType>`
  position: relative;
  display: flex;
  width: 48rem;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;

  border-radius: 0.4rem;
  background: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.16),
    0px 0px 8px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid var(--Color-Common-Border-Primary, #7c899c);

  ${({ $type }) =>
    $type === "error" &&
    css`
      border: 1px solid var(--Color-Common-Border-Warning, #e53e06);
    `}

  ${({ $type }) =>
    $type === "success" &&
    css`
      border: 1px solid var(--Color-Common-Text-Success, #0198e9);
    `}
  
  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    width: 100%;
    max-width: 48rem;
    margin: 0 auto;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.7rem;
  cursor: pointer;
`;

export const AlertTitle = styled.h3<AlertType>`
  font-size: 2rem;
  font-weight: 600;
  line-height: 135%;
  color: var(--Color-Common-Text-Primary, #2a2f37);

  ${({ $type }) =>
    $type === "error" &&
    css`
      color: var(--Color-Common-Text-Warning, #e53e06);
    `}

  ${({ $type }) =>
    $type === "success" &&
    css`
      color: var(--Color-Common-Text-Success, #0198e9);
    `}
`;

export const AlertInfo = styled.div<AlertType>`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: ${({ theme }) => theme.font.fontFamily.pretendard};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 145%; /* 2.03rem */
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0.8rem;
  gap: var(--Spacing-20, 2rem);
`;

export const SubButton = styled.button`
  display: flex;
  height: 4rem;
  width: 21rem;
  max-width: 21rem;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  padding: var(--Spacing-Btn-8, 0.8rem) var(--Spacing-Btn-16, 1.6rem);

  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  font-size: 1.4rem;
  font-weight: 500;

  cursor: pointer;
`;

export const ConfirmButton = styled.button<AlertType>`
  display: flex;
  height: 4rem;
  width: 21rem;
  max-width: 21rem;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  padding: var(--Spacing-Btn-8, 0.8rem) var(--Spacing-Btn-16, 1.6rem);

  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Interactive-Primary, #1baefe);

  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  margin-left: auto;
  cursor: pointer;

  ${({ $type }) =>
    $type === "error" &&
    css`
      background: var(--Color-Common-Bg-Interactive-Destructive, #e53e06);
    `}

  ${({ $type }) =>
    $type === "success" &&
    css`
      background: var(--Color-Common-Bg-Interactive-Primary, #1baefe);
    `}

  ${({ $type }) =>
    $type === "text" &&
    css`
      background: transparent;
      color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
    `}

  ${({ $buttonColor }) =>
    $buttonColor &&
    css`
      background: ${$buttonColor};
    `}
`;
