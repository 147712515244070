import React, { Dispatch, SetStateAction, useId } from "react";

import {
  UnsuitableReasonList,
  UnsuitableReasonType,
} from "./constants/UnsuitableReason.constants";
import { useHandleUnsuitableReasonItem } from "./hooks/useHandleUnsuitableReasonItem";
import * as S from "./UnsuitableReasonCheckItem.style";

export interface InfoForSelectedRejectReason
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  reasonId: string;
  reason: string | null;
  explanation?: string;
  reasonType: UnsuitableReasonType;
  placeholder?: string;
  isTarget: boolean;
  duplId?: number | null;
  articleRejectReason: string | null;
  unsuitablePressReasonList: UnsuitableReasonList[];
  setUnsuitablePressReasonList: Dispatch<
    SetStateAction<UnsuitableReasonList[]>
  >;
  draftArticleId: number;
  mcEditorName: string | null;
  aiDone: boolean;
  closeModalUnsuitablePress: () => void;
}

const UnsuitableReasonCheckItem = ({
  reasonId,
  reason,
  explanation,
  reasonType,
  placeholder,
  isTarget,
  duplId,
  articleRejectReason,
  unsuitablePressReasonList,
  setUnsuitablePressReasonList,
  draftArticleId,
  mcEditorName,
  aiDone,
  closeModalUnsuitablePress,
}: InfoForSelectedRejectReason) => {
  const infoForSelectedRejectReason = {
    reasonId,
    reason,
    explanation,
    reasonType,
    placeholder,
    isTarget,
    duplId,
    articleRejectReason,
    unsuitablePressReasonList,
    setUnsuitablePressReasonList,
    draftArticleId,
    mcEditorName,
    aiDone,
    closeModalUnsuitablePress,
  };
  const { handleCheckTargetReason, handleClickCheckBox } =
    useHandleUnsuitableReasonItem(infoForSelectedRejectReason);

  const checkboxId = useId();

  return (
    <S.CheckItemLabelWrapper
      htmlFor={checkboxId}
      onChange={() => handleCheckTargetReason(reasonType)}
    >
      <S.Checkbox
        id={checkboxId}
        type="checkbox"
        checked={isTarget}
        isTarget={isTarget}
        onClick={() => handleClickCheckBox(reasonType)}
      />
      <S.CheckItemLabel isTarget={isTarget}>{reason}</S.CheckItemLabel>
    </S.CheckItemLabelWrapper>
  );
};

export default UnsuitableReasonCheckItem;
