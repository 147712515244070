"use client";

import styled from "styled-components";

export const ChartWrapper = styled.div`
  width: 100%;
  height: 18.3rem;
  background-color: white;
  font-family: var(--font-pt-serif);
`;

export const TooltipBox = styled.div`
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  background: var(--Color-Common-Bg-Primary, #fff);
  padding: 0.1rem 0.2rem 0.4rem 0.2rem;

  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);
`;

export const TooltipMonth = styled.div`
  padding: var(--Spacing-4, 0.4rem);
  gap: var(--Spacing-4, 0.4rem);

  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 130%; /* 1.56rem */
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding: var(--Spacing-0, 0rem) var(--Spacing-4, 0.4rem);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-4, 0.4rem);
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Spacing-4, 0.4rem);
`;

export const TextWrapper = styled.div`
  display: flex;
  gap: 0.2rem;

  .description {
    color: var(--Color-Label-Text-Interactive-Payment, #597181);
  }
`;

export const TooltipDot = styled.div<{
  $backgroundColor: string;
}>`
  width: 0.65rem;
  height: 0.65rem;
  border-radius: 50%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const TooltipYear = styled.div`
  color: var(--Color-Common-Text-Secondary, #515c6c);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 130%; /* 1.56rem */
`;

export const TooltipAmount = styled.div<{ $isDollars: boolean }>`
  color: ${({ $isDollars }) => ($isDollars ? "#515C6C" : "#2A2F37")};
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 130%;
`;
