import styled from "styled-components";

export const AiImageFilterWrapper = styled.div``;

export const AiImageRegenerateButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-Btn-8, 0.8rem);
  height: 3.2rem;
  max-height: var(--Size-Btn-Lg, 4.8rem);
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Bg-Inverse-ToDo, #15d555);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16),
    0px 0px 1px 0px rgba(0, 0, 0, 0.12);
  padding: var(--Spacing-Btn-8, 0.8rem) var(--Spacing-24, 2.4rem)
    var(--Spacing-Btn-8, 0.8rem) var(--Spacing-Btn-16, 1.6rem);
  cursor: pointer;

  &:disabled {
    border: 1px solid var(--Color-Common-Border-Disabled, #b1b8c4);
    background: var(--Color-Common-Bg-Disabled, #cbd0d8);
    cursor: not-allowed;
  }
`;
