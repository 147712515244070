import React, { useEffect, useRef } from "react";

import { getGroupShellTitle } from "@/components/business/FillInTableContainer/shared/utils/getTextFromData";
import { VentureCapitalGroupTable } from "@/types/ventureCapital.types";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import {
  FillInTableTableCreatorTitleRowContainer,
  TableCreatorGroupShellTitle,
} from "@FillInTableContainer/shared";
import { useGetNumberOfShell } from "@FillInTableContainer/shared/hooks/useGetNumberOfShell";
import { isSeparatedShellTitle } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import VentureCapitalInvestorsCreatorShell from "./components/VentureCapitalInvestorsCreatorShell/VentureCapitalInvestorsCreatorShell";
import { useHandleVentureCapitalGroupShell } from "./hooks/useHandleVentureCapitalGroupShell";
import * as S from "./VentureCapitalInvestorsCreatorGroupShell.style";

import type { TableCreatorShellList } from "@FillInTableContainer/shared/types/tableCreator.types";

interface Props {
  groupTableKey: VentureCapitalGroupTable;
  groupTableIndex: number;
  groupShellKey: string | null;
  shellList: TableCreatorShellList;
  tableKey: string | null;
  tableIndex: number;
  groupShellIndex: number;
  groupShellCount: number;
  isTableScroll: boolean;
}

const VentureCapitalInvestorsTableCreatorGroupShell = ({
  groupTableKey,
  groupTableIndex,
  groupShellKey,
  shellList,
  tableKey,
  tableIndex,
  groupShellIndex,
  groupShellCount,
  isTableScroll,
}: Props) => {
  const positionPropsInfo = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  };
  const { shellCount } = useGetNumberOfShell();
  const articleSection = useArticleSection();
  const selectButtonRef = useRef<HTMLDivElement>(null);
  const {
    isFocused,
    isClearGroupShell,
    isDeleteGroupShell,
    isAlertGroupShell,
    handleDefaultFocus,
  } = useHandleVentureCapitalGroupShell({
    positionPropsInfo,
    groupShellCount,
  });

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;
        const clickSelectButton = selectButtonRef.current?.contains(target);

        if (clickSelectButton) return;

        handleDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleDefaultFocus, isFocused]);

  return (
    <S.GroupShellOutsideWrapper
      isFocused={isFocused}
      isClearGroupShell={isClearGroupShell}
      isDeleteGroupShell={isDeleteGroupShell}
      isAlertGroupShell={isAlertGroupShell}
    >
      {isSeparatedShellTitle(articleSection, groupShellKey) &&
        groupShellIndex === 0 && (
          <FillInTableTableCreatorTitleRowContainer
            positionPropsInfo={positionPropsInfo}
            shellList={shellList}
            isTableScroll={isTableScroll}
          />
        )}

      <S.GroupShellWrapper>
        {getGroupShellTitle(articleSection, groupShellKey) && (
          <TableCreatorGroupShellTitle
            groupShellKey={groupShellKey}
            groupShellCount={groupShellCount}
            groupShellIndex={groupShellIndex}
            isAlertGroupShell={isAlertGroupShell}
          />
        )}
        <S.SelectWrapper>
          <S.GroupShell>
            {shellList.map(({ shellKey, subShellList }, shellListIndex) => (
              <React.Fragment key={`shellList-${String(shellListIndex)}`}>
                {newArrayToCountValue(
                  shellCount(shellKey, tableIndex, groupShellIndex),
                ).map((_, shellIndex) => (
                  <VentureCapitalInvestorsCreatorShell
                    key={`shell-${String(groupTableIndex)}-${String(
                      tableIndex,
                    )}-${String(groupShellIndex)}-${String(shellIndex)}`}
                    groupTableKey={groupTableKey}
                    groupTableIndex={groupTableIndex}
                    groupShellKey={groupShellKey}
                    groupShellIndex={groupShellIndex}
                    shellKey={shellKey}
                    subShellList={subShellList}
                    shellIndex={shellIndex}
                    tableKey={tableKey}
                    tableIndex={tableIndex}
                    shellCount={shellCount(
                      shellKey,
                      tableIndex,
                      groupShellIndex,
                    )}
                    isShellOverOne={
                      shellCount(shellKey, tableIndex, groupShellIndex) > 1
                    }
                    isSeparatedShellTitle={isSeparatedShellTitle(
                      articleSection,
                      groupShellKey,
                    )}
                    isClearGroupShell={isClearGroupShell}
                    isDeleteGroupShell={isDeleteGroupShell}
                    isAlertGroupShell={isAlertGroupShell}
                  />
                ))}
              </React.Fragment>
            ))}
          </S.GroupShell>
        </S.SelectWrapper>
      </S.GroupShellWrapper>
    </S.GroupShellOutsideWrapper>
  );
};

export default VentureCapitalInvestorsTableCreatorGroupShell;
