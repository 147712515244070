import React, { ReactNode, useEffect } from "react";

import { showToast } from "@atoms/Toast/Toast";

import * as S from "./ErrorMessageContainer.style";

type Props = {
  message: string;
  clickButton?: () => void;
  addComponents?: ReactNode;
};

const ErrorMessageContainer = ({
  message,
  clickButton,
  addComponents,
}: Props) => {
  useEffect(() => {
    showToast({
      icon: "error",
      backgroundColor: "hotPink",
      text: message,
      position: "bottomLeft",
      autoClose: 5000,
      customButton: clickButton
        ? [
            {
              name: "Retry",
              action: clickButton,
            },
          ]
        : [],
    });
  }, [clickButton, message]);

  return (
    <S.ErrorComponentWrapper>
      {addComponents && addComponents}
      <S.ErrorMessageContainer>
        <S.ErrorMessageTitle>Logic Error</S.ErrorMessageTitle>
        <S.ErrorMessageContent>{message}</S.ErrorMessageContent>
      </S.ErrorMessageContainer>
    </S.ErrorComponentWrapper>
  );
};

export default ErrorMessageContainer;
