import { ExpeditedProgramSection } from "@/types/expeditedProgram.types";
import { EXPEDITED_PROGRAM } from "@FillInTableContainer/shared/constants/expeditedProgramKey.constants";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useUpdateEntryData } from "@hooks/useUpdateArticleData";
import { useEntryDataState } from "@stores/entryData";
import { useInitEntryDataValue } from "@stores/initEntryData";
import { deepCopy } from "@utils/deepCopy";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import {
  BASIC_DATA_FOR_ADDING_EXPEDITED_PROGRAM_TABLES,
  CHECK_TABLE_KEY_BY_EXPEDITED_PROGRAM_GROUP_TABLE_KEY,
} from "../../../constants/tableCreatorTable.constants";

interface useExpeditedProgramDrugProfileGroupTableReturn {
  handleAddTable: (tableIndex: number) => void;
}

interface Props {
  groupTableKey: ExpeditedProgramSection;
}

export const useExpeditedProgramDrugProfileGroupTable = ({
  groupTableKey,
}: Props): useExpeditedProgramDrugProfileGroupTableReturn => {
  const articleId = getPressIdFromURL();
  const articleSection = EXPEDITED_PROGRAM;
  const [entryData, setEntryData] = useEntryDataState(articleId);
  const initEntryData = useInitEntryDataValue(articleId);
  const updateEntryData = useUpdateEntryData();
  const { changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();

  const handleAddTable = (tableIndex: number) => {
    if (focusStatus.container !== "noFocus") return;

    const targetTableListInEntryData =
      deepCopy(entryData?.[articleSection]?.[groupTableKey]) || [];

    if (!Array.isArray(targetTableListInEntryData)) return;

    const newArrayLength = targetTableListInEntryData.length + 1;
    const emptyArray = new Array(newArrayLength).fill({});

    const addTableData = emptyArray.map((_, index) => {
      if (index === tableIndex) {
        return BASIC_DATA_FOR_ADDING_EXPEDITED_PROGRAM_TABLES[articleSection]?.[
          groupTableKey
        ](initEntryData);
      }
      if (index > tableIndex) {
        return targetTableListInEntryData[index - 1];
      }
      return targetTableListInEntryData[index];
    });

    const updatedEntryData = {
      [articleSection]: {
        ...entryData[articleSection],
        [groupTableKey]: addTableData,
      },
    };

    setEntryData(updatedEntryData);
    updateEntryData(updatedEntryData);
    changeCurrentHistory({
      entryData: updatedEntryData,
      changedDataInfo: {
        target: "table",
        action: "add",
        position: {
          ...DEFAULT_POSITION_INFO,
          groupTableKey,
          tableIndex,
          tableKey:
            CHECK_TABLE_KEY_BY_EXPEDITED_PROGRAM_GROUP_TABLE_KEY[
              articleSection
            ][groupTableKey],
          value: "",
        },
      },
    });
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeInfo(DEFAULT_POSITION_INFO);
  };

  return {
    handleAddTable,
  };
};
