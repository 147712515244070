import styled from "styled-components";

export const DefaultButtonStyle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  min-height: var(--Size-Btn-Minimum, 2.8rem);
  max-height: var(--Size-Btn-Maximum, 4.8rem);
  padding: 0.8rem;

  cursor: pointer;
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  font-size: 1.2rem;
  font-weight: 500;

  &:disabled {
    border-radius: var(--Border-Radius-4, 0.4rem);
    color: var(--Color-Common-Text-Disabled, #96a1b0);
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not([disabled]) {
    border-radius: var(--Border-Radius-circle, 99999.9rem);
    background: var(--Color-Common-Bg-Interactive-Secondary-Hovered, #f0f2f4);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
      0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  &:active:not([disabled]) {
    border-radius: var(--Border-Radius-circle, 99999.9rem);
    background: var(--Color-Common-Bg-Interactive-Secondary-Pressed, #e5e7eb);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
      0px 1px 2px 0px rgba(0, 0, 0, 0.16);
  }
`;

export const Container = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  padding: 0 0.8rem;
  flex-shrink: 0;
  overflow: hidden;

  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Interactive-Secondary, #b1b8c4);
  background: var(--Color-Common-Bg-Primary, #fff);

  @media only screen and (min-width: 1px) and (max-width: 1280px) {
    position: fixed;
    left: 2rem;
    bottom: 7.5rem;
  }
`;

export const UndoButton = styled(DefaultButtonStyle)`
  gap: 0.2rem;
`;

export const RedoButton = styled(DefaultButtonStyle)`
  gap: 0.2rem;
`;

export const SaveButton = styled(DefaultButtonStyle)`
  gap: var(--Spacing-4, 0.4rem);
`;
