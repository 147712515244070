import {
  ARTICLE_CONTENTS_INVESTORS_PARAGRAPH_GROUP_KEY as PARAGRAPH_GROUP_KEY,
  ARTICLE_CONTENTS_INVESTORS_SHELL_KEYS as SHELL_KEYS,
} from "@frontend/common";
import dayjs from "dayjs";

import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import { REF_CODE_VENTURE_CAPITAL_INVESTOR_NAME } from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { VentureCapitalInvestorsContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/ventureCapitalInvestorsContent.types";

import { createContentFromHTML } from "../../../utils/getValueFromRefMap";

export const useCreateVentureCapitalInvestorsContent = () => {
  const refMap = useArticleContentRefMap();

  return () => {
    const content: VentureCapitalInvestorsContent = {
      text: {
        [PARAGRAPH_GROUP_KEY]: {
          [SHELL_KEYS.investorName]: createContentFromHTML(
            refMap,
            REF_CODE_VENTURE_CAPITAL_INVESTOR_NAME,
          ),
        },
      },
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
