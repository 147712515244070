import styled from "styled-components";

export const BottomButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  height: 4rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 130%;
  border-radius: 0.4rem;
  padding: 0 2.4rem;
  cursor: pointer;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const PrevButton = styled(BottomButton)`
  background-color: #ffffff;
  border: 0.1rem solid #0198e9;
  color: #0198e9;
  padding: 0 2.4rem 0 1.6rem;

  &:disabled {
    color: #96a1b0;
    border: 0.1rem solid #b1b8c4;
  }
`;

export const NextButton = styled(BottomButton)`
  background-color: #0198e9;
  color: #ffffff;
  border: 0.1rem solid #0198e9;
  padding: 0 1.6rem 0 2.4rem;

  &:disabled {
    background-color: #cbd0d8;
    color: #96a1b0;
    border: 0.1rem solid #cbd0d8;
  }
`;

export const ReviewInProgressButton = styled(BottomButton)`
  background-color: #f90;
  color: #ffffff;
  border: 0.1rem solid #f90;
  padding: 0 1.6rem 0 2.4rem;

  &:disabled {
    background-color: #cbd0d8;
    color: #96a1b0;
    border: 0.1rem solid #cbd0d8;
  }
`;
