import styled from "styled-components";

export const ArticleDetailIndexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;
  width: 100%;
  height: 3.8rem;
  padding: 0.4rem 0;
`;

export const IndexTitle = styled.p`
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 2.4rem;
  font-weight: 600;
  color: var(--Color-Common-Text-Success, #0198e9);
`;
