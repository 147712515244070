import React from "react";

export interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconSmallMolecule = ({
  width = 24,
  height = 24,
  color = "#515C6C",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        className="modality-icon-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.97118 6.52441C9.77825 6.63682 9.65995 6.84363 9.66084 7.06692L9.675 10.6174L6.51613 12.458C6.3232 12.5704 6.2049 12.7772 6.20579 13.0005L6.22036 16.6534C6.20194 16.6614 6.18374 16.6704 6.16581 16.6803L3.46351 18.1789C3.16165 18.3463 3.05264 18.7267 3.22004 19.0286C3.38745 19.3304 3.76787 19.4395 4.06973 19.2721L6.77203 17.7735C6.81015 17.7523 6.8452 17.7278 6.877 17.7004L9.97795 19.4743C10.1718 19.5852 10.41 19.5842 10.6029 19.4718L14.0288 17.4756C14.2218 17.3632 14.3401 17.1564 14.3392 16.9331L14.325 13.3827L17.4839 11.5421C17.6768 11.4296 17.7951 11.2228 17.7942 10.9995L17.7798 7.37553C17.7981 7.36754 17.8162 7.35861 17.8341 7.34871L20.5364 5.85013C20.8382 5.68273 20.9472 5.30231 20.7798 5.00044C20.6124 4.69857 20.232 4.58957 19.9302 4.75697L17.2279 6.25555C17.1979 6.27218 17.1698 6.29091 17.1437 6.31145L14.0221 4.52573C13.8282 4.41486 13.59 4.41581 13.3971 4.52822L9.97118 6.52441ZM10.2579 11.7245L13.0748 13.3358L13.0877 16.5773L10.2854 18.2101L7.47015 16.5997L7.45722 13.3564L10.2579 11.7245ZM13.7421 12.2756L16.5428 10.6437L16.5298 7.40038L13.7146 5.78992L10.9123 7.42277L10.9252 10.6642L13.7421 12.2756Z"
        fill={color}
      />
    </svg>
  );
};

IconSmallMolecule.displayName = "SmallMolecule";
export default IconSmallMolecule;
