import styled from "styled-components";

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.4rem;
  width: 100%;
  padding: var(--Spacing-20, 2rem);
  border-radius: 0.4rem;
  border: var(--Border-Width-2, 2px) solid
    var(--Color-Common-Border-Danger-Subtle, #f9a9ae);
  background: var(--Color-Common-Bg-Danger-Subtle, #fcd4d7);
`;

export const Title = styled.div`
  width: 100%;
  color: var(--Color-Common-Text-Danger-Bold, #860912);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

export const ArticleId = styled.div`
  width: 100%;
  color: var(--Color-Common-Text-Secondary, #515c6c);
  font-family: var(--font-pretendard);
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
`;

export const Line = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: #7c899c;
`;

export const Description = styled.div`
  width: 100%;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: var(--font-pretendard);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  white-space: pre-line;
`;

export const DetailDescription = styled.div`
  width: 100%;
  color: var(--Color-Common-Text-Secondary, #515c6c);
  font-family: var(--font-pretendard);
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  white-space: pre-line;
`;

export const Button = styled.button`
  display: flex;
  height: 4rem;
  min-height: var(--Size-Btn-Sm, 2.8rem);
  max-height: var(--Size-Btn-Lg, 4.8rem);
  padding: 0 var(--Spacing-40, 4rem);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-Btn-4, 0.4rem);
  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Interactive-Secondary, #fff);

  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500; /* 2.03rem */
  cursor: pointer;
`;
