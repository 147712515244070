import { useEntryDataQuery } from "@api/entryData/entryData";
import { DRUG_NAME_ENTITY } from "@constants/labels.constants";

export const useDrugNameList = () => {
  const entryData = useEntryDataQuery();
  const DRUG_NAME_LIST = {
    clinicalTrials: {
      brandName:
        entryData.data["Clinical Trials"]?.["Drug Profile"]?.flatMap((list) =>
          list["Brand Name"].value.map((item) => item.text.trim()),
        ) ?? [],
      codeName:
        entryData.data["Clinical Trials"]?.["Drug Profile"]?.flatMap((list) =>
          list["Code Name"].value.map((item) => item.text.trim()),
        ) ?? [],
      innName:
        entryData.data["Clinical Trials"]?.["Drug Profile"]?.flatMap((list) =>
          list["INN Name"].value.map((item) => item.text.trim()),
        ) ?? [],
    },
    deals: {
      brandName:
        entryData.data.Deals?.["Drug Profile"]?.flatMap((list) =>
          list["Brand Name"].value.map((item) => item.text.trim()),
        ) ?? [],
      codeName:
        entryData.data.Deals?.["Drug Profile"]?.flatMap((list) =>
          list["Code Name"].value.map((item) => item.text.trim()),
        ) ?? [],
      innName:
        entryData.data.Deals?.["Drug Profile"]?.flatMap((list) =>
          list["INN Name"].value.map((item) => item.text.trim()),
        ) ?? [],
    },
    regulatory: {
      brandName:
        entryData.data.Regulatory?.["Drug Profile"]?.flatMap((list) =>
          list["Brand Name"].value.map((item) => item.text.trim()),
        ) ?? [],
      codeName:
        entryData.data.Regulatory?.["Drug Profile"]?.flatMap((list) =>
          list["Code Name"].value.map((item) => item.text.trim()),
        ) ?? [],
      innName:
        entryData.data.Regulatory?.["Drug Profile"]?.flatMap((list) =>
          list["INN Name"].value.map((item) => item.text.trim()),
        ) ?? [],
    },
    expeditedProgram: {
      brandName:
        entryData.data["Expedited Program"]?.["Drug Profile"]?.flatMap((list) =>
          list["Brand Name"].value.map((item) => item.text.trim()),
        ) ?? [],
      codeName:
        entryData.data["Expedited Program"]?.["Drug Profile"]?.flatMap((list) =>
          list["Code Name"].value.map((item) => item.text.trim()),
        ) ?? [],
      innName:
        entryData.data["Expedited Program"]?.["Drug Profile"]?.flatMap((list) =>
          list["INN Name"].value.map((item) => item.text.trim()),
        ) ?? [],
    },
  };

  const drugNameList: { value: string; entity: string }[] = Array.from(
    new Set([
      ...DRUG_NAME_LIST.clinicalTrials.innName,
      ...DRUG_NAME_LIST.clinicalTrials.codeName,
      ...DRUG_NAME_LIST.clinicalTrials.brandName,
      ...DRUG_NAME_LIST.deals.innName,
      ...DRUG_NAME_LIST.deals.codeName,
      ...DRUG_NAME_LIST.deals.brandName,
      ...DRUG_NAME_LIST.regulatory.innName,
      ...DRUG_NAME_LIST.regulatory.codeName,
      ...DRUG_NAME_LIST.regulatory.brandName,
      ...DRUG_NAME_LIST.expeditedProgram.innName,
      ...DRUG_NAME_LIST.expeditedProgram.codeName,
      ...DRUG_NAME_LIST.expeditedProgram.brandName,
    ]),
  ).map((text) => ({ value: text, entity: DRUG_NAME_ENTITY }));

  return { drugNameList };
};
