import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useTemporaryTokenQuery } from "@api/temporaryToken/temporaryToken";
import { setAuthorization } from "@libs/authorization/utils/setAuthorization";

import { getQueryParamsForRedirect } from "../utils/getQueryParamsForRedirect";
import {
  toastAdminReLogin,
  toastRedirectFail,
  toastRedirectSuccess,
} from "../utils/toastRedirect";

const RedirectChecker = () => {
  const navigate = useNavigate();
  const { id, page, token } = getQueryParamsForRedirect();
  const { isSuccess, data, isError } = useTemporaryTokenQuery({
    id,
    page,
    token,
  });

  // React Query의 onSuccess, onError 내부에서는 navigate가 동작하지 않아서 useEffect를 사용함.
  useEffect(() => {
    if (isSuccess && data) {
      setAuthorization({
        accessToken: data.accessToken,
        accountName: data.name,
        accountEmail: data.email,
        refreshToken: "",
      });

      navigate(`/${page}?&id=${id}&status=admin`);
      toastRedirectSuccess();
    }
    if (isError) {
      navigate("/");
      toastRedirectFail();
    }
  }, [data, id, isError, isSuccess, navigate, page]);

  if (!token || token.length !== 20) return <>{toastAdminReLogin()}</>;

  return <></>;
};

export default RedirectChecker;
