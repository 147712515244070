"use client";

import styled from "styled-components";

export const DBDetailTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  overflow: hidden;

  & > div:last-child {
    border-bottom: none;
  }
`;
