"use client";

import styled, { css } from "styled-components";

import { GROUP_TOOLTIP_CONTENTS_BACKGROUND } from "./constants/dealSnapshotTreemap.constants";
import { DealSnapshotType } from "./types/dealSnapshotTreemap.types";
import { DEAL_SNAPSHOT_TEXT_COLOR_BY_CATEGORY } from "../../../../shared";

export const Wrapper = styled.div<{
  $treemapType: DealSnapshotType;
  $currentFocusGroup: string;
}>`
  .group-name-icon-wrapper {
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .group-name-text {
    font-family: var(--font-pt-serif);
    font-weight: 700;
    fill: ${({ $treemapType }) =>
      DEAL_SNAPSHOT_TEXT_COLOR_BY_CATEGORY[$treemapType]};
    font-size: 11px;
    text-transform: capitalize;
    user-select: none;
  }
  .group-name-text[data-detail="true"] {
    font-size: 14px;
  }
  &:hover {
    .text-wrapper {
      background-color: var(--hover-bg);
    }
  }
  .text-wrapper[data-left-bottom-radius="true"] {
    border-bottom-left-radius: 8px;
    overflow: hidden;
  }
  .text-wrapper[data-right-bottom-radius="true"] {
    border-bottom-right-radius: 8px;
    overflow: hidden;
  }
  .deal-maps-text {
    width: 100%;
    height: fit-content;
    font-family: var(--font-pt-serif);
    text-align: center;
    padding: 0 8px;
    color: var(--Color-Common-Text-Primary, #2a2f37);
    overflow-wrap: break-word;
    user-select: none;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .text-wrapper[data-text-size-group="1"] {
    div {
      width: 100%;
      height: fit-content;
      font-size: 24px;
      font-weight: 700;
      padding: 0 8px;
    }
    .deal-map-text-company {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .text-wrapper[data-text-size-group="2"] {
    div {
      width: 100%;
      height: fit-content;
      font-size: 18px;
      font-weight: 700;
      padding: 0 8px;
    }
    .deal-map-text-company {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .text-wrapper[data-text-size-group="3"] {
    div {
      width: 100%;
      height: fit-content;
      font-size: 14px;
      font-weight: 700;
      padding: 0 8px;
    }
    .deal-map-text-company {
      font-size: 12px;
      font-weight: 700;
    }
  }

  .text-wrapper[data-text-size-group="4"] {
    div {
      width: 100%;
      height: fit-content;
      font-size: 12px;
      font-weight: 700;
      padding: 0rem 4px;
    }
    .deal-map-text-company {
      font-size: 11px;
      font-weight: 700;
    }
  }
  .text-wrapper[data-text-size-group="5"] {
    div {
      width: 100%;
      height: fit-content;
      font-size: 11px;
      font-weight: 700;
      padding: 0 4px;
    }
    .deal-map-text-company {
      font-size: 10px;
      font-weight: 700;
    }
  }
  .text-wrapper[data-text-size-group="6"] {
    div {
      width: 100%;
      height: fit-content;
      font-size: 10px;
      font-weight: 700;
      padding: 0 4px;
    }

    .deal-map-text-company {
      height: fit-content;
      font-size: 10px;
      font-weight: 700;
    }
  }

  .text-wrapper[data-text-size-group="7"] {
    div {
      width: 100%;
      height: fit-content;
      font-size: 8px;
      font-weight: 700;
      padding: 0 4px;
    }
  }
  foreignObject {
    background-color: transparent;
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%;
    height: 100%;
    background-color: #fff;

    overflow-wrap: break-word;
    cursor: pointer;

    div {
      height: 100%;
    }

    &:hover {
      background-color: var(--item-hover-bg);
    }
  }

  @media (hover: hover) and (pointer: fine) {
    .text-wrapper:hover {
      .deal-map-text-name {
        color: var(--Color-Label-Text-Interactive-Medicine, #005eeb) !important;
      }
      .deal-map-text-value {
        color: var(--Color-Label-Text-Interactive-Payment, #597181) !important;
      }
    }
  }

  .deal-maps-text[data-line="0"] {
    display: none;
  }
  .deal-maps-text[data-line="1"] {
    -webkit-line-clamp: 1;
  }
  .deal-maps-text[data-line="2"] {
    -webkit-line-clamp: 2;
  }
  .deal-maps-text[data-line="3"] {
    -webkit-line-clamp: 3;
  }
  .deal-maps-text[data-line="4"] {
    -webkit-line-clamp: 4;
  }
  .deal-maps-text[data-line="5"] {
    -webkit-line-clamp: 5;
  }
  .deal-maps-text[data-line="6"] {
    -webkit-line-clamp: 6;
  }
  .deal-maps-text[data-line="7"] {
    -webkit-line-clamp: 7;
  }
  .deal-maps-text[data-line="8"] {
    -webkit-line-clamp: 8;
  }
  .deal-maps-text[data-line="9"] {
    -webkit-line-clamp: 9;
  }
  .deal-maps-text[data-line="10"] {
    -webkit-line-clamp: 10;
  }
  .deal-maps-text[data-line="11"] {
    -webkit-line-clamp: 11;
  }
  .deal-maps-text[data-line="12"] {
    -webkit-line-clamp: 12;
  }
  .deal-maps-text[data-line="13"] {
    -webkit-line-clamp: 13;
  }
  .deal-maps-text[data-line="14"] {
    -webkit-line-clamp: 14;
  }

  @media (pointer: coarse) {
    &:hover {
      background: #fff;

      .text-wrapper {
        background: #fff;

        .deal-map-text-name {
          color: var(--Color-Common-Text-Primary, #2a2f37);
        }
        .deal-map-text-company {
          color: var(--Color-Common-Text-Primary, #2a2f37);
        }
        .deal-map-text-value {
          color: var(--Color-Common-Text-Primary, #2a2f37);
        }
      }
    }
  }

  ${({ $currentFocusGroup }) => {
    if ($currentFocusGroup === "") return css``;
    const targetClassName = `.leaf-${$currentFocusGroup.replace(
      /[\s/&()]+/g,
      "_",
    )}`;
    return css`
      .group-leaf-default {
        opacity: 0.7 !important;
      }
      ${targetClassName} {
        opacity: 1 !important;
      }
    `;
  }}

  .company-logo-wrapper {
    width: 18px;
    height: 18px;
    border-radius: var(--Border-Radius-2, 0.2rem);
    border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
    background: var(--Color-Common-Bg-Primary, #fff);
    overflow: hidden;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

export const TreemapWithTooltipWrapper = styled.div<{
  $treemapType: DealSnapshotType;
}>`
  width: 100%;
  height: 100%;

  .tooltip {
    position: absolute;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    width: 240px;
    z-index: 1000;

    .tooltip-item-wrapper {
      gap: 0.4rem;
      background-color: #fff;
      border: 1px solid #ccc;
      padding: 4px 8px;
      border-radius: 4px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    &[data-item-tooltip-position="topRight"] {
      display: flex;
      justify-content: flex-end;

      height: 250px;
      padding-bottom: 7px;

      .tooltip-arrow-icon {
        top: auto !important;
        bottom: 1px;
        transform: translateX(-50%) scaleY(-1) !important;
      }
    }

    &[data-item-tooltip-position="top"] {
      display: flex;
      justify-content: flex-end;
      height: 250px;
      padding-bottom: 7px;

      .tooltip-arrow-icon {
        top: auto !important;
        bottom: 1px;
        transform: translateX(-50%) scaleY(-1) !important;
      }
    }

    .tooltip-arrow-icon {
      position: absolute;
      z-index: 100 !important;
    }

    .tooltip-item {
      position: relative;
      color: var(--Color-Common-Text-Primary, #2a2f37);
      font-family: var(--font-pt-serif);
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 145%;
      padding-left: 1.4rem;

      .tooltip-item-value {
        font-family: var(--font-pt-serif);
        font-size: 1.4rem;
        font-weight: 700;
        overflow-wrap: break-word;
      }
      .status-text-payment {
        color: var(--Color-Label-Text-Interactive-Payment, #597181);
      }
      .undisclosed-payment-false {
        white-space: nowrap;
      }
      .status-text-drug-name {
        color: var(--Color-Label-Text-Interactive-Medicine, #005eeb);
      }
      .status-text-modality {
        color: var(--Color-Label-Text-Interactive-Modality, #8219d2);
      }
      .status-text-endpoint {
        color: var(--Color-Label-Text-Interactive-Endpoint, #29b5c2);
      }
      .status-text-disease {
        color: var(--Color-Label-Text-Interactive-Disease, #db0f72);
      }
      .status-text-phase {
        color: var(--Color-Label-Text-Phase, #615c00);
        &.status-text-word-spacing-true {
          word-spacing: -0.3rem;
        }
      }

      .snapshot-capitalize {
        text-transform: capitalize;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        color: var(--Color-Common-Text-Primary, #2a2f37);
      }
    }
  }

  .group-tooltip-wrapper {
    position: relative;
    display: flex;
    width: 24rem;
    z-index: 100;

    .group-tooltip-contents {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: var(--Spacing-0, 0rem);
      border-radius: var(--Border-Radius-4, 0.4rem);
      overflow: hidden;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
        0px 0px 2px 0px rgba(0, 0, 0, 0.12);
    }

    & .icon-arrow {
      position: absolute;
      top: -7px;
      display: block;
      width: 24px;
      height: 7px;
    }

    &[data-position="top"] {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      height: 480px;

      .icon-arrow {
        top: auto;
        bottom: -7px;
        transform: scaleY(-1);

        path {
          fill: ${({ $treemapType }) =>
            GROUP_TOOLTIP_CONTENTS_BACKGROUND[$treemapType]};
        }
      }
    }

    &[data-position="topRight"] {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      height: 480px;

      .icon-arrow {
        top: auto;
        left: auto;
        bottom: -7px;
        right: 0px;
        transform: scaleY(-1);

        path {
          fill: ${({ $treemapType }) =>
            GROUP_TOOLTIP_CONTENTS_BACKGROUND[$treemapType]};
        }
      }
    }

    &[data-position="left"] .icon-arrow {
      left: 0px;
    }
    &[data-position="right"] .icon-arrow {
      right: 0px;
    }
  }

  .group-tooltip-item {
    width: 100%;
  }
  .group-title-wrapper {
    width: 100%;
    font-family: var(--font-pt-serif);
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 145%;
    padding: 0.4rem;

    .group-name {
      font-family: var(--font-pt-serif);
      font-weight: 700;
      font-size: 1.4rem;
      text-transform: capitalize;
    }
    .group-count-item {
      font-family: var(--font-pt-serif);
      font-weight: 700;
      font-size: 1.4rem;
      color: var(--Color-Common-Text-Primary, #2a2f37);
    }
    .group-value {
      font-family: var(--font-pt-serif);
      font-weight: 700;
      font-size: 1.4rem;
      color: var(--Color-Label-Text-Interactive-Payment, #597181);
    }
  }

  .group-item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    width: 100%;
    padding: 0.4rem;

    .group-item-row {
      position: relative;
      font-family: var(--font-pt-serif);
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 145%;
      padding-left: 0.9rem;

      &::before {
        content: "";
        position: absolute;
        left: 0.2rem;
        top: 1rem;
        width: 0.3rem;
        height: 0.3rem;
        background-color: var(--Color-Label-Text-Interactive-Medicine, #005eeb);
        border-radius: 50%;
      }
    }
    .group-item-drug-name {
      font-family: var(--font-pt-serif);
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 145%;
      color: var(--Color-Label-Text-Interactive-Medicine, #005eeb);
    }
    .group-item-value {
      font-family: var(--font-pt-serif);
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 145%;
      color: var(--Color-Label-Text-Interactive-Payment, #597181);
    }
    .undisclosed-payment-false {
      white-space: nowrap;
    }
    .icon-more {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
`;
