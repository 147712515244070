import styled from "styled-components";

import UpdateButtonDisabledIcon from "@images/editArticle/icon-update-disabled-button.svg";
import UpdateButtonIcon from "@images/editArticle/icon-update-enabled-button.svg";

export const UpdateButton = styled.button`
  width: 2rem;
  height: 2rem;
  background-image: url(${UpdateButtonIcon});
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background-image: url(${UpdateButtonDisabledIcon});
  }
`;
