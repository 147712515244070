import styled, { css } from "styled-components";

import { hideScrollbarX } from "@styles/mixin";

export const Scroll = styled.div`
  overflow: scroll;
  height: 4.4rem;
  ${hideScrollbarX};
`;

export const Layout = styled.div`
  display: flex;
  align-items: flex-start;

  height: 4.4rem;
  cursor: pointer;
  padding-bottom: 0.8rem;
`;
export const ItemWrapper = styled.div`
  height: 100%;
`;

export const Item = styled.button<{ isFocused: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1;

  color: var(--Color-Common-Text-Primary, #2a2f37);
  cursor: pointer;
  border-radius: 0.4rem;
  border: 1px solid transparent;
  white-space: nowrap;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  padding: 0 1.2rem;
  outline: none;

  ${({ isFocused }) =>
    isFocused &&
    css`
      opacity: 1;
      background: var(--Color-Common-Bg-Interactive-Primary-Subtle, #e6f6ff);
      color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
    `}
`;
