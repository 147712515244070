import React from "react";

import * as S from "./KeyCommentContainer.style";
import {
  IconKeyCommentProfile,
  IconKeyCommentRectangle,
  IconQuotes,
} from "../../../shared/Icons";

interface Props {
  quote: React.ReactNode;
  spokesperson: React.ReactNode;
  type?: "insideTable" | "single";
}

const KeyCommentContainer = ({
  quote,
  spokesperson,
  type = "single",
}: Props) => {
  return (
    <S.KeyCommentWrapper $type={type}>
      <S.SpokespersonWrapper $type={type}>
        <S.ProfileImageWrapper $type={type}>
          <IconKeyCommentProfile />
        </S.ProfileImageWrapper>
        <S.SpokespersonText $type={type}>{spokesperson}</S.SpokespersonText>
      </S.SpokespersonWrapper>
      <S.QuoteWrapper $type={type}>
        {quote}
        <S.IconRectangleWrapper $type={type}>
          <IconKeyCommentRectangle />
        </S.IconRectangleWrapper>
        <S.IconQuotesLeftWrapper $type={type}>
          <IconQuotes />
        </S.IconQuotesLeftWrapper>
        <S.IconQuotesRightWrapper $type={type}>
          <IconQuotes />
        </S.IconQuotesRightWrapper>
      </S.QuoteWrapper>
    </S.KeyCommentWrapper>
  );
};

export default KeyCommentContainer;
