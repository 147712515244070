import { AIDropdownListFormat } from "../components/TableCreatorListBox/types/tableCreatorDropdown.types";

export const changeFormatAiDropdownList: (
  dropdown: string[],
  shellKey: string | null,
) => AIDropdownListFormat[] = (dropdown, shellKey) => {
  if (!dropdown.length) return [];

  return [
    {
      key: "AI",
      shellKey,
      list: dropdown,
    },
  ];
};
