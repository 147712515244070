import React from "react";

const IconDefaultCompanyImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
    >
      <rect
        opacity="0.1"
        x="9.66699"
        y="4.5"
        width="18"
        height="27"
        fill="#0198E9"
      />
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66699 3.75C9.25278 3.75 8.91699 4.08579 8.91699 4.5V30.75H5.16699C4.75278 30.75 4.41699 31.0858 4.41699 31.5C4.41699 31.9142 4.75278 32.25 5.16699 32.25H9.66699H15.667H21.667H27.667H32.167C32.5812 32.25 32.917 31.9142 32.917 31.5C32.917 31.0858 32.5812 30.75 32.167 30.75H28.417V4.5C28.417 4.08579 28.0812 3.75 27.667 3.75H9.66699ZM26.917 30.75V5.25H10.417V30.75H14.917V25.5C14.917 25.0858 15.2528 24.75 15.667 24.75H21.667C22.0812 24.75 22.417 25.0858 22.417 25.5V30.75H26.917ZM20.917 30.75V26.25H16.417V30.75H20.917ZM14.167 8.25C14.5812 8.25 14.917 8.58579 14.917 9V12.75C14.917 13.1642 14.5812 13.5 14.167 13.5C13.7528 13.5 13.417 13.1642 13.417 12.75V9C13.417 8.58579 13.7528 8.25 14.167 8.25ZM19.417 9C19.417 8.58579 19.0812 8.25 18.667 8.25C18.2528 8.25 17.917 8.58579 17.917 9V12.75C17.917 13.1642 18.2528 13.5 18.667 13.5C19.0812 13.5 19.417 13.1642 19.417 12.75V9ZM23.167 8.25C23.5812 8.25 23.917 8.58579 23.917 9V12.75C23.917 13.1642 23.5812 13.5 23.167 13.5C22.7528 13.5 22.417 13.1642 22.417 12.75V9C22.417 8.58579 22.7528 8.25 23.167 8.25ZM14.917 17.25C14.917 16.8358 14.5812 16.5 14.167 16.5C13.7528 16.5 13.417 16.8358 13.417 17.25V21C13.417 21.4142 13.7528 21.75 14.167 21.75C14.5812 21.75 14.917 21.4142 14.917 21V17.25ZM18.667 16.5C19.0812 16.5 19.417 16.8358 19.417 17.25V21C19.417 21.4142 19.0812 21.75 18.667 21.75C18.2528 21.75 17.917 21.4142 17.917 21V17.25C17.917 16.8358 18.2528 16.5 18.667 16.5ZM23.917 17.25C23.917 16.8358 23.5812 16.5 23.167 16.5C22.7528 16.5 22.417 16.8358 22.417 17.25V21C22.417 21.4142 22.7528 21.75 23.167 21.75C23.5812 21.75 23.917 21.4142 23.917 21V17.25Z"
        fill="#0198E9"
      />
    </svg>
  );
};

IconDefaultCompanyImage.displayName = "DefaultCompanyImage";

export default IconDefaultCompanyImage;
