export const MODALITY_ENTITY = "E031";
export const TARGET_ENTITY = "E020";
export const DRUG_NAME_ENTITY = "E010";
export const PHASE_ENTITY = "E052";
export const DISEASE_ENTITY = "E040";
export const ENDPOINTS_ENTITY = "E060";
export const DESIGN_ENTITY = "E051";
export const ROA_ENTITY = "E032";

export const DEAL_TYPE_ENTITY = "E060";
