import { apiClient } from "@api/apiClient";

import {
  UpdatePressInfoRequest,
  UpdatePressInfoResponse,
} from "./pressInfo.interface";

const API_ENDPOINT = {
  put: "/des/press/update",
};

export const draftPressInfoApis = {
  put: async (updatePressInfo: UpdatePressInfoRequest) => {
    const { data } = await apiClient.put<UpdatePressInfoResponse>(
      API_ENDPOINT.put,
      updatePressInfo,
    );

    return data;
  },
};
