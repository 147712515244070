import React, { ForwardedRef, forwardRef } from "react";

import DBFilterSearchInput from "./DBFilterSearchInput/DBFilterSearchInput";
import * as S from "./DBFilterSearchInputContainer.styles";
import { IconSearch } from "../../../shared/Icons";

interface Props {
  onKeyUpHandler?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleClickSearchButton?: () => void;
  onFocusHandler: (state: boolean) => void;
  onBlurHandler?: (state: boolean) => void;
  onChangeHandler?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}

const DBFilterSearchInputContainer = forwardRef<HTMLInputElement, Props>(
  (
    {
      onKeyUpHandler,
      handleClickSearchButton,
      onFocusHandler,
      onChangeHandler,
      onBlurHandler,
      placeholder,
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <S.SearchBarWrapper>
        <DBFilterSearchInput
          ref={ref}
          onKeyUpHandler={onKeyUpHandler}
          placeholder={placeholder}
          onFocusHandler={onFocusHandler}
          onBlurHandler={onBlurHandler}
          onChangeHandler={onChangeHandler}
        />
        <S.SearchButton type="button" onClick={handleClickSearchButton}>
          <IconSearch />
        </S.SearchButton>
      </S.SearchBarWrapper>
    );
  },
);

export default DBFilterSearchInputContainer;
