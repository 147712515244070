import {
  ParagraphSectionLayout,
  ParagraphTitle,
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
  ARTICLE_CONTENTS_ROUND_AND_STATUS_PARAGRAPH_TITLE as PARAGRAPH_TITLE,
  ARTICLE_CONTENTS_ROUND_AND_STATUS_PARAGRAPH_GROUP_KEY as PARAGRAPH_GROUP_KEY,
  ARTICLE_CONTENTS_ROUND_AND_STATUS_SHELL_KEYS as SHELL_KEYS,
  ARTICLE_CONTENTS_ROUND_AND_STATUS_SHELL_TITLE as SHELL_TITLE,
  ARTICLE_CONTENTS_ROUND_AND_STATUS_PARAGRAPH_CODE as PARAGRAPH_CODE,
  ARTICLE_CONTENTS_ROUND_AND_STATUS_SECTION_CODE as SECTION_CODE,
} from "@frontend/common";

import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import { useUpdateParagraph } from "@EditArticleContainer/shared/hooks/useUpdateParagraph";
import {
  REF_CODE_VENTURE_CAPITAL_ROUND_NAME,
  REF_CODE_VENTURE_CAPITAL_ROUND_TYPE,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

import * as S from "./VentureCapitalParagraphRoundAndStatus.styles";

const COMMON_EDITABLE_TEXT_STYLE = {
  padding: "0.8rem",
  borderRadius: "0",
};
const VALUE_SHELL_PADDING = "0.2rem";

const VentureCapitalParagraphRoundAndStatus = () => {
  const paragraph = useParagraphEditedData(PARAGRAPH_CODE);
  const [updateParagraph, loadingIndex, updateKeyMap] = useUpdateParagraph({
    paragraphCode: PARAGRAPH_CODE,
    sectionCode: SECTION_CODE,
  });

  const roundNameText = createMultiLineText({
    textList: paragraph.text[PARAGRAPH_GROUP_KEY][
      SHELL_KEYS.roundName
    ].value.map((shell) => shell.text ?? ""),
    parseString: (text) => `- ${text?.trim()}`,
  });
  const roundTypeText = createMultiLineText({
    textList: paragraph.text[PARAGRAPH_GROUP_KEY][
      SHELL_KEYS.roundType
    ].value.map((shell) => shell.text ?? ""),
    parseString: (text) => `- ${text?.trim()}`,
  });

  const isEmptyValue = [roundNameText, roundTypeText].every(
    (text) => text.trim() === "",
  );

  if (isEmptyValue) {
    return null;
  }

  return (
    <ParagraphSectionLayout>
      <ParagraphTitle title={PARAGRAPH_TITLE} />
      <S.ParagraphAdditionalInformationWrapper key={updateKeyMap.get(0)}>
        <S.ParagraphAdditionalInformationUpdateButton
          onClick={() => updateParagraph(0)}
          disabled={loadingIndex === 0}
        />
        <TableContainer>
          <TableRowContainer>
            <TableShellTitle>{SHELL_TITLE.roundName}</TableShellTitle>
            <TableShellTitle>{SHELL_TITLE.roundType}</TableShellTitle>
          </TableRowContainer>
          <TableRowContainer>
            <TableShellValue padding={VALUE_SHELL_PADDING}>
              <EditableTextContainer
                refKey={REF_CODE_VENTURE_CAPITAL_ROUND_NAME}
                item={{
                  text: roundNameText,
                  labels: [],
                }}
                canTextEdit
                style={COMMON_EDITABLE_TEXT_STYLE}
              />
            </TableShellValue>
            <TableShellValue padding={VALUE_SHELL_PADDING}>
              <EditableTextContainer
                refKey={REF_CODE_VENTURE_CAPITAL_ROUND_TYPE}
                item={{
                  text: roundTypeText,
                  labels: [],
                }}
                canTextEdit
                style={COMMON_EDITABLE_TEXT_STYLE}
              />
            </TableShellValue>
          </TableRowContainer>
        </TableContainer>
      </S.ParagraphAdditionalInformationWrapper>
    </ParagraphSectionLayout>
  );
};

export default VentureCapitalParagraphRoundAndStatus;
