import { useCallback } from "react";

import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { PositionInfoProps } from "../ClinicalTrialsTableCreatorShell";
import type { ArticleEntryData } from "@/types/articleEntryData.types";
import type {
  ClinicalTrialsKeyCommentShellKey,
  KeyComment,
} from "@/types/clinicalTrials.types";

interface UseHandleTableCreatorShellReturn {
  handleClickToClearKeyCommentShell: () => void;
  handleKeyDownToClearKeyCommentShell: (event: KeyboardEvent) => void;
}

export const useHandleClinicalTrialsKeyCommentShell = (
  positionPropsInfo: PositionInfoProps,
): UseHandleTableCreatorShellReturn => {
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const handleClearShell = useCallback(() => {
    const clearKeyCommentShell = (): ArticleEntryData => {
      return {
        ...entryData,
        "Clinical Trials": {
          ...entryData["Clinical Trials"],
          "Key Comment": {
            ...entryData["Clinical Trials"]?.["Key Comment"],
            [info.shellKey as ClinicalTrialsKeyCommentShellKey]: {
              ...entryData["Clinical Trials"]?.["Key Comment"]?.[
                info.shellKey as ClinicalTrialsKeyCommentShellKey
              ],
              value: [],
            },
          } as KeyComment,
        },
      };
    };

    if (info.groupTableKey === "Key Comment") {
      setEntryData(clearKeyCommentShell());
      changeCurrentHistory({
        entryData: clearKeyCommentShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
    }

    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  const handleClickToClearKeyCommentShell = () => {
    if (focusStatus.type === "clear") {
      handleClearShell();
    }
  };

  const handleKeyDownToClearKeyCommentShell = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearShell();
      }
    },
    [focusStatus.type, handleClearShell],
  );

  return {
    handleClickToClearKeyCommentShell,
    handleKeyDownToClearKeyCommentShell,
  };
};
