/* eslint-disable react/no-unused-prop-types */

import {
  EntryDataInvestorValueAndOptions,
  VentureCapitalGroupTable,
} from "@/types/ventureCapital.types";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { useGetInfoOfShell } from "@FillInTableContainer/shared/hooks/useGetInfoOfShell";
import { TableCreatorSubShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import { getShellPlaceholder } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getShellTitle } from "@FillInTableContainer/shared/utils/getTextFromData";

import VentureCapitalInvestorTableCreatorTextFieldContainer from "./components/VentureCapitalInvestorTableCreatorTextFieldContainer/VentureCapitalInvestorTableCreatorTextFieldContainer";
import { useHandleVentureCapitalCreatorShell } from "./hooks/useHandleVentureCapitalCreatorShell";
import * as S from "./VentureCapitalInvestorsCreatorShell.style";

export interface PositionInfoProps {
  groupTableKey: VentureCapitalGroupTable;
  groupTableIndex: number;
  groupShellKey: string | null;
  groupShellIndex: number;
  tableKey: string | null;
  tableIndex: number;
  shellKey: string;
  shellIndex: number;
  shellCount: number;
}

interface Props extends PositionInfoProps {
  shellCount: number;
  subShellList?: TableCreatorSubShellList;
  isShellOverOne: boolean;
  isSeparatedShellTitle: boolean;
  isTitleRowShell?: boolean;
  isClearGroupShell: boolean;
  isDeleteGroupShell: boolean;
  isAlertGroupShell: boolean;
}

const VentureCapitalInvestorsCreatorShell = ({
  groupTableKey,
  groupTableIndex,
  groupShellKey,
  groupShellIndex,
  tableKey,
  shellKey,
  tableIndex,
  shellIndex,
  shellCount,
  isTitleRowShell = false,
}: Props) => {
  const positionPropsInfo: PositionInfoProps = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
    shellKey,
    shellIndex,
    shellCount,
  };

  const { columnSize } = useHandleVentureCapitalCreatorShell(positionPropsInfo);

  const articleSection = useArticleSection();
  const { shellValue } = useGetInfoOfShell();

  return (
    <S.ShellWrapper column={columnSize}>
      {!isTitleRowShell && (
        <VentureCapitalInvestorTableCreatorTextFieldContainer
          placeholder={
            getShellPlaceholder(articleSection, shellKey) ||
            getShellTitle(articleSection, shellKey)
          }
          shellValue={
            shellValue(
              shellKey,
              tableIndex,
              groupShellIndex,
              shellIndex,
            ) as EntryDataInvestorValueAndOptions
          }
          shellKey={shellKey}
          shellIndex={shellIndex}
          groupTableKey={groupTableKey}
          groupTableIndex={groupTableIndex}
          groupShellKey={groupShellKey}
          groupShellIndex={groupShellIndex}
          tableKey={tableKey}
          tableIndex={tableIndex}
        />
      )}
    </S.ShellWrapper>
  );
};

export default VentureCapitalInvestorsCreatorShell;
