"use client";

import styled from "styled-components";

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const FooterContentsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
  max-width: 192rem;
  height: 100%;
  padding: var(--Spacing-12, 1.2rem) var(--Spacing-32, 3.2rem);

  @media only screen and (min-width: 1px) and (max-width: calc(990px - 0.1rem)) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const FooterContentsColumn = styled.div`
  width: 100%;

  @media only screen and (min-width: 990px) and (max-width: calc(1280px - 0.1rem)),
    only screen and (min-width: 768px) and (max-width: calc(990px - 0.1rem)) {
    width: 100%;
  }
  @media only screen and (min-width: 1px) and (max-width: 767px) {
    width: 100%;
  }
`;

export const BrandInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  padding: var(--Spacing-12, 1.2rem) 0rem;

  @media only screen and (min-width: 1px) and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2.4rem;
  }
`;
export const BrandInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.6rem;
  width: 100%;

  @media only screen and (min-width: 1px) and (max-width: 989px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const BrandSlogan = styled.p`
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1.4rem;
  font-weight: 500;
  color: ${({ theme }) => theme.color.neural.gray01};
  letter-spacing: 0.028rem;
  opacity: 0.9;

  @media only screen and (min-width: 1px) and (max-width: 989px) {
    line-height: 160%;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: #e5e7eb;
  opacity: 0.5;
`;
