import { SectionCode } from "@frontend/common";
import { useSuspenseQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/queryKeys";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { imageKeywordApis } from "./imageKeyword.service";

export const useGetImageKeywordQuery = (sectionCode: SectionCode) => {
  const pressId = getPressIdFromURL();
  const query = useSuspenseQuery({
    queryKey: [QUERY_KEYS.imageKeyword, pressId],
    queryFn: () => imageKeywordApis.get(pressId, sectionCode),
  });

  return query;
};
