"use client";

import styled, { css } from "styled-components";

export const TableWrapper = styled.div<{
  $isDisplay: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);
  overflow: hidden;

  & > div:last-child {
    border-bottom: none;
  }

  ${({ $isDisplay }) =>
    !$isDisplay &&
    css`
      display: none;
    `}
`;
