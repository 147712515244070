import { useNavigate } from "react-router-dom";

import { ToastPosition } from "@atoms/Toast/constants/toast.constants";
import { showToast } from "@atoms/Toast/Toast";
import { ROUTE_PATHS } from "@routes/routePath";

export const useToastDBSaved = () => {
  const navigate = useNavigate();

  return (pressId: string) => {
    showToast({
      text: "DB Save completed!",
      backgroundColor: "sky",
      position: "bottomLeft",
      icon: "save",
      customButton: [
        {
          name: "View",
          action: () => {
            navigate({
              pathname: ROUTE_PATHS.fillInTable,
              search: `?id=${pressId}`,
            });
          },
        },
      ],
    });
  };
};

export const useToastAiEntryCompleted = () => {
  const navigate = useNavigate();
  let destination: string = ROUTE_PATHS.insertImage;

  if (window.location.pathname === ROUTE_PATHS.editArticle)
    destination = ROUTE_PATHS.editArticle;

  return (pressId: number) => {
    showToast({
      backgroundColor: "green",
      text: "AI Entry completed!",
      icon: "edit",
      position: "bottomLeft",
      customButton: [
        {
          name: "View",
          action: () =>
            navigate({
              pathname: destination,
              search: `?id=${pressId}`,
            }),
        },
      ],
    });
  };
};

export const toastArticlePublish = (urlTitle: string) => {
  showToast({
    text: "Article Publish completed!",
    backgroundColor: "blue",
    position: "bottomLeft",
    icon: "edit",
    customButton: [
      {
        name: "View",
        action: () => {
          window.open(`${import.meta.env.VITE_PUBLISH_URL}/${urlTitle}`);
        },
      },
    ],
  });
};

export const toastDraftSave = () => {
  showToast({
    text: "Draft Save completed!",
    backgroundColor: "blueGreen",
    icon: "save",
    position: "bottomLeft",
  });
};

export const toastSetDBSaved = () => {
  showToast({
    text: "Set Status As DB Save completed!",
    backgroundColor: "blueGreen",
    icon: "save",
    position: "bottomLeft",
  });
};

export const toastFailUpdatedEditor = () => {
  showToast({
    text: "Fail to update editor!",
    backgroundColor: "red",
    icon: "error",
    position: "bottomLeft",
  });
};

export const toastFail = (
  message = "Unknown Error!",
  position: ToastPosition = "bottomLeft",
) => {
  showToast({
    text: message,
    backgroundColor: "red",
    icon: "error",
    position,
  });
};

export const toastSuccess = (
  message = "Success!",
  backgroundColor: "green" | "blueGreen" = "green",
  position: ToastPosition = "bottomLeft",
) => {
  showToast({
    text: message,
    backgroundColor,
    icon: "edit",
    position,
  });
};

export const toastWaitForAI = (count: number, total: number) => {
  showToast({
    text: `Waiting for AI... (${count} / ${total})`,
    backgroundColor: "orange",
    icon: "edit",
    autoClose: 2000,
    position: "bottomLeft",
  });
};

export const toastFailedCreateAiImage = (
  draftArticleId: number,
  moveToViewImage: () => void,
  retryCreateAiImage: () => void,
) => {
  showToast({
    text: `Image generation failed\n(PRESS ID: #${draftArticleId})`,
    backgroundColor: "hotPink",
    icon: "alertTriangle",
    position: "bottomLeft",
    customButton: [
      {
        name: "View",
        action: moveToViewImage,
      },
      {
        name: "Retry",
        action: retryCreateAiImage,
      },
    ],
    autoClose: 5000,
  });
};

export const toastSuccessCreateAiImage = (
  draftArticleId: number,
  moveToViewImage: () => void,
) => {
  showToast({
    text: `Image generation complete\n(PRESS ID: #${draftArticleId})`,
    backgroundColor: "green",
    icon: "aiComplete",
    position: "bottomLeft",
    customButton: [
      {
        name: "View",
        action: moveToViewImage,
      },
    ],
    autoClose: 5000,
  });
};

export const toastCreateAiImageSuccess = () => {
  showToast({
    text: "Start generating AI images.",
    backgroundColor: "green",
    icon: "aiComplete",
    position: "bottomLeft",
  });
};

export const toastCreateAiImageFailed = () => {
  showToast({
    text: "Failed to generate AI images.",
    backgroundColor: "hotPink",
    icon: "alertTriangle",
    position: "bottomLeft",
  });
};

export const toastSuccessCopyPress = (
  draftArticleId: number,
  moveToPage: () => void,
) => {
  showToast({
    text: `Press Duplication Complete\nPRESS ID: #${draftArticleId}`,
    backgroundColor: "green",
    icon: "copy",
    position: "bottomLeft",
    customButton: [
      {
        name: "View",
        action: moveToPage,
      },
    ],
    autoClose: 5000,
  });
};

export const toastFailCopyPress = () => {
  showToast({
    text: `Press Duplication Failed`,
    backgroundColor: "hotPink",
    icon: "alertTriangle",
    position: "bottomLeft",
    autoClose: 5000,
  });
};
