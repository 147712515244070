import React, { useCallback, useEffect } from "react";

import { CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS } from "@frontend/common";

import useArticleSection from "@/components/business/FillInTableContainer/hooks/useArticleSection";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import {
  DEFAULT_FOCUS_STATUS,
  FocusStatus,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { ShellColumnSize } from "@FillInTableContainer/shared/types/shellSize.types";
import { getShellColumnSize } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useAddClinicalTrialsDesign2Shell } from "./useAddClinicalTrialsDesign2Shell";
import { useClearClinicalTrialsDesign1Shell } from "./useClearClinicalTrialsDesign1Shell";
import { useClearClinicalTrialsDesign2Shell } from "./useClearClinicalTrialsDesign2Shell";
import { useDeleteClinicalTrialsDesign2Shell } from "./useDeleteClinicalTrialsDesign2Shell";

import type { PositionInfoProps } from "../ClinicalTrialDesignShell";

interface UseHandleTableCreatorShellReturn {
  handleFocusTextFieldWrapper: (event: React.MouseEvent) => void;
  clickForClearShell: () => void;
  handleFocusOutShell: () => void;
  handleAddShell: (groupShellInfoParams: {
    tableIndexParameter: number;
    groupShellIndexParameter: number;
    shellIndexParameter: number;
    shellKeyParameter: string;
    subGroupShellIndexParameter: number;
  }) => void;
  isFocused: boolean;
  isFocusedColumn: boolean;
  isClearShell: boolean;
  isDeleteShell: boolean;
  isDeleteColumn: boolean;
  isEnableEdit: boolean;
  isAlertShell: boolean;
  isLastShell: boolean;
  focusStatus: FocusStatus;
  columnSize: ShellColumnSize;
  isFocusedAddButton: boolean;
  isClearTable: boolean;
  isDeleteTable: boolean;
}

export const useHandleClinicalTrialsTableCreatorShell = (
  positionPropsInfo: PositionInfoProps,
): UseHandleTableCreatorShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    subGroupShellIndex,
    subGroupShellKey,
    tableKey,
    tableIndex,
    shellKey,
    shellIndex,
    shellCount,
  } = positionPropsInfo;

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { validatedInfo, changeValidatedInfo } = useValidatedInTableCreator();

  const { clearClinicalDesign1Shell } =
    useClearClinicalTrialsDesign1Shell(positionPropsInfo);

  const { addDesignTwoShell } =
    useAddClinicalTrialsDesign2Shell(positionPropsInfo);

  const { clearClinicalDesign2Shell } =
    useClearClinicalTrialsDesign2Shell(positionPropsInfo);

  const { deleteClinicalDesign2Shell } =
    useDeleteClinicalTrialsDesign2Shell(positionPropsInfo);

  const articleSection = useArticleSection();

  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );
  const isEnableEdit = articleInfo
    ? ENABLE_GROUP_TABLE_KEY_LIST["Clinical Trials"][groupTableKey].includes(
        articleInfo.draftArticleStatus,
      )
    : false;

  const columnSize = getShellColumnSize(articleSection, shellKey);
  const isLastShell = shellCount === shellIndex + 1;

  const isFocusedColumn =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableIndex === tableIndex &&
    info.tableKey === tableKey &&
    info.groupShellKey === groupShellKey &&
    info.shellKey ===
      (shellKey === "Endpoint Group Information" ? "Group Name" : shellKey) &&
    info.shellIndex === shellIndex &&
    info.subGroupShellIndex === null &&
    info.subGroupShellKey === null &&
    info.valueIndex === null &&
    focusStatus.container === "column" &&
    isEnableEdit;

  const isFocused =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableIndex === tableIndex &&
    info.tableKey === tableKey &&
    info.groupShellKey === groupShellKey &&
    info.groupShellIndex === groupShellIndex &&
    info.subGroupShellIndex === subGroupShellIndex &&
    info.subGroupShellKey === subGroupShellKey &&
    info.shellKey === shellKey &&
    info.shellIndex === shellIndex &&
    isEnableEdit;

  const isTableFocus =
    info.groupTableKey === groupTableKey &&
    info.tableKey === tableKey &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === null &&
    info.groupShellIndex === null &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    isEnableEdit;

  const isClearTable =
    isTableFocus &&
    focusStatus.type === "clear" &&
    focusStatus.container === "table";

  const isDeleteTable =
    isTableFocus &&
    focusStatus.type === "delete" &&
    focusStatus.container === "table";

  const isClearShell =
    isFocused &&
    focusStatus.type === "clear" &&
    focusStatus.container === "shell";

  const isDeleteShell =
    isFocused &&
    focusStatus.type === "delete" &&
    focusStatus.container === "shell";

  const isDeleteColumn =
    isFocusedColumn &&
    focusStatus.type === "delete" &&
    focusStatus.container === "column";

  const isFocusedAddButton =
    focusStatus.container === "noFocus" && isEnableEdit;

  const isAlertShell =
    validatedInfo.filter(
      (validatedItem) =>
        validatedItem.groupTableKey === groupTableKey &&
        validatedItem.groupTableIndex === groupTableIndex &&
        validatedItem.tableIndex === tableIndex &&
        validatedItem.groupShellKey === groupShellKey &&
        validatedItem.groupShellIndex === groupShellIndex &&
        validatedItem.subGroupShellIndex === subGroupShellIndex &&
        validatedItem.subGroupShellKey === subGroupShellKey &&
        validatedItem.shellKey === shellKey &&
        validatedItem.shellIndex === shellIndex &&
        validatedItem.valueIndex === null &&
        validatedItem.type === "alert" &&
        validatedItem.container === "shell",
    ).length > 0;

  const handleFocusTextFieldWrapper = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (
      focusStatus.type !== "clear" ||
      (focusStatus.type === "clear" && focusStatus.container !== "shell")
    ) {
      changeFocusStatus({
        type: "clear",
        container: "shell",
      });
    }

    changeInfo({
      ...positionPropsInfo,
      valueIndex: null,
    });

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleFocusOutShell = () => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  };

  const handleAddShell = (groupShellInfoParams: {
    tableIndexParameter: number;
    groupShellIndexParameter: number;
    shellIndexParameter: number;
    shellKeyParameter: string;
    subGroupShellIndexParameter: number;
  }) => {
    if (focusStatus.container !== "noFocus") return;

    if (
      groupShellKey === CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign2
    )
      addDesignTwoShell(groupShellInfoParams);
  };

  const clickForClearShell = useCallback(() => {
    if (focusStatus.type === "clear") {
      if (
        info.groupShellKey ===
        CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign1
      )
        clearClinicalDesign1Shell();

      if (
        info.groupShellKey ===
        CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign2
      )
        clearClinicalDesign2Shell();
    }

    if (focusStatus.type === "delete")
      if (
        info.groupShellKey ===
        CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign2
      )
        deleteClinicalDesign2Shell();
  }, [
    clearClinicalDesign1Shell,
    clearClinicalDesign2Shell,
    deleteClinicalDesign2Shell,
    focusStatus.type,
    info.groupShellKey,
  ]);

  const handleKeyDownToDeleteShell = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== "Delete") return;

      clickForClearShell();
    },
    [clickForClearShell],
  );

  useEffect(() => {
    if (focusStatus.container !== "shell") return;
    window.addEventListener("keydown", handleKeyDownToDeleteShell);

    return () => {
      window.removeEventListener("keydown", handleKeyDownToDeleteShell);
    };
  }, [focusStatus, handleKeyDownToDeleteShell]);

  return {
    handleFocusOutShell,
    handleFocusTextFieldWrapper,
    clickForClearShell,
    handleAddShell,
    isClearTable,
    isDeleteTable,
    isFocused,
    isFocusedColumn,
    isClearShell,
    isDeleteShell,
    isDeleteColumn,
    isEnableEdit,
    focusStatus,
    isAlertShell,
    isLastShell,
    columnSize,
    isFocusedAddButton,
  };
};
