"use client";

import styled, { css } from "styled-components";

export const TableWrapper = styled.div<{ $isEditMode?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .company-profile-shell {
    font-family: var(--font-pt-serif);
  }

  ${({ $isEditMode }) =>
    $isEditMode &&
    css`
      cursor: not-allowed;
    `}
`;

export const CompanyNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: var(--font-pretendard);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;

  .disable-link-true {
    cursor: default;
  }
`;

export const CompanyLink = styled.a<{ $isDisabled: boolean }>`
  display: flex;
  align-items: start;
  flex-direction: row;
  gap: 0.8rem;
  text-decoration: none;
  cursor: pointer;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: var(--font-pretendard);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;

  & > p {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding-left: 0.4rem;
    }
  }

  ${({ $isDisabled }) =>
    $isDisabled === false &&
    css`
      &:hover {
        color: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);

        path {
          fill: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);
        }
      }

      &:active {
        color: var(--Color-Common-Text-Interactive-Primary-Pressed, #015c80);

        path {
          fill: var(--Color-Common-Text-Interactive-Primary-Pressed, #015c80);
        }
      }
    `}
`;

export const TickerValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 0.4rem;

  &.is-bullet-list-true {
    a {
      position: relative;
      padding-left: 1.6rem;
    }
    a::before {
      position: absolute;
      top: -0rem;
      left: 0rem;
      width: 0.4rem;

      content: "・";
      color: var(--Color-Common-Text-Primary, #2a2f37);
    }
  }
`;

export const TickerValueLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.6rem;

  svg {
    margin-bottom: 0.2rem;
  }

  /* &:visited {
    div {
      color: var(--Color-Common-Text-Interactive-Visited, #017abc);
    }
    path {
      fill: var(--Color-Common-Text-Interactive-Visited, #017abc);
    }
  } */

  &:hover {
    div {
      color: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);
    }
    path {
      fill: var(--Color-Common-Text-Interactive-Primary-Hovered, #017abc);
    }
  }

  &:active {
    div {
      color: var(--Color-Common-Text-Interactive-Primary-Pressed, #015c80);
    }
    path {
      fill: var(--Color-Common-Text-Interactive-Primary-Pressed, #015c80);
    }
  }
`;

export const TickerText = styled.div`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: var(--font-pt-serif);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
`;

export const CountryValueWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.6rem;
`;

export const CountryFlagWrapper = styled.div`
  padding-top: 0.1rem;
`;

export const CountryText = styled.div`
  flex: 1;
  min-height: 2.8rem;
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: var(--font-pt-serif);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
`;

export const BulletListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
  padding-left: 1.2rem;
`;

export const BulletItem = styled.li`
  width: 100%;
  list-style-type: "・ ";
  list-style-position: outside;
  font-family: var(--font-pt-serif);
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 150%;
  text-decoration: inherit;
  text-align: inherit;
  padding-left: 0.1rem;

  &::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
  }
`;
