import React from "react";

type Props = {
  width?: number;
  height?: number;
  color?: string;
  svgDataId?: string;
};

const IconChevronUp = ({
  width = 24,
  height = 24,
  color = "black",
  svgDataId = "button",
}: Props) => {
  return (
    <svg
      data-button="chevron-up"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        data-button={svgDataId}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5581 9.05806C11.8021 8.81398 12.1979 8.81398 12.4419 9.05806L17.4419 14.0581C17.686 14.3021 17.686 14.6979 17.4419 14.9419C17.1979 15.186 16.8021 15.186 16.5581 14.9419L12 10.3839L7.44194 14.9419C7.19786 15.186 6.80214 15.186 6.55806 14.9419C6.31398 14.6979 6.31398 14.3021 6.55806 14.0581L11.5581 9.05806Z"
        fill={color}
      />
    </svg>
  );
};

IconChevronUp.displayName = "ChevronUp";
export default IconChevronUp;
