import React, { createContext, useContext, useMemo, useState } from "react";

import {
  HistoryAiImage,
  HistorySelectImage,
  HistoryUploadImage,
} from "../types/insertImage.types";

export type InsertImageHistoryInfo = (
  | HistorySelectImage
  | HistoryUploadImage
  | HistoryAiImage
)[];

export const DEFAULT_IMAGE_HISTORY_INFO: InsertImageHistoryInfo = [];

export const InsertImageHistoryInfoContext = createContext<{
  insertImageHistoryInfo: InsertImageHistoryInfo;
  changeInsertImageHistoryInfo: (
    updatedHistoryInfo: InsertImageHistoryInfo,
  ) => void;
}>({
  insertImageHistoryInfo: DEFAULT_IMAGE_HISTORY_INFO,
  changeInsertImageHistoryInfo: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const InsertImageHistoryInfoProvider = ({ children }: Props) => {
  const [insertImageHistoryInfo, setInsertImageHistoryInfo] =
    useState<InsertImageHistoryInfo>(DEFAULT_IMAGE_HISTORY_INFO);

  const changeInsertImageHistoryInfo = (
    updatedHistoryInfo: InsertImageHistoryInfo,
  ): void => {
    setInsertImageHistoryInfo(updatedHistoryInfo);
  };

  const insertImageHistoryInfoWrapper = useMemo(
    () => ({ insertImageHistoryInfo, changeInsertImageHistoryInfo }),
    [insertImageHistoryInfo],
  );
  return (
    <InsertImageHistoryInfoContext.Provider
      value={insertImageHistoryInfoWrapper}
    >
      {children}
    </InsertImageHistoryInfoContext.Provider>
  );
};

export const useInsertImageHistoryInfo = () => {
  const { insertImageHistoryInfo, changeInsertImageHistoryInfo } = useContext(
    InsertImageHistoryInfoContext,
  );

  return { insertImageHistoryInfo, changeInsertImageHistoryInfo };
};
