import React from "react";

import {
  ParagraphSectionLayout,
  ParagraphTitle,
  ARTICLE_CONTENTS_INVESTORS_PARAGRAPH_TITLE as PARAGRAPH_TITLE,
  ARTICLE_CONTENTS_INVESTORS_PARAGRAPH_GROUP_KEY as PARAGRAPH_GROUP_KEY,
  ARTICLE_CONTENTS_INVESTORS_SHELL_KEYS as SHELL_KEYS,
  ARTICLE_CONTENTS_INVESTORS_PARAGRAPH_CODE as PARAGRAPH_CODE,
  ARTICLE_CONTENTS_INVESTORS_SECTION_CODE as SECTION_CODE,
  CompanyImage,
} from "@frontend/common";

import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { useCreateCompanyLogoComponentList } from "@EditArticleContainer/shared/hooks/useCreateCompanyLogoComponentList";
import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import { useUpdateParagraph } from "@EditArticleContainer/shared/hooks/useUpdateParagraph";
import { REF_CODE_VENTURE_CAPITAL_INVESTOR_NAME } from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";

import * as S from "./VentureCapitalParagraphInvestors.styles";

const VentureCapitalParagraphInvestors = () => {
  const paragraph = useParagraphEditedData(PARAGRAPH_CODE);
  const [updateParagraph, loadingIndex, updateKeyMap] = useUpdateParagraph({
    paragraphCode: PARAGRAPH_CODE,
    sectionCode: SECTION_CODE,
  });
  const { findTargetCompanyLogo, checkTargetGroupIsPerson } =
    useCreateCompanyLogoComponentList();

  const investorNameText = paragraph.text[PARAGRAPH_GROUP_KEY][
    SHELL_KEYS.investorName
  ].value
    .map((shell) => {
      const text = shell.text ?? "";
      const isLeading = shell.is_lead_investor ?? false;
      return `${text}${isLeading ? "(LEAD)" : ""}`;
    })
    .filter((text) => text !== "")
    .join("__");

  if (investorNameText === "") {
    return null;
  }

  return (
    <ParagraphSectionLayout>
      <ParagraphTitle title={PARAGRAPH_TITLE} />
      <S.ParagraphAdditionalInformationWrapper key={updateKeyMap.get(0)}>
        <S.ParagraphAdditionalInformationUpdateButton
          onClick={() => updateParagraph(0)}
          disabled={loadingIndex === 0}
        />
        <S.InvestorsWrapper>
          {investorNameText.split("__").map((text) => {
            const isLead = text.includes("(LEAD)");
            const name = text.replaceAll("(LEAD)", "");
            const logo = findTargetCompanyLogo(name);
            const isPerson = checkTargetGroupIsPerson(name);

            return (
              <S.InvestorItem key={name}>
                <S.InvestorLogoWrapper>
                  <CompanyImage
                    imageSize={40}
                    containerSize={40}
                    borderRadius="0.4rem"
                    logoUrl={logo}
                    className="investor-logo"
                    isPerson={isPerson}
                  />
                </S.InvestorLogoWrapper>
                <S.Divider />
                <S.InvestorNameWrapper>
                  {isLead && (
                    <S.InvestorLeadLabel>Lead Investor</S.InvestorLeadLabel>
                  )}
                  <S.InvestorNameText>{name}</S.InvestorNameText>
                </S.InvestorNameWrapper>
              </S.InvestorItem>
            );
          })}
        </S.InvestorsWrapper>

        <EditableTextContainer
          refKey={REF_CODE_VENTURE_CAPITAL_INVESTOR_NAME}
          item={{
            text: investorNameText,
            labels: [],
          }}
          canTextEdit
          style={{
            display: "none",
          }}
        />
      </S.ParagraphAdditionalInformationWrapper>
    </ParagraphSectionLayout>
  );
};

export default VentureCapitalParagraphInvestors;
