"use client";

import styled, { css } from "styled-components";

type ChipStyleProps = {
  $chipStatus: "up" | "keep" | "down";
  $size: "md" | "lg";
};

// TODO: 색상 common 으로 설정되어 있는지 확인하고 추가하기
const ChipStyle = {
  up: {
    background: "var(--Color-Common-Bg-Progress, #11ac45)",
    color: "var(--Color-Common-Text-Interactive-Inverse, #fff)",
  },
  keep: {
    background: "var(--Color-Common-Bg-Tertiary, #CBD0D8)",
    color: "var(--Color-Common-Text-Secondary, #515C6C)",
  },
  down: {
    background: "var(--Color-Common-Bg-Danger, #DB0F1D)",
    color: "var(--Color-Common-Text-Interactive-Inverse, #fff)",
  },
};

export const ChipWrapper = styled.div`
  position: relative;
  &:hover {
    .market-tooltip {
      display: block;
    }
  }
`;

export const StatusChip = styled.div<
  ChipStyleProps & { $isInteractive?: boolean }
>`
  display: flex;
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-8, 0.8rem);
  gap: var(--Spacing-2, 0.2rem);
  cursor: default;
  width: fit-content;

  border-radius: var(--Border-Radius-4, 0.4rem);
  background: ${({ $chipStatus }) => ChipStyle[$chipStatus].background};

  ${({ $isInteractive }) =>
    $isInteractive &&
    css`
      cursor: pointer;

      &:hover {
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16),
          0px 0px 4px 0px rgba(0, 0, 0, 0.12);
      }
    `}
`;

export const StockIconWrapper = styled.div`
  width: 1.6rem;
  height: 1.6rem;
`;

export const ChipText = styled.div<
  ChipStyleProps & { $isMobileTextColumn?: boolean }
>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  color: ${({ $chipStatus }) => ChipStyle[$chipStatus].color};
  font-size: ${({ $size }) => ($size === "md" ? "1.2rem" : "1.6rem")};
  font-weight: 500;
  text-align: right;
  line-height: 130%;
  user-select: none;

  @media (min-width: 990px) and (max-width: 1400px) {
    ${({ $isMobileTextColumn }) =>
      $isMobileTextColumn &&
      css`
        flex-direction: column;
        align-items: flex-end;
      `}
  }
`;

export const StockText = styled.div`
  white-space: nowrap;
`;

export const PreMarketTextWrapper = styled.div`
  white-space: nowrap;
`;
