"use client";

import styled, { css } from "styled-components";

export const ShowMoreButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2rem;
`;

export const ShowMoreButton = styled.button<{ $isMore: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: fit-content;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  cursor: pointer;

  ${({ $isMore }) =>
    $isMore &&
    css`
      svg {
        transform: scaleY(-1);
      }
    `}
`;
