import { DealsSection } from "@/types/deals.types";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import {
  FocusStatus,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  PositionInfo,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { TableCreatorGroupShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import { getPressIdFromURL } from "@utils/getStateFromURL";

interface Props {
  groupTableKey: DealsSection;
  tableKey: string | null;
  groupShellList: TableCreatorGroupShellList;
  groupTableIndex: number;
  tableIndex: number;
}

interface UseHandleTableCreatorTableReturn {
  info: PositionInfo;
  focusStatus: FocusStatus;
  isFocused: boolean;
}

export const useHandleDealTypeTableCreatorTable = (
  tablePropsInfo: Props,
): UseHandleTableCreatorTableReturn => {
  const { groupTableKey, tableKey, tableIndex } = tablePropsInfo;

  const articleSection = "Deals";

  const { info } = useTableCreatorPositionInfo();
  const { focusStatus } = useTableCreatorFocusStatus();

  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );

  const isEnableEdit = articleInfo
    ? ENABLE_GROUP_TABLE_KEY_LIST[articleSection][groupTableKey].includes(
        articleInfo.draftArticleStatus,
      )
    : false;

  const isFocused =
    info.groupTableKey === groupTableKey &&
    info.tableKey === tableKey &&
    info.tableIndex === tableIndex &&
    isEnableEdit;

  return {
    info,
    focusStatus,
    isFocused,
  };
};
