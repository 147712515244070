import React from "react";

import * as S from "./TooltipBase.style";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  padding?: string;
}

const TooltipBase = ({
  children,
  padding = "var(--Spacing-4, 0.4rem) var(--Spacing-8, 0.8rem) var(--Spacing-4, 0.4rem) var(--Spacing-4, 0.4rem)",
  ...props
}: Props) => {
  return (
    <S.Tooltip {...props} $padding={padding}>
      {children}
    </S.Tooltip>
  );
};

export default TooltipBase;
