import { apiClient } from "@api/apiClient";

import type { ArticlePublishResponse } from "@interface/articlePublish.interface";

const API_ENDPOINT = {
  articlePublish: "/des/draft-article/publish",
};

export const articlePublishApis = {
  post: async (id: string, articleSection: string) => {
    const { data } = await apiClient.post<ArticlePublishResponse>(
      API_ENDPOINT.articlePublish,
      {
        id: Number(id),
        articleSection,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );
    return data;
  },
};
