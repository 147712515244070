import {
  MainLabelColorType,
  MainLabelEntityTypes,
  MainLabelFormatStatus,
  MainLabelFormatTypes,
} from "../types/articleMainLabel.types";

/**
 * @description
 * immutable : 수정 불가능한 라벨
 * singleEditable : 하나의 값으로 수정 가능한 라벨 (radio dropdown)
 * multipleEditable : 여러개의 값으로 수정 가능한 라벨 (checkbox dropdown)
 */

export const MAIN_LABEL_FORMAT_TYPES: Record<
  MainLabelFormatTypes,
  MainLabelFormatStatus
> = {
  MLT001: "immutable",
  MLT002: "immutable",
  MLT003: "immutable",
  MLT004: "multipleEditable",
  MLT005: "singleEditable",
  MLT101: "immutable",
};

export const MAIN_LABEL_ENTITY_TO_COLOR: Record<
  MainLabelEntityTypes,
  MainLabelColorType
> = {
  E010: "blue",
  E020: "orange",
  E030: "purple",
  E040: "pink",
  E050: "yellow",
  E060: "green",
};
