import styled from "styled-components";

export const MainLabelRadioDropDownWrapper = styled.fieldset`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.8rem;
  top: calc(100% + 0.4rem);
  left: 0;

  width: max-content;
  padding: 0.8rem 0.8rem 0.8rem 0.2rem;
  border-radius: 0.4rem;
  border: 1px solid var(--Color-Common-Border-Primary, #7c899c);
  background-color: ${({ theme }) => theme.color.neural.black};
  z-index: 3;
`;

export const RadioItemWrapper = styled.label`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.6rem;
  cursor: pointer;
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
`;

export const RadioInput = styled.input`
  all: unset;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  border: 0.1rem solid ${({ theme }) => theme.color.neural.white};

  text-align: center;
  line-height: 1rem;

  &:checked {
    &::after {
      content: "";
      display: inline-block;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.neural.white};
    }
  }
`;

export const LabelWrapper = styled.div``;

export const LabelText = styled.span`
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 145%;
`;
