import { useEffect } from "react";

import { ArticleImageSocketMessage } from "@/customEventTypes";

import { parseJSON } from "./parseJSON";

const ARTICLE_CONTENT_SOCKET_EVENT = "article-content-socket-event";

const createArticleContentSocketEvent = (message: ArticleImageSocketMessage) =>
  new CustomEvent(ARTICLE_CONTENT_SOCKET_EVENT, {
    detail: {
      ...message,
    },
  });

export const parseArticleContentSocketMessage = (message: string) =>
  parseJSON<ArticleImageSocketMessage>(message);

export const dispatchArticleContentSocketEvent = (
  message: ArticleImageSocketMessage,
) => document.dispatchEvent(createArticleContentSocketEvent(message));

export const useArticleContentSocketMessageCallback = (
  callback: (args: CustomEvent<ArticleImageSocketMessage>) => void,
) => {
  useEffect(() => {
    document.addEventListener(ARTICLE_CONTENT_SOCKET_EVENT, callback);

    return () => {
      document.removeEventListener(ARTICLE_CONTENT_SOCKET_EVENT, callback);
    };
  }, [callback]);
};
