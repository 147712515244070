import type { ClinicalTrialsSection } from "@/types/clinicalTrials.types";

export const CHECK_TABLE_KEY_BY_GROUP_TABLE_KEY: {
  "Clinical Trials": {
    [key in ClinicalTrialsSection]: string | null;
  };
} = {
  "Clinical Trials": {
    "Drug Profile": "Drug",
    "Clinical Endpoints (Table)": "Data",
    "Clinical Trial Design-1": "Clinical Design",
    "Clinical Trial Design-2": null,
    "Clinical Results": null,
    "Clinical Endpoints (Description)": null,
    "Key Comment": null,
  },
};
