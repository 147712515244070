import styled, { css } from "styled-components";

import {
  DividerWrapper,
  PlusBar,
  PlusButton,
} from "@FillInTableContainer/shared/styles/dividerPlusBar.style";

export const GroupShellDividerWrapper = styled(DividerWrapper)`
  top: -2rem;
  left: 0.8rem;
  width: calc(100% - 1.6rem);
  height: 1.6rem;

  ${({ isLastPosition }) =>
    isLastPosition &&
    css`
      top: 102%;
    `}
`;

export const GroupShellDividerPlusBar = styled(PlusBar)`
  width: 100%;
`;

export const GroupShellPlusButton = styled(PlusButton)``;
