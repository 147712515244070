import React, { forwardRef, useEffect, useRef, useState } from "react";

import * as S from "./SearchIconAbsoluteInput.style";
import {
  PlaceholderInput,
  IconSearch,
} from "../../../../../../../../../../../../../components";

type Props = {
  keyDownEnterSearchArticles: (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
};

const SearchIconAbsoluteInput = forwardRef<HTMLInputElement, Props>(
  ({ keyDownEnterSearchArticles }: Props, ref) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);

    const [isInputOpen, setIsInputOpen] = useState(false);

    const clickSearchButton = (currentOpenStatus: boolean) => {
      setIsInputOpen(currentOpenStatus);
    };

    useEffect(() => {
      const changeInputOpenStatus = (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        const isSearchButton = buttonRef.current?.contains(target);
        const isInputSection = inputRef.current?.contains(target);

        if (isSearchButton || isInputSection) return;

        setIsInputOpen(false);
      };

      window.addEventListener("click", changeInputOpenStatus);

      return () => window.removeEventListener("click", changeInputOpenStatus);
    }, []);

    return (
      <S.AbsoluteWrapper>
        <S.SearchButton
          ref={buttonRef}
          $isInputOpen={isInputOpen}
          onClick={() => clickSearchButton(!isInputOpen)}
        >
          <IconSearch width={25.6} height={25.6} color="#0198E9" />
        </S.SearchButton>
        {isInputOpen && (
          <S.InputWrapper ref={inputRef}>
            <PlaceholderInput
              ref={ref}
              placeholder="Article Search..."
              styleType="line"
              status="default"
              width="25.6rem"
              height="3.2rem"
              onKeyDown={keyDownEnterSearchArticles}
            />
          </S.InputWrapper>
        )}
      </S.AbsoluteWrapper>
    );
  },
);

export default SearchIconAbsoluteInput;
