import React from "react";

import { ClassificationList } from "@PressListContainer/classificationInfo.constants";

import { FOR_ARTICLE_CLASSIFICATION_CODE } from "../constants/classificationFilter.constants";

export const changeForArticleList = (
  event: React.ChangeEvent<HTMLInputElement>,
  list: ClassificationList[],
) => {
  const changeList = list.map((classification) => {
    if (FOR_ARTICLE_CLASSIFICATION_CODE.includes(classification.code))
      return {
        code: classification.code,
        name: classification.name,
        isCheck: event.currentTarget.checked,
      };

    return {
      code: classification.code,
      name: classification.name,
      isCheck: false,
    };
  });
  return changeList;
};
