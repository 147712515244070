import styled from "styled-components";

export const SignInFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 4rem;
`;

export const SignInInputBox = styled.div`
  display: flex;
  width: 64rem;
  padding: 4rem 4rem 4.8rem 4rem;
  flex-direction: column;
  justify-content: center;
  gap: 4rem;

  border-radius: 2rem;
  background: #fff;

  box-shadow: 0px 8px 24px 0px rgba(7, 68, 161, 0.05);
`;
