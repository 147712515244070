import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import { useGetNumberOfTable } from "./useGetNumberOfTable";
import { GroupTableKeyType } from "../types/tableCreator.types";

const useCheckIsDisplayTableDragDivider = () => {
  const { tableCount } = useGetNumberOfTable();

  const isFirstTable = (tableIndex: number | null) => tableIndex === 0;
  const isLastTable = (
    tableIndex: number | null,
    currentGroupTableKey: GroupTableKeyType,
  ) =>
    tableIndex ===
    newArrayToCountValue(tableCount(currentGroupTableKey)).length - 1;

  const handleCheckDragDividerDisplay = ({
    moveDragIndex,
    tableIndex,
    currentGroupTableKey,
    lastIndex,
    startDragIndex,
    isDraggingTable,
  }: {
    moveDragIndex: number | null;
    tableIndex: number;
    currentGroupTableKey: GroupTableKeyType;
    lastIndex?: number;
    startDragIndex: number | null;
    isDraggingTable: boolean;
  }) => {
    if (moveDragIndex === null) return false;
    if (!isDraggingTable) return false;

    if (isFirstTable(startDragIndex) && !lastIndex) {
      return moveDragIndex + 1 === tableIndex;
    }

    if (isLastTable(moveDragIndex, currentGroupTableKey)) {
      return moveDragIndex + 1 === lastIndex;
    }

    return moveDragIndex === tableIndex;
  };

  return { isFirstTable, isLastTable, handleCheckDragDividerDisplay };
};

export default useCheckIsDisplayTableDragDivider;
