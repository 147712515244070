import styled from "styled-components";

import {
  DefaultGroupShellWrapper,
  TableContentWrapper,
} from "@FillInTableContainer/shared/styles/table.style";

export const TableWrapper = styled(TableContentWrapper)``;

export const GroupShellWrapper = styled(DefaultGroupShellWrapper)``;
