"use client";

import styled, { css } from "styled-components";

export const RowWrapper = styled.div<{ $isDisplay: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  background-color: var(--Color-Common-Border-Tertiary, #e5e7eb);
  border-bottom: 1px solid var(--Color-Common-Border-Tertiary, #e5e7eb);

  & > div:last-child {
    border-right: none;
  }

  ${({ $isDisplay }) =>
    !$isDisplay &&
    css`
      display: none;
    `}
`;
