import { ClinicalTrialDesignText } from "@EditArticleContainer/shared/types/paragraphContents/contents/clinicalTrialDesignContent.types";

import { ClinicalTrialDesign1 } from "./clinicalTrialDesign1";
import { ClinicalTrialDesign2 } from "./clinicalTrialDesign2";

export class ClinicalTrialDesign {
  private clinicalTrialDesignData: ClinicalTrialDesignText;

  constructor(clinicalTrialDesignData: ClinicalTrialDesignText) {
    this.clinicalTrialDesignData = clinicalTrialDesignData;
  }

  public get data() {
    return this.clinicalTrialDesignData;
  }

  public getClinicalTrialDesign1() {
    return {
      data: new ClinicalTrialDesign1(this.data?.["Clinical Trial Design-1"]),
      // key: this.data.updateDt,
    };
  }

  public getClinicalTrialDesign2() {
    return {
      data: new ClinicalTrialDesign2(this.data["Clinical Trial Design-2"]),
      // key: this.data.updateDt,
    };
  }
}
