import React from "react";

import { getTableTitle } from "@/components/business/FillInTableContainer/shared/utils/getTextFromData";
import RequiredMark from "@atoms/RequiredMark/RequiredMark";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { GroupTableKeyType } from "@FillInTableContainer/shared/types/tableCreator.types";
import { padStart } from "@utils/padStart/padStart";

import * as S from "./TableCreatorTableTitle.style";
import { useGetNumberOfTable } from "../../hooks/useGetNumberOfTable";
import {
  isRequiredTable,
  getTableColumnSize,
} from "../../utils/getOptionsFromData";
import DealSwapRolesButton from "../DealSwapRolesButton/DealSwapRolesButton";
import TableCreatorDragButton from "../TableCreatorDragButton/TableCreatorDragButton";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  tableKey: string | null;
  tableIndex: number;
  groupTableKey: GroupTableKeyType;
  groupTableIndex: number;
  changeFocusStatusForDragging?: () => void;
  changeFocusStatusForDragEnd?: () => void;
  handleDragStart?: (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => void;
  handleDragEnd?: (
    groupTableKey: GroupTableKeyType,
    tableKey?: string | null,
  ) => void;
  handleDragOn?: () => void;
  onClickHandler?: () => void;
  isDraggableTable?: boolean;
  isDragging?: boolean;
  tableTitle?: string;
}

const DISABLED_DRAG_BUTTON_TABLE = [
  "Licensor",
  "Additional Information",
  "Deal Purpose",
  "Acquired Company",
  "Acquiring Company",
];

const TableCreatorTableTitle = ({
  tableKey,
  tableTitle,
  tableIndex,
  groupTableKey,
  handleDragEnd,
  handleDragOn,
  handleDragStart,
  isDraggableTable = false,
  isDragging = false,
  onClickHandler,
  changeFocusStatusForDragEnd,
  changeFocusStatusForDragging,
  ...rest
}: Props) => {
  const articleSection = useArticleSection();
  const { tableCount } = useGetNumberOfTable();

  const tableLength = tableKey ? tableCount(groupTableKey) : 1;

  const isExpressTableNumber =
    tableKey !== "Licensor" &&
    tableKey !== "Originator" &&
    tableKey !== "Acquired Company" &&
    tableKey !== "Acquiring Company" &&
    tableKey !== "Additional Information" &&
    tableKey !== "Deal Purpose";

  const currentTableTitle =
    tableTitle || getTableTitle(articleSection, tableKey);

  const isNumberingTableTitle = tableLength > 1 && isExpressTableNumber;
  const numberingTableTitleText = `${currentTableTitle} ${padStart(
    tableIndex + 1,
  )}`;

  const defaultTitle = isNumberingTableTitle
    ? numberingTableTitleText
    : currentTableTitle;

  const isRequired = isRequiredTable(articleSection, tableKey);

  const isDisabledDragButton = DISABLED_DRAG_BUTTON_TABLE.includes(
    tableKey || "",
  );

  return (
    <S.TableTitleWrapper {...rest}>
      <S.RelativeWrapper>
        <S.TitleContentsWrapper
          column={getTableColumnSize(articleSection, tableKey)}
          isDragging={isDragging}
          onClick={onClickHandler}
          onDragStart={(e) => {
            if (handleDragStart && changeFocusStatusForDragging) {
              handleDragStart(e, tableIndex);
              changeFocusStatusForDragging();
            }
          }}
          onDragEnd={() => {
            if (handleDragEnd && changeFocusStatusForDragEnd) {
              handleDragEnd(groupTableKey);
              changeFocusStatusForDragEnd();
            }
          }}
          onDrag={() => handleDragOn && handleDragOn()}
          draggable={isDraggableTable}
        >
          <S.Title isDragging={isDragging}>
            {isRequired && <RequiredMark text="*" />} {defaultTitle}
            <DealSwapRolesButton tableKey={tableKey} />
          </S.Title>
          {tableLength > 1 && !isDisabledDragButton && (
            <TableCreatorDragButton
              isDragging={isDragging}
              dragContainer="table"
              onClick={(e) => {
                e.stopPropagation();
                if (changeFocusStatusForDragging) {
                  changeFocusStatusForDragging();
                }
              }}
            />
          )}
        </S.TitleContentsWrapper>
      </S.RelativeWrapper>
    </S.TableTitleWrapper>
  );
};
export default TableCreatorTableTitle;
