import styled from "styled-components";

import IconCheck from "@images/fillInTable/tableCreator/icon-check-white.svg";

export const Checkbox = styled.input`
  display: flex;
  all: unset;
  width: 1.44rem;
  height: 1.44rem;
  border-radius: var(--Border-Radius-2, 0.2rem);
  border: 0.1rem solid var(--Color-Common-Border-Interactive-Secondary, #b1b8c4);
  background: #fff;

  &:checked {
    border: 0.1rem solid var(--Color-Common-Border-Interactive-Primary, #1baefe);
    background: url(${IconCheck}) no-repeat center center
      var(--Color-Common-Bg-Interactive-Primary, #1baefe);
  }

  &:hover {
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
      0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }
`;
