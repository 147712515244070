"use client";

import styled from "styled-components";

export const DeepInsightWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100rem;
  min-height: 11.8rem;

  color: ${({ theme }) => theme.color.neural.gray10};
  font-size: 2rem;
  font-weight: 400;
  line-height: 160%;
  border-radius: 1.2rem;
  border: 1px solid ${({ theme }) => theme.color.neural.gray02};
  background-color: ${({ theme }) => theme.color.neural.white};
  padding: 3.2rem 1.6rem 4.8rem 1.6rem;
  overflow: hidden;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    font-size: 1.4rem;
    font-weight: 400;
    padding: 1.6rem 1.6rem 4.8rem 1.6rem;
    line-height: 180%;
  }
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 4.8rem;
  transform: translateX(-50%);
`;
