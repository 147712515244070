import ComponentErrorBoundary from "@/components/shared/business/ComponentErrorBoundary/ComponentErrorBoundary";
import { TableCreatorGroupTableTitle } from "@FillInTableContainer/shared";
import TableCreatorNavigationTableWrapper from "@FillInTableContainer/shared/components/TableCreatorNavigationTableWrapper/TableCreatorNavigationTableWrapper";
import { ClinicalTrialStatusLayoutValue } from "@FillInTableContainer/shared/types/tableCreator.types";
import {
  getGroupTableTitle,
  getLayoutFromData,
} from "@FillInTableContainer/shared/utils/getTextFromData";

import ClinicalTrialStatusTableCreatorGroupTable from "./components/ClinicalTrialStatusTableCreatorGroupTable/ClinicalTrialStatusTableCreatorGroupTable";

const ClinicalTrialStatusTableCreator = () => {
  const layout = getLayoutFromData(
    "Clinical Trial Status",
  ) as ClinicalTrialStatusLayoutValue;

  const errorWithComponent = (title: string) => (
    <TableCreatorGroupTableTitle title={title} isRequired={false} />
  );

  return (
    <TableCreatorNavigationTableWrapper classificationKey="Clinical Trial Status">
      <>
        {layout.map(({ groupTableKey, tableList }, groupTableIndex) => (
          <ComponentErrorBoundary
            addComponents={errorWithComponent(
              getGroupTableTitle("Clinical Trial Status", groupTableKey),
            )}
          >
            <ClinicalTrialStatusTableCreatorGroupTable
              key={`group-table-${String(groupTableIndex)}`}
              groupTableKey={groupTableKey}
              tableList={tableList}
              groupTableIndex={groupTableIndex}
            />
          </ComponentErrorBoundary>
        ))}
      </>
    </TableCreatorNavigationTableWrapper>
  );
};

export default ClinicalTrialStatusTableCreator;
