import { RegulatoryParagraphContents } from "@EditArticleContainer/shared/types/paragraphContents/paragraphContents.types";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useCreateApprovalProcessContent } from "./hooks/useCreateApprovalProcessContent";
import { useCreateDrugProfileContent } from "./hooks/useCreateDrugProfileContent";
import { useCreateKeyCommentsContent } from "./hooks/useCreateKeyCommentsContent";
import { useCreateTitleContent } from "./hooks/useCreateTitleContent";
import { useCreateTopicContent } from "./hooks/useCreateTopicContent";

export const useCreateRegulatoryContent = () => {
  const createTitle = useCreateTitleContent();
  const createTopic = useCreateTopicContent();
  const createDrugProfile = useCreateDrugProfileContent();
  const createKeyComments = useCreateKeyCommentsContent();
  const createApprovalProcess = useCreateApprovalProcessContent();

  return async (): Promise<RegulatoryParagraphContents> => {
    const title = createTitle();
    const topic = createTopic();
    const drugProfile = createDrugProfile();
    const keyComments = createKeyComments();
    const approvalProcess = createApprovalProcess();

    return {
      "Article Id": Number(getPressIdFromURL()),
      TC201: title,
      TC202: topic,
      TC203: drugProfile,
      TC206: keyComments,
      TC213: approvalProcess,
    };
  };
};
