import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconLogIn = ({ width = 24, height = 24, color = "black" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.875 4.5C13.875 4.15482 14.1548 3.875 14.5 3.875H17.8333C18.4411 3.875 19.024 4.11644 19.4538 4.54621C19.8836 4.97598 20.125 5.55888 20.125 6.16667V17.8333C20.125 18.4411 19.8836 19.024 19.4538 19.4538C19.024 19.8836 18.4411 20.125 17.8333 20.125H14.5C14.1548 20.125 13.875 19.8452 13.875 19.5C13.875 19.1548 14.1548 18.875 14.5 18.875H17.8333C18.1096 18.875 18.3746 18.7653 18.5699 18.5699C18.7653 18.3746 18.875 18.1096 18.875 17.8333V6.16667C18.875 5.8904 18.7653 5.62545 18.5699 5.4301C18.3746 5.23475 18.1096 5.125 17.8333 5.125H14.5C14.1548 5.125 13.875 4.84518 13.875 4.5ZM9.89139 7.39139C10.1355 7.14731 10.5312 7.14731 10.7753 7.39139L14.9419 11.5581C15.0009 11.617 15.0456 11.6848 15.0761 11.7572C15.1076 11.8319 15.125 11.9139 15.125 12C15.125 12.0874 15.107 12.1707 15.0746 12.2463C15.0474 12.3097 15.0092 12.3696 14.9601 12.4231C14.9535 12.4302 14.9467 12.4372 14.9398 12.4441L10.7753 16.6086C10.5312 16.8527 10.1355 16.8527 9.89139 16.6086C9.64731 16.3645 9.64731 15.9688 9.89139 15.7247L12.9911 12.625H4.5C4.15482 12.625 3.875 12.3452 3.875 12C3.875 11.6548 4.15482 11.375 4.5 11.375H12.9911L9.89139 8.27527C9.64731 8.03119 9.64731 7.63546 9.89139 7.39139Z"
        fill={color}
      />
    </svg>
  );
};

IconLogIn.displayName = "LogIn";

export default IconLogIn;
