import React from "react";

import * as S from "./PreviewLoading.style";

const Loader = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.375 3.66699C11.375 3.32181 11.6548 3.04199 12 3.04199C12.3452 3.04199 12.625 3.32181 12.625 3.66699V7.00033C12.625 7.3455 12.3452 7.62533 12 7.62533C11.6548 7.62533 11.375 7.3455 11.375 7.00033V3.66699ZM11.375 17.0003C11.375 16.6551 11.6548 16.3753 12 16.3753C12.3452 16.3753 12.625 16.6551 12.625 17.0003L12.625 20.3337C12.625 20.6788 12.3452 20.9587 12 20.9587C11.6548 20.9587 11.375 20.6788 11.375 20.3337L11.375 17.0003ZM18.3336 5.66666C18.0896 5.42258 17.6938 5.42258 17.4498 5.66666L15.0914 8.02499C14.8473 8.26907 14.8473 8.6648 15.0914 8.90888C15.3355 9.15295 15.7312 9.15295 15.9753 8.90888L18.3336 6.55054C18.5777 6.30647 18.5777 5.91074 18.3336 5.66666ZM8.02475 15.0917C8.26883 14.8476 8.66456 14.8476 8.90864 15.0917C9.15271 15.3358 9.15271 15.7315 8.90864 15.9756L6.5503 18.3339C6.30623 18.578 5.9105 18.578 5.66642 18.3339C5.42234 18.0898 5.42234 17.6941 5.66642 17.45L8.02475 15.0917ZM20.9584 12.0003C20.9584 11.6551 20.6785 11.3753 20.3334 11.3753H17C16.6548 11.3753 16.375 11.6551 16.375 12.0003C16.375 12.3455 16.6548 12.6253 17 12.6253H20.3334C20.6785 12.6253 20.9584 12.3455 20.9584 12.0003ZM7.00002 11.3753C7.3452 11.3753 7.62502 11.6551 7.62502 12.0003C7.62502 12.3455 7.3452 12.6253 7.00002 12.6253L3.66669 12.6253C3.32151 12.6253 3.04169 12.3455 3.04169 12.0003C3.04169 11.6551 3.32151 11.3753 3.66669 11.3753L7.00002 11.3753ZM15.0914 15.0917C14.8473 15.3358 14.8473 15.7315 15.0914 15.9756L17.4498 18.3339C17.6938 18.578 18.0896 18.578 18.3336 18.3339C18.5777 18.0898 18.5777 17.6941 18.3336 17.45L15.9753 15.0917C15.7312 14.8476 15.3355 14.8476 15.0914 15.0917ZM5.66642 6.55054C5.42234 6.30647 5.42234 5.91074 5.66642 5.66666C5.9105 5.42258 6.30623 5.42258 6.5503 5.66666L8.90864 8.02499C9.15271 8.26907 9.15271 8.6648 8.90864 8.90888C8.66456 9.15295 8.26883 9.15295 8.02475 8.90888L5.66642 6.55054Z"
      fill="#7C899C"
    />
  </svg>
);

const PreviewLoading = () => {
  return (
    <S.PreviewLoadingWrapper>
      <S.PreviewLoadingText>Preview Loading...</S.PreviewLoadingText>
      <S.IconLoader>{Loader}</S.IconLoader>
    </S.PreviewLoadingWrapper>
  );
};

export default PreviewLoading;
