import dayjs from "dayjs";

import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import {
  REF_CODE_DEAL_NAME,
  REF_CODE_DEAL_STATUS,
  REF_CODE_DEAL_TYPE,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { DealTypeAndStatusContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/dealTypeAndStatusContent.types";

import { createContentFromHTML } from "../../../utils/getValueFromRefMap";

export const useCreateDealTypeAndStatusContent = () => {
  const refMap = useArticleContentRefMap();

  return () => {
    const content: DealTypeAndStatusContent = {
      text: {
        "Deal Type & Status": {
          "Deal Status": createContentFromHTML(refMap, REF_CODE_DEAL_STATUS),
          "Deal Type": createContentFromHTML(refMap, REF_CODE_DEAL_TYPE),
          "Deal Name": createContentFromHTML(refMap, REF_CODE_DEAL_NAME),
        },
      },
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
