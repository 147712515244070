import React from "react";

import BottomButtonContainer from "@/components/shared/ui/BottomButtonContainer/BottomButtonContainer";
import UndoRedoButton from "@/components/shared/ui/UndoRedoButton/UndoRedoButton";
import UpdateStatusButtons from "@/components/shared/ui/UpdateStatusButtons/UpdateStatusButtons";
import { useMovePage } from "@hooks/useMovePage";
import IconArrowLeft from "@images/common/icon-arrow-left.svg";

import * as S from "./InsertImageBottomButtonContainer.style";
import MoveEditArticleButton from "../MoveEditArticleButton/MoveEditArticleButton";

interface Props {
  saveDraftHandler: {
    undoIsDisabled: boolean;
    undoOnClickHandler: () => void;
    saveIsDisabled: boolean;
    saveOnClickHandler: () => void;
    redoIsDisabled: boolean;
    redoOnClickHandler: () => void;
  };
}

const InsertImageBottomButtonContainer = ({ saveDraftHandler }: Props) => {
  const movePage = useMovePage();

  const movePrev = () => {
    movePage("fillInTable");
  };

  return (
    <BottomButtonContainer>
      <S.LeftButtonContainer>
        <S.PrevButton onClick={movePrev}>
          <img src={IconArrowLeft} alt="go to edit Frame" />
          Fill in Table
        </S.PrevButton>
        <UpdateStatusButtons />
      </S.LeftButtonContainer>
      <S.BottomRightButtonWrapper>
        <UndoRedoButton {...saveDraftHandler} />
        <MoveEditArticleButton />
      </S.BottomRightButtonWrapper>
    </BottomButtonContainer>
  );
};

export default InsertImageBottomButtonContainer;
