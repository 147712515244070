"use client";

import styled from "styled-components";

export const Title = styled.h2<{ $isLeftAlign: boolean; $fontSize: number }>`
  color: #019df6;
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: ${({ $fontSize }) => $fontSize / 10}rem;
  font-weight: 600;
  line-height: 100%;
  width: ${({ $isLeftAlign }) => ($isLeftAlign ? "100%" : "")};
`;
