import styled from "styled-components";

import {
  DefaultGroupTableWrapper,
  DefaultTableListWrapper,
} from "@FillInTableContainer/shared/styles/groupTable.style";

export const GroupTableWrapper = styled(DefaultGroupTableWrapper)``;

export const TableCreatorTableListWrapper = styled(DefaultTableListWrapper)``;
