import styled from "styled-components";

export const DeleteButton = styled.button`
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;

  &:hover {
    svg {
      path {
        fill: #b83205;
      }
    }
  }

  &:active {
    svg {
      path {
        fill: #8b2504;
      }
    }
  }
`;
