import React, { useCallback, useEffect, useRef } from "react";

import { ENTRY_DATA_SECTION_KEYS as SECTION_KEYS } from "@frontend/common";

import { getMaxShellValueLength } from "@/components/business/FillInTableContainer/shared/utils/getOptionsFromData";
import { EntryDataInvestorValue } from "@/types/ventureCapital.types";
import SubGroupShellDividerPlusBar from "@FillInTableContainer/components/FillInTable/components/TableCreator/components/DealsTableCreator/components/DealsDealTermsTableCreatorContainer/components/DealTermsLicenseAgreementGroupTable/components/DealTermsLicenseAgreementTable/components/DealTermsLicenseAgreementGroupShellSection/components/DealTermsLicenseAgreementGroupShell/components/DealTermsLicenseAgreementSubGroupShell/components/SubGroupShellDividerPlusBar/SubGroupShellDividerPlusBar";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { DefaultShellTitle } from "@FillInTableContainer/shared";
import TableCreatorTextFieldCheckboxInput from "@FillInTableContainer/shared/components/TableCreatorTextFieldContainer/components/TableCreatorTextField/components/TableCreatorTextFieldCheckboxInput/TableCreatorTextFieldCheckboxInput";
import useCheckDisplayValueDragDivider from "@FillInTableContainer/shared/components/TableCreatorTextFieldContainer/components/TableCreatorTextField/hooks/useCheckDisplayValueDragDivider";
import TableCreatorTextField from "@FillInTableContainer/shared/components/TableCreatorTextFieldContainer/components/TableCreatorTextField/TableCreatorTextField";
import TableDragDivider from "@FillInTableContainer/shared/components/TableDragDivider/TableDragDivider";
import { IS_DRAGGING_SHELL } from "@FillInTableContainer/shared/constants/isActiveDragging.constants";
import { useTableCreatorFocusStatus } from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  DEFAULT_VALIDATED_INFO,
  useValidatedInTableCreator,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useHandleTableCreatorValueDrag } from "@FillInTableContainer/shared/hooks/useHandleTableCreatorValueDrag";
import { GroupTableKeyType } from "@FillInTableContainer/shared/types/tableCreator.types";
import { getShellTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { padStart } from "@utils/padStart/padStart";

import { useCheckLeadInvestorName } from "./hooks/useCheckLeadInvestorName";
import { useInvestorTableValueAdd } from "./hooks/useInvestorTableValueAdd";
import { useInvestorTableValueClearDeleteButton } from "./hooks/useInvestorTableValueClearDeleteButton";
import { useInvestorTableValueTextClear } from "./hooks/useInvestorTableValueTextClear";
import * as S from "./VentureCapitalInvestorTableCreatorTextFieldContainer.style";

interface Props {
  groupTableKey: GroupTableKeyType;
  tableKey: string | null;
  groupShellKey: string | null;
  groupTableIndex: number;
  tableIndex: number;
  groupShellIndex: number;
  subGroupShellKey?: string | null;
  subGroupShellIndex?: number | null;
  placeholder: string;
  shellKey: string;
  shellIndex: number;
  shellValue: {
    value: EntryDataInvestorValue[] | [];
    options: string[];
  };
}

const VentureCapitalInvestorTableCreatorTextFieldContainer = ({
  groupTableKey,
  tableKey,
  groupShellKey,
  groupTableIndex,
  tableIndex,
  groupShellIndex,
  subGroupShellIndex = null,
  subGroupShellKey = null,
  placeholder,
  shellKey,
  shellIndex,
  shellValue,
}: Props) => {
  const { addInvestorTableValue } = useInvestorTableValueAdd();
  const selectButtonRef = useRef<HTMLDivElement>(null);

  const articleSection = useArticleSection();
  const valueLength = getMaxShellValueLength(articleSection, shellKey);

  const valueList = shellValue.value || [];

  const isMultipleValue =
    valueLength > 1 && valueLength > valueList.length && valueList.length !== 0;
  const DEFAULT_SHELL_VALUE: EntryDataInvestorValue[] = valueList;

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();

  const indexGroup = {
    tableIndex,
    groupShellIndex,
    shellIndex,
  };
  const {
    handleValueDragEnd,
    handleValueDragOn,
    handleValueDragOver,
    handleValueDragStart,
    valueDraggedIndexInfo,
    isDraggingValue,
  } = useHandleTableCreatorValueDrag(
    SECTION_KEYS.ventureCapital,
    indexGroup,
    shellKey,
  );

  const { checkLeadInvestorName } = useCheckLeadInvestorName();
  const { clearDeleteInvestorValue } = useInvestorTableValueClearDeleteButton();
  const { clearInvestorTableValueText, clearInvestorTableValueCheckbox } =
    useInvestorTableValueTextClear();

  const valueListCount = DEFAULT_SHELL_VALUE.length - 1;
  const { isLastValue, handleCheckDragDividerDisplay } =
    useCheckDisplayValueDragDivider({
      isDraggingValue,
      valueListCount: valueList.length - 1,
    });

  const isFocused = (valueIndex: number) =>
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableKey === tableKey &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === groupShellKey &&
    info.groupShellIndex === groupShellIndex &&
    info.shellKey === "Investor Name" &&
    info.shellIndex === shellIndex &&
    info.valueIndex === valueIndex &&
    focusStatus.container === "value";

  const focusValueTextAndCheckbox = (valueIndex: number) => {
    changeInfo({
      ...info,
      groupTableKey,
      groupTableIndex,
      tableIndex,
      tableKey,
      groupShellKey,
      groupShellIndex,
      shellKey,
      shellIndex,
      valueIndex,
    });
    changeFocusStatus({
      type: "clear",
      container: "value",
    });
  };

  const focusInvestorNameText = (valueIndex: number) => {
    changeInfo({
      ...info,
      groupTableKey,
      groupTableIndex,
      tableIndex,
      tableKey,
      groupShellKey,
      groupShellIndex,
      shellKey,
      shellIndex,
      valueIndex,
    });
    changeFocusStatus({
      type: "clear",
      container: "textField",
    });
  };

  const focusInvestorNameCheckbox = (valueIndex: number) => {
    changeInfo({
      ...info,
      groupTableKey,
      groupTableIndex,
      tableIndex,
      tableKey,
      groupShellKey,
      groupShellIndex,
      shellKey,
      shellIndex,
      valueIndex,
    });
    changeFocusStatus({
      type: "clear",
      container: "checkbox",
    });
  };

  const handleFocusOutShell = useCallback(() => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  }, [changeInfo, changeValidatedInfo]);

  const isValueFocus =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableKey === tableKey &&
    info.tableIndex === tableIndex &&
    info.groupShellKey === groupShellKey &&
    info.groupShellIndex === groupShellIndex &&
    info.shellKey === "Investor Name" &&
    info.shellIndex === shellIndex;

  const isClearValue = (valueIndex: number) => {
    return (
      info.groupTableKey === groupTableKey &&
      info.groupTableIndex === groupTableIndex &&
      info.tableKey === tableKey &&
      info.tableIndex === tableIndex &&
      info.groupShellKey === groupShellKey &&
      info.groupShellIndex === groupShellIndex &&
      info.shellKey === "Investor Name" &&
      info.shellIndex === shellIndex &&
      info.valueIndex === valueIndex &&
      (focusStatus.type === "clear" || focusStatus.type === "delete") &&
      focusStatus.container === "value"
    );
  };

  const isClearTextField = (valueIndex: number) => {
    return (
      info.groupTableKey === groupTableKey &&
      info.groupTableIndex === groupTableIndex &&
      info.tableKey === tableKey &&
      info.tableIndex === tableIndex &&
      info.groupShellKey === groupShellKey &&
      info.groupShellIndex === groupShellIndex &&
      info.shellKey === "Investor Name" &&
      info.shellIndex === shellIndex &&
      info.valueIndex === valueIndex &&
      focusStatus.type === "clear" &&
      focusStatus.container === "textField"
    );
  };

  const isClearCheckbox = (valueIndex: number) => {
    return (
      info.groupTableKey === groupTableKey &&
      info.groupTableIndex === groupTableIndex &&
      info.tableKey === tableKey &&
      info.tableIndex === tableIndex &&
      info.groupShellKey === groupShellKey &&
      info.groupShellIndex === groupShellIndex &&
      info.shellKey === "Investor Name" &&
      info.shellIndex === shellIndex &&
      info.valueIndex === valueIndex &&
      focusStatus.type === "clear" &&
      focusStatus.container === "checkbox"
    );
  };

  useEffect(() => {
    const handleCheckClickOutsideShell = () => {
      if (isValueFocus) handleFocusOutShell();
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleFocusOutShell, isValueFocus]);

  return (
    <S.TextFieldWrapper>
      {shellValue.value.map((value, valueIndex) => (
        <S.ValueRelativeWrapper key={`value-${value}-${String(valueIndex)}`}>
          {isClearValue(valueIndex) && focusStatus.type !== "default" && (
            <S.DeleteValueButtonWrapper>
              <S.DeleteValueButton
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                  clearDeleteInvestorValue(valueIndex);
                }}
              >
                <img src={IconXMark} alt="button for clear table" />
                {focusStatus.type === "clear" && "Clear"}
                {focusStatus.type === "delete" && "Delete"}
              </S.DeleteValueButton>
            </S.DeleteValueButtonWrapper>
          )}
          <S.ValueSelectButtonDividerWrapper
            ref={selectButtonRef}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event?.preventDefault();
              event?.stopPropagation();
              focusValueTextAndCheckbox(valueIndex);
            }}
            isPossibleSelected
          >
            <S.ValueSelectButtonDivider className="select-button divider" />
            <S.ValueSelectButton className="select-button button">
              SELECT
            </S.ValueSelectButton>
          </S.ValueSelectButtonDividerWrapper>
          <SubGroupShellDividerPlusBar
            buttonText="Investor Data"
            onClick={() => addInvestorTableValue(valueIndex)}
            isLastPosition={false}
            isVisibility
            className="investor-data-divider-plus-bar"
          />
          <S.DraggableValueWrapper
            isTargetDraggingTable={
              isDraggingValue &&
              valueDraggedIndexInfo[0] !== valueIndex &&
              IS_DRAGGING_SHELL.includes(shellKey)
            }
            onDragOver={(e) => handleValueDragOver(e, valueIndex)}
          >
            {!(isMultipleValue && valueIndex === valueListCount) &&
              IS_DRAGGING_SHELL.includes(shellKey) && (
                <TableDragDivider
                  isDraggingEndPosition={handleCheckDragDividerDisplay({
                    moveDragIndex: valueDraggedIndexInfo[1],
                    startDragIndex: valueDraggedIndexInfo[0],
                    valueIndex,
                  })}
                />
              )}
            <S.InvestorValuesWrapper>
              <S.ValueBorderWrapper
                isFocused={
                  isClearTextField(valueIndex) || isFocused(valueIndex)
                }
                isClearStatus={false}
                isDeleteStatus={false}
                isAlertStatus={false}
                isDisableEdit={false}
                isSeparatedShellTitle={false}
                isTitleRowShell={false}
                $isPressed={false}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                  event.stopPropagation();
                  focusInvestorNameText(valueIndex);
                }}
              >
                {isClearTextField(valueIndex) &&
                  focusStatus.type !== "default" && (
                    <S.DeleteTextButtonWrapper>
                      <S.DeleteTextButton
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>,
                        ) => {
                          event.stopPropagation();
                          clearInvestorTableValueText(valueIndex);
                        }}
                      >
                        <img src={IconXMark} alt="button for clear textfield" />
                        {focusStatus.type === "clear" && "Clear"}
                      </S.DeleteTextButton>
                    </S.DeleteTextButtonWrapper>
                  )}
                <DefaultShellTitle
                  isFocused={false}
                  isClearShell={false}
                  isDeleteShell={false}
                  isAlertShell={false}
                  isRequiredShell={false}
                  shellTitle={getShellTitle(articleSection, shellKey)}
                  shellTitleNumbering={padStart(valueIndex + 1)}
                  isSeparatedShellTitle={false}
                  columnSize="regular"
                />

                <TableCreatorTextField
                  key={`text-field-${shellKey}-${value}-${String(valueIndex)}`}
                  groupTableKey={groupTableKey}
                  tableKey={tableKey}
                  groupShellKey={groupShellKey}
                  groupTableIndex={groupTableIndex}
                  tableIndex={tableIndex}
                  groupShellIndex={groupShellIndex}
                  subGroupShellKey={subGroupShellKey}
                  subGroupShellIndex={subGroupShellIndex}
                  placeholder={placeholder}
                  shellKey={shellKey}
                  shellIndex={shellIndex}
                  shellValue={shellValue}
                  valueIndex={valueIndex}
                  isMultipleLastValue={false}
                  isMultipleValue={valueListCount > 1}
                  handleDragEnd={handleValueDragEnd}
                  handleDragOn={handleValueDragOn}
                  handleDragStart={handleValueDragStart}
                />
              </S.ValueBorderWrapper>
              <S.CheckboxBorderWrapper
                isFocused={isClearCheckbox(valueIndex) || isFocused(valueIndex)}
                isClearStatus={false}
                isDeleteStatus={false}
                isAlertStatus={false}
                isDisableEdit={false}
                isSeparatedShellTitle={false}
                isTitleRowShell={false}
                $isPressed={false}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                  event.stopPropagation();
                  focusInvestorNameCheckbox(valueIndex);
                }}
              >
                {isClearCheckbox(valueIndex) &&
                  focusStatus.type !== "default" && (
                    <S.DeleteTextButtonWrapper>
                      <S.DeleteTextButton
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>,
                        ) => {
                          event.stopPropagation();
                          clearInvestorTableValueCheckbox(valueIndex);
                        }}
                      >
                        <img src={IconXMark} alt="button for clear textfield" />
                        {focusStatus.type === "clear" && "Clear"}
                      </S.DeleteTextButton>
                    </S.DeleteTextButtonWrapper>
                  )}
                <DefaultShellTitle
                  isFocused={false}
                  isClearShell={false}
                  isDeleteShell={false}
                  isAlertShell={false}
                  isRequiredShell={false}
                  shellTitle="Lead"
                  isSeparatedShellTitle={false}
                  columnSize="regular"
                />
                <S.CheckboxWrapper>
                  <TableCreatorTextFieldCheckboxInput
                    checked={
                      shellValue.value[valueIndex]?.is_lead_investor || false
                    }
                    onChange={(event) => {
                      event.stopPropagation();
                      checkLeadInvestorName(event, valueIndex);
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  />
                </S.CheckboxWrapper>
              </S.CheckboxBorderWrapper>
            </S.InvestorValuesWrapper>
            {IS_DRAGGING_SHELL.includes(shellKey) &&
              isLastValue(valueIndex) && (
                <TableDragDivider
                  isDraggingEndPosition={handleCheckDragDividerDisplay({
                    moveDragIndex: valueDraggedIndexInfo[1],
                    startDragIndex: valueDraggedIndexInfo[0],
                    valueIndex,
                    lastIndex: valueList.length,
                  })}
                />
              )}
          </S.DraggableValueWrapper>
          {isLastValue(valueIndex) && (
            <SubGroupShellDividerPlusBar
              buttonText="Investor Data"
              onClick={() => addInvestorTableValue(valueIndex + 1)}
              isLastPosition
              isVisibility
              className="investor-data-divider-plus-bar-last"
            />
          )}
        </S.ValueRelativeWrapper>
      ))}
    </S.TextFieldWrapper>
  );
};

export default VentureCapitalInvestorTableCreatorTextFieldContainer;
