import React from "react";

import * as S from "./TableTitle.style";

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  title: React.ReactNode;
}

const TableTitle = ({ title, ...rest }: Props) => {
  return <S.TableTitleWrapper {...rest}>{title}</S.TableTitleWrapper>;
};

export default TableTitle;
