import {
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS as DRUG_PROFILE_SHELL_KEYS,
  ENTRY_DATA_KEY_COMMENTS_GROUP_TABLE_KEY as KEY_COMMENTS,
  ENTRY_DATA_KEY_COMMENTS_SHELL_KEYS as KEY_COMMENTS_SHELL_KEYS,
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN as CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_1_KEYS as CLINICAL_TRIAL_DESIGN_1_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS as CLINICAL_TRIAL_DESIGN_2_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS as CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS as DESIGN_GROUP_INFORMATION_KEYS,
  ENTRY_DATA_CLINICAL_RESULTS as CLINICAL_RESULTS,
  ENTRY_DATA_CLINICAL_RESULTS_KEYS as CLINICAL_RESULTS_KEYS,
  ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE as CLINICAL_ENDPOINTS_TABLE,
  ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS as CLINICAL_ENDPOINTS_TABLE_KEYS,
  ENTRY_DATA_ENDPOINTS_TABLE_INFORMATION_KEYS as ENDPOINTS_TABLE_INFORMATION_KEYS,
  ENTRY_DATA_ENDPOINT_GROUP_INFORMATION_KEYS as ENDPOINT_GROUP_INFORMATION_KEYS,
  ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION as CLINICAL_ENDPOINTS_DESCRIPTION,
  ENTRY_DATA_CLINICAL_ENDPOINTS_DESCRIPTION_KEYS as CLINICAL_ENDPOINTS_DESCRIPTION_KEYS,
  ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";

const getDrugProfileFormatCheckList = (entryData: ArticleEntryData) => {
  const targetPath = entryData?.[SECTION_KEYS.clinicalTrial]?.[DRUG_PROFILE];
  if (!targetPath) return [`${DRUG_PROFILE} data is missing`];
  if (!Array.isArray(targetPath)) return [`${DRUG_PROFILE} data is missing`];
  if (targetPath.length === 0) return [`${DRUG_PROFILE} data is missing`];

  const checkDrugProfileTableValues = targetPath.flatMap(
    (tableItem, tableIndex) => {
      const requiredFields = [
        {
          key: DRUG_PROFILE_SHELL_KEYS.brandName,
          label: DRUG_PROFILE_SHELL_KEYS.brandName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.innName,
          label: DRUG_PROFILE_SHELL_KEYS.innName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.codeName,
          label: DRUG_PROFILE_SHELL_KEYS.codeName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.moa,
          label: DRUG_PROFILE_SHELL_KEYS.moa,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.modality,
          label: DRUG_PROFILE_SHELL_KEYS.modality,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
          label: DRUG_PROFILE_SHELL_KEYS.otherCharacteristics,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.roa,
          label: DRUG_PROFILE_SHELL_KEYS.roa,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.target,
          label: DRUG_PROFILE_SHELL_KEYS.target,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.targetIndication,
          label: DRUG_PROFILE_SHELL_KEYS.targetIndication,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.therapeuticArea,
          label: DRUG_PROFILE_SHELL_KEYS.therapeuticArea,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
          label: DRUG_PROFILE_SHELL_KEYS.originatorDeveloper,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.genericBiosimilar,
          label: DRUG_PROFILE_SHELL_KEYS.genericBiosimilar,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.refProductName,
          label: DRUG_PROFILE_SHELL_KEYS.refProductName,
        },
        {
          key: DRUG_PROFILE_SHELL_KEYS.phase,
          label: DRUG_PROFILE_SHELL_KEYS.phase,
        },
      ];

      return requiredFields
        .map(({ key, label }) =>
          !tableItem[key]?.value
            ? `${label} is missing in ${DRUG_PROFILE} table ${tableIndex}`
            : "",
        )
        .filter((message) => !!message);
    },
  );

  return [...checkDrugProfileTableValues];
};

const getClinicalTrialDesignFormatCheckList = (entryData: ArticleEntryData) => {
  const targetPath =
    entryData?.[SECTION_KEYS.clinicalTrial]?.[CLINICAL_TRIAL_DESIGN];

  if (!targetPath) return [`${CLINICAL_TRIAL_DESIGN} data is missing`];
  if (!Array.isArray(targetPath))
    return [`${CLINICAL_TRIAL_DESIGN} data is missing`];
  if (targetPath.length === 0)
    return [`${CLINICAL_TRIAL_DESIGN} data is missing`];

  const checkClinicalTrialDesignTableValues = targetPath.flatMap(
    (tableItem, tableIndex) => {
      const requiredGroupFields = [
        {
          key: CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1,
          label: CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1,
        },
        {
          key: CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2,
          label: CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2,
        },
      ];

      const groupFieldsList = requiredGroupFields
        .map(({ key, label }) =>
          !tableItem[key]
            ? `${label} is missing in ${CLINICAL_TRIAL_DESIGN} ${tableIndex}`
            : "",
        )
        .filter((message) => !!message);

      const clinicalTrialDesign1List = [
        {
          key: CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDetail,
          label: CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDetail,
        },
        {
          key: CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDiagnostic,
          label: CLINICAL_TRIAL_DESIGN_1_KEYS.inclusionCriteriaDiagnostic,
        },
        {
          key: CLINICAL_TRIAL_DESIGN_1_KEYS.nctName,
          label: CLINICAL_TRIAL_DESIGN_1_KEYS.nctName,
        },
        {
          key: CLINICAL_TRIAL_DESIGN_1_KEYS.clinicalDesignPhase,
          label: CLINICAL_TRIAL_DESIGN_1_KEYS.clinicalDesignPhase,
        },
        {
          key: CLINICAL_TRIAL_DESIGN_1_KEYS.totalPatientsNumber,
          label: CLINICAL_TRIAL_DESIGN_1_KEYS.totalPatientsNumber,
        },
        {
          key: CLINICAL_TRIAL_DESIGN_1_KEYS.trialIntervention,
          label: CLINICAL_TRIAL_DESIGN_1_KEYS.trialIntervention,
        },
        {
          key: CLINICAL_TRIAL_DESIGN_1_KEYS.trialName,
          label: CLINICAL_TRIAL_DESIGN_1_KEYS.trialName,
        },
      ];

      const checkClinicalTrialDesign1Format = clinicalTrialDesign1List
        .map(({ key, label }) =>
          !tableItem[CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1][key]
            ? `${label} is missing in ${CLINICAL_TRIAL_DESIGN} ${tableIndex}`
            : "",
        )
        .filter((message) => !!message);

      const clinicalTrialDesign2Target =
        tableItem[CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2];

      const clinicalTrialDesign2GroupList =
        clinicalTrialDesign2Target?.length === 0
          ? [
              `${CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2} is missing in ${CLINICAL_TRIAL_DESIGN} table ${tableIndex}`,
            ]
          : [];

      const checkClinicalTrialDesign2SingleFormat =
        clinicalTrialDesign2Target.flatMap((groupShellItem, groupSellIndex) => {
          const requiredFields = [
            {
              key: CLINICAL_TRIAL_DESIGN_2_KEYS.armName,
              label: CLINICAL_TRIAL_DESIGN_2_KEYS.armName,
            },
            {
              key: CLINICAL_TRIAL_DESIGN_2_KEYS.patientsNumber,
              label: CLINICAL_TRIAL_DESIGN_2_KEYS.patientsNumber,
            },
          ];

          const checkSingleDataFormat = requiredFields
            .map(({ key, label }) =>
              !groupShellItem[key]?.value
                ? `${label} is missing in ${CLINICAL_TRIAL_DESIGN} table ${tableIndex} ${CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2} group ${groupSellIndex}`
                : "",
            )
            .filter((message) => !!message);

          const designGroupInformationTarget =
            groupShellItem[CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation];

          const checkDesignGroupInformationList =
            designGroupInformationTarget?.length === 0
              ? [
                  `${CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation} is missing in ${CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2} table ${tableIndex} groupShell ${groupSellIndex}`,
                ]
              : [];

          const checkDesignGroupInformationData =
            designGroupInformationTarget.flatMap(
              (shellListInfo, shellListIndex) => {
                const requiredDesignGroupInformationData = [
                  {
                    key: DESIGN_GROUP_INFORMATION_KEYS.does,
                    label: DESIGN_GROUP_INFORMATION_KEYS.does,
                  },
                  {
                    key: DESIGN_GROUP_INFORMATION_KEYS.drugName,
                    label: DESIGN_GROUP_INFORMATION_KEYS.drugName,
                  },
                  {
                    key: DESIGN_GROUP_INFORMATION_KEYS.duration,
                    label: DESIGN_GROUP_INFORMATION_KEYS.duration,
                  },
                ];

                return requiredDesignGroupInformationData
                  .map(({ key, label }) =>
                    !shellListInfo[key]?.value
                      ? `${label} is missing in ${CLINICAL_TRIAL_DESIGN} table ${tableIndex} ${CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2} group ${groupSellIndex} shellList ${CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation} shellListIndex ${shellListIndex}`
                      : "",
                  )
                  .filter((message) => !!message);
              },
            );

          return [
            ...checkSingleDataFormat,
            ...checkDesignGroupInformationList,
            ...checkDesignGroupInformationData,
          ];
        });

      return [
        ...(groupFieldsList || []),
        ...(checkClinicalTrialDesign1Format || []),
        ...(clinicalTrialDesign2GroupList || []),
        ...(checkClinicalTrialDesign2SingleFormat || []),
      ];
    },
  );

  return [...checkClinicalTrialDesignTableValues];
};

const getKeyCommentFormatCheckList = (entryData: ArticleEntryData) => {
  const targetPath = entryData?.[SECTION_KEYS.clinicalTrial]?.[KEY_COMMENTS];
  if (!targetPath) return [`${KEY_COMMENTS} data is missing`];

  const requiredFields = [
    {
      key: KEY_COMMENTS_SHELL_KEYS.quote,
      label: KEY_COMMENTS_SHELL_KEYS.quote,
    },
    {
      key: KEY_COMMENTS_SHELL_KEYS.spokesperson,
      label: KEY_COMMENTS_SHELL_KEYS.spokesperson,
    },
  ];

  return (
    requiredFields
      ?.map(({ key, label }) =>
        !targetPath[key]?.value ? `${label} is missing in ${KEY_COMMENTS}` : "",
      )
      ?.filter((message) => !!message) || []
  );
};

const getClinicalResultsCheckList = (entryData: ArticleEntryData) => {
  const targetPath =
    entryData?.[SECTION_KEYS.clinicalTrial]?.[CLINICAL_RESULTS];
  if (!targetPath) return [`${CLINICAL_RESULTS} data is missing`];

  const requiredFields = [
    {
      key: CLINICAL_RESULTS_KEYS.resultsClassification,
      label: CLINICAL_RESULTS_KEYS.resultsClassification,
    },
  ];

  return (
    requiredFields
      ?.map(({ key, label }) =>
        !targetPath[key]?.value
          ? `${label} is missing in ${CLINICAL_RESULTS}`
          : "",
      )
      ?.filter((message) => !!message) || []
  );
};

const getClinicalEndpointsTableCheckList = (entryData: ArticleEntryData) => {
  const targetPath =
    entryData?.[SECTION_KEYS.clinicalTrial]?.[CLINICAL_ENDPOINTS_TABLE];

  if (!targetPath) return [`${CLINICAL_ENDPOINTS_TABLE} data is missing`];
  if (!Array.isArray(targetPath))
    return [`${CLINICAL_ENDPOINTS_TABLE} data is missing`];
  if (targetPath.length === 0)
    return [`${CLINICAL_ENDPOINTS_TABLE} data is missing`];

  const checkClinicalEndpointsTableValues = targetPath.flatMap(
    (tableInfo, tableInfoIndex) => {
      const requiredSingleField = [
        {
          key: CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableTitle,
          label: CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableTitle,
        },
        {
          key: CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableNotes,
          label: CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableNotes,
        },
      ];

      const checkRequiredSingleFieldList = requiredSingleField
        .map(({ key, label }) =>
          !tableInfo[key]?.value
            ? `${label} is missing in ${CLINICAL_ENDPOINTS_TABLE} table ${tableInfoIndex} ${CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation}`
            : "",
        )
        .filter((message) => !!message);

      const endpointsTableInformationTarget =
        tableInfo[
          ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation
        ];

      const checkEndpointsTableInformationLength =
        endpointsTableInformationTarget?.length === 0
          ? [
              `${ENTRY_DATA_CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation} is missing in ${CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation} table ${tableInfoIndex}`,
            ]
          : [];

      const checkEndpointTableInformationListFormat =
        endpointsTableInformationTarget.flatMap(
          (groupShellListInfo, groupShellListIndex) => {
            const requiredField = [
              {
                key: ENDPOINTS_TABLE_INFORMATION_KEYS.endpointClassification,
                label: ENDPOINTS_TABLE_INFORMATION_KEYS.endpointClassification,
              },
              {
                key: ENDPOINTS_TABLE_INFORMATION_KEYS.endpointNameIncludingMeasurementTiming,
                label:
                  ENDPOINTS_TABLE_INFORMATION_KEYS.endpointNameIncludingMeasurementTiming,
              },
            ];

            const singleFieldCheckFormatList = requiredField
              .map(({ key, label }) =>
                !groupShellListInfo[key]?.value
                  ? `${label} is missing in ${CLINICAL_ENDPOINTS_TABLE} table ${tableInfoIndex} ${CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation} groupShell ${groupShellListIndex}`
                  : "",
              )
              .filter((message) => !!message);

            const endpointGroupInformationTarget =
              groupShellListInfo[
                ENDPOINTS_TABLE_INFORMATION_KEYS.endpointGroupInformation
              ];

            const checkEndpointGroupInformationListLength =
              endpointGroupInformationTarget?.length === 0
                ? [
                    `${ENDPOINTS_TABLE_INFORMATION_KEYS.endpointGroupInformation} is missing in ${CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation} table ${tableInfoIndex} ${ENDPOINTS_TABLE_INFORMATION_KEYS.endpointGroupInformation}`,
                  ]
                : [];

            const checkEndpointGroupInformationDataList =
              endpointGroupInformationTarget.flatMap(
                (shellListInfo, shellListIndex) => {
                  const requiredFields = [
                    {
                      key: ENDPOINT_GROUP_INFORMATION_KEYS.groupData,
                      label: ENDPOINT_GROUP_INFORMATION_KEYS.groupData,
                    },
                    {
                      key: ENDPOINT_GROUP_INFORMATION_KEYS.pValue,
                      label: ENDPOINT_GROUP_INFORMATION_KEYS.pValue,
                    },
                    {
                      key: ENDPOINT_GROUP_INFORMATION_KEYS.groupName,
                      label: ENDPOINT_GROUP_INFORMATION_KEYS.groupName,
                    },
                  ];

                  return requiredFields
                    .map(({ key, label }) =>
                      !shellListInfo[key]?.value
                        ? `${label} is missing in ${CLINICAL_ENDPOINTS_TABLE} table ${tableInfoIndex} ${CLINICAL_ENDPOINTS_TABLE_KEYS.endpointsTableInformation} groupShell ${groupShellListIndex} ${ENDPOINTS_TABLE_INFORMATION_KEYS.endpointGroupInformation} ${shellListIndex}`
                        : "",
                    )
                    .filter((message) => !!message);
                },
              );

            return [
              ...singleFieldCheckFormatList,
              ...checkEndpointGroupInformationListLength,
              ...checkEndpointGroupInformationDataList,
            ];
          },
        );

      return [
        ...checkRequiredSingleFieldList,
        ...checkEndpointsTableInformationLength,
        ...checkEndpointTableInformationListFormat,
      ];
    },
  );

  return [...checkClinicalEndpointsTableValues];
};

export const getClinicalEndpointsDescription = (
  entryData: ArticleEntryData,
) => {
  const targetPath =
    entryData?.[SECTION_KEYS.clinicalTrial]?.[CLINICAL_ENDPOINTS_DESCRIPTION];
  if (!targetPath) return [`${KEY_COMMENTS} data is missing`];

  const requiredFields = [
    {
      key: CLINICAL_ENDPOINTS_DESCRIPTION_KEYS.endpointDescription,
      label: CLINICAL_ENDPOINTS_DESCRIPTION_KEYS.endpointDescription,
    },
  ];

  return (
    requiredFields
      ?.map(({ key, label }) =>
        !targetPath[key]?.value
          ? `${label} is missing in ${CLINICAL_ENDPOINTS_DESCRIPTION}`
          : "",
      )
      ?.filter((message) => !!message) || []
  );
};

export const getClinicalTrialEntryDataFormatCheckList = (
  entryData: ArticleEntryData,
) => [
  ...getDrugProfileFormatCheckList(entryData),
  ...getClinicalTrialDesignFormatCheckList(entryData),
  ...getKeyCommentFormatCheckList(entryData),
  ...getClinicalResultsCheckList(entryData),
  ...getClinicalEndpointsTableCheckList(entryData),
  ...getClinicalEndpointsDescription(entryData),
];
