import React from "react";

import * as S from "./ColumnSelectButton.style";

interface Props {
  top: string;
  left: string;
  clickEvent:
    | (() => void)
    | ((event: React.MouseEvent<HTMLDivElement>) => void);
}

const ColumnSelectButton = ({ clickEvent, top, left }: Props) => {
  return (
    <S.SelectButtonWrapper $top={top} $left={left} onClick={clickEvent}>
      <S.Divider />
      <S.SelectButton>SELECT</S.SelectButton>
    </S.SelectButtonWrapper>
  );
};

export default ColumnSelectButton;
