import React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const IconAlertTriangle = ({
  width = 40,
  height = 40,
  color = "#ffffff",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1467 6.84317C18.7124 6.52464 19.3508 6.3573 20 6.3573C20.6493 6.3573 21.2876 6.52464 21.8534 6.84317C22.4192 7.16171 22.8933 7.62068 23.23 8.17582L23.2329 8.18057L23.2329 8.18057L34.9968 27.8195L35.0049 27.8333C35.3348 28.4045 35.5093 29.0522 35.5112 29.7118C35.513 30.3715 35.3421 31.0201 35.0154 31.5932C34.6888 32.1662 34.2178 32.6438 33.6492 32.9783C33.0807 33.3129 32.4345 33.4927 31.7749 33.4999L31.7639 33.5001V33.5H8.23614H8.22515L8.22515 33.4999C7.56554 33.4927 6.91934 33.3129 6.35082 32.9783C5.7823 32.6438 5.31128 32.1662 4.98462 31.5932C4.65797 31.0201 4.48706 30.3715 4.48891 29.7118C4.49075 29.0522 4.66529 28.4045 4.99515 27.8333L5.00327 27.8195L16.7672 8.18057L16.77 8.17582C17.1068 7.62068 17.5809 7.16171 18.1467 6.84317ZM20 8.3573C19.6945 8.3573 19.3941 8.43605 19.1279 8.58595C18.8623 8.73546 18.6397 8.95071 18.4813 9.21103L18.48 9.21307L6.72358 28.8396C6.57065 29.1069 6.48976 29.4094 6.4889 29.7174C6.48803 30.0278 6.56846 30.3331 6.72218 30.6028C6.8759 30.8724 7.09755 31.0972 7.36509 31.2546C7.63122 31.4112 7.93351 31.4958 8.2422 31.5H31.7579C32.0665 31.4958 32.3688 31.4112 32.635 31.2546C32.9025 31.0972 33.1242 30.8724 33.2779 30.6028C33.4316 30.3331 33.512 30.0278 33.5112 29.7174C33.5103 29.4094 33.4294 29.1069 33.2765 28.8396L21.52 9.21307L21.5187 9.21083C21.3603 8.9506 21.1377 8.73542 20.8722 8.58595C20.606 8.43605 20.3056 8.3573 20 8.3573ZM20 14.8333C20.5523 14.8333 21 15.281 21 15.8333V21.3889C21 21.9412 20.5523 22.3889 20 22.3889C19.4477 22.3889 19 21.9412 19 21.3889V15.8333C19 15.281 19.4477 14.8333 20 14.8333ZM20 25.9444C19.4477 25.9444 19 26.3922 19 26.9444C19 27.4967 19.4477 27.9444 20 27.9444H20.0139C20.5662 27.9444 21.0139 27.4967 21.0139 26.9444C21.0139 26.3922 20.5662 25.9444 20.0139 25.9444H20Z"
        fill={color}
      />
    </svg>
  );
};

IconAlertTriangle.displayName = "AlertTriangle";

export default IconAlertTriangle;
