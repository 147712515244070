import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useDesDealTypeDropdownDisplayNameValue } from "@/contexts/desDealTypeDropdownDisplayNameContext";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { dropDownList } from "@api/dropDownList/dropDownList.service";
import {
  SECTION_ACCORDING_TO_CLASSIFICATION,
  SECTION_CODE,
} from "@FillInTableContainer/components/FillInTable/constants/common.constants";
import { DEFAULT_DROPDOWN_LIST } from "@FillInTableContainer/shared/components/TableCreatorListBox/constants/tableCreatorListBox.constants";
import {
  handleDropdownListWithCategory,
  handleDropdownListWithoutCategory,
} from "@FillInTableContainer/shared/components/TableCreatorListBox/utils/handleDropdownList";

import type { TableCreatorDropdownContextType } from "@FillInTableContainer/shared/components/TableCreatorListBox/types/tableCreatorDropdown.types";

const TableCreatorDropdownContext =
  createContext<TableCreatorDropdownContextType>(DEFAULT_DROPDOWN_LIST);

export const TableCreatorDropdownProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { data: articleInfo } = useArticleInfoQuery();
  const { changeDealTypeTextToDisplayName } =
    useDesDealTypeDropdownDisplayNameValue();
  const classification = articleInfo?.classificationManual;

  if (!classification) throw new Error("Classification is not defined");

  const section = SECTION_ACCORDING_TO_CLASSIFICATION[classification];
  const sectionCode = useMemo(() => SECTION_CODE[section], [section]);
  const [dropdownList, setDropdownList] =
    useState<TableCreatorDropdownContextType>(DEFAULT_DROPDOWN_LIST);

  const getTableCreatorDropdownList = useCallback(async () => {
    const { data } = await dropDownList.get(sectionCode);

    setDropdownList({
      categoryDropdown: handleDropdownListWithCategory(data),
      defaultDropdown: handleDropdownListWithoutCategory(
        data,
        changeDealTypeTextToDisplayName,
      ),
    });
  }, [sectionCode, changeDealTypeTextToDisplayName]);

  useEffect(() => {
    if (sectionCode) {
      getTableCreatorDropdownList();
    }
  }, [getTableCreatorDropdownList, sectionCode]);

  return (
    <TableCreatorDropdownContext.Provider value={dropdownList}>
      {children}
    </TableCreatorDropdownContext.Provider>
  );
};

export const useTableCreatorDropdown = () => {
  const dropdown = useContext(TableCreatorDropdownContext);

  return {
    dropdownList: dropdown,
    defaultDropdownList: dropdown.defaultDropdown,
    categoryDropdownList: dropdown.categoryDropdown,
  };
};
