export const DRAFT_STATUS_CODE = {
  notForAiEntry: "S112",
  aiEntryInProgress: "S300",
  aiEntryDone: "S111",
  reviewInProgress: "S120",
  reviewInProgressHalf: "S121",
  dbSaved: "S910",
  dbSavedHalf: "S911",
  editArticle: "S210",
  insertImage: "S220",
  published: "S920",
  unsuitable: "S930",
  systemError: "S800",
} as const;
