import React from "react";

import * as S from "./TableShellValue.style";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  width?: string;
  justifyContent?: string;
  padding?: string;
  isDisabled?: boolean;
  fontSize?: string;
  children: React.ReactNode;
  isCapitalize?: boolean;
}

const DBDetailTableShellValue = ({
  width,
  justifyContent,
  padding,
  fontSize = "1.6rem",
  isDisabled,
  isCapitalize,
  children,
  ...rest
}: Props) => {
  return (
    <S.ValueShell
      $width={width}
      $justifyContent={justifyContent}
      $padding={padding}
      $isDisabled={isDisabled}
      $fontSize={fontSize}
      $isCapitalize={isCapitalize}
      {...rest}
    >
      {children}
    </S.ValueShell>
  );
};

export default DBDetailTableShellValue;
