/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";

const MINIMUM_COLUMN_WIDTH = 0;

export const useHandleResizeWidth = () => {
  const widthHandleBarRef = useRef<HTMLDivElement>(null);
  const columnRef = useRef<HTMLDivElement>(null);
  const columnWrapperRef = useRef<HTMLDivElement>(null);

  const [dragging, setDragging] = useState<boolean>(false);
  const [initialX, setInitialX] = useState<number>(0);

  const checkColumnWrapperWidth = () => {
    return (columnWrapperRef.current?.getBoundingClientRect().width || 0) < 1407
      ? 1407
      : columnWrapperRef.current?.getBoundingClientRect().width || 1407;
  };

  const initColumnWrapperWidth = checkColumnWrapperWidth();

  const initialColumnWidth =
    localStorage.getItem("columnWidth") &&
    Number(localStorage.getItem("columnWidth")) > 160 &&
    Number(localStorage.getItem("columnWidth")) + 160 < initColumnWrapperWidth
      ? Number(localStorage.getItem("columnWidth"))
      : 0;

  const [columnWidth, setColumnWidth] = useState<number>(initialColumnWidth);
  const [columnWrapperWidth, setColumnWrapperWidth] = useState<number>(
    initColumnWrapperWidth,
  );

  const mouseDownWidthHandleBar = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (columnWidth === 0 && columnRef.current) {
      setColumnWidth(columnRef.current.clientWidth);
    }

    setDragging(true);
    setInitialX(event.clientX);
  };

  const calculatedWidth = useCallback(
    (clientX: number) => {
      const updatedWidth = columnWidth + clientX - initialX;
      return updatedWidth;
    },
    [columnWidth, initialX],
  );

  const mouseMoveWidthHandleBar = useCallback(
    (event: MouseEvent) => {
      const calculatedClientX = calculatedWidth(event.clientX);

      if (
        dragging &&
        widthHandleBarRef.current &&
        calculatedClientX > MINIMUM_COLUMN_WIDTH &&
        calculatedClientX < columnWrapperWidth - MINIMUM_COLUMN_WIDTH
      ) {
        setColumnWrapperWidth(initColumnWrapperWidth);
        setColumnWidth(calculatedClientX);
        setInitialX(event.clientX);
        localStorage.setItem("columnWidth", String(calculatedClientX));
      }

      if (dragging) {
        document.body.style.cursor = "col-resize";
      } else {
        document.body.style.cursor = "default";
      }
    },

    [initColumnWrapperWidth, calculatedWidth, columnWrapperWidth, dragging],
  );

  useEffect(() => {
    const mouseUpWidthHandleBar = () => {
      setDragging(false);
    };
    document.addEventListener("mousemove", mouseMoveWidthHandleBar);
    document.addEventListener("mouseup", mouseUpWidthHandleBar);

    return () => {
      document.removeEventListener("mousemove", mouseMoveWidthHandleBar);
      document.removeEventListener("mouseup", mouseUpWidthHandleBar);
    };
  }, [mouseMoveWidthHandleBar]);

  useEffect(() => {
    if (columnRef.current && columnWrapperRef.current) {
      setColumnWidth(columnRef.current.clientWidth);
      setColumnWrapperWidth(initColumnWrapperWidth);
    }
  }, []);

  useEffect(() => {
    const resizeColumnWidth = () => {
      if (columnRef.current && columnWrapperRef.current) {
        if (checkColumnWrapperWidth() < columnRef.current.clientWidth) {
          setColumnWidth(columnRef.current.clientWidth);
        }

        setColumnWrapperWidth(checkColumnWrapperWidth());
      }
    };
    window.addEventListener("resize", resizeColumnWidth);
    return () => {
      window.removeEventListener("resize", resizeColumnWidth);
    };
  }, []);

  return {
    mouseDownWidthHandleBar,
    columnWidth,
    columnWrapperWidth,
    widthHandleBarRef,
    columnRef,
    columnWrapperRef,
  };
};
