import { MutableRefObject, ReactNode, createContext, useContext } from "react";

import { useWebSocket } from "@hooks/useWebSocket";

type WebSocketContextType = MutableRefObject<WebSocket | undefined>;

const WebSocketContext = createContext<WebSocketContextType | null>(null);

export const WebSocketProvider = ({ children }: { children: ReactNode }) => {
  const webSocketClient = useWebSocket();

  return (
    <WebSocketContext.Provider value={webSocketClient}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketClient = () => {
  const webSocketClient = useContext(WebSocketContext);

  if (webSocketClient === null) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }

  return { webSocketClient };
};
