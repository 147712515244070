import { useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { setAuthorization } from "@libs/authorization/utils/setAuthorization";
import { openModal } from "@libs/modal/openModal";
import { ROUTE_PATHS } from "@routes/routePath";
import { authApis } from "@services/auth.service";

import AuthenticationErrorModal from "../components/AuthenticationErrorModal/AuthenticationErrorModal";
import LoginErrorModal from "../components/LoginErrorModal/LoginErrorModal";

import type { SignInFormType } from "../types/signIn.types";

export const useSignIn = () => {
  const [checkedRememberMe, setCheckedRememberMe] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const prevPathname = location.state?.pathname ?? ROUTE_PATHS.pressList;
  const prevSearch = location.state?.search ?? "";

  // FIXME: 해당 부분은 Mutation을 어떻게 분리해서 사용할지 결정되면 분리해야함.
  const { mutate, isPending, isError } = useMutation({
    mutationFn: authApis.post,
    onSuccess: (data) => {
      const { accessToken, refreshToken, accountName, accountEmail } = data;
      setAuthorization({
        accessToken,
        refreshToken,
        accountName,
        accountEmail,
        checkedRememberMe,
      });

      navigate({ pathname: prevPathname, search: prevSearch });
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          openModal(({ closeModal }) => (
            <LoginErrorModal closeModal={closeModal} />
          ));
        } else if (error.response?.status === 403) {
          openModal(({ closeModal }) => (
            <AuthenticationErrorModal closeModal={closeModal} />
          ));
        }
      } else {
        throw error;
      }
    },
  });

  const toggleRememberMe = () => {
    setCheckedRememberMe(!checkedRememberMe);
  };

  const submitSignIn = (data: SignInFormType) => {
    mutate(data);
  };

  return {
    checkedRememberMe,
    isPending,
    isError,
    toggleRememberMe,
    submitSignIn,
  };
};
