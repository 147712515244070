"use client";

import styled, { css } from "styled-components";

import { NeuralColors } from "../types/themeColor.types";

interface InputProps {
  $inputColor: NeuralColors;
  $inputSize: string;
  $inputWeight: string;
  $inputAlign: string;
  $lineColor: string;
  $isError: boolean;
}

export const LabelWrapper = styled.label`
  width: 100%;
  position: relative;

  .input-label {
    display: none;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 130%;
    color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  }

  &:focus-within {
    .input-label {
      display: block;
    }
  }
`;

export const LabelText = styled.p`
  color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 100%;
`;

export const LabelTextWrapper = styled.div`
  position: absolute;
  left: 0.8rem;
  top: -0.8rem;
  padding: 0 0.4rem;
  background-color: white;
  z-index: 2;
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Input = styled.input<InputProps>`
  all: unset;
  width: 100%;
  height: 4rem;
  font-size: ${({ $inputSize }) => `${Number($inputSize) / 10}rem`};
  font-weight: ${({ $inputWeight }) => $inputWeight};
  text-align: ${({ $inputAlign }) => $inputAlign};
  color: var(--Color-Common-Text-Primary, #2a2f37);
  line-height: 145%;
  padding: 1.2rem;
  box-sizing: border-box;
  cursor: text;

  &::placeholder {
    color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
    opacity: 0.5;
  }

  &:disabled {
    background: var(--Color-Common-Bg-Disabled, #cbd0d8);
    color: var(--Color-Common-Text-Disabled, #96a1b0);
    cursor: not-allowed;
  }

  ${({ $lineColor }) =>
    $lineColor !== "null" &&
    css`
      border: 0.1rem solid ${$lineColor};
      border-radius: 0.4rem;
      &:focus {
        border: 1px solid
          var(--Color-Common-Border-Interactive-Primary, #0198e9);
      }
    `}

  ${({ $isError }) =>
    $isError &&
    css`
      border: 1px solid var(--Color-Common-Border-Warning, #e53e06);
    `}

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;
