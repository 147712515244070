import { useEffect, useState } from "react";

import {
  fetchAllArticleData,
  invalidateAllArticleDataQuery,
  useUpdateArticleContentDataMutation,
} from "@api/articleContentData/articleContentData";
import { articleContentDataApis } from "@api/articleContentData/articleContentData.service";
import { useSectionCodeToClassification } from "@hooks/useSectionCode";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import { parseJSON } from "@utils/parseJSON";
import { toastFail, toastSuccess, toastWaitForAI } from "@utils/toast";

import { ParagraphCode } from "../types/editArticle.types";
import { ParagraphContent } from "../types/paragraphContents/paragraphContents.types";

const POLLING_INTERVAL = 3000;
const TICK_LIMIT = 100;
export const useRegenerate = <T extends ParagraphCode>(paragraphCode: T) => {
  const [tick, setTick] = useState<number>(0);
  const [invokeTime, setInvokeTime] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutateAsync: updateArticleContentData } =
    useUpdateArticleContentDataMutation();
  const sectionCode = useSectionCodeToClassification();

  useEffect(() => {
    if (!isLoading) {
      setTick(0);
      return;
    }

    const timeoutId = setTimeout(async () => {
      const result = await fetchAllArticleData({
        articleId: getPressIdFromURL(),
        sectionCode,
        paragraphCode,
      });

      if (tick >= TICK_LIMIT) {
        toastFail("Failed to Regenerate");
        setInvokeTime(null);
        setIsLoading(false);
        return;
      }

      // 시간이 서버마다 다른 이슈가 있어서 변경되었는지만 확인.
      if (invokeTime !== result.data.updateDt) {
        const currentParagraph = parseJSON<ParagraphContent[T]>(
          result.data.data,
        );
        const updatedArticleContentData = {
          "Article Id": Number(getPressIdFromURL()),
          [paragraphCode]: currentParagraph,
        };
        await updateArticleContentData(updatedArticleContentData);
        await invalidateAllArticleDataQuery(getPressIdFromURL(), paragraphCode);
        toastSuccess("Regenerate completed!", "green");
        setInvokeTime(null);
        setIsLoading(false);
        return;
      }
      toastWaitForAI(tick + 1, TICK_LIMIT);
      setTick(tick + 1);
    }, POLLING_INTERVAL);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    invokeTime,
    isLoading,
    paragraphCode,
    sectionCode,
    tick,
    updateArticleContentData,
  ]);

  const regenerate = async () => {
    try {
      setIsLoading(true);
      const paragraph = await fetchAllArticleData({
        articleId: getPressIdFromURL(),
        sectionCode,
        paragraphCode,
      });
      setInvokeTime(paragraph.data.updateDt);
      articleContentDataApis.reload(
        getPressIdFromURL(),
        sectionCode,
        paragraphCode,
      );
    } catch {
      toastFail("AI Server is not responding");
      setInvokeTime(null);
      setIsLoading(false);
    }
  };

  return { regenerate, isLoading };
};
