"use client";

import styled from "styled-components";

export const FixedButtonWrapper = styled.div`
  max-width: 120rem;
  position: fixed;
  right: 4rem;
  bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;
  z-index: 30;
`;

export const FixedButtonToScroll = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  cursor: pointer;
`;
