import React from "react";

import { IconInfo } from "@frontend/common";

import Modal from "@atoms/Modal/Modal";
import useModal from "@hooks/useModal";
import IconInsertImagePlus from "@images/insertImage/icon-plus-insert-image.svg";
import IconSelectedImageCheck from "@images/insertImage/icon-selected-image-check-white.svg";
import IconWide from "@images/insertImage/icon-title-wide.svg";
import { SelectImageType } from "@InsertImageContainer/shared/types/insertImage.types";
import ImageView from "@organisms/ImageView/ImageView";

import * as S from "./InsertImageBox.style";

import type { ImagePlatform } from "@interface/image.interface";

interface Props {
  imagePath: string;
  platform: ImagePlatform;
  keywordList: {
    keyword: string;
    directoryName: string;
  }[];
  artistName: string;
  isSelectedImage: boolean;
  index: number;
  addHistoryImageList: (type: SelectImageType, index?: number) => void;
  setStatusToInsertImage: () => void;
}

const InsertImageBox = ({
  imagePath,
  artistName,
  keywordList,
  platform,
  isSelectedImage,
  index,
  addHistoryImageList,
  setStatusToInsertImage,
}: Props) => {
  const { isModal, openModal, closeModal } = useModal();

  const keywordListToString = keywordList?.map((item) => (
    <S.Keyword key={item.keyword}>{item.keyword}</S.Keyword>
  ));

  const handleClickImage = () => {
    addHistoryImageList("select", index);
    setStatusToInsertImage();
  };

  const handleZoomEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    openModal();
  };

  return (
    <S.InsertImageBoxWrapper>
      <S.Image isSelectedImage={isSelectedImage} onClick={handleClickImage}>
        <S.ImageBackground
          isSelectedImage={isSelectedImage}
          className="image-background"
        >
          {isSelectedImage ? (
            <S.SelectedImageText>
              <img src={IconSelectedImageCheck} alt="button " />
              Selected
            </S.SelectedImageText>
          ) : (
            <S.SelectedImageText>
              <img src={IconInsertImagePlus} alt="button " />
              Click to Insert Image
            </S.SelectedImageText>
          )}

          <S.ZoomInButton
            type="button"
            className="button-zoom-in"
            onClick={handleZoomEvent}
          >
            <img src={IconWide} alt="button " />
          </S.ZoomInButton>
        </S.ImageBackground>

        <img src={imagePath} alt={artistName} />
      </S.Image>
      <S.KeywordWrapper>
        {keywordListToString}
        <S.InfoButton>
          <IconInfo />
          <S.InfoContainer className="image-tooltip">
            <S.InfoItem>
              <S.InfoTitle>Artist:</S.InfoTitle>
              <S.InfoValue>{artistName}</S.InfoValue>
            </S.InfoItem>
            <S.InfoItem>
              <S.InfoTitle>Source:</S.InfoTitle>
              <S.InfoValue>{platform}</S.InfoValue>
            </S.InfoItem>
          </S.InfoContainer>
        </S.InfoButton>
      </S.KeywordWrapper>
      {isModal && (
        <Modal closeModal={closeModal}>
          <ImageView selectImage={imagePath} closeModal={closeModal} />
        </Modal>
      )}
    </S.InsertImageBoxWrapper>
  );
};

export default InsertImageBox;
