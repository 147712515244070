import React from "react";

import {
  ClinicalTrialIdentifierIcon,
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
} from "@frontend/common";

import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";

import * as S from "./ClinicalTrialDesign1Table.style";
import { ClinicalTrialDesign1 } from "../../utils/clinicalTrialDesign1";

interface Props {
  clinicalTrialDesign1: ClinicalTrialDesign1;
  designGroupTableIndex: number;
}

const COMMON_EDITABLE_TEXT_STYLE = {
  padding: "0.8rem",
  borderRadius: "0",
};
const VALUE_SHELL_PADDING = "0.2rem";

const ClinicalTrialDesign1Table = ({
  clinicalTrialDesign1,
  designGroupTableIndex,
}: Props) => {
  const createClinicalTrialIdentifierIcon = () => {
    const valueList = clinicalTrialDesign1.clinicalTrialIdentifier.text
      .replaceAll("- ", "")
      .split("\n");

    if (valueList.filter((item) => !!item).length === 0) return [];
    return valueList.map((item) => (
      <ClinicalTrialIdentifierIcon value={item} />
    ));
  };

  const REF_GROUP_TABLE_INDEX = `||${designGroupTableIndex}`;

  const isFirstRowEmpty =
    clinicalTrialDesign1.trialName.text === "" &&
    clinicalTrialDesign1.clinicalTrialIdentifier.text === "" &&
    clinicalTrialDesign1.clinicalDesignPhase.text === "" &&
    clinicalTrialDesign1.totalPatientsNumber.text === "";

  const isSecondRowEmpty =
    clinicalTrialDesign1.inclusionCriteriaDiagnostic.text === "" &&
    clinicalTrialDesign1.inclusionCriteriaDetail.text === "";

  return (
    <S.ClinicalTrialDesign1TableWrapper
      className={`hide-row-${isFirstRowEmpty && isSecondRowEmpty}`}
    >
      <TableContainer>
        <TableRowContainer className={`hide-row-${isFirstRowEmpty}`}>
          <TableShellTitle style={{ flex: "calc(20% + 1px)" }}>
            Trial Name
          </TableShellTitle>
          <TableShellTitle style={{ flex: "calc(30% + 1px)" }}>
            Clinical Trial Identifier
          </TableShellTitle>
          <TableShellTitle style={{ flex: "25%" }}>Phase</TableShellTitle>
          <TableShellTitle style={{ flex: "25%" }}>
            Total Number of Patients
          </TableShellTitle>
        </TableRowContainer>
        <TableRowContainer
          className={`hide-row-${isFirstRowEmpty} border-bottom-none-${isSecondRowEmpty}`}
        >
          <TableShellValue
            padding={VALUE_SHELL_PADDING}
            style={{ flex: "calc(20% + 1px)" }}
          >
            <EditableTextContainer
              item={{
                text: clinicalTrialDesign1.trialName.text,
                labels: clinicalTrialDesign1.trialName.labels ?? [],
              }}
              canTextEdit
              canLabelEdit
              refKey={`${clinicalTrialDesign1.trialName.key}${REF_GROUP_TABLE_INDEX}`}
              style={COMMON_EDITABLE_TEXT_STYLE}
            />
          </TableShellValue>
          <TableShellValue
            padding={VALUE_SHELL_PADDING}
            style={{ flex: "calc(30% + 1px)" }}
          >
            <EditableTextContainer
              item={{
                text: clinicalTrialDesign1.clinicalTrialIdentifier.text,
                labels:
                  clinicalTrialDesign1.clinicalTrialIdentifier.labels ?? [],
              }}
              canTextEdit
              canLabelEdit
              refKey={`${clinicalTrialDesign1.clinicalTrialIdentifier.key}${REF_GROUP_TABLE_INDEX}`}
              style={COMMON_EDITABLE_TEXT_STYLE}
              imagePosition="left"
              imageNodeList={createClinicalTrialIdentifierIcon()}
              isDisabled
              isClinicalTrialIdentifier
            />
          </TableShellValue>

          <TableShellValue
            padding={VALUE_SHELL_PADDING}
            style={{ flex: "25%" }}
          >
            <EditableTextContainer
              item={{
                text: clinicalTrialDesign1.clinicalDesignPhase.text,
                labels: clinicalTrialDesign1.clinicalDesignPhase.labels ?? [],
              }}
              canTextEdit
              canLabelEdit
              refKey={`${clinicalTrialDesign1.clinicalDesignPhase.key}${REF_GROUP_TABLE_INDEX}`}
              style={COMMON_EDITABLE_TEXT_STYLE}
            />
          </TableShellValue>
          <TableShellValue
            padding={VALUE_SHELL_PADDING}
            style={{ flex: "25%" }}
          >
            <EditableTextContainer
              item={{
                text: clinicalTrialDesign1.totalPatientsNumber.text,
                labels: clinicalTrialDesign1.totalPatientsNumber.labels ?? [],
              }}
              canTextEdit
              canLabelEdit
              refKey={`${clinicalTrialDesign1.totalPatientsNumber.key}${REF_GROUP_TABLE_INDEX}`}
              style={COMMON_EDITABLE_TEXT_STYLE}
            />
          </TableShellValue>
        </TableRowContainer>
        <TableRowContainer className={`hide-row-${isSecondRowEmpty}`}>
          <TableShellTitle>Inclusion Criteria - Diagnostic</TableShellTitle>
          <TableShellTitle>Inclusion Criteria - Detail</TableShellTitle>
        </TableRowContainer>
        <TableRowContainer className={`hide-row-${isSecondRowEmpty}`}>
          <TableShellValue padding={VALUE_SHELL_PADDING}>
            <EditableTextContainer
              item={{
                text: clinicalTrialDesign1.inclusionCriteriaDiagnostic.text,
                labels:
                  clinicalTrialDesign1.inclusionCriteriaDiagnostic.labels ?? [],
              }}
              canTextEdit
              canLabelEdit
              refKey={`${clinicalTrialDesign1.inclusionCriteriaDiagnostic.key}${REF_GROUP_TABLE_INDEX}`}
              style={COMMON_EDITABLE_TEXT_STYLE}
            />
          </TableShellValue>
          <TableShellValue padding={VALUE_SHELL_PADDING}>
            <EditableTextContainer
              item={{
                text: clinicalTrialDesign1.inclusionCriteriaDetail.text,
                labels:
                  clinicalTrialDesign1.inclusionCriteriaDetail.labels ?? [],
              }}
              canTextEdit
              canLabelEdit
              refKey={`${clinicalTrialDesign1.inclusionCriteriaDetail.key}${REF_GROUP_TABLE_INDEX}`}
              style={COMMON_EDITABLE_TEXT_STYLE}
            />
          </TableShellValue>
        </TableRowContainer>
      </TableContainer>
    </S.ClinicalTrialDesign1TableWrapper>
  );
};

export default ClinicalTrialDesign1Table;
