import { useCallback } from "react";

import {
  CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_SECTION_KEYS,
} from "@frontend/common";

import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { PositionInfoProps } from "../ClinicalTrialDesignShell";
import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface Returns {
  deleteClinicalDesign2Shell: () => void;
}

export const useDeleteClinicalTrialsDesign2Shell = (
  positionPropsInfo: PositionInfoProps,
): Returns => {
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { changeFocusStatus } = useTableCreatorFocusStatus();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const deleteClinicalDesign2Shell = useCallback(() => {
    if (
      info.groupShellKey ===
      CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign2
    ) {
      const deleteClinicalTrialDesignTwoShell = (): ArticleEntryData => {
        const targetDesign2Data =
          entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
            ENTRY_DATA_CLINICAL_TRIAL_DESIGN
          ];

        return {
          ...entryData,
          [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
            ...entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
            [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: targetDesign2Data?.map(
              (tableInfo, tableInfoIndex) => {
                if (info.tableIndex === tableInfoIndex) {
                  return {
                    ...tableInfo,
                    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2]:
                      tableInfo[
                        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS
                          .clinicalTrialDesign2
                      ].map((subGroupShellInfo, subGroupShellInfoIndex) => {
                        if (
                          subGroupShellInfoIndex === info.subGroupShellIndex
                        ) {
                          return {
                            ...subGroupShellInfo,
                            [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation]:
                              subGroupShellInfo[
                                ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS
                                  .designGroupInformation
                              ].filter(
                                (_, shellInfoIndex) =>
                                  shellInfoIndex !== info.shellIndex,
                              ),
                          };
                        }

                        return subGroupShellInfo;
                      }),
                  };
                }

                return tableInfo;
              },
            ),
          },
        };
      };

      setEntryData(deleteClinicalTrialDesignTwoShell());
      changeCurrentHistory({
        entryData: deleteClinicalTrialDesignTwoShell(),
        changedDataInfo: {
          target: "shell",
          action: "delete",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  return {
    deleteClinicalDesign2Shell,
  };
};
