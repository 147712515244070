import React from "react";

import Modal from "@atoms/Modal/Modal";
import useModal from "@hooks/useModal";
import IconSearch from "@images/common/icon-search-white.svg";
import IconChange from "@images/insertImage/icon-change-image.svg";
import IconX from "@images/insertImage/icon-close-preview-image.svg";
import IconUpload from "@images/insertImage/icon-upload-image.svg";

import ImageInfoInput from "./components/ImageInfoInput/ImageInfoInput";
import ImageView from "./components/ImageView/ImageView";
import InsertImageUploadButton from "./components/InsertImageUploadButton/InsertImageUploadButton";
import SelectImageLabel from "./components/SelectImageLabel/SelectImageLabel";
import * as S from "./ImageSource.style";
import {
  InsertImageFilterKeys,
  SelectedImageLabelList,
} from "../../shared/types/filterKeyword.types";

import type {
  ImageInfoType,
  ImageType,
} from "../../shared/types/insertImage.types";

interface Props {
  previewImage: ImageType | null;
  handlePreviewImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  uploadImageToStorage: () => void;
  selectedLabelList: SelectedImageLabelList;
  changeSelectedLabelList: (labelName: SelectedImageLabelList) => void;
  handleImageLabel: (
    event: React.ChangeEvent<HTMLInputElement>,
    labelName: InsertImageFilterKeys,
  ) => void;
  handleDeleteUploadFile: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleArticleImagePreview: () => void;
  handleImageInfoInput: (
    event: React.ChangeEvent<HTMLInputElement>,
    name: "artist" | "source",
  ) => void;
  canUploadDB: boolean;
  imageInfo: ImageInfoType;
  imageSourceRef: React.RefObject<HTMLInputElement>;
  artistRef: React.RefObject<HTMLInputElement>;
  checkedUploadedImage: () => boolean;
}

const ImageSource = ({
  handlePreviewImage,
  previewImage,
  selectedLabelList,
  changeSelectedLabelList,
  handleImageLabel,
  handleDeleteUploadFile,
  uploadImageToStorage,
  handleArticleImagePreview,
  canUploadDB,
  checkedUploadedImage,
  handleImageInfoInput,
  imageInfo,
  imageSourceRef,
  artistRef,
}: Props) => {
  const { isModal, openModal, closeModal } = useModal();
  const isDisabledImagePreview =
    !previewImage ||
    selectedLabelList.length === 0 ||
    imageInfo.artist === "" ||
    imageInfo.source === "";

  const handleZoomInImage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    openModal();
  };

  return (
    <S.ImageSourceWrapper>
      <S.ImageSourceColumn>
        <S.StepTitle>1. Add Image</S.StepTitle>
        <S.Divider />
        <S.FixedSize>
          {previewImage && (
            <S.PreviewImage>
              <img
                src={
                  typeof previewImage === "string"
                    ? previewImage
                    : Buffer.from(previewImage).toString()
                }
                alt="select main banner"
              />
            </S.PreviewImage>
          )}
          <label htmlFor="insertImage">
            <S.ImageDropSection selectImage={previewImage !== null}>
              {previewImage && (
                <S.ImageDropButton>
                  <button type="button" onClick={handleDeleteUploadFile}>
                    <img src={IconX} alt="button close" />
                  </button>
                  <button type="button" onClick={handleZoomInImage}>
                    <img src={IconSearch} alt="button search" />
                  </button>
                </S.ImageDropButton>
              )}
              <S.AddImageTitle className={previewImage ? "" : "add-image-text"}>
                <img src={previewImage ? IconChange : IconUpload} alt="" />
                {previewImage ? "Click to Change Image" : "Click to Add Image"}
              </S.AddImageTitle>
              <input
                type="file"
                id="insertImage"
                accept="image/*"
                onChange={handlePreviewImage}
              />
            </S.ImageDropSection>
          </label>
        </S.FixedSize>
        <S.SelectImageLabelButton type="button" />
      </S.ImageSourceColumn>
      <S.ImageSourceFlexible>
        <S.StepTitle>2. Select Image Keyword</S.StepTitle>
        <S.Divider />

        <SelectImageLabel
          previewImage={previewImage}
          selectedLabelList={selectedLabelList}
          handleImageLabel={handleImageLabel}
          changeSelectedLabelList={changeSelectedLabelList}
        />
      </S.ImageSourceFlexible>
      <S.ImageSourceColumn>
        <S.StepTitle>3. Enter Copyrights</S.StepTitle>
        <S.Divider />
        <S.ImageInfoInputWrapper>
          <ImageInfoInput
            type="search"
            placeholder="Enter Artist Name"
            onChange={(event) => handleImageInfoInput(event, "artist")}
            disabled={!previewImage || selectedLabelList.length === 0}
            ref={artistRef}
            label="Artist"
            defaultValue={imageInfo.artist}
          />
          <ImageInfoInput
            type="search"
            placeholder="Enter Image Source"
            onChange={(event) => handleImageInfoInput(event, "source")}
            disabled={!previewImage || selectedLabelList.length === 0}
            ref={imageSourceRef}
            label="Source"
            defaultValue={imageInfo.source}
          />
        </S.ImageInfoInputWrapper>

        <InsertImageUploadButton
          uploadImageToStorage={uploadImageToStorage}
          handleArticleImagePreview={handleArticleImagePreview}
          canUploadDB={canUploadDB}
          checkedUploadedImage={checkedUploadedImage}
          isDisabledImagePreview={isDisabledImagePreview}
        />
      </S.ImageSourceColumn>
      {isModal && (
        <Modal>
          <ImageView selectImage={previewImage} closeModal={closeModal} />
        </Modal>
      )}
    </S.ImageSourceWrapper>
  );
};

export default ImageSource;
