import {
  ARTICLE_CONTENTS_ROUND_AND_STATUS_PARAGRAPH_GROUP_KEY as PARAGRAPH_GROUP_KEY,
  ARTICLE_CONTENTS_ROUND_AND_STATUS_SHELL_KEYS as SHELL_KEYS,
} from "@frontend/common";
import dayjs from "dayjs";

import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import {
  REF_CODE_VENTURE_CAPITAL_ROUND_NAME,
  REF_CODE_VENTURE_CAPITAL_ROUND_TYPE,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { VentureCapitalRoundAndStatusContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/ventureCapitalRoundAndStatusContent.types";

import { createContentFromHTML } from "../../../utils/getValueFromRefMap";

export const useCreateVentureCapitalRoundAndStatusContent = () => {
  const refMap = useArticleContentRefMap();

  return () => {
    const content: VentureCapitalRoundAndStatusContent = {
      text: {
        [PARAGRAPH_GROUP_KEY]: {
          [SHELL_KEYS.roundName]: createContentFromHTML(
            refMap,
            REF_CODE_VENTURE_CAPITAL_ROUND_NAME,
          ),
          [SHELL_KEYS.roundType]: createContentFromHTML(
            refMap,
            REF_CODE_VENTURE_CAPITAL_ROUND_TYPE,
          ),
        },
      },
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
