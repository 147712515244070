import React from "react";

import {
  DealTermsTableContainer,
  PARAGRAPH_CODE,
  ARTICLE_CONTENTS_DEAL_TERMS_KEYS as DEAL_TERMS_KEYS,
  ARTICLE_DETAIL_DEAL_TERMS_SC_LA_SHELL_TITLE as SHELL_TITLE,
  ARTICLE_DETAIL_DEAL_TERMS_TABLE_TITLE as TABLE_TITLE,
} from "@frontend/common";

import { useCreateCompanyLogoComponentList } from "@EditArticleContainer/shared/hooks/useCreateCompanyLogoComponentList";
import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import {
  REF_CODE_LICENSOR_NAME,
  REF_CODE_LICENSOR_RESPONSIBILITY,
  REF_CODE_LICENSEE_NAME,
  REF_CODE_LICENSEE_RESPONSIBILITY,
  REF_CODE_UPFRONT,
  REF_CODE_MILESTONE,
  REF_CODE_TOTAL_PRICE_UP_TO,
  REF_CODE_ROYALTY,
  REF_CODE_OTHER_FINANCIAL_TERMS,
  REF_CODE_DEAL_OTHER_PAYMENT_SC_LA,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import {
  createEditArticleShellList,
  createEditArticleSingleTableShellList,
} from "@EditArticleContainer/shared/utils/createEditArticleShellList";

import {
  licenseeMilestoneLabels,
  licenseeMilestoneText,
  licenseeNameLabels,
  licenseeNameText,
  licenseeOtherFinancialTermsLabels,
  licenseeOtherFinancialTermsText,
  licenseeOtherPaymentLabels,
  licenseeOtherPaymentText,
  licenseeResponsibilityLabels,
  licenseeResponsibilityText,
  licenseeRoyaltyLabels,
  licenseeRoyaltyText,
  licenseeTotalPriceLabels,
  licenseeTotalPriceText,
  licenseeUpfrontLabels,
  licenseeUpfrontText,
  licensorNameLabels,
  licensorNameText,
  licensorResponsibilityLabels,
  licensorResponsibilityText,
} from "./utils/getLicenseAgreementShellValue";
import { useCreateRightsTables } from "../../hooks/useCreateRightsTables";
import { useSellerBuyerByDealType } from "../../hooks/useSellerBuyerByDealType";

interface Props {
  noBackgroundArrow?: boolean;
}

const LicenseAgreementTable = ({ noBackgroundArrow }: Props) => {
  const { sellerName, buyerName } = useSellerBuyerByDealType();
  const { createCompanyLogoComponentList } =
    useCreateCompanyLogoComponentList();
  const dealTerms = useParagraphEditedData(PARAGRAPH_CODE.TY002.dealTerms);

  const createRightsTables = useCreateRightsTables();

  const SHELL_TEXT = {
    licensorNameText: licensorNameText(dealTerms),
    licensorResponsibilityText: licensorResponsibilityText(dealTerms),
    licenseeNameText: licenseeNameText(dealTerms),
    licenseeResponsibilityText: licenseeResponsibilityText(dealTerms),
    licenseeUpfrontText: licenseeUpfrontText(dealTerms),
    licenseeMilestoneText: licenseeMilestoneText(dealTerms),
    licenseeOtherPaymentText: licenseeOtherPaymentText(dealTerms),
    licenseeTotalPriceText: licenseeTotalPriceText(dealTerms),
    licenseeRoyaltyText: licenseeRoyaltyText(dealTerms),
    licenseeOtherFinancialTermsText: licenseeOtherFinancialTermsText(dealTerms),
  };

  const SHELL_LABELS = {
    licensorNameLabels: licensorNameLabels(dealTerms),
    licensorResponsibilityLabels: licensorResponsibilityLabels(dealTerms),
    licenseeNameLabels: licenseeNameLabels(dealTerms),
    licenseeResponsibilityLabels: licenseeResponsibilityLabels(dealTerms),
    licenseeUpfrontLabels: licenseeUpfrontLabels(dealTerms),
    licenseeMilestoneLabels: licenseeMilestoneLabels(dealTerms),
    licenseeOtherPaymentLabels: licenseeOtherPaymentLabels(dealTerms),
    licenseeTotalPriceLabels: licenseeTotalPriceLabels(dealTerms),
    licenseeRoyaltyLabels: licenseeRoyaltyLabels(dealTerms),
    licenseeOtherFinancialTermsLabels:
      licenseeOtherFinancialTermsLabels(dealTerms),
  };

  const topTableItems = [
    createEditArticleShellList([
      {
        title: sellerName || SHELL_TITLE[DEAL_TERMS_KEYS.licensorName],
        text: SHELL_TEXT.licensorNameText,
        labels: SHELL_LABELS.licensorNameLabels,
        refKey: REF_CODE_LICENSOR_NAME,
        imagePosition: "left",
        imageNodeList: createCompanyLogoComponentList(
          SHELL_TEXT.licensorNameText.replaceAll("- ", "").split(`\n`) ?? "",
        ),
        isDisabled: true,
      },
      {
        title: SHELL_TITLE[DEAL_TERMS_KEYS.licensorResponsibilityRights],
        text: SHELL_TEXT.licensorResponsibilityText,
        labels: SHELL_LABELS.licensorResponsibilityLabels,
        refKey: REF_CODE_LICENSOR_RESPONSIBILITY,
      },
    ]),
    createEditArticleShellList([
      {
        title: buyerName || SHELL_TITLE[DEAL_TERMS_KEYS.licenseeName],
        text: SHELL_TEXT.licenseeNameText,
        labels: SHELL_LABELS.licenseeNameLabels,
        refKey: REF_CODE_LICENSEE_NAME,
        imagePosition: "left",
        imageNodeList: createCompanyLogoComponentList(
          SHELL_TEXT.licenseeNameText.replaceAll("- ", "").split(`\n`) ?? "",
        ),
        isDisabled: true,
      },
      {
        title: SHELL_TITLE[DEAL_TERMS_KEYS.licenseeResponsibilityRightsScLa],
        text: SHELL_TEXT.licenseeResponsibilityText,
        labels: SHELL_LABELS.licenseeResponsibilityLabels,
        refKey: REF_CODE_LICENSEE_RESPONSIBILITY,
      },
    ]),
  ];

  const bottomTableHeaderItems = [
    TABLE_TITLE.rightsScope,
    TABLE_TITLE.financialTerms,
  ];

  const bottomTableItemsList = [
    createRightsTables(),
    [
      createEditArticleShellList([
        {
          title: SHELL_TITLE[DEAL_TERMS_KEYS.upfrontPayment],
          text: SHELL_TEXT.licenseeUpfrontText,
          labels: SHELL_LABELS.licenseeUpfrontLabels,
          refKey: REF_CODE_UPFRONT,
        },
        {
          title: SHELL_TITLE[DEAL_TERMS_KEYS.milestonePayment],
          text: SHELL_TEXT.licenseeMilestoneText,
          labels: SHELL_LABELS.licenseeMilestoneLabels,
          refKey: REF_CODE_MILESTONE,
        },
        {
          title: SHELL_TITLE[DEAL_TERMS_KEYS.otherPaymentScLa],
          text: SHELL_TEXT.licenseeOtherPaymentText,
          labels: SHELL_LABELS.licenseeOtherPaymentLabels,
          refKey: REF_CODE_DEAL_OTHER_PAYMENT_SC_LA,
        },
        {
          title: SHELL_TITLE[DEAL_TERMS_KEYS.totalPaymentScLa],
          text: SHELL_TEXT.licenseeTotalPriceText,
          labels: SHELL_LABELS.licenseeTotalPriceLabels,
          refKey: REF_CODE_TOTAL_PRICE_UP_TO,
        },
        {
          title: SHELL_TITLE[DEAL_TERMS_KEYS.royalty],
          text: SHELL_TEXT.licenseeRoyaltyText,
          labels: SHELL_LABELS.licenseeRoyaltyLabels,
          refKey: REF_CODE_ROYALTY,
        },
      ]),
    ],
  ];

  const otherTableItemsList = createEditArticleSingleTableShellList([
    {
      title: SHELL_TITLE[DEAL_TERMS_KEYS.otherFinancialTermsScLa],
      text: SHELL_TEXT.licenseeOtherFinancialTermsText,
      labels: SHELL_LABELS.licenseeOtherFinancialTermsLabels,
      refKey: REF_CODE_OTHER_FINANCIAL_TERMS,
    },
  ]);

  return (
    <DealTermsTableContainer
      topTableItems={topTableItems}
      bottomTableHeaderItems={bottomTableHeaderItems}
      bottomTableItemsList={bottomTableItemsList}
      otherTableItemsList={otherTableItemsList}
      noBackgroundArrow={noBackgroundArrow}
      isEditable
    />
  );
};

export default LicenseAgreementTable;
