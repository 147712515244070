"use client";

import styled from "styled-components";

export const LnbWrapper = styled.div`
  display: block;
  height: 5.6rem;
  padding: 0 4rem;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    padding: 0;
  }
`;

export const ContentsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const AccessDeniedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 5.6rem);
  max-width: 68rem;
  width: 100%;
  gap: 2.4rem;
  padding: 0 4rem;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    padding: 0 1.6rem;
  }
`;

export const AccessDeniedImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
`;

export const GuideText = styled.h1`
  color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 140%;
`;

export const Description = styled.p`
  color: var(--Color-Common-Text-Secondary, #515c6c);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 145%;
`;
export const SubDescription = styled.p`
  color: var(--Color-Common-Text-Interactive-Primary, #0198e9);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 145%;
`;
