import React from "react";

import {
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
} from "@frontend/common/src/shared/components/core";

import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { REF_CODE_APPROVAL_PROCESS_ADDITIONAL_INFORMATION_ON_APPROVAL_PROCESS } from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { ParagraphContent } from "@EditArticleContainer/shared/types/paragraphContents/paragraphContents.types";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

interface Props {
  tableData: ParagraphContent["TC213"]["text"]["Approval Process"]["Additional Information on the Approval Process"];
}

const COMMON_EDITABLE_TEXT_STYLE = {
  padding: "0.8rem",
  borderRadius: "0",
};
const VALUE_SHELL_PADDING = "0.2rem";

const ApprovalProcessAdditionalInformationTable = ({ tableData }: Props) => {
  const additionalInformationText = createMultiLineText({
    textList: tableData?.value.map?.((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });
  const additionalInformationLabels = tableData?.value.at(0)?.labels || [];

  return (
    <TableContainer isDisplay={!!additionalInformationText}>
      <TableRowContainer>
        <TableShellTitle>
          Additional Information on Approval Process
        </TableShellTitle>
      </TableRowContainer>
      <TableRowContainer>
        <TableShellValue padding={VALUE_SHELL_PADDING}>
          <EditableTextContainer
            item={{
              text: additionalInformationText,
              labels: additionalInformationLabels,
            }}
            refKey={
              REF_CODE_APPROVAL_PROCESS_ADDITIONAL_INFORMATION_ON_APPROVAL_PROCESS
            }
            canTextEdit
            style={COMMON_EDITABLE_TEXT_STYLE}
          />
        </TableShellValue>
      </TableRowContainer>
    </TableContainer>
  );
};

export default ApprovalProcessAdditionalInformationTable;
