import React, { useCallback, useEffect } from "react";

import { LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS } from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import {
  DEFAULT_FOCUS_STATUS,
  FocusStatus,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  PositionInfo,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useHandleDealTermsLicenseeTableGroupShell } from "./useHandleDealTermsLicenseeTableGroupShell";

interface UseHandleGroupShellReturn {
  focusStatus: FocusStatus;
  currentInfoStatus: PositionInfo;
  isFocused: boolean;
  isClearGroupShell: boolean;
  isDeleteGroupShell: boolean;
  isAlertGroupShell: boolean;
  isEnableEdit: boolean;
  isLastGroupShell: boolean;
  isFocusedAddButton: boolean;
  handleDefaultFocus: () => void;
  handleFocusGroupShell: () => void;
  clickAddGroupShellButton: (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
    },
  ) => void;
  handleClickToDeleteGroupShell: () => void;
  changePositionInfo: () => void;
  changePositionInfoToDefault: () => void;
  changeFocusStatus: (focusStatus: FocusStatus) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: DealsSection;
    groupTableIndex: number;
    groupShellSectionKey: string | null;
    groupShellSectionIndex: number | null;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
  groupShellCount: number;
}

const isFocusedGroupShellSection: string[] = [
  LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS.financialTerms,
];

export const useHandleLicenseAgreementTableCreatorGroupShell = ({
  positionPropsInfo,
  groupShellCount,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    groupShellSectionKey,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const articleSection = "Deals";
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { validatedInfo, changeValidatedInfo } = useValidatedInTableCreator();

  const {
    clickAddRightScopeGroupShellButton,
    handleClickToDeleteLicenseeTableGroupShell,
    handleKeyDownToDeleteLicenseeTableGroupShell,
  } = useHandleDealTermsLicenseeTableGroupShell({ positionPropsInfo });

  const { data: articleData } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );

  const isEnableEdit = articleData
    ? ENABLE_GROUP_TABLE_KEY_LIST[articleSection][groupTableKey].includes(
        articleData.draftArticleStatus,
      )
    : false;
  const isLastGroupShell = groupShellIndex === groupShellCount - 1;

  const isFocused =
    info.groupTableKey === groupTableKey &&
    info.groupTableIndex === groupTableIndex &&
    info.tableIndex === tableIndex &&
    info.tableKey === tableKey &&
    info.groupShellKey === groupShellKey &&
    info.groupShellIndex === groupShellIndex &&
    info.subGroupShellKey === null &&
    info.subGroupShellIndex === null &&
    info.shellKey === null &&
    isEnableEdit;

  const isFocusedAddButton =
    focusStatus.container === "noFocus" && isEnableEdit;

  const clickAddGroupShellButton = (
    event: React.MouseEvent<HTMLButtonElement>,
    groupShellInfo: {
      tableIndexParameter: number;
      groupShellIndexParameter: number;
      groupShellKeyParameter: string | null;
    },
  ) => {
    clickAddRightScopeGroupShellButton(event, groupShellInfo);
  };

  const handleFocusGroupShellSection = () => {
    changeInfo({
      ...positionPropsInfo,
      groupShellKey: null,
      groupShellIndex: null,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
    changeFocusStatus({ type: "clear", container: "groupShellSection" });
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleFocusGroupShell = () => {
    if (isFocusedGroupShellSection.includes(groupShellSectionKey || ""))
      return handleFocusGroupShellSection();

    changeInfo({
      ...positionPropsInfo,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
    changeFocusStatus({ type: "clear", container: "groupShell" });
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const changePositionInfo = () => {
    changeInfo({
      groupTableKey,
      groupTableIndex,
      tableKey,
      tableIndex,
      groupShellKey,
      groupShellIndex,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
  };

  const changePositionInfoToDefault = () => {
    changeInfo(DEFAULT_POSITION_INFO);
  };

  const handleDefaultFocus = () => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const isClearGroupShell =
    isFocused &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    info.subGroupShellKey !== "Milestone" &&
    focusStatus.type === "clear" &&
    focusStatus.container === "groupShell";

  const isDeleteGroupShell =
    isFocused &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    info.subGroupShellIndex === null &&
    info.subGroupShellKey === null &&
    focusStatus.type === "delete" &&
    focusStatus.container === "groupShell";

  const isAlertGroupShell =
    validatedInfo.filter(
      (validatedItem) =>
        validatedItem.groupTableKey === groupTableKey &&
        validatedItem.groupTableIndex === groupTableIndex &&
        validatedItem.tableIndex === tableIndex &&
        validatedItem.groupShellKey === groupShellKey &&
        validatedItem.groupShellIndex === groupShellIndex &&
        validatedItem.shellKey === null &&
        validatedItem.shellIndex === null &&
        validatedItem.valueIndex === null &&
        validatedItem.type === "alert" &&
        validatedItem.container === "groupShell",
    ).length > 0;

  const handleClickToDeleteGroupShell = () => {
    handleClickToDeleteLicenseeTableGroupShell();
  };

  const handleKeyDownToDeleteGroupShell = useCallback(
    (event: KeyboardEvent) => {
      if (info.groupTableKey !== "Licensee Table") return;
      handleKeyDownToDeleteLicenseeTableGroupShell(event);
    },
    [handleKeyDownToDeleteLicenseeTableGroupShell, info.groupTableKey],
  );

  useEffect(() => {
    if (focusStatus.container !== "groupShell") return;
    window.addEventListener("keydown", handleKeyDownToDeleteGroupShell);

    return () => {
      window.removeEventListener("keydown", handleKeyDownToDeleteGroupShell);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusStatus]);

  return {
    focusStatus,
    currentInfoStatus: info,
    isFocused,
    isClearGroupShell,
    isDeleteGroupShell,
    isAlertGroupShell,
    isEnableEdit,
    isLastGroupShell,
    isFocusedAddButton,
    handleFocusGroupShell,
    clickAddGroupShellButton,
    handleClickToDeleteGroupShell,
    handleDefaultFocus,
    changePositionInfo,
    changePositionInfoToDefault,
    changeFocusStatus,
  };
};
