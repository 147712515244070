import { getRange } from "./getRange";

// 현재 사용되지 않으나, 추후에 사용될 수 있으므로 남겨둔다.
// const addLabel__legacy = (
//   editableText: EditableText,
//   newLabel: Label,
// ) => {
//   const newTextContents = { ...editableText };

//   // 겹치는 라벨은 모두 제거한다.
//   let newLabels = newTextContents.labels.filter(
//     ({ position: [start, end] }) =>
//       newLabel.position[0] > end || newLabel.position[1] < start,
//   );

//   // 새로운 라벨을 추가한다.
//   newLabels.push(newLabel);

//   // 라벨을 시작점 기준으로 정렬한다.
//   newLabels = newLabels.sort(
//     ({ position: [startA] }, { position: [startB] }) => startA - startB,
//   );

//   newTextContents.labels = newLabels;
//   return newTextContents;
// };

export const addLabel = (entity: string, attributionId?: string) => {
  const range = getRange();
  if (range.collapsed) return;

  if (
    range.startContainer === range.endContainer &&
    range.startContainer.parentNode?.nodeName === "MARK"
  ) {
    const markNode = range.startContainer.parentNode as HTMLElement;
    if (markNode.dataset.entity) {
      markNode.dataset.entity = entity;
    }
  } else {
    const allText = range.cloneContents().textContent ?? "";
    const textNode = document.createTextNode(allText);
    range.deleteContents();
    range.insertNode(textNode);
    const markNode = document.createElement("mark");
    markNode.dataset.entity = entity;
    if (attributionId) markNode.dataset.attributionId = attributionId;

    range.surroundContents(markNode);
    range.setStartAfter(markNode);
    range.setEndAfter(markNode);
  }
};

export const removeLabel = () => {
  const range = getRange();
  if (range.collapsed) return;

  if (
    range.startContainer === range.endContainer &&
    range.startContainer.parentNode?.nodeName === "MARK"
  ) {
    const allText = range.startContainer.textContent ?? "";
    const textNode = document.createTextNode(allText);
    const markNode = range.startContainer.parentNode;
    markNode?.parentNode?.replaceChild(textNode, markNode);
    range.insertNode(textNode);
    range.setStartAfter(textNode);
    range.setEndAfter(textNode);
  } else {
    const allText = range.cloneContents().textContent ?? "";
    const textNode = document.createTextNode(allText);
    range.deleteContents();
    range.insertNode(textNode);
    range.setStartAfter(textNode);
    range.setEndAfter(textNode);
  }
};
