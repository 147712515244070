export const ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS =
  "Expedited Program Contents";

export const ENTRY_DATA_EXPEDITED_PROGRAM_CONTENTS_KEYS = {
  updateDate: "Update Date",
  designatedCompanyName: "Designated Company name",
  regulatoryAgency: "Regulatory Agency",
  expeditedProgram: "Expedited Program",
  designatedIndication: "Designated Indication",
  additionalInformationOnExpeditedProgram:
    "Additional Information on Expedited Program",
} as const;
