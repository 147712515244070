import { SECTION_CODE_ACCORDING_TO_CLASSIFICATION } from "@frontend/common";

import { getArticleInfoData } from "@api/articleInfo/useArticleInfo";
import { useGetMainLabelInfoQuery } from "@api/mainLabel/mainLabel";

import { MainLabelInfoData } from "../types/articleMainLabel.types";

export const useMainLabelInfoList = (): MainLabelInfoData => {
  const articleInfo = getArticleInfoData();
  const classification = articleInfo?.data.classificationManual || "";
  const articleSectionType =
    SECTION_CODE_ACCORDING_TO_CLASSIFICATION[classification];
  const { data } = useGetMainLabelInfoQuery(articleSectionType);

  const defaultMainLabelInfo = data?.data
    ?.sort((a, b) => a.viewOrd - b.viewOrd)
    .map((item) => {
      return {
        labelName: item.labelName,
        type: item.type,
        articleSection: item.articleSection,
        entity: item.entity,
        value: [],
      };
    });

  return defaultMainLabelInfo;
};
