import styled from "styled-components";

export const StatusFilterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &:first-child {
  }
  &:last-child {
    border-bottom: none;
  }
`;

export const StatusFilterFieldset = styled.fieldset`
  display: flex;
  flex-wrap: wrap;
  width: 50rem;
  gap: 1.2rem;
`;

export const StatusFilterContent = styled.div`
  width: 100%;
  text-align: left;
  word-break: keep-all;
  margin-top: 1.6rem;
`;

export const StatusFilterTitle = styled.h3`
  display: flex;
  align-items: center;
  height: 1.2rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.color.neural.gray09};
  padding: 0;
  gap: 2rem;
  gap: 2rem;
`;
