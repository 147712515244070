import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const IconTrendingDown = ({ width = 16, height = 16 }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.22576 4.00408C1.59188 3.63796 2.18547 3.63796 2.55158 4.00408L6.05534 7.50783L8.1702 5.39297C8.53632 5.02685 9.12991 5.02685 9.49603 5.39297L13.1734 9.07034L13.1734 8.00032C13.1734 7.48256 13.5931 7.06282 14.1109 7.06283C14.6287 7.06283 15.0484 7.48256 15.0484 8.00033L15.0484 11.3337C15.0484 11.8514 14.6287 12.2712 14.1109 12.2712H10.7776C10.2598 12.2712 9.84007 11.8514 9.84007 11.3337C9.84007 10.8159 10.2598 10.3962 10.7776 10.3962H11.8476L8.83312 7.38171L6.71825 9.49657C6.35213 9.86269 5.75854 9.86269 5.39243 9.49657L1.22576 5.3299C0.859643 4.96379 0.859643 4.3702 1.22576 4.00408Z"
        fill="white"
      />
    </svg>
  );
};

export default IconTrendingDown;
