import React from "react";

import * as S from "./ValidationMessage.style";

interface Props {
  messageInfo: [boolean, string];
  openValidationInfo: () => void;
  isMultipleValue?: boolean;
}

const IconArrow = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.64647 3.75777C7.45121 3.95304 7.45121 4.26962 7.64647 4.46488L10.6818 7.50022H4.11114C3.835 7.50022 3.61114 7.72408 3.61114 8.00022C3.61114 8.27637 3.835 8.50022 4.11114 8.50022H10.6818L7.64647 11.5356C7.45121 11.7308 7.45121 12.0474 7.64647 12.2427C7.84174 12.4379 8.15832 12.4379 8.35358 12.2427L12.2425 8.35378C12.3362 8.26001 12.3889 8.13283 12.3889 8.00022C12.3889 7.86762 12.3362 7.74044 12.2425 7.64667L8.35358 3.75778C8.15832 3.56251 7.84174 3.56251 7.64647 3.75777Z"
      fill="#E53E06"
    />
  </svg>
);

const IconInfo = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99989 3.06934C5.27682 3.06934 3.06934 5.27682 3.06934 7.99989C3.06934 10.723 5.27682 12.9304 7.99989 12.9304C10.723 12.9304 12.9304 10.723 12.9304 7.99989C12.9304 5.27682 10.723 3.06934 7.99989 3.06934ZM1.81934 7.99989C1.81934 4.58646 4.58646 1.81934 7.99989 1.81934C11.4133 1.81934 14.1804 4.58646 14.1804 7.99989C14.1804 11.4133 11.4133 14.1804 7.99989 14.1804C4.58646 14.1804 1.81934 11.4133 1.81934 7.99989ZM7.9999 7.37489C8.34508 7.37489 8.6249 7.65471 8.6249 7.99989V10.2221C8.6249 10.5673 8.34508 10.8471 7.9999 10.8471C7.65472 10.8471 7.3749 10.5673 7.3749 10.2221V7.99989C7.3749 7.65471 7.65472 7.37489 7.9999 7.37489ZM7.9999 5.15267C7.65472 5.15267 7.3749 5.43249 7.3749 5.77767C7.3749 6.12285 7.65472 6.40267 7.9999 6.40267H8.00545C8.35063 6.40267 8.63045 6.12285 8.63045 5.77767C8.63045 5.43249 8.35063 5.15267 8.00545 5.15267H7.9999Z"
      fill="#E53E06"
    />
  </svg>
);

const ValidationMessage = ({
  messageInfo,
  openValidationInfo,
  isMultipleValue = false,
}: Props) => {
  const isPassed = messageInfo[0];
  const message = messageInfo[1];
  return (
    <S.ValidationMessageWrapper
      $isMultipleValue={isMultipleValue}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      // 부모 컴포넌트에 걸려있는 Event를 막기 위한 이벤트
      onMouseEnter={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      onMouseLeave={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      onFocus={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <S.MessageText $isPassed={isPassed}>
        {IconArrow}
        {message}
      </S.MessageText>
      {!isPassed && (
        <S.ValidationInfoButton
          type="button"
          onClick={() => openValidationInfo()}
        >
          {IconInfo}
        </S.ValidationInfoButton>
      )}
    </S.ValidationMessageWrapper>
  );
};

export default ValidationMessage;
