import { atomFamily, useRecoilState, useRecoilValue } from "recoil";

import { getInitEntryData } from "@api/initEntryData/initEntryData";

import { RECOIL_KEYS } from "./recoilKeys";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

type ArticleId = string;

const initEntryDataState = atomFamily<ArticleEntryData, ArticleId>({
  key: RECOIL_KEYS.initEntryDataState,
  default: (articleId) => getInitEntryData(articleId),
});

export const useInitEntryDataValue = (articleId: ArticleId) =>
  useRecoilValue(initEntryDataState(articleId));

export const useInitEntryDataState = (articleId: ArticleId) =>
  useRecoilState(initEntryDataState(articleId));
