import styled, { css } from "styled-components";

export const ScrollTableWrapper = styled.div<{
  isTableScroll: boolean;
}>`
  ${({ isTableScroll }) =>
    isTableScroll &&
    css`
      padding-top: 0rem;
      padding-bottom: 2rem;
      overflow-x: scroll;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        height: 0.8rem;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: var(--Border-Radius-circle, 99999.9rem);
        background: var(--Color-Bg-Tertiary, #cbd0d8);

        &:hover {
          background: var(--Color-Bg-Interactive-Primary-Hovered, #0198e9);
        }

        &:active {
          background: var(--Color-Bg-Interactive-Primary-Pressed, #017abc);
        }
      }
      &::-webkit-scrollbar-track {
        background: var(--Color-Bg-Secondary, #f0f2f4);
      }
    `}
`;
