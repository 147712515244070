import React from "react";

import useArticleSection from "@/components/business/FillInTableContainer/hooks/useArticleSection";
import { useGetInfoOfShell } from "@FillInTableContainer/shared/hooks/useGetInfoOfShell";
import { useGetNumberOfShell } from "@FillInTableContainer/shared/hooks/useGetNumberOfShell";
import {
  GroupTableKeyType,
  TableCreatorShellList,
} from "@FillInTableContainer/shared/types/tableCreator.types";
import { getCustomTitleShellKey } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import FillInTableTableCreatorTitleShell from "./components/FillInTableTableCreatorTitleShell/FillInTableTableCreatorTitleShell";
import TableCreatorRowTitle from "./components/TableCreatorRowTitle/TableCreatorRowTitle";
import * as S from "./FillInTableTableCreatorTitleRowContainer.style";

interface Props {
  positionPropsInfo: {
    groupTableKey: GroupTableKeyType;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number;
    tableKey: string | null;
    tableIndex: number;
  };
  shellList: TableCreatorShellList;
  isTableScroll: boolean;
}

const FillInTableTableCreatorTitleRowContainer = ({
  positionPropsInfo,
  shellList,
  isTableScroll,
}: Props) => {
  const { shellCount } = useGetNumberOfShell();
  const { shellValue } = useGetInfoOfShell();
  const articleSection = useArticleSection();
  const { groupTableIndex, groupShellIndex, tableIndex } = positionPropsInfo;

  return (
    <S.FillInTableTableCreatorTitleRowWrapper isTableScroll={isTableScroll}>
      {shellList.map(({ shellKey }, shellListIndex) => (
        <S.TitleRow key={`shellList-${String(shellListIndex)}`}>
          {newArrayToCountValue(
            shellCount(shellKey, tableIndex, groupShellIndex),
          ).map((_, shellIndex) => (
            <React.Fragment
              key={`shell-${String(groupTableIndex)}-${String(
                tableIndex,
              )}-${String(groupShellIndex)}-${String(shellIndex)}`}
            >
              {getCustomTitleShellKey(articleSection, shellKey) ? (
                <FillInTableTableCreatorTitleShell
                  positionPropsInfo={{
                    ...positionPropsInfo,
                    shellIndex,
                    shellKey: getCustomTitleShellKey(articleSection, shellKey),
                  }}
                  valueInfo={shellValue(
                    "Group Name",
                    tableIndex,
                    groupShellIndex,
                    shellIndex,
                  )}
                  shellCount={shellCount(shellKey, tableIndex, groupShellIndex)}
                />
              ) : (
                <TableCreatorRowTitle
                  positionPropsInfo={{
                    ...positionPropsInfo,
                    shellIndex: 0,
                    shellKey,
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </S.TitleRow>
      ))}
    </S.FillInTableTableCreatorTitleRowWrapper>
  );
};

export default FillInTableTableCreatorTitleRowContainer;
