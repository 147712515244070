import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface LoaderWrapperProps {
  width: string;
  height: string;
}

export const LoaderWrapper = styled.div<LoaderWrapperProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

export const Loader = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  animation: ${spin} 1s ease-in-out infinite;
`;
