import dayjs from "dayjs";
import { Link } from "react-router-dom";

import { PRODUCT_NAME } from "@constants/productDefault.constants";
import logoImage from "@images/common/logo-symbol.svg";
import { useHandleSignOut } from "@libs/authorization/hooks/useHandleSignOut";
import { ROUTE_PATHS } from "@routes/routePath";

import * as S from "./Header.style";

const TEXT_SIGN_OUT = "Sign Out";

const Header = () => {
  const { handleSignOut } = useHandleSignOut();

  return (
    <S.HeaderWrapper>
      <S.HeaderCenterBox>
        <S.HeaderColumn>
          <h1>
            <Link to={ROUTE_PATHS.pressList}>
              <img src={logoImage} alt="go to home" />
              <span>{PRODUCT_NAME}</span>
            </Link>
          </h1>
          <S.Date>{dayjs().format("MMMM DD, YYYY")}</S.Date>
        </S.HeaderColumn>
        <S.HeaderColumn>
          <S.SignOutButton type="button" onClick={handleSignOut}>
            {TEXT_SIGN_OUT}
          </S.SignOutButton>
        </S.HeaderColumn>
      </S.HeaderCenterBox>
    </S.HeaderWrapper>
  );
};

export default Header;
