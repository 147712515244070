import React from "react";

import { useSaveEditedArticle } from "@EditArticleContainer/shared/hooks/useSaveEditedArticle";
import { useMovePage } from "@hooks/useMovePage";
import { useUpdateArticleStatus } from "@hooks/useUpdateArticleStatus";
import IconCheck from "@images/editArticle/icon-check-button.svg";
import { toastArticlePublish, toastFail } from "@utils/toast";

import { useDisabledPublishButton } from "./hooks/useDisabledPublishButton";
import { usePublish } from "./hooks/usePublish";
import * as S from "./PublishButton.style";

const PublishButton = () => {
  const { saveArticleContent } = useSaveEditedArticle();
  const updateArticleStatus = useUpdateArticleStatus();
  const disabledPublishButton = useDisabledPublishButton();
  const movePage = useMovePage();
  const publish = usePublish();

  const handlePublishButtonClick = async () => {
    if (disabledPublishButton) {
      toastFail("Please select image before publishing.", "bottomLeft");
      return;
    }
    if (window.confirm("Are you sure you want to publish?") === false) return;
    await saveArticleContent();

    const publishedData = await publish();
    if (!publishedData || !publishedData.urlTitle) {
      return;
    }
    const { urlTitle } = publishedData;

    movePage("pressList");
    updateArticleStatus("published");
    toastArticlePublish(urlTitle);
  };

  return (
    <S.NextButton onClick={handlePublishButtonClick}>
      Publish
      <img src={IconCheck} alt="go to next step page" />
    </S.NextButton>
  );
};

export default PublishButton;
