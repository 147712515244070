import ResetIcon from "@images/pressList/icon-reload.svg";
import SearchFilterTag from "@PressListContainer/shared/components/SearchFilterTag/SearchFilterTag";
import { usePressListFilterState } from "@stores/pressList";

import {
  CLASSIFICATION_CODE,
  EDITOR_INFO_CODE,
  STATUS_FILTER,
  StatusCode,
} from "./constants/filterCode.constants";
import * as S from "./PressListSearchResult.style";

interface SearchType {
  [key: string]: string | undefined;
}

const PressListSearchResult = () => {
  const [pressListFilter, setPressListFilter] = usePressListFilterState();

  const {
    search,
    pressListDataTotal,
    confirmClassificationAi,
    confirmClassificationManual,
    status,
  } = pressListFilter;

  const isSearch =
    (search && !!Object.keys(search).length) ||
    !!confirmClassificationAi.length ||
    !!confirmClassificationManual.length ||
    !!status.length;

  const handleDeleteClassificationAI = (currentCode?: string) => {
    const deleteClassificationAiFilter = confirmClassificationAi.filter(
      (filterCode) => filterCode !== currentCode,
    );

    setPressListFilter({
      ...pressListFilter,
      confirmClassificationAi: deleteClassificationAiFilter,
      page: 1,
    });
  };

  const handleDeleteClassificationManual = (currentCode: string) => {
    const deleteClassificationManualFilter = confirmClassificationManual.filter(
      (filterCode) => filterCode !== currentCode,
    );

    setPressListFilter({
      ...pressListFilter,
      confirmClassificationManual: deleteClassificationManualFilter,
      page: 1,
    });
  };

  const handleDeleteStatus = (currentCode: string) => {
    const deleteStatusFilter = status.filter(
      (filterCode) => filterCode !== currentCode,
    );

    setPressListFilter({
      ...pressListFilter,
      status: deleteStatusFilter,
      page: 1,
    });
  };

  const handleDeleteSearch = (currentKey: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [currentKey]: currentSearch, ...otherSearch } =
      search as SearchType;

    setPressListFilter({
      ...pressListFilter,
      search: {
        ...otherSearch,
      },
      page: 1,
    });
  };

  const handleResetFilter = () => {
    setPressListFilter({
      ...pressListFilter,
      search: {},
      confirmClassificationAi: [],
      confirmClassificationManual: [],
      status: [],
    });
  };

  const handleCheckPressIdSearch = (key: string, value: string) => {
    if (key !== "pressId") return value;

    const pressIdValue = value.replace(/#/g, "");

    return `#${pressIdValue}`;
  };

  return (
    <>
      {isSearch && (
        <S.SearchResult>
          <S.SearchResultText>Search Results for</S.SearchResultText>
          {search &&
            Object.entries(search).map(
              ([key, value]) =>
                value && (
                  <SearchFilterTag
                    key={key}
                    type="result"
                    text={handleCheckPressIdSearch(key, value)}
                    isAllInfo={!!(key === "allInfo" || key === "allEditor")}
                    isUserInput
                    code={EDITOR_INFO_CODE[key]}
                    onClickDeleteButton={() => handleDeleteSearch(key)}
                  />
                ),
            )}
          {confirmClassificationAi &&
            confirmClassificationAi.map((code) => (
              <SearchFilterTag
                key={code}
                type="result"
                text={CLASSIFICATION_CODE[code]}
                isAllInfo={false}
                isClassification
                aiSelected
                onClickDeleteButton={() => handleDeleteClassificationAI(code)}
              />
            ))}
          {confirmClassificationManual &&
            confirmClassificationManual.map((code) => (
              <SearchFilterTag
                key={code}
                type="result"
                text={CLASSIFICATION_CODE[code]}
                isAllInfo={false}
                isClassification
                onClickDeleteButton={() =>
                  handleDeleteClassificationManual(code)
                }
              />
            ))}
          {status &&
            status.map((statusCode) => (
              <SearchFilterTag
                key={statusCode}
                type="result"
                text={STATUS_FILTER[statusCode as StatusCode]}
                isAllInfo={false}
                code={statusCode}
                onClickDeleteButton={() => handleDeleteStatus(statusCode)}
              />
            ))}
          <S.SearchTotalCount>({pressListDataTotal})</S.SearchTotalCount>
          <S.ResetButton onClick={handleResetFilter}>
            <img src={ResetIcon} width={10} alt="filter reset icon" />
            <S.ResetButtonText>Filter Reset</S.ResetButtonText>
          </S.ResetButton>
        </S.SearchResult>
      )}
    </>
  );
};

export default PressListSearchResult;
