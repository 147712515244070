import {
  ENTRY_DATA_INVESTORS_GROUP_TABLE_KEY as INVESTORS,
  ENTRY_DATA_INVESTORS_TABLE_KEYS as INVESTORS_TABLE_KEYS,
  ENTRY_DATA_INVESTORS_GROUP_SHELL_KEYS as INVESTORS_GROUP_SHELL_KEYS,
  ENTRY_DATA_INVESTORS_SHELL_KEYS as INVESTORS_SHELL_KEYS,
} from "@frontend/common";

import { VentureCapitalGroupTable } from "@/types/ventureCapital.types";
import { TableCreatorTableList } from "@FillInTableContainer/shared/types/tableCreator.types";

interface VentureCapitalFundedCompanyLayout {
  groupTableKey: VentureCapitalGroupTable;
  tableList: TableCreatorTableList;
}

export const VENTURE_CAPITAL_INVESTORS_GROUP_TABLE_LAYOUT: VentureCapitalFundedCompanyLayout =
  {
    groupTableKey: INVESTORS,
    tableList: [
      {
        tableKey: INVESTORS_TABLE_KEYS.none,
        groupShellList: [
          {
            groupShellKey: INVESTORS_GROUP_SHELL_KEYS.none,
            shellList: [
              {
                shellKey: INVESTORS_SHELL_KEYS.investorName,
              },
            ],
          },
        ],
      },
    ],
  };
