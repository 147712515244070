import { useNavigate } from "react-router-dom";

import Modal from "@atoms/Modal/Modal";
import { ROUTE_PATHS } from "@routes/routePath";

import * as S from "./PressListPageEntryGuide.style";

interface Props {
  draftArticleId: number;
  closeModal: () => void;
  updatePressList: () => void;
}

const PressListPageEntryGuide = ({
  closeModal,
  draftArticleId,
  updatePressList,
}: Props) => {
  const navigate = useNavigate();
  const moveToPressClassification = () => {
    navigate(`${ROUTE_PATHS.pressClassification}?id=${draftArticleId}`);
  };

  const handleRefreshEvent = () => {
    updatePressList();
    closeModal();
  };

  return (
    <Modal closeModal={closeModal}>
      <S.PressListEntryModalWrapper>
        <S.ModalTitle>Another editor is currently working</S.ModalTitle>
        <S.ModalText>
          Access is not available because there is another editor who currently
          working on the press you tried to access. Please try again after the
          editor has completed the task. You can view the{" "}
          <S.PressClassificationLink
            type="button"
            onClick={() => moveToPressClassification()}
          >
            Press Classification
          </S.PressClassificationLink>
          .
        </S.ModalText>
        <S.ModalButtonWrapper>
          <S.CancelButton type="button" onClick={closeModal}>
            Cancel
          </S.CancelButton>
          <S.RefreshButton type="button" onClick={handleRefreshEvent}>
            Refresh the page
          </S.RefreshButton>
        </S.ModalButtonWrapper>
      </S.PressListEntryModalWrapper>
    </Modal>
  );
};

export default PressListPageEntryGuide;
