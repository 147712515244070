import Button from "@atoms/Button/Button";
import Checkbox from "@atoms/Checkbox/Checkbox";

import SignInInput from "./components/SignInInput/SignInInput";
import SignInLoader from "./components/SignInLoader/SignInLoader";
import { useSignIn } from "./hooks/useSignIn";
import { useSignInForm } from "./hooks/useSignInForm";
import * as S from "./SignInForm.style";

const SignInForm = () => {
  const { register, handleSubmit, errors } = useSignInForm();
  const { submitSignIn, toggleRememberMe, checkedRememberMe, isPending } =
    useSignIn();

  return (
    <>
      <SignInLoader isLoading={isPending} />
      <S.SignInFormWrapper>
        <S.SignInInputBox>
          <SignInInput
            {...register("email")}
            type="email"
            label="Email Address"
            placeholder="johndoe@example.com"
            error={errors.email?.message}
          />
          <SignInInput
            {...register("password")}
            type="password"
            label="Password"
            placeholder="• • • • • • • •"
            error={errors.password?.message}
          />
          <Checkbox checked={checkedRememberMe} onChange={toggleRememberMe}>
            Remember Me
          </Checkbox>
        </S.SignInInputBox>

        <Button
          type="submit"
          buttonStyle="solid"
          onClick={handleSubmit(submitSignIn)}
        >
          Sign In
        </Button>
      </S.SignInFormWrapper>
    </>
  );
};

export default SignInForm;
