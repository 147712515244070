import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { useCompanyLogoInfoQuery } from "@api/companyLogo/companyLogo";
import { useSectionCodeToClassification } from "@hooks/useSectionCode";

export const useGetCompanyNameList = () => {
  const { data: articleInfo } = useArticleInfoQuery();
  const articleId = articleInfo?.draftArticleId ?? "";
  const sectionCode = useSectionCodeToClassification();
  const { data: companyLogoInfoList } = useCompanyLogoInfoQuery(
    articleId,
    sectionCode,
  );

  const getCompanyNameList = () => {
    return (
      companyLogoInfoList?.data?.data?.map(
        (logoInfo) => logoInfo.companyName,
      ) || []
    );
  };

  const getMainCompanyLogos = () => {
    return (
      companyLogoInfoList?.data?.data
        ?.filter(
          (logoInfo) =>
            logoInfo.main === true && logoInfo.companyLogoUri !== null,
        )
        ?.map((logoInfo) => logoInfo.companyLogoUri) || []
    );
  };

  const getMainCompanyNameList = () => {
    return (
      companyLogoInfoList?.data?.data
        ?.filter(
          (logoInfo) => logoInfo.main === true && logoInfo.companyName !== null,
        )
        ?.map((logoInfo) => logoInfo.companyName) || []
    );
  };

  return {
    companyLogoInfos: companyLogoInfoList?.data?.data || [],
    mainCompanyLogos: getMainCompanyLogos(),
    mainCompanyNameList: getMainCompanyNameList(),
    companyNameList: getCompanyNameList(),
  };
};
