import { useSuspenseQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/queryKeys";
import { editorRolesApis } from "@services/editorRoles.service";

import { queryClient } from "../../api/queryClient";

export const useEditorRolesQuery = () => {
  const query = useSuspenseQuery({
    queryKey: [QUERY_KEYS.editorRoles],
    queryFn: () => editorRolesApis.get(),
    select: ({ roles }) => roles,
    staleTime: 5000, // FIXME: 해당 시간은 임시 값으로, 추후에 정확한 값을 넣어야 함
  });

  return query;
};

export const removeEditorRoles = () =>
  queryClient.removeQueries({ queryKey: [QUERY_KEYS.editorRoles] });
