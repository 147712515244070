import { AUTHORITY_TYPE } from "../constants/authorityType.constants";
import { EDITOR_ROLES } from "../constants/editorRoles.constants";

import type { AuthorityType, EditorRoleCode } from "../types/roles.types";

const ALLOWED_EDITOR_ROLES: EditorRoleCode[] = [
  EDITOR_ROLES.EDTK0100,
  EDITOR_ROLES.EDTK0201,
  EDITOR_ROLES.EDTK0202,
  EDITOR_ROLES.EDTK0303,
  EDITOR_ROLES.EDTK0401,
  EDITOR_ROLES.EDTK0402,
  EDITOR_ROLES.EDTK0503,
  EDITOR_ROLES.EDTK0603,
  EDITOR_ROLES.EDTK0703,
  EDITOR_ROLES.EDTK0803,
];

interface Args {
  editorRoles: EditorRoleCode[];
  authorityType: AuthorityType;
}

export class PressClassificationRoleChecker {
  private editorRoles: EditorRoleCode[];

  private authorityType: AuthorityType;

  constructor({ editorRoles, authorityType }: Args) {
    this.editorRoles = editorRoles;
    this.authorityType = authorityType;
  }

  private get hasValidRoles(): boolean {
    return this.editorRoles.some((role) => ALLOWED_EDITOR_ROLES.includes(role));
  }

  private get isSuperAdmin(): boolean {
    return this.authorityType === AUTHORITY_TYPE.superAdmin;
  }

  public get canAccess(): boolean {
    return this.isSuperAdmin || this.hasValidRoles;
  }
}
