import { aiImagesApis } from "@api/aiImages/aiImages.service";
import { getPressIdFromURL } from "@utils/getStateFromURL";
import {
  toastCreateAiImageFailed,
  toastCreateAiImageSuccess,
} from "@utils/toast";

export const handleAiImageRegenerate = (pressId = getPressIdFromURL()) => {
  try {
    aiImagesApis.create(pressId);
    toastCreateAiImageSuccess();
  } catch (error) {
    toastCreateAiImageFailed();
  }
};
