import { DEAL_TYPE_KEYS } from "../../constants/dealTypeKeys.constants";
import { MERGE_AND_ACQUISITION_INPUT_TYPE_LIST } from "../../constants/mergeAndAcquisitionTypeKeys.constants";

export const formatDealTypeToLowerCase = (currentDealType: string): string => {
  const removeBlankAndUppercaseDealTypeInput = currentDealType
    .replaceAll(" ", "")
    .toLowerCase();

  if (
    !MERGE_AND_ACQUISITION_INPUT_TYPE_LIST.includes(
      removeBlankAndUppercaseDealTypeInput,
    )
  )
    return currentDealType.toLowerCase();

  return DEAL_TYPE_KEYS.mergeAndAcquisition;
};
