import styled from "styled-components";

import { UpdateButton } from "@EditArticleContainer/shared/components/UpdateButton/UpdateButton.style";

export const ParagraphDrugProfileUpdateButton = styled(UpdateButton)`
  position: absolute;
  top: 0.8rem;
  left: -1.6rem;
  transform: translateX(-100%);
`;

export const GroupTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;
