import { useCallback, useEffect, useState } from "react";

import { useInsertImageWebSocketMessages } from "@/contexts/InsertImageWebSocketMessagesContext";
import {
  useGetAiImageInfoQuery,
  useGetAiImagesQuery,
} from "@api/aiImages/aiImages";
import { AiImageList } from "@InsertImageContainer/shared/types/insertImage.types";

const AI_IMAGE_COUNT = 4;

export const useHandleAiImageList = () => {
  const [aiImageList, setAiImageList] = useState<AiImageList>([]);
  const { insertImageWebSocketMessages } = useInsertImageWebSocketMessages();

  const { data: aiImageInfo, refetch: refetchAiImageInfo } =
    useGetAiImageInfoQuery();
  const { data: aiImageData, refetch: refetchAiImageData } =
    useGetAiImagesQuery();

  const aiStatus = aiImageInfo?.data?.aiImageStatus;

  const createAiImageList = useCallback(() => {
    switch (aiStatus) {
      case "SUCCESS":
        return [
          ...aiImageData.data.map((item) => ({
            ...item,
            imageStatus: "SUCCESS",
          })),
        ].filter(({ imageId }) => !!imageId);
      case "FAIL":
        return [
          ...aiImageData.data.map((item) => ({
            ...item,
            imageStatus: "SUCCESS",
          })),
          ...new Array(AI_IMAGE_COUNT).fill({
            imageId: 0,
            imageName: "",
            imagePath: "",
            platform: "",
            artistName: "",
            keyword: null,
            imageStatus: "FAIL",
          }),
        ];
      case "PENDING":
      case "GENERATING":
        return [
          ...aiImageData.data.map((item) => ({
            ...item,
            imageStatus: "SUCCESS",
          })),
          ...new Array(AI_IMAGE_COUNT).fill({
            imageId: 0,
            imageName: "",
            imagePath: "",
            platform: "",
            artistName: "",
            keyword: null,
            imageStatus: "GENERATING",
          }),
        ];
      default:
        return [];
    }
  }, [aiImageData.data, aiStatus]);

  useEffect(() => {
    refetchAiImageInfo();
    refetchAiImageData();
  }, [insertImageWebSocketMessages, refetchAiImageInfo, refetchAiImageData]);

  useEffect(() => {
    setAiImageList(createAiImageList());
  }, [createAiImageList, aiStatus]);

  return aiImageList;
};
