import { useCallback } from "react";

import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { TableInfoProps } from "../RegulatoryTableCreatorTable";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleDrugProfileTableReturn {
  handleClickDrugProfileDeleteButton: () => void;
  handleKeyDownToDeleteDrugProfileTable: (event: KeyboardEvent) => void;
}

export const useHandleDrugProfileTable = (
  tablePropsInfo: TableInfoProps,
): UseHandleDrugProfileTableReturn => {
  const { groupTableKey, tableKey, groupTableIndex, tableIndex } =
    tablePropsInfo;

  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const articleSection = "Regulatory";

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();

  const drugProfileTableLength =
    entryData[articleSection]?.["Drug Profile"]?.length || 0;

  const handleClearTable = useCallback(() => {
    const clearDrugProfile = (): ArticleEntryData => {
      return {
        ...entryData,
        Regulatory: {
          ...entryData.Regulatory,
          "Drug Profile": entryData.Regulatory?.["Drug Profile"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  "Brand Name": {
                    ...tableInfo?.["Brand Name"],
                    value: [],
                  },
                  "Code Name": {
                    ...tableInfo?.["Code Name"],
                    value: [],
                  },
                  "INN Name": {
                    ...tableInfo?.["INN Name"],
                    value: [],
                  },
                  MOA: {
                    ...tableInfo?.MOA,
                    value: [],
                  },
                  "Therapeutic Area": {
                    ...tableInfo?.["Therapeutic Area"],
                    value: [],
                  },
                  Modality: {
                    ...tableInfo?.Modality,
                    value: [],
                  },
                  "Other Characteristics": {
                    ...tableInfo?.["Other Characteristics"],
                    value: [],
                  },
                  ROA: {
                    ...tableInfo?.ROA,
                    value: [],
                  },
                  Target: {
                    ...tableInfo?.Target,
                    value: [],
                  },
                  "Target Indication": {
                    ...tableInfo?.["Target Indication"],
                    value: [],
                  },
                  "Originator/Developer": {
                    ...tableInfo?.["Originator/Developer"],
                    value: [],
                  },
                  "Generic / Biosimilar": {
                    ...tableInfo?.["Generic / Biosimilar"],
                    value: ["false"],
                  },
                  "Ref.Product Name": {
                    ...tableInfo?.["Ref.Product Name"],
                    value: [],
                  },
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    if (info.groupTableKey === "Drug Profile") {
      setEntryData(clearDrugProfile());
      changeCurrentHistory({
        entryData: clearDrugProfile(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      if (drugProfileTableLength > 1) {
        changeFocusStatus({ type: "delete", container: "table" });
      } else {
        changeFocusStatus(DEFAULT_FOCUS_STATUS);
      }
    }
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    drugProfileTableLength,
    entryData,
    groupTableIndex,
    groupTableKey,
    info.groupTableKey,
    info.tableIndex,
    setEntryData,
    tableIndex,
    tableKey,
  ]);

  const handleDeleteTable = useCallback(() => {
    if (drugProfileTableLength > 1) {
      changeFocusStatus(DEFAULT_FOCUS_STATUS);

      const deleteDrugProfile = (): ArticleEntryData => {
        return {
          ...entryData,
          Regulatory: {
            ...entryData.Regulatory,
            "Drug Profile": entryData.Regulatory?.["Drug Profile"]?.filter(
              (tableInfo, tableInfoIndex) => tableInfoIndex !== info.tableIndex,
            ),
          },
        };
      };
      if (info.groupTableKey === "Drug Profile") {
        setEntryData(deleteDrugProfile());
        changeCurrentHistory({
          entryData: deleteDrugProfile(),
          changedDataInfo: {
            target: "table",
            action: "delete",
            position: {
              groupTableKey,
              groupTableIndex,
              tableKey,
              tableIndex,
              groupShellKey: null,
              groupShellIndex: null,
              shellKey: null,
              shellIndex: null,
              valueIndex: null,
              value: null,
            },
          },
        });
        changeInfo(DEFAULT_POSITION_INFO);
      }
    }
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    drugProfileTableLength,
    entryData,
    groupTableIndex,
    groupTableKey,
    info.groupTableKey,
    info.tableIndex,
    setEntryData,
    tableIndex,
    tableKey,
  ]);

  const handleClickDrugProfileDeleteButton = () => {
    if (focusStatus.type === "clear") {
      handleClearTable();
    }

    if (focusStatus.type === "delete") {
      handleDeleteTable();
    }
  };

  const handleKeyDownToDeleteDrugProfileTable = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearTable();
      }

      if (focusStatus.type === "delete" && event.key === "Delete") {
        handleDeleteTable();
      }
      changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    },
    [
      changeValidatedInfo,
      focusStatus.type,
      handleClearTable,
      handleDeleteTable,
    ],
  );

  return {
    handleClickDrugProfileDeleteButton,
    handleKeyDownToDeleteDrugProfileTable,
  };
};
