import { KEY_CLINICAL_TRIALS } from "@FillInTableContainer/components/FillInTable/constants/clinicalTrials.constants";
import { removeBrackets } from "@utils/removeBrackets";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

const DISABLED_PHASE_MAIN_LABEL_KEYWORDS = [
  "screening/undisclosed/undefined",
  "screening",
];

export const MAIN_LABEL_VALUE_CLINICAL_TRIALS: Record<
  string,
  (data: ArticleEntryData) => string[]
> = {
  [KEY_CLINICAL_TRIALS.drugName]: (data: ArticleEntryData) => {
    const drugNamePath = data["Clinical Trials"]?.["Drug Profile"]?.[0];
    const innName = drugNamePath?.["INN Name"].value[0]?.text;
    const brandName = drugNamePath?.["Brand Name"].value[0]?.text;
    const codeName = drugNamePath?.["Code Name"].value[0]?.text;

    if (brandName) return [brandName];
    if (codeName) return [codeName];
    if (innName) return [innName];
    return [];
  },
  [KEY_CLINICAL_TRIALS.target]: (data: ArticleEntryData) =>
    [
      ...new Set(
        data["Clinical Trials"]?.["Drug Profile"]?.[0]?.Target?.value?.map(
          (item) => removeBrackets(item.text),
        ),
      ),
    ] || [],
  [KEY_CLINICAL_TRIALS.modality]: (data: ArticleEntryData) =>
    data["Clinical Trials"]?.["Drug Profile"]?.[0]?.Modality?.value?.map(
      (item) => item.text.replace("(etc)", "").trim(),
    ) || [],
  [KEY_CLINICAL_TRIALS.inclusionCriteriaDiagnostic]: (data: ArticleEntryData) =>
    data["Clinical Trials"]?.["Clinical Trial Design"]?.[0]?.[
      "Clinical Trial Design-1"
    ]?.["Inclusion Criteria - Diagnostic"]?.value?.map((item) => item.text) ||
    [],
  [KEY_CLINICAL_TRIALS.phase]: (data: ArticleEntryData) =>
    data["Clinical Trials"]?.["Clinical Trial Design"]?.[0]?.[
      "Clinical Trial Design-1"
    ]?.["Clinical Design Phase"]?.value
      ?.filter((_, index) => index === 0)
      ?.map((item) => item.text)
      ?.filter((item) => !DISABLED_PHASE_MAIN_LABEL_KEYWORDS.includes(item)) ||
    [],
  [KEY_CLINICAL_TRIALS.resultsClassification]: (data: ArticleEntryData) =>
    data["Clinical Trials"]?.["Clinical Results"]?.[
      "Results Classification"
    ]?.value?.map((item) => item.text) || [],
};

export const MAIN_LABEL_VALUE_DEALS: Record<
  string,
  (data: ArticleEntryData) => string[]
> = {
  "Drug Name": (data: ArticleEntryData) => {
    const drugNamePath = data.Deals?.["Drug Profile"]?.[0];
    const innName = drugNamePath?.["INN Name"].value[0]?.text;
    const brandName = drugNamePath?.["Brand Name"].value[0]?.text;
    const codeName = drugNamePath?.["Code Name"].value[0]?.text;

    if (brandName) return [brandName];
    if (codeName) return [codeName];
    if (innName) return [innName];
    return [];
  },
  Target: (data: ArticleEntryData) =>
    [
      ...new Set(
        data.Deals?.["Drug Profile"]?.[0]?.Target?.value?.map((item) =>
          removeBrackets(item.text),
        ),
      ),
    ] || [],
  Modality: (data: ArticleEntryData) =>
    data.Deals?.["Drug Profile"]?.[0]?.Modality?.value?.map((item) => {
      return item.text.replace("(etc)", "").trim();
    }) || [],
  "Target Indication": (data: ArticleEntryData) =>
    data.Deals?.["Drug Profile"]?.[0]?.["Target Indication"]?.value?.map(
      (item) => item.text,
    ) || [],
  Phase: (data: ArticleEntryData) =>
    data.Deals?.["Drug Profile"]?.[0]?.Phase?.value
      ?.filter((_, index) => index === 0)
      ?.map((item) => item.text)
      ?.filter((item) => !DISABLED_PHASE_MAIN_LABEL_KEYWORDS.includes(item)) ||
    [],
  "Deal Type": (data: ArticleEntryData) => {
    const initDealType =
      data.Deals?.["Deal Type & Status"]?.["Deal Type"]?.value?.map((item) =>
        item.text?.replaceAll("r&d", "R&D")?.trim(),
      ) || [];

    return initDealType;
  },
};

export const MAIN_LABEL_VALUE_REGULATORY: Record<
  string,
  (data: ArticleEntryData) => string[]
> = {
  "Drug Name": (data: ArticleEntryData) => {
    const drugNamePath = data.Regulatory?.["Drug Profile"]?.[0];
    const innName = drugNamePath?.["INN Name"].value[0]?.text;
    const brandName = drugNamePath?.["Brand Name"].value[0]?.text;
    const codeName = drugNamePath?.["Code Name"].value[0]?.text;

    if (brandName) return [brandName];
    if (codeName) return [codeName];
    if (innName) return [innName];
    return [];
  },
  Target: (data: ArticleEntryData) =>
    [
      ...new Set(
        data.Regulatory?.["Drug Profile"]?.[0]?.Target?.value?.map((item) =>
          removeBrackets(item.text),
        ),
      ),
    ] || [],
  Modality: (data: ArticleEntryData) =>
    data.Regulatory?.["Drug Profile"]?.[0]?.Modality?.value?.map((item) => {
      return item.text.replace("(etc)", "").trim();
    }) || [],
  "Target Indication": (data: ArticleEntryData) =>
    data.Regulatory?.["Drug Profile"]?.[0]?.["Target Indication"]?.value?.map(
      (item) => item.text,
    ) || [],
  "Marketing Status": (data: ArticleEntryData) =>
    data.Regulatory?.["Approval Process Contents"]?.["Approval Process"]?.[0][
      "Marketing Status"
    ].value
      ?.filter((_, index) => index === 0)
      ?.map((item) => item.text) || [],

  "Application Type": (data: ArticleEntryData) =>
    data.Regulatory?.["Approval Process Contents"]?.["Approval Process"]?.[0][
      "Application Type"
    ].value
      ?.filter((_, index) => index === 0)
      ?.map((item) => item.text) || [],
};

export const MAIN_LABEL_VALUE_EXPEDITED_PROGRAM: Record<
  string,
  (data: ArticleEntryData) => string[]
> = {
  "Drug Name": (data: ArticleEntryData) => {
    const drugNamePath = data["Expedited Program"]?.["Drug Profile"]?.[0];
    const innName = drugNamePath?.["INN Name"].value[0]?.text;
    const brandName = drugNamePath?.["Brand Name"].value[0]?.text;
    const codeName = drugNamePath?.["Code Name"].value[0]?.text;

    if (brandName) return [brandName];
    if (codeName) return [codeName];
    if (innName) return [innName];
    return [];
  },
  Target: (data: ArticleEntryData) =>
    [
      ...new Set(
        data["Expedited Program"]?.["Drug Profile"]?.[0]?.Target?.value?.map(
          (item) => removeBrackets(item.text),
        ),
      ),
    ] || [],
  Modality: (data: ArticleEntryData) =>
    data["Expedited Program"]?.["Drug Profile"]?.[0]?.Modality?.value?.map(
      (item) => {
        return item.text.replace("(etc)", "").trim();
      },
    ) || [],
  "Target Indication": (data: ArticleEntryData) =>
    data["Expedited Program"]?.["Drug Profile"]?.[0]?.[
      "Target Indication"
    ]?.value?.map((item) => item.text) || [],
  Phase: (data: ArticleEntryData) =>
    data["Expedited Program"]?.["Drug Profile"]?.[0]?.Phase?.value
      ?.map((item) => item.text)
      ?.filter((item) => !DISABLED_PHASE_MAIN_LABEL_KEYWORDS.includes(item)) ||
    [],
  "Expedited Program": (data: ArticleEntryData) =>
    data["Expedited Program"]?.["Expedited Program Contents"]?.[
      "Expedited Program"
    ].value
      ?.filter((_, index) => index === 0)
      ?.map((item) => item.text) || [],
};

export const MAIN_LABEL_VALUE_VENTURE_CAPITAL: Record<
  string,
  (data: ArticleEntryData) => string[]
> = {
  "Drug Name": (data: ArticleEntryData) => {
    const drugNamePath = data["Venture Capital"]?.["Drug Profile"]?.[0];
    const innName = drugNamePath?.["INN Name"].value[0]?.text;
    const brandName = drugNamePath?.["Brand Name"].value[0]?.text;
    const codeName = drugNamePath?.["Code Name"].value[0]?.text;

    if (brandName) return [brandName];
    if (codeName) return [codeName];
    if (innName) return [innName];
    return [];
  },
  Target: (data: ArticleEntryData) =>
    [
      ...new Set(
        data["Venture Capital"]?.["Drug Profile"]?.[0]?.Target?.value?.map(
          (item) => removeBrackets(item.text),
        ),
      ),
    ] || [],
  Modality: (data: ArticleEntryData) =>
    data["Venture Capital"]?.["Drug Profile"]?.[0]?.Modality?.value?.map(
      (item) => {
        return item.text.replace("(etc)", "").trim();
      },
    ) || [],
  "Target Indication": (data: ArticleEntryData) =>
    data["Venture Capital"]?.["Drug Profile"]?.[0]?.[
      "Target Indication"
    ]?.value?.map((item) => item.text) || [],
  Phase: (data: ArticleEntryData) =>
    data["Venture Capital"]?.["Drug Profile"]?.[0]?.Phase?.value
      ?.filter((_, index) => index === 0)
      ?.map((item) => item.text)
      ?.filter((item) => !DISABLED_PHASE_MAIN_LABEL_KEYWORDS.includes(item)) ||
    [],
  "Round Type": (data: ArticleEntryData) => {
    const initDealType =
      data["Venture Capital"]?.["Round Type & Status"]?.[
        "Round Type"
      ]?.value?.map((item) => item.text?.trim()) || [];

    return initDealType;
  },
};
