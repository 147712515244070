import React from "react";

import { Label } from "@frontend/common/src/components/EditArticle/EditableText/types/editableText.types";

export const checkEveryTextIsEmpty = (
  items: {
    title: string;
    text: string;
    labels: Label[];
    refKey: string;
    imagePosition?: "left" | "bottom";
    imageNodeList?: React.ReactNode[];
    isDisabled?: boolean;
    displayForTitle?: boolean;
    titleInShell?: boolean;
  }[],
) => {
  return items.every((item) => !item.text?.trim());
};
