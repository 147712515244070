import { DropdownListContainer } from "../types/tableCreatorDropdown.types";

export const LIST_SECTION_TITLE = {
  ai: "AI Model",
  dictionary: "Dictionary",
} as const;

export const DEFAULT_DROPDOWN_LIST: DropdownListContainer = {
  categoryDropdown: [],
  defaultDropdown: [],
};

export const CATEGORY_DROP_DOWN_SHELL_KEYS = [
  "Modality",
  "Expedited Program",
  "Application Type",
];
