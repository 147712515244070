"use client";

import styled, { css } from "styled-components";

export const SocialLoginButton = styled.button<{
  $width?: string;
  $isHover?: boolean;
}>`
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  background-color: white;
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid var(--Color-Common-Border-Interactive-Secondary, #b1b8c4);
  cursor: auto;

  ${({ $isHover }) =>
    $isHover &&
    css`
      cursor: pointer;
      &:hover {
        border: 1px solid
          var(--Color-Common-Border-Interactive-Secondary-Hovered, #96a1b0);
        background: var(
          --Color-Common-Bg-Interactive-Secondary-Hovered,
          #f0f2f4
        );
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16),
          0px 0px 2px 0px rgba(0, 0, 0, 0.12);
      }

      &:active {
        border: 1px solid
          var(--Color-Common-Border-Interactive-Secondary-Pressed, #7c899c);
        background: var(
          --Color-Common-Bg-Interactive-Secondary-Pressed,
          #e5e7eb
        );
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset,
          0px 0px 2px 0px rgba(0, 0, 0, 0.06) inset;
      }
    `}
`;
export const SocialLoginButtonText = styled.span`
  color: var(--Color-Common-Text-Interactive-Secondary, #515c6c);
  font-size: 1.4rem;
  font-weight: 500;
`;
