import React from "react";

interface Props {
  width?: number;
  height?: number;
  fill?: string;
}

const IconRightSingleArrow = ({
  width = 16,
  height = 16,
  fill = "#A8B3C5",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Chevron">
        <path
          id="Symbol"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.90688 2.17114C4.60941 2.41745 4.58529 2.83897 4.85302 3.11265L9.66699 8L4.85302 12.8874C4.58529 13.161 4.60941 13.5826 4.90688 13.8289C5.20435 14.0752 5.66253 14.053 5.93025 13.7793L11.1476 8.44598C11.3957 8.19244 11.3957 7.80756 11.1476 7.55402L5.93025 2.2207C5.66253 1.94702 5.20435 1.92484 4.90688 2.17114Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

IconRightSingleArrow.displayName = "RightSingleArrow";

export default IconRightSingleArrow;
