import React from "react";

import { getPressIdFromURL } from "@utils/getStateFromURL";

interface Props {
  children: React.ReactNode;
}

const EditArticleArticleIdGuard = ({ children }: Props) => {
  const articleId = getPressIdFromURL();

  if (articleId === "") {
    throw new Error("Article ID is not defined.");
  }

  return <>{children}</>;
};

export default EditArticleArticleIdGuard;
