import { Cookies } from "react-cookie";

/**
 * 쿠키를 관리하기 위한 스크립트
 */

interface CookieSetOptions {
  path?: string;
  expires?: Date;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: boolean | "none" | "lax" | "strict";
}

const cookies = new Cookies();

const setCookie = (name: string, value: string, options?: CookieSetOptions) => {
  cookies.set(name, value, { ...options });
};

const getCookie = (name: string) => cookies.get(name);

const removeCookie = (name: string) => {
  cookies.remove(name, { path: "/" });
};

export { setCookie, getCookie, removeCookie };
