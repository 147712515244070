import { useSuspenseQuery } from "@tanstack/react-query";

import { queryClient } from "@api/queryClient";
import { QUERY_KEYS } from "@api/queryKeys";
import { pressInfoApis } from "@services/pressInfo.service";

export const usePressInfoQuery = (pressId: number) => {
  return useSuspenseQuery({
    queryKey: [QUERY_KEYS.pressInfo, pressId],
    queryFn: () => pressInfoApis.get(pressId),
    select: ({ data: innerData }) => innerData,
    staleTime: 60 * 1000 * 1000, // FIXME: 해당 시간은 임시 값으로, 추후에 정확한 값을 넣어야 함. 본문이기 때문에 긴 시간으로 설정
  });
};

export const getPressContentData = (pressId: number) => {
  return queryClient.getQueryData([QUERY_KEYS.pressInfo, pressId]);
};
