import {
  InsertImageFilterKeys,
  InsertImageFilterKeywordEntity,
} from "../types/filterKeyword.types";

export const KEYWORD_RANK_BY_KEYS: Record<InsertImageFilterKeys, number> = {
  Modality: 1,
  "Therapeutic Area": 2,
  "Originator/Developer": 3,
  "Licensor Name": 4,
  "Licensee Name": 5,
  "Company Name": 6,
  "Investor Name": 7,
  "Classification Type": 8,
  "Authorized Company": 9,
  "Designated Company name": 10,
};

export const KEYWORD_TITLE_BY_KEYS: Record<InsertImageFilterKeys, string> = {
  Modality: "Modality",
  "Originator/Developer": "Company",
  "Therapeutic Area": "Therapeutic Area",
  "Licensor Name": "Licensor Name",
  "Licensee Name": "Licensee Name",
  "Company Name": "Company Name",
  "Investor Name": "Investor Name",
  "Classification Type": "Classification Type",
  "Authorized Company": "Company",
  "Designated Company name": "Company",
};

export const KEYWORD_ENTITY_BY_KEYS: Record<
  InsertImageFilterKeys,
  InsertImageFilterKeywordEntity
> = {
  Modality: "E030",
  "Originator/Developer": null,
  "Therapeutic Area": null,
  "Licensor Name": null,
  "Licensee Name": null,
  "Company Name": null,
  "Investor Name": null,
  "Classification Type": null,
  "Authorized Company": null,
  "Designated Company name": null,
};
