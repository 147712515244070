import React from "react";

import { ArticleContentRefMapProvider } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";

interface Props {
  children: React.ReactNode;
}

const EditArticleProvider = ({ children }: Props) => {
  return (
    <ArticleContentRefMapProvider>{children}</ArticleContentRefMapProvider>
  );
};

export default EditArticleProvider;
