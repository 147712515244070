import { DEAL_TYPE_KEYS } from "../../constants/dealTypeKeys.constants";
import { formatDealTypeToLowerCase } from "../formatDealTypeToLowerCase";

export const checkIsMergeAndAcquisitionLayout = (
  currentSelectedDealType: string[],
) => {
  const formatLowerCaseDealTypeList = currentSelectedDealType.map(
    (dealTypeItem) => formatDealTypeToLowerCase(dealTypeItem),
  );

  if (formatLowerCaseDealTypeList.includes(DEAL_TYPE_KEYS.mergeAndAcquisition))
    return true;

  return false;
};
