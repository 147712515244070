import styled from "styled-components";

import { scrollYBarStyle } from "@styles/mixin";

export const PressLabelViewerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
  border-radius: 0.4rem;
  border: 1px solid var(--grayscale-spectrum-neural-blue-gray-03, #dee5ef);
  padding: 1.6rem 1.6rem 0;
  background: #fff;
  color: #000;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  margin-top: 0.8rem;

  ${scrollYBarStyle}
`;
export const SearchInputContainer = styled.div`
  width: 100%;
  padding: 1.6rem 0;
`;
