import React from "react";

import { QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { ThemeProvider } from "styled-components";

import Toast from "@atoms/Toast/Toast";
import GlobalFont from "@styles/GlobalFont";
import GlobalStyle from "@styles/GlobalStyle";
import { theme } from "@styles/theme";

import { queryClient } from "./api/queryClient";
import ApiErrorBoundary from "./components/shared/business/ApiErrorBoundary/ApiErrorBoundary";
import GlobalErrorBoundary from "./components/shared/business/GlobalErrorBoundary/GlobalErrorBoundary";
import { InsertImageWebSocketMessagesProvider } from "./contexts/InsertImageWebSocketMessagesContext";
import { WebSocketProvider } from "./contexts/WebSocketContext";

const GlobalProvider = ({ children }: { children: React.ReactNode }) => (
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <GlobalFont />
      <GlobalStyle />
      <Toast />
      <BrowserRouter>
        <RecoilRoot>
          <InsertImageWebSocketMessagesProvider>
            <WebSocketProvider>
              <GlobalErrorBoundary>
                <ApiErrorBoundary>{children}</ApiErrorBoundary>
              </GlobalErrorBoundary>
            </WebSocketProvider>
          </InsertImageWebSocketMessagesProvider>
        </RecoilRoot>
      </BrowserRouter>
    </QueryClientProvider>
  </ThemeProvider>
);

export default GlobalProvider;
