import { apiClient } from "@api/apiClient";
import { AuthorizeData } from "@interface/auth.interface";

export const AUTHORIZE_API_ENDPOINT = {
  post: "/auth/authorize",
};

interface AuthorizeArgs {
  email: string;
  password: string;
}

export const authApis = {
  post: async ({ email, password }: AuthorizeArgs) => {
    const { data } = await apiClient.post<AuthorizeData>(
      AUTHORIZE_API_ENDPOINT.post,
      {
        email,
        password,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );

    return {
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
      roles: data.roles,
      authorityType: data.authorityType,
      accountName: data.accountName,
      accountEmail: data.accountEmail,
    };
  },
};
