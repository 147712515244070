"use client";

import styled, { css } from "styled-components";

export const FilterListWrapper = styled.div<{
  $isWide: boolean;
  $isResize: boolean;
  $height: number;
  $resultHeight: number;
  $minHeight: number;
}>`
  display: flex;
  gap: 1.6rem;
  width: 100%;
  min-height: 8.3rem;
  max-height: 17.6rem;
  padding: var(--Spacing-12, 1.2rem) var(--Spacing-16, 1.6rem)
    var(--Spacing-16, 1.6rem) var(--Spacing-16, 1.6rem);

  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Secondary, #f0f2f4);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px 0px rgba(0, 0, 0, 0.16);

  transition: all 0.6s ease-in-out;

  ${({ $isWide, $height, $resultHeight, $minHeight }) =>
    $isWide &&
    css`
      background-color: var(
        --Color-Common-Bg-Interactive-Primary-Subtle,
        #e6f6ff
      );

      transition: all 0.6s ease-in-out;

      height: ${$height / 10}rem;
      min-height: ${($resultHeight + $minHeight) / 10}rem;
      max-height: ${($resultHeight + 324) / 10}rem;
    `}

  ${({ $isResize }) =>
    $isResize &&
    css`
      transition: none;
    `}
`;
