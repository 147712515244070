import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;
  height: 100%;
`;

export const Description = styled.p`
  color: var(--Color-Common-Text-Tertiary, #7c899c);
  text-align: center;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  white-space: pre;
`;
