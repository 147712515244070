"use client";

import styled from "styled-components";

export const HoverValueWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.6rem;
  padding: 0rem 0.4rem;

  .hover-box:has(&) {
    &:hover {
      color: var(--Color-Common-Text-Interactive-Secondary-Hovered, #3d4551);
      background: var(--Color-Common-Bg-Interactive-Selected-Hovered, #e6f6ff);
    }
  }
`;
