import styled from "styled-components";

import { hideScrollbar } from "@styles/mixin";

export const LabelingPressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 12.6rem);
`;

export const ContentsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  border-radius: 0.8rem;
  border-radius: 0.8rem;
  border: 1px solid var(--Color-Border-Secondary, #b1b8c4);
  background: var(--Color-Bg-Primary, #fff);
  box-shadow: 0px 4px 8px 0px rgba(7, 68, 161, 0.02);
  padding: 0 1.6rem;
  overflow: hidden;
`;

export const LabelingPressContainer = styled.div`
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  line-height: 145%;
  font-weight: 400;
  color: #000;
`;

export const PressInfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
  padding-right: 5.9rem;

  .copy-press-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
`;

export const PressContentScrollWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 1.6rem;
  ${hideScrollbar}
`;

export const RightButtonToGoNextPageWrapper = styled.div`
  position: relative;
`;

export const BottomButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  height: 4rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 130%;
  border-radius: 0.4rem;
  padding: 0 2.4rem;
  cursor: pointer;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const LeftButtonWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
`;

export const PrevButton = styled(BottomButton)`
  background-color: #ffffff;
  border: 0.1rem solid #0198e9;
  color: #0198e9;
  padding: 0 2.4rem 0 1.6rem;

  &:disabled {
    color: #96a1b0;
    border: 0.1rem solid #b1b8c4;
  }
`;

export const NextButton = styled(BottomButton)`
  background-color: #0198e9;
  color: #ffffff;
  border: 0.1rem solid #0198e9;
  padding: 0 1.6rem 0 2.4rem;

  &:disabled {
    background-color: #cbd0d8;
    color: #96a1b0;
    border: 0.1rem solid #cbd0d8;
  }
`;

export const DoneButton = styled(BottomButton)`
  background-color: #0198e9;
  color: #ffffff;
  border: 0.1rem solid #0198e9;

  &:disabled {
    background-color: #cbd0d8;
    color: #96a1b0;
    border: 0.1rem solid #cbd0d8;
  }
`;
