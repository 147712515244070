import styled from "styled-components";

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.4rem 0;
  width: 51.2rem;
  height: 20.3rem;
  background-color: ${({ theme }) => theme.color.neural.white};
  padding: 2.8rem 3.2rem 2.4rem 3.2rem;
  border-radius: 2rem;
  cursor: auto;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const ModalTitle = styled.h1`
  display: flex;
  align-items: center;
  height: 1.4rem;
  color: ${({ theme }) => theme.color.neural.gray09};
  font-size: 2rem;
  font-weight: 600;
`;

export const ModalErrorCode = styled.span`
  color: ${({ theme }) => theme.status.S930};
  opacity: 0.5;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
`;

export const ModalText = styled.div`
  position: relative;
  bottom: 6px;
  height: 5.4rem;
  p {
    color: ${({ theme }) => theme.color.neural.gray09};
    font-size: 1.4rem;
    line-height: 1.6;
    font-weight: 400;
  }
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const OkButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 21.4rem;
  height: 3.5rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 100%;
  color: ${({ theme }) => theme.color.neural.white} !important;
  padding: 1.2rem 0;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.status.S930};
  cursor: pointer;
`;

export const Dimmed = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
`;
