import dayjs from "dayjs";

import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import { ClinicalTrialDesignContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/clinicalTrialDesignContent.types";

import {
  createContentFromHTML,
  createContentFromHTMLForSwitchTable,
} from "../../../utils/getValueFromRefMap";

export const useCreateClinicalTrialDesignContent = () => {
  const refMap = useArticleContentRefMap();

  return () => {
    const armNameList = [...refMap.keys()].filter((key) =>
      key.startsWith("TC004-Arm Name-"),
    );

    const groupDesignList = [...refMap.keys()].filter((key) =>
      key.startsWith("TC004-Arm Name-0"),
    );

    const content: ClinicalTrialDesignContent = {
      text: {
        "Clinical Trial Design": groupDesignList.map((_, groupTableIndex) => {
          const targetArmNameList = armNameList.filter(
            (key) => key.split("||")[1] === `${groupTableIndex}`,
          );

          return {
            "Clinical Trial Design-1": {
              "Trial Name": createContentFromHTML(
                refMap,
                `TC004-Trial Name||${groupTableIndex}`,
              ),
              "Clinical Design Phase": createContentFromHTML(
                refMap,
                `TC004-Phase||${groupTableIndex}`,
              ),
              "Total Patients Number": createContentFromHTML(
                refMap,
                `TC004-Total Patients Number||${groupTableIndex}`,
              ),
              "Inclusion Criteria - Diagnostic": createContentFromHTML(
                refMap,
                `TC004-Inclusion Criteria - Diagnostic||${groupTableIndex}`,
              ),
              "Inclusion Criteria - Detail": createContentFromHTML(
                refMap,
                `TC004-Inclusion Criteria - Detail||${groupTableIndex}`,
              ),
              "NCT Name": createContentFromHTML(
                refMap,
                `TC004-NCT Name||${groupTableIndex}`,
              ),
            },
            "Clinical Trial Design-2": targetArmNameList.map(
              (__, shellIndex) => {
                return {
                  "Arm Name": createContentFromHTMLForSwitchTable(
                    refMap,
                    `TC004-Arm Name-${shellIndex}||${groupTableIndex}`,
                  ),
                  "Patients Number": createContentFromHTML(
                    refMap,
                    `TC004-Patients Number-${shellIndex}||${groupTableIndex}`,
                  ),
                  "Design Group Information": [
                    {
                      "Drug Name": createContentFromHTML(
                        refMap,
                        `TC004-Design Group Information-${shellIndex}||${groupTableIndex}`,
                      ),
                      Dose: {
                        value: [
                          {
                            text: "",
                            labels: [],
                          },
                        ],
                      },
                      Duration: {
                        value: [
                          {
                            text: "",
                            labels: [],
                          },
                        ],
                      },
                    },
                  ],
                };
              },
            ),
          };
        }),
      },
      labels: [],
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
