import React, { forwardRef } from "react";

import * as S from "./InputChips.style";
import IconPlus from "../../../shared/Icons/IconPlus/IconPlus";

interface Props {
  onBlurHandler: () => void;
  onKeydownHandler: (e: React.KeyboardEvent) => void;
}

const InputChips = forwardRef(
  (
    { onBlurHandler, onKeydownHandler }: Props,
    ref: React.ForwardedRef<HTMLSpanElement>,
  ) => {
    return (
      <S.InputChipsWrapper>
        <S.PlusIcon>
          <IconPlus />
        </S.PlusIcon>
        <S.Input
          ref={ref}
          data-placeholder="Add Interest"
          contentEditable
          suppressContentEditableWarning
          onBlur={onBlurHandler}
          onKeyDown={(e) => onKeydownHandler(e)}
        />
      </S.InputChipsWrapper>
    );
  },
);

export default InputChips;
