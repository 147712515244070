"use client";

import styled, { css } from "styled-components";

import {
  LABEL_TEXT_COLOR,
  LABEL_TYPE_COLOR,
} from "./constants/DBResultsLabel.constants";
import { LabelType } from "./DBResultsLabel";

export const LabelWrapper = styled.button<{
  $labelType: LabelType;
  $isGroupLabel?: boolean;
}>`
  display: flex;
  padding: var(--Spacing-2, 0.2rem) var(--Spacing-6, 0.6rem);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-2, 0.2rem);
  border-radius: var(--Border-Radius-4, 0.4rem);
  border: 1px solid
    ${({ $labelType }) =>
      $labelType === "normal"
        ? "var(--Color-Common-Border-Tertiary, #e5e7eb)"
        : LABEL_TYPE_COLOR[$labelType].primary};
  background: ${({ $labelType }) => LABEL_TYPE_COLOR[$labelType].primary};
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12),
    0px 1px 2px 0px rgba(0, 0, 0, 0.16);
  cursor: pointer;

  &:hover:not(.group-label) {
    background: ${({ $labelType }) => LABEL_TYPE_COLOR[$labelType].hover};
    border: 1px solid ${({ $labelType }) => LABEL_TYPE_COLOR[$labelType].hover};
  }

  &:active:not(.group-label) {
    background: ${({ $labelType }) => LABEL_TYPE_COLOR[$labelType].active};
    border: 1px solid ${({ $labelType }) => LABEL_TYPE_COLOR[$labelType].active};
  }

  ${({ $isGroupLabel, $labelType }) =>
    $isGroupLabel &&
    css`
      > span {
        color: ${LABEL_TEXT_COLOR[$labelType]};
      }

      > svg {
        path {
          fill: ${LABEL_TEXT_COLOR[$labelType]};
        }
      }

      background-color: ${$labelType === "normal"
        ? LABEL_TYPE_COLOR[$labelType].active
        : "white"};
      border: 1px solid
        ${$labelType === "normal"
          ? "var(--Color-Common-Border-Primary, #7C899C)"
          : LABEL_TYPE_COLOR[$labelType].active};
    `}
`;

export const LabelText = styled.span`
  color: var(--Color-Common-Text-Primary, #2a2f37);
  font-family: ${({ theme }) => theme.font.fontFamily.pretendard};
  font-size: 1.2rem;
  font-weight: 500;
`;

export const CountText = styled.div<{ $labelType: LabelType }>`
  width: 1.6rem;
  height: 1.6rem;
  padding: 0.1333rem;
  color: ${({ $labelType }) => LABEL_TEXT_COLOR[$labelType]};
  font-family: ${({ theme }) => theme.font.fontFamily.pretendard};
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 130%;

  border-radius: 50%;
  background-color: ${({ $labelType }) =>
    $labelType === "normal"
      ? "var(--Color-Common-Bg-Tertiary, #CBD0D8)"
      : LABEL_TYPE_COLOR[$labelType].hover};
`;
