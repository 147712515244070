import React from "react";

import * as S from "./TableCreatorTextFieldCheckboxInput.style";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
}

const TableCreatorTextFieldCheckboxInput = ({ checked, ...rest }: Props) => {
  return <S.Checkbox type="checkbox" checked={checked} {...rest} />;
};

export default TableCreatorTextFieldCheckboxInput;
