import React from "react";

import * as S from "./InsertImageNoImageDescriptionContainer.style";

const NO_IMAGE_DATA_DESCRIPTION = {
  ai: `There are no generated AI images.\nTo add one, click the AI Image Generate button.`,
  select: `There are no images in the library.\nTo add one, proceed with User Upload.`,
};

type Props = {
  type: "ai" | "select";
};

const InsertImageNoImageDescriptionContainer = ({ type }: Props) => {
  return (
    <S.Wrapper>
      <S.Description>{NO_IMAGE_DATA_DESCRIPTION[type]}</S.Description>
    </S.Wrapper>
  );
};

export default InsertImageNoImageDescriptionContainer;
