export const checkEqualArray = (
  firstArray: unknown[],
  secondArray: unknown[],
) => {
  if (firstArray.length !== secondArray.length) {
    return false;
  }

  return firstArray.every(
    (item, index) =>
      JSON.stringify(item) === JSON.stringify(secondArray[index]),
  );
};
