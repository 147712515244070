import styled from "styled-components";

export const ImageSourceButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 2.4rem;

  button {
    width: 100%;
    padding: 0;
    cursor: pointer;
  }
`;

export const SourceButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 0.4rem;
  padding: 0.8rem;
  border: 0.1rem solid #0198e9;
`;
export const PreviewButton = styled(SourceButton)`
  background-color: #ffffff;
  color: #0198e9;

  &:disabled {
    border: 0.1rem solid #b1b8c4;
    color: #96a1b0;
  }
`;

export const UploadButton = styled(SourceButton)`
  background-color: #0198e9;
  color: #ffffff;

  &:disabled {
    border: 0.1rem solid #cbd0d8;
    background-color: #cbd0d8;
    color: #96a1b0;
  }
`;
