import {
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
} from "@frontend/common";

import useArticleSection from "@/components/business/FillInTableContainer/hooks/useArticleSection";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { EXPEDITED_PROGRAM } from "../constants/expeditedProgramKey.constants";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseGetNumberOfShellReturn {
  shellCount: (
    groupTableKey: string,
    tableIndex: number,
    groupShellIndex: number,
    subGroupShellIndex?: number,
  ) => number;
}

const SHELL_PATH_TO_COUNT_SHELL: Record<
  string,
  {
    [key: string]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      subGroupShellIndex?: number,
    ) => number;
  }
> = {
  "Clinical Trials": {
    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation]: (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
      subGroupShellIndex?: number,
    ) =>
      data?.[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN
      ]?.[tableIndex][
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
      ]?.[subGroupShellIndex || 0]?.[
        ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation
      ]?.length || 1,

    "Endpoint Group Information": (
      data: ArticleEntryData,
      tableIndex: number,
      groupShellIndex: number,
    ) =>
      data?.["Clinical Trials"]?.["Clinical Endpoints (Table)"]?.[tableIndex]?.[
        "Endpoints Table Information"
      ]?.[groupShellIndex]?.["Endpoint Group Information"].length || 1,
  },
  "Clinical Trial Status": {},
  Regulatory: {},
  [EXPEDITED_PROGRAM]: {},
  Deals: {
    "Drug Profile": (data: ArticleEntryData) =>
      data?.Deals?.["Drug Profile"]?.length || 1,
  },
  [SECTION_KEYS.ventureCapital]: {
    "Drug Profile": (data: ArticleEntryData) =>
      data?.[SECTION_KEYS.ventureCapital]?.["Drug Profile"]?.length || 1,
  },
};

export const useGetNumberOfShell = (): UseGetNumberOfShellReturn => {
  const articleSection = useArticleSection();
  const entryData = useEntryDataValue(getPressIdFromURL());

  return {
    shellCount: (
      shellKey: string,
      tableIndex: number,
      groupShellIndex: number,
      subGroupShellIndex?: number,
    ) =>
      SHELL_PATH_TO_COUNT_SHELL[articleSection][shellKey]
        ? SHELL_PATH_TO_COUNT_SHELL[articleSection][shellKey](
            entryData,
            tableIndex,
            groupShellIndex,
            subGroupShellIndex,
          ) || 1
        : 1,
  };
};
