import React from "react";

import * as S from "./Modal.style";
import Portal from "./Portal";

interface Props {
  children: React.ReactNode;
  closeModal?: () => void;
}

const Modal = ({ children, closeModal }: Props) => {
  return (
    <Portal>
      <S.ModalWrapper>
        <S.ModalContents>{children}</S.ModalContents>
        <S.Background onClick={closeModal} />
      </S.ModalWrapper>
    </Portal>
  );
};

export default Modal;
