"use client";

import React, { useEffect, useRef, useState } from "react";

import * as S from "./DBSearchFilterSelect.style";
import { IconChevronDown, IconChevronUp } from "../../../../shared/Icons";

interface FilterType {
  key: string;
  name: string;
}

interface Props {
  currentFilter: string;
  handleChangeFilter: (filter: FilterType) => void;
  filterList: FilterType[];
}

const DBSearchFilterSelect = ({
  currentFilter,
  handleChangeFilter,
  filterList,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleCheckClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const clickOutsideDropDown = !dropdownRef.current?.contains(target);
      const clickSelectButton = selectButtonRef.current?.contains(target);
      const isButton = target.dataset.button;

      if (isOpen && clickOutsideDropDown && !clickSelectButton && !isButton) {
        setIsOpen(false);
      }
    };
    window.addEventListener("click", handleCheckClickOutside);

    return () => window.removeEventListener("click", handleCheckClickOutside);
  }, [isOpen]);

  return (
    <S.FilterSelectWrapper>
      <S.FilterSelectButton
        ref={selectButtonRef}
        onClick={() => setIsOpen(!isOpen)}
      >
        <S.CurrentFilter>{currentFilter}</S.CurrentFilter>
        <S.DropdownButton>
          {isOpen ? <IconChevronUp /> : <IconChevronDown />}
        </S.DropdownButton>
      </S.FilterSelectButton>
      {isOpen && (
        <S.FilterDropdownList ref={dropdownRef}>
          {filterList.map(({ key, name }) => (
            <S.FilterItem
              key={key}
              $isSelect={name === currentFilter}
              onClick={() => {
                setIsOpen(false);
                handleChangeFilter({ key, name });
              }}
            >
              {name}
            </S.FilterItem>
          ))}
        </S.FilterDropdownList>
      )}
    </S.FilterSelectWrapper>
  );
};

export default DBSearchFilterSelect;
