"use client";

import styled from "styled-components";

export const SocialMedia = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1.6rem;
`;

export const SocialMediaTitle = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: 3.2rem;
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1.4rem;
  font-weight: 500;
  color: ${({ theme }) => theme.color.neural.white};
  opacity: 0.9;
`;

export const SocialMediaList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1.6rem;
  height: 3.2rem;
`;

export const SocialMediaItem = styled.span<{ width: number; height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;

  img {
    width: ${({ width }) => `${width}rem`};
    height: ${({ height }) => `${height}rem`};
  }
`;
