import { useEffect, useState } from "react";

import { useHandleUnsuitablePress } from "@/components/business/PressClassificationContainer/hooks/useHandleUnsuitablePress";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import IconX from "@images/pressClassification/icon-unsuitable-x-mark.svg";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import UnsuitableReasonCheckItem from "./components/UnsuitableReasonCheckItem/UnsuitableReasonCheckItem";
import * as S from "./PressUnsuitableReasonContainer.style";
import {
  DEFAULT_REASON,
  UNSUITABLE_REASON_LIST,
} from "./UnsuitableReason.constants";

interface Props {
  closeModalUnsuitablePress: () => void;
  isOpenModalUnsuitablePress: boolean;
  articleRejectReason: string | null;
}

const PressUnsuitableReasonContainer = ({
  closeModalUnsuitablePress,
  isOpenModalUnsuitablePress,
  articleRejectReason,
}: Props) => {
  const draftArticleId = getPressIdFromURL();
  const { data: articleInfo } = useArticleInfoQuery(Number(draftArticleId));
  const [isUpdated, setIsUpdated] = useState(false);
  const { unsuitablePressReasonList, setUnsuitablePressReasonList } =
    useHandleUnsuitablePress();

  useEffect(() => {
    const updateResponseDataList = UNSUITABLE_REASON_LIST.map((item) => {
      if (
        item.reason === articleInfo.articleRejectReason &&
        !!articleInfo.articleRejectReason
      ) {
        if (item.type === "addId")
          return {
            ...item,
            duplId: articleInfo.duplArticleId,
            isTarget: true,
          };

        return {
          ...item,
          isTarget: true,
        };
      } else if (
        articleInfo.articleRejectReason &&
        !DEFAULT_REASON.includes(articleInfo.articleRejectReason)
      ) {
        if (item.type === "writing")
          return {
            ...item,
            reason: articleInfo.articleRejectReason,
            isTarget: true,
          };
      }

      return item;
    });

    setUnsuitablePressReasonList(updateResponseDataList);
    setIsUpdated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleInfo.articleRejectReason, setUnsuitablePressReasonList]);

  return (
    <S.PressUnsuitableReasonContainer
      isOpenModalUnsuitablePress={isOpenModalUnsuitablePress}
    >
      <S.TopWrapper>
        <S.Text>
          Please select only one reason why the article is not suitable.
        </S.Text>
        <S.CloseButton onClick={closeModalUnsuitablePress}>
          <img src={IconX} alt="close button" />
        </S.CloseButton>
      </S.TopWrapper>
      <S.ReasonCheckListBox>
        {isUpdated &&
          unsuitablePressReasonList.map((item) => (
            <UnsuitableReasonCheckItem
              key={item.id}
              name="unsuitableReason"
              reasonId={item.id}
              reasonType={item.type}
              isTarget={item.isTarget}
              placeholder={item.placeholder}
              reason={item.reason}
              explanation={item.explanation}
              duplId={item.duplId}
              articleRejectReason={articleRejectReason}
              unsuitablePressReasonList={unsuitablePressReasonList}
              setUnsuitablePressReasonList={setUnsuitablePressReasonList}
            />
          ))}
      </S.ReasonCheckListBox>
    </S.PressUnsuitableReasonContainer>
  );
};

export default PressUnsuitableReasonContainer;
