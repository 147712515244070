"use client";

import styled, { css } from "styled-components";

export const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`;

export const ModalContents = styled.div<{ $type: "fix" | "full" }>`
  z-index: 32;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  ${({ $type }) =>
    $type === "fix" &&
    css`
      display: block;
      left: 2rem;
      bottom: 2.2rem;
    `}
`;

export const guideModalWrapper = styled.div<{ $type: "fix" | "full" }>`
  display: flex;
  width: ${({ $type }) => ($type === "fix" ? "36rem" : "100%")};
  padding: 3.2rem 1.6rem;
  flex-direction: column;
  align-items: center;
  gap: var(--Spacing-20, 2rem);

  border-radius: var(--Border-Radius-8, 0.8rem);
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12),
    0px 4px 8px 0px rgba(0, 0, 0, 0.16);

  ${({ $type }) =>
    $type === "full" &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

export const GuideContentWrapper = styled.div`
  color: var(--grayscale-spectrum-neural-blue-gray-95, #11161e);
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 150%;
`;
export const GuideTitle = styled.h4`
  color: var(--grayscale-spectrum-neural-blue-gray-95, #11161e);
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 140%;
`;

export const GuideContent = styled.p`
  color: var(--grayscale-spectrum-neural-blue-gray-95, #11161e);
  font-family: ${({ theme }) => theme.font.fontFamily.jost};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 150%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1.6rem;
`;

export const CopyButton = styled.button`
  display: flex;
  height: 4rem;
  width: 100%;
  min-height: var(--Size-Btn-Sm, 2.8rem);
  max-height: var(--Size-Btn-Lg, 4.8rem);
  padding: var(--Spacing-Btn-8, 0.8rem) var(--Spacing-Btn-16, 1.6rem);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-Btn-4, 0.4rem);

  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Interactive-Primary, #1baefe);

  color: var(--Color-Common-Text-Interactive-Inverse, #fff);

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  cursor: pointer;
`;

export const ContinueButton = styled.button`
  color: var(--Color-Common-Text-Secondary, #515c6c);
  text-align: center;
  padding: 0;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  cursor: pointer;
`;
