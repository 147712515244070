import { useRef, useEffect, useCallback } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { useInsertImageWebSocketMessages } from "@/contexts/InsertImageWebSocketMessagesContext";
import { handleAiImageRegenerate } from "@InsertImageContainer/components/AiImageFilter/utils/handleAiImageRegenerate";
import { parseArticleContentSocketMessage } from "@utils/ArticleContentSocketEvent";
import { getCookie } from "@utils/cookie";
import {
  toastFailedCreateAiImage,
  toastSuccessCreateAiImage,
} from "@utils/toast";

const DEV_SERVER_URL = "https://msa.bio-research.dev";
const LOCAL_SERVER_URL = "/v2/local";
const USER_EMAIL_KEY = "accountEmail";

const { hostname } = new URL(
  import.meta.env.VITE_BASE_V2_URL === LOCAL_SERVER_URL
    ? DEV_SERVER_URL
    : import.meta.env.VITE_BASE_V2_URL,
);

export const useWebSocket = () => {
  const socketRef = useRef<WebSocket>();
  const location = useLocation();
  const navigate = useNavigate();
  const userEmail = getCookie(USER_EMAIL_KEY);
  const { changeInsertImageWebSocketMessages } =
    useInsertImageWebSocketMessages();

  const moveToInsertImage = useCallback(
    (draftArticleId: number) =>
      navigate(`/insert-image?id=${draftArticleId}&type=ai`),
    [navigate],
  );

  useEffect(() => {
    if (location.pathname === "/") return;

    (async () => {
      const websocketHost = hostname;
      const websocketUrl = `wss://${websocketHost}/message/ai/image/process`;
      socketRef.current = new WebSocket(websocketUrl);

      socketRef.current.onmessage = (ev) => {
        const response: string = ev.data;

        if (response.startsWith("{")) {
          const message = parseArticleContentSocketMessage(response);
          const { aiImageStatus, draftArticleId, email } = message;
          const isMine = email === userEmail;

          if (!isMine) return;

          changeInsertImageWebSocketMessages(message);

          if (aiImageStatus === "SUCCESS") {
            toastSuccessCreateAiImage(draftArticleId, () =>
              moveToInsertImage(draftArticleId),
            );
          }
          if (aiImageStatus === "FAIL") {
            toastFailedCreateAiImage(
              draftArticleId,
              () => moveToInsertImage(draftArticleId),
              () => handleAiImageRegenerate(String(draftArticleId)),
            );
          }
        }
      };
    })();

    return () => {
      socketRef.current?.close();
    };
  }, [
    changeInsertImageWebSocketMessages,
    location.pathname,
    moveToInsertImage,
    userEmail,
  ]);

  return socketRef;
};
