import { useCallback, useState } from "react";

import { CopyPressButton } from "@frontend/common";
import { useNavigate } from "react-router-dom";

import BottomButtonContainer from "@/components/shared/ui/BottomButtonContainer/BottomButtonContainer";
import PressTitle from "@/components/shared/ui/PressTitle/PressTitle";
import TopFixInfo from "@/components/shared/ui/TopFixInfo/TopFixInfo";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { useCopyPress } from "@hooks/useCopyPress/useCopyPress";
import IconArrowLeft from "@images/common/icon-arrow-left.svg";
import IconArrowRightDisable from "@images/common/icon-arrow-right-gray.svg";
import IconArrowRight from "@images/common/icon-arrow-right.svg";
import { usePressClassificationRoles } from "@libs/roles/hooks/usePressClassificationRoles";
import PublishablePressCheckbox from "@organisms/PublishablePressCheckbox/PublishablePressCheckbox";
import { ROUTE_PATHS } from "@routes/routePath";
import { usePressIdFromURL } from "@utils/getStateFromURL";

import PressClassificationPress from "./components/PressClassificationPress/PressClassificationPress";
import PressLabelInfoList from "./components/PressLabelInfoList/PressLabelInfoList";
import UnsuitableButton from "./components/UnsuitableButton/UnsuitableButton";
import { useCheckPageEntryInPressClassification } from "./hooks/useCheckPageEntryInPressClassification";
import * as S from "./PressClassificationContainer.style";

const DISABLED_STATUS_TO_MOVE_NEXT_PROCESS: string[] = [
  DRAFT_STATUS_CODE.unsuitable,
  DRAFT_STATUS_CODE.aiEntryInProgress,
  DRAFT_STATUS_CODE.notForAiEntry,
];

const DISABLED_STATUS_MANUAL_CLASSIFICATION: string[] = [
  DRAFT_STATUS_CODE.aiEntryInProgress,
];

const PressClassificationContainer = () => {
  const navigate = useNavigate();
  const [currentSize, setCurrentSize] = useState({
    width: 0,
    y: 0,
  });

  const draftArticleId = usePressIdFromURL();
  const { data: articleInfo } = useArticleInfoQuery(Number(draftArticleId));
  const pressStatus = articleInfo?.draftArticleStatusHighest;
  const classificationManual = articleInfo?.classificationManual;
  const unsuitableReason = articleInfo?.articleRejectReason;
  const { hasManualClassificationRoles, hasNextPageAccessRoles } =
    usePressClassificationRoles();
  const { canEntryFillInTablePage } = useCheckPageEntryInPressClassification();
  const { copyPress } = useCopyPress();

  const disabledClassificationManual =
    DISABLED_STATUS_MANUAL_CLASSIFICATION.includes(pressStatus || "");

  const moveNext = async () => {
    navigate(`${ROUTE_PATHS.fillInTable}?id=${draftArticleId}`);
  };

  const movePrev = async () => {
    navigate(ROUTE_PATHS.pressList);
  };

  const isDisabledNextProcess =
    DISABLED_STATUS_TO_MOVE_NEXT_PROCESS.includes(pressStatus || "") ||
    !articleInfo?.classificationManual;

  const handleCurrentPressSize = useCallback((element: HTMLDivElement) => {
    if (!element) return;

    setCurrentSize({
      width: element.getBoundingClientRect().width,
      y: element.getBoundingClientRect().y + 16,
    });
  }, []);

  return (
    <S.LabelingPressWrapper>
      <TopFixInfo
        disabledClassificationManual={disabledClassificationManual}
        hasManualClassificationRoles={hasManualClassificationRoles}
      />
      <S.PressInfoContainer>
        <PressLabelInfoList title="Label Info" />
        <PressTitle />
        <CopyPressButton className="copy-press-button" onClick={copyPress} />
      </S.PressInfoContainer>
      <S.ContentsWrapper>
        <S.LabelingPressContainer ref={handleCurrentPressSize}>
          <S.PressContentScrollWrapper>
            <PressClassificationPress
              columnLabelViewerSize={currentSize}
              classificationManual={classificationManual}
            />
          </S.PressContentScrollWrapper>
        </S.LabelingPressContainer>
      </S.ContentsWrapper>
      <BottomButtonContainer>
        <S.LeftButtonWrapper>
          <S.PrevButton onClick={movePrev}>
            <img src={IconArrowLeft} alt="go to press list" />
            Press List
          </S.PrevButton>
          <UnsuitableButton />
          <PublishablePressCheckbox />
        </S.LeftButtonWrapper>
        <S.RightButtonToGoNextPageWrapper>
          {hasNextPageAccessRoles ? (
            !canEntryFillInTablePage ? (
              <S.NextButton disabled>
                Another Editor is working...
                <img
                  src={IconArrowRightDisable}
                  alt="Another Editor is working..."
                />
              </S.NextButton>
            ) : (
              <S.NextButton disabled={isDisabledNextProcess} onClick={moveNext}>
                Fill in Table
                <img
                  src={
                    isDisabledNextProcess
                      ? IconArrowRightDisable
                      : IconArrowRight
                  }
                  alt="go to fill in Table"
                />
              </S.NextButton>
            )
          ) : (
            <S.DoneButton
              onClick={movePrev}
              disabled={!classificationManual && !unsuitableReason}
            >
              Done
            </S.DoneButton>
          )}
        </S.RightButtonToGoNextPageWrapper>
      </BottomButtonContainer>
    </S.LabelingPressWrapper>
  );
};

export default PressClassificationContainer;
