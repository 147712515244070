import React from "react";

import {
  getMainLabelData,
  usePostMainLabelMutation,
} from "@api/mainLabel/mainLabel";

import {
  FormattedMainLabelItemType,
  MainLabelNames,
} from "../../../../../types/articleMainLabel.types";

export const useMainLabelRadioDropDown = () => {
  const mainLabelList = getMainLabelData();
  const { mutateAsync: updateMainLabel } = usePostMainLabelMutation();

  const getTargetMainLabelIdByLabelName = (targetLabelName: MainLabelNames) =>
    String(
      mainLabelList.filter((label) => label.labelName === targetLabelName)?.[0]
        .id,
    );

  const changeTargetMainLabel = (
    event: React.ChangeEvent<HTMLInputElement>,
    labelInfo: FormattedMainLabelItemType,
  ) => {
    const changedMainLabel = event.target.value;

    const updatedMainLabelList = mainLabelList.map((label) => {
      return {
        id: String(label.id),
        labelName: label.labelName,
        type: label.type,
        articleSection: label.articleSection,
        entity: label.entity,
        value:
          String(label.id) ===
          getTargetMainLabelIdByLabelName(labelInfo.labelName)
            ? changedMainLabel
            : label.labelValue,
      };
    });

    updateMainLabel(updatedMainLabelList);
  };
  return {
    changeTargetMainLabel,
  };
};
