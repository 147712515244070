import { ENTRY_DATA_SECTION_KEYS as SECTION_KEYS } from "@frontend/common";

import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { ARTICLE_SECTION_BY_CLASSIFICATION } from "@FillInTableContainer/shared/constants/articleSection.constants";
import { NotCorrectDataFormatError } from "@pages/FillInTablePage/errors/classes/notCorrectDataFormat";
import { useEntryDataValue } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { getClinicalTrialEntryDataFormatCheckList } from "../utils/initialEntryDataFormat/initialEntryDataFormatClinicalTrials";
import { getClinicalTrialStatusEntryDataFormatCheckList } from "../utils/initialEntryDataFormat/initialEntryDataFormatClinicalTrialStatus";
import { getDealEntryDataFormatCheckList } from "../utils/initialEntryDataFormat/initialEntryDataFormatDeals";
import { getExpeditedProgramEntryDataFormatCheckList } from "../utils/initialEntryDataFormat/initialEntryDataFormatExpeditedProgram";
import { getRegulatoryEntryDataFormatCheckList } from "../utils/initialEntryDataFormat/initialEntryDataFormatRegulatory";
import { getVentureCapitalEntryDataFormatCheckList } from "../utils/initialEntryDataFormat/initialEntryDataFormatVentureCapital";

export const useCheckInitialEntryDataFormat = () => {
  const entryData = useEntryDataValue(getPressIdFromURL());
  const { data: articleInfo } = useArticleInfoQuery();

  const articleSection =
    (articleInfo &&
      ARTICLE_SECTION_BY_CLASSIFICATION[articleInfo?.classificationManual]) ||
    "none";

  const checkClassificationEntryDataFormat = () => {
    if (articleSection === "none") return ["Article section not found."];

    if (articleSection === SECTION_KEYS.deals)
      return getDealEntryDataFormatCheckList(entryData);

    if (articleSection === SECTION_KEYS.clinicalTrial)
      return getClinicalTrialEntryDataFormatCheckList(entryData);

    if (articleSection === SECTION_KEYS.clinicalTrialStatus)
      return getClinicalTrialStatusEntryDataFormatCheckList(entryData);

    if (articleSection === SECTION_KEYS.regulatory)
      return getRegulatoryEntryDataFormatCheckList(entryData);

    if (articleSection === SECTION_KEYS.expeditedProgram)
      return getExpeditedProgramEntryDataFormatCheckList(entryData);

    if (articleSection === SECTION_KEYS.ventureCapital)
      return getVentureCapitalEntryDataFormatCheckList(entryData);

    return [];
  };

  const checkData = checkClassificationEntryDataFormat();

  const checkDataLength = checkData.length;
  if (checkDataLength > 0) {
    throw new NotCorrectDataFormatError(checkData);
  }
};
