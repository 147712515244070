import styled from "styled-components";

import { hideScrollbarY } from "@styles/mixin";

export const TableCreatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const GroupTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  height: calc(100% - 4.4rem);
  padding-bottom: 33.6rem;

  ${hideScrollbarY};
`;
