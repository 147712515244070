import React from "react";

import IconSpin from "@images/spin/icon-spin.svg";

import * as S from "./Loader.style";

interface Props {
  width?: string;
  height?: string;
}

const Loader = ({ width = "8rem", height = "8rem" }: Props) => {
  return (
    <S.LoaderWrapper width={width} height={height}>
      <S.Loader src={IconSpin} alt="loading..." />
    </S.LoaderWrapper>
  );
};

export default Loader;
