import { ExpeditedProgramSection } from "@/types/expeditedProgram.types";
import {
  EXPEDITED_PROGRAM,
  EXPEDITED_PROGRAM_GROUP_KEY as eg,
  EXPEDITED_PROGRAM_CONTENTS as ec,
} from "@FillInTableContainer/shared/constants/expeditedProgramKey.constants";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  handleClickToClearExpeditedProgramContentsGroupShell: () => void;
  handleKeyDownToClearExpeditedProgramContentsGroupShell: (
    event: KeyboardEvent,
  ) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: ExpeditedProgramSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useExpeditedProgramContentsGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();

  const handleClearGroupShell = () => {
    const clearExpeditedProgramContentsFirstGroupShell =
      (): ArticleEntryData => {
        const currentData =
          entryData[EXPEDITED_PROGRAM]?.[eg.expeditedProgramContents];

        return {
          ...entryData,
          [EXPEDITED_PROGRAM]: {
            ...entryData[EXPEDITED_PROGRAM],
            [eg.expeditedProgramContents]: {
              ...currentData,
              [ec.designatedCompanyName]: {
                ...currentData?.[ec.designatedCompanyName],
                value: [],
              },
              [ec.expeditedProgram]: {
                ...currentData?.[ec.expeditedProgram],
                value: [],
              },
              [ec.regulatoryAgency]: {
                ...currentData?.[ec.regulatoryAgency],
                value: [],
              },
              [ec.updateDate]: {
                ...currentData?.[ec.updateDate],
                value: [],
              },
            },
          },
        } as ArticleEntryData;
      };

    const clearExpeditedProgramContentsSecondGroupShell =
      (): ArticleEntryData => {
        const currentData =
          entryData[EXPEDITED_PROGRAM]?.[eg.expeditedProgramContents];

        return {
          ...entryData,
          [EXPEDITED_PROGRAM]: {
            ...entryData[EXPEDITED_PROGRAM],
            [eg.expeditedProgramContents]: {
              ...currentData,
              [ec.AdditionalInformation]: {
                ...currentData?.[ec.AdditionalInformation],
                value: [],
              },
              [ec.designatedIndication]: {
                ...currentData?.[ec.designatedIndication],
                value: [],
              },
            },
          },
        } as ArticleEntryData;
      };

    if (info.groupShellKey === "first") {
      setEntryData(clearExpeditedProgramContentsFirstGroupShell());
      changeCurrentHistory({
        entryData: clearExpeditedProgramContentsFirstGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (info.groupShellKey === "second") {
      setEntryData(clearExpeditedProgramContentsSecondGroupShell());
      changeCurrentHistory({
        entryData: clearExpeditedProgramContentsSecondGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  };

  const handleClickToClearExpeditedProgramContentsGroupShell = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShell();
    }
  };

  const handleKeyDownToClearExpeditedProgramContentsGroupShell = (
    event: KeyboardEvent,
  ) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearGroupShell();
    }
  };

  return {
    handleClickToClearExpeditedProgramContentsGroupShell,
    handleKeyDownToClearExpeditedProgramContentsGroupShell,
  };
};
