import React from "react";

import { IconAlertTriangle, IconCheck, IconCopy } from "@frontend/common";

import * as S from "./CopyButton.style";

type Props = {
  copyStatus: "default" | "success" | "error";
  copyCurrentTextValue: (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => Promise<void>;
};

const CopyButton = ({ copyStatus, copyCurrentTextValue }: Props) => {
  const getCopyIcon = () => {
    if (copyStatus === "default")
      return (
        <S.CopyButton type="button" onClick={copyCurrentTextValue}>
          <IconCopy width={24} height={24} color="#515C6C" />
        </S.CopyButton>
      );

    if (copyStatus === "success")
      return (
        <S.IconWrapper>
          <IconCheck width={24} height={24} color="#11AC45" />
        </S.IconWrapper>
      );
    if (copyStatus === "error")
      return (
        <S.IconWrapper>
          <IconAlertTriangle width={24} height={24} color="#96A1B0" />
        </S.IconWrapper>
      );
  };

  return <>{getCopyIcon()}</>;
};

export default CopyButton;
