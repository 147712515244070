import { SectionCode } from "@frontend/common";
import { useSuspenseQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/queryKeys";

import { companyLogoApis } from "./companyLogo.service";

export const useCompanyLogoInfoQuery = (
  articleId: number,
  articleSection: SectionCode,
) => {
  const query = useSuspenseQuery({
    queryKey: [QUERY_KEYS.companyLogoList, articleId],
    queryFn: () => {
      return companyLogoApis.post({
        articleId,
        articleSection,
      });
    },
  });

  return query;
};
