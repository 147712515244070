import React from "react";

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const IconEdit = ({ width = 16, height = 16, color = "#515C6C" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8888 3.03976C11.7481 3.03976 11.6088 3.06748 11.4788 3.12132C11.3488 3.17516 11.2307 3.25407 11.1312 3.35355L3.72513 10.7597L3.15696 12.8429L5.24024 12.2748L12.6463 4.86867C12.7458 4.76919 12.8247 4.65108 12.8786 4.5211C12.9324 4.39112 12.9601 4.25181 12.9601 4.11111C12.9601 3.97042 12.9324 3.83111 12.8786 3.70113C12.8247 3.57114 12.7458 3.45304 12.6463 3.35355C12.5469 3.25407 12.4288 3.17516 12.2988 3.12132C12.1688 3.06748 12.0295 3.03976 11.8888 3.03976ZM11.0961 2.19744C11.3474 2.09334 11.6168 2.03976 11.8888 2.03976C12.1608 2.03976 12.4302 2.09334 12.6815 2.19744C12.9328 2.30153 13.1611 2.45411 13.3535 2.64645C13.5458 2.83879 13.6984 3.06713 13.8025 3.31844C13.9066 3.56975 13.9601 3.8391 13.9601 4.11111C13.9601 4.38312 13.9066 4.65247 13.8025 4.90378C13.6984 5.15509 13.5458 5.38343 13.3535 5.57578L5.85346 13.0758C5.79193 13.1373 5.71541 13.1817 5.63146 13.2046L2.57591 14.0379C2.4028 14.0851 2.21767 14.036 2.09079 13.9091C1.96392 13.7822 1.91475 13.5971 1.96197 13.424L2.7953 10.3684C2.81819 10.2845 2.8626 10.208 2.92413 10.1464L10.4241 2.64645C10.6165 2.45411 10.8448 2.30153 11.0961 2.19744Z"
        fill={color}
      />
    </svg>
  );
};

IconEdit.displayName = "Edit";
export default IconEdit;
