import { UseQueryOptions, useSuspenseQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/queryKeys";
import { PageEntryData } from "@interface/pageEntry.interface";
import { pageEntryApis } from "@services/pageEntry.service";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { queryClient } from "../../api/queryClient";

export const usePageEntryQuery = (
  url: string,
  options?: UseQueryOptions<PageEntryData, unknown, PageEntryData, string[]>,
) => {
  const query = useSuspenseQuery<
    PageEntryData,
    unknown,
    PageEntryData,
    string[]
  >({
    queryKey: [QUERY_KEYS.pageEntry, url],
    queryFn: async () => {
      const response = await pageEntryApis.get(url);
      return response?.data;
    },
    ...options,
    select: (data: PageEntryData): PageEntryData => data,
  });

  return query;
};

export const usePageEntryEditorWritingQuery = (
  url: string,
  options?: UseQueryOptions<boolean, unknown, boolean, string[]>,
) => {
  const draftArticleId = getPressIdFromURL();
  const query = useSuspenseQuery({
    queryKey: [QUERY_KEYS.pageEntryEditorWriting, url],
    queryFn: () => pageEntryApis.post(url, draftArticleId),
    ...options,
    select: (data) => data,
  });

  return query;
};

export const getPageEntry = async (url: string) => {
  const response = queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.pageEntry, url],
    queryFn: async () => pageEntryApis.get(url),
  });

  return response;
};

export const postPageEntry = async (url: string, draftArticleId: string) => {
  const response = queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.pageEntryEditorWriting, url],
    queryFn: async () => pageEntryApis.post(url, draftArticleId),
  });

  return response;
};
