import dayjs from "dayjs";

import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import {
  REF_CODE_EXPEDITED_PROGRAM_ADDITIONAL_INFORMATION_ON_EXPEDITED_PROGRAM,
  REF_CODE_EXPEDITED_PROGRAM_DESIGNATED_COMPANY_NAME,
  REF_CODE_EXPEDITED_PROGRAM_DESIGNATED_INDICATION,
  REF_CODE_EXPEDITED_PROGRAM_EXPEDITED_PROGRAM,
  REF_CODE_EXPEDITED_PROGRAM_REGULATORY_AGENCY,
  REF_CODE_EXPEDITED_PROGRAM_UPDATE_DATE,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { ExpeditedProgramContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/expeditedProgramContent.types";
import { createContentFromHTML } from "@EditArticleContainer/shared/utils/getValueFromRefMap";

export const useCreateExpeditedProgramTableContent = () => {
  const refMap = useArticleContentRefMap();

  return () => {
    const content: ExpeditedProgramContent = {
      text: {
        "Expedited Program Contents": {
          "Update Date": createContentFromHTML(
            refMap,
            REF_CODE_EXPEDITED_PROGRAM_UPDATE_DATE,
          ),
          "Designated Company name": createContentFromHTML(
            refMap,
            REF_CODE_EXPEDITED_PROGRAM_DESIGNATED_COMPANY_NAME,
          ),
          "Regulatory Agency": createContentFromHTML(
            refMap,
            REF_CODE_EXPEDITED_PROGRAM_REGULATORY_AGENCY,
          ),
          "Expedited Program": createContentFromHTML(
            refMap,
            REF_CODE_EXPEDITED_PROGRAM_EXPEDITED_PROGRAM,
          ),
          "Designated Indication": createContentFromHTML(
            refMap,
            REF_CODE_EXPEDITED_PROGRAM_DESIGNATED_INDICATION,
          ),
          "Additional Information on Expedited Program": createContentFromHTML(
            refMap,
            REF_CODE_EXPEDITED_PROGRAM_ADDITIONAL_INFORMATION_ON_EXPEDITED_PROGRAM,
          ),
        },
      },
      labels: [],
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
