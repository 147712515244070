import { useCallback } from "react";

import {
  CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
  ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS,
  ENTRY_DATA_SECTION_KEYS,
} from "@frontend/common";

import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { PositionInfoProps } from "../ClinicalTrialDesignShell";
import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface Returns {
  clearClinicalDesign2Shell: () => void;
}

export const useClearClinicalTrialsDesign2Shell = (
  positionPropsInfo: PositionInfoProps,
): Returns => {
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { changeFocusStatus } = useTableCreatorFocusStatus();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());

  const clearClinicalDesign2Shell = useCallback(() => {
    const clearClinicalTrialDesignTwoShell = (): ArticleEntryData => {
      const defaultDataPath =
        entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN
        ];

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: defaultDataPath?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex)
                return {
                  ...tableInfo,
                  [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2]:
                    tableInfo[
                      ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
                    ].map((subGroupShellInfo, subGroupShellInfoIndex) => {
                      if (subGroupShellInfoIndex === info.subGroupShellIndex) {
                        if (
                          info.shellKey === "Arm Name" ||
                          info.shellKey === "Patients Number"
                        ) {
                          return {
                            ...subGroupShellInfo,

                            [info.shellKey]: {
                              ...subGroupShellInfo[info.shellKey],
                              value: [],
                            },
                          };
                        }
                        return {
                          ...subGroupShellInfo,
                          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation]:
                            subGroupShellInfo[
                              ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS
                                .designGroupInformation
                            ].map((shellInfo, shellInfoIndex) => {
                              if (shellInfoIndex === info.shellIndex) {
                                return {
                                  [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.drugName]:
                                    {
                                      ...shellInfo[
                                        ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS
                                          .drugName
                                      ],
                                      value: [],
                                    },
                                  [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.does]:
                                    {
                                      ...shellInfo[
                                        ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS
                                          .drugName
                                      ],
                                      value: [],
                                    },
                                  [ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS.duration]:
                                    {
                                      ...shellInfo[
                                        ENTRY_DATA_DESIGN_GROUP_INFORMATION_KEYS
                                          .duration
                                      ],
                                      value: [],
                                    },
                                };
                              }

                              return shellInfo;
                            }),
                        };
                      }

                      return subGroupShellInfo;
                    }),
                };
              return tableInfo;
            },
          ),
        },
      };
    };

    if (
      info.groupShellKey ===
      CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign2
    ) {
      setEntryData(clearClinicalTrialDesignTwoShell());
      changeCurrentHistory({
        entryData: clearClinicalTrialDesignTwoShell(),
        changedDataInfo: {
          target: "shell",
          action: "clear",
          position: {
            ...positionPropsInfo,
            value: null,
            valueIndex: null,
          },
        },
      });

      const designGroupInformationShellLength =
        entryData[ENTRY_DATA_SECTION_KEYS.clinicalTrial]?.[
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN
        ]?.[info.tableIndex][
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
        ]?.[info.subGroupShellIndex || 0][
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation
        ].length || 0;

      if (
        designGroupInformationShellLength > 1 &&
        info.shellKey ===
          ENTRY_DATA_CLINICAL_TRIAL_DESIGN_2_KEYS.designGroupInformation
      ) {
        changeFocusStatus({
          type: "delete",
          container: "shell",
        });
        return;
      }
    }

    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    changeInfo,
    entryData,
    info,
    positionPropsInfo,
    setEntryData,
  ]);

  return {
    clearClinicalDesign2Shell,
  };
};
