"use client";

import styled from "styled-components";

export const StatusChipList = styled.div<{ $isColumnAligned?: boolean }>`
  display: flex;
  flex-direction: ${({ $isColumnAligned }) =>
    $isColumnAligned ? "column" : "row"};
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.4rem;
`;
